const SRC_PADDING_BASE = 24;

export enum IMAGE_PADDING {
	PULL_SCALE = 1.5,
	ACTIVE_SCALE = 3,
	//active is 1/3 the size of the src image
	ACTIVE_SHAPE = SRC_PADDING_BASE / IMAGE_PADDING.ACTIVE_SCALE, //delibrately overlap by 1 pixel to hide the sins
	//pull is 1/1.5 the size of the src image
	PULL_SHAPE = SRC_PADDING_BASE / IMAGE_PADDING.PULL_SCALE
}
