'use strict';

import * as moment from "moment";
import * as utils from "utils";
import * as tinycolor from "tinycolor2";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

import TicketFilter from "js/model/Ticket";
let _lastReadableColor = {};
export default function TicketOps(scope, fbConnection){
	this.scope = scope;
	this.state = scope.legacyRouting.oldStyleSnapshot();
	
	this.fb = fbConnection;
	
	this.duplicateOffset = 25;
	this.timesAddClicked = 0;
}

TicketOps.prototype = {
	
	//### bulkAdd(list)
	// called by the bulk add ui. Perfoms some clean up, then calls add.
	//* a list of data to turn into tickets
	bulkAdd: function(list){
		if(!list || !list.length){ Logging.debug('list is empty'); return; }
		var self = this;
		var pullUpdate = this.scope.plan.$ref().child('pullUpdateDisabled');
		
		this.fakeRoles(list, this.scope.roles.fb);
		this.fakeLocations(list, this.scope.locations);
		
		this.allowIfExists(list, this.scope.priorities, "priorityId");
		this.allowIfExists(list, this.scope.projectMembers.fb, "creatorId");
		this.allowIfExists(list, this.scope.projectMembers.fb, "responsibleProjectMemberId");
		this.allowIfExists(list, this.scope.projectMembers.fb, "assignedProjectMemberId");
		
		//this.handleConstraints(list);
		
		var ticketList = [];
		var constraintList = [];
		list.forEach(function(t){
			if(t.type === "constraint"){
				constraintList.push(t);
			}
			else{
				ticketList.push(t);
			}
		});
		
		this.fb.set(pullUpdate, true)
		.then(function(){
			if(!ticketList.length){return self.scope.$q.when(true)}
			return self.addTickets(ticketList, null, {silent: true, tickets: self.scope.tickets});
		})
		.then(function(ticketRes){
			if(!constraintList.length){return ticketRes;}
			return self.addTickets(constraintList, null, {silent: true, tickets: self.scope.tickets}).then(function(resultArray){
				var p = [];
				resultArray.forEach(function(res){
					p.push(self.scope.constraints2.addIndex(self.state.planId, res.$id));
				});
				return self.scope.$q.all(p);
			});
		})
		.then(function(){ console.log('all done'); })
		.finally(function(){ self.fb.remove(pullUpdate); });
	},
	
	allowIfExists: function allowIfExists(importList, fbList, id){
		importList.forEach(function(t){
			if(!fbList.$getRecord(t[id])){
				delete t[id];
			}
		})
	},
	
	handleConstraints: function(importList){
		importList.forEach(function(t){
			if(t.type === "constraint"){
				
			}
		});
	},
	
	//### fakeRoles(ticketList, rolesList)
	//check a list of tickets and roles for name matches
	//then either assigns the roleId, or makes a new role
	fakeRoles: function(ticketList, rolesList){
		var rolesMap = {};
		var alreadyCreatedRoles = {};
		rolesList.forEach(function(r, key){ rolesMap[r.data.roleName] = rolesList.$keyAt(key); });
		ticketList.forEach(function(ticket){
			if(ticket.tempRoleName){
				var trm = ticket.tempRoleName.trim();
				//set to existing role
				if(rolesMap[trm]){
					ticket.roleId = rolesMap[trm];
				}
				else if(alreadyCreatedRoles[trm]){
					ticket.roleId = alreadyCreatedRoles[trm];
				}
				//make new role
				else{
					console.log('new role');
					var ref = rolesList.$ref();
					var roleId = ref.push();
					roleId.set({
						"roleId": roleId.key,
						"roleName": trm
					});
					ticket.roleId = roleId.key;
					alreadyCreatedRoles[trm] = roleId.key;
				}
				delete ticket.tempRoleName;
			}
		});
	},
	
	fakeLocations: function(ticketList, locationList){
		var locationsMap = {};
		var alreadyCreatedLocations = {};
		locationList.forEach(function(r, key){ locationsMap[r.data.name] = locationList.$keyAt(key); });
		ticketList.forEach(function(ticket){
			if(ticket.tempLocationName){
				//set to existing location
				if(locationsMap[ticket.tempLocationName]){
					ticket.locationId = locationsMap[ticket.tempLocationName];
				}
				else if(alreadyCreatedLocations[ticket.tempLocationName]){
					ticket.locationId = alreadyCreatedLocations[ticket.tempLocationName];
				}
				//make new location
				else{
					var ref = locationList.$ref().ref;
					var locationId = ref.push();
					locationId.set({
						//"locationId": locationId.key,
						"name": ticket.tempLocationName
					});
					ticket.locationId = locationId.key;
					alreadyCreatedLocations[ticket.tempLocationName] = locationId.key;
				}
				delete ticket.tempLocationName;
			}
		});
	},
	
	convertDepPlanIds: function(obj, newPlanId, oldPlanId){
		var scope = this.scope;
		var self = this;
		var baseRef = this.fb.fbRef("tickets/"+self.state.projectId);
		
		function toDepKey(planId, ticketId, essorType, essorPlanId, essorTicketId){
			return baseRef.child(planId).child(ticketId).child(essorType)
					.child(essorPlanId).child(essorTicketId);
		}
		
		var promises = [];
		
		var cessors = ['successors','predecessors'];
		cessors.forEach(function(cessor, cessorToggle){
			utils.$loop(obj[cessor],function(plan, planId){
				var opposite = cessors[(cessorToggle+1)%cessors.length];
				utils.$loop(plan, function(__, otherTicketId){
					//remove stale link on other side regardless
					console.log('planId', planId);
					var ref = toDepKey(planId, otherTicketId, opposite, oldPlanId, obj.id);
					promises.push(self.fb.remove(ref));
					
					if(planId === self.state.planId || planId === 'constraints'){
						//update the opposite
						var ref = toDepKey(planId, otherTicketId, opposite, newPlanId, obj.id);
						promises.push(self.fb.set(ref, obj.id));
					}
					else{
						//remove this side
						plan[otherTicketId] = null;
					}
				});
			});
		});
		
		return scope.$q.all(promises);
		
	},
	
	convert: function(obj){
		var self = this;
		var scope = this.scope;
		var ticket = new TicketFilter(obj);
		
		var newPlanId = self.state.planId;
		//theoretically this should work fine when converting groups of tickets/constraints...
		
		var promises = [];
		
		promises.push(scope.convertDepPlanIds(ticket, newPlanId, 'constraints'));
		promises.push(this.fb.set(this.fb.fbRef('tickets/'+this.scope.planUrl+ticket.id), ticket));
		
		return scope.$q.all(promises);
	},
	
	//### checkForPrecedence(tickets)
	//ensures that tickets in a group delete can only be deleted if all their precedence is internal
	checkForPrecedence: function(tickets){
		var scope = this.scope;
		var self = this;
		
		return new scope.$q(function(resolve, reject){
			var promises = [];
			var validHash = {};
			var matches = [];
			var actualMatches = [];
			tickets.forEach(function(t){ validHash[t.$id] = true; });
			var result = false;
			tickets.forEach(function(ticket){
				utils.relationIterator(ticket, function(obj){
					if(!validHash[obj.id]){ result = true; matches.push(obj); }
				});
			});
			if(result){
				//console.log('matches', matches);
				
				matches.forEach(function(relObj){
					var ticket = scope.getTicketAndPlan(relObj.planId, relObj.id);
					if(!ticket){
						promises.push(remoteRequest(relObj.planId, relObj.id).then(function(ticket){
							// console.log('remoteTicket', ticket);
							if(!ticket){return} //broken ref, ignore it
							actualMatches.push(ticket);
						}));
					}
					else if(checkForPrecedenceIndexOf(actualMatches, ticket) === -1){
						actualMatches.push(ticket);
					}
				});
				//console.log('actualMatches', actualMatches);
				//resolve(actualMatches); return;
				//return actualMatches;
			}
			//resolve(false);
			scope.$q.all(promises).then(function(){
				resolve(actualMatches.length ? actualMatches : false);
			})
		});

		function remoteRequest(planId, ticketId){
			var fb = self.fb.fbRef('tickets').child(self.state.projectId).child(planId).child(ticketId);
			return new scope.$q(function(resolve, reject){
				fb.once('value', function(ticket){
					if(ticket.exists()){
						resolve({
							obj: {data: ticket.val(), "$id": ticket.key},
							ref: fb,
							isExternalData: true,
							thingsToWrite: []
						})
					}
					else{ resolve(null); }
				});
			});
		}
		
		//a .obj comparator
		function checkForPrecedenceIndexOf(arr, o) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].obj == o.obj) {
					return i;
				}
			}
			return -1;
		}
	},
	
	//performs all the calculations necessary to center a ticket via matrix transform
	//returns the string to provide as the css transform (transform: theStringReturned)
	generateCenterString: function(id){
		var scope = this.scope;
		//abort if something horrendous happens
		if(!scope.tickets.$getRecord(id)){
			// window.reporting.reportWarning("MOC-1554 would have fired", {
				// ticketId: id
			// });
			Logging.debug("MOC-1554 would have fired");
			return "initial"; 
		}
		var ticket = scope.tickets.$getRecord(id);
		
		var stickyWidth = 330;
		var stickyHeight = 320;
		
		var offsetX = (scope.hud.x ? scope.hud.x : 0);
		var offsetY = (scope.hud.y ? scope.hud.y : 0);

		var stickyX = ticket.data.left;
		var stickyY = ticket.data.top;

		var x = (scope.hud.shiftedW / scope.hud.z - stickyWidth) / 2;
		var y = (scope.hud.h / scope.hud.z - stickyHeight) / 2;

		return 'matrix(' + [0.78 / scope.hud.z, 0, 0, 0.78 / scope.hud.z, -offsetX / scope.hud.z + x - stickyX, -offsetY / scope.hud.z + y - stickyY].join(',') + ')';
	},
	
	//convienence function to actually apply the center transform to the supplied ticket
	//has no internal validation so any external use will need to perform it's own checks
	//as to whether or not this is allowed
	moveToCenter: function(ticketId, element){
		if(!ticketId){
			if(this.scope.flags.stickyInEditMode){
				ticketId = this.scope.flags.stickyInEditMode;
				element = this.scope.tickets.$getRecord(this.scope.flags.stickyInEditMode);
			}
			else{
				return;
			}
		}
		
		$(element).css({
			transform: this.generateCenterString(ticketId)
		});
	},
	
	validateAndLog: function(ticket){
		var validation = ticket.validateChanges();
		if(validation){ Logging.warning(validation); }
		return validation;
	},
	
	closeProgress: function(ticket){
		if(this.validateAndLog(ticket)){return;}
		ticket.local.editStatus = false;
		this.scope.editTicketService.close();
	},
	
	
	cantAddTickets: function(tickets){
		var scope = this.scope;
		//has role
		if(!scope.you.projectMember.roleId || !scope.currentRole.$id){
			return "You cannot add tickets without an assigned role.";
		}
	},
	
	//### addTickets(tObj, count, config)
	// adds...tickets. Function returns a promise that resolves once
	// all tickets have been added. Promise resolves to an array of
	// tickets the same length and order of the objects passed in.
	// This can be used to map old to new.
	//* count - the number of tickets to add (defaults to one)
	//* tObj - a thing representing the data to init a ticket with
	// can be undefined, an object or an array equal to count length
	//
	//#### config
	// * silent - don't open any tickets
	// * tickets [a firebaseTickets object] - used to override the default of $scope.tickets
	addTickets: function(tObj, count, config){
		var scope = this.scope;
		var self = this;
		
		if(!config){config = {};}
		
		var tickets = config.tickets || scope.floatingTickets;
		
		var objArray = tickets.constructAddArray(tObj, count);
		
		var cant = this.cantAddTickets(objArray);
		if(cant){ Logging.warning(cant); return scope.$q.rejectErr(cant); }
		
		var pmId = scope.you.projectMember;
		pmId = pmId ? pmId.$id : null;
		
		//todo - now perform any necessary modifications to the array, then call tickets.add
		objArray.forEach(function(obj, arrayPosition){
			if(!obj.roleId && obj.type !== "constraint"){ obj.roleId = utils.checkNested(scope.currentRole, "data", "roleId") ? scope.currentRole.data.roleId : null; }
			obj.creatorId = pmId;
		});
		return tickets.add(objArray).then(function(resultArray){
			if(resultArray.length && !config.silent){
				self.safelyOpenNewTicket(resultArray[resultArray.length-1],{replace:true});
			}
			return resultArray;
		});
	},
	
	adminOrRoleCheck: function(tickets){
		var scope = this.scope;
		return scope.accessCheck.isAdmin() || tickets.every(function(t){
			return scope.accessCheck.hasAccessToTicket(t) && !t.data.promisePeriodPosition;
		});
	},
	
	//### deleteTickets:
	// deletes tickets
	//* rawTickets - something that represents tickets, is ran through scope.tickets.alwaysTickets
	//
	//#### config
	// * skipWarning [true/false] - don't display a confirmation prompt
	// * skipValidation [true/false] - skip precedence check
	// * tickets [a firebaseTickets object] - used to override the default of $scope.tickets
	deleteTickets: function(rawTickets, config){
		if(!config){ config = {}; }
		var self = this;
		var scope = this.scope;
		var $q = scope.$q;
		var tickets = config.tickets || scope.tickets;
		
		var ticketList = scope.tickets.alwaysTickets(rawTickets);
		if(!ticketList){ return; }
		
		if(!this.adminOrRoleCheck(ticketList)){
			Logging.warning("Please see an administrator to make this change.");
			return;
		}
		
		

		
		//some overhead for an optional prompt
		//first a warning check
		return (function(){
			if(config.skipWarning){ return $q.when(true); }
			else{
				return self.scope.popup({
					title: 'Are you sure you want to delete all these tickets?',
					description: 'This cannot be undone.'
				});
			}
		}())
		//then a precedence check
		.then(function(){ return self.checkForPrecedence(ticketList); })
		.then(function(precedenceList){
			if(!config.skipValidation){
				//var precedenceList = self.checkForPrecedence(ticketList);
				console.log('precedence list', precedenceList);
				if(precedenceList){
					//Logging.warning('Tickets have precedence, can\'t delete');
					//return $q.rejectErr('no');
					return self.scope.popup({
						template: require("tpl/popups/dependency-deletion.html"),
						"data": {"ticketList": precedenceList}
					},self.scope).then(function(){
						var planId = self.state.planId;
						//they wants it, now actually clean up
						ticketList.forEach(function(ticketBeingDeleted){
							precedenceList.forEach(function(problemTicket){
								var s = "obj.data.predecessors."+planId;
								var nested = utils.getNested(problemTicket, s);
								console.log('nested', nested, s );
								if(nested && nested[ticketBeingDeleted.$id]){
									nested[ticketBeingDeleted.$id] = null;
									if(problemTicket.isExternalData){
										problemTicket.ref.child('predecessors').child(planId).child(ticketBeingDeleted.$id).remove();
									}
								}
								
								s = s.replace("predecessors", "successors");
								var nested = utils.getNested(problemTicket, s)
								console.log('nested', nested);
								if(nested && nested[ticketBeingDeleted.$id]){
									nested[ticketBeingDeleted.$id] = null;
									if(problemTicket.isExternalData){
										problemTicket.ref.child('successors').child(planId).child(ticketBeingDeleted.$id).remove();
									}
								}
							});
						});
						precedenceList.forEach(function(p){
							if(p.isExternalData){} //noop
							else if(p.obj._debugIdxData){
								//scope.
								scope.timelineTickets.$save(p.obj);
								console.log('save constraint', p);
							}
							else{
								p.obj.$parent.$save(p.obj);
								console.log('save ticket', p);
							}
						});
					});
				}
			}
			return $q.when();
		})
		.then(function(){
			if(scope.ticketEditService.isEditing){
				var finished = scope.ticketEditService.simpleClose(ticketList);
				ticketList.forEach(function(t){
					tickets.selection.remove(t);
				});
			}
			else{
				tickets.selection.empty();
			}
			console.log('here!');
			return tickets.remove(ticketList);
		});
	},
	
	//### duplicateTickets(rawTickets, config)
	// duplicate...
	//* rawTickets - tickets, gets ran through alwaysTickets
	//#### config
	//* noSelection [true] - prevents selecting the freshly duplicated tickets
	//* disableDeselection [true] - prevents emptying the multi-select list
	//* editNew [true] - opens the new ticket up in edit mode
	//* replace [false] - replaces the current ticket
	//* tickets [a firebaseTickets object] - used to override the default of $scope.tickets
	duplicateTickets: function(rawTickets, config){
		// debugger;
		if(!config){ config = {}; }
		var self = this;
		var scope = this.scope;
		var tickets = config.tickets || scope.tickets;
		var ticketList = tickets.alwaysTickets(rawTickets);
		if(!ticketList){ return; }
		
		if(!this.adminOrRoleCheck(ticketList)){
			Logging.warning("Please see an administrator to make this change.");
			return;
		}
		//console.log('ticketOps duplicate config', config);
		scope.flags.disableDrag = true;
		if(!config.disableDeselection){
			
			
			var selection = scope.planState.tickets.selectedTicket$.getValue().list;
			
			//this section is all for the reporting
			var emptyTickets = "";
			var anyBroken = false;
			selection.forEach((ticket, ticketId)=>{
				if(!ticket){ anyBroken = true; emptyTickets += ticketId + ', '; }
			})
			if(anyBroken){
				if(window.reporting){
					window.reporting.reportRealWarning(
						"tickets list screwed up in duplicate", 
						Object.assign(utils.makeStack(new Error("tickets list screwed up in duplicate")), {
							duplicateConfig: config,
							emptyTickets: emptyTickets,
							planStateState: scope.planState.stateSync,
							entireTicketListCount: (scope.planState.tickets && scope.planState.tickets._internalList) ? scope.planState.tickets._internalList.size : 0,
							selectedTicketsCount: selection.size
						})
					);
				}
			}
			
			if(selection.size){
				scope.planState.actions.unSelectTicket(selection);
			}
		}
		var duplicateOffsets = ticketList.map(t => {
			if((scope.activeLine && scope.activeLine.$value !== undefined) && t.data.left < scope.activeLine.$value){
				// return { x: 0, y: 145+50 };
				return { x: 0, y: 25 };
			}
			return null;
		})
		tickets.duplicate(ticketList, duplicateOffsets).then(function(resultObj){
			// for(var i = 0; i < resultObj.resultArray.length; i++){
			// 	if(!config.noSelection){
			// 		tickets.selection.add(resultObj.resultArray[i]);
			// 	}
			// }
			if(!config.noSelection){
				scope.planState.actions.selectTicketDelayed(resultObj.resultArray.map(t => t.$id));
			}
			
			if(config.editNew){ self.safelyOpenNewTicket(resultObj.resultArray[0], config); }
			else{ scope.ticketEditService.simpleClose(); }
		})
		.finally(function(){
			scope.flags.disableDrag = false;
		});
	},
	
	//### safelyOpenNewTicket(ticket)
	// when a ticket is created theres a period where it's element may or may not have been assigned.
	// In addition it might be initialized in varying order.
	// So this method will wait and try both orders
	safelyOpenNewTicket: function(ticket, config){
		var self = this;
		function thenOpen(){
			self.scope.ticketEditService.editStandard(self.scope, [ticket], config);
			
			//also scroll over floating tickets
			if(ticket.$parent === self.scope.floatingTickets){
				var newScrollLeft = ticket.local.element.position().left + ticket.local.element.width();
				ticket.local.element.parent().animate({scrollLeft: newScrollLeft+'px'}, {duration: 200});
			}
			// self.scope.ticketEditService.editStandard(self.scope, [ticket]);
		}
		thenOpen();
		// if(ticket.local.element){
		// 	thenOpen();
		// }
		// else{
		// 	ticket.local.createdCallback = function(){
		// 		thenOpen();
		// 	};
		// }
	},
	
	toggleEditMode: function (tickets)
	{
		//replace with a proper isEditing api
		if(this.scope.ticketEditService.ticketList){
			this.scope.ticketEditService.close();
		}
		else{
			this.scope.ticketEditService.editStandard(this.scope, tickets);
		}
		// if(!this.scope.flags.stickyInEditMode){
			// this.openEdit(ticket);
		// }
		// else{
			// this.closeEdit(ticket);
		// }
	},
	
	// This function returns a string suitable for ng-style from a ticket item
	// This just does placement
	getItemStyle: function (t){
		if(!t || !t.data){ return; }
		var obj:any = {
			left: t.data.left + 'px',
			top: t.data.top + 'px',
			zIndex: t.data.zorder
		}
		if(t.data.width){
			obj.width = t.data.width + 'px';
		}
		if(t.data.height){
			obj.height = t.data.height + 'px';
		}
		return obj;
	},
	
	getColor: function(ticket){
		var scope = this.scope;
		if(ticket.data.type === "constraint"){
			var pmId = ticket.local && ticket.local.editMode ? ticket.local.responsibleProjectMemberId : ticket.data.responsibleProjectMemberId;
			//console.log('pmId', pmId);  //<--------------------- this is fine, it's the set that went retarded
			if(!pmId){return "";}
			var user = scope.meshedUsers.$getPmRecord(pmId);
			//console.log('user', pmId, user);
			return (user ? user.data.roleId : "");
		}
		var roleId = ticket.local && ticket.local.editMode ? ticket.local.roleId : ticket.data.roleId;
		if(!roleId){ return "";}
		return roleId;
	},
	
	stateCheckboxChanged: function(state, ticket){
		var local = ticket.local;
		if(state === 'missed'){
			local.actualFinish = null;
		}
		else if(state === 'completed'){
			//default to ticket.plannedFinish
			if(ticket.data.plannedFinish || ticket.data.lastPlannedFinish){
				var dat = ticket.data.plannedFinish || ticket.data.lastPlannedFinish;
				local.actualFinish = moment(dat).format('YYYY-MM-DD');
			}
		}
		
		if(local.promise){
			local.promise.varianceReason = null;
			local.promise.varianceComment = null;
		}
	},
	
	clearStatus: function(ticket){
		var local = ticket.local;
		local.state = null;
		local.actualFinish = null;//ticket.data.lastPlannedFinish || ticket.data.plannedFinish || null;
		if(local.promise){
			local.promise.varianceReason = null;
			local.promise.varianceComment = null;
		}
	}
};
