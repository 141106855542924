'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";

import { dateCache } from "ng2/common/date-cache";

angular.module('ProjectView').factory("projectViewDateSync", ProjectViewDateSyncFactory);

/**
 * @namespace projectViewDateSync
 * @desc Connects to the {Hud} observers and updates visible dates on every ticket when the hud is updated.
 * @returns {{activate: activate, update: update, getDateRange: getDateRange}}
 * @memberOf ProjectView
 * @constructor
 */
ProjectViewDateSyncFactory.$inject = ["projectViewDateType", "activeColumnService"];
function ProjectViewDateSyncFactory(projectViewDateType, activeColumnService){
	var hud, timeline, pullColumns, milestone, activeLine, todayLine;
	var visibleDates = [];
	var maxVisibleDays = 0;
	var _lastVisibleDays = 0;
	var _lastHudX;
	var clampedDate = null;
	var running = false;

	/**
	 * @function activate
	 * @desc Called to activate the Overview date sync.
	 * @param planHud {Hud} The Plan View HUD class
	 * @param planTimeline {Timeline} The Plan View Timeline (MCScope.timeline)
	 * @param planPullColumns {fbObject} The Plan View Pull Columns (MCScope.pullColumns)
	 * @param planMilestone {fbObject} The Plan View Milestone (MCScope.milestone)
	 * @param planActiveLine {fbObject} The Firebase Object containing the Active Line. (MCScope.activeLine)
	 * @param planTodayLine {fbObject} The Firebase Object containing the today line (MCScope.today)
	 * @memberOf ProjectView.projectViewDateSync
	 */
	function activate(planHud, planTimeline, planPullColumns, planMilestone, planActiveLine, planTodayLine, running$){
		hud = planHud;
		timeline = planTimeline;
		pullColumns = planPullColumns;
		milestone = planMilestone;
		activeLine = planActiveLine;
		todayLine = planTodayLine;
		hud.observers.push(update);
		
		running$.subscribe((innerRunning)=> {
			running = innerRunning; 
			if(running){
				update();
			}
		})
	}

	/**
	 * @function guessDateInWhiteSpace
	 * @desc Calculates a date when no dates are visible on the timeline
	 * @private
	 * @memberOf ProjectView.projectViewDateSync
	 */
	function guessDateInWhiteSpace(){
		var lastDateType = projectViewDateType.getLastDateType(hud, todayLine, milestone, activeLine, pullColumns);
		var screenX;
		var lastDate;
		if (lastDateType === 'milestone') {
			screenX = hud.realToScreenX(milestone.targetX);
			lastDate = milestone.targetDate;
		}
		else if (lastDateType === 'activeLine') {
			screenX = hud.realToScreenX(activeLine.$value);
			lastDate = activeColumnService.guessDateFromX(activeLine.$value);
		}
		else if (lastDateType === 'todayLine') {
			screenX = hud.realToScreenX(todayLine.line.$value);
			lastDate = todayLine.date.$value;
		}
		screenX += 20;
		var today = dateCache.todayString;
		var dayDifferenceFromToday = dateCache.diffDays(today, lastDate);
		var daysAdded = Math.abs(dayDifferenceFromToday) + Math.abs(screenX / 30);
		var leftEdgeDate = dateCache.addDays(today, daysAdded);
		var rightEdgeDate = dateCache.addDays(today, 60);
		if (isTodayLineVisible() && lastDateType === 'todayLine') {
			leftEdgeDate = todayLine.date.$value;
		}
		if (isActiveLineVisible() && lastDateType !== 'activeLine') {
			leftEdgeDate = activeColumnService.guessDateFromX(activeLine.$value);
		}
		if (isMilestoneVisible() && lastDateType !== 'milestone') {
			leftEdgeDate = milestone.targetDate;
		}
		visibleDates = [
			leftEdgeDate,
			rightEdgeDate
		];
	}

	/**
	 * @function pullColumnUpdate
	 * @desc Update the visible dates using viusible pull columns
	 * @param visiblePullColumns {array} An array representing the current visible columns on the timeline
	 * @param lastVisibleDate {string} A date string showing the last date visible on the timeline
	 * @param lastVisibleDateType {ProjectViewDateType} The last type ID visible on the
	 * @memberOf ProjectView.projectViewDateSync
	 * @private
	 */
	function pullColumnUpdate(visiblePullColumns, lastVisibleDate, lastVisibleDateType){
		var firstVisibleDate = visiblePullColumns[visiblePullColumns.length - 1].plannedStart;
		var firstFinishX = (visiblePullColumns[visiblePullColumns.length - 1].origFinishX) ? visiblePullColumns[visiblePullColumns.length - 1].origFinishX : visiblePullColumns[visiblePullColumns.length - 1].finishX;
		var lastFinishX = (visiblePullColumns[0].origFinishX) ? visiblePullColumns[0].origFinishX : visiblePullColumns[0].finishX;
		var firstVisibleColumnScreenX = hud.realToScreenX(firstFinishX);
		var lastVisibleColumScreenX = hud.realToScreenX(lastFinishX);
		if (lastVisibleDateType === 'milestone') {
			lastVisibleColumScreenX = hud.realToScreenX(milestone.targetX);
		} else if (lastVisibleDateType === 'activeLine') {
			lastVisibleColumScreenX = hud.realToScreenX(activeLine.$value);
		} else if (lastVisibleDateType === 'todayLine') {
			lastVisibleColumScreenX = hud.realToScreenX(todayLine.line.$value);
		}
		if (lastVisibleColumScreenX > hud.w) {
			lastFinishX = (visiblePullColumns[0].origStartX) ? visiblePullColumns[0].origStartX : visiblePullColumns[0].startX;
			lastVisibleColumScreenX = hud.realToScreenX(lastFinishX);
		}
		var offsetFromLedgeEdgeOfScreen = firstVisibleColumnScreenX;
		var offsetFromRightEdgeOfScreen = hud.w - lastVisibleColumScreenX;
		var visibleDays = dateCache.diffDays(lastVisibleDate, firstVisibleDate);
		// Prevents the guidelines from locking up when transitioning to the pull side
		if (visibleDays > maxVisibleDays) {
			maxVisibleDays = visibleDays;
		}
		else if (visibleDays < _lastVisibleDays && _lastHudX >= hud.x && isActiveLineVisible()) {
			visibleDays = maxVisibleDays;
		}
		_lastVisibleDays = visibleDays;
		var pixelsPerDay = Math.round(hud.w / visibleDays);
		var daysAdded = Math.round(offsetFromRightEdgeOfScreen / pixelsPerDay);
		var daysRemoved = Math.round(offsetFromLedgeEdgeOfScreen / pixelsPerDay);
		if (!isActiveLineVisible()) {
			firstVisibleDate = dateCache.addDays(firstVisibleDate, -daysRemoved);
		}
		lastVisibleDate = dateCache.addDays(lastVisibleDate, daysAdded);
		if (clampedDate && lastVisibleDate > clampedDate){lastVisibleDate = clampedDate;}
		var rightEdgeDate = lastVisibleDate;
		visibleDates = [
			firstVisibleDate,
			rightEdgeDate
		];
		_lastHudX = hud.x;
	}
	/**
	 * @function columnUpdate
	 * @param visiblePullColumns {Array} An array of visible pull columns
	 * @private
	 * @memberOf ProjectView.projectViewDateSync
	 */
	function columnUpdate(visiblePullColumns){
		var lastVisibleDate;
		var lastVisibleDateType = projectViewDateType.getLastDateType(hud, todayLine, milestone, activeLine, pullColumns);
		if (lastVisibleDateType === 'todayLine' && isTodayLineVisible()) {
			lastVisibleDate = todayLine.date.$value;
			lastVisibleDateType = 'todayLine';
		} else if (lastVisibleDateType === 'milestone' && isMilestoneVisible()) {
			lastVisibleDate = milestone.targetDate;
			lastVisibleDateType = 'milestone';
		} else if (lastVisibleDateType === 'activeLine' && isActiveLineVisible()) {
			lastVisibleDate = activeColumnService.guessDateFromX(activeLine.$value);
			lastVisibleDateType = 'activeLine';
		} else if (lastVisibleDateType === 'column') {
			lastVisibleDate = visiblePullColumns[0].plannedFinish;
			lastVisibleDateType = 'column';
		} else {
			lastVisibleDate = visiblePullColumns[0].plannedFinish;
			lastVisibleDateType = 'column';
		}
		if (!isFirstColumnVisible(visiblePullColumns) && !isLastColumnVisible(visiblePullColumns) && (lastVisibleDateType === 'activeLine' || lastVisibleDateType === 'todayLine')) {
			guessDateInWhiteSpace();
		} else {
			pullColumnUpdate(visiblePullColumns, lastVisibleDate, lastVisibleDateType);
		}
	}

	/**
	 * @function activeAndPullColumnUpdate
	 * @desc Update loop when both active dates and pull column dates are visible
	 * @param activeColumns {Array} An array of visible active columns
	 * @param pc {Array} An array of visible pull columns
	 * @private
	 * @memberOf ProjectView.projectViewDateSync
	 */
	function activeAndPullColumnUpdate(activeColumns, pc){
		// Combine both columns into a unified array
		var unifiedDates = [];
		if (activeColumns && activeColumns.length > 0) {
			activeColumns.forEach(function (ac) {
				unifiedDates.push(ac);
				if (unifiedDates[unifiedDates.length - 1].activeDay) {
					unifiedDates[unifiedDates.length - 1].plannedFinish = unifiedDates[unifiedDates.length - 1].activeDay;
					unifiedDates[unifiedDates.length - 1].plannedStart = unifiedDates[unifiedDates.length - 1].activeDay;
				}
			});
		}
		if (pc && pc.length > 0) {
			pc.forEach(function (pc) {
				if (pc.plannedStart || pc.plannedFinish) {
					unifiedDates.push(pc);
					if (unifiedDates[unifiedDates.length - 1].plannedFinish) {
						unifiedDates[unifiedDates.length - 1]["activeDay"] = unifiedDates[unifiedDates.length - 1].plannedFinish;
					}
				}
			});
		}
		unifiedDates.sort(utils.sortBy('activeDay', true, 'date'));
		if (unifiedDates.length > 0) {
			columnUpdate(unifiedDates);
		} else if (isMilestoneVisible() || isActiveLineVisible() || isTodayLineVisible()) {
			guessDateInWhiteSpace();
		}
	}

	/**
	 * @function isLastColumnVisible
	 * @desc Used to determine if the last column in a given list is visible on the screen
	 * @param visibleColumns {Array} An array of visible columns
	 * @memberOf ProjectView.projectViewDateSync
	 * @private
	 * @returns {boolean}
	 */
	function isLastColumnVisible(visibleColumns){
		var res = false;
		var startX = visibleColumns[0].origStartX || visibleColumns[0].startX;
		if (visibleColumns && startX) {
			var visibleColumnsScreenX = hud.realToScreenX(startX);
			res = (visibleColumnsScreenX <= hud.w && visibleColumnsScreenX >= 0)
		}
		return res;
	}

	/**
	 * @function isFirstColumnVisible
	 * @desc Used to determine if the first column in a given list is visible on the screen
	 * @param visibleColumns [Array} An array of visible columns
	 * @memberOf ProjectView.projectViewDateSync
	 * @private
	 * @returns {boolean}
	 */
	function isFirstColumnVisible(visibleColumns){
		var res = false;
		var finishX = visibleColumns[visibleColumns.length - 1].origFinishX || visibleColumns[visibleColumns.length - 1].finishX;
		if (visibleColumns && finishX) {
			var visibleColumnsScreenX = hud.realToScreenX(finishX);
			res = (visibleColumnsScreenX <= hud.w && visibleColumnsScreenX >= 0)
		}
		return res;
	}

	/**
	 * @function isMilestoneVisible
	 * @desc Used to determine if the milestone line is visible on the screen
	 * @memberOf ProjectView.projectViewDateSync
	 * @private
	 * @returns {boolean}
	 */
	function isMilestoneVisible(){
		var res = false;
		if (milestone && milestone.targetX) {
			var milestoneScreenX = hud.realToScreenX(milestone.targetX + 20);
			res = (milestoneScreenX <= hud.w && milestoneScreenX >= 0)
		}
		return res;
	}

	/**
	 * @function isActiveLineVisible
	 * @desc Used to determine if the active line is visible on the screen
	 * @memberOf ProjectView.projectViewDateSync
	 * @private
	 * @returns {boolean}
	 */
	function isActiveLineVisible(){
		var res = false;
		if (activeLine && activeLine.$value) {
			var activeLineScreenX = hud.realToScreenX(activeLine.$value + 20);
			res = (activeLineScreenX <= hud.w && activeLineScreenX >= 0)
		}
		return res;
	}

	/**
	 * @function isTodayLineVisible
	 * @desc Used to determine if the today line is visible on the screen
	 * @memberOf ProjectView.projectViewDateSync
	 * @private
	 * @returns {boolean}
	 */
	function isTodayLineVisible(){
		var res = false;
		if (todayLine && todayLine.line.$value) {
			var todayLineScreenX = hud.realToScreenX(todayLine.line.$value + 20);
			res = (todayLineScreenX <= hud.w && todayLineScreenX >= 0)
		}
		return res;
	}

	/**
	 * @function update
	 * @desc The main update of the date syncer. Used to sync Overview guidelines with the plan view camera
	 * @param [newMilestone] {fbObject} The new milestone data. Used to update the milestone data in the overview.
	 * @memberOf ProjectView.projectViewDateSync
	 * @returns {*}
	 */
	function update(newMilestone?){
		if(!running){ return {}; }
		// debugger;
		if (newMilestone) {
			return milestone = newMilestone;
		}
		var observeParams = activeColumnService.observeParams;
		var activeColumns = activeColumnService.getSubColumns({
			columns: observeParams.columns,
			min: observeParams.min,
			max: observeParams.max - 1
		});

		var visiblePullColumns = verifyPullColumns(activeColumns, timeline.columns);
		if (!isActiveLineVisible() && !isMilestoneVisible() && !isTodayLineVisible() && (activeColumns.length === 0 && visiblePullColumns.length === 0) ) {
			guessDateInWhiteSpace();
		} else {
			activeAndPullColumnUpdate(activeColumns, visiblePullColumns);
		}
	}
	/**
	 * @function verifyPullColumns
	 * @param ac {array} An array of visible active columns
	 * @param pc {Array} An array of visible pull columns
	 * @private
	 * @memberOf ProjectView.projectViewDateSync
	 */
	function verifyPullColumns(ac, pc){
		// Active line is visible, we have pull columns but none are in the visible array. Clamp the day.
		if (!isActiveLineVisible() && !isMilestoneVisible() && !isTodayLineVisible() && pc.length === 0 && ac.length === 0 && pullColumns.length >= 1 && hud.realToScreenX(pullColumns[pullColumns.length-1].finishX) >= 0){
			clampedDate = pullColumns[pullColumns.length-1].activeDay;
			pc = [pullColumns[pullColumns.length-1]];
		} else if (isActiveLineVisible() && pullColumns.length >= 1 && !pc.length){
			clampedDate = pullColumns[pullColumns.length-1].activeDay;
		} else if (pc.length === 1 && hud.realToScreenX(pc[0].finishX) >= hud.w && pullColumns.length >= 1){
			clampedDate = pullColumns[pullColumns.length-1].activeDay;
			pc = [];
		}else if (pc.length > 1 && hud.realToScreenX(pc[0].finishX) >= hud.w && !isMilestoneVisible()){
			clampedDate = pc[0].activeDay;
		}else{
			clampedDate = null;
		}
		return pc;
	}
	/**
	 * @function getDateRange
	 * @desc The the first visible and last visible dates
	 * @returns {Array} Index of 0 is the first visible date. Index of 1 is the last visible date.
	 * @memberOf ProjectView.projectViewDateSync
	 */
	function getDateRange(){
		return visibleDates
	}

	return{
		activate: activate,
		update: update,
		getDateRange: getDateRange
	}
}
