import { Directive, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
/**
 * CloseWhenIgnoredDirective
 * USAGE:
 * - Pass in an Input observable via: [closeWhenIgnored]="ob$"
 * - Setup a listener for the output: (whenIgnored)="doSomething()"
 *
 * When the input observable changes, this directive registers listeners using the element this directive is attached to
 * If events are fired which do not contain the element this is attached to, then this directive emits an event.
 * You can listen for this event and then do something (Such as closing a dropdown menu)
 */
@Directive({
	selector: '[closeWhenIgnored]'
})
export class CloseWhenIgnoredDirective implements OnInit {
	private appliedSubject$: Subject<boolean> = new Subject();
	private element: HTMLInputElement;
	
	@Input('closeWhenIgnored') set registerListeners(subject: any) {
		if (subject && (subject as Observable<any>).pipe){
			this.appliedSubject$.next(true);
			(subject as Observable<any>).pipe(
				takeUntil(this.appliedSubject$)
			).subscribe((input) => {
				this.whenOpened();
			})
		}
	}
	
	@Output() whenIgnored: EventEmitter<any> = new EventEmitter();
	
	
	constructor(private elRef: ElementRef) {
		//elRef will get a reference to the element where
		this.element = elRef.nativeElement;
	}
	
	ngOnInit() {
		this.whenOpened();
	}
	
	public whenOpened(): void {
		window.$('body').on('mousedown pointerdown touchstart', this.touchdown);
	}
	
	private touchdown = (event): void  => {
		if(jQuery.contains(this.element, event.target)){return;}
		event.stopPropagation();
		//event.preventDefault();
		this.unEvent();
		this.whenIgnored.emit(true)
	};
	
	private unEvent(): void {
		window.$('body').off('mousedown pointerdown touchstart',  this.touchdown);
	}
}
