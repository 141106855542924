import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Side } from "../../common/models/Side";
import { TICKET_DATE_TEXT_SETTINGS } from "./";
import { Ticket, TicketTypes } from "../../common/models/Ticket";
import {TicketUtils} from "../utils/TicketUtils";

export class TicketBand extends TicketComponentHelper implements ComponentInterface {
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		
		this.alpha = 0.4;
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer, null);
	}
	
	private onChanged = (ticketContainer: TicketContainer, oldContainer: TicketContainer) => {
		const data = ticketContainer.getData();
		const type = TicketUtils.getTicketType(data);
		if (data.view.liveStart || data.view.liveFinish){
			this.renderable = true;
		} else {
			this.renderable = false;
		}
		this.width = data.view.width;
		this.height = (data.view.side === Side.PULL) ? 36 : 20;
		
		this.x = 0;
		this.y = this._getLocationHeightOffset(data);
	};
	
	private _getLocationHeightOffset(ticket: Ticket): number{
		let _offset: number = 0;
		if (ticket.view.location){
			_offset += (ticket.view.side === Side.PULL) ? 40 : 25;
		}
		
		return _offset;
	}
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}