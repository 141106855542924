import { mesh, Texture } from "pixi.js";
import { LEVEL_OF_DETAIL_TABLE, LevelOfDetail, LEVEL_OF_DETAIL_SIGNALS } from "../core";
import { ComponentManager } from "../component-system/ComponentManager";

/**
 * Extends teh PIXI.NineSlicePlane and adds in culling. This class works well for dynamically changing ninesliceplanes
 */
export class FancyNineSlicePlane extends mesh.NineSlicePlane{
	public active: boolean = false;
	public visible: boolean = false;
	/**
	 * @param {PIXI.Texture} texture Texture for this NineSlicePlane
	 * @param {number} leftWidth size of the left vertical bar (A)
	 * @param {number} topHeight size of the top horizontal bar (C)
	 * @param {number} rightWidth size of the right vertical bar (B)
	 * @param {number} bottomHeight size of the bottom horizontal bar (D)
	 */
	constructor(texture: Texture, leftWidth?: number, topHeight?: number, rightWidth?: number, bottomHeight?: number){
		super(texture, leftWidth, topHeight, rightWidth, bottomHeight);
	}
	/**
	 * Renders the object using the WebGL renderer
	 * @param renderSession
	 */
	_renderWebGL(renderSession){
		super._renderWebGL(renderSession);
	}
	/**
	 * Renders the object using the Canvas renderer
	 * @param renderSession
	 */
	_renderCanvas(renderSession){
		super._renderCanvas(renderSession);
	}
	/**
	 * Update the transformation matrix on this object and all children. May need to optimize this.
	 */
	public updateTransform(){
		if (this.visible && this.renderable){
				super.updateTransform();
		}
	}
	/**
	 * Destroy me
	 */
	public destroy(options?){
		super.destroy(options);
	}
}
