'use strict';

export default function Point(x?, y?){/*   
	Point (2d x,y)
	see: https://github.com/paperjs/paper.js/blob/master/src/basic/Point.js 
	
	new Point(x || {x,y} || [x,y] || {width,height} || {left,top} , y)
	.clone -> new Point(this)
	.set(x, y, silent) -> this
	.equals(new Point(x), y) -> Boolean()
	.toString()  -> '{x:x,y:y}'
	.serialize() -> [x,y],
	.add(Point p)      -> new Point(this + p)
	.subtract(Point p) -> new Point(this - p)
	.multiply(Point p) -> new Point(this * p)
	.divide(Point p)   -> new Point(this / p)
	.modulo(Point p)   -> new Point(this % p)
	.negate() -> new Point(-x, -y)
	.transform(Matrix) -> new Point()
	.distance(point) -> Number()
	.quandrant -> 1 || 2 || 3 || 4
	.length = sqrt(x^2 + y^2) 
	.normalize(length)
	.angle -> Number(radians)
	.angle = Point(x,y)
	.radians(point) -> Number(radians)
	.degrees(point) -> Number(degrees)
	.angleTo(point) -> Number(degrees)
	.rotate(Number(degrees), Point(center)) -> new Point()
	.insideOf(Rect) -> Boolean()
	.closeTo   (Point, Number(tolerance)) -> Boolean()
	.colinear  (Point, Number(tolerance)) -> Boolean()
	.orthogonal(Point, Number(tolerance)) -> Boolean()
	.isZero() -> Boolean()
	.isNaN()  -> Boolean()
	.scale(Point p)   -> Number(.dot(p) / .dot(p))
	.dot(Point p)     -> Number(x*p.x + y*p.y)
	.cross(Point p)   -> Number(x*p.x - y*p.y)
	.project(Point p) -> new Point(this * .scale(p))
	.round[X|Y] -> round(Number) || [X|Y] round(Point)
	.floor[X|Y] -> round(Number) || [X|Y] floor(Point)
	.ceil[X|Y]  -> ceil(Number)  || [X|Y] ceil(Point)
	.abs[X|Y]   -> abs(Number)   || [X|Y] abs(Point)
	
	Point.min(p1, p2) -> .min(p.x, p.y)
	Point.max(p1, p2) -> .max(p.x, p.y)
	Point.random -> new Point()
	*/ 
	
	this.observers = [];
	this.initialize.apply(this, arguments) 
}

Point.prototype = {
	constructor: Point,
	initialize: function Point(x, y) {
		var type = typeof x;
		if (type === 'number') {
			this.x = x; this.y = (typeof y === 'number') ? y : x;
		} else {
			if (Array.isArray(x)) { this.x = x[0]; this.y = x.length > 1 ? x[1] : x[0]; } 
			else if (x && x.x != null) { this.x = x.x; this.y = x.y || x.z || x.x; } 
			else if (x && x.width != null) { this.x = x.width; this.y = x.height || this.x }
			else if (x && x.left != null) { this.x = x.left; this.y = x.top || x.bottom || this.x }      
			else if (x && x.angle != null) { this.x = x.length; this.y = 0; this.angle = x }
			else { this.x = this.y = 0 }
		}
		return this;
	},
	get clone() { return new Point(this) },  
	set: function(x, y, silent){ 
		this.x = x; this.y = y;
		if(!silent) { this.update() }
		return this;
	},
	update: function(){
		this.observers.forEach(function(fn){ fn() }); 
	},
	equals: function(point, y) { return this === point 
		// todo: this.tolerance? 
		|| point && (Array.isArray(point) && this.x === point[0] && this.y === point[1])  
		|| (point = new Point(point)) && (this.x === point.x && this.y === point.y)
		|| (this.x === point && this.y === y)    
		|| false
	},
	toString: function(){ return '{ x: ' + this.x + ', y: ' + this.y + ' }' },
	serialize: function(){ return [this.x, this.y] },
	add: function(point){ return new Point(this.x + point.x, this.y + point.y) },
	subtract: function(point) { return new Point(this.x - point.x, this.y - point.y) },
	multiply: function(point) { return new Point(this.x * point.x, this.y * point.y) },
	divide: function(point) { return new Point(this.x / point.x, this.y / point.y) },
	modulo: function(point) { return new Point(this.x % point.x, this.y % point.y) },
	negate: function() { return new Point(-this.x, -this.y) },
	transform: function(matrix) { return matrix ? matrix.point(this) : this },
	distance: function(point, squared) {
		var d = Math.pow(point.x - this.x, 2) 
					+ Math.pow(point.y - this.y, 2);
		return squared ? d : Math.sqrt(d);
	},
	get quandrant(){ return this.x >= 0 ? (this.y >= 0 ? 1 : 4) : (this.y >= 0 ? 2 : 3) },
	get length(){ return Math.sqrt(this.x * this.x + this.y * this.y) },
	set length(length){
		if (this.isZero()) {
			var angle = this._angle || 0;
			this.set( Math.cos(angle) * length, Math.sin(angle) * length );
		} else {
			var scale = length / this.getLength();			
			//if (Numerical.isZero(scale)) this.angle();
			this.set( this.x * scale, this.y * scale );
		}
	},
	normalize: function(length) {
		if (length === undefined) length = 1;
		var current = this.length();
		var scale = current !== 0 ? length / current : 0;
		var point = new Point(this.x * scale, this.y * scale);
		if (scale >= 0) point._angle = this._angle;
		return point;
	},
	get angle(){ return this.radians() },
	set angle(point){ 
		this._angle = point && point.angle || this._angle || 0;
		if (!this.isZero()) {
			var length = this.length;
			this.set( Math.cos(point.angle) * length, Math.sin(point.angle) * length )
		}
	}, 
	radians: function(point){
		if (!point) {
			return this.isZero()
					? this._angle || 0
					: this._angle = Math.atan2(this.y, this.x);
		} else {
			// div = this.length * point.length;
			// if (div == 0) return NaN;
			// else return Math.acos(this.dot(point) / div);
		}
	},
	degrees: function(point){ return this.radians(point) * 180 / Math.PI },
	angleTo: function(point){ return Math.atan2(this.cross(point), 
																							this.dot(point)) * 180 / Math.PI },
	rotate: function(angle, center) {
		if (angle === 0) return this.clone;
		angle = angle * Math.PI / 180;
		var point = center ? this.subtract(center) : this,
			s = Math.sin(angle),
			c = Math.cos(angle);
		point = new Point( point.x * c - point.y * s,	point.x * s + point.y * c	);
		return center ? point.add(center) : point;
	},
	insideOf: function(rect){ return rect.contains(this); },
	closeTo: function(point, tolerance){ return this.distance(point) < tolerance || this.tolerance },
	colinear: function(point, tolerance){ return Math.abs(this.cross(point)) < tolerance || this.tolerance },
	orthogonal: function(point, tolerance){ return Math.abs(this.dot(point)) < tolerance || this.tolerance },
	isZero: function(){ return this.x == 0 || isNaN(this.x) && this.y == 0 || isNaN(this.y) },
	isNaN: function(){ return isNaN(this.x) || isNaN(this.y) },
	scale: function(point){ return this.dot(point) / point.dot(point) },
	dot: function(point){ return this.x * point.x + this.y * point.y },
	cross: function(point){ return this.x * point.x - this.y * point.y },
	project: function(point){
		if (point.isZero()) return new Point(0,0);
		else return point.multiply(new Point(this.scale(point)))
	},

	get roundX(){ return Math.round(this.x) },
	get roundY(){ return Math.round(this.y) },
	get round(){ return new Point(this.roundX, this.roundY) },

	get ceilX(){ return Math.ceil(this.x) },
	get ceilY(){ return Math.ceil(this.y) },
	get ceil(){ return new Point(this.ceilX, this.ceilY) },

	get floorX(){ return Math.floor(this.x) },
	get floorY(){ return Math.floor(this.y) },
	get floor(){ return new Point(this.floorX, this.floorY) },
	
	get absX(){ return Math.abs(this.x) },
	get absY(){ return Math.abs(this.y) },
	get abs(){ return new Point(this.absX, this.absY) }
};
var anyPoint:any = Point;
anyPoint.min = function(p1, p2){
	return new Point(Math.min(p1.x, p2.x), Math.min(p1.y, p2.y))
};

anyPoint.max = function(p1, p2){
	return new Point(Math.max(p1.x, p2.x), Math.max(p1.y, p2.y))
};

anyPoint.random = function(){
	return new Point(Math.random(), Math.random())
};
