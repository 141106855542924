import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyJoinRequirements } from 'ng2/fancy-firebase/base';
import { Location } from 'ng2/common/models/Location';

export class LocationList extends FancyFirebaseList<Location, FancyAction> implements FancyJoinRequirements<Location>{
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		return new Location(action.payload.val());
	}
	$$updated(child:Location, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.update(action.payload.val());
	}
	
	//put in interface so you can explicitly say you're doing this
	applyJoinData(baseKey: string, child, otherChild:any){
		switch(baseKey){
			case "locationColor":
				child.data.color = otherChild ? otherChild.color: null;
				break;
			default: break;
		}
	}
	getDataForKey(baseKey: string, child){
		return child.data[baseKey];
	}
	getPrevDataForKey(baseKey: string, child){
		return child.prevData[baseKey];
	}
}
