import * as angular from 'angular';

interface roleChangerApi{
	set: (id: string) => void; // Provides the logic to save the new roleId to firebase
}

class PrimaryRoleChangerController implements roleChangerApi{
	/** MEMBERS */
	private lastRoleId: string;
	private lastAssignedRoleLength: number = -1;
	protected projectMember: any;
	protected roles: any;
	protected multipleRolesUtils: any;
	public currentRole: any;
	public assignedRoles: Array<object> = [];
	/** METHODS */
	constructor(){}
	
	private updateModel(){
		this.currentRole = this.roles.$getRecord(this.projectMember.roleId) || null;
		this.lastRoleId = this.projectMember.roleId;
	}
	private generateAssignedRoles(){
		if (!this.multipleRolesUtils || !this.roles || !this.projectMember){return}
		this.assignedRoles = this.multipleRolesUtils.createRoleList(this.roles, this.projectMember);
		this.lastAssignedRoleLength = this.assignedRoles.length;
		this.updateModel();
	}
	private updateAssignedRoleList(){
		if (this.projectMember && this.multipleRolesUtils.countRoles(this.projectMember, this.roles) !== this.lastAssignedRoleLength){
			this.generateAssignedRoles();
		}
	}
	$doCheck(){
		this.updateAssignedRoleList();
	}
	set(newId: string){
		if (!this.projectMember && newId !== this.projectMember.roleId){return}
		this.projectMember.$ref().update({roleId: newId});
	}
}

angular.module('ticketspaceApp').component('primaryRoleChanger', {
	bindings: {
		projectMember: "<",
		roles: "<",
		multipleRolesUtils: "<"
	},
	template: require('tpl/directive/primary-role-changer.html'),
	controller: PrimaryRoleChangerController
});