import { Directive, Input, Output, TemplateRef, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef, OnInit, EventEmitter } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LoadingBlockerSpinnerComponent } from "./loading-blocker-spinner/loading-blocker-spinner.component";
import { LoadingBlockerSuccessComponent } from "./loading-blocker-success/loading-blocker-success.component";
import { LoadingBlockerFailureComponent } from "./loading-blocker-failure/loading-blocker-failure.component";

export enum LoadingBlockerState {
	empty = "empty",
	complete = "complete",
	inProgress = "inProgress",
	failure = "failure"
};

class ViewRefManager{
	constructor(
		private viewContainer: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver
	){}
	private cache:any = {};
	
	attach(component, type: LoadingBlockerState){
		if(this.cache[type]){return this.viewContainer.insert(this.cache[type].hostView);}
		
		let cmpFactory = this.componentFactoryResolver.resolveComponentFactory(component);
		
		let viewRef = this.viewContainer.createComponent(cmpFactory);
		this.cache[type] = viewRef;
		return viewRef;
	}
	detach(){
		if(!this.viewContainer.length){return;}
		
		Object.keys(this.cache).forEach(key => {
			let index = this.viewContainer.indexOf(this.cache[key]);
			if(index !== -1){
				this.viewContainer.detach(index);
			}
		});
		
		
		// if(this.viewContainer.length){ this.viewContainer.detach(); }
	}
}

@Directive({ 
	selector: '[loadingBlocker]',
	// host: {class: 'square'},
})
export class LoadingBlockerDirective implements OnInit {
	private replaceSubject = new Subject();
	// private hasView = false;
	private viewRefManager = new ViewRefManager(this.viewContainer, this.componentFactoryResolver);
	private _animationDelay = 900;
	private _progressInput = LoadingBlockerState.empty;
	
	private animationTimeoutId;
	
	//consider doing both a complete callback and a change callback, this one is just complete
	@Output() onStateChange = new EventEmitter<LoadingBlockerState>();
	
	@Input() set animationDelay(delay:number){
		this._animationDelay = delay;
	}
	
	// @Input() inputObservable: Observable<LoadingBlockerState>;
	
	@Input('loadingBlocker') set progressInput(state: LoadingBlockerState | Observable<LoadingBlockerState>){
		if(state && (state as Observable<any>).pipe){
			this.replaceSubject.next(true);
			(state as Observable<any>).pipe(takeUntil(this.replaceSubject)).subscribe(input => {
				this.handleInput(input);
			})
		}
		else{
			this.handleInput(state as LoadingBlockerState);
		}

		
	}
	
	handleInput(state: LoadingBlockerState){
		if(state === this._progressInput){return;}
		this.viewRefManager.detach();
		
		switch(state){
			case LoadingBlockerState.inProgress:
				this.viewRefManager.attach(LoadingBlockerSpinnerComponent, state);
				break;
			case LoadingBlockerState.complete:
				this.viewRefManager.attach(LoadingBlockerSuccessComponent, state);
				break;
			case LoadingBlockerState.failure:
				this.viewRefManager.attach(LoadingBlockerFailureComponent, state);
				break;
			default: break;
		}
		
		if(state === LoadingBlockerState.complete || state === LoadingBlockerState.failure){
			this.animationTimeoutId = setTimeout(()=>{
				this.onStateChange.emit(state);
				this._progressInput = LoadingBlockerState.empty;
				this.viewRefManager.detach();
				// this.cd.markForCheck();
				// console.log('prog', this.progressInput);
			},this._animationDelay);
		}
		
		this._progressInput = state;
	}
	
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private cd: ChangeDetectorRef
	){
		
	}
	
	ngOnInit(){
		this.viewContainer.createEmbeddedView(this.templateRef);
		

	}
	ngOnDestroy(){
		// this.shutdownSubject.next(true);
	}

}




//this component is currently in a test state, roughly illustrating how structural directives can work
// it currently just does the inverse of ngIf

// loadingBlocker functionality
// - is placed on a wrapper component
// - should slap a div that fully overlaps the component it's added to when a condition is true, and make it go away when a condition is false
// -- what exactly this view is should have a default, and be customizable
// -- this overlapping view should be able to handle success, failure, and complete
// --- only complete is relevant for showing/ hiding the blocker
// --- success and failure should simply show alternative ui and include animation time in the blocker going away
// - todo, finish this
