import { Point } from "pixi.js";
/**
 * Pulled from Phaser.Math.
 * MIT
 */
export class MathUtil{
	static DEG_TO_RAD_FACTOR: number = Math.PI / 180;
	static RAD_TO_DEG_FACTOR: number = 180 / Math.PI;
	/**
	 * Twice PI.
	 */
	static PI2: number = Math.PI * 2;
	/**
	 * Returns a number between the `min` and `max` values.
	 *
	 * @param {number} min - The minimum value. Must be positive, and less than 'max'.
	 * @param {number} max - The maximum value. Must be position, and greater than 'min'.
	 * @return {number} A value between the range min to max.
	 */
	static between(min: number, max: number){
		return Math.floor(Math.random() * (max - min -1) + min)
	};
	/**
	 * Two number are fuzzyEqual if their difference is less than epsilon.
	 *
	 * @param {number} a - The first number to compare.
	 * @param {number} b - The second number to compare.
	 * @param {number} [epsilon=0.0001] - The epsilon (a small value used in the calculation)
	 * @return {boolean} True if |a-b|<epsilon
	 */
	static fuzzyEqual(a: number, b: number, epsilon: number = 0.0001){
		return Math.abs(a - b) < epsilon;
	}
	/**
	 * `a` is fuzzyLessThan `b` if it is less than b + epsilon.
	 *
	 * @param {number} a - The first number to compare.
	 * @param {number} b - The second number to compare.
	 * @param {number} [epsilon=0.0001] - The epsilon (a small value used in the calculation)
	 * @return {boolean} True if a<b+epsilon
	 */
	static fuzzyLessThan(a: number, b: number, epsilon: number = 0.0001){
		return a < b + epsilon;
	}
	/**
	 * `a` is fuzzyGreaterThan `b` if it is more than b - epsilon.
	 *
	 * @param {number} a - The first number to compare.
	 * @param {number} b - The second number to compare.
	 * @param {number} [epsilon=0.0001] - The epsilon (a small value used in the calculation)
	 * @return {boolean} True if a>b+epsilon
	 */
	static fuzzyGreaterThan(a: number, b: number, epsilon: number){
		return a > b - epsilon;
	}
	/**
	 * Applies a fuzzy ceil to the given value.
	 *
	 * @param {number} val - The value to ceil.
	 * @param {number} [epsilon=0.0001] - The epsilon (a small value used in the calculation)
	 * @return {number} ceiling(val-epsilon)
	 */
	static fuzzyCeil(val: number, epsilon: number = 0.0001){
		return Math.ceil(val - epsilon);
	}
	/**
	 * Applies a fuzzy floor to the given value.
	 *
	 * @param {number} val - The value to floor.
	 * @param {number} [epsilon=0.0001] - The epsilon (a small value used in the calculation)
	 * @return {number} floor(val+epsilon)
	 */
	static fuzzyFloor(val: number, epsilon: number = 0.0001){
		return Math.floor(val + epsilon);
	}
	/**
	 * Averages all values passed to the function and returns the result.
	 *
	 * @params {...number} The numbers to average
	 * @return {number} The average of all given values.
	 */
	static average(...values: Array<number>){
		let sum = 0;
		let len = values.length;
		
		for (let i = 0; i < len; i++) {
			sum += (+arguments[i]);
		}
		
		return sum / len;
	}
	/**
	 * @param {number} n
	 * @return {number} n mod 1
	 */
	static shear(n: number){
		return n % 1;
	}
	/**
	 * Snap a value to nearest grid slice, using rounding.
	 *
	 * Example: if you have an interval gap of 5 and a position of 12... you will snap to 10 whereas 14 will snap to 15.
	 *
	 * @param {number} input - The value to snap.
	 * @param {number} gap - The interval gap of the grid.
	 * @param {number} [start=0] - Optional starting offset for gap.
	 * @return {number} The snapped value.
	 */
	static snapTo(input: number, gap: number, start: number = 0){
		if (gap === 0) {return input;}
		input -= start;
		input = gap * Math.round(input / gap);
		return start + input;
	}
	/**
	 * Snap a value to nearest grid slice, using floor.
	 *
	 * Example: if you have an interval gap of 5 and a position of 12... you will snap to 10.
	 * As will 14 snap to 10... but 16 will snap to 15.
	 *
	 * @param {number} input - The value to snap.
	 * @param {number} gap - The interval gap of the grid.
	 * @param {number} [start=0] - Optional starting offset for gap.
	 * @return {number} The snapped value.
	 */
	static snapToFloor(input: number, gap: number, start: number = 0){
		if (gap === 0) {return input;}
		input -= start;
		input = gap * Math.floor(input / gap);
		return start + input;
	}
	/**
	 * Snap a value to nearest grid slice, using ceil.
	 *
	 * Example: if you have an interval gap of 5 and a position of 12... you will snap to 15.
	 * As will 14 will snap to 15... but 16 will snap to 20.
	 *
	 * @param {number} input - The value to snap.
	 * @param {number} gap - The interval gap of the grid.
	 * @param {number} [start=0] - Optional starting offset for gap.
	 * @return {number} The snapped value.
	 */
	static snapToCeil(input: number, gap: number, start: number = 0){
		if (gap === 0) {return input;}
		input -= start;
		input = gap * Math.ceil(input / gap);
		return start + input;
	}
	/**
	 * Round to some place comparative to a `base`, default is 10 for decimal place.
	 * The `place` is represented by the power applied to `base` to get that place.
	 *
	 *     e.g. 2000/7 ~= 285.714285714285714285714 ~= (bin)100011101.1011011011011011
	 *
	 *     roundTo(2000/7,3) === 0
	 *     roundTo(2000/7,2) == 300
	 *     roundTo(2000/7,1) == 290
	 *     roundTo(2000/7,0) == 286
	 *     roundTo(2000/7,-1) == 285.7
	 *     roundTo(2000/7,-2) == 285.71
	 *     roundTo(2000/7,-3) == 285.714
	 *     roundTo(2000/7,-4) == 285.7143
	 *     roundTo(2000/7,-5) == 285.71429
	 *
	 *     roundTo(2000/7,3,2)  == 288       -- 100100000
	 *     roundTo(2000/7,2,2)  == 284       -- 100011100
	 *     roundTo(2000/7,1,2)  == 286       -- 100011110
	 *     roundTo(2000/7,0,2)  == 286       -- 100011110
	 *     roundTo(2000/7,-1,2) == 285.5     -- 100011101.1
	 *     roundTo(2000/7,-2,2) == 285.75    -- 100011101.11
	 *     roundTo(2000/7,-3,2) == 285.75    -- 100011101.11
	 *     roundTo(2000/7,-4,2) == 285.6875  -- 100011101.1011
	 *     roundTo(2000/7,-5,2) == 285.71875 -- 100011101.10111
	 *
	 * Note what occurs when we round to the 3rd space (8ths place), 100100000, this is to be assumed
	 * because we are rounding 100011.1011011011011011 which rounds up.
	 *
	 * @param {number} value - The value to round.
	 * @param {number} [place=0] - The place to round to.
	 * @param {number} [base=10] - The base to round in. Default is 10 for decimal.
	 * @return {number} The rounded value.
	 */
	static roundTo(value: number, place: number = 0, base: number = 10){
		let p = Math.pow(base, -place);
		return Math.round(value * p) / p;
	}
	/**
	 * Floors to some place comparative to a `base`, default is 10 for decimal place.
	 * The `place` is represented by the power applied to `base` to get that place.
	 *
	 * @param {number} value - The value to round.
	 * @param {number} [place=0] - The place to round to.
	 * @param {number} [base=10] - The base to round in. Default is 10 for decimal.
	 * @return {number} The rounded value.
	 */
	static floorTo(value: number, place: number = 0, base: number = 10){
		let p = Math.pow(base, -place);
		return Math.floor(value * p) / p;
	}
	/**
	 * Ceils to some place comparative to a `base`, default is 10 for decimal place.
	 * The `place` is represented by the power applied to `base` to get that place.
	 *
	 * @param {number} value - The value to round.
	 * @param {number} [place=0] - The place to round to.
	 * @param {number} [base=10] - The base to round in. Default is 10 for decimal.
	 * @return {number} The rounded value.
	 */
	static ceilTo(value: number, place: number = 0, base: number = 10){
		let p = Math.pow(base, -place);
		return Math.ceil(value * p) / p;
	}
	/**
	 * Rotates currentAngle towards targetAngle, taking the shortest rotation distance.
	 * The lerp argument is the amount to rotate by in this call.
	 *
	 * @param {number} currentAngle - The current angle, in radians.
	 * @param {number} targetAngle - The target angle to rotate to, in radians.
	 * @param {number} [lerp=0.05] - The lerp value to add to the current angle.
	 * @return {number} The adjusted angle.
	 */
	static rotateTo(currentAngle: number, targetAngle: number, lerp: number = 0.05){
		if (currentAngle === targetAngle) {return currentAngle;}
		
		if (Math.abs(targetAngle - currentAngle) <= lerp || Math.abs(targetAngle - currentAngle) >= (MathUtil.PI2 - lerp)) {
			currentAngle = targetAngle;
		} else {
			if (Math.abs(targetAngle - currentAngle) > Math.PI) {
				if (targetAngle < currentAngle) {targetAngle += MathUtil.PI2;}
				else {targetAngle -= MathUtil.PI2;}
			}
			
			if (targetAngle > currentAngle) {currentAngle += lerp;}
			else if (targetAngle < currentAngle) {currentAngle -= lerp;
			}
		}
		
		return currentAngle;
	}
	/**
	 * Gets the shortest angle between `angle1` and `angle2`.
	 * Both angles must be in the range -180 to 180, which is the same clamped
	 * range that `sprite.angle` uses, so you can pass in two sprite angles to
	 * this method, and get the shortest angle back between the two of them.
	 *
	 * The angle returned will be in the same range. If the returned angle is
	 * greater than 0 then it's a counter-clockwise rotation, if < 0 then it's
	 * a clockwise rotation.
	 *
	 * @param {number} angle1 - The first angle. In the range -180 to 180.
	 * @param {number} angle2 - The second angle. In the range -180 to 180.
	 * @return {number} The shortest angle, in degrees. If greater than zero it's a counter-clockwise rotation.
	 */
	static getShortestAngle(angle1: number, angle2: number){
		let difference = angle2 - angle1;
		if (difference === 0) {return 0;}
		
		let times = Math.floor((difference - (-180)) / 360);
		
		return difference - (times * 360);
	}
	/**
	 * Find the angle of a segment from (x1, y1) -> (x2, y2).
	 *
	 * @param {number} x1 - The x coordinate of the first value.
	 * @param {number} y1 - The y coordinate of the first value.
	 * @param {number} x2 - The x coordinate of the second value.
	 * @param {number} y2 - The y coordinate of the second value.
	 * @return {number} The angle, in radians.
	 */
	static angleBetween(x1: number, y1: number, x2: number, y2: number){
		return Math.atan2(y2 - y1, x2 - x1);
	}
	/**
	 * Find the angle of a segment from (x1, y1) -> (x2, y2).
	 *
	 * The difference between this method and Math.angleBetween is that this assumes the y coordinate travels
	 * down the screen.
	 *
	 * @param {number} x1 - The x coordinate of the first value.
	 * @param {number} y1 - The y coordinate of the first value.
	 * @param {number} x2 - The x coordinate of the second value.
	 * @param {number} y2 - The y coordinate of the second value.
	 * @return {number} The angle, in radians.
	 */
	static angleBetweenY(x1: number, y1: number, x2: number, y2: number){
		return Math.atan2(x2 - x1, y2 - y1);
	}
	/**
	 * Find the angle of a segment from (point1.x, point1.y) -> (point2.x, point2.y).
	 *
	 * @param {PIXI.Point} point1 - The first point.
	 * @param {PIXI.Point} point2 - The second point.
	 * @return {number} The angle between the two points, in radians.
	 */
	static angleBetweenPoints(point1: Point, point2: Point){
		return Math.atan2(point2.y - point1.y, point2.x - point1.x);
	}
	/**
	 * Find the angle of a segment from (point1.x, point1.y) -> (point2.x, point2.y).
	 * @param {PIXI.Point} point1
	 * @param {PIXI.Point} point2
	 * @return {number} The angle, in radians.
	 */
	static angleBetweenPointsY(point1: Point, point2: Point){
		return Math.atan2(point2.x - point1.x, point2.y - point1.y);
	}
	/**
	 * Reverses an angle.
	 * @param {number} angleRad - The angle to reverse, in radians.
	 * @return {number} The reverse angle, in radians.
	 */
	static reverseAngle(angleRad: number){
		return MathUtil.normalizeAngle(angleRad + Math.PI);
	}
	/**
	 * Normalizes an angle to the [0,2pi) range.
	 * @param {number} angleRad - The angle to normalize, in radians.
	 * @return {number} The angle, fit within the [0,2pi] range, in radians.
	 */
	static normalizeAngle(angleRad){
		angleRad = angleRad % (2 * Math.PI);
		return angleRad >= 0 ? angleRad : angleRad + 2 * Math.PI;
	}
	/**
	 * Adds the given amount to the value, but never lets the value go over the specified maximum.
	 *
	 * @param {number} value - The value to add the amount to.
	 * @param {number} amount - The amount to add to the value.
	 * @param {number} max - The maximum the value is allowed to be.
	 * @return {number} The new value.
	 */
	static maxAdd(value: number, amount: number, max: number){
		return Math.min(value + amount, max);
	}
	/**
	 * Subtracts the given amount from the value, but never lets the value go below the specified minimum.
	 *
	 * @param {number} value - The base value.
	 * @param {number} amount - The amount to subtract from the base value.
	 * @param {number} min - The minimum the value is allowed to be.
	 * @return {number} The new value.
	 */
	static minSub(value: number, amount: number, min: number){
		return Math.max(value - amount, min);
	}
	/**
	 * Ensures that the value always stays between min and max, by wrapping the value around.
	 *
	 * If `max` is not larger than `min` the result is 0.
	 *
	 * @param {number} value - The value to wrap.
	 * @param {number} min - The minimum the value is allowed to be.
	 * @param {number} max - The maximum the value is allowed to be, should be larger than `min`.
	 * @return {number} The wrapped value.
	 */
	static wrap(value: number, min: number, max: number){
		let range = max - min;
		if (range <= 0) {return 0;}
		let result = (value - min) % range;
		if (result < 0) {result += range;}
		return result + min;
	}
	/**
	 * Adds value to amount and ensures that the result always stays between 0 and max, by wrapping the value around.
	 *
	 * Values _must_ be positive integers, and are passed through Math.abs. See wrap for an alternative.
	 *
	 * @param {number} value - The value to add the amount to.
	 * @param {number} amount - The amount to add to the value.
	 * @param {number} max - The maximum the value is allowed to be.
	 * @return {number} The wrapped value.
	 */
	static wrapValue(value: number, amount: number, max: number){
		let diff;
		value = Math.abs(value);
		amount = Math.abs(amount);
		max = Math.abs(max);
		diff = (value + amount) % max;
		return diff;
	}
	/**
	 * Returns true if the number given is odd.
	 *
	 * @param {number} n - The number to check.
	 * @return {boolean} True if the given number is odd. False if the given number is even.
	 */
	static isOdd(n: number){
		return !!(n & 1);
	}
	/**
	 * Returns true if the number given is even.
	 *
	 * @param {number} n - The number to check.
	 * @return {boolean} True if the given number is even. False if the given number is odd.
	 */
	static isEven(n: number){
		return !(n & 1);
	}
	/**
	 * Variation of Math.min that can be passed either an array of numbers or the numbers as parameters.
	 *
	 * Prefer the standard `Math.min` function when appropriate.
	 *
	 * See: http://jsperf.com/math-s-min-max-vs-homemade
	 *
	 * @return {number} The lowest value from those given.
	 */
	static min(...vals: Array<number>){
		let i = 1;
		let min = 0;
		for (let len = vals.length; i < len; i++) {
			if (vals[i] < vals[min]) {min = i;}
		}
		return vals[min];
	}
	/**
	 * Variation of Math.max that can be passed either an array of numbers or the numbers as parameters.
	 *
	 * Prefer the standard `Math.max` function when appropriate.
	 *
	 * @return {number} The largest value from those given.
	 */
	static max(...vals: Array<number>){
		let i = 1;
		let max = 0;
		for (let len = vals.length; i < len; i++) {
			if (vals[i] > vals[max]) {
				max = i;
			}
		}
		return vals[max];
	}
	/**
	 * Variation of Math.min that can be passed a property and either an array of objects or the objects as parameters.
	 * It will find the lowest matching property value from the given objects.
	 *
	 * @return {number} The lowest value from those given.
	 */
	static minProperty(property: number, ...args: Array<any>){
		let i = 1;
		let min = 0;
		for (let len = args.length; i < len; i++) {
			if (args[i][property] < args[min][property]) {
				min = i;
			}
		}
		
		return args[min][property];
	}
	/**
	 * Variation of Math.max that can be passed a property and either an array of objects or the objects as parameters.
	 * It will find the largest matching property value from the given objects.
	 *
	 * @return {number} The largest value from those given.
	 */
	static maxProperty(property: number, ...args: Array<any>) {
		let i = 1;
		let max = 0;
		for (let len = args.length; i < len; i++) {
			if (args[i][property] > args[max][property]) {
				max = i;
			}
		}
		return args[max][property];
	}
	/**
	 * Keeps an angle value between -180 and +180; or -PI and PI if radians.
	 *
	 * @param {number} angle - The angle value to wrap
	 * @param {boolean} [radians=false] - Set to `true` if the angle is given in radians, otherwise degrees is expected.
	 * @return {number} The new angle value; will be the same as the input angle if it was within bounds.
	 */
	static wrapAngle(angle: number, radians?: boolean){
		return radians ? MathUtil.wrap(angle, -Math.PI, Math.PI) : MathUtil.wrap(angle, -180, 180);
	}
	/**
	 * A Linear Interpolation Method, mostly used by Tween.
	 *
	 * @param {Array} v - The input array of values to interpolate between.
	 * @param {number} k - The percentage of interpolation, between 0 and 1.
	 * @return {number} The interpolated value
	 */
	static linearInterpolation(v: Array<number>, k: number){
		let m = v.length - 1;
		let f = m * k;
		let i = Math.floor(f);
		
		if (k < 0) {
			return MathUtil.linear(v[0], v[1], f);
		}
		
		if (k > 1) {
			return MathUtil.linear(v[m], v[m - 1], m - f);
		}
		
		return MathUtil.linear(v[i], v[i + 1 > m ? m : i + 1], f - i);
	}
	/**
	 * A Bezier Interpolation Method, mostly used by Tween.
	 *
	 * @param {Array} v - The input array of values to interpolate between.
	 * @param {number} k - The percentage of interpolation, between 0 and 1.
	 * @return {number} The interpolated value
	 */
	static bezierInterpolation(v: Array<number>, k: number){
		let b = 0;
		let n = v.length - 1;
		
		for (let i = 0; i <= n; i++) {
			b += Math.pow(1 - k, n - i) * Math.pow(k, i) * v[i] * MathUtil.bernstein(n, i);
		}
		
		return b;
	}
	/**
	 * A Catmull Rom Interpolation Method, mostly used by Tween.
	 *
	 * @param {Array} v - The input array of values to interpolate between.
	 * @param {number} k - The percentage of interpolation, between 0 and 1.
	 * @return {number} The interpolated value
	 */
	static catmullRomInterpolation(v: Array<number>, k: number) {
		let m = v.length - 1;
		let f = m * k;
		let i = Math.floor(f);
		
		if (v[0] === v[m]) {
			if (k < 0) {
				i = Math.floor(f = m * (1 + k));
			}
			return MathUtil.catmullRom(v[(i - 1 + m) % m], v[i], v[(i + 1) % m], v[(i + 2) % m], f - i);
		}
		else {
			if (k < 0) {
				return v[0] - (MathUtil.catmullRom(v[0], v[0], v[1], v[1], -f) - v[0]);
			}
			
			if (k > 1) {
				return v[m] - (MathUtil.catmullRom(v[m], v[m], v[m - 1], v[m - 1], f - m) - v[m]);
			}
			return MathUtil.catmullRom(v[i ? i - 1 : 0], v[i], v[m < i + 1 ? m : i + 1], v[m < i + 2 ? m : i + 2], f - i);
		}
	}
	/**
	 * Calculates a linear (interpolation) value over t.
	 *
	 * @param {number} p0
	 * @param {number} p1
	 * @param {number} t - A value between 0 and 1.
	 * @return {number}
	 */
	static linear(p0: number, p1: number, t: number) {
		return (p1 - p0) * t + p0;
	}
	/**
	 * @param {number} n
	 * @param {number} i
	 * @return {number}
	 */
	static bernstein(n: number, i: number) {
		return MathUtil.factorial(n) / MathUtil.factorial(i) / MathUtil.factorial(n - i);
	}
	/**
	 * @param {number} value - the number you want to evaluate
	 * @return {number}
	 */
	static factorial(value: number) {
		if (value === 0) {return 1;}
		let res = value;
		while(--value) {res *= value;}
		return res;
	}
	/**
	 * Calculates a catmum rom value.
	 *
	 * @param {number} p0
	 * @param {number} p1
	 * @param {number} p2
	 * @param {number} p3
	 * @param {number} t
	 * @return {number}
	 */
	static catmullRom (p0: number, p1: number, p2: number, p3: number, t: number) {
		let v0 = (p2 - p0) * 0.5, v1 = (p3 - p1) * 0.5, t2 = t * t, t3 = t * t2;
		return (2 * p1 - 2 * p2 + v0 + v1) * t3 + (-3 * p1 + 3 * p2 - 2 * v0 - v1) * t2 + v0 * t + p1;
	}
	/**
	 * The absolute difference between two values.
	 *
	 * @param {number} a - The first value to check.
	 * @param {number} b - The second value to check.
	 * @return {number} The absolute difference between the two values.
	 */
	static difference(a: number, b: number){
		return Math.abs(a - b);
	}
	/**
	 * Round to the next whole number _away_ from zero.
	 *
	 * @param {number} value - Any number.
	 * @return {integer} The rounded value of that number.
	 */
	static roundAwayFromZero(value: number) {
		return (value > 0) ? Math.ceil(value) : Math.floor(value);
	}
	/**
	 * Generate a sine and cosine table simultaneously and extremely quickly.
	 * The parameters allow you to specify the length, amplitude and frequency of the wave.
	 * This generator is fast enough to be used in real-time.
	 * Code based on research by Franky of scene.at
	 *
	 * @param {number} length - The length of the wave
	 * @param {number} sinAmplitude - The amplitude to apply to the sine table (default 1.0) if you need values between say -+ 125 then give 125 as the value
	 * @param {number} cosAmplitude - The amplitude to apply to the cosine table (default 1.0) if you need values between say -+ 125 then give 125 as the value
	 * @param {number} frequency  - The frequency of the sine and cosine table data
	 * @return {{sin:number[], cos:number[]}} Returns the table data.
	 */
	static sinCosGenerator(length: number, sinAmplitude: number = 1.0, cosAmplitude: number = 1.0, frequency: number = 1.0) {
		let sin = sinAmplitude;
		let cos = cosAmplitude;
		let frq = frequency * Math.PI / length;
		
		let cosTable = [];
		let sinTable = [];
		
		for (let c = 0; c < length; c++) {
			
			cos -= sin * frq;
			sin += cos * frq;
			
			cosTable[c] = cos;
			sinTable[c] = sin;
			
		}
		
		return { sin: sinTable, cos: cosTable, length: length };
	}
	/**
	 * Returns the euclidian distance between the two given set of coordinates.
	 *
	 * @param {number} x1
	 * @param {number} y1
	 * @param {number} x2
	 * @param {number} y2
	 * @return {number} The distance between the two sets of coordinates.
	 */
	static distance(x1: number, y1: number, x2: number, y2: number) {
		let dx = x1 - x2;
		let dy = y1 - y2;
		return Math.sqrt(dx * dx + dy * dy);
	}
	/**
	 * Returns the euclidean distance squared between the two given set of
	 * coordinates (cuts out a square root operation before returning).
	 *
	 * @param {number} x1
	 * @param {number} y1
	 * @param {number} x2
	 * @param {number} y2
	 * @return {number} The distance squared between the two sets of coordinates.
	 */
	static distanceSq(x1: number, y1: number, x2: number, y2: number) {
		let dx = x1 - x2;
		let dy = y1 - y2;
		return dx * dx + dy * dy;
	}
	/**
	 * Returns the distance between the two given set of coordinates at the power given.
	 *
	 * @param {number} x1
	 * @param {number} y1
	 * @param {number} x2
	 * @param {number} y2
	 * @param {number} [pow=2]
	 * @return {number} The distance between the two sets of coordinates.
	 */
	static distancePow(x1: number, y1: number, x2: number, y2: number, pow: number = 2){
		return Math.sqrt(Math.pow(x2 - x1, pow) + Math.pow(y2 - y1, pow));
	}
	/**
	 * Force a value within the boundaries by clamping it to the range `min`, `max`.
	 *
	 * @param {float} v - The value to be clamped.
	 * @param {float} min - The minimum bounds.
	 * @param {float} max - The maximum bounds.
	 * @return {number} The clamped value.
	 */
	static clamp(v: number, min: number, max: number) {
		if (v < min) {return min;
		}
		else if (max < v) {
			return max;
		}
		else {
			return v;
		}
	}
	/**
	 * Clamp `x` to the range `[a, Infinity)`.
	 * Roughly the same as `Math.max(x, a)`, except for NaN handling.
	 *
	 * @param {number} x
	 * @param {number} a
	 * @return {number}
	 */
	static clampBottom(x: number, a: number) {
		return x < a ? a : x;
	}
	/**
	 * Checks if two values are within the given tolerance of each other.
	 *
	 * @param {number} a - The first number to check
	 * @param {number} b - The second number to check
	 * @param {number} tolerance - The tolerance. Anything equal to or less than this is considered within the range.
	 * @return {boolean} True if a is <= tolerance of b.
	 */
	static within(a: number, b: number, tolerance: number) {
		return (Math.abs(a - b) <= tolerance);
	}
	/**
	 * Linear mapping from range <a1, a2> to range <b1, b2>
	 *
	 * @param {number} x - The value to map
	 * @param {number} a1 - First endpoint of the range <a1, a2>
	 * @param {number} a2 - Final endpoint of the range <a1, a2>
	 * @param {number} b1 - First endpoint of the range <b1, b2>
	 * @param {number} b2 - Final endpoint of the range  <b1, b2>
	 * @return {number}
	 */
	static mapLinear(x: number, a1: number, a2: number, b1: number, b2: number) {
		return b1 + ( x - a1 ) * ( b2 - b1 ) / ( a2 - a1 );
	}
	/**
	 * Smoothstep function as detailed at http://en.wikipedia.org/wiki/Smoothstep
	 *
	 * @param {float} x - The input value.
	 * @param {float} min - The left edge. Should be smaller than the right edge.
	 * @param {float} max - The right edge.
	 * @return {float} A value between 0 and 1.
	 */
	static smoothstep(x: number, min: number, max: number) {
		// Scale, bias and saturate x to 0..1 range
		x = Math.max(0, Math.min(1, (x - min) / (max - min)));
		// Evaluate polynomial
		return x * x * (3 - 2 * x);
	}
	/**
	 * Smootherstep function as detailed at http://en.wikipedia.org/wiki/Smoothstep
	 *
	 * @param {float} x - The input value.
	 * @param {float} min - The left edge. Should be smaller than the right edge.
	 * @param {float} max - The right edge.
	 * @return {float} A value between 0 and 1.
	 */
	static smootherstep(x: number, min: number, max: number) {
		x = Math.max(0, Math.min(1, (x - min) / (max - min)));
		return x * x * x * (x * (x * 6 - 15) + 10);
	}
	/**
	 * A value representing the sign of the value: -1 for negative, +1 for positive, 0 if value is 0.
	 *
	 * This works differently from `Math.sign` for values of NaN and -0, etc.
	 *
	 * @param {number} x
	 * @return {integer} An integer in {-1, 0, 1}
	 */
	static sign(x: number) {
		return ( x < 0 ) ? -1 : ( ( x > 0 ) ? 1 : 0 );
	}
	/**
	 * Work out what percentage value `a` is of value `b` using the given base.
	 *
	 * @param {number} a - The value to work out the percentage for.
	 * @param {number} b - The value you wish to get the percentage of.
	 * @param {number} [base=0] - The base value.
	 * @return {number} The percentage a is of b, between 0 and 1.
	 */
	static percent(a: number, b: number, base: number = 0) {
		if (a > b || base > b) {
			return 1;
		}
		else if (a < base || base > a) {
			return 0;
		}
		else {
			return (a - base) / b;
		}
	}
	/**
	 * Convert degrees to radians.
	 *
	 * @param {number} degrees - Angle in degrees.
	 * @return {number} Angle in radians.
	 */
	static degToRad(degrees: number){
		return degrees * MathUtil.DEG_TO_RAD_FACTOR;
	}
	/**
	 * Convert radians to degrees.
	 *
	 * @param {number} radians - Angle in radians.
	 * @return {number} Angle in degrees
	 */
	static radToDeg(radians: number){
		return radians * MathUtil.RAD_TO_DEG_FACTOR;
	}
}