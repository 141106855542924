'use strict';
import * as angular from "angular";

//### firebaseEmailDirectory
// This factory isn't an angularfire object and more of a utility that uses angularfire.
angular.module('ticketspaceApp')

// Remove $q
.factory('firebaseEmailDirectory', ["fbConnection", function(fbConnection) {
	
	var refUrl = 'emailDirectory/';
	
	//escapes an email for use as a firebase key
	function escapeEmail(email) {
		if (!email) { return false; }
		// Replace '.' (not allowed in a Firebase key) with ',' (not allowed in an email address)
		email = email.toLowerCase();
		email = email.replace(/\./g, ',');
		return email;
	}
	
	function getUserId(email){
		return fbConnection.loadObject(refUrl+escapeEmail(email)).then(function(data){
			return data.$value;
		});
	}
	
	function setUserId(email, userId){
		return fbConnection.set(fbConnection.fbRef(refUrl+escapeEmail(email), userId));
	}

	return {
		getUserId: getUserId,
		setUserId: setUserId
	};
}]);
