'use strict';
import * as utils from "utils";

export default function TicketFilter(item){
	//general list
	var allowedList = [
		"activityName", 
		"actualFinish", 
		"createdAt", 
		"creatorId",
		"dateRequired",
		"dateRequested",
		"id", 
		"left", 
		"locationId", 
		"plannedDateOverride", 
		"predecessors", 
		"prettyId", 
		"priority",
		"responsibleProjectMemberId",
		"responsibleProjectMemberRoleId",
		"assignedProjectMemberId",
		"assignedProjectMemberRoleId",
		"roleId", 
		"successors", 
		"top", 
		"type", 
		"varianceReason"
	];
	allowedList.forEach(function(a){
		if(item[a]){ this[a] = item[a]; }
	},this);

	//special handling
	if(this.type !== "milestone" && this.type !== "constraint"){
		this.crewSize = (item.crewSize !== undefined) ? item.crewSize : 2;
		this.durationDays = (item.durationDays !== undefined) ? item.durationDays : 1;
	}

	//take what's there
	this.width = item.width;
	this.height = item.height;
	if(!item.width){
		//fallback to defaults
		if(utils.checkNestedProto(this, 'constructor', 'scope', 'ticketConstants', 'pullTicketWidthPixels') && this.constructor.scope.ticketConstants.pullTicketWidthPixels.$value){
			this.width = this.constructor.scope.ticketConstants.pullTicketWidthPixels.$value;
			this.height = this.constructor.scope.ticketConstants.pullTicketHeightPixels.$value;
		}
		else{ //absolute fallback
			this.width = 290;
			this.height = 270;
		}
	}
}

var TF:any = TicketFilter;
TF.globals = function(scope)
{
	this.scope = scope;
};

TF.byId = function(id){
	return this.scope.tickets.$getRecord(id);
};
