import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { BehaviorSubject, Observable, empty, of, from } from "rxjs";
import { mergeMap, scan, filter, pluck } from "rxjs/operators";

import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyActionTypes } from 'ng2/fancy-firebase/base';
import { FancySupplementalAction, SupplementalActionTypes, CombinedSupplementalActions } from "ng2/actions/supplemental-actions";

import { Swimlane, SwimlaneRenderTypes } from "ng2/common/models/Swimlane";
import { Camera } from "ng2/common/models/CanvasConnector";

import {DefaultActionHandlers, DefaultActionTypes} from "ng2/fancy-firebase/generics";

import GoldenColors from "js/model/GoldenColors";
const goldenColors = new GoldenColors(0.1);

export interface SwimlaneOccupiedSpace{
	top: number,
	bottom: number,
	center: number,
	overlapTop: number, //top - dragHeight/2
	overlapBottom: number //bottom + dragHeight/2
}

//both generic types are necessary when using a creator, otherwise one is fine (possibly none if typescript is smart enough)
export function monitorSubList<Item, MappedItem = Item>(
	observable:Observable<FancyAction<Item>>, 
	compFunc: (action: FancyAction<Item>)=>boolean, 
	isRemoveAction: (type: string)=>boolean,
	customCreator?: (action: FancyAction)=>MappedItem
): Observable<Map<string, MappedItem>>{
	return observable
	.pipe(
		scan( (state:{changed: boolean, list: Map<string, any>}, action:FancyAction) => {
			let changed = false;
			let compRes = compFunc(action);
			let remove = isRemoveAction(action.type);
			if(remove){
				changed = state.list.has(action.key);
				if(changed){ state.list.delete(action.key); }
			}
			else if(compRes && !state.list.has(action.key)){
				let thing;
				if(customCreator){thing = customCreator(action)}
				else{ thing = action.payload; }
				state.list.set(action.key, thing);
				changed = true;
			}
			else if(!compRes && state.list.has(action.key)){
				state.list.delete(action.key);
				changed = true;
			}
			state.changed = changed;
			return state;
		}, {changed: false, list: new Map()}),
		filter(state => (state as any).changed),
		pluck("list")
	)
}

export interface SwimlaneWithSelfObserver{
	swimlane: Swimlane,
	ob$: Observable<FancyAction<Swimlane>>
}

export class SwimlaneList extends FancyFirebaseList<Swimlane, FancyAction>{
	private defaultActionsHandlers:DefaultActionHandlers<Swimlane, FancyAction, SwimlaneList> = new DefaultActionHandlers(this, [DefaultActionTypes.addItem]);
	public editList$: BehaviorSubject<Map<string, SwimlaneWithSelfObserver>> = new BehaviorSubject(new Map());
	private camera: Camera;
	private range = {top: 0, bottom: 0};
	
	protected addActions = {
		[FancyActionTypes.childAdded]: FancyActionTypes.childAdded,
		[DefaultActionTypes.addItem]: DefaultActionTypes.addItem
	}
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
		
		
		let obs = monitorSubList<Swimlane, SwimlaneWithSelfObserver>(
			this.rawEvent$,
			(action)=> action.payload.currentRenderType === SwimlaneRenderTypes.edit, 
			(type)=> this.isRemoveAction(type),
			(action) => { return { swimlane: action.payload, ob$: this.getChildOb$(action.key) } }
		)
		this.subscriptions.push(obs.subscribe(this.editList$));
		
		// this.rawEvent$.subscribe()
		
		console.log('editList', this.editList$);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		let swim = new Swimlane(action.key);
		swim.camera = this.camera;
		swim.databaseUpdate(action.payload.val());
		return swim;
	}
	$$updated(child:Swimlane, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.databaseUpdate(action.payload.val());
	}
	
	handleCustomEvents(baseObservable){
		return baseObservable.pipe(
			mergeMap((action:FancyAction)=>{
				var item = this.defaultActionsHandlers.handle(action);
				
				switch(action.type){
					//override the add event
					case DefaultActionTypes.addItem: item = this.handleAddItem(action);
					default: break;
				}
				if(!item){ return empty(); }
				return of(new FancyAction(item, action.type));
			})
		)
	}
	
	handleSupplementalEvents(baseObservable:Observable<CombinedSupplementalActions>):Observable<FancySupplementalAction>{
		return baseObservable.pipe(
			mergeMap(action =>{
				switch(action.type){
					case SupplementalActionTypes.cameraChanged:
						return this.cameraUpdate(action.payload)
					default: break;
				}
				
				return empty();
			})
		)
	}
	
	protected handleAddItem(action: FancyAction){
		let id = this.listRef.query.ref.push().key;
		let swim = this.makeEmptySwimlane(id);
		swim.switchViews(SwimlaneRenderTypes.edit);
		this._internalList.set(id, swim);
		
		let payload:any = swim.generateDatabaseOutput();
		this.listRef.set(id, payload);
		return swim;
	}
	
	public emptySwimlaneData(){
		let range = this.getRange();
		let top;
		if(!range.length && this.camera){
			let worldRect = this.camera.globalView;
			top = worldRect.top + (worldRect.height / 2);
		}
		else if(!range.length && !this.camera){
			top = 0;
		}
		else{
			top = range[range.length-1].bottom + 30;
		}
		
		return {
			top: top,
			bottom: top +870,
			color: goldenColors.getRandomColor().rgba
		};
	}
	
	public makeEmptySwimlane(id, data?){
		let swim = new Swimlane(id);
		swim.camera = this.camera;
		if(data){ swim.databaseUpdate(data); }
		else{
			let data = this.emptySwimlaneData();
			swim.databaseUpdate(data);
		}
		return swim;
	}
	
	//temp method to get adds working, ripped from old code, can do better
	getRange(record?){
		const minSeperation = 5;
		//start inefficient:
		//- make new list with only positions
		//- sort
		var newArr = Array.from(this._internalList.values()).filter((rec)=>{return rec !== record;}).sort(function(a,b){
			return a.view.top - b.view.top;
		}).map(function(s){
			return {top: s.view.top, bottom: s.view.bottom}
		});
		
		for(var i = newArr.length-1; i>=0; i--){
			var above = i ? newArr[i-1] : null;
			var below = newArr[i];
			if(i && below.top - above.bottom < 5){
				above.bottom = below.bottom;
				newArr.splice(i, 1);
			}
		}
		return newArr;
	}
	
	getRange2(filterOut?:Array<string>, filteredSize = 0, minSize = 5){
		let filtered = {};
		if(filterOut){filterOut.forEach(id => filtered[id] = id)}
		
		let filteredList:Array<SwimlaneOccupiedSpace> = [];
		this._internalList.forEach((swim, swimId)=>{
			if(filtered[swimId]){ return; }
			// if(filteredList.length){
			// 	let lastItem = filteredList[filteredList.]
			// }
			
			filteredList.push({
				top: swim.view.top,
				bottom: swim.view.bottom,
				center: swim.view.top + (swim.view.bottom - swim.view.top)/2,
				overlapTop: swim.view.top - filteredSize / 2,
				overlapBottom: swim.view.bottom + filteredSize / 2
			});
		});
		filteredList = filteredList.sort(function(a,b){
			return a.top - b.top;
		});
		
		for(var i = filteredList.length-1; i>=0; i--){
			var above = i ? filteredList[i-1] : null;
			var below = filteredList[i];
			if(i && below.top - above.bottom < minSize){
				above.bottom = below.bottom;
				above.overlapBottom = above.bottom + filteredSize /2;
				above.center = above.top + (above.bottom - above.top)/2;
				filteredList.splice(i, 1);
			}
		}
		return filteredList;
	}
	
	//TODO - is probably worth genericizing this
	protected cameraUpdate(camera: Camera){
		
		this.camera = camera;
		let actionList = [];
		this._internalList.forEach(thing => {
			thing.camera = camera;
			thing.recalcLocal();
			let act = this.constructAction(this.actionCtor, thing, SupplementalActionTypes.cameraChanged, thing.$id);
			actionList.push(act);
		})
		return from(actionList);
	}
}
