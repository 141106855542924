'use strict';
import * as angular from "angular";
import * as firebase from "firebase/app";
//using the working assumption that there is not reason for anyone other then the user
//to access their own preferences...
angular.module('ticketspaceApp')
.factory('firebaseUserPreferences', ["$q", "$firebaseObject", "fbConnection", function($q, $firebaseObject, fbConnection) {
	
	var userId;
	
	var defaults = {
		planSortType: 'id',
		showSharedSamplePlan: true,
		showAllRolesOnPlans: false,
		showMyRoleOnPlans: false,
		showLaborCount: true,
		reportsLastProjectId: null,
		reportsLastAsOfDate: null,
		reportsLastUpToDate: null,
		reportsLastNumberOfWeeks: "1",
		reportsLastGanttTimeScale: "day",
		reportsLastTimeScale: 20,
		reportsLastShowCompleted:true,
		reportsLastSortByRole:null, //maybe fix this?
		reportsLastBreakUpByRole:false,
		reportsLastGroupByLocation:null, //maybe fix this?
		reportsLastOrderLocationByName:null, //maybe fix this?
		reportsOnlyLateConstraints:null,
		reportsFilterRoles: null,
		reportsFilterPlans: null,
		reportsFilterLocations: null,
		autoOpenProjectOverview: false,
		planDisableAliasing: false
	};
	
	var setters = {};
	
	for(var key in defaults){
		(function(key){
			var setterString = 'set' + key.charAt(0).toUpperCase() + key.slice(1);
			// By the time this is called, the 'key' is set to the last key in the defaults object.
			// Don't want to risk breaking more stuff, soooo huzzah for another argument :D...:(...:'(
			setters[setterString] = function(val, newKey){
				key = newKey || key;
				if(val === undefined){ val = this[key]; }
				this[key] = val;
				this.$save();
			}
		})(key)
	}
	
	function PreferenceFactory(ref){
		$firebaseObject.call(this, ref);
		var self = this;
		this.userId = userId;
		this.$loaded().then(function(){
			self.loadDefaults();
		});
	}
	
	PreferenceFactory.prototype.loadDefaults = function(){
		var count = 0;
		for(var key in defaults){
			if(this[key] === undefined || this[key] === null){
				count++;
				this[key] = defaults[key];
			}
		}
		if(count){
			return this.$save();
		}
		else{
			return this;
		}
	};
	PreferenceFactory.prototype.set = function(key, val){
		if(val === undefined){ val = null; }
		this[key] = val;
		this.$save();
	}
	PreferenceFactory.prototype.setObj = function(obj){
		for(var key in obj){
			var val = obj[key];
			if(val === undefined){ val = null; }
			this[key] = val;
		}
		this.$save();
	}
	
	angular.extend(PreferenceFactory.prototype, setters);
	
	//can add methods later if desired, at the moment just use the object
	
	return function(id){
		if(!id){
			Logging.warning('preferences not loaded'); return false;
		}
		userId = id;
		var fact = $firebaseObject.$extend(PreferenceFactory);
		
		var obj = new fact(fbConnection.fbRef('userPreferences/'+userId));
		return obj;
	};
	
}]);
