'use strict';

import * as angular from "angular";
import * as moment from "moment";

import {LegacyRouting} from "ng2/routes/legacy-routing.service";

angular.module('ticketspaceApp')

.factory('firebaseCounter', ["$q", "fbConnection", function($q, fbConnection) {
	return function(ref){
		var currentCount = null;
		return new $q(function(resolve, reject){
			ref.transaction(function(currentVal) {
				currentCount = currentVal + 1 || 0;
				return currentCount;
			}, function(error, committed) {
				if (error || !committed){ reject("counter transaction failed"); }
				else{ resolve(currentCount); }
			});
		});
	}
}])


//so...

// - priority sorting is probably broken
// - do more "cross-plan" testing
// - are the timelineTickets updated when you delete?
// - duplicating constraints (no pm)
// - revisit miniSave and add "only if changed" support
// - look into firebaseIndex inherited $watch update frequency
// - make prettyId work with new constraints
// - look into new composite key structure for constraints
// - make sort by plan work

.factory('firebaseConstraints2', ["fbConnection", "$firebaseArray", "$timeout", "legacyRouting", "firebaseIndex", "fbdbTime", "firebaseMeshedUsersGlobal", function(fbConnection, $firebaseArray, $timeout, legacyRouting, firebaseIndex, fbdbTime, firebaseMeshedUsersGlobal) {
	var SORT_DATE = "plannedFinish";
	var today = fbdbTime();
	
	var meshedUsers;
	firebaseMeshedUsersGlobal.getAsync().then(function(arr){
		meshedUsers = arr;
	});
	
	//consider throwing away the weekMap if "today" changes
	var weekMap = {};
	//date is expected to be a YYYY-MM-DD formatted string
	function getWeekDiff(date){
		if(!date){ return null; }
		if(weekMap[date]){ return weekMap[date]; }
		var diff = moment(date, 'YYYY-MM-DD').startOf('week').diff(today.startOf('week'), 'weeks');
		weekMap[date] = diff;
		//console.log('today', today.format('YYYY-MM-DD'), 'date', date, 'diff', diff);
		return diff;
	}
	function formatWeek(week){
		if(week === null){ return 'No Date Set'; }
		switch(week){
			case -1: return "Last Week";
			case 0: return "This Week";
			case 1: return "Next Week";
			default:
				if(week > 0){ return 'In ' + week + ' Weeks'; }
				else{ return Math.abs(week) + ' Weeks Ago' }
		}
	}
	
	//a value that already doesn't exist in the groupedList
	//this may not actually be necessary (do some testing later)
	function staleHeaderCheck(val, list){
		if(!list || !list.length){return -1;}
		for(var i = 0; i < list.length; i++){
			if(list[i].value === val){return i;}
		}
		return -1;
	}
	
	var sortTypes = {
		"plannedFinish": {
			getHeader: function getHeader(rec){
				if(!rec.data){ var week = null; }
				else{
					var date = rec.data.plannedFinish || rec.data.lastPlannedFinish;
					//console.log('date', date)
					var week = getWeekDiff(date);
				}
				return {
					"value": week,
					"display": formatWeek(week)
				};
			},
			headerSortFn: function headerSortFn(a,b){
				if(a.value === null && b.value === null){return 0;}
				if(a.value === null){return -1;}
				if(b.value === null){ return 1; }
				
				if(this.sortDescending){ return a.value - b.value; }
				return b.value - a.value;
			},
			childSortFn: function childSortFn(a,b){
				if(a.data.plannedFinish === b.data.plannedFinish){return defaultSortFallback(a,b);}
				if(!a.data.hasOwnProperty('plannedFinish')){return -1;}
				if(!b.data.hasOwnProperty('plannedFinish')){ return 1; }
				if(this.sortDescending){ return a.data.plannedFinish.localeCompare(b.data.plannedFinish); }
				return b.data.plannedFinish.localeCompare(a.data.plannedFinish);
			}
		},
		"dateRequested": {
			getHeader: function getHeader(rec){
				if(!rec.data){ var week = null; }
				else{
					var date = rec.data.dateRequested;
					//console.log('date', date)
					var week = getWeekDiff(date);
				}
				return {
					"value": week,
					"display": formatWeek(week)
				};
			},
			headerSortFn: function headerSortFn(a,b){
				if(a.value === null && b.value === null){return 0;}
				if(a.value === null){return -1;}
				if(b.value === null){ return 1; }
				if(this.sortDescending){ return a.value - b.value; }
				return b.value - a.value;
			},
			childSortFn: function childSortFn(a,b){
				if(a.data.dateRequested === b.data.dateRequested){return defaultSortFallback(a,b);}
				if(!a.data.hasOwnProperty('dateRequested')){return -1;}
				if(!b.data.hasOwnProperty('dateRequested')){ return 1; }
				if(this.sortDescending){ return a.data.dateRequested.localeCompare(b.data.dateRequested); }
				return b.data.dateRequested.localeCompare(a.data.dateRequested);
			}
		},
		"createdAt": {
			getHeader: function getHeader(rec){
				if(!rec.data){ var week = null; }
				else{
					var date = rec.data.createdAt;
					var week = getWeekDiff(date);
				}
				return {
					"value": week,
					"display": formatWeek(week)
				};
			},
			headerSortFn: function headerSortFn(a,b){
				if(a.value === null && b.value === null){return 0;}
				if(a.value === null){return -1;}
				if(b.value === null){ return 1; }
				if(this.sortDescending){ return a.value - b.value; }
				return b.value - a.value;
			},
			childSortFn: function childSortFn(a,b){
				if(a.data.createdAt === b.data.createdAt){return defaultSortFallback(a,b);}
				if(!a.data.hasOwnProperty('createdAt')){return -1;}
				if(!b.data.hasOwnProperty('createdAt')){ return 1; }
				if(this.sortDescending){ return a.data.createdAt.localeCompare(b.data.createdAt); }
				return b.data.createdAt.localeCompare(a.data.createdAt);
			}
		},
		"priority": {
			getHeader: function getHeader(rec){
				if(!rec.data || !rec.data.priorityId){ return {value: null, display: "No priority"}; }
				var p = this.sortGetterFn(rec.data.priorityId);
				//todo - figure out how much detail needs to be stored for priority, for now just return it...
				return {
					value: p.sortOrder,
					display: p.text
				};
			},
			headerSortFn: function headerSortFn(a,b){
				if(a.value === null && b.value === null){return 0;}
				if(this.sortDescending){
					if(b.value === null){return 1;}
					if(a.value === null){ return -1; }
					return a.value - b.value;
				}
				else{
					if(a.value === null){return 1;}
					if(b.value === null){ return -1; }
					return b.value - a.value;
				}

			},
			childSortFn: function childSortFn(a,b){
				return defaultSortFallback(a,b);
			}
		},
		"plan": {
			getHeader: function getHeader(rec){
				if(!rec._debugIdxData || !rec._debugIdxData.planId){ return {value: null, display: "No plan?"}; }
				var p = this.sortGetterFn(rec._debugIdxData.planId);
				//todo - figure out how much detail needs to be stored for priority, for now just return it...
				return {
					value: p.name,
					display: p.name
				};
			},
			headerSortFn: function headerSortFn(a,b){
				if(a.value === null && b.value === null){return 0;}
				if(a.value === null){return -1;}
				if(b.value === null){ return 1; }
				if(this.sortDescending){ return b.value.localeCompare(a.value); }
				return a.value.localeCompare(b.value);
			},
			childSortFn: function childSortFn(a,b){
				return defaultSortFallback(a,b);
			}
		},
		"responsibleIndividual": {
			getHeader: function getHeader(rec){
				if(!rec.data || (!rec.data.assignedProjectMemberId && !rec.data.responsibleProjectMemberId)){ return {value: null, display: "No id"}; }
				var user = meshedUsers.$getPmRecord(rec.data.assignedProjectMemberId);
				if(!user){ user = meshedUsers.$getPmRecord(rec.data.responsibleProjectMemberId); }
				if(!user){ return {value: null, display: "No Data"}; }
				var role = user.data.roleName || "No role assigned";
				return {
					value: role || null,
					display: role
				};
			},
			headerSortFn: function headerSortFn(a,b){
				if(a.value === null && b.value === null){return 0;}
				if(a.value === null){return 1;}
				if(b.value === null){ return -1; }
				if(this.sortDescending){ return b.value.localeCompare(a.value); }
				return a.value.localeCompare(b.value);
			},
			childSortFn: function childSortFn(a,b){
				var aId = a.data.assignedProjectMemberId || a.data.responsibleProjectMemberId || null;
				var bId = b.data.assignedProjectMemberId || b.data.responsibleProjectMemberId || null;
				if(aId === bId){ return defaultSortFallback(a,b); }
				if(!aId){return -1;}
				if(!bId){ return 1; }
				var aPM = meshedUsers.$getPmRecord(aId);
				var bPM = meshedUsers.$getPmRecord(bId);
				
				if(aPM === null && bPM === null){return 0;}
				if(aPM === null){return 1;}
				if(bPM === null){ return -1; }
				
				return aPM.data.formattedName.localeCompare(bPM.data.formattedName);
			}
		}
	}
	
	//sorts by plannedFinish, then activityName
	function defaultSortFallback(a,b){
		//activityName
		if(a.data.plannedFinish === b.data.plannedFinish){
			if(a.data.activityName === b.data.activityName){return 0;} //should protect against localeCompare errors
			if(!a.data.hasOwnProperty('activityName')){return -1;}
			if(!b.data.hasOwnProperty('activityName')){ return 1; }
			return a.data.activityName.localeCompare(b.data.activityName);
		}
		if(!a.data.hasOwnProperty('plannedFinish')){return -1;}
		if(!b.data.hasOwnProperty('plannedFinish')){ return 1; }
		return a.data.plannedFinish.localeCompare(b.data.plannedFinish);
	}

	function Constraint2(snap, idxSnap){
		this.$id = idxSnap.key;
		this.local = {};
		this._debugIdxData = idxSnap.val(); //ensure this gets added first!
		this.update(snap);
	}
	Constraint2.prototype = {
		update: function(snap){
			//store the data on...data
			var oldData = this.data || {};

			this.data = snap.val();
			var changed = !angular.equals(this.data, oldData);
			
			if(changed && this.data){
				//this.local.meshedPlannedFinish = this.data.plannedFinish || this.data.lastPlannedFinish || null;
				//this.local.meshedPlannedStart = this.data.plannedStart || this.data.lastPlannedStart || null;
				this.local.meshedPlannedFinish = this.data.plannedFinish || null;
			}
			// if(changed){
			// 	this.local.dateDiff = getWeekDiff(this.data.plannedFinish);
			// }
			this._oldSnap = snap;

			return changed;
		},
		toJSON: function(){
			//console.log('toJSONing in constraint2', this.data);
			//return the parsed data from it's "data" location
			return this.data;
		}
	};

	//Array instance section
	function ConstraintsFactory(ref, dataRef){
		//setup the stuff that must be loaded initially
		this._groupedList = {};
		this._groupHeaders = [];
		
		this.setSortType('plannedFinish', undefined, true);
		
		// call the super constructor
		var thing = firebaseIndex.call(this, ref, dataRef);
		//slap shit onto the external api
		thing.groupedList = this._groupedList;
		thing.groupHeaders = this._groupHeaders;
		
		return thing;
	}
	ConstraintsFactory.prototype = {
		$$addedIdx: function(snap, idxSnap){
			//console.log('addedIdx', snap.key, idxSnap.key);
			//console.log('snap', snap.val());
			var t = new Constraint2(snap, idxSnap);
			//console.log('$$addedIdx constraint', t);
			//t.$parent = this.$list;
			
			//grouping on add, very simple...
			//console.log('adding...', t);
			this.addToGroup(t);

			return t;
		},
		$$getDataRef: function(idxSnap){
			//console.log('get child', idxSnap.val(), idxSnap.key);
			if(idxSnap._dataRef){ return idxSnap._dataRef; }
			if(idxSnap.exists()){
				var c = this._baseDataRef.child(idxSnap.child('planId').val()).child(idxSnap.child('ticketId').val());
				//console.log('c', c.toString());
				return c;
			}
			return false;
		},
		$$updatedIdx: function(dataSnap, idxSnap){
			//grouping on update
			//console.log('updatedIdx', dataSnap.key, idxSnap.key);
			var record = this.$getRecord(idxSnap.key);
			if(!record){console.log('record doesn\'t exist...'); return;} //fix this error message...
			
			var old = {
				"$id": record.$id,
				"data": angular.extend({}, record._oldSnap.val()),
				"local": angular.extend({}, record.local),
				"_debugIdxData": angular.extend({}, record._debugIdxData)
			}
			//console.log('record', JSON.stringify(dataSnap.val(), null, 2), JSON.stringify(old.data,  null, 2));
			
			
			// var keys = {};
			// if(dataSnap.val() && old.data){
			// 	Object.keys(dataSnap.val()).forEach(function(k){keys[k] = k;});
			// 	Object.keys(old.data).forEach(function(k){keys[k] = k;});
			// 	Object.keys(keys).forEach(function(k){
			// 		if(old.data[k] !== dataSnap.val()[k]){
			// 			console.log('changed: ', k);
			// 		}
			// 	});
			// }
			
			var changed = record.update(dataSnap);
			
			//TODO - invent new sort compatible metric for doing these group recalcs less frequently
			//if(dataSnap.child(SORT_DATE).val() !== old.data[SORT_DATE]){
			//console.log('updating...');
				this.removeFromGroup(record, old);
				this.addToGroup(record);
			//}
			//it's possible the entire group reference for that item will need to be updated. Assume no for now...
			
			return changed;
		},
		$$removedIdx: function(snap){
			var record = this.$getRecord(snap.key);
			//console.log('removedIdx',snap.key, record);
			if(!record){return;}
			
			this.removeFromGroup(record);
			record.data = null;
		},
		
		$destroy: function(){
			delete this.sortGetterFn;
			return $firebaseArray.prototype.$destroy.apply(this,arguments);
		},
		
		//todo, either actually index these by ticketId, or provide a cache
		$getByTicketId: function(ticketId, planId){
			
			if(!planId){
				var state = legacyRouting.oldStyleSnapshot();
				planId = state.planId;
			}
			var conId = planId+","+ticketId;
			return this.$getRecord(conId);
			// for(var i=0; i< this.$list.length; i++){
			// 	if(this.$list[i]._debugIdxData.ticketId === id){ return this.$list[i];}
			// }
			// return null;
		},
		
		addIndex: function addIndex(planId, ticketId){
			var constraintId = planId+","+ticketId;
			var ref = this._ref.child(constraintId);
			ref.set({
				"planId": planId, "ticketId": ticketId
			});
			return ref;
		},
		
		//some sort types will require a getter function because they rely on external data
		setSortType: function(typeOrObj, getterFn, noRecalc, sortDescending){
			var self = this;
			if(typeof typeOrObj === "string"){ var sortObj = sortTypes[typeOrObj]; }
			else{ var sortObj = typeOrObj; }
			
			for(var key in sortObj){
				self[key] = sortObj[key];
			}
			this.sortGetterFn = getterFn;
			this.sortDescending = !!sortDescending;
			//now recalc the grouped lists..
			if(!noRecalc){ self.recalcGroups(); }
		},
		
		toggleShowCompleted: function toggleShowCompleted(force){
			
			if(force === true || force === false){ this.showCompleted = force; }
			else{ this.showCompleted = !this.showCompleted; }
			//console.log('called', this.showCompleted);
			this.recalcGroups();
		},
		toggleThisPlanOnly: function toggleThisPlanOnly(force){
			if(force === true || force === false){ this.thisPlanOnly = force; }
			else{ this.thisPlanOnly = !this.thisPlanOnly; }
			this.recalcGroups();
		},
		toggleOnlyLate: function toggleOnlyLate(force){
			if(force === true || force === false){ this.onlyLate = force; }
			else{ this.onlyLate = !this.onlyLate; }
			this.recalcGroups();
		},
		
		//### miniSave(id, obj)
		// Validates and saves the limited properties used in constraintlog editing
		// If a value is passed in via the object and is supported it will replace the existing properties.
		// If a supported value is not passed, that value will simply not be updated.
		// obj:
		// priorityId - id indicating priority level
		// complete - bool, if true, sets the actualFinish to the plannedDate, if false (or null), nulls actualFinish
		// notes - string, the notes, unvalidated
		miniSave: function miniSave(id, obj, completeOnly: boolean = false){
			var rec = this.$getRecord(id);
			//console.log(rec);
			if(!rec){Logging.warning("id is invalid"); return;}
			var cleanObj:any = {};
			if(obj.priorityId !== undefined){ cleanObj.priorityId = obj.priorityId; }
			console.log('obj', obj, obj.locationId);
			if(obj.locationId  !== undefined){ cleanObj.locationId = obj.locationId; }
			if(!obj.actualFinish && obj.complete === false || obj.complete === null || obj.complete){
				//if(!rec.data.plannedFinish){Logging.warning("date required to complete"); return;}
				// Handle Procore here so if the constraint logic gets updated, hopefully
				// the Procore specific stuff does too.
				if(obj.procore){
					cleanObj.actualFinish = obj.actualFinish;
					cleanObj.lastPlannedFinish = rec.data.plannedFinish;
					cleanObj.lastPlannedStart = rec.data.plannedStart;
				}
				else if(obj.complete){
					cleanObj.actualFinish = rec.data.plannedFinish || rec.data.actualFinish;
					cleanObj.lastPlannedFinish = rec.data.plannedFinish || rec.data.lastPlannedFinish;
					cleanObj.lastPlannedStart = rec.data.plannedStart || rec.data.lastPlannedStart;
				}
				else{
					cleanObj.actualFinish = null;
					cleanObj.actualStart = null;
					cleanObj.plannedFinish = rec.data.lastPlannedFinish || rec.data.plannedFinish || null;
					cleanObj.plannedStart = rec.data.lastPlannedStart || rec.data.plannedStart || null;
				}
			}
			if(obj.hasOwnProperty("notes")){cleanObj.notes = obj.notes;}
			if (obj.hasOwnProperty('actualFinish')){cleanObj.actualFinish = obj.actualFinish;}
			
			// MOC-2679
			if (completeOnly){
				rec.data = cleanObj;
			} else {
				for(var key in cleanObj){
					rec.data[key] = cleanObj[key];
				}
			}
			return this.$save(rec, completeOnly);
		},
		
		//### getEditData(id)
		// returns some data for the record appropriately massaged for edit mode
		getEditData: function getEditData(id){
			var rec = this.$getRecord(id);
			if(!rec){ console.log('this is probably a problem'); return {}; }
			return {
				"priorityId": rec.data.priorityId,
				"complete": !!rec.data.actualFinish,
				"notes": rec.data.notes
			};
		},
		
		
		//sort override api
		//getHeader(rec) -> {"display": "Something to display", value: 0}
		//headerSortFn -> sort function using the header values passed in earlier (maybe make optional? and just sort numerically by value)
		//childSortFn -> sort function for the actual values
		recalcGroups: function recalcGroups(){
			this._groupHeaders.splice(0,this._groupHeaders.length);
			for(var key in this._groupedList){ delete this._groupedList[key]; }
			this.$list.forEach(function(c){
				this.addToGroup(c, true);
			}, this);
			
			//this._groupHeaders.sort(this.headerSortFn);
			//for(var key in this._groupedList){ this._groupedList[key].sort(this.childSortFn); }
			
			this._groupHeaders.sort(this.headerSortFn.bind(this));
			for(var key in this._groupedList){ this._groupedList[key].sort(this.childSortFn.bind(this)); }
		},

		
		//group maintenence
		//absolutely no effort is made to prevent duplicates.
		//On the assumption that angularfire takes care of that.
		addToGroup: function addToGroup(rec, noSort){
			var state = legacyRouting.oldStyleSnapshot();
			//console.log('addToGroup', rec);
			if(!rec.data){return;}
			
			//in theory actualFinish existing means finished
			if(!this.showCompleted && rec.data.actualFinish){
				return;
			}
			//console.log("rec", rec, rec._debugIdxData);
			if(this.thisPlanOnly && state.planId && state.planId !== rec._debugIdxData.planId){
				return;
			}
			if(this.onlyLate && !rec.data.isLateConstraintRequested){
				return;
			}
		
			//console.log('add to group', rec.$id, rec);
			var header = this.getHeader(rec);
			//console.log('header', header);
			if(!this._groupedList[header.value]){
				//console.log('new', this._groupedList[header.value], header.value);
				this._groupedList[header.value] = [];
				
				//switch to a proper insertion sort later, be lazy for now...
				this._groupHeaders.push(header);
				//if(!noSort){ this._groupHeaders.sort(this.headerSortFn); }
				if(!noSort){ this._groupHeaders.sort(this.headerSortFn.bind(this)); }
			}
			this._groupedList[header.value].push(rec);
			if(!noSort){ this._groupedList[header.value].sort(this.childSortFn.bind(this)); }
		},
		//takes an optional "old" argument in case the local record
		// was already updated, is assumed to be "record.data"
		// and falls back to that is not passed in.
		removeFromGroup: function removeFromGroup(record, old){
			//console.log('remove from group1', old);
			if(!old){ old = record; }
			if(!old){return;}
			//remove
			//console.log('remove from group2', record, old);
			
			var header = this.getHeader(old);
			//console.log('header', header);
			var oldWeek = header.value;//getWeekDiff(old[SORT_DATE]);
			var o = this._groupedList[oldWeek];
			var idx;
			if(o === undefined){ //nothin' to remove, probably a result of slow loading data
				//console.log('stale');
				idx = staleHeaderCheck(oldWeek, this.groupHeaders);
				if(idx !== -1){ this.groupHeaders.splice(i,1); }
				return;
			}
			//console.log('pre-splice', o, o.length);
			idx = o.indexOf(record);
			if(idx !== -1){ o.splice(idx,1); }
			//console.log('post-splice', o, o.length);
			if(!o.length){
				var innerIdx;
				for(var i = 0; i < this._groupHeaders.length; i++){
					if(this._groupHeaders[i].value === oldWeek){
						innerIdx = i; break;
					}
				}
				//console.log('innerIdx', innerIdx);
				if(innerIdx !== undefined){ this._groupHeaders.splice(innerIdx,1); }
				delete this._groupedList[oldWeek];
			}
		}
	};
	var theFactory = firebaseIndex.$extend(ConstraintsFactory);
	return theFactory;
	/*
	function returnFunction(refUrl, exceptionRefUrl){
		var temp = new theFactory(fbConnection.fbRef(refUrl));
		return temp;
	}
	//add properties to the returnFunction for static code
	
	return returnFunction;
	*/
}])

.factory('firebaseTimelineTickets', ["fbConnection", "$q", "$firebaseArray", "$timeout", "legacyRouting", "firebaseIndex", "fbdbTime", function(fbConnection, $q, $firebaseArray, $timeout, legacyRouting, firebaseIndex, fbdbTime) {
	
	function TimelineConstraint(snap, idxSnap){
		this.$id = idxSnap.key;
		this.local = {};
		this.update(snap);
	}
	TimelineConstraint.prototype = {
		update: function(snap){
			//store the data on...data
			var oldData = this.data || {};

			this.data = snap.val();
			var changed = !angular.equals(this.data, oldData);
			
			return changed;
		},
		toJSON: function(){
			//console.log('toJSONing in constraint2', this.data);
			//return the parsed data from it's "data" location
			return this.data;
		}
	};

	//Array instance section
	function TimelineTicketsFactory(ref, dataRef){
		//setup the stuff that must be loaded initially
		
		// call the super constructor
		var thing = firebaseIndex.call(this, ref, dataRef);
		//slap shit onto the external api
		
		return thing;
	}
	TimelineTicketsFactory.prototype = {
		$$addedIdx: function(snap, idxSnap){
			//console.log('addedIdx', snap.key, idxSnap.key);
			//console.log('snap', snap.val());
			var t = new TimelineConstraint(snap, idxSnap);

			return t;
		},
		$$getDataRef: function(idxSnap){
			//console.log('get child', idxSnap.val(), idxSnap.key);
			if(idxSnap._dataRef){ return idxSnap._dataRef; }
			if(idxSnap.exists()){
				var c = this._baseDataRef.child(idxSnap.child('planId').val()).child(idxSnap.child('ticketId').val());
				//console.log('c', c.toString());
				return c;
			}
			return false;
		},
		$$updatedIdx: function(dataSnap, idxSnap){
			//debugger;
			//grouping on update
			//console.log('arguments', arguments);
			//console.log('updatedIdx', dataSnap.key, idxSnap.key);
			var record = this.$getRecord(idxSnap.key);
			var changed = record.update(dataSnap);
			
			return changed;
		},
		addIndex: function addIndex(planId, ticketId){
			var composite = this.formCompositeId(planId, ticketId);
			var ref = this._ref.child(composite);
			ref.set({
				"planId": planId, "ticketId": ticketId
			});
			return ref;
		},
		$remove: function $remove(recordOrIndex){
			if(this.hasDependencies(recordOrIndex)){
				Logging.warning("Can't remove, has dependencies");
				return $q.rejectErr("Can't remove, has dependencies");
			}
			return $firebaseArray.prototype.$remove.apply(this, arguments);
		},
		$getCompositeRecord: function $getCompositeRecord(planId, ticketId){
			var composite = this.formCompositeId(planId, ticketId);
			return this.$getRecord(composite);
		},
		formCompositeId: function formCompositeId(planId, ticketId){
			return planId+","+ticketId;
		},
		
		hasDependencies: function hasDependencies(recordOrIndex){
			var state = legacyRouting.oldStyleSnapshot();
			var key = this.$keyAt(recordOrIndex);
			var con = this.$getRecord(key);

			var currentPlanId = state.planId;
			if(!con || !con.data){console.log('record doesn\'t exist'); return false;}
			var preds = con.data.predecessors ? Object.keys(con.data.predecessors) : [];
			var succs = con.data.successors ? Object.keys(con.data.successors) : [];
			if(preds.indexOf(currentPlanId) !== -1){ preds.splice(preds.indexOf(currentPlanId), 1) }
			if(succs.indexOf(currentPlanId) !== -1){ succs.splice(succs.indexOf(currentPlanId), 1) }
			// return  preds.length > 1 || succs.length > 1 || (con.data.predecessors && !con.data.predecessors[currentPlanId]) || (con.data.successors && !con.data.successors[currentPlanId]);
			return  preds.length > 0 || succs.length > 0;
		}
		// $$removedIdx: function($id){
		// 	//console.log('removedIdx', $id);
		// 	var record = this.$getRecord($id);
		// 	record.data = null;
		// },

	};
	var theFactory = firebaseIndex.$extend(TimelineTicketsFactory);
	return theFactory;
}]);
