'use strict';

import * as angular from "angular";
import * as moment from "moment";

import { dateCache } from "ng2/common/date-cache";

angular.module('ProjectView').factory("projectViewUtils", ProjectViewUtilitiesFactory);

/**
 * @namespace projectViewUtils
 * @desc Provides Utility functions to translate dates into pixel coordinates for proper drawing position in the overview window.
 * @memberOf ProjectView
 * @returns {{calculateDrawPos: calculateDrawPos, checkDateRange: checkDateRange, getPixelUnits: getPixelUnits, getTicketStatusDraw: getTicketStatusDraw, isDateBefore: isDateBefore, getDayDifference: getDayDifference, getFinishDate: getFinishDate, getStartDate: getStartDate, planIsEmpty: planIsEmpty}}
 * @constructor
 */
function ProjectViewUtilitiesFactory(){
	/**
	 * @function calculateDrawPos
	 * @desc Calculates the drawing position of an object in the overview window based on the input dates. Uses the today date as an anchor point to draw the graphics.
	 * @param startDate {String|Date} A {DateString} or a {Date} of the left edge of the graphics object to draw.
	 * @param endDate {String|Date} A {DateString} or a {Date} of the right edge of the graphics object to draw.
	 * @param totalDays {Number} A {Number} which represents the width of the object to draw.
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {Number} Number representing the offset pixel amount to draw the graphic, relative to the today date.
	 */
	function calculateDrawPos(startDate:string, endDate:string, totalDays:number){
		var todayDate = dateCache.todayString;
		if (checkDateRange(startDate, endDate, todayDate)){
			return -getPixelUnits(totalDays) + getDayDifference(endDate, todayDate);
		} else if (endDate <= todayDate){
			return -getPixelUnits(totalDays) - getPixelUnits(getDayDifference(endDate, todayDate));
		} else if (endDate >= todayDate) {
			return getPixelUnits(totalDays) + getPixelUnits(getDayDifference(endDate, todayDate));
		} else {
			return 0;
		}
	}

	/**
	 * @function checkDateRange
	 * @desc Checks to see if the start date is less than the end date and if the end date is greater than the ceiling date.
	 * @param start {Date} The starting date you wish to check against.
	 * @param end {Date} The ending date you wish to check against
	 * @param compare {Date} The ceiling date you wish to check against
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {boolean} Returns true is start <= end && end >= compare.
	 */
	function checkDateRange(start:string, end:string, compare:string){
		// start = (typeof start === 'string') ? new Date(start).getTime() : start;
		// end = (typeof end === 'string') ? new Date(end).getTime() : end;
		// compare = (typeof compare === 'string') ? new Date(compare).getTime() : compare;
		return (start <= end && end >= compare)
	}

	/**
	 * @function getPixelUnits
	 * @desc Returns the X pixel drawing location given amount of days and scale. Always draws relative to {Today}
	 * @param days {Number} A number representing the amount of days in the future or the past from today. -1 = Yesterday. 1 = Tomorrow.
	 * @param scale {Number} The drawing scale. Used to scale the Overview projection horizontally.
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {number} Representing the X-global coordinate to draw.
	 */
	function getPixelUnits(days, scale?){
		scale = scale || 1;
		return Math.abs(days * scale);
	}

	/**
	 * @function getTicketStatusDraw
	 * @desc Calculates the drawing position of the complete and incomplete ticket status bars on top of the plan bar.
	 * @param plan {Object} An object with the same structure as plan_controller.plan
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {{earliestLastPlannedStart, latestLastPlannedFinish, earliestPlannedStart, latestPlannedFinish}}
	 */
	function getTicketStatusDraw(plan){
		var startDate = getStartDate(plan);
		function getDateDifference(start, compare){
			return (isDateBefore(start, compare)) ? getDayDifference(startDate, compare) * -1 : getDayDifference(startDate, compare)
		}
		return {
			earliestLastPlannedStart : getDateDifference(startDate, plan.earliestLastPlannedStart),
			latestLastPlannedFinish: getDateDifference(startDate, plan.latestLastPlannedFinish),
			earliestPlannedStart: getDateDifference(startDate, plan.earliestPlannedStart),
			latestPlannedFinish: getDateDifference(startDate, plan.latestPlannedFinish)
		}
	}

	/**
	 * @function isDateBefore
	 * @desc Used to determine if a date is before another date
	 * @param date1 {String|Date}
	 * @param date2 {String|Date}
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {boolean}
	 */
	function isDateBefore(date1, date2){
		return date2 < date1;
		// date1 = moment(date1);
		// date2 = moment(date2);
		// return date2.isBefore(date1);
	}

	/**
	 * @function getDayDifference
	 * @desc Inclusively calculates the day difference, given two dates.
	 * @param start {String} The starting date
	 * @param end {String} The ending date
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {number} representing the amount of days separating the two dates.
	 */
	function getDayDifference(start:string, end:string){
		// console.log('getDayDifference', start, end, dateCache.diffDays(start,end), Math.abs(moment(start).diff(moment(end), 'days')))
		return Math.abs(dateCache.diffDays(start,end));
		// start = moment(start);
		// end = moment(end);
		// return Math.abs(start.diff(end, 'days'))
	}

	/**
	 * @function getFinishDate
	 * @desc Returns the first available finish date for the plan bar.
	 * @param plan {Object} An object with the same structure as plan_controller.plan
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {string} DateString
	 */
	function getFinishDate(plan){
		var milestone = (plan.milestone) ? plan.milestone : {};
		return plan.targetFinishDate || milestone.targetDate || plan.latestPlannedFinish || plan.latestLastPlannedFinish || plan.latestActualFinish || dateCache.todayString;
	}

	/**
	 * @function getStartDate
	 * @desc Returns the first available starting date for the plan bar
	 * @param plan {Object} An object with the same structure as plan_controller.plan
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {string} DateString
	 */
	function getStartDate(plan){
		return plan.targetStartDate || plan.earliestLastPlannedStart || plan.earliestPlannedStart || dateCache.todayString;
	}

	/**
	 * @function planIsEmpty
	 * @desc Checks to determine if the plan is empty of any viable ticket data, given the start and end dates
	 * @param start {string|date}
	 * @param end {string|date}
	 * @memberOf ProjectView.projectViewUtils
	 * @returns {Boolean} DateString
	 */
	function planIsEmpty(start, end){
		start = moment(start);
		end = moment(end);
		return start.isSame(end);
	}

	return{
		calculateDrawPos: calculateDrawPos,
		checkDateRange: checkDateRange,
		getPixelUnits: getPixelUnits,
		getTicketStatusDraw: getTicketStatusDraw,
		isDateBefore: isDateBefore,
		getDayDifference: getDayDifference,
		getFinishDate: getFinishDate,
		getStartDate: getStartDate,
		planIsEmpty: planIsEmpty
	}
}
