import { NgModule } from '@angular/core';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';

import { LegacyRouting } from "./legacy-routing.service";
import { CustomReuseStrategy } from "./custom-reuse-strategy.service";

//route component imports
import { PlanComponent } from "./plan.component";
import { ProjectDashboardComponent } from "./project-dashboard/project-dashboard.component";
import { AccountComponent } from "./account/account.component";
import { AccountProfileComponent } from "./account-profile/account-profile.component";
import { SignupComponent } from "./signup/signup.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { HarnessComponent } from "./harness/harness.component";
import { ReportComponent } from "./report/report.component";
import { TestComponent } from "./test/test.component";

import {
	RallyPointResolverService,
	RallyComponent,
	RallyEmailComponent,
	RallyLoginComponent,
	RallyStartComponent,
	RallySignupComponent,
} from "./rally-point";

import { EmptyComponent } from "./empty.component";
import { UserDataResolver } from "./user-data-resolver.service";
import { NotAuthenticatedGuard } from "./not-authenticated-guard.service";
import { WaitForFrameGuard } from "./wait-for-frame-guard.service";
import {UnsubstantiatedRouteExecution, UnsubstantiatedRoutePaths} from "./unsubstantiated-route-execution.service";
import { DefaultRouteGuard } from "./default-route-guard.service";


// Process for using 2 routers:
// - replace old route with a blank route (possibly still with resolve information)
// - create configuration for new one
// - other things, test and fix shit

const appRoutes = [
	{
		path: 'plan/:projectId/:planId', 
		component: PlanComponent, 
		canActivate: [WaitForFrameGuard, UserDataResolver],
		resolve:{
			userData: UserDataResolver
		},
		
		// RunGuardsAndResolvers: 'always'
	},
	{
		path: 'projects/:projectId', 
		component: ProjectDashboardComponent,
		canActivate: [WaitForFrameGuard, UserDataResolver],
		resolve:{
			userData: UserDataResolver
		},
	},
	{ path: 'projects', redirectTo: 'projects/' },
	{
		path: 'reports/:projectId/:requestId', 
		component: ReportComponent, 
		canActivate: [WaitForFrameGuard, UserDataResolver],
		resolve:{
			userData: UserDataResolver
		},
	},
	{
		path: 'account', 
		component: AccountComponent,
		canActivate: [WaitForFrameGuard, UserDataResolver],
		resolve:{
			userData: UserDataResolver
		},
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'profile'
			},
			{
				path: 'profile',
				component: AccountProfileComponent
			}
		]
	},
	{
		path: 'rally', 
		component: RallyComponent,
		resolve: {rallyData: RallyPointResolverService},
		canActivate: [WaitForFrameGuard, RallyPointResolverService],
		canActivateChild: [RallyPointResolverService],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'start'
			},{
				path: 'start',
				component: RallyStartComponent
			},{
				path: 'email',
				component: RallyEmailComponent
			},{
				path: 'login',
				component: RallyLoginComponent
			},{
				path: 'signup',
				component: RallySignupComponent
			}
		]
	},
	{
		path: 'sign-up', 
		component: SignupComponent,
		canActivate: [WaitForFrameGuard, NotAuthenticatedGuard],
	},
	{
		path: 'login', 
		component: LoginComponent,
		canActivate: [WaitForFrameGuard, NotAuthenticatedGuard],
	},
	{
		path: 'reset-password', 
		component: ResetPasswordComponent,
		canActivate: [WaitForFrameGuard, NotAuthenticatedGuard],
	},
	{
		path: 'logout',
		component: EmptyComponent,
		canActivate: [UnsubstantiatedRouteExecution]
	},
	{
		path: 'auth',
		component: EmptyComponent,
		canActivate: [UnsubstantiatedRouteExecution]
	},
	{
		path: 'harness',
		component: HarnessComponent,
		canActivate: [WaitForFrameGuard]
	},
	{
		path: 'test',
		component: TestComponent,
		canActivate: [WaitForFrameGuard]
	},
	// {path: 'plan/:projectId/:planId', redirectTo: 'login' },
	{ 
		path: '**', 
		component: EmptyComponent,
		canActivate: [WaitForFrameGuard, DefaultRouteGuard],
		data: {placeholder: true}
	},
	
	// { path: '**', component: PlanComponent },
]

@NgModule({
	imports: [
		RouterModule.forRoot(
			appRoutes,
			//https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
			{useHash: true, onSameUrlNavigation: 'reload'}//, enableTracing: true} //debug
		)
	],
	exports: [
		RouterModule
	],
	providers: [
		LegacyRouting,
		UserDataResolver,
		WaitForFrameGuard,
		NotAuthenticatedGuard,
		DefaultRouteGuard,
		UnsubstantiatedRouteExecution,
		{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
		{provide: APP_BASE_HREF, useValue: '!'}
	]
})
export class AppRoutingModule{}
