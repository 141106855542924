import {Injectable} from "@angular/core";

import { Component, OnInit, ChangeDetectionStrategy, SecurityContext, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from '@angular/common/http';

import * as utils from "utils";
import * as firebase from "firebase/app";
import { baseRef } from "ng2/fancy-firebase/firebase-utils";

import {Perf, perf} from "ng2/common/perfLog"
import {REPORT_FAILED_TO_OPEN_WINDOW} from "../strings";

// declare var __webpack_hash__: string;
// console.log('webpack-hash', __webpack_hash__);

// import styles from 'styles/main';
// console.log('styles', styles);

const requestPath = "fbdbRequests";

let stringTranslate = `
(function(){
	var sectionsToChange = [
		".report-controller .left-container",
		".report-controller .center-container",
		".weekly-work-report-table thead",
		".sixweek-header",
		".gantt-tbl thead",
		".ppc-by-week-tbl thead",
		".constraint-log-table thead"
	];

	var replacements = [
		{orig: /^Plans/, replacement: "Pläne"},
		{orig: /^Roles/, replacement: "Gewerke"},
		{orig: /^Locations/, replacement: "Standorte"},
		{orig: /^Start Date/, replacement: "Startdatum"},
		{orig: /^End Date/, replacement: "Enddatum"},
		{orig: "Data as of", replacement: "Daten per"},
		{orig: "Weeks Commencing", replacement: "Woche beginnend"},
		{orig: "Week Commencing", replacement: "Woche beginnend"},
		{orig: "Task Description", replacement: "Aufgabenbeschreibung"},
		{orig: "All Plans", replacement: "Alle Pläne"},
		{orig: "All Roles", replacement: "Alle Gewerke"},
		{orig: "All Locations", replacement: "Alle Standorte"},
		{orig: "Prerequisite Work", replacement: "Notwendige Vorraussctzung"},
		{orig: "Project Roles", replacement: "Projekt Gewerke"},
		{orig: "Prior?", replacement: "vorherige woche?"},
		{orig: "Crew Size", replacement: "Kolonnengröße"},
		{orig: "Next", replacement: "nächste woche"},
		{orig: "Complete", replacement: "erledigt"},
		{orig: "Met Promise", replacement: "Zusage eingehalten"},
		{orig: "Reason for Variance", replacement: "Grund für Abweichung"},
		{orig: "Progress Notes", replacement: "Fortschrittsbemerkung"},
		{orig: "Notes", replacement: "Bemerkungen"},
		{orig: "Constraints", replacement: "Einschränkungen"},
		{orig: "Reason For Variance", replacement: "Grund für Abweichung"},
		{orig: "Six Week Look Ahead", replacement: "6-Wochenvorschau"},
		{orig: "Gantt Project Report", replacement: "Balkenplan"},
		{orig: "Days", replacement: "Tage"},
		{orig: "Planned Start", replacement: "Geplanter Anfang"},
		{orig: "Planned Finish", replacement: "Geplantes Ende"},
		{orig: "Actual Finish", replacement: "Tatsächliches Ende"},
		{orig: "Bold italic indicates a constraint", replacement: "Fett kursiv bedeutet eine Einschränkung"},
		{orig: "PPC By Week", replacement: "Wöchentlicher PEA Wert"},
		{orig: "Promises Met", replacement: "Zusage eingehalten"},
		{orig: "Promises Made", replacement: "gemachte Zusagen"},
		{orig: "Combined", replacement: "verbunden"},
		{orig: "PPC by Variance Reason", replacement: "PEA Nach Abweichungsgrund"},
		{orig: "PPC by Variance", replacement: "PEA Nach Abweichungsgrund"},
		{orig: "PPC Variance By Role", replacement: "Fett kursiv bedeutet eine Einschränkung"},
		{orig: "Constraint Description", replacement: "Beschreibung der Einschränkung"},
		{orig: "Constrained Tasks", replacement: "Einschränkende Aufgaben"},
		{orig: "Date Identified", replacement: "Tag der Feststellung"},
		{orig: "Date Promised", replacement: "Zugesagtes Datum"},
		{orig: "Reponsible Party", replacement: "Verantwortlicher"},
		{orig: "Notes", replacement: "Tag der Feststellung"},
		{orig: "Current Plan", replacement: "Aktueller Plan"},
		{orig: "Original Milestone", replacement: "Original Meilenstein"},
		{orig: "Days current schedule is before or after milestone", replacement: "Aktueller Plan ist vor oder nach Meilenstein"},
		{orig: "Work Days", replacement: "Arbeitstage"},
		{orig: "Required Date", replacement: "Zugesagtes Datum"},
		{orig: "Planned Date", replacement: "Geplantes Datum"},
		{orig: "Responsible Project Member", replacement: "Verantwortlicher"},
		{orig: "Assigned To", replacement: "Verantwortlicher"},
		{orig: "Week", replacement: "Woche"}
	];

	var walkDOM = function (node) {
		if(!node){return;}
		else if(node.hasChildNodes()){
			node.childNodes.forEach(function(childNode){ walkDOM(childNode); });
		}
		else{
			if(node.nodeType === 3){ //text
				var s = node.nodeValue.trim();
				if(!s){return;}
				//console.log(s);

				replacements.forEach(function(obj){
					node.nodeValue = node.nodeValue.replace(obj.orig, obj.replacement);
				});
			}
		}
	};

	function walkDOMList(list){
		list.forEach(function(selector){
			var matches = document.querySelectorAll(selector);
			matches.forEach(function(m){
				walkDOM(m);
			})
		});
	}

	return function(){ walkDOMList(sectionsToChange); }
})();
`


@Injectable()
export class ReportService{
	storage = firebase.storage();
	goCloud = MB4_PREPROCESSOR_CONFIG.USE_CLOUD;
	
	constructor(
		private http: HttpClient
	){
		
	}
	
	getCloudThing(path){
		var storageRef = this.storage.ref(path);
		return storageRef.getDownloadURL().then((url)=>{
			return this.requestCloudBlob(url)
		});
	}

	requestCloudBlob(url){
		if(!url){ throw new Error("Cloud blob requires a url. Maybe you need to enable cloud storage?")}
		return this.http.get(url, {responseType: "text"}).toPromise();
	}
	
	legacyReportAssembly(resultData){
		if (resultData['report']){
			return resultData['report'];
		} else if (resultData['report-0']){
			var i = 0;
			var tableAssembly = '';
			while (resultData['report-'+i]){
				tableAssembly += resultData['report-'+i];
				i++;
			}
			return tableAssembly;
		} else {
			console.log('no report...');
			throw new Error('report doesn\'t exist');
		}
	}
	
	shouldGermanify(projectId):Promise<boolean>{
		return baseRef("projects").child(projectId).child("germanify").once("value").then(request=> !!request.val());
	}
	
	requestResult(requestId: string):Promise<firebase.database.DataSnapshot>{
		return baseRef(requestPath).child(requestId).once("value").then((request) => {
			if(!request.exists()){
				throw new Error('this report has been removed');
			}
			return request;
		})
	}
	
	removeRequest(requestId: string){
		return baseRef(requestPath).child(requestId).remove();
	}
	
	getReport(requestId: string, projectId: string){
		return Promise.all([this.requestResult(requestId), this.shouldGermanify(projectId)]).then(results => {
			let germanify = results[1];
			let request = results[0];
			
			if(this.goCloud){
				return this.getCloudThing(request.child('results').child('reportBodyBlob').val()).then(reportData => {
					return {
						data: reportData,
						germanify: germanify,
						reportName: request.child('name').val() as string
					};
				})
			}
			else{
				return {
					data: this.legacyReportAssembly(request.child('results').val()),
					germanify: germanify,
					reportName: request.child('name').val() as string
				}
			}
		})
		.then(reportData => {
			let doctoredData = reportData.data;
			if(reportData.germanify){
				doctoredData = doctoredData.replace('<button onclick="window.print()" ng-hide="reports.loading">Print</button>', '<button  onclick="window.translateToGerman()" ng-hide="reports.loading">Übersetzen</button> <button onclick="window.print()" ng-hide="reports.loading">Print</button>');
			}
			
			doctoredData = `<script type="text/javascript"> window.translateToGerman = ${stringTranslate}</script>` + doctoredData;
			
			//temp
			// doctoredData = doctoredData.replace(`<button ng-click="reports.back()" ng-hide="reports.loading" class="secondary-action">Close</button>`, `<button onclick="reportsBack()" ng-hide="reports.loading" class="secondary-action">Close</button>`)
			
			let google = `<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script><script>google.charts.load('current', {'packages':['corechart']});</script>`;
			let jquery = `<script src="https://code.jquery.com/jquery-3.3.1.min.js" integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8=" crossorigin="anonymous"></script>`;
			let reportSpecificStyling = (reportData.reportName === 'projectReportBundle') ? 'projectReportBundle' : reportData.reportName;
			// console.log('doctoredData', doctoredData);
			
			return `<html><head><title>TouchPlan</title>${google} ${jquery}</head><body class="report-controller ${reportSpecificStyling}">${doctoredData}</body></html>`;
		})
	}
	
	public generateAndOpenReport(reportHtml: string): void {
		let newWindow = window.open();
		if (!newWindow){window.Logging.warning(REPORT_FAILED_TO_OPEN_WINDOW); return;}
		
		newWindow.document.write(`<div id="report-loader-blocker">Loading. Please wait...</div>`);
		
		setTimeout(() => {
			newWindow.document.write(reportHtml);

			for (let i = 0; i < document.styleSheets.length; i++) {
				let styleElement = newWindow.document.createElement("style");
				newWindow.document.head.appendChild(styleElement);

				let pageStylesheet: any = document.styleSheets[i];
				let sheet: any = styleElement.sheet;
				try {
					for (let rule = pageStylesheet.cssRules.length; rule >= 0; rule--) {
						if (pageStylesheet.cssRules[rule] && pageStylesheet.cssRules[rule].cssText) {
							sheet.insertRule(pageStylesheet.cssRules[rule].cssText);
						}
					}
				} catch (e) {}
			}

			// This tells the browser we stopped writing.
			// See: https://stackoverflow.com/questions/31387380/document-created-with-window-open-never-reaches-complete-readystate
			newWindow.document.close();
		});
		
		
		// MOC-3086
		function initReports(){
			if (newWindow.initializeReports){
				newWindow.initializeReports.forEach((fn, idx) => {
					fn();
				});
			}
			newWindow.document.getElementById("report-loader-blocker").outerHTML = "";
		}
		
		newWindow.onload = initReports;
	}
	
	getCompleteReport(requstId:string, projectId:string){
	
	}

}
