import { CreateTextureInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { RenderDependencies, Camera } from "../core/";
import { SpriteConstraint, SpriteMilestone, SpriteTask, SpriteTicket } from "./";
import { TaskRenderer } from "../ticket-renderer/";
import { LayerManager } from "../layers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture, loaders, utils } from "pixi.js";
import { Subject } from "rxjs";
import { TicketCollision } from "../ticket-collision/";
import { LevelOfDetail } from "../core/LevelOfDetail";
import { FAILED_TO_LOAD_TICKET_TEXTURES } from "../../common/strings";
import { TICKET_TEXTURE_OPTIONS } from "./TICKET_TEXTURE_OPTIONS";

/**
 * This class has a dependency to the TicketMapper. Where the TicketMapper is responsible for linking data to a DataContainer, the TicketManager is responsible for updating the SpriteTicket with the data update.
 */
export class TicketManager{
	public update$: Subject<any> = new Subject();
	public ticketCollision: TicketCollision;
	/**
	 * A cached object mapping a ticket $id to a SpriteTicket. This is used to prevent traversal of the scene graph
	 * @memberOf TicketManager
	 */
	private _cache: Map<string, SpriteMilestone|SpriteConstraint|SpriteTask>;
	/**
	 * Instance of a TaskRenderer
	 */
	public taskRenderer: TaskRenderer;
	/**
	 * Instance of LayerManager
	 */
	public layerManager: LayerManager;
	/**
	 * The current planState
	 */
	public planState: PlanState;
	public loader: loaders.Loader = new loaders.Loader();
	
	public camera: Camera;
	
	private _listener: utils.EventEmitter;
	private _listenerEvents: string[];
	private _render: () => any;
	/**
	 * @param {PlanState} planState
	 * @param {LayerManager} layerManager
	 * @param {Camera} camera The camera
	 * @param renderFn The renderer update function
	 * @param {CreateTextureInterface} [rendererOptions]
	 */
	constructor(planState: PlanState, layerManager: LayerManager, camera: Camera, renderFn: () => any, rendererOptions?: CreateTextureInterface){
		this._cache = new Map();
		this.taskRenderer = new TaskRenderer(true, rendererOptions);
		
		// Add Ticket texture as a static variable to PIXI.Texture. Adding it there so it is easy to access later...
		(<any>Texture).TASK_TEXTURE = this.taskRenderer.getTexture("ticket");
		(<any>Texture).TASK_OUTLINE_SEARCH = this.taskRenderer.getTexture("ticketOutlineSearchMesh");
		(<any>Texture).TASK_OUTLINE_DEPENDENCY = this.taskRenderer.getTexture("ticketOutlineDependencyMesh");
		(<any>Texture).TASK_OUTLINE_SELECTED = this.taskRenderer.getTexture("ticketOutlineSelectedMesh");
		(<any>Texture).TASK_OUTLINE_ERROR = this.taskRenderer.getTexture("ticketOutlineErrorMesh");
		
		this.layerManager = layerManager;
		this.planState = planState;
		this.camera = camera;
		this._render = renderFn;
		
		this.ticketCollision = new TicketCollision(this.planState, this, this.camera, this._render);
		
		
		RenderDependencies.addDependency('ticketTextureAtlas');
		RenderDependencies.addDependency('ticketOverlap');
		this._loadTextureAtlas(TICKET_TEXTURE_OPTIONS.ATLAS_REFERENCE_ID, TICKET_TEXTURE_OPTIONS.TEXTURE_ATLAS_PATH);
	}
	/**
	 * Middleware which fires BEFORE each ticket resource is loaded. Purpose of this middleware is to watch
	 * the loaded resources for when the ticket-texture.png image is next to load. It will then replace that URL string with
	 * the busty version of the ticket-textures-<random-shit>.png path.
	 * @param resource The loaders.Resource of the _next_ resource to be loaded.
	 * @param nextFn Invoke to continue the loading process.
	 */
	private _modifyAtlasUrlPreLoad = (resource: loaders.Resource, nextFn) => {
		if (resource.url === TICKET_TEXTURE_OPTIONS.TEXTURE_ATLAS_IMAGE_PATH_NO_BUSTY){
			resource.url = TICKET_TEXTURE_OPTIONS.TEXTURE_ATLAS_IMAGE_PATH_BUSTY;
		}
		nextFn();
	};
	/**
	 * Loads the ticket textures.
	 * @param {string} referenceId
	 * @param {string} path
	 */
	private _loadTextureAtlas(referenceId: string, path: string){
		this.loader.onError.add(this._onTextureAtlasLoadedError);
		this.loader
			.add(referenceId, path)
			.add(TICKET_TEXTURE_OPTIONS.TEXTURE_TICKET_OVERLAP, TICKET_TEXTURE_OPTIONS.TEXTURE_TICKET_OVERLAP)
			.pre(this._modifyAtlasUrlPreLoad)
			.load(this._onTextureAtlasLoaded);
	}
	/**
	 * Called once the ticket textures have been loaded.
	 *
	 * Once ticket texture atlas is loaded, it iterates through the PIXI.Texture and adds it to the TicketManager.resources list for easy access later.
	 * @param {PIXI.loaders.Loader} loader
	 * @param {PIXI.loaders.ResourceDictionary} resources
	 */
	private _onTextureAtlasLoaded = (loader: loaders.Loader, resources: loaders.ResourceDictionary) => {
		RenderDependencies.markAsLoaded('ticketTextureAtlas');
		RenderDependencies.markAsLoaded('ticketOverlap');
	};
	/**
	 * Invoked if an error occurs.
	 * @param err
	 */
	private _onTextureAtlasLoadedError = (err) => {
		console.log("ERROR", err);
		window.reporting.reportError(new Error("Failed to load ticket textures"), err);
		window.Logging.error(FAILED_TO_LOAD_TICKET_TEXTURES);
		//throw new Error(err.message);
	};
	/**
	 * If no ticket exists, then this is a new ticket with new data. Create the view
	 * @param {TicketContainer} ticketContainer The DataContainer associated with this ticket
	 */
	private createTicket(ticketContainer: TicketContainer): SpriteTask{
		let t =  new SpriteTask(
			this.taskRenderer.getTexture("ticket"),
			this.planState,
			ticketContainer
		);
		
		// Add our ticket to the "SURFACE" sorting layer.
		ticketContainer.SURFACE.addChild(t);
		
		// Caches our SpriteTicket. This makes it easier to retrieve later.
		this._cache.set(ticketContainer.getData().$id, t);
		t.updateData(this.planState, ticketContainer);
		if (RenderDependencies.firstDraw){
			t.update(this.planState, ticketContainer);
		}
		
		return t;
	}
	/**
	 * Called when a new milestone has been added.
	 * @param {TicketContainer} ticketContainer
	 */
	private createMilestone(ticketContainer: TicketContainer): SpriteMilestone{
		let m = new SpriteMilestone(
			utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_MILESTONE_NAME],
			this.planState,
			ticketContainer
		);
		
		// Add our ticket to the "SURFACE" sorting layer.
		ticketContainer.SURFACE.addChild(m);
		
		// Caches our SpriteTicket. This makes it easier to retrieve later.
		this._cache.set(ticketContainer.getData().$id, m);
		m.updateData(this.planState, ticketContainer);
		if (RenderDependencies.firstDraw){
			m.update(this.planState, ticketContainer);
		}
		return m;
	}
	/**
	 * Called when a new constraint has been added.
	 * @param {TicketContainer} ticketContainer
	 */
	private createConstraint(ticketContainer: TicketContainer): SpriteConstraint{
		let c = new SpriteConstraint(
			utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_NAME],
			utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_NAME],
			this.planState,
			ticketContainer
		);
		
		ticketContainer.SURFACE.addChild(c);
		
		// Caches our SpriteTicket. This makes it easier to retrieve later.
		this._cache.set(ticketContainer.getData().$id, c);
		c.updateData(this.planState, ticketContainer);
		if (RenderDependencies.firstDraw){
			c.update(this.planState, ticketContainer);
		}
		return c;
	}
	/**
	 * Called everytime a TicketMapper updates data on a data container
	 * @param {PlanState} planState the current plan state
	 * @param {TicketContainer} ticketContainer The DataContainer that was updated
	 * @returns {any}
	 */
	public updateData = (planState: PlanState, ticketContainer: TicketContainer) => {
		const t: SpriteTicket = this._cache.get(ticketContainer.getData().$id);
		if (!t) {
			let data = ticketContainer.getData();
			let lm = ticketContainer.ticketManager.layerManager;
			// Ticket does not exist yet, so create the view
			if (!data.view.type || data.view.type === 'task'){
				let t = this.createTicket(ticketContainer);
				if (data.isGhost){
					//TODO: Need to fix da Ghost
					// let ticket = dataContainer.components.get('ticket');
					// ticket.active = false;
					// ticket.visible = true;
					// ticket.alpha = 0.3;
				}
				return t
			} else if (data.view.type === 'milestone'){
				let t = this.createMilestone(ticketContainer);
				if (data.isGhost){
					//ticketContainer.components.get('ticket').active = false;
					//ticketContainer.components.get('ticket').visible = false;
				}
				return t
			} else  {
				let t = this.createConstraint(ticketContainer);
				if (data.isGhost){
					//ticketContainer.components.get('ticket').active = false;
					//ticketContainer.components.get('ticket').visible = false;
				}
				return t
			}
		}
		
		// Update ticket data.
		t.updateData(this.planState, ticketContainer);
		// let data = dataContainer.getData();
		// dataContainer.components.active = dataContainer.visible;
		//dataContainer.updateData(this.planState, dataContainer);
	};
	/**
	 * When the renderer is destroyed, this method is called.
	 */
	public destroy = () => {
		this._cache.clear();
		this.loader.destroy();
		this.ticketCollision.destroy();
		
		this._cache = null;
		
		this.taskRenderer.destroy();
		this.taskRenderer = null;
		
		// Clean-up the listener
		this._listenerEvents.forEach((eventName: string) => {
			this._listener.off(eventName, this[eventName])
		});
	};
	/**
	 * Called when a ticket is destroyed.
	 * @param {PlanState} planState
	 * @param {TicketContainer} dataContainer
	 */
	public destroyTicket = (planState: PlanState, dataContainer: TicketContainer) => {
		let key = dataContainer.getData().$id;
		if (this._cache.get(key)) {
			this._cache.delete(key);
		}
		return this;
	};
	/**
	 * Given the {ticketId}, return the corresponding SpriteTask, SpriteConstraint or SpriteMilestone
	 * @param {string} ticketId
	 */
	public get(ticketId: string): SpriteTask|SpriteConstraint|SpriteMilestone {
		return this._cache.get(ticketId);
	}
	/**
	 * Registers an event listener
	 * @param eventEmitter The emitter to listen to
	 * @param events A list of events to listen to.
	 */
	public listen(eventEmitter: utils.EventEmitter, ...events: string[]){
		this._listener = eventEmitter;
		this._listenerEvents = events;
		events.forEach((eventName) => {
			eventEmitter.on(eventName, this[eventName])
		});
	}
}
