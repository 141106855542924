'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

angular.module('ticketspaceApp').factory('firebaseAccessCode', ["$firebaseObject", "fbdbTime", "$q", function($firebaseObject, fbdbTime, $q){
	
	//performance of this is shit!
	//console.log('TODO - need to ensure this updates the ui consistently. Also perf.')
	function updateCountdown(){
		this._countdownMillis = this.timestamp - (new Date).valueOf();
		this._formattedCountDown = Math.ceil(moment.duration(this._countdownMillis, "milliseconds").asMinutes());
		if(this._countdownMillis < 0){ this.validCheck(this.timestamp); this.$$notify(); }
	}
	
	return $firebaseObject.$extend({
		"$$updated": function $$updated(snap){
			var changed = $firebaseObject.prototype.$$updated.apply(this, arguments);
			
			//run on the assumption that the default updated replaces the old obj
			// if(!snap.exists()){ this._isValid = false; this._validityMessage = "Access code does not exist.";}
			// else if(moment(snap.child("timestamp").val()).isBefore(moment())){ this._isValid = false; this._validityMessage = "Access code has expired."; }
			// else{ this._isValid = true; delete this._validityMessage;}
			
			this.validCheck(snap.child('timestamp').val());
			this.resetTimer();

			return changed;
		},
		"resetTimer": function resetTimer(){
			if(this._timerKey){clearInterval(this._timerKey); delete this._timerKey; }
			if(this._isValid){
				updateCountdown.apply(this);
				setInterval(updateCountdown.bind(this), 60000)
			}
		},
		"validCheck": function validCheck(timestamp){
			if(!timestamp){ this._isValid = false; this._validityMessage = "Access code is invalid.";}
			else if(moment(timestamp).isBefore(fbdbTime())){ this._isValid = false; this._validityMessage = "Access code has expired."; }
			else{ this._isValid = true; delete this._validityMessage;}
		},
		"expireTimer": function expireTimer(){
			if(this.timestamp){
				this.timestamp = fbdbTime().valueOf() - 1000; //just put it slightly further back to be sure
				this.validCheck(this.timestamp);
				analyticsService.rallyPointAccessCodeEnded(this.$id);
				this.$save();
			}
		},
		"extend": function extend(){
			var newTimestamp;
			if(!this._isValid){ newTimestamp = fbdbTime().add(30, 'minutes').valueOf(); } //not sure if this is wise
			else{ newTimestamp = moment(this.timestamp).add(30, 'minutes').valueOf(); }
			//if(newTimestamp > moment().add(3, 'hours').valueOf()){var e = "Max pin time is 3 hours"; Logging.warning(e); return $q.rejectErr(e); }
			this.timestamp = newTimestamp;
			this.validCheck(newTimestamp);
			this.resetTimer();
			this.$$notify();
			return this.$save();
		},
		"$destroy": function $destroy(){
			$firebaseObject.prototype.$destroy.apply(this, arguments);
			this._isValid = false;
		},
		"$$error": function $$error(e){
			//console.log("ERRRROOORRR", e);
		}
	});
}]);


