/**
 * @namespace CanvasWrapper
 * @desc The CanvasWrapper namespace contains classes which make interacting with a HTMLCanvasElement easier and with high performance. Effectively, they organize your drawing objects into hierarchical trees, with parent-child relationships.
 */
angular.module('CanvasWrapper').factory("Renderer", CanvasWrapperRenderer);

CanvasWrapperRenderer.$inject = ["PIXI", "Viewport", "Emitter"];
function CanvasWrapperRenderer(PIXI, Viewport, Emitter){
	/**
	 * @namespace Renderer
	 * @desc The core CanvasWrapper renderer
	 * @param canvas {string|canvas} The ID of the canvas to bind the renderer too OR the Canvas element itself
	 * @param renderOptions {Object} An object containing renderer settings.
	 * @memberOf CanvasWrapper
	 * @constructor
	 */
	var Renderer = function(canvas, renderOptions, viewportOptions){
		renderOptions = renderOptions || {};
		viewportOptions = viewportOptions || {};
		canvas = (typeof canvas === 'string') ? document.getElementById(canvas) : canvas;
		renderOptions["view"] = canvas;
		// Initiate the ticker and renderer
		/**
		 * @name app
		 * @desc An instance of {PIXI.Application}
		 * @memberOf CanvasWrapper.Renderer
		 */
		this.app = new PIXI.Application(renderOptions.width || 100, renderOptions.height || 100, renderOptions);
		/**
		 * @name renderer
		 * @desc An instance of the PIXI.Application.renderer
		 * @memberOf CanvasWrapper.Renderer
		 */
		this.renderer = this.app.renderer;
		/**
		 * @name stage
		 * @desc An instance of {PIXI.Application.stage}
		 * @memberOf CanvasWrapper.Renderer
		 * @type {PIXI.Container}
		 */
		this.stage = this.app.stage;
		/**
		 * @name view
		 * @desc An instance of {PIXI.Application.view}
		 * @type {HTMLCanvasElement}
		 * @memberOf CanvasWrapper.Renderer
		 */
		this.view = this.app.view;
		/**
		 * @name ticker
		 * @desc The main Renderer ticker
		 * @memberOf CanvasWrapper.Renderer
		 * @type {PIXI.ticker}
		 */
		this.ticker = this.app.ticker;
		// Set-up background color
		this.renderer.backgroundColor = parseInt(renderOptions.backgroundColor.substring(1), 16);

		viewportOptions["view"] = this.view;
		viewportOptions["renderer"] = this.renderer;
		viewportOptions["ticker"] = this.ticker;

		// Set-up the viewport
		/**
		 * @name viewport
		 * @desc An instance of {CanvasWrapper.Viewport}
		 * @type {CanvasWrapper.Viewport}
		 * @memberOf CanvasWrapper.Renderer
		 */
		this.viewport = new Viewport(viewportOptions);

		// Auto start?
		if (!renderOptions.autoStart){this.app.stop();}
		/**
		 * @function destroy
		 * @desc Destroys the renderer and flushes all textures from VRAM
		 * @memberOf CanvasWrapper.Renderer
		 * @param removeFromDOM
		 */
		this.destroy = function(removeFromDOM){
			this.app.destroy(removeFromDOM, {children: false, texture: false, baseTexture: false});
		};
		/**
		 * @function getTicker
		 * @desc returns {CanvasWrapper.Renderer.ticker}
		 * @memberOf CanvasWrapper.Renderer
		 * @returns {CanvasWrapper.Renderer.ticker}
		 */
		this.getTicker = function(){
			return this.ticker
		};
		/**
		 * @function getRenderer
		 * @desc Returns the {CanvasWrapper.Renderer.renderer
		 * @memberOf CanvasWrapper.Renderer
		 * @returns {CanvasWrapper.Renderer.renderer}
		 */
		this.getRenderer = function(){
			return this.getRenderer()
		};
		/**
		 * @function getStage
		 * @desc Returns the {CanvasWrapper.Renderer.stage}
		 * @memberOf CanvasWrapper.Renderer
		 * @returns {CanvasWrapper.Renderer.stage}
		 */
		this.getStage = function(){
			return this.stage
		};
		/**
		 * @function getView
		 * @desc Return the {CanvasWrapper.Renderer.view}
		 * @memberOf CanvasWrapper.Renderer
		 * @returns {CanvasWrapper.Renderer.view}
		 */
		this.getView = function(){
			return this.view
		};
		/**
		 * @function start
		 * @desc Starts the main renderer ticker
		 * @memberOf CanvasWrapper.Renderer
		 */
		this.start = function(){
			return this.app.start();
		};
		/**
		 * @function stop
		 * @desc Stops the main renderer ticker
		 * @memberOf CanvasWrapper.Renderer
		 */
		this.stop = function(){
			return this.app.stop();
		};
		/**
		 * @function getViewport
		 * @desc Returns the viewport instance
		 * @returns {CanvasWrapper.Viewport}
		 * @memberOf CanvasWrapper.Renderer
		 */
		this.getViewport = function(){
			return this.viewport;
		};
	};

	return Renderer;
}
