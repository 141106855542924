import { Injectable } from '@angular/core';
import { Router, ActivationEnd, Event, ParamMap} from '@angular/router';

import { filter, tap, map } from 'rxjs/operators';

import { User } from "ng2/common/models/User";
import { AuthService } from "ng2/common/ajs-upgrade-providers";

import {RouteUtils} from "ng2/common/services/route-utils.service";
 
@Injectable()
export class LegacyRouting {
	private paramMapSnapshot: ParamMap = {
		keys: [],
		has: ()=>{return false},
		get: ()=>{return null},
		getAll: ()=>{return []}
	};
	
	private queryMapSnapshot: ParamMap = {
		keys: [],
		has: ()=>{return false},
		get: ()=>{return null},
		getAll: ()=>{return []}
	};
	
	constructor(private router: Router, private routeUtils: RouteUtils){
		if(this.router.routerState.snapshot.root.firstChild){
			this.paramMapSnapshot = this.router.routerState.snapshot.root.firstChild.paramMap;
			this.queryMapSnapshot = this.router.routerState.snapshot.root.firstChild.queryParamMap;
		}
		// Some hacky shit here
		this.router.events.pipe(
			filter( (evt: Event): evt is ActivationEnd => evt instanceof ActivationEnd ),
			map((param:ActivationEnd)=>{
				return { paramMap: param.snapshot.paramMap, queryParamMap: param.snapshot.queryParamMap }
			})
		)
		.subscribe((param)=>{
			this.paramMapSnapshot = param.paramMap;
			this.queryMapSnapshot = param.queryParamMap;
		})
	}
	public go(basePath: string, ...rest){
		let root = "/";
		let commands = [root+basePath, ...rest]
		
		return this.router.navigate(commands);
	}
	public rawGo(commands: Array<string>){
		return this.router.navigate(commands);
	}
	public oldStyleSnapshot(){
		let obj:any = {};
		
		//do query params first so main ones override
		this.queryMapSnapshot.keys.forEach((key)=>{
			obj[key] = this.queryMapSnapshot.get(key);
		});
		
		this.paramMapSnapshot.keys.forEach((key)=>{
			obj[key] = this.paramMapSnapshot.get(key);
		});
		
		return obj;
	}
	
	public routeName(){
		return this.routeUtils.routeName();
	}
	
	
	public href(path: Array<string>){
		let tree = this.router.createUrlTree(path)
		//some hardcoded bs
		let str = "#!"+ tree.toString()
		return str;
	}

}
