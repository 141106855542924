'use strict';

import * as angular from "angular";
import * as utils from "utils";

angular.module('ticketspaceApp')

//### limitationEnforcer
// Encapsulates all the operations revolving around enforcing limitations.
// Currently only used by plans and roles.
// ##### Usage:
// * call refresh to set the projectId and load the corresponding data
// * call create to make instances
.factory('limitationEnforcer', ["fbConnection", function(fbConnection) {
	
	var globals:any = {};
	
	//#### refresh(projectId)
	// reloads the stored limits based on the passed in projectId
	function refresh(projectId){
		if(globals.projectId === projectId){ return; } //no need to update
		globals.projectId = projectId;
		globals.limits = fbConnection.fbObject('projectLimitations/'+projectId);
	}
	
	//#### create(data)
	// runs through the object.create flow so that a new object is created with passed in data
	// and has it's proto set to enforcer
	function create(data){
		var newObj = Object.create(virtualEnforcer);
		
		if(data){
			newObj.data = {};
			for(var key in data){
				newObj.data[key] = data[key];
			}
		}
		return newObj;
	}
	
	
	//### Virtual class
	
	//#### invalid(self, lite)
	//* returns true if everything is going to break, false otherwise
	//* lite flag bypasses the limits check
	function invalid(self, lite?){
		if(!self || !self.data){
			console.error('data isn\'t set, this class should not be called directly');
			return true;
		}
		if(lite){return false; }
		if(!globals.limits){ return true; }
		return false;
	}
	
	//####validateCount()
	//checks if the current limit count doesn't match the actual count.
	//Updates the limit count
	function validateCount(offset){
		if(invalid(this, true)){return;}
		var self = this;
		var count = offset ? offset : 0;
		if(this.data.localData.length !== undefined){ count = count + this.data.localData.length; }
		else{
			utils.$loop(this.data.localData, function(){count++;});
		}
		var remoteCount = fbConnection.fbObject('projectLimitations/'+globals.projectId+'/'+this.data.countKey);
		remoteCount.$loaded().then(function(){
			if(remoteCount.$value !== count){
				console.warn(self.data.countKey+ ' differs from count of plan');
				remoteCount.$value = count;
				remoteCount.$save();
			}
		});
	}
	
	//####increment(num)
	//* increments the limit count by num (default is 1)
	function increment(num){
		if(invalid(this, true)){return;}
		if(!num){ num = 1; }
		
		if(!isNaN(globals.limits[this.data.countKey])){
			//console.log('this.data.countKey', this.data.countKey);
			var ref = fbConnection.fbRef('projectLimitations/'+globals.projectId+'/'+this.data.countKey);
			fbConnection.set(ref, globals.limits[this.data.countKey] + num);
		}
	}
	
	//####decrement()
	//* convienence wrapper of increment for -1
	function decrement(){
		return this.increment(-1);
	}
	
	//####max()
	//* returns the max
	function max(){
		if(invalid(this)){return 0;}
		else if(globals.limits[this.data.maxKey]){
			return globals.limits[this.data.maxKey];
		}
		return 0;
	}
	
	//####count()
	//* returns the count
	function count(){
		if(invalid(this)){return 0;}
		return Number(globals.limits[this.data.countKey]);
	}
	
	function diff(){
		return this.max() - this.count();
	}
	
	function prettyPrintRatio(friendlyNameSingular, friendlyName){
		if(invalid(this)){return '';}
		
		var c = this.count();
		var m = this.max();
		var d = this.diff();
		
		friendlyName = c === 1 ? friendlyNameSingular : friendlyName;
		friendlyName = friendlyName || '';
		
		if(d > 0){ var diffString = '('+d+' spots remaining)'; }
		else if(d<0){ var diffString = '('+d*-1+' over limit)'; }
		else{ var diffString = ''; }
		
		var output = c + ' ' + friendlyName;
		if(m === 0){
			return output;
		}
		return output+' '+diffString;
	}
	
	function canAdd(){
		if(invalid(this)){return false;}
		var l = globals.limits;
		//console.log('this', this, this.max(), this.count());
		if(this.max() && this.count() && this.count() + 1 > this.max()){
			//Logging.warning('no more for you!');
			return false;
		}
		return true;
	}
	
	
	
	
	var virtualEnforcer =  {
		increment: increment,
		decrement: decrement,
		canAdd: canAdd,
		prettyPrintRatio: prettyPrintRatio,
		count: count,
		max: max,
		diff: diff,
		validateCount: validateCount
	};
	
	return {
		refresh: refresh,
		create: create
	};
	
	
}]);
