'use strict';
import * as angular from "angular";

angular.module('ticketspaceApp')
// Removed $rootScope
.factory('firebaseVariances', ["$q", "$log", "$firebaseUtils", "$firebaseArray", "accessCheck", "fbConnection", "popup", function($q, $log, $firebaseUtils, $firebaseArray, accessCheck, fbConnection, popup) {

	var theMax = 32;
	var styles = [
		"#5DA4DA",
		"#F25858",
		"#FAA43D",
		"#F27CB1",
		"#61BC67",
		"#DFCF40",
		"#B475B4",
		"#B2902E",
		"#2F7D99",
		"#4E4C4D",
		"#9BFFA3",
		"#F6F63A",
		"#6b156b",
		"#f3dcc0",
		"#d1ebff",
		"#ad0b0b"
	];
	
	
	function Variance(snap){
		this.$id = snap.key;
		this.local = {};
		this.update(snap);
	}
	Variance.prototype = {
		update: function(snap){
			//store the data on...data
			var oldData = this.data || {};
			
			this.data = snap.val();
			var changed = !angular.equals(this.data, oldData);
			this.local.prevData = oldData;
			return changed;
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		},
		displayName: function(){
			return this.data.varianceKey + '. ' + this.data.varianceReason;
		}
	};
	
	function VarianceFactory(ref){
		// call the super constructor
		this._usedStyles = {};
		var thing = $firebaseArray.call(this, ref);
		thing._usedStyles = this._usedStyles;
		thing.backgroundStyleList = styles;
		return thing;
	}
	VarianceFactory.prototype = {
		$$added: function(snap){
			$firebaseArray.prototype.$$added.call(this,snap);
			var v = new Variance(snap);
			this.updateUsedStyles('add', snap);
			return v;
		},
		$$updated: function(snap){
			var rec = this.$getRecord(snap.key);
			var changed = rec.update(snap);
			this.updateUsedStyles('change', snap, rec.local.prevData.varianceColor);
			
			return changed;
		},
		$$removed: function(snap){
			this.updateUsedStyles('remove', snap, snap);
			return this.$getRecord(snap.key);
		},
		$add: function(data){
			var self = this;
			var args = arguments;
			
			if(this.$list.length >= theMax-1){
				return $q.rejectErr("Please contact Customer Support to add this variance");
			}
			
			//data.varianceColor = this.nextStyle();
			
			if(!data || !data.varianceReason){
				return $q.rejectErr("Reason is required.");
			}
			return this.getNextKey().then(function(key){
				data.varianceKey = key;
				data.varianceColor = self.nextStyle(key);
				
				return $firebaseArray.prototype.$add.apply(self,args);
			});
		},
		$remove: function(idx){
			return $firebaseArray.prototype.$remove.apply(this,arguments);
		},
		confirmRemove: function(idx){
			var self = this;
			var args = arguments;
			return popup({title: "Are you sure you want to delete this variance reason?"}).then(function(){
				return self.$remove.apply(self, args);
			});
		},
		getNextKey: function(){
			var self = this;
			return new $q(function(resolve, reject){
				self.$list.counter.$ref().transaction(function(currentVal){
					return (currentVal||0) + 1;
				}, function(error, committed, snap){
					if(error){reject(error);}
					else if(committed){
						resolve(snap.val());
					}
				});
			});
		},
		set: function(idx, data){
			//console.log('setting', idx, data);
			var item = this.$list[idx];
			if(!item){return $q.rejectErr("item doesn't exist");}
			if(!data || !data.varianceReason || !data.varianceColor){
				return $q.rejectErr("name and style is required");
			}
			item.data.varianceReason = data.varianceReason;
			item.data.varianceColor = data.varianceColor;
			return this.$save(idx);
		},
		updateUsedStyles: updateUsedStyles,
		nextStyle: nextStyle,
		cloneForEdit: cloneForEdit
	};
	
	
	//lifted out of roles.js, could probably stand to be modernized
	function updateUsedStyles(action, snap, oldVal){
		var self = this;
		if(snap.exists()){
			
			var style = snap.child('varianceColor').val();
			if(action === 'add' && style){
				add(style);
			}
			else if(action === 'remove' && style){
				remove(style);
			}
			else if(action === 'change'){
				var oldStyle = oldVal;
				if(oldStyle !== style){
					add(style); remove(oldStyle);
				}
			}
		}
		
		//console.log(action, this._usedStyles, style, oldVal);
		
		function add(style){
			if(!style){return;}
			if(!self._usedStyles[style]){self._usedStyles[style] = 1;}
			else{ self._usedStyles[style]++;}
		}
		function remove(style){
			if(!style){return;}
			if(self._usedStyles[style] === 1){delete self._usedStyles[style];}
			else{ self._usedStyles[style]--;}
		}
	}
	function nextStyle(key){
		return styles[key % styles.length];
		// var options = styles;
		// var self = this;
		// var match;
		// 
		// options.every(function(style){
		// 	match = style;
		// 	return self.$list.some(function(v){
		// 		if(v.data.varianceColor === style){ return true; }
		// 		return false;
		// 	});
		// });
		// return match;
	}
	
	function cloneForEdit(index, collection){
		if(index === -1){
			return { 	varianceColor: collection.nextStyle() };
		}
		if(index !== -1 && collection[index] !== undefined){
			var obj = angular.extend({}, collection[index].data);
			return obj;
		}
		return {};
	}
	
	var eFactory = $firebaseArray.$extend(VarianceFactory);
	
	return function(refUrl){
		var sync = new eFactory(fbConnection.fbRef(refUrl));
		var counter = fbConnection.fbObject(fbConnection.fbRef(refUrl).ref.parent.child('varianceCount'));
		sync.counter = counter;
		
		//init the counter
		if(accessCheck.isAdmin()){
			$q.all([sync.$loaded(), counter.$loaded()]).then(function(){
				if(counter.$value === null && sync.length){
					counter.$ref().transaction(function(data){
						return sync.reduce(function(oldThing, newThing){
							return newThing.data.varianceKey > oldThing.data.varianceKey ? newThing : oldThing;
						}).data.varianceKey;
					});
				}
			});
		}
		
		return sync;
	}
}]);
