import { SimpleAttributeList } from "./SimpleAttribute";

/**
 * # Usage flow:
 * - implement the views you wish to use in the child class, along with the enum and view interface
 * - initialize by constructing, setting the attribute list, than calling databaseUpdate (along with subscribing for more database updates in whatever way)
 * - when changing the local data call localUpdate with the changed data (undefined keys are ignored, nulls mean remove)
 * - when working with something involving many local changes you'll want to change views at the beginning of the operation, use localUpdate during, then change back at the end
 * - after this, use generateDatabaseOuput to create a firebase writing object representing the new data
 *
 * When adding custom behavior override and (usually) super the public methods
 */
export abstract class SplitData<Fields, RenderType extends string>{
	public view: Fields = {} as Fields;
	public primaryType: RenderType; //can probably do better here
	public currentRenderType: RenderType;
	protected abstract attributeList: SimpleAttributeList;
	
	constructor(public $id: string, primaryType: RenderType){
		this.primaryType = this.currentRenderType = primaryType;
		
	}
	
	/**
	 * An update specifically intended to operate on the primaryType, will apply a the database -> local data transformations. 
 	Might be better to simply have a flag for applying database transformations
	 * @param  data The object containing the data.
	 */
	public databaseUpdate(data:Object){
		this[this.primaryType as string] = data;
		
		//reset this because primary "probably" is a new object each time. Consider creating a "rawPrimary"
		// to track that instead and maintain a "same object" thing for primary.
		// Could also do that without the raw one, depends on need.
		if(this.currentRenderType === this.primaryType){ this.view = this[this.primaryType as string]; }
	}
	
	/**
	 * Apply local udpates (to the current view generally)
	 * @param  data       Data that should be changed
	 * @param  currentRenderType Override the render type, defaults to current
	 */
	public localUpdate(data:Object, renderType?: RenderType){
		renderType = this.defType(renderType);
		let view = this[renderType as string];

		for( var key in this.attributeList ){
			if(data[key] !== undefined){ view[key] = data[key]; }
		}
	}
	
	/**
	 * Copys data from the current renderType to a new renderType
	 * @param  renderType   The renderType to change to
	 * @param  cancel=false If true, don't copy data and just change the type. This effectively throws out any changes made in that view
	 * @param targetRenderOverride	In case you want to copy the data from a different view for whatever reason
	 */
	public switchViews(renderType: RenderType, cancel = false, targetRenderOverride?: RenderType){
		if(!targetRenderOverride){ targetRenderOverride = this.currentRenderType; }
		if(renderType === targetRenderOverride){ return; }
		if(!cancel){
			this.copyDataBetweenViews(targetRenderOverride, renderType);
		}
		this.currentRenderType = renderType;
		this.view = this[renderType as string];
	}
	
	/**
	 * Convert a view into database data to be returned and written
	 * @param  renderType Type, defaults to the database instead of the current type
	 * @return            An object that is the database representation of the current state of the view. Use to persist changes.
	 */
	public generateDatabaseOutput(renderType?: RenderType){
		if(!renderType){ renderType = this.primaryType; }
		let view = this[renderType as string];
		let writeObj = {};
		
		for( var key in this.attributeList ){
			if(view[key] !== undefined && !this.attributeList[key].noWrite){ writeObj[key] = view[key]; }
		}
		
		return writeObj;
	}
	
	/** does the actual work of copying data between views */
	protected copyDataBetweenViews(srcRenderType: RenderType, targetRenderType: RenderType){
		let srcView = this[srcRenderType as string];
		let targetView = this[targetRenderType as string];
		
		for( var key in this.attributeList ){
			if(srcView[key] !== undefined && !this.attributeList[key].ignoreLocal){ targetView[key] = srcView[key]; }
		}
	}
	
	/** slightly abstract the "override render or use default" thing */
	protected defType(renderType?: RenderType){
		return renderType || this.currentRenderType;
	}
}
