import { DataContainerManager, TicketContainer } from "./";
import { Container, utils } from "pixi.js";
import { LayerManager } from "../layers/";
import { Subject, Observable } from "rxjs";
import { PlanState } from "../../common/services/plan-state.service";
import { APPLICATION_EVENTS } from "../core/";

import { FancyTicketAction, TicketActionTypes } from "ng2/actions/ticket-actions";
/**
 * This class handles the mapping of ticket data to parent DataContainer. Further, it exposes an RxJS Subject to push the changed DataContainer to listening Observers
 */
export class TicketMapper extends DataContainerManager{
	public firstDraw$: Subject<any> = new Subject();
	/**
	 * Instance of a EventEmitter3. Used internally by PIXI to handle events.
	 */
	public emitter: utils.EventEmitter = new utils.EventEmitter();
	/**
	 * Stores the reference of the ticket root node PIXI.Container. All tickets will be a child to this node in the scene graph.
	 */
	private _ticketParent: Container;
	/**
	 * Stores the reference to the layer manager.
	 */
	private _layerManager: LayerManager;
	private _firstTicketDraw: boolean = false;
	/**
	 * @param {PlanState} planState
	 * @param {LayerManager} layerManager instance of layer manager
	 * @param {PIXI.Container | FancyContainer} _ticketParent
	 * @param {Observable<any>} ticketObservable$
	 */
	constructor(planState: PlanState, layerManager: LayerManager, _ticketParent: Container, ticketObservable$: Observable<any>){
		super(planState);
		this._ticketParent = _ticketParent;
		this._layerManager = layerManager;
		this.observe(ticketObservable$);
	}
	/**
	 * Creates a new ticket and then calls the renderer, which renders the first ticket pass.
	 * @param ticketData The new ticket data
	 */
	private createNewTicket(ticketData){
		let ticket = ticketData.payload;
		return this.createNode(ticketData.key, this._ticketParent, ticket);
	}
	/**
	 * Helper method which dispatches the updateData event.
	 * @param payload
	 * @param {DataContainer} dataContainer
	 * @returns TicketMapper
	 */
	private emitUpdateData(payload, dataContainer: TicketContainer){
		if (!dataContainer){return}
		dataContainer.assign(payload);
		dataContainer.updateData(this.planState, dataContainer);
		this.emitter.emit(APPLICATION_EVENTS.UPDATE_DATA, this.planState, dataContainer);
		return this
	}
	/**
	 * Updates the data container associated with the ticket data that was updated.
	 * @param ticketArray
	 */
	public updateData = (ticketArray) => {
		let dataContainer :TicketContainer;
		if (!this._firstTicketDraw){
			window.perf.log("start first ticket draw");
		}
		ticketArray.forEach((ticketData) => {
			// New ticket added. Create a new data container
			if(this.planState.tickets.isAddAction(ticketData)){
				dataContainer = this.createNewTicket(ticketData);
				dataContainer.updateData(this.planState, dataContainer);
				this.emitter.emit(APPLICATION_EVENTS.UPDATE_DATA, this.planState, dataContainer);
			}
			// Data removed. Dispatch destroy event and clean-up destroyed tickets
			else if(this.planState.tickets.isRemoveAction(ticketData)){
				dataContainer = this.mapping.get(ticketData.key);
				//this.emitter.emit(APPLICATION_EVENTS.DESTROY, dataContainer, {children: true, texture: false, baseTexture: false});
				//this._layerManager.removeGroup(ticketData.key + "_GROUP");
				this.mapping.remove(ticketData.key);
				this.emitter.emit(APPLICATION_EVENTS.DESTROY_TICKET, this.planState, dataContainer);
				dataContainer.destroy({children: true, texture: false, baseTexture: false});
			}
			//the drag start event, rearrange tickets (need a special override since a selection can be done without interacting)
			else if(ticketData.type === TicketActionTypes.ticketChangedToDrag){
				dataContainer = this.mapping.get(ticketData.key);
				dataContainer.cutInLine();
				this.emitUpdateData(ticketData.payload, dataContainer);
			}
			// Existing ticket data changed. Update assigned data ad dispatch updateData event
			else{
				this.emitUpdateData(ticketData.payload, this.mapping.get(ticketData.key));
			}
		});
		
		if (ticketArray.length >= 1){
			// Dispatches an event telling the main renderer to re-draw.
			this.emitter.emit(APPLICATION_EVENTS.DRAW);
		}
		
		if (!this._firstTicketDraw){
			this.firstDraw$.next();
			this._firstTicketDraw = true;
			window.perf.log("end first ticket draw",  {previousEventName: "start first ticket draw"});
		}
	};
	/**
	 * Dispatches an update event on all data containers.
	 */
	public update = () => {
		let cache = this.mapping.getCache();
		cache.forEach((dataContainer: TicketContainer) => {
			dataContainer.update(this.planState, dataContainer);
		});
	};
	
	public destroy(){
		//TODO: DO CLEAN-UP
		this._ticketParent = null;
		this._layerManager = null;
		// debugger;
		this.emitter.emit(APPLICATION_EVENTS.DESTROY);
		super.destroy();
	}
}
