import { ComponentInterface } from "../../interfaces/";
import { PlanState } from "../../../common/services/plan-state.service";
import { TicketContainer } from "../../data-mappers/";
import { Component } from "../../component-system/";

import * as strings from "ng2/common/strings";

import * as utils from "utils";

export class TicketOpenEdit extends Component implements ComponentInterface{
	constructor(){super();}
	/**
	 * The setup method is called once all properties on a component have been decorated by the ComponentManager.
	 *
	 * From this point on, you can safely use this.getParent() and this.getManager().
	 *
	 * Arguments passed into setup are passed in via the ComponentManager.add(name, component, ...args)
	 * @param {PlanState} planState
	 * @param {TicketContainer} dataContainer
	 */
	public setup(planState: PlanState, dataContainer: TicketContainer): void{
		let parent = this.getParent();
		parent.interactive = true;
		parent.cursor = "pointer";
		
		const singleTap = (event)=>{
			dataContainer.cutInLine();
			//planState.actions.changeTicketViewToEdit(dataContainer.getData());
			
			let tappedTicket = dataContainer.getData();
			//TODO - break this divide into seperate classes
			if(planState.dependencies.syncMode && planState.dependencies.syncMode.target){
				let syncTicket = planState.dependencies.checkTicketCache(planState.dependencies.syncMode.target);
				if(!planState.accessCheck.hasAccessToTicket2(syncTicket)){
					Logging.warning(strings.NON_ADMIN_CANNOT_SET_DEPENDENCY);
					return;
				}
				planState.actions.simpleDependencyToggle(tappedTicket);
			}
			else{
				if(Zone.current.name !== "angular"){
					window.reporting.reportRealWarning("Zone is outside angular zone, things will be slow");
				}
				utils.trackTicketTapping.start('tap ticket detected', {startingZone: Zone.current.name});
				planState.angularZone.run(()=>{
					utils.trackTicketTapping.log('tap ticket (zone knows)');
					planState.legacyActions.openEditMode([tappedTicket]);
				})
				// Zone.current.run(()=>{
				// 	console.log('zone run', Zone.current.name);
				// 	utils.trackTicketTapping.log('tap ticket (zone knows)');
				// });
				
			}
		};
		
		parent.on('hammer-singletap', singleTap);
	}
	/**
	 * Destroy shit or else
	 */
	public destroy(){
		let parent = this.getParent();
		parent.off('hammer-singletap');
	}
}
