import { PlanState } from "../../../common/services/plan-state.service";
import { dateCache } from "../../../common/date-cache";
import { LaborCount } from "../../../common/models/LaborCount";

export class LaborQuery {
	public data: Map<string, LaborCount>;
	constructor(public planState: PlanState) {
	}
	
	public setData(data: Map<string, LaborCount>){
		this.data = data;
	}
	
	public getRange(start: string, end: string, inclusive: "()"|"[)"|"(]"|"[]" = "()"): Map<string, LaborCount>{
		let result = new Map();
		const startDate = dateCache.getMoment(start);
		const endDate = dateCache.getMoment(end);
		if (!this.data){return result}
		this.data.forEach((labor, key) => {
			let date = dateCache.getMoment(key);
			if (date.isBetween(startDate, endDate, null, inclusive)){
				result.set(key, labor);
			}
		});
		return result;
	}
	
	public getDay(day: string): LaborCount {
		if (!this.data){return null}
		return this.data.get(day);
	}
	
	public destroy(): void {
		this.data.clear();
		this.data = null;
	}
}