
/**
* API convention:
* 
* This component is meant to be embedded in a parent component that has
* a parent form.  It's expected that the parent passes it's FormGroup
* reference in as an Input to this component.  Further, the parent must
* also configure the form control as part of creating the form group,
* specicifically referring to this component with the name "description",
* and supplying a required validator.  The parent can optionally supply an
* initial text value too.
* 
*/

import { Component,
		 Input, 
		 Output,
		 EventEmitter } from '@angular/core';
import { OnInit } from '@angular/core';

import { EditTicketsCombined } from 'ng2/common/models/EditTicketsCombined';
import { TicketType } from './TicketType';

/**
 * Displays a thumbnail of the selected ticket type when multiple
 * tickets are editted.  Provides a menu to switch between types and
 * to update the form data when the type changes.
 *
 */
@Component({
	selector: 'multi-edit-thumbnail',
	template: require('./multi-edit-thumbnail.component.html'),
})
export class MultiEditThumbnail {

	/**
	 * @input an ETC
	 */
	@Input() etc: EditTicketsCombined;

	/**
	 * @Outputs a type changed event to signal the parent that the containers
	 * needs updating to the new type.
	 */
	@Output() public typeChanged: EventEmitter<string> = new EventEmitter<string>();

	isMenuShown:boolean = false;

	// Compute a style property based on currentType
	get ticketStyle() {
		// return 'multi-edit-ticket-thumbnail' for all types, and optionally
		// append a second type specific class for milestones and
		// constraints
		var requiredString = 'multi-edit-ticket-thumbnail';
		var optionalString = !(this.etc.currentType === TicketType.Task) ? ' multi-edit-ticket-thumbnail--' + this.etc.currentType : '';
		var optionalBorder = this.isMenuShown ? ' multi-edit-ticket-thumbnail-active' : ''
		return requiredString + optionalString + optionalBorder;
	}

	// Going to need some kind of getMenuTypeStyle too.  Awaiting class
	// structure details from Chris
	getMenuTypeStyle(type: string) {  // This should be TicketType
		// return 'ticket-edit-all-thumbnail-icon-small' for all types, and optionally
		// append a second type specific class for milestones and
		// constraints
		var requiredString = 'ticket-edit-all-thumbnail-icon-small';
		var optionalString = !(type === TicketType.Task) ? ' ticket-edit-all-thumbnail-icon-small--' + type : '';
		return requiredString + optionalString;
	}

	// Pretty up the type string, return it in Title case with an 's' if count > 1
	getMenuTypeFormatted(type: string) {
		var optionalS = this.etc.typesInCollection.get(type).count > 1 ? 's' : '';
		return type.charAt(0).toUpperCase() + type.slice(1) + optionalS;
	}

	// Toggles the isMenuShown property
	toggleMenu() {
		this.isMenuShown = !this.isMenuShown;
	}

	setCurrentType(type: string) {
		this.etc.currentType = type;
		this.isMenuShown = false;
		// Need to freshen up the form in the parent
		this.typeChanged.emit(type);
	}

	// Returns etc.typesInCollection.keys() as an array sorted in the type pecking 
	// order Tasks -> Constraints -> Milestones.  
	getSortedKeys() {
		var sortedKeys: string[] = [];
		for (const key of this.etc.typesInCollection.keys()) {
			sortedKeys.push(key);
		}
		sortedKeys.sort(function(a,b) {
			const peckingOrder = ['task', 'constraint', 'milestone'];
			return peckingOrder.indexOf(a) - peckingOrder.indexOf(b);
		});
		return sortedKeys;
	}

}
