import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import {database} from 'firebase/app';
import { BehaviorSubject, Observable, empty, from } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators';
import * as moment from "moment";

import {TicketList, PullColumnList} from "ng2/fancy-firebase/factories";
import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyJoinRequirements } from 'ng2/fancy-firebase/base';
import {TicketActionTypes, FancyTicketAction} from 'ng2/actions/ticket-actions';
import {FancySupplementalAction, SupplementalActionTypes} from 'ng2/actions/supplemental-actions';

import { CalendarWorkdays } from "ng2/common/models/CalendarWorkdays";

export class TimelineTicketList extends TicketList{
	public projectRef: database.Reference;
	
	constructor(protected angularLand, path, fancyJoins, protected workdays:CalendarWorkdays, protected pullColumnList: PullColumnList){
		super(angularLand, path, fancyJoins, workdays);
		this.suppressActionsForLoading = false;
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		let ticket = super.$$added(action);
		ticket.isTimelineTicket = true;
		ticket.pullColumnList = this.pullColumnList;
		ticket.timelineX = this.pullColumnList.getXFromDate(ticket.view.liveFinish);
		ticket.timelineTicketSplitId = this.spiltId(action.key);
		return ticket;
	}
	
	$$removed(t){
		// t is undefined, so passing undefined to the parent, which is TicketList.ts, just causing this to break in TicketList.ts instead of here.
		// So going to remove the super.$$removed call, since we have no references to cleanup (Ticket is already undefined)
		// if(!t){ super.$$removed(t); return; }
		if(!t){ return; }
		//t.timelineTicketSplitId = null;
		t.timelineX = null;
		t.pullColumnList = null;
		super.$$removed(t);
	}
	
	handleSupplementalEvents(baseObservable):Observable<any>{
		//there's only one, so don't bother with the action bs
		return baseObservable
		.pipe(
			mergeMap((action:FancySupplementalAction) =>{
				let emits = [];
				this._internalList.forEach((ticket)=>{
					let x = this.pullColumnList.getXFromDate(ticket.view.liveFinish);
					if(x !== ticket.timelineX){
						ticket.timelineX = x;
						emits.push(new FancyTicketAction(ticket, TicketActionTypes.ticketPlanDataUpdated))
					}
				});
				return from(emits);
			})
		)
	}
	
	protected getTicketRef(ticketId){
		let splitId = this.spiltId(ticketId);
		return this.projectRef.child(splitId[0]).child(splitId[1]);
	}
	
	private spiltId(combinedId: string){
		return combinedId.split(',') as [string, string];
	}
	
	destroy(){
		this.workdays = null;
		this.pullColumnList = null;
		super.destroy();
	}
}
