'use strict';

import * as angular from "angular";
import { LegacyRouting } from "ng2/routes/legacy-routing.service";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

angular.module('ticketspaceApp')
.factory('firebaseLocations', ["$q", "$firebaseUtils", "$firebaseArray", "fbConnection", "popup", "legacyRouting", function($q, $firebaseUtils, $firebaseArray, fbConnection, popup, legacyRouting: LegacyRouting) {
	function Location(snap){
		this.$id = snap.key;
		this.local = {};
		this.update(snap);
	}
	Location.prototype = {
		update: function(snap){
			//store the data on...data
			var oldData = this.data || {};
			this.data = snap.val();
			//var changed = !angular.equals(this.data, oldData);
			this.prevData = snap.val();
			return true;
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		}
	};
	
	//Array instance section
	function LocationsFactory(ref, locationColors){
		// call the super constructor
		this.locationColors = locationColors;
		this._usedStyles = this.locationColors.getUsedColors();
		this.colorPalette = this.locationColors.getColorPalette();
		var thing = $firebaseArray.call(this, ref);
		thing._usedStyles = this._usedStyles;
		thing.locationColors = locationColors;
		thing.colorPalette = this.colorPalette;
		return thing;
	}
	LocationsFactory.prototype = {
		$$added: function(snap){
			var style = snap.child('locationColor').val();
			$firebaseArray.prototype.$$added.call(this,snap);
			var t = new Location(snap);
			t.$parent = this.$list;
			this.locationColors.updateUsedColors('add', style);
			return t;
		},
		$$updated: function(snap){
			var t = this.$getRecord(snap.key);
			var colorId = snap.child('locationColor').val();
			var color = this.locationColors.$getRecord(colorId);
			var oldColor = this.locationColors.$getRecord(t.prevData.locationColor);
			if (color && oldColor){this.locationColors.updateUsedColors('change', color.data.color, oldColor.data.color);}
			else {
				// MOC-2489
				this.locationColors.updateUsedColors('add', colorId);
			}
			t.update(snap);
		},
		$$removed: function(snap){
			var t = this.$getRecord(snap.key);
			var style = snap.child('locationColor').val();
			t.$parent = null;
			this.locationColors.updateUsedColors('remove', style);
			return t;
		},
		$add: function(data){
			if(!data || !data.name || !data.locationColor){
				return $q.rejectErr("name and style is required");
			}
			return $firebaseArray.prototype.$add.apply(this,arguments)
			.then(function(ref){
				analyticsService.createdALocation(ref.key, legacyRouting.oldStyleSnapshot().planId, data.name, data.locationColor);
				return ref;
			});
		},
		$remove: function(idx){
			if(this.$list[idx]){
				var rec = this.$list[idx];
				analyticsService.deletedALocation(rec.$id, legacyRouting.oldStyleSnapshot().planId, rec.data.name);
			}
			return $firebaseArray.prototype.$remove.apply(this,arguments);
		},
		confirmRemove: function(idx){
			var self = this;
			var args = arguments;
			return popup({title: "Are you sure you want to delete this location?"}).then(function(){
				return self.$remove.apply(self, args);
			});
		},
		$childRef: function(childId){
			return this.$ref().child(childId).ref;
		},
		editableListAdd: function(data){
			if(!data || !data.name || !data.locationColor){return $q.rejectErr("Location must have a name and color!");}
			var self = this;
			return this.locationColors.handleAddColor(data.locationColor)
				.then(function(record){
					data.locationColor = record.$id;
					return self.$list.$add.call(self.$list, data)
				})
				.then(function(snap){
					return snap;
				})
		},
		set: function(idx, data){
			var self = this;
			var item = this.$list[idx];
			if(!item){return $q.rejectErr("item doesn't exist");}
			if(!data || !data.name || !data.locationColor){
				return $q.rejectErr("Location name and color is required");
			}
			item.data.name = data.name;
			var color = data.locationColor;
			var newRec = this.locationColors.getRecordByColor(color);
			item.data.locationColor = newRec.$id;
			return self.$save(idx);
		},
		nextStyle: nextStyle,
		cloneForEdit: cloneForEdit
	};
	var theFactory = $firebaseArray.$extend(LocationsFactory);

	function nextStyle(){
		return this.locationColors.getFirstUnusedColor();
	}
	
	function cloneForEdit(index, collection){
		if(index === -1){
			return { locationColor: collection.nextStyle() };
		}
		if(index !== -1 && collection[index] !== undefined){
			var obj = angular.extend({}, collection[index].data);
			if(obj.locationColor){obj.locationColor = this.locationColors.getColor(obj.locationColor);}
			return obj;
		}
		return {};
	}
	
	
	function returnFunction(refUrl, locationColors){
		return new theFactory(fbConnection.fbRef(refUrl), locationColors);
	}
	//add properties to the returnFunction for static code
	
	return returnFunction;
}]);
