import {FancyAction, FancyFirebaseList} from "ng2/fancy-firebase/base";

import * as utils from "utils";
import { ListLike } from "utils";
import { Swimlane, SwimlaneRenderTypes } from "ng2/common/models/Swimlane";

import { FancyActionableFirebase, DefaultActionTypes, hasId } from "ng2/fancy-firebase/generics";




import { SwimlaneList } from "ng2/fancy-firebase/factories";



//decide how to implement the generic of this
// - the action calling side
// - the action handling side in the firebase list
// - List vs Object

// Action builder side:
// - for each type, need a function that accepts something to identify the list item(s) and arbitrary data to be used/ forwarded
// - that function will create the action and push it onto the list
// 
// Action handling side:
// - need to handle a thing in various way depending on the type of that thing
// - want default handling of that thing that can be overriden by the children for specific types

// type CombinedType = AddItemAction | RemoveItemAction | LocalUpdateAction;
// 
// interface AddItemAction extends FancyAction{}
// interface RemoveItemAction extends FancyAction{}
// interface LocalUpdateAction extends FancyAction{}











export class Swimlanes extends FancyActionableFirebase<Swimlane, FancyAction, SwimlaneList> {
	constructor(public list: SwimlaneList){
		super(list);
	}
	
	destroy(){
		
	}
}
