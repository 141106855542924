'use strict';
import * as angular from "angular";

angular.module('ticketspaceApp')
.factory('firebaseIndex', ["$q", "fbConnection", "$timeout", "$firebaseUtils", "$firebaseArray", "$firebaseObject", function($q, fbConnection, $timeout, $firebaseUtils, $firebaseArray, $firebaseObject) {
	//Array instance section
	function IndexFactory(indexRef, dataRef, indexKey){
		this._baseDataRef = dataRef;
		this.keyToIndex = indexKey || "$id";
		var thing = $firebaseArray.call(this, indexRef);
		
		return thing;
	}
	IndexFactory.prototype = {
		$dataRef: function(){
			return this._baseDataRef;
		},
		$$added: function(snap, prevChild){
			return this.getNewData(snap);
		},
		//lifecycle method to override how the child is acquired
		$$getDataRef: function(idxSnap){
			if(idxSnap._dataRef){ return idxSnap._dataRef; }
			return this._baseDataRef.child(idxSnap.key);
		},
		// MOC-2679
		$save: function(indexOrItem, doUpdate: boolean = false){
			var self = this;
			var item = self._resolveItem(indexOrItem);
			var key = self.$keyAt(item);
			var def = $q.defer();
			
			var outerZone = Zone.current;
			
			if( key !== null ) {
				var ref = self.$$getDataRef(item);
				var dataJSON;

				try {
					dataJSON = (!doUpdate) ? $firebaseUtils.toJSON(item) : $firebaseUtils.toJSON(indexOrItem);
					console.log("Data json", dataJSON);
				} catch (err) {
					def.reject(err);
					console.log("Err", err );
				}
				if (typeof dataJSON !== 'undefined') {
					if (doUpdate){
						fbConnection.update(ref, dataJSON).then($firebaseUtils.runInZone(outerZone, function() {
							self.$$notify('child_changed', key);
							def.resolve(ref);
						})).catch($firebaseUtils.runInZone(outerZone, def.reject));
					}else {
						$firebaseUtils.doSet(ref, dataJSON).then($firebaseUtils.runInZone(outerZone, function() {
							self.$$notify('child_changed', key);
							def.resolve(ref);
						})).catch($firebaseUtils.runInZone(outerZone, def.reject));
					}
				}
			}
			else {
				def.reject('Invalid record; could not determine key for '+indexOrItem);
			}

			return def.promise;
		},
		//required keys:
		//$id
		//$indexData - maybe don't use this at all and rely entirely on $id
		$$addedIdx: function(snap, idxSnap){
			var thing = snap.val();
			if( !angular.isObject(thing) ) {
				thing = { $value: thing };
			}
			thing.$id = idxSnap.key;
			thing.$indexData = idxSnap.val();
			//console.log('thing', thing);
			return thing;
		},
		$$updated: function(snap){
			var self = this;
			//console.log('!!!', snap.key);
			//the key has been updated, in theory if there's any hit here, 
			//it should be destroyed and recreated at the new spot,
			//since a key === value parity is assumed. Making this method effectively pointless
			var oldData = this.$getRecord(snap.key);
			var newData = snap.val();
			//console.log('old', oldData, 'new', newData);
			if(!angular.equals(oldData, newData)){
				//off the old data callback
				oldData._cleanUp();
				//call $$removedIdx (also create that method and define it's purpose)
				this.$$removedIdx(snap);
				//create a new on callback to the data and call $$addedIdx
				return this.getNewData(snap).then(function(newObj){
					//console.log('oldData', oldData, 'newData', newObj);
					var idx = self.$indexFor(snap.key); //<--- continue here
					//console.log('idx', idx);
					if(idx !== -1){
						//console.log('replacing');
						self.$list[idx] = newObj;
					}
				});
			}
			//so...no-oping it
			return false;
		},
		$$updatedIdx: function(snap){
			var rec = this.$getRecord(snap.key);
			var changed = false;
			if(rec){
				changed = $firebaseUtils.updateRec(rec, snap);
				$firebaseUtils.applyDefaults(rec, this.$$defaults);
			}
			return changed;
		},
		$$removed: function(snap){
			//console.log('$$removed', snap.key, snap.val());
			var abort = this.$$removedIdx(snap);
			if(abort){return !abort;}
			var rec = this.$getRecord(snap.key);
			//console.log('$$removed rec', rec);
			if(rec){rec._cleanUp();}
			return $firebaseArray.prototype.$$removed.call(this,snap);
		},
		$$removedIdx: function(snap){
			// var rec = this.$getRecord(snap.key);
			// if(rec){rec._cleanUp();}
			// return $firebaseArray.prototype.$$removed.call(this,snap);
		},
		$destroy: function(){
			if(this.$list){
				this.$list.forEach(function(l){
					l._cleanUp();
				});
			}
		},
		getNewData: function(idxSnap){
			//todo - implement this function to create a new on listener
			// then get the rest of $$update working
			// then go test it.
			
			var self = this;
			var child = this.$$getDataRef(idxSnap);
			var hasFirsted = false;
			var defer = $q.defer();
			
			//console.log('index, get new data', idxSnap.val(), hasFirsted);
			
			// //consider ripping this out of here so it's not constantly re-declared
			var theCb = child.on('value', function(dataSnap){
				
				//console.log('index, get new data on cb', idxSnap.val(), dataSnap.val(), hasFirsted);
				
				//$$addedIdx
				if(!hasFirsted){
					var newObj = this.$$addedIdx(dataSnap, idxSnap) || {};
					//consider moving this to an internal parallel so it's not public
					newObj._cleanUp = function(){
						child.off('value', theCb, self);
					}
					hasFirsted = true;
					newObj._debugIdxData = idxSnap.val();
					newObj._dataRef = child;
					//console.log('index added', newObj, newObj._debugIdxData);
					defer.resolve(newObj);
				}
				//$$updatedIdx
				else{
					$timeout(function(){
						//in theory if the index record doesn't exist it's already been removed
						// so the update call can be safely suppressed
						if(!self.$getRecord(idxSnap.key)){return;}
						var changed = self.$$updatedIdx(dataSnap, idxSnap);
						if(changed){self.$$notify();}
					});
				}
			}, this);
			
			return defer.promise;
		}
	};
	var theFactory = $firebaseArray.$extend(IndexFactory);
	theFactory.$extend = function(ChildClass, methods){
		if( arguments.length === 1 && angular.isObject(ChildClass) ) {
			methods = ChildClass;
			ChildClass = function(ref) {
				if( !(this instanceof ChildClass) ) {
					return new ChildClass(ref);
				}
				theFactory.apply(this, arguments);
				return this.$list;
			};
		}
		return $firebaseUtils.inherit(ChildClass, theFactory, methods);
	}
	
	return theFactory;
	/*
	function returnFunction(refUrl, idxUrl){
		var temp = new theFactory(fbConnection.fbRef(refUrl), fbConnection.fbRef(idxUrl));
		return temp;
	}
	//console.log('theFactory', theFactory.$extend, $firebaseArray.prototype);
	
	return returnFunction;
	*/
}]);
