'use strict';

import * as angular from "angular";
import * as moment from "moment";

angular.module('ticketspaceApp')


//TODO
// - -canLock property-
// - -restore tickets in region gathering when locking (rip off select function)-
// - restore pre-promise snapshotting?
// - -add ticket pinning-
// - add pin editing ui
// - optimization (update only when necessary, $notify/ update before saving, etc...)

.factory('firebasePromisePeriod3', ["$rootScope", "$firebaseObject", "fbConnection", "activeColumnService", "backgroundAreaService", function($rootScope, $firebaseObject, fbConnection, activeColumnService, backgroundAreaService) {
	var enableLog = false;
	function log(...args){
		if(!enableLog){return;}
		console.log.apply(undefined, args);
	}
	
	//------------------------------------
	// Private api
	//-----------------------------------
	function _getCurrentPeriod(snap){
		if(!snap.child('promisePeriodPosition').exists()){return null;}
		return snap.child('promisePeriodStack').child(snap.child('promisePeriodPosition').val()).val();
	};
	
	//Array instance section
	function PromisePeriodFactory3(ref){
		this._supportedKeys = ["promisePeriodNext", "promisePeriodCurrent"];
		
		
		// call the super constructor
		var obj = $firebaseObject.apply(this, arguments);
		// this.minimized = {};
		// this._supportedKeys.forEach(function(key){ this.minimized[key] = true; }, this);
		
		this.range = {startX: null, endX: null};
		return obj;
	}
	PromisePeriodFactory3.prototype = {
		$$updated: function(snap){
			var self = this;
			//log('updatus', snap.val());
			
			var continueOn = this.validate(snap);
			
			if(!continueOn){
				//todo, should probably functionize this
				["activeLineDate", "currentPromise", "isInitialPeriod", "promiseCount", "promisePeriodCurrent",
					"promisePeriodNext", "promisePeriodPosition", "promisePeriodStack"].forEach(function(key){
						self[key] = null;
				});
				this.range = {startX: null, endX: null};
				self.draw();
				return;
			}
			log('validated');
			
			//---------------------------------------
			//generate the old bs keys
			var periods = [];
			snap.child('promisePeriodStack').forEach(function(p){ periods.push(p); });
			
			//map new data to old api, this "works", but is generally terrible
			// refactor at some point...
			this._supportedKeys.forEach(function(key, i){
				var pos = periods.length-1-i;
				this[key] = periods[pos] ? periods[pos].val() : null;
				if(this[key]){ this[key].$id = periods[pos].key; }
			}, this);
			
			if(this.promisePeriodNext && this.promisePeriodNext.locked){
				this.promisePeriodCurrent = this.promisePeriodNext;
				this.promisePeriodNext = null;
				log('doing this');
			}
			//-------------------------------------------
			
			this.currentPromise = _getCurrentPeriod(snap);
			this.promiseCount = periods.length;
			
			//this._supportedKeys
			["promisePeriodStack", "promisePeriodPosition", "activeLineDate"].forEach(function(key){
				var c = snap.child(key);
				self[key] = c.val();
			});
			
			if((this.currentPromise && this.currentPromise.locked) || this.promiseCount > 1){ this.isInitialPeriod = false; }
			else{ this.isInitialPeriod = true; }
			
			this.draw();
		},
		$save: function $save(){
			var self = this;
			
			
			var obj = {};
			
			//this._supportedKeys
			["promisePeriodStack", "promisePeriodPosition"].forEach(function(key){
				if(self[key] !== undefined){obj[key] = self[key];}
			});
			
			log('obj', obj);
			
			return fbConnection.update(self.$ref(),obj);
		},
		
		minimize: function minimize(periodId){
			log('period', periodId);
			if(!periodId){return;}
			var p = this.promisePeriodStack[periodId];
			log("p", p);
			if(p){
				p.minimized = !p.minimized;
				this.$save();
			}
		},
		
		//if false returns, abort?
		//REWRITE IN PROGRESS
		validate: function(snap){
			var self = this;
			if(!snap.child('activeLineDate').exists()){return false;}
			
			
			log('STARTING VALIDATION');
			var todayDate = snap.child('fbdbTime').val();
			if(!todayDate){ return false;}
			todayDate = moment(todayDate, "YYYY-MM-DD HH:mm:ssZZ");
			var nextWeekToday = moment(todayDate).add(1, "weeks");
			
			//this is "fine", but there's nothing to validate against
			//if(todayDate.isSameOrAfter(moment(snap.child('activeLineDate').val(), "YYYY-MM-DD"))){
				//return true;
			//}
			
			
			// var newPeriods = {};
			// this._supportedKeys.forEach(function(key){
			// 	newPeriods[key] = snap.child(key).val();
			// },this);
			
			//var proposedPeriods = {};
			var currentPeriod = _getCurrentPeriod(snap);
			var activeLineDate = snap.child('activeLineDate').val();
			var proposedPeriods = {};
			var proposedPosition = snap.child('promisePeriodPosition').val();
			var reset = false;
			
			
			
			//generate new
			if(!currentPeriod){
				log("TODO - no existing promise");
				var proposedPosition = snap.child('promisePeriodStack').ref.push().key;
				proposedPeriods = {}; //empty it
				var p = this.createFromScratch(snap);
				if(p){
					proposedPeriods[proposedPosition] = p;
				}
				
			}
			//try to generate a new promise and advance
			else if(currentPeriod.locked){
				log("TODO - current promise is locked, try to generate next");
				var nextPeriod = self.generateNextPeriod(activeLineDate, currentPeriod);
				var capped = this.capActiveLine(activeLineDate, nextPeriod);
				if(capped){
					proposedPosition = snap.child('promisePeriodStack').ref.push().key;
					//write period
					proposedPeriods[proposedPosition] = capped;
				}
			}
			
			// DISABLED
			//do special things with the first promise
			//else if(promiseCount === 1){
			else if(snap.child('promisePeriodStack').numChildren() === 1 && currentPeriod.endDate > snap.child('activeLineDate').val()){
				log("TODO - updates to first promise");
				reset = true;
				
				var proposedPosition = snap.child('promisePeriodStack').ref.push().key;
				proposedPeriods = {}; //empty it
				var p = this.absorbInitialPeriod(snap);
				log('p', p);
				if(p){
					proposedPeriods[proposedPosition] = p;
				}
			}
			
			//just sanity check the current promise, cap at active line, write if current value is bad etc...
			else{
				log("TODO - auto-updating disabled, just validate current promise");
				var capped = this.capActiveLine(activeLineDate, currentPeriod);
				if(capped){
					//is different
					if(capped.startDate !== currentPeriod.startDate || capped.endDate !== currentPeriod.endDate){
						proposedPeriods[proposedPosition] = capped;
					}
				}
			}
			
			
			
			
			
			//if should write anything
			if(Object.keys(proposedPeriods).length){
				//probably functionize this?
				var base = reset ? {} : snap.child('promisePeriodStack').val() || {};
				var newStack = angular.extend(base, proposedPeriods);
				log('thing', base, newStack);
				//continue here: why is false
				log('newStack', newStack, proposedPeriods);
				var constructedSaveObj = {
					"promisePeriodPosition": proposedPosition,
					"promisePeriodStack": newStack
				};
				log('writing...', JSON.stringify(constructedSaveObj, null, 2), constructedSaveObj);
				this.liveUpdate(constructedSaveObj);
				return false;
			}
			return true;
		},
		
		
		
		liveUpdate: function liveUpdate(obj){
			var self = this;
			log('obj', obj);
			//possibly dangerous
			Object.keys(obj).forEach(function(key){
				self[key] = obj[key]
			});
			this.draw();
			return this.$save();
		},
		
		updateCurrentPeriod: function updateCurrentPeriod(obj){
			if(!obj && !obj.startDate && !obj.endDate){
				log('can\'t update promise to nothing');
			}
			var stack = this.promisePeriodStack;
			stack[this.promisePeriodPosition] = obj;
			this.liveUpdate({"promisePeriodStack": stack});
			
		},
		
		isPeriodChanged: function isPeriodChanged(period, snapVal){
			//var snapVal = snap.child('ppKey').val();
			log('isChanged', period, snapVal);
			return !snapVal
				|| snapVal.startDate !== period.startDate 
				|| snapVal.endDate !== period.endDate;
		},
		
		capActiveLine: function capActiveLine(activeLineDate, period){
			if(!period){ return false; }
			if(period.startDate > activeLineDate){ return false; } //may be invalid, same day could be valid
			if(period.endDate > activeLineDate){
				return {startDate: period.startDate, endDate: activeLineDate};
			}
			return period;
		},
		
		//return false if a period can't be generated?
		generateNextPeriod: function generateNextPeriod(activeLineDate, period){
			var diff = moment(period.endDate).diff(period.startDate, 'days');
			var start = moment(period.endDate).add(1, 'days');
			var end = moment(start).add(diff, 'days');
			var nextPeriod = {startDate: start.format("YYYY-MM-DD"), endDate: end.format("YYYY-MM-DD")};
			log('nexting...', diff, start, end, nextPeriod);
			return this.capActiveLine(activeLineDate, nextPeriod);
		},
		
		// fakePreviousPeriod: function fakePreviousPeriod(currentPeriod){
		// 	if(!currentPeriod || !currentPeriod.startDate || !currentPeriod.endDate){return null;}
		// 	var diff = moment(currentPeriod.endDate).diff(currentPeriod.startDate, 'days');
		// 	
		// 	log('hi', diff);
		// 	var end = moment(currentPeriod.startDate).subtract(1, 'days');
		// 	var start = moment(currentPeriod.startDate).subtract(diff+1, 'days');
		// 	return {startDate: start.format("YYYY-MM-DD"), endDate: end.format("YYYY-MM-DD")};
		// },
		
		//mainly for debugging purposes
		erasePeriods: function erasePeriods(){
			var constructedSaveObj = {
				"promisePeriodPosition": null,
				"promisePeriodStack": null
			};
			this.liveUpdate(constructedSaveObj);
		},
		
		createFromScratch: function createFromScratch(snap){
			var defaultWeekLength = 6;
			var defaultWeekStart = 1; //monday
			
			var todayDate = snap.child('fbdbTime').val();
			todayDate = moment(todayDate, "YYYY-MM-DD HH:mm:ssZZ");
			var activeLineDate = snap.child('activeLineDate').val();
			
			//moment considers sunday to be the following week, so the current period will set to monday
			// leave for now, but it might need to be worked around
			var weekStart = moment(todayDate).day(1); 
			var weekEnd = moment(weekStart).add(defaultWeekLength, 'days');
			
			//jump it ahead a week (breaking this out in case it "feels" weird)
			weekStart.add(1, 'weeks');
			weekEnd.add(1, 'weeks');
			
			var period = {startDate: weekStart.format("YYYY-MM-DD"), endDate: weekEnd.format("YYYY-MM-DD")};
			
			var capped = this.capActiveLine(activeLineDate, period);
			if(capped){ return capped; }
			
			//fallback to a full week ending at the active line
			var s = moment(activeLineDate).subtract(6, 'days').format('YYYY-MM-DD');
			return {startDate: s, endDate: activeLineDate};
		},
		
		absorbInitialPeriod: function absorbInitialPeriod(snap){
			var currentPeriod = _getCurrentPeriod(snap);
			if(!currentPeriod){return false;}
			var days = moment(currentPeriod.endDate).diff(currentPeriod.startDate, 'days');
			var activeLineDate = snap.child('activeLineDate').val();
			var weekStart = moment(activeLineDate).subtract(days, 'days');
			return {startDate: weekStart.format("YYYY-MM-DD"), endDate: activeLineDate};
		},
		
		//date - already momented
		dateInPeriod: function(date, period){
			if(!date || !period || !period.startDate || !period.endDate){return false;}
			log('date', date, period);
			return date.isBetween(period.startDate, period.endDate, null, '[]'); //fully inclusive check
		},
		dateAfterPeriod: function(date, period){
			if(!date || !period || !period.startDate || !period.endDate){return false;}
			log('date', date, period);
			return date.isAfter(period.startDate, period.endDate); 
		},
		
		//saveTicketsFn - a function that's expected to save the tickets, should return a promise when it's done
		// not implemented as a promise because it should execute before saving the promise region. 
		lock: function lock(saveTicketsFn){
			var self = this;
			var nextPeriod = this.generateNextPeriod(this.activeLineDate, this.currentPromise);
			if(!nextPeriod && this.currentPromise.locked){return;}
			
			
			var proposedPeriods = angular.extend({}, this.promisePeriodStack);
			var proposedPosition;
			//lock old
			proposedPeriods[this.promisePeriodPosition].locked = true;
			
			if(nextPeriod){
				proposedPosition = this.$ref().ref.child('promisePeriodStack').push().key;
				//write new period
				proposedPeriods[proposedPosition] = nextPeriod;
			}
			else{
				proposedPosition = this.promisePeriodPosition;
			}
				
			var constructedSaveObj = {
				"promisePeriodPosition": proposedPosition,
				"promisePeriodStack": proposedPeriods
			};
			
			//consider not using the callback and just shotgunning all writes
			return saveTicketsFn().then(function(){
				log('writing...', JSON.stringify(constructedSaveObj, null, 2), constructedSaveObj);
				return self.liveUpdate(constructedSaveObj);
			}).then(function(){
				console.log('prop', proposedPosition)
				return proposedPosition;
			})
		},
		
		draw: function(){
			var self = this;
			activeColumnService.$loaded().then(function(){
				self._supportedKeys.forEach(function(ppKey){
					if(self[ppKey] && self[ppKey].startDate <= self.activeLineDate && self[ppKey].endDate <= self.activeLineDate){
						self[ppKey].startX = activeColumnService.dateMap(moment(self[ppKey].startDate).subtract(1,'days').format('YYYY-MM-DD'), function(x){
							if(!self[ppKey]){return;}
							self[ppKey].startX = x;
							self.drawSimple(self[ppKey].startX, self[ppKey].endX, ppKey);
						});
						self[ppKey].endX = activeColumnService.dateMap(self[ppKey].endDate, function(x){
							if(!self[ppKey]){return;}
							self[ppKey].endX = x;
							self.drawSimple(self[ppKey].startX, self[ppKey].endX, ppKey);
						});
						self.drawSimple(self[ppKey].startX, self[ppKey].endX, ppKey);
					}
					else{
						self.drawSimple(null, null, ppKey);
					}
				});
			});
		},
		
		updateTotal: function updateTotal(startX, endX){
			if(!this.range){return;} //I think this is a stale callback before it's finished cleaning up?
			var self = this;
			var s = Infinity;
			var e = -Infinity;
			
			self._supportedKeys.forEach(function(ppKey){
				if(!self[ppKey]){ return; }
				var p = self[ppKey];
				if(p.startX < s){s = p.startX;}
				if(p.endX > e){e = p.endX;}
			});
			this.range.startX = (s === Infinity) ? null : s;
			this.range.endX = (e === -Infinity) ? null : e;
		},
		
		drawSimple: function(startX, endX, type){
			backgroundAreaService.recalc(startX, endX, type);
			this.updateTotal(startX, endX);
		},
		render: function(){
			backgroundAreaService.recalc(this.startX, this.endX);
		},
};
	
	var theFactory = $firebaseObject.$extend(PromisePeriodFactory3);
	
	return theFactory;
}]);
