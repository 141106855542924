import { Component, Input,  ViewChild } from '@angular/core';

/**
 * Displays dates and allows selection via a calendar.  Uses the
 * primeng p-calendar component and adds clear and cancel buttons.
 */
@Component({
	selector: 'ticket-calendar',
	template: require('./ticket-calendar.component.html')
})
export class TicketCalendarComponent {

	/**
	 * @Input useRange - Set true to use a start - finish range as with tasks.
	 *                   Set false to use a single date like with constraints
	 *                   and milestones.
	 */
	@Input() useRange: boolean;

	/**
	 * @Input singleDate - Optional, supply this when useRange is false.
	 */
	@Input() singleDate: Date;

	/**
	 * @Input rangeDate - Optional, supply this when useRange is true.
	 */
	@Input() rangeDate: Date[];
	
	/**
	 * @Input showIcon - boolean indicating when icon is needed.
	 */
	@Input() showIcon?: boolean;

	/**
	 * @Input dateFormat - string, the date format.
	 */
	@Input() dateFormat?: string;

	/**
	 * @Input placeholder - string, placeholder text when no date.
	 */
	@Input() placeholder?: string;

	@ViewChild('myCalendar') datePicker;

	constructor(){
		console.log('TicketCalendarComponent constructor');
	}

	en: any; // Temp futzing with PrimeNg calendar
	selectionMode: string;
	theDate: any;
	
	ngOnInit() {
		this.en = {
            firstDayOfWeek: 0,
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
            monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
            monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
            today: 'Today',
            clear: 'Clear'
		};
		this.selectionMode = this.useRange ? "range" : "single";
		this.theDate = this.useRange ? this.rangeDate : this.singleDate;

		// Initialize optional stuff if needed
		this.showIcon = (this.showIcon == undefined) ? true : this.showIcon;	
		this.dateFormat = (this.dateFormat == undefined) ? "M d" : this.dateFormat;	
		this.placeholder = (this.placeholder == undefined) ? "placeholder" : this.placeholder;	
		}

	clearDate() {
		this.theDate = null;
	}

	close() {
		// see last comment here: https://github.com/primefaces/primeng/issues/2001
		console.log("close clicked");
		this.datePicker.overlayVisible = false;
	}

}
