import { Point, Container, utils } from "pixi.js";
import { PlanState } from "../../common/services/plan-state.service";
import { Camera, LevelOfDetail, LEVEL_OF_DETAIL_TABLE, LEVEL_OF_DETAIL_SIGNALS } from "../core";
import * as Utils from "utils";
import { Pin, PinnedCount } from "./";
import { FancySpritePool } from "../../common/utils/GenericPool";
import { TICKET_TEXTURE_OPTIONS } from "../tickets/";
import { FancySprite, FancyGraphics, FancyContainer } from "../graphics/";
import { PromisePinData, PromisePin, pinColors, pinState } from "../../common/utils/promise-pin-utils";
import { combineLatest, Subject } from "rxjs";
import { map, sample, takeUntil, switchMap, filter } from "rxjs/operators";
import { Ticket } from "../../common/models/Ticket";
import { FancyTicketAction } from "../../actions/ticket-actions";
import { TicketRenderTypes } from "../../common/models/Ticket";
import { sessionStorage, SessionSettings } from "../../common/services/SessionStorage";
import { Side } from "../../common/models/Side";
import { ITriangulateData } from "../fontpath/Utils";
import {FancyText} from "../fontpath/FancyText";
/**
 * An enum used to control FancyPinHistory drawing options
 */
export enum FANCY_PIN_OPTIONS {
	// The line width
	LINE_WIDTH = 1,
	// The strength of the curve. The number represents the Y offset applied for the quadratic bezier curve control point
	CURVE_STRENGTH = 44,
	// Flag width (The blue or red flag that is drawn for the actual finish position)
	FLAG_STATUS_WIDTH = 10,
	// The LOD level to trigger a re-draw Draw every tick when at this LOD level
}

interface PromisePinDataWithPosition extends PromisePinData {
	left: number,
	top: number,
	side: Side,
	width: number,
	height: number,
	livePromisePeriodStack: any;
}

interface IPinHistoryPinCache {
	[key: string]: PromisePinDataWithPosition
}

/**
 * Interface to define our dirty cache
 */
interface IPinHistoryDirtyCache {
	visible: boolean,
	oldPinData: IPinHistoryPinCache,
	lastSide: Side
}

/**
 * The FancyPinHistory class is responsible for drawing all Pins and Pin Breadcrumbs for all tickets.
 *
 * It listens to the planState.tickets.list$ observable and does a re-draw when ticket data changes.
 */
export class FancyPinHistory {
	public renderable: boolean = false;
	private _deferredTickets;
	private _pinPool;
	private _pinnedCountPool;
	private shutdownSubject$: Subject<boolean> = new Subject();
	private _camera: Camera;
	public lodLevel: LEVEL_OF_DETAIL_TABLE = LEVEL_OF_DETAIL_TABLE.LOW;
	/**
	 * An array used to track DisplayObjects that need to be cleaned up when the FancyPinHistory class is destroyed
	 */
	private _destroyList = [];
	/**
	 * An instance to the PIXI.Graphics class which draws the dashed lines
	 */
	private _graphics: FancyGraphics;
	/**
	 * An instance of a PIXI.Container to store the graphics object.
	 */
	private _pinLineContainer: FancyContainer = new FancyContainer();
	private _pinCountContainer: FancyContainer = new FancyContainer();
	
	/**
	 * A cache to track the tickets which are part of our draw list
	 */
	private drawList: Map<string, IPinHistoryDirtyCache> = new Map();
	/**
	 * Used to control if the pin history is dirty or not. dirty = true means we need to re-draw the stencil buffer and re-upload to the gpu.
	 *
	 * NOTE: Pins are updated on every ticket change. This flag is specifically used to control when we update the dashed lines.
	 */
	private dirty: boolean = true;
	/**
	 * Used to enable solid lines. Renders much faster than dashed lines.
	 */
	private enableSolidLines: boolean = false;
	
	private getCachedNumber;
	
	constructor(public planState: PlanState, camera: Camera, render: () => any) {
		// Set the parent group of our pinLineContainer to be UNDER the tickets. This ensures that our lines will always render below tickets
		// regardless of the order they are added into the scene graph
		this._graphics = new FancyGraphics();
		this._graphics.alpha = 0.70;
		
		this._camera = camera;
		
		// Add the graphics object as a child to the pinLineContainer and then add our pinLineContainer to the world (cameraContainer)
		this._pinLineContainer.addChild(this._graphics);
		
		camera.cameraContainer.addChild(this._pinLineContainer, this._pinCountContainer);
		
		// Create our FancySpritePool for TicketPins
		const pinCreator = (): Pin => {
			return new Pin(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_PINNED_BACKGROUND_NAME]);
		};
		
		const pinnedCountCreator = (): PinnedCount => {
			return new PinnedCount(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_PINNED_BACKGROUND_NAME]);
		};
		
		const pinPool: FancySpritePool = new FancySpritePool(pinCreator, this._pinLineContainer);
		const pinnedCountPool: FancySpritePool = new FancySpritePool(pinnedCountCreator, this._pinCountContainer);
		
		this._pinPool = pinPool;
		this._pinnedCountPool = pinnedCountPool;
		
		// Watch for ticket changes
		planState.tickets.list$.pipe(
			takeUntil(this.shutdownSubject$),
			sample(planState.renderQueueWithCleanup$)
		).subscribe((allTickets: Map<string, Ticket>) => {
			this.drawList.clear();
			this._deferredTickets = allTickets;
			this.update();
			render();
		});
		
		// Handle showing/hiding of pin history by forcing the dirty flag to true and re-drawing
		sessionStorage.changed$.pipe(
			takeUntil(this.shutdownSubject$),
			sample(planState.renderQueueWithCleanup$),
		).subscribe((sessionSettings: SessionSettings) => {
			this.enableSolidLines = sessionSettings.enableSolidLines;
			this.dirty = true;
			this.update();
			render();
		});
		
		// This observer only fires for selected tickets that are dragged.
		// The purpose of this one is to re-draw each tick when you are dragging a ticket (So dashed lines update)
		planState.tickets.selectedTicket$.pipe(
			takeUntil(this.shutdownSubject$),
			switchMap((res) => {
				return planState.tickets.soakedRaw$.pipe(
					filter(action => res.list.has(action.key)),
					filter(action => action.payload.renderType === TicketRenderTypes.drag),
					filter(action => !!action.payload.view.liveLastPromise)
				);
			}),
			sample(planState.renderQueueWithCleanup$)
		).subscribe((ticketAction: FancyTicketAction) => {
			if (!this.renderable){return}
			this.dirty = true;
		});
		
		// Remove item from drawList when ticket removed
		planState.tickets.soakedRaw$.pipe(
			takeUntil(this.shutdownSubject$),
			sample(planState.renderQueueWithCleanup$)
		).subscribe((action) => {
			if (planState.tickets.isRemoveAction(action)){
				this.drawList.delete(action.key);
				this.dirty = true;
				this.update();
			}
		});
		
		
		this.getCachedNumber = function(){
			const cache: Map<string, ITriangulateData> = new Map();
			return function(key: string, creatorFn): ITriangulateData {
				const res = cache.get(key);
				if (res){
					return res
				} else if (creatorFn){
					const newData: ITriangulateData = creatorFn();
					cache.set(key, newData);
					return newData
				}
			}
		}();
		
		LevelOfDetail.emitter.on(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
		
		// Mark our container and graphics as an object that needs to be destroyed
		this._destroyList.push(this._pinLineContainer, this._pinCountContainer, pinPool, pinnedCountPool);
	}
	
	public lodChanged = (newLod, oldLod) => {
		this.renderable = (this.lodLevel <= newLod || LevelOfDetail.isForced);
		if (this.renderable){
			this._graphics.renderable = true;
			this._pinPool.show();
			this._pinnedCountPool.show();
			this.update();
		} else if (!this.renderable){
			this._graphics.renderable = false;
			this._pinPool.hide();
			this._pinnedCountPool.hide();
		}
	};
	
	public update(){
		if (this.renderable && this._deferredTickets){
			this._updateDrawList(this._deferredTickets);
			this._draw(this.drawList, this._pinPool, this._pinnedCountPool);
		}
	}
	
	private _updateDrawList(tickets: Map<string, Ticket>): Map<string, IPinHistoryDirtyCache> {
		
		if (tickets.size !== this.drawList.size){
			this.dirty = true;
		}
		tickets.forEach((ticket: Ticket) => {
			// MOC-2953 - Only draw if ticket no hidden
			if (ticket.hidden){return}
			let pinVisible: boolean = false;
			let verticalBarsVisible: boolean = false;
			let completedDashedLineVisible: boolean = false;
			let dashedLineVisible: boolean = false;
			// Always draw the latest pin, if exists and visible...
			if (ticket.view.liveLastPromise){
				const latestPromisePin: PromisePin = ticket.view.liveLastPromise;
				pinVisible = this.isPinVisible(latestPromisePin.data, ticket);

				// Check if the latest pin was finished and the actualFinish are not equal. Draw the vertical bars if so.
				if (latestPromisePin.data && latestPromisePin.data.state === pinState.completedEarly || latestPromisePin.data.state === pinState.completedLate) {
					verticalBarsVisible = this.isVerticalBarsVisible(ticket);
				}
				
				// Draw from right edge of ticket to latest prior miss
				if (ticket.view.liveLastPromise.data.finishX !== ticket.view.left + ticket.view.width) {
					// Calculate the start point (The right edge of the ticket)
					const ticketStartPointRightEdge: Point = this._getPoint(
						ticket.view.left + ticket.view.width,
						ticket.view.top
					);
					// Calculate the end point (The latest promise pin)
					const ticketEndPoint: Point = this._getPoint(
						latestPromisePin.data.finishX,
						ticket.view.top
					);
					
					completedDashedLineVisible = this.isDashedLineVisible(ticketStartPointRightEdge, ticketEndPoint);
				}
				
				// Draw the dashed lines between prior misses
				let previousPromisePin: PromisePin;
				ticket.view.livePromisePeriodStack.forEach((promisePin: PromisePin) => {
					if (previousPromisePin && sessionStorage.getSetting('showPinHistory')){
						// Get the pin data
						const previousPinData: PromisePinData = previousPromisePin.data;
						const currentPinData: PromisePinData = promisePin.data;
						
						// Calculate the line start point.
						const dashStartPoint: Point = this._getPoint(
							currentPinData.finishX,
							ticket.view.top
						);
						
						// Calculate the line end point.
						const dashEndPoint: Point = this._getPoint(
							previousPinData.finishX,
							ticket.view.top
						);
						
						const result = this.isDashedLineVisible(dashStartPoint, dashEndPoint);
						
						if (result){
							dashedLineVisible = true;
						}
					}
					previousPromisePin = promisePin;
				});
			}
			const visible: boolean = pinVisible || verticalBarsVisible || dashedLineVisible || completedDashedLineVisible;
			this._checkDirty(ticket, visible);
			this._updatePinCache(ticket, visible);
		});
		
		return this.drawList
	}

	private _updatePinCache(ticket: Ticket, visible: boolean): void {
		const currentPromisePins: PromisePin[] = ticket.view.livePromisePeriodStack || [];

		let newPinCache = this.drawList.get(ticket.$id) || {};
		currentPromisePins.forEach((promisePin: PromisePin) => {
			const pinData: PromisePinData = promisePin.data;
			newPinCache[promisePin.$id] = {
				plannedStart: pinData.plannedStart,
				plannedFinish: pinData.plannedFinish,
				actualFinish: pinData.actualFinish,
				finishX: pinData.finishX,
				color: pinData.color,
				state: pinData.state,
				variance: pinData.variance,
				left: ticket.view.left,
				top: ticket.view.top,
				side: ticket.view.side,
				width: ticket.view.width,
				height: ticket.view.height,
				livePromisePeriodStack: ticket.view.livePromisePeriodStack
			};
		});
		
		this.drawList.set(ticket.$id, {visible: visible, oldPinData: newPinCache, lastSide: ticket.view.side});
	}

	private _checkDirty(ticket: Ticket, visible: boolean): void {
		
		const pinCache: IPinHistoryDirtyCache = this.drawList.get(ticket.$id);
		const oldData: IPinHistoryDirtyCache = this.drawList.get(ticket.$id);

		// We do not have a cache for this ticket. That means ticket is either new or it has been deleted. In either case, we need to refresh
		if (!oldData || !oldData.oldPinData){
			this.dirty = true; return;
		}
		
		const newData: PromisePin[] = ticket.view.livePromisePeriodStack || [];
		const pinsCache: IPinHistoryPinCache = oldData.oldPinData;

		const lastVisible: boolean = (pinCache) ? pinCache.visible : null;
		// If this ticket is currently visible, and it was NOT visible during the last tick, then that tells us this is a new ticket in the viewport.
		// If so, do an additional check to see if the new ticket actually has pins. If it does, then refresh the buffer.
		if (visible !== lastVisible && newData.length > 0){
			this.dirty = true; return
		}
		
		if (ticket.view.side !== pinCache.lastSide){
			this.dirty = true;
			return
		}
		
		newData.forEach((currentPin: PromisePin) => {
			const stalePin: PromisePinDataWithPosition = pinsCache[currentPin.$id];
			const currentPinData: PromisePinData = currentPin.data;
			// We don't have a copy of this pin in the pin cache. Mark the system as dirty so it refreshes.
			if (!stalePin){
				this.dirty = true; return;
			}
			// Perform dirty check
			if (currentPinData.actualFinish !== stalePin.actualFinish || stalePin.plannedStart !== currentPinData.plannedStart || stalePin.plannedFinish !== currentPinData.plannedFinish ||
				stalePin.finishX !== currentPinData.finishX || stalePin.color !== currentPinData.color || stalePin.variance !== currentPinData.variance ||
				ticket.view.left !== stalePin.left || ticket.view.top !== stalePin.top || currentPinData.state !== stalePin.state || ticket.view.livePromisePeriodStack !== stalePin.livePromisePeriodStack){
				this.dirty = true;
			}
		})
	}

	/**
	 * Draws the promise pins and dashed lines for all tickets
	 * @param {Map<string, boolean>} drawList The map of ticket IDs to draw
	 * @param {FancySpritePool} pinPool Pool of {Pin} instances
	 * @param {FancySpritePool} pinnedCountPool Pool of {PinnedCount} instances
	 */
	private _draw(drawList: Map<string, IPinHistoryDirtyCache>, pinPool: FancySpritePool, pinnedCountPool: FancySpritePool): void {
		// Clear the stencil buffer and sprite pool
		if (this.dirty){
			this._graphics.clear();
		}
		pinPool.reset();
		pinnedCountPool.reset();

		if (!this.renderable || !drawList || !this._deferredTickets){return}

		drawList.forEach((ticketCacheData: IPinHistoryDirtyCache, id: string) => {
			// Only process if this ticket is in our draw list.
			if (!ticketCacheData || !ticketCacheData.visible){return}
			
			const ticket: Ticket = this._deferredTickets.get(id);

			// MOC-2953 - Only draw if ticket no hidden
			if (!ticket || ticket.hidden || ticket.view.side === Side.PULL){return}
			
			// Always draw the latest pin, if exists
			if (ticket.view.liveLastPromise){
				const latestPromisePin: PromisePin = ticket.view.liveLastPromise;
				this._drawPin(latestPromisePin.data, ticket, pinPool, true);
				
				// Only draw pinned count if there are prior misses
				if (ticket.view.livePromisePeriodStack.length - 1 > 0){
					this._drawPinnedCount(latestPromisePin.data, ticket, pinnedCountPool);
				}
				
				// Check if the latest pin was finished and the actualFinish are not equal. Draw the vertical bars if so.
				if (this.dirty && latestPromisePin.data && (latestPromisePin.data.state === pinState.completedEarly || latestPromisePin.data.state === pinState.completedLate)) {
					this._drawVerticalBars(latestPromisePin.data, ticket);
				}
				
				// Draw from right edge of ticket to latest prior miss
				if (this.dirty && ticket.view.liveLastPromise.data.finishX !== ticket.view.left + ticket.view.width) {
					// Calculate the start point (The right edge of the ticket)
					const ticketStartPointRightEdge: Point = this._getPoint(
						ticket.view.left + ticket.view.width,
						ticket.view.top
					);
					// Calculate the end point (The latest promise pin)
					const ticketEndPoint: Point = this._getPoint(
						latestPromisePin.data.finishX,
						ticket.view.top
					);
					// Use the Midpoint Formula to determine the Quadratic Curve control point. For the Y, use the CURVE_STRENGTH offset
					const ticketControlPoint: Point = this._getPoint(
						(ticketEndPoint.x + ticketStartPointRightEdge.x) / 2,
						ticket.view.top - FANCY_PIN_OPTIONS.CURVE_STRENGTH
					);
					
					this._drawDashedLine(ticketStartPointRightEdge, ticketControlPoint, ticketEndPoint, latestPromisePin.data.color);
				}
				
				// Draw the dashed lines between prior misses
				let previousPromisePin: PromisePin;
				ticket.view.livePromisePeriodStack.forEach((promisePin: PromisePin) => {
					if (previousPromisePin && sessionStorage.getSetting("showPinHistory")){
						// Get the pin data
						const previousPinData: PromisePinData = previousPromisePin.data;
						const currentPinData: PromisePinData = promisePin.data;
						
						// Draw a pin
						this._drawPin(previousPinData, ticket, pinPool, false);

						if (this.dirty){
							// Calculate the line start point.
							const dashStartPoint: Point = this._getPoint(
								currentPinData.finishX,
								ticket.view.top
							);

							// Calculate the line end point.
							const dashEndPoint: Point = this._getPoint(
								previousPinData.finishX,
								ticket.view.top
							);

							// Use the Midpoint Formula to determine the Quadratic Curve control point. For the Y, use the CURVE_STRENGTH offset
							const dashControlPoint: Point = this._getPoint(
								(dashEndPoint.x + dashStartPoint.x) / 2,
								ticket.view.top - FANCY_PIN_OPTIONS.CURVE_STRENGTH
							);

							this._drawDashedLine(dashStartPoint, dashControlPoint, dashEndPoint, previousPromisePin.data.color);
						}
					}
					previousPromisePin = promisePin;
				})
			}
		});

		// We are no longer dirty.
		this.dirty = false;
	}
	
	private isPinVisible(promisePin: PromisePinData, ticket: Ticket): boolean {
		return this._camera.globalView.contains(promisePin.finishX, ticket.view.top);
	}
	
	private isVerticalBarsVisible(ticket: Ticket): boolean {
		return (this._camera.globalView.contains(ticket.view.left + ticket.view.width, ticket.view.top) ||
			this._camera.globalView.contains(ticket.view.liveFinishX - FANCY_PIN_OPTIONS.FLAG_STATUS_WIDTH, ticket.view.top));
	}
	
	private isDashedLineVisible(firstPoint: Point, secondPoint: Point): boolean {
		const screenPoint: Point = new Point(this._camera.realToScreenX(secondPoint.x), this._camera.realToScreenY(secondPoint.y));
		const w: number = Math.abs(firstPoint.x - secondPoint.x);
		return !((screenPoint.x + w * this._camera.scale.x < 0 || screenPoint.x >= this._camera.view.width) || (screenPoint.y + FANCY_PIN_OPTIONS.CURVE_STRENGTH * this._camera.scale.y < 0 || screenPoint.y >= this._camera.view.height));
		//return (this._camera.globalView.contains(firstPoint.x, firstPoint.y) || this._camera.globalView.contains(secondPoint.x, secondPoint.y));
	}
	/**
	 * Draws the pin on the canvas
	 * @param {PromisePinData} promisePin
	 * @param {Ticket} ticket
	 * @param {FancySpritePool} pinPool
	 * @param {Boolean} latest Is this the most recent pin? If it is, draw larger than the rest.
	 */
	private _drawPin(promisePin: PromisePinData, ticket: Ticket, pinPool: FancySpritePool, latest: boolean = false): void {
		// Calculate the line end point.
		const pinPoint: Point = this._getPoint(
			promisePin.finishX,
			ticket.view.top
		);
		
		const pinSprite: FancySprite = pinPool.grab() as FancySprite;
		if (!pinSprite){return;}
		if (latest){
			pinSprite.width = 49;
			pinSprite.height = 49;
		} else {
			pinSprite.width = 34;
			pinSprite.height = 34;
		}
		pinSprite.x = pinPoint.x - pinSprite.width / 2;
		pinSprite.y = pinPoint.y - pinSprite.height / 2;
		if(promisePin.variance){ (pinSprite as Pin).variance = promisePin.variance.combinedVarianceText; }
		else{ (pinSprite as Pin).variance = null; }
		pinSprite.tint = Utils.convertHexStringToNumber(promisePin.color);
	}
	/**
	 * Draws the prior missed count on the canvas
	 * @param {PromisePinData} promisePin
	 * @param {Ticket} ticket
	 * @param {FancySpritePool} pinnedCountPool Pool of {PinnedCount} instances
	 */
	private _drawPinnedCount(promisePin: PromisePinData, ticket: Ticket, pinnedCountPool: FancySpritePool): void {
		// Calculate the line end point.
		const pinPoint: Point = this._getPoint(
			promisePin.finishX,
			ticket.view.top
		);
		
		const baseCount: number = ticket.view.livePromisePeriodStack.length - 1;
		const count: string = (baseCount > 99) ? "99" : baseCount + "";
		const pinnedCountSprite: PinnedCount = pinnedCountPool.grab() as PinnedCount;
		const fancyText = pinnedCountSprite.fancyText;
		pinnedCountSprite.x = (pinPoint.x - pinnedCountSprite.width / 2) + 12;
		pinnedCountSprite.y = (pinPoint.y + pinnedCountSprite.height / 2) -2;
		pinnedCountSprite.fancyText.text = count;
		// Create a cache of numbers so we don't have to keep calculating text
		if (pinnedCountSprite.fancyText.isFancy && pinnedCountSprite.fancyText.dirty){
			const data: ITriangulateData = this.getCachedNumber(count, () => {return pinnedCountSprite.fancyText.calculateText(true);});
			(<FancyText>pinnedCountSprite.fancyText).mergedVertexData = data.vertexData;
			(<FancyText>pinnedCountSprite.fancyText).mergedIndexData = data.indexData;
			(<FancyText>pinnedCountSprite.fancyText).vertexCount = data.vertexCount;
			
			// We are manually handling text state. Remove dirty flag, but mark it as needing a new VAO for rendering.
			(<FancyText>pinnedCountSprite.fancyText).dirty = false;
			(<FancyText>pinnedCountSprite.fancyText).needsVAO = true;
		}
		pinnedCountSprite.tint = Utils.convertHexStringToNumber(pinColors.red);
		
		// Text is long. Offset the x a tad.
		if (count[1]){
			fancyText.x = 26;
		}
	}
	/**
	 * Draws the late/early vertical lines on the canvas
	 * @param {PromisePinData} promisePin
	 * @param {Ticket} ticket
	 */
	private _drawVerticalBars(promisePin: PromisePinData, ticket: Ticket): void {
		// The point representing the right edge of the ticket
		const ticketRightEdge: Point = this._getPoint(
			ticket.view.left + ticket.view.width,
			ticket.view.top
		);
		// The point representing where the actual finish 'flag' is drawn
		const actualFinish: Point = this._getPoint(
			ticket.view.liveFinishX - FANCY_PIN_OPTIONS.FLAG_STATUS_WIDTH,
			ticket.view.top
		);
		// The control point to manage the curve
		const cp: Point = this._getPoint(
			(actualFinish.x + ticketRightEdge.x) / 2,
			ticket.view.top - FANCY_PIN_OPTIONS.CURVE_STRENGTH
		);
		
		this._graphics.lineStyle(FANCY_PIN_OPTIONS.LINE_WIDTH, Utils.convertHexStringToNumber(promisePin.color));
		this._graphics.moveTo(ticketRightEdge.x, ticketRightEdge.y);
		
		// This check fixes a problem where we draw an extra line for the early/late flag at the same position
		if (actualFinish.x !== ticketRightEdge.x - FANCY_PIN_OPTIONS.FLAG_STATUS_WIDTH) {
			if (this.enableSolidLines) {
				this._graphics.quadraticCurveTo(cp.x, cp.y, actualFinish.x, actualFinish.y);
			} else {
				this._graphics.dashedQuadraticCurveTo(cp.x, cp.y, actualFinish.x, actualFinish.y);
			}
		}
		
		this._graphics.lineStyle();
		this._graphics.beginFill(Utils.convertHexStringToNumber(promisePin.color));
		this._graphics.drawRect(actualFinish.x, actualFinish.y, FANCY_PIN_OPTIONS.FLAG_STATUS_WIDTH, ticket.view.height);
		this._graphics.endFill();
	}
	/**
	 * Draws the dashed lines between prior misses
	 * @param {Point} firstPoint The first PromisePinData
	 * @param {Point} controlPoint The control point for the curve
	 * @param {Point} endPoint The second PromisePinData
	 * @param {string} lineColor The color of the dashed line
	 */
	private _drawDashedLine(firstPoint: Point, controlPoint: Point, endPoint: Point, lineColor: string): void {
		this._graphics.lineStyle(FANCY_PIN_OPTIONS.LINE_WIDTH, Utils.convertHexStringToNumber(lineColor));
		this._graphics.moveTo(firstPoint.x, firstPoint.y);
		// Control point
		if (this.enableSolidLines){
			this._graphics.quadraticCurveTo(controlPoint.x, controlPoint.y, endPoint.x, endPoint.y);
		} else {
			this._graphics.dashedQuadraticCurveTo(controlPoint.x, controlPoint.y, endPoint.x, endPoint.y);
		}
	}
	/**
	 * Helper method to return a new PIXI.Point
	 * @param {number} x The X coordinate (in world space)
	 * @param {number} y The Y coordinate (in world space)
	 */
	private _getPoint(x: number, y: number): Point {
		return new Point(x, y);
	}
	
	/**
	 * Called when the renderer is to be destroyed. This removes all elements in the _destroyList
	 * @param destroyOptions
	 */
	public destroy(destroyOptions?): void {
		this.shutdownSubject$.next(true);
		this._destroyList.forEach(thing => thing.destroy ? thing.destroy(destroyOptions) : null);
		LevelOfDetail.emitter.off(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
		this._deferredTickets = null;
	}
}
