import {Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectionStrategy, SecurityContext, Output, EventEmitter} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {FormControl} from "@angular/forms";
import { Subject, concat, of, BehaviorSubject, combineLatest, defer } from "rxjs";
import { filter, switchMap, map, tap, takeUntil, distinctUntilChanged } from "rxjs/operators";
import { PlanState, StateEvents } from '../../services/plan-state.service';

import { analyticsService } from "ng2/common/utils/AnalyticsService";

import * as firebaseUtils from "ng2/fancy-firebase/firebase-utils";
import { DraggableContainerComponent } from '../draggable-container/draggable-container.component';

import { Base64 } from 'js-base64';

function identifyHostFromUrl(url){
	let parsedUrl: URL;
	try{
		parsedUrl = new URL(url);
	}
	catch(e){
		console.log(e);
		return null;
	}

	if(parsedUrl.host && parsedUrl.host.match(/zoom\.us/)){ return 'zoom'; }
	if(parsedUrl.host && parsedUrl.host.match(/jit\.si/)){ return 'jitsi'; }
	return null;
}

function makeZoomUrl(meetingId) {
	let otherParse: URL;
	try{
		otherParse = new URL(meetingId);
	}
	catch(e){
		console.log(e);
		return null;
	}
	
	let host = identifyHostFromUrl(meetingId);
	let params = otherParse.searchParams;

	if(host === 'zoom'){
		otherParse.pathname = otherParse.pathname.replace(/\/j\/(\d+)/, '/wc/$1/join');
		params.set('prefer', '1');
	}

	otherParse.search = params.toString();
	
	return otherParse.toString();
}

@Component({
	selector: 'meeting-popup',
	template: require('./meeting-popup.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetingPopup implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("draggableContainer") draggableContainer:DraggableContainerComponent;

	@Output() public closePopup = new EventEmitter();

	// private meetingId = '957474608';
	private shutdownSubject$ = new Subject();

	private sharedEmbedUrlRef: firebase.database.Reference;

	private url = new FormControl('');
	public videoHost = new FormControl('zoom');

	public dropdownOptions = [
		{label: 'Jit.si', value: 'jitsi'},
		{label: 'Zoom.us', value: 'zoom'}
	]

	constructor(private sanitizer: DomSanitizer, public planState: PlanState) {}

	// private firebaseSafify(url: string) {

	// }
	// private unFirebaseSafify(url: string) {

	// }

	public meetingJoined$ = new BehaviorSubject(false);

	public sharedUrl$ = this.planState.stateChange$.pipe(
		filter((event) => event === StateEvents.FULLY_LOADED),
		switchMap(event => {
			return this.planState.plan.object$
		}),
		map(plan => plan && plan.sharedEmbedUrl ? plan.sharedEmbedUrl : null),
		// tap(url => console.log('!!!!!!!!!!!! plan embed url', url))
	);

	//this needs to be rethought since only some hosts need it...
	public userProcessedUrl$ = combineLatest(
		concat(defer(()=> of(this.planState.youSync)), this.planState.you).pipe(
			// tap(you => console.log('you', you)),
			map(you => you && you.data && you.data.user ? you.data.user.firstName : 'placeholder'),
			distinctUntilChanged() //prevent weird duplicate emissions
		),
		this.sharedUrl$
	).pipe(
		map(things => {
			if(!things[1]) { return null; }
			let host = identifyHostFromUrl(things[1]);

			if(host === 'zoom'){
				let encodedName = Base64.encode(things[0]);
				return `${things[1]}&un=${encodedName}`;
			}
			else{
				return things[1]
			}

		}),
		tap(you => console.log('you', you)),
		// distinctUntilChanged()
	);

	public userProcessedSanitizedUrl$ = this.userProcessedUrl$.pipe(
		map(url => this.sanitizeUrl(url))
	);

	// public sanitizedSharedUrl$ = this.sharedUrl$.pipe(
	// 	map(url => this.sanitizeUrl(url))
	// );

	// public you$ = concat(of(this.planState.youSync), this.planState.you);
	
	ngOnInit() {
		this.sharedEmbedUrlRef = firebaseUtils.baseRef('plans').child(this.planState.projectId).child(this.planState.planId).child('sharedEmbedUrl');

		analyticsService.openedVideoMeeting(this.planState.planId);
		// this.sharedUrl$.pipe(
		// 	filter( url => !!url ),
		// 	takeUntil(this.shutdownSubject$)
		// ).subscribe(url => {
		// 	this.url.setValue(url);
		// });
	}

	ngAfterViewInit(){
		this.draggableContainer.setWidth(500);
		this.draggableContainer.setHeight(400);
		this.draggableContainer.setX(0);
		this.draggableContainer.setY(0);
	}
	
	ngOnDestroy(){
		this.shutdownSubject$.next();
		this.shutdownSubject$.complete();
	}

	setUrl(meetingId: string) {
		if (!meetingId) {
			Logging.warning('A meeting url must be entered');
			return;
		}

		const url = makeZoomUrl(meetingId);
		if (!url) {
			Logging.warning('The provided link is not a valid zoom url ' + meetingId);
			return;
		}

		const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);	

		this.sharedEmbedUrlRef.set(url, error => {
			if(!error){ this.joinMeeting(); }
			else {
				Logging.warning('Cannot save meeting id.')
			}
		});
		
		analyticsService.createdVideoMeeting(this.planState.planId);
	}

	setJitsiUrl(){
		if (!this.planState.projectId || !this.planState.planId) {
			Logging.warning('Something went wrong, try refreshing');
			return;
		}
		const base = 'https://meet.jit.si/';
		const tpId = this.planState.projectId + ',' + this.planState.planId;

		const combination = base + tpId;

		this.setUrl(combination);
	}

	sanitizeUrl(url: string){
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
		// return this.sanitizer.bypassSecurityTrustResourceUrl('https://zoom.us/wc/'+ this.meetingId +'/join?prefer=1&un=dG9tbXk=');
	}

	joinMeeting(){
		this.meetingJoined$.next(true);
		analyticsService.joinedVideoMeeting(this.planState.planId);
	}

	endMeeting(){
		this.sharedEmbedUrlRef.set(null);
		this.meetingJoined$.next(false);
	}

	close(){
		this.closePopup.emit();
	}
	
}
