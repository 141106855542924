import {IFontData, Utils, ITriangulateCalcData, FONT_TRIG_DATA, IFontOptions} from "./";
import {LocalStorageHelper} from "../utils/";
import {ITriangulateData} from "./Utils";
import {ILayoutData, WordWrapper} from "./WordWrapper";

import * as utils from "utils";
/**
 * Interface to define the cache data structure
 */
export interface IGlyphCacheFamily {
	[key: string]: any
}
/**
 * Interface to control the initial font data that is loaded initially
 */
export interface IGlyphCacheInit {
	family: string;
	data: ITriangulateCalcData
}
/**
 * Singleton class which manages our cache of vertex and index data for each glyph.
 */
class GlyphCache {
	private _cache: Map<string, IGlyphCacheFamily> = new Map();
	
	constructor(caches: IGlyphCacheInit[] = []) {
		caches.forEach((cache: IGlyphCacheInit) => {
			this.fromTrigJson(cache.family, cache.data);
		});
	}
	
	/**
	 * Takes a pre-calculated object of triangulated glyphs and adds it to the cache
	 * @param {string} fontFamily The font family to add this data too
	 * @param {ITriangulateCalcData} data The pre-calculated glyphs
	 */
	public fromTrigJson(fontFamily: string, data: ITriangulateCalcData): void {
		// Update internal cache to match localStorage if exists
		if (LocalStorageHelper.isSameVersion(fontFamily, data.VERSION)) {
			this._cache.set(fontFamily, LocalStorageHelper.read(fontFamily));
		} else {
			// We either don't have localStorage data OR the cache data is newer than the localStorage. In which case, reset
			// Check to see if family already has data
			let familyData: IGlyphCacheFamily = this._cache.get(fontFamily) || {};
			for (let key in data) {
				familyData[key] = data[key];
			}
			this._cache.set(fontFamily, familyData);
			// Safely write to localStorage
			LocalStorageHelper.write(fontFamily, familyData);
		}
	}
	
	private cloneChar(char: ITriangulateCalcData): ITriangulateCalcData{
		let newChar = {};
		//array data
		["indexData", "vertexData"].forEach(key => { 
			if(char[key]){ newChar[key] = [...char[key]];}
		});
		//simple data
		["vertexCount", "VERSION", "FONT_SIZE"].forEach((key)=>{
			if(char[key] !== undefined){ newChar[key] = char[key]; }
		});
		
		return newChar as any;
	}
	
	/**
	 * Returns the Triangulation data for a single character.
	 *
	 * If the char does not exist in the Glyph cache, it will be triangulated and added in
	 * @param {string} fontFamily The font family to pull the triangulation data from
	 * @param {IFontData} fontData The Font data. Will triangulate if the data is not in the cache
	 * @param {string} char
	 */
	public getChar(fontFamily: string, fontData: IFontData, char: string): ITriangulateCalcData {
		let familyData: IGlyphCacheFamily = this._cache.get(fontFamily) || {};
		if (familyData && familyData[char]) {
			// Need to return a new object, so we don't pollute the cache (Dammit JavaScript and yo references)
			// return JSON.parse(JSON.stringify(familyData[char]))
			return this.cloneChar(familyData[char]);
		}
		console.log(char + " NO EXIST?!");
		// Char is not calculated. Do that now...
		const fontSize = this.getFontSize(fontFamily) || 44;
		const data: ITriangulateCalcData = Utils.triangulateChar(fontData, char, fontSize);
		familyData[char] = data;
		// Update localStorage
		LocalStorageHelper.write(fontFamily, familyData);
		return this.cloneChar(data);
	}
	
	/**
	 * Returns an array of Triangulation data.
	 *
	 * NOTE: The vertices in the cache are all generated assuming and X and Y value of 0,0. So you'll need to manipulate
	 * the vertices to reflect the world position of your object before drawing.
	 * @param {string} fontFamily The font family to retrieve the glyph from
	 * @param {IFontData} fontData The Font data. Will triangulate if the data is not in the cache
	 * @param {string} str The string to get triangulation data for
	 */
	public getString(fontFamily: string, fontData: IFontData, str: string): ITriangulateCalcData[] {
		let result: ITriangulateCalcData[] = [];
		for (let i = 0; i < str.length; i++) {
			const char: string = str.charAt(i);
			result.push(this.getChar(fontFamily, fontData, char));
		}
		return result;
	}
	
	/**
	 * Gets the vertex data from cache and process each vertex to apply the object position offset
	 * @param {string} fontFamily FontFamily of the glyph to retrieve
	 * @param {IFontData} fontData The FontData. Used to generate the glyph if it does not exist
	 * @param {string} char The character to retrieve triangulation data for
	 * @param {number} x The X position of the object
	 * @param {number} y The Y position of the object
	 * @param {number} indexOffset the amount to offset the indices by
	 *
	 * // TODO: This step might be offloaded to the GPU!
	 */
	public offsetTrigDataForChar(fontFamily: string, fontData: IFontData, char: string, x: number, y: number, indexOffset: number): ITriangulateCalcData {
		let result = this.getChar(fontFamily, fontData, char);
		// Iterate through the vertices and apply offset
		for (let i = 0; i < result.vertexData.length; i += 2) {
			result.vertexData[i] += x;
			result.vertexData[i + 1] += y;
		}
		// Iterate through the indices and apply offset
		for (let i = 0; i < result.indexData.length; i++) {
			result.indexData[i] += indexOffset;
		}
		return result;
	}
	
	public offsetTrigDataForCharAsync(fontFamily: string, fontData: IFontData, char: string, x: number, y: number, indexOffset: number): Promise<ITriangulateCalcData> {
		return new Promise((resolve)=>{
			setTimeout(()=>{
				resolve( this.offsetTrigDataForChar(fontFamily, fontData, char, x, y, indexOffset) )
			})
		});
	}
	
	/**
	 * Return a flattened object that can be fed to the GPU for drawing
	 * @param {string} fontFamily
	 * @param {IFontData} fontData
	 * @param {IFontOptions} fontOptions
	 * @param {ILayoutData} layout
	 * @param {string} text
	 */
	public getMergedString(fontFamily: string, fontData: IFontData, fontOptions: IFontOptions, layout: ILayoutData, text: string): ITriangulateData {
		let totalOffset: number = 0;
		let vertex: any[] = [];
		let index: any[] = [];
		const sizeRatio: number = this.getFontSize(fontFamily) / fontOptions.fontSize;
		layout.glyphs.forEach((glyph, idx) => {
			const x: number = WordWrapper.getPxUnits(fontData, fontOptions.fontSize, glyph.position[0]) * sizeRatio;
			const y: number = -WordWrapper.getPxUnits(fontData, fontOptions.fontSize, glyph.position[1]) * sizeRatio;
			const char: string = text.charAt(idx);
			if (char === "\n"){return}
			const data: ITriangulateCalcData = this.offsetTrigDataForChar(fontFamily, fontData, glyph.char, x, y, totalOffset);
			vertex.push(data.vertexData);
			index.push(data.indexData);
			
			totalOffset += data.vertexCount;
		});
		
		vertex = utils.flattenArray(vertex);
		index = utils.flattenArray(index);
		
		return {
			vertexData: new Float32Array(vertex),
			indexData: new Uint16Array(index),
			vertexCount: totalOffset
		}
	}
	
	public getMergedStringAsync(fontFamily: string, fontData: IFontData, fontOptions: IFontOptions, layout: ILayoutData, text: string): Promise<ITriangulateData> {
		return new Promise((resolve)=>{
			setTimeout(()=>{ resolve(this.getMergedString(fontFamily, fontData, fontOptions, layout, text)) })
		})
	}
	
	/**
	 * Returns the FONT_SIZE used to generate the vertices and indices in the cached data
	 * @param {string} fontFamily
	 */
	public getFontSize(fontFamily: string): number {
		const familyData: IGlyphCacheFamily = this._cache.get(fontFamily);
		return familyData.FONT_SIZE || 0;
	}
}

export const glyphCache = new GlyphCache([
	{
		family: "Roboto Medium",
		data: FONT_TRIG_DATA.ROBOTO_MEDIUM
	},
	{
		family: "Roboto",
		data: FONT_TRIG_DATA.ROBOTO
	},
	{
		family: "Roboto Black",
		data: FONT_TRIG_DATA.ROBOTO_BLACK
	}]
);

window.glyphCache = glyphCache;
