/**
 * @name multipleRolesUtils
 * @desc A little utility service so I don't have to duplicate the same code across different controllers....
 */
import * as angular from "angular";
angular.module('ticketspaceApp').factory("multipleRolesUtils", MultipleRolesUtils);

MultipleRolesUtils.$inject = ["accessCheck"];
function MultipleRolesUtils(accessCheck){
	/**
	 * @function parseRoleList
	 * @desc Turns an array from the UI select into a dictionary.
	 * @param list {Array}
	 */
	function parseRoleList(list: Array<object>){
		let dictionary = {};
		list.forEach(function(role: any){
			dictionary[role.$id] = role.$id;
		});
		return dictionary
	}
	
	/**
	 * @function countRoles
	 * @desc Count the number of roles assigned to a user
	 * @param {Object} projectMember
	 * @param {Object} fbRoles
	 * @returns {number} The total number of secondary roles.
	 */
	function countRoles(projectMember: any, fbRoles: any){
		let length = (fbRoles && fbRoles.length) ? fbRoles.length : 0;
		if (!projectMember || projectMember.accessLevel === 'Admin'){return length;}
		if(!fbRoles){ return 0; }
		let count = 0;
		let roles = projectMember.secondaryRoles || {};
		if (projectMember.roleId){roles[projectMember.roleId] = projectMember.roleId;}
		for (let key in roles){
			// MOC-2666
			if (fbRoles.$getRecord(key)){
				count++;
			}
		}
		return count;
	}
	/**
	 * @function filterRoleList
	 * @desc Generates an array of unselected roles.
	 * @param allRoles {Array} The list of all roles in the project
	 * @param [primaryRoleId] {String} The primary role ID of the project member
	 * @param [secondaryRoleList] {Array|Object} The list of secondary roles this project member has. If array, it is parsed and turned into an object
	 * @returns {Array}
	 */
	function filterRoleList(allRoles: Array<object>, primaryRoleId?: string, secondaryRoleList?: any){
		if (secondaryRoleList && Array.isArray(secondaryRoleList)){secondaryRoleList = parseRoleList(secondaryRoleList);}
		secondaryRoleList = secondaryRoleList || {};
		primaryRoleId = primaryRoleId || null;
		let roleList = [];
		if (primaryRoleId){secondaryRoleList[primaryRoleId] = primaryRoleId;}
		allRoles.forEach(function(role: any){
			if (role.$id !== secondaryRoleList[role.$id]){
				roleList.push(role);
			}
		});
		return roleList;
	}
	/**
	 * @function createRoleList
	 * @desc Creates a list of roles using the assigned role list.
	 * @param roles {object} The roles
	 * @param projectMember {Object} The project member
	 * @returns {Array} An array of Roles
	 */
	function createRoleList(roles: any, projectMember: any){
		if (projectMember && projectMember.accessLevel && projectMember.accessLevel === 'Admin'){return roles.slice()}
		projectMember = (projectMember.data) ? projectMember.data : projectMember;
		let roleList = [];
		let rolesDictionary = projectMember.secondaryRoles || {};
		if (projectMember.roleId){rolesDictionary[projectMember.roleId] = projectMember.roleId;}
		for (let roleId in rolesDictionary){
			let linkedRole = roles.$getRecord(roleId);
			if (linkedRole){roleList.push(linkedRole);}
		}
		return roleList;
	}
	/**
	 * @function convertSecondaryRoles
	 * @desc Takes a projectMember object and flattens the array-based list to prepare for a Firebase write.
	 * @param projectMember {ProjectMember}
	 * @returns {ProjectMember}
	 */
	function convertSecondaryRoles(projectMember: any){
		projectMember.secondaryRoles = {};
		projectMember.roles.forEach(function(role){
			projectMember.secondaryRoles[role.$id] = role.$id;
		});
		//projectMember.role = projectMember.roles[0];
		projectMember.roles = null;
		return projectMember
	}
	/**
	 * @function parseSecondaryRoles
	 * @desc Turns a flat secondaryRoles object and links each to a Role object. Typically used to turn assigned roles into a selectablelist
	 * @param roles {Array} The role list
	 * @param projectMember {Object} The project member
	 * @returns {Array}
	 */
	function parseSecondaryRoles(roles: any, projectMember: any){
		projectMember = (projectMember && projectMember.data) ? projectMember.data : projectMember;
		let combinedList = projectMember.secondaryRoles || {};
		let newList = [];
		if (projectMember.roleId){combinedList[projectMember.roleId] = projectMember.roleId;}
		for (let key in combinedList){
			let linkedRole = roles.$getRecord(combinedList[key]);
			if (linkedRole){newList.push(linkedRole);}
		}
		return newList;
	}
	function unflattenAssignedRoles(roles: any, secondaryRoles: any, primaryRoleId: string){
		let combinedList = secondaryRoles || {};
		let newList = [];
		if (primaryRoleId){combinedList[primaryRoleId] = primaryRoleId;}
		for (let key in combinedList){
			let linkedRole = roles.$getRecord(combinedList[key]);
			if (linkedRole){newList.push(linkedRole);}
		}
		return newList;
	}
	return{
		convertSecondaryRoles: convertSecondaryRoles,
		filterRoleList: filterRoleList,
		parseRoleList: parseRoleList,
		createRoleList: createRoleList,
		countRoles: countRoles,
		parseSecondaryRoles: parseSecondaryRoles,
		unflattenAssignedRoles: unflattenAssignedRoles
	}
}
