'use strict';

import * as angular from "angular";

angular.module('ticketspaceApp')
//### addStatic(constructor, statics)
// Adds the list of static properties to the constructor and prototype of the passed in object
// * constructor - a function intended to be called with new
// * statics - an object containing "things" to be added. Uses standard copy rules.
// So functions and primitives are usually safe, objects will maintain references.
.factory('addStatic', function() {
	function addProps(target, src){
		for(var key in src){ //add has own check
			if(src.hasOwnProperty(key)){
				if(!target[key]){
					target[key] = src[key];
				}
			}
		}
	}
	function add(constructor, statics){
		if(!constructor || !statics){ console.log('nothing to do...'); return;}
		//consider object/function checks
		addProps(constructor, statics);
		addProps(constructor.prototype, statics);
	}
	
	return {
		all: add,
		direct: addProps
	}
});
