import { Camera } from "./";
import { utils } from "pixi.js";
/**
 * enum
 */
export enum LEVEL_OF_DETAIL_TABLE {
	"MAXIMUM" = 0.60,
	"VERY_HIGH" = 0.40,
	"MEDIUM" = 0.20,
	"LOW" = 0.10,
	"VERY_LOW" = 0.01,
	"ALWAYS" = -Infinity,
	"START" = -100 // The starting value. Set very high on purpose.
}
window.LevelOfDetaiLEnum = LEVEL_OF_DETAIL_TABLE;
export enum LEVEL_OF_DETAIL_TABLE_STRINGS {
	"MAXIMUM" = "MAXIMUM",
	"VERY_HIGH" = "VERY_HIGH",
	"MEDIUM" = "MEDIUM",
	"LOW" = "LOW",
	"VERY_LOW" = "VERY_LOW",
	"ALWAYS" = "ALWAYS",
	"START" = "START" // The starting value. Set very high on purpose.
}

export enum LEVEL_OF_DETAIL_SIGNALS {
	"CHANGED" = "changed",
	"FORCE_LOD" = "forceLOD"
}
/**
 * Level of Detail singleton class that tracks the current detail level.
 */
export class LevelOfDetail{
	public static currentLOD: string = "START";
	public static emitter: utils.EventEmitter = new utils.EventEmitter();
	public static isForced: boolean = false;
	private static _lastLod: string;
	public static getLOD(): string{
		return LevelOfDetail.currentLOD;
	}
	public static getLODLevel(): number{
		return LEVEL_OF_DETAIL_TABLE[LevelOfDetail.currentLOD];
	}
	public static getLastLOD(): string {
		return this._lastLod
	}
	public static getLastLODLevel(): number{
		return LEVEL_OF_DETAIL_TABLE[LevelOfDetail._lastLod];
	}
	public static update(camera: Camera, force: boolean = false){
		if (camera && camera.cameraContainer && !LevelOfDetail.isForced){
			for (let level in LEVEL_OF_DETAIL_TABLE){
				let convertedNumber = parseFloat(level);
				if (!isNaN(convertedNumber)){
					if (camera.cameraContainer.scale.x >= convertedNumber){
						LevelOfDetail._lastLod = LevelOfDetail.currentLOD;
						LevelOfDetail.currentLOD = LEVEL_OF_DETAIL_TABLE[convertedNumber];
						break;
					}
				}
			}
		}
		if (LevelOfDetail.getLODLevel() !== LevelOfDetail.getLastLODLevel() || force){
			LevelOfDetail.emitter.emit(LEVEL_OF_DETAIL_SIGNALS.CHANGED, LevelOfDetail.getLODLevel(), LevelOfDetail.getLastLODLevel());
		}
	}
	
	public static forceUpdate(){
		LevelOfDetail.emitter.emit(LEVEL_OF_DETAIL_SIGNALS.CHANGED, LevelOfDetail.getLODLevel(), LevelOfDetail.getLastLODLevel());
	}
	
	/**
	 * Forces the Detail Level to the provided value
	 * @param {LEVEL_OF_DETAIL_TABLE} value
	 */
	public static forceLOD(value: LEVEL_OF_DETAIL_TABLE_STRINGS) {
		LevelOfDetail._lastLod = LevelOfDetail.currentLOD;
		LevelOfDetail.currentLOD = value;
		LevelOfDetail.forceUpdate();
		LevelOfDetail.emitter.emit(LEVEL_OF_DETAIL_SIGNALS.FORCE_LOD, LevelOfDetail.getLODLevel(), LevelOfDetail.getLastLODLevel());
		LevelOfDetail.isForced = true;
	}
	
	public static clearForcedLOD(): void {
		LevelOfDetail.isForced = false;
	}
	
	public static reset(){
		LevelOfDetail.currentLOD = "START";
	}
}

window.lod = LevelOfDetail;