/**
 * @namespace CanvasRenderer
 * @description Namespace containing all classes used to power the TouchPlan canvas-based renderer.
 */
import { Observable, Subject, fromEvent, merge, zip, interval, empty } from "rxjs";
import { map, sample, tap, takeUntil } from "rxjs/operators";
import { CanvasRendererInterface, FancyInterface } from "../interfaces/";
import { Application, Container, WebGLRenderer, CanvasRenderer, Rectangle, autoDetectRenderer, utils, ticker, settings} from "pixi.js";
import { FancyContainer } from "../graphics/FancyContainer";
import { PlanState } from "../../common/services/plan-state.service";
import { TicketMapper } from "../data-mappers/";
import { TicketManager } from "../tickets/";
import { LayerManager, LAYER_NAMES } from "../layers/";
import { Camera, LevelOfDetail, APPLICATION_EVENTS, rendererInfo, RenderDependencies } from './';
import { FancyGraphics } from "../graphics/";
import { fontManager } from "../fontpath/";
import * as HammerWrapper from "./HammerWrapper";
import * as GlobalUtils from "utils";
import { FancyPinHistory } from "../pin-history/FancyPinHistory";
import {PlanDrag, PlanPinch, PlanTap, SelectionBox, SelectionBoxId, HoldCircle} from "ng2/canvas-renderer/interaction";
import { ComponentManager } from "../component-system/";
import { renderQueue } from "../../common/RenderQueue";
import { DependencyLines } from "../non-tickets/DependencyLines";
import { TweenManager } from "../tween/";
import { FancySwimlanes } from "../swimlanes/FancySwimlanes";
// DEBUG SHIT
import { RenderTime } from "../debug/RenderTime";
import { FontTriangulator } from "../debug/FontTriangulator";
import { sessionStorage } from "../../common/services/SessionStorage";

/**
 * Starts up a new PIXI.WebGLRenderer or PIXI.CanvasRenderer
 * @memberOf CanvasRenderer
 */
export class RendererApplication extends utils.EventEmitter{
	private shutdownSubject$ = new Subject();
	public preRender$ = new Observable().pipe(takeUntil(this.shutdownSubject$));
	public postRender$ = new Observable().pipe(takeUntil(this.shutdownSubject$));
	
	public swimlanes: FancySwimlanes;
	
	/**
	 * Components on the RendererApplication (Usually interaction)
	 */
	public components: ComponentManager;
	/**
	 * Local instance of PIXI.Application.
	 */
	public application: Application;
	/**
	 * The root display node of our scene graph.
	 */
	public stage: FancyContainer;
	/**
	 * The container that contains the
	 */
	public cameraContainer: FancyContainer;
	/**
	 * The canvas this renderer is currently bound to.
	 */
	public view: HTMLCanvasElement;
	/**
	 * The renderer this application is using. Either PIXI.WebGLRenderer or PIXI.CanvasRenderer
	 */
	public renderer: WebGLRenderer|CanvasRenderer;
	/**
	 * Instance of TicketMapper
	 */
	public ticketMapper: TicketMapper;
	/**
	 * Instance of TicketManager
	 */
	public ticketManager: TicketManager;
	/**
	 * Instance of LayerManager
	 */
	public layerManager: LayerManager = new LayerManager();
	/**
	 * Instance of the TweenManager
	 */
	public tweenManager: TweenManager;
	/**
	 * An instance of the camera which provides our view into the TouchPlan world
	 */
	public camera: Camera;
	/**
	 * An object that contains data for the screen dimensions and position
	 */
	public screen;
	/**
	 * Instance of a TweenManager. This manages all tweens.
	 */
	public pinHistory: FancyPinHistory;
	/**
	 * A single instance of the FancyGraphics object class. Used to draw things in the world.
	 */
	public _graphics: FancyGraphics = new FancyGraphics();
	public uiGraphics: FancyGraphics = new FancyGraphics();
	private hammerConnector;
	private debug;
	private simpleDestructionList = [];
	
	private backgroundGestureContainer: FancyContainer = new FancyContainer();
	
	/**
	 * @param planState
	 * @param initOptions
	 */
	constructor(public planState: PlanState, initOptions: CanvasRendererInterface){
		super();
		// Create a new PIXI Application.
		this.backgroundGestureContainer.type = "plan";
		this.components = new ComponentManager(this);
		rendererInfo.renderer = this.renderer = autoDetectRenderer(initOptions);
		//this.renderer.view = initOptions.view;
		this.stage = new FancyContainer();
		this.cameraContainer = new FancyContainer();
		this.view = initOptions.view;
		this.screen = this.renderer.screen;
		this.tweenManager = new TweenManager(this);
		
		this.hammerConnector = HammerWrapper.init(this);
		this.screwinAroundWithGestures();
		

		
		/**
		 * !DEBUG CRAP!
		 */
		this.debug = new RenderTime('', {
			fontSize: 12
		}, null);//this.stage);
		
		this.layerManager.addLayer(LAYER_NAMES.BACKGROUND, this.stage);
		
		this.stage.addChild(this.cameraContainer);
		
		// Create our Layers.
		// Our layers represent the base drawing order of our scene.
		this.layerManager.addLayer(LAYER_NAMES.TICKETS, this.cameraContainer);
		this.layerManager.addLayer(LAYER_NAMES.TICKET_DRAG, this.cameraContainer);
		this.layerManager.addLayer(LAYER_NAMES.WORLD_ABOVE, this.cameraContainer);
		this.layerManager.addLayer(LAYER_NAMES.UI, this.stage);
		
		this.camera = new Camera(this.cameraContainer, this.stage, this.tweenManager, this.cameraContainer.x, this.cameraContainer.y, this.screen.width, this.screen.height);
		this.camera.worldGraphics = this._graphics;
		
		// Setup world graphics instance
		this.layerManager.getLayer(LAYER_NAMES.WORLD_ABOVE).addChild(this._graphics);
		this.layerManager.getLayer(LAYER_NAMES.UI).addChild(this.uiGraphics);
		
		// Only init some things only when the required textures have been loaded.
		window.perf.log('renderer application init');
		RenderDependencies.loaded$.pipe(takeUntil(this.shutdownSubject$)).subscribe(() => {
			// HoldCircle
			this.components.add('planHold', new HoldCircle(), this.planState, this.backgroundGestureContainer, this.tweenManager, this.safeRender);
			
			// Mark Canvas as loaded
			this.swimlanes = new FancySwimlanes(planState, this.layerManager.getLayer(LAYER_NAMES.BACKGROUND), this.layerManager.getLayer(LAYER_NAMES.UI), this.camera, this);
			// Create our PinHistory renderer
			this.pinHistory = new FancyPinHistory(planState, this.camera, this.safeRender);
			
			// planState.settingStorage.innerChanged$.next({key: null, value: null});
			
			this.simpleDestructionList.push(this.pinHistory);
			
			this.planState.assignCanvasConnector(this);
			
			window.perf.log("canvas dependencies loaded", {previousEventName: "renderer application init"});
			
			// Dispatch the LOD
			LevelOfDetail.update(this.camera);
			LevelOfDetail.forceUpdate();
			//ubsubLoaded.unsubscribe();
		});
		
		// Create instance of our TicketMapper.
		// This registers an observer on the passed in Observable/Subject.
		// Every time an update is received, it maps the data to a corresponding DataContainer. If no DataContainer exists, it creates one.
		// The TickerMapper exposes a new Observable which fires every time a DataContainer is updated. It passes the DisplayObject representing the DataContainer to all observers.
		// The first parameter is the DisplayObject where new DataContainers will be created.
		let mergedTicketEvents = merge(planState.tickets.soakedRaw$, planState.ticketDragService.ghostTickets); //risky new thing
		// mergedTicketEvents = empty(); //for disabling ticket rendering
		
		this.ticketMapper = new TicketMapper(planState, this.layerManager, this.layerManager.getLayer(LAYER_NAMES.TICKETS), mergedTicketEvents);
		
		// Ticket data changed. I don't like this. Fix later.
		this.ticketMapper.emitter.on('draw', ()=>{
			this.ticketManager.update$.next();
		});
		
		const unsubFirstDraw = this.ticketMapper.firstDraw$.subscribe(() => {
			RenderDependencies.firstDraw = true;
			this.run();
			
			console.log("FIRST DRAW!");
			
			unsubFirstDraw.unsubscribe();
		});
		
		// Load the fonts
		fontManager.loadFont(require('../../../fonts/Roboto-Bold.ttf'));
		//fontManager.loadFont(require('../../../fonts/Roboto-Regular.ttf'));
		fontManager.loadFont(require('../../../fonts/Roboto-Medium.ttf'));
		fontManager.loadFont(require('../../../fonts/Roboto-Black.ttf'));
		
		// Create instance of Ticket Manager
		// This class is responsible for updating and creating all SpriteTickets and associated them with a DataContainer.
		// We register an Observer to the TicketMapper ticketUpdated$ subject which kicks off the ticket update cycle.
		this.ticketManager = new TicketManager(planState, this.layerManager, this.camera, this.safeRender);
		this.ticketManager.listen(this.ticketMapper.emitter, 'updateData', 'destroyTicket');
		
		this.ticketManager.update$.subscribe(() => {
			this.safeRender();
		});
		
		const cancel = GlobalUtils.throttleEvent("resize", "throttledResize");
		window.addEventListener("throttledResize", this.onResize);
		
		var depLines = new DependencyLines(this.planState, this.cameraContainer, this.camera, this.safeRender);
		this.simpleDestructionList.push(depLines);
		
		sessionStorage.changed$.pipe(
			takeUntil(this.shutdownSubject$),
			sample(planState.renderQueueWithCleanup$)
		).subscribe(() => {
			this.run();
		});
		
		// Set pre and post render observables
		// this.preRender$ = fromEvent(this.renderer, 'prerender');
		// this.postRender$ = fromEvent(this.renderer, 'postrender');
		
		// let evt$ = merge(
		// 	this.preRender$.pipe(
		// 		scan((acc, thing) => acc+1, 0),
		// 		map(count => { return {name: "prerender", extraData: {count: count}}} )
		// 	),
		// 	this.postRender$.pipe(
		// 		scan((acc, thing) => acc+1, 0),
		// 		map(count => { return {name: "postrender", extraData: {count: count}}} )
		// 	),
		// );
		let evt$ = merge(
			this.preRender$.pipe(
				map(count => { return {name: "prerender", extraData: {logSettle: true, spammy: true}}} )
			),
			this.postRender$.pipe(
				map(count => { return {name: "postrender", extraData: {logSettle: true, previousEventName: "prerender", spammy: true}}} )
			),
		);
		
		window.perf.merge(evt$);
		(<any>window).triangulator = FontTriangulator;
		
		this._scaleDown();
		
		//MOC-2868 - test
		/*
		var detached = false;
		var detachees = new Map();
		
		setInterval(()=>{
			console.time('remove ABOVE '+ (detached ? '(adding back)' : ''));
			if(detached){
				this.ticketManager._cache.forEach((t,idx) => {
					let ticketContainer:any = t.parent.parent;
					let det = detachees.get(idx);
					//v1 - most efficient (~1.5ms)
					// ticketContainer.ABOVE.children.push.apply(ticketContainer.ABOVE.children, det);
					//v2 - using pixi properly
					// det.forEach(d => {
					// 	ticketContainer.ABOVE.addChild(d);
					// })
					//v3 - slightly more stable
					ticketContainer.ABOVE.children.push.apply(ticketContainer.ABOVE.children, det);
					ticketContainer.ABOVE.onChildrenChange(0);
				});
				detachees.clear();
			}
			else{
				this.ticketManager._cache.forEach((t,idx) => {
					let ticketContainer:any = t.parent.parent;
					detachees.set(idx, [...ticketContainer.ABOVE.children]);
					//v1 - most efficient
					// ticketContainer.ABOVE.children.length = 0; //empty it
					//v2 - using pixi properly
					// ticketContainer.ABOVE.removeChildren();
					//v3 - slightly more stable
					ticketContainer.ABOVE.children.length = 0; //empty it
					ticketContainer.ABOVE.onChildrenChange(0);
				});
			}
			
			console.timeEnd('remove ABOVE '+ (detached ? '(adding back)' : ''));
			detached = !detached;
			
		}, 5000)
		*/
	
		
		// make er tick
		// let uniqueTickName = ()=>{
		// 	this.render();
		// };
		// let unZoneInterval = window.__zone_symbol__setInterval;
		// unZoneInterval(uniqueTickName,1000);
		// setTimeout( ()=> { (renderQueue as any).sharedTicker.stop(); console.log('profile now')}, 5000)
		
		// DEBUG CODE FOR TESTING SWIMLANE STUFF
		// let graphics = new FancyGraphics();
		// graphics.x = 0;
		// graphics.y = 0;
		//
		// this.layerManager.getLayer(LAYER_NAMES.UI).addChild(graphics);
		//
		// this.camera.changed$.subscribe(() => {
		// 	graphics.clear();
		// 	const y = this.camera.realToScreenY(graphics.y);
		// 	graphics.lineStyle(1, 0x00ff00, 0.2);
		// 	graphics.beginFill(0x00ff00, 0.1);
		// 	graphics.drawRect(0, y, window.innerWidth, 500 * this.camera.scale.y);
		// 	graphics.endFill();
		// })
		//
		// for (let i = 0; i < 1000; i++){
		// graphics.drawDashedRect(0, 100, window.innerWidth, 100, 5);
		// 	graphics.lineStyle(1, 0x00ff00, 1);
		// 	graphics.drawDashedRect(Math.floor((Math.random() * 1440) + 1), Math.floor((Math.random() * 770) + 1), Math.floor((Math.random() * 100) + 10), Math.floor((Math.random() * 100) + 10), 5);
		// }
		this._logGPUInfo();
	}
	
	private _logGPUInfo(): void {
		if (rendererInfo.isWebGL()){
			const gl: WebGLRenderingContext = (<WebGLRenderer>this.renderer).gl;
			const dbgInfo = gl.getExtension('WEBGL_debug_renderer_info');
			if (dbgInfo){
				const vendor = gl.getParameter(dbgInfo.UNMASKED_VENDOR_WEBGL);
				const renderer = gl.getParameter(dbgInfo.UNMASKED_RENDERER_WEBGL);
				console.log("WebGL VENDOR:", vendor);
				console.log("WebGL RENDERER:", renderer);
				analytics.identify({
					webglVendor: vendor,
					webglRenderer: renderer
				})
			}
		}
	}
	
	/**
	 * Test code. Remove me. Probably. Maybe.
	 */
	public screwinAroundWithGestures(){
		(<any>this.backgroundGestureContainer).imUnique = true;
		this.backgroundGestureContainer.hitArea = new Rectangle(0,0, this.renderer.view.width, this.renderer.view.height);
		this.backgroundGestureContainer.interactive = true;
		
		this.stage.addChild(this.backgroundGestureContainer);
		
		this.backgroundGestureContainer.on('hammer-singletap', (event)=>{
			this.planState.actions.abortEdit();
		});
		
		this.components.add('planTap', new PlanTap(), this.planState, this.backgroundGestureContainer);
		this.components.add('planDrag', new PlanDrag(), this.planState, this.backgroundGestureContainer);
		this.components.add('planPinch', new PlanPinch(), this.planState, this.backgroundGestureContainer);
		this.components.add(SelectionBoxId.toString(), new SelectionBox(), this.planState, this.backgroundGestureContainer);
		
		let debugRectContainer = new PIXI.Graphics();
		let added = false;
		
		this.planState.debugRectangles.listener$.subscribe((rectList)=>{
			// console.log('hi', rectList);
			if(rectList.size > 0){
				if(!added){ added = true; this.cameraContainer.addChild(debugRectContainer); }
			}
			else{
				if(added){ added = false; this.cameraContainer.removeChild(debugRectContainer); }
			}
			
			debugRectContainer.clear();
			rectList.forEach((rect)=>{
				debugRectContainer.beginFill(rect.color, 0.3);
				debugRectContainer.drawRect(rect.x, rect.y, rect.width, rect.height);
			});
		});
		
		this.cameraContainer.addChild(debugRectContainer);
		
	}
	/**
	 * Called before an update loop
	 */
	public preUpdate = (delta: number) => {
		if (!this.renderer){return}
		// let a = performance.now();
		this.camera.preUpdate(delta);
		this.emit(APPLICATION_EVENTS.PRE_UPDATE);
		// let b = performance.now();
		// this.debug.preUpdateTime = b - a;
	};
	/**
	 * Calls a single iteration of the update loop. This calls the 'update' function on each ticket and each ticket component.
	 * Useful when you need to run logic every tick update.
	 */
	public update = (delta: number) => {
		// let a = performance.now();
		this.camera.update(delta);
		LevelOfDetail.update(this.camera);
		if (!this.renderer){return}
		this.components.update();
		this.ticketMapper.update();
		if (this.ticketManager.ticketCollision){
			this.ticketManager.ticketCollision.update();
		}
		if (this.pinHistory){
			this.pinHistory.update();
		}
		this.emit(APPLICATION_EVENTS.UPDATE);
		// let b = performance.now();
		// this.debug.updateTime = b - a;
	};
	/**
	 * Called after an update loop
	 */
	public postUpdate = (delta: number) => {
		if (!this.renderer){return}
		// let a = performance.now();
		this.emit(APPLICATION_EVENTS.POST_UPDATE);
		// let b = performance.now();
		// this.debug.postUpdateTime = b - a;
		// this.debug.update();
	};
	
	public safeUpdate = () => {
		renderQueue.push((delta)=>{
			this.preUpdate(delta);
			this.update(delta);
			this.postUpdate(delta);
		}, "update")
		// renderQueue.push(this.preUpdate, "preUpdate");
		// renderQueue.push(this.update, "update");
		// renderQueue.push(this.postUpdate, "postUpdate");
		this.debug.fps = ticker.shared.FPS;
	};
	
	/** Shorthard for pushing render onto the renderQueue */
	public safeRender = ()=>{
		renderQueue.push(this.render, "draw");
	};
	/**
	 * Helper method to render a single display object, or the entire scene
	 */
	public render = () => {
		// This prevents an error that can occur if you leave the plan page in the middle of a draw
		if (!this.renderer){return}
		// let a = performance.now();
		// this.debug.drawCount = this.camera.totalInView;
		this.renderer.render(this.stage);
		// let b = performance.now();
		// this.debug.renderTime = b - a;
		// this.debug.update();
	};
	
	private _scaleDown(): void {
		this.view.style.width = this.renderer.width / this.renderer.resolution + 'px';
		this.view.style.height = this.renderer.height / this.renderer.resolution + 'px';
	}
	
	// MOC-2949
	public printResize(w: number, h: number): void {
		this.renderer.resize(w, h);
		this._scaleDown();
	}
	
	public onResize = (event): RendererApplication => {
		if (!this.planState || !this.planState.scopeSoup){return}
		let w = this.planState.scopeSoup.hud.w;
		let h = this.planState.scopeSoup.hud.h;
		this.renderer.resize(w, h);
		this.camera.resize(w, h);
		this.hammerConnector.updateCache(this.view);
		this.backgroundGestureContainer.hitArea = new Rectangle(0,0, this.renderer.view.width, this.renderer.view.height);
		this._scaleDown();
		this.safeRender();
		return this
	};
	/**
	 * Ticks the renderer and calls preUpdate, update, postUpdate and render functions.
	 */
	public run = () => {
		this.safeUpdate();
		this.safeRender();
	};
	/**
	 * Called when the scene graph should be destroyed. This happens when navigating away from the plan view page usually. This does the following:
	 * 1. Destroys stage, renderer and nulls all references
	 * 2. Gets the textureCache, iterates the list and calls .destroy() on each texture
	 * 3. Gets the baseTextureCache, iterates the list and calls .destroy() on each BaseTexture
	 */
	public destroy(){
		window.perf.log("start destroy");
		this.components.destroy();
		this.ticketMapper.destroy();
		this.ticketManager.destroy();
		this.tweenManager.destroy();
		if (this.swimlanes){this.swimlanes.destroy();}
		window.perf.log("ticketMapper destroyed");
		window.perf.log("start destructionList");
		this.simpleDestructionList.forEach(d => d.destroy ? d.destroy({children: false, texture: false, baseTexture: false}) : null);
		this.simpleDestructionList = null;
		window.perf.log("end destructionList", {previousEventName: "start destructionList"});
		window.perf.log("emit destroy event");
		this.emit(APPLICATION_EVENTS.DESTROY);
		window.perf.log("end emit destroy event", {previousEventName: "emit destroy event"});
		// Only recursively destroy children. We will clean-up textures later...
		// Since we use the same BaseTexture for every ticket instance, trying to destroy the same texture multiple times will result in an error
		window.perf.log("start stage destruction");
		this.stage.destroy({children: false, texture: false, baseTexture: false});
		this.stage = null;
		window.perf.log("end stage destruction", {previousEventName: "start stage destruction"});
		this.renderer.destroy(false);
		this.renderer = null;
		this.hammerConnector.destroy();
		this.shutdownSubject$.next(true);

		this.destroyTextureCacheAsync();
		
		// Remove resize listener
		window.removeEventListener("throttledResize", this.onResize);
		
		LevelOfDetail.emitter.removeAllListeners();
		LevelOfDetail.reset();
		RenderDependencies.isLoaded = false;
		
		this.layerManager.destroy();
		
		this.layerManager = null;
		
		window.perf.log("finished destroying", {previousEvent: "start destroy"});
	}
	
	/** immediately clears the cache and creates a 5ms queue that destroys the textures later on */
	private destroyTextureCacheAsync(){
		let textureCache = Object.assign({}, utils.TextureCache);
		let baseTexture = Object.assign({}, utils.BaseTextureCache);
		utils.clearTextureCache();
		
		let texture$ = new Observable(subscriber =>{
			for(var key in textureCache){ subscriber.next(textureCache[key]); }
			for(var key in baseTexture){ subscriber.next(baseTexture[key]); }
			subscriber.complete();
		});
		let tick$ = interval(5);
		zip(texture$, tick$, (tex, tick)=>tex).pipe(
		).subscribe({
			// next: x => console.log('got value ' + x),
			// error: err => console.error('something wrong occurred: ' + err),
			complete: () => { done(); },
		});
		function done(){
			window.perf.log("end base texture destruction", {previousEventName: "start base texture destruction"});
		}
		window.perf.log("start texture destruction");
	}
}
