import {Ticket} from "./Ticket";

export class FloatingTicket extends Ticket{
	static getId(){
		var id = "-float-"+ FloatingTicket.idCounter;
		FloatingTicket.idCounter++;
		return id;
	}
	static idCounter = 1;
	
	public isAFloatingTicket = true;
	
	constructor(data?){
		super(data, FloatingTicket.getId());
	}
}
