

export class GenericPool<T>{
	protected pool:Array<T> = [];
	protected position = 0;
	
	constructor(protected creationFn: ()=>T, preInitCount = 0){
		
	}
	
	public grab():T{
		let item;
		if(this.position < this.pool.length){ item = this.pool[this.position]; }
		else{ item = this.creationFn(); this.pool.push(item); }
		this.position++;
		return item;
	}
	
	public reset(){
		this.position = 0;
		// If a 'defaultState' object exists, reset the pooled object properties to the default
		this.pool.forEach((item: any) => {
			if (item.defaultState){
				for (let key in item.defaultState){
					item[key] = item.defaultState[key];
				}
			}
		})
	}
	
	//maybe implement as a thing to remove some items that haven't been used in awhile
	private flush(){
		
	}
	
	public destroy(){
		
	}
}

import { Sprite, Container, mesh } from "pixi.js";
import {FancySprite, FancyContainer} from "ng2/canvas-renderer/graphics";

export class SpritePool extends GenericPool<Sprite>{
	constructor(protected creationFn: ()=>Sprite, protected container: Container){
		super(creationFn);
	}
	
	//bah, can't call super because efficiency, yaaaay copy/paste
	public grab():Sprite{
		let item;
		if(this.position < this.pool.length){ item = this.pool[this.position]; }
		else{ 
			item = this.creationFn(); 
			this.pool.push(item);
			this.container.addChild(item);
		}
		this.position++;
		
		item.visible = true;
		// console.log('item', item);
		return item;
	}
	public reset(){
		super.reset();
		this.pool.forEach(s => s.visible = false)
	}
	
	public destroy(options?){
		this.pool.forEach(s => s.destroy(options));
	}
	
	public hide(): void {
		this.pool.forEach(s => s.renderable = false)
	}
	
	public show(): void {
		this.pool.forEach(s => s.renderable = true)
	}
}

export class FancySpritePool extends GenericPool<FancySprite>{
	constructor(protected creationFn: ()=>FancySprite, protected container: FancyContainer, protected onlyPool = false){
		super(creationFn);
	}
	
	//bah, can't call super because efficiency, yaaaay copy/paste
	public grab():FancySprite{
		let item;
		if(this.position < this.pool.length){ 
			item = this.pool[this.position];
			item.attach();
		}
		else{ 
			item = this.creationFn(); 
			item.visible = true;
			this.pool.push(item);
			this.container.addChild(item);
		}
		this.position++;
		return item;
	}
	public reset(){
		super.reset();
		if(this.onlyPool){this.container.detachAllChildren(); }
		else{ this.container.detachChildren(this.pool); }
	}
	public destroy(options?){
		this.pool.forEach(s => s.destroy(options));
	}
	public hide(): void {
		this.container.detachChildren(this.pool);
	}
	public show(): void {
		this.container.attachChildren(this.pool);
	}
}

export class PlanePool extends GenericPool<mesh.Plane>{
	constructor(protected creationFn: ()=>mesh.Plane, protected container: Container){
		super(creationFn);
	}
	
	//bah, can't call super because efficiency, yaaaay copy/paste (x2)
	public grab():mesh.Plane{
		let item;
		if(this.position < this.pool.length){ item = this.pool[this.position]; }
		else{
			item = this.creationFn();
			this.pool.push(item);
			this.container.addChild(item);
		}
		this.position++;
		
		item.visible = true;
		// console.log('item', item);
		return item;
	}
	public reset(){
		super.reset();
		this.pool.forEach(item => item.visible = false);
	}
	
	public destroy(){
		this.pool.forEach(s => s.destroy());
	}
}
