import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { BehaviorSubject, Observable, empty } from 'rxjs';
import { debounce, map, first, mergeMap, takeUntil } from 'rxjs/operators';
import { drop } from 'ng2/common/rxjs-internal-extensions';
import * as moment from "moment";

import {renderQueue} from "ng2/common/RenderQueue";

import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyJoinRequirements } from 'ng2/fancy-firebase/base';
import {FancySupplementalAction, SupplementalActionTypes} from 'ng2/actions/supplemental-actions';

import { ActiveColumnService } from "ng2/common/ajs-upgrade-providers";

export class PullColumn{
	finishX: number;
	pullColumn: number;
	startX: number;
	tminusFinish: number;
	plannedStart?: string;
	plannedFinish?: string;
	
	constructor(obj){
		this.update(obj);
	}
	update(obj){
		this.finishX = obj.finishX;
		this.pullColumn = obj.pullColumn;
		this.startX = obj.startX;
		this.tminusFinish = obj.tminusFinish;
		this.plannedStart = obj.plannedStart;
		this.plannedFinish = obj.plannedFinish;
	}
}

interface PullColumnsAngularLandConnection extends AngularLandConnection{
	activeColumnService: ActiveColumnService
}



// via supplementalEvents method:
// - create a pullColumns fancyfirebaselist
// - handle the added/removed/updated thing and rip off the date cache code from the old pullColumns list to build a cache of dates to positions
// - map in the activeColumn service and the activeline if it's not in the activeColumn service
// ** if guessX is used, which it should be able too, we won't need to watch for changes in the service
// - add a getXFromDate method that accepts a date and uses all the above to return a "best guess" x value
// 
// naive version:
// - store a timelineX value on the timelineTicket
// - pass in the timeline list observable via supplementalEvents
// - on change check all the timeline tickets, run the getXFromDate function against the livePlannedFinish
// - if the value is different, update it, and emit, otherwise suppress
// - when a ticket updates, run the getXFromDate function and update the value
// possible optimizations
// - nothing really comes to mind, timeline lines tend up update en-mass, anything involving a ticket cache ties to two together quite tightly




export class PullColumnList extends FancyFirebaseList<PullColumn, FancyAction>{
	private dateIdx: Map<string, string>;
	private milestoneData?: {x: number, date: string};
	public date$: BehaviorSubject<Map<string, PullColumn>> = new BehaviorSubject(new Map());
	
	constructor(protected angularLand:PullColumnsAngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
		
		let time = renderQueue.ob$.pipe(
			drop(2), 
			takeUntil(this.shutdownSubject)
		);
		
		this.list$.pipe(
			debounce(()=> time),
			map((curr:Map<string, PullColumn>)=>{
				return this.recalcDateCache(curr);
			})
		).subscribe(this.date$);
		// this.dateToX$.subscribe((thing)=>{
		// 	console.log('thing', thing);
		// })
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		return new PullColumn(action.payload.val());
	}
	$$updated(child:PullColumn, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.update(action.payload.val());
	}
	
	
	
	getXFromDate (queryDate:string, dateCache?: Map<string, PullColumn>){
		if(!queryDate){ return null; }
		if(!dateCache){
			this.date$.pipe(first()).subscribe((list)=>{ dateCache = list; })
		}
		let milestoneColumn = this._internalList.get("0");
		
		//it's on the active side, use guess date
		if(this.angularLand.activeColumnService.activeLine && queryDate <= this.angularLand.activeColumnService.activeLine.date){
			return this.angularLand.activeColumnService.guessXFromDate(queryDate);
		}
		//if it's among the columns, use those
		else if(dateCache.has(queryDate)){
			// console.log('queryDate', dateCache.get(queryDate).finishX);
			return dateCache.get(queryDate).finishX;
		}
		//if there's a milestone and it's greater put it at milestone + 300
		// else if(this.milestoneData && this.milestoneData.date && this.milestoneData.x !== undefined && queryDate > this.milestoneData.date){
		// 	return this.milestoneData.x + 300;
		// }
		else if(milestoneColumn && queryDate > milestoneColumn.plannedFinish){
			return milestoneColumn.finishX + 300;
		}
		//if there's an active space and it makes it this far, put it at activeLine + 300
		else if(this.angularLand.activeColumnService.activeLine){
			return this.angularLand.activeColumnService.activeLine.x + 300;
		}
		
		//no logical place to put it, it can go at 12 - DEVTEXT
		return 12;
	}
	
	private recalcDateCache(pullColumns:Map<string, PullColumn>){
		var cache = new Map<string, PullColumn>();
		pullColumns.forEach(function(p){
			if(!p.finishX || !p.plannedFinish){ return; }
			cache.set(p.plannedFinish, p);
			if(p.plannedFinish !== p.plannedStart){
				var failsafe = 90;
				var count = 0;
				var lastDate = moment(p.plannedStart, 'YYYY-MM-DD');
				var date = moment(p.plannedFinish, 'YYYY-MM-DD');
				while(date.subtract(1,'days').isSameOrAfter(lastDate) && count++ < failsafe){
					cache.set(date.format('YYYY-MM-DD'), p);
				}
			}
		});
		return cache;
	}
	
	
	
	handleSupplementalEvents(baseObservable):Observable<any>{
		return baseObservable.pipe(
			mergeMap((action:FancySupplementalAction) =>{
				console.log('action', action);
				switch(action.type){
					case SupplementalActionTypes.milestoneUpdated:
						this.milestoneData = action.payload;
						return this.date$.pipe(first());
					default: return empty();
				}
			})
		)
	}
	
	//put in interface so you can explicitly say you're doing this
	// applyJoinData(baseKey: string, child, otherChild:any){
	// 	switch(baseKey){
	// 		case "locationColor":
	// 			child.data.color = otherChild ? otherChild.color: null;
	// 			break;
	// 		default: break;
	// 	}
	// }
	// getDataForKey(baseKey: string, child){
	// 	return child.data[baseKey];
	// }
	// getPrevDataForKey(baseKey: string, child){
	// 	return child.prevData[baseKey];
	// }
}
