import {Ticket, TicketFields} from "ng2/common/models/Ticket";

import { dateCache } from "ng2/common/date-cache";

import * as clone from "clone";

export interface IdMapConfig{
	roleId?: {[key: string]: string},
	assignedProjectMemberId?: {[key: string]: string},
	creatorId?: {[key: string]: string},
	responsibleProjectMemberId?: {[key: string]: string},
	locationId?: {[key: string]: string},
	priorityId?: {[key: string]: string},
	projectMemberId?: {[key: string]: string},
	procoreRfiId?: {[key: string]: string},
	varianceId?: {[key: string]: string} //special handling
}

export interface SrcData{
	activeLineX: number;
	activeLineDate: string;
	planId: string;
	projectId: string;
}

let idMapKeys = ["roleId", "assignedProjectMemberId", "creatorId", "responsibleProjectMemberId", "locationId", "priorityId", "procoreRfiId"];

function isTicketArray(arg:any): arg is Array<Ticket> {
	return arg && arg[0] && arg[0].$id !== undefined;
	// return arg.$id !== undefined;
}

function isMap<T>(arg:any): arg is Map<string,T>{
	return arg.size !== undefined;
}

type SupportiveTicketArray = Array<Ticket> | Map<string, Ticket> | Array<TicketFields> | Map<string, TicketFields>;

export class TicketCopy{
	private srcTickets:Array<TicketFields>;
	private timelineTicketExistanceMap:Map<string, any>;
	private srcData: SrcData;
	
	
	// copy, save the .srcTickets somewhere, paste, wait a bit for fbdb to do things, copy the pastedTickets, 
	// call compareDatas with the old data and the new data
	//...this isn't going to be helpful on second thought, too much data changing on copys
	compareDatas(passedIn, local?){
		let allGood = true;
		let fields = ["activityName", "actualFinish", "createdAt", "crewSize", "dateRequested", "dateRequired", "dateRequiredOverride", "isActualized", "isLateConstraintRequested", "isLateTicketFinish", "isOutOfOrder", "lastPlannedFinish", "lastPlannedStart", "notes", "plannedFinish", "plannedStart", "promisePeriodPosition", "type"];
		if(!local){local = this.srcTickets}
		
		if(passedIn.length !== local.length){ console.log('length mismatch'); return false;}
		
		fields.forEach(fieldName => {
			passedIn.forEach((ticketData, idx)=>{
				if(ticketData[fieldName] !== undefined && ticketData[fieldName] !== local[idx][fieldName]){
					console.log('mismatch for fieldName ', fieldName, " at idx ", idx, ticketData, local[idx]); allGood = true;
				}
			});
			local.forEach((ticketData, idx)=>{
				if(ticketData[fieldName] !== undefined && ticketData[fieldName] !== passedIn[idx][fieldName]){
					console.log('mismatch for fieldName ', fieldName, " at idx ", idx, ticketData, local[idx]); allGood = true;
				}
			});
		});
		return allGood;
	}
	
	private getTicketDataArray(ticketList: SupportiveTicketArray){
		let processedArray: Array<TicketFields>;
		
		//handle maps or arrays
		let ticketArray:Array<Ticket> | Array<TicketFields>;
		if(isMap<Ticket | TicketFields>(ticketList)){
			ticketArray = Array.from((ticketList as any).values())
		}
		else{ ticketArray = ticketList; }
		
		//handle tickets or ticketFields
		if(isTicketArray(ticketArray)){
			console.log('isTicketArray');
			// processedArray = ticketArray.map(t => t.dumpDatabaseRepresentation());
			processedArray = ticketArray.map(t => t.rawDatabase);
		}
		else{
			processedArray = ticketArray;
		}
		return processedArray;
	}
	
	//technically supports checking both sides, but in theory there's no good reason why we shouldn't be able to overspecify
	// the passed in config. For now consider A to be the calculated map and b to be the passed in map
	private compareMaps(mapA: IdMapConfig, mapB: IdMapConfig){
		if(!mapA || !mapB){ return false; }
		
		function checkOneSide(mapA: IdMapConfig, mapB: IdMapConfig){
			for(var key in mapA){
				if(!mapB[key]){ console.log('missing key', key); return false; }
				for(var id in mapA[key]){
					if(!mapB[key][id]){ console.log('missing key', key, id); return false; }
				}
			}
			return true;
		}
		
		return checkOneSide(mapA, mapB);// && checkOneSide(mapB, mapA);
	}
	
	private iterVariance(ticket, cb){
		if(ticket.promisePeriodStack){
			for(var ppId in ticket.promisePeriodStack){
				if(ticket.promisePeriodStack[ppId] && ticket.promisePeriodStack[ppId].varianceId){
					cb(ticket.promisePeriodStack[ppId].varianceId, ticket.promisePeriodStack[ppId])
				}
			}
		}
	}
	
	public expandProjectMember(config: IdMapConfig){
		if(config && config.projectMemberId){
			config.assignedProjectMemberId = config.projectMemberId;
			config.responsibleProjectMemberId = config.projectMemberId;
			config.creatorId = config.projectMemberId;
		}
		return config;
	}
	public joinProjectMember(config: IdMapConfig){
		if(!config){ return config; }
		config.projectMemberId = {...(config.assignedProjectMemberId || {}), ...(config.responsibleProjectMemberId || {}), ...(config.creatorId || {})};
		delete config.assignedProjectMemberId;
		delete config.responsibleProjectMemberId;
		delete config.creatorId;
		return config;
	}
	
	collectRequiredTicketMappings(ticketList:SupportiveTicketArray){
		let processedArray = this.getTicketDataArray(ticketList);
		let collection: IdMapConfig = {};
		
		
		processedArray.forEach(ticket => {
			idMapKeys.forEach(key => {
				if(!ticket[key]){ return }
				if(!collection[key]){ collection[key] = {}; }
				collection[key][ticket[key]] = 'placeholder-'+ticket[key];
			})
			
			//variance
			this.iterVariance(ticket, (varianceId, pp)=>{
				var key = "varianceId";
				if(!collection[key]){ collection[key] = {}; }
				collection[key][varianceId] = 'placeholder-'+varianceId;
			})
		})
		
		return collection;
	}
	
	copyTickets(ticketList:SupportiveTicketArray, timelineTicketList:Map<string, any>, activeLineX:number, activeLineDate:string, planId:string, projectId:string){
		//placeholder validation
		if(!ticketList || !timelineTicketList || activeLineX === undefined || !activeLineDate || !planId || !projectId){ console.log('all args needed', arguments); return; }
		
		this.srcTickets = this.getTicketDataArray(ticketList);
		this.timelineTicketExistanceMap = timelineTicketList;;
		this.srcData = {
			activeLineX,
			activeLineDate,
			planId,
			projectId
		};
		
		if(!this.srcTickets || !this.srcTickets.length){ console.log('no tickets were copied'); return; }
		
		console.log('copied '+this.srcTickets.length+' tickets');
		
	}
	
	//notes:
	// - in order to implement same plan copying, the ticketId's need squizzling, this will require passing in an id generator
	// - should switch to a deep copy when cloning srcData for dependency data mostly
	pasteTickets(activeLineX: number, activeLineDate: string, planId:string, startingY: number, startingX, ticketIdGetter: ()=>string, config: IdMapConfig, bypassValidation = false){
		if(!this.srcTickets || !this.srcData){ console.log('no src data to copy from'); return; }
		// if(this.srcData.activeLineDate > activeLineDate){ console.log('can only copy tickets from a plan with an active line equal to or before the target plan'); return; }
		
		if(!config){ config = {}; }
		
		this.expandProjectMember(config);
		let xOffset = activeLineX - this.srcData.activeLineX;
		let calculatedSrcMappings = this.collectRequiredTicketMappings(this.srcTickets);
		
		if(!bypassValidation && !this.compareMaps(calculatedSrcMappings, config)){
			console.log('the provided config is incomplete, aborting...');
			console.log('original', calculatedSrcMappings, 'provided', config);
			return;
		}
		
		//for copying into, so that paste can be run multiple times off the same src data
		let writeTicketList:Array<TicketFields> = [];
		
		let pullList:Array<TicketFields> = [];
		let activeList:Array<TicketFields> = [];
		
		let topY = Infinity;
		let lowestPullX = Infinity;
		
		//map ticketid to array index for quicker access
		let ticketCache:any = {};
		let oldToNewId:any = {};
		
		let stats = {
			pullToPull: 0,
			pullToActive: 0,
			activeToActive: 0,
			activeToPull: 0
		};
		
		//initial iteration
		this.srcTickets.forEach((rawTicketData, idx) => {
			let ticket = clone(rawTicketData);
			ticket.id = ticketIdGetter();
			
			ticketCache[ticket.id] = ticket;
			oldToNewId[rawTicketData.id] = ticket.id;
			
			for(var key in config){
				if(ticket[key] && config[key][ticket[key]]){
					ticket[key] = config[key][ticket[key]];
				}
			}
			
			this.iterVariance(ticket, (varianceId, pp)=>{
				var key = "varianceId";
				if(config[key] && config[key][varianceId]){
					pp.varianceId = config[key][varianceId];
				}
			})
			
			if(ticket.top < topY){ topY = ticket.top; }
			
			let finish = ticket.plannedFinish || ticket.lastPlannedFinish;
			//pull
			if(!finish || finish > activeLineDate){
				if(ticket.left  < lowestPullX){ lowestPullX = ticket.left; }
				pullList.push(ticket);
			}
			//active
			else{
				activeList.push(ticket);
			}
			
			
			// ticket.left = ticket.left + xOffset;
			// writeTicketList.push(ticket);
		});
		if(lowestPullX === Infinity){ lowestPullX = startingX; } //shouldn't matter, Infinity means no pull tickets
		
		let yOffset = startingY - topY;
		let pullXOffset = startingX - lowestPullX;
		
		//do a second iteration for dependencies to ease adding ticketId swizzling later
		//also to adjust the top value
		activeList.forEach(ticket =>{
			let start = ticket.plannedStart || ticket.lastPlannedStart;
			let activeLineDayOffset = dateCache.diffDays(activeLineDate,  start) + 1;
			ticket.left = activeLineX - 145 * activeLineDayOffset;// - ticket.width;
			ticket.height = 135;
			
			ticket.top = ticket.top + yOffset;
			// ticket.left = ticket.left + xOffset;
			
			if(ticket.isActive){ stats.activeToActive++; }
			else { stats.activeToPull++; }
			
			//actually need to do date math now to account for the pull tickets being converted into actuals
			
			dependencyStuff(ticket, this);
		});
		
		pullList.forEach(ticket =>{
			ticket.top = ticket.top + yOffset;
			ticket.left = ticket.left + pullXOffset;
			ticket.width = 290;
			ticket.height = 270;
			
			if(ticket.isActive){ stats.pullToActive++; }
			else{ stats.pullToPull++; }
			
			dependencyStuff(ticket, this);
		});
		
		
		function dependencyStuff(ticket, self){
			['predecessors', 'successors'].forEach(depKey => {
				if(!ticket[depKey]){return;}
				
				//strip other plans
				if(ticket[depKey][self.srcData.planId]){
					ticket[depKey] = {[planId]: ticket[depKey][self.srcData.planId]}
					
					let ticketSection = ticket[depKey][planId];
					
					//now strip tickets that don't exist
					let keepers:any = {};
					let anyKeepers = false;
					for(var tDep in ticketSection){
						//swizzle here
						if(ticketCache[oldToNewId[tDep]] !== undefined){ keepers[oldToNewId[tDep]] = ticketSection[tDep]; anyKeepers = true; }
					}
					
					ticket[depKey][planId] = keepers; //replace planId
					if(!anyKeepers){ delete ticket[depKey]; }
				}
				else{ delete ticket[depKey]; }
			});
		}
		
		console.log('paste stats', stats);
		return [...activeList, ...pullList];
	}
	
	public stripStatus(){
		if(!this.srcTickets || !this.srcTickets.length){ console.log('no tickets have been copied'); return; }
		let statusKeys = ["actualFinish", "isActualized", "lastPlannedFinish", "lastPlannedStart", "promisePeriodStack", "promisePeriodPosition"];
		this.srcTickets.forEach(ticket => {
			if(!ticket.plannedStart && ticket.lastPlannedStart){ ticket.plannedStart = ticket.lastPlannedStart; }
			if(!ticket.plannedFinish && ticket.lastPlannedFinish){ ticket.plannedFinish = ticket.lastPlannedFinish; }
			statusKeys.forEach(key => {
				delete ticket[key];
			})
		})
	}
	
}

export const ticketCopy = new TicketCopy();
window.ticketCopy = ticketCopy;
