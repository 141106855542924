import {Subject, merge, from, of} from "rxjs";
import { filter, tap } from "rxjs/operators";

export class DataSnapshotMock{
	
	constructor(public _internalVal, public key?){
	}
	
	val(){
		console.log('databaseSnapshot', this, this._internalVal)
		return this._internalVal || null;
	}
	
	exists(){
		return this.val() !== null;
	}
}

export class AngularFireActionMock{
	payload;
	
	constructor(public type, public key, payload){
		this.payload = new DataSnapshotMock(payload, key);
	}
}

export class AngularFireMock{
	additionalHits = new Subject();
	
	constructor(public testData1){
		
	}
	
//this.listRef.query.ref.once('value', (dataWeDontCareAbout)=>{
	
	list(path){
		return {
			stateChanges: ()=>{
				return merge(from(this.testData1), this.additionalHits);
			},
			
			query: {
				ref: {
					once: function(type, cb){
						//just return immediately?
						cb();
					}
				}
			}
		}
	}
	
	object(afRef){
		return {
			snapshotChanges: ()=>{
				return merge(of(afRef), this.additionalHits.pipe(
					// tap(action => { console.log('tap changes', action); debugger; }),
					filter(action => (action as any).key === afRef.key)
				));
			},
		}
	}
	
	triggerAdditionalCall(afa){
		this.additionalHits.next(afa);
	}
}


export class FancyActionTrackerFactoryMock{
	create(){
		var thing = new Subject();
		(thing as any).destroy = function(){}
		return {actionTracker$: thing, unsubscriber: null};
		// return new Subject();
	}
	destroy(){}
}
