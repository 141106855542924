/* tslint:disable */

import * as utils from "utils";
import { genAnalyticsBoilerplate } from "./AnalyticsDecorators";

//in the future this should subscribe to the various state services, plan, project, user, etc
// for now? leach off the old analytics service
export class AnalyticsService{
	private globals = {}
	
	static convertArrayToString(arr:Array<string|number>){
		if(!arr || !arr.length){ return null; }
		return arr.join(', ');
	}
	
	protected getGlobals(arr:Array<string>){
		// let obj = {};
		// arr.forEach(thing => {
		// 	if(this.globals[thing] !== undefined){
		// 		obj[thing] = this.globals[thing];
		// 	}
		// })
		// return obj;
		
		//legacyMethod:
		let idx = arr.indexOf('projectId'); //unconvert for legacy stuff
		if(idx !== -1){ arr[idx] = "$id"; }
		
		var obj = window.legacyAnalyticsService.projectProps(arr);
		
		idx = arr.indexOf('state');
		if(idx !== -1){ obj['state'] = window.legacyAnalyticsService.getStateName(); }
		return obj;
	}
	
	//to declare additional events of the standard format the below can be used:
	//@genAnalyticsBoilerplate(["argument1"]) eventNameInCamelCase(argument1: type1){ return this.getGlobals(['projectId']); }


	@genAnalyticsBoilerplate(["planId","planName","date"]) activeLineSetViaCalendar(planId, planName, date){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["ticketId","planId"]) addedAConstraint(ticketId, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["ticketId","planId"]) addedAMilestone(ticketId, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["ticketId","planId"]) addedATicket(ticketId, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["projectMemberId","invitedEmail"]) addProjectMemberUserExists(projectMemberId, invitedEmail){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["invitedEmail"]) addProjectMemberUserDoesNotExist(invitedEmail){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["myTicketsCount","ticketCount","planId"]) clickedOnlyViewMyTickets(myTicketsCount, ticketCount, planId){ return this.getGlobals(['projectId']); }
	//the plus/ minus buttons
	@genAnalyticsBoilerplate([]) clickedZoomButton(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["oldPlanId","newPlanId"]) copiedAPlan(oldPlanId, newPlanId){ return this.getGlobals(['projectId', 'projectName']); }
	//user created
	@genAnalyticsBoilerplate(["userId","email","projects"]) created(userId, email, projects){}
	@genAnalyticsBoilerplate(["projectId","name","projectLocation"]) createdAProject(projectId, name, projectLocation) {}
	@genAnalyticsBoilerplate(["locationId","planId","locationName","background"]) createdALocation(locationId, planId, locationName, background?){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","planName"]) createdAPlan(planId, planName){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["roleId","roleName"]) createdARole(roleId, roleName){ return this.getGlobals(['projectId', 'projectName', 'state']); }
	@genAnalyticsBoilerplate([]) dateViolationBlocked(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) dateViolationNagConfirm(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["locationId","planId","locationName"]) deletedALocation(locationId, planId, locationName?){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","planName"]) deletedAPlan(planId, planName){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) deletedAProject(){ return this.getGlobals(['projectId','projectName']); }
	@genAnalyticsBoilerplate(["projectMemberId","userId"]) deletedAProjectMember(projectMemberId, userId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["roleId","roleName"]) deletedARole(roleId, roleName){ return this.getGlobals(['projectId','projectName', 'state']); }
	@genAnalyticsBoilerplate(["ticketId","planId","isFloating","type"]) deletedATicket(ticketId, planId, isFloating, type){ return this.getGlobals(['projectId','projectName']); }
	@genAnalyticsBoilerplate(["activeLineDate","columnMovedTo","columnFinish","columnsMoved","planId"]) draggedActiveLine(activeLineDate, columnMovedTo, columnFinish, columnsMoved, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["ticketId","planId","isFloating"]) duplicatedATicket(ticketId, planId, isFloating){ return this.getGlobals(['projectId','projectName']); }
	@genAnalyticsBoilerplate(["ticketId","planId","planLocked","promisedFinish","isFloating"]) editedATicket(ticketId, planId, planLocked, promisedFinish, isFloating){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) exitPlan(planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["projectName"]) freeTrialActivated(projectName){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["type","requestId","startDate","endDate","roles","plans"]) generatedReport(type, requestId, startDate, endDate, roles, plans){ return this.getGlobals(['projectId', 'state']); }
	@genAnalyticsBoilerplate(["ticketId","locationId","planId"]) locationSetOnTicket(ticketId, locationId, planId?){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) lockPlan(planId){ return this.getGlobals(['projectId', 'state']); }
	login(userId){ utils.analyticsHelper.track("Log in", {"userId": userId}); } //support legacy event name
	@genAnalyticsBoilerplate([]) logOut(){}
	@genAnalyticsBoilerplate(["name","date","planId"]) milestoneChanged(name, date, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["projectMemberId","isAdmin","planId"]) missingRoleOnPlanLoad(projectMemberId, isAdmin, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["count","planId","withinMyTickets","toMyTickets","fromMyTickets","crossedLine"]) movedTickets(count, planId, withinMyTickets, toMyTickets, fromMyTickets, crossedLine){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["count","planId"]) multiselectDelete(count, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["count","planId"]) multiselectDoubleTap(count, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["count","planId"]) multiselectDuplicate(count, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["count","planId"]) multiselectRegion(count, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["count","planId"]) multiselectTapPlanToCancel(count, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","ticketCount"]) openedEditAll(planId, ticketCount){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","ticketCount"]) openedEditCycle(planId, ticketCount){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) openedReportsPanel(){ return this.getGlobals(['projectId', 'projectName']); }
	@genAnalyticsBoilerplate([]) partialUserCreation(){}
	@genAnalyticsBoilerplate(["projectId","message"]) planDataBadData(projectId, message){}
	@genAnalyticsBoilerplate(["projectId"]) planDataSyncClosed(projectId){} //planDataCloseSyncForm
	@genAnalyticsBoilerplate(["projectId"]) planDataSyncFromText(projectId){}
	@genAnalyticsBoilerplate(["projectId"]) planDataSyncOpened(projectId){} //planDataOpenSyncForm
	@genAnalyticsBoilerplate([]) positionViolationBlocked(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) positionViolationNagConfirm(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["projectId","planId"]) procoreDeleteLinkToRfi(projectId, planId){}
	@genAnalyticsBoilerplate(["projectId","planId"]) procoreGetRfiPanelEdit(projectId, planId){} //procoreGetRFIPanelEdit
	@genAnalyticsBoilerplate(["projectId","planId"]) procoreGetRfiTicketEdit(projectId, planId){} //procoreGetRFITicketEdit
	@genAnalyticsBoilerplate(["projectId","planId"]) procoreLinkExistingRfi(projectId, planId){} //procoreLinkExisitingRFI
	@genAnalyticsBoilerplate(["projectId","planId"]) procoreLinkNewRfi(projectId, planId){} //procoreLinkNewRFI
	@genAnalyticsBoilerplate(["projectId"]) procoreLogin(projectId){}
	@genAnalyticsBoilerplate(["projectId","planId"]) procoreViewRfiInProcore(projectId, planId){} //procoreViewRFIInProcore
	@genAnalyticsBoilerplate(["email"]) projectMemberInviteDeleted(email){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) projectViewExpanded(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) projectViewJumpToNewPlan(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) projectViewOpened(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) projectViewSeeAllClicked(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) projectViewSettingsClicked(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) promiseIntersectChanged(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) promisePeriodDragged(planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","planName","promiseId","ticketCount"]) promisePeriodLocked(planId, planName, promiseId, ticketCount){ return this.getGlobals(['projectId', 'projectType']); }
	@genAnalyticsBoilerplate(["ticketId","planId"]) promisePinAddedToTicket(ticketId, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["variance"]) promisePinClickedOnPlan(variance){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["ticketId","planId"]) promisePinRemovedFromTicket(ticketId, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) promiseSettingsOpened(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["accessCode"]) rallyPointAccessCodeEnded(accessCode){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["accessCode"]) rallyPointAccessCodeGenerated(accessCode){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) rallyPointHideDetailsClicked(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["sortType"]) rallyPointListSorted(sortType){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) rallyPointOpened(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) rallyPointShowDetailsClicked(){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["type"]) requestCsv(type){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["email","sendingUserId"]) resendInvite(email, sendingUserId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["email"]) resetPassword(email){}
	@genAnalyticsBoilerplate(["roleId","name","style"]) roleColorChanged(roleId, name, style){ return this.getGlobals(['projectId', 'state']); }
	@genAnalyticsBoilerplate(["searchToggleState"]) searchBarOpened( searchToggleState ){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["menuAlreadyOpen","ticketId","planId"]) selectedDependencyTarget( menuAlreadyOpen:boolean, ticketId:string, planId:string ){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) setProjectImage(){ return this.getGlobals(['projectId', 'projectName']); }
	@genAnalyticsBoilerplate(["snapshotId"]) snapshotCreated(snapshotId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) snapshotLogOpened(planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["snapshotId","name","type","planId"]) snapshotRestored(snapshotId, name, type, planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["snapshotId","name"]) snapshotSaved(snapshotId, name){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["on"]) toggledShowAllDependencies(on:boolean){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["on"]) toggledShowPinHistory(on:boolean){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["on"]) toggledEnableTicketOverlap(on:boolean){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) unlockPlan(planId){ return this.getGlobals(['projectId', 'state']); }
	@genAnalyticsBoilerplate(["planId","ticketId"]) updateStatusButtonClicked(planId, ticketId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","ticketId","varianceId"]) varianceClearedByMovingTicket(planId, ticketId, varianceId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","ticketId","varianceId"]) varianceSetByMovingTicket(planId, ticketId, varianceId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","planName"]) viewedAPlan(planId, planName){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["name"]) viewedAProject(name){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate([]) wentToProfile(){ return this.getGlobals(['state']); }
	@genAnalyticsBoilerplate(["planId","planName","projectName"]) zoomToFit( planId, planName, projectName ){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","planName","projectName"]) zoomToPrint( planId, planName, projectName ){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","filterValue"]) showCrewSizes(planId, filterValue){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) hideCrewSizes(planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId","filterValue"]) setCrewSizesFilter(planId, filterValue){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["projectId"]) pokeProjectPresence(projectId){ return this.getGlobals(['projectId']); }
	
	//meeting popup
	@genAnalyticsBoilerplate(["planId"]) openedVideoMeeting(planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) createdVideoMeeting(planId){ return this.getGlobals(['projectId']); }
	@genAnalyticsBoilerplate(["planId"]) joinedVideoMeeting(planId){ return this.getGlobals(['projectId']); }
}

export const analyticsService = new AnalyticsService();

window.analyticsService = analyticsService;
