import {Text, Sprite, CanvasRenderer, WebGLRenderer} from "pixi.js"
/**
 * @class ImprovedText
 * @desc ImprovedText class which renders the text at a resolution which differs from the main renderer resolution.
 * @memberOf CanvasWrapper
 */
export class ImprovedText extends Text{
	/**
	 * @member _lastResolution
	 * @type {Number}
	 * @memberOf ImprovedText
	 * @protected
	 */
	protected _lastResolution: number;
	/**
	 * @constructor
	 * @param {string} text The text to render
	 * @param style {PIXI.TextStyle} The styling of the rendered text
	 * @param {HTMLCanvasElement} [canvas] The canvas to render the text to. If not provided, will create a cache canvas and render the text on that.
	 * @memberOf ImprovedText
	 */
	constructor(text: string, style, canvas?: HTMLCanvasElement){
		super(text, style, canvas);
		this.resolution = style.resolution || 1;
		this._lastResolution = style.resolution || 1;
	}
	/**
	 * @function renderWebGL
	 * @desc Called when WebGL mode is enabled
	 * @param {PIXI.WebGLRenderer} renderer
	 * @memberOf ImprovedText
	 */
	renderWebGL(renderer: WebGLRenderer){
		if (this.resolution !== this._lastResolution) {
			this.resolution = this._lastResolution;
			this.dirty = true;
		}
		super.updateText(true);
		Sprite.prototype.renderWebGL.call(this, renderer);
	}
	/**
	 * @function _renderCanvas
	 * @desc Called when WebGL mode is not enabled
	 * @param {PIXI.CanvasRenderer} renderer
	 * @memberOf ImprovedText
	 */
	_renderCanvas(renderer: CanvasRenderer){
		if (this.resolution !== this._lastResolution) {
			this.resolution = this._lastResolution;
			this.dirty = true;
		}
		super.updateText(true);
		// MOC-2471
		renderer.plugins[this.pluginName].render(this);
	}
}