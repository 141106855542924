import { FancySprite } from "../graphics/FancySprite";
import { SwimlaneContainer } from "./SwimlaneContainer";
import { Texture, utils } from "pixi.js";
import { Swimlane } from "../../common/models/Swimlane";
import { PlanState } from "../../common/services/plan-state.service";
import { SWIMLANE_GRAPHICS } from "./SwimlaneRenderer";
import * as Utils from "utils";

import { EventBinder } from "../interaction"
// import { DefaultActionTypes } from "ng2/plan-things-rename/Swimlanes"
// import {filter} from "rxjs/operators";
/**
 * The SwimlaneArea is the main background area for a swimlane.
 */
export class SwimlaneArea extends FancySprite {
	public visible: boolean = true;
	private _lastColor: string;
	private _binder = new EventBinder(this, 'hammer-panstart', 'hammer-panend');
	
	constructor(swimlaneModel: Swimlane, private planState: PlanState, texture: Texture = utils.TextureCache[SWIMLANE_GRAPHICS.SWIMLANE_AREA]){
		super(texture);
		
		// TODO: Parse the 'a' value from a rgba string and use that.
		this.alpha = 0.1;
		
		this._binder.add('hammer-panstart', (event)=>{
			(this.parent as SwimlaneContainer).lineContainer.cutInLine();
			let cleanedMap = new Map();
			this.planState.swimlanes.list.editList$.getValue().forEach((s, id) => cleanedMap.set(id, s.swimlane));
			return this.planState.swimlaneDragService.dragStart(event.center, cleanedMap);
		});
		this._binder.add('hammer-pan', (event)=>{
			return this.planState.swimlaneDragService.drag(event.center);
		});
		this._binder.add('hammer-panend', (event)=>{
			return this.planState.swimlaneDragService.dragEnd(event.center);
		});
	}
	
	public setColor(color: string){
		if (color !== this._lastColor){
			this.tint = Utils.rgbaToNumber(color);
			this._lastColor = color;
		}
	}
	
	public destroy(){
		this._binder.destroy();
	}
}
