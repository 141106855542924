import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { tap } from 'rxjs/operators';
import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyFragmenter, Fragment } from 'ng2/fancy-firebase/base';

export class TimelineTicketListPlaceholder extends FancyFirebaseList<any, FancyAction>{
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		var thing = Object.assign({prevData: {}}, action.payload.val());
		thing.$id = action.payload.key;
		return thing;
	}
	$$updated(child, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.prevData.stuff = child.stuff;
		Object.assign(child, action.payload.val());
	}
	
	//put in interface so you can explicitly say you're doing this
	applyJoinData(baseKey: string, child, otherChild:any){
		child.stuff = otherChild;
	}
	getDataForKey(baseKey: string, child){
		return child.$id;
	}
	getPrevDataForKey(baseKey: string, child){
		return child.$id;
	}
}

export class TimelineTicketFragmenter extends FancyFragmenter{
	add(id:string){
		if(!this.list.has(id)){
			let fragment = new Fragment(this.angularFireDatabase.object(this.getChildRef(id)));
			
			fragment.event$.pipe( tap(thing => {(thing as any).key = id; })).subscribe(this.innerEmitter$);
			this.list.set(id, fragment);
		}
	}
	
	getChildRef(id:string){
		var ids = id.split(','); 
		return this.baseRef.child(ids[0]).child(ids[1])
	}
}
