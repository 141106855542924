'use strict';
export const DateValidationConstants = {
	X_WARNING_THRESHOLD: 30000,
	X_WARNING_OFFSET: 10000,
	Y_WARNING_THRESHOLD: 30000,
	Y_WARNING_OFFSET: 10000,
	ABYSS_LIMIT: 15000000,
	DAYS_WARNING: 1825,
	DAYS_WARNING_OFFSET: 120,
	ENABLE_VALIDATOR: true
};
