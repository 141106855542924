import { Injectable } from '@angular/core';
import { Router, Resolve, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { User } from "ng2/common/models/User";
import { AuthService } from "ng2/common/ajs-upgrade-providers";

 
@Injectable()
export class UserDataResolver implements Resolve<User>, CanActivate {
	constructor(private router: Router, private authService: AuthService){
		console.log('authService', authService);
	}
	
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User>{
		return this.authService.waitForUser();
	}
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>{
		
		// let outerZone = Zone.current;
		//$requireSignIn does not respect the zone
		// return this.authService.auth.$requireSignIn().then(user => !!user ).catch(e => false)
		return this.authService.newWaitForSignIn().then(user => !!user ).catch(e => false)
		.then(success => {
			// if(outerZone !== Zone.current){
			// 	return outerZone.run(()=>{
			// 		if(!success){ this.redirectToLogin(); return false; }
			// 		return success;
			// 	})
			// }
			
			if(!success){ this.redirectToLogin(); return false; }
			return success;
		});
	}
	
	redirectToLogin(){
		this.router.navigate(['/login']);
	}
}
