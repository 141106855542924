import * as utils from "utils";
/**
 * GridTicks class manages all of our X and Y axes ticks (The lines for each data point)
 */
export class GridTicks {
	public ticks: number[] = [];
	public tickLods: number[] = [];
	public tickRatios: number[] = [];
	
	public minScale: number = 0.1;
	public maxScale: number = 1000.0;
	
	public minValueScale: number = 1.0;
	public maxValueScale: number = 1.0;
	
	public pixelRange: number = 500;
	
	public minSpacing: number = 20;
	public maxSpacing: number = 80;
	
	public minValue: number;
	public maxValue: number;
	
	public minTickLevel: number;
	public maxTickLevel: number;
	
	public destroy(): void {
		this.ticks = null;
		this.tickLods = null;
		this.tickRatios = null;
	}
	
	public initTicks(lods: number[], min: number, max: number): GridTicks {
		if (min <= 0) {min = 1;}
		if (max <= 0) {max = 1;}
		if (max < min) {max = min;}
		
		this.tickLods = lods;
		this.minScale = min;
		this.maxScale = max;
		
		let curTick: number = 1.0;
		let curIdx: number = 0;
		
		this.ticks.push(curTick);
		
		let minScale = min;
		let maxScale = max;
		let maxTickValue = 1;
		let minTickValue = 1;
		
		while (curTick * this.tickLods[curIdx] <= maxScale) {
			curTick = curTick *  this.tickLods[curIdx];
			curIdx = curIdx + 1 > this.tickLods.length - 1 ? 0 : curIdx + 1;
			this.ticks.push(curTick);
			
			maxTickValue = curTick;
		}
		
		this.minValueScale = 1.0 / maxTickValue * 100;
		
		curIdx = this.tickLods.length-1;
		curTick = 1.0;
		while (curTick / this.tickLods[curIdx] >= minScale ) {
			curTick = curTick / this.tickLods[curIdx];
			curIdx = curIdx - 1 < 0 ? this.tickLods.length-1 : curIdx - 1;
			this.ticks.unshift(curTick);
			minTickValue = curTick;
		}
		
		this.maxValueScale = 1.0 / minTickValue * 100;
		return this;
	}
	
	public spacing(min: number, max: number): GridTicks {
		this.minSpacing = min;
		this.maxSpacing = max;
		return this;
	}
	
	public range(minValue: number, maxValue: number, pixelRange: number): GridTicks {
		this.minValue = Math.fround(Math.min(minValue,maxValue));
		this.maxValue = Math.fround(Math.max(minValue,maxValue));
		this.pixelRange = pixelRange;
		
		this.minTickLevel = 0;
		this.maxTickLevel = this.ticks.length-1;
		
		for (let i = this.ticks.length-1; i >= 0; --i) {
			let ratio = this.ticks[i] * this.pixelRange / (this.maxValue - this.minValue);
			this.tickRatios[i] = (ratio - this.minSpacing) / (this.maxSpacing - this.minSpacing);
			if (this.tickRatios[i] >= 1.0) {
				this.maxTickLevel = i;
			}
			if (ratio <= this.minSpacing) {
				this.minTickLevel = i;
				break;
			}
		}
		
		for (let j = this.minTickLevel; j <= this.maxTickLevel; ++j ) {
			this.tickRatios[j] = utils.clamp01(this.tickRatios[j]);
		}
		
		return this;
	}
	
	public ticksAtLevel(level: number, excludeHigherLevel: boolean): number[] {
		let results = [];
		let tick = this.ticks[level];
		let start = Math.floor(this.minValue / tick);
		let end = Math.ceil(this.maxValue / tick);
		for (let i = start; i <= end; ++i) {
			if (!excludeHigherLevel || level >= this.maxTickLevel || i % Math.round(this.ticks[level+1] / tick) !== 0 ) {
				results.push(i * tick);
			}
		}
		return results;
	}
	
	public levelForStep(step: number): number{
		for (let i = 0; i < this.ticks.length; ++i) {
			let ratio = this.ticks[i] * this.pixelRange / (this.maxValue - this.minValue);
			if ( ratio >= step ) {
				return i;
			}
		}
		return -1;
	}
}