import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IColorPickerOptions } from "./color-picker.component";
import { ColorPickerWithPaletteComponent } from "./color-picker-with-palette.component";

const defaultColorPalleteOptions: IColorPickerOptions = {
	showAlpha: false,
	showInput: true,
	showPaletteOnly: true,
	showPalette: true,
	hideAfterPaletteSelect:true,
	preferredFormat: 'hex'};

@Component({
	selector: 'color-palette',
	template: require('./color-picker.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ColorPaletteComponent extends ColorPickerWithPaletteComponent {
	
	@Input() options: IColorPickerOptions = defaultColorPalleteOptions;
	
	constructor() {
		super();
	}
}
