import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { FancyNineSlicePlane } from "../graphics/";
import { Texture } from "pixi.js";
/**
 *
 */
export abstract class TicketMeshComponent extends FancyNineSlicePlane implements ComponentInterface {
	public visible: boolean = true;
	protected ticket: TicketContainer;
	protected planState: PlanState;
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer, leftWidth?: number, topHeight?: number, rightWidth?: number, bottomHeight?: number) {
		super(texture, leftWidth, topHeight, rightWidth, bottomHeight);
		
		this.ticket = ticketContainer;
		this.planState = planState;
	}
	
	destroy(options?){
		this.ticket = null;
		this.planState = null;
		super.destroy();
	}
}
