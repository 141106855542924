import { Injectable } from "@angular/core";
import { AngularFireDatabase} from '@angular/fire/database';

import { FancyIdJoin } from 'ng2/fancy-firebase/base';
import { FancyActionTrackerFactory } from 'ng2/common/services/fancy-action-tracker-factory.service';

import { ActiveColumnService } from "ng2/common/ajs-upgrade-providers";


@Injectable()
abstract class GenericListFactory{
	extraAngulars; /** to be optionally populated by inheritors so they can customize their AngularLandConnection. Expects an object */
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){}
	
	create(ListConstructor, path, fancyJoins?, additionalDependencies:Array<any> = []){
		return new ListConstructor({
			angularFireDatabase: this.db,
			fancyActionTrackerFactory: this.fancyActionTrackerFactory,
			...this.extraAngulars
		}, path, fancyJoins, ...additionalDependencies)
	}
}

//Shortest possible syntax, steps:
//- import List
//- export class file
//- change two List refs, and one ListFactory ref


// RoleList
// -----------------------------------------------------------------------------
import { RoleList } from "ng2/fancy-firebase/lists/RoleList";
export * from "ng2/fancy-firebase/lists/RoleList";

@Injectable()
export class RoleListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):RoleList{ return super.create(RoleList, path, fancyJoins); }
}


// TicketList
// -----------------------------------------------------------------------------
import { TicketList } from "ng2/fancy-firebase/lists/TicketList";
export * from "ng2/fancy-firebase/lists/TicketList";

@Injectable()
export class TicketListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory, protected activeColumnService: ActiveColumnService){ 
		super(db,fancyActionTrackerFactory); 
		this.extraAngulars = {activeColumnService};
	}
	create(path, fancyJoins?, additionalDependencies?:Array<any>):TicketList{ return super.create(TicketList, path, fancyJoins, additionalDependencies); }
}

// LocationList
// -----------------------------------------------------------------------------
import { LocationList } from "ng2/fancy-firebase/lists/LocationList";
export * from "ng2/fancy-firebase/lists/LocationList";

@Injectable()
export class LocationListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):LocationList{ return super.create(LocationList, path, fancyJoins); }
}

// ProjectMemberList
// -----------------------------------------------------------------------------
import { ProjectMemberList } from "ng2/fancy-firebase/lists/ProjectMemberList";
export * from "ng2/fancy-firebase/lists/ProjectMemberList";

@Injectable()
export class ProjectMemberListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):ProjectMemberList{ return super.create(ProjectMemberList, path, fancyJoins); }
}

// PriorityList
// -----------------------------------------------------------------------------
import { PriorityList } from "ng2/fancy-firebase/lists/PriorityList";
export * from "ng2/fancy-firebase/lists/PriorityList";

@Injectable()
export class PriorityListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):PriorityList{ return super.create(PriorityList, path, fancyJoins); }
}

// VarianceList
// -----------------------------------------------------------------------------
import { VarianceList } from "ng2/fancy-firebase/lists/VarianceList";
export * from "ng2/fancy-firebase/lists/VarianceList";

@Injectable()
export class VarianceListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):VarianceList{ return super.create(VarianceList, path, fancyJoins); }
}

// TimelineTicketListPlaceholder
// -----------------------------------------------------------------------------
import { TimelineTicketListPlaceholder } from "ng2/fancy-firebase/lists/TimelineTicketListPlaceholder";
export * from "ng2/fancy-firebase/lists/TimelineTicketListPlaceholder";

@Injectable()
export class TimelineTicketListPlaceholderFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):TimelineTicketListPlaceholder{ return super.create(TimelineTicketListPlaceholder, path, fancyJoins); }
}

// PullColumnList
// -----------------------------------------------------------------------------
import { PullColumnList } from "ng2/fancy-firebase/lists/PullColumns";
export * from "ng2/fancy-firebase/lists/PullColumns";

@Injectable()
export class PullColumnListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory, protected activeColumnService: ActiveColumnService){ 
		super(db,fancyActionTrackerFactory); 
		this.extraAngulars = {activeColumnService};
	}
	create(path, fancyJoins?):PullColumnList{ return super.create(PullColumnList, path, fancyJoins); }
}

// TimelineTicketList
// -----------------------------------------------------------------------------
import { TimelineTicketList } from "ng2/fancy-firebase/lists/TimelineTicketList";
export * from "ng2/fancy-firebase/lists/TimelineTicketList";

@Injectable()
export class TimelineTicketListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory, protected activeColumnService: ActiveColumnService){ 
		super(db,fancyActionTrackerFactory);
		this.extraAngulars = {activeColumnService};
	}
	create(path, fancyJoins?, additionalDependencies?:Array<any>):TimelineTicketList{ return super.create(TimelineTicketList, path, fancyJoins, additionalDependencies); }
}

// VarianceList
// -----------------------------------------------------------------------------
import { SwimlaneList } from "ng2/fancy-firebase/lists/SwimlaneList";
export * from "ng2/fancy-firebase/lists/SwimlaneList";

@Injectable()
export class SwimlaneListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):SwimlaneList{ return super.create(SwimlaneList, path, fancyJoins); }
}

// LaborCountList
// -----------------------------------------------------------------------------
import { LaborCountList } from "ng2/fancy-firebase/lists/LaborCountList";
export * from "ng2/fancy-firebase/lists/LaborCountList";

@Injectable()
export class LaborCountListFactory extends GenericListFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){ super(db,fancyActionTrackerFactory); }
	create(path, fancyJoins?):LaborCountList{ return super.create(LaborCountList, path, fancyJoins); }
}