import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Location } from "@angular/common";
import { AppState } from "../../../services/app-state.service";
import { Router } from "@angular/router";
import { AuthService } from "../../../../../js/services/auth-service";

@Component({
	selector: 'nav-bar-account',
	template: require('./nav-bar-account.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarAccountComponent implements OnInit {
	constructor(public appState: AppState, public router: Router, public location: Location, private authService: AuthService) {
	}
	
	public goBack(): void {
		this.location.back();
	}
	
	public goToDashboard(): void {
		this.router.navigate(['projects']);
	}
	
	public logout(): void {
		this.authService.logout();
	}
	
	ngOnInit() {}
}
