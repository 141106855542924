import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketMeshComponent } from "./";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Ticket } from "../../common/models/Ticket";
import * as utils from "utils";

export class TicketSelectedTask extends TicketMeshComponent implements ComponentInterface {
	public renderable: boolean = false;
	
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer, leftWidth: number = 25, topHeight: number = 25, rightWidth: number = 25, bottomHeight: number = 25) {
		super(texture, planState, ticketContainer, leftWidth, topHeight, rightWidth, bottomHeight);
		
		this.alpha = 1.0;
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data: Ticket = ticketContainer.getData();
		
		this.renderable = (data["selected"] || data["searched"] || <any>data["dependencyWeight"] || data["isGhost"]) || data.view.hasPredecessorOutOfOrder;
		
		if (this.renderable){
			this.width = data.view.width + 30;
			this.height  = data.view.height + 30;
			
			this.x = -15;
			this.y = -15;
			// Set the correct texture.
			// The reason why we do this using textures instead of tint is because of https://github.com/pixijs/pixi.js/issues/3862
			if (data["selected"]){
				this._texture = (<any>Texture).TASK_OUTLINE_SELECTED;
			} else if (data["searched"]){
				this._texture = (<any>Texture).TASK_OUTLINE_SEARCH;
			} else if (<any>data["dependencyWeight"]) {
				this._texture = (<any>Texture).TASK_OUTLINE_DEPENDENCY;
			} else if (data["isGhost"]){
				const color = (data.view.liveRole && data.view.liveRole.color) ? data.view.liveRole.color : "#000000";
				this.tint = utils.convertHexStringToNumber(color);
			} else if (data.view.hasPredecessorOutOfOrder) {
				this._texture = (<any>Texture).TASK_OUTLINE_ERROR;
			}
		}
	};
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}
