import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseObject, AngularLandConnection } from 'ng2/fancy-firebase/base';
import { Project } from 'ng2/common/models';

export class ProjectObject extends FancyFirebaseObject<Project>{
	
	constructor(angularLand:AngularLandConnection, path:any){ //fix any's
		super(angularLand, path);
	}
	
	$$updated(child:any){
		if(!child || !child.payload.exists()){ return null; }
		return new Project(child.payload.val());
	}
}
