import { NgModule, forwardRef } from '@angular/core';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorPickerComponent } from './color-picker.component';
import { ColorPickerWithPaletteComponent } from "./color-picker-with-palette.component";
import { ColorPaletteComponent } from "./color-palette.component";
import { ColorPickerWithAlphaComponent } from "./color-picker-with-alpha.component";

@NgModule({
	declarations: [
		ColorPickerComponent,
		ColorPickerWithPaletteComponent,
		ColorPaletteComponent,
		ColorPickerWithAlphaComponent
	],
	imports: [
		ReactiveFormsModule
	],
	exports: [
		ColorPickerComponent,
		ColorPickerWithPaletteComponent,
		ColorPaletteComponent,
		ColorPickerWithAlphaComponent
	],
	providers: [],
	entryComponents: [
		ColorPickerComponent,
		ColorPickerWithPaletteComponent,
		ColorPaletteComponent,
		ColorPickerWithAlphaComponent
	]
})
export class ColorPickerModule { }