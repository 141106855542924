

import * as angular from "angular";
import {LegacyRouting} from "ng2/routes/legacy-routing.service";
import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

class CsvRequestController{
	static $inject = ["projectNotifier", "$q", "fbdbRequests"];
	projectId: string;
	planId: string;
	prefix: string;
	type: "project"|"plan";
	// loaded = false;
	iconStatus = {
		"project": null, //"progress" | "complete" | "failure";
		"plan": null
	};
	csvData;
	
	private state;
	constructor(private projectNotifier: ProjectNotifier, private $q, private fbdbRequests){

	}
	
	$onInit(){
		this.state = this.projectNotifier.projectStateSnapshot;
	}
	
	makeRequest(type){
		var projectId = this.projectId || this.state.projectId;
		var planId = this.planId || this.state.planId;
		// var type = this.type;
		// var isIe = (<any>window).detect.parse(window.navigator.userAgent).browser.family === 'IE';
		// 
		// if(isIe){ Logging.warning("Internet Explorer does not support exporting CSV files. Please use a different browser or contact Touchplan support to obtain your file."); return }
		if(!projectId){return this.$q.rejectErr("projectId is missing");}
		
		Object.keys(this.iconStatus).forEach(key => {
			this.iconStatus[key] = null;
		});
		
		this.iconStatus[type] = "progress";
		var req = type === "plan" ? this.fbdbRequests.requestPlanTable(projectId, planId) : this.fbdbRequests.requestProjectTable(projectId);
		req.then((csvData)=>{
			this.csvData = csvData;
			analyticsService.requestCsv(type);
			// console.log('csvData', csvData);
			this.iconStatus[type] = "complete";
		})
		.catch((e)=>{
			this.iconStatus[type] = "failure";
		})
		
		
	}
	doDownload(fileName){
		let content = this.csvData.$value;
		let data = decodeDataUri(content);
		
		let uriContent = URL.createObjectURL(new Blob([data], {type : 'text/plain'}));
		// let uriContent = content;
		let link = document.createElement('a');
		link.setAttribute('href', uriContent);
		link.setAttribute('download', fileName);
		let event = new MouseEvent('click');
		link.dispatchEvent(event);
	}


}

angular.module("ticketspaceApp").component("csvRequestComponent", {
		controllerAs: "$ctrl",
		controller: CsvRequestController,
		bindings: {
			"projectId": "@",
			"planId": "@",
			"csvPlanPrefix": "@",
			"csvProjectPrefix": "@",
			"type": "@"
		},
		template: require('tpl/components/csv-request.html'),
		transclude: true
	}
)

function decodeDataUri(dataURI) {
	dataURI = decodeURI(dataURI);
	var commaIdx = dataURI.indexOf(',') +1;
	var data = dataURI.slice(commaIdx);
	return data;
}
