import { Utils, FontRenderer } from "./";
import { Polygon, Point } from "pixi.js";
/**
 * The FancyPolygon extends the PIXI.Polygon class and adds some helper methods for calculating calculating shapes within
 * shapes for the triangulation step.
 */
export class FancyPolygon extends Polygon {
	/**
	 * An array of PIXI.Point instances storing the X and Y coordinates of a point. Using polyPoints here instead of the
	 * inherited "points" property because the data is stored as an object of X and Y coordinates instead of an array of numbers.
	 */
	public polyPoints: Point[] = [];
	/**
	 * An array of children of this FancyPolygon.
	 */
	public children: FancyPolygon[] = [];
	/**
	 * The area of this polygon. Used to calculate if the polygon has any holes within its bounds.
	 */
	public area: number = 0.0;
	/**
	 * Creates a new contour. Invoked when the OpenType.js path data is of type 'M'
	 * @param {PIXI.Point} p The new Point of the contour
	 */
	public moveTo(p: Point) {
		this.polyPoints.push(p);
	}
	/**
	 * Create a new contour line from the previous position to the given coordinate. Invoked when the OpenType.js
	 * path data is of type 'L'
	 * @param {PIXI.Point} p The new point of the contour line
	 */
	public lineTo(p: Point) {
		this.polyPoints.push(p);
	}
	/**
	 * Close the path. If stroked, this will draw a line from the first to the last point of the contour.
	 * Invoked when the OpenType.js path data is of type 'Z'
	 */
	public close() {
		let current: Point = this.polyPoints[this.polyPoints.length - 1];
		this.polyPoints.forEach((next: Point) => {
			this.area += 0.5 * Utils.cross(current, next);
			current = next;
		})
	}
	/**
	 * Draw a quadratic bézier curve from the current position to the given coordinate.
	 * @param {PIXI.Point} p
	 * @param {PIXI.Point} p1
	 */
	public conicTo(p: Point, p1: Point){
		const p0 = this.polyPoints[this.polyPoints.length - 1];
		const dist = Utils.distance(p0, p1) + Utils.distance(p1, p);
		const steps = Math.max(2, Math.min(FontRenderer.MAX_BEZIER_STEPS, dist / FontRenderer.BEZIER_STEP_SIZE));
		
		for (let i = 1; i <= steps; ++i) {
			const t = i / steps;
			this.polyPoints.push(Utils.lerp(Utils.lerp(p0, p1, t), Utils.lerp(p1, p, t), t));
		}
	}
	/**
	 * Draw a bézier curve from the current position to the given coordinate.
	 * @param {PIXI.Point} p Current position
	 * @param {PIXI.Point} p1 control point 1
	 * @param {PIXI.Point} p2 control point 2
	 */
	public cubicTo(p: Point, p1: Point, p2: Point) {
		const p0 = this.polyPoints[this.polyPoints.length - 1];
		const dist = Utils.distance(p0, p1) + Utils.distance(p1, p2) + Utils.distance(p2, p);
		const steps = Math.max(2, Math.min(FontRenderer.MAX_BEZIER_STEPS, dist / FontRenderer.BEZIER_STEP_SIZE));
		
		for (let i = 1; i <= steps; ++i) {
			const t = i / steps;
			const a = Utils.lerp(Utils.lerp(p0, p1, t), Utils.lerp(p1, p2, t), t);
			const b = Utils.lerp(Utils.lerp(p1, p2, t), Utils.lerp(p2, p, t), t);
			this.polyPoints.push(Utils.lerp(a, b, t));
		}
	}
	/**
	 * Determine if a FancyPolygon is within another FancyPolyogn.
	 * @param {PIXI.Point} p
	 * @returns {boolean}
	 */
	public inside(p: Point): boolean {
		let count = 0, cur = this.polyPoints[this.polyPoints.length - 1];
		this.polyPoints.forEach(next => {
			const p0 = (cur.y < next.y ? cur : next);
			const p1 = (cur.y < next.y ? next : cur);
			if (p0.y < p.y + FontRenderer.EPSILON && p1.y > p.y + FontRenderer.EPSILON) {
				if ((p1.x - p0.x) * (p.y - p0.y) > (p.x - p0.x) * (p1.y - p0.y)) {
					count += 1;
				}
			}
			cur = next;
		});
		return (count % 2) !== 0;
	}
}

