'use strict';

export default function Statistics( numberList ){
    this.list = numberList || [];
}

Statistics.prototype.median = function(){
    var median = 0, numsLen = this.list.length;
    this.sortList();
    if (numsLen % 2 === 0) {
        median = (this.list[numsLen / 2 - 1] + this.list[numsLen / 2]) / 2;
    } else {
        median = this.list[(numsLen - 1) / 2]; }

    return median;
};

Statistics.prototype.sortList = function(){
    return this.list.sort();
};

Statistics.prototype.mean = function(  ){
    var total = 0;
    for (var i = 0; i < this.list.length; i += 1) {
        total += this.list[i];
    }
    return total / this.list.length;
};

Statistics.prototype.mode = function(  ) {
    var modes = [], count = [], i, number, maxIndex = 0;
    for (i = 0; i < this.list.length; i += 1) {
        number = this.list[i];
        count[number] = (count[number] || 0) + 1;
        if (count[number] > maxIndex) {
            maxIndex = count[number];
        }
    }
    for (i in count) {
        if (count.hasOwnProperty(i)) {
            if (count[i] === maxIndex) {
                modes.push(Number(i));
            }
        }
    }
    return modes;
};

Statistics.prototype.range = function(  ) {
    this.sortList();
    return [this.list[0], this.list[this.list.length - 1]];
};

Statistics.prototype.addNumber = function( number ){
    this.list.push( number );
};

Statistics.prototype.setNumberList = function( list ) {
    this.list = list;
};
