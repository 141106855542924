import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Side } from "../../common/models/Side";

export class TicketLocation extends TicketComponentHelper implements ComponentInterface {
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data = ticketContainer.getData();
		if (!data.view.location){
			this.renderable = false;
		} else{
			this.renderable = true;
			this.tint = this.ticket.locationColor;
			this.ticket.locationContainer = this;
			this.width = data.view.width;
			this.height = (data.view.side === Side.PULL) ? 40 : 25;
		}
	};
	
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}