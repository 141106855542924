import * as angular from 'angular';

declare const Intercom: any;
declare const analytics: any;

class IntercomHandler{
	private $timeout: any;
	private fbReporting: any;
	private isReady: boolean = false;
	private popup: any;
	public isShown: boolean = false;
	public notificationCount: number = 0;
	static $inject = ['$timeout', 'fbReporting', 'popup'];
	
	constructor($timeout: any, fbReporting: any, popup: any){
		this.$timeout = $timeout;
		this.fbReporting = fbReporting;
		this.popup = popup;
		analytics.ready(() => {this.onReady();});
	};
	onReady(){
		this.isReady = true;
		this.loadIntercom();
	}
	loadIntercom(){
		Intercom('onShow', () => {this.onShow()});
		Intercom('onHide', () => {this.onHide()});
		Intercom('onUnreadCountChange', (count: number) => {this.onUnreadCountChange(count)});
	}
	show(){
		if (!this.isReady){this.displayError();return}
		this.isShown = true;
		Intercom('show');
	}
	hide(){
		if (!this.isReady){this.displayError();return}
		this.isShown = false;
		Intercom('hide');
	}
	toggle(){
		if (!this.isReady){this.displayError();return}
		this.isShown = !this.isShown;
		if (this.isShown){Intercom('show');}
		else {Intercom('hide');}
	}
	private displayError(){
		this.fbReporting.reportRealWarning("Intercom blocked by ad blocker, probably.");
		this.popup({
			title: "Could not connect to support",
			description: "Something is blocking connection to live support. Please email us at support@touchplan.io",
			template: require('tpl/popups/simple.html')
		})
	}
	private onShow(){
		this.$timeout(() => {
			this.isShown = true;
		})
	}
	private onHide(){
		this.$timeout(() => {
			this.isShown = false;
		})
	}
	private onUnreadCountChange(count: number){
		this.$timeout(() => {
			this.notificationCount = count;
		})
	}
}

angular.module('ticketspaceApp').service('intercomHandler', IntercomHandler);
