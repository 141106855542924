import {Ticket, TicketFields} from "ng2/common/models/Ticket";
import { PlanState } from "./plan-state.service";

import * as Papa from "papaparse";

import * as utils from "utils";

import {ticketCopy, IdMapConfig, SrcData} from "./TicketCopy"

// roleId?: {[key: string]: string},
// assignedProjectMemberId?: {[key: string]: string},
// creatorId?: {[key: string]: string},
// responsibleProjectMemberId?: {[key: string]: string},
// locationId?: {[key: string]: string},
// priorityId?: {[key: string]: string},
// procoreRfiId?: {[key: string]: string},
// varianceId?: {[key: string]: string} //special handling

export class DataInfusedTicketCopy{
	
	private dataGetter;
	
	constructor(private planState: PlanState){
		
		this.dataGetter = {
			roleId: {
				listGetter: ()=> planState.roles._internalList,
				interestingData: (thing) =>  { return {
					roleColor: thing.color,
					roleName: thing.roleName
				}}
			},
			projectMemberId: {
				listGetter: ()=> planState.projectMembers._internalList,
				interestingData: (thing) =>  { return {
					roleName: utils.getNested(thing, "role.roleName") || "n/a",
					roleId: thing.roleId,
					userId: thing.userId,
					email: utils.getNested(thing, "user.email") || "n/a",
					firstName: utils.getNested(thing, "user.firstName") || "n/a",
					lastName: utils.getNested(thing, "user.lastName") || "n/a",
					company: utils.getNested(thing, "user.company") || "n/a",
				}}
			},
			locationId: {
				listGetter: ()=> planState.locations._internalList,
				interestingData: (thing) =>  { return {
					color: utils.getNested(thing, "data.color") || "n/a",
					name: utils.getNested(thing, "data.name") || "n/a",
				}}
			},
			priorityId: {
				listGetter: ()=> planState.priorities._internalList,
				interestingData: (thing) =>  { return {
					color: utils.getNested(thing, "color") || "n/a",
					text: utils.getNested(thing, "text") || "n/a",
				}}
			},
			//procoreRfiId?
			// varianceId: {
			// 	listGetter: ()=> planState.variances._internalList,
			// 	interestingData: (thing) =>  { return {
			// 		color: utils.getNested(thing, "color") || "n/a",
			// 		text: utils.getNested(thing, "text") || "n/a",
			// 	}}
			// },
		}
	}
	
	
	
	
	
	//collect all the data the tickets various id's map to, this is to make mapping the id's a bit easier
	public genHelperLists(){
		let output:any = {};
		
		for (var key in this.dataGetter){
			let list = this.dataGetter[key].listGetter();
			let chunk = [];
			list.forEach((item, itemId)=>{
				let cleanedThing = this.dataGetter[key].interestingData(item);
				let differentlyOrderedThing = Object.assign({$id: itemId}, cleanedThing);
				chunk.push(differentlyOrderedThing);
			})
			
			output[key] = chunk;
		}
		
		let outputString = "";
		for(var key in output){
			outputString += "##############################\n# "+key+"\n##############################\n"
			outputString += Papa.unparse(output[key]);
			outputString += "\n\n\n";
		}
		(console as any).save(outputString, this.planState.planId+"-test.csv");
		
		return output;
	}
	
	public getIdConfig(){
		return JSON.stringify(ticketCopy.joinProjectMember(ticketCopy.collectRequiredTicketMappings(this.planState.tickets.selectedTicket$.getValue().list)), null, 2);
	}
	
	public copyTickets(){
		let ticketList = this.planState.tickets.selectedTicket$.getValue().list;
		let timelineTicketList = this.planState.timelineTickets._internalList;
		let activeLineX = this.planState.plan._internalObject.activeLineX;
		let activeLineDate = this.planState.plan._internalObject.activeLineDate;
		let planId = this.planState.planId;
		let projectId = this.planState.projectId;
		
		return ticketCopy.copyTickets(ticketList, timelineTicketList, activeLineX, activeLineDate, planId, projectId);
	}
	
	public pasteTickets(config: IdMapConfig, bypassValidation = false){
		let activeLineX = this.planState.plan._internalObject.activeLineX;
		let activeLineDate = this.planState.plan._internalObject.activeLineDate;
		let planId = this.planState.planId;
		let projectId = this.planState.projectId;
		
		let startingY = -Infinity;
		let startingX = -Infinity;
		this.planState.tickets._internalList.forEach(t => {
			if(t.database.top > startingY){ startingY = t.database.top; }
			if(t.database.left > startingX){ startingX = t.database.left; }
		})
		if(startingY === -Infinity){ startingY = 10000; }
		if(startingX === -Infinity){ startingX = activeLineX || 500; }
		startingY += 500;
		startingX += 500;
		
		let idProvider = ()=> this.planState.tickets.listRef.query.ref.push().key;
		
		let newTickets = ticketCopy.pasteTickets(activeLineX, activeLineDate, planId, startingY, startingX, idProvider, config, bypassValidation);
		if(!newTickets){return;}
		newTickets.forEach(t => {
			this.planState.actions.createTicketWithId(t.id, t, undefined, {selectImmediately: true});
		})
		
	}
	
	public stripStatus(){
		return ticketCopy.stripStatus();
	}
	
	public destroy(){
		this.planState = null;
	}
}
