import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
	selector: 'multi-select',
	template: require('../tpl/multiselect.html')
})

export class MultiSelectComponent{
	@Input() list: any = [];
	@Input() model: any = [];
	@Input() overlayVisible: boolean = false;
	@Input() placeholder: string = 'Choose';
	@Input() filter: boolean = true;
	@Input() label: string = null;
	@Input() disabled: boolean = false;
	@Input() defaultLabel: string = 'Choose';
	@Input() style: object = null;
	@Input() styleClass: string = "tp-multiselect";
	@Input() panelStyle: object = null;
	@Input() panelStyleClass: string = null;
	@Input() scrollHeight: string = "200px";
	@Input() dataKey: string = null;
	@Input() displaySelectedLabel: boolean = true;
	@Input() maxSelectedLabels: number = 3;
	@Input() selectedItemsLabel: string = "{0} items selected";
	@Input() showToggleAll: boolean = true;
	@Input() resetFilterOnHide: boolean = false;
	@Input() dropdownIcon: string = "fa fa-fw fa-caret-down";
	@Input() selectAllText: string = "Select All Roles";
	@Output() modelChange: EventEmitter<any> = new EventEmitter();
	onChange(thing){
		this.modelChange.emit(thing);
	}
}
