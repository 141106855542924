import { Sprite, Texture } from 'pixi.js';
import { LEVEL_OF_DETAIL_TABLE, LEVEL_OF_DETAIL_SIGNALS, LevelOfDetail } from "../core";
import { FancyInterface } from "../interfaces";

import {detachable, constructDetach, DetachInterface} from "./Detachable";

/**
 * Extends PIXI.Sprite and does tweaks to the main renderer loop to inject the Culling utility class. Also adds a simple component api
 */

//merge the declaration of FancyContainer with that of detach. This makes typescript happy.
export interface FancySprite extends DetachInterface{}

@detachable()
export class FancySprite extends Sprite implements FancyInterface{
	public active: boolean = true;
	/**
	 * Controls the detail level of when this sprite becomes visible/invisible
	 *
	 * Set to LEVEL_OF_DETAIL_TABLE.VERY_LOW to have it always be visible.
	 */
	public visible: boolean = false;
	/**
	 * @param {PIXI.Texture} texture The texture to use for the sprite
	 */
	constructor(texture: Texture){
		super(texture);
		constructDetach(this);
	}
	/**
	 * Renders the object using the WebGL renderer
	 * @param renderSession
	 */
	public _renderWebGL(renderSession){
		super._renderWebGL(renderSession);
	}
	/**
	 * Renders the object using the Canvas renderer
	 * @param renderSession
	 */
	public _renderCanvas(renderSession){
		// Render this element
		super._renderCanvas(renderSession);
	}
	/**
	 * Update the transformation matrix on this object and all children. May need to optimize this.
	 */
	public updateTransform(){
		if (this.visible && this.renderable){
				super.updateTransform();
		}
	}
	
	/** Moves this container to the end of the children list on it's parent (or top of the zOrder stack) */
	public cutInLine(){
		if(!this.isDetached){
			this.parent.setChildIndex(this, this.parent.children.length -1);
		}
	}
	
	/**
	 * @param {Boolean} [options.children = false] If true, will call destroy on all children as well.
	 * @param {Boolean} [options.texture = false] If true, will destroy the texture as well.
	 * @param {Boolean} [options.baseTexture = false] If true, will destroy the base texture as well.
	 */
	public destroy(options?: object|boolean): void{
		// Destroy textures
		super.destroy(options);
	}
}
