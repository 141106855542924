import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";

import { AppState } from "ng2/common/services/app-state.service";
import { RallyBaseComponent } from "../rally-base.component";
import { RallyPointSignupService } from "ng2/common/services/rally-point-signup.service";

import { AuthService } from "ng2/common/ajs-upgrade-providers";

// import { LoadingBlockerState } from "ng2/common/components/loading-blocker";

@Component({
	selector: 'rally-start',
	template: require('./rally-start.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RallyStartComponent extends RallyBaseComponent implements OnInit, OnDestroy {
	public rallyForm = this.formBuilder.group({
		accessCode: ['']
	});

	constructor(
		private formBuilder: FormBuilder,
		public rallyPointSignupService: RallyPointSignupService,
		private appState: AppState,
		private authService: AuthService
	) {
		super(rallyPointSignupService);
	}
	
	next(){
		this.rallyPointSignupService.startLoading(this.loadingSet);
		
		if(!this.rallyForm.controls.accessCode.value){
			Logging.warning("an access code must be entered"); 
			this.rallyPointSignupService.failLoading(this.loadingSet);
			return;
		}
		
		this.rallyPointSignupService.patchState(this.rallyForm.value);
		
		this.rallyPointSignupService.getAccessCodeData(this.rallyForm.controls.accessCode.value).then((codeData)=>{
			return this.rallyPointSignupService.testAccessCode(codeData);
		}).then((result)=>{
			// this.rallyPointSignupService.handleRedirect(result, this.loadingSet, this.loadingChanged);
			this.redirect(result);
		})
	}
	
	logout(){
		this.authService.logout();
	}
}
