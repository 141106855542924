
export class Variance  { 
	public varianceColor: string;
	public varianceKey: number;
	public varianceReason: string;
	public combinedVarianceText: string;
	
	constructor(obj){
		this.update(obj);
	}
	
	update(obj){
		this.varianceColor = obj.varianceColor;
		this.varianceKey = obj.varianceKey;
		this.varianceReason = obj.varianceReason;
		this.combinedVarianceText = this.varianceKey + ". " + this.varianceReason;
	}
}
