import { utils, extras, Texture, Rectangle } from "pixi.js";

import {map} from "rxjs/operators";

import { SWIMLANE_GRAPHICS, SWIMLANE_LABEL_SETTINGS } from "./";
import { PlanState } from "../../common/services/plan-state.service";
import { Swimlane } from "../../common/models/Swimlane";
import * as Utils from "utils";
import { EventBinder } from "../interaction"

export enum SWIMLANE_SPOT {
	TOP = "top",
	BOTTOM = "bottom"
}

export class SwimlaneLine extends extras.TilingSprite{
	private _lastColor: string;
	private _binder = new EventBinder(this, 'hammer-panstart', 'hammer-panend');
	constructor(private swimlaneModel: Swimlane, private planState: PlanState, private spot: SWIMLANE_SPOT, texture: Texture = utils.TextureCache[SWIMLANE_GRAPHICS.SWIMLANE_LINE]){
		super(texture);
		
		this.hitArea = new Rectangle(0,0,1,1);
		
		this._binder.add('hammer-panstart', (event)=>{
			// let filteredObservable = this.planState.swimlanes.list.editList$.pipe(
			// 	map(list => {
			// 		let map = new Map
			// 		return 
			// 	})
			// );
			let tempMap = new Map();
			tempMap.set(swimlaneModel.$id, swimlaneModel);
			return this.planState.swimlaneDragService.dragEdgeStart(event.center, tempMap);
		});
		this._binder.add('hammer-pan', (event)=>{
			if(spot === SWIMLANE_SPOT.TOP){
				return this.planState.swimlaneDragService.dragTopEdge(event.center);
			}
			else if(spot === SWIMLANE_SPOT.BOTTOM){
				return this.planState.swimlaneDragService.dragBottomEdge(event.center);
			}
		});
		this._binder.add('hammer-panend', (event)=>{
			return this.planState.swimlaneDragService.dragEdgeEnd(event.center);
		});
	}
	
	public toggleInteractive(): void {
		this.setInteractive(!this.interactive);
	}
	
	public setEdit(width: number, isEdit: boolean){
		this.setInteractive(isEdit);
		this.setHitArea(width);
	}
	
	public setInteractive(value: boolean): void{
		this.interactive = value;
		
		if (this.interactive){
			this.cursor = "row-resize";
			this.setColor(SWIMLANE_LABEL_SETTINGS.LINE_EDIT_COLOR);
		} else {
			this.cursor = null;
			this.setColor(SWIMLANE_LABEL_SETTINGS.LINE_NORMAL_COLOR);
		}
	}
	
	public setColor(color: string){
		if (color !== this._lastColor){
			this.tint = Utils.rgbaToNumber(color);
			this._lastColor = color;
		}
	}
	
	public setHitArea(width: number){
		(<Rectangle>this.hitArea).x = 0;
		(<Rectangle>this.hitArea).y = -SWIMLANE_LABEL_SETTINGS.LINE_HIT_AREA_HEIGHT;
		(<Rectangle>this.hitArea).width = width;
		(<Rectangle>this.hitArea).height = SWIMLANE_LABEL_SETTINGS.LINE_HIT_AREA_HEIGHT * 2; // Times by two to offset the y value form above
	}
	
	public destroy(){
		super.destroy();
	}
}
