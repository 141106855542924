import * as angular from "angular";

angular.module('CanvasWrapper').factory("InteractionPinch", PinchFactory);
/**
 * @namespace Pinch
 * @returns {CanvasWrapper.Interaction.Pinch}
 * @memberOf CanvasWrapper.Interaction
 * @constructor
 */
PinchFactory.$inject = ["InteractionHandleEvent"];
function PinchFactory(InteractionHandleEvent){
	var handleEvent = InteractionHandleEvent;
	// Handles pinch
	function Pinch(displayNode, inertia) {
		var oldScale;
		var startDistance = 0;
		function pinchStart(e) {
			handleEvent(displayNode).on('touchmove', pinchMove);
		}

		function pinchMove(e) {
			var t = e.data.originalEvent.targetTouches;
			if (!t || t.length < 2) {return}
			var dx = t[0].clientX - t[1].clientX;
			var dy = t[0].clientY - t[1].clientY;
			var distance = Math.sqrt(dx * dx + dy * dy);
			if (!displayNode._pinch) {
				displayNode._pinch = {
					p: {
						distance: distance,
						date: new Date()
					}
				};
				startDistance = distance;
				handleEvent(displayNode).emit('pinchstart', {distance: distance});
				return;
			}

			var now:any = new Date();
			var interval = now - displayNode._pinch.p.date;
			if (interval < 12) {
				return
			}
			var center = {
				x: (t[0].clientX + t[1].clientX) / 2,
				y: (t[0].clientY + t[1].clientY) / 2
			};
			var scale = distance / displayNode._pinch.p.distance;
			var scaleChange = scale - oldScale;
			var event = {
				distance: distance,
				startDistance: startDistance,
				scale: distance / displayNode._pinch.p.distance,
				velocity: distance / interval,
				zoomIn: (distance > startDistance),
				offsetX: -(center.x * scaleChange),
				offsetY: -(center.y * scaleChange),
				points: t[0],
				scaleChange: scaleChange,
				center: center,
				data: e.data
			};
			oldScale = event.scale;
			if (Math.abs(startDistance - distance) >= 20){
				handleEvent(displayNode).emit('pinchmove', event);
			}
			displayNode._pinch.pp = {
				distance: displayNode._pinch.p.distance,
				date: displayNode._pinch.p.date
			};

			displayNode._pinch.p = {
				distance: distance,
				date: now
			};
		}

		function pinchEnd(e) {
			handleEvent(displayNode).removeListener('touchmove', pinchMove);
			if (!displayNode._pinch || displayNode._pinch.intervalId) {return}
			if (inertia && displayNode._pinch.pp) {
				if (displayNode._pinch.intervalId) {
					return
				}
				var interval = (new Date() as any) - displayNode._pinch.p.date;
				var velocity = (displayNode._pinch.p.distance - displayNode._pinch.pp.distance) / interval;
				var center = displayNode._pinch.p.center;
				var distance = displayNode._pinch.p.distance;
				displayNode._pinch.intervalId = setInterval(function () {
					if (Math.abs(velocity) < 0.04) {
						clearInterval(displayNode._pinch.intervalId);
						handleEvent(displayNode).emit('pinchend');
						displayNode._pinch = null;
						return
					}
					var updatedDistance = distance + velocity * 12;
					var event = {
						scale: updatedDistance / distance,
						velocity: velocity,
						center: center,
						data: e.data,
						zoomIn: (distance > startDistance)
					};
					handleEvent(displayNode).emit('pinchmove', event);
					distance = updatedDistance;
					velocity *= 0.8
				}, 12)
			} else {
				handleEvent(displayNode).emit('pinchend');
				displayNode._pinch = null;
			}
		}

		displayNode.interactive = true;
		handleEvent(displayNode).on('touchstart', pinchStart);
		handleEvent(displayNode).on('touchend', pinchEnd);
		handleEvent(displayNode).on('touchendoutside', pinchEnd);
	};

	return Pinch
}
