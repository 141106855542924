import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';

const Hammer = require("js/vendor/hammer/hammer");

@Component({
	selector: 'draggable-container',
	template: require('./draggable-container.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DraggableContainerComponent implements OnInit, OnDestroy, AfterViewInit{
	@Input() showIcon = true;
	@Input() iconType = 'move'; // supports move or close for now

	@Output() close:EventEmitter<any> = new EventEmitter();

	@ViewChild('dragContainer') dragContainer:ElementRef;
	@ViewChild('dragArea') dragArea:ElementRef;
	@ViewChild('resizeHandle') resizeHandle:ElementRef;

	private hammerListeners = [];

	private readonly MIN_WIDTH = 100;
	private readonly MIN_HEIGHT = 100;

	public width:number;
	public height:number;
	public top:number;
	public left:number;

	private isInitialized:boolean;
	private viewportWidth:number;
	private viewportHeight:number;
	private originalX:number;
	private originalY:number;
	private offsetX:number;
	private offsetY:number;
	private isDragging:boolean;
	private isResizing:boolean;

	private borderTopBottom:number;
	private borderLeftRight:number;

	constructor(private changeDetectorRef:ChangeDetectorRef){
		this.resizeHandler();

		this.width = 200;
		this.left = this.viewportWidth - this.width * 2;
	}

	ngOnInit(){
	}

	ngAfterViewInit(){
		const dragArea:HTMLElement = this.dragArea.nativeElement;

		const dragStart = event => this.dragStart(event);
		const drag = event => this.drag(event);
		const dragEnd = () => this.dragEnd()
		const resizeStart = event => this.resizeStart(event);
		const resize = event => this.resize(event);
		const resizeEnd = () => this.resizeEnd();
		const resizeHandler = () => this.resizeHandler();

		this.hammerListeners.push(
			() => Hammer.off(dragArea, 'mousedown', dragStart),
			() => Hammer.off(window, 'mousemove', drag),
			() => Hammer.off(window, 'mouseup', dragEnd),
			() => Hammer.off(resizeHandle, 'mousedown', resizeStart),
			() => Hammer.off(window, 'mousemove', resize),
			() => Hammer.off(window, 'mouseup', resizeEnd),
			() => Hammer.off(window, 'resize', resizeHandler),
		);

		Hammer.on(dragArea, 'mousedown', dragStart);
		Hammer.on(window, 'mousemove', drag);
		Hammer.on(window, 'mouseup', dragEnd);

		const resizeHandle:HTMLElement = this.resizeHandle.nativeElement;
		Hammer.on(resizeHandle, 'mousedown', resizeStart);
		Hammer.on(window, 'mousemove', resize);
		Hammer.on(window, 'mouseup', resizeEnd);
		Hammer.on(window, 'resize', resizeHandler);



		const style = getComputedStyle(this.dragContainer.nativeElement);
		this.borderTopBottom = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
		this.borderLeftRight = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
		
		this.isInitialized = true;	
	}

	ngOnDestroy(){
		this.hammerListeners.forEach( h => h() );
	}

	public setWidth(width:number):void{
		this.width = width;
		this.changeDetectorRef.detectChanges();
	}

	public setHeight(height:number):void{
		this.height = height;
		this.changeDetectorRef.detectChanges();
	}

	public setX(x:number):void{
		this.left = x;
		this.changeDetectorRef.detectChanges();
	}

	public setY(y:number):void{
		this.top = y;
		this.changeDetectorRef.detectChanges();
	}

	public snapToLeftEdge():void{
		this.left = 0;
		this.changeDetectorRef.detectChanges();
	}

	public snapToRightEdge():void{
		this.left = this.viewportWidth - this.dragContainer.nativeElement.offsetWidth;
		this.changeDetectorRef.detectChanges();
	}

	public centerHorizontally(left:number, right:number):void{
		this.left = (left + right - this.dragContainer.nativeElement.offsetWidth) / 2;
		this.changeDetectorRef.detectChanges();
	}

	public centerVertically(top:number, bottom:number):void{
		this.top = (top + bottom - this.dragContainer.nativeElement.offsetHeight) / 2;
		this.changeDetectorRef.detectChanges();
	}

	private updatePosition(top:number = this.top, left:number = this.left):void{
		if(this.isInitialized){
			this.top = Math.min(Math.max(0, top), this.viewportHeight - this.dragContainer.nativeElement.offsetHeight);
			this.left = Math.min(Math.max(0, left), this.viewportWidth - this.dragContainer.nativeElement.offsetWidth);
			this.changeDetectorRef.detectChanges();
		}
	}

	private updateSize(mouseX:number, mouseY:number):void{
		if(mouseX > this.dragContainer.nativeElement.offsetLeft){
			this.width = Math.min(Math.max(mouseX + this.offsetX - this.dragContainer.nativeElement.offsetLeft, this.MIN_WIDTH), this.viewportWidth - this.dragContainer.nativeElement.offsetLeft - this.borderLeftRight);
		}

		if(mouseY > this.dragContainer.nativeElement.offsetTop){
			this.height = Math.min(Math.max(mouseY + this.offsetY - this.dragContainer.nativeElement.offsetTop, this.MIN_HEIGHT), this.viewportHeight - this.dragContainer.nativeElement.offsetTop - this.borderTopBottom);
		}

		this.changeDetectorRef.detectChanges();
	}

	private dragStart(event):void{
		this.isDragging = true;
		this.originalX = this.dragContainer.nativeElement.offsetLeft;
		this.originalY = this.dragContainer.nativeElement.offsetTop;
		this.offsetX = event.clientX;
		this.offsetY = event.clientY;
	}

	private drag(event):void{
		if(!this.isDragging){
			return;
		}

		const top = this.originalY + event.clientY - this.offsetY;
		const left = this.originalX + event.clientX - this.offsetX;
		this.updatePosition(top, left);
	}

	private dragEnd():void{
		this.isDragging = false;
	}

	private resizeStart(event):void{
		this.isResizing = true;
		this.offsetX = this.dragContainer.nativeElement.offsetLeft + this.width - event.clientX;
		this.offsetY = this.dragContainer.nativeElement.offsetTop + this.height - event.clientY;
	}

	private resize(event):void{
		if(this.isResizing){
			this.updateSize(event.clientX , event.clientY);
		}
	}

	private resizeEnd():void{
		this.isResizing = false;
		this.changeDetectorRef.detectChanges();
	}

	private resizeHandler():void{
		this.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		this.viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		this.width = this.width > this.viewportWidth ? this.viewportWidth : this.width;
		this.height = this.height > this.viewportHeight ? this.viewportHeight : this.height;

		this.updatePosition();
	}
}
