'use strict';

import * as angular from "angular";
import {LegacyRouting} from "ng2/routes/legacy-routing.service";

import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

declare const window: any;

angular.module('ticketspaceApp')
.factory('firebaseMeshedUsers', ["$q", "$firebaseUtils", "$firebaseArray", "legacyRouting", "fbConnection", function($q, $firebaseUtils, $firebaseArray, legacyRouting, fbConnection) {
	
	
//TODO - continue testing this at various level
// - maybe consider mapping some things into $$update...somehow, or not


//consider adding objectMapping support for keys (to support duplicates)
	var supportedKeys = {
		"users": [
			"accountId", "email", "company", "phone", "firstName",
			"lastName", "zip"
		],
		"projectMembers": [
			"accessLevel", "roleId", "secondaryRoles"
		],
		"roles":[
			"roleName", "roleStyle"
		],
		"accounts": [
			"tier", "trialDays"
		],
		"presence": [
			"lastOnline", "lastUpdate", "state", "status"
		]
	};
	
	//list of decendents more then one level deep, used to off things when a high level node loses access
	var deepDecendents = {
		"projectMembers": ["roles"]
	};
	
	function orderByNameComparator(a,b){
		// If we don't get strings, just compare by index
		/*
    if (a.type !== 'string' || b.type !== 'string') {
      return (a.index < b.index) ? -1 : 1;
    }
		console.log('a',a);
		return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
		*/
		if(!a.data.formattedName && !b.data.formattedName){return 0;}
		if(!a.data.formattedName){return 1;}
		if(!b.data.formattedName){return -1;}
		
		return a.data.formattedName.toLowerCase().localeCompare(b.data.formattedName.toLowerCase());
	}
	
	function formatName(topObj){
		var obj = topObj.data;
		if(obj.firstName || obj.lastName){ obj.formattedName = (obj.firstName+" " || "") + (obj.lastName || ""); }
		else if(obj.email){obj.formattedName = obj.email; }
		else{ obj.formattedName = "n/a"; }
	}
	
	//prejectId required, config can optionally be used to spec an alternative project path
	//maybe add support to override everything in baseRefCollection as well
	function MeshFactory(projectId, config){
		
		//---------------------------------------------------------
		// RIDICULOUS BS INCOMING!!
		// - special override for SampleProjectV3 that effectively mocks out some fake data
		//---------------------------------------------------------
		if(projectId === "SampleProjectV3"){
			var fakeList: any = [{
				"$id": "billBelichick",
				"data": {
					"userId": "billBelichick",
					"accountId": "-JvyAb-Ra4pqxNbgXHgt",
					"email": "bill@patriots.com",
					"company": "New England Patriots",
					"phone": "",
					"firstName": "Bill",
					"lastName": "Belichick",
					"zip": "",
					"formattedName": "Bill Belichick",
					"tier": "individual",
					"projectMemberId": "billBelichicklProjectMemberId",
					"accessLevel": "Admin",
					"roleId": "-KeKjh5SwIwQZiJlfKq2",
					"roleName": "Mechanical",
					"roleColor": "-Kvo9bSpRrXMJx4DYaqp"
			    }
			},
				{
					"$id": "tomBrady",
					"data": {
						"userId": "tomBrady",
						"accountId": "isThisNeeded",
						"email": "tom@patriots.com",
						"company": "New England Patriots",
						"phone": "",
						"firstName": "Tom",
						"lastName": "Brady",
						"zip": "",
						"formattedName": "Tom Brady",
						"tier": "individual",
						"projectMemberId": "tomBradyProjectMemberId",
						"accessLevel": "General",
						"roleId": "-KeKjh5f-GvVplC-vLHS",
						"roleName": "CM",
						"roleColor": "-Kvo9bSpRrXMJx4DYar1"
					}
				},
				{
					"$id": "robGronkowski",
					"data": {
						"userId": "robGronkowski",
						"accountId": "isThisNeeded",
						"email": "rob@patriots.com",
						"company": "New England Patriots",
						"phone": "",
						"firstName": "Rob",
						"lastName": "Gronkowski",
						"zip": "",
						"formattedName": "Rob Gronkowski",
						"tier": "individual",
						"projectMemberId": "robGronkowskiProjectMemberId",
						"accessLevel": "General",
						"roleId": "-KeKjh5f-GvVplC-vLHQ",
						"roleName": "Concrete",
						"roleColor": "-Kvo9bSpRrXMJx4DYaqo"
					}
				}];
			//handle both in one chunk
			//this will of course cause bad things if you use the same keys for user and pm ids above
			//so... don't do that
			var fakeListMap = {
				"billBelichick": 0,
				"billBelichicklProjectMemberId": 0,
				"tomBrady": 1,
				"tomBradyProjectMemberId": 1,
				"robGronkowski": 2,
				"robGronkowskiProjectMemberId": 2
			};
			//do nothing
			["$add", "$destroy"].forEach(function(key){ fakeList[key] = function(){} });
			//return the test user
			["$getPmRecord", "$getRecord"].forEach(function(key){
				fakeList[key] = function(a){
					if(fakeListMap[a]){return fakeList[fakeListMap[a]];} //return the actual one
					return a ? fakeList[0] : null; //or the first one
				}
			});
			//call watch cb immediately
			fakeList.$watch = function(cb){ 
				if(cb){cb();}
				return function(){}
			}
			fakeList.$loaded = function(){ return $q.when(); }
			return fakeList;
		}
		// CONCLUDE RIDICULOUS BS
		// RESUMING NORMAL CODE
		
		
		config = config || {};
		var path = config.path || 'projects/'+projectId;
		var ref = fbConnection.fbRef(path).child('users');
		
		this.projectId = projectId;
		this.baseRefCollection = {
			"accounts": fbConnection.fbRef('accounts/'),
			"users": fbConnection.fbRef('users/'),
			"userIndex": fbConnection.fbRef("indices/users/"),
			"projectMembers": fbConnection.fbRef("projectMembers/"+projectId),
			"roles": fbConnection.fbRef("roles/"+projectId),
			"presence": fbConnection.fbRef('presence/')
		};
		this.projectMemberIdx = {};
		
		// call the super constructor
		var thing = $firebaseArray.call(this, ref);
		return thing;
	}
	MeshFactory.prototype = {
		$$added: function(snap){
			return this.buildInitial(snap);
		},
		buildInitial: function(userIdSnap){
			var self = this;
			return new $q(function(resolve, reject){
				if(!userIdSnap.exists()){ reject(new Error('this shouldn\'t be possible')); return; }
				
				//object to be returned
				var obj:any = {data: {}, '$snaps': {}};
				//the callbacks to be used in offing
				var cbs:any = {};
				//store the refs, again, for offing
				var refs:any = {};
				
				
				//setup...
				var userId = userIdSnap.key;
				obj.$id = userId;
				obj.data.userId = userId;
				obj.$snaps["userId"] = userIdSnap;
				obj.$refs = refs;
				obj.$cbs = cbs;
				
				//some rather ugly crap to resolve the initial add only once everythings loads as far as it can go
				var resolvedCount = 0;
				var firstUsers = true;
				var firstProjectMembers = true;
				function tryResolve(){
					resolvedCount++;
					//console.log('tryResolve', resolvedCount);
					if(resolvedCount >= 2){
						resolve(obj); 
					}
				}
				
				//#### doUpdate(snap, supportedKeys)
				// manages the updates into/ out of the obj
				function doUpdate(snap, supportedKeys){
					var newObj;
					self.$list.$lastUpdated = Date.now();
					if(!snap || !snap.exists()){ newObj = {}; }
					else{ newObj = snap.val(); }
					
					supportedKeys.forEach(function(key){
						if(newObj[key] !== undefined && newObj[key] !== null){ obj.data[key] = newObj[key]; }
						else{ delete obj.data[key]; }
					});
				}
				
				//#### updateDescendent(snap, idxKey, descendentKey)
				// handles the cleanup and reindexing when an id changes
				function updateDescendent(snap, idxKey, descendentKey){
					//reset things
					if(refs[descendentKey]){
						refs[descendentKey].off('value', cbs[descendentKey]);
						var deep = deepDecendents[descendentKey];
						if(deep){
							deep.forEach(function(d){
								if(refs[d]){ 
									refs[d].off('value', cbs[d]);
									doUpdate(null, supportedKeys[d]);
								}
							});
						}
					}
					//console.log('a', descendentKey,supportedKeys[descendentKey] );
					doUpdate(null, supportedKeys[descendentKey]);
					
					var idx = idxKey ? snap.child(idxKey).val() : snap.val();
					if(idx){
						refs[descendentKey] = self.baseRefCollection[descendentKey].child(idx);
						refs[descendentKey].on('value', cbs[descendentKey]);
					}
					
					return idx;
				}
				
				//callbacks
				
				//users callback
				cbs['users'] = function usersValueCb(userSnap){
					var accountNeedsUpdate = obj.data.accountId !== userSnap.child('accountId').val();
					doUpdate(userSnap, supportedKeys.users);
					obj.$snaps["users"] = userSnap;
					
					formatName(obj);
					
					if(accountNeedsUpdate){
						if(!updateDescendent(userSnap, 'accountId', 'accounts')){
							//first run
							if(firstUsers){ tryResolve(); }
							firstUsers = false;
						}
					}
				};
				//acounts callback
				cbs['accounts'] = function accountsValueCb(accountSnap){
					//console.log('account things', accountSnap.val(), accountSnap.ref.toString());
					doUpdate(accountSnap, supportedKeys.accounts);
					obj.$snaps["accounts"] = accountSnap;
					//first run
					if(firstUsers){ tryResolve(); }
					firstUsers = false;
				};
				//userIndex callback
				cbs["userIndex"] = function userIndexValueCb(pmIndexSnap){
					var pmChanged = pmIndexSnap.val() !== obj.data.projectMemberId;
					obj.$snaps["userIndex"] = pmIndexSnap;
					obj.data.projectMemberId = pmIndexSnap.val();
					
					if(pmChanged){
						if(!updateDescendent(pmIndexSnap, null, "projectMembers")){
							//first run
							if(firstProjectMembers){ tryResolve(); }
							firstProjectMembers = false;
						}
					}
				};
				//projectMembers callback
				cbs["projectMembers"] = function projectMembersValueCb(pmSnap){
					var roleChanged = pmSnap.child('roleId').val() !== obj.data.roleId;
					doUpdate(pmSnap, supportedKeys.projectMembers);
					obj.$snaps["projectMembers"] = pmSnap;
					self.$$notify('child_changed', userId);
					
					if(roleChanged){
						if(!updateDescendent(pmSnap, 'roleId', "roles")){
							//first run
							if(firstProjectMembers){ tryResolve(); }
							firstProjectMembers = false;
						}
					}
				};
				//roles callback
				cbs["roles"] = function rolesValueCb(roleSnap){
					doUpdate(roleSnap, supportedKeys.roles);
					obj.$snaps["roles"] = roleSnap;
					
					if(firstProjectMembers){ tryResolve(); }
					firstProjectMembers = false;
				};
				
				//users callback
				cbs['presence'] = function presenceValueCb(userSnap){
					var state = legacyRouting.oldStyleSnapshot();
					doUpdate(userSnap, supportedKeys.presence);
					var plan = userSnap.child('meta/planId');
					if(plan.exists() && plan.val() === state.planId){ obj.data.onSamePlan = true; }
					else{ obj.data.onSamePlan = false; }
					obj.$snaps["presence"] = userSnap;
				};
				
				refs['users'] = self.baseRefCollection.users.child(userId);
				refs['users'].on('value', cbs['users']);
				
				refs['presence'] = self.baseRefCollection.presence.child(userId);
				refs['presence'].on('value', cbs['presence']);
				
				refs["userIndex"] = self.baseRefCollection.userIndex.child(userIdSnap.key).child('projectMember').child(self.projectId).child('projectMemberId');
				refs["userIndex"].on('value', cbs["userIndex"]);
			});
		},
		$$updated: function(snap){
			//in theory the "value" this is actually worthless, so just override this to do nothing
		},
		$$removed: function(snap){
			var rec = this.$getRecord(snap.key);
			if(rec){
				for(var key in rec.refs){
					rec.refs[key].off('value', rec.cbs[key]);
				}
			}
			return $firebaseArray.prototype.$$removed.apply(this, arguments);
			// this.updateUsedStyles('remove', snap, snap);
			// return this.$getRecord(snap.key);
		},
		$$process: function(type, rec, prevChild){
			
			if(type === "child_removed"){
				//console.log('removed', this.$indexFor(this.$$getKey(rec)), rec);
				delete this.projectMemberIdx[rec.data.projectMemberId];
			}
			
			var changed = $firebaseArray.prototype.$$process.apply(this, arguments);
			
			if(type === "child_added"){
				//console.log('added', this.$indexFor(this.$$getKey(rec)), rec);
				var key = this.$$getKey(rec);
				var i = this.$indexFor(key);
				this.projectMemberIdx[key] = i;
			}
		
			
			return changed;
		},
		$pmIndexFor: function(key){
			//console.log('pmKey', key, this.projectMemberIdx);
			var self = this;
			var cache = self.projectMemberIdx;
			// evaluate whether our key is cached and, if so, whether it is up to date
			if( !cache.hasOwnProperty(key) || !self.$list[key] || !self.$list[key].data.projectMemberId !== key ) {
				// update the hashmap
				var pos = self.$list.findIndex(function(rec) { return rec.data.projectMemberId === key; });
				//console.log('pos', pos);
				if( pos !== -1 ) {
					cache[key] = pos;
				}
			}
			//console.log('cache', cache, key, cache[key]);
			return cache.hasOwnProperty(key) ? cache[key] : -1;
		},
		$getPmRecord: function(key){
			var i = this.$pmIndexFor(key);
			//console.log('this.$list', i, this.$list, this.$list[i]);
			return i > -1? this.$list[i] : null;
		},
		$destroy: function(){
			if(this.$list){
				this.$list.forEach(function(rec){
					for(var key in rec.refs){
						rec.refs[key].off('value', rec.cbs[key]);
					}
				});
			}
			return $firebaseArray.prototype.$destroy.apply(this, arguments); 
		},
		//slap utility functions directly onto the collection
		orderByNameComparator: orderByNameComparator
	};
	
	var eFactory = $firebaseArray.$extend(MeshFactory);
	
	//add utility functions
	eFactory.orderByNameComparator = orderByNameComparator;
	
	return eFactory;
}])
//figure out how to keep this updated with project changes
.factory('firebaseMeshedUsersGlobal', ["$q", "firebaseMeshedUsers", "projectNotifier", function($q, firebaseMeshedUsers, projectNotifier: ProjectNotifier) {
	var projectId;
	var arr:any = [];
	var defer = $q.defer();
	
	update(projectId);
	projectNotifier.projectState.subscribe(function(state){
		if ( !state.projectId ) return;
		if(projectId !== state.projectId){
			update(state.projectId);
		}
	});
	
	function update(newProjectId){
		projectId = newProjectId;
		if(!projectId){
			if(arr.$destroy){ arr.$destroy(); }
			return;
		}
		arr = new firebaseMeshedUsers(projectId);
		defer.resolve(arr);
	}
	
	return {
		getSync: function(){
			return arr;
		},
		getAsync: function(){ //this is probably going to have issues long term...
			return defer.promise;
		}
	}
}])
.run(["firebaseMeshedUsersGlobal", function(firebaseMeshedUsersGlobal) {window.meshGetter = firebaseMeshedUsersGlobal.getSync;}]); //this effectively just ensures that this service is init'd really early in the lifecycle
