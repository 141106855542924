import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseList, AngularLandConnection } from 'ng2/fancy-firebase/base';

export class RoleList extends FancyFirebaseList<any, FancyAction>{
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		return Object.assign({prevData: {}}, action.payload.val());
	}
	$$updated(child, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.prevData.roleColor = child.roleColor;
		Object.assign(child, action.payload.val());
	}
	
	//put in interface so you can explicitly say you're doing this
	applyJoinData(baseKey: string, child, otherChild:any){
		switch(baseKey){
			case "roleColor":
				child.color = otherChild ? otherChild.color: null;
				break;
			default: break;
		}
	}
	getDataForKey(baseKey: string, child){
		return child[baseKey];
	}
	getPrevDataForKey(baseKey: string, child){
		return child.prevData[baseKey];
	}
}
