'use strict';

import * as angular from "angular";

angular.module('ticketspaceApp')
.factory('atomicAssembler', function() {
	
	function segment(path, obj){
		var newObj = {};
		newObj[path] = obj;
		return newObj;
	}
	
	function assemble(){
		var base = {};
		
		for(var i = 0; i < arguments.length; i++){
			angular.extend(base, arguments[i]);
		}
		return base;
	}
	
	return {
		segment: segment,
		assemble: assemble
	};
});
