export const joinKeyMapping = {
	 "roleId": {
		 "key": "role",
		 "updateFunctions": ["magicRoleRecalc"]
	 },
	 "locationId": {
		 "key": "location",
		 "updateFunctions": []
	 },
	 "responsibleProjectMemberId": {
		 "key": "responsibleProjectMember",
		 "updateFunctions": ["magicRoleRecalc"]
	 },
	 "assignedProjectMemberId": {
		 "key": "assignedProjectMember",
		 "updateFunctions": ["magicRoleRecalc"]
	 },
	 "priorityId": {
		 "key": "priority"
	 }
}

export const baseList = {
	"activityName": {
		"type": "string",
		"clientWrite": true
	},
	"actualCrewSize": {
		"type": "number",
		"clientWrite": false,
		"plannedKey": "crewSize",
		"localKeyOverride": "liveCrewSize"
	},
	"actualDurationDays": {
		"type": "number",
		"clientWrite": false,
		"plannedKey": "durationDays",
		"localKeyOverride": "liveDurationDays"
	},
	"actualFinish": {
		"type": "date",
		"clientWrite": true,
		"plannedKey": "plannedFinish",
		"localKeyOverride": "liveFinish"
	},
	"actualStart": {
		"type": "date",
		"clientWrite": false,
		"plannedKey": "plannedStart",
		"localKeyOverride": "liveStart"
	},
	//this relates to  derived data, under another key, can probably just assume that's hard coded?
	"assignedProjectMemberId": {
		"type": "string",
		"clientWrite": true,
	},
	"createdAt": {
		"type": "string",
		"clientWrite": true,
	},
	//this could have derived data, don't think we need to bother for now though
	"creatorId": {
		"type": "string",
		"clientWrite": true,
	},
	"crewSize": {
		"type": "number",
		"clientWrite": true,
		"actualKey": "actualCrewSize",
		"localKeyOverride": "liveCrewSize"
	},
	"dateRequested": {
		"type": "date",
		"clientWrite": true,
	},
	"dateRequired": {
		"type": "date",
	},
	"dateRequiredOverride": {
		"type": "date",
		"clientWrite": true,
	},
	"durationDays": {
		"type": "number",
		"clientWrite": true,
		"actualKey": "actualDurationDays",
		"localKeyOverride": "liveDurationDays"
	},
	"hasPredecessorOutOfOrder": {
		"type": "boolean",
	},
	"hasScheduleViolations": {
		"type": "boolean",
	},
	"height": {
		"type": "number",
		"clientWrite": true,
	},
	"id": {
		"type": "string",
		"clientWrite": true,
	},
	"isActive": {
		"type": "boolean",
	},
	"isBlocked": {
		"type": "boolean",
	},
	"isCyclic": {
		"type": "boolean",
	},
	"isLateConstraintRequested": {
		"type": "boolean",
	},
	"isLateTicketFinish": {
		"type": "boolean",
	},
	"isOutOfOrder": {
		"type": "boolean",
	},
	"left": {
		"type": "number",
		"clientWrite": true,
	},
	"notes": {
		"type": "string",
		"clientWrite": true,
	},
	"locationId": {
		"type": "string",
		"clientWrite": true,
	},
	"plannedFinish": {
		"type": "date",
		"clientWrite": true,
		// "actualKey": "actualFinish",
		"actualKey": "lastPlannedFinish", //TEMP FIX FOR NON-RENDERING ACTUALS
		"localKeyOverride": "liveFinish"
	},
	"plannedStart": {
		"type": "date",
		"clientWrite": true,
		// "actualKey": "actualStart",
		"actualKey": "lastPlannedStart", //TEMP FIX FOR NON-RENDERING ACTUALS
		"localKeyOverride": "liveStart"
	},
	"predecessorOutOfOrderCount": {
		"type": "number"
	},
	"predecessors": {
		"type": "object",
		"clientWrite": true,
	},
	"priorityId": {
		"type": "string",
		"clientWrite": true,
	},
	"procoreRfiId": {
		"type": "string",
		"clientWrite": true,
	},
	"promisePeriodPosition": {
		"type": "string",
		"clientWrite": true,
	},
	"promisePeriodStack": {
		"type": "object",
		"clientWrite": true,
	},
	"pullColumn": {
		"type": "number"
	},
	"responsibleProjectMemberId": {
		"type": "string",
		"clientWrite": true,
	},
	"roleId": {
		"type": "string",
		"clientWrite": true,
	},
	"successors": {
		"type": "object",
		"clientWrite": true,
	},
	"tminusFinish": {
		"type": "number"
	},
	"top": {
		"type": "number",
		"clientWrite": true,
	},
	"type": {
		"type": "string",
		"clientWrite": true,
	},
	"width": {
		"type": "number",
		"clientWrite": true,
	},
	"workingDays": {
		"type": "object",
		"clientWrite": true,
	},
}

export const derivedProperties = {
	"isActualized": {
		"type": "boolean"
	},
	"shortActivityName": {
		"type": "string"
	}
}

export let localList = {};
export let reverseLiveMap = {};
function addToReverse(liveKey, type, realKey){ 
	if(!reverseLiveMap[liveKey]){ reverseLiveMap[liveKey] = {};}
	reverseLiveMap[liveKey][type] = realKey;
}

for(var key in baseList){
	let baseData = baseList[key];
	let skip = false;
	
	if(baseData.actualKey){
		key = baseData.localKeyOverride; //<------------ figure out something when override isn't set
		addToReverse(baseData.localKeyOverride, "actual", baseData.actualKey);
	}
	if(baseData.plannedKey){
		// skip = true; //...why is this skipping?
		addToReverse(baseData.localKeyOverride, "planned", baseData.plannedKey);
	}
	
	
	if(!skip){
		localList[key] = baseData;
	}

}
console.log('reverseLiveMap', reverseLiveMap);

Object.assign(localList, derivedProperties);
// console.log('localList', localList, baseList);
