'use strict';

import Column from "js/lib/ui/Column";
import Row from "js/lib/ui/Row";

export default function Alignment(scope){
	this.list = [];
	this.scope = scope;
};

Alignment.prototype.getNumberList = function(list, prop){
	var numberList = [];
	list.forEach(function(ticket){
		if (ticket.data[prop]) {numberList.push(ticket.data[prop]);}
	});
	return numberList;
};

Alignment.prototype.sortBy = function(field, reverse, primer){
	var key = primer ? function(x) {return primer(x.data[field])} : function(x) {return x.data[field]};
	reverse = !reverse ? 1 : -1;
	return function (a, b) {
		return a = key(a), b = key(b), reverse * (((a > b) as any) - ((b > a as any)));
	}
};

Alignment.prototype.getMax = function(numberList){
	return Math.max.apply(null, numberList);
};

Alignment.prototype.getDifference = function(x1, x2){
	return (x1 >= x2) ? x1 - x2 : 0;
};

Alignment.prototype.getLatest = function(){
	return this.list[this.list.length-1];
};

Alignment.prototype.getProp = function(prop){
	return this.getLatest()[prop]
};

Alignment.prototype.create = function(data, type){
	if (type === 'left'){
		return this.list.push(new Column(data));
	} else {
		return this.list.push(new Row(data));
	}
};

Alignment.prototype.save = function(saveFn){
	this.list.forEach(function(thing){
		saveFn(thing.save());
	});
};

// Determine which column the ticket is in. Make new column if not in a column. Create a new column if no columns created yet.
Alignment.prototype.assign = function(ticket, type){
	// Create first column if we don't have any created yet.
	var startX = (type === 'left') ? "startX" : "startY" ;
	var finishX = (type === 'left') ? "finishX" : "finishY" ;
	var wh = (type === 'left') ? "width" : "height";
	var data = {};
	if (this.list.length === 0){
		data[startX] = ticket.data[type];
		data[finishX] = ticket.data[type] + ticket.data[wh];
		data["list"] = [ticket];
		return this.create(data, type)
	}
	// Otherwise calculate the column to place this ticket in.
	var ticketPos = {
		left: ticket.data.left,
		top: ticket.data.top,
		width: ticket.data.width,
		height: ticket.data.height
	};
	var l = this.list.length;
	for (var i = 0; i < l; i++){
		if (this.list[i].inBounds(ticketPos)){
			// This ticket is grouped with an existing column
			this.list[i].add(ticket);
			return this.list[i][startX] = this.list[i].calculateMode(this.getNumberList(this.list[i].list, type));
		}
	}

	// This ticket exists in a different column.
	var magicNumber = this.getMinSpacing(ticket, type);
	var dif = this.getDifference(ticket.data[type] + ticket.data[wh], this.getProp("forceX") || this.getProp(startX));
	// This handles the activeLine and prevents pullTickets from crossing it.
	if (dif <= 0){
		magicNumber = 0;
		dif = 0;
	}
	var newX = ticket.data[type] - dif - magicNumber;
	if (type === "left" && newX < this.scope.activeLine.$value && ticket.data.left > this.scope.activeLine.$value){
		newX = ticket.data.left;
		magicNumber = 0;
		console.log("Blocked by activeLine");
	}
	data[startX] = newX;
	data[finishX] = ticket.data[type] + ticket.data[wh] + dif;
	data["list"] = [ticket];
	data["forceX"] = (magicNumber > 0) ? ticket.data[type] - dif - magicNumber : false;
	return this.create(data, type)
};

Alignment.prototype.getMinSpacing = function(ticket, type){
	// Returns the minimum spacing value used by ticket snapping.
	var min = 0;
	if (type === 'left'){
		min = 50;
	} else {
		min = (ticket.data.left > this.scope.activeLine.$value) ? 50 : 50;
	}
	return min;
};

Alignment.prototype.sort = function(ticketList, type){
	var self = this;
	// Sort ticketList
	ticketList = ticketList.sort(this.sortBy(type || "left", true, parseInt));
	ticketList.forEach(function(ticket){
		// Do not apply column based sort on active tickets
		if (type === 'left' && self.scope.activeLine && self.scope.activeLine.$value && ticket.data.left <= self.scope.activeLine.$value){
			console.log("Blocked!");
			return;
		}
		self.assign(ticket, type);
	});
	// Return generated list
	return this.list;
};

Alignment.prototype.verify = function(){
	var self = this;
	this.list.forEach(function(thing){
		thing.verify(self.scope.activeLine.$value || null);
	});
};

// If id undefined then align all tickets in column
Alignment.prototype.align = function(id){
	// We don't know which column has the id we wish to align, so call align for each column.
	this.list.forEach(function(thing){
		thing.align(id);
	});
	return this.list;
};
