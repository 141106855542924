import { Rectangle, Point, Container, DisplayObject, utils, ticker } from "pixi.js";
import { fromEvent, Observable, Subject } from "rxjs";
import { takeUntil, share } from "rxjs/operators";
import { FancyGraphics } from "../graphics/FancyGraphics";
import { TweenManager, Easing } from "../tween/";

/**
 * Enum storing all of the camera effects the camera supports.
 */
export enum CAMERA_EFFECT_TYPES {
	"FLASH",
	"FADE_OUT",
	"FADE_IN"
}

/**
 * @param 0 Current viewport
 * @param 1 Current scale
 * @param 2 Last viewport
 * @param 3 Last scale
 */
export interface ViewportChangedEvent{
	view: Rectangle,
	scale: Point,
	lastView?: Rectangle,
	lastScale?: Point
}
/**
 * A simple camera class that provides helpful methods for manipulating the viewport into the TouchPlan world.
 */
export class Camera extends utils.EventEmitter {
	/** The display object that contains the entire TouchPlan world **/
	public cameraContainer: Container;
	/** The current view dimensions of the camera. **/
	public view: Rectangle;
	public globalView: Rectangle;
	/** The current scale value of the Camera **/
	public scale: Point = new Point(0, 0);
	/** Rounds the view x and y coordinates if true. **/
	public roundPixels: boolean = true;
	/** Read only property that controls how many display objects are current in the camera viewport **/
	public totalInView: number = 0;
	/** Instance of EventEmitter3. Dispatches events when the camera completes certain activities **/
	public emitter: utils.EventEmitter = new utils.EventEmitter();
	public worldGraphics: FancyGraphics;
	/**
	 * An observable for when the camera viewport changed
	 */
	public changed$: Observable<ViewportChangedEvent>;
	/** PIXI.Graphics object which draws special camera effects **/
	public effect: FancyGraphics;
	/** The length of the current effect **/
	private _effectDuration: number = 0;
	/** The current camera effect type being drawn **/
	private _effectType: number = 0;
	/**
	 * Shuts down the camera changed observable
	 */
	private _shutdownSubject: Subject<any> = new Subject();
	private _lastView: Rectangle;
	private _lastScale: Point = new Point(0, 0);
	public tweenManager: TweenManager;
	public isScalingAnimation: boolean = false;
	/**
	 * @param {PIXI.Container | FancyContainer} cameraContainer
	 * @param {FancyContainer | PIXI.Container} stage
	 * @param {TweenManager} tweenManager
	 * @param {number} x
	 * @param {number} y
	 * @param {number} width
	 * @param {number} height
	 */
	constructor(cameraContainer: Container, stage: Container, tweenManager: TweenManager, x: number, y: number, width: number, height: number){
		super();
		this.tweenManager = tweenManager;
		this.cameraContainer = cameraContainer;
		// Create the viewport rectangle
		this.view = new Rectangle(x, y, width, height);
		this._lastView = new Rectangle(x, y, width, height);
		this.globalView = this.getGlobalView();
		
		this.effect = new FancyGraphics();
		
		stage.addChild(this.effect);
		
		this.changed$ = fromEvent<ViewportChangedEvent>(this, 'viewportChanged').pipe(takeUntil(this._shutdownSubject), share())
	}
	
	public screenToRealX(x){
		return x / this.cameraContainer.scale.x - this.view.x / this.cameraContainer.scale.x;
	}
	public screenToRealY(y){
		return y / this.cameraContainer.scale.y - this.view.y / this.cameraContainer.scale.y;
	}
	public realToScreenX(x){
		return x * this.cameraContainer.scale.x + this.view.x;
	}
	public realToScreenY(y){
		return y * this.cameraContainer.scale.y + this.view.y;
	}
	/**
	 * Method that is called before the update loop
	 */
	public preUpdate(delta?: number){
		this.totalInView = 0;
	}
	/**
	 * Returns the current scale value
	 */
	public getScale(){
		return this.cameraContainer.scale;
	}
	
	public getScreen(): Rectangle {
		return new Rectangle(0, 0, this.view.width, this.view.height);
	}
	
	public getGlobalView(): Rectangle {
		return new Rectangle(
			this.screenToRealX(0),
			this.screenToRealY(0),
			this.view.width,
			this.view.height
		)
	}
	
	/**
	 * Called each time the renderer updates
	 */
	public update(deltaTimeMs: number = ticker.shared.elapsedMS){
		if (this._effectDuration > 0){
			this.updateEffect(deltaTimeMs);
		}
		
		if (this.roundPixels){
			this.view.x = Math.floor(this.view.x);
			this.view.y = Math.floor(this.view.y);
			this.view.width = Math.floor(this.view.width);
			this.view.height = Math.floor(this.view.height);
		}
		
		// Update the 'world' position
		this.cameraContainer.x = this.view.x;
		this.cameraContainer.y = this.view.y;
		this.cameraContainer.scale.x = this.scale.x;
		this.cameraContainer.scale.y = this.scale.y;
		
		this.globalView.x = this.screenToRealX(0);
		this.globalView.y = this.screenToRealY(0);
		this.globalView.width = this.screenToRealX(this.view.width) - this.globalView.x;
		this.globalView.height = this.screenToRealY(this.view.height) - this.globalView.y;
	}
	/**
	 * Focus the camera on the passed in DisplayObject
	 * @param {PIXI.DisplayObject} displayObject
	 */
	public focusOn(displayObject: DisplayObject){
		this.setPosition(
			Math.round(displayObject.x - (this.view.width / 2)),
			Math.round(displayObject.y - (this.view.height / 2))
		);
	}
	/**
	 * Set the camera position to the provided X and Y coordinates.
	 * @param {number} x
	 * @param {number} y
	 */
	public setPosition(x: number, y: number, silent = false){
		this._lastView.x = this.view.x;
		this._lastView.y = this.view.y;
		this.view.x = x;
		this.view.y = y;
		if(!silent){ this._dispatchViewChanged(); }
	}
	/**
	 * Set the scale value of the camera. This will zoom in/zoom out the cameraContainer.
	 * @param {number} xScale
	 * @param {number} yScale
	 */
	public setScale(xScale: number, yScale: number, silent = false){
		this._lastScale.x = this.scale.x;
		this._lastScale.y = this.scale.y;
		this.scale.x = xScale;
		this.scale.y = yScale;
		if(!silent){ this._dispatchViewChanged(); }
	}
	
	public setPositionAndScale(x:number, y:number, z:number, z2 = z){
		this.setPosition(x, y, true);
		this.setScale(z, z2, true);
		this._dispatchViewChanged();
	}
	
	public tweenScale(scale: number, duration: number = 350, easing = Easing.QUADRATIC.IN_OUT): void {
		this.isScalingAnimation = true;
		
		this.tweenManager.tween(this.scale, {
			x: scale,
			y: scale
		}, duration, {
			easing: easing,
			onComplete: () => {
				this.isScalingAnimation = false;
				this._dispatchViewChanged();
			}
		}).start();
	}
	
	public tweenView(x: number, y: number, duration: number = 350, easing = Easing.QUADRATIC.IN_OUT): void {
		this.isScalingAnimation = true;
		
		this.tweenManager.tween(this.view, {
			x: x,
			y: y
		}, duration, {
			easing: easing,
			onComplete: () => {
				this.isScalingAnimation = false;
				this._dispatchViewChanged();
			}
		}).start();
	}
	
	public focusOnXY(x: number, y: number): void {
		this.tweenView(x, y);
	}
	
	public focusOnXYZ(x: number, y: number, z: number): void {
		this.tweenView(x, y);
		this.tweenScale(z);
	}
	
	public focusOnPoint(point: Point, scale?: number): void {
		this.tweenView(point.x, point.y);
		if (scale){
			this.tweenScale(scale);
		}
	}
	
	public scaleToFit(newPoint: Point, scale: number){
		this.tweenView(newPoint.x, newPoint.y);
		this.tweenScale(scale);
	}
	
	/**
	 * A simple camera effect to make the camera flash.
	 * @param {number} color The color to flash the screen
	 * @param {number} duration The length of the flash
	 * @returns {Camera}
	 */
	public startFlash(color: number = 0x000000, duration: number = 1000){
		this.effect.clear();
		this.effect.beginFill(color);
		this.effect.drawRect(0, 0, this.view.width, this.view.height);
		this.effect.alpha = 1;
		
		this._effectDuration = duration;
		this._effectType = CAMERA_EFFECT_TYPES.FLASH;
		return this;
	}
	/**
	 * A simple camera effect which starts at alpha 0 and lineally fades to the provided color
	 * @param {number} color
	 * @param {number} duration
	 */
	public startFadeOut(color: number = 0x000000, duration: number = 1000){
		this.effect.clear();
		this.effect.beginFill(color);
		this.effect.drawRect(0,0, this.view.width, this.view.height);
		this.effect.alpha = 0;
		this._effectDuration = duration;
		this._effectType = CAMERA_EFFECT_TYPES.FADE_OUT;
	}
	/**
	 * A simple camera effect which starts at alpha 1 and lineally fades in to the provided color
	 * @param {number} color
	 * @param {number} duration
	 */
	public startFadeIn(color: number = 0x000000, duration: number = 1000){
		this.effect.clear();
		this.effect.beginFill(color);
		this.effect.drawRect(0,0, this.view.width, this.view.height);
		this.effect.alpha = 1;
		this._effectDuration = duration;
		this._effectType = CAMERA_EFFECT_TYPES.FADE_IN;
	}
	/**
	 * Called when the scene is destroyed. Nulls references and destroys the graphics object
	 */
	public destroy(){
		this._shutdownSubject.next();
		this.effect.destroy();
		this.cameraContainer = null;
		this.view = null;
	}
	/**
	 * Called by the update function, updates the state of the current running effect.
	 */
	private updateEffect(deltaTimeMs: number = ticker.shared.elapsedMS){
		if (this._effectType === CAMERA_EFFECT_TYPES.FADE_OUT){
			this.effect.alpha += deltaTimeMs / this._effectDuration;
			if (this.effect.alpha >= 1){
				this._effectDuration = 0;
				this.effect.alpha = 1;
				this.emit('fadeComplete');
			}
		} else if (this._effectType === CAMERA_EFFECT_TYPES.FADE_IN){
			this.effect.alpha -= deltaTimeMs / this._effectDuration;
			if (this.effect.alpha >= 1){
				this._effectDuration = 0;
				this.effect.alpha = 1;
				this.emit('fadeComplete');
			}
		} else if (this._effectType === CAMERA_EFFECT_TYPES.FLASH){
			this.effect.alpha -= deltaTimeMs / this._effectDuration;
			if (this.effect.alpha <= 0){
				this._effectDuration = 0;
				this.effect.alpha = 0;
				this.emit('flashComplete');
			}
		}
	}
	
	public resize(width: number, height: number): Camera {
		this.view.width = width;
		this.view.height = height;
		this.update();
		this._dispatchViewChanged();
		return this;
	}
	/**
	 * Helper function to dispatch an event every time the viewport changes.
	 */
	private _dispatchViewChanged(): void{
		this.update();
		this.emit('viewportChanged', {
			view: this.view,
			scale: this.scale,
			lastView: this._lastView,
			lastScale: this._lastScale
		});
	}
}
