import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";

import { RallyPointSignupService } from "ng2/common/services/rally-point-signup.service";

// import { LoadingBlockerState } from "ng2/common/components/loading-blocker";

export abstract class RallyBaseComponent implements OnInit, OnDestroy {
	protected shutdownSubject = new Subject();
	public loadingChanged = new Subject<any>();
	public loadingSet = new Subject<any>();
	
	public rallyForm:FormGroup;

	constructor(
		public rallyPointSignupService: RallyPointSignupService
	) { }
	
	abstract next()
	
	redirect(testResult){
		this.rallyPointSignupService.handleRedirect(testResult, this.loadingSet, this.loadingChanged);
	}

	ngOnInit() {
		this.rallyPointSignupService.rallyState.pipe(takeUntil(this.shutdownSubject)).subscribe(data => {
			this.rallyForm.patchValue(data)
			if(!data.accessCodeData && data.accessCode){ this.rallyPointSignupService.getAccessCodeData(data.accessCode); }
		});
	}
	ngOnDestroy(){
		this.shutdownSubject.next(true);
	}
}
