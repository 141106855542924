import {Injectable, Inject, NgZone} from "@angular/core";
import {Observable, BehaviorSubject, Subject, Subscription, of } from "rxjs";
import {filter, take, tap, takeUntil, map, switchMap} from "rxjs/operators"

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { auth } from "firebase/app";

import * as firebaseUtils from "ng2/fancy-firebase/firebase-utils";
import {UserObject} from "ng2/fancy-firebase/objects/UserObject";
import { User } from "ng2/common/models";

import { FancyActionTrackerFactory } from "./fancy-action-tracker-factory.service";

import { DefaultProjectSetup } from "ng2/common/ajs-upgrade-providers";

//next things:
//- implement getting the user data from auth
// - use in account.profile to init and update form


import {CoreState} from "ng2/common/interfaces/app-state.interface";

@Injectable()
export class AppState implements CoreState{
	private shutdownSubject = new Subject();
	private unsubscribeList:Array<Subscription>;
	private angularLand = {
		angularFireDatabase: this.angularFireDatabase,
		fancyActionTrackerFactory: this.fancyActionTrackerFactory
	}
	
	public user: Observable<User>;
	public userSync: User;
	
	constructor(
		public angularFireAuth: AngularFireAuth,
		private angularFireDatabase: AngularFireDatabase,
		private fancyActionTrackerFactory: FancyActionTrackerFactory,
		private defaultProjectSetup: DefaultProjectSetup
	){
		window.appState = this;
	}
	
	//see no real reason to bother passing in user data...
	setup(){
		this.unsubscribeList = [];
		
		this.user = this.angularFireAuth.user.pipe(
			takeUntil(this.shutdownSubject),
			switchMap(authUser => {
				if(!authUser){ return of(null); }
				
				var userObject = new UserObject(this.angularLand, firebaseUtils.baseRef("users").child(authUser.uid));
				
				return userObject.object$;
			}),
			// tap(user => console.log('user get', user))
		);
		
		this.user.subscribe((user) => {
			console.log('user', user);
			this.userSync = user
			// this.checkForCopyDefaultProject(user);
		});
		
		//only try to copy the sample project fir 
		this.user.pipe(
			filter(user => user !== null && user !== undefined),
			take(1)
		).subscribe(user => {
			console.log('first user', user);
			this.checkForCopyDefaultProject(user);
		})
	}
	boom(){

		this.shutdownSubject.next(true);
		this.unsubscribeList.forEach( sub => sub.unsubscribe() );
	}
	
	
	private checkForCopyDefaultProject(user?: User){
		if(user && user.hasNotCopiedDefault){
			this.defaultProjectSetup.addToSharedProject(user.id).then(()=>{
				return this.defaultProjectSetup.copySampleProject(user.id)
				.then(function(){
					console.log('default project copied');
				})
				.catch(function(error){
					console.log('failed to copy default project', error);
				});
			});
		}
	}
}
