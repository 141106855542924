import { InjectionToken } from "@angular/core";

import { AccessCheck } from "js/services/access-check";
import { ActiveColumnService } from "js/services/active-columns";
import { DatePositionValidator } from "js/services/date-position-validator.factory";
import { AuthService } from "js/services/auth-service";
import { DefaultProjectSetup } from "js/services/default-project-setup";
import { RallyPointService } from "js/services/firebase-wrappers/rally-point";
import { FbdbRequests } from "js/services/fbdb-requests";


//re-export proper class based so every upgrade can imported from here
export { AccessCheck, ActiveColumnService, DatePositionValidator, AuthService, DefaultProjectSetup, RallyPointService, FbdbRequests };

//A generic place for upgrading angularjs services to angular
//
//----------------------------
// STATE_PARAMS
//----------------------------
// extra bs required because we can't easily get at the class information since it's not our code
// Example:
// import FIREBASE_USERS_TOKEN
// constructor( @Inject(FIREBASE_USERS_TOKEN) firebaseUsers)

// export const FIREBASE_USERS_TOKEN = new InjectionToken('firebaseUsers');
// export function firebaseUsersFactory(i: any){
// 	return i.get('firebaseUsers');
// }
// export const firebaseUsersFactoryProvider = {
// 	provide: FIREBASE_USERS_TOKEN,
// 	useFactory: firebaseUsersFactory,
// 	deps: ['$injector']
// }


//cheating for this one
export const FIREBASE_USERS_TOKEN = new InjectionToken('firebaseUsers');
export function firebaseUsersFactory(i: any){
	return i.get('firebaseUsers');
}
export const firebaseUsersFactoryProvider = {
	provide: FIREBASE_USERS_TOKEN,
	useFactory: firebaseUsersFactory,
	deps: ['$injector']
}


//template for things that don't require an InjectionToken (anything where we can access a definition class)
//----------------------------
// ClassName
//----------------------------
// import ClassName from "path"
// constructor( className: ClassName)

export function accessCheckFactory(i: any){
    return i.get('accessCheck');
}
export const accessCheckProvider = {
    provide: AccessCheck,
    useFactory: accessCheckFactory,
    deps: ['$injector']
}


//----------------------------
// ActiveColumnService
//----------------------------
export function activeColumnServiceFactory(i: any){
    return i.get('activeColumnService');
}
export const activeColumnServiceProvider = {
    provide: ActiveColumnService,
    useFactory: activeColumnServiceFactory,
    deps: ['$injector']
}

//----------------------------
// DatePositionValidator
//----------------------------
export function datePositionValidatorFactory(i: any){
	return i.get('DatePositionValidator');
}
export const datePositionValidatorProvider = {
	provide: DatePositionValidator,
	useFactory: datePositionValidatorFactory,
	deps: ['$injector']
}


//----------------------------
// AuthService
//----------------------------
export function authServiceFactory(i: any){
	return i.get('authService');
}
export const authServiceFactoryProvider = {
	provide: AuthService,
	useFactory: authServiceFactory,
	deps: ['$injector']
}

//----------------------------
// DefaultProjectSetup
//----------------------------
export function defaultProjectSetupFactory(i: any){
	return i.get('defaultProjectSetup');
}
export const defaultProjectSetupProvider = {
	provide: DefaultProjectSetup,
	useFactory: defaultProjectSetupFactory,
	deps: ['$injector']
}

//----------------------------
// RallyPointService
//----------------------------
export function rallyPointServiceFactory(i: any){
	return i.get('rallyPointService');
}
export const rallyPointServiceFactoryProvider = {
	provide: RallyPointService,
	useFactory: rallyPointServiceFactory,
	deps: ['$injector']
}

//----------------------------
// FbdbRequests
//----------------------------
export function fbdbRequestsFactory(i: any){
	return i.get('fbdbRequests');
}
export const fbdbRequestsFactoryProvider = {
	provide: FbdbRequests,
	useFactory: fbdbRequestsFactory,
	deps: ['$injector']
}





//--------------------------------------------------------------------------
//- Module definition
//--------------------------------------------------------------------------

import { NgModule } from '@angular/core';

@NgModule({
	imports: [],
	providers: [
		accessCheckProvider,
		activeColumnServiceProvider,
		datePositionValidatorProvider,
		authServiceFactoryProvider,
		defaultProjectSetupProvider,
		rallyPointServiceFactoryProvider,
		firebaseUsersFactoryProvider,
		fbdbRequestsFactoryProvider
	]
})
export class AjsUpgradeModule{}
