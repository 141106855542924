'use strict';
//keywords:
//	todo
//	genericize

import * as angular from "angular";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

angular.module('ticketspaceApp')
.factory('firebaseRoles', ["$q", "$log", "$firebaseUtils", "$firebaseArray", "firebaseVirtualWrapper", "fbConnection", "SystemColors", "popup", function($q, $log, $firebaseUtils, $firebaseArray, firebaseVirtualWrapper, fbConnection, SystemColors, popup) {
	//it's worth investigating if this edit stuff can be inherited from another object since most of these use the same thing
	function RolesList(refUrl, roleColors){
		this.init(refUrl, roleColors);
	}
	RolesList.prototype = Object.create(firebaseVirtualWrapper);
	RolesList.prototype.setup({'addKey': 'roleId'});

	//#### init(refUrl)
	// builds the instance, creates the fb object and inits some of the other keys
	RolesList.prototype.init = function init(refUrl, roleColors){
		var sync = firebaseVirtualWrapper.init.call(this, refUrl, eFactory, roleColors); //super
		sync.$watch(function(){
			sync.sort(function(a,b){
				var baseName = a.data.roleName || "";
				return baseName.localeCompare(b.data.roleName);
			});
		});
	};

	//#### edit(id)
	// sets edit for the id passed in
	RolesList.prototype.edit = function edit(id){
		var val = firebaseVirtualWrapper.edit.call(this, id);
		if(val === -1){
			this.temp.roleColor = this.nextStyle();
		}
		else if(val){
			this.temp.roleName = val.data.roleName;
			this.temp.roleColor = val.data.roleColor;
		}
	};

	RolesList.prototype.finishEdit = function finishEdit(){
		//sanity checks
		if(!this.temp.roleName){return $q.reject(new Error('name is required'));}
		
		//return an object faking an error because the catch handler is expecting an actual error...
		var existingRole = this.checkForName(this.temp.roleName);
		if(existingRole && existingRole.$id !== this.editIndex){ return $q.reject(new Error('Roles must have unique names')); } 

		return firebaseVirtualWrapper.finishEdit.call(this);
	};

	RolesList.prototype.nextStyle = function nextStyle(){
		return this.roleColors.getFirstUnusedColor();
	};
	
	RolesList.prototype.checkForName = function checkForName(name){
		if(!name){return false;}
		for(var i = 0; i < this.fb.length; i++){
			if(this.fb[i].data.roleName === name){return this.fb[i]}
		}
		return false;
	};
	
	//first character of the first 2 valid words
	//fallback to first 2 characters of the first word
	function getAbrev(wordList, startAt?){
		startAt = startAt || 0;
		if(!wordList || !wordList.length){return "NA";}
		var abrev = "";
		for(var i = startAt; i < wordList.length; i++){
			var isValid = !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(wordList[i][0]);
			if(isValid){abrev += wordList[i][0]}
		}
		if(abrev.length < 1){ return "NA";}
		else if(abrev.length < 2){ return abrev += wordList[0][1] ? wordList[0][1] : "" }
		else{ return abrev[0]+abrev[1];}
	}

	function Role(snap){
		this.$id = snap.key;
		this.update(snap);
	}
	Role.prototype = {
		update: function(snap){
			//store the data on...data
			
			var newData = snap.val();
			if(!this.data && newData || ( this.data && newData && this.data.roleName !== newData.roleName)){
				var n = newData.roleName;
				
				if(n){
					var words:Array<string> = n.split(' ');
					var doctoredWords = [];
					words.forEach(word => {
						if(word !== ""){ doctoredWords.push(word); }
					})
					this.abrev = getAbrev(doctoredWords);
				}
			}
			
			this.data = newData;
			if (!this.data.roleId){this.data.roleId = snap.key;}
			
			this.prevData = snap.val();
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		}
	}

	function RolesFactory(ref, roleColors){
		this.roleColors = roleColors;
		this._usedStyles = this.roleColors.getUsedColors();
		this.colorPalette = this.roleColors.getColorPalette();
		var thing = $firebaseArray.call(this, ref);
		thing._usedStyles = this._usedStyles; // <----------------- and now expose the same externally
		thing.colorPalette = this.colorPalette;
		thing.roleColors = this.roleColors;
		return thing;
	}
	RolesFactory.prototype = {
		$$added: function(snap){
			var color = snap.child('roleColor').val();
			$firebaseArray.prototype.$$added.call(this,snap);
			this.roleColors.updateUsedColors('add', color);
			return new Role(snap)
		},
		$$updated: function(snap){
			var rec = this.$getRecord(snap.key);
			var colorId = snap.child('roleColor').val();
			var color = this.roleColors.$getRecord(colorId);
			var oldColor = this.roleColors.$getRecord(rec.prevData.roleColor);
			if (color && oldColor){this.roleColors.updateUsedColors('change', color.data.color, oldColor.data.color);}
			else {
				// MOC-2489
				this.roleColors.updateUsedColors('add', colorId);
			}
			rec.update(snap);
		},
		$$removed: function(snap){
			var style = snap.child('roleStyle').val();
			var color = snap.child('roleColor').val();
			if (!color && style){color = SystemColors.ROLE_CLASS_NAME_TO_COLOR[style];}
			this.roleColors.updateUsedColors('remove', color);
			return this.$getRecord(snap.key);
		},
		editableListAdd: function(data){
			if (!data.roleName || !data.roleColor){return $q.rejectErr("Role must have a name and a color!");}
			var self = this;
			// The handleAddColor function always returns a promise with a color record as the first returned parameter
			// If no color exists, then it adds a color to Firebase and the palette and then gets the ID.
			// Once it has the ID, then we finish up and add the role, with the roleColor pointing to the new color id.
			return this.roleColors.handleAddColor(data.roleColor)
				.then(function(record){
					data.roleColor = record.$id;
					return self.$list.$add.call(self.$list, data, 'roleId')
				})
				.then(function(snap){
					analyticsService.createdARole(snap.key, data.roleName);
					return snap;
				})
		},
		editableListRemove: function(idx){
			var args = arguments;
			var self = this;
			return popup({title: "Are you sure you want to delete this role? (Existing tickets for this role will turn black.)"}).then(function(){
				if(idx && self.$list[idx]){
					var role = self.$list[idx];
					analyticsService.deletedARole(role.$id, role.data.roleName);
				}
				
				return self.$list.$remove.apply(self.$list, args);
			});

		},
		// Update role work days
		updateRoleWorkDays: function(id){
			return this.$save(this.$getRecord(id)).catch(function(err){console.log(err);});
		},
		usedRoleList: function(){
			return this._usedStyles;
		},
		set: function(idx, data){
			let item = this.$list[idx];
			if(!item){return $q.rejectErr("item doesn't exist");}
			if(!data || !data.roleName || !data.roleColor){return $q.rejectErr("Role name and color is required");}
			item.data.roleName = data.roleName;
			let color = data.roleColor;
			let newRec = this.roleColors.getRecordByColor(color);
			item.data.roleColor = newRec.$id;
			return this.$save(idx);
		},
		//copied/ pasted from the other one until this file gets fixed...
		nextStyle: function nextStyle(){
			return this.roleColors.getFirstUnusedColor();
		},
		cloneForEdit: function (index, collection){
			if(index === -1){
				return { roleColor: collection.nextStyle() };
			}
			if(index !== -1 && collection[index] !== undefined){
				var obj = angular.extend({}, collection[index].data);
				if(obj.roleColor){obj.roleColor = this.roleColors.getColor(obj.roleColor);}
				return obj;
			}
			return {};
		}
	};

	var eFactory = $firebaseArray.$extend(RolesFactory);

	// individual factory caching test
	var lastProjectId;
	var listCache;

	var functionToReturn:any = function(projectId, roleColors){
		var refUrl = 'roles/' + projectId;
		var theFac = new RolesList(refUrl, roleColors);
		return theFac
	};

	return functionToReturn;
}]);
