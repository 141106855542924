//file to collect any custom rxjs operators we write

declare let Zone: any;
// import "zone.js";


import {Observable} from "rxjs";
import { scan } from "rxjs/operators";

const hiddenZone = Zone.current.fork({name: 'hideFromAngular'});

/** counts the number of events, emits the count on each event */
export function countLive(){
	return function countLiveImplementation(source){
		return source.pipe(
			scan((count, evt)=>{ return count + 1; }, 0)
		)
	}
}

/**
 * drops every n emissions, then emits, then drops, etc.
 * @param  count number of emissions to drop, n > 1
 */
export function drop(count: number, log = false){
	const max = count + 1;
	let counter = 0;
	return function dropImplementation(source) {
		
		return Observable.create(subscriber => {
			return source.subscribe(
				value => {
					if(log){ console.log('counter', counter+'/'+max); }
					if(counter % max === 0){ subscriber.next(value); counter = 0;}
					counter += 1;
				}, 
				err => subscriber.error(err),
				() => subscriber.complete()
			);
		});
	};
}

export function runInHiddenZone() {
	return runInZone(Zone.root);
}

export function runInRootZone(cb){
	return Zone.root.run(cb);
}


export function runInZone(zone) {
	return function mySimpleOperatorImplementation(source) {
		return Observable.create(observer => {
			const onNext = (value) => zone.run(() => observer.next(value));
			const onError = (e) => zone.run(() => observer.error(e));
			const onComplete = () => zone.run(() => observer.complete());
			return source.subscribe(onNext, onError, onComplete);
		});
	};
}

export function alternativeBuffer(bufferObs:Observable<any>){
	let queue = [];
	let hasStuff = false;
	return function alternativeBufferImplementation(srcObs):Observable<Array<any>> {
		return Observable.create(subscriber => {
			srcObs.subscribe(
				value => {
					queue.push(value);
					hasStuff = true;
				}, 
				err => subscriber.error(err),
				() => subscriber.complete()
			);
			
			bufferObs.subscribe(t => {
				if(hasStuff){
					// console.time("frame ended");
					subscriber.next(queue); 
					queue = []; 
					hasStuff = false;
				}
			},
			err => subscriber.error(err),
			() => subscriber.complete())
		});
	};
}
