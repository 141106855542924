'use strict';

import * as angular from "angular";
import * as utils from "utils";
import { ProjectNotifier } from "ng2/common/services/project-notifier.service";
declare const window: any;

import { LegacyRouting } from "ng2/routes/legacy-routing.service";

angular.module('ticketspaceApp')

//## analyticsService
// A service intended to manage all (most?) the analytics events. The idea is
// that each event with have a function tied to it that can be called with
// event time arguments. The service also maintains it's own internal cache
// of data that it can add to events. It is also init'd early as part of the run
// cycle and added to window.analyticsService.
//
// Current naming convention "the event name" (event key) -> theEventName (function)
// ### Currently cached objects
// * projects - a list of previously accessed angularfire based project objects
//
// todo
// * merge utils.analyticsHelper functionality into this service
// * add a debug tool to display function signature from the console

.factory('analyticsService', ["firebaseProjects", "projectNotifier", "legacyRouting", function(firebaseProjects, projectNotifier: ProjectNotifier, legacyRouting:LegacyRouting) {
	
	//----------------------------------------------
	// Internal
	//----------------------------------------------
	var projects = {};
	var projectId = null;
	
	projectNotifier.projectState.subscribe(function(state){
		if(state.projectId){
			projectId = state.projectId
			
			if(!projects[state.projectId]){
				projects[projectId] = firebaseProjects.getProject(projectId);
				var unwatch = projects[projectId].$watch(function(){
		
					if(!projects[projectId]){unwatch(); return;} //additional check
					if(projects[projectId].$value === null){
						delete projects[projectId];
						unwatch();
					}
				});
			}
		}
	});
	
	function getProps(sourceObj, arr){
		
		var obj = {};
		arr.forEach(function(key){
			if(sourceObj && sourceObj[key]){
				if(key === '$id'){ obj['projectId'] = sourceObj[key]; }
				else{ obj[key] = sourceObj[key]; }
			}
		});
		return obj;
	}
	
	function projectProps(arr){ return getProps(projects[projectId], arr); }
	
	function clean(thing){
		return (thing === undefined || thing === null) ? null : thing;
	}
	
	function camelCase(name){
		return name.split(' ').map(function(name, idx){
			return idx ? name.slice(0,1).toUpperCase()+name.slice(1) : name;
		}).join('');
	}
	
	function getStateName(){
		return clean(legacyRouting.routeName());
	}


	
	var returnValue = {
		projectProps: projectProps,
		getStateName: getStateName,
	};
	// window.analyticsService = returnValue;
	window.legacyAnalyticsService = returnValue;
	return returnValue;
}])
.run(["analyticsService", function(analyticsService){
	
}]);
