
import { Component } from '@angular/core';


/**
 * Displays a menu of actions for the ticket.  Available actions
 * vary with the state of the ticket.
 * 
 * More detail...
 */
@Component({
	selector: 'ticket-action-menu',
	template: require('./ticket-action-menu.component.html'),
})
export class TicketActionMenuComponent {

	isMenuShown:boolean = false;

	// Toggles the isMenuShown property
	toggleMenu() {
		this.isMenuShown = !this.isMenuShown; 
	} 

}
