//TODO
// - figure out some better place to put this file


//NEXT STEPS 
// - get Ticket setup to accept multiple initialization options
// - make typings work
// - figure out design for "appEvents"

import { database } from 'firebase/app';
import { AngularFireDatabase, AngularFireList, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { Observable, Subject, BehaviorSubject, Subscription, ConnectableObservable, queueScheduler, merge, empty, from, of } from 'rxjs';
import { takeUntil, filter, map, mapTo, groupBy, debounce, skipWhile, takeWhile, buffer, share, bufferToggle, observeOn, tap, multicast, publish, concat, mergeMap, merge as mergeOperator } from 'rxjs/operators'
import { drop, runInHiddenZone, alternativeBuffer } from "ng2/common/rxjs-internal-extensions";

import * as firebaseUtils from 'ng2/fancy-firebase/firebase-utils';

import * as utils from "utils";

import { FancyAction, FancyActionTypes, AngularLandConnection, FancyIdJoin } from 'ng2/fancy-firebase/base';
import { FancySupplementalAction, SupplementalActionTypes } from "ng2/actions/supplemental-actions";

import { FancyActionTracker, FancyActionTrackerObj } from 'ng2/common/services/fancy-action-tracker-factory.service';

import { renderQueue } from "ng2/common/RenderQueue";

//types/ interfaces
type PathOrRefOrObservable = string | database.Reference | Observable<AngularFireAction<DatabaseSnapshot<any>>>;
function isRef(x: PathOrRefOrObservable):x is database.Reference{
	return (<any>x).ref !== undefined;
}
function isObservable(x: PathOrRefOrObservable) : x is Observable<AngularFireAction<DatabaseSnapshot<any>>>{
	return (<any>x).subscribe !== undefined;
}

interface hasType{
	type: string
}



export class FancyFirebaseList<FancyModel, SomeOtherActionName extends FancyAction>{
	private rawSubject:Subject<SomeOtherActionName>; //temp
	protected shutdownSubject = new Subject();
	
	//some code to log firebase events
	protected enableLogging = false;
	protected eventHistory = [];
	
	protected addActions:any = {[FancyActionTypes.childAdded]: FancyActionTypes.childAdded};
	protected removeActions:any = {[FancyActionTypes.childRemoved]: FancyActionTypes.childRemoved}
	
	/** if true none of the external observables will emit and events will be collected */
	protected suppressActionsForLoading = false;
	protected stopSuppressing$ = new Subject();
	private suppressedActions = [];
	protected subscriptions:Array<Subscription> = [];
	
	public loaded = false;
	public listRef: AngularFireList<FancyModel>

	protected _firebaseEvent$: Observable<any>; //oh screw you typescript
	
	//TEMP this needs to be corrected, just use for setting up the FancyIdJoin
	public _internalList = new Map<string, FancyModel>();
	
	/** TODO - move private somehow*/
	public appEvent$: Subject<SomeOtherActionName>;
	protected supplementalUpdate$: Subject<SomeOtherActionName>;
	
	public joinEvent$ = new Subject<FancyAction>();
	//public joinEvent$ = new Subject<FancyAction>().pipe(this.decorateActionWithPrev(action => this._internalList.get(action.key)));
	
	/** Subscribe to recieve raw "this ticket changed events" */
	public rawEvent$: ConnectableObservable<SomeOtherActionName>;
	/** Subscribe to recieve an updated list of tickets events. */
	public list$: BehaviorSubject<Map<string, FancyModel>>; //setup some sort of optimization that doesn't run the update until the whole thing is initially loaded
	
	private fancyJoins?: [FancyIdJoin<FancyModel, any>];

	public actionTracker: FancyActionTrackerObj;

	constructAction<SomeOtherActionName>(ctor, payload: any, type: string, key?: string):SomeOtherActionName{
		return new ctor(payload, type, key);
	}
	

	
	constructor(
			protected angularLand:AngularLandConnection,
			path:PathOrRefOrObservable, 
			protected actionCtor: new (...args: any[]) => SomeOtherActionName,
			fancyJoins?: FancyIdJoin<FancyModel, any> | [FancyIdJoin<FancyModel, any>]
		){
		
		this.actionTracker = this.angularLand.fancyActionTrackerFactory.create();
		
		if(fancyJoins){
			if(Array.isArray(fancyJoins)){ this.fancyJoins = fancyJoins; }
			else{ this.fancyJoins = [fancyJoins]; }
			this.fancyJoins.forEach((fancyJoin)=>{
				fancyJoin.start(this);
			})
		}
		
		var subject = new Subject<SomeOtherActionName>();
		this.rawSubject = subject;
		this.list$ = new BehaviorSubject(new Map());
		this.appEvent$ = new Subject();
		var mappedAppEvents = this.handleCustomEvents(this.appEvent$);
		
		this.supplementalUpdate$ = new Subject();
		var mappedSupplementalEvents = this.handleSupplementalEvents(this.supplementalUpdate$).pipe(
			takeUntil(this.shutdownSubject)
		);

		this._firebaseEvent$ = 
		merge(
			this.setupInputObservable(path)
			.pipe(
				takeUntil(this.shutdownSubject),
				map((action)=>{
					if(this.enableLogging){
						this.eventHistory.push(action.type + " " + action.key);
					}
					
					var child:FancyModel;
					var newAction:SomeOtherActionName;
					if(action.type === FancyActionTypes.childAdded){
						if(this._internalList.has(action.key)){ return null; } //allows for custom adds, might be better to convert to child_changed
						child = this.$$added(action);
						newAction = this.constructAction(this.actionCtor, child, action.type, action.key);
						this._internalList.set(action.key, child);
					}
					else if(action.type === FancyActionTypes.childChanged){
						child = this._internalList.get(action.key);
						newAction = this.constructAction(this.actionCtor, child, action.type, action.key);
						
						if(!child && window.reporting){
							window.reporting.reportRealWarning(
								"child undefined in firebaseUpdate call", 
								Object.assign(utils.makeStack(new Error("child undefined in firebaseUpdate call")), {
									actionKey: action ? action.key : 'undefined',
									actionType: action ? action.type : 'undefined',
									actionPayload: action && action.payload ? action.payload.val() : 'undefined',
									eventHistory: this.eventHistory,
									ticketCount: (this._internalList && this._internalList.size) ? this._internalList.size : 0
								}))
						}
						
						// this.decoratePrev(newAction, child);
						this.$$updated(child, action);
						// child.update(action.payload.val());
					}
					else if(action.type === FancyActionTypes.childRemoved){
						child = this._internalList.get(action.key);
						newAction = this.constructAction(this.actionCtor, child, action.type, action.key);
						// this.decoratePrev(newAction, child);
						this.$$removed(child); //chance to react
						this._internalList.delete(action.key);
					}
					// return {"child": child, "action": action.type};
					// return new FancyAction(child, action.type, action.key);
					return newAction;
					// return new SomeOtherActionName(child, action.type, action.key);
				}),
				filter(action => !!action)
			),
			mappedSupplementalEvents,
			mappedAppEvents as Observable<SomeOtherActionName>,
			this.joinEvent$
		)
		.pipe(
			//this doesn't feel like the "best" way to do this
			tap((action)=>{
				if(this.fancyJoins){
					this.fancyJoins.forEach((fancyJoin)=>{
						fancyJoin.manipBase(action);
					});
				}
			}),
			filter(action => {
				var stop = this.suppressActionsForLoading;
				if(this.suppressActionsForLoading){
					this.suppressedActions.push(action);
					// console.log('this.suppressedaction', this.suppressedActions)
				}
				return !stop;
			}),
			mergeOperator(this.stopSuppressing$.pipe(
				mergeMap(no =>{
					let list = this.suppressedActions;
					this.suppressActionsForLoading = false;
					this.suppressedActions = [];
					// console.log('dump', )
					return from(list);
				})
			)),
			observeOn(queueScheduler),
			tap(()=>{
				this.list$.next(this._internalList);
			})
		);

		


		//no, you're wrong rxjs, this is ConnectableObservable. Maybe you'll be right in a later version
		this.rawEvent$ = (this._firebaseEvent$.pipe(multicast(subject)) as ConnectableObservable<SomeOtherActionName>);
		this.rawEvent$.connect();
		
		//setup loaded check (somewhat experimental)
		//when angularfire1 does this they also check on all the child events, not sure how necessary this is
		//skip when not initialized by a path or ref
		if(this.listRef){
			this.listRef.query.ref.once('value', (dataWeDontCareAbout)=>{
				this.loaded = true;
			});
		}
	}
	
	private setupInputObservable(path : PathOrRefOrObservable){
		if(typeof path === "string"){
			this.listRef = this.angularLand.angularFireDatabase.list(path);
			return this.listRef.stateChanges()
		}
		else if(isRef(path)){
			//it's actually valid to pass a ref, the typescript definition is dumb
			this.listRef = this.angularLand.angularFireDatabase.list(<any>path);
			return this.listRef.stateChanges()
		}
		else if(isObservable(path)){
			return path;
		}
		else{
			throw new Error("the hell you pass in here");
		}
	}
	
	/**
	 * A thing that filters down events that happen in a render frame to at most one "non-significant" event per key.
	 * Or n significant events. Is useful because a lot of events will fire back to back for the same thing.
	 * And most of the tiome all that's wanted is a "data changed" notification
	 * @param  significantActions An object containing a key === value list of actionTypes that _need_ to happen.
	 * @param  sourceObservable   The observable to use as a source, defaults to rawEvent$.
	 * 	This would allow you to use safeRawEvent$ or something like that.
	 * @return                    Returns the transformed observable
	 */
	public getSoakedEvents$(significantActions?: Object, sourceObservable?: Observable<SomeOtherActionName>){
		// if(!this.suppressActionsForLoading) {return}
		//potentially create a variance here to say that there are no significant actions so that the forEach can be skipped
		// also consider creating a shared one if the significant events are consistent (maybe in the extended class)
		if(!significantActions){ significantActions = {}; } 
		if(!sourceObservable){ sourceObservable = this.rawEvent$; }

		let baseTick$ = renderQueue.ob$.pipe(takeUntil(this.shutdownSubject));
		// let baseTick$ = renderQueue.fakeFrame$.pipe(takeUntil(this.shutdownSubject));
		const delay250$ = baseTick$.pipe(drop(15, false), takeUntil(this.shutdownSubject));
		
		// var lastTime;
		// delay250$.subscribe(() => {console.log('delay250', Date.now() - lastTime, this); lastTime = Date.now();} )
		
		// console.time("delay");
		// baseTick$.subscribe(() => {console.timeEnd("delay"); console.time("delay")} )
		
		//ideally we shouldn't use a variable, but instead have a start/ stop observable
		// will _likely_ have to write an operator though
		let skip = false;
		
		//cull the animation frame updates when there's been no ticket updates
		this.rawEvent$.subscribe(thing => {skip = false; });
		let settle$ = this.rawEvent$.pipe( debounce(() => delay250$ ) );
		settle$.subscribe(thing => { skip = true; } );
		let tick$ = baseTick$.pipe(
			
			// tap(tick => {console.log('tick$', tick)}),
			filter(t => !skip), //ignore events while skipped
			// tap(tick => {console.log('tick$', tick)}),
			 //now multicast the suppressed one to avoid the splice when idle
			 // share(),
			 publish()
		);
		(tick$ as ConnectableObservable<number>).connect();
		// tick$.subscribe(test => console.log('tick$', test));
		
		return this.rawEvent$.pipe(
			// tap(raw => console.log('raw', raw)),
			groupBy(action => action.key),
			mergeMap(action$ => {
				// console.log('merge map', action$);
				return action$.pipe(
					// tap(tick => {console.log('action$', tick, 'skip:', skip)}),
					// buffer(tick$),
					alternativeBuffer(tick$), //a special buff that doesn't emit empty arrays
					// tap(raw => console.log('alt buff', raw)),
					// map(t => [t]),
					mergeMap(list => {
						// debugger;
						// console.log('list', list);
						// if(list.length === 0){return empty();}
						if(list.length === 1){ return of(list[0]); }
						
						let matched = [];
						list.forEach(e => {
							if(significantActions[e.type] !== undefined){matched.push(e);}
						})
						if(matched.length){return from(matched);}
						return of(list[0]);
					})
				)
			}),
			// takeUntil(this.shutdownSubject)
		);
	}
	
	/**
	 * Re-emits an action for every child in the list, allowing a potential observer to start fully
	 * in sync despite not subscribing since the beginning. Typically the observer
	 * would be expected to subscribe to this (executes syncronously) and then subscribe to
	 * the rawEvent$ proper.
	 */
	replayRawEvent$():Observable<SomeOtherActionName>{
		return from(this._internalList as any)
		.pipe(
			map((thing: [string, FancyModel])=>{
				return this.constructAction<SomeOtherActionName>(this.actionCtor, thing[1], FancyActionTypes.childReplayed, thing[0]);
			})
		)
	}
	
	/**
	 * A special variant of rawEvent$ that first replays the existing data as events,
	 * then runs off of the standard rawEvent$. Should be careful with this, it's
	 * pretty likely that it will run with a different execution order than standard
	 * rawEvent$. This _may_ be a problem in some cases.
	 */
	safeRawEvent$(){
		return this.replayRawEvent$().pipe(concat(this.rawEvent$))
	}
	
	/** get a custom observer for a list item that inits to the current known value, than emits the rawEvents for only that item */
	getChildOb$(id:string){
		let first = this._internalList.get(id);
		let firstAction = this.constructAction<SomeOtherActionName>(this.actionCtor, first, FancyActionTypes.childReplayed, id)
		return of(firstAction).pipe(
			concat(this.rawEvent$.pipe(
				filter(action => action.key === id)
			))
		)
	}
	
	/**
	 * Called by the list building code, 
	 * @param  action the angularfire snapshot
	 * @return        The constructed child object
	 */
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		var obj = action.payload.val();
		//TODO - create fancyfirebaseprimative class with default handling
		if(typeof obj !== "object"){ console.log('base FancyFirebase list does not support primative values. Extend and override with object mapping'); }
		else if(obj){ obj.$id = action.key; }
		return obj;
	}
  
	$$updated(child:FancyModel, action: AngularFireAction<DatabaseSnapshot<any>>){
		//TODO - create fancyfirebaseprimative class with default handling
		var payload = action.payload.val();
		if(typeof payload  !== "object"){ console.log('base FancyFirebase list does not support primative values. Extend and override with object mapping'); }
		Object.assign(child, payload);
	}
	$$removed(child:FancyModel){
		
	}
	
	//pass in a supplemental obserable to subscribe to, optionally wrapping it in an action
	public addSupplementalObservable(obs:Observable<SomeOtherActionName>, actionType?:FancyActionTypes, key?){
		if(!actionType){
			this.subscriptions.push(obs.subscribe(this.supplementalUpdate$))
		}
		else{
			this.subscriptions.push(obs.pipe(map(thing => this.constructAction(this.actionCtor, thing, actionType, key || null) )).subscribe(this.supplementalUpdate$));
		}
	}
	
	//override with the custom observable chain
	handleCustomEvents(baseObservable){
		return baseObservable;
	}
	
	handleSupplementalEvents(baseObservable):Observable<FancySupplementalAction>{
		return empty();
	}
	
	private cleanActionType(action: hasType | string){
		if(typeof action === "string"){ return action; }
		else if(action && action.type){ return action.type; }
	}
	
	public isAddAction(action: hasType | string){
		return !!this.addActions[this.cleanActionType(action)];
	}
	public isRemoveAction(action: hasType | string){
		return !!this.removeActions[this.cleanActionType(action)];
	}
	public isChangeAction(action: hasType | string){
		let clean = this.cleanActionType(action);
		return !this.addActions && !this.removeActions;
	}
	
	public destroy(){
		this.shutdownSubject.next();
		this.rawSubject.complete();
		this.appEvent$.complete();
		this.joinEvent$.complete();
		this.list$.complete();
		this.list$ = null;
		this.actionTracker.actionTracker$.destroy(this.actionTracker.unsubscriber); this.actionTracker = null;
		this.stopSuppressing$.complete();
		this.supplementalUpdate$.complete();
		this.shutdownSubject.complete();
		
		this.subscriptions.forEach( sub => sub.unsubscribe() );
		
		//probably not necessary
		// this.rawEvent$.co
		this._firebaseEvent$ = null;
		this.rawSubject = null;
		this.rawEvent$ = null;
		
		this._internalList = null;
		
		if(this.fancyJoins){
			this.fancyJoins.forEach(f => f.destroy())
		}
	}
	
	
	/**
	 * Called by the join class, if you use it, you'll be expected to override this
	 * method. It's expected to take a child of this class (extended), and an action of another
	 * extended instance of this class and apply the otherAction to this child.
	 * @param  baseKey     The key on the child to map.
	 * @param  child       [description]
	 * @param  otherAction [description]
	 * @return             void
	 */
	/*
	applyJoinData(baseKey: string, child:FancyModel, otherChild:any){
		console.log('YOU MUST CONSTRUCT MORE OVERRIDE');
		// switch(baseKey){
		// 	child.bleh = otherAction;
		// }
		// return child;
	}
	getDataForKey(baseKey: string, child:FancyModel){
		console.log('YOU MUST CONSTRUCT MORE OVERRIDE');
		return child[baseKey];
	}
	getPrevDataForKey(baseKey: string, child:FancyModel){
		return "you screwed up";
	}
	*/
	
}
