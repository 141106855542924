
import { Component, Input } from '@angular/core';

import { EditTicketsCombined } from 'ng2/common/models/EditTicketsCombined';

@Component({
	selector: 'multi-edit-masthead',
	template: require('./multi-edit-masthead.component.html'),
})
export class MultiEditMasthead {

	/**
	 * @input an ETC
	 */
	@Input() etc: EditTicketsCombined;

}
