import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Ticket, TicketTypes } from "../../common/models/Ticket";
import {TicketUtils} from "../utils/TicketUtils";

export enum COMPLETED_ALIGNMENT_SETTINGS {
	"PADDING_RIGHT_ONE_DAY" = 0,
	"PADDING_RIGHT" = 25,
	"ALIGNMENT_HEIGHT" = 110, // From the spec
	"ALIGNMENT_HEIGHT_HALF" = 55 // From the spec / 2
}

export class TicketCompletedCheck extends TicketComponentHelper implements ComponentInterface {
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		
		// Dimensions came from Davina.
		this.width = 107;
		this.height = 98;
		
		this.alpha = 0.50;
		
		const data = ticketContainer.getData();
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data: Ticket = ticketContainer.getData();
		this.renderable = data.view.isActualized;
		
		this._positionCheckmark(data);
	};
	/**
	 * Spec says:
	 * Task:
	 * - Align checkmark with assumption of 110px height
	 * - Checkmark is 25px from the right-edge if duration days > 1
	 * - Checkmark is 0px from right-edge if duration days = 1
	 */
	private _positionCheckmark(data: Ticket): void {
		const type = TicketUtils.getTicketType(data);
		switch (type) {
			case TicketTypes.MILESTONE:
				this.x = this._getSingleDay(data.view.width);
				this.y = data.view.height / 2 - (this.height / 2);
				break;
			case TicketTypes.CONSTRAINT:
				this.x = this._getSingleDay(data.view.width);
				this.y = data.view.height / 2 - (this.height / 2);
				break;
			default: // task
				// 25 is the padding from right edge. Only applied if duration days > 1
				const padding: number = (data.view.liveDurationDays > 1) ? COMPLETED_ALIGNMENT_SETTINGS.PADDING_RIGHT : COMPLETED_ALIGNMENT_SETTINGS.PADDING_RIGHT_ONE_DAY;
				// If duration days > 1, align to the right with a padding
				// If duration days === 1, align to the center
				this.x = (data.view.liveDurationDays > 1) ? this._getXMultiDay(data.view.width, padding) : this._getSingleDay(data.view.width, padding);
				// Spec calls for 110px height for the alignment calculation.
				this.y = data.view.height - COMPLETED_ALIGNMENT_SETTINGS.ALIGNMENT_HEIGHT_HALF - (this.height / 2);
				break;
		}
	}
	
	private _getSingleDay(width: number, padding: number = 0): number {
		return (width / 2) - (this.width / 2) - padding;
	}
	/**
	 * Returns the X coordinate for a multi-day ticket
	 * @param {number} width The width of the ticket model
	 * @param {number} padding The padding of the right edge
	 */
	private _getXMultiDay(width: number, padding: number = 0): number {
		return width - this.width - padding;
	}
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED_ISACTUALIZED, this.onChanged);
		super.destroy(options);
	}
}