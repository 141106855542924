'use strict';



import * as angular from "angular";
import * as moment from "moment";
import * as Pikaday from "pikaday";

angular.module('ticketspaceApp')
.factory('floatingCalendar', function() {

	// html is being added now, probably just pass it back on conclusion and the sender can parse out what they need
	function popCalendar(defaultDate, displayHtml, config){
		if(!config){config = {};}
		var cb = arguments[arguments.length -1];
		
		var picker = new Pikaday({
			format: 'YYYY-MM-DD',
			defaultDate: moment(defaultDate, "YYYY-MM-DD").toDate(),
			setDefaultDate: true,
			onSelect: function(date) {
				cb(moment(date).format("YYYY-MM-DD"), parsedDisplayHtml);
				picker.destroy();
				wrapper.remove();
			}
		});
		var wrapper = $('<div>');
		
		var calWrap = $('<div class="pika-wrap">');
		var parsedDisplayHtml = $(displayHtml);
		calWrap.append(parsedDisplayHtml);
		calWrap.append(picker.el);
		var closeButton = $('<button class="btn--red">Clear current date</button>');
		
		if(!config.noDelete){
			calWrap.append(closeButton);
		}
		closeButton.on('click', function(e){
			cb(false);
			wrapper.off();
			picker.destroy();
			wrapper.remove();
		});
		
		wrapper.append(calWrap);
		
		
		wrapper.addClass('popup-wrapper popup-wrapper-shade');
		wrapper.on("click", function(e:any){
			if(e.target != wrapper[0]){return;}
			cb(defaultDate, parsedDisplayHtml);
			wrapper.off();
			picker.destroy();
			wrapper.remove();
		});
		$('body').append(wrapper);

		// MOC-3976 - Return a function that can be used to close the popup
		const off = function() {
			wrapper.off();
			picker.destroy();
			wrapper.remove();
		};

		return off;
	}
	
	return popCalendar;
});
