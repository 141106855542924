import { Observable, Subject, interval, empty } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Rectangle } from "pixi.js";

import Point from "js/lib/math/Point";

import {PlanState} from "ng2/common/services/plan-state.service";

var defaultEdgeDistance = {
	top: 100,
	right: 100,
	left: 100, 
	bottom: 100
}
interface EdgeDistance{
	top: number,
	right: number,
	left: number, 
	bottom: number
}

interface EdgeTickEvent{
	accumulation: any; //point
	shiftedPoint: any; //point
	lastPoint: any;
	deltaX: number;
	deltaY: number;
}


//TODO - test using tickSubject to monitor for edge ticks on other things
//TODO - interestingly, if we use screen coordinates, we don't need to do that
//it seems to make sense creating a new instance anytime it's needed, neatly avoids problems in handling multiple things at once
export class DragAtEdge{
	public edgeDistance: EdgeDistance;
	public screenSize: Rectangle;
	public deadZones?: Array<Rectangle>;
	
	private shiftBase = 20;//50;
	private shiftScale = new Point(0,0);
	private point;
	
	private tickSubject = new Subject();
	private ticking = false;
	private interval$ = interval(25).pipe(filter(()=>this.ticking));
	
	public change$:Observable<EdgeTickEvent>;

	private deltaAccumulation = new Point(0,0);
	
	constructor(
			public planState: PlanState,
			//public screenSizeObserver: Observable<Rectangle>, //the screensize could change, so it should be observable of some sort
			public screenSizeObserver: Rectangle,
			edgeDistance?)
	{
		if(!edgeDistance){ this.edgeDistance = defaultEdgeDistance; }
		
		this.screenSize = screenSizeObserver;
		
		this.change$ = this.tickSubject.pipe(map(()=>{
			var deltaX = this.shiftBase * this.shiftScale.x;
			var deltaY = -this.shiftBase * this.shiftScale.y;
			
			this.deltaAccumulation.x += deltaX;
			this.deltaAccumulation.y += deltaY;
			
			return {
				accumulation: this.deltaAccumulation,
				lastPoint: this.point,
				shiftedPoint: new Point(this.point.x+this.deltaAccumulation.x, this.point.y+this.deltaAccumulation.y),
				deltaX: deltaX,
				deltaY: deltaY
			}
		}));
		
		
		this.interval$.subscribe(this.tickSubject);
	}
	
	
	//call this from drag functions
	public update(point){
		var shouldShiftPlan = false;
		this.shiftScale.x = 0;
		this.shiftScale.y = 0;
		
		// if(this.deadZones && this.deadZones.length){ console.log('deadZones', point, this.deadZones[0], this.deadZones[0].contains(point.x, point.y)); }
		if(this.deadZones && this.deadZones.some(zone => zone.contains(point.x, point.y))){
			// console.log('deadzones', this.deadZones);
			this.ticking = false;
			return;
		}
		
		//these scale calcs are completely garbage, clean up at some point, just migrate the old ones for now...
		if (point.x > this.screenSize.width - this.edgeDistance.right){
			// this.shiftScale.x = (point.x - (this.screenSize.width - this.edgeDistance.right)) / (this.edgeDistance.right / 4);
			this.shiftScale.x = this.scaleCalc(point.x, this.screenSize.width - this.edgeDistance.right, this.edgeDistance.right);
			shouldShiftPlan = true;
		}
		else if (point.x < this.edgeDistance.left){
			// this.shiftScale.x = -(this.edgeDistance.left - point.x) / (this.edgeDistance.left / 4);
			this.shiftScale.x = -this.scaleCalc(point.x, this.edgeDistance.right, this.edgeDistance.left);
			shouldShiftPlan = true;
		}
		if (point.y < this.edgeDistance.top){
			// this.shiftScale.y = (this.edgeDistance.top - 14 - (point.y)) / (this.edgeDistance.top / 4);
			this.shiftScale.y = this.scaleCalc(point.y, this.edgeDistance.top, this.edgeDistance.top);
			shouldShiftPlan = true;
		}
		else if (point.y > this.screenSize.height - this.edgeDistance.bottom){
			// this.shiftScale.y = -(point.y + 14 - (this.screenSize.height - this.edgeDistance.bottom)) / (this.edgeDistance.bottom / 4);
			this.shiftScale.y = -this.scaleCalc(point.y, this.screenSize.height - this.edgeDistance.top, this.edgeDistance.bottom);
			shouldShiftPlan = true;
		}
		
		//apply the assorted drag operations calculated above
		if (shouldShiftPlan){
			this.point = point;
			// this.deltaAccumulation = new Point(0,0);
			this.tickSubject.next();
			this.ticking = true;
		}
		else{ //or clear the interval
			this.ticking = false;
		}
	}
	
	scaleCalc(target:number, borderSize:number, edgeDistance){
		var bleh = Math.max(0, Math.min(edgeDistance, Math.abs(borderSize - target)))  / edgeDistance;
		return bleh;
	}
	
	//we done
	public cancel(){
		this.tickSubject.complete();
		console.log('check for leaks...');
	}
}
