import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators"

import { Point, Rectangle } from "pixi.js";
import { default as PaperPoint } from 'js/lib/math/Point';

import * as utils from "utils";
import * as strings from "ng2/common/strings";

import { Ticket } from "ng2/common/models";
import { DragAtEdge } from "ng2/common/models/DragAtEdge"

import {PlanState} from "ng2/common/services/plan-state.service";
import { ActiveColumnService, DatePositionValidator } from "ng2/common/ajs-upgrade-providers";

import { SwimlaneOccupiedSpace } from "ng2/fancy-firebase/factories";
import { Swimlane, SwimlaneRenderTypes } from "ng2/common/models/Swimlane";
import { DefaultActionTypes } from "ng2/fancy-firebase/generics";
import {SWIMLANE_DRAG_TOO_FAR} from "../strings";

interface StartData{
	screenX:number,
	screenY:number,
	x:number,
	y:number,
	z:number
}

interface angularLand{
	activeColumnService: ActiveColumnService
	datePositionValidator: DatePositionValidator
}


export enum SwimlaneDragType {
	body = "body",
	edge = "edge"
}

const minSize = 200;


export class SwimlaneDragService{
	public dragInProgress = false;
	public dragCanceled = false;
	
	public includeTickets = true;
	private includedTickets: Set<Ticket>;
	
	private startRect?: Rectangle;
	private startCenter?: number;
	
	private dragAtEdge:DragAtEdge;
	private startData:StartData;
	
	private resetSubject = new Subject();
	
	private selection: Map<string, Swimlane>;
	private oneSelection: Swimlane;
	private oneMinMax: {top: number, bottom: number};
	/** used by getRange */
	private selectionFilter: Array<string>;
	private occupiedSpace: Array<SwimlaneOccupiedSpace>;
	private type: SwimlaneDragType;
	
	constructor(private planState: PlanState, private angularLand: angularLand){
		
	}
	
	/** called by both body and edge dragging */
	private dragStartGeneric(center: Point, selectedSwimlanes:Map<string, Swimlane>){
		this.dragInProgress = true;
		this.dragCanceled = false;
		
		this.selection = selectedSwimlanes;
		if(this.selection.size === 1){ 
			this.selection.forEach(s => this.oneSelection = s );
		}
		this.selectionFilter = Array.from(selectedSwimlanes.keys())
		
		this.startData = this.calcStartData(center);
		this.setUpAbort();
		this.recalcStart();
		this.setUpDragAtEdge();
	}
	public dragStart(center: Point, selectedSwimlanes:Map<string, Swimlane>){
		this.type = SwimlaneDragType.body;
		this.dragStartGeneric(center, selectedSwimlanes);
		this.dragStartTickets();
	}
	public dragEdgeStart(center: Point, selectedSwimlanes:Map<string, Swimlane>){
		this.type = SwimlaneDragType.edge;
		this.dragStartGeneric(center, selectedSwimlanes);
	}
	
	
	public setUpAbort(){
		//TODO - this also needs to handle removed events for the swimlanes being dragged, but nothing else
		this.planState.swimlanes.list.rawEvent$.pipe(
			takeUntil(this.resetSubject),
			filter(action => action.type !== DefaultActionTypes.cameraChanged),
			filter(action => !this.selection.has(action.key) || this.planState.swimlanes.list.isRemoveAction(action.type))
		).subscribe(action => {
			if(this.selection.has(action.key) && this.planState.swimlanes.list.isRemoveAction(action.type)){
				Logging.warning(strings.DRAG_SWIMLANE_REMOVED);
				this.dragCancel();
			}
			else{
				Logging.warning(strings.DRAG_SWIMLANES_MODIFIED);
				this.dragCancel();
			}
		});
	}
	
	private setUpDragAtEdge(){
		var camera = this.planState.canvasConnector.camera;
		
		//placeholder
		let bleh = new Rectangle(0, 0, window.innerWidth, window.innerHeight);
		
		//move this to a function
		this.dragAtEdge = new DragAtEdge(this.planState, bleh);
		
		this.dragAtEdge.change$.pipe(takeUntil(this.resetSubject)).subscribe((map)=>{
			let z = camera.getScale().x;

			this.planState.scopeSoup.hud.onTranslate(new PaperPoint(-map.deltaX, -map.deltaY));
			// console.log('here');
			this.selection.forEach((t, idx)=>{
				this.planState.swimlanes.localUpdate(t.$id, {"top": t.view.top + map.deltaY/z, "bottom": t.view.bottom + map.deltaY/z})
				// this.planState.actions.updateTicketLiveData(t.$id, {left: t.view.left + map.deltaX/z, "top": t.view.top + map.deltaY/z});
			})
			if(this.includedTickets && this.includedTickets.size){
				this.includedTickets.forEach(t => {
					this.planState.actions.updateTicketLiveData(t.$id, {left: t.view.left + map.deltaX/z, "top": t.view.top + map.deltaY/z});
				})
			}
		})
	}
	
	private recalcStart(){
		collectStartPos(this.selection);
		this.startRect = calculateStartRect(this.selection);
		this.startCenter = this.startRect.top + this.startRect.height / 2;
		
		let tickets = utils.getTicketsInRect(this.planState.tickets._internalList, this.startRect) as Array<Ticket>;
		
		let minSize = (this.selection.size === 1 && this.type === SwimlaneDragType.body && (!this.includeTickets || tickets.length === 0)) ? 5 : this.startRect.height;
		
		this.occupiedSpace = this.planState.swimlanes.list.getRange2(this.selectionFilter, this.startRect.height, minSize);
		
		if(this.selection.size === 1){
			this.oneMinMax = this.getOneRange(this.oneSelection.view.dragStartData);
		}
	}
	
	private getOneRange(record){
		var innerRange = {top: -Infinity, bottom: Infinity};
		this.occupiedSpace.forEach((thing)=>{
			if(thing.bottom <= record.top || (record.top < thing.bottom && record.bottom > thing.bottom)){ innerRange.top = thing.bottom; } //new version, seems to work
			if((thing.top >= record.bottom || (record.bottom > thing.top && record.top < thing.top)) && thing.top <= innerRange.bottom){ innerRange.bottom = thing.top; } //new version, not working
		});
		return innerRange;
	}
	
	private getDelta(center: Point){
		let camera = this.planState.canvasConnector.camera;
		let worldPoint = new Point(camera.screenToRealX(center.x), camera.screenToRealY(center.y));
		return new Point(worldPoint.x - this.startData.x, worldPoint.y - this.startData.y);
	}
	
	public dragTopEdge(center: Point){
		if(this.dragCanceled){ return true; }
		let delta = this.getDelta(center);
		
		let updateData = {
			top: this.oneSelection.view.dragStartData.top + delta.y,
			bottom: this.oneSelection.view.bottom
		}
		if(updateData.top > updateData.bottom - minSize){ updateData.bottom = updateData.top + minSize; }
		this.dragEdge(updateData);
	}
	public dragBottomEdge(center: Point){
		if(this.dragCanceled){ return true; }
		let delta = this.getDelta(center);
		
		let updateData = {
			top: this.oneSelection.view.top,
			bottom: this.oneSelection.view.dragStartData.bottom + delta.y
		}
		if(updateData.top > this.oneSelection.view.bottom - minSize){ updateData.bottom = updateData.top + minSize; }
		if(updateData.bottom < updateData.top + minSize){ updateData.top = updateData.bottom - minSize; }
		this.dragEdge(updateData);
	}
	
	public dragEdge(updateData:{top:number, bottom:number}){
		if(updateData.top < this.oneMinMax.top){ updateData.top = this.oneMinMax.top; }
		if(updateData.bottom > this.oneMinMax.bottom){ updateData.bottom = this.oneMinMax.bottom; }
		
		if(updateData.top > this.oneMinMax.bottom - minSize){ updateData.top = this.oneMinMax.bottom - minSize; }
		if(updateData.bottom < this.oneMinMax.top + minSize){ updateData.bottom = this.oneMinMax.top + minSize; }
		// console.log('updateData', updateData);
		this.planState.swimlanes.localUpdate(this.oneSelection.$id, updateData);
	}
	
	public drag(gesturePoint: Point){
		if(this.dragCanceled){ return true; }
		this.dragAtEdge.update(gesturePoint);
		
		let delta = this.getDelta(gesturePoint);
		let newCenter = this.startCenter + delta.y;
		
		//maybe change to a range delta?
		let rectUpdateDelta = {
			top: delta.y,
			bottom: delta.y,
		}
		// console.log('this.occupiedSpace', this.occupiedSpace);
		//overlapping the occupiedSpace, move it
		if(this.occupiedSpace.length && newCenter >= this.occupiedSpace[0].overlapTop && newCenter < this.occupiedSpace[this.occupiedSpace.length-1].overlapBottom){
			//position the center so it's bottom is touching the top most region at the start
			let lastAcceptablePosition = this.occupiedSpace[0].top - this.startRect.height/2;
			let aboveIdx = -1;
			this.occupiedSpace.forEach((space, idx) => {
				if(newCenter > space.overlapTop && newCenter <= space.center){ lastAcceptablePosition = space.top - this.startRect.height/2; aboveIdx = idx-1; } //set to above
				else if(newCenter <= space.center){ return; } //use last position
				else if(newCenter <= space.overlapBottom){ lastAcceptablePosition = space.bottom + this.startRect.height/2; aboveIdx = idx; } //set last position to below and move on
				else{ lastAcceptablePosition = newCenter; aboveIdx = idx; }
			});
			let centerDelta = lastAcceptablePosition - this.startCenter;
			
			let newTop = this.startRect.top + centerDelta;
			let newBottom = this.startRect.bottom + centerDelta;
			// console.log('aboveIdx', aboveIdx);
			if(aboveIdx !== -1 && newTop < this.occupiedSpace[aboveIdx].bottom){ 
				newTop = this.occupiedSpace[aboveIdx].bottom;
			}
			if(aboveIdx !== -1 && aboveIdx + 1 < this.occupiedSpace.length && newBottom > this.occupiedSpace[aboveIdx+1].top ){
				newBottom = this.occupiedSpace[aboveIdx+1].top;
			}
			rectUpdateDelta.top = newTop - this.startRect.top;
			rectUpdateDelta.bottom = newBottom - this.startRect.bottom;
		}
		
		this.selection.forEach(swim =>{
			// console.log('Write time: new top', swim.view.dragStartData.top + rectUpdateDelta.top, 'newBottom', swim.view.dragStartData.bottom + rectUpdateDelta.bottom);
			this.planState.swimlanes.localUpdate(swim.$id, {
				top: swim.view.dragStartData.top + rectUpdateDelta.top,
				bottom: swim.view.dragStartData.bottom + rectUpdateDelta.bottom,
			});
		});
		
		//this should really use a proper center delta, for _now_ top will be fine...
		this.dragTickets(rectUpdateDelta.top);
	}
	
	
	private dragEndGeneric(center: Point){
		this.planState.swimlanes.persistData(this.selection, SwimlaneRenderTypes.edit); //persist from edit mode
		this.dragCleanUp();
	}
	public dragEnd(center: Point){
		let failed: boolean = false;
		// MOC-2612 - Verify all swimlanes in selection have valid positions
		this.selection.forEach((swimlane: Swimlane) => {
			// Only want to fail once.
			if (failed){return true;}
			if (!this.angularLand.datePositionValidator.positionWarning(Infinity, swimlane.edit.top)) {
				Logging.warning(SWIMLANE_DRAG_TOO_FAR);
				failed = true;
				this.dragCancel();
			}
		});
		if(this.dragCanceled){ return true; }
		//this.planState.swimlanes.changeView
		this.dragEndTickets();
		this.dragEndGeneric(center);
		
	}
	public dragEdgeEnd(center){
		if(this.dragCanceled){ return true; }
		
		this.dragEndGeneric(center);
	}
	
	public dragCancel(){
		this.dragCanceled = true;

		//reset the swimlanes
		if(this.selection){
			this.selection.forEach(s => {
				if(s.view.dragStartData){
					this.planState.swimlanes.localUpdate(s.$id, s.view.dragStartData);
				}
			});
		}
		
		//reset the ticket if implicated
		if(this.includedTickets && this.includedTickets.size){
			this.planState.actions.changeTicketViewToDefault(this.includedTickets);
		}

		this.dragCleanUp();
	}
	
	//called to cleanup after a drag
	public dragCleanUp(){
		this.oneSelection = null;
		this.resetSubject.next(true);
		this.dragInProgress = false;
		
		this.includedTickets = null;
		this.selection = null;
		this.selectionFilter = null;
		this.occupiedSpace = null;
		this.dragAtEdge.cancel();
		this.dragAtEdge = null;
	}
	
	private dragStartTickets(){
		if(!this.includeTickets){ return; }
		
		let arr = [];
		this.selection.forEach(s => {
			arr = arr.concat(utils.getTicketsInRect(this.planState.tickets._internalList, new Rectangle(
				Infinity, s.view.top, Infinity, s.view.bottom - s.view.top
			)))
		})
		
		//very lazy remove duplicates, do better if slow
		this.includedTickets = new Set(arr);
		
		this.planState.actions.changeTicketViewToDrag(this.includedTickets);
		
		this.includedTickets.forEach( t => t.view.dragStartData =  {top: t.view.top } );
		
		this.planState.tickets.rawEvent$.pipe(
			takeUntil(this.resetSubject),
			filter(action => this.planState.tickets.isRemoveAction(action.type) && this.includedTickets.has(action.payload) )
		).subscribe(action => {
			this.includedTickets.delete(action.payload);
		})
	}
	private dragTickets(deltaY: number){
		if(!this.includeTickets){ return; }
		
		this.includedTickets.forEach( t => {
				// console.log('update ticket', t.$id, {top: t.view.dragStartData.top + deltaY} );
				this.planState.actions.updateTicketLiveData(t.$id, {top: t.view.dragStartData.top + deltaY});
		});
	}
	private dragEndTickets(){
		if(!this.includeTickets){ return; }
		
		this.planState.actions.persistTicketData(this.includedTickets);
		this.planState.actions.changeTicketViewToDefault(this.includedTickets);
		this.includedTickets = null;
		
	}
	
	public destroy(){
		
	}
	
	private calcStartData(center: Point){
		var camera = this.planState.canvasConnector.camera;
		var realX = camera.screenToRealX(center.x);

		return {
			screenX: center.x,
			screenY: center.y,
			x: realX,
			y: camera.screenToRealY(center.y),
			z: camera.getScale().x,
		}
	}
}

function collectStartPos(swimlanes:Map<string, Swimlane>){
	swimlanes.forEach((t) => {
		t.view.dragStartData = {
			top: t.view.top,
			bottom: t.view.bottom,
			center: t.view.top + (t.view.bottom - t.view.top)/2
		};
	});
}

function calculateStartRect(swimlanes:Map<string, Swimlane>){
	var tops = [];
	var bottoms = [];
	swimlanes.forEach((t)=>{
		tops.push(t.view.dragStartData.top);
		bottoms.push(t.view.dragStartData.bottom);
	});
	var maxY = Math.max.apply(Math, bottoms);
	var minY = Math.min.apply(Math, tops);

	return new Rectangle(0, minY, Infinity, maxY - minY);
}
