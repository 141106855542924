/**
 * @namespace LineOptions
 * @desc Options object which is used to configure the render of the CanvasWrapper.Line class.
 * @param [options] {Object} An optional object containing any of the following keys, which will override the default values.
 * @param [options.LINE_COLOR='#000000'] {String} The color of the line
 * @param [options.LINE_SIZE=0] {Number} The size (width) of the line.
 * @param [options.LINE_ALPHA=0] {Number} The alpha of the outer line drawn for the timeline.
 * @param [options.NATIVE_LINES=false] {Boolean} If true, draws lines using LINES instead of TRIANGLE_STRIP
 * @memberOf CanvasWrapper
 * @constructor
 * @returns {Object}
 */
angular.module('CanvasWrapper').factory('LineOptions', LineOptions);
function LineOptions(){
	function options(options){
		return{
			LINE_COLOR: options.LINE_COLOR || '#ffffff',
			LINE_SIZE: options.LINE_SIZE || 1,
			LINE_ALPHA: options.LINE_ALPHA || 1,
			NATIVE_LINES: options.NATIVE_LINES || false
		}
	}
	return options
}