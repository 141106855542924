import {FancyAction, FancyFirebaseList} from "ng2/fancy-firebase/base";

import { ListLike } from "utils";
import { FancyActionableFirebase, DefaultActionTypes, hasId } from "ng2/fancy-firebase/generics";

import { LaborCountList } from "ng2/fancy-firebase/factories";
import { LaborCount } from "../common/models/LaborCount";

export class LaborCounts extends FancyActionableFirebase<LaborCount, FancyAction, LaborCountList> {
	constructor(public list: LaborCountList){
		super(list);
	}
	
	destroy(){
	
	}
}
