import { Subject, BehaviorSubject } from "rxjs";
import { takeUntil, share, mapTo, tap } from "rxjs/operators";
import { Point } from "pixi.js";
import { default as PaperPoint } from 'js/lib/math/Point';

// import {FancyAction} from "ng2/fancy-firebase/base/FancyAction"

import { Camera, ViewportChangedEvent } from "ng2/canvas-renderer/core";
export { Camera, ViewportChangedEvent };

import { RendererApplication } from "ng2/canvas-renderer/core/RendererApplication";


// Notes
// 
// - hud code is equiped to take a snapshot of the previous state, apply translations (deltas) to that and set it's own point
// - need to add similar functionality to camera for parity
// -- which requires figuring out how useful the stacking behavior actually is

// Implementation plan
// - can accept pixi points as arguments, then convert to paper points for the hud (if necessary)
// - just map in old hud methods initially
// - then switch the camera to be the master, and have the hud listen to changes from the observable in here

export class CanvasConnector{
	private shutdownSubject = new Subject();
	private _internalCamera = new BehaviorSubject<Camera>(null);
	
	public cameraChange$ = this._internalCamera.pipe(share());
	
	public ready = false;
	
	public camera:Camera;
	
	// public onStartTranslate(){
	// 
	// }
	// public onTranslate(){
	// 
	// }
	// public onEndTranslate(){
	// 
	// }
	
	// this._binder.add('hammer-panstart', (event)=>{
	// 	hud.onStartTranslate();
	// });
	// this._binder.add('hammer-pan', (event)=>{
	// 	hud.onTranslate(new Point(event.deltaX, event.deltaY));
	// });
	// this._binder.add('hammer-panend', (event)=>{
	// 	hud.onEndTranslate();
	// });
	
	
	constructor(){
		// rendererApplication.camera
		
		// camera.setPosition(this.x, this.y);
		// camera.setScale(this.z, this.z);
	}
	
	connectApplication(rendererApplication: RendererApplication){
		this.ready = true;
		this.camera = rendererApplication.camera;
		
		this.camera.changed$.pipe(
			takeUntil(this.shutdownSubject),
			mapTo(this.camera),
		).subscribe(this._internalCamera);
	}
	
	destroy(){
		this.shutdownSubject.next(true);
		this.camera = null;
	}
}
