'use strict';

import * as angular from "angular";
import { analyticsService } from "ng2/common/utils/AnalyticsService";
/**
 * @namespace projectViewApi
 * @desc Exposes public members and public methods from the current running Overview instance. Set the current instance by running build(instance)
 * @memberOf ProjectView
 * @constructor
 */
angular.module('ProjectView').factory('ProjectViewApi', ProjectViewAPI);

ProjectViewAPI.$inject = [];
function ProjectViewAPI(){
	var api:any = {};
	var _loaded = false;
	function build(instance, directiveCtrl){
		api.destroy = function(){instance.destroy();};
		/**
		 * @function getSelectedHeatmap
		 * @desc Returns an object containing the currently selected Planbar
		 * @returns {ProjectView.ProjectViewCore.selectedHeatmap}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getSelectedPlanBar = function(){return instance.selectedHeatmap;};
		/**
		 * @function getView
		 * @desc Returns the HTML Canvas Element the Overview is currently bound to.
		 * @returns {ProjectView.ProjectViewCore.view}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getView = function(){return instance.view;};
		/**
		 * @function getRenderer
		 * @desc Returns the Renderer application
		 * @returns {CanvasWrapper.Renderer}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getRenderer = function(){return instance.renderer;};
		/**
		 * @function getStage
		 * @desc Returns the root display node in the Overview scene graph
		 * @returns {ProjectView.ProjectViewCore.stage}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getStage = function(){return instance.stage;};
		/**
		 * @function getTicker
		 * @desc Returns the currently running ticker
		 * @returns {ProjectView.ProjectViewCore.ticker}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getTicker = function(){return instance.ticker;};
		/**
		 * @function getPlanBars
		 * @desc Returns an array of currently active plan bars being rendered in the Overview
		 * @returns {ProjectView.ProjectViewCore.heatmaps}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getPlanBars = function(){return instance.heatmaps;};
		/**
		 * @function getLayerManager
		 * @desc Returns the instance of the Overview layer manager
		 * @returns {CanvasWrapper.LayerManager}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getLayerManager = function(){return instance.layerManager;};
		/**
		 * @function getViewport
		 * @desc Returns the instance of the Overview camera
		 * @returns {CanvasWrapper.Viewport}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getViewport = function(){return instance.viewport;};
		/**
		 * @function start
		 * @desc Starts the Overview renderer ticker
		 * @returns {*}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.start = function(){
			return instance.start();
		};
		/**
		 * @function stop
		 * @desc Stops the Overview renderer ticker
		 * @returns {*}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.stop = function(){return instance.stop();};
		/**
		 * @function resize
		 * @desc Resizes the overview renderer window and then triggers an update and draw
		 * @param w {Number} The new width of the Overview window
		 * @param h {Number} The new height of the Overview window
		 * @memberOf ProjectView.projectViewApi
		 * @returns {*}
		 */
		api.resize = function(w, h){return instance.resize(w, h);};
		/**
		 * @function destroy
		 * @desc Destroys the current instance and purges all textures from VRAM
		 * @returns {*}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.destroy = function(){return instance.destroy();};
		/**
		 * @function getTimeline
		 * @desc Returns an instance of the Project View timeline
		 * @returns {ProjectView.ProjectViewTimeline}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getTimeline = function(){return instance.timeline;};
		/**
		 * @function seeAll
		 * @desc Resizes the Overview camera so all existing plan bars scale to fit into the available viewport space.
		 * @returns {*}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.seeAll = function(){return instance.seeAll();};
		/**
		 * @function centerGuidelines
		 * @desc Focuses the Overview camera so it is centered with the mid-point of the guidelines.
		 * @returns {*}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.centerGuidelines = function(){return instance.centerGuidelines();};
		/**
		 * @function focusOnPlan
		 * @desc Focuses the Overview camera so it is centered with the mid-point of the guidelines.
		 * @param id {String} The id of the plan to focus on in the Overview window
		 * @param spacing {Number} The spacing of the focus?
		 * @param minScale {Number} The minimum scale value of the focus. Will not zoom out past this value.
		 * @param maxScale {Number} The maximum scale value of the focus. Will not zoom in past this value.
		 * @returns {*}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.focusOnPlan = function(id, spacing, minScale, maxScale){return instance.focusOnPlan(id, spacing, minScale, maxScale);};
		/**
		 * @function setPosition
		 * @desc Sets the Overview camera to be a specific position and scale.
		 * @param x {Number} The X-coordinate to set the camera
		 * @param y {Number} The Y-coordinate to set the camera
		 * @param scale {Number} The scale factor to set the camera
		 * @memberOf ProjectView.projectViewApi
		 * @returns {*}
		 */
		api.setPosition = function(x, y, scale){return instance.setPosition(x, y, scale);};
		/**
		 * @function loaded
		 * @desc Returns the loading state of the Overview window.
		 * @returns {boolean}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.loaded = function(){return _loaded};
		/**
		 * @function getSize
		 * @desc Returns the current class applied to the Overview window
		 * @memberOf ProjectView.projectViewApi
		 * @returns {String} The CSS class name applied to the overview window
		 */
		api.getSize = function(){return directiveCtrl.sizeSelected;};
		/**
		 * @function isExpanded
		 * @desc Returns if the Overview window is currently expanded or not
		 * @memberOf ProjectView.projectViewApi
		 * @returns {Boolean}
		 */
		api.isExpanded = function(){return directiveCtrl.expanded;};
		/**
		 * @function goToPlan
		 * @desc Maintains current viewport, loads a new plan and then attempts to normalize coordinates from the last plan to the current plan.
		 * @memberOf ProjectView.projectViewApi
		 */
		api.goToPlan = function(){directiveCtrl.goToPlan();};
		/**
		 * @function setSelectedPlanBar
		 * @desc Sets the selectedPlanBar to arg[0]
		 * @param planBar
		 * @memberOf ProjectView.projectViewApi
		 */
		api.setSelectedPlanBar = function(planBar){directiveCtrl.setSelectedPlanBar(planBar);};
		/**
		 * @function getInstance
		 * @desc Returns the currently running ProjectViewCore
		 * @returns {ProjectView.ProjectViewCore}
		 * @memberOf ProjectView.projectViewApi
		 */
		api.getInstance = function(){return instance;};
		_loaded = true;
	}

	var returnValue = angular.extend(api, {
		build: build
	});

	return returnValue
}
