//in theory if we get "all" strings in here, we could replace this file with another language version and the entire app would be translated
// since it operates at "compile time" for the most part, we'd pull that off with a custom build and a seperate subdomain for that language

export const TEST_STRING = `test string`;
export const TEST_ARGS = (a1)=>`start of ${a1}`

//Drag to floating things

/** Warning displayed when trying to drag a completed ticket into the floating space */
export const DRAG_COMPLETED_TO_FLOATING = `Cannot move completed tickets to the on deck area.`;
/** the title of the popup when trying to drag constrains into the floating space */
export const DRAG_CONSTRAINT_TO_FLOATING_PROMPT_TITLE = `One (or more) of these tickets is a constraint.`;
/** the description of the popup when trying to drag constrains into the floating space */
export const DRAG_CONSTRAINT_TO_FLOATING_PROMPT_DESCRIPTION = `Constraints will be removed from the rest of the project until moved back into a plan.  Continue?`;
/** the title of the popup when trying to drag a ticket with dependencies into the floating space */
export const DRAG_DEPENDENCY_TO_FLOATING_PROMPT_TITLE = `One (or more) of these tickets has a dependency.`;
/** the description of the popup when trying to drag a ticket with dependencies into the floating space */
export const DRAG_DEPENDENCY_TO_FLOATING_PROMPT_DESCRIPTION = `Remove the dependency and complete the move to the on deck area?`;
/** displayed when trying to drag a promised ticket into the floating space */
export const DRAG_PROMISED_INTO_FLOATING = `Cannot move completed tickets to the on deck area.`;
/** displayed when trying to drag a pinned ticket across the active line. Logged once per drag if applicable */
export const PINNED_TICKETS_DRAGGED_TO_ACTIVE = `Pinned tickets cannot be dragged across the active line.`
/** displayed when trying to drag tickets and the active line is modified during the drag */
export const ACTIVE_LINE_MODIFIED_DURING_DRAG = `The active line was modified, your ticket's positions have been reset, please try again.`

// Validator
export const VALIDATOR_DRAG_TOO_FAR_AWAY = 'This is too far away from your other tickets. Click see all tickets in plan actions to reset your screen';

//Dependency warnings

/** Warning triggered when you tap a completed ticket when setting dependencies */
export const SET_DEPENDENCY_TO_ACTUAL = (ticketName)=>`Cannot make a completed ticket a predecessor.`;
/** Warning triggered when you have a completed ticket selected and try to set other tickets as predecessors */
export const SET_DEPENDENCY_FROM_ACTUAL = `Cannot change precedence of a completed ticket.`;
/** trigged when trying to set the opposite of an already existing dependency */
export const SET_DEPENDENCY_TO_OPPOSITE = (ticketName)=>`Making "${ticketName}" a predecessor would create a loop, since the current ticket is a predecessor of "${ticketName}".`;
/** Warning triggered when a non-admin tries to set a dependency on a ticket they don't own */
export const NON_ADMIN_CANNOT_SET_DEPENDENCY = `You cannot set predecessors on the selected ticket, you do not have access.`;

// Dependency mode prompts
/** displayed in the dependency prompt */
export const DEPENDENCY_PROMPT_DESCRIPTION = `You are now setting dependencies. Tap a ticket to toggle it on or off as a predecessor.  Tap and hold another ticket to change it's dependencies.`;
/** displayed in the dependency prompt */
export const DEPENDENCY_PROMPT_TOGGLE_TO_ALL = `Show all dependencies`;
/** displayed in the dependency prompt */
export const DEPENDENCY_PROMPT_TOGGLE_TO_SELECTED = `Show just the selected ticket's dependencies`;

// Ticket Overlap Loggings
/** Displayed on initial load when ticket count > 1,000 */
export const OVERLAP_FORCE_DISABLED = "Ticket Overlap disabled because your plan has over 1,000 tickets.";
/** Displayed when the user overrides the ticket overlap when it was forcefully disabled */
export const OVERLAP_FORCE_DISABLED_OVERRIDE = "Ticket Overlap may cause performance degradation on plans with more than 1,000 tickets.";

/** Displayed when a large plan is opened */
export const LARGE_PLAN_DETECTED_TEXT = "Large plan opened. Tickets completed over 1 month ago are hidden. Ticket overlap calculation is disabled. Use advanced settings to override.";

/** Displayed when the user overrides the completed ticket hiding when it was forcefully disabled */
export const COMPLETED_FORCE_DISABLED_OVERRIDE = "Completed tickets may cause performance degradation on plans with more than 1,000 tickets.";
/** Labels for avanced settings panel */
export const ADVANCED_SETTINGS_TICKET_OVERLAP_LABEL = "Enable Ticket Overlap";
export const ADVANCED_SETTINGS_COMPLETED_TICKETS_LABEL = "Show Completed Tickets";
export const ADVANCED_SETTINGS_DISABLE_WEBGL_LABEL = "Disable WebGL (Must reload)";


// Tapped on Ticket Error notices
/**
 * The notice that displays when you tap on a ticket error (Yellow exclamation icon)
 */
export const PAST_DUE_NOTICE = "This ticket is past due!";
/**
 * The notice that displays when you tap on a ticket error (Red dependency error icon)
 */
export const DEPENDENCY_ERROR_NOTICE = "This ticket is out of sequence!";
/**
 * The string for the "Enable Solid Lines" text in the Advanced Settings panel
 */
export const ENABLE_SOLID_LINES_LABEL = "Enable Solid Lines";

/**
 * The error message string that displays when connection to Intercom could not be established
 */
export const INTERCOM_CONNECTION_ERROR = "Something is blocking connection to live support. Please email us at support@touchplan.io.";

/**
 * CONSTRAINTS
 */
export const CONSTRAINT_ON_LOCKED_PLAN = "This Constraint is in a locked plan. Please unlock the plan to edit this constraint.";
/**
 * ACCESS JOIN
 */
export const ACCESS_CODE_QUICK_JOIN_NEED_CODE = "Must enter an Access Code.";
export const ACCESS_CODE_QUICK_JOIN_INVALID_CODE = "Invalid Access Code.";

/**
 * CREW SIZES
 */
export const CREW_SIZES_ZOOM_IN_MESSAGE = "Zoom in to see crew sizes.";
export const CREW_SIZES_CLOSED_MESSAGE = "Okay, crew sizes will remain closed. You can enable it anytime through the 'Plan Actions' menu";

//SWIMLANES
/** swimlane being dragged is removed */
export const DRAG_SWIMLANE_REMOVED = "Another user has removed one of the swimlanes you're moving, reverting your changes."
/** not dragged swimlanes modified mid drag */
export const DRAG_SWIMLANES_MODIFIED = "The swinlanes have been edited by another user, reverting your changes."
/** too small text */
export const SWIMLANE_TOO_SMALL = "Please zoom in to see the swimlane controls."
/** The warning that comes up when trying to close edit on a swimlane when exiting edit. */
export const SWIMLANE_NEEDS_LABEL = "A label is required."
/**
 * Notification which appears when you try editing a swimlane when you are not an admin
 */
export const SWIMLANE_NO_EDIT_RIGHTS = "Only Admins can edit Swimlanes. Please contact an admin on your project for any changes";
export const SWIMLANE_DRAG_TOO_FAR = "Cannot drag swimlane there as it is to far away from the rest of your plan.";
//Usage
// import {TEST_STRING, TEST_ARGS} from "ng2/common/strings";
// Logging.notice(TEST_STRING);
// Logging.notice(TEST_ARGS("blob"));

// import * as strings from "ng2/common/strings";
// <i>${require('ng2/common/strings').TEST_STRING}</i>
export const REPORT_FAILED_TO_OPEN_WINDOW = "Failed to open the Report Window. Do you have pop-ups enabled?";
// Displayed to a user when the ticket textures fail to load correctly.
export const FAILED_TO_LOAD_TICKET_TEXTURES = "The ticket images did not load. Please refresh the page.";

export const CONSTRAINT_LINKED_TO_PROCORE = "Please mark this RFI complete in procore to complete it in Touchplan";