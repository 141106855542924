'use strict';
import * as utils from "utils";

export default function Spread(listPropName){/*
	Create an optimal spread of elements with respect to elements that are visible,
	and the minimum distance between elements.

	new Nutella([listPropName])
	.spread(list, left, width, min, [listPropName]) -> list // calculate the spread

	listPropName will use the supplied property name when calculating with a list
	of objects.

	if a new listPropName is given to spread it will use that instead of what was
	given to the constructor */

	this.listPropName = listPropName || null;
	
	this.min = 10;  // will be overwritten
	this.list = []; // this will be set when calling main spread function as needed
					
}

Spread.prototype = {

	// since we want to work either directly with number arrays or object arrays,
	// we need some helpers to normalize value access

	// Get value at index
	valueAt: function(index){
		if(this.listPropName){
			return this.list[index][this.listPropName]
		} else {
			return this.list[index]
		}
	},

	// retrieve value for an element
	valueFor: function(item){
		if(this.listPropName){
			return item[this.listPropName]
		} else {
			return item
		}
	},

	// get all values from array
	values: function(list){
		return (list || this.list).map(function(item){
			return this.valueFor(item)
		}, this)
	},

	// grab the element closest to a particular value
	closestTo: function(n){
		var distanceMap = []
		this.list.forEach(function(item, index){
			distanceMap.push({
				index: index,
				//item: item,
				distance: Math.abs(n - this.valueFor(item))
			})
		}, this)
		var closest = distanceMap.reduce(function(closest, current){
			if(closest.distance < current.distance) return current;
			else return closest;
		}, (distanceMap[0] || null))
		return closest.index;
	},


	// Main entry point, sets the current list and bounds. Optionally, change the 
	// current listPropName that is being used for value lookup.
	spread: function(list, left, width, min, listPropName){

		// Minimum distance between points
		this.min = min || this.min;

		// Property to use for value lookup
		this.listPropName = listPropName || this.listPropName;

		// Set 'this.list' to the current list of supplied elements
		this.list = list.slice().filter(function(item){
			// filter by left/width
			var value = this.valueFor(item)
			return value > left && value < left + width;
		}, this)

		// quick exits
		if(this.list.length == 0) return []
		if(this.list.length == 1) return [list[0]]

		// sort low -> high
		this.list.sort(utils.bind(this, function(A, B){
			return this.valueFor(A) - this.valueFor(B)
		}));

		// Start the real work..
		var spread = []
		for(var front = 0, back = this.list.length-1; front < back; front++, back--){
				
			if(spread.length == 0 
				|| (Math.abs(this.valueAt(back) - this.valueFor(spread[spread.length-1])) > min
				&& Math.abs(this.valueAt(back) - this.valueFor(spread[0])) > min)){
				spread.push(this.list[back])
			}
				
			if(Math.abs(this.valueFor(spread[0]) - this.valueAt(front)) > min
				&& Math.abs(this.valueFor(spread[spread.length-1]) - this.valueAt(front)) > min){
				spread.unshift(this.list[front])
			}
			
		}
			
		if(Math.abs(this.valueAt(back) - this.valueFor(spread[spread.length-1])) > min
			&& Math.abs(this.valueAt(back) - this.valueFor(spread[0])) > min){
			spread.push(this.list[back])
		}

		return spread;
		
	}

}
