"use strict";

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";

declare const window: any;

angular.module('ticketspaceApp').directive('fancyLogging', ["developerMode", "fbReporting", function (developerMode, fbReporting) {
	
	function removeElement(ele){
		ele.removeClass('in');
		// ele.hide(500);
		setTimeout(function(){
			ele.remove();
		}, 600);
	}
	
	function genElement(message, severity, cb){
		if(severity === 3){ message = 'Debug: ' + message; }
		var item = $('<div class="fancy-log">'+message+'</div>');
		//item.hide();
		
		var hideDelay = 10000;
		switch(severity){
			case 0: item.addClass('error'); break;
			case 1: item.addClass('warning'); break;
			case 2: item.addClass('notice'); break;
			case 3: item.addClass('debug'); hideDelay = 20000; break;
			case 4: item.addClass('warning'); hideDelay = 60000; break;
		}
		
		setTimeout(function(){
			removeElement(item);
			if(cb){cb()};
		}, hideDelay);
		
		item.click(function(){
			removeElement(item);
			if(cb){cb()};
		});
		
		return item;
	}
	
	
    return {
        restrict: 'EA',
		scope: true,
        compile: function(element, attrs){
			//console.log('element', element);
			element.addClass('fancy-logging-wrapper');

			var errorMap = [];

			var lastAdded:any = {};
			
			var Logging = (function(){

				function log(message, severity){
					console.log('Logging: "' + message + '", ' + moment().format());
					if(lastAdded.message === message && lastAdded.severity === severity){
						var child = lastAdded.element.find('div');
						if(child.length === 0){
							child = $('<div>');
							lastAdded.element.append(child);
						}
						lastAdded.count++;
						child.text(lastAdded.count);
						return lastAdded.element;
					}
					var item = genElement(message, severity, function(){
						if(lastAdded.message === message && lastAdded.severity === severity){
							lastAdded = {};
						}
					});
					element.append(item);
					item.css('opacity');
					//item.show(500);
					item.addClass('in');
					
					if(severity === 1){
						fbReporting.reportWarning(message, utils.makeStack(new Error(message)));
					}
					
					lastAdded = {message: message, severity: severity, element: item, count: 1};
					
					return item;
				}
				
				function tempItem(message, severity, uniqueId){
					severity = severity || 2;
					var item = log(message, severity);
					if(uniqueId){ item.addClass(uniqueId); }
					
					item.addClass('fancy-logging-temp');
				}
				
				function close(uniqueId){
					console.log('close', uniqueId);
					if(uniqueId){
						var e = element.find('.'+uniqueId);
						e.hide(300, e.remove);
					}
					else{
						var e = element.find('.fancy-logging-temp');
						e.hide(300, e.remove);
					}
				}
				var errThrottle = utils.throttleError( function( message, level ){ log( message, level )}, -10000 );
				return {
					log: log,
					error: function (message) { errThrottle( message, 0)},
					warning: function(message){ log(message, 1); },
					longWarning: function(message){ log(message, 4); },
					notice: function(message){ log(message, 2); },
					debug: function(message){ if(developerMode){ errThrottle( message, 3)  } },
					tempItem: tempItem,
					close: close
				};
			})();
			window.Logging = Logging;

        }
    };
}]);
