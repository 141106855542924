angular.module('ProjectView').factory('TimelineOptions', TimeLineOptions);
function TimeLineOptions(){
	/**
	 * @namespace TimelineOptions
	 * @param [options] {Object} An optional object containing any of the following keys, which will override the default values.
	 * @param [options.HEIGHT=24] {Number} The height of the Timeline background render area, in pixels
	 * @param [options.BACKGROUND_COLOR='#ffffff'] {String} The color of the timeline background.
	 * @param [options.BACKGROUND_ALPHA=1] {Number} The alpha value of the timeline background. 0 = 0% opacity
	 * @param [options.LINE_COLOR='#ffffff'] {String} The color of the outer line for the timeline
	 * @param [options.LINE_SIZE=1] {Number} The size of the outer line drawn for the timeline.
	 * @param [options.LINE_ALPHA=1] {Number} The alpha of the outer line drawn for the timeline.
	 * @memberOf ProjectView
	 * @constructor
	 * @returns {Object}
	 */
	function options(options){
		return{
			HEIGHT: options.HEIGHT || 24,
			BACKGROUND_COLOR: options.BACKGROUND_COLOR || '#ffffff',
			BACKGROUND_ALPHA: options.BACKGROUND_ALPHA || 1,
			LINE_COLOR: options.LINE_COLOR || '#ffffff',
			LINE_SIZE: options.LINE_SIZE || 1,
			LINE_ALPHA: options.LINE_ALPHA || 1,
			COLUMN_WIDTH: options.COLUMN_WIDTH || 100
		}
	}
	return options
}