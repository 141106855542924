'use strict';

import Point from "js/lib/math/Point";

export default function Line(arg0, arg1?, arg2?, arg3?, arg4?){/*
	Line (2D)
	see: https://github.com/paperjs/paper.js/blob/master/src/basic/Line.js
	
	new Line((x, y, dx, dy || {x,y}, {dx,dy}), asVector)
	.p .point = Point(x,y) -> this
	.v .vector = Point(dy,dx) -> this
	.l .length = Number(length) -> this
	.clone = new Line(this)
	.intesect(line, isInfinite) -> undefined || Point(x,y)
	.side(point) -> Number(1 || 0 || -1)
	.distance(point) -> Number(length)
	
	Line.intersect(apx, apy, avx, avy, bpx, bpy, bvx, bvy, asVector, isInfinite) -> Point
	Line.side(px, py, vx, vy, x, y, asVector) -> Number(1 || 0 || -1)
	Line.distance(px, py, vx, vy, x, y, asVector) -> Number(length)
	
*/ this.initialize.apply(this, arguments)}
var anyLine:any = Line;

Line.prototype = {
	constructor: Line,
	initialize: function(arg0, arg1, arg2, arg3, arg4) {
		this.observers = [];
		var asVector = false;
		if (arguments.length >= 4) {
			this.px = arg0; this.py = arg1;
			this.vx = arg2; this.vy = arg3;
			asVector = arg4;
		} else {
			this.px = arg0.x; this.py = arg0.y;
			this.vx = arg1.x; this.vy = arg1.y;
			asVector = arg2;
		}
		if (!asVector) {
			this._vx -= this.px; this._vy -= this.py;
		}
	},
	get clone(){ return new Line(this) },
	get point(){ return new Point(this.px, this.py) },
	set point(p){ this.px = p.x; this.py = p.y; this.update(); },
	get p(){ return this.point },
	set p(p){ this.point = p },
	get vector(){ return new Point(this.vx, this.vy) },
	set vector(v){ this.vx = v.x, this.vy = v.y; this.update(); },  
	get v(){ return this.vector },
	set v(v){ this.vector = v },
	get length(){ return this.vector.length },
	set length(l){ this.vector = (this.vector.length = l);  },  
	get l(){ return this.length },
	set l(l){ this.length = l },
	intersect: function(line, isInfinite){
		return anyLine.intersect(this.px, this.py, this.vx, this.vy,
													line.px, line.py, line.vx, line.vy,
													true, isInfinite)                          
	},
	update: function(){
		this.observers.forEach(function(fn){ fn() }); 
	},
	side: function(point){
		return anyLine.side(this.px, this.py, this.vx, this.vy, point.x, point.y, true)
	},
	distance: function(point){
		return Math.abs(anyLine.distance(this.px, this.py, this.vx, this.vy, point.x, point.y, true))
	}
}

anyLine.intersect = function(apx, apy, avx, avy, bpx, bpy, bvx, bvy, asVector, isInfinite) {
	if (!asVector) { avx -= apx; avy -= apy; bvx -= bpx; bvy -= bpy }
	var cross = bvy * avx - bvx * avy;
	if (cross == 0) {
		var dx = apx - bpx, dy = apy - bpy,
				ta = (bvx * dy - bvy * dx) / cross, tb = (avx * dy - avy * dx) / cross;
		if ( (isInfinite || 0 <= ta && ta <= 1) && (isInfinite || 0 <= tb && tb <= 1)){
			return new Point( apx + ta * avx, apy + ta * avy );
		}
	}
}

anyLine.side = function(px, py, vx, vy, x, y, asVector) {
	if (!asVector) { vx -= px; vy -= py }
	var v2x = x - px, v2y = y - py, ccw = v2x * vy - v2y * vx;
	if (ccw === 0) {
		ccw = v2x * vx + v2y * vy;
		if (ccw > 0) {  
			v2x -= vx; v2y -= vy; ccw = v2x * vx + v2y * vy;
			if (ccw < 0) ccw = 0;
		}
	}
	return ccw < 0 ? -1 : ccw > 0 ? 1 : 0;
}
	
anyLine.distance = function(px, py, vx, vy, x, y, asVector) {
	if (!asVector) { vx -= px; vy -= py }
	var m = vy / vx, b = py - m * px;
	return (y - (m * x) - b) / Math.sqrt(m * m + 1);
}
