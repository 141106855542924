'use strict';
//see ng-repeat document
//https://github.com/angular/angular.js/blob/master/src/ng/directive/ngRepeat.js#L3

//$transclude.isSlotFilled(slotName)

//todo
// - need to add survivability when a cell is deleted and the editIndex falls out of sync (currently just clearing it)
// - add a loading indicator and a better means of handing saves and such

import * as angular from "angular";
import * as utils from "utils";

angular.module("ticketspaceApp")

//<editable-list custom-repeat="location in locations track by $id">
	//<editable-list-new>new template</editable-list-new>
	//<editable-list-cell>template</editable-list-cell>
	//<editable-list-edit>edit template</editable-list-edit>
//</editable-list>
.directive("editableList", function(){
	
	function defaultEditClone(basePath, index, collection){
		if(index !== -1 && collection[index] !== undefined){
			if(basePath){ var obj = utils.getNested(collection[index], basePath); }
			else{ var obj = collection[index];}
			return angular.extend({}, obj);
		}
		return {};
	}
	
	return {
		scope: true,
		transclude: {
			"cell": "editableListCell",
			"newEdit": "editableListNewEdit",
			"newCell": "?editableListNewCell",
			"edit": "editableListEdit",
			"sectionHeader": "?editableListSectionHeader"
		},
		compile: function (element, attrs){
			element.addClass('editable-list');
			// Hello!
			
			//parse repeat expression, rip off native
			//go lazy and only support simple syntax (x in object track by $id)
			var expression = attrs.customRepeat;
			var match = expression.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?\s*$/);
			if(!match){
				throw new Error("Expected expression in form of '_item_ in _collection_ track by _id_ but got "+expression+" (only the simple repeat variant is supported)");
			}

			//use these keys in link
			var collectionId = match[2];
			var childKey = match[1];
			var idKey = match[3];
			
			return function link(scope, element, attr, ctrl, $transclude){
				
				//use effectively "magic properties"
				//null -> nothing editing
				//0 - Inf -> the thing at that index
				//-1 -> new
				var editIndex = null;
				var collection = [];
				
				var isDisabled = false;
				
				var editCloneFn;
				var collectionCache = {};
				
				
				
				//setup the constant cells, only need to be created once
				var constantCells = {
					"newCell": null,
					"newEdit": null,
					"edit": null
				};
				
				//the api passed out and bound to internal on childScopes
				//some bind fun to keep "this" as "this"
				var api:any = {editModel: {}, isLoading: false};
				api.openEdit = (function openEdit(index){
					//console.log('open edit', editIndex);
					if(isDisabled){return;}
					if(editIndex !== null){
						this.closeEdit();
					}
					
					editIndex = index;
					this.editModel = editCloneFn(index, collection);
					
					if(index === -1){
						var e = collection.length ? collectionCache[collection[0][idKey]].element[0] : null;
						element[0].insertBefore(constantCells.newEdit.element[0], e);
					}
					else{
						var dat = collection[index];
						if(!dat){Logging.warning("can't edit nothing"); return;}
						var cell = collectionCache[dat[idKey]];
						
						var newCell = constantCells.edit;
						updateScope(newCell, index);
						cell.cellType = newCell.cellType;
						//onsole.log('open cell', cell);
						this._cell = cell;
						
						element[0].insertBefore(newCell.element[0], cell.element[0].nextSibling);
					}
				}).bind(api);
				api.closeEdit = (function closeEdit(){
					if(element[0].contains(constantCells.newEdit.element[0])){
						element[0].removeChild(constantCells.newEdit.element[0]);
					}
					//console.log('editIndex', editIndex);
					if(editIndex !== null && editIndex !== -1){
						if(this._cell){ this._cell.cellType = "cell"; }
						// var dat = collection[editIndex];
						// console.log('dat', dat);
						// if(dat){
						// 	var cell = collectionCache[dat[idKey]];
						// 	console.log('close cell',cell);
						// 	cell.cellType = "cell";
						// }
						if(element[0].contains(constantCells.edit.element[0])){
							element[0].removeChild(constantCells.edit.element[0]);
							//console.log('removing edit state', element[0]);
						}
					}
					
					this.editModel = {};
					editIndex = null;
				}).bind(api);
				api.openNew = (function openNew(){
					this.openEdit(-1);
				}).bind(api);
				api.toggleEdit = (function toggleEdit(idx){
					if(idx === editIndex){ this.closeEdit(); }
					else{ this.openEdit(idx); }
				}).bind(api);
				api.startLoading = (function startLoading(){
					this.isLoading = true;	//need to update the element or something at this point
				}).bind(api);
				api.finishLoading = (function finishLoading(){
					this.isLoading = false;	
				}).bind(api);
				//testing some wrapped handling
				api.handlePromise = (function handlePromise(promise){
					var self = this;
					//console.log('editIndex in handle', editIndex);
					promise.then(function(){
						self.closeEdit();
					})
					.catch(function(err){
						console.log('err', err);
						if(err){ Logging.warning(err.message); }
					}).finally(function(){
						self.finishLoading();
					});
				}).bind(api);
				
				//populdate constant cells
				if($transclude.isSlotFilled("newCell")){
					constantCells.newCell = createChild(element, null, "newCell");
					updateScope(constantCells.newCell, null);
					element.append(constantCells.newCell.element);
				}
				constantCells.newEdit = createChild(element, null, "newEdit");
				updateScope(constantCells.newEdit, null);
				constantCells.edit = createChild(element, null, "edit");
				updateScope(constantCells.edit, null);
				
				//when disabled is truthy edit functions cease to work
				// might need to also abort current edit when this changes
				// behaves a bit sketchy with non-primative expresions, fix if such a thing becomes necessary
				scope.$watch(attr.disablesIt, function(exp){
					isDisabled = !!exp;
				});
				
				//can provide a clone-method attribute to customize how the edit object gets populated
				// default - just clone the list element
				// string - specs a child of the list element to clone
				// function - function(basePath, index, collection). Spec a function for custom handling
				scope.$watch(attr.cloneMethod, function(method){
					//console.log('method', method);
					if(method === undefined){
						editCloneFn = defaultEditClone.bind(undefined,undefined);
					}
					else if(typeof method === 'string'){
						editCloneFn = defaultEditClone.bind(undefined, method);
					}
					else if(typeof method === 'function'){
						editCloneFn = method;
					}
					else{
						throw new Error("clone-method type is invalid. Must be string or function.")
					}
				});
				
				scope.$watch(attr.api, function(external:any){
					//if(external){ angular.extend(external, api); 	}
					if(external){ external.api = api; }
				});
				
				//update the collectionItem scope
				function updateScope(collectionItem, index){
					//console.log('collectionItem', collectionItem, collection[index]);
					if(index !== undefined && index !== null){
						collectionItem.index = index;
						collectionItem.id = collection[index][idKey];
						collectionItem.scope[childKey] = collection[index]; //still need to consider where data will be stored
						collectionItem.scope.$index = index;
					}
					else{
						collectionItem.scope.$index = -1;
						collectionItem.index = -1;
					}
					collectionItem.scope.internal = api;
				}
				
				//get a new element
				function createChild(element, index, transcludeSlot){
					var obj = {};
					$transclude(function(clone, scope){
						clone.addClass('editable-list-cell');
						obj = {
							scope: scope,
							element: clone,
							cellType: transcludeSlot,
							index: index
						};
					},element, transcludeSlot);
					return obj;
				}
				
				var oldLength = 0;

				//make angular updates and such
				scope.$watchCollection(collectionId, function(c:any){
					//console.log('collection changed');
					//empty
					if(oldLength && (!c || !c.length)){
						for(var key in collectionCache){
							collectionCache[key].scope.$destroy();
							collectionCache[key].element.remove();
						}
						oldLength = 0;
					}
					collection = c;
					//and abort
					if(!c){	return;	}
				
					var matchedCache = {}; //track what still exists
					for(var i = 0; i < c.length; i++){
						var item = c[i];
						var childObj;
						//old thing (update)
						if(collectionCache[item[idKey]]){
							childObj = collectionCache[item[idKey]];
							updateScope(childObj, i);
						}
						//new thing (add)
						else{
							childObj = createChild(element, i, "cell");
							updateScope(childObj, i);
							collectionCache[item[idKey]] = childObj;
						}
						
						matchedCache[item[idKey]] = item[idKey];
						
						//just re-append everything
						//a potential optimization would be to also track current position and only update changes
						//appends should be light enough that this never becomes an issue though
						element.append(childObj.element);
						//put edits back where they should go
						if(childObj.cellType === "edit"){
							//console.log('childObj', childObj)
							element.append(constantCells.edit.element);
							editIndex = i;
						}
					}
					
					//remove removed things
					//can probably rather easily add animations by adding a "deleted key", and applying a class and a timeout that deletes it
					//or maybe directly using the $animate service somehow...
					for(var key in collectionCache){
						var thing = collectionCache[key];
						if(!matchedCache[thing.id]){
							//theoretically if the user is loading their the one that removed it
							if(thing.cellType === "edit" && !api.isLoading){ 
								Logging.warning("Changes not saved, another user has removed this item.");
								api.closeEdit();
							}
							thing.scope.$destroy();
							thing.element.remove();
							delete collectionCache[key];
						}
					}
					oldLength = collection.length;
				});
			}
		}
	};
});
