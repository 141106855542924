import * as angular from "angular";
var anyWindow:any = window;

angular.module('CanvasWrapper').factory("InteractionPan", PanFactory);
/**
 * @namespace Pan
 * @returns {CanvasWrapper.Interaction.Pan}
 * @memberOf CanvasWrapper.Interaction
 * @constructor
 */
PanFactory.$inject = ["InteractionHandleEvent"];
function PanFactory(InteractionHandleEvent){
	var handleEvent = InteractionHandleEvent;
	function Pan(displayNode, inertia){
		function mouseDown (e) {
			start(e.data.originalEvent)
		}

		function touchStart (e) {
			start(e.data.originalEvent.targetTouches[0])
		}

		function start (t) {
			if (displayNode._pan) {
				if (!displayNode._pan.intervalId) {
					return
				}
				clearInterval(displayNode._pan.intervalId);
				handleEvent(displayNode).emit('panend')
			}
			displayNode._pan = {
				p: {
					x: t.clientX,
					y: t.clientY,
					date: new Date()
				}
			};
			handleEvent(displayNode).on('mousemove', mouseMove);
			handleEvent(displayNode).on('touchmove', touchMove)
		}

		function mouseMove (e) {
			move(e, e.data.originalEvent)
		}

		function touchMove (e) {
			var t = e.data.originalEvent.targetTouches;
			if (!t || t.length > 1) {
				end(e, t[0]);
				return
			}
			move(e, t[0])
		}

		function move (e, t) {
			var now:any = new Date();
			var interval = now - displayNode._pan.p.date;
			if (interval < 12) {
				return
			}
			var dx = t.clientX - displayNode._pan.p.x;
			var dy = t.clientY - displayNode._pan.p.y;
			var distance = Math.sqrt(dx * dx + dy * dy);
			if (!displayNode._pan.pp) {
				var threshold = (t instanceof anyWindow.MouseEvent) ? 2 : 7;
				if (distance > threshold) {
					handleEvent(displayNode).emit('panstart')
				} else {
					return
				}
			} else {
				var event = {
					deltaX: dx,
					deltaY: dy,
					velocity: distance / interval,
					data: e.data
				};
				handleEvent(displayNode).emit('panmove', event)
			}
			displayNode._pan.pp = {
				x: displayNode._pan.p.x,
				y: displayNode._pan.p.y,
				date: displayNode._pan.p.date
			};
			displayNode._pan.p = {
				x: t.clientX,
				y: t.clientY,
				date: now
			}
		}

		function mouseUp (e) {
			end(e, e.data.originalEvent)
		}

		function touchEnd (e) {
			if (e && e.data.originalEvent.changedTouches && e.data.originalEvent.changedTouches.length > 0){
				end(e, e.data.originalEvent.changedTouches[0])
			}
		}

		function end (e, t) {
			handleEvent(displayNode).removeListener('mousemove', mouseMove);
			handleEvent(displayNode).removeListener('touchmove', touchMove);
			if (!displayNode._pan || !displayNode._pan.pp) {
				displayNode._pan = null;
				return
			}
			if (inertia) {
				if (displayNode._pan.intervalId) {
					return
				}
				var interval = (new Date() as any) - displayNode._pan.pp.date;
				var vx = (t.clientX - displayNode._pan.pp.x) / interval;
				var vy = (t.clientY - displayNode._pan.pp.y) / interval;
				displayNode._pan.intervalId = setInterval(function(){
					if (Math.abs(vx) < 0.04 && Math.abs(vy) < 0.04) {
						clearInterval(displayNode._pan.intervalId);
						handleEvent(displayNode).emit('panend');
						displayNode._pan = null;
						return
					}
					var touch = {
						clientX: displayNode._pan.p.x + vx * 12,
						clientY: displayNode._pan.p.y + vy * 12
					};
					move(e, touch);
					vx *= 0.9;
					vy *= 0.9;
				}, 12)
			} else {
				handleEvent(displayNode).emit('panend');
				displayNode._pan = null
			}
		}

		displayNode.interactive = true;
		handleEvent(displayNode).on('mousedown', mouseDown);
		handleEvent(displayNode).on('touchstart', touchStart);
		handleEvent(displayNode).on('mouseup', mouseUp);
		handleEvent(displayNode).on('mouseupoutside', mouseUp);
		handleEvent(displayNode).on('touchend', touchEnd);
		handleEvent(displayNode).on('touchendoutside', touchEnd);

		return handleEvent(displayNode);
	};

	return Pan
}
