import { DataContainerIndex, TicketContainer } from "./";
import { Container } from "pixi.js";
import { Observable } from "rxjs";
import {buffer, filter } from "rxjs/operators";
import { AbstractObserverInterface } from "../interfaces/";
import { PlanState } from "../../common/services/plan-state.service";
import { RenderDependencies } from "../core/";

/**
 * *Introduction*
 
 As of TouchPlan release-30, we are currently running a hybrid application with both AngularJS and Angular 5 code. This hybrid application set-up is currently being achieved by the Angular 5 Downgrade Module; however this set-up causes many issues which prevent an easy transition from AngularJS to Angular 5. This is described well in this document: https://mocasystems.atlassian.net/wiki/spaces/FEAT/pages/184483841/Late+2017+Early+2018+Development+Roadmap. A solution for the current blocker issues with the downgrade module can be mitigated by using the Angular Upgrade Module instead, however this negatively affects performance to the point of making the application unusable. The underlying cause is we have too many elements in the DOM, caused by the ticket elements, which causes slow rendering time of a scene. Further, the Upgrade Module causes the application ticker to fire much more frequently, also due to the amount of ticket elements existing in the DOM.
 
 
 A current proposed solution is to migrate all of the ticket elements, which are being managed by Angular, from the DOM into a single DOM canvas element. By moving all of our rendering tasks to the canvas, we should theoretically gain increased performance in the application and increased scalability when we need to draw a significant amount of renderable objects. This move will also remove all ticket DOM elements from Angular space and should also greatly improve change detection performance in Angular. The net result should be we gain access to the Angular UpgradeModule with negligible performance impact and we gain all of the advantages that the canvas provides.
 
 
 However, one problem with this migration is we are removing Angular from controlling all ticket elements. Typically, Angular/AngularJS change detection will detect a model change and then trigger an update to the view. By removing Angular/AngularJS’ control of the ticket view, we have to effectively re-create the behavior so our ticket element view drawn on the canvas is updated as the model changes. This document is meant to draft out how we can solve that problem.
 
 
 *Goals*
 * Provide easy access to the Scene Graph, allowing for easy manipulation of renderable objects in the canvas at a high level.
 ** I think the goal here is to manipulate the DisplayObjects drawn on the plan surface at a high level. Avoid doing low level direct draw calls to the plan surface and instead focus on simply changing data. Basically, when data changes, I do not want any api at a higher level being responsible for having to re-draw anything on the canvas. The intent is: Change your data, and the renderer will update automatically.
 * Each renderable display node should be able to have a data set assigned.
 ** Probably extend the PIXI.Container for this and name the new class DataContainer?
 ** Idea here: DataContainer is a Container which exposes new member(s) with assigned data, and possibly some helper functions. Any texture, mesh or sprite we draw on the scene which relies on a dataset will be added as a child to the DataContainer. Children of the DataContainer can access all publicly accessible methods and members of the parent by accessing the parent property. Thus, manipulation of the DataContainer will affect all of its children.
 * Should be written in a way which allows for multiple instances of the renderer to be run simultaneously, with each having different data streams.
 ** There have been talks about showing multiple plans at once. By allowing the renderer to have multiple instances, we should be able to create new instances with different data and render an entirely new world space with its own camera to interact with it.
 * Use a push based notification system to handle changes. It’s not ideal to add yet another change detection system in.
 
 
 *Concept*
 PIXI.js draws the scene using the constructed scene graph, with each display node being a DisplayObject (or an extension thereof). In order to meet all of our goals outlined above we need, at the minimum, the following pieces:
 # A specific type of DisplayObject on our scene graph that stores assigned data, and probably exposes some helper functions.
 # A class that maps passed data to a DisplayObject in our scene graph and keeps data synced
 # A class which caches data to a node in the scene graph. This is to prevent constant traversal of the scene graph tree, which could slow significantly for large scenes.
 
 Our special scene graph node will be an extension of PIXI.Container and I propose we name it DataContainer. For every DisplayObject we want to draw in world space that relies on external data, we first add a DataContainer node to our scene graph and for each graphical representation of that dataset we want to draw, we add as a child to the DataContainer. Now, all of the children of the DataContainer will have access to the same data by accessing its own parent member. This simplifies updating of data since we only have to ensure our data containers are updated, and any change will automatically propagate down the scene graph to our children during the next draw cycle.
 
 Further, this should even allow the drawing of multiple plans within the same renderer, basically avoiding the problem of creating a new renderer for multiple plans. Assume the following Scene Graph:
 {noformat}
 - Container: ‘world space’
 -- DataContainer: “Plan A”
 --- DataContainer: “Plan A Timeline”
 --- DataContainer: “Plan A Tickets”
 ---- DataContainer: <random ticket ID>
 ----- Sprite: <Ticket Role Color>
 -- DataContainer: “Plan B”
 --- DataContainer: “Plan B Timeline”
 --- DataContainer: “Plan B Tickets”
 ---- DataContainer: <ticket ID>
 ----- Sprite: <Ticket Role Color>
 {noformat}
 
 Based on the Scene Graph above, we can theoretically sort our graph based on plan and easily differentiate between the original ‘opened’ plan and extra viewable plans. In the UI, we could add additional effects to differentiate the two for the user, such as a ‘ghosting’ effect when extra plans are rendered with different opacity or some other visual cue. With this set-up, we are drawing two different plans within the same ‘world space’. There are problems here; such as dates and coordinates from plan to plan will not be consistent, among other issues. However, this shows how we can be flexible with the scene graph to build what we need.
 
 
 Next, we need a class to actually keep our external data and the data in our DataContainers synchronized. Ultimately, this class will be the glue to keep our view (The plan surface) in sync with the model. I propose we name this class DataContainerManager. This class will construct a new DataContainerIndex and expose methods for subscribing to observables and it will have the ability to create new DataContainers. Although a DataContainer will never be visible to a user and will not be affected by any matrix transforms, we cannot assume that the DataContainer will not have children with the same intended behavior. As such, the DataContainerManager must have the ability to create new DataContainers at any position in the scene graph. The idea here is the DataContainerManager had methods to register new observers on passed observables. When data is pushed, the DataContainerManager collects the new data, queries the DataContainerIndex (Our cache) and updates the data on the associated DataContainer. Once the DataContainer has new data, the new data propagates down the scene graph to all of the DataContainer children and re-rendered. We now have an updated view.
 
 
 Finally, we need a cache which maps a dataset to a DataContainer. We could have this built-into the DataContainerManager, but I’d personally prefer to have it be independent. The purpose of this class is to prevent a traversal across our entire scene graph in order to update DataContainers, so the DataContainerManager only has to pass in the unique identifier of the data that changed and get the DataContainer associated with that data. I see this being a simple dictionary, with the key being the unique identifier of the data.
 
 
 *Overview*
 DataContainer class:
 * Very Light. No user interaction, no matrix transformations. Non-renderable.
 * Extends PIXI.Container.
 * Represents data in our scene graph
 * Enforce that this cannot be renderable and is never affected by transform operations.
 * Renderable elements are added as children to the data store where they receive data.
 * Exposes methods child display nodes can access to retrieve data.
 
 DataContainerManager class:
 * Maps data to a collection of DataContainers.
 * Has the ability to create DataContainers in the scene graph
 * Has the ability to delete and modify DataContainers in the scene graph
 * Must have the ability to create DataContainer is very specific places in the scene graph.
 * Exposes methods to register observers for data changes
 * Probably also needs to expose observables to handle user interaction on the plan surface. Example case: ticket was dragged and dropped, push the new coordinates out so it can be saved in Firebase
 * Assumption is the observer function has a parameter containing the entirely new state of the changed data. Can be re-worked easily enough if we are only passing the data that changed.
 * Might be useful to have multiple instances of this class tracked in a singleton, so the same data manager can easily be imported into different classes. Example: DataContainerManager.getInstance (id) returns the DataContainerManager with that ID.
 * Can extend the DataContainerManager so  we can change how it maps different _types_ of data to a DataContainer.
 
 DataContainerIndex class:
 * Acts as a cache of DataContainers
 * Effectively a key:value pair, with the key being a unique identifier (Typically a $id value or some other key from Firebase?)
 * Prevents constantly traversing the Scene Graph to update data, which can slow down for large logical scene representations.
 
 DataManagerIndex class:
 * A singleton which has the ability to create new DataContainerManager and then tracks it.
 * Might be useful for importing into other classes? Could just do: import {DataManagerIndex} from ‘blah’ and then DataManagerIndex.getInstance(<unique ID>) and have the DataContainerManager that corresponds to that id.
 * After further thought, we probably won’t need too many instances of the DataContainerManager, since a single instance should be able to handle most of what we need to do….
 
 
 *Flow*
 Initialization:
 * Create instance of DataContainerManager. DataContainerManager will create the DataManagerIndex in the constructor.
 * Register a dataset: Something like DataContainerManager.register(Observable, unique id, parent node)? This sets up an observer on the observable and tracks unsubscribe return call. Unique ID could either be a string representing the property on the returned data or a unique number. Alternatively, could force an interface on all data passed to an observer and use an ‘id’ key and drop the unique id parameter. The parent node defines where the DataContainer is added in the scene graph. Although the DataContainer will be immune to transforms, we may want to transform a higher level node and have it affect all DataContainers children (Such as transforming tickets during zoom)
 * Once observer is called, query the cache for a DataContainer. If no DataContainer exists, create one and initialize it with the new data and then add the DataContainer to the scene graph as a child to the parent node parameter.
 * Updating DataContainers should more or less follow this same set-up.
 */
export abstract class DataContainerManager implements AbstractObserverInterface{
	/**
	 * Contains an instance of the DataContainerIndex class which maps and caches data containers
	 */
	public mapping: DataContainerIndex;
	/**
	 * The plan state
	 */
	public planState: PlanState;
	constructor(planState: PlanState){
		this.planState = planState;
		this.mapping = new DataContainerIndex();
	}
	/**
	 * This method generates a new DataContainer, caches it, and adds the new data container to the provided parent {@link PIXI.Container}
	 * @param {string} identifier The unique identifier for the new DataContainer
	 * @param {PIXI.DisplayObject} parent The parent PIXI.DisplayObject where a new DataContainer will be added to
	 * @param {Object} data The data that will be assigned to the newly created DataContainer
	 * @returns DataContainer
	 */
	protected createNode(identifier: string, parent: Container, data: any){
		// Create a new DataContainer, and bind data
		let dataContainer: TicketContainer = new TicketContainer(this.planState);
		dataContainer.assign(data);
		
		dataContainer.setup();
		dataContainer.x = data.view.left;
		dataContainer.y = data.view.top;
		
		// Add new DataContainer tp the provided parent node
		parent.addChild(dataContainer);
		this.mapping.cache(identifier, dataContainer);
		return dataContainer;
	}
	/**
	 * This method is called when an observer receives a complete notification from the subscribed observable. Stubbed out. Expected the extended classes will implement the required logic.
	 */
	public complete = () => {};
	/**
	 * This method is called when an observer receives an error notification from the subscribed observable. Stubbed out. Expected the extended classes will implement the required logic.
	 */
	public error = (err: any) => {};
	/**
	 * Updates the DataContainer associated with the data passed in as a parameter. Stubbed out.
	 * @param [args] The new data.
	 * @returns DataContainerManager
	 */
	public updateData = (...args: any[]) => {};
	/**
	 * The main update loop.
	 * @param args
	 */
	public update = (...args: any[]) => {};
	/**
	 * Sets up a new observer on a passed Observable. We do this so we can link up a change into the DataContainerManager.update method
	 * @param {Observable} dataStream$ The observable that contains the data we are subscribing too.
	 */
	public observe(dataStream$: Observable<any>){
		return dataStream$
		.pipe(
			buffer(RenderDependencies.ticker),
			filter(arr => !!arr.length)
		)
		.subscribe({
			next: this.updateData,
			error: this.error,
			complete: this.complete
		});
	}
	/**
	 * @param observer
	 */
	public subscribe(observer: any){
		//TODO this. Idea: call this to subscribe to changes coming from the view, such as when an element in the canvas is dragged.
	}
	
	public destroy(options?){
		this.planState = null;
		this.mapping.destroy();
	}
}
