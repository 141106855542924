import { Component, OnInit, ChangeDetectionStrategy, Host } from '@angular/core';
import { IntercomService } from "../../../services/intercom.service";
import  { DropdownMenuComponent } from "../../dropdown-menu/dropdown-menu.component";

@Component({
	selector: 'support-menu',
	template: require('./support-menu.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportMenuComponent implements OnInit {
	/**
	 * Inject the IntercomService into the SupportMenu
	 */
	constructor(public intercomService: IntercomService) {
		//console.log("PARENT", parent, parent.toggle);
	}

	ngOnInit() {}
}
