import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/components/dropdown/dropdown';
import { MultiSelectModule } from 'primeng/components/multiselect/multiselect';

import { RoleMultiSelectComponent } from "./components/role-multi-select.component";
import { RoleDropdownComponent } from "./components/role-dropdown.component";
import { DropdownComponent} from "./components/dropdown.component";
import { MultiSelectComponent} from "./components/multi-select.component";
import { PriorityDropdownComponent } from "./components/priority-dropdown.component";

// Extended components
import { MultiSelectOverrideComponent } from "./components/multiselect-override.component";

@NgModule({
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		DropdownModule,
		MultiSelectModule
	],
	declarations: [
		MultiSelectComponent,
		DropdownComponent,
		RoleMultiSelectComponent,
		RoleDropdownComponent,
		MultiSelectOverrideComponent,
		PriorityDropdownComponent
	],
	entryComponents : [
		MultiSelectComponent,
		DropdownComponent,
		RoleMultiSelectComponent,
		RoleDropdownComponent,
		MultiSelectOverrideComponent,
		PriorityDropdownComponent
	],
	exports: [
		MultiSelectComponent,
		DropdownComponent,
		RoleMultiSelectComponent,
		RoleDropdownComponent,
		MultiSelectOverrideComponent,
		PriorityDropdownComponent
	]
})

export class DropdownSelectModule {}