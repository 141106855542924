//Current problem, mapping between ticket.edit and TicketEditService
// The following flow should be viable, excluding the arrows
// 
// EditTickets(tickets)
// - builds the central edit model that the ui gets directly bound to from the database state (maybe the live state? could get interesting)
// - subscribe to observer stream to react to edit changes
// - change view on each ticket to edit, via observer chain, make a ticket_edited event maybe?
// 
// 
// sample 1: editing description in tecketEdit model:
// - bind to blur or changed+soak, or changed+observer based soak
// - call TicketActions.updateEditTicket(ticket)
// -- it nexts an observer 
// eeeeeeee two way binding problem <----------------------------------------------
// -- update observer gets called with the same data...
// 
// 
// sample 2: editing width while dragging edge
// - it nexts many observers during the drag
// - drop edge
// - call TicketActions.updateTicket(ticket)
// -- it nexts an observer
// -- ticketEdit handles observer by recalcing it's data? <-------------------------
// --- scary
// -- we may want to throttle the observer in ticketEdit
// 
// 
// 
// 
// changes to ticket.view need to propaogate to ticketEdit so that width changes make it through
// changes to ticketEdit need to propaogate to ticket.view for the liveness
// changes to ticket.database should not propagate to either, this is handled by ticket.view 


import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import * as moment from "moment";
import * as utils from "utils";

import { Observable, Subject, BehaviorSubject, ConnectableObservable, from, of, empty, merge } from 'rxjs';
import { scan, tap, filter, map, pluck, catchError, first, bufferTime, buffer, skipWhile, mergeMap, switchMap, debounce, sample, groupBy, takeUntil } from "rxjs/operators";
import { drop } from 'ng2/common/rxjs-internal-extensions';

import { FancyFirebaseList, AngularLandConnection, FancyJoinRequirements, FancyAction, FancyActionTypes } from 'ng2/fancy-firebase/base';
import { PullColumnList } from "ng2/fancy-firebase/factories";
import * as firebaseUtils from 'ng2/fancy-firebase/firebase-utils';
import { Ticket, FloatingTicket, TicketRenderTypes } from 'ng2/common/models';
import { CalendarWorkdays } from "ng2/common/models/CalendarWorkdays";
import { Plan } from 'ng2/common/models/Plan';
import { Variance } from 'ng2/common/models/Variance';

import { FancyTicketAction, TicketActionTypes, CombinedTicketActions } from "ng2/actions/ticket-actions";

import { FancySupplementalAction, SupplementalActionTypes, SessionSettings } from "ng2/actions/supplemental-actions";

import { TicketSelection } from "ng2/common/models/TicketSelection"
import { ActiveColumnService } from "ng2/common/ajs-upgrade-providers";

import { renderQueue } from "ng2/common/RenderQueue";

export interface EditTicketsState{
	lastActionType: FancyAction,
	list: Map<string, Ticket>
}

interface TicketsAngularLandConnection extends AngularLandConnection{
	activeColumnService: ActiveColumnService
}


// Keep TicketList very lean, if it requires access to something in the injector, that method does not belong on the TicketList.
// - it will additionally not have access to the state outside of it's own internal state
// - this is potentially limiting, and potentially breaks up the api into peices
// - logically we'll want a TicketActions injectable which is where the api will go for manipulating Tickets. This should have access to the state and injectables.
// - the idea of TicketActions would be to loosely replicate the idea of reducer actions
//   without the limitations of immutability.
// - instead of a ticket action referencing a central store, the action methods will be provided the instance of tickets to operate on.
// - alternatively it could manipulate planState instead of passing in the instance of tickets
// we _will_ infact need some of the factory implementation to get at the angularfire instance when initializing.





/**
 * .editTicket$ will be notified of updates if this returns true
 */
function isEditInterested(action:FancyTicketAction){
	return action.type === TicketActionTypes.ticketChangedToEdit 
	|| action.type === TicketActionTypes.ticketChangedToDefault
	|| action.payload.renderType === TicketRenderTypes.edit
}


export class TicketList extends FancyFirebaseList<Ticket, FancyTicketAction> implements FancyJoinRequirements<Ticket>{
	public editTicket$ = new BehaviorSubject(undefined);
	public selectedTicket$ = new BehaviorSubject( new TicketSelection() );
	
	private delayedSelection = new Map<string, string>();
	
	/** rawEvent$ filtered down to a theoretical subset of "necessary" events */
	public soakedRaw$: Observable<FancyTicketAction>;
	
	//show longest crap
	//No idea why this is actually an observable, maybe... make it not that at some point
	private pullTicket$ = new BehaviorSubject( new Map<string, Ticket>() );
	private lastPullColumns:Array<any>;
	private lastVarianceList?: Map<string, Variance>;
	
	private lastSettings:SessionSettings = {};
	
	protected addActions = {
		[FancyActionTypes.childAdded]: FancyActionTypes.childAdded,
		[TicketActionTypes.floatingTicketSaved]: TicketActionTypes.floatingTicketSaved,
		[TicketActionTypes.floatingTicketAdded]: TicketActionTypes.floatingTicketAdded,
		[TicketActionTypes.createTicketWithId]: TicketActionTypes.createTicketWithId
	}
	protected removeActions = {
		[FancyActionTypes.childRemoved]: FancyActionTypes.childRemoved,
		[TicketActionTypes.ticketMovedToFloating]: TicketActionTypes.ticketMovedToFloating
	}
	
	private planData: Plan;
	
	private tick500 = renderQueue.ob$.pipe(drop(31), takeUntil(this.shutdownSubject));
	
	constructor(protected angularLand:TicketsAngularLandConnection, path:any, fancyJoins: any, protected workDays: CalendarWorkdays){ //fix any's
		super(angularLand, path, FancyTicketAction, fancyJoins);
		this.suppressActionsForLoading = true;
		this.enableLogging = true;
		
		this.letEditTicketsPlay();
		this.setupSelection();
		this.makePullList();
		this.setupBufferedEvents();
		
		// this.actionTracker$.firstOfType(TicketActionTypes.ticketPersistData).pipe(takeUntil(this.shutdownSubject)).subscribe((thing)=>{
		// 	console.log('ticketList actionTracker', thing);
		// })
	}
	
	private setupBufferedEvents(){
		TicketActionTypes.ticketChangedToDrag
		let requiredEvents:any = {
			...this.addActions, 
			...this.removeActions, 
			[TicketActionTypes.ticketChangedToDrag]: TicketActionTypes.ticketChangedToDrag,
			[TicketActionTypes.ticketSelected]: TicketActionTypes.ticketSelected
		};
		this.soakedRaw$ = this.getSoakedEvents$(requiredEvents);
		// this.soakedRaw$ = this.rawEvent$;
	}
	
	/** to be called after "new-ing" it */
	private addExtraTicketData(t: Ticket){
		t.getXFromDate = (date:string)=>{
			return this.getXFromDate(date);
		};
		t.checkForLongestPull = (pullColumn: number, plannedStart: string) =>{
			return this.isLongestPull(pullColumn, plannedStart);
		}
		
		if(this.lastVarianceList){ t.varianceList = this.lastVarianceList; }
		if(this.planData){ t.activeLineX = this.planData.activeLineX;}
		t.planHasActiveSpace = !!this.planData && this.planData.planHasActiveSpace;
		
		if(this.delayedSelection.size && this.delayedSelection.has(t.$id)){
			t.selected = true;
			this.delayedSelection.delete(t.$id);
		}
			
		t.externalProperties.shouldHideCompletedTickets = this.lastSettings.hideCompletedTickets;
	}
	
	localAdd(id, data){
		var t = new Ticket(null, id);
		this.addExtraTicketData(t);
		t.firebaseUpdate(data);
		return t;
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		var t = new Ticket(null, action.key);
		//do this thing to preserve "this"
		// t.calculateActuals = (actualFinish, durationDays, roleId, originalWidth)=>{
		// 	return this.calculateTicketActuals(actualFinish, durationDays, roleId, originalWidth);
		// }
		
		this.addExtraTicketData(t);
		
		t.firebaseUpdate(action.payload.val());

		return t;
	}
	$$updated(ticket: Ticket,action: AngularFireAction<DatabaseSnapshot<any>>){
		//debug logging
		// if(window.reporting && !ticket){
		// 	window.reporting.reportRealWarning(
		// 		"ticket undefined in firebaseUpdate call", 
		// 		Object.assign(utils.makeStack(new Error("ticket undefined in firebaseUpdate call")), {
		// 			actionKey: action ? action.key : 'undefined',
		// 			actionType: action ? action.type : 'undefined',
		// 			isSelectedTicketsDefined: !!this.selectedTicket$,
		// 			ticketCount: (this._internalList && this._internalList.size) ? this._internalList.size : 0
		// 		}))
		// }
		
		ticket.firebaseUpdate(action.payload.val());
	}
	
	$$removed(t:Ticket){
		//remove external references
		t.getXFromDate = null;
		t.checkForLongestPull = null;
		t.activeLineX = null;
		t.planHasActiveSpace = null;
		t.varianceList = null;
	}
	
	setupSelection(){
		this.rawEvent$
		.pipe(
			scan( (state:{changed: boolean, selection:TicketSelection}, action:FancyTicketAction) => {
				var changed = false;
				// console.log('selection action', action.type, action.key, action.payload, action.payload.selected, state.selection.list.has(action.key), this._internalList.has(action.key));
				if(action.payload.selected && !state.selection.list.has(action.key)){
					state.selection.add(action.key, action.payload);
					changed = true;
				}
				else if(!action.payload.selected && state.selection.list.has(action.key)){
					state.selection.remove(action.key);
					changed = true;
				}
				//data removed
				else if(state.selection.list.has(action.key) && !this._internalList.has(action.key)){
					state.selection.remove(action.key);
					changed = true;
				}
				
				state.selection.updateStats(action.payload);
				
				state.changed = changed;
				return state;
			}, {changed: false, selection: new TicketSelection()}),
			filter(state => state.changed),
			pluck("selection")
		)
		.subscribe(this.selectedTicket$);
	}
	
	makePullList(){
		this.rawEvent$
		.pipe(
			scan( (state:{changed: boolean, pullTickets:Map<string, Ticket>}, action:FancyTicketAction) => {
				var changed = false;
				// debugger;
				if(action.payload.view.pullColumn !== undefined && !state.pullTickets.has(action.key)){
					state.pullTickets.set(action.key, action.payload);
					changed = true;
				}
				else if(action.payload.view.pullColumn !== undefined && state.pullTickets.has(action.key)){
					// state.pullTickets.delete(action.key);
					changed = false;
				}
				//data removed
				else if(state.pullTickets.has(action.key) && !this._internalList.has(action.key)){
					state.pullTickets.delete(action.key);
					changed = true;
				}
				state.changed = changed;
				// console.log('state.pullTickets', state.pullTickets);
				return state;
			}, {changed: false, pullTickets: new Map()}),
			filter(state => state.changed),
			pluck("pullTickets")
		)
		.subscribe(this.pullTicket$);
	}
	
	letEditTicketsPlay(){
		this.rawEvent$
		.pipe(
			filter(isEditInterested),
			scan( (state:EditTicketsState, action:FancyTicketAction) => {
				// console.log('action', action);
				//this is potentially less reliable than directly querying the rendering type (quicker though)
				if(action.type === TicketActionTypes.ticketChangedToEdit){
				// console.log("hi', action")
					state.list.set(action.key, action.payload)
				}
				else if(action.type === TicketActionTypes.ticketChangedToDefault){
					state.list.delete(action.key)
				}
				state.lastActionType = action;
				return state;
			}, {list: new Map(), lastActionType: null}),
			catchError((e, o)=>{
				console.log('ERROR', e);
				return o;
			})
		)
		.subscribe(this.editTicket$);
	}
	
	handleCustomEvents(baseObservable){
		return baseObservable.pipe(
			mergeMap((action:CombinedTicketActions)=>{
				// console.log('action', action.type);
				var failure = false;
				var ticket = this._internalList.get(action.key);
				switch(action.type){
					case TicketActionTypes.ticketDragged:
						if(ticket.renderType !== TicketRenderTypes.drag){ ticket.changeToSecondaryView(TicketRenderTypes.drag); }
						ticket.localUpdate(action.payload, TicketRenderTypes.drag );
						break;
					case TicketActionTypes.ticketChangedToDrag:
						ticket.changeToSecondaryView(TicketRenderTypes.drag);
						break;
					case TicketActionTypes.ticketChangedToEdit:
						ticket.changeToSecondaryView(TicketRenderTypes.edit);
						break;
					case TicketActionTypes.ticketChangedToDefault:
						ticket.changeToDatabaseView();
						break;
					case TicketActionTypes.ticketSelected:
						ticket.selected = true;
						break;
					case TicketActionTypes.ticketUnselected:
						// console.log('ticket', ticket);
						ticket.selected = false;
						break;
					case TicketActionTypes.ticketSelectionDelayed:
						//just select it normally
						if(ticket){
							ticket.selected = true;
							(<any>action.type) = TicketActionTypes.ticketSelected;
							(<any>action.payload) = ticket;
						}
						else{
							this.delayedSelection.set(action.key, action.key);
							failure = true;
						}
						break;
					case TicketActionTypes.ticketSearched:
						ticket.searched = true;
						break;
					case TicketActionTypes.ticketUnsearched:
						ticket.searched = false;
						break;
					case TicketActionTypes.ticketLiveUpdate:
						// debugger;
						ticket.localUpdate(action.payload);
						break;
					case TicketActionTypes.ticketPersistData:
						var updateData = ticket.persistSaveData();
						// console.log(JSON.stringify(updateData, null, 2));
						this.getTicketRef(action.key).update(updateData)
						// .then(()=> this.actionTracker$.dispatchSuccess(action))
						.catch((e)=> { failure = true; this.actionTracker.actionTracker$.dispatchFailure(action, e)})
						break;
					case TicketActionTypes.ticketPersistDataLocalOnly:
						var updateData = ticket.persistSaveData();
						break;
					//this event does not recieve a ticket, but an object instead
					case TicketActionTypes.floatingTicketAdded:
						var floatingTicket = new FloatingTicket(null);
						this.addExtraTicketData(floatingTicket);
						floatingTicket.firebaseUpdate(action.payload);
						
						// console.log('floatingTicket', floatingTicket);
						this._internalList.set(floatingTicket.$id, floatingTicket);
						ticket = floatingTicket;
						break;
					case TicketActionTypes.ticketMovedToFloating:
						if(ticket.isAFloatingTicket){
							this._internalList.delete(ticket.$id);
						}
						else{
							ticket.externalProperties.ticketMovedToFloating = true;
							ticket.recalcHidden();
							// ticket.hidden = true;
							//change the action type (this is the first time we're doing this sort of thing)
							(<any>action.type) = FancyActionTypes.childChanged;
						}
						break;
					case TicketActionTypes.ticketReturnedToPlan:
						ticket.externalProperties.ticketMovedToFloating = false;
						ticket.recalcHidden();
						// ticket.hidden = false;
						break;
					case TicketActionTypes.floatingTicketSaved:
						ticket = action.payload;
						var data = ticket.dumpDatabaseRepresentation();
						var id = (<any>this.listRef).push().key; //no, this does exist angularfire, you're wrong
						
						data.id = id;
						
						var newTicket = this.localAdd(id, data);
						this._internalList.set(id, newTicket);
						ticket = newTicket;
						
						// data.id = id;
						// ticket.$id = id; //this is mainly for benefit of the action return value
						this.listRef.set(id, data)
						.then(()=> this.actionTracker.actionTracker$.dispatchSuccess(action), 
						(e)=> { 
							failure = true; 
							this.actionTracker.actionTracker$.dispatchFailure(action, e);
							
							// probably not necessary, firebase should emit a child_removed anyways
							// this._internalList.delete(id);
							// this.appEvent$.next(new FancyTicketAction(newTicket, FancyActionTypes.childRemoved))
						})
						break;
					case TicketActionTypes.createTicketWithId:
						// ticket = action.payload;
						
						ticket = this.createTicket(action.payload, action.key, action);
						if(action.config.selectImmediately){ ticket.selected = true; }
						break;
							
					case TicketActionTypes.deleteTicket:
						this.listRef.remove(action.key);
						break;
					case TicketActionTypes.removeFloatingTickets:
						var output = [];
						this._internalList.forEach((t, key)=>{
							if(key.includes("float")){
								this._internalList.delete(key);
								this.selectedTicket$.pipe(first()).subscribe((selection)=>{
									t.selected = false;
								})
								output.push(new FancyTicketAction(t, TicketActionTypes.ticketMovedToFloating));
							}
						})
						return from(output);
					case TicketActionTypes.updateDependencyTarget:
						if(!ticket){failure = true; break;} //this can happen on ticket deletion of a dependency target
						ticket.dependencyWeight = action.weightyness;
						break;
					case TicketActionTypes.ticketSavedToFloating: break;
					default: console.log("action "+action.type+": "+action+" is not being handled"); break;
				}
				if(failure){ return empty(); }
				return of(new FancyTicketAction(ticket, action.type));
			})
		)
	}
	
	private createTicket(data, id:string, action?){
		data.id = id;
		
		var newTicket = this.localAdd(id, data);
		this._internalList.set(id, newTicket);
		var ticket = newTicket;
		
		if(!action){ return ticket; }

		this.listRef.set(id, data)
		.then(()=> this.actionTracker.actionTracker$.dispatchSuccess(action), 
		(e)=> {
			this.actionTracker.actionTracker$.dispatchFailure(action, e);
		})
		
		return ticket;
	}
	
	handleSupplementalEvents(baseObservable:Observable<any>):Observable<FancyTicketAction>{
		return baseObservable.pipe(
			groupBy(action => (<any>action).type),
			mergeMap(ob$ => {
				switch(ob$.key){
					case SupplementalActionTypes.planUpdated:
						return ob$.pipe(
							debounce(()=>this.tick500),
							mergeMap(action =>{
								return merge(this.updatePlanData(action.payload), 
									this.updateLongest(action.payload));
							})
						)
					case SupplementalActionTypes.varianceListUpdated:
						//start with something really simple
						return ob$.pipe(
							debounce(()=>this.tick500),
							mergeMap(action =>{
								this.lastVarianceList = action.payload;
								let list = [];
								this._internalList.forEach(t => {
									var update = t.updateVariance(action.payload);
									if(update){list.push(new FancyTicketAction(t, TicketActionTypes.ticketPlanDataUpdated))}
								});
								return from(list);
							})
						)
					case SupplementalActionTypes.sessionSettingsChanged:
						return ob$.pipe(
							mergeMap(action =>{
								return this.updateSessionSettings(action.payload)
							})
						)
					default: return empty();
				}
			}),
			// tap(events => console.log('events', events))
			// debounceTime(500),
			// mergeMap((action:FancySupplementalAction) =>{
			// 	console.log('supped', action, this);
			// 	switch(action.type){
			// 		case SupplementalActionTypes.planUpdated:
			// 			return merge(this.updateLongest(action.payload),
			// 			this.updatePlanData(action.payload));
			// 		case SupplementalActionTypes.varianceListUpdated:
			// 			console.log('supped', action);
			// 			break;
			// 		default: return empty();
			// 	}
			// }),
			// takeUntil(this.shutdownSubject)
		)
	}
	
	
	//this is... not great, currently using the original value to calculate a width, which is used to calculate a start
	//that doesn't work right for non-workdays
	//so...
	//probably going to need to either calculate workdays from the finish, which is inconsistent with other stuff, or don't calculate workdays
	// disabled for now
	/*
	private calculateTicketActuals(actualFinish: string, durationDays: number, roleId: string, originalWidth: number){
		console.log('calculating actuals');
		//no real way to avoid the moment call
		var simpleDays = originalWidth/145;
		var startDate = moment(actualFinish).subtract(simpleDays, "days").format("YYYY-MM-DD");
		// var work = this.workDays.getWorkdayRepresentation(startDate, durationDays, roleId, true);
		var x = this.angularLand.activeColumnService.guessXFromDate(startDate);
		
		return {
			left: x,
			width: 145 * simpleDays
			//width: 145 * work.calendarDays
		}
	}
	*/

	private updateSessionSettings(settings: SessionSettings){
		//use map so all settings can just dump into here and replace if necessary
		let changedTickets = new Map<string, Ticket>();
		if(this.lastSettings.hideCompletedTickets !== settings.hideCompletedTickets){
			
			this._internalList.forEach((tick, key) => {
				tick.externalProperties.shouldHideCompletedTickets = settings.hideCompletedTickets;
				if(tick.recalcHidden()){ changedTickets.set(key, tick); }
			});
		}
		
		this.lastSettings = Object.assign({}, settings);
		
		if(changedTickets.size){
			let outputList:Array<FancyTicketAction> = [];
			changedTickets.forEach((ticket, key)=>{
				outputList.push(new FancyTicketAction(ticket, TicketActionTypes.ticketPlanDataUpdated)); 
			});
			return from(outputList);
		}
		
		return empty();
	}

	private getXFromDate(date:string){
		//this _might_ not be necessary, but it makes it safer
		let safeActiveLine = this.planData ? {x: this.planData.activeLineX, date: this.planData.activeLineDate} : undefined;
		// let safeActiveLine = undefined;
		return this.angularLand.activeColumnService.guessXFromDate(date, undefined, safeActiveLine);
	}
	private isLongestPull(pullColumn: number, plannedStart: string){
		var pullColumns = this.lastPullColumns;
		// debugger;
		return !!(pullColumns && pullColumns[pullColumn]
				&& pullColumns[pullColumn].plannedStart
				&& pullColumns[pullColumn].plannedStart === plannedStart);
	}
	
	private updateLongest(plan:Plan){
		if(!plan){ return empty(); }
		this.lastPullColumns = plan.pullColumns || [];
		let tickets;
		if(this.suppressActionsForLoading){ tickets = this._internalList; }
		else{ tickets = this.pullTicket$.getValue(); }
		
		let changedTickets = [];
		// debugger;
		tickets.forEach((ticket)=>{
			//ticket has been removed and the pullcolumn observable hasn't been updated yet
			if(!this._internalList.has(ticket.$id)){return;}
			// console.log('ffff - does equal', ticket === this._internalList.get(ticket.$id));
			// debugger;
			let newIsLongest = plan && plan.pullColumns && plan.pullColumns[ticket.view.pullColumn]
					&& plan.pullColumns[ticket.view.pullColumn].plannedStart
					&& plan.pullColumns[ticket.view.pullColumn].plannedStart === ticket.view.plannedStart;
			if(newIsLongest && !ticket.isLongestPull){
				ticket.isLongestPull = true; 
				changedTickets.push(new FancyTicketAction(ticket, TicketActionTypes.ticketPlanDataUpdated)); 
			}
			else if(!newIsLongest && ticket.isLongestPull){
				ticket.isLongestPull = false; 
				changedTickets.push(new FancyTicketAction(ticket, TicketActionTypes.ticketPlanDataUpdated)); 
			}
		});
		
		if(changedTickets.length){ return from(changedTickets);	}
		else{ return empty(); }
	}
	
	//these supplemental updates apply ticket changes outside of the normal handling flow,
	// the emit is a simple notification and is otherwise unhandled internally
	private updatePlanData(plan:Plan){
		var usefulProps = ["activeLineDate", "activeLineX"]
		var exitObs;
		
		var oldPlanData = this.planData;
		this.planData = plan;
		
		//interesting data changed
		var partiallyEmpty = !plan != !oldPlanData;
		// if(plan){ console.log('plan update', plan.activeLineX, plan.activeLineDate); }
		if( partiallyEmpty || (oldPlanData && usefulProps.some((key)=>{ return oldPlanData[key] !== plan[key]; }) )){
			var changedTickets = [];
			this._internalList.forEach((ticket)=>{
				if(!plan){ ticket.activeLineX = null; ticket.planHasActiveSpace = false;}
				else{ ticket.activeLineX = plan.activeLineX; ticket.planHasActiveSpace = plan.planHasActiveSpace; }
				let emit = false;
				emit = ticket.recalcSide() || emit;
				emit = ticket.recalcPins() || emit;
				if(emit){
					changedTickets.push(new FancyTicketAction(ticket, TicketActionTypes.ticketPlanDataUpdated));
				}
			})
			exitObs =  from(changedTickets);
		}
		else{ exitObs = empty(); }
		if(plan && this.suppressActionsForLoading){
			this.stopSuppressing$.next(true);
		}
		// this.planData = plan;
		return exitObs;
	}
	
	protected getTicketRef(ticketId){
		return this.listRef.query.ref.child(ticketId);
	}
	
	startTimerTest(time = 1){
		var timer = this.rawEvent$.pipe(
			bufferTime(time),
			skipWhile( x => !x.length )
		)
		
		
		timer.pipe(first()).subscribe((x)=>{ console.time('observer ran'); })
		
		var cancel = timer.subscribe((x)=>{
			console.log('tick', x.length);
			if(!x.length){
				console.timeEnd('observer ran');
				return cancel.complete();
			}
		});
	}
	
	
	//put in interface so you can explicitly say you're doing this
	applyJoinData(baseKey: string, child:Ticket, otherChild:any){
		// var oldTicket = child.clone();
		var betterChild = null;
		switch(baseKey){
			case "roleId": betterChild = otherChild; break;
			case "locationId": betterChild = otherChild ? otherChild.data : null; break;
			case "responsibleProjectMemberId": betterChild = otherChild ? otherChild : null; break;
			case "assignedProjectMemberId": betterChild = otherChild ? otherChild : null; break;
			case "priorityId": betterChild = otherChild ? otherChild: null; break;
			default: break;
		}
	
		
		if(Ticket.joinKeyMapping[baseKey] !== undefined){
			var conf = Ticket.joinKeyMapping[baseKey];
			child.view[conf.key] = betterChild;
			if(conf.updateFunctions && conf.updateFunctions.length){
				conf.updateFunctions.forEach((f)=>{ child[f](); })
			}
		}
		
		child.populatePrev(baseKey);
	}
	getDataForKey(baseKey: string, child:Ticket){
		return child.view[baseKey];
	}
	getPrevDataForKey(baseKey: string, child:Ticket){
		return child.prevData[baseKey];
	}
	
	destroy(){
		this.workDays = null;
		this.lastVarianceList = null;
		
		this.selectedTicket$.complete(); this.selectedTicket$ = null;
		this.pullTicket$.complete(); this.pullTicket$ = null;
		this.editTicket$.complete(); this.editTicket$ = null;
		
		this._internalList.forEach( t => this.$$removed(t) );
		
		super.destroy();
		this.soakedRaw$ = null;
		
	}
}
