import * as angular from "angular";
/**
 * @namespace ColorPicker
 * @desc A Color Picker based on the Spectrum library (https://github.com/bgrins/spectrum)
 * @memberOf Directives
 * @returns {Directives.ColorPicker}
 * @constructor
 */
angular.module('ticketspaceApp').directive("colorPicker", ColorPicker);

function ColorPicker(){
	return {
		restrict: 'EA',
		require: '?ngModel',
		scope: {
			usedColors: '=?',
			fallbackValue: '=?',
			autoShowValue: '=?',
			colorBucketPosition: '=?',
			showAddColorButton: '=?',
			showPicker: '=?',
			disabled: '=?',
			format: '=?',
			options: '=?',
			triggerId: '@?',
			palette: '=?',
			onChange: '&?',
			onShow: '&?',
			onHide: '&?',
			onMove: '&?',
			onChoose: '&?',
			onCancel: '&?',
			onClose: '&?',
			onAddColor: '&?',
			onPaletteChange: '&?',
			onBeforeShow: '&?',
			onChangeOptions: '=?',
			onShowOptions: '=?',
			onHideOptions: '=?',
			onMoveOptions: '=?',
			labelText: '=?',
			addColorText: '=?'
		},
		replace: true,
		template: require('tpl/directive/color-picker.html'),
		link: function($scope, $element, attrs, $ngModel){
			var handlerNameDictionary = {
				'change': 'onChange',
				'move': 'onMove',
				'hide': 'onHide',
				'show': 'onShow'};
			var $input = $element.find('input');
			function format(tiny){
				return (tiny) ? tiny.toString($scope.format) : tiny
			}

			function callOnChange(color){
				if (angular.isFunction($scope.onChange)){$scope.onChange({color: color});}
			}

			function setViewValue(color){
				var newValue = $scope.fallbackValue;
				if (color){
					newValue = format(color);
				} else if (angular.isUndefined($scope.fallbackValue)){
					newValue = color;
				}
				$ngModel.$setViewValue(newValue);
				callOnChange(newValue);
			}

			function onChange(color){
				$scope.$evalAsync(setViewValue.call(this, color));
			}

			function onToggle(){
				$input.spectrum('toggle');
				return false;
			}

			function iterateHandlers(handlerName, eventName){
				function eventHandler(color){
					if (!spectrumEventHandlerOptions || spectrumEventHandlerOptions.update){
						onChange(color);
					}
					if (handlerName !== 'change' && angular.isFunction($scope[handlerName])){
						return $scope[handlerName]({color: format(color)});
					} else {
						return null;
					}
				}
				var spectrumEventHandlerOptions = $scope[handlerName + 'Options'];
				localOpts[eventName] = eventHandler;
			}

			function beforeShowHandler(color){
				return $scope.onBeforeShow({color: format(color)});
			}

			function modelRender(){
				$input.spectrum('set', $ngModel.$viewValue || '');
				callOnChange($ngModel.$viewValue);
			}

			function disableWatcher(newDisabled){
				$input.spectrum(newDisabled ? 'disable' : 'enable');
			}

			function paletteWatcher(palette){
				$input.spectrum('option', 'palette', palette);
			}

			function autoShowWatcher(value){
				if (value){
					$input.spectrum('option', 'forceShow', true);
					$input.spectrum('show');
				}else{
					$input.spectrum('option', 'forceShow', false);
					$input.spectrum('hide');
				}
				return false
			}

			function showAddColorButtonWatcher(newVal){$input.spectrum('option', 'showAddColorButton', newVal);}

			function colorBucketPositionWatcher(newVal){$input.spectrum('option', 'specialIndex', newVal);}

			function showPickerWatcher(newVal){$input.spectrum('option', 'showPaletteOnly', newVal);}

			function usedColorsWatcher(colorList){$input.spectrum('option', 'usedColors', colorList);}

			function paletteChangedWatcher(color){$scope.onPaletteChange(color);}

			function scopeDestroyHandler(){
				$input.spectrum('hide');
				if ($scope.triggerId){angular.element(document.body).off('click', '#' + $scope.triggerId, onToggle);}
				if (unwatchDisabled){unwatchDisabled();}
				if (unwatchPalette){unwatchPalette();}
				if (unwatchUsedColors){unwatchUsedColors();}
				if (unwatchAutoshow){unwatchAutoshow();}
				if (unwatchColorBucketPosition){unwatchColorBucketPosition();}
				if (unwatchShowPicker){unwatchShowPicker();}
				if (unwatchShowAddColor){unwatchShowAddColor();}
				$input.spectrum('destroy');
			}

			var baseOpts = {color: $ngModel.$viewValue};
			var localOpts:any = {};

			angular.forEach(handlerNameDictionary, iterateHandlers);

			if (angular.isFunction($scope.onBeforeShow)){localOpts.beforeShow = beforeShowHandler;}

			if (angular.isFunction($scope.onPaletteChange)){localOpts.onPaletteChange = paletteChangedWatcher;}

			if (angular.isFunction($scope.onChoose)){localOpts.onChoose = $scope.onChoose;}

			if (angular.isFunction($scope.onCancel)){localOpts.onCancel = $scope.onCancel;}

			if (angular.isFunction($scope.onClose)){localOpts.onClose = $scope.onClose;}

			if (angular.isFunction($scope.onAddColor)){localOpts.onAddColor = $scope.onAddColor;}

			if ($scope.palette){localOpts.palette = $scope.palette;}

			if ($scope.colorBucketPosition){localOpts.specialIndex = $scope.colorBucketPosition;}

			if ($scope.usedColors){localOpts.usedColors = $scope.usedColors;}
			var options = angular.extend({}, baseOpts, $scope.options, localOpts);

			if ($scope.triggerId){angular.element(document.body).on('click', '$' + $scope.triggerId, onToggle);}

			$ngModel.$render = modelRender;

			if (options.color){
				$input.spectrum('set', options.color || '');
				setViewValue(options.color);
			}
			$input.spectrum(options);

			if (angular.isDefined(options.disabled)){$scope.disabled = !!options.disabled;}

			// Bind listeners to the appropriate destroy handlers.
			$scope.$on('$destroy', scopeDestroyHandler);
			// Create watchers
			var unwatchDisabled = $scope.$watch('disabled', disableWatcher);
			var unwatchPalette = $scope.$watch('palette', paletteWatcher, true);
			var unwatchAutoshow = $scope.$watch('autoShowValue', autoShowWatcher, true);
			var unwatchColorBucketPosition = $scope.$watch("colorBucketPosition", colorBucketPositionWatcher, true);
			var unwatchShowAddColor = $scope.$watch("showAddColorButton", showAddColorButtonWatcher, true);
			var unwatchShowPicker = $scope.$watch('showPicker', showPickerWatcher, true);
			var unwatchUsedColors = $scope.$watch('usedColors', usedColorsWatcher, true);
		}
	}
}