import { Observable, from } from "rxjs";
import { map } from "rxjs/operators";
import { FancyFirebaseList, FancyAction, FancyActionTypes, FancyJoinRequirements, FancyFragmenter } from "ng2/fancy-firebase/base";
import * as utils from "utils";

import { filter, mergeAll } from "rxjs/operators";

// Literal interface for FancyIdJoin(er)
export interface IFancyIdJoin {
	applyJoinData: (baseKey: string, child: any, otherChild: any) => any;
	getDataForKey: (baseKey: string, child: any) => any;
	getPrevDataForKey: (baseKey: string, child: any) => any;
}

//potential change, switch inline manipuation of base updates to write back onto the list like actions


/**
 * Use to join together multiple [[FancyFirebaseList]]'s.
 * Requires the baseList to implement the [[FancyJoinRequirements]] interface
 * 
 * ```
 * var sourceList = RoleListFactory.create(args);
 * var joiner = new FancyIdJoin("roleId", sourceList);
 * var baseList = TicketListFactory.create(args, join);
 * ```
 */
export class FancyIdJoin<FancyBaseModel, FancySourceModel>{
	 /** the list with the ids */
	baseList: FancyFirebaseList<FancyBaseModel, FancyAction> & FancyJoinRequirements<FancyBaseModel>;
	/** the cache containing the reverse references from sourceList to baseList (maybe use another nested map layer */
	sourceToBaseCache = new Map<string, [string]>(); 
	joinActionId: string;
	ignoreMyself;
	
	
	/**
	* @param baseKey    An id to key the join off of
  * @param sourceList The firebase list containing the source data to be mapped into the base list
	 */
	constructor(
			private baseKey: string,
			private sourceList: FancyFirebaseList<FancySourceModel, FancyAction>,
			private fragmenter?: FancyFragmenter
	){
		this.joinActionId = this.decorateActionId(baseKey);
		this.ignoreMyself = filter( action => (<any>action).type !== this.joinActionId );
		// console.log('joiner', this);
	}
	
	decorateActionId(baseId){
		return "join_action_"+baseId;
	}
	
	//recieve the base list and setup subscriptions
	//
	//pass1: just pass in the whole damn thing
	//pass2: if possible pull back to just the internal list and the update functions
	start(baseList){
		this.baseList = baseList;
		// console.log('start join', this.sourceList);
		var sourceObservable = this.handleSourceObserver(this.sourceList.safeRawEvent$());
		sourceObservable
		.subscribe((action)=>{
			this.baseList.joinEvent$.next(action);
		});
  
	}
	
	 
	
	/**
	 * the place where work is done, handles the firebase (or other? 
	 * maybe just treat as updates?) events and builds/ maintains the cache 
	 * and throws out new base events when changes are detected.
	 * On base these changes get forwarded out to manipBase of this and any other joiners.
	 * @param  obs essentially this.sourceObservable
	 * @return     Another observable to chain off of
	 */
	handleSourceObserver(obs:Observable<FancyAction>){
		return obs.pipe(
			this.ignoreMyself,
			map((action:FancyAction)=>{
				
				var cacheList = this.sourceToBaseCache.get(action.key);
				
				// commenting this out for now, it seems... incorrect to do this
				// if(action.type === FancyActionTypes.childRemoved){
				// 	this.sourceToBaseCache.delete(action.key);
				// }
				// console.log('source earlier', cacheList, action);
				return {cacheList: cacheList, sourceAction: action}
			}),
			filter( enhancedAction => enhancedAction.cacheList !== undefined && !!enhancedAction.cacheList.length),
			map((enhancedAction)=>{
				// console.log('join source obs');
				//TODO - there's probably a pure observable chain way to do this
				var newList = enhancedAction.cacheList.reduce((arr, key)=>{
					if(this.baseList._internalList.has(key)){
						var baseChild = this.baseList._internalList.get(key);
						if(baseChild){
							// console.log('source', this.baseKey, baseChild, enhancedAction.sourceAction.payload);
							var oldChild = this.baseList.applyJoinData(this.baseKey, baseChild, enhancedAction.sourceAction.payload); //NEW THING REMOVED, MAY BREAK EVERYTHING
							var action = new FancyAction(baseChild, this.joinActionId, key);
							arr.push(action);
						}
					}
					else{console.log('was breaking here');}
					return arr;
				}, []) || [];
				return from(newList); 
			}),
			mergeAll()
		)
		
		
		
	}

	/**
	 * unnecessary update culling
	 */
	private hasIdChanged(action:FancyAction){
		var baseChild = action.payload;
		var sourceId = this.baseList.getDataForKey(this.baseKey, baseChild);
		var oldSourceId = this.baseList.getPrevDataForKey(this.baseKey, baseChild);
		var oldSourceChild = this.sourceList._internalList.get(oldSourceId);
		// return true;
		//it changed if the base was deleted
		if(!this.baseList._internalList.has(action.key) && this.sourceToBaseCache.has(oldSourceId)){
			return true;
		}
		//it changed if the source was deleted
		if(oldSourceId && !this.sourceList._internalList.has(oldSourceId)){
			return true;
		}
		
		return sourceId !== oldSourceId;
	}
	
	/**
	 * Causes the base list to be manipulated inline with the source data without
	 * calling any additional events. Should likely only ever be called by the base
	 * [[FancyFirebaseList]]
	 */
	manipBase(action:FancyAction){
		if(this.baseList === null){ return; } //destroyed async stuff
		// if(action.type === this.joinActionId){ return; } //self referencing fix for the "placeholder list" concept
		// console.log('all', action); 
		if(!this.hasIdChanged(action)){return;}
		// console.log('handle me!', action); 
		// if(action.key === "-float-1"){
		// 	debugger;
		// }
		//use action and getDataForKey, to get sourceId
		var baseChild = action.payload;
		var sourceId = this.baseList.getDataForKey(this.baseKey, baseChild);
		//remove from cache if the removed type
		// if(action.type === FancyActionTypes.childRemoved){
		// if(this.baseRemovalActions[action.type]){
		if(this.baseList.isRemoveAction(action)){
			this.removeFromCache(sourceId, action.key);
			//remove the fragmenter if the cache has been emptied
			if(this.fragmenter && this.fragmenter.has(sourceId) && !this.sourceToBaseCache.has(sourceId)){
				this.fragmenter.remove(sourceId);
			}
			// return undefined;
		}
		else{
			var sourceChild = this.sourceList._internalList.get(sourceId);
			var oldSourceId = this.baseList.getPrevDataForKey(this.baseKey, baseChild);
			var oldSourceChild = this.sourceList._internalList.get(oldSourceId);
			this.baseList.applyJoinData(this.baseKey, baseChild, sourceChild);
			
			if(oldSourceId){
				this.removeFromCache(oldSourceId, action.key);
			}
			
			this.addToCache(sourceId, action.key);
			
			if(this.fragmenter){
				//add the new one if it's not there
				if(sourceId && !this.fragmenter.has(sourceId)){
					this.fragmenter.add(sourceId);
				}
				//remove it if that fails, and it's undefined (may not need this one) and was previously there and if the cache is empty for that source value
				else if(!sourceChild && this.fragmenter.has(sourceId) && !this.sourceToBaseCache.has(sourceId)){
					this.fragmenter.remove(sourceId);
				}
				//remove the old one
				if(!oldSourceChild && this.fragmenter.has(oldSourceId) && !this.sourceToBaseCache.has(oldSourceId)){
					this.fragmenter.remove(oldSourceId);
				}
				//doing this a second time mainly so that there's a syncronous path (currently just for testing)
				// this.baseList.applyJoinData(this.baseKey, baseChild, this.sourceList._internalList.get(sourceId));
			}
			
			// if(sourceChild){
				// this.addToFragmenterIfNotThere(sourceId);
			// }
			
			
			
			
			// if(this.fragmenter && sourceId){
			// 	this.fragmenter.add(sourceId);
			// }
			// this.addToFragmenterIfNotThere(sourceId);
			//RIGHT HERE, this is why constraints are broken
			// if(sourceChild){
				// this.addToCache(sourceId, action.key);
			// }
		
		}
	}
	
	private addToFragmenterIfNotThere(sourceId){
		if(!this.sourceToBaseCache.has(sourceId) && this.fragmenter){
			this.fragmenter.add(sourceId);
		}
	}
	
	private addToCache(sourceId, baseId){
		if(!sourceId || !baseId){ return; }
		if(!this.sourceToBaseCache.has(sourceId)){
			this.sourceToBaseCache.set(sourceId, [baseId]);
			// if(this.fragmenter){ this.fragmenter.add(sourceId); }
		}
		else{ this.sourceToBaseCache.get(sourceId).push(baseId); }
		// console.log('add to cache', sourceId, baseId, this.sourceToBaseCache);
	}
	private removeFromCache(sourceId, baseId){
		var arr = this.sourceToBaseCache.get(sourceId);
		if(!arr || !arr.length){return;}
		var index = arr.indexOf(baseId);
		if(index === -1){ return; }
		utils.fastSplice(arr, index, 1);
		if(!arr.length){
			// if(this.fragmenter){ this.fragmenter.remove(sourceId); }
			this.sourceToBaseCache.delete(sourceId);
		}
		// console.log('remove from cache', sourceId, baseId, this.sourceToBaseCache);
		//arr.splice(index, 1);
	}
	
	public destroy(){
		this.sourceList = null;
		this.baseList = null;
		this.sourceToBaseCache = null;
		if(this.fragmenter){ this.fragmenter.destroy(); this.fragmenter = null; }
	}
	
}
