import { FancySprite } from "../graphics/FancySprite";
import { Texture, utils } from "pixi.js";
import { analyticsService } from "ng2/common/utils/AnalyticsService";
import { LEVEL_OF_DETAIL_TABLE } from "../core/LevelOfDetail";
import { TICKET_TEXTURE_OPTIONS } from "../tickets/";
import { PinnedCount } from "./PinnedCount";
import { TICKET_LAYERS } from "../layers/";

import { EventBinder } from "ng2/canvas-renderer/interaction/interaction-utils";
/**
 * The Pin class is created and managed by the FancyPinHistory.
 */
export class Pin extends FancySprite {
	/**
	 * Stores the reference to the Pin FancySprite
	 */
	private _pin: FancySprite;
	/**
	 * Stores the reference to the Pin border FancySprite
	 */
	private _pinBorder: FancySprite;
	
	private binder:EventBinder;
	
	public variance?: string;
	/**
	 * @param {PIXI.Texture} texture The Pin background texture
	 */
	constructor(texture: Texture){
		super(texture);
		// Set the LOD Level for this graphic
		//this.lodLevel = LEVEL_OF_DETAIL_TABLE.MEDIUM;
		// All assets are created at 3x scale. So we scale down the dimensions so it is to scale with the plan
		// SCALE IS SET IN FancyPinHistory now
		// this.scale.x = 0.33;
		// this.scale.y = 0.33;
		this.width = 49;
		this.height = 49;
		this.interactive = true;
		this.cursor = "pointer";
		
		this._setupTicketPin();
	}
	/**
	 * Creates the Ticket Pin and Ticket Border and adds it as a child to the pin background
	 */
	private _setupTicketPin(): void {
		this._pin = new FancySprite(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_PINNED_NAMED]);
		this._pin.x = 28;
		this._pin.y = 18;
		this._pin.visible = true;
		this._pin.active = true;
		//this._pin.zIndex = TICKET_LAYERS.ABOVE_TICKET;
		
		this._pinBorder = new FancySprite(utils.TextureCache["ticket-pinned-border.png"]);
		//this._pinBorder.zIndex = TICKET_LAYERS.ABOVE_TICKET;
		this._pinBorder.x = 1;
		this._pinBorder.y = 1;
		this._pinBorder.visible = true;
		this._pinBorder.active = true;
		
		this.binder = new EventBinder(this);
		this.binder.add('hammer-singletap', ()=>{
			if(this.variance){ Logging.notice(this.variance); }
			analyticsService.promisePinClickedOnPlan(this.variance);
		});
		
		this.addChild(this._pin, this._pinBorder);
	}
	/**
	 * Called when the Pin should be destroyed.
	 * @param {Object | boolean} options
	 */
	public destroy(options): void {
		this._pin.destroy(options);
		this._pinBorder.destroy(options);
		this.binder.destroy();
		super.destroy(options);
	}
}
