'use strict';

import * as utils from "utils";
import * as moment from "moment";

import Spread from "js/lib/ui/Spread";
import AnimationQueue from "js/lib/ui/AnimationQueue";
import Redom from "js/lib/ui/Redom";

var indicatorArrowSrc = require("images/indicator-arrow.png");
var milestoneIconSrc = require("images/milestone-icon.png");


export default function Timeline(scope, options){/*
	Dynamic timline ui element. Tracks column groups and displays an appropriate date
	
	new Timeline(scope, {$overlay, $underlay, columnWidth, columnMinWidth, padding})
	.scope = <requires .hud, .items>
	.columns = [{left, right, date}[n]]
	.overlay = $overlay
	.underlay = $underlay
	.columnWidth = Number
	.columnMinWidth = Number
	.padding = Number
	.recalc() -> void // update all data
	.update() -> void // trigger redraw
	*/
	this.initialize.apply(this, arguments);
}

Timeline.prototype = {
	initialize: function(scope, options) {
		options = options || {};
		this.scope = scope;
		this.hud = this.scope.hud;
		this.columns = [];
		this.observers = [];
		this.dateCache = {};
		this.overlay = options.overlay || this.scope.hud.overlay.find(".timeline");
		this.underlay = options.underlay || this.scope.hud.underlay.find(".ui");
		this.columnWidth = options.columnWidth || 330;
		this.columnMinWidth = options.columnMinWidth || 75;
		this.padding = options.padding || 10;
		this.columnLayout = new Spread("finishX");
		this._observers = [];
		
		var self = this;
		
		this.redom = {
			column: {
				marker: new Redom("<div class='column-marker'>"),
				indicator: new Redom("<img src='"+indicatorArrowSrc+"' alt='Indicator Arrow' style='width: 11px; height: 7px;' />"),
				label: new Redom("<span class='column-marker'>")
			},
			milestone: {
				marker: new Redom("<div style='overflow:hidden; height:900px; width:20px;' class='event-marker' data-element-type='milestone'><div class='milestone-line'><div class='milestone-name'><span class='column-marker'></span></div></div></div>"),
				label: new Redom("<span class='column-marker'>"),
				indicator: new Redom("<img src='"+milestoneIconSrc+"' alt='MIcon' style='width: 20px; height: 30px; position:absolute;' />")
			}
		};
		
		this.calcX = function(x){
			return x * this.z + this.x;
		}
		
		
		this.updater = utils.bind(this, function(){
			if(this.hud.isDestroyed){ return; }
			var x = this.hud.x;
			var z = this.hud.z;
			this.columns = this.columnLayout.spread(
				this.scope.pullColumns, // column list
				-x/z, // drawing bound, left
				Math.ceil(this.hud.w/z) + this.columnWidth, // drawing bound, width
				this.columnMinWidth/z // // drawing bound, min column spacing
			)
			var nColumns = this.columns.length;
					
			var columnMarkers = this.redom.column.marker.get(this.scope.pullColumns.length)
			var columnIndicators = this.redom.column.indicator.get(nColumns)
			var columnLabels = this.redom.column.label.get(nColumns)
		
			var nMilestones = 1;
			var milestoneMarkers = this.redom.milestone.marker.get(nMilestones)
			var milestoneLabels = this.redom.milestone.label.get(nMilestones)
			var milestoneIndicators = this.redom.milestone.indicator.get(nMilestones)
			
			//hack all the things
			// just break the lines specifically out of the other iterator so they don't get spreaded
			this.scope.pullColumns.forEach(function(column,key){
				var xx = column.finishX * z + x; // column position
				
				//milestone is drawn elsewhere
				if(scope.milestoneX && column.finishX === scope.milestoneX.$value){ return; }
				
				// draw normal column
				var jmarkerNode = columnMarkers.shift();			
				var markerNode = jmarkerNode[0];
				
				if(this.scope.activeLine && this.scope.activeLine.$value !== null){
					if(xx < this.scope.activeLine.$value * z+x){
						jmarkerNode.detach();
						return;
					}
				}
				
				markerNode.style.left = xx + 'px';
				this.underlay[0].appendChild(markerNode);	
			}, this);

			this.columns.forEach(function(column,key){
			
				var label = column.plannedFinish && this.dateCache[column.plannedFinish] ? this.dateCache[column.plannedFinish] : "";
				var xx = column.finishX * z + x; // column position
				
				//milestone is drawn elsewhere
				if(scope.milestoneX && column.finishX === scope.milestoneX.$value){ return; }
				
				// draw normal column
				var jlabelNode = columnLabels.shift();						
				var jindicatorNode = columnIndicators.shift();
				var labelNode = jlabelNode[0];						
				var indicatorNode = jindicatorNode[0];
				
				if(this.scope.activeLine && this.scope.activeLine.$value !== null){
					if(xx < this.scope.activeLine.$value * z+x){
						jlabelNode.detach();
						jindicatorNode.detach();
						return;
					}
				}
				
				labelNode.innerHTML = label;
				labelNode.style.left = xx - this.columnMinWidth + 'px';
				labelNode.style.width = this.columnMinWidth + 'px';
				indicatorNode.style.left = xx - 5 + 'px';
				
				this.overlay[0].appendChild(labelNode);
				this.underlay[0].appendChild(indicatorNode);							
			}, this);

			this.columns.sort(function(a, b) {
				a = new Date(a.plannedFinish);
				b = new Date(b.plannedFinish);
				return a>b ? -1 : a<b ? 1 : 0;
			});

			[columnLabels, columnMarkers, columnIndicators, milestoneLabels, milestoneMarkers, milestoneIndicators]
			.forEach(this.detachUnusedNodes, this);
		});
		scope.orderedExecution.add("timeline", utils.bind(this, function(){ this.update() }));
		
		
		scope.shiftApi.on("shift", function(x, activeLineX, side){
			if(side === "pull"){
				self.scope.pullColumns.forEach(function(column){
					column.finishX += x;
				});
				self.update();
			}
		});
	},

	recalc: utils.soak(function(){	
		this.cacheColumnDates();
		this.update()
		var self = this;
		//voodoo that fixes trying to change a dom node before it exists
		/*
		setTimeout(function(){
			$(".event-marker .column-marker").html(self.scope.milestone.activityName);
		}, 1000);
		*/
		this.observers.forEach(function(fn) { fn() });
	}, 1000),
	
	cacheColumnDates: function(){
		if(!this.dateCache){ this.dateCache = {}; }
		
		utils.$loop(this.scope.pullColumns, function(column, key){
			if(column.pullColumn !== undefined && column.plannedFinish){
				var date = moment(column.plannedFinish, "YYYY-MM-DD", true)
				if(date.isValid()){
					var label = date.format("MMM DD YYYY");
					this.dateCache[column.plannedFinish] = label;
				}
			}
		}, this);
	},
	
	update: function (){
		AnimationQueue(this.updater)
	},
	
	shift: function(x){
		this.scope.pullColumns.forEach(function(column){
			column.finishX += x;
		});
		this.update();
	},
	
	detachUnusedNodes: function(nodeList) { 
		if(nodeList && nodeList.length > 0){
			nodeList.forEach(this.detachUnusedNode, this)
		}
	},
	detachUnusedNode: function(node) { node.detach() },
	
	subscribe: function subscribe(fn){
		this._observers.push(fn);
	},
	observe: function observe(cols){
		var self = this;
		this._observers.forEach(function(fn){
			fn(cols);
		});
	}
	
	
};
