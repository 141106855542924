'use strict';

import * as angular from "angular";

angular.module('ticketspaceApp.dev-settings', [])

.constant('firebaseRefUrl', MB4_PREPROCESSOR_CONFIG.FBPATH)
.constant('firebaseFullPath', MB4_PREPROCESSOR_CONFIG.FBHOST + MB4_PREPROCESSOR_CONFIG.FBPATH)
.constant('developerMode', MB4_PREPROCESSOR_CONFIG.ENABLE_DEV_MODE)
.constant('defaultUserTier', MB4_PREPROCESSOR_CONFIG.DEFAULT_USER_TIER)
.constant('touchcoreServer', MB4_PREPROCESSOR_CONFIG.TOUCHCORE_SERVER)
.value('git', []);
