import { Injectable } from '@angular/core';
import { Router, Resolve, CanActivate, CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { RallyPointSignupService, acceptedFields } from "ng2/common/services/rally-point-signup.service";

import * as utils from "utils";

import {RallyEmailComponent, RallySignupComponent, RallyLoginComponent} from "./";

// import { AppState } from "ng2/common/services/app-state.service";
// 
// import { User } from "ng2/common/models/User";
// import { AuthService } from "ng2/common/ajs-upgrade-providers";

 
@Injectable()
export class RallyPointResolverService implements Resolve<any>, CanActivate, CanActivateChild {
	constructor(
		private router: Router, 
		private rallyPointSignupService: RallyPointSignupService
		// private authService: AuthService
	){
		
	}
	
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		console.log('resolve');
	
		// return obj;
	}
	
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log('canActivateChild', route, state);
		
		let rallyState = this.rallyPointSignupService.rallyStateSync;
		let isCodeValid = rallyState.accessCode && rallyState.accessCodeData.$value !== null && rallyState.accessCodeData._isValid;
		
		if(!isCodeValid && route.component === RallyEmailComponent){
			this.router.navigateByUrl(utils.parseUrl(state.url, '../start'));
			return false;
		}
		if(route.component === RallySignupComponent || route.component === RallyLoginComponent){
			if(!isCodeValid){
				this.router.navigateByUrl(utils.parseUrl(state.url, '../start'));
				return false;
			}
			if(!rallyState.email){
				this.router.navigateByUrl(utils.parseUrl(state.url, '../email'));
				return false;
			}
		}
		
		// debugger;
		return true;
	}
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log('canActivate');
		
		let obj:any = {};
		acceptedFields.forEach(key => {
			let val = route.paramMap.get(key);
			if(val){ obj[key] = val; }
		})
		
		this.rallyPointSignupService.patchState(obj);
		
		return (()=>{
			let data = this.rallyPointSignupService.rallyStateSync;
			if(!data.accessCodeData && data.accessCode){ return this.rallyPointSignupService.getAccessCodeData(data.accessCode) }
			return Promise.resolve(true);
		})()
		.then(()=> {
			console.log('canActivate resolved')
			return true; //reverse true
		});
	}
	
	redirectToLogin(){
		this.router.navigate(['/login']);
	}
}
