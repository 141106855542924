'use strict';
import * as angular from "angular";

/*
	Tracks which 'view' window has focus.
 */
 angular.module('ticketspaceApp')
.factory('focusManager', function(){
	var registrations = [
		'plan',
		'projectView'
	];
	var currentFocus = 'plan';

	function setFocus(focusValue){
		return currentFocus = focusValue;
	}

	function get(){
		return currentFocus
	}

	function isValid(focusValue){
		return (registrations.indexOf(focusValue) >= 0)
	}

	function update(newFocusValue){
		if (newFocusValue !== currentFocus && isValid(newFocusValue)){return setFocus(newFocusValue)}
		else {return currentFocus}
	}

	function match(matchWith){
		return (currentFocus === matchWith)
	}

	return {
		set: setFocus,
		get: get,
		update: update,
		match: match
	}
});
