import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from "@angular/forms";
import { IColorPickerOptions, ColorPickerComponent, IUsedColors } from "./color-picker.component";
import * as tinycolor from "tinycolor2";

const defaultColorPicketPalleteOptions: IColorPickerOptions = {
	showAlpha: false,
	preferredFormat: 'hex',
	showPalette: true
};

@Component({
	selector: 'color-picker-with-palette',
	template: require('./color-picker.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ColorPickerWithPaletteComponent extends ColorPickerComponent {
	
	@Input() palette: [[string]];
	@Input() usedColors: IUsedColors;
	@Input() options: IColorPickerOptions = defaultColorPicketPalleteOptions;
	@Output() paletteChange: EventEmitter<FormControl> = new EventEmitter();
	
	public paletteColor: FormControl;
	
	constructor() {
		super();
		
		this.paletteColor = new FormControl('');
	}
	
	protected _onPaletteChange(e, tiny: tinycolor): void {
		this.paletteColor.setValue(tiny);
		
		this.paletteChange.emit(this.paletteColor);
	}
	
	public ngOnInit(): void{
		this.options["palette"] = this.palette;
		this.options["usedColors"] = this.usedColors;
		super.ngOnInit();
		
		this._input.on('paletteChange.spectrum', (e, tiny: tinycolor) => this._onPaletteChange(e, tiny));
	}
}
