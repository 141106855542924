import { Injectable } from '@angular/core';
import { Router, Resolve, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { User } from "ng2/common/models/User";
import { AuthService } from "ng2/common/ajs-upgrade-providers";

 
@Injectable()
export class NotAuthenticatedGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService){
	}
	
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>{
		return this.authService.newWaitForSignIn().then((auth)=>{
			if(auth){ this.redirectToDashboard();	return false; }
			else{ return true; }
		});
	}
	
	redirectToDashboard(){
		this.router.navigate(['/projects']);
	}
}
