import * as angular from "angular";

angular.module('CanvasWrapper').factory("InteractionHover", HoverFactory);
/**
 * @namespace Hover
 * @returns {CanvasWrapper.Interaction.Hover}
 * @memberOf CanvasWrapper.Interaction
 * @constructor
 */
HoverFactory.$inject = ["InteractionHandleEvent"];
function HoverFactory(InteractionHandleEvent){
	var handleEvent = InteractionHandleEvent;
	function Hover(displayNode){
		function pointerOver(){
			handleEvent(displayNode).emit('hover');
		}

		function pointerOut(){
			handleEvent(displayNode).emit('hoverexit');
		}

		handleEvent(displayNode).on('mouseover', pointerOver);
		handleEvent(displayNode).on('pointerover', pointerOver);
		handleEvent(displayNode).on('mouseout', pointerOut);
		handleEvent(displayNode).on('pointerout', pointerOut);

		return handleEvent(displayNode);
	};

	return Hover
}
