import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { take, mapTo, tap, skip } from "rxjs/operators";

import { renderQueue } from "ng2/common/RenderQueue";
 
@Injectable()
export class WaitForFrameGuard implements  CanActivate {
	constructor(){}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		// return renderQueue.requestAnimationFramePromise();
		return renderQueue.ob$.pipe(
			skip(1),
			take(1), 
			mapTo(true),
			tap(thing=> console.log('frame framed'))
		);
	}
}
