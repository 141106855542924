'use strict';
import * as angular from "angular";

//!!!!
// tickets are calling .selected() in an ng-repeat, bloody huge performance hit...fix that

angular.module('ticketspaceApp')
.factory('SelectionCollection2', ["Subscriber2", function(Subscriber2) {
	// this bog standard "new" syntax javascript style appears to be the most testable, 
	// so...what the hell, why not. Onwards with no privacy!
	
	
	//...and yet, privacy out of nowhere
	function canAdd(self, item){
		return true;
		// return self._subscriber.emit('pre-add', item, self.selectedItems);
	}
	
	function SelectionCollection2(scope){
		this.selectedItems = [] // Current items
		
		this._scope = scope;
		this._subscriber = new Subscriber2();
		this.on = this._subscriber.on.bind(this._subscriber);
		
		this.completedList = {};
		this.completedCount = 0;
		
	}
	SelectionCollection2.prototype = {
		"add": function add(item, noApply){
			if(this.selectedItems.indexOf(item) === -1 && canAdd(this, item)){
				this.selectedItems.push(item);
				
				this.completedIsFine = false;
				//adding a reliance on ticket data, this code is almost dead
				if(item && item.data && item.data.actualFinish){
					this.completedList[item.$id] = item;
					this.completedCount++;
				}
			}
			if(!noApply){ this._scope.$applyAsync(); }
		},
		"remove": function(item, noApply){
			if(!this.selectedItems.length){return;}
			var found = false;
			if((found = this.selectedItems.indexOf(item)) !== -1){
				this.selectedItems.splice(found, 1)
				this.completedIsFine = false;
				//adding a reliance on ticket data, this code is almost dead
				if(item && item.data && item.data.actualFinish){
					delete this.completedList[item.$id];
					this.completedCount--;
				}
			}
			if(!noApply){ this._scope.$applyAsync(); }
		},
		"selected": function(item){
			return !item ? false : this.selectedItems.indexOf(item) !== -1;
		},
		"toggle": function(item, noApply){
			if(this.selected(item)){ this.remove(item, true); }
			else{ this.add(item, true); }
			if(!noApply){ this._scope.$applyAsync(); }
		},
		"count": function(){ return this.selectedItems.length; }, // pretty damn pointless
		"isEmpty": function(){ return !this.selectedItems.length; },
		"empty": function(noApply){
			this.selectedItems = [];
			
			this.completedList = {};
			this.completedCount = 0;
			
			if(!noApply){ this._scope.$applyAsync(); }
		},
		"removeCompleted": function removeCompleted(){
			for(var key in this.completedList){
				this.remove(this.completedList[key], true);
			}
			this._scope.$applyAsync();
		},
		"addList": function addList(list, union){
			if(list){
				if(!union){
					this.empty()
				}
				list.forEach(function(item){ this.add(item, true) },this)
				this._scope.$applyAsync();
			}
		}
	};
	return SelectionCollection2;

}]).run(["SelectionCollection2", function(SelectionCollection2){}]);
