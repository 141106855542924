'use strict';
import * as angular from "angular";
import * as utils from "utils";

angular.module('ticketspaceApp')
.factory("appHelp", ["$log", function($log) {
	//maps names to files
	var _urlMap = {
		dashboard: 'help/index.html',
		dashboardMembers: 'help/add-project-members.html',
		dashboardPlans: 'help/create-a-plan.html',
		dashboardReports: 'help/create-reports.html',
		dashboardRoles: 'help/assign-project-roles.html',
		login: 'help/login.html',
		plan: 'help/pull-plan-overview.html',
		planConstraints: 'help/what-are-constraints.html',
		planConstraintsAdd: 'help/adding-new-constraints.html',
		planDependencies: 'help/defining-dependencies.html',
		planTickets: 'help/edit-tickets.html'
	};
	
	var _default = _urlMap['dashboard'];
	var _selected = _urlMap['dashboard'];
	
	
	function setDefault(name){
		if(name && _urlMap[name]){
			_default = _urlMap[name];
			_selected = _urlMap[name];
		}
		else{
			$log.log("help for: " + name + " doesn't exist");
		}
	}
	
	function set(name){
		if(name && _urlMap[name]){
			_selected = _urlMap[name];
		}
		else{
			$log.log("help for: " + name + " doesn't exist");
		}
	}
	
	function navigate(place){
		if(place){ set(place); }
		window.open(_selected);
	}
	
	function clear(){
		_selected = _default;
	}
	
	return {
		clear: clear,
		set: set,
		setDefault: setDefault,
		navigate: navigate
	}
}]);
	
