import { Texture, Polygon, Point } from "pixi.js";
import { SpriteTicket } from "./";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { MILESTONE_SIZE_OFFSET, MILESTONE_SIZE_OFFSET2 } from "../interfaces/milestone-size.interface";
import { TicketTypes } from "../../common/models/Ticket";
import { ticketSchema } from "../ticket-components/";
import { Side } from "../../common/models/Side";

import { IMAGE_PADDING } from "../interfaces";

export interface ISizeOffset {
	width: number,
	height: number
}

const pullSizeOffset = MILESTONE_SIZE_OFFSET2.PULL;
const activeSizeOffset = MILESTONE_SIZE_OFFSET2.ACTIVE;
/**
 *This class is the main representation of a ticket. It has a simple component api to add individual pieces associated with the same data
 */
export class SpriteMilestone extends SpriteTicket{
	public offsetSize: ISizeOffset = {width: MILESTONE_SIZE_OFFSET.WIDTH, height: MILESTONE_SIZE_OFFSET.HEIGHT};
	public type: string = TicketTypes.MILESTONE;
	/**
	 * @param {PIXI.Texture} ticketShape
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	constructor(ticketShape: Texture, planState: PlanState, ticketContainer: TicketContainer){
		super(ticketShape, planState, ticketContainer);
		
		this.x = -activeSizeOffset / 2 - IMAGE_PADDING.ACTIVE_SHAPE;
		this.y = -activeSizeOffset / 2 - IMAGE_PADDING.ACTIVE_SHAPE;
		
		const data = ticketContainer.getData();
		
		// Add the milstone schema to this ticket
		if (data.isGhost){
			ticketContainer.components.addDynamicComponents(
				ticketSchema.createGhostMilestoneSchema(planState, ticketContainer)
			);
		} else {
			ticketContainer.components.addDynamicComponents(
				ticketSchema.createMilestoneSchema(planState, ticketContainer)
			);
		}
	}
	/**
	 * Called once the data container data changes
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 * @returns {any}
	 */
	public updateData(planState: PlanState, ticketContainer: TicketContainer){
		super.updateData(planState, ticketContainer);
		let data = this.getData();
		let points;
		if (data.view.side === Side.PULL){
			this.offsetSize.width = data.view.width + pullSizeOffset;
			this.offsetSize.height = data.view.height + pullSizeOffset;
			
			this.x = -pullSizeOffset/2 - IMAGE_PADDING.PULL_SHAPE;
			this.y = -pullSizeOffset/2 - IMAGE_PADDING.PULL_SHAPE;
			this.width = this.offsetSize.width + IMAGE_PADDING.PULL_SHAPE*2;
			this.height = this.offsetSize.height + IMAGE_PADDING.PULL_SHAPE*2;
			
			points = [
				new Point(-pullSizeOffset/2, -pullSizeOffset/2 + (this.offsetSize.height / 2)), // left point
				new Point(-pullSizeOffset/2 + (this.offsetSize.width / 2), -pullSizeOffset/2), // top point
				new Point(-pullSizeOffset/2 + this.offsetSize.width, -pullSizeOffset/2 + (this.offsetSize.height / 2)), // right point
				new Point(-pullSizeOffset/2 + this.offsetSize.width / 2, -pullSizeOffset/2 + this.offsetSize.height), // bottom point
			];
		}
		else{
			this.offsetSize.width = data.view.width + activeSizeOffset;
			this.offsetSize.height = data.view.height + activeSizeOffset;
			
			this.x = -activeSizeOffset / 2 - IMAGE_PADDING.ACTIVE_SHAPE;
			this.y = -activeSizeOffset / 2 - IMAGE_PADDING.ACTIVE_SHAPE;
			this.width = this.offsetSize.width + IMAGE_PADDING.ACTIVE_SHAPE*2;
			this.height = this.offsetSize.height + IMAGE_PADDING.ACTIVE_SHAPE*2;
			
			points = [
				new Point(-activeSizeOffset/2, -activeSizeOffset/2 + (this.offsetSize.height / 2)), // left point
				new Point(-activeSizeOffset/2 + (this.offsetSize.width / 2), -activeSizeOffset/2), // top point
				new Point(-activeSizeOffset/2 + this.offsetSize.width, -activeSizeOffset/2 + (this.offsetSize.height / 2)), // right point
				new Point(-activeSizeOffset/2 + this.offsetSize.width / 2, -activeSizeOffset/2 + this.offsetSize.height), // bottom point
			];
		}
		
		ticketContainer.SURFACE.hitArea = new Polygon(points);
		
		return data
	}
}
