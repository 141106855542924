import {utils} from "pixi.js";
import {ComponentManager} from "./";
import {LEVEL_OF_DETAIL_TABLE, LevelOfDetail, LEVEL_OF_DETAIL_SIGNALS} from "../core/";
import {PlanState} from "../../common/services/plan-state.service";
import {ComponentInterface} from "../interfaces/";
/**
 * An abstract class. This class is used as our foundation for all components within the Canvas View renderer area.
 * Most of the methods are stubbed out by default. May expand in future to add more utility/helper methods.
 */
export abstract class Component extends utils.EventEmitter implements ComponentInterface{
	public active: boolean = true;
	/**
	 * Sets this component to active or inactive. An inactive component will never be invoked.
	 */
	public visible: boolean = false;
	/**
	 * This always points to the ComponentManager which is responsible for this component.
	 * Typically, a component is always self-contained. However, there may be cases where a component needs access to another component.
	 * By accessing the common manager, a component can request another component from the same stack.
	 */
	public manager: ComponentManager;
	/**
	 * This always points to the DisplayObject or Object where the ComponentManager is attached to.
	 * This is useful when you are setting up listeners on a parent and need easy access to them later to provide clean-up
	 */
	public managerParent: any;
	/**
	 * This property is used to set the detail level of this component. If the current view level is less than this value,
	 * then this component will never run.
	 */
	public lodLevel: LEVEL_OF_DETAIL_TABLE = LEVEL_OF_DETAIL_TABLE.ALWAYS;
	/**
	 * Reference to the current state of the plan.
	 */
	public planState: PlanState;
	constructor(){
		super();
		LevelOfDetail.emitter.on(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
	}
	/**
	 * @param {number} newLod
	 * @param {number} oldLod
	 */
	public lodChanged = (newLod: number, oldLod: number): boolean => {
		return this.visible = (this.lodLevel <= newLod && this.active);
	};
	/**
	 * Called after the Component has been constructed. At this point, Component.parent and Component.manager should be set and can be safely used.
	 * @param args - The arguments that are being passed into the setup.
	 */
	public setup(...args: Array<any>){}
	/**
	 * Called if the parent component manager has the updateData loop enabled. This is usually called by an outside event.
	 * @param args
	 */
	public updateData(...args: Array<any>){}
	/**
	 * Called during each renderer tick.
	 * @param args
	 */
	public update(...args: Array<any>){}
	/**
	 * Helper method to always return the DisplayObject or Object where the ComponentManager is attached to.
	 */
	public getParent(){
		return this.managerParent
	}
	/**
	 * Always returns the ComponentManager responsible for managing this component.
	 */
	public getManager(){
		return this.manager;
	}
	/**
	 * Called when the component manager has a new component added
	 */
	public componentAdded(...args: Array<any>){}
	
	/**
	 * Called when the component manager has a component removed.
	 * @param args
	 */
	public componentRemoved(...args: Array<any>){}
	/**
	 * Called when a draw event is dispatched.
	 * @param args
	 */
	public draw(...args: Array<any>){}
	public destroy(options?){
		LevelOfDetail.emitter.off(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
	}
}