import { Observable, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import {User} from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";

import { ProjectMember } from "./ProjectMember";

import {FancyAction} from "ng2/fancy-firebase/base";

export class You{
	public auth: User; //TODO - this is not actually user, it's auth
	public data: ProjectMember;
	constructor(user: User, pmData: FancyAction){
		this.auth = user;
		this.data = pmData.payload;
	}
}

export function observeYou(afAuthUser: Observable<User>, pmData: Observable<FancyAction>){
	return combineLatest(afAuthUser, pmData)
	.pipe(
		filter(comb => !comb[0] || comb[0].uid === comb[1].payload.userId),
		map(comb => {
			if(!comb[0]){ return null; }
			return new You(comb[0], comb[1])
		})
	)
}
