import { LEVEL_OF_DETAIL_TABLE } from "../core/";
import { FancySprite } from "../graphics/";
import { Texture, utils, Point } from "pixi.js";
import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { TICKET_LAYERS } from "../layers/";
import { ComponentManager} from "../component-system/";
import { TICKET_TEXTURE_OPTIONS } from "./";
import { rendererInfo, RenderDependencies } from "../core/";
import { WORD_WRAP_MODE } from "../fontpath/WordWrapper";
import { TicketOverlap } from "../ticket-collision/";
// Interaction
import { TicketDrag, TicketOpenEdit, TicketDoubleTap, TicketHold } from "../interaction";
/**
 * This class is the main representation of a ticket. It has a simple component api to add individual pieces of code as children.
 * Further, it adds some common components for each of the current ticket types.
 *
 * The SpriteTask, SpriteConstraint and SpriteMilestone all extend from this base class
 */
export class SpriteTicket extends FancySprite implements ComponentInterface{
	public active: boolean = true;
	public visible: boolean = true;
	public manager: ComponentManager;
	public managerParent: any;
	public alwaysActive: boolean = true;
	public type: string;
	public overlap: any;
	public collidingWith: Map<SpriteTicket, SpriteTicket> = new Map();
	public collidingOverlaps: Map<SpriteTicket, TicketOverlap> = new Map();
	public enableCollisions: boolean = true;
	public textureOffset = new Point(0,0);
	public ticketContainer: TicketContainer;
	/**
	 * @param {PIXI.Texture} ticketShape
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	constructor(ticketShape: Texture, planState: PlanState, ticketContainer: TicketContainer){
		super(ticketShape);
		
		this.ticketContainer = ticketContainer;
		ticketContainer.sprite = this;
	}
	/**
	 * Returns the DataContainer's assigned data, otherwise returns null.
	 * @returns {any}
	 */
	public getData(){
		return (this.parent && (this.parent as any).getData) ? (this.parent as any).getData() : null;
	}
	
	public getTicketContainer(): TicketContainer {
		return this.ticketContainer
	}
	/**
	 * Called when the associated DataContainer is updated.
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	public updateData(planState: PlanState, ticketContainer: TicketContainer){
		const data = ticketContainer.getData();
		
		// Set ticket color
		if (!data.isGhost){
			this.tint = ticketContainer.liveRoleColor;
		}
		
		// if (data.view.isActualized && !data.isGhost) {
		// 	// Not a ghost and ticket is finished. alpha should be 0.7 according to Davina.
		// 	this.alpha = 0.7;
		// } else if (!data.isGhost) {
		// 	// Ticket is not a ghost and is not finished. Alpha of 1.
		// 	this.alpha = 1.0;
		// } else {
		// 	this.alpha = 0.5;
		// }
		
		this.ticketContainer = ticketContainer;
	}
	/**
	 * Called each render tick
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	public update(planState: PlanState, ticketContainer: TicketContainer){
	}
	/**
	 * @param {Boolean} [options.children = false] If true, will call destroy on all children as well.
	 * @param {Boolean} [options.texture = false] If true, will destroy the texture as well.
	 * @param {Boolean} [options.baseTexture = false] If true, will destroy the base texture as well.
	 */
	public destroy(options?: object|boolean): void{
		super.destroy(options);
		if (this.overlap){
			this.overlap.destroy(options);
		}
	}
}
