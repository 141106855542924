import { Texture, extras } from "pixi.js";
/**
 * TickerOverlap default
 */
export interface ITicketOverlapDefaultState {
	active: boolean;
	renderable: boolean;
	mask: any;
}
export class TicketOverlap extends extras.TilingSprite {
	public defaultState: ITicketOverlapDefaultState = {
		active: true,
		renderable: false,
		mask: null
	};
	public active: boolean;
	constructor(texture: Texture){
		super(texture);
		
		//this.zIndex = 15;
		this.alpha = 0.33;
		this.active = true;
		this.visible = true;
	}
}