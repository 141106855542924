'use strict';
import * as angular from "angular";

angular.module('ticketspaceApp')
.factory('OrderedExecutor', function() {
	function OrderedExecutor(){
		//resume here, added to start of hud observers for testing
		//start adding things to the order list
		this.order = [
			//calculations
			"activeColumnService",
			"timeline",
			"swimlanes",
			"timelineConstraints",
			"updateLod",
			//just rendering
			"updateTicketVisibility",
			// "dependencies",
			"namifyZoom",
			"drawDisplayLine",
			"drawBackgroundArea",
			"updatePositionInFirebase",
			"random"
		];
		//index by order key
		this.callbacks = {};
		
		this.init();
		
	};
	OrderedExecutor.prototype = {
		"init": function(){
			var self = this;
			this.order.forEach(function(o, key){
				self.callbacks[key] = [];
			});
			//self.callbacks["random"] = [];
		},
		"add": function add(id, cb){
			//add to non-ordered section
			if(arguments.length === 1){
				cb = arguments[0];
				id = "random";
			}
			
			var idx = this.order.indexOf(id);
			if(idx !== -1){
				//console.log('idx', idx);
				this.callbacks[idx].push(cb);
			}
			else{ console.log('key: '+id+' is not supported'); }

		},
		//startingIndex, skip ahead in the list. Use to implement promise support...
		"execute": function execute(startingIndex, subIndex){
			var i = startingIndex || 0;
			for(; i < this.order.length; i++){
				var j = subIndex || 0;
				for(; j < this.callbacks[i].length; j++){
					//console.log('hi');
					this.callbacks[i][j]();
				}
			}
		},
		
		"destroy": function destroy(){
			this.callbacks = {};
			this.order = [];
		}
	};
	
	return OrderedExecutor;
});
