'use strict';

//todo: add a watch function to the user generation/ project generation stuff that allows the generated
//array to be updated when new entires are added

import * as angular from "angular";
import * as utils from "utils";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

angular.module('ticketspaceApp')
.factory('firebaseSnapshots', ["$window", "$log", "$firebaseUtils", "$firebaseArray", "fbdbRequests", "firebaseVirtualWrapper", function($window, $log, $firebaseUtils, $firebaseArray, fbdbRequests, firebaseVirtualWrapper) {
	// - be sortable (initially by inverse fbIds, possibly by other fields)
	// - be filterable by search log
	// - have a "single use" edit mode
	// - be able to restore old values by clicking cancel
	// http://web.archive.org/web/20150320063526/https://www.firebase.com/docs/web/libraries/angular/api.html#angularfire-extending-the-factories
	
	var prettyMap = {
		'userRestoreRequest': 'auto save prior to restore',
		'systemOnActiveLineChange': 'auto save prior to active line move',
		'systemOnPromisePeriodLock': 'auto save prior to promise lock'
	};
	
	
	function SnapshotList(refUrl){
		this.init(refUrl);
	}
	SnapshotList.prototype = Object.create(firebaseVirtualWrapper);
	//SnapshotList.prototype.setup({'addKey': 'roleId'});
	
	
	//#### init(refUrl)
	// builds the instance, creates the fb object and inits some of the other keys
	SnapshotList.prototype.init = function init(refUrl){
		var sync = firebaseVirtualWrapper.init.call(this, refUrl, sFactory); //super
		/*
		sync.$watch(function(){
			sync.sort(function(a,b){
				return a.data.roleName.localeCompare(b.data.roleName);
			});
		});
		*/
		sync.$loaded(function(){
			sync.sort(sorter);
			sync.$watch(function(){ sync.sort(sorter); });
		});
	};
	
	SnapshotList.prototype.finishEdit = function finishEdit(){
		var name = this.temp.name;
		return firebaseVirtualWrapper.finishEdit.call(this).then(function(data){
			analyticsService.snapshotSaved(data, name);
			return data;
		});
	};
	
	//#### edit(id)
	// sets edit for the id passed in
	SnapshotList.prototype.edit = function edit(id){
		var val = firebaseVirtualWrapper.edit.call(this, id);
		if(val === -1){
			//this.temp.roleStyle = this.nextStyle(stickyStyleList);
		}
		else if(val){
			['name', 'description'].forEach(function(key){
				this.temp[key] = val.data[key];
			},this);
		}
	};
	
	//#### takeSnapshot(projectId, planId, userId)
	// wraps the fbdbRequest also clearing edit, returns the promise
	SnapshotList.prototype.takeSnapshot = function takeSnapshot(projectId, planId, userId, extraParams, meta){
		var self = this;
		return fbdbRequests.snapshot.take(projectId, planId, userId, extraParams, meta).then(function(data){
			self.clearEdit();
			
			if(utils.checkNested(data, 'response', 'snapshotId')){
				var s = self.fb.$getRecord(data.response.snapshotId);
				if(!s.isSystem()){
					self.edit(data.response.snapshotId);
				}
				analyticsService.snapshotCreated(s.$id);
			}
			
			return data;
		});
	};
	
	//#### restoreSnapshot(projectId, planId, userId)
	// wraps the fbdbRequest, returns the promise
	SnapshotList.prototype.restoreSnapshot = function restoreSnapshot(projectId, planId, userId){
		var snapToRestore = this.fb.$getRecord(this.editIndex);
		return fbdbRequests.snapshot.restore(projectId, planId, this.editIndex, userId).then(function(data){
			if(snapToRestore){
				analyticsService.snapshotRestored(snapToRestore.$id, snapToRestore.data.name, snapToRestore.data.initiatedBy, planId);
			}
			return data;
		});
	};
	
	// SnapshotList.prototype.finishEdit = function finishEdit(){
		// if(!this.temp.roleName){Logging.warning('name is required'); return;}
		
		// firebaseVirtualWrapper.finishEdit.call(this);
	// };
	
	function Snapshot(snap, searchString){
		this.$id = snap.key;
		this.update(snap);
		this.filter(searchString);
	}
	Snapshot.prototype = {
		update: function(snap){
			//store the data on...data
			this.data = snap.val();
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		},
		filter: function(searchString){
			if(!searchString){searchString = '';}
			//console.log('filter called', searchString);
			if(!searchString){ this.hide = false; return; }
			if(utils.checkNestedProto(this,'data','name','indexOf')
					&& this.data.name.toLowerCase().indexOf(searchString) !== -1){
				this.hide = false;
			}
			else if(this.data.prettyId 
					&& this.data.prettyId.indexOf(searchString) !== -1){
				this.hide = false;
			}
			else{
				this.hide = true;
			}
		},
		userHidden: function(system, restore){
			if(system && this.isSystem()){
				if(!restore && this.data.requestName === 'restore') //Added restore snapshots should show if showing restores.
					return false;
				else
					return true;
			}
			else if(restore && this.data.requestName === 'restore'){
				return true;
			}
			return false;
		},
		isSystem: function(){
			return this.data.initiatedBy === 'userRestoreRequest' || this.data.initiatedBy === 'systemOnActiveLineChange' || this.data.initiatedBy === 'systemOnPromisePeriodLock'
		},
		autoReason: function(){
			if(!this.data.initiatedBy){return '';}
			return prettyMap[this.data.initiatedBy];
		}
	};
	
	
	var sFactory = $firebaseArray.$extend({
		
		$$added: function(snap){
			//console.log('added');
			return new Snapshot(snap, this.searchString);
		},
		$$updated: function(snap){
			//console.log('updated');
			var rec = this.$getRecord(snap.key);
			rec.update(snap);
			rec.filter(this.searchString);
		},
		
		filter: function(searchString){
			var self = this;
			if(this.searchString === searchString.toLowerCase()){return;}
			this.searchString = searchString.toLowerCase();
			self.$list.forEach(function(val){
				val.filter(self.searchString);
			});
		}
		
	});
	
	function sorter(a,b){
		if(a.$id === b.$id){ return 0; }
		if(a.$id < b.$id){ return 1; }
		if(a.$id > b.$id){ return -1; }
	}
	$window.sorter = sorter;
	
	return function(refUrl){
		return new SnapshotList(refUrl);
		/*
		
		sync.$loaded(function(){
			sync.sort(sorter);
			sync.$watch(function(){ sync.sort(sorter); });
		});
		
		return sync;
		*/
	}
}]);
