import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import {BehaviorSubject} from "rxjs";

import {ReportService} from "ng2/common/services/report.service";

import * as firebase from "firebase/app";

import {Perf, perf as fallbackPerf} from "ng2/common/perfLog"

import { LoadingBlockerState } from "ng2/common/components/loading-blocker";

@Component({
	selector: 'report',
	template: require('./report.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportComponent implements OnInit {
	public loadingSet = new BehaviorSubject<any>(LoadingBlockerState.empty);
	
	storage = firebase.storage();
	goCloud = MB4_PREPROCESSOR_CONFIG.USE_CLOUD;
	reportData;
	
	@ViewChild('template', { read: ElementRef }) reportTemplate: ElementRef;
	
	constructor(
		private reportService: ReportService,
		private route: ActivatedRoute,
		private router: Router
	) { }

	ngOnInit() {
		console.log('on init');
		this.loadingSet.next(LoadingBlockerState.inProgress);
		this.originWindowSetup();
		let syncRoute = this.route.snapshot;
		
		this.reportService.getReport(syncRoute.paramMap.get('requestId'), syncRoute.paramMap.get('projectId')).then(data => {
				$(this.reportTemplate.nativeElement).append(data);
				
				// can re-enable this to make the tab open, still need to solve the css thing
				// let html = `<html>${data}</html>`;
				// let newWindow = window.open();
				// console.log("NEW WINDOW", newWindow);
				// newWindow.document.write(html);
				
				this.loadingSet.next(LoadingBlockerState.complete);
				console.log('all done');
			})
			.catch(e => {
				console.log('report gen error', e);
				Logging.warning(e.message);
				this.loadingSet.next(LoadingBlockerState.failure);
				setTimeout(()=>{
					this.reportsBack();
				}, 300)
			})
		
		// this.reportService.getReport(syncRoute.paramMap.get('requestId'), syncRoute.paramMap.get('projectId')).then(data => {
		// 	$(this.reportTemplate.nativeElement).append(data);
		//
		// 	// TODO: Dan to make these changes on fbdb
		// 	let google = `<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script><script>google.charts.load('current', {'packages':['corechart']});</script>`;
		// 	let jQuery = `<script src="https://code.jquery.com/jquery-3.3.1.min.js" integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8=" crossorigin="anonymous"></script>`;
		//
		// 	let html = `<html><head>${google} ${jQuery}</head><body class="report-controller">${data}</body></html>`;
		// 	let newWindow = window.open();
		// 	newWindow.document.write(html);
		//
		// 	for (let i = 0; i < document.styleSheets.length; i++) {
		// 		let styleElement = newWindow.document.createElement("style");
		// 		newWindow.document.head.appendChild(styleElement);
		//
		// 		let pageStylesheet: any = document.styleSheets[i];
		// 		let sheet: any = styleElement.sheet;
		// 		try {
		// 			for (let rule = pageStylesheet.cssRules.length; rule >= 0; rule--) {
		// 				if (pageStylesheet.cssRules[rule] && pageStylesheet.cssRules[rule].cssText) {
		// 					sheet.insertRule(pageStylesheet.cssRules[rule].cssText);
		// 				}
		// 			}
		// 		} catch (e) {}
		// 	}
		//
		//
		// 	this.loadingSet.next(LoadingBlockerState.complete);
		// 	console.log('all done');
		// })
		// .catch(e => {
		// 	console.log('report gen error', e);
		// 	Logging.warning(e.message);
		// 	this.loadingSet.next(LoadingBlockerState.failure);
		// 	setTimeout(()=>{
		// 		// this.reportsBack();
		// 	}, 300)
		// })
	}
	
	reportsBack = ()=>{
		let syncRoute = this.route.snapshot;
		this.reportService.removeRequest(syncRoute.paramMap.get('requestId'));
		
		if(window.opener && window.opener.reportClosed){
			var loc = window.location.hash;
			if(window.opener && window.opener.reportClosed && window.opener.reportClosed[loc]){
				window.opener.reportClosed[loc]();
			}
			window.close(); 
		}
		else{ this.router.navigate(['projects', syncRoute.paramMap.get('projectId')]) }
	}

	originWindowSetup(){
		// if(window.opener){
		// 	console.log('window.opener', window.opener);
		// 	// This is giving a CORs error: ncaught DOMException: Blocked a frame with origin "https://weekly-stage.firebaseapp.com" from accessing a cross-origin frame
		// 	//wtf? - wrapping in arbitrary try/ catch
		// 	try{ var perf:Perf = window.opener.perf; }
		// 	catch(e){ var perf:Perf = fallbackPerf; }
		// }
		// else{
		// 	var perf:Perf = fallbackPerf;
		// }
		// //somehow _still_ not defined
		// if(!perf){
		// 	perf = <any>{
		// 		log: ()=>{}
		// 	}
		// }
		
		window.reportsBack = this.reportsBack;
	}
}