import {Injectable, Inject, NgZone} from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import {Observable, BehaviorSubject, Subject, Subscription } from "rxjs";
import {filter, tap, takeUntil, map} from "rxjs/operators"

import { sessionStorage } from "ng2/common/services/SessionStorage";

import {renderQueue} from "ng2/common/RenderQueue";

import {CoreState} from "ng2/common/interfaces/app-state.interface";
import {Ticket, TicketRenderTypes, Plan, CalendarWorkdays} from "ng2/common/models";
import { You, observeYou } from "ng2/common/models/You";
import { Dependencies } from "ng2/common/models/Dependencies";
import { Swimlanes } from "ng2/plan-things-rename/Swimlanes";

import { CanvasConnector } from "ng2/common/models/CanvasConnector";
import { DebugRectangles } from "ng2/common/models/DebugRectangles";

import { FancyFirebaseObject } from "ng2/fancy-firebase/base";

import {
	TicketList, RoleList, LocationList, ProjectMemberList, PriorityList,
	LaborCountList
} from "ng2/fancy-firebase/factories";
import { PlanObject } from "ng2/fancy-firebase/objects/PlanObject";
import { TicketActionTypes } from "ng2/actions/ticket-actions";

import {applyScopedActions, AllThePlanActions} from "ng2/actions/plan-actions";

import {TicketDragService} from "ng2/common/models/TicketDragService";
import {SwimlaneDragService} from "ng2/common/models/SwimlaneDragService";

import { AccessCheck, ActiveColumnService, DatePositionValidator } from "ng2/common/ajs-upgrade-providers";

import { DataInfusedTicketCopy } from "ng2/common/services/DataInfusedTicketCopy"; //just load it in for debugging
import { LaborCounts } from "../../plan-things-rename/LaborCounts";
import { changeTicketViewToEdit, changeTicketViewToDefault, ticketDragged } from "ng2/actions/ticket-actions";
import {FancyFirebaseList} from "../../fancy-firebase/base/FancyFirebaseList";
import { FancyAction } from "../../fancy-firebase/base/FancyAction";
import {ProjectObject} from "../../fancy-firebase/objects/ProjectObject";
import {FbdbSettingsObject} from "../../fancy-firebase/objects/FbdbSettingsObject";
import {EnabledChecker, IEnabledFeatures} from "../models/EnabledChecker";

export enum StateEvents {
	"INVALID"= -2, //not actually a valid state, only exists for error handling
	"BOOMED" = -1, //blow up
	"UNINITIALIZED" = 0, //default
	"INITIALIZED" = 1, //setup called, angular stuff loaded
	"SCOPE_SOUPED" = 2, //angularjs plan_controller initialized
	"CANVAS_LOADED" = 3, //rendererApplication setup
	"FULLY_LOADED" = 100, //baked potato
};


@Injectable()
export class PlanState implements CoreState{
	private stateLifeCycle?: PlanStateLifeCycle;
	private internalShutdown = new Subject();
	private unsubscribeList:Array<Subscription>;
	
	public route: ActivatedRoute;
	/** a version of the renderQueue that completes when the planState is destroyed */
	public renderQueueWithCleanup$: Observable<number>;

	/** extremely temporary thing for the meeting popup. Remove this and solve better in r37 */
	public showMeetingPopup = false;
	
	public tickets: TicketList;
	public timelineTickets: TicketList;
	public roles: RoleList;
	public locations: LocationList;
	public projectMembers: ProjectMemberList;
	public priorities: PriorityList;
	public plan: PlanObject;
	public calendarWorkdays: CalendarWorkdays;
	public you: Observable<You>;
	public youSync: You;
	public dependencies: Dependencies;
	public swimlanes: Swimlanes;
	public laborCounts: LaborCounts;
	public projectLaborCounts: LaborCounts;
	public fbdbSettings: FbdbSettingsObject;
	public project: ProjectObject;
	
	/** probably don't use this */
	public legacySearch: {
		in$: BehaviorSubject<Array<Ticket>>
		out$: Observable<Array<Ticket>>
	};
	
	public planId?: string;
	public projectId?: string;
	
	/** legacy access to the old plan_controller scope. Seriously think about what you're doing if you decide to use this.*/
	public scopeSoup: any;
	
	public stateChange$ = new BehaviorSubject<StateEvents>(StateEvents.UNINITIALIZED); //maybe hide the subject and expose an .asObservable()
	public stateSync = StateEvents.UNINITIALIZED;
	
	public actions:AllThePlanActions = (<AllThePlanActions>{}); //shh... don't question it
	public canvasConnector?: CanvasConnector;
	
	public ticketDragService: TicketDragService;
	public swimlaneDragService: SwimlaneDragService;
	
	public debugRectangles: DebugRectangles = new DebugRectangles();
	
	public ticketCopy: DataInfusedTicketCopy;
	
	public enabledChecker: EnabledChecker;
	
	constructor(
			public activeColumnService: ActiveColumnService, 
			private datePositionValidator: DatePositionValidator, 
			public angularZone: NgZone,
			public accessCheck: AccessCheck //don't like this, but it's needed deep within canvas code
		){
		this.stateChange$.subscribe((state)=> this.stateSync = state );
		
		applyScopedActions(this.actions, this);
	}
	
	setup(
		route: ActivatedRoute,
		canvasConnector: CanvasConnector,
		tickets: TicketList,
		timelineTickets: TicketList,
		roles: RoleList,
		locations: LocationList,
		projectMembers: ProjectMemberList,
		priorities: PriorityList,
		plan: PlanObject,
		calendarWorkdays: CalendarWorkdays,
		youObservable: Observable<You>,
		dependencies: Dependencies,
		swimlanes: Swimlanes,
		laborCount: LaborCounts,
		projectLaborCount: LaborCounts,
		fbdbSettings: FbdbSettingsObject,
		project: ProjectObject
	){
		this.unsubscribeList = [];
		this.stateLifeCycle = new PlanStateLifeCycle();
		this.route = route;
		this.canvasConnector = canvasConnector;
		this.tickets = tickets;
		this.timelineTickets = timelineTickets;
		this.roles = roles;
		this.locations = locations;
		this.projectMembers = projectMembers;
		this.priorities = priorities;
		this.plan = plan;
		this.calendarWorkdays = calendarWorkdays;
		
		this.you = youObservable.pipe(takeUntil(this.internalShutdown));
		this.you.subscribe(you => this.youSync = you);
		
		this.dependencies = dependencies;
		this.swimlanes = swimlanes;
		this.laborCounts = laborCount;
		this.projectLaborCounts = projectLaborCount;
		this.fbdbSettings = fbdbSettings;
		this.project = project;
		
		this.ticketDragService = new TicketDragService(this, {activeColumnService: this.activeColumnService, datePositionValidator: this.datePositionValidator});
		this.swimlaneDragService = new SwimlaneDragService(this, {activeColumnService: this.activeColumnService, datePositionValidator: this.datePositionValidator});
		
		window.planState = this;
		
		this.renderQueueWithCleanup$ = renderQueue.ob$.pipe(takeUntil(this.internalShutdown));
		
		this.projectId = this.route.snapshot.paramMap.get("projectId");
		this.planId = this.route.snapshot.paramMap.get("planId");
		
		var newState = this.stateLifeCycle.angularLoaded();
		if(newState !== StateEvents.INVALID){
			this.stateChange$.next(newState);
		}
		
		this.ticketCopy = new DataInfusedTicketCopy(this);
		
		this.ticketEventHandling();
		
		this.legacySearch = {} as any;
		this.legacySearch.in$ = new BehaviorSubject([]);
		this.legacySearch.out$ = this.legacySearch.in$.pipe(
			takeUntil(this.internalShutdown),
			map((legacyList) => {
				let list:Array<Ticket> = [];
				legacyList.forEach(oldTicket => {
					let ticket = this.tickets._internalList.get(oldTicket.$id);
					if(ticket){ list.push(ticket); }
				});
				return list;
			})
		);
		
		this.enabledChecker = new EnabledChecker(this.project.object$, this.fbdbSettings.object$, this.plan.object$);
		// MOC-3188 - Combine our enabled features data with the session storage data. That way, we only have to subscribe to session storage
		// to get a full list of features enabled at either the project of global fbdb level
		this.enabledChecker.changed$.pipe(takeUntil(this.internalShutdown)).subscribe((enabledFeatures: IEnabledFeatures) => {
			for (let key in enabledFeatures){
				sessionStorage[key] = enabledFeatures[key];
			}
		})
	}
	
	//wtf...
	// apparently I can't simulate the zone loss issue from firebase callbacks
	// this is either because explicitly setting the zone has different behavior than operating from the current zone
	// or it's breaking in some stranger way...
	//General conclusion here, might not make sense to create a general solution from moving firebase callbacks into the current zone
	// since it sometimes seems to retain the zone just fine...
	testZone(){
		console.log('startZone', Zone.current.name);
		//run entire thing in angular block, entire thing is angular
		// this.angularZone.run(()=>{
		// 	console.log('pre-exec startZone', Zone.current.name);
		// 	let ticket:Ticket = (this.tickets._internalList as any).first();
		// 	let ref = window.firebase.database().ref('Rev4/tickets/-LOt4AWX9LB8hJdwA87j/-LaLXGXV0wME0FCTLHPA').child(ticket.$id).child('activityName');
		// 
		// 	ref.set(ticket.view.activityName + 'g').then(t => {
		// 		console.log('endZone', Zone.current.name);
		// 	})
		// });
		
		//create ref in angular, run set in root, ends in root
		// let ref;
		// let ticket:Ticket;
		// this.angularZone.run(()=>{
		// 	console.log('pre-exec startZone', Zone.current.name);
		// 	ticket = (this.tickets._internalList as any).first();
		// 	ref = window.firebase.database().ref('Rev4/tickets/-LOt4AWX9LB8hJdwA87j/-LaLXGXV0wME0FCTLHPA').child(ticket.$id).child('activityName');
		// });
		// ref.set(ticket.view.activityName + 'g').then(t => {
		// 	console.log('endZone', Zone.current.name);
		// })
		
		//create ref in root, run in angular
		let ref;
		let ticket:Ticket;
		console.log('pre-exec startZone', Zone.current.name);
		ticket = (this.tickets._internalList as any).first();
		ref = window.firebase.database().ref('Rev4/tickets/-LOt4AWX9LB8hJdwA87j/-LaLXGXV0wME0FCTLHPA').child(ticket.$id).child('activityName');
		
		this.angularZone.run(()=>{
			ref.set(ticket.view.activityName + 'g').then(t => {
				console.log('endZone', Zone.current.name);
			})
		});
	}
	
	boom(){
		//this can potentially be called more than once
		if(this.stateSync === StateEvents.BOOMED){ return; }
		
		// Logging.warning("ʘ‿ʘ : The memory, she leaks");
		// Logging.warning("щ（ﾟДﾟщ）: Da vram, she bleeds");
		// TODO Don't do this FFS YOU MONSTER ლ(｀ー´ლ)
		//(<any>window).TouchPlanCanvas.destroy(true);
		this.internalShutdown.next(true);
		this.tickets.destroy(); this.tickets = null;
		this.timelineTickets.destroy(); this.timelineTickets = null;
		this.roles.destroy(); this.roles = null;
		this.locations.destroy(); this.locations = null;
		this.projectMembers.destroy(); this.projectMembers = null;
		this.priorities.destroy(); this.priorities = null;
		this.plan.destroy(); this.plan = null;
		this.debugRectangles.destroy(); //don't null this
		this.dependencies.destroy(); this.dependencies = null;
		this.swimlanes.destroy(); this.swimlanes = null;
		this.laborCounts.destroy(); this.laborCounts = null;
		this.projectLaborCounts.destroy(); this.projectLaborCounts = null;
		this.scopeSoup = null; this.scopeSoup = null;
		this.canvasConnector.destroy(); this.canvasConnector = null;
		this.calendarWorkdays.destroy(); this.calendarWorkdays = null;
		this.ticketDragService.destroy(); this.ticketDragService = null;
		this.swimlaneDragService.destroy(); this.swimlaneDragService = null;
		this.ticketCopy.destroy(); this.ticketCopy = null;
		this.enabledChecker.destroy(); this.enabledChecker = null;
		this.stateChange$.next(StateEvents.BOOMED);
		
		this.you = null;
		this.projectId = null;
		this.planId = null;
		
		this.unsubscribeList.forEach( sub => sub.unsubscribe() );
		
		sessionStorage.hideCompletedTickets = false;
		
		delete window.planState;
	}
	
	public assignScopeSoup(soup){
		var newState = this.stateLifeCycle.scopeSoupLoaded();
		if(newState !== StateEvents.INVALID){
			this.scopeSoup = soup;
			this.stateChange$.next(newState);
		}
	}
	public assignCanvasConnector(application){
		var newState = this.stateLifeCycle.canvasLoaded();
		if(newState !== StateEvents.INVALID){
			this.canvasConnector.connectApplication(application);
			this.stateChange$.next(newState);
		}
	}
	
	//TODO - move somewhere else
	// attempt handling specific ticket actions to run some additional code based on it
	private ticketEventHandling(){
		var stateSnapshot = this.route.snapshot.paramMap;
		// var supportedActions = {};
		this.tickets.rawEvent$.pipe(
			filter(action => 
				(action.type === TicketActionTypes.floatingTicketSaved && action.payload.view.type === "constraint")
				|| (action.type === TicketActionTypes.createTicketWithId && action.payload.view.type === "constraint")
				|| (action.type === TicketActionTypes.ticketSavedToFloating && action.payload.view.type === "constraint")
				|| (action.type === TicketActionTypes.ticketSavedToFloating && this.scopeSoup.timelineTickets.$getCompositeRecord(stateSnapshot.get("planId"), action.key))
			)
		).subscribe((action)=>{
			if(action.payload.view.type === "constraint"){
				if(action.type === TicketActionTypes.ticketSavedToFloating){
					let constraint = this.scopeSoup.constraints2.$getByTicketId(action.key);
					this.scopeSoup.constraints2.$remove(constraint);
				}
				else if(action.type === TicketActionTypes.floatingTicketSaved || action.type === TicketActionTypes.createTicketWithId){
					this.scopeSoup.constraints2.addIndex(stateSnapshot.get("planId"), action.key);
				}
			}
			var timelineRec = this.scopeSoup.timelineTickets.$getCompositeRecord(stateSnapshot.get("planId"), action.key);
			if(timelineRec){
				this.scopeSoup.timelineTickets.$remove(timelineRec);
			}
		})
	}
	
	//some temporary interfaces from the app code to old app functionality
	public legacyActions = {
		openEditMode: (newTickets)=>{
			var old = this.legacyActions.getOldTicketsFromNew(newTickets);
			this.scopeSoup.ticketEditService.editStandard(this.scopeSoup, old);
		},
		getOldTicketsFromNew: (newTickets)=>{
			var arr = [];
			//using forEach instead of [...] because it works the same for arrays and maps
			newTickets.forEach((t)=>{
				var oldT = this.scopeSoup.tickets.$getRecord(t.$id);
				if(oldT){arr.push(oldT);}
				else{ console.log('old and new tickets aren\'t synced?', t.$id); }
			})
			return arr;
		}
	}
}

class PlanStateLifeCycle{
	private angular = false;
	private plan_controller = false;
	private canvas = false;
	private	everything = false;
	
	private map ={
		"angular": StateEvents.INITIALIZED,
		"plan_controller": StateEvents.SCOPE_SOUPED,
		"canvas": StateEvents.CANVAS_LOADED
	}
	
	public angularLoaded():StateEvents{
		return this.calcState("angular");
	}
	public scopeSoupLoaded():StateEvents{
		return this.calcState("plan_controller");
	}
	public canvasLoaded():StateEvents{
		return this.calcState("canvas");
	}
	
	private calcState(key):StateEvents{
		if(this[key]){return StateEvents.INVALID;} //no returning
		window.perf.log("planState - "+key, {previousEventName: "planState - angular"});
		this[key] = true;
		if(this.angular && this.plan_controller && this.canvas){return StateEvents.FULLY_LOADED;}
		else{return this.map[key];}
	}
}
