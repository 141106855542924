import { FancyTextStyle } from "../interfaces/";
/**
 * Contains default tags and default styling.
 * This could be a const instead of a class with static methods I guess. meh ¯\_(ツ)_/¯
 */
export class FancyDefaultStyles{
	/**
	 * The default styling for text.
	 */
	public static DEFAULT_STYLE: FancyTextStyle = {
		align: "left",
		breakWords: false,
		debugMode: false,
		dropShadow: false,
		dropShadowAngle: Math.PI / 6,
		dropShadowBlur: 0,
		dropShadowColor: "#000000",
		dropShadowDistance: 5,
		fill: "white",
		fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
		fontFamily: "Roboto",
		fontSize: 30,
		fontStyle: "normal",
		fontVariant: "normal",
		fontWeight: "normal",
		letterSpacing: 0,
		lineHeight: 0,
		lineJoin: 'miter',
		miterLimit: 10,
		padding: 0,
		resolution: 2,
		stroke: "black",
		strokeThickness: 0,
		textBaseline: "alphabetic",
		wordWrap: true,
		wordWrapWidth: 100
	};
}
