import {FancyAction, FancyFirebaseList} from "ng2/fancy-firebase/base";
import {SplitData} from "./SplitData";

import { DefaultActionTypes, CombinedDefaultActions, AbortViewAction, ChangeViewAction, PersistDataAction } from "./DefaultActionTypes";


/**
 * A helper class to be used by a FancyFirebaseList (and possibly the object variant?)
 * that includes a default set of handling for the default set of actions. To opt out
 * of default handling, pass in the excluded action types via the limiterList,
 * then implement your own version of the handling.
 * @param list        The parent list that owns this class.
 * @param limiterList The exlude list for opting out of handling
 */

//enhancement, FancyModel's SplitData should be typed
export class DefaultActionHandlers<FancyModel extends SplitData<Object, any>, FancyCustomAction extends FancyAction, List extends FancyFirebaseList<FancyModel, FancyCustomAction>>{
	constructor(public list: List, private limiterList?: Array<DefaultActionTypes>){
		
	}
	public handle(action: CombinedDefaultActions){
		if(this.skip(action.type)){return true;}
		
		switch(action.type){
			case DefaultActionTypes.addItem: return this.handleAddItem(action);
			case DefaultActionTypes.removeItem: return this.handleRemoveItem(action);
			case DefaultActionTypes.localUpdate: return this.handleLocalUpdate(action);
			case DefaultActionTypes.changeView: return this.handleChangeView(action as ChangeViewAction<FancyModel>);
			case DefaultActionTypes.abortView: return this.handleAbortView(action as AbortViewAction<FancyModel>);
			case DefaultActionTypes.persistData: return this.handlePersistData(action as PersistDataAction<FancyModel>);
			default: return false;
		}
	}
	private skip(fa: string){
		return this.limiterList && this.limiterList[fa];
	}
	
	public handleAddItem(action: CombinedDefaultActions<FancyModel>){
		this.list.listRef.push(action.payload);
		return action.payload;
	}
	public handleRemoveItem(action: CombinedDefaultActions<FancyModel>){
		this.list.listRef.remove(action.key);
		return action.payload;
	}
	public handleLocalUpdate(action: CombinedDefaultActions<FancyModel>){
		let item:any = this.list._internalList.get(action.key);
		item.localUpdate(action.payload);
		return item;
	}
	public handleChangeView(action: ChangeViewAction<FancyModel>){
		action.payload.switchViews(action.renderType);
		return action.payload;
	}
	public handleAbortView(action: AbortViewAction<FancyModel>){
		action.payload.switchViews(action.renderType, true);
		return action.payload;
	}
	public handlePersistData(action: PersistDataAction<FancyModel>){
		let item = action.payload;
		// item.copyDataBetweenViews(item.currentRenderType, item.primaryRenderType);
		let dbOutput = item.generateDatabaseOutput(action.renderType);
		this.list.listRef.query.ref.child(action.key).update(dbOutput);
		return item;
	}
}
