'use strict';

import * as angular from "angular";
import * as utils from "utils";

angular.module('ticketspaceApp')
.factory('firebaseProjectLimitations', ["$firebaseObject", "fbConnection", function($firebaseObject, fbConnection) {
	
	var basePath = 'projectLimitations/';
	
	var limitFactory = $firebaseObject.$extend({
		//consider changes this to run on change instead of on digest for performance reasons
		getMax: function(subKey){
			return this[subKey];
		},
		isActive: function(){
			return false; //utils.checkNested(this,"stripeSubscription","status") && this.stripeSubscription.status === "active";
		}
	});
	
	function getLimits(projectId){
		return new limitFactory(fbConnection.fbRef(basePath+projectId));
	}
	
	return {
		getLimits: getLimits
	};
}]);
