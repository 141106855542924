'use strict';

import * as angular from "angular";

angular.module('CanvasWrapper').factory("Viewport", CanvasWrapperViewportFactory);
CanvasWrapperViewportFactory.$inject = ["PIXI", "focusManager", "Emitter", "InteractionManager", "projectViewConfig"];
function CanvasWrapperViewportFactory(PIXI, focusManager, Emitter, InteractionManager, projectViewConfig){
	/**
	 * @namespace Viewport
	 * @returns {{activate: activate, update: update, getDateRange: getDateRange}}
	 * @memberOf CanvasWrapper
	 * @constructor
	 */
	var ViewPort = function (options) {
		return this.activate.apply(this, arguments)
	};

	ViewPort.prototype = {
		constructor: ViewPort,
		/**
		 * @function activate
		 * @desc Initiates all local variables and starts up the viewport
		 * @param [options] {Object} Optional viewport options
		 * @memberOf CanvasWrapper.Viewport
		 * @private
		 */
		activate: function (options) {
			if (!options) {
				options = {};
			}
			/**
			 * @name zoom
			 * @desc Main object which stores the viewport current zoom state.
			 * @memberOf CanvasWrapper.Viewport
			 * @type {{scale: (*|number), max: (*|number), min: number, xOffset: number, yOffset: number, type: null}}
			 */
			this.zoom = {
				scale: options.zoomScale || 1,
				max: options.zoomMax || 4,
				min: options.zoomMin || 0.5,
				xOffset: options.xOffset || 0,
				yOffset: options.yOffset || 1,
				type: null
			};
			// Set-up ViewPort properties
			/**
			 * @name zoomLayers
			 * @desc An array containing the PIXI.DisplayObject which are affected by zoom
			 * @memberOf CanvasWrapper.Viewport
			 * @type {Array}
			 */
			this.zoomLayers = options.zoomLayers || [];
			/**
			 * @name panLayers
			 * @desc An array containing the PIXI.DisplayObject which are affected by pan
			 * @memberOf CanvasWrapper.Viewport
			 * @type {Array}
			 */
			this.panLayers = options.panLayers || [];
			/**
			 * @name view
			 * @desc Reference to {CanvasWrapper.Renderer.view}
			 * @type {HTMLCanvasElement}
			 * @memberOf CanvasWrapper.Viewport
			 */
			this.view = options.view;
			/**
			 * @name renderer
			 * @desc Reference to {CanvasWrapper.Renderer}
			 * @type {CanvasWrapper.Renderer}
			 * @memberOf CanvasWrapper.Viewport
			 */
			this.renderer = options.renderer;
			/**
			 * @name name
			 * @desc The name of this instance of the viewport. used to control the focusManager
			 * @type {String}
			 * @memberOf CanvasWrapper.Viewport
			 */
			this.name = options.name;
			/**
			 * @name ticker
			 * @desc reference to the renderer ticket
			 * @memberOf CanvasWrapper.Viewport
			 * @type {CanvasWrapper.renderer.ticker}
			 */
			this.ticker = options.ticker;
			/**
			 * @name focusManager
			 * @desc Reference to the focusManager service
			 * @memberOf CanvasWrapper.Viewport
			 */
			this.focusManager = focusManager;
			/**
			 * @name resolution
			 * @desc The resolution/devicePixelRatio to render at
			 * @memberOf CanvasWrapper.Viewport
			 * @type {number}
			 */
			this.resolution = options.resolution || 1;
			this.pos = {
				x: 0,
				y: 0
			};
			/**
			 * @name emitter
			 * @desc Reference to the CanvasWrapper.Emitter
			 * @type {CanvasWrapper.Emitter}
			 * @memberOf CanvasWrapper.Viewport
			 */
			this.emitter = new Emitter();

			// Set-up Pan
			if (options.canPan) {
				this.bindPan();
			}

			this.x = -1;
			this.y = -1;

			// Set-up zoom
			if (options.zoomStyle === 'smooth') {
				this.bindSmoothZoom(options.zoomSmoothness, options.zoomPower, options.pinchPower);
			} else if (options.zoomStyle === 'fixed') {
				this.bindZoom();
			}
		},
		/**
		 * @function getPositions
		 * @desc Returns an object position with the X and Y coordinate of the panLayers
		 * @memberOf CanvasWrapper.Viewport
		 * @returns {{x, y}}
		 */
		getPositions: function () {
			return {
				x: this.panLayers[0].x,
				y: this.panLayers[0].y
			};
		},
		/**
		 * @function setMaxZoom
		 * @desc Sets the maximum amount the Viewport can zoom out
		 * @param zMax {Number} The maximum zoom out amount
		 * @memberOf CanvasWrapper.Viewport
		 */
		setMaxZoom: function(zMax){
			this.zoom.max = zMax;
		},
		/**
		 * @function setMinZoom
		 * @desc Sets the maximum amount the Viewport can zoom in
		 * @param zMin {Number} the maximum zoom in amount
		 * @memberOf CanvasWrapper.Viewport
		 */
		setMinZoom: function(zMin){
			this.zoom.min = zMin;
		},
		/**
		 * @function setPosition
		 * @desc Sets the position of BOTH the zoomLayers and the panLayers
		 * @param pos {Object} An object with a x and y property
		 * @param scale {Number} Set the scale of the transform. Only applies to the zoomLayers.
		 * @memberOf CanvasWrapper.Viewport
		 */
		setPosition: function (pos, scale) {
			this.zoomLayers.forEach(function (layer, idx) {
				layer.scale.x = scale;
				layer.scale.y = scale;
				layer.x = pos.x;
				layer.y = pos.y;
			});

			this.panLayers.forEach(function (layer, idx) {
				layer.x = pos.x;
				layer.y = pos.y;
			});

			this.pos = pos;

			this.zoom.scale = scale;

			this.emitter.emit('setPosition', {pos: pos, factor: scale});
		},
		/**
		 * @function getScale
		 * @desc Get the current viewport scale value
		 * @memberOf CanvasWrapper.Viewport
		 * @returns {CanvasWrapper.Viewport.zoom.scale}
		 */
		getScale: function () {
			return this.zoom.scale
		},
		/**
		 * @function bindPan
		 * @desc Handles all pan movement of the camera
		 * @private
		 * @memberOf CanvasWrapper.Viewport
		 */
		bindPan: function () {
			var self = this;
			var mouseDown = false;
			var x = -1;
			var y = -1;

			function move(eventData){
				if (mouseDown && self.focusManager.match(self.name)) {
					var event = eventData.data.originalEvent;
					x = event.clientX;
					y = event.clientY;
					self.panLayers.forEach(function (layer) {
						self.zoom.xOffset = layer.position.x + eventData.deltaX;
						self.zoom.yOffset = layer.position.y + eventData.deltaY;
						self.pos.x = self.zoom.xOffset;
						self.pos.y = self.zoom.yOffset;
						layer.position.set(self.zoom.xOffset, self.zoom.yOffset);
					});
					self.emitter.emit('pan', eventData);
				}
			}
			function pointerDown(){
				x = -1;
				y = -1;
				self.focusManager.update(self.name);
				if (self.focusManager.match(self.name)) {
					mouseDown = true;
					self.emitter.emit('pointerdown');
				}
			}
			function pointerUp(){
				mouseDown = false;
				self.emitter.emit('pointerup');
			}

			function hovering(){
				self.focusManager.update(self.name);
			}

			function hoverExit(){
				self.focusManager.update('plan');
			}

			// Enable panning on the main renderer.
			var listener = InteractionManager.pan(this.renderer, true);
			// Set-up listeners.
			listener.on('panstart', pointerDown);
			listener.on('panmove', move);
			listener.on('panend', pointerUp);
			var hoverListener = InteractionManager.hover(this.renderer);
			hoverListener.on('hover', hovering);
			hoverListener.on('hoverexit', hoverExit);
		},
		/**
		 * @function renderSmoothZoom
		 * @desc Called on every tick from {CanvasWrapper.Renderer.ticker}. Update the Viewport and applies zoom transform
		 * @memberOf CanvasWrapper.Viewport
		 */
		renderSmoothZoom: function(){
			if (!this._zooming){return}
			var self = this;
			function zoom(factor) {
				// Stops an error when you change state inbetween frame draws
				var point;
				if (!self.renderer || !self.renderer.plugins) {return}
				if (!self._e || self.zoomButtonPressed){
					point = {
						x: self.view.width/2,
						y: self.view.height/2
					}
				}else {
					if (self._e.velocity){
						point = self._e.center;
						var bounds = self.view.getBoundingClientRect();
						point.x -= bounds.left;
						point.y -= bounds.top;
					} else{
						point = (self.renderer.plugins && self.renderer.plugins.interaction && self.renderer.plugins.interaction.eventData && self.renderer.plugins.interaction.eventData.data) ? self.renderer.plugins.interaction.eventData.data.global : {x: self.view.width/2, y: self.view.height/2};
					}
				}
				var newScale = self.zoom.scale * factor;
				if (newScale > self.zoom.max) {
					self.zoom.scale = self.zoom.max;
					return;
				}
				else if (newScale < self.zoom.min) {
					self.zoom.scale = self.zoom.min;
					return;
				}
				self.zoomLayers.forEach(function (layer, idx) {
					layer.scale.x *= factor;
					layer.scale.y *= factor;
					self.zoom.type = 'zoom';
					self.zoom.scale = layer.scale.x;
					layer.x -= (point.x - layer.x) * (factor - 1);
					layer.y -= (point.y - layer.y) * (factor - 1);
					self.pos.x = layer.x;
					self.pos.y = layer.y;
				});
				self.zoomButtonPressed = false;
				self.emitter.emit('zoom', {pointer: point, event: self._e, factor: factor});
			}

			var time = Date.now();
			var delta_t = (time - this._lastUpdate);
			this._lastUpdate = time;

			var step = Math.pow(this._remaining, delta_t / 100);
			this._remaining /= step;
			if (Math.abs(this._remaining - 1) < this._change) {
				zoom(this._remaining);
				this._remaining = 1;
				this._zooming = false;
			} else {
				zoom(this._remaining);
				this._remaining = 1;
				this._zooming = false;
			}
		},
		/**
		 * @function realToScreenX
		 * @desc Convert a Viewport global coordinate into a screen coordinate.
		 * @memberOf CanvasWrapper.Viewport
		 * @param x {Number} The x value of the global Viewport coordinate.
		 * @returns {Number} The screen X value
		 */
		realToScreenX: function(x){
			return x * this.getScale() + this.pos.x;
		},
		/**
		 * @function realToScreenY
		 * @desc Convert a Viewport global coordinate into a screen coordinate
		 * @param y {Number} The y value of the global Viewport coordinate
		 * @memberOf CanvasWrapper.Viewport
		 * @returns {Number} The screen Y value
		 */
		realToScreenY: function(y){
			return y * this.getScale() + this.pos.y;
		},
		/**
		 * @function screenToRealX
		 * @desc Convert a display x coordinate into a Viewport global x coordinate
		 * @param x {Number} The x coordinate of the screen
		 * @memberOf CanvasWrapper.Viewport
		 * @returns {Number} The Viewport global X coordinate
		 */
		screenToRealX: function(x){
			return x / this.getScale() - this.pos.x / this.getScale();
		},
		/**
		 * @function screenToRealY
		 * @desc Convert the display Y coordinate to into a global Viewport y coordinate
		 * @param y {Number} The y coordinate of the screen
		 * @memberOf CanvasWrapper.Viewport
		 * @returns {number} The viewport global Y coordinate
		 */
		screenToRealY: function(y){
			return y / this.getScale() - this.pos.y / this.getScale();
		},
		/**
		 * @function bindSmoothZoom
		 * @private
		 * @param delta
		 * @param power
		 * @memberOf CanvasWrapper.Viewport
		 */
		bindSmoothZoom: function (delta, power) {
			this._remaining = 1;
			this._zooming = false;
			this._lastUpdate = 0;
			this._change = delta;
			this._e = {};
			var self = this;

			function desktopZoom(e){
				var zoomIn = e.deltaY < 0;
				self.handleZoom(zoomIn, power, e);
			}

			function pinchZoom(e){
				if (focusManager.match(self.name)){
					self._e = e;
					var zoomIn = e.zoomIn;
					var zoomFactor;
					if (zoomIn) {zoomFactor = 1.025;
					} else {zoomFactor = (1 / 1.025);}
					self._remaining *= zoomFactor;
					if (!self._zooming) {
						self._lastUpdate = Date.now();
						self._zooming = true;
					}
				}
			}

			// Draw the zoom effect with each update of ticker
			this.ticker.add(this.renderSmoothZoom, this, PIXI.UPDATE_PRIORITY.HIGH);

			// Set-up listeners.
			// Set-up listener for mouse wheel
			this.view.addEventListener("wheel", desktopZoom);

			InteractionManager.pinch(this.renderer, false);
			this.renderer.plugins.interaction.on('pinchmove', pinchZoom);
		},
		/**
		 * @function handleZoom
		 * @desc Handles all zoom logic
		 * @param zoomingIn {Boolean} Controls if we are zooming in or out
		 * @param power {Number} The power of the zoom
		 * @param e {Event} The browser input event data
		 * @memberOf CanvasWrapper.Viewport
		 */
		handleZoom: function (zoomingIn, power, e) {
			if (focusManager.match(this.name)) {
				var zoomIn = zoomingIn;
				var zoomFactor;
				if (zoomIn) {
					zoomFactor = power;
				} else {
					zoomFactor = (1 / power);
				}
				this._remaining *= zoomFactor;
				if (!this._zooming) {
					this._lastUpdate = Date.now();
					this._zooming = true;
				}
				if (e) {
					this._e = e;
					e.preventDefault();
				}
			}
		},
		/**
		 * @function zoomIn
		 * @desc Zoom the viewport in. This transforms all layers which are on the zoomLayers member
		 * @param power {Number} The power of the zoom. Affects how fast the zoom in animation is
		 * @param force {Number} The force of the zoom. This controls how smooth the zoom transition is
		 * @memberOf CanvasWrapper.Viewport
		 */
		zoomIn: function (power, force) {
			if (force){
				this.focusManager.update(this.name);
				this.zoomButtonPressed = true;
			}
			power = power || projectViewConfig.VIEWPORT.ZOOM_INTENSITY;
			this.handleZoom(false, power);
		},
		/**
		 * @function zoomOut
		 * @desc Zoom the Viewport out. This transforms all layers which are on the zoomLayers member
		 * @param power {Number} The power of the zoom. Affects how fast the zoom out animation is.
		 * @param force {Number} The force of the zoom. Affects how smooth the zoom transition is.
		 * @memberOf CanvasWrapper.Viewport
		 */
		zoomOut: function (power, force) {
			if (force){
				this.focusManager.update(this.name);
				this.zoomButtonPressed = true;
			}
			power = power || projectViewConfig.VIEWPORT.ZOOM_INTENSITY;
			this.handleZoom(true, power);
		}
	};
	return ViewPort;
}
