//these properties not being defined will be considered the same as them being false

export interface SimpleAttribute{
	ignore?: boolean; //don't copy from the database source to the primary view (not currently implemented)
	noWrite?: boolean; //strip out the data when generating the database writable object
	ignoreLocal?: boolean; //don't copy the data when switching between modes. Set when you want special handling.
}
export interface SimpleAttributeList{
	[key: string]: SimpleAttribute
}

/**
 * Used to generate a default attribute list. Mainly for simple objects, or as a placeholder when setting things up.
 * @param  arr An array of keys to use to generate the default simple attribute list
 */
export function generateSimplestList(arr?: Array<string>): SimpleAttributeList{
	if(!arr){return {};}
	let obj = {};
	arr.forEach(key => { obj[key] = {}; })
	return obj;
}
