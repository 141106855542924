import { FancyPolygon, IFontData } from "./";
import { Point } from "pixi.js";
import * as earcut from "earcut";
/**
 * Enum to map OpenType.js path type data with a corresponding function.
 */
enum PATH_COMMAND_TYPES {
	"MOVE" = "M",
	"LINE_TO" = "L",
	"CUBIC_TO" = "C",
	"QUADRATIC_TO" = "Q",
	"CLOSE_PATH" = "Z"
}
/**
 * Interface for the object once a string has been triangulated
 */
export interface ITriangulateData {
	indexData: Uint16Array,
	vertexData: Float32Array,
	vertexCount: number
}
/**
 * Interface for the TrigData pre-calc
 */
export interface ITriangulateCalcData {
	indexData: number[],
	vertexData: number[],
	vertexCount: number;
	VERSION?: string;
	FONT_SIZE?: number;
}
/**
 * This Utils class exposes all static methods. It handles most of the implementation details for triangulating
 * OpenType.js font path data into vertex and index data.
 */
export class Utils {
	/**
	 * Calculate the distance between two points.
	 * @param {PIXI.Point} p1
	 * @param {PIXI.Point} p2
	 * @returns {number}
	 */
	public static distance(p1: Point, p2: Point): number{
		const dx = p1.x - p2.x;
		const dy = p1.y - p2.y;
		return Math.sqrt(dx * dx + dy * dy);
	}
	/**
	 * A simple linear interpolation function using PIXI.Point
	 * @param {PIXI.Point} p1
	 * @param {PIXI.Point} p2
	 * @param {number} t
	 * @returns {PIXI.Point}
	 */
	public static lerp(p1: Point, p2: Point, t: number): Point {
		return new Point(
			(1 - t) * p1.x + t * p2.x,
			(1 - t) * p1.y + t * p2.y
		)
	}
	/**
	 *
	 * @param {PIXI.Point} p1
	 * @param {PIXI.Point} p2
	 * @returns {number}
	 */
	public static cross(p1: Point, p2: Point): number {
		return p1.x * p2.y - p1.y * p2.x;
	}
	/**
	 * Get all closed contours of the given OpenType.js path data and create FancyPolygons.
	 * @param path The OpenType.js path object
	 */
	public static getClosedContours(path): FancyPolygon[] {
		let polys: FancyPolygon[] = [];
		path.commands.forEach(({type, x, y, x1, y1, x2, y2}) => {
			switch(type) {
				case PATH_COMMAND_TYPES.MOVE:
					polys.push(new FancyPolygon());
					polys[polys.length - 1].moveTo(new Point(x, y));
					break;
				case PATH_COMMAND_TYPES.LINE_TO:
					polys[polys.length - 1].moveTo(new Point(x, y));
					break;
				case PATH_COMMAND_TYPES.CUBIC_TO:
					polys[polys.length - 1].cubicTo(new Point(x, y), new Point(x1, y1), new Point(x2, y2));
					break;
				case PATH_COMMAND_TYPES.QUADRATIC_TO:
					polys[polys.length - 1].conicTo(new Point(x, y), new Point(x1, y1));
					break;
				case PATH_COMMAND_TYPES.CLOSE_PATH:
					polys[polys.length -1].close();
					break;
			}
		});
		return polys;
	}
	/**
	 * Sort the FancyPolygons by area
	 * @param {FancyPolygon[]} polys
	 */
	public static sortContoursByDescendingArea(polys: FancyPolygon[]): FancyPolygon[] {
		return polys.sort((a, b) => {
			return Math.abs(b.area) - Math.abs(a.area)
		});
	}
	/**
	 * Given the polygonal points, we need to calculate where the holes are.
	 *
	 * What is a sword without a scabbard to put it in?
	 * @param {FancyPolygon[]} polys
	 */
	public static findHoles(polys: FancyPolygon[]) {
		const root = [];
		for (let i = 0; i < polys.length; ++i) {
			let parent = null;
			for (let j = i - 1; j >= 0; --j) {
				// A contour is a hole if it is inside its parent with reverse winding
				if (polys[j].inside(polys[i].polyPoints[0]) && polys[i].area * polys[j].area < 0) {
					parent = polys[j];
					break;
				}
			}
			if (parent){
				parent.children.push(polys[i]);
			} else {
				root.push(polys[i]);
			}
		}
		return root;
	}
	/**
	 * Return the total amount of points from an array of FancyPolygons
	 * @param {FancyPolygon[]} polys
	 * @returns {number}
	 */
	public static totalPoints(polys: FancyPolygon[]) {
		return polys.reduce((sum: number, p: FancyPolygon) => sum + p.polyPoints.length, 0);
	}
	/**
	 * Processes the string and turns it into an object with flat array containing the triangulation data
	 * @param {FancyPolygon} poly The polygon to process
	 * @param {Array<number>} indexData The index data
	 * @param {Float32Array} vertexData The vertex data
	 * @param vertexCount The total vertex count
	 * @param {number} offset The indices offset (For merged geometry)
	 */
	public static process(poly: FancyPolygon, indexData: Array<number>, vertexData: Float32Array, vertexCount, offset: number = 0) {
		const coords = [];
		const holes = [];
		poly.polyPoints.forEach(({x, y}) => coords.push(x, y));
		poly.children.forEach((child: FancyPolygon) => {
			// Childs of all children are separate shapes, so handle that.
			child.children.forEach((poly) => {
				Utils.process(poly, indexData, vertexData, vertexCount, offset);
			});
			
			holes.push(coords.length / 2);
			child.polyPoints.forEach(({x, y}) => coords.push(x, y));
		});
		
		vertexData.set(coords, vertexCount.count * 2);
		// Triangulate and retrieve index data
		earcut(coords, holes).forEach((i) => {
			indexData.push(i + offset + vertexCount.count);
		});
		vertexCount.count += coords.length / 2;
	}
	/**
	 * Triangulates a string based on the provided OpenType.js path.
	 * @param path The OpenType.js path. Acquired via IFontData.getPath()
	 * @param {number} offset The indices offset.
	 */
	public static triangulate(path: any, offset: number = 0): ITriangulateData {
		let polys: FancyPolygon[] = Utils.getClosedContours(path);
		polys = Utils.sortContoursByDescendingArea(polys);
		
		let root: FancyPolygon[] = Utils.findHoles(polys);
		
		let totalPoints: number = polys.reduce((sum, p) => sum + p.polyPoints.length, 0);
		let vertexData: Float32Array = new Float32Array(totalPoints * 2);
		let vertexCount = {count: 0};
		
		const indexData = [];
		
		root.forEach((poly: FancyPolygon) => {
			Utils.process(poly, indexData, vertexData, vertexCount, offset);
		});
		
		return {
			indexData: new Uint16Array(indexData),
			vertexData: vertexData,
			vertexCount: vertexCount.count
		}
	}
	/**
	 * Helper function to calculate trig data for a single char for ITriangulateCalcData
	 * @param {IFontData} fontData
	 * @param {string} char
	 * @param {number} fontSize
	 */
	public static triangulateChar(fontData: IFontData, char: string, fontSize = 44){
		if (char === "↵"){return}
		const pathData = fontData.getPath(char, 0, 0, fontSize);
		const trigData: ITriangulateData = Utils.triangulate(pathData, 0);
		let data: ITriangulateCalcData = {vertexData: [], indexData: [], vertexCount: 0};
		
		data.vertexData = Array.from(trigData.vertexData);
		data.indexData = Array.from(trigData.indexData);
		data.vertexCount = trigData.vertexCount;
		
		return data
	}
	/**
	 * Same as `Utils.concat` except this one returns an
	 * array of arrays if the models have indices. This is because
	 * WebGL can only handle 16bit indices (ie, < 65536) So, as it
	 * is concatenating, if the data would make indices > 65535 it
	 * starts a new set of arrays.
	 *
	 * @param arrayOfArrays Arrays to concat
	 */
	public static concatLarge(arrayOfArrays){
		//TODO: Implement
	}
	
	public static concat(arrayOfArrays) {
		//TODO: Implement
	}
}

(<any>window).FontUtils = Utils;