import { Shader } from "pixi.js";
// Import the shaders
const vertexBatchedSource = require('./shaders/vertBatched.glsl');
const fragmentSource = require('./shaders/frag.glsl');
/**
 * The Font Shader is responsible for compiling the vertex and fragment shaders.
 * It extends the PIXI.Shader class to take advantage of helpful utility methods for managing
 * the WebGLProgram.
 */
export class FontShader extends Shader {
	constructor(gl: WebGLRenderingContext) {
		super(gl, vertexBatchedSource, fragmentSource);
		
		this._setInitialUniforms();
	}
	/**
	 * Sets the initial u_color uniform value to Float32Array([1.0, 1.0, 1.0, 1.0])
	 */
	private _setInitialUniforms(): void {
		this.bind();
		this.uniforms.u_color = new Float32Array([1.0, 1.0, 1.0, 1.0])
	}
}