import {Component, AfterViewInit, ChangeDetectionStrategy, ViewChild, OnDestroy, ChangeDetectorRef, ElementRef} from '@angular/core';
import { PlanState, StateEvents } from "../../common/services/plan-state.service";
import {SessionSettings, sessionStorage, SessionStorage} from "../../common/services/SessionStorage";
import { LaborCountApplication, LABOUR_COUNT_SETTINGS } from "./renderer/LaborCountApplication";
import { SelectItem } from "primeng/api";
import { ViewportChangedEvent } from "../../canvas-renderer/core/Camera";
import { takeUntil, map, distinctUntilChanged } from "rxjs/operators";
import {Subject, combineLatest, BehaviorSubject} from "rxjs";
import {CREW_SIZES_CLOSED_MESSAGE, CREW_SIZES_ZOOM_IN_MESSAGE} from "../../common/strings";
import {AuthService} from "../../../js/services/auth-service";

export enum LABOR_COUNT_FILTER_VALUES{
	ALL_ROLES = "allRoles",
	MY_ROLE = "myRole"
}

@Component({
	selector: 'labour-count',
	template: require('./labour-count.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class LabourCountComponent implements AfterViewInit, OnDestroy {
	@ViewChild('canvasChart') canvasChart;
	@ViewChild('container') container;
	public filterOptions: SelectItem[] = [];
	
	public selectedRoleFilter: string = "";

	public zoomInMessage: string = CREW_SIZES_ZOOM_IN_MESSAGE;
	public zoomtextHidden$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public isHidden$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	public shutdownSubject$: Subject<boolean> = new Subject();
	public sessionStorage: SessionStorage;
	public application: LaborCountApplication;

	private _lastShow: boolean = null;
	constructor(public planState: PlanState, private authService: AuthService) {
		this.sessionStorage = sessionStorage;

		this.filterOptions = [
			{label: "All Roles", value: LABOR_COUNT_FILTER_VALUES.ALL_ROLES},
			{label: "My Role", value: LABOR_COUNT_FILTER_VALUES.MY_ROLE}
		];
		
		this.selectedRoleFilter = "";
	}
	
	public filterChanged(newValue): void {
		this.selectedRoleFilter = newValue.value;
		if (this.application){
			window.analyticsService.setCrewSizesFilter(this.planState.planId, this.selectedRoleFilter);
			this.application.updateFilter(this.selectedRoleFilter);
		}
	}
	
	ngAfterViewInit() {
		let unsub = this.planState.stateChange$.subscribe((stateEvent: StateEvents) => {
			if (stateEvent === StateEvents.FULLY_LOADED){
				this.setup();
			}

			if (stateEvent === StateEvents.BOOMED) {
				unsub.unsubscribe();
			}
		});
	}
	
	ngOnDestroy(){
		this.shutdownSubject$.next(true);
		if (this.application){
			this.application.destroy();
		}
	}
	
	public setup(): void {
		// Initialize the labour count render
		this.application = new LaborCountApplication(this.planState, {
			width: window.innerWidth,
			height: LABOUR_COUNT_SETTINGS.HEIGHT,
			view: this.canvasChart.nativeElement,
			transparent: true,
			resolution: (window.devicePixelRatio && window.devicePixelRatio >= 1) ? window.devicePixelRatio : 1,
			autoResize: false,
			roundPixels: true
		});

		// MOC-3189
		this.sessionStorage.showLaborCount = this.authService.userPreferences.showLaborCount;

		// Watch the plan camera for changes. Do this so we can move the view labor container around to accomodate space for DOW text
		this.planState.canvasConnector.camera.changed$.pipe(
			takeUntil(this.shutdownSubject$)
		).subscribe((newValues: ViewportChangedEvent) => {
			// This makes the crew sizes renderer 'responsive'
			if (this.application && newValues.scale.x <= LABOUR_COUNT_SETTINGS.SCALE_FOR_DAYS){
				this.application.drawDays = false;
			} else if (this.application) {
				this.application.drawDays = true;
			}

			if (newValues.scale.x <= LABOUR_COUNT_SETTINGS.MAX_ZOOM_LEVEL){
				this.zoomtextHidden$.next(false);
			} else {
				this.zoomtextHidden$.next(true);
			}
		});

		this.sessionStorage.changed$.pipe(
			takeUntil(this.shutdownSubject$),
			distinctUntilChanged()
		).subscribe((settings: SessionSettings) => {
			// Crew Sizes must be enabled on project or server AND user must have crew sizes showing
			if (settings.crewSizesEnabled && settings.showLaborCount){
				this.isHidden$.next(false);
				this.application.active = settings.showLaborCount;
				this.application.update(this.application._cachedDays, true);
				if (settings.crewSizesEnabled !== this._lastShow) {
					window.analyticsService.showCrewSizes(this.planState.planId, this.selectedRoleFilter);
					this._lastShow = settings.crewSizesEnabled;
				}
				// MOC-3189
				this.saveHiddenPreferences(true);
			} else if (this.application) {
				this.isHidden$.next(true);
				this.application.active = settings.showLaborCount;
				// MOC-3189 - Only turn this off if project has crewSizes enabled
				if (settings.crewSizesEnabled){
					this.saveHiddenPreferences(false);
				}
			}
		});
	}
	
	public closeClicked(): void {
		Logging.notice(CREW_SIZES_CLOSED_MESSAGE);
		sessionStorage.showLaborCount = false;
		this.saveHiddenPreferences(false);
	}
	
	private saveHiddenPreferences(isVisible: boolean): void {
		this.authService.userPreferences.set("showLaborCount", isVisible);
	}
}