import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Role } from "../../models/Role";
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
@Component({
	selector: 'multi-role-select',
	template: require('./multi-role-select.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.Default
})
export class MultiRoleSelectComponent implements OnInit {
	@Input() roleList$: Subject<Map<string, Role>>;
	@Input() styleClass = 'tp-multiselect';
	@Input() dropdownIcon = "fa fa-fw fa-caret-down";
	@Input() panelStyleClass = '';
	@Input() placeholder = 'All Roles';
	@Input() sortAlphabetically = true;
	@Output() roleChanged: EventEmitter<any> = new EventEmitter();

	public roleList: SelectItem [] = [];
	public currentRole: Role = null;

	constructor() { }

	ngOnInit() {
		this.roleList$.subscribe((newRoles: Map<string, Role>) => {
			const sortedRoles = (this.sortAlphabetically) ? new Map([...newRoles.entries()].sort((a, b) => {
				const firstRoleName: string = a[1].roleName.toLowerCase();
				const secondRoleName: string = b[1].roleName.toLowerCase();
				return firstRoleName.localeCompare(secondRoleName);
			})) : newRoles;

			this.roleList = [];
			sortedRoles.forEach((role: Role, roleId: string) => {
				this.roleList.push({
					label: role.roleName,
					value: role
				});
			});
		});
	}

	public changed(event) {
		this.roleChanged.emit(event);
	}
}
