import { Graphics, Point } from "pixi.js";
/**
 * The FancyGraphics class extends PIXI.Graphics and contains new functionality. Decided to create an extended class
 * instead of monkey-patching the new functionality in.
 */
export class FancyGraphics extends Graphics{
	constructor(nativeLines: boolean = false){
		super(nativeLines);
		
		Graphics.CURVES.adaptive = false;
	}
	/**
	 * Draws a diamond and returns
	 * @param {number} x - The left point of the diamond
	 * @param {number} y - The left point of the diamond
	 * @param {number} width - The width of the diamond
	 * @param {number} height - The height of the diamond
	 */
	public drawDiamond(x: number, y: number, width: number, height: number): FancyGraphics {
		// Create points
		const rightPoint: Point = new Point(
			x + width,
			y
		);
		const bottomPoint: Point = new Point(
			x + (width / 2),
			y + (height / 2)
		);
		const topPoint: Point = new Point(
			x + (width / 2),
			y - (height / 2)
		);
		// Move to the left point (x and y)
		this.moveTo(x, y);
		this.lineTo(topPoint.x, topPoint.y);
		this.lineTo(rightPoint.x, rightPoint.y);
		this.lineTo(bottomPoint.x, bottomPoint.y);
		this.lineTo(x, y);
		
		return this;
	}
	/**
	 * Draws a diamond using dashes and returns FancyGraphics
	 * @param {number} x - The left point of the diamond
	 * @param {number} y - The left point of the diamond
	 * @param {number} width - The width of the diamond
	 * @param {number} height - The height of the diamond
	 * @param {number} dashLength - The length of the dash
	 */
	public drawDashedDiamond(x: number, y: number, width: number, height: number, dashLength: number = 5): FancyGraphics {
		// Create points
		const rightPoint: Point = new Point(
			x + width,
			y
		);
		const bottomPoint: Point = new Point(
			x + (width / 2),
			y + height
		);
		const topPoint: Point = new Point(
			x + (width / 2),
			y / 2
		);

		this.drawDash(x, y, topPoint.x, topPoint.y, dashLength);
		this.drawDash(topPoint.x, topPoint.y, rightPoint.x, rightPoint.y, dashLength);
		this.drawDash(rightPoint.x, rightPoint.y, bottomPoint.x, bottomPoint.y, dashLength);
		this.drawDash(bottomPoint.x, bottomPoint.y, x, y, dashLength);
		
		return this;
	}
	
	/**
	 * Draws a rectangle with dashed lines
	 * @param {number} x
	 * @param {number} y
	 * @param {number} width
	 * @param {number} height
	 * @param {number} dashLength
	 * @returns {FancyGraphics}
	 */
	public drawDashedRect(x: number, y: number, width: number, height: number, dashLength: number = 5): FancyGraphics {
		const right: number = x + width;
		const bottom: number = y + height;
		
		// Draw top line
		this.drawDash(x, y, right, y, dashLength);
		// Draw right line
		this.drawDash(right, y, right, bottom, dashLength);
		// Draw bottom line
		this.drawDash(right, bottom, x, bottom, dashLength);
		// Draw left line
		this.drawDash(x, bottom, x, y, dashLength);
		
		return this;
	}
	
	/**
	 * Draws a dashed line and returns a FancyGraphics.
	 * @param {number} x1 The start point X position
	 * @param {number} y1 The start point Y position
	 * @param {number} x2 The end point X position
	 * @param {number} y2 The end point Y position
	 * @param {number} dashLength The length of the dash
	 */
	public drawDash(x1: number, y1: number, x2: number, y2: number, dashLength: number): FancyGraphics {
		this.moveTo(x1, y1);
		
		let dX = x2 - x1;
		let dY = y2 - y1;
		let dashes = Math.floor(Math.sqrt(dX * dX + dY * dY) / dashLength);
		let dashX = dX / dashes;
		let dashY = dY / dashes;
		
		let q = 0;
		while (q++ < dashes) {
			x1 += dashX;
			y1 += dashY;
			this[q % 2 == 0 ? 'moveTo' : 'lineTo'](x1, y1);
		}
		this[q % 2 == 0 ? 'moveTo' : 'lineTo'](x2, y2);
		return this;
	}
	/**
	 * Draws a dashed quadratic curve
	 * @param {number} cpX Control point x
	 * @param {number} cpY Control point Y
	 * @param {number} toX Destination point X
	 * @param {number} toY Destination point Y
	 * @param {number} dashLength The length of the dash
	 * @returns {FancyGraphics}
	 */
	public dashedQuadraticCurveTo(cpX: number, cpY: number, toX: number, toY: number, dashLength: number = 10): FancyGraphics{
		if (this.currentPath) {
			if (this.currentPath.shape.points.length === 0) {
				this.currentPath.shape.points = [0, 0];
			}
		}
		else {
			this.moveTo(0, 0);
		}
		
		const points = this.currentPath.shape.points;
		let xa = 0;
		let ya = 0;
		if (points.length === 0) {this.moveTo(0, 0);}
		
		const fromX = points[points.length - 2];
		const fromY = points[points.length - 1];
		let dX = fromX - toX;
		let dY = fromY - toY;
		const n = Math.floor(Math.sqrt(dX * dX + dY * dY) / dashLength);
		
		let q = 0;
		while (q++ < n) {
			const j = q / n;
			xa = fromX + ((cpX - fromX) * j);
			ya = fromY + ((cpY - fromY) * j);
			this[q % 2 == 0 ? 'moveTo' : 'lineTo'](xa + (((cpX + ((toX - cpX) * j)) - xa) * j), ya + (((cpY + ((toY - cpY) * j)) - ya) * j));
		}
		
		this.dirty++;
		
		return this
	}
	/**
	 * Update the transformation matrix on this object and all children. May need to optimize this.
	 */
	public updateTransform(){
		// Remove the check because it is breaking masks
		//if (this.visible && this.renderable){
			super.updateTransform();
		//}
	}
}
