//some misc firebase related utilities

import * as firebase from "firebase/app";
// import { Reference } from "@firebase/database-types";

import environment from 'ng2/common/environment';

export function baseRef(path?:string) : firebase.database.Reference{
	if(path){return firebase.database().ref(environment.refUrl).child(path);}
	else{ firebase.database().ref(environment.refUrl) }
}

/**
 * a "safe" way of mapping data into an object from a firebase update
 * @param  targetObject [description]
 * @param  key          [description]
 * @param  shouldAdd    [description]
 * @param  data         [description]
 * @return              [description]
 */
export function	addOrRemoveFirebaseProperty(targetObject, key, shouldAdd, data){
	if(shouldAdd){ targetObject[key] = data; }
	else{ delete targetObject[key]; }
}

export function isRef(x: any):x is firebase.database.Reference{
	return (<any>x).ref !== undefined;
}
