"use strict";

import * as angular from "angular";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import Hud from "js/lib/ui/Hud";

import Point from "js/lib/math/Point";


import activeLineHandling from "js/gesture_handling/active_line_handling";
import planHandling from "js/gesture_handling/plan_handling";
import promisePeriodHandling from "js/gesture_handling/promise_period_handling";
import ticketHandling from "js/gesture_handling/ticket_handling";



//declare const Hammer:any;
const Hammer = require("js/vendor/hammer/hammer");

declare const window:any;

/*
-----------------------------------------------------------------------------------
Important Note
-----------------------------------------------------------------------------------
Until all target devices support touch-action or hammer provides a better
means of preventing preventDefault this file requires a custom version of hammer.js.
This custom version is the sort that is unlikely to be accepted as a pull request.

When updating hammer the follow block of code needs to be inserted near the top of
the preventDefaults function of the src/touchaction.js file.

        if (this.manager.session.preventionDisabled ||
            (this.manager.options.shouldNotPreventDefaults &&
            this.manager.options.shouldNotPreventDefaults(input))) {
            this.manager.session.preventionDisabled = true;
            return;
        }
		
This opens up an api where a second function can be passed into the hammer
init function which gets called before any defaults are prevented to
give you a chance to react and prevent the prevention of defaults.


*/


var lastMessage = "";
function simpleLog(message)
{
	var enable = false;
	if(enable && lastMessage != message)
		console.log(message);
	
	lastMessage = message;
}
/**
 * implements all the gesture handling for the
 * plan and the sticky notes therein.  This directive gets applied
 * to the outermost <div> in the pull plan hierarchy, and hammer is
 * bound to this element.  This means we'll detect the various gestures
 * on the top level container (the plan) as well as the things contained
 * therein (notes, note fields, etc.).
 *
 * Elements themselves are tagged with a type in the HTML via the custom
 * data attributes "data-element-type".  The combination of the gesture
 * type and element type is used to dispatch to the appropriate handler.
 *
 * Thus if you tap on a sticky, dispatch will be to the tapStickyHandler,
 * if you tap on the plan it will be to the tapPlanHandler, etc.
 *
 * Element types are in the array KNOWN_ELEMENT_TYPES.  Tag elements like
 * so in the HTML to work in concert with this scheme:
 *
 *           data-element-type = "xxx" (xxx is one of the known types)
 *
 */
angular.module('ticketspaceApp').directive('planGestureManager', ["$timeout", "$document", "$compile", "focusManager", function ($timeout, $document, $compile, focusManager)
{
	return {
		restrict: 'A',
		link: function (scope:any, element, attr)
		{

			scope.$on('$destroy', function(){
				if(hammertime){ //one less error when everything breaks...
					hammertime.stop(true);
					hammertime.destroy();
				}
			});
			
			//called whenever something starts, clears assorted pre-defined things
			//that are supposed to clear on "doing something else"
			//TODO - add more shit
			function stopDoingSomethingElse(){
				scope.acceptRegionApi.close();
			}
			
			//--------- Function globals --------
			
			//todo - trim down this list
			var KNOWN_ELEMENT_TYPES = [
				'plan', 'sticky', 'stickyField', 'floating-ticket', 'milestone',
				'timeline-ticket-redirect', 'constraintLog', 'constraintLog-element', 'pin',
				'roleSelection', 'constraintLog-element-draggable',
				'editToolbarDiv', 'dependencyBar', "roleSelector", 'activeLine', 'activeLineNub', 'promise-period-handle',
				'promise-period-handle-left', 'promise-period-handle-right', 'timeline-bar', 'timeline-ticket', 'projectView',
				'timeline-group', 'timeline-group-ticket', 'overlay-toolbar',
				'blackout', 'none'
			];
			
			//defines the distance from the side of the screen that will be considered "the edge"
			var THE_EDGE = 100;
			
			var hud = scope.hud;
			// var hud = scope.hud = new Hud(
			// 	$(element), //container
			// 	$(".zoomContent",element), //surface
			// 	$(".zoomContent-overlay",element), //overlay
			// 	$(".zoomContent-underlay-dependencies",element), //underlay
			// 	scope
			// );
			
			//references to the broken out handling elements
			var planH = new planHandling(scope);
			var ticketH = new ticketHandling(scope);
			var activeLineH = new activeLineHandling(scope);
			var promisePeriodH = new promisePeriodHandling(scope);
			
			window.legacyTicketHandling = ticketH;

			//contains information worth remembering about hammer events
			var status:any = {
				somethingHappening: false,
				action: '',
				elementType: '',
				element: null,
				//constants, vaguely replicate an enum
				FAIL: -1,
				DRAG_PLAN: 1,
				PINCH_PLAN: 2,
				HOLD_PLAN: 3,
				DRAG_TICKET: 4,
				HOLD_TICKET: 5,
				HOLD_TIMELINE_TICKET: 6,
				HOLD_TIMELINE_GROUP_TICKET: 15,
				HOLD_CONSTRAINT: 7,
				DRAG_CONSTRAINT: 8,
				DRAG_ACTIVE_LINE: 9,
				DRAG_PROMISE_PERIOD: 10,
				DRAG_TIMELINE_BAR: 11,
				DRAG_PROMISE_PERIOD_LEFT: 12,
				DRAG_PROMISE_PERIOD_RIGHT: 13,
				DRAG_FLOATING_TICKET: 14,
				
				//eObj is the "firstMatch", which is an object
				//containing element and elementType keys
				set: function(action, eObj, startEvent){
					this.action = action;
					this.elementType = eObj.elementType;
					this.element = eObj.element;
					this.startEvent = startEvent
					this.somethingHappening = true;
					return eObj;
				},
				clear: function(){
					this.somethingHappening = false;
					this.elementType = '';
					this.element = null;
					this.startEvent = null;
					this.action = '';
				},
				fail: function(allGood){
					if(allGood === false){ this.action = this.FAIL; }
				}
			};
			
			window.debugStatus = {
				status: status,
				clear: function(){
					status.clear();
					status.oneEventStarted = false;
				}
			};
			
			
			//try and make firefox work...
			// Hammer.defaults.domEvents = true;
			// Hammer.defaults.touchAction = 'none';
			// Hammer.defaults.cssProps.userSelect = 'all';
			// Hammer.defaults.cssProps.userDrag = 'all';
			
			// Set up hammer on the element
			var hammertime = new Hammer.Manager(element[0], {
				//touchAction:'auto',
				recognizers: [
					[Hammer.Pinch],
					[Hammer.Pan],
					[Hammer.Tap, {event: 'doubletap', taps: 2, threshold: 7, posThreshold: 25}],
					[Hammer.Tap, {event: 'singletap', threshold: 7}],
					[Hammer.Press, {time: 333, threshold: 3}]
					
					
				],
				shouldNotPreventDefaults: function(input){
					//console.log('input', input);
					var firstMatch = findFirstMatchingElementType(input.target, ['editToolbarDiv', 'roleSelector', 'none','constraintLog-element-draggable'])
					//console.log(firstMatch);
					
					return firstMatch.elementType === 'editToolbarDiv'
						|| firstMatch.elementType === 'roleSelector'
						|| firstMatch.elementType === 'none'
				}
			});
			//can't add during creation since doubletap doesn't exist at the time
			//hammertime.get('doubletap').recognizeWith('singletap');
			//hammertime.get('singletap').requireFailure('doubletap');

			var first;
			//fixes the target for events that have stupid detection
			function fixTarget(event){
				if(event && event.target && first && first.target){
					event.hammerTarget = event.target;
					event.target = first.target;
				}
			}
			
			//elementTypes optional
			function genericStart(event, elementTypes?){
				if(status.somethingHappening){return false;}
				if(status.oneEventStarted){ return false;}

				status.oneEventStarted = true;
				fixTarget(event);
				var firstMatch = findFirstMatchingElementType(event.target, elementTypes || KNOWN_ELEMENT_TYPES);

				if(firstMatch.elementType !== "none"){
					focusManager.update(firstMatch.elementType);
					//console.log('hi', firstMatch.elementType);
					stopDoingSomethingElse();
				}

				if(event.type==='pinchstart'){
					switch(firstMatch.elementType){
						// case 'plan': 
						// 	return status.set(status.PINCH_PLAN,firstMatch,event);
						default: return false;
					}
				}
				else if(event.type === 'panstart'){
					switch(firstMatch.elementType){
						// case 'plan': 
						// 	return status.set(status.DRAG_PLAN,firstMatch,event);
						case 'sticky':
							return status.set(status.DRAG_TICKET,firstMatch,event);
						case 'floating-ticket':
							return status.set(status.DRAG_FLOATING_TICKET,firstMatch,event);
						case 'constraintLog-element-draggable':
							return status.set(status.DRAG_CONSTRAINT,firstMatch,event);
						case 'activeLineNub':
								firstMatch = {
									elementType: 'activeLineNub',
									element: $('#the-real-active-line')
								}
						case 'activeLine':
							return status.set(status.DRAG_ACTIVE_LINE,firstMatch,event);
						case 'promise-period-handle':
							return status.set(status.DRAG_PROMISE_PERIOD,firstMatch,event);
						case 'promise-period-handle-left':
							return status.set(status.DRAG_PROMISE_PERIOD_LEFT,firstMatch,event);
						case 'promise-period-handle-right':
							return status.set(status.DRAG_PROMISE_PERIOD_RIGHT,firstMatch,event);
						// case 'timeline-bar':
							// return status.set(status.DRAG_TIMELINE_BAR,firstMatch,event);
						case 'editToolbarDiv':
							event.srcEvent.preventDefault(); break;
						default: return false;
					}
				}
				else if(event.type === 'press'){
					//console.log( "Element Type", firstMatch.elementType );
					switch(firstMatch.elementType){
						//case 'sticky': return status.set(status.HOLD_TICKET,firstMatch,event);
						case 'sticky': status.oneEventStarted = false; return false;
						case 'activeLineNub': status.oneEventStarted = false; return false;
						case 'activeLine': status.oneEventStarted = false; return false;
						// case 'timeline-ticket': return status.set(status.HOLD_TIMELINE_TICKET,firstMatch,event);
						// case 'timeline-group-ticket': return status.set(status.HOLD_TIMELINE_GROUP_TICKET,firstMatch,event);
						case 'timeline-ticket': status.oneEventStarted = false; return false;	
						case 'timeline-group-ticket': status.oneEventStarted = false; return false;	
						case 'constraintLog-element': return status.set(status.HOLD_CONSTRAINT,firstMatch,event);
						default: return false;
					}
				}
				// else if(event.type === 'pressup'){
				// 	switch(firstMatch.elementType){
				// 		case 'sticky': return status.set(status.HOLD_TICKET,firstMatch,event);
				// 		default: return false;
				// 	}
				// }
				return false;
			}
			
			//----------------------------------------
			// PINCH / TRANSFORM SECTION
			//----------------------------------------
			hammertime.on('pinchstart', function(event){
				if (!focusManager.match('plan')){return;}
				var match = genericStart(event, ['plan']);
				if(!match){ return; }
				simpleLog('pinchstart');
				status.fail(planH.transformStart(event, match.element));
				//console.log('pinchstart');
			});
			hammertime.on('pinchmove', function(event){
				if (!focusManager.match('plan')){return;}
				if (status.action === status.PINCH_PLAN){
					planH.transform(event, status.element);
				}
			});
			hammertime.on('pinchend pinchcancel', function(event){
				if (!focusManager.match('plan')){return;}
				if (status.action === status.PINCH_PLAN){
					planH.transformEnd(event, status.element);
					status.clear();
				}
				simpleLog('pinchend');
			});
			
			//----------------------------------------
			// PAN/DRAG SECTION
			//----------------------------------------
			hammertime.on('panstart', function(event){
				//console.log(event);
				simpleLog('panstart');
				//this method is "sometimes" called after a hold
				//so just ignore if it does...
				if(status.action === status.HOLD_PLAN){return;}
				var match = genericStart(event);
				//console.log('draggin', match, status.action);
				if(!match){ return; }
				simpleLog('panstart');
				switch(status.action){
					case status.DRAG_PLAN:
						if(event.srcEvent.shiftKey){
						}
						else{ status.fail(planH.dragStart(event, status.element)); }
						break;
					case status.DRAG_TICKET:
						status.fail(ticketH.dragStart(event, status.element, "tickets"));
						break;
					case status.DRAG_FLOATING_TICKET:
						status.fail(ticketH.dragStart(event, status.element, "floatingTickets"));
						break;
					case status.DRAG_ACTIVE_LINE:
						status.fail(activeLineH.dragStart(event, status.element));
						break;
					case status.DRAG_TIMELINE_BAR:
						// status.fail(planH.scrollStart(event, status.element));
						break;
					case status.DRAG_PROMISE_PERIOD:
						status.fail(promisePeriodH.dragStart(event, status.element));
						break;
					case status.DRAG_PROMISE_PERIOD_LEFT:
						status.fail(promisePeriodH.scaleStart(event, status.element, "left"));
						break;
					case status.DRAG_PROMISE_PERIOD_RIGHT:
						status.fail(promisePeriodH.scaleStart(event, status.element, "right"));
						break;
					default: console.log('unknown event');
				}
				
				//console.log('panstart');
			});
			hammertime.on('pan', function(event){
				simpleLog('pan');
				switch(status.action){
					case status.DRAG_PLAN:
						planH.drag(event, status.element);
						break;
					case status.DRAG_TICKET:
					case status.DRAG_FLOATING_TICKET:
						status.fail(ticketH.drag(event, status.element));
						break;
					case status.DRAG_ACTIVE_LINE:
						status.fail(activeLineH.drag(event, status.element));
						break;
					case status.DRAG_TIMELINE_BAR:
						// status.fail(planH.scroll(event, status.element));
						break;
					case status.DRAG_PROMISE_PERIOD:
						status.fail(promisePeriodH.drag(event, status.element));
						break;
					case status.DRAG_PROMISE_PERIOD_LEFT:
						status.fail(promisePeriodH.scale(event, status.element, "left"));
						break;
					case status.DRAG_PROMISE_PERIOD_RIGHT:
						status.fail(promisePeriodH.scale(event, status.element, "right"));
						break;
					default: break;
				}
			});
			hammertime.on('panend', function(event){
				simpleLog('panend');
				switch(status.action){
					case status.DRAG_PLAN:
						planH.dragEnd(event, status.element);
						status.clear();
						break;
					case status.DRAG_TICKET:
					case status.DRAG_FLOATING_TICKET:
						ticketH.dragEnd(event, status.element);
						status.clear();
						break;
					case status.DRAG_ACTIVE_LINE:
						activeLineH.dragEnd(event, status.element);
						status.clear();
						break;
					case status.DRAG_TIMELINE_BAR:
						// planH.scrollEnd(event, status.element);
						status.clear();
						break;
					case status.DRAG_PROMISE_PERIOD:
						promisePeriodH.dragEnd(event, status.element);
						status.clear();
						break;
					case status.DRAG_PROMISE_PERIOD_LEFT:
						promisePeriodH.scaleEnd(event, status.element, "left");
						status.clear();
						break;
					case status.DRAG_PROMISE_PERIOD_RIGHT:
						promisePeriodH.scaleEnd(event, status.element, "right");
						status.clear();
						break;
					default: break;
				}
				//if(event.isFinal){status.clear();}
			});
			
			var singleTapTimeouts = [];
			function clearSingleTaps(){
				singleTapTimeouts.forEach(function(val){clearTimeout(val);});
				singleTapTimeouts = [];
			}
			//----------------------------------------
			// TAP SECTION
			//----------------------------------------
			//todo
			//list of removed events to be re-implemented as ng-clicks
			//- roleSelection
			hammertime.on('singletap', function(event){
				focusManager.update(findFirstMatchingElementType(event.target, KNOWN_ELEMENT_TYPES).elementType);
				if (!focusManager.match('plan')){return;}
				simpleLog('singletap');
				//console.log('status', status);
				if(status.somethingHappening){return;}
				fixTarget(event);
				singleTapTimeouts.push(setTimeout(function(){
					var firstMatch = findFirstMatchingElementType(event.target, KNOWN_ELEMENT_TYPES)
					
					switch (firstMatch.elementType){
						case 'sticky':
							break;
						case 'floating-ticket':
							ticketH.tap(event, firstMatch.element);
							break;
						case 'timeline-group-ticket':
							var timelineData = firstMatch.element.timelineData;
							if(timelineData){
								firstMatch.element.idxData = timelineData.constraint._debugIdxData;
							}
						case 'timeline-ticket':
							if(scope.accessCheck.isReadOnly()){ scope.readOnlyReaction(); return; }
							console.log(firstMatch.element.timelineData.constraint.$id)
							scope.planState.actions.simpleDependencyToggle(firstMatch.element.timelineData.constraint.$id);
							break;
						case 'timeline-ticket-redirect':
							var ticket = firstMatch.element.ticket;
							var ticketType = ticket.constraint.data.type || "constraint";
							scope.popup({"title": "Would you like to leave this plan and go to the " + ticketType + "?"}).then(function(){
								scope.gotoNewPlan(ticket.constraint._debugIdxData.planId,ticket.constraint._debugIdxData.ticketId);
							});
							break;
						case 'timeline-group':
							var e = firstMatch.element;
							if(e.timelineList){
								if(scope.lastTimelineGroupMenu){
									scope.lastTimelineGroupMenu.clear();
								}
								else{
									$timeout(function(){
										var childScope = scope.$new();
										childScope.list = e.timelineList;
										var groupElement = $compile('<timeline-ticket-overflow></timeline-ticket-overflow>')(childScope);
										e.parentNode.appendChild(groupElement[0]);
										groupElement[0].style.left = e.style.left;
										scope.lastTimelineGroupMenu = {e: groupElement, s: childScope, clear: function(){
											scope.lastTimelineGroupMenu.s.$destroy();
											scope.lastTimelineGroupMenu.e.remove();
											scope.lastTimelineGroupMenu = null;
										}};
									})

								}
							}
							break;
						case 'milestone':
							scope.showFloatingMilestoneCal();
							break;
						case 'blackout':
							scope.ticketEditService.close();
							// scope.$applyAsync();
							break;
						case 'plan':
							if(scope.lastTimelineGroupMenu){
								scope.lastTimelineGroupMenu.clear();
							}
							 //revert this to calling plan_handling's tap
							planH.tap(event, firstMatch.element);
							scope.lastTimelineGroupMenu = null;
							
							//scope.floatingRegionApi.hide();
							break;
						case 'stickyField':
							//console.log('firstMatch', firstMatch);
							break;
						case 'activeLineNub':
							break;
						case 'activeLine':
							scope.showFloatingActiveCal();
							break;
						case 'pin':
							//console.log('firstMatch', firstMatch.);
							var t = firstMatch.element.parentNode.parentNode.ticket;
							if(!t || !t.data.promisePeriodStack){break;}
							var p = t.data.promisePeriodStack[firstMatch.element.promiseId];
							if(!p || !p.varianceId){break;}
							var reason = scope.statusOptions.$getRecord(p.varianceId);
							var words = reason.data.varianceKey + " "+ reason.data.varianceReason;
							//Logging.notice(words);
							// analyticsService.promisePinClickedOnPlan();
							var newElement = $('<div class="variance-tag">'+words+'</div>');
							setTimeout(function(){
								newElement.remove();
							},3000);
							firstMatch.element.appendChild(newElement[0]);
							
							break;
						default: 
							//console.log('!!', firstMatch);
							break;
					}
				},300));
				//console.log('hammertime', event, firstMatch);
			});
			hammertime.on('doubletap', function(event){
				simpleLog('doubleTap');
				if(status.somethingHappening){return;}
				fixTarget(event);
				clearSingleTaps();
				var firstMatch = findFirstMatchingElementType(event.target, KNOWN_ELEMENT_TYPES)
				switch (firstMatch.elementType){
					case 'sticky':
					case 'floating-ticket':
						ticketH.doubleTap(event, firstMatch.element);
						break;
					case 'plan':
						planH.doubleTap(event, firstMatch.element);
						break;
					default: break;
				}
			});
			
			//----------------------------------------
			// PRESS/HOLD SECTION
			//----------------------------------------
			hammertime.on('press', function(event){
				if(scope.flags.stickyInEditMode){return}
				simpleLog('press');
				var match = genericStart(event);
				//console.log('match', match, status.action);
				if(!match){return; }
				switch(status.action){
					case status.HOLD_TICKET:
						break;
					case status.HOLD_TIMELINE_GROUP_TICKET:
					case status.HOLD_TIMELINE_TICKET:
						var td = (element as any).timelineData;
						if(td){
							scope.planState.dependencies.toggleTarget(td.constraint.$id);
						}
						break;
					case status.HOLD_CONSTRAINT:
						break;
					case status.HOLD_PLAN:
						break;
					default: break;
				}
				//else do plan check
				
			});
			//this event is highly unreliable...
			//move it's shit into the hammer.input event when the final event is detected
			hammertime.on('pressup', function(event){
				simpleLog('pressup');
				// if(scope.flags.stickyInEditMode){return}
				// var match = genericStart(event);
				var match = findFirstMatchingElementType(first.target,  KNOWN_ELEMENT_TYPES);
				//console.log('match', match, event);
				if(event.type === 'pressup'){
					if(match.elementType === "timeline-group-ticket"){
						var td = (match.element as any).timelineData;
						if(td){
							scope.planState.dependencies.toggleTarget(td.constraint.$id);
						}
					}
				}
				
				if(event.isFinal){
					status.oneEventStarted = false;
					status.clear();
				}
			});
			
			//resume - need to find a means of clearing the status after the drag end is done
			//but still not allow a new event to start until you begin an entirely new gesture...
			
			//raw events
			hammertime.on('hammer.input', function(event){
				if(window.enableHammerDebug){console.log('event', event);}
				//event.preventDefault();
				//console.log('status',status.action, event);
				if(event.isFirst){first = event;}
				if(event.isFinal){
					//first = null;
					status.oneEventStarted = false;
					//status.clear();
					simpleLog('done');
					//console.log(event.type, event);
					
					if(status.action === status.HOLD_PLAN){
					}
					else if(status.action === status.HOLD_TICKET
							|| status.action === status.HOLD_CONSTRAINT){
						status.clear();	
					} else if ( status.action === status.HOLD_TIMELINE_TICKET ){
						status.clear();
					} else if ( status.action === status.HOLD_TIMELINE_GROUP_TICKET ){
						status.clear();
					}
					else if(status.action === status.FAIL){
						status.clear();
					}
				}
			});
			
			
			//fancy hold gesture...
			
			//CANVAS_STACKING_ORDER_PROBLEM
			// MOC-2902. Delete once stable
			// (function(){
			// 	var element = $('<div class="hold-animation"></div>');
			// 	var lastPos;
			// 	$('body').append(element);
			// 	hammertime.on('hammer.input', function(e){
			// 		//return;
			// 		if(e.isFirst){
			// 			//console.log(e);
			// 			var firstMatch = findFirstMatchingElementType(e.target, KNOWN_ELEMENT_TYPES)
			// 			switch(firstMatch.elementType){
			// 				case 'sticky':
			// 					break;
			// 				case 'timeline-ticket':
			// 					break;
			// 				case 'plan':
			// 					//element.addClass('hold-animation-with-arrows');
			// 					if(scope.flags.stickyInEditMode){return}
			// 					break;
			// 				default: return;
			// 			}
			// 			lastPos = new Point(e.clientX, e.clientY);
			// 			//element.addClass('hold-animation-start');
			// 			element.css({
			// 				top: e.center.y,
			// 				left: e.center.x
			// 			});
			// 			//console.log('down');
			// 		}
			// 		else if( e.isFinal){
			// 			element.removeClass('hold-animation-start');
			// 			element.removeClass('hold-animation-with-arrows');
			// 			lastPos = null;
			// 			//console.log('up');
			// 		}
			// 		else{
			// 			//console.log('moved');
			// 			if(!lastPos){ return; }
			// 			var range = 3;
			// 			if(Math.abs(e.deltaX) >= 3
			// 					|| Math.abs(e.deltaY) >= 3){
			// 				lastPos = null;
			// 				element.removeClass('hold-animation-start');
			// 				element.removeClass('hold-animation-with-arrows');
			// 			}
			// 		}
			// 		//console.log('srcEvent', e);
			// 	});
			// })();
			
			//shh...
			hud.container.bind("touchmove", function (ev){
				//console.log('touchmove', ev);
				//ev.preventDefault();
			});
			
			$("input, button, textarea, select").on("touchstart touchmove", function(ev) {
				//console.log('!!!');
				ev.stopPropagation();
			});
			
			// This applies to the desktop only. 
			hud.container.bind("mousewheel DOMMouseScroll", function (ev) {
				if (!focusManager.match('plan')){return;}
				//abort all transforms if a sticky is being edited
				if (scope.flags.stickyInEditMode)
					return true;
				//just run the default behavior if you're hovering over the constraintLog, lets it scroll
				var firstMatch = findFirstMatchingElementType(ev.target, ["constraintLog", "dependencyBar", "roleSelector", "none", 'overlay-toolbar']);
				if (firstMatch.elementType === 'overlay-toolbar' || firstMatch.elementType == "constraintLog" || firstMatch.elementType == "dependencyBar"
					|| firstMatch.elementType == "roleSelector" || firstMatch.elementType == "none" 
					|| firstMatch.elementType == "INPUT" || firstMatch.elementType == "BUTTON" || firstMatch.elementType == "TEXTAREA"){
					return true;
				}
				
				// MOC-91: We still seem to get some spurious wheel events on the
				// iPad.  They always have a wheel delta of 0.  It seems safe to
				// ignore events with no delta (no delta means no need to change
				// the scale).  So we ignore these for the side effect of fixing
				// the spurious wheel events on the iPad.
				if (ev.wheelDelta == 0) return false;
				planH.mousewheelOnApplication(ev);
				return false;
			});
			
			// $('#canvas-view').bind("mousewheel DOMMouseScroll", function (ev) {
			// 
			// 	// MOC-91: We still seem to get some spurious wheel events on the
			// 	// iPad.  They always have a wheel delta of 0.  It seems safe to
			// 	// ignore events with no delta (no delta means no need to change
			// 	// the scale).  So we ignore these for the side effect of fixing
			// 	// the spurious wheel events on the iPad.
			// 	if ((ev as any).wheelDelta == 0) return false;
			// 	planH.mousewheelOnApplication(ev);
			// 	return false;
			// });
		}
	}
}])
.directive('gestureDisabler', function (){
	
	function link(scope, element, attr){
		console.log('woo');
		// Set up hammer on the element
		var hammertime = new Hammer.Manager(element[0], {
			touchAction:'auto',
			recognizers: [
				//[Hammer.Pinch],
				[Hammer.Pan],
				//[Hammer.Tap, {event: 'doubletap', taps: 2, threshold: 7, posThreshold: 25}],
				//[Hammer.Tap, {event: 'singletap', threshold: 7}],
				//[Hammer.Press]
			]
		});
		
		hammertime.on('pan', function(event){
			console.log('pan');
		});
	}
	
	return {
		link: link
	}
});

//------------- Private API -----------
// *
// * Takes an element and looks at it and it's ancestors to find the first
// * one that matches a known element type (via data-element-type and the
// * KNOWN_ELEMENT_TYPES).  Returns this first match along with it's type.
// *
// *
function findFirstMatchingElementType(element, knownTypes)
{
	// Look for the first matching element type and dispatch
	// accordingly.
	var currentElement = element;
	var elementType = $(currentElement).data('element-type');
	
	//abort if it's an input, should restore the ability to select text and the like...
	var type = $(currentElement).prop('tagName');
	if(type == 'INPUT' || type == 'BUTTON' || type == 'TEXTAREA')
		return {
			element: currentElement,
			elementType: type
		};
	
	
	// Loop through the element hierarchy until we find a known
	// type, or get to the outermost tag.
	while (currentElement.parentNode && !(knownTypes.indexOf(elementType) >= 0))
	{
		currentElement = currentElement.parentNode;
		elementType = $(currentElement).data('element-type');
	}
	//return our match
	return {
		element: currentElement,
		elementType: elementType
	};
}

// ----------------------------------------------------------------
//	Implementations for misc gesture handling
//	The larger segments have been shifted to their own files
//	/js/gesture_handling
// ----------------------------------------------------------------

/*
$(document).on("keydown", function (e) {
		console.log("e", e.target.innerHTML);
    if (e.which === 8 && !$(e.target).is("input:not([readonly]):not([type=radio]):not([type=checkbox]), textarea, [contentEditable], [contentEditable=true]")) {
        e.preventDefault();
    }
});
*/
