import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { RallyBaseComponent } from "../rally-base.component";
import { RallyPointSignupService } from "ng2/common/services/rally-point-signup.service";

@Component({
	selector: 'rally-login',
	template: require('./rally-login.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RallyLoginComponent extends RallyBaseComponent implements OnInit {
	public rallyForm = this.formBuilder.group({
		email: [''],
		password: ['']
	});

	constructor(
		private formBuilder: FormBuilder,
		public rallyPointSignupService: RallyPointSignupService,
		private router: Router
	) {
		super(rallyPointSignupService);
	}
	
	next(){
		this.rallyPointSignupService.startLoading(this.loadingSet);
		this.rallyPointSignupService.patchState(this.rallyForm.value);
		
		this.rallyPointSignupService.login(this.rallyForm.controls.email.value, this.rallyForm.controls.password.value).then(result => {
			this.redirect(result);
		});
	}
	
	redirectToResetPassword(){
		this.router.navigate(['reset-password']);
	}
}
