import { Texture, Circle } from "pixi.js";
import { SpriteTicket } from "./";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { TicketTypes } from "../../common/models/Ticket";
import { Side } from "../../common/models/Side";
import { ticketSchema } from "../ticket-components/";

import { IMAGE_PADDING } from "../interfaces";
/**
 * This class is the main representation of a constraint. It has a simple component api to add individual pieces associated with the same data
 */
export class SpriteConstraint extends SpriteTicket{
	public type: string = TicketTypes.CONSTRAINT;
	public isPull: boolean = false;
	/**
	 * Represents the texture that is drawn on the active side.
	 */
	private _activeConstraintTexture: Texture;
	/**
	 * Represents the texture that is drawn on the pull side.
	 */
	private _pullConstraintTexture: Texture;
	/**
	 * @param {PIXI.Texture} ticketShape
	 * @param {PIXI.Texture} ticketShapePull
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	constructor(ticketShape: Texture, ticketShapePull: Texture, planState: PlanState, ticketContainer: TicketContainer){
		super(ticketShape, planState, ticketContainer);
		this._activeConstraintTexture = ticketShape;
		this._pullConstraintTexture = ticketShapePull;
		
		const data = ticketContainer.getData();
		
		this.x = -IMAGE_PADDING.ACTIVE_SHAPE;
		this.y = -IMAGE_PADDING.ACTIVE_SHAPE;
		
		// Add the milstone schema to this ticket
		if (data.isGhost){
			ticketContainer.components.addDynamicComponents(
				ticketSchema.createGhostConstraintSchema(planState, ticketContainer)
			);
		} else {
			ticketContainer.components.addDynamicComponents(
				ticketSchema.createConstraintSchema(planState, ticketContainer)
			);
		}
	}
	/**
	 * Called when the associated DataContainer is updated.
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	public updateData(planState: PlanState, ticketContainer: TicketContainer){
		super.updateData(planState, ticketContainer);
		
		let data = ticketContainer.getData();
		if (!data){return}
		// Set the correct texture.
		if (data.view.side === Side.PULL){
			this.texture = this._pullConstraintTexture;
			this.x = -IMAGE_PADDING.PULL_SHAPE;
			this.y = -IMAGE_PADDING.PULL_SHAPE;
			this.width = data.view.width + IMAGE_PADDING.PULL_SHAPE*2;
			this.height = data.view.height + IMAGE_PADDING.PULL_SHAPE*2;
			this.isPull = true;
			ticketContainer.SURFACE.hitArea = new Circle(data.view.width / 2, data.view.height / 2, 150);
		}else{
			this.texture = this._activeConstraintTexture;
			this.x = -IMAGE_PADDING.ACTIVE_SHAPE;
			this.y = -IMAGE_PADDING.ACTIVE_SHAPE;
			this.width = data.view.width + IMAGE_PADDING.ACTIVE_SHAPE*2;
			this.height = data.view.height + IMAGE_PADDING.ACTIVE_SHAPE*2;
			this.isPull = false;
			ticketContainer.SURFACE.hitArea = new Circle(data.view.width / 2, data.view.height / 2, 75);
		}
		
		//this.alpha = 0;
	}
}
