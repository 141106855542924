import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";

import { RallyBaseComponent } from "../rally-base.component";
import { RallyPointSignupService } from "ng2/common/services/rally-point-signup.service";

import {titleFields} from "ng2/common/constants/title-fields";

@Component({
	selector: 'rally-signup',
	template: require('./rally-signup.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RallySignupComponent extends RallyBaseComponent implements OnInit {
	public titleFields = titleFields;
	public rallyForm = this.formBuilder.group({
		email: [{value:'', disabled: true}, Validators.required],
		firstName: ['', Validators.required],
		lastName: ['', Validators.required],
		company: ['', Validators.required],
		password: ['', Validators.required],
		password2: ['', Validators.required],
		phone: ['', Validators.required],
		title: ['', Validators.required],
		terms: ['', Validators.required],
	});

	constructor(
		private formBuilder: FormBuilder,
		public rallyPointSignupService: RallyPointSignupService
	) {
		super(rallyPointSignupService);
	}
	
	next(){
		if(this.rallyForm.invalid){
			Logging.warning("Please fill in all of the fields."); return;
		}
		this.rallyPointSignupService.startLoading(this.loadingSet);
		this.rallyPointSignupService.patchState(this.rallyForm.value);
		
		this.rallyPointSignupService.signup(this.rallyPointSignupService.rallyStateSync).then(result => {
			
			this.redirect(result);
		});
	}
}
