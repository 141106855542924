import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PlanState } from "../../../services/plan-state.service";
import {dateCache, DateCache} from "../../../date-cache";
import {BehaviorSubject, concat, Observable, of} from "rxjs";
import {Router} from "@angular/router";
import {AuthService} from "../../../../../js/services/auth-service";
import {AnalyticsService} from "../../../utils/AnalyticsService";
import {IntercomService} from "../../../services/intercom.service";
import {You} from "../../../models/You";
import {ProjectMember} from "../../../models/ProjectMember";
import {SessionStorage, sessionStorage} from "../../../services/SessionStorage";

@Component({
	selector: 'nav-bar-plan',
	template: require('./nav-bar-plan.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarPlanComponent implements OnInit {
	private dateCache: DateCache = dateCache;
	public lagBehindHide: boolean = false;
	public statusHidden$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public analyticsService: AnalyticsService;
	public projectMembers: any;
	public sessionStorage: SessionStorage = sessionStorage;
	constructor(private planState: PlanState, public router: Router, public authService: AuthService, public intercomService: IntercomService) {
		this.analyticsService = window.analyticsService;
	}

	ngOnInit() {
		window.PlanBar = this;
		this.planState.projectMembers.list$.subscribe((projectMembers: Map<string, ProjectMember>) => {
			this.projectMembers = projectMembers;
		});
	}
	/**
	 * whoAreYou returns an Observable using youSync and you because you is not a BehaviourSubject
	 * So you can have a case where you fires before role changer subscribes, so initial role it not set correctly.
	 */
	public whoAreYou(): Observable<You>{
		return concat(of(this.planState.youSync), this.planState.you);
	}
	
	public hidePlanStatus(): void {
		this.statusHidden$.next(true);
	}
	
	public showPlanStatus(): void {
		this.statusHidden$.next(false);
	}
	
	public navigate(path: string[]): void {
		this.router.navigate(path);
	}
	
	public logout(): void {
		this.authService.logout();
	}
	
	public toggleSearchExpand(): void {
		this.planState.scopeSoup.searchApi.toggleSmallExpandMenu();
	}
	
	public getProjectMemberName(projectMemberId: string): string {
		if (this.planState.projectId === "SampleProjectV3"){ projectMemberId = "billBelichicklProjectMemberId";}
		let pm = this.projectMembers.get(projectMemberId);
		return (pm && pm.user)? pm.user.firstName + " " + pm.user.lastName : "No Active User";
	}
}
