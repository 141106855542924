// Created by kdo on 7/21/15.

'use strict';
import * as angular from "angular";

angular.module('ticketspaceApp')
//greatly dislike this name...can't think of anything better though
// Removed firebaseIndices, firebaseProjects, firebaseUsers
.factory('firebaseProjectManager', ["$q", "$log", "firebaseRoles", "firebaseProjectMembers", function($q, $log, firebaseRoles, firebaseProjectMembers) {
	var mainProjectManager;

	function ProjectManager(projectId){
		//Load Roles, Project Members and other data.
		//whatever else we need.
		this.roles = firebaseRoles(projectId);
		this.projectMembers = firebaseProjectMembers(projectId)
		
		var self = this;
		
		this.loadedPromise = $q.all([this.roles.fb.$loaded(), this.projectMembers.fb.$loaded()]);
	}
	
	ProjectManager.prototype = {
		$loaded: function(){ return this.loadedPromise; }
		
	}

	//I don't think we really need any more functions since the data will be doing

	return {
		createNewProjectManager: function (projectId) {
			mainProjectManager = new ProjectManager(projectId);

			return mainProjectManager;
		},
		getProjectManager:function() {
			return mainProjectManager;
		}
	};
}]);
