import {NgModule} from '@angular/core';
import {CanvasViewComponent} from "./components/canvas-view.component";
/**
 * @module CanvasView
 * @description Angular 5 component which creates the canvas renderer
 */
@NgModule({
	imports: [],
	declarations: [CanvasViewComponent],
	entryComponents: [CanvasViewComponent],
	exports: [CanvasViewComponent]
})

export class CanvasViewModule{}