import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Side } from "../../common/models/Side";
import { Ticket } from "../../common/models/Ticket";
import { FancyText } from "../fontpath/FancyText";
import { rendererInfo } from "../core/RendererInfo";
import { HighResolutionText } from "../text/HighResolutionText";

export enum TICKET_DURATION_DAYS_SETTINGS {
	// padding-bottom: 20px
	// Align to bottom-left, then offset the height and 20
	// 28 is the height of TicketCrewSize.
	// 20 is the spacing we want.
	// Using the height of TicketCrewSize instead of this.height so all of the icons line-up neatly (Duration Days icon is larger than Crew Size)
	PADDING_BOTTOM = 10,
	ROW_HEIGHT = 35
}

export class TicketDurationDays extends TicketComponentHelper implements ComponentInterface {
	
	private _value: FancyText|HighResolutionText;
	
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		
		// Fall back to Canvas if WebGL is not available
		if (rendererInfo.isWebGL()){
			this._value = new FancyText("Roboto Medium");
			this._value.active = true;
			this._value.visible = true;
			
			this._value.x = this.texture.width + 17;
			this._value.fontSize = 96; // 32px * 3x scale factor = 96;
		} else {
			this._value = new HighResolutionText("");
			this._value.active = true;
			this._value.visible = true;
			
			this._value.x = this.texture.width + 20;
			this._value.style.fontSize = 96; // 32px * 3x scale factor = 96;
		}
		
		this.addChild(this._value);
		
		this.width = 30;
		this.height = 35;
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this._value.on("triangulationFinished", this.setPosition);
		
		this.onChanged(ticketContainer);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data: Ticket = ticketContainer.getData();
		this.renderable = data.view.side === Side.PULL;
		if (this.renderable){
			this.tint = ticketContainer.liveRoleTextColor;
			this._value.text = "" + data.view.liveDurationDays;
			this._value.tint = ticketContainer.liveRoleTextColor;
			if (this._value.dirty && data.view.liveDurationDays){
				this._value.calculateText();
			}
			this.setPosition();
		}
	};
	
	private setPosition = () => {
		const data: Ticket = this.ticket.getData();
		const width: number = (this._value.isFancy) ? (<any>this._value).widthPx : this._value.width;
		this.x = data.view.width - this.width - width / 2 - 15;
		// padding-bottom: 20px
		// Align to bottom-left, then offset the height and 20
		// 28 is the height of TicketCrewSize.
		// 20 is the spacing we want.
		// Using the height of TicketCrewSize instead of this.height so all of the icons line-up neatly (Duration Days icon is larger than Crew Size)
		this.y = data.view.height - TICKET_DURATION_DAYS_SETTINGS.ROW_HEIGHT - TICKET_DURATION_DAYS_SETTINGS.PADDING_BOTTOM;
	};
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this._value.removeAllListeners();
		this._value.destroy();
		super.destroy(options);
	}
}