import {IMAGE_PADDING} from "./image-padding.interface"

export enum MILESTONE_SIZE_OFFSET {
	WIDTH = 45,
	HEIGHT = 45
}

export enum MILESTONE_SIZE_OFFSET2{
	ACTIVE = 45,
	PULL = 90
}
