'use strict';

//This is now basically just a wrapper for the renderQueue. Just initializes, then push calls renderQueue's push
//Everything else is just here so something doesn't error, remove the whole thing eventually.

import { renderQueue } from "ng2/common/RenderQueue";

var dict = {};


//actual version
export default function AnimationQueue(fn?, key?):void{

	// Create as singleton  
	if ((AnimationQueue as any).instance){
		if (fn) { return (AnimationQueue as any).instance.add(fn, undefined, key) }
		else    { return (AnimationQueue as any).instance }
	} else    { (AnimationQueue as any).instance = this }
	
	var queue = []; // The backlog of functions to exec next frame
	var running = false; // Are we between a play() and a frame()?
	
	// Normalize frame = requestAnimationFrame(fn)
	var frame = (function(){ 
		var e = 'equestAnimationFrame';
		return window['r'+e] || window['mozR'+e] || window['webkitR'+e] || window['msR'+e]
			|| function(fn){ setTimeout(fn, 0) }
	})();
	
	// Exec queue in a frame()
	var playNow = function(){ 
		running = true;
		//console.log('playing');
		frame(function(){
			//console.log('here');
			
			for(var key in dict){
				var dictFn = dict[key];
				
				if(dictFn){
					//console.log('dict', key, dictFn);
					dictFn();
					dict[key] = undefined;
				}
			}
			var fn; while(fn = queue.pop()){ fn() }
			running = false;
			
		})
	};
	
	this.add = function(fn, noAutoPlay, key ){
		renderQueue.push(fn, key);
	}
	
	/* Original
	// Add a function to the queue
	this.add = function(fn, noAutoPlay, key){
			//these two operations do precisely nothing in their current state
			//also tried with splice instead of slice, actually worsened performance
			//idx = queue.indexOf(fn)
			//if(idx >= 0){ queue.slice(idx, 1) }
			if(key){
				//console.log('key', key);
				dict[key] = fn;
			}
			else{
				queue.unshift(fn)
			}
			if(!running && !noAutoPlay){ this.play();}
			return this;
	};
	*/
	
	// Kick off a noAutoPlay function
	this.play = this.play = function(){
			//removing part of else if so dict version works...
			if (running){ return } else /*if (queue.length > 0)*/ { playNow() }
			return this;
	};
	
	// add(/play(/playNow(fn))) a function if supplied to constructor;
	if(fn){ this.add(fn) }
	return this;
};

// Hitch singleton methods onto constructor
(function(){
	var aq = new AnimationQueue();
	(AnimationQueue as any).add = aq.add;
	(AnimationQueue as any).play = aq.play;
})();
