import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketMeshComponent } from "./";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/";
import { Ticket } from "../../common/models/Ticket";

export enum TASK_SHADDOW_SETTINGS {
	LEFT_OFFSET = 42,
	TOP_OFFSET = 42
}

export class TaskShadowMesh extends TicketMeshComponent implements ComponentInterface {
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer, 61, 58, 61, 58);
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		// In the ticket shadow texture, the ticket shape is offset 10-pixels from the top-left. This is to factor in the
		// "shadow" in the texture. So we offset the image 10-pixels when we draw so it all aligns neatly.
		this.x = -10;
		this.y = -10;
		
		this.alpha = 0.25;
		
		this.onChanged(ticketContainer);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data: Ticket = ticketContainer.getData();
		this.width = data.view.width + TASK_SHADDOW_SETTINGS.LEFT_OFFSET;
		this.height = data.view.height + TASK_SHADDOW_SETTINGS.TOP_OFFSET;
	};
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}