angular.module('ProjectView').factory("projectViewDateType", ProjectViewDateType);

/**
 * @namespace projectViewDateType
 * @returns {{getLastDateType: getLastDateType}}
 * @memberOf ProjectView
 * @constructor
 */
function ProjectViewDateType(){
	/**
	 * @function getLastDateType
	 * @desc Determines what the last date type is.
	 * @memberOf ProjectView.projectViewDateType
	 * @returns {String} The string representing the last date type. Valid options are: 'todayLine', 'milestone', 'activeLine', 'column'
	 */
	function getLastDateType(hud, todayLine, milestone, activeLine, pullColumns){
		var milestoneX = (milestone && milestone.targetX) ? milestone.targetX : null;
		var milestoneScreenX = (milestone && milestone.targetX) ? hud.realToScreenX(milestone.targetX) : null;
		var activeLineScreenX = (activeLine && activeLine.$value) ? hud.realToScreenX(activeLine.$value) : null;
		var activeLineX = (activeLine && activeLine.$value) ? activeLine.$value : null;
		var todayLineX = (todayLine && todayLine.line.$value) ? todayLine.line.$value : null;
		if (todayLineX >= activeLineX && todayLineX >= milestoneX) {
			return 'todayLine'
		} else if (activeLineX !== milestoneX && (activeLineScreenX <= milestoneScreenX) && milestoneScreenX) {
			return 'milestone'
		} else if (activeLine && activeLine.$value && pullColumns.length === 0) {
			return 'activeLine'
		} else {
			return 'column'
		}
	}

	return{
		getLastDateType: getLastDateType
	}
}
