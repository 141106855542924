import { FancyContainer } from "../graphics/FancyContainer";
import {ComponentManager} from "../component-system/ComponentManager";
import {PlanState} from "../../common/services/plan-state.service";
import {TicketContainer} from "./TicketContainer";

import { HoldCircle, TicketDoubleTap, TicketDrag, TicketOpenEdit, TicketHold } from "../interaction/";

export class TicketInteractionContainer extends FancyContainer {
	public components: ComponentManager;
	public type: string = "ticket";
	
	constructor(planState: PlanState, ticketContainer: TicketContainer){
		super();
		window.TICKET = this;
		this.components = new ComponentManager(this);
		this.interactive = true;
		this.interactiveChildren = false;
		this.cursor = "pointer";
		
		// WINDOW stuff (Hack for MOC-2902)
		let tweenManager = ticketContainer.tweenManager;
		let safeRender = (<any>window).TouchPlanCanvas.safeRender;
		
		// Add interaction
		this.components.add('openTicketEdit', new TicketOpenEdit(), planState, ticketContainer);
		this.components.add('ticketDoubleTap', new TicketDoubleTap(), planState, ticketContainer);
		this.components.add('ticketDrag', new TicketDrag(), planState, ticketContainer);
		this.components.add('ticketHold', new TicketHold(), planState, ticketContainer);
		this.components.add('holdCircle', new HoldCircle(), planState, this, tweenManager, safeRender);
	}
}