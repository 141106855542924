import { utils } from "pixi.js";
import * as OpenType from "opentype.js";
/**
 * The events that the FontManager emits.
 */
export enum EVENTS_FONT_MANAGER {
	"LOADED" = "loaded",
	"ERROR" = "error"
}
/**
 * Singleton which exposes methods to manage loaded fonts. Dispatches events once a font has been loaded.
 */
export class FontManager extends utils.EventEmitter {
	constructor(){
		super();
	}
	/**
	 * Stores the parsed font data for all loaded fonts.
	 */
	private _cache: Map<string, any> = new Map();
	/** a cache of file path to font family, which the main cache is indexed by */
	private _fileCache: Map<string, string> = new Map();
	/**
	 * Returns the parsed font data for the provided fontFamily
	 */
	public getFont(fontFamily: string){
		return this._cache.get(fontFamily);
	}
	/**
	 * Load and parse the font.
	 * @param {string} path
	 */
	public loadFont(path: string): void {
		if(this._fileCache.has(path)){ this.reEmit(this._fileCache.get(path), this._cache.get(this._fileCache.get(path))); }
		else{
			//a touch silly, but not sure if onLoad is actually used externally
			OpenType.load(path, (err, font: any)=>{
				if(!err){
					let fontFamily = font["names"]["fontFamily"]["en"];
					this._fileCache.set(path, fontFamily); 
				}
				this.onLoad(err, font);
			}); 
		}
		
	}
	private reEmit(fontFamily:string, font){
		console.log('re-emit font', fontFamily);
		this.emit(EVENTS_FONT_MANAGER.LOADED, fontFamily, font);
	}
	/**
	 * Returns a parsed font file from OpenType.js that can be used for drawing
	 * @param err The error if the font failed to load
	 * @param font The parsed font data for the font file.
	 */
	public onLoad = (err, font: any): any => {
		if (err){return this.onError}
		let fontFamily = font["names"]["fontFamily"]["en"];
		console.log("Load", fontFamily);
		this._cache.set(fontFamily, font);
		this.emit(EVENTS_FONT_MANAGER.LOADED, fontFamily, font);
	};
	/**
	 * Called when an error occurred, and the font path could not be loaded or parsed.
	 * @param err The error
	 */
	public onError = (err): void => {
		console.log(err);
		this.emit(EVENTS_FONT_MANAGER.ERROR, err);
	};
	/**
	 * Called when the FontManager should be destroyed
	 */
	public destroy(): void {
		this.removeAllListeners();
		this._cache.clear();
		this._cache = null;
	}
}

export const fontManager = new FontManager();

// TODO: Remove. Useful for testing.
window.fontManager = fontManager;
