'use strict';

import * as angular from "angular";


// todo - refactor out explicit project access set, need to figure out how projects are being managed

angular.module('ticketspaceApp')
// Removed $rootScope
.factory('firebaseProjectMembers', ["$q", "$log", "$firebaseUtils", "$firebaseArray", "fbConnection", "firebaseVirtualWrapper", "firebaseIndices", "firebaseProjects", "firebaseUsers", "firebaseProjectMembersStatic", function($q, $log, $firebaseUtils, $firebaseArray, fbConnection, firebaseVirtualWrapper, firebaseIndices, firebaseProjects, firebaseUsers, firebaseProjectMembersStatic) {

	//it's worth investigating if this edit stuff can be inherited from another object since most of these use the same thing
	function PMList(projectId){
		this.init(projectId);
	}
	PMList.prototype = Object.create(firebaseVirtualWrapper);
	PMList.prototype.setup({'addKey': 'projectMemberId'});

	//#### init(projectId)
	// builds the instance, creates the fb object and inits some of the other keys
	PMList.prototype.init = function init(projectId){
		var refUrl = 'projectMembers/'+projectId;
		var sync = firebaseVirtualWrapper.init.call(this, refUrl, eFactory); //super
		
		sync.$loaded(function(){
			sync.$watch(function(event){
				firebaseUsers.getUsers().then(function(users){
					//console.log('pre watch sort', projectId, self.users, users.users, event);
					self.users = users.users;
					sync.sort(sortFn);
				});
			});
			firebaseUsers.getUsers().then(function(users){
				self.users = users.users;
				sync.sort(sortFn);
			});
		});

		this.projectId = projectId;
		this.users = firebaseUsers.getUsersSync().users;
		//console.log('firebaseUsers', this.users['simplelogin:234']);

		var self = this;
		//currently in a state where it's using a more simplistic user only sort
		function sortFn(a,b){
			
			var ua = self.users[a.data.userId];
			var ub = self.users[b.data.userId];
			if(!ua && !ub){return 0;} if(!ua){return 1;} if(!ub){return -1;}
			try{
				return ua.parsedName().localeCompare(ub.parsedName());
			}
			catch(e){
				console.error(e, a, b, ua, ub, self.users, self.projectId);
			}
		}
	};


	//#### edit(id)
	// sets edit for the id passed in
	PMList.prototype.edit = function edit(id){
		var val = firebaseVirtualWrapper.edit.call(this, id);
		if(val === -1){
			this.temp.email = null;
			this.temp.accessLevel = 'General';
		}
		else if(val){
			['roleId', 'accessLevel'].forEach(function(key){
				this.temp[key] = val.data[key];
			},this);
			//this.temp.role = this.roles.fb.$getRecord(val.data.roleId);
		}
	};

	//implementation details - consider implementing generic add/remove (static?) functions
	// then using prototype functions that call the generics mapping temp
	// will potentially fall apart when it needs to access the instance
	PMList.prototype.remove = function remove(projectId, userId, projectMemberId){
		if(!projectId || !userId){ return $q.reject('projectId or userId is not defined'); }

		var pm = this.fb.$getRecord(projectMemberId);
		if(!pm){ return $q.reject('projectMember not found');}

		return this.fb.$remove(pm).then(function(){
			var batch = fbConnection.batch();
			batch.push(firebaseIndices.removeProjectMember(projectId, userId));
			batch.push(firebaseIndices.removeProjectAccess(projectId, userId));
			batch.push(firebaseProjects.removeAccess(projectId, userId));
			return batch.end().then(function(){
				return projectMemberId;
			});
		});
	};

	//####deleteEdit()
	//wraps the remove call
	PMList.prototype.deleteEdit = function deleteEdit(projectId){
		var self = this;
		var pm = this.fb.$getRecord(this.editIndex);
		var userId = pm ? pm.data.userId : null;
		return this.remove(projectId, userId, this.editIndex).then(function(pmId){
			self.clearEdit();
			return pmId;
		});
	};

	PMList.prototype.add = function add(projectId, pmObj){
		if(pmObj){ this.temp = pmObj; }
		
		var pm = {
			userId: this.temp.userId,
			roleId: this.temp.roleId ? this.temp.roleId : null,
			accessLevel: this.temp.accessLevel
		};
		
		return firebaseProjectMembersStatic.addAtomic(projectId, pm).then(function(ref){
			return ref.key;
		});
	};

	PMList.prototype.finishEdit = function finishEdit(){
		//sanity checks
		//if(!this.temp.roleName){Logging.warning('name is required'); return;}
		if(this.editIndex === -1){
			console.log('run adds through the add() flow');
		}
		else{
			if(this.temp.role){
				this.temp.roleId = this.temp.role.$id;
				if (this.temp.role._$visited){delete this.temp.role._$visited;} // Yet more hacks on hacks.
			} //hacks on hacks
			var p = this.save();
			this.clearEdit();
			return p;
		}
		//firebaseVirtualWrapper.finishEdit.call(this);
	};
	
	PMList.prototype.getViaUser = function getViaUser(userId){
		//consider caching, or just flat out replacing with indices call
		if(!this.fb.length){return {};}
		for(var i = 0; i < this.fb.length; i++){
			if(this.fb[i].data.userId === userId){ return this.fb[i]; }
		}
		return {};
	};

	function ProjectMember(snap){
		this.$id = snap.key;
		this.update(snap);
	}
	ProjectMember.prototype = {
		update: function(snap){
			//store the data on...data
			this.data = snap.val();
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		},
		_roleCache: {},
		role: function role(getter){
			if(!this.data.roleId){return {};}
			if(this._roleCache[this.data.roleId]){return this._roleCache[this.data.roleId];}
			if(!getter){return {};}
			this._roleCache = {};
			this._roleCache[this.data.roleId] = getter(this.data.roleId);
			return this._roleCache[this.data.roleId];
		}
	};

	function ProjectMembersFactory(ref){
		// call the super constructor
		return $firebaseArray.call(this, ref);
	}
	ProjectMembersFactory.prototype = {
		$$added: function(snap){
			//console.log('added', this);
			return new ProjectMember(snap)
		},
		$$updated: function(snap){
			console.log('updated');
			var rec = this.$getRecord(snap.key);
			rec.update(snap);
			return rec;
		},
		$$removed: function(snap){
			//console.log('$firebaseUtils', $firebaseUtils);
			this.$list._editIndex = 0;
			this.$list._temp = null;

			return this.$getRecord(snap.key);
		}
	};

	var eFactory = $firebaseArray.$extend(ProjectMembersFactory);

	var functionToReturn:any = function(projectId){
		return new PMList(projectId);
	}
	
	functionToReturn.addAtomic = firebaseProjectMembersStatic.addAtomic;
	
	return functionToReturn;

}])

.factory('firebaseProjectMembers2', ["$q", "$log", "$firebaseUtils", "$firebaseArray", "fbConnection", "firebaseVirtualWrapper", "firebaseIndices", "firebaseProjects", "firebaseUsers", "firebaseProjectMembersStatic", function($q, $log, $firebaseUtils, $firebaseArray, fbConnection, firebaseVirtualWrapper, firebaseIndices, firebaseProjects, firebaseUsers, firebaseProjectMembersStatic) {

	function ProjectMember(snap){
		this.$id = snap.key;
		this.update(snap);
	}
	ProjectMember.prototype = {
		update: function(snap){
			//store the data on...data
			this.data = snap.val();
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		}
	};

	function ProjectMembersFactory(ref){
		// call the super constructor
		return $firebaseArray.call(this, ref);
	}
	ProjectMembersFactory.prototype = {
		$$added: function(snap){
			//console.log('added', this);
			return new ProjectMember(snap)
		},
		$$updated: function(snap){
			//console.log('updated');
			var rec = this.$getRecord(snap.key);
			rec.update(snap);
			return rec;
		},
		$$removed: function(snap){
			//console.log('$firebaseUtils', $firebaseUtils);
			return this.$getRecord(snap.key);
		},
		// setWithValidation: function(item, newObj){
		// 	if(!item || !newObj){ return; }
		// 	if(newObj.accessLevel === "Admin" || newObj.accessLevel === "General"){
		// 		
		// 	}
		// }
	};

	return $firebaseArray.$extend(ProjectMembersFactory);
}])


.factory("firebaseProjectMembersStatic", ["$q", "$firebaseAuth", "fbConnection", "firebaseIndices", function($q, $firebaseAuth, fbConnection, firebaseIndices){
	//this is rather broken without severe painful security rule re-writes
	function addAtomic(projectId, pmObj, extraThingsToWriteAtTheSameTime){
		var auth = $firebaseAuth();
		if(!projectId || !pmObj){ return $q.rejectErr('projectId or data undefined'); }
		if(!pmObj.userId){ pmObj.userId = auth.$getAuth().uid }
		
		//check if they exist
		return firebaseIndices.projectMember(projectId, pmObj.userId).then(function(pmIndex){
			if(pmIndex.$value !== null){ return $q.rejectErr('User already added to project'); }
			
			var baseRef = fbConnection.fbRef();
			var pmRef = baseRef.child('projectMembers').child(projectId).push();
			
			pmObj.projectMemberId = pmRef.key;
			
			var obj = extraThingsToWriteAtTheSameTime || {};
			obj['indices/users/' + pmObj.userId + '/projectAccess/'+projectId+'/'] = projectId;
			obj['indices/users/' + pmObj.userId + '/projectMember/'+projectId+'/projectMemberId/'] = pmRef.key;
			obj['projectMembers/'+projectId+'/'+pmRef.key] = pmObj;
			obj['projects/'+projectId+'/users/'+pmObj.userId] = true;
			
			console.log( JSON.stringify(obj));
			
			return fbConnection.update(baseRef, obj).then(()=>{
				return pmRef;
			})
		});
	}
	
	return {
		addAtomic: addAtomic
	}
}]);
