import { TicketTextCanvas } from "./";
import { PlanState } from "../../common/services/plan-state.service";
import { TicketContainer } from "../data-mappers/TicketContainer";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { TICKET_LOCATION_NAME_SETTINGS } from "./TICKET_TEXT_SETTINGS";
import { FancyGraphics, FancyContainer } from "../graphics/";
import { FancyTextStyle } from "../interfaces/fancy-text-style.interface";
import { Side } from "../../common/models/Side";

export class TicketLocationNameCanvas extends TicketTextCanvas {
	// TODO: Apply the mask globally on the world
	public maskContainer: FancyContainer = new FancyContainer();
	public maskGraphics: FancyGraphics = new FancyGraphics();
	
	constructor(planState: PlanState, ticketContainer: TicketContainer, text: string = "", style: FancyTextStyle = {wordWrap: true, fill: "#ffffff"}, canvas?: HTMLCanvasElement) {
		super(text, style, planState, ticketContainer, canvas);
		
		this.maskContainer.mask = this.maskGraphics;
		this.maskContainer.addChild(this);
		
		ticketContainer.ABOVE.addChild(this.maskGraphics);
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer);
	}
	
	public onChanged = (ticketContainer: any) => {
		const data = ticketContainer.getData();
		if (!data.view.location){
			this.renderable = false;
		} else{
			let pullSuffix = (data.view.side === Side.PULL) ? "_PULL" : "";
			
			this.x = TICKET_LOCATION_NAME_SETTINGS["LEFT_MARGIN" + pullSuffix] || 0;
			this.y = TICKET_LOCATION_NAME_SETTINGS["TOP_MARGIN" + pullSuffix] || 0;
			
			const paddingRight: number = TICKET_LOCATION_NAME_SETTINGS["RIGHT_MARGIN" + pullSuffix] || 0;
			const height: number = TICKET_LOCATION_NAME_SETTINGS["DRAWING_AREA_HEIGHT" + pullSuffix] || 0;
			
			this.style.wordWrapWidth = data.view.width - paddingRight - this.x;
			
			// Draw text mask
			this.maskGraphics.clear();
			this.maskGraphics.beginFill(0x000000, 1);
			this.maskGraphics.drawRect(this.x, 0, this.style.wordWrapWidth, height);
			this.maskGraphics.endFill();
			
			this.renderable = true;
			this.tint = this.ticket.locationTextColor;
			
			this.setText(data.view.location.name);
			if (data.view.side === Side.PULL){
				this.style.fontSize = 32;
			} else {
				this.style.fontSize = 18;
			}
		}
		
	};
	
	public destroy(options?): void {
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this.maskContainer.destroy(options);
		
		this.maskContainer = null;
		this.maskGraphics = null;
		super.destroy(options);
	}
}