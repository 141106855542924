/**
 * Const to control constants for image paths and crap like that.
 */
export const TICKET_TEXTURE_OPTIONS = {
	ATLAS_REFERENCE_ID : "ticketTextureAtlasData",
	// Require the image so webpack copies it over.
	TEXTURE_ATLAS_IMAGE: require("images/fancycanvas/ticket-textures.png"),
	// The default URL (No cache busting). Will match the URL with this pre-load and replace it with a doctored URL.
	TEXTURE_ATLAS_IMAGE_PATH_NO_BUSTY: "images/fancycanvas/ticket-textures.png",
	// A doctored URL for the texture-textures.
	TEXTURE_ATLAS_IMAGE_PATH_BUSTY: require("images/fancycanvas/ticket-textures.png"),
	// Root path to where the texture atlas data file can be found. Must be a doctoredPath via require
	TEXTURE_ATLAS_PATH : require("images/fancycanvas/ticket-textures.json"),
	// The reference ID for the textures once loaded from the texture atlas.
	TEXTURE_CREW_ICON_NAME : "crew-icon.png",
	TEXTURE_DURATION_DAYS_NAME : "duration-days.png",
	TEXTURE_TICKET_COMPLETE_NAME : "ticket-checkmark.png",
	TEXTURE_TICKET_CHECKMARK_BACKGROUND : "ticket-checkmark-background.png",
	TEXTURE_CONSTRAINT_SELECTED_NAME : "ticket-constraint-selected.png",
	TEXTURE_CONSTRAINT_SELECTED_PULL_NAME : "ticket-constraint-selected-pull.png",
	TEXTURE_CONSTRAINT_NAME : "ticket-constraint-texture.png",
	TEXTURE_CONSTRAINT_ASSIGNED_BORDER : "constraint-assign-icon-border.png",
	TEXTURE_CONSTRAINT_ASSIGNED_ICON : "constraint-assign-background.png",
	TEXTURE_ERROR_NAME : "ticket-error-icon.png",
	TEXTURE_DEPENDENCY_ERROR_NAME : "dependencies-error-icon.png",
	TEXTURE_MILESTONE_NAME : "ticket-milestone-texture.png",
	TEXTURE_MILESTONE_SELECTED_NAME : "ticket-milestone-selected.png",
	TEXTURE_MILESTONE_SELECTED_PULL_NAME : "ticket-milestone-selected-pull.png",
	TEXTURE_PINNED_BACKGROUND_NAME : "ticket-pinned-background.png",
	TEXTURE_PINNED_NAMED : "ticket-pinned.png",
	TEXTURE_TICKET_HANDLES : "ticket-with-handles.png",
	TEXTURE_TICKET_THING: "thing.png",
	TEXTURE_DEPENDENCY_ARROWHEAD: "arrow-head.png",
	TEXTURE_ARROW: "arrow.png",
	TEXTURE_TICKET_OVERLAP: require("images/fancycanvas/overlap.png")
};