'use strict';


export default function Redom(tag){/*
	Small utility class to aid in the reuse of dom nodes
	
	new Redom("<div><span>snippet</span></div>")
	.get(numberOfCopies) -> Array(DomNode) 

	Todo: Could be fleshed out more for better utility support */
	
	this.tag = tag;
	this.pool = [];
}

Redom.prototype = {
	get: function(n){
		while (n > this.pool.length){
			this.pool.push($(this.tag))
		}
		this.pool.slice(n).forEach(function(node){
			node.detach()
		});
		return this.pool.slice(0, n)
	}
};

//more simplistic redom variant
// operates on native dom elements and doesn't bother returning a sliced array
export function Redom2(element){
	if(typeof element === 'string'){
		this.srcElement = $(element)[0];
	}
	else{
		this.srcElement = element;
	}
	this.pool = [];
}
Redom2.prototype = {
	get: function(n){
		while (n > this.pool.length){
			this.pool.push(this.srcElement.cloneNode(true));
		}
		return this.pool;
	}
};
