import{ Component, ViewChild, ElementRef, AfterViewInit, Input, Output, ChangeDetectionStrategy, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Observable } from "rxjs";
// import{ PlanState, StateEvents } from "ng2/common/services/plan-state.service"
import { TemplateRef } from '@angular/core';

import { DependencyMode } from "ng2/common/models/Dependencies";

import { Ticket } from "ng2/common/models";


/** Example of angular observable input handling
alternatives:
	- use async with safe traversal: *ngIf="(mode | async)?.target"
	- use async with ngIf as variable: *ngIf="(mode | async) as syncMode; syncMode && syncMode.target"
	- use internal subscription:
	```
	js
	constructor{private cd: ChangeDetectorRef}
	mode.subscribe(m => { this.modeSync = m; this.cd.detectChanges() }) //also consider this.cd.markForCheck()
	html
	*ngIf="modeSync && modeSync.target"
	```
*/

@Component({
		selector:'dependency-prompt',
		template: require('ng2/plan-components/dependency-prompt.component.html'),
		changeDetection: ChangeDetectionStrategy.OnPush
})

export class DependencyPromptComponent implements OnInit, OnDestroy {
	static oldAngularName = "dependencyPrompt";
	// @ViewChild('multiSelectWrapper') multiSelectWrapper:ElementRef;
	@Input() mode: Observable<DependencyMode>;
	public modeSync?: DependencyMode;
	
	@Output() onSelectAll = new EventEmitter<boolean>();
	@Output() onExit = new EventEmitter<boolean>();
	
	exit(){
		this.onExit.emit(true);
	}
	toggleSelectAll(){
		this.onSelectAll.emit(true);
	}
	
	ngOnInit(){
		// this.mode.subscribe(m => {
		// 	console.log('mode subbed', m);
		// 	this.modeSync = m;
		// 	this.cd.markForCheck()
		// })
	}

	ngOnDestroy(){
		
	}
	
	constructor (){

	}
}
