import { RendererCache } from "../ticket-renderer/RendererCache";
import { Texture, BaseTexture } from "pixi.js";
import {SWIMLANE_LABEL_SETTINGS} from "./SwimlaneLabel";
import { SWIMLANE_LABEL_STYLE } from "./SwimlaneLabel";

export enum SWIMLANE_GRAPHICS {
	SWIMLANE_AREA = "swimlane",
	SWIMLANE_LINE = "swimlaneLine",
	SWIMLANE_TEXT_BACKGROUND = "swimlaneTextBackground",
	SWIMLANE_LINE_CIRCLE = "swimlaneLineCircle",
	TEXTURE_SWIMLANE_DRAG_HANDLE = "swimlane-drag-handle.png"
}

export class SwimlaneRenderer extends RendererCache {
	constructor(nativeLines: boolean = true){
		super(nativeLines);
		
		this.generateSwimlane();
		this.generateTextBackground();
		this.generateDashedLine();
		this.generateLineCircle();
	}
	
	protected generateSwimlane(): void {
		this._scratchContext.save();
		this._scratchContext.clearRect(0, 0, this._scratchCanvas.width, this._scratchCanvas.height);
		
		this._scratchCanvas.width = 4;
		this._scratchCanvas.height = 4;
		
		this._scratchContext.fillStyle = 'white';
		this._scratchContext.fillRect(0, 0, 4, 4);
		
		this.addToGlobalCache(SWIMLANE_GRAPHICS.SWIMLANE_AREA, new Texture(new BaseTexture(this.getCanvasTexture())));
	}
	
	protected generateLineCircle(): void {
		this._scratchContext.save();
		this._scratchContext.clearRect(0, 0, this._scratchCanvas.width, this._scratchCanvas.height);
		
		this._scratchCanvas.width = 50;
		this._scratchCanvas.height = 50;
		
		this._scratchContext.fillStyle = SWIMLANE_LABEL_SETTINGS.LINE_EDIT_COLOR;
		this._scratchContext.beginPath();
		this._scratchContext.arc(25, 25, 25, 0, 2 * Math.PI);
		this._scratchContext.fill();
		
		this.addToGlobalCache(SWIMLANE_GRAPHICS.SWIMLANE_LINE_CIRCLE, new Texture(new BaseTexture(this.getCanvasTexture())));
	}
	
	protected generateTextBackground(): void {
		this._scratchContext.save();
		this._scratchContext.clearRect(0, 0, this._scratchCanvas.width, this._scratchCanvas.height);
		
		const height: number = SWIMLANE_LABEL_STYLE.fontSize + SWIMLANE_LABEL_SETTINGS.BACKGROUND_TOP_PADDING + SWIMLANE_LABEL_SETTINGS.BACKGROUND_BOTTOM_PADDING;
		
		this._scratchCanvas.width = 256;
		this._scratchCanvas.height = height;
		this._scratchContext.fillStyle = SWIMLANE_LABEL_SETTINGS.BACKGROUND_COLOR;
		this.canvasRoundedRect(0, 0, 256, height, SWIMLANE_LABEL_SETTINGS.BACKGROUND_RADIUS);
		this._scratchContext.fill();
		// this._scratchContext.fillRect(0, 0, 24, 24, SWIMLANE_LABEL_SETTINGS.BACKGROUND_RADIUS);
		
		this.addToGlobalCache(SWIMLANE_GRAPHICS.SWIMLANE_TEXT_BACKGROUND, new Texture(new BaseTexture(this.getCanvasTexture())));
	}
	
	protected generateDashedLine(): void {
		this.lineStyle(1, 0xFFFFFF);
		this.drawDash(0, 0, 9, 0, SWIMLANE_LABEL_SETTINGS.AREA_DASH_LENGTH);
		
		this.addToGlobalCache(SWIMLANE_GRAPHICS.SWIMLANE_LINE, this.generateCanvasTexture());
	}
}