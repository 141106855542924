import { ObjectRenderer, WebGLRenderer, CanvasRenderer, utils, CanvasSpriteRenderer, glCore } from "pixi.js";
import { FontShader, IFontObject } from "./";
/**
 * Stores the MERGED index and vertex buffers for each object
 */
export interface IGLDataCache {
	shader: glCore.GLShader,
	vertexBuffer: glCore.GLBuffer,
	indexBuffer: glCore.GLBuffer,
	vao: glCore.VertexArrayObject
}
/**
 * Interface for OpenType.js parsed data.
 */
export interface IFontNameLocale {
	[key: string]: string;
}
/**
 * Interface for reading which features the loaded font supports. See: https://docs.microsoft.com/en-us/typography/opentype/spec/featuretags
 */
export interface IFontDataFeatures {
	[key: string]: boolean
}
/**
 * Interface for reading OpenType.js identification data.
 */
export interface IFontNames {
	copyright?: IFontNameLocale,
	fontFamily?: IFontNameLocale,
	fontSubFamily?: IFontNameLocale,
	fullName?: IFontNameLocale
}
/**
 * Interface for reading if a font supports kerning, hinting and any other OpenType features
 */
export interface IFontDataOptions {
	kerning?: boolean,
	features?: IFontDataFeatures,
	hinting?: boolean
}
/**
 * Simple interface for interacting with parsed OpenType.js data
 */
export interface IFontData {
	unitsPerEm: number,
	glyphNames: any,
	names: IFontNames,
	getPath(text: string, x: number, y: number, fontSize: number, options?: IFontDataOptions): any;
	charToGlyph(char: string): any;
}
/**
 * The FontRenderer is responsible for drawing the path data for each FancyText object. It works like this:
 *  1. For every FancyText instance, add it into a collection of objects that need to be drawn
 *  2. Iterate through the drawable objects. For each, create a VertexArrayObject using that objects index and vertex data
 *  Cache the VAO on the FancyText object so it only needs to be re-created on change.
 *  3. Upload the color and worldTransform uniforms to the GPU.
 *  4. For each VAO, draw using drawElements
 *
 *  TODO: Future optimization: Merge ALL index and vertex data for ALL FancyText objects into a single VertexArrayObject and then only perform one draw call per 65536 indices
 */
export class FontRenderer extends ObjectRenderer {
	/**
	 * Used to control bezier discretization. Higher values will reduce the discretization error, but at a higher computational cost
	 */
	public static MAX_BEZIER_STEPS: number = 10;
	public static BEZIER_STEP_SIZE: number = 3.0;
	public static EPSILON: number = 1e-6;
	/**
	 * Stores an instance of our Vertex and Fragment shaders
	 */
	public normalShader: FontShader;
	/**
	 * Stores all of our IFontObject's that are both renderable and visible
	 */
	private _instances: IFontObject[] = [];
	/**
	 * The current index in our _instances array.
	 */
	private _currentIndex: number = 0;
	/**
	 * Used for shading the IFontOjbect
	 */
	private _tempColor: Float32Array;
	constructor(renderer: WebGLRenderer){super(renderer);}
	/**
	 * Generic method called when there is a WebGL context change.
	 */
	public onContextChange(): void {
		let gl = this.renderer.gl;
		// Create the shader
		this.normalShader = new FontShader(gl);
		this._tempColor =  new Float32Array(4);
	}
	/**
	 * Starts the renderer and sets the shader
	 */
	public start(){}
	/**
	 * Method which draws and then empties the current batch of IFontObjects
	 */
	public flush(){
		const renderer: WebGLRenderer = this.renderer;
		const shader = this.normalShader;
		
		// Bind our shader to the current WebGLProgram
		renderer.bindShader(shader);
		
		this._instances.forEach((fontEntity: IFontObject, idx) => {
			if (!fontEntity || !fontEntity.visible || !fontEntity.renderable || (<any>fontEntity)._destroyed){return}
			renderer.state.setBlendMode(fontEntity.blendMode);
			
			const glData: IGLDataCache = this._getGLDataCache(renderer, fontEntity, shader);
			
			renderer.bindVao(glData.vao);
			
			// Calculate color of the FontEntity
			const color = this._tempColor;
			utils.hex2rgb(fontEntity.tint, (color as any));
			const alpha = fontEntity.worldAlpha;
			
			// Premultiplied alpha tint
			color[0] *= alpha;
			color[1] *= alpha;
			color[2] *= alpha;
			color[3] = alpha;
			shader.uniforms.u_color = color;
			
			if (!fontEntity.mergedIndexData){
				
				// fontEntity.dirty = true;
				// fontEntity.calculateText();
				return;
			}
			
			shader.uniforms.worldMatrix = fontEntity.transform.worldTransform.toArray(true);
			// renderer.gl.drawElements(renderer.gl.LINE_STRIP, fontEntity.mergedIndexData.length, renderer.gl.UNSIGNED_SHORT, 0);
			glData.vao.draw(renderer.gl.TRIANGLES, fontEntity.mergedIndexData.length, 0);
		});
		
		// Reset our index
		this._currentIndex = 0;
	}
	/**
	 * Called when we want to render a IFontObject.
	 * @param {IFontObject} fontEntity The IFontObject that we want to render. PIXI typically calls this automatically
	 */
	public render(fontEntity: IFontObject): void {
		this._populateEntities(fontEntity);
	}
	/**
	 * Method which increments the currentIndex and adds the IFontObject into the batch for drawing, if it is both renderable and visible.
	 * @param {IFontObject} fontEntity
	 */
	private _populateEntities(fontEntity: IFontObject){
		if (fontEntity.renderable && fontEntity.visible){
			this._instances[this._currentIndex++] = fontEntity;
		}
	}
	/**
	 * Method which manages the creation and caching of VertexArrayObject's for each IFontObject.
	 * @param {PIXI.WebGLRenderer} renderer Instance for the WebGL renderer
	 * @param {IFontObject} fontPath The IFontObject that we are creating a VAO for
	 * @param {FontShader} shader The shader
	 */
	private _createVAO(renderer: WebGLRenderer, fontPath: IFontObject, shader: FontShader): IGLDataCache {
		renderer.bindVao(null);
		
		// Saftey check to verify that the font has been merged before drawing
		if (!fontPath.mergedIndexData || !fontPath.mergedVertexData){
			fontPath.calculateText();
		}
		
		const data: IGLDataCache = {
			shader: this.normalShader,
			indexBuffer: glCore.GLBuffer.createIndexBuffer(renderer.gl, fontPath.mergedIndexData, renderer.gl.STREAM_DRAW),
			vertexBuffer: glCore.GLBuffer.createVertexBuffer(renderer.gl, fontPath.mergedVertexData, renderer.gl.STATIC_DRAW),
			vao: null
		};
		
		data.vao = new glCore.VertexArrayObject(renderer.gl)
			.activate()
			.clear()
			.addIndex(data.indexBuffer)
			.addAttribute(
				data.vertexBuffer,
				shader.attributes.aVertexPosition,
				renderer.gl.FLOAT,
				false,
				0,
				0
			);
		
		fontPath.glDatas[renderer.CONTEXT_UID] = data;
		fontPath.needsVAO = false;
		return data;
	}
	/**
	 * Helper method which will return a cached IGLDataCache object or create a new IGLDataCache object
	 * @param {PIXI.WebGLRenderer} renderer Instance for the WebGL renderer
	 * @param {IFontObject} fontPath The IFontObject that we are creating a VAO for
	 * @param {FontShader} shader The shader
	 */
	private _getGLDataCache(renderer: WebGLRenderer, fontPath: IFontObject, shader: FontShader): IGLDataCache {
		if (fontPath.glDatas[renderer.CONTEXT_UID] && !fontPath.needsVAO){
			return fontPath.glDatas[renderer.CONTEXT_UID];
		} else {
			return this._createVAO(renderer, fontPath, shader);
		}
	}
}

/**
 * Register the FontRenderer as a PIXI plugin.
 */
WebGLRenderer.registerPlugin("font-as-geometry", FontRenderer);
CanvasRenderer.registerPlugin("font-as-geometry", (FontRenderer as any));
