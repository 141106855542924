import * as moment from "moment";
import * as utils from "utils";

import { LinkMap } from "ng2/common/utils/link-map";


// interface MomentStringPair{
// 	moment: moment.Moment,
// 	string: string
// }

/** wraps a couple very specific moment operations that all called frequenent in a cache */
export class DateCache{
	static defaultFormat = "YYYY-MM-DD";
	/** moment of today */
	public today:moment.Moment;
	/** string date of today */
	public todayString:string;
	/** shortcut to utils.dateJar, it does cached formatting */
	public dateJar = utils.dateJar; //just forward this
	
	private generalCache = new Map<string, moment.Moment>();
	private addDaysCache = new LinkMap<string, string, number>();
	private dateDiffCache = new LinkMap<number>();
	private dowCache = new Map<string, number>();
	
	constructor(){
		this.updateToday();
		this.setupCacheClearing();
	}
	
	/** be careful with this one, many moment operations manipulate the original moment */
	public getMoment(date:string){
		let thing = this.generalCache.get(date);
		if(thing){ return thing; }
		let mo = moment(date);
		this.generalCache.set(date, mo);
		return mo;
	}
	
	/** cached moment(startDate).add(days, 'days'); */
	public addDays(startDate: string, days: number){
		let thing = this.addDaysCache.get(startDate, days);
		if(thing){ return thing; }
		var mo = moment(startDate).add(days, 'days');
		thing = utils.dateJar(mo, DateCache.defaultFormat);
		this.addDaysCache.set(startDate, days, thing);
		return thing;
	}
	
	/** cached moment(startDate).diff(endDate, 'days'); */
	public diffDays(startDate:string, endDate:string){
		let thing = this.dateDiffCache.get(startDate, endDate);
		if(thing){return thing;}
		// console.log('diff', startDate, endDate);
		thing = moment(startDate).diff(endDate, 'days');
		this.dateDiffCache.set(startDate, endDate, thing);
		return thing;
	}
	
	/** cached moment(date).day(); */
	public getDow(date:string){
		let thing = this.dowCache.get(date);
		if(thing){return thing;}
		thing = moment(date).day();
		this.dowCache.set(date, thing);
		return thing;
	}
	
	//set a timeout to reset this once midnight hits
	private updateToday(){
		this.today = moment();
		this.todayString = utils.dateJar(this.today, DateCache.defaultFormat); //this.today.format(DateCache.defaultFormat);
	}
	
	public defaultFormat(date:moment.Moment){
		return utils.dateJar(date, DateCache.defaultFormat);
	}
	
	private setupCacheClearing(){
		var now = new Date();
		var night = new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate() + 1, // the next day, ...
			0, 0, 0 // ...at 00:00:00 hours
		);
		var msToMidnight = night.getTime() - now.getTime();
		setTimeout(()=>{
			this.clearCache();
			this.setupCacheClearing();
		}, msToMidnight);
	}
	private clearCache(){
		this.updateToday();
		this.addDaysCache = new LinkMap();
		this.dateDiffCache = new LinkMap();
		this.dowCache = new Map();
	}
}

export const dateCache = new DateCache();
