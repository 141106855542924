import {FancyAction, FancyFirebaseList} from "ng2/fancy-firebase/base";

import {DefaultActionTypes} from "./DefaultActionTypes";
import {hasId} from "./interfaces";

import * as utils from "utils";
import { ListLike } from "utils";


/**
 * An abstract class to be extended by "a thing that interfaces with firebase".
 * It's intended to wrap the FancyFirebaseList as the "write" component to correspond with it's reads.
 * A default set of actions is included for standard firebase manipulation.
 * PlanState(or projectState?) is not included by default, but the extending class can opt into it
 * if it needs to know about the current state of the app. Just pass it in as a second argument of
 * the constructor. In general all the default methods operate on the Model or List<Model> system where possible.
 * @param list [description]
 */
export abstract class FancyActionableFirebase<FancyModel extends hasId, FancyCustomAction extends FancyAction, List extends FancyFirebaseList<FancyModel, FancyCustomAction>>{
	
	constructor(public list:List){
	}
	
	protected pushAction(type: DefaultActionTypes, key:string, payload:any, additionalArgs?: Object){
		//this coercision should be safe because the type checking will all be done via the interfaces
		let action = new FancyAction(payload, type, key) as FancyCustomAction; 
		if(additionalArgs){ Object.assign(action, additionalArgs) }
		this.list.appEvent$.next(action);
	}
	
	//don't see any logical reason to try and make this support lists, since the
 	//list would need to be in a specific format or we'd need a getter to keep it generic
	//so... might as well just iterate externally
	public localUpdate(key: string, payload: Object){
		this.pushAction(DefaultActionTypes.localUpdate, key, payload);
	}
	
	public changeView(list: ListLike<FancyModel> | FancyModel, renderType: string){
		let additionalArgs = { renderType: renderType };
		utils.iterateStuff(list, (item)=>{ this.pushAction(DefaultActionTypes.changeView, item.$id, item, additionalArgs) })
	}
	public abortView(list: ListLike<FancyModel> | FancyModel, renderType: string){
		let additionalArgs = { renderType: renderType };
		utils.iterateStuff(list, (item)=>{ this.pushAction(DefaultActionTypes.abortView, item.$id, item, additionalArgs) })
	}
	
	public persistData(list: ListLike<FancyModel> | FancyModel, renderType?: string){
		if(renderType){ var additionalArgs = { renderType: renderType }; }
		utils.iterateStuff(list, (item)=>{ this.pushAction(DefaultActionTypes.persistData, item.$id, item, additionalArgs) })
	}
	
	public addItem(initialData: Object){
		this.pushAction(DefaultActionTypes.addItem, "empty", initialData);
	}
	
	public removeItem(list: ListLike<FancyModel> | FancyModel, additionalArgs?: Object){
		utils.iterateStuff(list, (item)=>{ this.pushAction(DefaultActionTypes.removeItem, item.$id, item, additionalArgs) })
	}
}
