'use strict';

import * as angular from "angular";
import * as utils from "utils";

import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

export class AccessCheck {

	static $inject = ['$q', 
					  '$rootScope',
					  'fbConnection',
					  'authService',
					  'firebaseProjects',
					  'projectNotifier',
					  'developerMode'];

	//stores the user related info for the current user
	private info:any = {};

	//stores the current app state
	private _state:any;

	//stores fetched data on the info object
	private _storeUserData(promiseOrValue, key): any {
		return this.$q.when(promiseOrValue)
		.then((val) => {
			this.info[key] = val;
			return val;
		});
	}
	
	//fetches data from a firebase path
	private _getUserData(path, key): any {
		var thing = this.fbConnection.fbObject(path);
		var promise = thing.$loaded().then(() => { return thing;})
		return this._storeUserData(promise, key);
	}

	// Does login for a user...
	private _login(user): void {
		var self = this;
		var pmKeys = this.fbConnection.fbArray('indices/users/'+user.uid+'/projectMember/');
		pmKeys.$loaded().then(() => {
			function updatePmList(){

				self.info.projectMembers = {};
				var batch = self.fbConnection.batch();
				pmKeys.forEach((value) => {
					var projectId = value.$id;
					var pmId = value.projectMemberId;
					
					var pmData = self.fbConnection.fbObject('projectMembers/'+projectId+'/'+pmId);
					batch.push(pmData.$loaded().then( () => {
						if(!self.info.projectMembers[projectId]){ self.info.projectMembers[projectId] = {}; }
						self.info.projectMembers[projectId] = pmData;
					}).catch(function(e){
						console.log('There is likely an orphaned projectMember index for '+user.uid+'.\nCheck the path of the error to find it.', e);
					}));
				});
				
				batch.end().then( () => {
					// Do nothing.
				});
			}
			
			updatePmList();
			pmKeys.$watch(updatePmList);
		});

		var batch = this.fbConnection.batch();
		batch.push(this._storeUserData(this.authService.waitForUser(), 'user'));
		batch.push(this._getUserData('planAccess', 'planAccess'));
		batch.end().then(function(){
			//console.log('info', info);
		});

	}

	constructor(private $q,
				private $rootScope,
				private fbConnection,
				private authService,
				private firebaseProjects,
				private projectNotifer: ProjectNotifier,
				private developerMode) {
		// Is there any code that needs to run when the instance is constructed
		projectNotifer.projectState.subscribe((state) => {
			this._state = state;

			if(this._state.projectId){
				var p = firebaseProjects.getProject(this._state.projectId);
				this._storeUserData(p.$loaded(), 'project');
			}
		});

		this.authService.auth.$onAuthStateChanged((authData) => {
			if(authData){
				this._login(authData);
			}
			else{
				// Do nothing
			}
		});
	}
	
	// #### Public API below	

	public get roleId(){return utils.checkNested(this.info, 'projectMembers', this._state.projectId) ? this.info.projectMembers[this._state.projectId].roleId : null;}
	public get secondaryRoles(){return utils.checkNested(this.info, 'projectMembers', this._state.projectId) ? this.info.projectMembers[this._state.projectId].secondaryRoles : null;}

	/**
	 * currentProjectMember()
	 * @desc Returns the current project member based on the state 
	 * data or returns false if it can't resolve
	 * @param none
	 * @returns ???
	 */
	currentProjectMember(): any {
		if(!this._state.projectId){ return false; }
		if(this.info.projectMembers && this.info.projectMembers[this._state.projectId]){
			return this.info.projectMembers[this._state.projectId];
		}
		return false;
	}
	
	/**
	 * isReadOnly(planId)
	 * @desc Returns true if readonly is enabled for the current projectMember
	 * on the current project, returns false otherwise
	 * @param planId - optional, will be used instead of the state's planId if passed in
	 * @returns boolean indicating if project member only has read access
	 */
	isReadOnly(planId?){
		//if the project is disabled everything is read only
		if(this.info.project && this.info.project._isDisabled){
			//console.log('read',info.project.ownerUserId, authService.userData.$id, info.project.projectType);
			if(this.info.project.ownerUserId === this.authService.userData.$id
					&& this.info.project.projectType === 'sampleProjectShared'){
				return false
			}
			return true;
		}
		
		//console.log('state', _state);
		//if(!_state){ return false; }
		if(!planId){ planId = this._state.planId; }
		if(!this._state.projectId || !planId){ return false; }
		
		var pm = this.currentProjectMember();
		//console.log('planId', planId);
		if(!pm){ return false; }
		
		//console.log('info', info.planAccess[_state.projectId][pm.$id], 'state', _state);
		if(utils.checkNested(this.info, 'planAccess', this._state.projectId, planId)){
			var plan = this.info.planAccess[this._state.projectId][planId];
			if(!plan[pm.$id]){ return !!plan.readOnlyMode; }
			
			var access = this.info.planAccess[this._state.projectId][planId][pm.$id].readOnlyMode;
			return access === false ? false : (access ? true : !!plan.readOnlyMode);
		}
		return false;
	}

	/**
	 * isAdmin()
	 * @desc Returns true if the user has admin access, false otherwise
	 * @param none 
	 * @returns boolean indicating if user has admin access
	 */
	isAdmin(){
		if(!this._state.projectId){ return false; }
		if(utils.checkNested(this.info, 'projectMembers', this._state.projectId)){
			if(this.info.projectMembers[this._state.projectId].accessLevel == "Admin"){
				return true; 
			}
		}
		return false
	}
	
	// Assuming this is no longer used.  Should probably expunge...
	// function iteratePlanAccess(cb, ctx){
	// 	var project = fbConnection.fbObject('projects/'+_state.projectId);
	// 	project.$loaded().then(function(){
	// 		if(project.users){
	// 			utils.$loop(project.users, function(value, key){
	// 				var projectMemberId = fbConnection.fbObject('indices/users/'+key+'/projectMember/'+_state.projectId+'/projectMemberId');
	// 				projectMemberId.then(function(){
	// 					if(projectMemberId.$value){
	// 						cb.call(ctx?ctx:this, projectMemberId.$value);
	// 					}
	// 				});
	// 			});
	// 		}
	// 	});
	// }
	
	/**
	 * Admin(roleId)
	 * @desc Returns true if the user is an admin, or if their role matches
	 * @param roleId 
	 * @returns boolean indicating the user has the role or is an admin
	 */
	roleOrAdmin(roleId){
		if(!this._state.projectId){ return false; }
		if(utils.checkNested(this.info, 'projectMembers', this._state.projectId)){
			if(this.info.projectMembers[this._state.projectId].accessLevel == "Admin"){
				return true; 
			}
			return this.hasRole(roleId);
		}
		return false
	}

	/**
	 * pmOrAdmin(pmId)
	 * @desc Returns true if the user is an admin, or if their pmId matches
	 * @param pmId 
	 * @returns boolean indicating the user has the given pmId or is an admin
	 */
	 pmOrAdmin(pmId){
		if(!this._state.projectId){ return false; }
		if(utils.checkNested(this.info, 'projectMembers', this._state.projectId)){
			if(this.info.projectMembers[this._state.projectId].accessLevel == "Admin"){
				return true; 
			}
			if(this.info.projectMembers[this._state.projectId].projectMemberId === pmId){
				return true; 
			}
		}
		return false;
	}
	
	/**
	 * hasAccessToTicket(ticket)
	 * @desc Returns true if the user is an admin, or if their pmId matches
	 * @param ticket 
	 * @returns boolean indicating the user has access to the given ticket
	 */
	hasAccessToTicket(ticket){
		if(!ticket || !ticket.data){return false}
		if(ticket.data.type === "constraint"){
			return this.pmOrAdmin(ticket.data.responsibleProjectMemberId) || this.pmOrAdmin(ticket.data.assignedProjectMemberId);
		}
		else{
			return this.roleOrAdmin(ticket.data.roleId);
		}
	}
	//stop-gap solution for new tickets
	hasAccessToTicket2(ticket){
		if(this.isAdmin()){return true}
		if(!ticket || !ticket.view || !ticket.view.liveRole){return false}
		if(ticket.view.type === "constraint"){
			return this.pmOrAdmin(ticket.view.responsibleProjectMemberId) || this.pmOrAdmin(ticket.view.assignedProjectMemberId);
		}
		return this.roleOrAdmin(ticket.view.liveRole.roleId);
	}

	/**
	 * lockPlan(planId)
	 * @desc Locks the given plan
	 * @param planId 
	 * @returns void
	 */
	lockPlan(planId){
		if(!this.isAdmin()){return;}
		planId = planId ? planId : this._state.planId;
		if(!this._state.projectId || !planId){ return false; }
		
		var path = 'planAccess/'+this._state.projectId+'/'+planId+'/readOnlyMode';
		this.fbConnection.set(this.fbConnection.fbRef(path), true);
		
		analyticsService.lockPlan(planId);
	}
	
	//#### unlockPlan(planId)
	// Sets the plan level readonly state
	//##### Args
	//* planId - string representing the id of the plan
	/**
	 * unlockPlan(planId)
	 * @desc Un-locks the given plan
	 * @param planId 
	 * @returns void
	 */
	unlockPlan(planId){
		if(!this.isAdmin()){return;}
		planId = planId ? planId : this._state.planId;
		if(!this._state.projectId || !planId){ return false; }
		
		var path = 'planAccess/'+this._state.projectId+'/'+planId+'/readOnlyMode';
		this.fbConnection.remove(this.fbConnection.fbRef(path));
		analyticsService.unlockPlan(planId);
	}
	
	/**
	 * @function hasRole
	 * @desc Returns boolean if current user has the provided roleId as either a primaryRoleId or as a secondaryRole
	 * @param roleId {String} The role ID to compare
	 * @returns {boolean}
	 */
	hasRole(roleId){
		if (this.info.projectMembers[this._state.projectId].roleId === roleId){return true}
		if (this.info.projectMembers[this._state.projectId].secondaryRoles){
			for (let key in this.info.projectMembers[this._state.projectId].secondaryRoles){
				if (this.info.projectMembers[this._state.projectId].secondaryRoles.hasOwnProperty(key) && this.info.projectMembers[this._state.projectId].secondaryRoles[key] === roleId){
					return true
				}
			}
		}
		return false;
	}
}

angular.module('ticketspaceApp').service('accessCheck', AccessCheck)
.run(["accessCheck", function(accessCheck) {}]); //this effectively just ensures that this service is init'd really early in the lifecycle
