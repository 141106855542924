import { Type, Component, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';

import { TaskEditComponent } from 'ng2/ticket-edit/components/task-edit.component';
import { MilestoneEditComponent } from 'ng2/ticket-edit/components/milestone-edit.component';
import { ConstraintEditComponent } from 'ng2/ticket-edit/components/constraint-edit.component';
import { MultiEditComponent } from 'ng2/ticket-edit/components/multi-edit.component';

export { TaskEditComponent, MilestoneEditComponent, ConstraintEditComponent, MultiEditComponent };

import * as interfaces from 'ng2/ticket-edit/interfaces';

import { EditType } from 'ng2/ticket-edit/edit-type.directive';

// import { TaskEditComponent } from 'ng2/ticket-edit/components/task-edit.component';

import { TicketEditServiceTemplater } from 'ng2/ticket-edit/ticket-edit-templater.service';

@Component({
	selector: 'ticket-edit-wrapper',
	template: '<div class="actual-ticket-edit-wrapper"><ng-template edit-type></ng-template></div>',
	host: {
		'[class.actual-ticket-edit-wrapper-empty]' : 'ticketEditWrapperEmpty'
	}
})
export class EditWrapperComponent implements AfterViewInit {
	@ViewChild(EditType) editType: EditType;
	
	constructor(private componentFactoryResolver: ComponentFactoryResolver,
			private ticketEditService: TicketEditServiceTemplater){
		console.log('EditWrapperComponent');
		(<any>window).editt = this;
	}
	public ticketEditWrapperEmpty = true;
	// changeChildComponent(childComponent: interfaces.ticketEditComponent){
	// changeChildComponent(childComponent:Component){ //WTF
	changeChildComponent(childComponent:any){
		// childComponent = TaskEditComponent;
		// childComponent = MilestoneEditComponent;
		this.ticketEditWrapperEmpty = false;
		let componentFactory = this.componentFactoryResolver.resolveComponentFactory(childComponent);
		let viewContainerRef = this.editType.viewContainerRef;
		viewContainerRef.clear();
		let componentRef = viewContainerRef.createComponent(componentFactory);
		
		return componentRef;
	}
	clearChildComponent(){
		this.ticketEditWrapperEmpty = true;
		this.editType.viewContainerRef.clear();
	}
	
	ngAfterViewInit(){
		console.log('after ngAfterViewInit');
		
		this.ticketEditService.registerEditWrapper(this);
		
		// let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.ticketEditService.experimentalComponentList[rand]);
		// let viewContainerRef = this.editType.viewContainerRef;
    // viewContainerRef.clear();
		// 
		// let componentRef = viewContainerRef.createComponent(componentFactory);
		//(<AdComponent>componentRef.instance).data = adItem.data;
	}
}
