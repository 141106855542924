import { TicketManager, SpriteTicket, SpriteMilestone, TICKET_TEXTURE_OPTIONS } from "../tickets";
import { FancyGraphics } from "../graphics";
import { PlanState } from "../../common/services/plan-state.service";
import { DisplayObject, Container, Rectangle, utils } from "pixi.js";
import { Ticket, TicketTypes, Side, TicketRenderTypes } from "../../common/models/Ticket";
import { combineLatest, Subject } from "rxjs";
import { map, debounce, switchMap, filter, sample, takeUntil } from "rxjs/operators";
import { drop } from "ng2/common/rxjs-internal-extensions";
import { SpritePool } from "../../common/utils/GenericPool";
import { Camera, LevelOfDetail, LEVEL_OF_DETAIL_SIGNALS, LEVEL_OF_DETAIL_TABLE } from "../core/";
import { QuadTree } from "../utils";
import { FancyTicketAction } from "../../actions/ticket-actions";
import { TicketOverlap } from "./";
import { MILESTONE_SIZE_OFFSET2 } from "../interfaces/milestone-size.interface";
import { OVERLAP_FORCE_DISABLED, OVERLAP_FORCE_DISABLED_OVERRIDE } from "../../common/strings";
import { sessionStorage, SessionSettings } from "../../common/services/SessionStorage";

enum TICKET_COLLISION_SETTINGS {
	FORCE_DISABLE_MAXIMUM_TICKETS = 1000,
}

/**
 * The TicketCollision is responsible for handling all collision checks. It has an observable collide$ which dispatches
 * an event to all subscribers when a collision takes place.
 *
 * It requires access to:
 *  - TicketManager : So it can access the visual representation of the Tickets (FancySprites)
 *
 *  A spatial hashing or QuadTree may need to be implemented to improve performance of axis-aligned bounding box
 *  collision detection (1,000 tickets checking against 1,000 tickets = 1,000,000 collision checks...).
 *  Alternatively, can also only do collision checks on tickets which are VISIBLE and within a certain Level of Detail level.
 */

export class TicketCollision {
	public dirty: boolean = false;
	/**
	 * Only do collision checks at LOW detail
	 */
	public lodLevel: LEVEL_OF_DETAIL_TABLE = LEVEL_OF_DETAIL_TABLE.VERY_HIGH;
	/**
	 * Used to control if ticket collisions are enabled or not.
	 */
	public enabled: boolean = false;
	public globalEnable: boolean = true;
	private _lastEnabled: boolean = false;
	private _deferredTickets;
	private _pool: SpritePool;
	/**
	 * Reference to the TicketManager. Used so we can access the SpriteTicket instance
	 */
	private _ticketManager: TicketManager;
	/**
	 * An instance of a FancyGraphics class. Used to draw custom shapes for our overlap mask
	 */
	private _graphics: FancyGraphics = new FancyGraphics();
	/**
	 * Reference to the PlanState object. Typically used to access the Ticket models
	 */
	private _planState: PlanState;
	/**
	 * Thing that holds all of the ticket overlaps
	 */
	private _overlapContainer: Container = new Container();
	/**
	 * A list of things to destroy
	 */
	private _destroyList: any[] = [];
	/**
	 * Our QuadTree to organize objects in world space
	 */
	private _quadTree: QuadTree;
	
	private shutDownSubject$: Subject<boolean>  = new Subject();
	/**
	 *
	 * @param {PlanState} planState The planState object
	 * @param {TicketManager} ticketManager The ticketManager instance so we can access SpriteTicket's
	 * @param {Camera} camera The camera instance
	 * @param renderFn Thing that is called to update the view
	 */
	constructor(planState: PlanState, ticketManager: TicketManager, camera: Camera, renderFn: () => any) {
		this._ticketManager = ticketManager;
		this._planState = planState;
		
		this._overlapContainer.addChild(this._graphics);
		
		this._quadTree = new QuadTree(
			0,
			0,
			1,
			1
		);
		
		camera.cameraContainer.addChild(this._overlapContainer);
		
		const overlapCreator = (): TicketOverlap => {
			return new TicketOverlap(
				utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_TICKET_OVERLAP]
			);
		};
		
		const overlapPool: SpritePool = new SpritePool(overlapCreator, this._overlapContainer);
		
		this._destroyList.push(this._overlapContainer, overlapPool, this._quadTree);
		
		// Manage the QuadTree bounds
		planState.plan.object$.pipe(
			takeUntil(this.shutDownSubject$)
		).subscribe((plan) => {
			if (!plan){return}
			const bounds = new Rectangle(
				plan.minX,
				plan.minY,
				plan.maxX - plan.minX,
				plan.maxY - plan.minY
			);
			this._quadTree.reset(bounds.x, bounds.y, bounds.width, bounds.height);
		});
		
		// Combine the ticketManager update$ observable with the tickets.list$ observable.
		// Do this so once all tickets have been initialized, an update event is fired so all sub-systems can updae.
		const delay$ = planState.renderQueueWithCleanup$.pipe(drop(30));
		const combined$ = combineLatest(
			//empty(), //disabling this for reasons
			planState.tickets.list$,
			ticketManager.update$
		).pipe(
			debounce( ()=> delay$ ),
			map(q => q[0]),
			takeUntil(this.shutDownSubject$),
		);
		
		let onlyDisableOnceWhenPassThreshold: boolean = false;
		
		// Subscribe to the combined observable.
		// NOTE: This Observable will only fire after the events have settled for 30 frames
		combined$.subscribe((tickets: Map<string, Ticket>) => {
			this._deferredTickets = tickets;
			this._pool = overlapPool;
			if (!onlyDisableOnceWhenPassThreshold){
				let result: boolean = (tickets.size > TICKET_COLLISION_SETTINGS.FORCE_DISABLE_MAXIMUM_TICKETS);
				this.globalEnable = !result;
				if (result){
					// this._logOverlapDisabledNotice();
					sessionStorage.enableTicketOverlap = false;
				}
				onlyDisableOnceWhenPassThreshold = true;
			}
			if (!this.enabled || !this.globalEnable){return}
			this._check(tickets, overlapPool);
			
			renderFn();
		});

		// This observer only fires for selected tickets that are dragged.
		planState.tickets.selectedTicket$.pipe(
			switchMap((res) => {
				return planState.tickets.soakedRaw$.pipe(
					filter(action => res.list.has(action.key) && action.payload.renderType === TicketRenderTypes.drag)
				);
			}),
			sample(this._planState.renderQueueWithCleanup$),
			takeUntil(this.shutDownSubject$),
		).subscribe((ticketAction: FancyTicketAction) => {
			if (!this.enabled || !this.globalEnable){return}
			this._dynamicSelfCheck(ticketAction.payload);
		});

		// Update overlaps once the camera stops moving
		const cameraDelay$ = planState.renderQueueWithCleanup$.pipe(drop(60));
		camera.changed$.pipe(
			debounce(() => cameraDelay$),
			takeUntil(this.shutDownSubject$)
		).subscribe((newPos) => {
			if (!this.globalEnable){
				this.clear();
				overlapPool.reset();
			} else if (this._deferredTickets) {
				this._check(this._deferredTickets, overlapPool);
				// Update the render
				renderFn();
			}
		});
		
		// Watch for changes to the setting storage
		sessionStorage.changed$.pipe(
			takeUntil(this.shutDownSubject$)
		).subscribe((settings: SessionSettings) => {
			this.globalEnable = settings.enableTicketOverlap;
			if (!this.globalEnable){
				this.clear();
				overlapPool.reset();
			} else if (this._deferredTickets) {
				this._check(this._deferredTickets, overlapPool);
			}
			
			if (this._deferredTickets && this._deferredTickets.size > TICKET_COLLISION_SETTINGS.FORCE_DISABLE_MAXIMUM_TICKETS && this.globalEnable){
				this._logPerformanceWarning();
			}
		});
		
		LevelOfDetail.emitter.on(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
		
		this.lodChanged(LevelOfDetail.getLODLevel(), LevelOfDetail.getLastLODLevel());
	}
	
	private clear(): void {
		this._graphics.clear();
		this._quadTree.clear();
	}
	
	private _logPerformanceWarning(): void {
		Logging.warning(OVERLAP_FORCE_DISABLED_OVERRIDE);
	}
	
	private _logOverlapDisabledNotice(): void {
		Logging.warning(OVERLAP_FORCE_DISABLED);
	}
	
	public update(){
		if (this.enabled && this.globalEnable && this.enabled !== this._lastEnabled && this._deferredTickets && this.dirty){
			this._check(this._deferredTickets, this._pool);
		}
	}
	/**
	 * Turn ON/OFF the collision as detail level changes
	 * @param {number} newLod The new LOD value
	 * @param {number} oldLod The old LOD value
	 */
	protected lodChanged = (newLod: number, oldLod: number) => {
		this.enabled = (this.lodLevel <= newLod);
		if (!this.enabled){
			this._graphics.clear();
			this._quadTree.clear();
			if (this._pool){
				this._pool.reset();
			}
			this._lastEnabled = this.enabled
		} else if (this.enabled !== this._lastEnabled && this._deferredTickets && this._pool){
			this.dirty = true;
		}
	};
	/**
	 * Applies 'live' collision detection for tickets being dragged, but only against tickets it previously collided against
	 * during the last collision check update.
	 * @param {Ticket} ticket The ticket data being updated
	 */
	private _dynamicSelfCheck(ticket: Ticket): void {
		const ticketSprite: SpriteTicket = this._ticketManager.get(ticket.$id);
		if (!ticketSprite){return}
		const container = ticketSprite.ticketContainer;
		if (!ticketSprite.enableCollisions && !ticketSprite.visible || !ticketSprite.active || !ticketSprite.renderable || !ticketSprite.collidingWith.size || !container || !container.renderable){return}
		// Do a collision check against all tickets this ticket was/is currently colliding width
		// (Determined during the last collision check update)
		ticketSprite.collidingWith.forEach((previousTicket: SpriteTicket) => {
			const overlap: TicketOverlap = ticketSprite.collidingOverlaps.get(previousTicket);
			const collideResult: boolean = this._intersects(ticketSprite, previousTicket);
			// Only clear the overlap if there is NO collision
			if (!collideResult){
				overlap.visible = false;
				// Delete the caches on both tickets
				ticketSprite.collidingWith.delete(previousTicket);
				ticketSprite.collidingOverlaps.delete(previousTicket);
				previousTicket.collidingWith.delete(ticketSprite);
				previousTicket.collidingOverlaps.delete(ticketSprite);
			} else {
				overlap.visible = false;
			}
		});
	}
	/**
	 * Performs a collision check on all tickets and determines which tickets are overlapping
	 * @param allTickets
	 * @param {SpritePool} overlapPool Pool of Overlap images
	 *
	 * TODO: !!!!!FOR THE LOVE OF GOD DON'T DO COLLISION CHECKS LIKE THIS YOU FILTHY MONSTER!!!!!
	 */
	private _check(allTickets: Map<string, Ticket>, overlapPool: SpritePool): void {
		// Clear the masks and the overlapPool
		// We check to make sure graphics exists first. Can cause an error by going back to dashboard then forward to the plan very quickly
		if (!this._graphics || !this._quadTree || !this.enabled || !this.globalEnable){return}
		this._graphics.clear();
		this._quadTree.clear();
		overlapPool.reset();
		
		let collisionChecks: number = 0;
		window.perf.log("start collision check");
		allTickets.forEach((ticket: Ticket) => {
			const ticketSprite: SpriteTicket = this._ticketManager.get(ticket.$id);
			if (!ticketSprite || !ticketSprite.enableCollisions || !ticketSprite.visible || !ticketSprite.active || !ticketSprite.renderable){return}
			const container = ticketSprite.ticketContainer;
			if (!container || !container.visible || !container.renderable){return}
			// Clear collidingWidth
			ticketSprite.collidingWith.clear();
			this._quadTree.insert(ticketSprite);
			
			const closeObjects = this._quadTree.grab(ticketSprite);

			closeObjects.forEach((closeTicket: SpriteTicket) => {
				if (closeTicket === ticketSprite || ticketSprite.collidingWith.get(closeTicket) || closeTicket.collidingWith.get(ticketSprite)){return}
				const collideResult: boolean = this._intersects(ticketSprite, closeTicket);
				if (collideResult){
					this._draw(ticketSprite, closeTicket, overlapPool);
				}
				collisionChecks++;
			});
			
		});
		window.perf.log("end collision check", {previousEvent: "start collision check"});
		console.log("PERFORMED: " + collisionChecks + " collision checks!");
		this._lastEnabled = this.enabled;
		this.dirty = false;
	}
	/**
	 * Draws the overlap ticket mask
	 * @param {SpriteTicket} ticket
	 */
	private _drawMasks(ticket: SpriteTicket): void {
		const ticketData: Ticket = ticket.getData();
		if (ticket.type === TicketTypes.MILESTONE) {
			var mTicket = ticket as SpriteMilestone;
			// const x = ticketData.view.left - MILESTONE_SIZE_OFFSET.WIDTH / 2;
			// const y = ticketData.view.top - MILESTONE_SIZE_OFFSET.HEIGHT / 2;
			const sideOffset = (ticketData.view.side === Side.PULL ? MILESTONE_SIZE_OFFSET2.PULL/2 : MILESTONE_SIZE_OFFSET2.ACTIVE/2);
			const x = ticketData.view.left - sideOffset;
			const y = ticketData.view.top - sideOffset;
			this._graphics.lineStyle(1, 0x000000, 1);
			this._graphics.beginFill(0x000000);
			this._graphics.drawDiamond(x, y + (mTicket.offsetSize.height / 2), mTicket.offsetSize.width, mTicket.offsetSize.height);
			this._graphics.endFill();
		} else if (ticket.type === TicketTypes.TASK){
			this._graphics.lineStyle(1, 0x000000, 1);
			this._graphics.beginFill(0x000000);
			this._graphics.drawRect(ticketData.view.left, ticketData.view.top, ticket.width, ticket.height);
			this._graphics.endFill();
		} else if (ticket.type === TicketTypes.CONSTRAINT){
			this._graphics.lineStyle(1, 0x000000, 1);
			this._graphics.beginFill(0x000000);
			const r: number = ticketData.view.width/2;
			// this._graphics.drawCircle(ticketData.view.left + (ticket.width / 2), ticketData.view.top + (ticket.height / 2) + offset, r);
			this._graphics.drawCircle(ticketData.view.left + (ticketData.view.width / 2), ticketData.view.top + (ticketData.view.height / 2), r);
			this._graphics.endFill();
		}
	}
	/**
	 * Draws the overlap
	 * @param {SpriteTicket} ticket
	 * @param {SpriteTicket} secondTicket
	 * @param {SpritePool} overlapPool
	 */
	private _draw(ticket: SpriteTicket, secondTicket: SpriteTicket, overlapPool: SpritePool): void {
		const intersection: Rectangle = this._getIntersectionRect(ticket, secondTicket);
		const overlapSprite: TicketOverlap = overlapPool.grab() as TicketOverlap;
		overlapSprite.x = intersection.x;
		overlapSprite.y = intersection.y;
		overlapSprite.width = intersection.width;
		overlapSprite.height = intersection.height;
		overlapSprite.renderable = true;
		
		this._drawMasks(ticket);
		
		overlapSprite.mask = this._graphics;
		
		// Set the cache on the ticket
		ticket.collidingWith.set(secondTicket, secondTicket);
		ticket.collidingOverlaps.set(secondTicket, overlapSprite);
		
		secondTicket.collidingWith.set(ticket, ticket);
		secondTicket.collidingOverlaps.set(ticket, overlapSprite);
	}
	/**
	 * Returns a rectangle of the intersection point
	 * @param {SpriteTicket} ticket
	 * @param {SpriteTicket} secondTicket
	 */
	private _getIntersectionRect(ticket: SpriteTicket, secondTicket: SpriteTicket): Rectangle {
		const firstData: Ticket = ticket.getData();
		const secondData: Ticket = secondTicket.getData();
		// const tickRec: Rectangle = new Rectangle(firstData.view.left, firstData.view.top, ticket.width, ticket.height); 
		const tickRec: Rectangle = new Rectangle(firstData.view.left, firstData.view.top, firstData.view.width, firstData.view.height);
		// const secondTickRec: Rectangle = new Rectangle(secondData.view.left, secondData.view.top, secondTicket.width, secondTicket.height);
		const secondTickRec: Rectangle = new Rectangle(secondData.view.left, secondData.view.top, secondData.view.width, secondData.view.height);
		
		const x: number = Math.max(tickRec.x, secondTickRec.x);
		const width: number = Math.min(tickRec.x + tickRec.width, secondTickRec.x + secondTickRec.width);
		const height: number = Math.max(tickRec.y, secondTickRec.y);
		const y: number = Math.min(tickRec.y + tickRec.height, secondTickRec.y + secondTickRec.height);
		return new Rectangle(x, y, width - x, height - y);
	}
	/**
	 * Tests if two DisplayObjects are intersecting
	 * @param {PIXI.DisplayObject} a
	 * @param {PIXI.DisplayObject} b
	 */
	private _intersects(a: SpriteTicket, b: SpriteTicket): boolean {
		const ticketA: Ticket = a.getData();
		const ticketB: Ticket = b.getData();
		
		return ticketA.view.left + ticketA.view.width > ticketB.view.left 
		&& ticketA.view.left < ticketB.view.left + ticketB.view.width 
		&& ticketA.view.top + ticketA.view.height > ticketB.view.top 
		&& ticketA.view.top < ticketB.view.top + ticketB.view.height;
	}
	/**
	 * Kill all the things
	 * @param options
	 */
	public destroy(options?){
		this._destroyList.forEach((thing) => {
			thing.destroy(options);
		});
		
		this.shutDownSubject$.next(true);
		this._quadTree = null;
		this._graphics = null;
		
		LevelOfDetail.emitter.off(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
	}
}
