"use strict";

import * as angular from "angular";

angular.module('ticketspaceApp').directive('ticketContents', ["$timeout", "subscriber", function ($timeout, subscriber) {
	
	return {
		restrict: 'EA',
		scope: true,
		priority: 1,
		transclude: true,
		link: function(scope:any, element:any, attrs){
			var ignoreLod = !!attrs.ignoreLod;
			
			scope.shouldTransclude = !!attrs.transcludeOverride;
			if(attrs.doNothing){return;}
			
			scope.ticket.local.element = element;
			element[0].ticket = scope.ticket;
			
			scope.locStyle = function(){
				var locColorRec = scope.locations.locationColors.$getRecord(scope.locations.$getRecord(scope.ticket.data.locationId));
				if(!locColorRec){return {};}
				return {
					"border-top-width": "40px",
					"border-top-style": "solid",
					"border-top-color": locColorRec.data.color
				};
			}
			
			if(scope.ticket.local.createdCallback){
				scope.ticket.local.createdCallback();
				delete scope.ticket.local.createdCallback;
			}

			//need to watch everything relative to the show check
			scope.$watch('ticket.local.readOnly', updateContents); //when does this even get set?
			scope.$watch('ticket.data.actualFinish', updateContents);
			scope.$watch('ticket.data.isActive', updateContents);
			
			var incElement;
			var textOnlyElement = element.find('.lod-text-only');
			var pElement = element.find('.promise-line-wrapper');
			
			var currentState = {};
			var workingState = {};
			var stateList = [
				"hide", 'lod-text-only-on'
			];
			
			function setState(states, forceRedraw){
				//console.log('states', states);
				//setTimeout(function(){
					for(var state in states){
						if(states[state] !== currentState[state] || forceRedraw){
							currentState[state] = states[state];
							switch(state){
								case 'hide':
									if(states[state]){ incElement.addClass('hide'); }
									else{ incElement.removeClass('hide');}
									break;
								case 'hide-promise':
									if(states[state]){ pElement.addClass('hide-promise'); }
									else{ pElement.removeClass('hide-promise');}
									break;
								case 'lod-text-only-on':
									if(states[state]){ 
										incElement.addClass('lod-text-only'); 
										textOnlyElement.addClass('lod-text-only-on');
									}
									else{ 
										incElement.removeClass('lod-text-only');
										textOnlyElement.removeClass('lod-text-only-on');
									}
									break;
								default: console.log('wtf?');
							}
						}
					}
				//});
			}
			
			//function updateLod(index, zoom){
			function updateLod(state, forceRedraw){
				if(ignoreLod){return;}
				if(!incElement){ return; }
				setState(state, forceRedraw);
			}
			
			if(!ignoreLod){
				scope.lod.watch.subscribe(updateLod);
			}
			
			scope.$on('$includeContentLoaded', function(e, src){
				if(src === 'tpl/ticket/view.html' || src === 'tpl/ticket/active.html'){
					incElement = element.find('ng-include');
					updateLod(scope.lod.workingState, true);
				}
				else if(src === 'tpl/ticket/edit.html'){
					textOnlyElement.removeClass('lod-text-only-on');
				}
			});
			scope.$on('$destroy',function(){
				element.ticket = null;
				scope.lod.watch.unsubscribe(updateLod);
			});
			
			function updateContents(){
				if(scope.ticket.data.isActive){
					scope.template = 'tpl/ticket/active.html';
				}
				else{
					scope.template = 'tpl/ticket/view.html';
				}
			}
		},
		template: require("tpl/ticket/ticket-contents.html")
		// template:'<div class="promise-line-wrapper"></div>',
    };
}]);
