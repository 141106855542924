import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Point, Rectangle } from "pixi.js";
import { FancyText, IFontOptions } from "../fontpath/";
import * as Utils from "utils";
import { Side } from "../../common/models/Side";
import { TicketUtils } from "../utils/TicketUtils";
import { Ticket } from "../../common/models/Ticket";
import {LEVEL_OF_DETAIL_TABLE, LevelOfDetail} from "../core/LevelOfDetail";

export interface TicketTextSettings {
	TASK_LEFT_MARGIN?: number;
	TASK_LEFT_MARGIN_PULL?: number;
	TASK_RIGHT_MARGIN?: number;
	TASK_RIGHT_MARGIN_PULL?: number;
	TASK_TOP_MARGIN?: number;
	TASK_TOP_MARGIN_PULL?: number;
	TASK_BOTTOM_MARGIN?: number;
	TASK_BOTTOM_MARGIN_PULL?: number;
	TASK_DRAWING_AREA_WIDTH?: number;
	TASK_DRAWING_AREA_WIDTH_PULL?: number;
	TASK_DRAWING_AREA_HEIGHT?: number;
	TASK_DRAWING_AREA_HEIGHT_PULL?: number;
	
	MILESTONE_LEFT_MARGIN?: number;
	MILESTONE_LEFT_MARGIN_PULL?: number;
	MILESTONE_RIGHT_MARGIN?: number;
	MILESTONE_RIGHT_MARGIN_PULL?: number;
	MILESTONE_TOP_MARGIN?: number;
	MILESTONE_TOP_MARGIN_PULL?: number;
	MILESTONE_BOTTOM_MARGIN?: number;
	MILESTONE_BOTTOM_MARGIN_PULL?: number;
	MILESTONE_DRAWING_AREA_WIDTH?: number;
	MILESTONE_DRAWING_AREA_WIDTH_PULL?: number;
	MILESTONE_DRAWING_AREA_HEIGHT?: number;
	MILESTONE_DRAWING_AREA_HEIGHT_PULL?: number;
	
	CONSTRAINT_LEFT_MARGIN?: number;
	CONSTRAINT_LEFT_MARGIN_PULL?: number;
	CONSTRAINT_RIGHT_MARGIN?: number;
	CONSTRAINT_RIGHT_MARGIN_PULL?: number;
	CONSTRAINT_TOP_MARGIN?: number;
	CONSTRAINT_TOP_MARGIN_PULL?: number;
	CONSTRAINT_BOTTOM_MARGIN?: number;
	CONSTRAINT_BOTTOM_MARGIN_PULL?: number;
	CONSTRAINT_DRAWING_AREA_WIDTH?: number;
	CONSTRAINT_DRAWING_AREA_WIDTH_PULL?: number;
	CONSTRAINT_DRAWING_AREA_HEIGHT?: number;
	CONSTRAINT_DRAWING_AREA_HEIGHT_PULL?: number;
}

export class TicketTextFontPath extends FancyText implements ComponentInterface {
	public active: boolean = true;
	public visible: boolean = true;
	public align = {x: false, y: false};
	public maskArea: Rectangle = new Rectangle(0, 0, 1, 1);
	protected ticket: TicketContainer;
	constructor(fontFamily: string, planState: PlanState, ticketContainer: TicketContainer, text: string = "", fontOptions: IFontOptions = {fontSize: 22, wordWrapOptions: {}}) {
		super(fontFamily, text, fontOptions);
		
		this.ticket = ticketContainer;
		this.tint = this.ticket.liveRoleTextColor;
	}
	
	public positionCenter(ticket: Ticket) {
		// Get the center point of the text
		const textCenter: Point = new Point((this.widthPx / 2), (this.heightPx / 2));
		// Get the center point of the maskArea
		const drawCenter: Point = new Point(this.maskArea.x + (this.maskArea.width / 2), this.maskArea.y + (this.maskArea.height / 2));
		if (this.align.x){
			this.x = (drawCenter.x - textCenter.x);
		}
		if (this.align.y){
			this.y = Utils.clamp(drawCenter.y - textCenter.y, this.maskArea.y);
		}
	}
	
	protected _getHeightOffset(ticket: Ticket): number {
		let _offset: number = 0;
		if (ticket.view.location){
			_offset += (ticket.view.side === Side.PULL) ? 40 : 25;
		}
		if ( (ticket.view.liveStart || ticket.view.liveFinish) && (LevelOfDetail.getLODLevel() >= LEVEL_OF_DETAIL_TABLE.MEDIUM || LevelOfDetail.isForced)){
			_offset += (ticket.view.side === Side.PULL) ? 36 : 20;
		}
		
		return _offset;
	}
	
	/**
	 * Applies offset and drawing area settings depending on the ticket type.
	 * @param {TicketContainer} ticketContainer
	 * @param {TicketTextSettings} settings
	 */
	protected applyOffsetAndDrawArea(ticketContainer: TicketContainer, settings: TicketTextSettings) {
		let data = ticketContainer.getData();
		let type = TicketUtils.getTicketType(data).toUpperCase();
		// Append a '_PULL' suffix if required.
		let pullSuffix = (data.view.side === Side.PULL) ? "_PULL" : "";
		
		// Set a width and height. This is so we can override the width or height from the ticket data.
		let width = settings[type + "_DRAWING_AREA_WIDTH" + pullSuffix] || data.view.width;
		let height = settings[type + "_DRAWING_AREA_HEIGHT" + pullSuffix] || data.view.height;
		
		// Set text offset (Changes depending on the active components
		this.wordWrapWidth = width - settings[type + "_RIGHT_MARGIN" + pullSuffix] - settings[type + "_LEFT_MARGIN" + pullSuffix];
		this.maskArea.x = settings[type + "_LEFT_MARGIN" + pullSuffix];
		this.maskArea.y = settings[type + "_TOP_MARGIN" + pullSuffix];
		
		// Set text drawing area.
		this.maskArea.width = this.wordWrapWidth as number;
		this.maskArea.height = (height - settings[type + "_BOTTOM_MARGIN" + pullSuffix]);
	}
	
	/**
	 * When text changes, re-calculate the trig data.
	 * @param {string} newText
	 * @param {string} oldText
	 */
	public onTextChanged = (newText: string, oldText: string) => {
		this.setText(newText);
		this.calculateText();
	};
	/**
	 * Override this to change where the text data comes from
	 * @param text
	 */
	public setText(text: string){
		if (!text || text === this._lastText){return}
		this.text = "".concat(text);
		this.dirty = true;
	}
	
	public destroy(options?): void {
		super.destroy(options);
	}
}