import {Component, ElementRef, Renderer2, ChangeDetectorRef, Input, forwardRef, AfterViewInit} from '@angular/core';
import {DomHandler} from 'primeng/components/dom/domhandler';
import {ObjectUtils} from 'primeng/components/utils/objectutils';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {trigger,state,style,transition,animate} from '@angular/animations';

import {MultiSelect} from 'primeng/components/multiselect/multiselect';

const DATATABLE_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MultiSelectOverrideComponent),
	multi: true
};

@Component({
	selector: "p-multiselect-custom",
	template: require('../tpl/multiselect-override.html'),
	providers: [DomHandler, ObjectUtils, DATATABLE_VALUE_ACCESSOR],
	animations: [
		trigger('overlayAnimation', [
			state('void', style({
				transform: 'translateY(5%)',
				opacity: 0
			})),
			state('visible', style({
				transform: 'translateY(0)',
				opacity: 1
			})),
			transition('void => visible', animate('225ms ease-out')),
			transition('visible => void', animate('195ms ease-in'))
		])
	],
	host: {
		'[class.ui-inputwrapper-filled]': 'filled',
		'[class.ui-inputwrapper-focus]': 'focus'
	},
})

export class MultiSelectOverrideComponent extends MultiSelect implements AfterViewInit{
	@Input() list: any = [];
	@Input() model: any = [];
	@Input() label: string = null;
	@Input() selectAllText: string = "Select All Roles";
	@Input() placeholder: string = 'Choose';
	@Input() sortKey: string = 'label';
	constructor(public el: ElementRef, public domHandler: DomHandler, public renderer: Renderer2, public objectUtils: ObjectUtils, private cd2: ChangeDetectorRef) {
		super(el, domHandler, renderer, objectUtils, cd2);
	}
	
	ngAfterViewInit(): void {
		if (this.options && this.sortKey){
			this.options.sort((first, second) => {
				if (!first[this.sortKey] || !second[this.sortKey]){return 0}
				const firstLabel: string = first[this.sortKey].toLowerCase();
				const secondLabel: string = second[this.sortKey].toLowerCase();
				if(firstLabel < secondLabel) { return -1; }
				if(firstLabel > secondLabel) { return 1; }
				return 0;
			})
		}
	}
}