import * as angular from "angular";
/**
 * @namespace CanvasWrapper.Interaction
 * @desc Links the DoubleTap, Hover, Tap, Pan, and Pinch factory and returns it
 */
angular.module('CanvasWrapper').factory("InteractionManager", InteractManagerFactory);
/**
 * @namespace InteractionManager
 * @returns {CanvasWrapper.Interaction.InteractionManager}
 * @memberOf CanvasWrapper.Interaction
 * @constructor
 */
InteractManagerFactory.$inject = ["InteractionTap", "InteractionDoubleTap", "InteractionHover", "InteractionPinch", "InteractionPan"];
function InteractManagerFactory(InteractionTap, InteractionDoubleTap, InteractionHover, InteractionPinch, InteractionPan){
	return {
		pinch: InteractionPinch,
		pan: InteractionPan,
		tap: InteractionTap,
		doubleTap: InteractionDoubleTap,
		hover: InteractionHover
	}
}
