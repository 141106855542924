import * as angular from "angular";

angular.module('CanvasWrapper').factory("InteractionHandleEvent", HandleEventFactory);
/**
 * @namespace HandleEvent
 * @returns {CanvasWrapper.Interaction.HandleEvent}
 * @memberOf CanvasWrapper.Interaction
 * @constructor
 */
function HandleEventFactory(){
	function handleEvent(displayNode){
		if (!displayNode.plugins){
			return displayNode
		} else {
			return displayNode["plugins"]["interaction"]
		}
	}
	return handleEvent
}
