import { DisplayObject } from 'pixi.js';
/**
 * A simple utility class which adds helper functions to manage culling of display objects
 */
export class DisplayObjectCulling{
	constructor(){}
	/**
	 * @param displayObject {PIXI.DisplayObject} The instance of the display object to check.
	 * @param renderSession Current render session
	 * @returns {boolean} true if bounds rect does intersect with the stage rect (inferred by renderer size)
	 */
	public isVisible(displayObject: DisplayObject, renderSession){
		let bounds = displayObject.getBounds();
		return (bounds.x + bounds.width >= renderSession.screen.x &&
			bounds.y + bounds.height >= renderSession.screen.y &&
			bounds.x <= renderSession.screen.width &&
			bounds.y <= renderSession.screen.height)
	}
}