import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture, Point, utils, Rectangle } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Side } from "../../common/models/Side";
import { Ticket, TicketTypes } from "../../common/models/Ticket";
import { IMAGE_PADDING } from "../interfaces/image-padding.interface";
import { TICKET_TEXTURE_OPTIONS } from "../tickets";
import { EventBinder } from "ng2/canvas-renderer/interaction/interaction-utils";
import { PAST_DUE_NOTICE, DEPENDENCY_ERROR_NOTICE } from "../../common/strings";

export enum TICKET_ERROR_ALIGNMENT_SETTINGS {
	TASK_OFFSET_LEFT_ACTIVE = 0,
	TASK_OFFSET_TOP_ACTIVE = 0,
	// Davina said to offset by 7 (Error icon can be a bit too close to Duration text otherwise)
	TASK_OFFSET_LEFT_PULL = 7,
	TASK_OFFSET_TOP_PULL = 0,
	
	// Spec says the error icon is 54px away from the right edge of a milestone
	// So we offset 54px (-54, we want to move left from the right edge)
	MILESTONE_OFFSET_LEFT_ACTIVE = -68,
	// Specs say the Y is 136px from the top middle.
	MILESTONE_OFFSET_TOP_ACTIVE = 136,
	// Spec says the error icon is 100px from the right edge of a milestone
	MILESTONE_OFFSET_LEFT_PULL = -100,
	// Specs says the error icon is 268px from the top point of a milestone
	MILESTONE_OFFSET_TOP_PULL = 268,
	
	// Specs says error icon is 20px from the right edge of the constraint
	CONSTRAINT_OFFSET_LEFT_ACTIVE = -20,
	// Spec says the error icon is 110px from the top of the constraint
	CONSTRAINT_OFFSET_TOP_ACTIVE = 110,
	// Spec says the error icon is 28px from the right edge of a constraint
	CONSTRAINT_OFFSET_LEFT_PULL = -28,
	// Specs says the error icon is 214px from the top point of a constraint
	CONSTRAINT_OFFSET_TOP_PULL = 214
}

export class TicketError extends TicketComponentHelper implements ComponentInterface {

	private _dependencyError: Texture;
	private _scheduleError: Texture;
	private binder: EventBinder;
	private isError: boolean = false;
	
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);

		this._scheduleError = texture;
		this._dependencyError = utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_DEPENDENCY_ERROR_NAME];

		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer);
		
		this.binder = new EventBinder(this);
		this.binder.add('hammer-singletap', ()=>{
			if (this.renderable){
				this._logMessageOnClick();
			}
		});
	}
	
	private _logMessageOnClick(): void {
		if (this.isError){
			Logging.warning(DEPENDENCY_ERROR_NOTICE);
		} else {
			Logging.warning(PAST_DUE_NOTICE);
		}
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data: Ticket = ticketContainer.getData();
		this.renderable = !data.view.isActualized && (data.view.hasScheduleViolations || data.view.hasPredecessorOutOfOrder);
		if (!this.renderable){
			this.interactive = false;
			this.cursor = null;
			return
		}
		this.interactive = true;
		this.cursor = 'pointer';
		const side: string = data.view.side;
		
		// Set scale of the texture
		if (side === Side.PULL){
			this.width = 58;
			this.height = 62;
		} else {
			this.width = 49;
			this.height = 49;
		}
		
		const pos: Point = this._getPoint(data);
		this.x = pos.x;
		this.y = pos.y;
		// Do a texture swap
		if (data.view.hasPredecessorOutOfOrder) {
			this.texture = this._dependencyError;
			this.isError = true;
		} else if (data.view.hasScheduleViolations) {
			this.texture = this._scheduleError;
			this.isError = false;
		}
		
	};
	
	private _getPoint(data: Ticket) {
		let point = new Point(0, 0);
		const type: string = data.view.type;
		
		switch (type){
			case TicketTypes.CONSTRAINT:
				if (data.view.side === Side.ACTIVE){
					// Set x to the width of the constraint
					// Add the offset to the x axes (-20 for active)
					// Subtract half the width of the error icon
					point.x = data.view.width + TICKET_ERROR_ALIGNMENT_SETTINGS.CONSTRAINT_OFFSET_LEFT_ACTIVE - (this.width / 2) + IMAGE_PADDING.ACTIVE_SHAPE;
					// Set the Y to the offset value
					// Subtract half the error icon height
					point.y = TICKET_ERROR_ALIGNMENT_SETTINGS.CONSTRAINT_OFFSET_TOP_ACTIVE - (this.height / 2) + IMAGE_PADDING.ACTIVE_SHAPE;
				} else {
					// Set x to the width of the constraint
					// Add the offset to the x axes (-28 for pull)
					// Subtract half the width of the error icon
					point.x = data.view.width + TICKET_ERROR_ALIGNMENT_SETTINGS.CONSTRAINT_OFFSET_LEFT_PULL - (this.width / 2) + IMAGE_PADDING.PULL_SHAPE;
					// Set the Y to the offset value
					// Subtract half the error icon height
					point.y = TICKET_ERROR_ALIGNMENT_SETTINGS.CONSTRAINT_OFFSET_TOP_PULL - (this.height / 2) + IMAGE_PADDING.PULL_SHAPE;
				}
				break;
			case TicketTypes.MILESTONE:
				if (data.view.side === Side.ACTIVE){
					// Set the x to the width of the data.
					// Add half the width of the error icon (spec aligned to the center of the icon)
					// Add a negative offset to the x axis (Spec called for 54px)
					point.x = data.view.width + (this.width / 2) + TICKET_ERROR_ALIGNMENT_SETTINGS.MILESTONE_OFFSET_LEFT_ACTIVE;
					// Set the Y to the offset from the top (136)
					// Subtract the height of the error icon
					point.y = TICKET_ERROR_ALIGNMENT_SETTINGS.MILESTONE_OFFSET_TOP_ACTIVE - (this.height);
				} else {
					// Set the x to the width of the data.
					// Add half the width of the error icon (spec aligned to the center of the icon)
					// Add a negative offset to the x axis (Spec called for -100)
					point.x = data.view.width + (this.width / 2) + TICKET_ERROR_ALIGNMENT_SETTINGS.MILESTONE_OFFSET_LEFT_PULL;
					// Set the Y to the offset from the top (268)
					// Subtract the height of the error icon
					point.y = TICKET_ERROR_ALIGNMENT_SETTINGS.MILESTONE_OFFSET_TOP_PULL - (this.height);
				}
				break;
			default:
				if (data.view.side === Side.ACTIVE){
					// Position to bottom-right corner of ticket
					// - Offset by error icon width and height
					// - offset by 5 on both axes (padding)
					// point.x = (data.view.width) - this.width - TICKET_ERROR_ALIGNMENT_SETTINGS.TASK_OFFSET_LEFT_ACTIVE;
					// point.y = (data.view.height) - this.height - TICKET_ERROR_ALIGNMENT_SETTINGS.TASK_OFFSET_TOP_ACTIVE;
					// MOC-2916
					point.x = (data.view.width) - (this.width / 2) + TICKET_ERROR_ALIGNMENT_SETTINGS.TASK_OFFSET_LEFT_ACTIVE;
					point.y = (data.view.height) - (this.height / 2) + TICKET_ERROR_ALIGNMENT_SETTINGS.TASK_OFFSET_TOP_ACTIVE;
				} else {
					// Davina said to offset by 7 (Error icon can be a bit too close to Duration text otherwise)
					point.x = (data.view.width) - (this.width / 2) + TICKET_ERROR_ALIGNMENT_SETTINGS.TASK_OFFSET_LEFT_PULL;
					point.y = (data.view.height) - (this.height / 2) + TICKET_ERROR_ALIGNMENT_SETTINGS.TASK_OFFSET_TOP_PULL;
				}
				break;
		}
		
		return point
	}
	
	public destroy(options?){
		this.binder.destroy();
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}