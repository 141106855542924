import { Injectable } from '@angular/core';
import { Router, Resolve, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import * as jwt_decode from "jwt-decode";

import { AuthService } from "ng2/common/ajs-upgrade-providers";

export enum UnsubstantiatedRoutePaths{
	logout = "logout",
	auth = "auth"
}

 
@Injectable()
export class UnsubstantiatedRouteExecution implements CanActivate {
	constructor(private router: Router, private authService: AuthService){
	}
	
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		switch(route.routeConfig.path){
			case UnsubstantiatedRoutePaths.logout: return this.logout();
			case UnsubstantiatedRoutePaths.auth: return this.auth(route);
			default: return false;
		}
	}
	
	logout(){
		this.authService.auth.$signOut();
		this.router.navigate(['/login']);
		return false;
	}
	auth(route: ActivatedRouteSnapshot){
		let token = route.queryParamMap.get('token');
		//special token auth
		if(token){
			this.authService.auth.$signInWithCustomToken(token)
			.then((data)=>{
				console.log('authed', data);
				var deTokenify:any = jwt_decode(token);
				if (deTokenify.claims && deTokenify.claims.projectId){
					this.router.navigate(['projects', deTokenify.claims.projectId]);
				} else {
					this.router.navigate(['projects']);
				}
			})
			.catch(function(e){
				Logging.warning("invalid token");
				console.log('auth failed', e);
			});
		}
		else{
			Logging.warning("no token");
			// this.router.navigate(['login']);
		}
		
		return false;
	}
	
}
