'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";

//todo, edit the directive (map the object from the controller through the directive).
//modify the directive to query isWork for each date and use that to determine drawn columns
//also figure out how people want to handle the zoomed out column, since sunday is no longer gaurenteed.
angular.module('ticketspaceApp')
.factory('firebaseProjectWorkdays', ["$q", "$firebaseObject", "fbConnection", function($q, $firebaseObject, fbConnection) {
	
	//something else worth considering is breaking this into 2 seperate factories for the two fields
	//it's slightly more efficient, and slightly more convoluted to setup and maintain
	//  ^
	//  |
	//  |
	//that's also a potential strat for combining in a seperate set of "global" workdays
	
	//workShiftsByDow = Int[7] [1,1,1,1,1,0,0]
	
	//Array instance section
	var defWorkShifts = [1,1,1,1,1,0,0];
	function ProjectWorkdaysFactory(ref){
		// call the super constructor
		var thing = $firebaseObject.call(this, ref);
		this.dateCache = {}; //real init
		this.workShiftsByDow = [];
		return thing;
	}
	ProjectWorkdaysFactory.prototype = {
		$$updated: function(snap){
			var newWorkShiftsByDow = snap.child("workShiftsByDow").val();
			var changed = !angular.equals(newWorkShiftsByDow, this.workShiftsByDow) || this.needsUpdate;

			if(!changed){return false;}
			
			//update data
			this.workShiftsByDow = newWorkShiftsByDow || defWorkShifts;
			
			changed = this.update() || changed;
			this.needsUpdate = false;
			return changed;
		},
		// arrComp: function(arr1, arr2){
		// 	if(!arr1 && !arr2){return true;}
		// 	else if(!arr1 || !arr2){ return false;}
		// 	
		// 	var l = arr2.length;
		// 	if(arr1.length !== l){return false;}
		// 	var something = true;
		// 	for(var i = 0; i < l; i++){
		// 		something = (arr1[i] === arr2[i]) && something;
		// 	}
		// 	return something;
		// },
		update: function(){
			//nuke the cache every time dow or exceptions are updated. Reads should be really fast.
			//infrequent operation, so it probably fine. Optimize that later if necessary...
			this.dateCache = {};
			
			if(this.projectExceptionDays){
				this.projectExceptionDays.forEach(function(thing){
					//theoretical implementation for a "reverse exception day"
					//if(this.projectExceptionDays[key].forceOff){dateCache[key] = true;}
					this.dateCache[thing.$id] = false;
				},this);
				return true;
			}
		},
		$save: function(){
			var self = this;
			
			return fbConnection.update(self.$ref(),{
				"workShiftsByDow": self.workShiftsByDow
			});
		},
		$destroy: function(){
			if(this.projectExceptionDays){this.projectExceptionDays.$destroy();}
			return $firebaseObject.prototype.$destroy.apply(this, arguments);
		},
		//consider soaking?
		// setDow: function(newArr){
		// 	var l = this.workShiftsByDow.length;
		// 	if(!newArr || newArr.length !== l){return;}
		// 	//console.log('first', this.workShiftsByDow);
		// 	for(var i = 0; i < l; i++){
		// 		this.workShiftsByDow[i] = newArr[i];
		// 	}
		// 	
		// 	//ensure next update works after "local apply"
		// 	//this.needsUpdate = true;
		// 	
		// 	//apply locally
		// 	this.update();
		// 	this.$$notify();
		// 	
		// 	this.$save();
		// },
		updateDow: function(){
			//apply locally
			this.update();
			this.$$notify();
			
			this.$save();
		},
		$remove: function(){
			//no-op this shit
			return $q.when(true);
			// return fbConnection.update(this.$ref(),{
				// "promisePeriodPosition": null,
				// "promisePeriodStack": null
			// });
		},
		canAdd: function(date){
			if(!date){return false;}
			return !this.projectExceptionDays.$getRecord(date);
		},
		addException: function(exception){
			if(!exception || !exception.description || !exception.date){ return $q.rejectErr("description/ date required"); }
			if(!this.canAdd(exception.date)){return $q.rejectErr("date is already in use"); }
			var ref = this.projectExceptionDays.$ref().child(exception.date);
			return fbConnection.set(ref, {description: exception.description});
		},
		setException: function(idx, data){
			//console.log('setting', idx, data);
			var item = this.projectExceptionDays[idx];
			if(!item){return $q.rejectErr("item doesn't exist");}
			if(!data || !data.description || !data.date){
				return $q.rejectErr("name and date is required");
			}
			//console.log('past the checks', item);
			if(item.$id === data.date){
				item.description = data.description;
				//console.log('should be here');
				return this.projectExceptionDays.$save(idx);
			}
			else{
				if(this.canAdd(data.date)){
					var promises = [];
					promises.push(this.projectExceptionDays.$remove(idx));
					promises.push(this.addException(data));
					return $q.all(promises);
				}
				else{
					return $q.rejectErr("date is already in use");
				}
			}
			
			
		},
		cloneForEdit: function(index, collection){
			if(index === -1){
				return { };
			}
			if(index !== -1 && collection[index] !== undefined){
				var thing = collection[index];
				var obj = {description: thing.description, date: thing.$id}
				return obj;
			}
			return {};
		},
		isWork: function(date){
			if(!this.workShiftsByDow){ return true; } //eff it, no idea how it got to this state, just throw it out
			if(!this.dateCache){this.dateCache = {}} //covers the case when this gets hit really early
			if(this.dateCache[date] !== undefined){ return this.dateCache[date]; }
			var day = utils.properMod(moment(date).day() - 1, 7); //shift it from sun = 0 to mon = 0
			this.dateCache[date] = !!this.workShiftsByDow[day];
			return this.dateCache[date];
		}
	};
	var theFactory = $firebaseObject.$extend(ProjectWorkdaysFactory);
	
	function returnFunction(refUrl, exceptionRefUrl){
		var temp = new theFactory(fbConnection.fbRef(refUrl));

		if ( exceptionRefUrl ) {
			temp.projectExceptionDays = fbConnection.fbArray(fbConnection.fbRef(exceptionRefUrl));
			temp.projectExceptionDays.$watch(function(){
				temp.update();
				temp.$$notify();
			});
		}

		return temp;
	}
	//add properties to the returnFunction for static code
	
	return returnFunction;
}]);
