// import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseObject, AngularLandConnection } from 'ng2/fancy-firebase/base';
import { User } from 'ng2/common/models';

export class UserObject extends FancyFirebaseObject<User>{
	
	constructor(angularLand:AngularLandConnection, path:any){ //fix any's
		super(angularLand, path);
	}
	
	$$updated(child:any){
		if(!child || !child.payload.exists()){ return null; }
		// return new User(child.payload.val());
		let newChild = child.payload.val()
		newChild.update = this.update;
		
		newChild.parsedName = this.parsedName(newChild);
		newChild.$id = child.key;
		
		return newChild;
	}
	
	parsedName(userData){
		if(!userData){ return 'userless'; }
		if(!userData.firstName && !userData.lastName){return userData.email;}
		return (userData.firstName ? userData.firstName : '') +' '+ (userData.lastName ? userData.lastName : '');
	}
	
	//really half-assed method just to get this working, design a proper solution when necessary
	update = (obj)=>{
		return this.listRef.update(obj);
	}
}
