import { ComponentInterface } from "../../interfaces/";
import { PlanState } from "../../../common/services/plan-state.service";
import { Component } from "../../component-system/";
import { EventBinder } from "../interaction-utils";
import { Point, utils } from "pixi.js";
import { FancyContainer } from "../../graphics/FancyContainer";
import { FancyGraphics, FancySprite } from "../../graphics/";
import { Tween, TweenManager, Easing } from "../../tween/";
import {TICKET_TEXTURE_OPTIONS} from "../../tickets/";

enum PLAN_HOLD_SETTINGS {
	CIRCLE_MAX_RADIUS = 100, // The Maximum radius for the circle animation
	CIRCLE_ANIMATION_TIME = 250, //ms
	CIRCLE_SHRINK_RADIUS = 15, // The size of the circle once it shrinks down from maximum
	CIRCLE_ALPHA = 0.3, // The alpha of the circle.
	CIRCLE_COLOR = 0x4FBDDB // The color of the circle
}

export interface IHoldCircle {
	radius: number
}

export class HoldCircle extends Component implements ComponentInterface{
	/**
	 * Boilerplate for dealing with events.
	 */
	private _arrowTop: any;
	private _arrowBottom: any;
	private _binder: EventBinder;
	private _graphics: FancyGraphics;
	private _tween: Tween;
	private _shrinkTween: Tween;
	private _tweenManager: TweenManager;
	private _holdCircle: IHoldCircle = {radius: 0};
	private _event: any;
	private _parent: FancyContainer;
	constructor(){super();}
	/**
	 * Arguments passed into setup are passed in via the ComponentManager.add(name, component, ...args)
	 * @param {PlanState} planState
	 * @param {Container} parent
	 * @param {TweenManager} tweenManager
	 * @param render
	 */
	public setup(planState: PlanState, parent: FancyContainer, tweenManager: TweenManager, render: () => void){
		this._binder = new EventBinder(parent);
		this._tweenManager = tweenManager;
		this._graphics = new FancyGraphics();
		this._parent = parent;
		
		this._arrowTop = new FancySprite(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_ARROW]);
		this._arrowBottom = new FancySprite(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_ARROW]);

		this._arrowTop.scale.x = 0.20;
		this._arrowTop.scale.y = 0.20;
		this._arrowBottom.scale.x = 0.20;
		this._arrowBottom.scale.y = 0.20;
		this._arrowTop.alpha = 0.3;
		this._arrowBottom.alpha = 0.3;

		this._arrowBottom.pivot = new Point(0.5, 0.5);
		this._arrowTop.pivot = new Point(0.5, 0.5);
		this._arrowTop.rotation = 5.49779; // Value is in radians. 300 degrees
		this._arrowBottom.rotation = 2.35619; // Value is in radians. 120 degrees

		// Do some sprite setup
		this._parent.addChild(this._arrowBottom, this._arrowTop, this._graphics);
		
		
		this._binder.add('hammer-hammer.input', (event) => {
			if (event.isFirst){
				if (this._tween){this._clearTween();}
				
				this._event = event;
				// Create the animation for the shrinking circle.
				this._shrinkTween = tweenManager.tween(this._holdCircle, {radius: PLAN_HOLD_SETTINGS.CIRCLE_SHRINK_RADIUS},  PLAN_HOLD_SETTINGS.CIRCLE_ANIMATION_TIME, {
					onUpdate: this.onTweenUpdate,
					easing: Easing.CUBIC.OUT,
					onComplete: () => {
						this.setArrowState(this._arrowTop, true);
						this.setArrowState(this._arrowBottom, true);
					}
				});
				
				this._tween = tweenManager.tween(this._holdCircle, {radius: PLAN_HOLD_SETTINGS.CIRCLE_MAX_RADIUS}, PLAN_HOLD_SETTINGS.CIRCLE_ANIMATION_TIME, {
					onUpdate: this.onTweenUpdate,
					easing: Easing.CUBIC.IN
				});
				
				this._tween.chain(this._shrinkTween);
				
				this._tween.start();
			} else if (event.isFinal){
				this._clearTween();
			} else {
				if(Math.abs(event.deltaX) >= 3 || Math.abs(event.deltaY) >= 3){
					this._clearTween();
				}
			}
		});
		
		this._binder.add('hammer-pressup', () => {
			this._clearTween();
			render();
		});
	}

	private setArrowState(arrow: FancySprite, value: boolean): void {
		arrow.active = value;
		arrow.visible = value;
		arrow.renderable = value;
	}

	private _clearTween = () => {
		if (this._tween){
			this._tween.destroy();
			this._holdCircle.radius = 0;
			this._shrinkTween.destroy();
		}
		this.setArrowState(this._arrowTop, false);
		this.setArrowState(this._arrowBottom, false);
		this._graphics.clear();
	};

	private onTweenUpdate = () => {
		const pos: Point = this._parent.toLocal(this._event.center);
		this._arrowBottom.x = pos.x + this._arrowBottom.width + 32;
		this._arrowBottom.y = pos.y + this._arrowBottom.height + 8;
		this._arrowTop.x = pos.x - this._arrowTop.width - 32;
		this._arrowTop.y = pos.y - this._arrowTop.height - 8;

		this._graphics.clear();
		this._graphics.beginFill(PLAN_HOLD_SETTINGS.CIRCLE_COLOR, PLAN_HOLD_SETTINGS.CIRCLE_ALPHA);
		this._graphics.drawCircle(pos.x, pos.y, this._holdCircle.radius);
		this._graphics.endFill();
	};

	/**
	 * Called on destroy. Clean-up your shit here.
	 */
	public destroy(){
		this._clearTween();
		this._binder.destroy();
		this._arrowBottom.destroy();
		this._arrowTop.destroy();
	}
}
