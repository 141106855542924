import { Subject, BehaviorSubject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

export interface SessionSettings{
	hideCompletedTickets?: boolean,
	showPinHistory?: boolean,
	showAllDependencies?: boolean,
	enableTicketOverlap?: boolean,
	enableSolidLines?: boolean,
	showLaborCount?: boolean,
	crewSizesEnabled?: boolean,
	sharedMeeting?: boolean
}

let keys = ["hideCompletedTickets", "showPinHistory", "showAllDependencies", "enableTicketOverlap", "enableSolidLines", "showLaborCount", "crewSizesEnabled", "sharedMeeting"];


export class SessionStorage {
	public innerChanged$ = new BehaviorSubject({});
	private shutdownSubject = new Subject();
	public changed$:Observable<SessionSettings> = this.innerChanged$.pipe(takeUntil(this.shutdownSubject));
	
	//undefined === false
	private _data: SessionSettings = {};

	constructor(){
		this.showAllDependencies = true;
		this.hideCompletedTickets = false;
	};
	
	public update(obj: SessionSettings){
		let somethingChanged = false;
		keys.forEach(key => {
			if(obj[key] !== undefined && obj[key] !== this._data[key]){
				this._data[key] = obj[key];
				somethingChanged = true;
			}
		})
		
		if(somethingChanged){
			this.innerChanged$.next(Object.assign({}, this._data));
		}
	}
	
	set hideCompletedTickets(val: boolean){
		this.update({"hideCompletedTickets": val})
	}
	
	set showPinHistory(val: boolean){
		this.update({"showPinHistory": val})
	}
	
	set showAllDependencies(val: boolean){
		this.update({"showAllDependencies": val});
	}
	
	set enableTicketOverlap(val: boolean){
		this.update({"enableTicketOverlap": val});
	}
	
	set enableSolidLines(val: boolean){
		this.update({"enableSolidLines": val});
	}
	
	set showLaborCount(val: boolean){
		this.update({"showLaborCount": val});
	}

	set crewSizesEnabled(val: boolean){
		this.update({"crewSizesEnabled": val});
	}

	set sharedMeeting(val: boolean){
		this.update({"sharedMeeting": val});
	}
	
	public getSetting(key: string): boolean{
		return this._data[key];
	}
	
	destroy(){
		this.shutdownSubject.next(true);
	}
}

export const sessionStorage = new SessionStorage();
