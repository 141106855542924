import * as angular from "angular";
import * as tinycolor from "tinycolor2"
import * as utils from 'utils';
angular.module('ticketspaceApp').factory("firebaseProjectColors", ProjectColorFactory);
ProjectColorFactory.$inject = ["$q", "$firebaseArray", "SystemColors", "$timeout"];
function ProjectColorFactory($q, $firebaseArray, SystemColors, $timeout){
	/**
	 * @class Color
	 * @desc A thing wrapper class which represents a Color object
	 * @param snap {DataSnapshot} - A firebase data snapshot
	 * @constructor
	 * @private
	 * @memberOf firebaseProjectColors
	 */
	function Color(snap){
		this.$id = snap.key;
		this.update(snap);
	}
	Color.prototype = {
		update: function(snap){
			var oldData = this.data || {};
			this.data = snap.val();
			if (!this.data.id){this.data.id = snap.key;}
			if (this.data.color){this.data.color = this.data.color.toLowerCase();}
			return !angular.equals(this.data, oldData);
		}
	};
	/**
	 * @namespace firebaseProjectColors
	 * @desc AngularFire service to manage project colors
	 * @memberOf ticketspaceApp
	 * @constructor
	 */
	function ProjectColors(ref){
		this.colorPalette = [[]];
		this._usedColors = {};
		this.editColorsModel = {
			active: false,
			startColor: null,
			currentColor: null,
			hideColorPicker: true,
			palettePosition: {},
			showAddColorButton: true
		};
		var thing = $firebaseArray.call(this, ref);
		thing.editColorsModel = this.editColorsModel;
		return thing
	}
	ProjectColors.prototype = {
		$$added: function(snap){
			var newRec = $firebaseArray.prototype.$$added.apply(this, arguments);
			if (!this.colorExists(newRec.color)){this.pushToColorPalette(this.colorPalette, newRec, SystemColors.MAX_COLORS_PER_ROW);}
			this.convertUsedColors(newRec.$id, snap.val());
			return new Color(snap);
		},
		$$updated: function(snap){
			var record = this.$getRecord(snap.key);
			if (record){
				var oldColor = record.data.color;
				var newColor = snap.child('color').val();
				this.updatePalette(oldColor, newColor);
			}
			record.update(snap);
		},
		$add: function(newData){
			if (!newData.color || !newData){return $q.reject("Must select a color!");}
			var self = this;
			if (!self.colorExists(newData.color)) {
				return $firebaseArray.prototype.$add.call(self, newData).then(function(snap) {
					Logging.notice("New color has been added!");
					return snap.ref.update({id: snap.key})
				})
			}else{
				Logging.warning("Must select a color!");
				return $q.reject("Must select a color!");
			}
		},
		handleAddColor: function(newColor){
			var self = this;
			return $q(function(resolve, reject){
				var record = self.getRecordByColor(newColor);
				if (!record){
					self.cleanupPalette();
					self.$add({color: newColor}).then(function(snap){
						self.resetEditModel();
						if (self.$list.length >= SystemColors.MAX_COLORS_PER_PALETTE){self.editColorsModel.showAddColorButton = false;}
						resolve(self.$getRecord(snap.key));
					})
				}
				else{
					resolve(record);
				}
			})
		},
		$$removed: function(snap){
			this.removeColorFromPalette(this.colorPalette, snap.child('color').val());
			if (this.$list.length <= SystemColors.MAX_COLORS_PER_PALETTE){this.editColorsModel.showAddColorButton = true;}
			return $firebaseArray.prototype.$$removed.call(this, snap);
		},
		getRecordByColor: function(color){
			color = utils.colorToHex(color);
			for (var i = 0; i < this.$list.length; i++){
				const dataColor = utils.colorToHex(this.$list[i].data.color);
				if (dataColor === color){
					return this.$list[i]
				}
			}
			return null;
		},
		getColor: function(id){
			var rec = this.$getRecord(id);
			return (rec) ? rec.data.color : "#000000"
		},
		addNewUsedColor: function(color){
			if (!this._usedColors[color]){
				this._usedColors[color] = 1;
			} else {
				this._usedColors[color]++;
			}
		},
		removeUsedColor: function(color){
			if (this._usedColors[color] === 1){
				delete this._usedColors[color];
			} else {
				this._usedColors[color]--;
			}
		},
		updateUsedColors: function(action, colorId, oldColorId){
			if (!colorId || !action){return}
			this.forceConvertUsedColorsToHexString();
			var self = this;
			var usedColorsApi = {
				add: function(newColor){
					if (!self._usedColors[newColor]){
						self._usedColors[newColor] = 1;
					} else {
						self._usedColors[newColor]++;
					}
				},
				remove: function(newColor){
					if (self._usedColors[newColor] === 1){
						delete self._usedColors[newColor];
					} else {
						self._usedColors[newColor]--;
					}
				},
				change: function(newColor, oldColor){
					if (newColor !== oldColor){
						this.remove(oldColor);
						this.add(newColor);
					}
				}
			};
			if (action === 'change'){
				usedColorsApi[action](colorId, oldColorId);
			}else {
				var rec = this.$getRecord(colorId);
				if (!rec){
					usedColorsApi[action](colorId, oldColorId);
				}else{
					this.convertUsedColors(colorId, rec);
					usedColorsApi[action](rec.data.color, oldColorId);
				}
			}
		},
		updateColor: function(oldColor, newColor){
			if (!this.colorExists(newColor)) {
				var self = this;
				var record = this.getRecordByColor(oldColor);
				if (record){
					if (this._usedColors[oldColor]){this.updateUsedColors('change', newColor, oldColor);}
					return this.$ref().child(record.$id).update({color: newColor}).then(function(){
						self.resetEditModel();
						Logging.notice("Color has been updated!");
					});
				} else{
					return this.handleAddColor(newColor)
				}
			} else{Logging.warning("Color already exists!");}
			return null;
		},
		convertUsedColors: function(id, rec){
			rec = rec || this.$getRecord(id);
			if (this._usedColors[id]){
				var count = this._usedColors[id];
				delete this._usedColors[id];
				var c = (rec.data) ? rec.data.color : rec.color;
				this._usedColors[utils.colorToHex(c)] = count;
			}
		},
		updatePalette: function(oldColor, newColor){
			var pos = this.getColorPalettePosition(oldColor);
			if (pos){this.colorPalette[pos.row][pos.index] = utils.colorToHex(newColor);}
		},
		updatePaletteAtPosition: function(row, index, newColor){
			if (angular.isUndefined(row) || angular.isUndefined(index)){return}
			if (this.colorPalette[row][index] !== newColor){this.colorPalette[row][index] = utils.colorToHex(newColor);}
		},
		getColorPalettePosition: function(color){
			for (var row = 0; row < this.colorPalette.length; row++){
				var idx = this.colorPalette[row].indexOf(color);
				if (idx >= 0){
					return {
						row: row,
						index: idx
					}
				}
			}
			return false;
		},
		getUsedColors: function(){
			return this._usedColors
		},
		colorExists: function(color){
			for (var i = 0; i < this.$list.length; i++){
				if (this.$list[i].data.color === color){
					return true
				}
			}
			return false
		},
		getColorPalette: function(){
			return this.colorPalette
		},
		getFirstUnusedColor: function(){
			for (var i = 0; i < this.$list.length; i++){
				var color = this.$list[i].data.color;
				if (!this._usedColors[color]){
					return color;
				}
			}
			return (this.$list[0]) ? this.$list[0].data.color : "#000000";
		},
		pushToColorPalette: function(palette, colorData, maxItemsPerRow){
			if (!palette || !colorData || !colorData.color){return}
			colorData.color = utils.colorToHex(colorData.color.toLowerCase());
			// Only allow legitimate colors into the palette.
			var parseColor:any = tinycolor(colorData.color);
			if (!parseColor._ok){return}
			var row = palette.length-1;
			if (palette[row].length >= maxItemsPerRow){
				row++;
				palette.push([]);
				palette[row].push(colorData.color);
			} else{
				palette[row].push(colorData.color);
			}
			return palette;
		},
		removeColorFromPalette: function(palette, color){
			if (!palette || !color){return}
			var pos = this.getColorPalettePosition(color);
			if (pos){
				palette[pos.row].splice(pos.index, 1);
			}
		},
		cleanupPalette: function(){
			var row = this.colorPalette.length;
			for (row < 0; row--;){
				var idx = this.colorPalette[row].length;
				for (idx < 0; idx--;){
					var c = this.colorPalette[row][idx];
					if (!this.getRecordByColor(c)){this.removeColorFromPalette(this.colorPalette, c);}
				}
				if (this.colorPalette[row].length === 0){
					this.colorPalette.splice(row, 1);
				}
			}
		},
		editColorsBucketChanged: function(){
			if (this.editColorsModel.active){
				this.updatePaletteAtPosition(this.editColorsModel.palettePosition.row, this.editColorsModel.palettePosition.index, this.editColorsModel.startColor);
				this.editSetColorModel(this.editColorsModel.currentColor);
				this.cleanupPalette();
			}
		},
		editSetColorModel: function(c){
			this.editColorsModel.palettePosition = this.getColorPalettePosition(c);
			this.editColorsModel.startColor = c;
			this.editColorsModel.currentColor = c;
			this.editColorsModel.hideColorPicker = false;
		},
		toggleEditColors: function(){
			if (this.$list.length >= SystemColors.MAX_COLORS_PER_PALETTE){this.editColorsModel.showAddColorButton = false;}
			this.editColorsModel.active = !this.editColorsModel.active;
			if (!this.editColorsModel.active){this.revertEdit();}
			this.forceConvertUsedColorsToHexString();
		},
		forceConvertUsedColorsToHexString: function(){
			var self = this;
			this.$list.forEach(function(rec){self.convertUsedColors(rec.$id, rec);})
		},
		revertEdit: function(){
			this.updatePaletteAtPosition(this.editColorsModel.palettePosition.row, this.editColorsModel.palettePosition.index, this.editColorsModel.startColor);
		},
		onEditChoose: function(){
			this.updateColor(this.editColorsModel.startColor, this.editColorsModel.currentColor);
		},
		onEditColorChanged: function(newColor){
			if (newColor && this.editColorsModel.palettePosition.row >= 0){
				this.updatePaletteAtPosition(this.editColorsModel.palettePosition.row, this.editColorsModel.palettePosition.index, newColor);
			}
		},
		onCancelEditColor: function(){
			var self = this;
			$timeout(function(){
				self.revertEdit();
				self.editColorsModel.active = false;
				self.resetEditModel();
				self.cleanupPalette();
			}, 0)
		},
		resetEditModel: function(){
			var self = this;
			$timeout(function(){
				self.editColorsModel.currentColor = null;
				self.editColorsModel.hideColorPicker = true;
				self.editColorsModel.palettePosition = false;
			}, 1);
		},
		onAddColorClicked: function(){
			if (this.$list.length >= SystemColors.MAX_COLORS_PER_PALETTE ){return}
			if (this.editColorsModel.palettePosition){
				this.updatePaletteAtPosition(this.editColorsModel.palettePosition.row, this.editColorsModel.palettePosition.index, this.editColorsModel.startColor);
				this.cleanupPalette();
			}else {
				this.cleanupPalette();
			}
			var self = this;
			$timeout(function(){
				var c = "#"+ ('00000'+(Math.random()*(1<<24)|0).toString(16)).slice(-6);
				self.pushToColorPalette(self.colorPalette, {color: c}, SystemColors.MAX_COLORS_PER_ROW);
				self.editSetColorModel(c);
			}, 0);
		}
	};
	return $firebaseArray.$extend(ProjectColors);
}
