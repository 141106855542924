angular.module('CanvasWrapper').factory("RenderLine", RenderLineFactory);

RenderLineFactory.$inject = ["PIXI"];
function RenderLineFactory(PIXI){
	/**
	 * @namespace Line
	 * @memberOf CanvasWrapper
	 * @param options {CanvasWrapper.LineOptions} Return value of CanvasWrapper.LineOptions.
	 * @constructor
	 * @returns {CanvasWrapper.Line}
	 */
	var RenderLine = function(options){
		options = options || {};
		var lineStyle = options.LINE_COLOR;
		var lineSize = options.LINE_SIZE;
		var lineAlpha = options.LINE_ALPHA;
		var line = new PIXI.Graphics(options.NATIVE_LINES);
		this.lastDraw = {};
		line.lineStyle(lineSize, parseInt(lineStyle.substring(1), 16), lineAlpha);
		this.get = function(){
			return line;
		};

		this.setPosition = function(x, y){
			return line.position.set(x, y)
		};

		this.setLineSize = function(size){
			return lineSize = size;
		};

		this.setLineStyle = function(colorString){
			return lineStyle = colorString
		};

		// Draw a line from starting X and Y coordinates to Destination X and Y coordinates
		this.draw = function(sx, sy, dx, dy, doNotClear){
			if (!doNotClear){line.clear();}
			line.lineStyle(lineSize, parseInt(lineStyle.substring(1), 16), lineAlpha);
			sx = (sx === null) ? this.lastDraw.sx : sx;
			sy = (sy === null) ? this.lastDraw.sy : sy;
			dx = (dx === null) ? this.lastDraw.dx : dx;
			dy = (dy === null) ? this.lastDraw.dy : dy;
			line.moveTo(sx, sy);
			line.lineTo(dx, dy);
			this.lastDraw = {
				sx: sx,
				sy: sy,
				dx: dx,
				dy: dy
			};
			return line;
		};

		this.setBlend = function(mode){
			this.get().blendMode = mode;
		};

		this.setVisible = function(value){
			line.visible = value;
		};

		this.clear = function(){
			line.clear();

		}
	};

	return RenderLine;
}
