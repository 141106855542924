import { Container } from "pixi.js";

import * as utils from "utils";

import {DetachInterface, constructDetach, detachable} from "./Detachable";

//merge the declaration of FancyContainer with that of detach. This makes typescript happy.
export interface FancyContainer extends DetachInterface{}

@detachable()
export class FancyContainer extends Container{
	public type: string = "FancyContainer";
	constructor(){
		super();
		constructDetach(this);
	}
	/**
	 * Returns the DataContainer's assigned data, otherwise returns null.
	 * @returns {any}
	 */
	public getData(){
		return (this.parent && (this.parent as any).getData) ? (this.parent as any).getData() : null;
	}
	// public updateTransform(){
	// 	if (this.visible){
	// 		super.updateTransform();
	// 	}
	// }
	/** Moves this container to the end of the children list on it's parent (or top of the zOrder stack) */
	public cutInLine(){
		if(!this.isDetached){
			this.parent.setChildIndex(this, this.parent.children.length -1);
		}
	}
}
