'use strict';

import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import AnimationQueue from "js/lib/ui/AnimationQueue";

/************************************************
*	Contains all the code related to interacting
*	with the plan.
*************************************************/
export default function activeLineHandling(scope){
	this.scope = scope;
	
	var startX;
	
	var element;
	var nubElement;
	var ghostElement;
	var ghostNub;
	var offset;
	var foundColumn;
	
	this.hold = function(event, currentElement){
		//console.log('evt', event, currentElement);
		
	};
	
	this.dragStart = function(event, currentElement){
		if(!scope.accessCheck.isAdmin()){ return false; }
		if(scope.accessCheck.isReadOnly()) { return false; }
		if(!scope.activeLine || scope.activeLine.$value === null){ return false; }
		
		element = $(currentElement);
		offset = parseInt(element.attr('offset')) / 2;

		ghostElement = element.clone();
		ghostElement.removeAttr('data-element-type display-line override-contents');
		ghostElement.addClass('transient');
		
		nubElement = $('.active-line-nub').parent().parent();
		ghostNub = nubElement.clone();
		ghostNub.addClass('transient');
		
		
		element.parent().append(ghostElement);
		nubElement.parent().append(ghostNub);

		startX = scope.activeLine.$value;// + offset;

		foundColumn = undefined;
	}
	
	this.drag = function(event, currentElement){
		if(scope.accessCheck.isReadOnly()) { return false; }
		
		var left = startX + event.deltaX / scope.hud.z;

		var al = startX + 12 * scope.hud.z;
		var max;
		if(!scope.pp.isInitialPeriod && scope.pp.range && scope.pp.range.endX !== null){ max = scope.pp.range.endX; }
		// if(scope.pp.promisePeriodNext){ var max = scope.pp.promisePeriodNext.endX; }
		// else if(scope.pp.promisePeriodCurrent){ var max = scope.pp.promisePeriodCurrent.endX; }
		if(!max){max = -Infinity; }
		
		scope.tickets.forEach(function (ticket) {
			if (ticket.data.left + ticket.data.width <= al) {
				if (ticket.data.left + ticket.data.width > max) {
					max = ticket.data.left + ticket.data.width;
				}
				if(ticket.data.actualFinish){
					var x = scope.activeColumnService.dateMap(ticket.data.actualFinish);
					if(x > max){ max = x; }
				}
			}
		});

		//abort if on active side
		if (left < max) {
			left = max;
		}

		var hit = utils.snapping(left, [
				scope.activeColumnService.columns(),
				[startX],
				scope.pullColumns
			],
			150, [
				function (item) {
					return item.origFinishX;
				},
				function (x) {
					return x;
				},
				function (item) {
					return item.finishX;
				}
			]);
		if (hit) {
			left = hit.loc;
			if (hit.loc !== hit.item) {
				foundColumn = hit.item;
			}
			else {
				foundColumn = undefined;
			}
		}
		else {
			left = scope.activeLine.$value;
		}

		scope.activeLine.$value = left;
		AnimationQueue(function () {
			element.css({'left': left * scope.hud.z + scope.hud.x + offset + 'px'});
			nubElement.css({'left': left * scope.hud.z + scope.hud.x + 'px'});
		});
	}
	
	this.dragEnd = function(event, currentElement) //now save -offset
	{
		if(scope.accessCheck.isReadOnly()) { return false; }
		
		var activeLineDate = scope.activeLineDate.$value;
		
		ghostElement.remove();
		ghostNub.remove();
		$('.active-line-nub').parent().removeClass('transient');
		if(foundColumn){
			console.log('found column', foundColumn);
			console.log('found column datas', foundColumn, foundColumn.activeDay, scope.activeLineDate.$value);
			if(Math.floor(startX) !== Math.floor(foundColumn.origFinishX)){
				Logging.notice("Activating");
			}
			if(foundColumn.pullColumn || foundColumn.pullColumn === 0){
				scope.fbConnection.fbRef('plans/'+scope.planUrl+'activatePullColumn').set(foundColumn.pullColumn);
				var movedColumns = scope.pullColumns.length - foundColumn.pullColumn;
				analyticsService.draggedActiveLine(activeLineDate, foundColumn.pullColumn, foundColumn.plannedFinish, movedColumns, scope.legacyState.planId);
				//console.log('found pull');
			}
			//sets active side stuff
			else if(foundColumn.activeDay !== scope.activeLineDate.$value){
				console.log('active side stuff');
				var extra = {'initiatedBy': "systemOnActiveLineChange"};
				var state = scope.legacyRouting.oldStyleSnapshot();
				return scope.snapshots.takeSnapshot(state.projectId, state.planId, scope.you.user.id, extra).then(function(){
					console.log('snapshot success');
					scope.fbConnection.fbRef('plans/'+ scope.planUrl).update({
						activeLineDate: foundColumn.activeDay,
						activeLineX: Math.round(foundColumn.origFinishX)
					});
				});
			}
		}
	}
}
