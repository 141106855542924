import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl} from '@angular/forms';

@Component({
	selector: 'fancy-checkbox',
	template: require('./fancy-checkbox.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FancyCheckboxComponent),
			multi: true
		}
	],
	host: {class: 'fancy-checkbox'},
})
export class FancyCheckboxComponent implements OnInit, OnDestroy, ControlValueAccessor {
	public shutdownSubject = new Subject();
	
	public internalCheckbox = new FormControl(false);
	
	// Both onChange and onTouched are functions
	onChange: any = () => { };
	onTouched: any = () => { };

	constructor() { }

	ngOnInit() {
		this.internalCheckbox.valueChanges.pipe( takeUntil(this.shutdownSubject) ).subscribe(val => {
			this.onChange(val);
			// this.internalCheckbox.setValue(val, {
			// 	emitEvent: false
			// });
		})
	}
	ngOnDestroy(){
		this.shutdownSubject.next(true);
	}


	/**
	 * Writes a new value to the element.
	 */
	writeValue(obj: any){
		console.log('writeValue', obj);
		// this.value = obj;
		this.internalCheckbox.setValue(obj);
	}
	/**
	 * Registers a callback function that should be called when
	 * the control's value changes in the UI.
	 */
	registerOnChange(fn: any){
		this.onChange = fn;
	}
	/**
	 * Registers a callback function that should be called when
	 * the control receives a blur event.
	 */
	registerOnTouched(fn: any){
		this.onTouched = fn;
	}
	/**
	 * This function is called by the forms API when
	 * the control status changes to or from "DISABLED".
	 */
	// setDisabledState?(isDisabled: boolean){
	// 
	// }
}
