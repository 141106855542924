import {Injectable} from "@angular/core";
import { Router, ActivationEnd, Event, ActivatedRouteSnapshot} from '@angular/router';
import { BehaviorSubject } from "rxjs";
import { filter, tap, map } from 'rxjs/operators';


@Injectable()
export class RouteUtils{
	public snapshot = new BehaviorSubject<ActivatedRouteSnapshot>(null);
	public snapshotSync: ActivatedRouteSnapshot;
	private lastSnapshotSync: ActivatedRouteSnapshot;
	
	constructor(
		private router: Router
	){
		this.router.events.pipe(
			filter( (evt: Event): evt is ActivationEnd => evt instanceof ActivationEnd ),
			map((param:ActivationEnd)=>{
				return param.snapshot;
			})
		).subscribe(this.snapshot);
		
		this.snapshot.subscribe((snapshot)=> {
			this.lastSnapshotSync = this.snapshotSync;
			this.snapshotSync = snapshot;
		});
	}
	
	/** Mostly just for analytics related stuff */
	routeName(snapshot?: ActivatedRouteSnapshot){
		if(!snapshot){ snapshot = this.snapshotSync; }
		if(!snapshot){return "";}
		
		while(snapshot.parent && snapshot.parent.url.length){
			snapshot = snapshot.parent;
		}
		return snapshot.url[0].path;
	}
	
	
}
