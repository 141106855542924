'use strict';

import * as angular from "angular";

declare const window: any;

angular.module('ticketspaceApp')

//### firebaseAccount
.factory('firebaseAccount', ["$q", "$firebaseObject", "firebaseAccountHelpers", "fbConnection", "authService", "defaultUserTier", "atomicAssembler", function($q, $firebaseObject, firebaseAccountHelpers, fbConnection, authService, defaultUserTier, atomicAssembler) {
	
	var globals:any = {};
	
	var accountFactory = $firebaseObject.$extend({
		isAwaitingAFreeTrial: function() {
			if(!this.tier || !authService.userData || authService.userData.$value === null) {return undefined;}
			return this.isStandardAccount() && authService.userData.hasNotCreatedFree;
		},
		hasUsedAllFreeTrials: function() {
			if(!this.tier || !authService.userData || authService.userData.$value === null) {return undefined;}
			return this.isStandardAccount() && !authService.userData.hasNotCreatedFree;
		},
		isStandardAccount: function() {
			if(!this.tier || !authService.userData || authService.userData.$value === null) {return undefined;}
			return this.tier === 'individual';
		},
		isSteve: function(){
			if(!this.tier || !authService.userData || authService.userData.$value === null) {return false;}
			return this.tier === "steve";
		}
	});
	
	authService.auth.$onAuthStateChanged(function(authData){
		if(authData && !globals.account){
			reload();
		}
		else if(!authData && globals.account){
			globals = {};
		}
		else{
			globals.invalid = false;
		}
	});
	
	reload();
	
	
	function reload(){
		//var accIndex = {};
		//var projIndex = {};
		
		if(!authService.user()){return;}
		
		var acc = fbConnection.fbObject('users/'+authService.user().uid + '/accountId');
		acc.$loaded().then(function(){
			if(!acc.$value === null){
				globals.invalid = true;
				console.log('user missing accountId');
				return;
			}
			var accountId = acc.$value;
			globals.account = new accountFactory(fbConnection.fbRef('accounts/'+accountId));
			globals.account.$loaded().then(function(){
				if(globals.account.$value === null){
					console.log('user\'s accountId entry does not correspond to an account');
				}
			});
		});
	}
	
	function create(userId){
		var obj:any = {
			tier: defaultUserTier,
			users: {}
		};
		obj.users[userId] = userId;
		var ref = fbConnection.fbRef('accounts').push();
		obj.id = ref.key;
		return fbConnection.set(ref, obj);
	}
	
	function addProject(projectId){
		if(globals.invalid){ return $q.rejectErr('can\'t add project'); }
		var acc = globals.account;
		if(!acc.projects){acc.projects = {};}
		
		if(acc.projects[projectId]){ $q.rejectErr('can\'t add project'); }
		
		acc.projects[projectId] = projectId;
		return acc.$save();
	}
	
	//future security rule (anyone can remove if project doesn't exist
	function removeProject(projectId, accountId){
		if(!accountId){ accountId = globals.accountId;}
		
		if(!projectId || !accountId){ return $q.rejectErr('id\'s must be defined'); }
		return fbConnection.remove(fbConnection.fbRef('accounts/'+accountId+'/projects/'+projectId));
	}
	
	function get(){
		//if(globals.invalid){ return false; }
		return globals.account;
	}
	
	return {
		removeProject: removeProject,
		addProject: addProject,
		create: create,
		createShell: firebaseAccountHelpers.createShell,
		reload: reload,
		get: get
	};
	
	
}])
.factory('firebaseAccountHelpers', ["fbConnection", "defaultUserTier", "atomicAssembler", function(fbConnection, defaultUserTier, atomicAssembler) {
	//returns an atomic update compatible structure
	function createShell(userId){
		var id = fbConnection.fbRef('accounts').push().key;
		var obj = {
			id: id,
			tier: defaultUserTier,
			users: {}
		};
		obj.users[userId] = userId;
		return atomicAssembler.segment('accounts/'+id, obj);
	}
	return {createShell: createShell};
}])
.run(["firebaseAccount", function(firebaseAccount){
	window.firebaseAccount = firebaseAccount;
}]);
