'use strict';
import * as angular from "angular";
import * as utils from "utils";
import * as jwt_decode from "jwt-decode";


import {take} from "rxjs/operators";

import {ngZone, angularfireAuth} from "ng2/InitEarly";

declare const window;
const detect = require("js/vendor/detect");
import { analyticsService } from "ng2/common/utils/AnalyticsService";

const defaultPopupTemplate = require('tpl/popup.html');

export class AuthService{
	static $inject = ["$rootScope", "$window", "$firebaseAuth", "$q", "authServiceHelpers", "fbConnection", "firebaseUsers", "firebaseUsersGetters", "firebaseUserPreferences"];
	
	public auth;
	
	private userLoadedDefer;
	private customIdentification;
	private userData;
	public userPreferences;
	private logoutListeners = [];
	
	public newAuthChanged = angularfireAuth.user;
	public newWaitForSignIn(){
		return this.newAuthChanged.pipe(take(1)).toPromise();
	}
	
	constructor(private $rootScope, private $window, private $firebaseAuth, 
			private $q, private authServiceHelpers, private fbConnection,
			private firebaseUsers, private firebaseUsersGetters, private firebaseUserPreferences){
		
		
		// var ngZone = window.ng.probe(window.getAllAngularRootElements()[0]).injector.get(window.ng.coreTokens.NgZone);
		var self = this;
			
		
		this.auth = $firebaseAuth();
		
		//dispose of this, rendered obsolete by the af2
		let old = this.auth.$onAuthStateChanged;
		this.auth.$onAuthStateChanged = function(cb, context){
			old((...args)=>{
				ngZone.run(cb, context, args);
			}, context)
		}
		
		this.userLoadedDefer = $q.defer();
		this.customIdentification = authServiceHelpers.customIdentification;
		

		
		// setTimeout(()=> {console.log('Zone authService timeout callback', Zone.current.name);	}, 3000)
		
		//include a global is mid creation flag
		this.newAuthChanged.subscribe((authData)=>{
			// console.log('Zone authService authStateChagned', Zone.current.name);		
			console.log('onAuth event fired...', authData, new Date());
			if(authData){
				console.log('...with auth!', new Date());
				authData.getIdToken().then((token)=>{
					console.log('token get', new Date());
					var deToken:any = jwt_decode(token);
					//console.log('detoken', deToken);
					//shift around where future calls look for things
					if(deToken.provider === "su"){
						//authData.provider = "auth";
						$rootScope.whoYou = authData.uid+" ("+authData.email+")";
						if(!deToken.allowAnalytics){
							authServiceHelpers.flags.preventStandardIdentify = true;
							self.customIdentification("suId", {
								"email": "su@touchplan.io",
								"firstName": "internal",
								"lastName": "tooling"
							});
						}
					}
					
					console.log('starting to get user data...',new Date());
					firebaseUsersGetters.userFromUserId(authData.uid, authServiceHelpers.flags.creationInProgress).then((data)=>{
						console.log('...user data get',new Date());
						var lcKey = "signupData"+authData.uid;
						//see if this is still happening...
						if(data.$value === null){
							// reportWarning("user with no userInfo tried to auth", {
							// 	authId: authData.uid
							// });
							console.warn('user with no userInfo tried to auth');
							analyticsService.partialUserCreation();
							//check localStorage for userData
							var lc: any;
							var userObj: any;
							
							try{ lc = localStorage.getItem(lcKey); } 
							catch(e){lc = null;}
							
							if(lc){
								userObj = JSON.parse(lc);
								//debug things:
								userObj.email = authData.email;
								userObj.id = authData.uid;
							}
							else{
								userObj = {
									"email": authData.email
								};
							}
							return firebaseUsers.create(userObj).then(()=>{
								console.log('all done');
								window.location.reload(true);
								//never resolve, just incase the reload is kind of slow
								return new $q(function(resolve, reject){});
							});
							
						}
						else{
							//empty the localstorage data (all the time)
							try { localStorage.removeItem(lcKey); }
							catch(e){}
						}
						
						self.userData = data;
						
						if(!authServiceHelpers.flags.preventStandardIdentify){
							self.customIdentification(data.id, data);
						}
						
						self.userPreferences = firebaseUserPreferences(data.id);
						//just continue without preferences
						//consider defaulting to something?
						if(!self.userPreferences){ console.log("MOC-1494 !self.userPreferences", authData.uid);return data; }
						//console.log('get preference...', new Date());
						return self.userPreferences.$loaded().then(function(){
							//console.log('...preference get', new Date());
							return data;
						});
					})
					.then(function(user){
						self.userLoadedDefer.resolve(user);
					})
					.catch(function(e){
						console.error("Something broke getting user!", e);
					});
				}).catch(function(e){
					console.log('token not get', e);
				});
			}
		});
		
		window.logout = this.logout.bind(this);
		
	}
	
	public user(){
		var obj = this.auth.$getAuth();
		if(obj){ obj.id = obj.uid; }
		return obj;
	}
	public userId(){
		var obj = this.auth.$getAuth();
		if(obj){ return obj.uid; }
		return null;
	}
	public waitForUser(){
		return this.userLoadedDefer.promise;
	}
	subscribeToLogout(fn){
		if(typeof fn === "function"){ this.logoutListeners.push(fn); }
	}
	
	logout(){
		//notify subscribers
		this.logoutListeners.forEach((fn)=>{
			fn();
		});
		this.logoutListeners = [];
		analyticsService.logOut();
		
		var self = this;
		setTimeout(()=>{
			utils.analyticsHelper.reset(['userId']);
			
			this.fbConnection.prepForLogout();
			self.auth.$signOut().then(()=> window.location.reload(false));
			
		},100);
	};
}

angular.module('ticketspaceApp').service("authService", AuthService)
.factory("authServiceHelpers", ["$window", function($window) {
	function customIdentification(userId, userObj, cb){
		//console.log('identifying');
		var uid = utils.analyticsHelper.id(userId);
		let hasWebGL: boolean = utils.isWebGLEnabled();
		
		var obj = {
			"id": userId,
			"email": userObj.email,
			"firstName": userObj.firstName,
			"lastName": userObj.lastName,
			"createdAt": userObj.userCreationDate,
			"validated_date": userObj.userValidatedDate,
			"hasValidated": userObj.hasValidated,
			"phone": userObj.phone,
			"zip": userObj.zipcode,
			"companyName": userObj.company,
			"method": userObj.method,
			"title": userObj.title,
			"lastDevice": detect.parse($window.navigator.userAgent).device.family,
			"hasWebGL": hasWebGL
		};
		analytics.identify(uid, obj, {}, cb || undefined);
	};
	
	return {
		"customIdentification": customIdentification,
		"flags": {
			"preventStandardIdentify": false,
			"creationInProgress": false
		}
	};
}]);
