import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {perf} from "ng2/common/perfLog";

import { PlanState, StateEvents } from "ng2/common/services/plan-state.service";
import * as firebaseUtils from "ng2/fancy-firebase/firebase-utils";
import * as utils from "utils";
import {renderQueue} from "ng2/common/RenderQueue";

import { Ticket } from 'ng2/common/models/Ticket';
import { Plan } from 'ng2/common/models/Plan';
import { CanvasConnector } from 'ng2/common/models/CanvasConnector';
import { You, observeYou } from 'ng2/common/models/You';
import {
	TicketList, TicketListFactory,
	RoleList, RoleListFactory,
	LocationList, LocationListFactory,
	ProjectMemberList, ProjectMemberListFactory,
	PriorityList, PriorityListFactory,
	VarianceList, VarianceListFactory,
	PullColumnList, PullColumnListFactory,
	TimelineTicketList, TimelineTicketListFactory,
	SwimlaneListFactory,
	TimelineTicketListPlaceholder, TimelineTicketListPlaceholderFactory, TimelineTicketFragmenter,
	LaborCountListFactory
} from 'ng2/fancy-firebase/factories';
import { Swimlanes } from "ng2/plan-things-rename/Swimlanes";

import { PlanObject } from "ng2/fancy-firebase/objects/PlanObject";
import { ProjectObject } from "ng2/fancy-firebase/objects/ProjectObject";
import { PullColumnObject } from "ng2/fancy-firebase/objects/PullColumns";
import { fakePmList } from "ng2/fancy-firebase/util/SharedProjectFakeData";

import { sessionStorage } from "ng2/common/services/SessionStorage";

import { FancyFragmenter, FancyFragmenterFactory, Fragment } from 'ng2/fancy-firebase/base';
import { Observable, Subject, Subscription, empty, combineLatest, of, from } from "rxjs";
import { map, mapTo, pluck, distinctUntilChanged, catchError, debounce, debounceTime, take, mergeMap, switchMap, takeUntil, bufferTime, filter, tap, withLatestFrom, distinct } from "rxjs/operators";
import { countLive, drop} from "ng2/common/rxjs-internal-extensions";

import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireAuth } from '@angular/fire/auth';

import { FancyFirebaseList, FancyFirebaseObject, FancyAction, FancyActionTypes, FancyIdJoin } from "ng2/fancy-firebase/base";
import { FancyActionTrackerFactory } from "ng2/common/services/fancy-action-tracker-factory.service";

import { FancySupplementalAction, SupplementalActionTypes } from "ng2/actions/supplemental-actions";

import { CalendarWorkdays } from "ng2/common/models/CalendarWorkdays";

import { TicketActionTypes } from "ng2/actions/ticket-actions";

import { Dependencies } from "ng2/common/models/Dependencies";

import * as strings from "ng2/common/strings";
import { LaborCounts } from "../../plan-things-rename/LaborCounts";
import { FbdbSettingsObject } from "../../fancy-firebase/objects/FbdbSettingsObject";



@Component({
	selector: 'plan',
	template: require("./plan.component.html")
})
export class PlanComponent implements OnInit, OnDestroy{
	private projectId:string;
	private planId:string;
	private shutdownSubject = new Subject();
	
	private stuffToDestroy = [];
	private unsubscribeList:Array<Subscription> = [];
	
	public sessionStorage = sessionStorage;

	constructor(
		private route: ActivatedRoute,
		private titleService: Title,
		private planState: PlanState,
		private fancyFragmenterFactory: FancyFragmenterFactory,
		private ticketListFactory:TicketListFactory,
		private roleListFactory:RoleListFactory,
		private locationListFactory:LocationListFactory,
		private projectMemberListFactory:ProjectMemberListFactory,
		private priorityListFactory:PriorityListFactory,
		private varianceListFactory:VarianceListFactory,
		private angularFireDatabase: AngularFireDatabase,
		private angularFireAuth: AngularFireAuth,
		private fancyActionTrackerFactory: FancyActionTrackerFactory,
		private timelineTicketListFactory: TimelineTicketListFactory,
		private timelineTicketListPlaceholderFactory: TimelineTicketListPlaceholderFactory,
		private pullColumnListFactory: PullColumnListFactory,
		private swimlaneListFactory: SwimlaneListFactory,
		private laborCountListFactory: LaborCountListFactory
	){
		perf.log('plan.component initialized');
	}
	
	//not entirely sure _when_ I want to init this, so isolate the real code...
	performSetup(){
		
		// Logging.notice(TEST_STRING);
		// Logging.notice(TEST_ARGS("blob"));
		var angularLand = {
			angularFireDatabase: this.angularFireDatabase,
			fancyActionTrackerFactory: this.fancyActionTrackerFactory
		}
		var renderer$ = renderQueue.ob$.pipe(takeUntil(this.shutdownSubject));
		
		console.log('before');
		this.route.data.subscribe(data => console.log('data', data));
		this.projectId = this.route.snapshot.paramMap.get('projectId');
		this.planId = this.route.snapshot.paramMap.get('planId');
		console.log('after', this.projectId, this.planId, (this.route.snapshot.paramMap as any).params);
		
		// this.angularFireDatabase.list("timelineTickets/"+this.projectId).stateChanges().pipe(
		// 	switchMap
		// )
		var canvasConnector = new CanvasConnector();
		
		
		var userListFragment = this.fancyFragmenterFactory.create("users");
		var userList = new FancyFirebaseList(angularLand, userListFragment.emitter$, FancyAction);
		userListFragment.start();
		var usersJoiner = new FancyIdJoin("userId", userList, userListFragment);
		
		// userList.rawEvent$.subscribe(evt => console.log('user', evt))
	
		var roleColorList = new FancyFirebaseList(angularLand, firebaseUtils.baseRef("projects").child(this.projectId).child("colors/roles"), FancyAction);
		var roleColorJoiner = new FancyIdJoin("roleColor", roleColorList);
		
		var locationColorList = new FancyFirebaseList(angularLand, firebaseUtils.baseRef("projects").child(this.projectId).child("colors/locations"), FancyAction);
		var locationColorJoiner = new FancyIdJoin("locationColor", locationColorList);
		
		var roleList = this.roleListFactory.create(firebaseUtils.baseRef("roles").child(this.projectId), roleColorJoiner);
		
		var rolesJoiner = new FancyIdJoin("roleId", roleList);
		// console.log('before');
		var pmRolesJoiner = new FancyIdJoin("roleId", roleList);
		
		//have yer damn locations
		var locationList = this.locationListFactory.create(firebaseUtils.baseRef("locations").child(this.projectId), locationColorJoiner);
		var locationJoiner = new FancyIdJoin("locationId", locationList);
		
		//and now the stupid projectMembers 
		if(this.projectId === "SampleProjectV3"){
			var projectMemberList = this.projectMemberListFactory.create(from(fakePmList));
		}
		else{
			var projectMemberList = this.projectMemberListFactory.create(firebaseUtils.baseRef("projectMembers").child(this.projectId), [pmRolesJoiner, usersJoiner]);
		}
		var assignedProjectMemberJoiner = new FancyIdJoin("assignedProjectMemberId", projectMemberList);
		var responsibleProjectMemberJoiner = new FancyIdJoin("responsibleProjectMemberId", projectMemberList);
		// projectMemberList.rawEvent$.subscribe(evt => console.log('pm', evt))
		
		
		var priorityList = this.priorityListFactory.create(firebaseUtils.baseRef("projects").child(this.projectId).child("priorities"));
		var priorityJoiner = new FancyIdJoin("priorityId", priorityList);
		
		var varianceList = this.varianceListFactory.create(firebaseUtils.baseRef("projects").child(this.projectId).child("variances"));
		
		var plan = new PlanObject(angularLand, firebaseUtils.baseRef("plans").child(this.projectId).child(this.planId));
		var project = new ProjectObject(angularLand, firebaseUtils.baseRef("projects").child(this.projectId));
		
		
		
		
		//it will be more optimal to subscribe to the two values directly, but this is more future compatible for splitting plan data
		var activeLineChanges = plan.object$.pipe(
			map(obj => {
				return {activeLineDate: obj.activeLineDate, activeLineX: obj.activeLineX}
			}),
			distinctUntilChanged((a,b)=>{
				return a.activeLineDate !== b.activeLineDate || a.activeLineX !== b.activeLineX;
			})
		)
		//CONTINUE HERE
		
		
		var pullColumns = new PullColumnObject(angularLand, firebaseUtils.baseRef("plans").child(this.projectId).child(this.planId).child('pullColumns'));
		pullColumns.addSupplementalObservable(canvasConnector.cameraChange$.pipe(
			map(viewport => new FancySupplementalAction(viewport, SupplementalActionTypes.cameraChanged))
		));
		
		
		
		
		
		
		
		
		
		
		
		
		
		// pullColumns.object$.subscribe(pull => {
		// 	console.log('pullColumns', pull);
		// })
		
		//used
		var pullColumnListMileStoneSupplement$ = plan.object$.pipe(
			map((plan) => {
				if(!plan || !plan.milestone){ return null; }
				return { x: plan.milestone.targetX, date: plan.milestone.targetDate }
			}),
			distinctUntilChanged((oldMill, newMill)=>{
				var returnValue = oldMill === newMill || (oldMill === null && newMill !== null) || (newMill === null && oldMill !== null) || oldMill.x !== newMill.x || oldMill.date !== newMill.date;
				return !returnValue;
			}),
			map(thing => new FancySupplementalAction(thing, SupplementalActionTypes.milestoneUpdated, this.planId))
		);
		// pullColumnListMileStoneSupplement$.subscribe(milestone => console.log('milestone', milestone));
		
		var pullColumnList = this.pullColumnListFactory.create(firebaseUtils.baseRef("plans").child(this.projectId).child(this.planId).child("pullColumns"));
		pullColumnList.addSupplementalObservable(pullColumnListMileStoneSupplement$);

		// pullColumnList.date$.subscribe((thing)=>{
		// 	pullColumnList.getXFromDate()
		// 	console.log('thing', thing);
		// });
		
		var projectWorkdays = this.angularFireDatabase.object(firebaseUtils.baseRef("projects").child(this.projectId).child('/workShiftsByDow'));
		var projectExceptionDays = new FancyFirebaseList(angularLand, firebaseUtils.baseRef("projectExceptionDays").child(this.projectId), FancyAction);
		var workDays = new CalendarWorkdays(roleList, projectWorkdays.valueChanges(), projectExceptionDays);
		
		
		// EXPERIMENTAL TIMELINE TICKET BS
		var rolesJoinerTT = new FancyIdJoin("roleId", roleList);
		var locationJoinerTT = new FancyIdJoin("locationId", locationList);
		var assignedProjectMemberJoinerTT = new FancyIdJoin("assignedProjectMemberId", projectMemberList);
		var responsibleProjectMemberJoinerTT = new FancyIdJoin("responsibleProjectMemberId", projectMemberList);
		var priorityJoinerTT = new FancyIdJoin("priorityId", priorityList);


		var timelineTicketFragment = new TimelineTicketFragmenter(angularLand, "tickets/"+this.projectId);
		// var tappedEmitter = timelineTicketFragment.emitter$.pipe(
		// 	tap(e => console.log('timeline fragment emitter event', e))
		// );
		
		var timelineTicketList = this.timelineTicketListFactory.create(timelineTicketFragment.emitter$, [
			rolesJoinerTT, locationJoinerTT, assignedProjectMemberJoinerTT, responsibleProjectMemberJoinerTT, priorityJoinerTT
		], [workDays, pullColumnList]);
		timelineTicketList.projectRef = firebaseUtils.baseRef("tickets/"+this.projectId);
		
		
		var pullColumnCacheUpdated = pullColumnList.date$.pipe(
			map(dates => new FancySupplementalAction(dates, SupplementalActionTypes.planUpdated, this.planId))
		)
		timelineTicketList.addSupplementalObservable(pullColumnCacheUpdated as any);
		
		timelineTicketFragment.start();
		
		var timelineTicketFragmentJoiner = new FancyIdJoin("$id", timelineTicketList, timelineTicketFragment);
		var timelineTicketPlaceholderList = this.timelineTicketListPlaceholderFactory.create(firebaseUtils.baseRef("timelineTickets/"+this.projectId), timelineTicketFragmentJoiner);
		
		// var youObservable =  observeYou(this.angularFireAuth.authState.pipe(takeUntil(this.shutdownSubject)), projectMemberList.rawEvent$);
		var youObservable =  observeYou(this.angularFireAuth.authState, projectMemberList.rawEvent$);
		
		window.settingStorage = sessionStorage;
		
		let setting$ = sessionStorage.changed$.pipe(
			takeUntil(this.shutdownSubject),
			map(settings => new FancyAction<any>(settings, SupplementalActionTypes.sessionSettingsChanged, "sessionSettings"))
		);

		window.perf.log('ticketList initialized');
		var ticketList = this.ticketListFactory.create(firebaseUtils.baseRef("tickets").child(this.projectId).child(this.planId), [
			rolesJoiner, locationJoiner, assignedProjectMemberJoiner, responsibleProjectMemberJoiner, priorityJoiner
		], [workDays, varianceList]);
		
		ticketList.addSupplementalObservable(varianceList.list$.pipe(
			<any>map(list => {
				return new FancySupplementalAction<Plan>(list as any, SupplementalActionTypes.varianceListUpdated, "varianceList");
			})
		))
		ticketList.addSupplementalObservable(setting$);
		
		
		//need to write a ticketCount property to /plan, subscribe to both tickets and plan, emit whenever the ticket count chnages and the plan value is not the same
		//set plan ticketCount property
		ticketList.list$.pipe(
			takeUntil(this.shutdownSubject),
			debounceTime(1000), //TODO - replace this
			withLatestFrom(plan.object$),
			mergeMap(arr => {
				if(arr[0].size === 0){ return empty(); }
				if(arr[1] === null){ return empty(); }
				if(arr[0].size !== arr[1].ticketCount){
					return of({next: arr[0].size, prev: arr[1].ticketCount});
				}
				return empty();
			}),
			filter(obj => obj.next !== obj.prev)
			// distinctUntilChanged()
		).subscribe((counts)=>{
			plan.updateTicketCount(counts.next);
			// console.log('ticketCount changed', counts.next);
			
			//special check for the first time it's set
			if(counts.next >= 1000 && (counts.prev === undefined || counts.prev === null)){
				sessionStorage.hideCompletedTickets = true;
				Logging.longWarning(strings.LARGE_PLAN_DETECTED_TEXT);
			}
		});
		
		plan.object$.pipe(
			filter(plan => !!plan),
			take(1)
		).subscribe(plan => {
			if(plan.ticketCount && plan.ticketCount >= 1000){
				sessionStorage.hideCompletedTickets = true;
				Logging.longWarning(strings.LARGE_PLAN_DETECTED_TEXT);
			}
		})
		
		sessionStorage.changed$.pipe(
			takeUntil(this.shutdownSubject),
			pluck('hideCompletedTickets'),
			distinctUntilChanged(),
			filter(thing => !!thing),
			switchMap(thing => {
				return ticketList.selectedTicket$.pipe(
					take(1),
					filter(tickets => !!tickets.list.size)
				)
			})
		).subscribe(thing => {
			this.planState.actions.unSelectTicket(thing.list)
		});
		
		
		
		let swimlaneList = this.swimlaneListFactory.create(firebaseUtils.baseRef("swimlanes").child(this.projectId).child(this.planId));
		let swimlanes = new Swimlanes(swimlaneList);
		
		swimlaneList.addSupplementalObservable(canvasConnector.cameraChange$.pipe(
			map(viewport => new FancySupplementalAction(viewport, SupplementalActionTypes.cameraChanged))
		));
		
		// MOC-3024 - Labor Counts
		let laborCountList = this.laborCountListFactory.create(firebaseUtils.baseRef("crewSizes").child(this.projectId).child(this.planId));
		let laborCounts = new LaborCounts(laborCountList);
		
		// MOC-3024 - The reason why I create a second list is to access the project wide labor count. I don't do it in the above list
		// because then we are watching updates for _all_ plans in a project, due to the way the data is structured.
		let projectLaborCountList = this.laborCountListFactory.create(firebaseUtils.baseRef("crewSizes").child(this.projectId).child("allPlans"));
		let projectLaborCounts = new LaborCounts(projectLaborCountList);
		
		// MOC-2875 - Create a list for fbdbSettings
		let fbdbSettingsObject = new FbdbSettingsObject(angularLand, firebaseUtils.baseRef("settings"));
		
		// MOC-2875 - We have an fbdbSettings list, but no easy way to access a specific value from the list in AngularJS-land.
		// So this little hack sets the value of settings/crewSizesEnabled to the sessionStorage.
		// When the plan-menu and plan-actions-menu is ported over to Angular, remove this and update the ng-show to use (planState.fbdbSettings.list.list$ | async)?
		
		// var planRef = this.angularFireDatabase.object<Plan>(firebaseUtils.baseRef("plans").child(this.projectId).child(this.planId));
		// var planObs = planRef.valueChanges();
		
		var delay$ = renderer$.pipe(drop(30));
		
		// this.unsubscribeList.push(ticketList.soakedRaw$.pipe(
			// mapTo("raw " + Date.now())
			// runInHiddenZone(),
			// countLive(),
			// debounceTime(1000),
			// debounce( ()=> delay$ )
			// tap((thing)=>{
			// 	console.log("is in zone", Zone.current.name);
			// })
		// ).subscribe(count => console.log('raw ticket update event count: ', count),
		// 	err => console.log('raw tickets err', err),
		// 	() => console.log('raw tickets complete')
		// ));

		
		// ticketList.soakedRaw$.pipe(
		// 	// mapTo("buffer " + Date.now())
		// 	// bufferTime(2000)
		// 	countLive(),
		// 	debounceTime(1000)
		// ).subscribe(count => console.log('debounced ticket update event count: ', count));
		// ticketList.list$.subscribe(test => console.log('ticket list', test));
		
		//not entirely convinced this is necessary, revert it for now...
		// var oldSyncedPlanData = combineLatest(plan.object$, this.planState.activeColumnService.recalced$).pipe(
		// 	mergeMap(list => {
		// 		if(list[0] && list[1] && (
		// 				list[0].activeLineX !== list[1].activeLineX
		// 				|| list[0].activeLineDate !== list[1].activeLineDate
		// 		)){
		// 			return empty(); //suppress if the lists are defined and the data is not the same
		// 		}
		// 
		// 		// if(list[0]){ console.log('list0', list[0].activeLineDate, list[0].activeLineX, 'list1', list[1]); }
		// 		return of(list[0]);
		// 	})
		// )
		
		var supTicketPlanEvents = plan.object$.pipe(map((data)=>{
			// console.log('data', data);
			return new FancySupplementalAction<Plan>(data, SupplementalActionTypes.planUpdated, this.planId);
		}));
		
		ticketList.addSupplementalObservable(supTicketPlanEvents as any);
		
		
		var dependencies = new Dependencies(ticketList, timelineTicketList, this.planId);
		
		dependencies.mode.pipe(
			takeUntil(this.shutdownSubject),
			pluck("isAllType"),
			distinctUntilChanged()
		).subscribe((allOn:boolean) => {
			sessionStorage.showAllDependencies = allOn;
		});
		
		
		//TODO - replace with projectState
		combineLatest(plan.object$, project.object$).pipe(takeUntil(this.shutdownSubject)).subscribe(objs => {
			if(!objs[0] || !objs[1] || !objs[1].projectName){ return; }
			let projectName = objs[1].projectName;
			let planName = objs[0].name;
			this.titleService.setTitle(planName + ' - ' + projectName + ' - Touchplan')
		})
		
		// ticketList.rawEvent$.pipe(bufferTime(50), filter(thing => !!thing.length)).subscribe(thing => console.log(thing))
		window.perf.merge(ticketList.list$.pipe(
			debounceTime(5300),
			map(thing => {return {name: "plan loaded 'tickets settled heuristic'", extraData: {timestampOffset: 3000, previousEventName: 'plan.component initialized'}}}),
			take(1)
		));

		
		// ticketList.selectedTicket$.subscribe((selecteds)=>{
		// 	console.log('selecteds', selecteds);
		// });
		
		this.planState.setup(
			this.route,
			canvasConnector,
			ticketList,
			timelineTicketList,
			roleList,
			locationList,
			projectMemberList,
			priorityList,
			plan,
			workDays,
			youObservable,
			dependencies,
			swimlanes,
			laborCounts,
			projectLaborCounts,
			fbdbSettingsObject,
			project
		)
		
		this.planState.stateChange$.pipe(takeUntil(this.shutdownSubject)).subscribe(state => {
			if(StateEvents.BOOMED === state){ this.destroyStuff(); }
		});
		
		
		this.stuffToDestroy.push(usersJoiner, roleColorJoiner, locationColorJoiner, 
			rolesJoiner, pmRolesJoiner, locationJoiner, assignedProjectMemberJoiner,
			responsibleProjectMemberJoiner, priorityJoiner, rolesJoinerTT,
			locationJoinerTT, assignedProjectMemberJoinerTT, 
			responsibleProjectMemberJoinerTT, priorityJoinerTT,
			timelineTicketFragmentJoiner,
			roleColorList, locationColorList, pullColumnList,
			timelineTicketFragment, timelineTicketPlaceholderList,
			projectExceptionDays, userList,
			varianceList);
		
		// ticketList.rawEvent$.subscribe((a)=>{
		// 	console.log('ticket update', a);
		// });
		
	}
	
	ngOnInit(){
		// console.log('ngOnInit');
		this.performSetup();
	}
	
	public pickerChanged(value): void {
		console.log("PICKER CHANGED", value);
	}
	
	ngAfterContentInit(){
		// console.log('ngAfterContentInit');
	}
	//if we delay setup until here, the scoupSoup will be defined
	// this means that the angular code is technically initialized and run before planState is considered ready
	// this may or may not ever be a problem
	ngAfterViewInit(){
		// console.log('ngAfterViewInit');
		// this.performSetup();
	}
	
	//don't want to override an internal destroy
	destroyStuff(){
		this.titleService.setTitle("Touchplan");
		console.log('actually clean up plan.component');
		this.shutdownSubject.next(true);
		this.unsubscribeList.forEach( sub => sub.unsubscribe() );
		this.stuffToDestroy.forEach( stuff => stuff.destroy() );
		this.stuffToDestroy = null;
	}

	//boom the planState when this is destroyed, should be able to use the subscription to planState's change event to actually clean up
	ngOnDestroy(){
		this.planState.boom();
	}
}
