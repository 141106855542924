import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[edit-type]'
})
export class EditType {
	constructor(public viewContainerRef: ViewContainerRef){
		console.log('EditType');
	}
}
