import { Component, HostBinding } from '@angular/core';
import { Router, ActivationEnd, Event } from '@angular/router';

import {RallySignupComponent, ReportComponent} from "ng2/routes";

import { filter } from "rxjs/operators";

import { AppState } from "ng2/common/services/app-state.service";

@Component({
	selector: 'ng2-app',
	template: '<router-outlet></router-outlet>'
})
export class AppComponent {
	@HostBinding('class.empty') isEmpty = true;
	//temp workaround
	@HostBinding('class.state-rally-signup') isRallySignup = false;
	@HostBinding('class.temp-reports-ie-fix') isReportsPage = false;
	
	routeClass: string = "weee";
	
	constructor(private router: Router, private appState: AppState){
		router.initialNavigation();
		console.log('ng2-app loaded'); 
		
		this.router.events
		// .pipe( filter(evt => evt instanceof ActivationEnd) ) //these are the same
		.pipe( filter( (evt: Event): evt is ActivationEnd => evt instanceof ActivationEnd) )
		.subscribe(event => {
			this.isRallySignup = event.snapshot.firstChild && event.snapshot.firstChild.component === RallySignupComponent;
			this.isReportsPage = event.snapshot.component === ReportComponent;
			
			if(event.snapshot.data.placeholder){
				this.isEmpty = true;
			}
			else{
				this.isEmpty = false;
			}
		})
		
		appState.setup();
		
	}
}
