import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'completed-selection-prompt',
  template: require('./completed-selection-prompt.component.html'),
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompletedSelectionPromptComponent implements OnInit {
	
	@Output() unselectCompleted = new EventEmitter();
	
  constructor() { }
  ngOnInit() {
  }
	
	unselect(){
		this.unselectCompleted.emit();
	}

}
