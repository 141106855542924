
export class Priority  { 
	public color: string;
	public sortOrder: number;
	public text: string;
	
	constructor(obj){
		this.update(obj);
	}
	
	update(obj){
		this.color = obj.color;
		this.sortOrder = obj.sortOrder;
		this.text = obj.text;
	}
}
