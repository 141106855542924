import { WebGLRenderer, CanvasRenderer } from 'pixi.js';

class RendererInfo {
	public renderer: WebGLRenderer|CanvasRenderer;
	
	constructor(){
		(<any>window).rendererInfo = this;
	}
	
	public isWebGL(): boolean {
		return (this.renderer instanceof WebGLRenderer)
	}
}

export const rendererInfo: RendererInfo = new RendererInfo();