import { SplitData, generateSimplestList } from "ng2/fancy-firebase/generics";

export enum LaborCountRenderType{
	database = "database"
}

export interface LaborCountFields{
	color?: string,
	roleId: string,
	plannedAndActual: number,
	date: string
}

export class LaborCount extends SplitData<LaborCountFields, LaborCountRenderType>{
	public database = {};
	protected attributeList = Object.assign(generateSimplestList([
		'roleId', 'plannedAndActual', 'date', 'color'
	]),{});
	constructor(id: string){
		super(id, LaborCountRenderType.database);
	}
	
	public databaseUpdate(data:Object){
		let massagedData: LaborCountFields[] = [];
		for (let roleKey in data){
			const plannedAndActual = data[roleKey].plannedAndActual !== undefined ? data[roleKey].plannedAndActual : data[roleKey].plannedAndLastPlanned;
			massagedData[roleKey] = {
				roleId: roleKey,
				//color: "",
				plannedAndActual,
				date: this.$id
			};
		}
		return super.databaseUpdate(massagedData);
	}
	
	public localUpdate(data:Object, renderType?: LaborCountRenderType){
		return super.localUpdate(data, renderType);
	}
}
