'use strict';


import AnimationQueue from "js/lib/ui/AnimationQueue";
import Point from "js/lib/math/Point";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

/************************************************
*	Contains all the code related to interacting
*	with the plan.
*************************************************/
export default function planHandling(scope){
	this.scope = scope;
	/**
	 * Call when the plan recieves a doubletap
	 * Begins or ends a GroupBox instance
	 *
	 */
	this.tap = function(event, currentElement){
		var state = scope.legacyRouting.oldStyleSnapshot();
		
		if(!scope.floatingTickets.selection.isEmpty()){
			scope.floatingTickets.selection.empty();
		}
		
		// scope.ticketEditService.close();
		// scope.$applyAsync();
	}
	 
	this.doubleTap = function(event, currentElement){
	}
	/**
	 * Call when dragstart on a plan is detected.
	 * Code here will initialize the conditions for a one finger
	 * drag on the plan to pan around.
	 */
	this.dragStart = function(event, currentElement){
		if (scope.flags.stickyInEditMode){ return false; }
		this.scope.hud.onStartTranslate();
		this.scope.ticketOps.timesAddClicked = 0;
	}

	/**
	 * Call when drag on a plan is detected.
	 * Code here will process the repeated events to pan
	 * the plan in response to a one finger drag.
	 */
	this.drag = function(event, currentElement){
		this.scope.hud.onTranslate(new Point(event.deltaX, event.deltaY));
	}

	/**
	 * Call when dragend on a plan is detected.
	 * Code here will finalize the conditions for a one finger
	 * drag on the plan to pan around.
	 */
	this.dragEnd = function(event, currentElement){
		this.scope.hud.onEndTranslate();
	}

	
	//for the first cut we're going to ignore an newly added tickets during the scroll
	var ticketsToScroll = [];
	var isPull;
	var leftEdge;
	var rightEdge;
	var lastDx;
	
	function scrToData(val){ return val / scope.hud.z - scope.hud.x / scope.hud.z; }
	function initScroll(){
		ticketsToScroll = [];
		leftEdge = Infinity;
		rightEdge = -Infinity;
		lastDx = 0;
	}
	
	function capEdge(dx){
		if(	scope.activeLine.$value === null){return dx;}
		if(isPull && Math.round(leftEdge + dx) <= scope.activeLine.$value + 16/scope.hud.z){
			dx = scope.activeLine.$value + 16/scope.hud.z - leftEdge;
		}
		if(!isPull && Math.round(rightEdge + dx) >= scope.activeLine.$value){
			dx = scope.activeLine.$value - rightEdge;
		}
		return dx;
	}
	
	this.scroll = function(event, currentElement){
		//console.log('scroll', event);
		this.scope.hud.onTranslate(new Point(0, event.deltaY));
		
		var dx = Math.floor(event.deltaX / scope.hud.z);
		dx = capEdge(dx);
		
		//fake column scrolling
		scope.shiftApi.shift(dx - lastDx, scope.activeLine.$value, isPull ? "pull" : "active");
		lastDx = dx;
		
		AnimationQueue(function(){
			ticketsToScroll.forEach(function(t){
				t.local.element.css({left: t.local.startLeft + dx});
			});
		}, "scrollTickets");
	}

	this.scrollEnd = function(event, currentElement){
		//console.log('end', event);
		this.scope.hud.onEndTranslate();
		
		scope.shiftApi.emit("redrawShift");
		
		var dx = Math.floor(capEdge(event.deltaX / scope.hud.z));
		console.log('plan dx', dx);
		
		var updateObj = {};
		var scrollOffset = scope.activeColumnService.scrollOffset();
		if(!isPull){
			//grow/shrink api
			var lastUpdate = scope.growActiveSpace.$value || 0;
			scope.growActiveSpace.$value = lastUpdate - dx + scrollOffset;
			scope.growActiveSpace.$save();
		}
		else{
			var lastUpdate = scope.growPullSpace.$value || 0;
			scope.growPullSpace.$value = dx + lastUpdate;
			scope.growPullSpace.$save();
		}
		
		AnimationQueue(function(){
			ticketsToScroll.forEach(function(t){
				t.local.element.css({left: t.local.startLeft + dx - scrollOffset});
			});
		}, "scrollTickets");
		
		this.scope.tickets.massSave(ticketsToScroll.map(function(t){
			return {
				"id": t.$id,
				"left": Math.round(t.local.startLeft + dx - scrollOffset)
			};
		}), updateObj, {applyLocalImmediately: true, noSet: true});
		//console.log('left, post-massSave', ticketsToScroll[0].data.left);
		
		scope.shiftApi.emit("shiftEnd", dx - lastDx, scope.activeLine.$value, isPull ? "pull" : "active");

		setTimeout(function(){
			$('.stickyNote').addClass('animateChanges');
		},100);
	}

	/**
	 * Called when transformstart on a plan is detected.
	 * Xxx...
	 */
	this.transformStart = function(event, currentElement){
		if (scope.flags.stickyInEditMode){ return false; }
		this.startCenter = actualCenter(event);
		
		this.scope.hud.onStartTransform();
		this.scope.ticketOps.timesAddClicked = 0;
	}

	
	function actualCenter(event){
		if(event.pointers.length === 2){
			return {
				x: (event.pointers[1].clientX + event.pointers[0].clientX)/2,
				y: (event.pointers[1].clientY + event.pointers[0].clientY)/2,
			}
		}
		return false;
	}
	this.actualDelta = function actualDelta(event){
		var center:any = actualCenter(event);
		return {
			deltaX: center.x - this.startCenter.x,
			deltaY: center.y - this.startCenter.y
		}
	}
	/**
	 * Called when transform on a plan is detected.
	 * Xxx...
	 */
	 
	//need to recalculate the center and deltas, since the hammer ones are based on the
	//start event not the start transform event
	this.transform = function(event, currentElement){
		var center = actualCenter(event);
		if(!center){ console.log('not 2 pointers'); return;}
		var delta = this.actualDelta(event);
		
		var p = new Point(delta.deltaX, delta.deltaY);
		var z = new Point(event.scale);
		var c = new Point(event.center.x, event.center.y);
		//console.log('x: '+delta.deltaX+' y: '+delta.deltaY+' sc: '+event.scale+' cx: '+center.x+' cy: '+center.y);
		this.scope.hud.onTransform(p, z, c)
	}
	/**
	 * Called when transformend on a plan is detected.
	 * Xxx...
	 */
	this.transformEnd = function(event, currentElement){
		this.scope.hud.onEndTransform();
	}

	/**
	 * Called when the mouse wheel is fired.
	 */
	this.mousewheelOnApplication = function(event) {
		var ev = event.originalEvent || event,
			z = ((ev.wheelDelta > 0 || ev.detail < 0) ? 1.05 : 0.95),
			c = new Point(ev.clientX, ev.clientY);
		this.scope.hud.onStartScale()
		this.scope.hud.onScale(z, c)
		this.scope.hud.onEndScale()
	}
}
