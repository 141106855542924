import {Observable} from "rxjs";

import { SplitData, generateSimplestList } from "ng2/fancy-firebase/generics";
import { Camera } from "ng2/common/models/CanvasConnector";

export enum SwimlaneRenderTypes{
	database = "database",
	edit = "edit"
}

export interface SwimlaneFields{
	top?: number,
	bottom?: number,
	screenTop?: number,
	screenBottom?: number,
	color?: string,
	label?: string,
	[key: string]: any
}

export class Swimlane extends SplitData<SwimlaneFields, SwimlaneRenderTypes>{
	public camera: Camera;
	
	public database: SwimlaneFields = {};
	public edit: SwimlaneFields = {};
	// public self$: Observable<Swimlane>; //lets try to not do this
	protected attributeList = Object.assign(generateSimplestList([
		'top', 'bottom', 'color', 'label'
	]),{
		'screenTop': {noWrite: true},
		'screenBottom': {noWrite: true}
	});
	
	public recalcLocal(renderType?: SwimlaneRenderTypes){
		if(!renderType){ renderType = this.currentRenderType; }
		let view = this[renderType];
		if(this.camera){
			view.screenTop = this.camera.realToScreenY(view.top);
			view.screenBottom = this.camera.realToScreenY(view.bottom);
		}
	}
	
	public databaseUpdate(data:Object){
		let res = super.databaseUpdate(data);
		this.recalcLocal(SwimlaneRenderTypes.database);
		return res;
	}
	
	public localUpdate(data:Object, renderType?: SwimlaneRenderTypes){
		let res = super.localUpdate(data, renderType);
		this.recalcLocal(renderType);
		return res;
	}
	
	constructor(id: string){
		super(id, SwimlaneRenderTypes.database);
		
	}
}
