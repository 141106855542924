'use strict';

import * as angular from "angular";
import * as firebase from "firebase/app";
declare const window: any;

import {LegacyRouting} from "ng2/routes/legacy-routing.service";
import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

angular.module('ticketspaceApp')
.factory('presence', ["$timeout", "fbConnection", "authService", "projectNotifier", "firebaseUsersPresence", "fbReporting", "legacyRouting", function($timeout, fbConnection, authService, projectNotifier: ProjectNotifier, firebaseUsersPresence, fbReporting, legacyRouting: LegacyRouting) {
	
	var info:any = {};

	var suppressionTime = false;
	var connectedRef = firebase.database().ref("/.info/connected");
	var presenceRef; //filled in when userId is known
	
	//bit of hackery that waits for a bit before setting up the reconnect dialog
	$timeout(function(){
		suppressionTime = true;
	}, 8000);


	
	var noticeThing = (function(){
		var conState;
		var lastTimeout;
		var lastState = true;
		
		return function queueChange(val){
			conState = val;
			clearTimeout(lastTimeout);
			
			lastTimeout = setTimeout(function(){
				if(lastState !== conState){
					if(conState){ Logging.notice('re-connected'); }
					else{ Logging.warning('lost connection'); }
				}
				lastState = conState;
			}, 5000);
		}
	})();
	
	
	connectedRef.on("value", function(snap) {
		info.connected = snap.val();
		if(suppressionTime){ noticeThing(!!snap.val()) }
		if (snap.val() === true) {
			connected();
		}
	});
	
	authService.auth.$onAuthStateChanged(function(authData){
		if(authData){
			info.userId = authData.uid;
			connected();
			setupWatch();
		}
		else{
			info.userId = null;
			if(info.presData){info.presData.$destroy(); info.presData = null;}
		}
	});
	
	var setOnlineCount = 0;
	console.log('--commenting out to debug $digest');
	// setInterval(function(){
	// 	if(setOnlineCount >= 100){
	// 		fbReporting.reportError("user set to online more than 100 times in 10 seconds", {userId: info.userId});
	// 		//console.log('100');
	// 	}
	// 	else if(setOnlineCount >= 10){
	// 		fbReporting.reportError("user set to online more than 10 times in 10 seconds", {userId: info.userId});
	// 		//console.log('10');
	// 	}
	// 	setOnlineCount = 0;
	// }, 10000);
	
	
	function setupWatch(){
		var presData = fbConnection.fbObject(getRef(info.userId));
		info.presData = presData;
		presData.$watch(function(){
			//console.log('update', presData);
			if(presData.status === "offline" && info.connected){
				presData.$ref().child('status').set("online");
				setOnlineCount++;
			}
		});
	}
	
	function manualDisconnect(){
		if(info.userId){ getRef(info.userId).update({status: 'offline'}); }
	}
	
	function getRef(id){
		return fbConnection.fbRef('presence/'+id);
	}
	
	function connected(){
		//ensure both authed and connected
		if(info.userId){
			presenceRef = getRef(info.userId);
			
			//set up onDisconnect stuff
			presenceRef.onDisconnect().update({
				id: info.userId,
				status: 'offline',
				lastOnline: firebase.database.ServerValue.TIMESTAMP,
				lastUpdate: firebase.database.ServerValue.TIMESTAMP,
				meta: null,
				state: null
			});
			authService.subscribeToLogout(function(){manualDisconnect();});
			
			presenceRef.update({
				id: info.userId,
				status: 'online',
				lastOnline: firebase.database.ServerValue.TIMESTAMP,
				lastUpdate: firebase.database.ServerValue.TIMESTAMP
			});
		}
	}
	
	//manage the promise state, update presence when state changes
	projectNotifier.projectState.subscribe((state) => {
		var stateName = legacyRouting.routeName();
		if(info.userId && presenceRef){
			if(stateName){
				presenceRef.update({
					id: info.userId,
					state: stateName,
					meta: state,
					lastUpdate: firebase.database.ServerValue.TIMESTAMP
				});
				
				if(state.projectId){
					pokeProjectPresence(state.projectId);
					// fbConnection.fbRef('projectPresence/'+state.projectId).update({
					// 	id: state.projectId,
					// 	lastUpdate: firebase.database.ServerValue.TIMESTAMP
					// });
				}
				
			}
		}
	});
	
	function pokeProjectPresence(projectId){
		window.analyticsService.pokeProjectPresence(projectId);
		fbConnection.fbRef('projectPresence/'+projectId).update({
			id: projectId,
			lastUpdate: firebase.database.ServerValue.TIMESTAMP
		});
	}
	
	return {
		getPresenceList: firebaseUsersPresence.getPresenceList,
		manualDisconnect: manualDisconnect,
		pokeProjectPresence: pokeProjectPresence

	};
}])
.run(["presence", function(presence) {window.pres = presence}]); //this effectively just ensures that this service is init'd really early in the lifecycle
