import { Component } from '@angular/core';

import { TicketEditService } from 'ng2/ticket-edit/ticket-edit.service';
import { AccessCheck } from "ng2/common/ajs-upgrade-providers";
import { PlanState } from 'ng2/common/services/plan-state.service';
import { FormBuilder } from '@angular/forms';


import { TicketEditComponent } from 'ng2/ticket-edit/components/ticket-edit.component';

@Component({
	selector: 'milestone-edit',
	template: require('./milestone-edit.component.html'),
})
export class MilestoneEditComponent extends TicketEditComponent {
	
	constructor( protected ticketEditService: TicketEditService,
				 protected accessCheck: AccessCheck,
				 protected formBuilder: FormBuilder,
				 protected planState: PlanState) {
		super(ticketEditService, accessCheck, formBuilder, planState);
		console.log('MilestoneEditComponent');
	}
}
