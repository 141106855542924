import { DisplayObject } from "pixi.js";
import { FancyContainer } from "../graphics/FancyContainer";
/**
 Purpose of this JIRA ticket is to mainly document how layers and drawing order are handled in canvas land for posterity.
 
 *Canvas-land PIXI.display analogy:*
 - *Stage* - The root node of the scene graph. This can be thought of as a city within a world. You can break down a 'city' into multiple suburbs if you want:
 So you can have a "Downtown" stage, a "suburb" stage and so forth, each with their own buildings and groups.
 - *Layers* - PIXI.display.Layer extends PIXI.Container. They are added to the *Stage*. This can be thought of as a building within a city.
 - *Groups* - PIXI.display.Group can be thought of as a business within a city. Only one business per building though. So a 'TouchPlan' group would be associated
 with a 'TouchPlan' building.
 - *Sprite/Ticket* - A Sprite/Ticket can be thought of as a person. They are added to a Group. For example, someone who works at TouchPlan will go to work in the "TouchPlan" building
 and his rank within that group can be sorted by zIndex/zOrder.
 
 The advantage of this system is that the "people" living within each Group can be moved around without having to completely re-arrange the PIXI scene graph
 in order to get proper drawing order. Maintaining the analogy: once a "person" is done work, he will then move into say a "atHome" Group and will order
 himself as if he was within the "atHome" layer, but in the scene graph, none of the data has changed and his position in the graph would not have changed.
 
 *Overview*
 - LayerManager class
 -- private _layers: Array<Layer>. Tracks all layers this LayerManager is tracking.
 -- private _groups: Array<Group>. Tracks all groups this LayerManager is tracking.
 -- public addLayer(name: string, group?: Group, parentContainer?: Container). Adds a new Layer to the LayerManager where:
 --- group = The PIXI.display.Group this layer is bound to.
 --- parentContainer = The Container in the scene graph where the layer is added as a child to. For tickets, this will usually be the cameraContainer.
 -- public getLayer(name: string): Layer. Returns the Layer matching the provided name.
 -- public getGroup(name: string): Group. Returns the group matching the provided name.
 
 - Layer class extends PIXI.display.Layer. A wrapper around PIXI.display.Layer which adds some simple tracking properties so we can easily
 track the layers we have and retrieve them.
 -- private _layer: Container property. An instance of PIXI.display.Layer. The layer we added
 -- public name: string property. A unique identifier to track the name of this layer. Used to return it later.
 -- public get: PIXI.display.Layer method. Returns this.layer. Typically invoked by the LayerManager
 
 - Group class extends PIXI.display.Group. A wrapper around PIXI.display.Group which lets us retrieve groups based on a unique identifier.
 -- private _group: PIXI.display.Group property. An instance of PIXI.display.Group.
 -- public name: string property. Unique identifier to identify this Group.
 -- public get(): PIXI.display.Group property. Returns this._group, typically invoked by the LayerManager
 
 *Current implementation (Canvas)*
 The current implementation I'm using in the canvas is essentially this:
 - Each ticket in the canvas creates a new Layer, with the name of the layer being the ID of the ticket.
 - _Things_ that are drawn on a ticket are added to the new layer the ticket created, with their zIndex controlling the draw order of the _thing_
 - This encapsulates the drawing of anything on a ticket to within a specific layer associated with that ticket and gurantees that if a ticket overlaps another,
 the new ticket _potentially_ covers up everything on the prior ticket.
 - Theoretically, if a ticket is dragged, all we need to do is to set the Group of the dragged ticket(s) to be a layer with a higher zIndex from the other layers,
 and then that ticket and all of the _things_ attached to it will also draw as if they were on the higher zIndex layer.
 */
/**
 * These are the ticket drawing layers.
 *
 * 1. BELOW_TICKET will be drawn underneath the ON_TICKET and ABOVE_TICKET layers
 * 2. ON_TICKET will be drawn above the BELOW_TICKET layer, but beneath the ABOVE_TICKET layer
 * 3. ABOVE_TICKET will be drawn above the BELOW_TICKET and ON_TICKET
 */
export enum TICKET_LAYERS{
	BELOW = "BELOW",
	SURFACE = "SURFACE",
	ABOVE = "ABOVE"
}
/**
 * These are all of the layers that exist in the canvas world.
 * By default, these are added to the LayerManager as a child to the cameraContainer display node
 */
export enum LAYER_NAMES{
	BACKGROUND = "BACKGROUND",
	TICKETS = "TICKETS",
	TICKET_DRAG = "TICKET_DRAG",
	WORLD_ABOVE = "WORLD_ABOVE",
	UI = "UI"
}
export class LayerManager{
	public layers: Map<string|number, FancyContainer> = new Map();
	constructor(){}
	/**
	 * Adds a new layer to the layer manager.
	 * @param {string} name The unique identifier for this layer
	 * @param {FancyContainer} parent The parent FancyContainer where this layer will be added as a child.
	 */
	public addLayer(name: string, parent?: FancyContainer): FancyContainer {
		if (this.layers.get(name)){throw new Error("Layer already exists: " + name);}
		let layer = new FancyContainer();
		this.layers.set(name, layer);
		if (parent){(parent as any).addChild(layer);}
		return layer;
	}
	public removeLayer(name): LayerManager {
		if (this.layers.get(name)) {
			this.layers.delete(name);
		}
		return this;
	}
	/**
	 * Returns the Layer matching name
	 * @param {string} name
	 */
	public getLayer(name: string|number): FancyContainer|null {
		let layer = this.layers.get(name);
		return (layer) ? layer : null;
	}
	public reset(){
		this.layers.clear();
	}
	
	public destroy(options?): void {
		this.layers.forEach((layer: FancyContainer) => {
			layer.destroy(options);
		});
		
		this.layers.clear();
		
		this.layers = null;
	}
}
