'use strict';

export default function Row(data){
	if (!data || !data.startY){throw "Invalid data parameter in Row constructor."}
	this.list = data.list || [];
	this.startY = data.startY;
	this.finishY = data.finishY;
	this.height = this.getHeight();
	this.forceX = data.forceX || false;
};

Row.prototype.add = function(thing){
	this.list.push(thing);
};

Row.prototype.getById = function(id){
	var l = this.list.length;
	for (var i = 0; i < l; i++){
		if (this.list[i].$id === id){return this.list[i]}
	}
	// No match.
	return false;
};

Row.prototype.getHeight = function(){
	return this.finishY - this.startY;
};

Row.prototype.inBounds = function(thingPos){
	var center = thingPos.top + thingPos.height / 2;
	return (center >= this.startY && center <= this.finishY)
};

Row.prototype.align = function(id){
	if (this.forceX){ this.startY = this.forceX;}
	var thing = this.getById(id);
	if (thing){
		thing.data.top = this.startY;
	} else {
		this.alignAll();
	}
};

Row.prototype.alignAll = function(){
	if (this.forceX){ this.startY = this.forceX;}
	var self = this;
	this.list.forEach(function(thing){
		thing.data.top = self.startY;
	})
};

Row.prototype.save = function(){
	var fbChangedTickets = [];
	this.list.forEach(function(ticket){
		fbChangedTickets.push({
			"id": ticket.$id,
			"top": ticket.data.top,
			"left": ticket.data.left
		});
	});
	return fbChangedTickets;
};

Row.prototype.calculateMode = function(numberList){
	var count = -100000;
	var countMap = {};
	var result;
	for (var i = 0; i < numberList.length; i++){
		countMap[numberList[i]] = (countMap[numberList[i]] || 0) + 1;
		if (countMap[numberList[i]] > count){
			count = countMap[numberList[i]];
			result = numberList[i];
		}
	}
	return result;
};

Row.prototype.verify = function(activeLineX){
	var self = this;
	var cnfCount = 0;
	this.list.forEach(function(ticket){
		for (var i = 0; i < self.list.length; i++){
			if (ticket.$id === self.list[i].$id) { continue; }
			if (ticket.data.left === self.list[i].data.left && ticket.data.top === self.list[i].data.top){
				cnfCount++;
				if (activeLineX && ticket.data.left < activeLineX){
					console.log("Active Side");
					ticket.data.top += (50 * cnfCount);
				} else {
					console.log("Pull Side");
					ticket.data.left += (50 * cnfCount);
				}
			}
		}
	})
};
