import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Inject, Optional } from '@angular/core';

@Component({
	selector: 'dropdown',
	template: require('../tpl/dropdown.html')
})

export class DropdownComponent{
	// constructor(@Inject('$scope') @Optional() private $scope){}
	
	
	@Input() list: any = [];
	@Input() model: any = [];
	@Input() label: string = null;
	@Input() name: string = null;
	@Input() scrollHeight: string = "200px";
	@Input() style: string = null;
	@Input() panelStyle: string = null;
	@Input() styleClass: string = "tp-dropdown";
	@Input() panelStyleClass: string = null;
	@Input() filter: boolean = false;
	@Input() filterBy: string = null;
	@Input() filterPlaceholder: string = null;
	@Input() autoWidth: boolean = true;
	@Input() required: boolean = false;
	@Input() disabled: boolean = false;
	@Input() editable: boolean = false;
	@Input() placeholder: string = null;
	@Input() dataKey: string = null;
	@Input() autofocus: boolean = false;
	@Input() resetFilterOnHide: boolean = false;
	@Input() dropdownIcon: string = "fa fa-fw fa-caret-down";
	@Output() modelChange: EventEmitter<any> = new EventEmitter();
	onChange(thing){
		// console.log('on change', this, this.$scope);
		// this.$scope.$parent.$apply();
		this.modelChange.emit(thing);
	}
}
