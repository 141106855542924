import { Container } from "pixi.js";
import * as utils from "utils";

/**
 * Detach api:
 * To add detach functionality to class "Thing"
 * 1) add decorator:
 * ```
 * @detachable()
 * class Thing{}
 * ```
 * 2) merge the class declaration with Detach
 * ```
 * interface Thing extends Detach {} 
 * ```
 * 3) Execute the detach constructor
 * ```
 * constructor(){
 * 	super();
 * 	constructDetach(this);
 * }
 * ```
 */

//TODO - get isList typing generic code from develop and use here

/* construct the Detach class methods that have been dumped onto the decorated class */
export function constructDetach<T = any>(instance:Detach){
	instance._detachedChildren = new Set<T>();
	instance.isDetached = false;
}

/** typeguard to see if a thing implements the detach interface */
export function implementsDetach(thing: any): thing is Detach{
	return thing && thing.detach !== undefined;
}

export interface DetachInterface{
	_detachedChildren:Set<any>;
	isDetached: boolean;
	detach: ()=>void;
	detachChildren: (item:any)=>void;
	detachAllChildren: ()=>void;
	attach: ()=>void;
	attachChildren: (item:any)=>void;
}

export class Detach extends Container{
	public _detachedChildren:Set<any>;
	public isDetached = false;
	
	/** Detach yourself from the parent */
	public detach(){
		if(this.parent && (this.parent as Detach).detachChildren){
			(this.parent as Detach).detachChildren(this);
		}
		else{ console.log('parent cannot detach', this.parent); }
	}
	/** detach one or many children from yourself */
	public detachChildren(itemOrArr){
		if(itemOrArr.forEach){
			for( var i = itemOrArr.length-1; i >= 0; i--){
				var idx = this.children.indexOf(itemOrArr[i]);
				if(idx !== -1){
					itemOrArr[i].isDetached = true;
					this._detachedChildren.add(itemOrArr[i]);
					utils.fastSplice(this.children, idx, 1);
				}
			}
		}
		else{
			var idx = this.children.indexOf(itemOrArr);
			if(idx !== -1){
				itemOrArr.isDetached = true;
				this._detachedChildren.add(itemOrArr);
				utils.fastSplice(this.children, idx, 1);
			}
		}
		//some sort of "onChildrenChange" method
	}
	/** detach all children from yourself (this is much more efficient when it can be used) */
	public detachAllChildren(){
		for(var i = 0; i < this.children.length; i++){ (this.children[i] as Detach).isDetached = true; this._detachedChildren.add(this.children[i]); }
		this.children.length = 0;
	}
	/** attach yourself back to your last known parent */
	public attach(){
		if(this.parent && (this.parent as Detach).attachChildren){
			(this.parent as Detach).attachChildren(this);
		}
		else{ console.log('parent cannot detach', this.parent); }
	}
	/** attach children back to yourself, children not previously detached will be skipped */
	public attachChildren(itemOrArr){
		if(itemOrArr.forEach){
			for(var i = 0; i < this.children.length; i++){ 
				let item: Detach = itemOrArr[i];
				if(this._detachedChildren.delete(item)){
					item.isDetached = false;
					this.children.push(itemOrArr[i]);
				}
			}
		}
		else{
			if(this._detachedChildren.delete(itemOrArr)){
				itemOrArr.isDetached = false;
				this.children.push(itemOrArr);
			}
		}
	}
}

/** decorator */
export function detachable(){
	return function(constructor: Function){
		Object.getOwnPropertyNames(Detach.prototype).forEach(name => {
			constructor.prototype[name] = Detach.prototype[name];
		});
		
	}
}
