import { Inject, Component, ViewChild, ChangeDetectionStrategy, OnDestroy, AfterViewInit, NgZone } from '@angular/core';
import { RendererApplication } from "../../canvas-renderer/core/RendererApplication";
import { PlanState } from "../../common/services/plan-state.service";
import { StateEvents } from "../../common/services/plan-state.service";
import { LocalStorageHelper } from "../../canvas-renderer/utils/LocalStorageHelper";
import { CanvasConnector } from "ng2/common/models/CanvasConnector";

import {AuthService} from "ng2/common/ajs-upgrade-providers";

@Component({
	selector: 'canvas-view',
	template: require('../tpl/view.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 */
export class CanvasViewComponent implements AfterViewInit, OnDestroy{
	private _defaultSettings;
	/**
	 * @member application
	 * @description Instance of the RendererApplication entry point
	 */
	public application: RendererApplication;
	/**
	 * Get the canvas element. We need this to instantiate the renderer.
	 */
	@ViewChild('canvasView') canvasView;
	/**
	 * @param {PlanState} planState
	 * @param authService
	 */
	constructor(private planState: PlanState, private authService:AuthService, private angularZone: NgZone){}
	
	/**
	 */
	ngAfterViewInit(){
		// window.PIXI.settings.SCALE_MODE = window.PIXI.SCALE_MODES.NEAREST;
		let prefs = this.authService.userPreferences;
		// See the WebGL spec for specification of this: https://www.khronos.org/registry/webgl/specs/latest/1.0/ ctrl+f for 'powerPreference'
		/**
		 * Indicates a request for a GPU configuration that prioritizes rendering performance over power consumption.
		 * Any content that chooses this value should be aware that it is more likely that the user agent will force a lost context at any time.
		 * Implementations may decide to initially respect this request and, after some time, lose the context and restore a new context ignoring the request.
		 * Developers are encouraged to only specify this value if they believe it is absolutely necessary, since it may significantly decrease battery life on mobile devices.
		 * - default
		 * - high-performance (dual GPU)
		 * - low-power (conserve battery life)
		 */
		let dualGPU: string = (prefs.enableDualGPU !== undefined && prefs.enableDualGPU !== false) ? "high-performance" : "default";
		this._defaultSettings = {
			width: window.innerWidth,
			height: window.innerHeight,
			transparent: true,
			antialias: prefs.planDisableAliasing !== undefined ? !prefs.planDisableAliasing : true,
			resolution: (window.devicePixelRatio && window.devicePixelRatio >= 1) ? window.devicePixelRatio : 1,
			autoResize: false,
			roundPixels: true,
			forceCanvas: prefs.forceCanvasRenderer !== undefined ? prefs.forceCanvasRenderer : false,
			forceFXAA: prefs.forceFXAA !== undefined ? prefs.forceFXAA : false,
			powerPreference: dualGPU
		};
		
		let unsub = this.planState.stateChange$.subscribe((stateEvent: StateEvents) => {
			if (stateEvent > 0 && stateEvent < 100 && stateEvent !== StateEvents.CANVAS_LOADED){
				this.startRenderer();
			}
			
			if (stateEvent === StateEvents.BOOMED){
				this.application.destroy();
				this.application = null;
				(<any>window).TouchPlanCanvas = null;
				unsub.unsubscribe();
			}
		});
	}
	/**
	 */
	public startRenderer(){
		const localSettings = LocalStorageHelper.read('rendererSettings') || this._defaultSettings;
		localSettings["view"] = <HTMLCanvasElement> this.canvasView.nativeElement;
		localSettings["forceCanvas"] = this._defaultSettings.forceCanvas;
		localSettings["antialias"] = this._defaultSettings.antialias;

		
		// this.angularZone.runOutsideAngular(()=>{
		// 	this.application = new RendererApplication(this.planState, localSettings);
		// })
		this.application = new RendererApplication(this.planState, localSettings);
		
		// this.canvasView.nativeElement.style.width = window.innerWidth;
		// this.canvasView.nativeElement.style.height = window.innerHeight;
		(<any>window).TouchPlanCanvas = this.application;

		// let canvasConnector = new CanvasConnector(this.application);
		// this.planState.assignCanvasConnector(this.application);
	}
	
	public setAA(value){
		const currentSettings = LocalStorageHelper.read('rendererSettings') || Object.assign({}, this._defaultSettings);
		currentSettings["antialias"] = value;
		LocalStorageHelper.write("rendererSettings", currentSettings);
		window.location.reload();
	}
	
	public setFXAA(value){
		const currentSettings = LocalStorageHelper.read('rendererSettings') || Object.assign({}, this._defaultSettings);
		currentSettings["antialias"] = !value;
		currentSettings["forceFXAA"] = value;
		LocalStorageHelper.write("rendererSettings", currentSettings);
		window.location.reload();
	}
	
	public reset(){
		LocalStorageHelper.remove("rendererSettings");
		window.location.reload();
	}
	
	ngOnDestroy(){
		(<any>window).TouchPlanCanvas = null;
		(<any>window).CanvasDebug = null;
	}
}
