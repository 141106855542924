'use strict';

import * as moment from "moment";
import * as utils from "utils";

import { renderQueue } from "ng2/common/RenderQueue";

import Matrix from "js/lib/math/Matrix";
import Point from "js/lib/math/Point";
import Rect from "js/lib/math/Rect";
import AnimationQueue from "js/lib/ui/AnimationQueue";

declare const window: any;

export default function Hud ($container?, $surface?, $overlay?, $underlay?, scope?){/*

	Hud
	Object for Plan transforms.  Takes an element (zoomedElement)
	that holds the plan and acts as a viewport into it.
	
	onStart<Event> at the beginning of a transform, then repeatedly
	on<Event> as the transform progresses, and finally
	onEnd<event> to persist the new transformation.
	
	
	new Hud($container, $surface, $overlay, $underlay)
	.container = $container
	.transformation = Matrix // authoritive source for current transformations
	.observers = [fn]
	.surface = Surface
	.overlay = Overlay
	.underlay = Underlay
	.lock = original matrix before transform start
	.updateViewport() -> void // call if the container size changes
	.w  -> Number // viewport width (in screen px)
	.h  -> Number // viewport height (in screen px)
	.ox -> Number // viewport offset x (in screen px)
	.oy -> Number // viewport offset y (in screen px)
	.lc -> Point  // left center of viewport (in screen px)
	.x  -> Number // x position of the plan
	.y  -> Number // y position of the plan
	.z  -> Number // current scaling factor
	.s  -> Point  // scaling factor as x,y point
	.p  -> Point  // position as x,y point
	.translate (Point) -> void // pans the hud
	.onStartTranslate() -> void // lock
	.onTranslate() -> void // translate to current delta
	.onEndTranslate -> void // lock
	.scale(Point z, Point c) -> void // scales the hud
	.onStartScale() -> void // lock
	.onScale(Point z, Point c) -> void // scales the hud around center c by delta z
	.onEndScale() -> void // lock
	.transform(mx) -> void // applies a new matrix in array representation
	.onStartTransform() -> void // lock
	.onTransform(Point p, Point z, Point c) -> void
	.onEndTransform() // lock  */
	
	this.initialize.apply(this, arguments)
}


Hud.prototype = {
	constructor: Hud,
	initialize: function($container, $surface, $overlay, $underlay, scope){
		this.scope = scope;
		this.container = $container || $(".container");
		this.updateViewport();
		// this.transformation = new Matrix();
		this.transformation = new Matrix([0.01,0,0,0.01,0,0]); //start zoomed out
		this.transformation.observers.push(utils.bind(this, this.update)) ;
		this.observers = [];
		this.surface = new Surface(this, $surface || this.container.find(".surface"));
		this.overlay = new Overlay(this, $overlay || this.container.find(".overlay"));
		// this.underlay = new Underlay(this, $underlay || this.container.find(".underlay"));
		this.locks = [];
		var boundResize = utils.bind(this, function(){ this.updateViewport() });
		$(window).on('resize', boundResize);
		
		//flip it on in 15s anyways as a fallback
		var self = this;
		var safetyTime = setTimeout(function(){ this.hasDoneInitialFit = true; }, 15000);
		
		this.update();
		
		this.destroy = function(){
			this.isDestroyed = true;
			this.container = null;
			this.transformation = null;
			this.surface = null;
			this.overlay = null;
			this.observers = [];
			$(window).off('resize', boundResize);
			clearTimeout(safetyTime);
		}
	},
	lock: function(setlock){
		if (setlock) { this.locks.push(this.transformation.clone); return this.lock(); }
		else { return (this.locks[this.locks.length-1]||{}).clone || this.transformation.clone }
	},
	unlock: function(){
		var lock = this.locks.pop(), prev = this.locks.pop();
		if(lock && prev){ this.locks=[prev.subtract(lock, true).add(this.transformation, true)] }
		return this;
	},
	update: function(){
		if((this.lastZ >= 0.1 && this.transformation.a < 0.1) ||
			(this.lastZ <= 0.1 && this.transformation.a > 0.1)){
			this.scope.$applyAsync();
		}
		this.lastZ = this.transformation.a;
		
		renderQueue.push(()=>{
			this.observers.forEach(function(fn){ fn() });
			this.scope.orderedExecution.execute();
			}, "hud-update");
		// // TODO: REMOVE THIS SHIT OMFG STOP TYLER
		if ((<any>window).TouchPlanCanvas) {
			let camera = (<any>window).TouchPlanCanvas.camera;
			if (camera.isScalingAnimation){
				return
			}
			camera.setPositionAndScale(this.x, this.y, this.z);
			// camera.setPosition(this.x, this.y);
			// camera.setScale(this.z, this.z);
			(<any>window).TouchPlanCanvas.run();
		}
		

	},
	updateViewport: function(){
		var offset = this.container.offset();
		this.rect = new Rect(offset.left, offset.top,
			this.container.width(), this.container.height() || window.innerHeight);
		//notify the angular parts of the app that the viewport has changed
		var scope = this.scope;
		if(scope.flags.stickyInEditMode){ scope.ticketOps.moveToCenter(); }
		this.scope.$applyAsync();
		return;
	},
	
	getOffset: function(){
		var offset = this.container.offset();
		return offset|| {left:0, top:0}
	},
	
	get w() { return this.rect.w }, // viewport width (in screen px)
	get h() { return this.rect.h }, // viewport height (in screen px)
	get o() { return this.rect.point }, // viewport offset
	get ox(){ return this.rect.x }, // viewport offset x (in screen px)
	get oy(){ return this.rect.y }, // viwwport offset y (in screen px)
	get lc(){ return new Point(this.ox, this.oy + this.h/2) }, // left center of viewport (in screen px)
	
	get x() { return this.transformation.tx || 0 }, //x position of the plan
	get y() { return this.transformation.ty || 0 }, //y position of the plan
	get z() { return this.transformation.a || 1 }, //current scaling factor
	get s() { return new Point(this.z); }, // scaling factor as x,y point
	get p() { return this.transformation.point() || new Point() }, // position as x,y point
	
	//modified coordinate system, refers to altered positioning in the plan when
	//the plan is obstructed by various things like the constraint log
	get shiftedW() {
		return this.w;
	},
	
	// these modifiers are in terms of screen px / clientX/clientY
	
	onStartTranslate: function()    { this.lock(true); },
	onTranslate:      function(p)   { this.transform(this.lock().translate(p.divide(this.s))) },
	onEndTranslate:   function()    { this.unlock(); },
	translate:        function(p)   { this.onStartTranslate();
		this.onTranslate(p);
		this.onEndTranslate();},
	
	onStartScale:     function()    { this.lock(true); },
	onScale:          function(z, c){ this.transform(this.lock().scale(z,
		c.subtract(this.p)
			//.subtract(this.lc)
			.divide(this.s))) },
	onEndScale:       function()    { this.unlock(); },
	scale:            function(z, c){ this.onStartScale();
		this.onScale(z, c);
		this.onEndScale() },
	scaleString: function(z,c){
		return this.lock().scale(z, c.subtract(this.p).divide(this.s)).toString();
	},
	
	onStartTransform: function()    {
		this.lock(true);
	},
	onTransform: function(p, z, c){
		var lock = this.lock(), origin = lock.decompose();
		var tmx = (new Matrix()).translate(p.divide(origin.scale.divide(z)));
		var zmx = (new Matrix()).scale(z, c.subtract(origin.translation).divide(origin.scale))
		this.transform(lock.push(tmx).push(zmx))
	},
	onEndTransform: function() { this.unlock(); },
	transform: function(mx) { this.transformation.array = mx.array },
	
	getElementsRect: function(list){
		function nest(obj, key){
			var inner = obj;
			key.split('.').forEach(function(val){
				if(inner[val] !== undefined){inner = inner[val];}
			});
			return inner;
		}
		
		var c = {
			minTop: Infinity,
			minLeft: Infinity,
			maxTop: -Infinity,
			maxLeft: -Infinity
		};
		if(!list){ return false; }
		
		list.forEach(function(comp){
			var sideKeys = Object.keys(comp.map);
			comp.src.forEach(function(obj, id){
				sideKeys.forEach(function(s){
					if(typeof comp.map[s] === 'function'){ var val = comp.map[s](obj); }
					else if(typeof obj === 'object'){
						if(obj !== null){ var val = nest(obj, comp.map[s]); }
					}
					else{ var val = obj; } //single value case
					if(s === 'minTop' || s === 'minLeft'){
						if(val < c[s]){ c[s] = val; }
					}
					else{
						if(val > c[s]){ c[s] = val; }
					}
				});
			});
		});
		if(c.minTop===Infinity||c.minLeft===Infinity||c.maxTop===-Infinity||c.maxLeft===-Infinity){ return false; }
		
		return new Rect(c.minLeft, c.minTop, c.maxLeft-c.minLeft, c.maxTop-c.minTop);
	},
	
	getEmptyPlanRect: function(){
		//console.log('this thing');
		if(this.scope.activeLine && this.scope.activeLine.$value !== null){
			var activeX = this.scope.activeLine.$value;
			var today = utils.checkNested(this.scope, "today", "line") ? this.scope.today.line.$value : activeX - 145 * 14;
			
			
			var leftWidth = Math.abs(activeX - today);
			var widthRatio = window.innerWidth/(leftWidth * 3);
			
			var width = leftWidth * (1.2 + 3 * widthRatio);
			// var width = leftWidth * 1.8;
			var height = width * 9/16;
			
			//console.log('width', width, window.innerWidth, window.innerWidth/width);
			
			//some magic value to be make the initial top y position > 10000
			var rect = new Rect(today, 10000, width, height);
			//console.log('rect', rect);
			return rect;
		}
		else if(utils.checkNested(this.scope, "today", "line") && this.scope.today.line.$value !== null){
			var today = this.scope.today.line;
			return new Rect(today - 500, 10000, 1000, 1000);
		}
		else{
			return new Rect(0, 10000, 1000, 1000);
		}
	},
	
	lastScaleTimeoutId: undefined,
	//potential arguments:
	//- additional data to add to the elementsRect population loop
	//- edgeOffest
	//- edgeOffsetRect, specify each offset individually
	//- src/target rect overrides, scale to custom sizes, etc...
	scaleToFit: function(){
		
		var self = this;
		
		//_someone_ is calling this an extra time on load, so soak it
		clearTimeout(this.lastScaleTimeoutId);
		this.lastScaleTimeoutId = setTimeout(activate, 60);
		
		function activate(){
			if(this.isDestroyed){return;}
			var ticketMap = {
				minLeft: 'data.left',
				maxLeft: function(o){return o.data.left+o.data.width;},
				minTop: 'data.top',
				maxTop: function(o){return o.data.top+o.data.height;}
			};
			var activeLineMap = {
				minLeft: '',
				maxLeft: ''
			};
			
			var swimRange = [];
			var swimTop = null;
			var swimBottom = null;
			if(self.scope.planState && self.scope.planState.swimlanes){
				swimRange = self.scope.planState.swimlanes.list.getRange2();
				swimTop = swimRange.length ? swimRange[0].top : null;
				swimBottom = swimRange.length ? swimRange[swimRange.length-1].bottom : null;
			}
			// debugger;
			//map must be an array
			var list = [{
				src: self.scope.tickets,
				map: ticketMap
			},{
				src: [self.scope.activeLine.$value],
				map: activeLineMap
			},{
				src: [self.scope.today.line.$value],
				map: activeLineMap
			},{
				src: [self.scope.milestoneX.$value],
				map: activeLineMap
			},{
				src: [swimTop],
				map: {minTop: ''}
			},{
				src: [swimBottom],
				map: {maxTop: ''}
			}
			];
			
			if(self.scope.tickets.length){
				var elementsRect = self.getElementsRect(list);
			}
			else if(swimRange.length){
				list.push({
					src: [10], map: {minLeft: '', maxLeft: ''}
				})
				elementsRect = self.getElementsRect(list);
			}
			else{
				var elementsRect = self.getEmptyPlanRect();
			}
			
			if(!elementsRect){ self.hasDoneInitialFit = true; return; }
			var headerOffset = 48+29;
			var containerRect = new Rect(76+10, headerOffset+10, self.shiftedW-20-76, self.h-20-headerOffset);
			var scale = Math.min(containerRect.w/elementsRect.w, containerRect.h/elementsRect.h);
			//Cap the maximum zoom at 1.165
			if(scale > 1.165)
				scale = 1.165;
			
			var newPoint = new Point(
				Math.abs(containerRect.w - elementsRect.w*scale)/2 - elementsRect.x*scale + containerRect.x,
				Math.abs(containerRect.h - elementsRect.h*scale)/2 - elementsRect.y*scale +containerRect.y
			);
			
			//TODO DO NOT USE WINDOW FFS
			if ((<any>window).TouchPlanCanvas){
				(<any>window).TouchPlanCanvas.camera.scaleToFit(newPoint, scale);
			}
			
			self.startAnimation();
			self.transformation.reset();
			self.scale(scale, new Point(0,0));
			self.translate(newPoint);
			self.endAnimation();
			self.hasDoneInitialFit = true;
		}
		
		

		

	},
	
	screenToRealX: function(x){ return x / this.z - this.x / this.z; },
	screenToRealY: function(y){ return y / this.z - this.y / this.z; },
	realToScreenX: function(x){ return x * this.z + this.x; },
	realToScreenY: function(y){ return y * this.z + this.y; },
	
	
	
	
	//test one, just use the hud transform function with the origin set to the topleft
	debugPrint: function(){
		var self = this;
		
		//something like this maybe?
		var ticketMap = {
			minLeft: 'data.left',
			maxLeft: function(o){return o.data.left+o.data.width;},
			minTop: 'data.top',
			maxTop: function(o){return o.data.top+o.data.height;}
		};
		var activeLineMap = {
			minLeft: '',
			maxLeft: ''
		};
		//map must be an array
		var list = [{
			src: this.scope.tickets,
			map: ticketMap
		},{
			src: [this.scope.activeLine.$value],
			map: activeLineMap
		},{
			src: [this.scope.today.line.$value],
			map: activeLineMap
		}
		];
		
		if(this.scope.tickets.length){
			var elementsRect = this.getElementsRect(list);
		}
		else{
			var elementsRect = this.getEmptyPlanRect();
		}
		
		if(!elementsRect){ return; }
		var headerOffset = 48+29;
		//84
		//var dpi = 87;
		var dpi = 96
		var containerRect = new Rect(0, 0, 11 * dpi, 8.5 * dpi);
		// var containerRect = new Rect(0+10, headerOffset+10, 11 * dpi - 20, 8.5 * dpi - 20 - headerOffset);
		//console.log('cont', containerRect, this.w, this.h);
		//var scale = Math.min(containerRect.w/elementsRect.w, containerRect.h/elementsRect.h);
		var scale = containerRect.w/elementsRect.w;
		//Cap the maximum zoom at 1.165
		//if(scale > 1.165)
		//	scale = 1.165;
		
		this.startAnimation();
		this.transformation.reset();
		this.scale(scale, new Point(0,0));
		this.translate(new Point(
			Math.abs(containerRect.w - elementsRect.w*scale)/2 - elementsRect.x*scale + containerRect.x,
			Math.abs(containerRect.h - elementsRect.h*scale)/2 - elementsRect.y*scale +containerRect.y
		));
		this.endAnimation();
	},
	
	marqueeMode: function(){
		//something like this maybe?
		var ticketMap = {
			minLeft: 'data.left',
			maxLeft: function(o){return o.data.left+o.data.width;},
			minTop: 'data.top',
			maxTop: function(o){return o.data.top+o.data.height;}
		};
		var activeLineMap = {
			minLeft: '',
			maxLeft: ''
		};
		//map must be an array
		var list = [{
			src: this.scope.tickets,
			map: ticketMap
		},{
			src: [this.scope.activeLine.$value],
			map: activeLineMap
		},{
			src: [this.scope.today.line.$value],
			map: activeLineMap
		},{
			src: [this.scope.milestoneX.$value],
			map: activeLineMap
		}
		];
		
		//todo - make scale work
		var elementsRect = this.getElementsRect(list);
		this.transformation.reset();
		this.scale(1, new Point(0,0));
		this.translate(new Point(-elementsRect.x+300, -elementsRect.y + 100));
		var self = this;
		
		//elementsRect.set(elementsRect.x, elementsRect.y, elementsRect.w, elementsRect.h);
		//console.log('elementsRect', elementsRect);
		
		var pos = new Point(elementsRect.x, elementsRect.y);
		
		var baseSpeed = 8;
		var direction = new Point(-baseSpeed,-baseSpeed);
		//var direction = new Point(-3,0);
		
		function rand(){
			return baseSpeed + Math.floor(Math.random() * 2 -1);
		}
		
		function goUp(p){
			
			p.y = Math.abs(rand())
			return p;
		}
		function goDown(p){
			p.y = -Math.abs(rand())
			return p;
		}
		function goLeft(p){
			p.x = Math.abs(rand());
			return p;
		}
		function goRight(p){
			p.x = -Math.abs(rand());
			return p;
		}
		
		
		setInterval(function(){
			//AnimationQueue(function(){
			self.translate(direction);
			//console.log('self.y', -self.y, elementsRect.top);
			if(-self.x > elementsRect.right - self.w + 300){direction = goLeft(direction);}
			else if(-self.x < elementsRect.left - 300){ direction = goRight(direction)}
			
			if(-self.y > elementsRect.bottom - self.h + 300 ){direction = goUp(direction) }
			if(-self.y < elementsRect.top - 300){direction = goDown(direction) }
			//});
			
		}, 6);
		
	},
	
	//### centerTicket(ticket, z)
	// pass a ticket, the hud is updated to center said ticket
	// * ticket - a ticket object
	// * screenOffset[Point] - a point window level offset from the center
	centerTicket: function(ticket, z){
		if(!ticket){return;}
		var z = z || this.z;
		var x = ticket.data.left - (this.w / z)/2 + ticket.data.width/2;
		var y = ticket.data.top - (this.h / z)/2 + ticket.data.height/2;
		//TODO DO NOT USE WINDOW FFS
		if ((<any>window).TouchPlanCanvas){
			(<any>window).TouchPlanCanvas.camera.focusOnXYZ(-x*z, -y*z, z || undefined);
		}
		this.setPositionExact(-x*z, -y*z, z || undefined);
	},
	
	focusTicketAt: function(ticket, screenPos){
		if(!screenPos){screenPos = new Point(0,0);}
		// var x = (ticket.data.left+ticket.data.width/2) - 76/this.z - screenPos.x/this.z;
		// var y = (ticket.data.top+ticket.data.height/2) - 77/this.z - screenPos.y/this.z;
		var x = (ticket.data.left+ticket.data.width/2) - screenPos.x/this.z;
		var y = (ticket.data.top+ticket.data.height/2)  - screenPos.y/this.z;
		//TODO DO NOT USE WINDOW FFS
		if ((<any>window).TouchPlanCanvas){
			(<any>window).TouchPlanCanvas.camera.focusOnXY(-x*this.z, -y*this.z);
		}
		this.setPositionExact(-x*this.z, -y*this.z);
	},
	
	
	//### setPosition(x, y, z)
	// sets the postion of the plan
	// * x
	// * y
	// * z - optional, allows z to be set as part of this, defaults to staying the same
	setPosition: function(x,y,z){
		if(!z){ z = this.z; }
		this.startAnimation();
		this.transformation.set(z, 0, 0, z, -x*z, -y*z);
		this.endAnimation();
	},
	
	setPositionExact: function(x,y,z){
		if(!z){ z = this.z; }
		this.startAnimation();
		this.transformation.set(z, 0, 0, z, x, y);
		this.endAnimation();
	},
	//Experimental javascript animation
	// hits performance so hard that tickets don't update in time
	// seems worse than just... "not animating"
	setPositionExactAnimated: function(x,y,z){
		if(!z){ z = this.z; }
		this.startAnimation();
		var ticks = 10;
		var xTick = -(this.x - x) / ticks;
		var yTick = -(this.y - y) / ticks;
		var newX = this.x;
		var newY = this.y;
		var cancel = setInterval(()=>{
			newX += xTick;
			newY += yTick;
			AnimationQueue(()=>{
				this.transformation.set(z, 0, 0, z, newX, newY);
			})
		},500/ticks);
		setTimeout(function(){
			clearInterval(cancel);
		}, 500);
		
		
		this.endAnimation();
	},
	getPositionDebug: function(){
		return "MCScope.hud.setPositionExact("+this.x+","+this.y+","+this.z+")";
	},
	
	setMatrix: function(m){
		this.startAnimation();
		this.transformation.set(m.a, m.c, m.b, m.d, m.tx, m.ty);
		this.endAnimation();
	},
	
	startAnimation: function(){
		if(this.z < 0.2 && window.isiPad){ return; }
		this.animationInProgress = true;
		this.surface.$.addClass("animateChanges")
		this.surface.$.css('opacity');
		//this.underlay.$.addClass("animateChanges")
	},
	
	endAnimation: function(){
		setTimeout(utils.bind(this, function(){
			if(this.isDestroyed){return;}
			this.animationInProgress = false;
			this.surface.$.removeClass("animateChanges")
			this.update();
			//this.underlay.$.removeClass("animateChanges")
		}), 600)
	},
	
	// actualToScreen: function(p){
	//
	// },
	// screenToActual: function(p){
	//
	// }
	
}

export function HudLayer (hud?){/*
	HudLayer
	Abstract class for layers of the HUD. */
}


HudLayer.prototype = {
	constructor: HudLayer,
	initialize: function(hud){
		this.hud = hud || {};
		this.hud.observers.push(utils.bind(this, function(){ this.update() }));
		this.transformation = this.hud.transformation || new Matrix();
		this.observers = []
		
	},
	update: function(){
		this.observers.forEach(function(fn){ fn() });
	},
	extend: function(methods){
		for(var name in methods){ this[name] = methods[name] }
	}
};

//Hud Overlay (UI elements. static position, unused)
export function Overlay (hud?, $overlay?){
	this.initialize.apply(this, arguments);
}

Overlay.prototype = new HudLayer();
Overlay.prototype.constructor = Overlay;
Overlay.prototype.extend({
	initialize: function(hud, $overlay){
		HudLayer.prototype.initialize.apply(this, arguments);
		this.$ = $overlay || $(".overlay");
	},
	update: function(){
		HudLayer.prototype.update.apply(this, arguments)
	}
});



//Hud Surface (main GUI)
//Adjusts css-transform to current transformation matrix */
export function Surface (hud?, $surface?){
	this.updater = utils.bind(this, function(){
		this.$.css("transform", this.transformation.toString());
	});
	
	this.initialize.apply(this, arguments);
}

Surface.prototype = new HudLayer();
Surface.prototype.constructor = Surface;
Surface.prototype.extend({
	initialize: function(hud, $surface){
		HudLayer.prototype.initialize.apply(this, arguments);
		this.$ = $surface || $(".surface");
	},
	update: function(){
		AnimationQueue(this.updater);
		HudLayer.prototype.update.apply(this, arguments)
	}
});

//Hud Underlay (background)
//Adjusts background-properties to current transformation matrix
// export function Underlay (hud?, $underlay?){
// 	this.initialize.apply(this, arguments);
// 
// 	//return this
// }
// Underlay.prototype = new HudLayer();
// Underlay.prototype.constructor = Underlay;
// Underlay.prototype.extend({
// 	initialize: function(hud, $underlay){
// 		HudLayer.prototype.initialize.apply(this, arguments);
// 		this.$ = $underlay || $(".underlay");
// 		return this
// 	},
// 	update: function(){
// 		this.transform = this.transformation.decompose()
// 			|| {translation: new Point(0, 0), scale: new Point(1, 1)};
// 		this.p = this.transform.translation;
// 		this.h = this.hud.h/2;
// 		this.$.each(function(){ AnimationQueue($(this).data('updater')) });
// 
// 		HudLayer.prototype.update.apply(this, arguments)
// 	}
// 
// });
