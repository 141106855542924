import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseObject, AngularLandConnection } from 'ng2/fancy-firebase/base';
import { Plan } from 'ng2/common/models';

export class PlanObject extends FancyFirebaseObject<Plan>{
	
	constructor(angularLand:AngularLandConnection, path:any){ //fix any's
		super(angularLand, path);
	}
	
	$$updated(child:any){
		if(!child || !child.payload.exists()){ return null; }
		return new Plan(child.payload.val());
	}
	
	//placeholder
	updateTicketCount(count){
		return this.listRef.query.ref.child('ticketCount').set(count);
	}
}
