import { Tween, TweenKeys, TweenSettings } from "./";
import { RendererApplication } from "../core/";
import { FancySprite, FancyContainer, FancyNineSlicePlane } from "../graphics/"
import { DisplayObject, ticker } from "pixi.js";
/**
 * The TweenManager is responsible for tracking all active tweens and to tick the renderer if there is a tween currently active.
 * Further, if a tween with a defined duration is set, the TweenManager will automatically cull expired tweens.
 */
export class TweenManager {
	/**
	 * The currently tracked Tweens
	 */
	private tweens: Array<Tween> = [];
	
	/**
	 * Instance of the current RendererApplication.
	 */
	private application: any;
	
	private _destroyed: boolean = false;
	/**
	 * @param {RendererApplication} application The renderer application this tween manager is bound to.
	 */
	constructor(application: any){
		this.application = application;
		ticker.shared.add(this.update, this);
	}
	/**
	 * Called when the TweenManager is destroyed.
	 */
	public destroy(){
		ticker.shared.remove(this.update);
		this.application = null;
		this.tweens = null;
		this._destroyed = true;
	}
	/**
	 * Called every tick. This updates the status for each tween
	 */
	public update(){
		if (!this._destroyed && this.tweens.length > 0){
			let activeTweens: Array<Tween> = [];
			this.tweens.forEach((tween: Tween)=> {
				if (tween.destroyed){return}
				tween.update();
				if(!tween.complete){
					activeTweens.push(tween);
				} else{
					// Tween is complete. Destroy it.
					tween.destroy();
				}
			});
			this.tweens = activeTweens;
			// We have an active Tween. Automatically tick the renderer.
			if (this.tweens.length >= 0){
				this.application.run();
			}
		}
	}
	/**
	 * Adds a new Tween to the manager.
	 *
	 * NOTE: The tween does not start by default. In order to start it, invoke .start().
	 * @param {FancySprite | FancyContainer | FancyNineSlicePlane | PIXI.DisplayObject | Object<any>} objectToTween The object you are tweening
	 * @param {TweenKeys} properties The properties on the object you wish to tween
	 * @param {number} duration The duration of the tween
	 * @param {TweenSettings} [settings] Any special tween settings
	 * @returns {Tween}
	 */
	public tween(objectToTween: FancySprite|FancyContainer|FancyNineSlicePlane|DisplayObject|TweenKeys, properties: TweenKeys, duration: number, settings?: TweenSettings){
		let tween = new Tween(objectToTween, properties, duration, settings);
		this.tweens.push(tween);
		return tween;
	}
	/**
	 * Pauses all active tweens
	 */
	public pauseTweens(){
		this.tweens.forEach((tween) => {
			tween.pause();
		})
	}
	/**
	 * Stops all active tweens
	 * @param {boolean} markComplete If true, will mark each tween as 'complete'
	 */
	public stopTweens(markComplete: boolean){
		this.tweens.forEach((tween) => {
			tween.stop(markComplete);
		})
	}
	/**
	 * Resumes all paused tweens
	 */
	public resumeTweens(){
		this.tweens.forEach((tween) => {
			tween.resume();
		})
	}
}
