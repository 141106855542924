'use strict';

import * as angular from "angular";
import * as moment from "moment";

//does timing things...
//declares a factory
angular.module('ticketspaceApp').factory("Timing", ["$log", function($log) {
	//variables
	var shouldLog = true;
	var data = {};
	var debounceData = {};
	
	//private functions
	var log = function(message){
		if(shouldLog){ $log.log(message); }
	};
	
	//validates that a timer for that type isn't already in progress
	var debounce = function(value){
		if(debounceData[value]){
			$log.error('recieved an extra start event for: ' + value + ', ignoring');
			return true;
		}
		debounceData[value] = window.performance.now();
		return false;
	};
	
	//public functions
	
	//returns the data history object
	//if type is specificed return just that type
	var getData = function(type){
		if(type){
			if(data[type]){
				return data[type];
			}
			else{
				$log.error('type not found');
			}
		}
		else{
			return data;
		}
	};
	
	//start the timer
	var start = function(type){
		if(!data[type]) { data[type] = []; }
		if(debounce(type)) { return };
		
	};
	
	var stop = function(type){
		if(debounceData[type]){
			var newTime = window.performance.now();
			var difference = newTime - debounceData[type];
			
			log(type + ': ' + Math.floor(difference) + 'ms');
			
			delete debounceData[type];
			
			data[type].push({
				duration: difference,
				timeFinished: moment().toISOString()
			});
		}
	};
	
	return {
		start: start,
		stop: stop,
		getData: getData
	}
}]);
	
