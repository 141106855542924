import { Injectable } from '@angular/core';

import * as interfaces from 'ng2/ticket-edit/interfaces';

import {TicketEditServiceTemplater} from 'ng2/ticket-edit/ticket-edit-templater.service';
import { EditTicketsState } from 'ng2/fancy-firebase/lists/TicketList'

//import all the core edit components
import { EditWrapperComponent, TaskEditComponent, MilestoneEditComponent, ConstraintEditComponent, MultiEditComponent } from 'ng2/ticket-edit/edit-wrapper.component';

import { FancyTicketAction, TicketActionTypes } from "ng2/actions/ticket-actions";
import {Ticket, TicketRenderTypes} from "ng2/common/models/Ticket";

import { PlanState, StateEvents } from 'ng2/common/services/plan-state.service';

import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";

// import TicketEdit from 'ng2/common/models/TicketEdit';

import { EditTicketsCombined } from 'ng2/common/models/EditTicketsCombined';




@Injectable()
export class TicketEditService{
	public isEditing = false;

	/**
	 * Whenever one or more tickets is being edited, EditTicketsCombined$
	 * emits a EditTicketsCombined instance which has all the data for the
	 * ticket(s) being edited.  This is what the UI uses to populate
	 * the edit component(s).
	 */
	public editTicketsCombined$ = new BehaviorSubject(undefined);
	
	// current implementaion isn't an Observable and assumes a 
	// single ticket.  This property will go away soon.
	//editModel: TicketEdit;

	// experimentalComponentList = [TaskEditComponent, ConstraintEditComponent];
	// editWrapperComponent: EditWrapperComponent; //to change the templates programatically
		
	constructor(private planState: PlanState, private ticketEditServiceTemplater: TicketEditServiceTemplater){
		console.log('construct TicketEditService');
		
		// planState.stateChange$.subscribe((stateEvent: StateEvents)=>{
		// 	switch(stateEvent){
		// 		case StateEvents.INITIALIZED:
		// 			this.generateEditWatcher(); break;
		// 		case StateEvents.BOOMED:
		// 			this.releasePlanData(); break;
		// 		default:
		// 			break;
		// 	}
		// })
	}
	
	private releasePlanData(){
		
	}
	private generateEditWatcher(){
		//move this elsewhere: some sort of editStateManager?
		
		//just shut this up until we use it
		/*
		this.planState.tickets.editTicket$.pipe(debounceTime(10)).subscribe((editTickets:EditTicketsState)=>{
			console.log('editTickets', editTickets)
			if(editTickets === undefined){ return; } //ignore if edit has never been called
			if(editTickets.list.size){ //open/ update edit
				if(this.isEditing){ this.updateEdit(editTickets); }
				else{ this.openEdit(editTickets); }
			}
			else{ //close edit
				this.closeEdit();
			}
		});*/

		// We're going to use the editTicket$ as the basis for building
		// EditTicketsCombined$.  Right now, editTicket$ isn't "live" in the sense
		// that it won't update if an underlying ticket changes, a ticket
		// being edited gets deleted, etc.
		// TODO - We'll need to fix editTicket$ to be live if we keep this
		// strategy
		
		// Implementation notes.
		// editTicket$ is going to emit a Map.  The map is the thing we need to
		// iterate through, and essentially reduce multiple values (one per 
		// ticket in the Map) down to a single combined value.  We do this across
		// all the needed properties to emit a new (was TicketEdit) for now go with 
		// an EditData.  
		this.planState.tickets.editTicket$
			.subscribe(
				(editTicketsState: EditTicketsState) => {
					let map = editTicketsState ? editTicketsState.list : undefined;
					if (map && map.size > 0) {
						// Create a new EditTicketsCombined and set the things related to the count 
						// of tickets in the map
						var etc = new EditTicketsCombined();
						etc.isEditAll = map.size > 1 ? true : false;

						// convenience function to dig out the property based on a path string
						const getDescendantProp = (obj, path) => (
							path.split('.').reduce((acc, part) => acc && acc[part], obj)
						);
						
						// If this isn't an edit all, just do the single ticket case and move on
						if ( !etc.isEditAll ) {
							// Single ticket case
							etc.totalTickets = map.size;
							var t = map.values().next().value
							// default to 'task' if there is no type set
							console.log("t in TicketEditService: ", t);
							var myType = t.rawDatabase.hasOwnProperty('type') ? t.rawDatabase.type : 'task';

							// Iterate the keys at etc.knownKeys.myType.singleTicket and plop
							// the corresponding ticket data into the typesInCollection Map
							// for this type.
							let ticketData: any
							ticketData = {};
							for (let key in etc.knownKeys[myType].singleTicket) {
								// console.log("key: ", key);
								// console.log("model prop: ", etc.knownKeys[myType].singleTicket[key]);
								// console.log("model val: ", getDescendantProp(t, etc.knownKeys[myType].singleTicket[key]));
								ticketData[key] = getDescendantProp(t, etc.knownKeys[myType].singleTicket[key]);
							};
							// For debugging stick the original ticket at the end
							ticketData['originalTicket'] = t;
							etc.typesInCollection.set(myType,  ticketData);
							// set the current type
							etc.currentType = this.getEditAllType('', myType);
						} else {
							// multiple ticket case
							etc.totalTickets = map.size;
							// Iterate the map, produce count by ticket type (i.e. task, constraint,
							// milestone) and the combined data for each ticket type in the map.
							for (var t of map.values()) {
								// Update counts by type
								var myType = t.rawDatabase.hasOwnProperty('type') ? t.rawDatabase.type : 'task';
								var typeData = etc.typesInCollection.get(myType) ? etc.typesInCollection.get(myType) : {};
								if ( !typeData.hasOwnProperty("count") ) {
									typeData['count'] = 1;
								} else {
									typeData['count']++;
								}
								for (let key in etc.knownKeys[myType].editAll) {
									//NYI
									// console.log("key: ", key);
									// console.log("model prop: ", etc.knownKeys[myType].editAll[key]);
									// console.log("model val: ", getDescendantProp(t, etc.knownKeys[myType].editAll[key]));
									// Test the other properties and either init them, or test if they're the same as prior
									if ( !typeData.hasOwnProperty(key) ) {
										typeData[key] = getDescendantProp(t, etc.knownKeys[myType].editAll[key]);
									} else {
										//test and kepp prior value or set to "multipleValues" as need be
										typeData[key] = ( typeData[key] === getDescendantProp(t, etc.knownKeys[myType].editAll[key]) ) ? typeData[key] : "multipleValues";
									}
								}
								// stick it in the map
								etc.typesInCollection.set(myType,  typeData);
								// Finally, update currentType
								etc.currentType = this.getEditAllType(etc.currentType, myType);
							}	
						}
						this.editTicketsCombined$.next(etc);
					} // if (map)
				}
			);
		this.editTicketsCombined$
			.pipe(tap(x => console.log("EditTicketsCombined$ emission: ", x)))
			.subscribe();
	} //generateEditWatcher()
	
	// public registerEditWrapper(editWrapperComponent:EditWrapperComponent){
	// 	if(this.editWrapperComponent){
	// 		//do some cleanup
	// 	}
	// 	this.editWrapperComponent = editWrapperComponent;
	// }
	
	private inferEditType(tickets){
		if(!tickets){
			console.log('tickets is undefined');
			//consider doing something like a ticket specific error view
			return;
		}
		var currentType: string;
		if(tickets.size > 1){
			return MultiEditComponent;
		}
		else{
			var t = tickets.values().next().value;
			currentType = t.rawDatabase.hasOwnProperty('type') ? t.rawDatabase.type : 'task';
		}
		switch(currentType){
			case 'constraint': return ConstraintEditComponent;
			case 'milestone': return MilestoneEditComponent;
			case 'task': return TaskEditComponent;
			default: return TaskEditComponent;
		}
	}
	
	//all of this is exceptionally placeholder-y
	public openEdit(editTicketsState: EditTicketsState, customComponent?:interfaces.ticketEditComponent, config?){
		let tickets = editTicketsState.list;
		let selectedComponent;
		if(customComponent){
			
		}
		else{
			selectedComponent = this.inferEditType(tickets);
		}
		
		// var firstTicket = tickets.values().next().value;
		// this.editModel = new TicketEdit(firstTicket.database);
		
		this.ticketEditServiceTemplater.openEdit(selectedComponent);
		// this.editWrapperComponent.changeChildComponent(selectedComponent);
		
		this.isEditing = true;
	}
	public updateEdit(editTickets: EditTicketsState){
		console.log('@dave implement updateEdit');
//		this.openEdit(editTickets);
	}
	public closeEdit(){
		console.log('@dave implement closeEdit');
		this.ticketEditServiceTemplater.closeEdit();
		// this.editWrapperComponent.clearChildComponent();
		this.isEditing = false;
	}
	
	
	/**
	 * Takes a role ID (string) and returns a role color (string for now)
	 */
	getRoleColor(roleId: string): string {
		return "NYI: role color string"
	}

	/**
	 * Compares the currentWinner ticket type with the challenger ticket
	 * type to determine the new winner for what editAll should open to.
	 * Parameters are strings (should probably be enums)
	 */
	getEditAllType(currentWinner: string, challenger: string): string {
		// The challenger wins if there is no currentWinner
		if ( !currentWinner ) {
			return challenger;
		} else {
			// Task beats everything
			if ( challenger === 'task' ||  currentWinner === 'task') {
				return 'task';
			} else {
				// Assume challenger is either constraint or MS.  constraint
				// beats milestone
				if ( challenger === 'constraint' || currentWinner === 'constraint' ) {
					return 'constraint';
				} else {
					return 'milestone';
				}
			}
		}
	}

}
