export enum TICKET_ACTIVITY_NAME_FONTS {
	ACTIVE = "Roboto Medium",
	PULL = "Roboto Black"
}

export enum TICKET_LOCATION_NAME_SETTINGS {
	"LEFT_MARGIN" = 15,
	"LEFT_MARGIN_PULL" = 15,
	"RIGHT_MARGIN" = 10,
	"RIGHT_MARGIN_PULL" = 15,
	"TOP_MARGIN" = 2,
	"TOP_MARGIN_PULL" = 2,
	"DRAWING_AREA_HEIGHT" = 25,
	"DRAWING_AREA_HEIGHT_PULL" = 40
}
export enum TICKET_DATE_TEXT_SETTINGS{
	"TASK_LEFT_MARGIN" = 15,
	"TASK_LEFT_MARGIN_PULL" = 15,
	"TASK_RIGHT_MARGIN" = 15,
	"TASK_RIGHT_MARGIN_PULL" = 15,
	"TASK_TOP_MARGIN" = 2,
	"TASK_TOP_MARGIN_PULL" = 6,
	"TASK_BOTTOM_MARGIN" = 0,
	"TASK_BOTTOM_MARGIN_PULL" = 0,
	"TASK_DRAWING_AREA_HEIGHT" = 22,
	"TASK_DRAWING_AREA_HEIGHT_PULL" = 40,
	
	"MILESTONE_LEFT_MARGIN" = 0,
	"MILESTONE_LEFT_MARGIN_PULL" = 0,
	"MILESTONE_RIGHT_MARGIN" = 0,
	"MILESTONE_RIGHT_MARGIN_PULL" = 0,
	"MILESTONE_TOP_MARGIN" = 4,
	"MILESTONE_TOP_MARGIN_PULL" = 0,
	"MILESTONE_BOTTOM_MARGIN" = 0,
	"MILESTONE_BOTTOM_MARGIN_PULL" = 0,
	"MILESTONE_DRAWING_AREA_HEIGHT" = 48,
	"MILESTONE_DRAWING_AREA_HEIGHT_PULL" = 72,
	
	"CONSTRAINT_LEFT_MARGIN" = 0,
	"CONSTRAINT_LEFT_MARGIN_PULL" = 0,
	"CONSTRAINT_RIGHT_MARGIN" = 0,
	"CONSTRAINT_RIGHT_MARGIN_PULL" = 0,
	"CONSTRAINT_TOP_MARGIN" = 10,
	"CONSTRAINT_TOP_MARGIN_PULL" = 16,
	"CONSTRAINT_BOTTOM_MARGIN" = 0,
	"CONSTRAINT_BOTTOM_MARGIN_PULL" = 0,
	"CONSTRAINT_DRAWING_AREA_HEIGHT" = 24,
	"CONSTRAINT_DRAWING_AREA_HEIGHT_PULL" = 48
}
/**
 * Magic Numbers.
 */
export enum TICKET_ACTIVITY_NAME_SETTINGS {
	"TASK_LEFT_MARGIN" = 15,
	"TASK_LEFT_MARGIN_PULL" = 15,
	"TASK_RIGHT_MARGIN" = 10,
	"TASK_RIGHT_MARGIN_PULL" = 15,
	"TASK_TOP_MARGIN" = 5,
	"TASK_TOP_MARGIN_PULL" = 15,
	"TASK_BOTTOM_MARGIN" = 20,
	"TASK_BOTTOM_MARGIN_PULL" = 88, // 25 from top. 20 from padding for icons. 28 from icon height. 15 from text padding
	
	"MILESTONE_LEFT_MARGIN" = 10,
	"MILESTONE_LEFT_MARGIN_PULL" = 20,
	"MILESTONE_RIGHT_MARGIN" = 10, // Extra 10 to offset left padding
	"MILESTONE_RIGHT_MARGIN_PULL" = 30, // Extra 15 to offset left padding
	"MILESTONE_TOP_MARGIN" = 5,
	"MILESTONE_TOP_MARGIN_PULL" = 0,
	"MILESTONE_BOTTOM_MARGIN" = 20,
	"MILESTONE_BOTTOM_MARGIN_PULL" = 45,
	// "MILESTONE_DRAWING_AREA_WIDTH" = 145,
	// "MILESTONE_DRAWING_AREA_WIDTH_PULL" = 270,
	// "MILESTONE_DRAWING_AREA_HEIGHT" = 110,
	// "MILESTONE_DRAWING_AREA_HEIGHT_PULL" = 220,
	
	"CONSTRAINT_LEFT_MARGIN" = 20,
	"CONSTRAINT_LEFT_MARGIN_PULL" = 20,
	"CONSTRAINT_RIGHT_MARGIN" = 15,
	"CONSTRAINT_RIGHT_MARGIN_PULL" = 15,
	"CONSTRAINT_TOP_MARGIN" = 10,
	"CONSTRAINT_TOP_MARGIN_PULL" = 30,
	"CONSTRAINT_BOTTOM_MARGIN" = 0,
	"CONSTRAINT_BOTTOM_MARGIN_PULL" = 0,
	"CONSTRAINT_DRAWING_AREA_WIDTH" = 0,
	"CONSTRAINT_DRAWING_AREA_WIDTH_PULL" = 0,
	"CONSTRAINT_DRAWING_AREA_HEIGHT" = 110,
	"CONSTRAINT_DRAWING_AREA_HEIGHT_PULL" = 180,
}
