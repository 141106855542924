import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { takeUntil, scan, map } from "rxjs/operators";

import { PlanState } from "ng2/common/services/plan-state.service";

import { Swimlane, SwimlaneRenderTypes } from "ng2/common/models/Swimlane";
// import {  } from "ng2/plan-things-rename/Swimlanes";
import { SwimlaneWithSelfObserver } from "ng2/fancy-firebase/factories";

@Component({
	selector: 'swimlanes',
	template: require('./swimlanes.component.html'),
	host: {'class': 'swimlanes'},
	// styles: [':host { position: absolute; top: 0; left: 0; z-index: 1000000;}'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwimlanesComponent implements OnInit, OnDestroy {
	// @Input() swimlanes: Map<string, Swimlane>
	private shutdownSubject = new Subject();
	public swimlanesSync: Array<SwimlaneWithSelfObserver>;
	public swimlane$: Observable<Array<SwimlaneWithSelfObserver>>;
	public includeTickets: boolean = false;
	
	constructor(private planState: PlanState) { }
	
	close(swimlane:Swimlane){
		console.log('close', swimlane);
		this.planState.swimlanes.changeView(swimlane, SwimlaneRenderTypes.database);
		this.planState.swimlanes.persistData(swimlane);
	}
	delete(swimlane:Swimlane){
		console.log('delete', swimlane);
		// this.planState.swimlanes.abortView(swimlane, SwimlaneRenderTypes.database);
		this.planState.swimlanes.removeItem(swimlane);
		
	}
	update(data:{model: Swimlane, data: Object}){
		console.log('update', data);
		this.planState.swimlanes.localUpdate(data.model.$id, data.data);
	}
	
	includeTicketsUpdated(state: boolean){
		this.includeTickets = state;
		console.log('state', state, 'includeTickets', this.includeTickets)
		// setInterval(()=> console.log('state', state, 'includeTickets', this.includeTickets), 1000);
		this.planState.swimlaneDragService.includeTickets = state;
	}

	ngOnInit() {
		console.log('components inited', performance.now() - window.blehTimestamp);
		this.includeTickets = this.planState.swimlaneDragService.includeTickets;
		
		this.swimlane$ = this.planState.swimlanes.list.editList$.pipe(
			takeUntil(this.shutdownSubject),
			map(mapBasedList => Array.from(mapBasedList.values())) //todo create an operator that converts a map to an array
			
		);
		this.swimlane$.subscribe(swimlanes => {
			this.swimlanesSync = swimlanes;
			// console.log('this.swimlanes', this.swimlanesSync);
		})
	}
	
	ngOnDestroy(){
		if(this.planState.swimlaneDragService) { this.planState.swimlaneDragService.includeTickets = true; }
		
		console.log('destroy swimlanes component');
		this.shutdownSubject.next(true);
	}

}


// Musings
// - can't ngFor a map efficiently, some processing will be necessary
// - current setup for the selected maps the collection generates is to only emit updates when the selection itself changes, not when the tickets involved in the selection change, swimlanes (and probably other things) will require monitoring ticket data
// - when unwrapping the selected tickets observable, then iterating it's children, the children will lose the magic OnPush properties that observables get when being passed to the child component (ngFor?) so they wil not be change detected
// - look into combining the selection and additional events to that selection, then emitting an array of observables, one for each swimlane
// 
// potential solution:
// 
// selection.pipe(
// 	switchMap(selectionList:Map => {
// 		rawEvent$.pipe(
// 			filter(action => selectionList.has(action.key)),
// 
// 		)
// 	})
// )
