import { Injectable, NgZone } from "@angular/core";

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import * as firebase from "firebase/app";

import environment from '../ng2/common/environment';


export let loadTesterFirebase;
export let ngZone: NgZone;
export let angularfireAuth: AngularFireAuth;

@Injectable({
	providedIn: 'root'
})
export class InitEarly{
	// public loadTesterFirebase?;
	
	constructor(private zone: NgZone, private afAuth: AngularFireAuth){
		ngZone = zone;
		angularfireAuth = afAuth;
	}
	
	initFirebase(){
		// firebase.initializeApp(environment.firebase);
		loadTesterFirebase = firebase.initializeApp({
		
			apiKey: "AIzaSyA1UcEDpM55ThZUZ-bi5EJ2noLqhwnQqWY",
			authDomain: "touchplan-event-collector.firebaseapp.com",
			databaseURL: "https://touchplan-event-collector.firebaseio.com",
			projectId: "touchplan-event-collector",
			storageBucket: "touchplan-event-collector.appspot.com",
			messagingSenderId: "659754640578"
		}, "other");
		// 
		
		// this.afAuth.user.subscribe((user)=>{
		// 	console.log('afAuth', user, Zone.current.name);
		// })
		// 
		// console.log('Zone firebase onAuthStateChanged init', Zone.current.name);		
		// firebase.auth().onAuthStateChanged(()=>{
		// 	console.log('Zone firebase onAuthStateChanged', Zone.current.name);		
		// })

	}
}
