'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

import Point from "js/lib/math/Point";

angular.module('ticketspaceApp')
.component('titleSelect', {
	template: require('tpl/components/ticket-title.html'),
	require: {ngModel: '^ngModel'},
	controller: ["$scope", "$timeout", function($scope, $timeout){
		var ctrl = this;
		ctrl.list = [
			{label: "Owner", value: "Owner"},
			{label: "Project Executive", value: "Project Executive"},
			{label: "Project Manager", value: "Project Manager"},
			{label: "Assistant PM", value: "Assistant PM"},
			{label: "Superintendent", value: "Superintendent"},
			{label: "Assistant Super", value: "Assistant Super"},
			{label: "Project Engineer", value: "Project Engineer"},
			{label: "Foreman", value: "Foreman"},
			{label: "Trade", value: "Trade"},
			{label: "Other", value: "Other"},
		];
		
		this.$onInit = function(){
			this.ngModel.$render = function(){
				ctrl.internalVal = {label: ctrl.ngModel.$viewValue, value: ctrl.ngModel.$viewValue};
			}
			$scope.$watch(function(){return ctrl.internalVal;}, function(val){
				let newValue = (val && val.value) ? val.value : val;
				ctrl.ngModel.$setViewValue(newValue);
			});
		}
		
		this.onDropdownChange = function(event){
			$timeout(()=>{
				this.internalVal = event;
			})
		}
	}]
})

.directive("fancyCheckbox2", function(){
	return {
		scope: true,
		transclude: true,
		require: '?ngModel',
		link: function(scope:any, element, attrs, modelCtrl:any){
			if(!modelCtrl){return;}
			element.addClass('fancy-checkbox');
			
			function assignClasses(){
				if(scope.model){ 
					element.addClass('fancy-checkbox-on');
					element.removeClass('fancy-checkbox-off');
				}
				else{
					element.addClass('fancy-checkbox-off');
					element.removeClass('fancy-checkbox-on');
				}
			}

			modelCtrl.$render = function(){
				scope.model = modelCtrl.$viewValue;
				assignClasses();
			}
			var unwatch = scope.$watch('model', function(){
					modelCtrl.$setViewValue(scope.model);
					assignClasses();
			});

			scope.$on('$destroy', function(){
				unwatch();
			});
		},
		//<span ng-transclude style="padding-left: 36px"></span>\
		template: '\
		<label class="">\
			<input type="checkbox" ng-model="model" style="display:none;">\
			<ng-transclude></ng-transclude>\
		</label>\
		'
	};
})

.component('periodOffIndicator', {
	template: '<div class="promise-period-handle left-handle"><i class="material-icons">chevron_left</i></div><div class="promise-period-handle right-handle"><i class="material-icons">chevron_right</i></div>',
	bindings: {
		"periodService": "<",
		"orderedExecutor": "<",
		"hud": "<"
	},
	controller: ["$element", function($element){
		var ctrl = this;
		
		var leftSide = $element.find('.left-handle');
		var rightSide = $element.find('.right-handle');
		//console.log('left', leftSide);
		
		leftSide.addClass("hide");
		rightSide.addClass("hide");
		
		this.$onInit = function(changesObj){
			ctrl.orderedExecutor.add(function(){
				//console.log('ctrl', ctrl, ctrl.periodService);
				if(!ctrl.periodService.range || !ctrl.periodService.range.startX || !ctrl.periodService.range.endX){leftSide.addClass('hide'); rightSide.addClass('hide'); return;}
				if(ctrl.hud){
					var leftScreenX = ctrl.hud.realToScreenX(ctrl.periodService.range.endX);
					if(leftScreenX < 84){ leftSide.removeClass("hide"); }
					else{ leftSide.addClass("hide"); }
					
					var rightScreenX = ctrl.hud.realToScreenX(ctrl.periodService.range.startX);
					if(rightScreenX > window.innerWidth){ rightSide.removeClass("hide"); }
					else{ rightSide.addClass("hide"); }
				}
			});
		};
	}]
})

.component('zoomButtons', {
	template: require("tpl/components/zoom-buttons.html"),
	bindings: {
		"hud": "<",
		"zoomPower": "<",
		"scaleToFit": "<"
	},
	controller: ["$element", function($element){
		var ctrl = this;
		var zoomAmount = this.zoomPower || 0.35;
		$element.addClass('zoom-buttons');

		this.zoomIn = function zoomIn(){
			(this.hud.zoomIn) ? this.hud.zoomIn(zoomAmount, true) : this.zoom(zoomAmount*1.2);
		}
		this.zoomOut = function zoomOut(){
			(this.hud.zoomOut) ? this.hud.zoomOut(zoomAmount, true) : this.zoom(-zoomAmount);
		}
		this.zoom = function zoom(zoomOffset){
			var z = 1 + zoomOffset;
			var c = new Point(window.innerWidth/2, window.innerHeight/2);
			
			this.hud.startAnimation();
			this.hud.scale(z, c);
			if(this.existingTimeout){ clearTimeout(this.existingTimeout); this.existingTimeout = null; }
			this.existingTimeout = setTimeout(function(){
				ctrl.hud.endAnimation();
			}, 500);
			analyticsService.clickedZoomButton();
		};

		this.seeAll = function(){
			if (this.scaleToFit){
				this.scaleToFit();
			}
		};
	}]
})
