import { TicketTextFontPath } from "./TicketTextFontPath";
import { PlanState } from "../../common/services/plan-state.service";
import { TicketContainer } from "../data-mappers/";
import { IFontOptions } from "../fontpath/";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { FancyContainer, FancyGraphics } from "../graphics/";
import { TICKET_ACTIVITY_NAME_SETTINGS} from "./TICKET_TEXT_SETTINGS";
import { TicketTypes, Ticket } from "../../common/models/";
import { TicketUtils } from "../utils/";
import { Side } from "../../common/models/";
import { LEVEL_OF_DETAIL_SIGNALS, LEVEL_OF_DETAIL_TABLE, TextComponents, SpecialLODConditions, getLodDefinition, LevelOfDetail } from "../core";
import { WORD_WRAP_MODE } from "../fontpath/WordWrapper";

export class TicketActivityNameFontPath extends TicketTextFontPath {
	// TODO: Apply the mask globally on the world
	public maskContainer: FancyContainer = new FancyContainer();
	public maskGraphics: FancyGraphics = new FancyGraphics();
	private _unsubUpdate: any;
	private _lastWidth: number;
	
	constructor(fontFamily: string, planState: PlanState, ticketContainer: TicketContainer, text: string = "", fontOptions: IFontOptions = {fontSize: 32, wordWrapOptions: {mode: WORD_WRAP_MODE.GREEDY, lineHeight: "32px"}}) {
		super(fontFamily, planState, ticketContainer, text, fontOptions);
		
		this.maskContainer.mask = this.maskGraphics;
		this.maskContainer.addChild(this);
		ticketContainer.ABOVE.addChild(this.maskGraphics);
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		LevelOfDetail.emitter.on(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
		this._unsubUpdate = ticketContainer.camera.changed$.subscribe(this.update);
		
		this.onChanged(ticketContainer);
		this.lodChanged();
	}
	
	public update = () => {
		if(!this.ticket.visible || !this.ticket.renderable){return}
		this._shift(this.ticket.getData());
		if (!this.dirty){return}
		this.onChanged(this.ticket);
	};
	
	public destroy(options?): void {
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		LevelOfDetail.emitter.off(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
		this._unsubUpdate.unsubscribe();
		super.destroy(options);
	}
	/**
	 * Aligns the ticket activity name for both the active and pull sides
	 * From spec:
	 * - ACTIVE:
	 * -- fontSize 32px if durationDays === 1
	 * -- fontSize: 42px if durationDays > 1
	 * -- font-weight: 500
	 * -- line-height: 32px if duration days === 1
	 * -- line-height is 36px if durationDays > 1
	 * -- Multi-line Text align left
	 * - PULL:
	 * -- fontSize: 32px
	 * -- lineHeight: 36px
	 * -- fontWeight: 500
	 * -- Multi-line Text Align center
	 * -- Align text to center of container.
	 * @param {Ticket} data The ticket model
	 */
	private _alignTask(data: Ticket): void {
		//need current lod
		const side: Side = data.view.side;
		const currentLod = LevelOfDetail.getLOD();
		let oneDayArr = data.view.liveDurationDays > 1 ? [] : [SpecialLODConditions.isOneDayTicket]
		
		//this needs to not run on frequent updates
		let lodArr:any = [
			TicketTypes.TASK,
			TextComponents.activityNameComponent,
			side,
			...oneDayArr,
			currentLod
		];
		
		let fontData = getLodDefinition(lodArr);
		
		this.fontSize = fontData.fontSize * window.ticketTextScale;
		this.lineHeight = fontData.lineHeight;
		if (side === Side.ACTIVE){
			this.align.x = false;
			this.align.y = false;
			this.alignment = "left";
		} else if (side === Side.PULL){
			this.align.y = true;
			this.alignment = "center";
		}
	}
	/**
	 * Align the ticket activity name for milestones on both the active and pull side
	 * From Spec:
	 * - ACTIVE:
	 * -- fontSize: 32px
	 * -- fontWeight: 500
	 * -- lineHeight: 32px
	 * -- Multi-line Text aligned center
	 * - PULL:
	 * -- fontSize: 36px
	 * -- fontWeight: 500
	 * -- lineHeight: 36px
	 * -- Multi-line Text aligned center
	 * -- Align to center of container
	 * @param {Ticket} data
	 */
	private _alignMilestone(data: Ticket): void {
		const side: Side = data.view.side;
		
		const currentLod = LevelOfDetail.getLOD();
		let fontData = getLodDefinition([
			TicketTypes.MILESTONE,
			TextComponents.activityNameComponent,
			side,
			currentLod
		] as any);
		this.fontSize = fontData.fontSize * window.ticketTextScale;
		this.lineHeight = fontData.lineHeight;
		
		if (side === Side.ACTIVE){
			this.align.x = false;
			this.align.y = true;
			this.alignment = "center";
		} else if (side === Side.PULL){
			this.align.y = true;
			//this.align.x = true;
			this.alignment = "center";
		}
	}
	/**
	 * This aligns the constraint ticket description text for both the active and pull sides
	 * From spec:
	 * - ACTIVE:
	 * -- fontSize: 32px
	 * -- fontWeight: 500
	 * -- lineHeight: 32px
	 * -- Multi-line Text aligned left
	 * - PULL:
	 * -- fontSize: 32px
	 * -- fontWeight: 500
	 * -- lineHeight: 36;
	 * -- Multi-line Text aligned center
	 * -- Align to center of container.
	 * @param {Ticket} data
	 */
	private _alignConstraint(data: Ticket): void {
		const side: Side = data.view.side;
		
		const currentLod = LevelOfDetail.getLOD();
		let fontData = getLodDefinition([
			TicketTypes.CONSTRAINT,
			TextComponents.activityNameComponent,
			side,
			currentLod
		] as any);
		this.fontSize = fontData.fontSize * window.ticketTextScale;
		this.lineHeight = fontData.lineHeight;
		
		if (side === Side.ACTIVE){
			this.align.x = false;
			this.align.y = true;
			this.alignment = "center";
			// this.fontSize = 32;
			// this.lineHeight = "32px";
		} else if (side === Side.PULL){
			this.align.y = true;
			this.alignment = "center";
			// this.fontSize = 32;
			// this.lineHeight = "36px";
		}
	}
	
	private _handleSide = () => {
		let data = this.ticket.getData();
		let type = TicketUtils.getTicketType(data);
		
		// We could simplify this A LOT we have common elements...but MOCA...Might be best to keep the alignment rules separate for the inevitable changes.
		// Simplify once things stabilize a bit...
		switch (type){
			case TicketTypes.CONSTRAINT:
				this._alignConstraint(data);
				break;
			case TicketTypes.MILESTONE:
				this._alignMilestone(data);
				break;
			default:
				this._alignTask(data);
				break;
		}
		//this._shift(data);
	};
	
	private _shift(data: Ticket): void {
		this.applyOffsetAndDrawArea(this.ticket, TICKET_ACTIVITY_NAME_SETTINGS);
		const offset: number =  this._getHeightOffset(data);
		this.maskArea.y += offset;
		this.maskArea.height -= offset;
		this.x = this.maskArea.x;
		this.y = this.maskArea.y;
		this.positionCenter(data);
		
		this._drawTextMask();
	}
	
	private _drawTextMask(): void {
		// Draw text mask
		this.maskGraphics.clear();
		this.maskGraphics.beginFill(0x000000, 1);
		// We add the offset (this.x) to the mask width when drawing the mask for MOC-2863.
		// Wordwrap library is shifting over the text slightly but is not factoring in the offset when drawing. As a result, some characters are sliced in half from the mask.
		this.maskGraphics.drawRect(this.maskArea.x, this.maskArea.y - 3, this.maskArea.width, this.maskArea.height + 3);
		this.maskGraphics.endFill();
	}
	
	private lodChanged = (newLod: number = LevelOfDetail.getLODLevel(), oldLod: number = LevelOfDetail.getLastLODLevel()) => {
		const data = this.ticket.getData();
		
		let type = TicketUtils.getTicketType(data);
		switch(type){
			case TicketTypes.TASK:
				this._alignTask(data); break;
			case TicketTypes.MILESTONE:
				this._alignMilestone(data); break;
			case TicketTypes.CONSTRAINT:
				this._alignConstraint(data); break;
			default: break;
		}
		if (this.dirty && this.ticket.visible && this.ticket.renderable){
			this.calculateText();
		}
	};

	private onChanged = (ticketContainer: TicketContainer, skipCheck: boolean = false) => {
		if (!ticketContainer.renderable || !ticketContainer.visible){return}
		this.tint = this.ticket.liveRoleTextColor;
		
		const data = ticketContainer.getData();
		
		this._shift(data);
		
		this._handleSide();
		
		this.setText(data.view.shortActivityName);
		// Update word wrap!
		if (data.view.width !== this._lastWidth && !skipCheck){
			this.dirty = true;
			this.calculateText();
		}
		if (this.dirty){this.calculateText();}
		
		this.positionCenter(data);
		
		// MAKE LESS VISIBLE
		if (data.view.isActualized){
			this.alpha = 0.5;
		} else {
			this.alpha = 1.0;
		}
		
		this._lastWidth = data.view.width;
	};
}
