'use strict';

import * as angular from "angular";
import * as utils from "utils";
import * as moment from "moment";
import * as firebase from "firebase/app";

angular.module('ticketspaceApp')
.factory('firebaseUsersPresence', ["$q", "$firebaseObject", "fbConnection", function($q, $firebaseObject, fbConnection) {
	var onlineCount = 0;
	
	var pFactory = $firebaseObject.$extend({
		//going to operate on the following plans:
		//no split units of time, round up or down
		//- < 1min = s
		//- < 60mins = m
		//- < 24hours = h
		//- < 100days = d
		//- > 100days = red dot
		//- no timestamp = red dot
		//- online = green dot
		//will need cache that gets cleared on actual data change, $$updated
		//should have a thing that causes date recalcs
		parseTime: function(){
			if(this.$value === null){return false;}
			var val = (new Date() as any - this.lastOnline) / 60000;
			if(val < 60){ return Math.floor(val)+'m'; } //currently just mins
			if((val /= 60) < 24){ return Math.floor(val)+'h'; }
			if((val /= 24) < 99){ return Math.floor(val)+'d'; }
			
			return false;
		},
		$$updated: function(snap){
			if(!this.counter){ this.counter = 0; }
			this.counter++;
			
			updateCount(this.status, snap.child('status').val());
			$firebaseObject.prototype.$$updated.apply(this, arguments);
			
		},
		$$error: function(err){}
	});
	
	function updateCount(old, current){
		if((!old && !current) || old === current){}
		else if(old === 'online'){
			onlineCount--;
		}
		else if(current === 'online'){
			onlineCount++;
		}
		//console.log('old', old, 'new', current, onlineCount);
	}
	
	//#### getPresenceList(users)
	//returns a promise resolving to a list of users
	function getPresenceList(users){
		if(!users){return;}
		onlineCount = 0;
		//console.log('usersLength', users.length);
		//onlineCount = users.length;
		var defer = $q.defer();
		var userList = Object.create({
			get length(){ return onlineCount; }
		});
		//console.log('userList', userList);
		
		var batch = fbConnection.batch();
		utils.$loop(users, function(user, key){
			var presence = new pFactory(fbConnection.fbRef('presence/'+key));
			batch.push(presence.$loaded().then(function(){
					userList[key] = presence;
				}));
		});
		batch.end().then(function(){
			defer.resolve(userList);
		});
		
		return defer.promise;
	}

	
	return {
		getPresenceList: getPresenceList
	};
}])

//two basic types of presence, "state" and "events". Mainly going to focus on events for now.
//consider making a whole slew of constructors for each event type
//decisions, for auto-clearing with clearly defined timestamp and a ttl, roll that into startEventName and endEventName, or let event handler figure it out... Maybe do both? (addm, if doing start/end, pass return value of start into end as an arugment).

//## presenceMaster
// Provides management services for the presence event system.
// Is mainly expected to coordinate event watchers and event emitters.
// * on(type, cb) - subscribe to events, use a string to spec the type you want, callback recieves an event object. A call to on
//   may trigger several historical events to fire if any exist.
// * off - to be implemented
// * collectEvent(type, config) - call to emit a new event (consider ditching this and just using emit...)
// * addHistoryProvider(fn) - allow event emitters to provide a function that returns historical events based on current "live" event data.
//   fn will be called with a type string, and fn is expected to return the event config, or something falsy if not found
.factory('presenceMaster', ["$q", "Subscriber2", function($q, Subscriber2) {
	var s = new Subscriber2;
	
	//for testing purposes, use an array. Switch to another subscriber maybe later...
	var historyProviders = [];
	
	var supportedEvents = {
		"heyListen": "heyListen",
		"ticketMoved": "ticketMoved"
	};
	
	var api:any = {
		collectEvent: function collectEvent(key, config){
			//if(supportedEvents[key]){
				s.emit(key, config);
			//}
		},
		addHistoryProvider: function(fn){
			historyProviders.push(fn);
		}
	};
	s.extend(api);
	api.on = function(t, cb){
		var typeList = s.on.apply(s, arguments);
		var eventReplayList = [];
		historyProviders.forEach(function(fn){
			typeList.forEach(function(type){
				var conf = fn(type);
				if(conf){eventReplayList.push(conf);}
			});
		});
		//ensure this doesn't need to be flipped
		eventReplayList.sort(function(a,b){
			return a.timestamp - b.timestamp;
		});
		eventReplayList.forEach(function(evConf){
			cb(evConf)
		})
	}
	return api;
}])

//fb.ref("/.info/serverTimeOffset")

//consider things like "user is currently doing x". There needs to be a means of indicating user is done doing x in all cases, including when they lose connection.

//points at a place in firebase where presence events might drop
//collects and forwards events to a global clearing house
//may also handle writes/ cleanup

//notes round 2
// - definitely have this handle the data-clean up for ttl'd things

//notes round 3:
// - ignore "user goes offline cleanup for state" for now, it should be managable within the event system
// - do state within the event system, it should be managable with on/off style events

//notes round 4:
// - next thing to do would be a demo involving the "temp event state", deleted a ticket seems like the way to go


// ## firebasePresenceCollector
// A firebase driven event emitter for presenceMaster.
// Basically, just create it, point it somewhere, and let it do it's thing
// * addTempEvent(type, config) - create an event the shows up, waits for a bit, 
//   then goes away on it's own. Potential enhancement: throw out an off event when it's done.
// * addStateEvent(type, config) - create an event that should indicate state, as in, the event should stick around until it's done.
//   Returns an off function that ends the state, clears the event, and throws out an type+"Off" event.
//
// todo - implement something to better manage users abruptly vanishing before completing their events.
// Will probably need to be a complete .offline() maintenence thing...
.factory('firebasePresenceCollector', ["$q", "$firebaseArray", "fbConnection", "presenceMaster", function($q, $firebaseArray, fbConnection, presenceMaster) {
	var clientSkew = 0;
	firebase.database().ref("/.info/serverTimeOffset").on('value', function(offset) {
    //console.log('client skew: ', offset.val());
		var t = new Date().getTime() + offset.val();
		clientSkew = offset.val() || 0;
		//console.log('theoretical server time: ', moment(t).format());
  });
	
	function PresenceCollectorFactory(){
		var self = this;
		presenceMaster.addHistoryProvider(function(type){
			//just iterate for now
			//if it becomes an issue a "type" cache can be created and maintained
			for(var i = 0; i < self.$list.length; i++){
				if(self.$list[i].type === type){return self.$list[i];}
			}
		});
		return $firebaseArray.apply(this, arguments);
	}
	PresenceCollectorFactory.prototype = {
		$$added: function $$added(snap){
			var obj = snap.val();
			var ttl = obj.ttl || 0;
			
			var timestamp = moment(obj.timestamp-clientSkew);
			timestamp.add(ttl, 'milliseconds');
			var timeSince = timestamp.diff(moment())
			console.log('timeSince', timeSince, timestamp.format(), moment().format(), obj.type);
			//forward
			if(timeSince > 0){
				presenceMaster.collectEvent(snap.child('type').val(), obj);
				return $firebaseArray.prototype.$$added.apply(this, arguments);
			}
			
			//old event, clean it up for now... this "may" become problematic
			snap.ref.remove();
			return false;
		},
		$$removed: function $$removed(snap){
			var obj = snap.val();
			presenceMaster.collectEvent(snap.child('type').val()+'Off', obj);
			return $firebaseArray.prototype.$$removed.apply(this, arguments);
		},
		//this serves as an "event" system
		addTempEvent: function(type, config){
			//disabled
			
			// if(typeof type !== "string"){
			// 	console.log('rabble rabble rabble');
			// 	return;
			// }
			// config = config || {ttl: 3000};
			// angular.extend(config, {
			// 	"type": type,
			// 	"timestamp": firebase.database.ServerValue.TIMESTAMP,
			// });
			// console.log('config', config);
			// this.$add(config).then(function(ref){
			// 	setTimeout(function(){
			// 		ref.remove();
			// 	}, config.ttl);
			// });
		},
		//two angles, the event stays around, and is removed when it's finished
		// - need to watch $removed and send off events, maybe for everything
		// - on/off events need to be much more reliable...
		// or there's an "on" event that fades as expected, and a seperate "off" event when it's done
		// - clients that arrive in-between the on/off window get nothing
		addStateEvent: function(type, config){
			if(typeof type !== "string"){
				console.log('rabble rabble rabble');
				return;
			}
			config = config || {};
			angular.extend(config, {
				"type": type,
				"ttl": 300000,
				"sendOff": true,
				"timestamp": firebase.database.ServerValue.TIMESTAMP,
			});
			
			
			//temporarily disabled
			// var childRef = this.$ref().push();
			// childRef.set(config);
			
			//return cleanup event?
			function offStateEvent(offConfig){
				//temporarily disabled
				// var ttl = 0;
				// if(offConfig && offConfig.ttl){ ttl = offConfig.ttl; }
				// angular.extend(config, offConfig);
				// 
				// setTimeout(function(){ childRef.remove(); }, ttl);
			}
			return offStateEvent;
		}
	}
	
	var pFactory = $firebaseArray.$extend(PresenceCollectorFactory);
	return pFactory;
}])


//start simple, have everyone aggressively write

.factory('firebasePresenceState', ["$q", "$firebaseObject", "fbConnection", "presenceMaster", function($q, $firebaseObject, fbConnection, presenceMaster) {
	
	function PresenceCollectorFactory(){
		var self = this;
			
		return $firebaseObject.apply(this, arguments);
	}
	PresenceCollectorFactory.prototype = {
		soakedUpdate: utils.soak(function(){
			this.$save();
		}, 1000)
	}
	
	var pFactory = $firebaseObject.$extend(PresenceCollectorFactory);
	return pFactory;
}]);
