import * as angular from "angular";

//ability to set max value, default to 1 or 100, then calculate the position based on that
export default angular.module('progressBar', [])
.directive('progressBar', ["progressBarManager", function(progressBarManager) {

	function link(scope, element, attrs){
		var max = 15;
		var position = 0;
		
		var elements = {
			wrapper:  angular.element(element[0].querySelector('.progress-bar-wrapper')),
			buffer:  angular.element(element[0].querySelector('.buffer-bar')),
			progress:  angular.element(element[0].querySelector('.progress-bar')),
		};
		
		var anyWindow:any = window;
		anyWindow.elements = elements;
		//console.log('element', elements.progress, elements.progress[0].offsetWidth);
		
		function setPosition(val){
			position = val;
			var percent = val/max * 100;
			elements.progress[0].style.width = percent+'%';
		}
		
		function update(type, data){
			switch(type){
				case 'setMax': 
					max = data;
					break;
				case 'set':
					setPosition(data);
					break;
				default:
					console.log('update called without a type: ', type);
					break;
			}
			//console.log('update', type, data);
		}
		
		//console.log('attrs', attrs, attrs.progressBarId);
		if(attrs.progressBarId){
			//subscribe
			var obj = progressBarManager.subscribeDirectiveUpdate(attrs.progressBarId, update);
			
			elements.wrapper.on('click', function(event){
				var phantomFriendlyElement:any = this;
				var actualX = event.pageX - phantomFriendlyElement.offsetLeft;
				var width = phantomFriendlyElement.offsetWidth;
				var percent = actualX/width;
				var progress = percent * max;
				
				obj.setProgress(progress);
				setPosition(progress);
			});
		}
	}
	
	return {
		restrict: 'E',
		compile: function(element, attrs){
			element.addClass("progress-bar-wrapper");
			return link;
		},
		template: 
				'<span class="buffer-bar animated-progress"></span>\
				<span class="progress-bar animated-progress"></span>'
	};
}])
//temp subscribe thing, needs to support more then one
//or just use angular events
.factory('progressBarManager', function(){
	var directiveList = {};
	
	function subscribeDirectiveUpdate(id, dir){
		//effectively the "directive-side" api
		directiveList[id] = {
			id: id,
			update: dir,
			setProgress: function(){}, //no-op until set
			on: function(key, fn){
				switch(key){
					case 'setProgress':
						this.setProgress = fn;
						break;
					default:
						console.log('unknown key', key);
						break;
				}
			}
		};
		return directiveList[id];
	}
	
	function ProgressBar(id){
		this.id = id;
	}
	ProgressBar.prototype = {
		setMax: function setMax(max){
			if(directiveList[this.id]){
				directiveList[this.id].update('setMax', max);
			}
		},
		setProgress: function setProgress(val){
			if(directiveList[this.id]){
				directiveList[this.id].update('set', val);
			}
		},
		on: function on(key, fn){
			switch(key){
				case 'progressSet':
					if(directiveList[this.id]){
						directiveList[this.id].setProgress = fn;
					}
					break;
				default:
					console.log('unknown key', key);
					break;
			}
		}
	};
	
	function get(id){
		return new ProgressBar(id);
	}
	
	return {
		subscribeDirectiveUpdate: subscribeDirectiveUpdate,
		get: get
	};
});
