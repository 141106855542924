import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyJoinRequirements } from 'ng2/fancy-firebase/base';

import { Priority } from "ng2/common/models/Priority";


export class PriorityList extends FancyFirebaseList<Priority, FancyAction>{
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		return new Priority(action.payload.val());
	}
	$$updated(child:Priority, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.update(action.payload.val());
	}
}
