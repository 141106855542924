import { Rectangle } from "pixi.js";
import {Observable, Subject, BehaviorSubject } from "rxjs";
import { takeUntil, scan, take } from "rxjs/operators";

import * as utils from "utils";

export interface DecoratedRectangle extends Rectangle{
	id: string;
	color: number;
	remove?: boolean;
}

/**
 * Used to dump some debug rectangles into the plan to see where things actually render live
 *
 * Usage:
 * addRectangle()
 * updateRectangle()
 * removeRectangle()
 */
export class DebugRectangles{
	private shutdownSubject = new Subject();
	
	private list$: BehaviorSubject<DecoratedRectangle> = new BehaviorSubject(null);
	public listener$: BehaviorSubject<Map<string, DecoratedRectangle>> = new BehaviorSubject(null);
	
	constructor(){
		this.list$.pipe(
			takeUntil(this.shutdownSubject),
			scan((acc:Map<string, DecoratedRectangle>, current?:DecoratedRectangle)=>{
				if(!acc){ acc = new Map(); }
				if(!current){ return acc; }
				if(current.remove){ acc.delete(current.id); }
				else { acc.set(current.id, current); }
				return acc;
			}, null)
		).subscribe(this.listener$);
	}

	
	addRectangle(rect: Rectangle, color?: number){
		var id = makeid();
		
		var dec: DecoratedRectangle = rect as DecoratedRectangle;
		
		dec.id = id;
		dec.color = color || parseInt(utils.randomColor().substring(1), 16);
		
		this.list$.next(dec);
		
		return id;
	}
	updateRectangle(id: string, rect: Rectangle){
		this.listener$.pipe(take(1)).subscribe((list)=>{
			var dec = list.get(id);
			if(!dec){ return;	}
			dec.x = rect.x;
			dec.y = rect.y;
			dec.width = rect.width;
			dec.height = rect.height;
			this.list$.next(dec);
		})
	}
	
	removeRectangle(id: string){
		this.listener$.pipe(take(1)).subscribe((list)=>{
			var dec = list.get(id);
			if(dec){ dec.remove = true; this.list$.next(dec); }
		});
		
	}
	
	destroy(){
		this.shutdownSubject.next(true);
	}
}













function makeid() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
