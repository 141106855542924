import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { FancyContainer, FancyGraphics } from "../graphics/";
import { TICKET_ACTIVITY_NAME_SETTINGS } from "./TICKET_TEXT_SETTINGS";
import { TicketTypes, Ticket } from "../../common/models/";
import { TicketUtils } from "../utils/";
import { Side } from "../../common/models/";
import { TicketTextCanvas } from "./";
import { TextStyleOptions } from "pixi.js";

export class TicketActivityNameCanvas extends TicketTextCanvas {
	public maskContainer: FancyContainer = new FancyContainer();
	public maskGraphics: FancyGraphics = new FancyGraphics();
	
	private _lastWidth: number;
	private _unsubUpdate: any;
	
	constructor(planState: PlanState, ticketContainer: TicketContainer, text: string = "", style: TextStyleOptions = {wordWrap: true, fill: "#ffffff"}, canvas?: HTMLCanvasElement) {
		super(text, style, planState, ticketContainer, canvas);
		
		this.maskContainer.mask = this.maskGraphics;
		this.maskContainer.addChild(this);
		
		ticketContainer.ABOVE.addChild(this.maskGraphics);
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this._unsubUpdate = ticketContainer.camera.changed$.subscribe(this.update);
		
		this.onChanged(ticketContainer);
	}
	
	public update = () => {
		if (!this.ticket.visible || !this.ticket.renderable){return}
		this.onChanged(this.ticket);
	};
	
	public destroy(options?): void {
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this._unsubUpdate.unsubscribe();
		super.destroy(options);
	}
	
	/**
	 * Aligns the ticket activity name for both the active and pull sides
	 * From spec:
	 * - ACTIVE:
	 * -- fontSize 32px if durationDays === 1
	 * -- fontSize: 42px if durationDays > 1
	 * -- font-weight: 500
	 * -- line-height: 32px
	 * -- Multi-line Text align left
	 * - PULL:
	 * -- fontSize: 32px
	 * -- lineHeight: 36px
	 * -- fontWeight: 500
	 * -- Multi-line Text Align center
	 * -- Align text to center of container.
	 * @param {Ticket} data The ticket model
	 */
	private _alignTask(data: Ticket): void {
		const side: Side = data.view.side;
		if (side === Side.ACTIVE){
			this.align.x = false;
			this.align.y = false;
			this.style.fontSize = (data.view.liveDurationDays > 1) ? 42 : 32;
			this.style.lineHeight = 32;
			this.style.align = "left";
		} else if (side === Side.PULL){
			this.align.x = true;
			this.align.y = true;
			this.style.align = "center";
			this.style.fontSize = 32;
			this.style.lineHeight = 36;
		}
	}
	/**
	 * Align the ticket activity name for milestones on both the active and pull side
	 * From Spec:
	 * - ACTIVE:
	 * -- fontSize: 32px
	 * -- fontWeight: 500
	 * -- lineHeight: 32px
	 * -- Multi-line Text aligned center
	 * - PULL:
	 * -- fontSize: 36px
	 * -- fontWeight: 500
	 * -- lineHeight: 36px
	 * -- Multi-line Text aligned center
	 * -- Align to center of container
	 * @param {Ticket} data
	 */
	private _alignMilestone(data: Ticket): void {
		const side: Side = data.view.side;
		if (side === Side.ACTIVE){
			this.align.x = true;
			this.align.y = true;
			this.style.fontSize = 32;
			this.style.lineHeight = 32;
			this.style.align = "center";
		} else if (side === Side.PULL){
			this.align.x = true;
			this.align.y = true;
			this.style.align = "center";
			this.style.fontSize = 32;
			this.style.lineHeight = 36;
		}
	}
	/**
	 * This aligns the constraint ticket description text for both the active and pull sides
	 * From spec:
	 * - ACTIVE:
	 * -- fontSize: 32px
	 * -- fontWeight: 500
	 * -- lineHeight: 32px
	 * -- Multi-line Text aligned left
	 * - PULL:
	 * -- fontSize: 32px
	 * -- fontWeight: 500
	 * -- lineHeight: 36;
	 * -- Multi-line Text aligned center
	 * -- Align to center of container.
	 * @param {Ticket} data
	 */
	private _alignConstraint(data: Ticket): void {
		const side: Side = data.view.side;
		if (side === Side.ACTIVE){
			this.align.x = true;
			this.align.y = true;
			this.style.align = "left";
			this.style.fontSize = 32;
			this.style.lineHeight = 32;
		} else if (side === Side.PULL){
			this.align.x = true;
			this.align.y = true;
			this.style.align = "center";
			this.style.fontSize = 32;
			this.style.lineHeight = 36;
		}
	}
	
	private _handleSide = () => {
		let data = this.ticket.getData();
		let type = TicketUtils.getTicketType(data);
		
		// We could simplify this A LOT we have common elements...but MOCA...Might be best to keep the alignment rules separate for the inevitable changes.
		// Simplify once things stabilize a bit...
		switch (type){
			case TicketTypes.CONSTRAINT:
				this._alignConstraint(data);
				break;
			case TicketTypes.MILESTONE:
				this._alignMilestone(data);
				break;
			default:
				this._alignTask(data);
				break;
		}
		
		const offset: number =  this._getHeightOffset(data);
		this.maskArea.y += offset;
		this.maskArea.height -= offset;
		this.x = this.maskArea.x;
		this.y = this.maskArea.y;
	};
	
	private onChanged = (ticketContainer: TicketContainer) => {
		if (!ticketContainer.renderable || !ticketContainer.visible){return}
		
		this.tint = this.ticket.liveRoleTextColor;
		
		this.applyOffsetAndDrawArea(this.ticket, TICKET_ACTIVITY_NAME_SETTINGS);
		
		this._handleSide();
		
		const data = ticketContainer.getData();
		this.setText(data.view.shortActivityName);
		
		this.positionCenter(data);
		
		// Draw text mask
		this.maskGraphics.clear();
		this.maskGraphics.beginFill(0x000000, 1);
		// We add the offset (this.x) to the mask width when drawing the mask for MOC-2863.
		// Wordwrap library is shifting over the text slightly but is not factoring in the offset when drawing. As a result, some characters are sliced in half from the mask.
		this.maskGraphics.drawRect(this.maskArea.x, this.maskArea.y, this.maskArea.width, this.maskArea.height);
		this.maskGraphics.endFill();
		
		this._lastWidth = data.view.width;
		
		// MAKE LESS VISIBLE
		if (data.view.isActualized){
			this.alpha = 0.5;
		} else {
			this.alpha = 1.0;
		}
	};
}