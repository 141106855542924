

angular.module('CanvasWrapper').factory("Layer", LayerFactory);

LayerFactory.$inject = ["PIXI"];
function LayerFactory(PIXI){
	/**
	 * @namespace Layer
	 * @returns {CanvasWrapper.Layer}
	 * @memberOf CanvasWrapper
	 * @constructor
	 */
	var Layer = function(name){
		this.container = new PIXI.Container();
		this.name = name;
	};

	Layer.prototype.get = function(){
		return this.container;
	};

	Layer.prototype.getName = function(){
		return this.name;
	};

	Layer.prototype.getChildren = function(){
		return this.container.children;
	};

	Layer.prototype.cullChildren = function(begin, end){
		return this.container.removeChildren(begin, end);
	};

	return Layer;
}
