import * as angular from "angular";

angular.module('CanvasWrapper').factory("StepGraph", ["GeometricShape", function(GeometricShape) {
	var StepGraph = function(displayNode, index){
		if (!displayNode){ throw "displayNode must be passed as first argument.";}

		var style = {
			FILL_COLOR: '#4a98bc', // The main color of the wave forms
			FILL_ALPHA: 1, // The alpha value of the wave forms
			MARGIN_BOTTOM: 4, // The bottom margin of the waveforms
			MARGIN_LEFT: 0 // The left margin of the waveforms
		};

		var shape = new GeometricShape();
		displayNode.addChild(shape.get());
		if (index){displayNode.setChildIndex(shape.get(), index);}

		this.draw = function(data, scale){
			data = data || {};
			scale = scale || 1;
			shape.get().beginFill(parseInt(style.FILL_COLOR.substring(1), 16), style.FILL_ALPHA);
			var top = 5;
			var bottom = style.MARGIN_BOTTOM;
			var left = style.MARGIN_LEFT;
			var calcHeight = displayNode.height - top;
			for (var i = 0; i < data.length; i++){
				var intensity = data[i] * 0.1; // Get intensity as a decimal
				var padding = (!intensity) ? 1 : 0;
				var heightPx = Math.round(calcHeight * intensity) + padding;
				var y = top + calcHeight - heightPx - bottom;
				var scaleX = (left + i + shape.get().x) * scale;
				shape.get().drawRect(scaleX, y + shape.get().y, scale, heightPx);
			}
			shape.get().endFill();
		};

		this.setVisible = function(value){
			shape.setVisible(value);
		};

		this.get = function(){
			return shape;
		};
	};

	return StepGraph;
}]);
