
export default class GoldenColors{
	static ratio = 0.618033988749895;
	static HSVtoRGB(h, s, v) {
		var r, g, b, i, f, p, q, t;
		if (arguments.length === 1) {
			s = h.s, v = h.v, h = h.h;
		}
		i = Math.floor(h * 6);
		f = h * 6 - i;
		p = v * (1 - s);
		q = v * (1 - f * s);
		t = v * (1 - (1 - f) * s);
		switch (i % 6) {
			case 0: r = v, g = t, b = p; break;
			case 1: r = q, g = v, b = p; break;
			case 2: r = p, g = v, b = t; break;
			case 3: r = p, g = q, b = v; break;
			case 4: r = t, g = p, b = v; break;
			case 5: r = v, g = p, b = q; break;
		}
		return {
			r: Math.round(r * 255),
			g: Math.round(g * 255),
			b: Math.round(b * 255)
		};
	}
	
	constructor(private defaultAlpha = 1){
		
	}
	
	getRandomColor(alpha?:number){
		var hue = Math.random();
		hue += GoldenColors.ratio;
		hue % 1;
		var raw:any = GoldenColors.HSVtoRGB(hue, 0.5, 0.95);
		raw.a = alpha || this.defaultAlpha;
		var rgb:colorObject = raw;
		return new Color(rgb)
	}
}

interface colorObject{
	r: number;
	g: number;
	b: number;
	a: number;
}

/**
 * [Create a color object, access the rgba properties directly, or via css ready rgba/ hex strings]
 * @param  {number & colorObject} r [Accepts red value or a colorObject]
 * @param  {number}    g [green]
 * @param  {number}    b [blue]
 * @param  {number}    a [alpha]
 * @return {[Color]}      [A color.]
 */
export class Color{
	readonly r: number;
	readonly g: number;
	readonly b: number;
	readonly a: number;
	
	rgba: string;
	hex: string;
	constructor(r:number | colorObject, g?:number, b?:number, a?:number){
		if(typeof r === "number"){ //more than one
			this.r = r; this.g = g; this.b = b; this.a = a;
		}
		else if(typeof r === "object"){
			this.r = r.r; this.g = r.g; this.b = r.b; this.a = r.a;
		}
		else{
			throw new Error("Colors must be well constructed");
		}
		
		this.rgba = `rgba(${this.r},${this.g},${this.b},${this.a})`;
		if(this.a === 1){
			this.hex = "#"+this.r.toString(16)+this.g.toString(16)+this.b.toString(16);
		}
	}
}
