angular.module('CanvasWrapper').factory("Emitter", EmitterFactory);

function EmitterFactory(){
	/**
	 * @namespace Emitter
	 * @desc A simple Emitter which works well with PIXI.Events
	 * @returns {CanvasWrapper.Emitter}
	 * @memberOf CanvasWrapper
	 * @constructor
	 */
	var Emitter = function(){
		this.events = {};

		this.on = function(event, listener){
			if (typeof this.events[event] !== 'object'){this.events[event] = [];}
			this.events[event].push(listener);
		};

		this.removeListener = function(event, listener){
			var idx;
			function indexOf(event, listener){return event.indexOf(listener)}
			if (typeof this.events[event] === 'object'){
				idx = indexOf(this.events[event], listener);
				if (idx > -1){
					this.events[event].splice(idx, 1);
				}
			}
		};
		
		this.removeAllListeners = function(){
			this.events = {};
		};
		
		this.emit = function(event){
			var i, listeners, length, args = [].slice.call(arguments, 1);

			if (typeof this.events[event] === 'object') {
				listeners = this.events[event].slice();
				length = listeners.length;

				for (i = 0; i < length; i++) {
					listeners[i].apply(this, args);
				}
			}
		};

		this.once = function(event, listener){
			this.on(event, function g(){
				this.removeListener(event, g);
				listener.apply(this, arguments);
			});
		}
	};

	return Emitter
}