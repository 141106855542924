import { ConstraintAssignedIcon, TaskShadowMesh, TicketBand, TicketCrewSize,
	TicketDateTextFontPath, TicketDurationDays, TicketError, TicketIsLongest, TicketLocation, TicketLocationNameFontPath,
	TicketSelected, TicketSelectedTask, TicketActivityNameFontPath, TicketActivityNameCanvas, TicketCompletedCheck, TicketDateTextCanvas,
	TicketLocationNameCanvas } from "./";
import { TicketContainer } from "../data-mappers/";
import { IDynamicComponent } from "../component-system/";
import { PlanState } from "../../common/services/plan-state.service";
import { utils, Texture } from "pixi.js";
import { TICKET_TEXTURE_OPTIONS } from "../tickets/";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { rendererInfo } from "../core/";

export class TicketSchema {
	constructor(){};
	
	public createCommonSchema(planState: PlanState, ticketContainer: TicketContainer): IDynamicComponent {
		const common: IDynamicComponent = {};
		
		// Create always visible common components
		common["ALWAYS"] = [
			{
				creator: () => new TicketCompletedCheck(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_TICKET_COMPLETE_NAME], planState, ticketContainer),
				layer: "SURFACE"
			}
		];
		
		common["MAXIMUM"] = [];
		
		// Common components which render when LOD is VERY_HIGH or higher
		common["VERY_HIGH"] = [];
		
		// Common components which render when LOD is MEDIUM or higher
		common["MEDIUM"] = [
			// {
			// 	creator: () => new TicketBand((<any>Texture).TASK_TEXTURE, planState, ticketContainer),
			// 	layer: "ABOVE"
			// }
		];
		
		// WEBGL Specific
		if (rendererInfo.isWebGL()){
			common["MEDIUM"].push(
				{
					creator: () => new TicketDateTextFontPath("Roboto Medium", planState, ticketContainer),
					layer: "ABOVE"
				}
			);
		} else {
			common["MEDIUM"].push(
				{
					creator: () => new TicketDateTextCanvas(planState, ticketContainer),
					layer: "ABOVE"
				}
			);
		}
		
		// Common components which render when LOD is LOW or higher
		common["LOW"] = [];
		// WebGL specific components
		if (rendererInfo.isWebGL()){
			common["LOW"].push(
				{
					creator: () => {
						let t = new TicketActivityNameFontPath("Roboto Medium", planState, ticketContainer);
						return t.maskContainer;
					},
					layer: "ABOVE"
				},
			);
		} else {
			// Canvas specific components
			common["LOW"].push(
				{
					creator: () => {
						let t = new TicketActivityNameCanvas(planState, ticketContainer);
						return t.maskContainer;
					},
					layer: "ABOVE"
				}
			)
		}
		
		// Common components which render when LOD is VERY_LOW or higher
		common["VERY_LOW"] = [
		];
		
		return common;
	}
	
	public createGhostTaskSchema(planState: PlanState, ticketContainer: TicketContainer) {
		const common: IDynamicComponent = {};
		const tr = ticketContainer.ticketManager.taskRenderer;
		const data = ticketContainer.getData();
		const color = (data.view.liveRole && data.view.liveRole.color) ? data.view.liveRole.color : "#000000";
		
		// Create always visible common components
		common["ALWAYS"] = [
			{
				// TICKET IS SELECTED?
				creator: () => new TicketSelectedTask(tr.getTexture('ticketOutlineMesh'), planState, ticketContainer),
				layer: "BELOW"
			}
		];
		common["MAXIMUM"] = [];
		common["VERY_HIGH"] = [];
		common["MEDIUM"] = [];
		common["LOW"] = [
			{
				creator: () => {
					let t = new TicketActivityNameFontPath("Roboto Medium", planState, ticketContainer);
					return t.maskContainer;
				},
				layer: "ABOVE"
			}
		];
		common["VERY_LOW"] = [];
		
		return common;
	}
	
	public createGhostMilestoneSchema(planState: PlanState, ticketContainer: TicketContainer) {
		const common: IDynamicComponent = {};
		const data = ticketContainer.getData();
		// Create always visible common components
		common["ALWAYS"] = [
			{
				// TICKET IS SELECTED?
				creator: () => new TicketSelected(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_MILESTONE_SELECTED_NAME], utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_MILESTONE_SELECTED_PULL_NAME], planState, ticketContainer),
				layer: "BELOW"
			}
		];
		common["MAXIMUM"] = [];
		common["VERY_HIGH"] = [];
		common["MEDIUM"] = [];
		common["LOW"] = [
			{
				creator: () => {
					let t = new TicketActivityNameFontPath("Roboto Medium", planState, ticketContainer);
					return t.maskContainer;
				},
				layer: "ABOVE"
			}
		];
		common["VERY_LOW"] = [];
		
		return common;
	}
	
	public createGhostConstraintSchema(planState: PlanState, ticketContainer: TicketContainer) {
		const common: IDynamicComponent = {};
		const data = ticketContainer.getData();
		const color = (data.view.liveRole && data.view.liveRole.color) ? data.view.liveRole.color : "#000000";
		
		// Create always visible common components
		common["ALWAYS"] = [
			{
				// TICKET IS SELECTED?
				creator: () => new TicketSelected(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_SELECTED_NAME], utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_SELECTED_PULL_NAME], planState, ticketContainer),
				layer: "BELOW"
			}
		];
		common["MAXIMUM"] = [];
		common["VERY_HIGH"] = [];
		common["MEDIUM"] = [];
		common["LOW"] = [
			{
				creator: () => {
					let t = new TicketActivityNameFontPath("Roboto Medium", planState, ticketContainer);
					return t.maskContainer;
				},
				layer: "ABOVE"
			}
		];
		common["VERY_LOW"] = [];
		
		return common;
	}
	
	/**
	 * Davina said this is fine
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	public createTaskSchema(planState: PlanState, ticketContainer: TicketContainer): IDynamicComponent {
		let commonSchema = this.createCommonSchema(planState, ticketContainer);
		let tr = ticketContainer.ticketManager.taskRenderer;
		/**
		 * Davina: "You are doing great"
		 * TicketSelected draw order: (Higher in list = lower draw order)
		 *  - is selected
		 *  - is dependency focused
		 *  - is search result
		 * Also Davina: "no..."
		 */
		commonSchema["LOW"].push(
			{
				creator: () => new TicketLocation((<any>Texture).TASK_TEXTURE, planState, ticketContainer),
				layer: "ABOVE"
			}
		);
		
		// WebGL specific components
		if (rendererInfo.isWebGL()){
			commonSchema["LOW"].push(
				{
					creator: () => {
						const t = new TicketLocationNameFontPath("Roboto Medium", planState, ticketContainer);
						return t.maskContainer
					},
					layer: "ABOVE"
				}
			)
		} else {
			commonSchema["LOW"].push(
				{
					creator: () => {
						const t = new TicketLocationNameCanvas(planState, ticketContainer);
						return t.maskContainer
					},
					layer: "ABOVE"
				}
			)
		}
		
		// Add the other low LOD components
		commonSchema["LOW"].push(
			{
				creator: () => new TicketIsLongest(tr.getTexture("isLongestTexture"), planState, ticketContainer),
				layer: "ABOVE"
			},
			{
				creator: () => new TicketDurationDays(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_DURATION_DAYS_NAME], planState, ticketContainer),
				layer: "ABOVE"
			},
			{
				creator: () => new TicketCrewSize(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CREW_ICON_NAME], planState, ticketContainer),
				layer: "ABOVE"
			},
			{
				creator: () => new TicketError(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_ERROR_NAME], planState, ticketContainer),
				layer: "ABOVE"
			}
		);
		
		commonSchema["MEDIUM"].push(
			{
				creator: () => new TaskShadowMesh(tr.getTexture('ticketShadowMesh'), planState, ticketContainer),
				layer: "BELOW"
			}
		);
		
		commonSchema["ALWAYS"].push(
			{
				// TICKET IS SELECTED?
				creator: () => new TicketSelectedTask(tr.getTexture('ticketOutlineMesh'), planState, ticketContainer),
				layer: "BELOW"
			}
		);
		
		return commonSchema;
	}
	
	public createMilestoneSchema(planState: PlanState, ticketContainer: TicketContainer) {
		let commonSchema = this.createCommonSchema(planState, ticketContainer);
		
		commonSchema["ALWAYS"].push(
			// Is selected?
			{
				creator: () => new TicketSelected(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_MILESTONE_SELECTED_NAME], utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_MILESTONE_SELECTED_PULL_NAME], planState, ticketContainer),
				layer: "BELOW"
			}
		);
		
		commonSchema["LOW"].push(
			{
				creator: () => new TicketError(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_ERROR_NAME], planState, ticketContainer),
				layer: "ABOVE"
			}
		);
		
		return commonSchema;
	}
	
	public createConstraintSchema(planState: PlanState, ticketContainer: TicketContainer) {
		let commonSchema = this.createCommonSchema(planState, ticketContainer);
		
		commonSchema["ALWAYS"].push(
			// IS SELECTED?
			{
				creator: () => new TicketSelected(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_SELECTED_NAME], utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_SELECTED_PULL_NAME], planState, ticketContainer),
				layer: "BELOW"
			},
			// ASSIGNED PERSON
			{
				creator: () => new ConstraintAssignedIcon(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_ASSIGNED_ICON], planState, ticketContainer),
				layer: "ABOVE"
			}
		);
		
		commonSchema["LOW"].push(
			{
				creator: () => new TicketError(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_ERROR_NAME], planState, ticketContainer),
				layer: "ABOVE"
			}
		);
		
		return commonSchema;
	}
}

export const ticketSchema = new TicketSchema();