'use strict';

import * as angular from "angular";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

//todo: add a watch function to the user generation/ project generation stuff that allows the generated
//array to be updated when new entires are added

angular.module('ticketspaceApp')
.factory('firebaseUsers', ["$q", "$firebaseAuth", "firebaseUsersGetters", "rallyPointService", "authServiceHelpers", "fbConnection", "fbdbTime", "fbReporting", "firebaseAccountHelpers", "firebaseProjectMembersStatic", "atomicAssembler", function($q, $firebaseAuth, firebaseUsersGetters, rallyPointService, authServiceHelpers, fbConnection, fbdbTime, fbReporting, firebaseAccountHelpers, firebaseProjectMembersStatic, atomicAssembler) {
	var fbAuth = $firebaseAuth();
	
	function analyticsStuff(user, projects){
		//change to new user
		console.log('identifying (during creation)...');
		authServiceHelpers.customIdentification(user.id, user, function(){
			console.log('sending created event');
			fbReporting.reportNotice('sent created event', {"userId": user.id});
			analyticsService.created(user.id, user.email, projects);
		});
	}
	
	
	

	
	//## Manage user creation
	
	//public
	function canCreate(email){
		return fbConnection.loadObject('emailDirectory/' + utils.escapeEmail(email))
		.then(function(data){
			if (data.$value === null) { return true; }
			else{ return $q.rejectErr("Can't create, user already exists"); }
		}).catch( function( error ) {
				Logging.error( "Can't create: " + error.message );
				throw error;
		});
	}
	
	function passwordReset(email){
		fbAuth.$sendPasswordResetEmail(email).then(function(){
		}, function(error){
			console.log('reset email failed', error);
		});
	}
	
	//### getInvitedUserData(email)
	// takes an email and uses to assemble a list (object) of projects to add the user to. 
	// returns a promise. It resolves to false if there are no projects.
	function getInvitedUserData(email){
		return fbConnection.loadArray('invitedUsers/emails/'+utils.escapeEmail(email)+'/projects')
		.then(function(projects){
			if(projects.length === 0){
				return false;
			}
			else{
				var promises = [];
				var projectList = {};
				projects.forEach(function(project){
					promises.push(fbConnection.loadObject('invitedUsers/projects/'+project.$value+'/'+utils.escapeEmail(email))
					.then(function(data){
						//in theory this should never happen, but if it does, that project will just be ignored
						if(data.$value !== null){
							projectList[project.$value] = angular.extend({},data); //non-live
						}
					}));
				});
				return $q.all(promises).then(function(){ return projectList; });
			}
		});
	}
	
	function addProjectMembers(projectList, userId){
		var promises = [];
		if(projectList){
			for(var key in projectList){
				var pm = {
					userId: userId,
					roleId: projectList[key].roleId || null,
					accessLevel: projectList[key].accessLevel
				};
				promises.push(firebaseProjectMembersStatic.addAtomic(key, pm));
			}
		}
		return $q.all(promises);
	}
	
	function addViaCode(user){
		if(!user || !user.accessCode){return $q.when(false); }
		
		var codeData = rallyPointService.getAccessCode(user.accessCode);
		return codeData.$loaded().then(function(){
			if(codeData._isValid){
				return rallyPointService.addProjectAccess(codeData.projectId, user.id, user.accessCode);
			}
		});
	}
	
	function create(user, password, noLogin){
		if(!user || !user.email){
			return $q.rejectErr('Invalid data, user and user.email must be defined.');
		}
		
		user.email = user.email.toLowerCase();
		user.email = user.email.trim();
		
		var invitedProjectList:any = false;
		authServiceHelpers.flags.preventStandardIdentify = true;
		
		authServiceHelpers.flags.creationInProgress = true;
		return canCreate(user.email).then(function(){ //check
			//get all data before writing anything
			//this means that any failures getting data are fully recoverable by simply trying again
			
			return getInvitedUserData(user.email);
		})
		.then(function(ipl){ //invited user data collected
			invitedProjectList = ipl;
			
			// need alt path the checks for current auth instead of the create and signin flow
			// then this can be called by the services.js thing when user data is null
			var auth = fbAuth.$getAuth();
			
			//flow B: the user is already authed (so their account probably already exists)
			if(auth){
				user.id = auth.uid;
				return firebaseUsersGetters.userFromUserId(auth.uid)
				.then(function(userData){
					if(userData.$value !== null){return $q.reject("User data exists, cannot recreate");}
					else{return auth;}
				});
			}
			//flow A: normal version, create account as is sensible
			else{
				return fbAuth.$createUserWithEmailAndPassword(user.email, password)
				.then(function(authUser){ //simple login created
					//console.log('create authUser', authUser);
					user.id = authUser.user.uid;
					
					//populate a localStorage object
					try{ localStorage.setItem("signupData"+user.id, JSON.stringify(user)); }
					catch(e){}
					//localStorage.setItem("signupData", JSON.stringify(user)); //temp version for testing
					
					if(noLogin){return;}
					else{
						//console.log('pre-auth');
						return fbAuth.$signInWithEmailAndPassword(user.email,password);
					}
				})
			}

		})
		.then(function(){ //fill in firebase data
			//console.log('post-auth callback');
			var accountShell = firebaseAccountHelpers.createShell(user.id);
			var accountId;
			for(var key in accountShell){
				if(user.email.match(/@testing\.touchplan\.io/)){ accountShell[key].tier = "testing"; }
				accountId = accountShell[key].id;
			}
			
			//fill in default user data...
			user.userCreationDate = fbdbTime.raw.$value;
			user.userValidatedDate = fbdbTime.raw.$value;
			user.accountId = accountId;
			user.hasNotCopiedDefault = true;
			user.hasNotCreatedFree = false;
			if(!user.method){user.method = invitedProjectList ? 'invite' : 'self register';}
			
			var userShell = atomicAssembler.segment('users/'+user.id, user);
			var emailShell = atomicAssembler.segment('emailDirectory/'+utils.escapeEmail(user.email), user.id);
			var result = atomicAssembler.assemble(accountShell, userShell, emailShell);
			var postPmAtomics = {};
			
			postPmAtomics["invitedUsers/emails/"+utils.escapeEmail(user.email)] = null;
			
			var projectsString = "";
			
			if(invitedProjectList){
				for(var key in invitedProjectList){
					postPmAtomics["invitedUsers/projects/"+key+"/"+utils.escapeEmail(user.email)] = null;
					if(projectsString !== ''){projectsString += ', ';}
					projectsString += fbConnection.fbRef('projects/'+key).toString();
				}
			}
			
			//console.log('result', result, invitedProjectList);
			
			return fbConnection.update(fbConnection.fbRef(), result)
			.then(function(){
				analyticsStuff(user, projectsString);
				return addProjectMembers(invitedProjectList, user.id);
			})
			.then(function(){
				return fbConnection.update(fbConnection.fbRef(), postPmAtomics);
			})
			.then(function(){
				return addViaCode(user);
			})
		})
		//...and some clean up that needs to always run
		.then(function(a){
			authServiceHelpers.flags.creationInProgress = false;
			return user;
		})
		.catch(function(e){
			authServiceHelpers.flags.creationInProgress = false;
			return $q.reject(e);
		})

	}
	
	return {
		getUsers: firebaseUsersGetters.getUsers,
		getUsersSync: firebaseUsersGetters.getUsersSync,
		userFromUserId: firebaseUsersGetters.userFromUserId,
		create: create,
		canCreate: canCreate,
		passwordReset: passwordReset
	};
}]);
