
import { InjectionToken } from "@angular/core";
import { Point, Rectangle, Container, Graphics, DisplayObject } from "pixi.js";
import { default as PaperPoint } from "js/lib/math/Point";
import { analyticsService } from "ng2/common/utils/AnalyticsService";
import { first } from "rxjs/operators";

import { DragAtEdge } from "ng2/common/models/DragAtEdge";

import { Camera } from "ng2/canvas-renderer/core";

import { PlanState } from "ng2/common/services/plan-state.service";
import { EventBinder } from "./interaction-utils";
import { Component } from "ng2/canvas-renderer/component-system/";
import { ComponentInterface } from "ng2/canvas-renderer/interfaces/";
import * as utils from "utils";

export const SelectionBoxId = new InjectionToken('selectionBox');

export class SelectionBox extends Component implements ComponentInterface{
	private _binder: EventBinder;
	private graphics:Graphics;
	private parent: Container;
	
	private dragAtEdge: DragAtEdge;
	
	started = false;
	
	private startPoint: Point;
	
	constructor(){super();}
	
	setup(planState: PlanState, parent: Container){
		this.planState = planState;
		this.parent = parent;
		this.graphics = new Graphics();
		this._binder = new EventBinder(parent);
		
		this._binder.add('hammer-press', evt => this.start(evt.center));
		this._binder.add('hammer-holddragstart', evt => this.startDrag(evt.center));
		this._binder.add('hammer-holddrag', evt => this.drag(evt.center));
		this._binder.add('hammer-holddragend', evt => this.end(evt.center));
		this._binder.add('hammer-pressup', evt => this.end(evt.center));
		
	}
	
	
	start(point: Point){
		this.started = true;
		this.startPoint = point;
		this.parent.addChild(this.graphics);
		
		var camera = this.getParent().camera;
		this.dragAtEdge = new DragAtEdge(this.planState, new Rectangle(0, 0, window.innerWidth, window.innerHeight));
		this.dragAtEdge.change$.subscribe((map)=>{
			var revisedPoint = map.shiftedPoint;
			let z = camera.getScale().x;
			
			this.startPoint.x -= map.deltaX;
			this.startPoint.y -= map.deltaY;
			
			this.drag(map.lastPoint, true);

			this.planState.scopeSoup.hud.onTranslate(new PaperPoint(-map.deltaX, -map.deltaY));
		});
	}
	startDrag(point: Point){
		if(this.started){
			//remove plan
		}
		else{
			//remove this one
		}
	}
	
	drag(point: Point, noDragAtEdge?: boolean){
		if(!this.started){return;}
		if(!noDragAtEdge){ this.dragAtEdge.update(point); }
		
		this.graphics.clear();
		let left = Math.min(this.startPoint.x, point.x);
		let right = Math.max(this.startPoint.x, point.x);
		let top = Math.min(this.startPoint.y, point.y);
		let bottom = Math.max(this.startPoint.y, point.y);
		
		this.getParent().run();
		this.graphics.beginFill(0x9C9C9C);
		this.graphics.fillAlpha = 0.5;
		this.graphics.lineStyle(2, 0x616161);
		//it's _really_ absurd that drawRect doesn't accept a rectangle argument...
		this.graphics.drawRect(left, top, right - left, bottom - top);
	}
	
	end(point: Point){
		if(!this.started){return;}
		this.dragAtEdge.cancel();
		
		let left = Math.min(this.startPoint.x, point.x);
		let right = Math.max(this.startPoint.x, point.x);
		let top = Math.min(this.startPoint.y, point.y);
		let bottom = Math.max(this.startPoint.y, point.y);
		let rect = new Rectangle(left, top, right - left, bottom - top);
		
		this.addToSelection(rect);
		
		this.started = false;
		this.graphics.clear();
		this.parent.removeChild(this.graphics);
	}
	
	addToSelection(rect:Rectangle){
		var camera: Camera = this.getParent().camera;
		let worldRect: Rectangle = new Rectangle(
			camera.screenToRealX(rect.x),
			camera.screenToRealY(rect.y),
			rect.width / camera.scale.x,
			rect.height / camera.scale.y
		);
		var hitList = [];
		// _super_ lazy check, paste in the old shit
		this.planState.tickets.list$.pipe(first()).subscribe(tickets => {
			tickets.forEach((ticket)=>{
				if(ticket.hidden || this.planState.accessCheck.isReadOnly() || !this.planState.accessCheck.hasAccessToTicket2(ticket)){return;}
				var tRect = new Rectangle(ticket.view.left, ticket.view.top, ticket.view.width, ticket.view.height);
				if(utils.overlapRect(worldRect, tRect)){
					hitList.push(ticket);
				}
			})
		});
		if(hitList.length){
			this.planState.actions.selectTicket(hitList);
			let planId = this.planState.route.snapshot.paramMap.get("planId");
			analyticsService.multiselectRegion(hitList.length, planId)
		}
	}
	
	destroy(){
		this._binder.destroy();
		this.graphics.destroy();
		this.planState = null;
		this.parent = null;
	}
}
