import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppState } from "../../../services/app-state.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../../../js/services/auth-service";

@Component({
	selector: 'nav-bar-dashboard',
	template: require('./nav-bar-dashboard.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarDashboardComponent implements OnInit {
	constructor(public appState: AppState, private router: Router, private authService: AuthService) {}

	ngOnInit() {
	}
	
	public goToProfile(): void {
		this.router.navigate(["account", "profile"]);
	}
	
	public logout(): void {
		this.authService.logout();
	}
}
