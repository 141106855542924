'use strict';

import * as angular from "angular";

angular.module('ticketspaceApp')
.factory("fbMonitoring", ["$log", "fbConnection", "$firebaseAuth", function($log, fbConnection, $firebaseAuth) {
	var signal = fbConnection.fbObject('signals/reload');
	signal.$loaded().then(function(){
		signal.$watch(function(){
			window.location.reload(true);
		});
	});
	
	var usersRef = fbConnection.fbRef('signals/users');
	var subPath = 'reload';
	var currentUid;
	var currentUser;
	var unwatchFn;
	
	function updateUid(user){
		if(unwatchFn){ unwatchFn(); }
		if(currentUser && currentUser.$destroy){ currentUser.$destroy(); currentUser = null; currentUid = null; }
		
		if(user){
			currentUid = user.uid;
			currentUser = fbConnection.fbObject(usersRef.child(user.uid).child(subPath));
			currentUser.$loaded().then(function(){
				unwatchFn = currentUser.$watch(function(){
					window.location.reload(true);
				});
			})
		}
		
	}
	$firebaseAuth().$onAuthStateChanged(updateUid);
	
	
	return {
	
	};
}]);
	
