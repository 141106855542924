import { utils, Container } from "pixi.js";
import { ComponentInterface } from "../interfaces/";
import { LevelOfDetail, Camera } from "../core/";
import {LEVEL_OF_DETAIL_SIGNALS, LEVEL_OF_DETAIL_TABLE, LEVEL_OF_DETAIL_TABLE_STRINGS} from "../core/LevelOfDetail";
import {Detach} from "../graphics/Detachable";
/**
 * The IDynamicComponentObject interface sets up the structure for dynamic components. These are components which are created
 * and destroyed during a LevelOfDetail change.
 */
export interface IDynamicComponentObject {
	creator: any;
	layer: string;
}
/**
 * The IDynamicComponent interface manages the IDynamicComponentObjects.
 */
export interface IDynamicComponent {
	[key: string]: IDynamicComponentObject[];
}
/**
 * Contains all of the events that the ComponentManager will dispatch
 */
enum COMPONENT_MANAGER_EVENTS{
	"ADDED" = "added",
	"REMOVED" = "removed",
}
/**
 *
 */
export class ComponentManager{
	public dynamicComponents: IDynamicComponent;
	public activeComponents: Map<string, ComponentInterface[]> = new Map();
	private _dynamicCache: Map<any, any> = new Map();
	
	public active: boolean = true;
	/**
	 * New instance of EventEmitter3. ComponentManager will use this to dispatch events.
	 * Other things can listen for events and respond accordingly.
	 */
	public emitter: utils.EventEmitter = new utils.EventEmitter();
	/**
	 * Contains the _thing_ where this instance of the ComponentManager is attached to.
	 */
	public parent: any;
	/**
	 * Stores a list of components currently add to this component manager
	 */
	private _components: Map<string, ComponentInterface> = new Map();
	/**
	 * Stores a reference to the event name. This is used for clean-up later.
	 */
	private _updateDataEventName: string;
	/**
	 * Reference to the emitter which dispatches the update data.
	 */
	private _updateDataEmitter: utils.EventEmitter;
	
	constructor(parent: any){
		this.parent = parent;
		LevelOfDetail.emitter.on(LEVEL_OF_DETAIL_SIGNALS.FORCE_LOD, this.onForceLOD);
		LevelOfDetail.emitter.on(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.lodChanged);
	}
	
	public lodChanged = (newLod: number) => {
		// Detach/Attach components
		if (!this.activeComponents || LevelOfDetail.isForced){return}
		this.activeComponents.forEach((thing: any, oldLod: string) => {
			if (thing.visible){
				//this.attach(thing);
			} else {
				//this.detach(thing);
			}
		});
	};
	
	public onForceLOD = (newLod: number, oldLod: number) => {
		if (this.dynamicComponents){
			for (let lod in LEVEL_OF_DETAIL_TABLE_STRINGS) {
				this._handleComponentCreation(lod);
			}
		}
	};
	
	//TODO: Come back to this.
	public check(newLod: number = LevelOfDetail.getLODLevel(), oldLod: number = LevelOfDetail.getLastLODLevel()){
		if (!this.parent.renderable){
			this._handleComponentDestruction();
			return
		}
		
		const lod: string = LevelOfDetail.getLOD();
		if (!this.dynamicComponents || !this.dynamicComponents[lod]){return}
		
		this._handleComponentCreation(lod);
		
		this._handleComponentDestruction();
	}
	
	private _handleComponentDestruction(): void {
		if (!this.activeComponents || LevelOfDetail.isForced){return}
		// We need the keys to compare the LOD level, so do this ridiculousness
		this.activeComponents.forEach((__, oldLod: string) => {
			let currentLodValue: number = LevelOfDetail.getLODLevel();
			let lodValue: number = LEVEL_OF_DETAIL_TABLE[oldLod];
			if (currentLodValue < lodValue){
				this.componentDestroyer(this.activeComponents.get(oldLod));
			}
		});
	}
	
	private _handleComponentCreation(lod: string): void {
		const creatorList: IDynamicComponentObject[] = this.dynamicComponents[lod];
		if (!creatorList){this._handleOtherLODLevels(); return}
		creatorList.forEach((dynamicComponent: IDynamicComponentObject) => {
			const _previousThing = this._dynamicCache.get(dynamicComponent.creator);
			if (_previousThing){
				_previousThing.visible = true;
			} else {
				let newThing = this.componentCreator(dynamicComponent.creator, dynamicComponent.layer);
				this.activeComponents.get(lod).push(newThing);
				this._dynamicCache.set(dynamicComponent.creator, newThing);
			}
		});
		
		this._handleOtherLODLevels();
	}
	
	private _handleOtherLODLevels(){
		if (!this.dynamicComponents){return}
		this.activeComponents.forEach((__, lod: string) => {
			let currentLodValue: number = LevelOfDetail.getLODLevel();
			let lodValue: number = LEVEL_OF_DETAIL_TABLE[lod];
			if (currentLodValue >= lodValue){
				const creatorList: IDynamicComponentObject[] = this.dynamicComponents[lod];
				creatorList.forEach((dynamicComponent) => {
					const oldThing = this._dynamicCache.get(dynamicComponent.creator);
					if (oldThing && this.parent.visible && this.parent.renderable){
						oldThing.visible = true;
						return
					} else if (!oldThing){
						let newThing = this.componentCreator(dynamicComponent.creator, dynamicComponent.layer);
						this.activeComponents.get(lod).push(newThing);
						this._dynamicCache.set(dynamicComponent.creator, newThing);
					}
				});
			}
		});
	}
	
	public addDynamicComponents(components: IDynamicComponent){
		this.dynamicComponents = components;
		for (let key in components){
			this.activeComponents.set(key, []);
		}
		
		this.check();
	}
	
	public attach(thing: Detach): void {
		if (thing.attach){
			thing.attach();
		}
	}
	
	public detach(thing: Detach): void {
		if (thing.detach){
			thing.detach();
		}
	}
	
	public componentCreator(thing: any, layer: string) {
		const t: any = thing();
		const layerContainer: Container = this.parent[layer];
		layerContainer.addChild(t);
		return t;
	}
	
	public componentDestroyer(things: any[]){
		things.forEach((thing: any) => {
			thing.visible = false;
			// if (thing.createOnce){
			// 	thing.visible = false;
			// } else{
			// 	thing.destroy({
			// 		children: true,
			// 		texture: true,
			// 		baseTexture: false
			// 	});
			// 	thing = null;
			// }
		});
	}
	/**
	 * Use this to add a component to the ComponentManager. Works like this:
	 *
	 * Pass in an instance of your component. When added, the ComponentManager will decorate the 'manager' and 'managerParent'
	 * properties with the component manager and the ting the component manager is attached to.
	 *
	 * @param {string} componentName
	 * @param {ComponentInterface} component
	 * @param args
	 */
	public add(componentName: string, component: ComponentInterface, ...args: Array<any>){
		if (this.get(componentName)){console.log("Component already exists!", componentName, component, this.getParent()); return}
		// Decorate properties on component
		component.managerParent = this.getParent();
		component.manager = this;
		component.active = this.active;
		// Track the new component
		this._components.set(componentName, component);
		// Call component setup
		if (component.setup){
			component.setup(...args);
		}

		if (!this.active){
			component.visible = false;
		} else {
			if (component.lodChanged) {
				component.lodChanged(LevelOfDetail.getLODLevel(), LevelOfDetail.getLastLODLevel());
			}
		}

		// Emit added event
		this.emitter.emit(COMPONENT_MANAGER_EVENTS.ADDED, component);
		
		return this
	}
	/**
	 * Use this to remove a component from the ComponentManager
	 * @param {string} componentName
	 */
	public remove(componentName: string){
		if (!this.get(componentName)){return}
		this._components.delete(componentName);
		this.emitter.emit(COMPONENT_MANAGER_EVENTS.REMOVED);
		return this;
	}
	/**
	 * Use this to retrieve a component from the component manager map
	 * @param {string} componentName
	 */
	public get(componentName: string): any{
		return this._components.get(componentName);
	}
	/**
	 * Use this to clean-up the updateData update loop
	 */
	public clearUpdateDataEvent(){
		if (!this._updateDataEventName){return}
		this._updateDataEmitter.off(this._updateDataEventName);
		this._updateDataEventName = null;
		this._updateDataEmitter = null;
	}
	/**
	 * This is called when the parent is destroyed. This should clear up all references and destroy things.
	 */
	public destroy(options?){
		this.clearUpdateDataEvent();
		this._components.forEach((component) => {
			if (component.destroy){ component.destroy(options); }
		});
		this._components = null;
		this.activeComponents.forEach(comp => {
			comp.forEach(thing => {
				if(thing.destroy){ thing.destroy(options); }
			});
		});
		this.activeComponents = null;
		this.emitter.removeAllListeners();
		
		LevelOfDetail.emitter.off(LEVEL_OF_DETAIL_SIGNALS.FORCE_LOD, this.onForceLOD);
		
		this.parent = null;
		this.emitter = null;
	}
	/**
	 * Returns the parent where the ComponentManager is attached to.
	 */
	public getParent(){
		return this.parent;
	}
	/**
	 * Called during each update loop
	 * @param args
	 */
	public update(...args: Array<any>){
		this.check();
		if (this.parent.renderable){
			if (this._components.size >= 1 && this.active){
				this._components.forEach((component) => {
					if (component.managerParent.visible && component.managerParent.renderable && component.active && (component.visible && component.renderable && component.update) || component.alwaysActive){
						component.update(...args);
					}
				})
			}
		}
	}
	/**
	 * Only called if the updateData update loop is enabled.
	 * @param args
	 */
	public updateData(...args: Array<any>){
		if (this._components.size >= 1 && this.active){
			this._components.forEach((component) => {
				if (component.updateData){
					component.updateData(...args);
				}
			})
		}
	}
}
