'use strict';

export default function Constraint(item){
	this.id = item.id || '';
	this.prettyId = item.prettyId || '';
	this.activityName = item.activityName || '';
	this.roleName = item.roleName || '';
	this.dateRequired = item.dateRequired || '';
	this.lastDateRequired = item.lastDateRequired || '';
	this.datePromised = item.datePromised || '';
	this.isComplete = (item.isComplete === undefined ? '' : item.isComplete);
	this.roleId = item.roleId || '';
	this.locationId = item.locationId || null;
	this.actualFinish = item.actualFinish || null;
	this.responsibleIndividual = item.responsibleIndividual || null;
	this.createdAt = item.createdAt || null;
	this.notes = item.notes || null;
	
	if(item.crewSize)
		this.crewSize = item.crewSize;
	if(item.durationDays)
		this.durationDays = item.durationDays;
	if(item.left !== undefined)
		this.left = item.left;
	if(item.top !== undefined)
		this.top = item.top;
		
		
	if(item.predecessors)
		this.predecessors = item.predecessors;
	if(item.successors)
		this.successors = item.successors;
	
	if(item.subtasks)
		this.subtasks = item.subtasks;
	
}

var Cons: any = Constraint;
Cons.globals = function(scope)
{
	this.scope = scope;
};


Cons.insert = function(){ console.info("NYI") };
Cons.update = function(){ console.info("NYI") };
Cons.remove = function(){ console.info("NYI") };

Constraint.prototype.update = function(){ console.info("NYI") }
Constraint.prototype.remote = function(){ console.info("NYI") }
