'use strict';
import * as angular from "angular";

//### firebaseVirtualWrapper
//defines an object intended to be used in an object.create call
//which provides a set of core methods to be used throughout all
//of the angularfire objects
angular.module('ticketspaceApp')
.factory('firebaseVirtualWrapper', ["$q", "$timeout", "fbConnection", function($q, $timeout, fbConnection) {
	
	return {
		
		//#### setup(config)
		//sets up some keys at the prototype level (by default)
		//* addKey - sets this.idKey to the value provided or the default 'id'. 
		//Correspondeds to the internal "id" key that we've typically added.
		setup: function setup(config){
			
			this.idKey = config && config.addKey ? config.addKey : 'id';
			
		},
		
		//#### init()
		//provides some initilization capabilities.
		init: function init(refUrl, factory){
			function constructClass(constructor, args) {
				function ReturnConstructor(): void {
					return constructor.apply(this, args);
				}
				ReturnConstructor.prototype = constructor.prototype;
				return new ReturnConstructor();
			}

			this.editIndex = 0;
			this.temp = {};
			var args = [].slice.call(arguments).splice(1);
			args[0] = fbConnection.fbRef(refUrl);
			this.fb = constructClass(factory, args);
			return this.fb;
		},
		
		//#### edit(id)
		// manages the generic edit state
		// * id - the id being edited
		// returns something of a 3 state system
		// * returns -1 if editing the new cell
		// * returns the object if the object is valid
		// * returns null (and cleans up) on error
		edit: function edit(id){
			this.editIndex = id;
			this.temp = {};
			if(id === -1){ return id; }
			var val = this.selected(id);
			if(val === null){
				this.editIndex = 0;
				return null;
			}
			return val;
		},
		
		//#### finishEdit()
		// concludes the edit process
		finishEdit: function finishEdit(){
			var promise = this.save();
			this.clearEdit();

			return promise;
		},
		
		//#### save(data)
		// handles saving the generic data
		// * data[optional] - the data to be saved to firebase, defaults to
		//this.temp if not set. The object is added verbatium if new, or
		//it's children are individually mapped to the firebaseItem.
		// * returns a promise that resolves to the id
		save: function save(data){
			var defer = $q.defer();
			
			var self = this;
			if(!data){ data = this.temp; }
			if(this.editIndex === -1){
				console.log('adding a new role');
				this.fb.$add(data).then(function(ref){
					console.log('add callback part 1', ref.key);
					ref.child(self.idKey).set(ref.key, function(){
						console.log('add id callback');
						$timeout(function(){
							console.log('inside the $timeout');
							defer.resolve(ref.key);
						});
					});
				});
			}
			else{
				var fbItem = this.selected();
				if(!fbItem){defer.reject('serious problem'); return defer.promise; }
				
				for(var key in data){
					fbItem.data[key] = (data[key] !== undefined) ? data[key] : null;
				}
				this.fb.$save(fbItem).then(function(){
					defer.resolve(fbItem.$id);
				});
			}
			return defer.promise;
		},
		
		//####deleteEdit(noClear)
		//deletes the editItem
		//* noClear - bool, if true don't clear the edit
		deleteEdit: function deleteEdit(noClear){
			var defer = $q.defer();
			var editIndex = this.editIndex;

			var val = this.fb.$getRecord(this.editIndex);
			if(val) {
				this.fb.$remove(val).then(function (something) {
					defer.resolve(val);
				});
			} else {
				defer.reject(editIndex);
			}
			
			if(!noClear){ this.clearEdit(); }

			return defer.promise;
		},
		
		//####clearEdit()
		//resets the edit back to it's default state
		clearEdit: function clearEdit(){
			this.editIndex = 0;
			this.temp = null;
		},
		//####selected(id)
		//returns the firebase item
		//* id[optional] - the id to check, defaults to this.editIndex
		selected: function selected(id){
			if(!id){ id = this.editIndex; }
			return this.fb.$getRecord(id);
		},
		//#### isEditing(id)
		// returns a bool indicating if the id is the one currently being edited
		// makes it so editIndex === id calls don't need to be littered all over the html
		isEditing: function isEditing(id){
			return id && id === this.editIndex;
		},
		//#### isNew()
		// returns a bool indicating if the new cell is being edited
		// makes it so editIndex === -1 calls don't need to be littered all over the html
		isNew: function isNew(){
			return this.editIndex === -1;
		}
	};
}]);
