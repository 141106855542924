import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {CalendarModule} from 'primeng/primeng';
import {DropdownModule} from 'primeng/primeng';
import {MenuModule} from 'primeng/primeng';

import { TicketEditService } from 'ng2/ticket-edit/ticket-edit.service';
import { TicketEditServiceTemplater } from 'ng2/ticket-edit/ticket-edit-templater.service';

import { EditWrapperComponent } from 'ng2/ticket-edit/edit-wrapper.component';
import { EditType } from 'ng2/ticket-edit/edit-type.directive';

import { TaskEditComponent } from 'ng2/ticket-edit/components/task-edit.component';
import { ConstraintEditComponent } from 'ng2/ticket-edit/components/constraint-edit.component';
import { MilestoneEditComponent } from 'ng2/ticket-edit/components/milestone-edit.component';
import { MultiEditComponent } from 'ng2/ticket-edit/components/multi-edit.component';

import { MultiEditThumbnail } from 'ng2/ticket-edit/components/multi-edit-thumbnail.component'; 
import { MultiEditMasthead } from 'ng2/ticket-edit/components/multi-edit-masthead.component'; 
import { TicketThumbnailComponent } from 'ng2/ticket-edit/components/ticket-thumbnail.component'; 
import { TicketActionMenuComponent } from 'ng2/ticket-edit/components/ticket-action-menu.component';
import { TicketRoleComponent } from 'ng2/ticket-edit/components/ticket-role.component';
import { TicketLocationComponent } from 'ng2/ticket-edit/components/ticket-location.component';
import { TicketIconsComponent } from 'ng2/ticket-edit/components/ticket-icons.component';
import { TicketMessageComponent } from 'ng2/ticket-edit/components/ticket-message.component';
import { TicketDescriptionComponent } from 'ng2/ticket-edit/components/ticket-description.component';
import { TicketFooterComponent } from 'ng2/ticket-edit/components/ticket-footer.component'; 
import { TicketCalendarComponent } from 'ng2/ticket-edit/components/ticket-calendar.component';
import { TicketCrewComponent } from 'ng2/ticket-edit/components/ticket-crew.component';
import { TicketDurationComponent } from 'ng2/ticket-edit/components/ticket-duration.component';


import {DropdownSelectModule} from "../dropdown-select/dropdown-select.module";

@NgModule({
	imports: [
		CommonModule,
		//FormsModule],
		FormsModule,
		ReactiveFormsModule,
		CalendarModule,
		DropdownModule,
		MenuModule,
		DropdownSelectModule],
	providers: [TicketEditService, TicketEditServiceTemplater],
	declarations: [EditWrapperComponent,
				   EditType,
				   TaskEditComponent,
				   ConstraintEditComponent,
				   MilestoneEditComponent,
				   MultiEditComponent,
				   MultiEditThumbnail,
					 MultiEditMasthead,
				   TicketThumbnailComponent,
				   TicketActionMenuComponent,
				   TicketRoleComponent,
				   TicketLocationComponent,
				   TicketIconsComponent,
				   TicketMessageComponent,
				   TicketDescriptionComponent,
				   TicketFooterComponent,
				   TicketCalendarComponent,
				   TicketCrewComponent,
				   TicketDurationComponent],
	entryComponents: [EditWrapperComponent, TaskEditComponent, ConstraintEditComponent, MilestoneEditComponent,MultiEditComponent]
})
export class TicketEditModule{}
