import { NgModule } from "@angular/core";

import { AngularFireDatabaseModule } from '@angular/fire/database'

import {
	RoleListFactory,
	TicketListFactory,
	LocationListFactory,
	ProjectMemberListFactory,
	PriorityListFactory,
	VarianceListFactory,
	SwimlaneListFactory,
	PullColumnListFactory,
	TimelineTicketListPlaceholderFactory,
	TimelineTicketListFactory,
	LaborCountListFactory
} from 'ng2/fancy-firebase/factories';
import { FancyFragmenterFactory } from 'ng2/fancy-firebase/base';

@NgModule({
	imports: [
		AngularFireDatabaseModule
	],
	providers: [
		FancyFragmenterFactory,
		TicketListFactory,
		RoleListFactory,
		LocationListFactory,
		ProjectMemberListFactory,
		PriorityListFactory,
		VarianceListFactory,
		SwimlaneListFactory,
		PullColumnListFactory,
		TimelineTicketListPlaceholderFactory,
		TimelineTicketListFactory,
		LaborCountListFactory
	],
})
export class FancyFirebaseModule{
	
}
