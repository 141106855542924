'use strict';
import * as moment from "moment";
import * as angular from "angular";
/**
 * @namespace GridText
 * @returns CanvasWrapper.GridText
 * @memberOf CanvasWrapper.Grid
 * @constructor
 */
angular.module('CanvasWrapper').factory('GridText', GridTextFactory);

GridTextFactory.$inject = ["PIXI"];
function GridTextFactory(PIXI){
	function Text(parentGrid, textNode, textSettings){
		this._hlabelIdx = 0;

		var _textCache = [];
		var _lastDrawCacheIndex = 0;
		var _lastXDraw = -9999999;
		var _lastScale;

		this.draw = function(x, y, date, scaleFactor, viewport){
			if (viewport.getScale() === viewport.zoom.max || viewport.getScale() === viewport.zoom.min){scaleFactor = _lastScale;}
			if (x <= _lastXDraw){_lastDrawCacheIndex = 0;} // Reset round robin draw order.
			else if (_lastDrawCacheIndex >= _textCache.length) {addToPool();} // Our pool is exhausted, add a new object to it.
			_textCache[_lastDrawCacheIndex].text = date;
			_textCache[_lastDrawCacheIndex].x = (x * scaleFactor) - (_textCache[_lastDrawCacheIndex].width /2);
			_textCache[_lastDrawCacheIndex].y = y || 4;
			if (!_textCache[_lastDrawCacheIndex].visible){_textCache[_lastDrawCacheIndex].visible = true;}
			_lastDrawCacheIndex++;
			_lastXDraw = x;
			_lastScale = scaleFactor;
		};

		function addToPool(){
			_textCache.push(new PIXI.Text("", textSettings));
			textNode.addChild(_textCache[_textCache.length-1]);
		}

		this.getDateText = function(x, format){
			return moment().add(x, 'days').format(format);
		};

		this.drawHorizontalLabels = function(y, hticks, scale, dateFormat, viewport){
			if (!hticks){return}
			for (var i = hticks.minTickLevel; i <= hticks.maxTickLevel; ++i) {
				var ratio = hticks.tickRatios[i];
				if (ratio >= 0.8) {
					var ticks = hticks.ticksAtLevel(i,true);
					for (var j = 0; j < ticks.length; ++j) {
						var screen_x = _snapPixel(parentGrid.valueToPixelH(ticks[j]));
						this.createLabel(screen_x, y, scale, dateFormat, viewport);
					}
				}
			}
			this.hideUnusedLabels(_lastDrawCacheIndex, _textCache.length);
		};

		this.hideUnusedLabels = function(start, end){
			for (var i = start; i < end; i++){
				if (_textCache[i]){_textCache[i].visible = false;}
			}
		};

		this.createLabel = function(x, y, scale, format, viewport){
			y = y || null;
			format = format || "MMM DD YYYY";
			this.draw(x, y, this.getDateText(x, format), scale, viewport);
		};

		this.getDateText = function(x, format){
			return moment().add(x, 'days').format(format);
		}
	}

	function _snapPixel(p){
		return Math.floor(p);
	}

	return Text
}
