
'use strict';
import * as angular from "angular";

angular.module('ticketspaceApp')
.factory('hubSpotThings', hubSpotThings)

hubSpotThings.$inject = ["$q", "fbdbRequests"];
function hubSpotThings($q, fbdbRequests){
	//ahh javascript...
	return {
		submitForm: submitForm
	}
	
	
	function submitForm (user){
		// This breaks in E2E tests...
		if (!user.firstName || !user){ return $q.rejectErr("user undefined"); }
		return fbdbRequests.hubSpot.submitFormData(
			"fefd7ca3-e778-47ae-ac69-f3a029d1e9d5",
			"Sign-Up",
			user.email,
			{
				"firstname": user.firstName,
				"lastname": user.lastName,
				"company": user.company,
				"phone": user.phone,
				"jobtitle": user.title,
				"method": user.method
			}
		).then(function(suc){
			console.log(suc);
		}).catch(function(error){
			console.log(error);
			//rethrow
			return $q.reject(error);
		});
	};
	
}
