import * as angular from "angular";
/**
 * @namespace GridTicks
 * @returns CanvasWrapper.GridTicks
 * @memberOf CanvasWrapper.Grid
 * @constructor
 */
angular.module('CanvasWrapper').factory('GridTicks', CanvasGridTicks);

function CanvasGridTicks(){
	function GridTicks(){
		this.ticks = [];
		this.tickLods = [];
		this.tickRatios = [];

		this.minScale = 0.1;
		this.maxScale = 1000.0;

		this.minValueScale = 1.0;
		this.maxValueScale = 1.0;

		this.pixelRange = 500;

		this.minSpacing = 20;
		this.maxSpacing = 80;

		this.initTicks = function(lods, min, max){
			if (min <= 0) {min = 1;}
			if (max <= 0) {max = 1;}
			if (max < min) {max = min;}

			this.tickLods = lods;
			this.minScale = min;
			this.maxScale = max;

			// generate ticks
			this.ticks = [];

			var curTick = 1.0;
			var curIdx = 0;

			this.ticks.push(curTick);

			var minScale = min;
			var maxScale = max;
			var maxTickValue = 1;
			var minTickValue = 1;

			while ( curTick * this.tickLods[curIdx] <= maxScale ) {
				curTick = curTick *  this.tickLods[curIdx];
				curIdx = curIdx + 1 > this.tickLods.length-1 ? 0 : curIdx + 1;
				this.ticks.push(curTick);

				maxTickValue = curTick;
			}

			this.minValueScale = 1.0/maxTickValue * 100;

			curIdx = this.tickLods.length-1;
			curTick = 1.0;
			while ( curTick / this.tickLods[curIdx] >= minScale ) {
				curTick = curTick / this.tickLods[curIdx];
				curIdx = curIdx - 1 < 0 ? this.tickLods.length-1 : curIdx - 1;
				this.ticks.unshift(curTick);
				minTickValue = curTick;
			}

			this.maxValueScale = 1.0/minTickValue * 100;
			return this;
		};

		this.spacing = function(min, max){
			this.minSpacing = min;
			this.maxSpacing = max;
			return this;
		};

		this.range = function(minValue, maxValue, pixelRange){
			this.minValue = fround(Math.min(minValue,maxValue));
			this.maxValue = fround(Math.max(minValue,maxValue));
			this.pixelRange = pixelRange;

			this.minTickLevel = 0;
			this.maxTickLevel = this.ticks.length-1;

			for (var i = this.ticks.length-1; i >= 0; --i) {
				var ratio = this.ticks[i] * this.pixelRange / (this.maxValue - this.minValue);
				this.tickRatios[i] = (ratio - this.minSpacing) / (this.maxSpacing - this.minSpacing);
				if ( this.tickRatios[i] >= 1.0 ) {
					this.maxTickLevel = i;
				}
				if ( ratio <= this.minSpacing ) {
					this.minTickLevel = i;
					break;
				}
			}

			for (var j = this.minTickLevel; j <= this.maxTickLevel; ++j ) {
				this.tickRatios[j] = clamp01(this.tickRatios[j]);
			}

			return this;
		};

		this.ticksAtLevel = function(level, excludeHigherLevel){
			var results = [];
			var tick = this.ticks[level];
			var start = Math.floor( this.minValue / tick );
			var end = Math.ceil( this.maxValue / tick );
			for (var i = start; i <= end; ++i) {
				if (!excludeHigherLevel || level >= this.maxTickLevel || i % Math.round(this.ticks[level+1] / tick) !== 0 ) {
					results.push(i * tick);
				}
			}
			return results;
		};

		this.levelForStep = function(step){
			for (var i = 0; i < this.ticks.length; ++i) {
				var ratio = this.ticks[i] * this.pixelRange / (this.maxValue - this.minValue);
				if ( ratio >= step ) {
					return i;
				}
			}
			return -1;
		};
	}
	/**
	 * @function clamp01
	 * @desc Clamps a number between 0 and 1
	 * @param num {Number}
	 * @memberOf CanvasWrapper.GridTicks
	 * @returns {Number}
	 */
	function clamp01(num){
		return clamp(num, 0, 1);
	}

	var fround = (function (array) {
		return function(x) {
			return array[0] = x, array[0];
		};
	})(new Float32Array(1)); // Float32Array is supported with IE10.
	/**
	 * @param {Number} num The number to clamp
	 * @param {Number} min The lower boundary of the output range
	 * @param {Number} max The upper boundary of the output range
	 * @returns A number in the range [min, max]
	 * @type Number
	 * @memberOf CanvasWrapper.GridTicks
	 * @private
	 */
	function clamp(num, min, max){
		return Math.min(max,Math.max(min, num));
	}

	return GridTicks
}