import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { TICKET_DATE_TEXT_SETTINGS } from "./TICKET_TEXT_SETTINGS";
import { TicketTypes, Ticket } from "../../common/models/";
import { TicketUtils } from "../utils/";
import { Side } from "../../common/models/";
import { TicketTextCanvas } from "./";
import { TextStyleOptions } from "pixi.js";
import { dateCache } from "../../common/date-cache";

export class TicketDateTextCanvas extends TicketTextCanvas {
	
	private _liveStart: string;
	private _liveFinish: string;
	private _lastLiveStart: string;
	private _lastLiveFinish: string;
	private _unsubUpdate: any;
	
	constructor(planState: PlanState, ticketContainer: TicketContainer, text: string = "", style: TextStyleOptions = {wordWrap: false, fill: "#ffffff"}, canvas?: HTMLCanvasElement) {
		super(text, style, planState, ticketContainer, canvas);
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this._unsubUpdate = ticketContainer.camera.changed$.subscribe(this.update);
		
		this.onChanged(ticketContainer);
	}
	
	public update = () => {
		if (!this.ticket.visible || !this.ticket.renderable){return}
		this.onChanged(this.ticket);
	};
	
	public destroy(options?): void {
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this._unsubUpdate.unsubscribe();
		super.destroy(options);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data = ticketContainer.getData();
		this._liveStart = data.view.liveStart;
		this._liveFinish = data.view.liveFinish;
		this.renderable = (data.view.liveStart || data.view.liveFinish) && ticketContainer.renderable && this.visible;
		
		if (this.renderable){
			this.tint = this.ticket.liveRoleTextColor;
			
			this.applyOffsetAndDrawArea(ticketContainer, TICKET_DATE_TEXT_SETTINGS);
			const type = TicketUtils.getTicketType(data);
			if (type === TicketTypes.MILESTONE || type === TicketTypes.CONSTRAINT){
				this.style.align = "center";
				this.align.x = true;
			}
			this.x = this.maskArea.x;
			this.y = this.maskArea.y + this._getLocationHeightOffset(data);
			
			this.style.fontSize = (data.view.side === Side.PULL) ? 22 : 16;
			
			if (this._lastLiveFinish !== this._liveFinish || this._lastLiveStart !== this._liveStart){
				this._format();
			}
			
			this.positionCenter(data);
			
			this._lastLiveStart = this._liveStart;
			this._lastLiveFinish = this._liveFinish;
			
			// MAKE LESS VISIBLE
			if (data.view.isActualized){
				this.alpha = 0.5;
			} else {
				this.alpha = 1.0;
			}
		}
	};
	
	private _format(){
		let start = this._liveStart;
		let end = this._liveFinish;
		let dateToUse = (!start && end) ? end : start;
		let formatted = (start !== end && start) ? dateCache.dateJar(start, "MMM DD") + " - " + dateCache.dateJar(end, "MMM DD") : dateCache.dateJar(dateToUse, "MMM DD");
		
		this.setText(formatted);
	}
	
	private _getLocationHeightOffset(ticket: Ticket): number{
		let _offset: number = 0;
		if (ticket.view.location){
			_offset += (ticket.view.side === Side.PULL) ? 40 : 25;
		}
		
		return _offset;
	}
}