import { Texture, BaseTexture } from 'pixi.js';
import { CreateTextureInterface } from "../interfaces/";
import { RendererCache } from "./";
import { TICKET_BORDER_COLORS } from "../ticket-components/TicketSelected";

/**
 * This class renders the basic ticket shape. Also has caching ability. Don't create too many instances of this.
 */
export class TaskRenderer extends RendererCache{
	/**
	 * @param {boolean} nativeLines If true the lines will be draw using LINES instead of TRIANGLE_STRIP
	 * @param {CreateTextureInterface} options CreateTextureInterface
	 */
	constructor(nativeLines: boolean, options?: CreateTextureInterface){
		super(nativeLines);
		this.createTexture(options);
		this.createMeshes();
	}
	/**
	 * Generates a rectangular shape and returns a {@link PIXI.Texture}
	 * @param {CreateTextureInterface} options CreateTextureInterface
	 * @returns {PIXI.Texture}
	 */
	public createTexture(options: CreateTextureInterface = {}){
		let x = options.x || 0;
		let y = options.y || 0;
		let tAlpha = options.alpha || 1;
		let tWidth = options.width || 145;
		let tHeight = options.height || 135;
		let tColor = (options.color) ? parseInt(options.color.substring(1), 16) : 0xFFFFFF;
		let tLineColor = (options.outlineColor) ? parseInt(options.outlineColor.substring(1), 16) : 0xFFFFFF;
		let tLineWidth = options.outlineWidth || 0;
		let tLineAlpha = options.outlineAlpha || 1;
		// Clear drawing buffer
		this.clear();
		// Draw the ticket shape
		this.lineStyle(tLineWidth, tLineColor, tLineAlpha);
		this.beginFill(tColor, tAlpha);
		this.drawRect(x, y, tWidth, tHeight);
		this.endFill();
		let t = this.generateCanvasTexture(options.scaleMode, options.resolution);
		// Cache texture
		this.cacheTexture("ticket", t);
		
		this.generateRoundedTexture();
		
		return this;
	}
	/**
	 * This generates the ticket mesh containing pre-baked shadows, and the ticket outline mesh. Much more efficient doing this than it is applying shadows or an outline as a post-processing effect, since shadows are very expensive.
	 * @returns CanvasRenderer.TaskRenderer
	 */
	public createMeshes(){
		// Create and cache the ticket shadow mesh
		this.cacheTexture("ticketShadowMesh", this.generateTicketShadowMesh());
		// We need to generate the task outline in all of the different colors we need. This is because PIXI.Mesh.tint does not support
		// the CanvasRenderer....
		// See: https://github.com/pixijs/pixi.js/issues/3862
		this.cacheTexture("ticketOutlineMesh", this.generateTicketOutlineMesh());
		this.cacheTexture("ticketOutlineSearchMesh", this.generateTicketOutlineMesh(TICKET_BORDER_COLORS.SEARCH));
		this.cacheTexture("ticketOutlineDependencyMesh", this.generateTicketOutlineMesh(TICKET_BORDER_COLORS.DEPENDENCY));
		this.cacheTexture("ticketOutlineSelectedMesh", this.generateTicketOutlineMesh(TICKET_BORDER_COLORS.SELECTED));
		this.cacheTexture("ticketOutlineErrorMesh", this.generateTicketOutlineMesh(TICKET_BORDER_COLORS.OUT_OF_SEQUENCE));
		return this
	}
	/**
	 * Generates a mesh representing the ticket shadow.
	 * @returns {PIXI.Texture}
	 */
	private generateTicketShadowMesh(){
		this._scratchContext.save();
		// Clear scratch canvas
		this._scratchContext.clearRect(0, 0, this._scratchCanvas.width, this._scratchCanvas.height);
		// Size the scratch canvas a bit larger than ticket size to account for the shadow drawing
		this._scratchCanvas.width = 190;
		this._scratchCanvas.height = 180;
		
		// Create the task size
		this._scratchContext.rect(10, 10, 147, 137);
		
		this._scratchContext.fillStyle = 'white';
		this._scratchContext.shadowColor = "#141414"; // because Davina doesn't like black
		this._scratchContext.shadowBlur = 10;
		this._scratchContext.shadowOffsetX = 0;
		this._scratchContext.shadowOffsetY = 2;
		this._scratchContext.fill();
		
		// Generate a texture
		return new Texture(new BaseTexture(this.getCanvasTexture()));
	}
	
	/**
	 * Generate the rounded texture for Task.isLongest
	 */
	public generateRoundedTexture(): Texture {
		this.clear();
		this.beginFill(0xffffff, 1);
		this.drawRoundedRect(0, 0, 60, 35, 10);
		this.endFill();
		
		let t = this.generateCanvasTexture();
		this.cacheTexture("isLongestTexture", t);
		return new Texture(new BaseTexture(this.getCanvasTexture()));
	}
	/**
	 * Generates a mesh representing the ticket outline.
	 * @return {PIXI.Texture}
	 */
	private generateTicketOutlineMesh(color = "white", lineWidth = 30){
		// Clear scratch canvas
		this._scratchContext.save();
		this._scratchContext.clearRect(0, 0, this._scratchCanvas.width, this._scratchCanvas.height);
		// Resize our scratch canvas
		this._scratchCanvas.width = 145;
		this._scratchCanvas.height = 135;
		// Draw stroke outline. We draw it as white, since we will be tinting this mesh to any color later.
		this._scratchContext.fillStyle = color;
		this._scratchContext.lineWidth = lineWidth;
		this._scratchContext.fillRect(0,0,145,135);
		this._scratchContext.restore();
		// Generate a texture
		return new Texture(new BaseTexture(this.getCanvasTexture()));
	}
}
