import * as utils from "utils";
// const getParameterNames = require('get-parameter-names');

function camelToSpace(text: string){
	var result = text.replace( /([A-Z])/g, " $1" );
	return result.toLowerCase();
}

export function genAnalyticsBoilerplate(funcArgs?){
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor){
		let origFunction = descriptor.value;
		// let funcArgs:Array<string> = getParameterNames(origFunction);
		// console.log('genAnalyticsBoilerplate', funcArgs, target, propertyKey, descriptor, origFunction );
		descriptor.value = function(...args){
			let baseValues = origFunction.apply(this, args) || {};
			let obj = {};
			funcArgs.forEach((key, idx) => {
				if(args[idx] !== undefined){ obj[key] = args[idx]; }
			});
			Object.assign(baseValues, obj);
			
			let spaceyName = camelToSpace(propertyKey);
			// console.log('spaceyName', spaceyName, "baseValues", baseValues);
			
			utils.analyticsHelper.track(spaceyName, baseValues);
		}
	}
}
