export const titleFields = [
	{label: "Owner", value: "Owner"},
	{label: "Project Executive", value: "Project Executive"},
	{label: "Project Manager", value: "Project Manager"},
	{label: "Assistant PM", value: "Assistant PM"},
	{label: "Superintendent", value: "Superintendent"},
	{label: "Assistant Super", value: "Assistant Super"},
	{label: "Project Engineer", value: "Project Engineer"},
	{label: "Foreman", value: "Foreman"},
	{label: "Trade", value: "Trade"},
	{label: "Other", value: "Other"},
];