const environment = {
	firebase: {
		apiKey: MB4_PREPROCESSOR_CONFIG.FBAPIKEY,
		authDomain: MB4_PREPROCESSOR_CONFIG.FBAUTHDOMAIN,
		databaseURL: MB4_PREPROCESSOR_CONFIG.FBHOST,
		projectId: MB4_PREPROCESSOR_CONFIG.FBAPP,
		storageBucket: MB4_PREPROCESSOR_CONFIG.FBSTORAGEBUCKET
	},
	refUrl: MB4_PREPROCESSOR_CONFIG.FBPATH,
	developerMode: MB4_PREPROCESSOR_CONFIG.ENABLE_DEV_MODE,
	
}
export default environment;
