'use strict';

import * as angular from "angular";

angular.module('ProjectView').factory('Timeline', ProjectViewTimelineFactory);

ProjectViewTimelineFactory.$inject = ["RenderLine", "GeometricShape", "projectViewConfig", "Grid", "GridText"];
function ProjectViewTimelineFactory(RenderLine, GeometricShape, projectViewConfig, Grid, GridText){
	/**
	 * @namespace ProjectView.ProjectViewTimeline
	 * @param bgNode {PIXI.Container} The parent node where the timeline background will be added as a child.
	 * @param lineNode {PIXI.Container} The parent node where the timeline lines will be added as a child.
	 * @param textNode {PIXI.Container} The parent node where the timeline text will be added as a child.
	 * @param viewport {CanvasWrapper.Viewport} Instance of the viewport controlling the render.
	 * @param timelineOptions {ProjectView.TimelineOptions} New options object containing the valid TimelineOption settings.
	 * @memberOf ProjectView
	 * @constructor
	 * @returns {ProjectView.ProjectViewTimeline}
	 */
	function ProjectViewTimeline(bgNode, lineNode, textNode, viewport, timelineOptions){
		this.grid = new Grid(lineNode);
		this.gridText = new GridText(this.grid, textNode, projectViewConfig.TIMELINE_TEXT);
		this.grid.setScaleH([2,3.5], 0.001, 500, 60, 80);
		this._lastScale = null;
		// Private
		var background = new GeometricShape();
		var timelineHeight = timelineOptions.HEIGHT || 24;
		activate();

		function activate(){
			bgNode.addChild(background.get());
		}

		this.draw = function(w, h, scale){
			if (this._lastScale && (viewport.getScale() === viewport.zoom.max || viewport.getScale() === viewport.zoom.min)){scale = this._lastScale;}
			if (viewport.zoom.scale <= 0.15){textNode.visible = false;}
			else if (viewport.zoom.scale > 0.15 && !textNode.visible){textNode.visible = true;}
			var rightEdge = viewport.screenToRealX(w);
			var leftEdge = viewport.screenToRealX(0);
			this.grid.drawHorizontalLines(leftEdge, rightEdge);
			this.gridText.drawHorizontalLabels(4, this.grid.hticks, scale, projectViewConfig.TIMELINE_TEXT.DATE_FORMAT, viewport);
			background.clear();
			background.drawRect(w, timelineHeight, timelineOptions.BACKGROUND_COLOR, timelineOptions.BACKGROUND_ALPHA);
			this._lastScale = scale;
		};

		this.update = function(w, h, scale){
			this.grid.resize(w, h);
			this.draw(w, h, scale);
		};
	}

	return ProjectViewTimeline
}
