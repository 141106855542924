import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Side } from "../../common/models/Side";
import { Ticket } from "../../common/models/Ticket";
import { FancyText } from "../fontpath/";
import { HighResolutionText } from "../text/HighResolutionText";
import { rendererInfo } from "../core/RendererInfo";

export class TicketCrewSize extends TicketComponentHelper implements ComponentInterface {
	private _value: FancyText|HighResolutionText;
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		
		this.width = 44;
		this.height = 35;
		
		// Fall back to Canvas if WebGL is not available
		if (rendererInfo.isWebGL()){
			this._value = new FancyText("Roboto");
			this._value.active = true;
			this._value.visible = true;
			
			this._value.x = this.texture.width + 17;
			//this._value.y = -6;
			this._value.fontSize = 96; // 32px * 3x scale factor = 96;
		} else {
			this._value = new HighResolutionText("");
			this._value.active = true;
			this._value.visible = true;
			
			this._value.x = this.texture.width + 20;
			this._value.style.fontSize = 96; // 32px * 3x scale factor = 96;
		}
		
		this.addChild(this._value);
		
		// Offset 15 pixels from the left edge
		this.x = 15;
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this.onChanged(ticketContainer, null);
	}
	
	private onChanged = (ticketContainer: TicketContainer, old) => {
		const data: Ticket = ticketContainer.getData();
		this.renderable = data.view.side === Side.PULL;
		if (this.renderable){
			this.tint = ticketContainer.liveRoleTextColor;
			
			this._value.text = "" + data.view.liveCrewSize;
			this._value.tint = ticketContainer.liveRoleTextColor;
			if (this._value.dirty && data.view.liveCrewSize){
				this._value.calculateText();
			}
			
			// padding-bottom: 20px
			// Align to bottom-left, then offset the height and 20
			this.y = data.view.height - this.height - 10;
		}
	};
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}