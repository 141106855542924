import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from "angular";


//-----------------------------------------------------------
// Steps for adding a component to ticketspace.html
// - create the component
// - import here
// - add to NgModule
// - add to angular.module
// Misc notes:
// - the downgraded component is externally considered to be in angularjs land
// -- this means that angular directives will need to be downgraded, angularjs directives
//    "should" be usable
// - however, use angular argument syntax for input/outputs: [name]="adsfasd"
//-----------------------------------------------------------


import {DependencyPromptComponent} from './dependency-prompt.component';



//the angular base
@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		DependencyPromptComponent
	],
	entryComponents: [
		DependencyPromptComponent
	],
	providers: [
		
	]
})
export class UpgradeShorthandModule {

}


angular.module('ticketspaceApp')
	.directive(DependencyPromptComponent.oldAngularName, downgradeComponent({component: DependencyPromptComponent}) as angular.IDirectiveFactory)
