import { utils, Point, Graphics, Sprite, Container } from "pixi.js";
import { combineLatest, Subject } from "rxjs";
import { first } from "rxjs/operators";

import { TICKET_TEXTURE_OPTIONS } from "../tickets/";
import { SpritePool } from "ng2/common/utils/GenericPool";
import { Line } from "ng2/common/models/Line";
import { PlanState } from "ng2/common/services/plan-state.service";

import { RenderDependencies } from "../core/RenderDependencies";
import {Camera } from "../core/Camera";

export class DependencyLines{
	private makeBoom = [];
	private onViewportChanged;
	constructor(private planState: PlanState, private cameraContainer: Container, private camera: Camera, private safeRender: ()=>void){
		var graphics = new Graphics();
		graphics.lineAlignment = 0.5;
		var timelineGraphics = new Graphics();
		var arrowHeadContainer = new Container();
		
		var arrowHeadMaker = ()=>{
			let spirit = new Sprite(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_DEPENDENCY_ARROWHEAD]);
			spirit.anchor.set(0.5, 0.15);
			spirit.scale.set(0.5);
			return spirit;
		}
		
		var arrowheadPool1 = new SpritePool(arrowHeadMaker, arrowHeadContainer);
		var arrowheadPool2 = new SpritePool(arrowHeadMaker, arrowHeadContainer);
		
		cameraContainer.addChild(graphics);
		cameraContainer.addChild(timelineGraphics);
		cameraContainer.addChild(arrowHeadContainer);
		//now cache and listen for camera events
		let lastTimelineLines = [];
		
		//wait for the first tick of loaded
		combineLatest(RenderDependencies.loaded$.pipe(first()), planState.dependencies.line$)
		.subscribe((temp)=>{
			
			let lines = temp[1];
			// console.log('lines', lines);
			let simpleLines = [];
			lastTimelineLines = [];
			
			lines.forEach((line)=>{
				if(line.start.yIsScreen || line.end.yIsScreen){ lastTimelineLines.push(line); }
				else{ simpleLines.push(line); }
			});
			this.renderDependencyLines(simpleLines, graphics, arrowheadPool1);
			this.renderDependencyLines(lastTimelineLines, timelineGraphics, arrowheadPool2);
			// this.render();
			this.safeRender();
		})
		
		this.onViewportChanged = () => {
			this.renderDependencyLines(lastTimelineLines, timelineGraphics, arrowheadPool2);
			
		};
		
		camera.on('viewportChanged', this.onViewportChanged);
		
		this.makeBoom.push(graphics, timelineGraphics, arrowHeadContainer, arrowheadPool1, arrowheadPool2);
	}
	
	private renderDependencyLines(lines:Array<Line>, graphics: Graphics, pool: SpritePool){
		// let timelineLines = [];
		
		graphics.clear();
		pool.reset();
		
		lines.forEach((line)=>{
			let hasShifted = line.start.yIsScreen || line.end.yIsScreen;
			let shiftedStart = line.start.yIsScreen ? new Point(line.start.x, this.camera.screenToRealY(line.start.y)) : line.start;
			let shiftedEnd = line.end.yIsScreen ? new Point(line.end.x, this.camera.screenToRealY(line.end.y)) : line.end;
			
			graphics.lineStyle(10, line.color);
			graphics.moveTo(shiftedStart.x, shiftedStart.y);
			graphics.lineTo(shiftedEnd.x, shiftedEnd.y);
			
			var arrowAngle = this.getArrowHeadAngle(shiftedStart, shiftedEnd);
			var arrowSprite = pool.grab();
			arrowSprite.x = shiftedEnd.x;
			arrowSprite.y = shiftedEnd.y;
			arrowSprite.tint = line.color;
			arrowSprite.rotation = arrowAngle;
		})
		// this.render();
		
		// return timelineLines;
	}
	
	private getArrowHeadAngle(startPoint:Point, endPoint: Point){
		var dx = endPoint.x - startPoint.x;
		var dy = endPoint.y - startPoint.y;
		var norm = Math.sqrt(dx*dx + dy*dy);
		
		var ndx = dx/norm;
		var ndy = dy/norm;
		
		var baseAngle = Math.atan2(ndx, ndy);
		return Math.PI - baseAngle;
	}
	
	public destroy(){
		window.perf.log("start destroy dependencies");
		this.makeBoom.forEach(thing => thing.destroy ? thing.destroy() : null);
		this.makeBoom = null;
		this.camera.off('viewportChanged', this.onViewportChanged);
		this.camera = null;
		window.perf.log("end destroy dependencies", {previousEvent: "start destroy dependencies"});
	}
}
