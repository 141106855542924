import { BehaviorSubject, Subject, ConnectableObservable, merge, combineLatest } from "rxjs";
import { tap, map, mapTo, scan, filter, multicast, sample, takeUntil} from "rxjs/operators";

import * as strings from "ng2/common/strings";

import {renderQueue} from "ng2/common/RenderQueue";

import { Point } from "pixi.js";
import deepEquals = require("fast-deep-equal");
import * as deepmerge from 'deepmerge';

import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import {FancyTicketAction, TicketActionTypes} from "ng2/actions/ticket-actions";
import { Ticket, Side } from "ng2/common/models";
import { Line } from "ng2/common/models/Line";
import {TicketList} from "ng2/fancy-firebase/factories";
import {LinkMap} from "ng2/common/utils/link-map";

import { MILESTONE_SIZE_OFFSET2 } from "ng2/canvas-renderer/interfaces/milestone-size.interface";


//misc notes
//- there's no particular reason more lists couldn't be supported, just need to
//differentiate between normal lists and assembled lists

export enum DependencyModeTypes{
	all = "all",
	allStandard = "all-target",
	allSpecial = "all-special",
	standard = "standard",
	hidden = "hidden"
}

export enum DependencyWeight{
	primary = "primary",
	secondary = "secondary"
}

export enum DependencyKeys{
	predecessors = "predecessors",
	successors = "successors"
}

export interface DependencyMode{
	type: DependencyModeTypes;
	isAllType: boolean;
	target?: string;
	depth?: number;
}

interface DependencyData{
	isCyclic: boolean,
	isOutOfOrder: boolean
}

interface DependencyLink{
	id: string,
	line?: Line,
	dependencyData?: DependencyData,
	lastData?: any,
	ticket?: Ticket
}

interface LastTicketData{
	predecessors: any,
	successors: any,
	top: number,
	left: number,
	width: number,
	height: number
}

interface TicketCache{
	ticket: Ticket,
	prevData?: LastTicketData
}
interface DelayedInitialization{
	ticket: Ticket,
	predData: DependencyData
}

interface PlannedFancyTicketAction extends FancyTicketAction{
	planId?: string;
}

type DependencyTree = Map<string, Map<string, DependencyLink>>;



const defaultDependencyData = {
	isCyclic: false,
	isOutOfOrder: false
}
const errorLineColor = 0xC32A3C;
const greyLineColor = 0xcccccc;

export class Dependencies{
	//map vs object... hard to say
	// start with maps?
	private predecessorTree:DependencyTree = new Map();
	private successorTree:DependencyTree = new Map();
	private destroyer$ = new Subject();
	
	private ticketCache: Map<string, TicketCache> = new Map();
	/** The tickets that need lines drawn to tickets that haven't yet loaded. Indexed by the ticket it's waiting for. The value however is for the ticket doing the waiting  */
	private waitingForInitialization: Map<string, DelayedInitialization> = new Map();
	
	public mode = new BehaviorSubject<DependencyMode>({type: DependencyModeTypes.all, isAllType: true});
	public syncMode: DependencyMode;
	
	public line$: ConnectableObservable<Array<Line>>;
	
	constructor(
		private tickets:TicketList,
		private timelineTickets:TicketList,
		private currentPlanId: string)
	{
		this.setup();
	}
	
	setup(){
		let base$ = merge(
			this.tickets.rawEvent$.pipe(map(action => {(action as PlannedFancyTicketAction).planId = this.currentPlanId; return action})), 
			this.timelineTickets.rawEvent$
		)
		.pipe(
			//stifle that infinite loop
			filter(action => action.type !== TicketActionTypes.updateDependencyTarget),
			filter(action => {
				return this.tickets.isRemoveAction(action) || this.hasDataChanged(action.payload);
			}),
			//use the tap to build up the cache
			tap((action:PlannedFancyTicketAction)=>{
				//special handling for timeline tickets in the same plan as the current one
				//single line version
				if(action.payload.isTimelineTicket && action.payload.timelineTicketSplitId[0] === this.currentPlanId){ return;  } //skip this shit
				// console.log(action.payload.view.activityName, action.key);
				
				if(this.tickets.isRemoveAction(action)){
					//restore the "predecessor awaiting a successor state", mainly related to timeline tickets
					if(this.successorTree.has(action.key)){
						this.successorTree.get(action.key).forEach(predLink =>{
							let predData = this.predecessorTree.get(predLink.id).get(action.key);
							this.waitingForInitialization.set(action.key, {ticket: predLink.ticket, predData: predData.dependencyData});
						});
					}
					if(action.key === this.syncMode.target){ this.setModeToOff(); }
					this.removeWholeList(action.key, this.predecessorTree, this.successorTree);
					this.removeWholeList(action.key, this.successorTree, this.predecessorTree);
				}
				//adds and updates shouldn't be notably different
				else{
					this.ticketCache.set(action.key, {ticket: action.payload});
					
					if(action.payload.view.predecessors){
						if(!this.predecessorTree.has(action.key)){ this.predecessorTree.set(action.key, new Map()); }
						let actionTree = this.predecessorTree.get(action.key);
						
						//add stuff
						for(var planId in action.payload.view.predecessors){
							//skip timeline tickets referring to different plans MOC-3134
							if(this.specialIsTimelineTicketCheck(action.payload) && planId !== this.currentPlanId){ continue; }
							
							for(var ticketId in action.payload.view.predecessors[planId]){
								var revisedId;
								var otherPlanId = planId+","+ticketId;
								if(planId === this.currentPlanId){ revisedId = ticketId; }
								else{ revisedId = otherPlanId}
								
								let predData = action.payload.view.predecessors[planId][ticketId];
								//connect things
								if(this.ticketCache.has(revisedId)){
									let endTicket = this.ticketCache.get(revisedId).ticket;
									this.populateLink(action.payload, endTicket, predData);
								}
								else{
									this.waitingForInitialization.set(ticketId, {ticket: action.payload, predData: predData});
								}
								
								//special handling for timeline tickets in the same plan as the current one
								//double line version
								// if(this.currentPlanId === planId){
								// 	if(this.ticketCache.has(otherPlanId)){
								// 		let endTicket = this.ticketCache.get(otherPlanId).ticket;
								// 		this.populateLink(action.payload, endTicket, predData);
								// 	}
								// 	else{
								// 		this.waitingForInitialization.set(otherPlanId, {ticket: action.payload, predData: predData});
								// 	}
								// }
								// single line version
								if(this.currentPlanId === planId){
								
								}
								//end special handling
								
							}
						}
					}
					//fix up loading order things
					if(this.waitingForInitialization.has(action.key)){
						let delayed = this.waitingForInitialization.get(action.key);
						if(!this.predecessorTree.has(delayed.ticket.$id) || !this.predecessorTree.get(delayed.ticket.$id).has(action.key)){
							this.populateLink(delayed.ticket, action.payload, delayed.predData);
							this.waitingForInitialization.delete(action.key);
						}
					}
					//remove Stuff
					if(this.predecessorTree.has(action.key)){
						this.predecessorTree.get(action.key).forEach((t, successorId, theMap)=>{
							let successorTuple = this.getPlanFromCombinedId(successorId);
							if(!utils.checkNestedOrNull(action.payload.view, "predecessors", successorTuple[0], successorTuple[1])){
								theMap.delete(successorId);
								//now remove the successor
								let tree = this.successorTree.get(successorId);
								if(tree){ tree.delete(action.key); }
							}
						})
					}
				}
			}),
			// filter(action => this.predecessorTree.has(action.key)), //TODO - this is probably incorrect, consider this a placeholder?
			map((action)=>{
				// this.predecessorTree.forEach((subMap, ticketId)=>{
				// 	let baseTicket = this.ticketCache.get(ticketId);
				// 	subMap.forEach((dep, subTicketId)=>{
				//
				// 	})
				// })
				var srcTicket = action.payload;
				this.setLine(srcTicket, action.key);

				
				return this.predecessorTree;
			})
		);
		
		//TODO - the mode and depenency graphs can now be combined and converted into the line list
		//TODO - the calculating which things are implicated and the lines should probably be isolated
		//and will need to be for the borders. Still haven't decided on the best method...
		let lineList$ = combineLatest(base$, this.mode).pipe(
			takeUntil(this.destroyer$),
			sample(renderQueue.ob$.pipe(takeUntil(this.destroyer$))),
			//we'll start by just building an array, may want to switch to a map?
			map((combination)=>{
				let mode = combination[1];
				let arr = [];
				//don't redraw all lines that are standard
				// let standardsInUse = {};
				
				if(mode.type === DependencyModeTypes.hidden){
					return arr;
				}
				let predLinks:LinkMap, succLinks:LinkMap;
				if(mode.type === DependencyModeTypes.standard || mode.type === DependencyModeTypes.allStandard){
					predLinks = slideDownTree(mode.target, this.predecessorTree).found;
					predLinks.forEachy((predKey, succKey)=>{
						let pred = this.predecessorTree.get(predKey).get(succKey);
						if(pred.ticket.hidden){return;}
						arr.push(pred.line);
					});
					
					succLinks = slideDownTree(mode.target, this.successorTree).found;
					succLinks.forEachy((predKey, succKey)=>{
						let pred = this.predecessorTree.get(predKey).get(succKey);
						if(pred.ticket.hidden){return;}
						arr.push(pred.line);
						
					}, true);
				}
				
				if(mode.type === DependencyModeTypes.allStandard || mode.type === DependencyModeTypes.all || mode.type === DependencyModeTypes.allSpecial){
					combination[0].forEach((subMap, startTicketId)=>{
						subMap.forEach((end, endTicketId)=>{
							let start = this.ticketCache.get(startTicketId)
							let line;
							if((predLinks && predLinks.has(startTicketId, endTicketId)) || (succLinks && succLinks.has(endTicketId, startTicketId))){
								line = end.line;
							}
							// if(startTicketId === mode.target || endTicketId === mode.target){
							// 	line = end.line;
							// }
							else{
								//render all colors
								// if(mode.type === DependencyModeTypes.allSpecial){
								if(mode.type === DependencyModeTypes.allSpecial || end.line.color === errorLineColor){
									line = end.line;
								}
								//this keeps the original data stable, but comes at a garbage collection cost
								else{
									line = Line.clone(end.line);
									line.color = greyLineColor;
								}
								if(!end.ticket.hidden && !start.ticket.hidden){
									arr.push(line);
								}
							}
						})
					})
				}
				

				return arr;
			})
		);
		
		var subject = new Subject<Array<Line>>();
		this.line$ = lineList$.pipe(multicast(()=> new Subject())) as ConnectableObservable<Array<Line>>;
		this.line$.connect();
		
		
		
		this.mode.pipe(takeUntil(this.destroyer$)).subscribe((mode)=>{
			console.log('modey', mode);
			//ignore the first tick. This will be a problem if we want to init the app with a target
			if(!this.syncMode){ this.syncMode = mode; return; }
			
			if(mode.target !== this.syncMode.target){
				if(this.syncMode.target){
					let combined = this.getPlanFromCombinedId(this.syncMode.target);
					let id = combined[0] === this.currentPlanId ? combined[1] : this.syncMode.target;
					
					let ticket = this.ticketCache.get(id);
					let ticketList = ticket.ticket.isTimelineTicket ? this.timelineTickets : this.tickets;
					ticketList.appEvent$.next({
						key: id,
						payload : ticket,
						type: TicketActionTypes.updateDependencyTarget,
						weightyness: undefined
					} as any);
				}
				if(mode.target){
					let combined = this.getPlanFromCombinedId(mode.target);
					let id = combined[0] === this.currentPlanId ? combined[1] : mode.target;
					let ticket = this.ticketCache.get(id);
					let ticketList = ticket.ticket.isTimelineTicket ? this.timelineTickets : this.tickets;
					ticketList.appEvent$.next({
						key: id,
						payload : ticket,
						type: TicketActionTypes.updateDependencyTarget,
						weightyness: DependencyWeight.primary
					} as any);
				}
			}
			this.syncMode = mode;
		})
	}
	
	private populateLink(srcTicket: Ticket, targetTicket: Ticket, predData: any){
		if(!this.predecessorTree.has(srcTicket.$id)){ this.predecessorTree.set(srcTicket.$id, new Map()); }
		this.predecessorTree.get(srcTicket.$id).set(targetTicket.$id, this.makeLink(targetTicket, predData));
		
		if(!this.successorTree.has(targetTicket.$id)){ this.successorTree.set(targetTicket.$id, new Map()); }
		this.successorTree.get(targetTicket.$id).set(srcTicket.$id, this.makeLink(srcTicket))
	}
	
	public checkTicketCache(ticketId: string){
		//yet more special handling for same plan timeline tickets
		let splitId = ticketId.split(',');
		if(splitId.length > 1 && splitId[0] === this.currentPlanId){
			ticketId = splitId[1];
		}
		
		let cachey = this.ticketCache.get(ticketId);
		return cachey ? cachey.ticket : null;
	}
	
	private setMode(type: DependencyModeTypes, target?: string, depth?: number){
		let allTypes = {
			[DependencyModeTypes.all]: DependencyModeTypes.all,
			[DependencyModeTypes.allSpecial]: DependencyModeTypes.allSpecial,
			[DependencyModeTypes.allStandard]: DependencyModeTypes.allStandard
		}
		this.mode.next({
			type: type,
			isAllType: !!allTypes[type],
			target: target,
			depth: depth
		})
	}
	public setModeToAll(){
		this.setMode(DependencyModeTypes.all);
	}
	public setModeToStandard(ticketId:string){
		this.setMode(DependencyModeTypes.standard, ticketId);
	}
	public setModeToAllStandard(ticketId:string){
		this.setMode(DependencyModeTypes.allStandard, ticketId);
	}
	public setModeToOff(){
		this.setMode(DependencyModeTypes.hidden);
	}
	public toggleTarget(target?:string){
		let turnOn = false;
		if(target !== this.syncMode.target){turnOn = true;}
		analyticsService.selectedDependencyTarget(!!this.syncMode.target, target, this.currentPlanId);
		if(this.syncMode.isAllType){
			this.setMode(turnOn ? DependencyModeTypes.allStandard : DependencyModeTypes.all, turnOn ? target : undefined);
		}
		else{
			this.setMode(turnOn ? DependencyModeTypes.standard : DependencyModeTypes.hidden, turnOn ? target : undefined);
		}
	}
	public toggleAll(force?:boolean){
		let turnOn = force !== undefined ? force : !this.syncMode.isAllType;
		analyticsService.toggledShowAllDependencies(turnOn);
		if(this.syncMode.target){
			this.setMode(turnOn ? DependencyModeTypes.allStandard : DependencyModeTypes.standard, this.syncMode.target);
		}
		else{
			this.setMode(turnOn ? DependencyModeTypes.all : DependencyModeTypes.hidden);
		}
	}
	
	private setLine(ticket: Ticket, ticketId: string){
		setLineForTree(ticket, ticketId, this.predecessorTree);
		if(this.successorTree.has(ticketId)){
			this.successorTree.get(ticketId).forEach((thing, otherTicketId)=>{
				setLineForTree(thing.ticket, otherTicketId, this.predecessorTree);
			})
		}
		// setLineForTree(ticket, ticketId, this.successorTree);
	}
	
	//TODO - this changed check may be better served by existing on the Ticket model
	//TODO - include a changed list in actions for the "local" events, assume a "full"
	//TODO - update for database events?
	private hasDataChanged(ticket:Ticket){
		if(!this.ticketCache.has(ticket.$id)){return true;}
		let prev = this.ticketCache.get(ticket.$id);
		//simple stuff
		return ["left", "top", "width", "height"].some(key => prev[key] !== ticket.view[key]) ||
			 this.hasCessorDataChanged(ticket, prev.ticket);
	}
	
	//TODO - implement
	private hasCessorDataChanged(ticket:Ticket, prev: Ticket){
		if((ticket.view.predecessors && !prev.view.predecessors) || (!ticket.view.predecessors && prev.view.predecessors)){return true;}
		//all else fails, deep equality check
		return deepEquals(ticket.view.predecessors, prev.view.predecessors);
	}
	
	/**
	 * Make a link
	 * @param  endTicket   The ticket that is being referenced, this.predecessorTree[startTicket] -> endTicket
	 * @return             [description]
	 */
	private makeLink(endTicket: Ticket, dependencyData?: DependencyData){
		return {
			id: endTicket.$id,
			ticket: endTicket,
			dependencyData: dependencyData || defaultDependencyData
		}
	}
	
	private removeWholeList(mainListKey: string, mainList:DependencyTree, referencedList: DependencyTree){
		var main = mainList.get(mainListKey);
		if(!main){return;} //removed thing that has no dependencies
		main.forEach((val, key)=>{
			let opposite = referencedList.get(key);
			if(opposite){
				opposite.delete(mainListKey);
				if(opposite.size === 0){ referencedList.delete(key); }
			}
			else{
				//broken dependency
			}
		})
		mainList.delete(mainListKey);
	}
	
	/** Returns a tuple, first arguement is the planId, the second is the ticketId 
		TODO - most likely this should be replaced with a cached planId value
	*/
	private getPlanFromCombinedId(combinedId: string){
		let brokenUp = combinedId.split(',');
		if(brokenUp.length === 2){ return brokenUp; }
		else{ return [this.currentPlanId, combinedId]}
	}
	
	/** debug thing */
	private dumpTree(){
		function build(tree){
			var obj = [];
			var maxLength = 0;
			tree.forEach((deeperTree, firstKey)=>{
				if(firstKey.length > maxLength){ maxLength = firstKey.length; }
			})
			
			tree.forEach((deeperTree, firstKey)=>{
				deeperTree.forEach((val, key)=>{
					obj.push(utils.pad(firstKey, maxLength, ' ') + ' -> ' + key)
				})
			})
			return obj;
		}

		return JSON.stringify( {
			predecessors: build(this.predecessorTree),
			successors: build(this.successorTree)
		}, null, 2)
	}
	
	
	//-------------------------------------------
	//- Writes
	//-------------------------------------------
	public addPredecessor(targetTicketId: string, srcTicketId?: string){
		if(!srcTicketId){ srcTicketId = this.syncMode.target; }
		if(!this.writeValidation(targetTicketId, srcTicketId)){return false;}

		let output = this._addPredecessor(targetTicketId, srcTicketId);
		return [output[0][srcTicketId], output[1][targetTicketId]];
	}
	
	private _addPredecessor(targetTicketId: string, srcTicketId: string){
		let currentMap = this.getTicketPredecessorLinks(srcTicketId);
		let depData = this.calculateLocalDependencyData(this.timelineSamePlanSafeCacheGet(targetTicketId).ticket, this.timelineSamePlanSafeCacheGet(srcTicketId).ticket);
		currentMap.set(srcTicketId, targetTicketId, depData);
		let succOut = this.addTempSuccessorStuff(currentMap, targetTicketId)
		succOut[targetTicketId].successors = this.decorateTimelineOutput(succOut[targetTicketId].successors, this.ticketCache.get(targetTicketId).ticket, "successors");
		
		let dirtyOut = this.createOutput(currentMap);
		dirtyOut[srcTicketId].predecessors = this.decorateTimelineOutput(dirtyOut[srcTicketId].predecessors, this.ticketCache.get(srcTicketId).ticket, "predecessors");
		
		let out = this.cleanupOutput(dirtyOut, srcTicketId);
		return [out, succOut];
	}
	
	
	
	public removePredecessor(targetTicketId: string, srcTicketId?: string){
		if(!srcTicketId){ srcTicketId = this.syncMode.target; }
		if(!this.writeValidation(targetTicketId, srcTicketId)){return false;}

		let output = this._removePredecessor(targetTicketId, srcTicketId);
		return [output[0][srcTicketId], output[1][targetTicketId]];
	}
	
	private _removePredecessor(targetTicketId: string, srcTicketId: string){
		let currentMap = this.getTicketPredecessorLinks(srcTicketId);
		// currentMap.delete(srcTicketId, targetTicketId);
		
		//normal remove
		currentMap.set(srcTicketId, targetTicketId, null);
		
		//special remove for timeline tickets on the same plan
		// only do this with the target initially since it's the one with the problem
		// it might be become a problem with src as well
		let combTarget = this.getPlanFromCombinedId(targetTicketId);
		if(combTarget[1] === targetTicketId){ //tapped plan ticket
			currentMap.set(srcTicketId, combTarget.join(','), null);
		}
		else{ //tapped timeline ticket
			currentMap.set(srcTicketId, combTarget[1], null);
		}
		//end special remove
		
		let succOut = this.addTempSuccessorStuff(currentMap, targetTicketId)
		succOut[targetTicketId].successors = this.decorateTimelineOutput(succOut[targetTicketId].successors, this.ticketCache.get(targetTicketId).ticket, "successors");
		
		//don't love this, might be better to change the output function
		let dirtyOut = this.createOutput(currentMap);
		dirtyOut[srcTicketId].predecessors = this.decorateTimelineOutput(dirtyOut[srcTicketId].predecessors, this.ticketCache.get(srcTicketId).ticket, "predecessors");
		let out = this.cleanupOutput(dirtyOut, srcTicketId);
		return [out, succOut];
	}

	//TODO - this is much less necessary now, trim or remove
	private cleanupOutput(dirtyOut:Object, ticketId: string, keyOverride = "predecessors"){
		let out:Object;
		if(dirtyOut[ticketId]){ out = dirtyOut; }
		else{
			out = {[ticketId]: {[keyOverride]: dirtyOut}};
		}
		return out;
	}
	
	//while we're still writing successors, return a tuple with the predecessor as the first argument and the successor as the second
	public togglePredecessor(targetTicketId: string, srcTicketId?: string){
		if(!srcTicketId){ srcTicketId = this.syncMode.target; }
		let srcTicket = this.timelineSamePlanSafeCacheGet(srcTicketId);
		let targetTicket = this.timelineSamePlanSafeCacheGet(targetTicketId);
		// debugger;
		if(this.predecessorTree.has(srcTicket.ticket.$id) && this.predecessorTree.get(srcTicket.ticket.$id).has(targetTicket.ticket.$id)){
			return this.removePredecessor(targetTicket.ticket.$id, srcTicket.ticket.$id);
		}
		else{
			return this.addPredecessor(targetTicket.ticket.$id, srcTicket.ticket.$id);
		}
	}
	
	private calculateLocalDependencyData(targetTicket: Ticket, srcTicket: Ticket){
		var datas = Object.assign({}, defaultDependencyData);
		if(targetTicket.view.liveFinish > srcTicket.view.liveStart){ datas.isOutOfOrder = true; }
		
		let newTree = copyDependencyTree(this.predecessorTree);
		if(!newTree.has(srcTicket.$id)){newTree.set(srcTicket.$id, new Map())}
		newTree.get(srcTicket.$id).set(targetTicket.$id, {id: targetTicket.$id});
		
		datas.isCyclic = slideDownTree(srcTicket.$id, newTree).isCyclical;
		return datas;
	}
	
	/** yet more special things to handle same plan timeline tickets */
	private timelineSamePlanSafeCacheGet(ticketId: string){
		let ticket = this.ticketCache.get(ticketId);
		if(!ticket){
			let splitId = ticketId.split(',');
			if(splitId.length > 1 && splitId[0] === this.currentPlanId){
				ticket = this.ticketCache.get(splitId[1]);
			}
		}
		return ticket;
	}
	
	/** returns false if validation has failed */
	private writeValidation(targetTicketId: string, srcTicketId: string){
		// let block = targetTicketId === srcTicketId
		// || this.ticketCache.get(targetTicketId).ticket.view.isActualized
		// || this.ticketCache.get(srcTicketId).ticket.view.isActualized
		// || (this.predecessorTree.has(targetTicketId) && this.predecessorTree.get(targetTicketId).has(srcTicketId))
		
		let errString;
		let blockSilent = false;
		let targetTicket = this.timelineSamePlanSafeCacheGet(targetTicketId);
		let srcTicket = this.timelineSamePlanSafeCacheGet(srcTicketId);
		
		if(targetTicketId === srcTicketId){
			blockSilent = true;
		}
		else if(targetTicket.ticket.view.isActualized){
			errString = strings.SET_DEPENDENCY_TO_ACTUAL(srcTicket.ticket.view.activityName);
		}
		else if(srcTicket.ticket.view.isActualized){
			errString = strings.SET_DEPENDENCY_FROM_ACTUAL;
		}
		else if((this.predecessorTree.has(targetTicketId) && this.predecessorTree.get(targetTicketId).has(srcTicketId))){
			errString = strings.SET_DEPENDENCY_TO_OPPOSITE(targetTicket.ticket.view.activityName);
		}
		
		if(errString){Logging.warning(errString);}
		// console.log('blockSilent', !blockSilent, !errString)
		return !blockSilent && !errString;
	}
	
	private addTempSuccessorStuff(dependencies: LinkMap<DependencyData>, srcTicketId:string){
		let out = this.createOutput(dependencies, true);
		return this.cleanupOutput(out, srcTicketId, "successors");
		// return out[srcTicketId] ? out[srcTicketId] : {successors: out};
	}
	
	private getTicketPredecessorLinks(ticketId: string){
		let links = new LinkMap<DependencyData>();
		let tree = this.predecessorTree.get(ticketId);
		if(tree){
			tree.forEach((dep, targetKey)=>{
				links.set(ticketId, targetKey, dep.dependencyData);
			})
		}
		return links;
	}
	
	private getTicketLinks(ticketId: string){
		let links = new LinkMap<DependencyData>();
		let tree = this.predecessorTree.get(ticketId);
		if(tree){
			tree.forEach((dep, targetKey)=>{
				links.set(ticketId, targetKey, dep.dependencyData);
			})
		}
		
		//refactor this
		let succTree = this.successorTree.get(ticketId);
		if(succTree){
			succTree.forEach((dep, targetKey)=>{
				this.predecessorTree.get(targetKey).forEach((dep2, targetKey2)=>{
					links.set(targetKey, targetKey2, dep.dependencyData);
				})
			})
		}
		
		return links;
	}
	
	public destroy(){
		this.destroyer$.next(true);
		this.mode.complete(); this.mode = null;
		this.ticketCache = null;
		this.predecessorTree = null;
		this.successorTree = null;
		this.waitingForInitialization = null;
		this.tickets = null;
		this.timelineTickets = null;
	}
	
	//writes: "srcId/predecessors/targetPlanId/targetTicketId"
	// returns an empty object when there's nothing to do
	private createOutput(dependencies: LinkMap<DependencyData>, shouldWriteSuccessors = false){
		let writeTree = shouldWriteSuccessors ? DependencyKeys.successors : DependencyKeys.predecessors;
		let output:any = {};
		
		dependencies.forEachy((src, target, depData)=>{
			let morphedTarget = this.getPlanFromCombinedId(target);
			
			//can calculate client side written isCyclic and isOutOfOrder here if desired
			utils.setNested(output, depData, src, writeTree, morphedTarget[0], morphedTarget[1]);
		}, shouldWriteSuccessors);
		return output;
	}
	
	
	public ticketHasDependencies(ticketId: string){
		return (this.predecessorTree.has(ticketId) && this.predecessorTree.get(ticketId).size > 0)
				|| (this.successorTree.has(ticketId) && this.successorTree.get(ticketId).size > 0)
	}
	
	//NEXT - add an action for unlinking/ figure out how to use this
	//NEXT - maybe just call it as part of the ticket drag end
	public unlinkTicket(ticketId: string, ignoreList?){
		let links = this.getTicketLinks(ticketId);
		//list of tickets with only their literal "ticketId", instead of timelineTicketId
		let ticketCache = new Map<string, Ticket>();

		links.forEachy((src, target, data)=>{
			if(src === ticketId){ links.set(src, target, null); }
			if(target === ticketId){ links.set(src, target, null); }
			
			[this.ticketCache.get(src), this.ticketCache.get(target)].forEach(ticket =>{
				ticketCache.set(ticket.ticket.$id, ticket.ticket);
			})
		});
		
		
		return {
			"writeData": [this.createOutput(links), this.createOutput(links, true)],
			"ticketCache": ticketCache
		}
	}
	
	private specialIsTimelineTicketCheck(ticket){
		return ticket.isTimelineTicket || this.timelineTickets._internalList.has(this.currentPlanId+','+ticket.$id);
	}
	
	private decorateTimelineOutput(output, ticket:Ticket, predKey:string){
		if(!ticket || !this.specialIsTimelineTicketCheck(ticket) || !ticket.view[predKey]){ return output; }
		if(!output){ return ticket.view[predKey]; }
		return deepmerge(ticket.view[predKey], output);
	}
	
}

//TODO - test this
function createExternalLinkMapForTimeline(ticketId: string, excludedPlanId: string, predOrSuccObj?: any){
	let linkMap = new LinkMap<Array<string>>();
	
	if(predOrSuccObj){
		Object.keys(predOrSuccObj).forEach(planId => {
			if(planId === excludedPlanId){ return; }
			Object.keys(predOrSuccObj[planId]).forEach(targetTicketId => {
				linkMap.set(ticketId, targetTicketId, [planId, targetTicketId]);
			});
		})
	}
	
	return linkMap;
}



function setLineForTree(ticket: Ticket, ticketId: string, tree: DependencyTree){
	if(tree.has(ticketId)){
		tree.get(ticketId).forEach((dep, ticketId)=>{
			
			// dep.line = getLineFromTickets(ticket, dep.ticket);
			dep.line = getLineFromTickets(dep.ticket, ticket);
			//timeline ticket shift
			if(dep.ticket.isTimelineTicket){
				dep.line.start.x = dep.ticket.timelineX;
				dep.line.start.yIsScreen = true;
				dep.line.start.y = 100;
			}
			if(ticket.isTimelineTicket){
				dep.line.end.x = ticket.timelineX;
				dep.line.end.yIsScreen = true;
				dep.line.end.y = 100;
			}
			
			if(dep.dependencyData && (dep.dependencyData.isCyclic || dep.dependencyData.isOutOfOrder)){
				dep.line.color = errorLineColor;
			}
		})
	}
}

function getLineFromTickets(startTicket: Ticket, endTicket: Ticket){
	let centeredStartPoint = new Point(startTicket.view.left + startTicket.view.width/2, startTicket.view.top + startTicket.view.height/2);
	let centeredEndPoint = new Point(endTicket.view.left + endTicket.view.width/2, endTicket.view.top + endTicket.view.height/2);
	
	if(endTicket.isTimelineTicket){
		centeredEndPoint.x = endTicket.timelineX;
		centeredEndPoint.y = -10000; //hack'n slash
	}
	if(startTicket.isTimelineTicket){
		centeredStartPoint.x = startTicket.timelineX;
		centeredStartPoint.y = -10000; //hack'n slash
	}
	// let startOffset = startTicket.view.type === "milestone" ? 10 : 0;
	// let endOffset = endTicket.view.type === "milestone" ? 30 : 10;
	let startOffset = 0;
	let endOffset = 10;
	
	if(startTicket.view.type === "milestone"){
		if(startTicket.view.side === Side.PULL){ startOffset += MILESTONE_SIZE_OFFSET2.PULL; }
		else{ startOffset += MILESTONE_SIZE_OFFSET2.ACTIVE; }
	}
	
	if(endTicket.view.type === "milestone"){
		if(endTicket.view.side === Side.PULL){ endOffset += MILESTONE_SIZE_OFFSET2.PULL; }
		else{ endOffset += MILESTONE_SIZE_OFFSET2.ACTIVE; }
	}
	
	let startAngle = utils.calcAngle(centeredStartPoint, centeredEndPoint);
	

	centeredStartPoint = utils.getCenterOffsetPoint(centeredStartPoint, centeredEndPoint, startTicket.view.width+startOffset, startTicket.view.height+startOffset, startTicket.view.type);
	centeredEndPoint = utils.getCenterOffsetPoint(centeredEndPoint, centeredStartPoint, endTicket.view.width + endOffset, endTicket.view.height + endOffset, endTicket.view.type);
	
	
	let endAngle = utils.calcAngle(centeredStartPoint, centeredEndPoint);
	if(!utils.essentiallyEquals(startAngle, endAngle)){
		return new Line(centeredEndPoint, centeredStartPoint);
	}

	return new Line(centeredStartPoint, centeredEndPoint);
}

function copyDependencyTree(tree: DependencyTree){
	let newTree:DependencyTree = new Map();
	tree.forEach((innerMap, key)=>{
		newTree.set(key, new Map(innerMap));
	})
	return newTree;
}

function slideDownTree(target: string, tree: DependencyTree, found?: LinkMap){
	let isCyclical = false;
	if(!found){ found = new LinkMap(); }
	if(!tree.has(target)){return { found, isCyclical: false }; }
	tree.get(target).forEach((link, subId)=>{
		if(found.has(target, subId)){
			isCyclical = true;
			return;
		}
		found.set(target, subId);
		isCyclical = slideDownTree(subId, tree, found).isCyclical || isCyclical;
	})
	return { found, isCyclical: isCyclical };
}
