'use strict';

import * as angular from "angular";

angular.module('ticketspaceApp')
//deprecating this factory, it's much too limited
.factory('subscriber', function() {
	
	//virtual class
	var virtualObserver = (function(){
		function observe(){
			for(var i = 0; i < this.observers.length; i++){
				this.observers[i].apply(undefined, arguments);
			}
		}
		
		function subscribe(fn){
			this.observers.push(fn);
			//unsubscribe function
			return function(){
				this.unsubscribe(fn);
			}
		}
		
		function unsubscribe(fn){
			for(var i = 0; i < this.observers.length; i++){
				if(this.observers[i] === fn){
					this.observers.splice(i,1);
					//console.log('removed one');
				}
			}
		}
		
		return {
			subscribe: subscribe,
			unsubscribe: unsubscribe,
			observe: observe
		}
	})();
	
	//#### create()
	// runs through the object.create flow so that a new object is created
	function create(ctx){
		var newObj = Object.create(virtualObserver);
		newObj.observers = [];
		return newObj;
	}

	return {
		create: create
	};
})

//todo - implement an off
// - on should support multiple types
.factory('Subscriber2', function() {
	
	function Subscriber(){
		this._observers = {'': []};
	}
	Subscriber.prototype = {
		//valid types:
		// - "aString",
		// - "space seperated list"
		// - nothing...
		"on": function on(type, cb){
			if(typeof type === "function"){cb = type;}
			if(!type || typeof type === "function"){this._observers[''].push(cb); return [];}
			type = type.trim();
			var typeList = type.split(' ');
			typeList.forEach(function(t){
				if(!this._observers[t]){ this._observers[t] = []; }
				this._observers[t].push(cb);
			}, this);
			return typeList;
		},
		//return a summary of return values, any trues with cause a final true, all falsy will be false
		// consider a special emit that returns a full array with all the individual results
		"emit": function emit(type){
			var args = Array.prototype.slice.call(arguments, 1);
			var resultSummary;
			
			if(this._observers[type]){
				this._observers[type].forEach(function(fn){
					resultSummary = fn.apply(undefined, args) || resultSummary;
				});
			}
			//hit the ones that want everything
			this._observers[""].forEach(function(fn){
				resultSummary = fn.apply(undefined, args) || resultSummary;
			});
			return resultSummary;
		},
		"extend": function extend(obj){
			var self = this;
			var external = ['on', 'emit'];
			external.forEach(function(key){ obj[key] = self[key].bind(self); });
		}
	};
	return Subscriber;
})

.factory('promiseHistoryService', ["subscriber", function(subscriber){
	var observationHandler = subscriber.create();
	
	return {
		subscribe: observationHandler.subscribe.bind(observationHandler),
		unsubscribe: observationHandler.unsubscribe.bind(observationHandler),
		observe: observationHandler.observe.bind(observationHandler)
	}
}]);
