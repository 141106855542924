import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {RallyPointService} from "../../../../js/services/firebase-wrappers/rally-point";
import {ACCESS_CODE_QUICK_JOIN_NEED_CODE, ACCESS_CODE_QUICK_JOIN_INVALID_CODE} from "../../strings";
import {Router} from "@angular/router";
import { LoadingBlockerState } from "../loading-blocker/loading-blocker";
import { Subject } from "rxjs";

@Component({
	selector: 'access-code-quick-join',
	template: require('./access-code-quick-join.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessCodeQuickJoinComponent implements OnInit {
	@Input() userId: string;
	
	public loadingObservable: Subject<LoadingBlockerState> = new Subject();
	private validCharactersRegex = /[^a-z0-9]/gi;
	
	accessCodeForm = this.formBuilder.group({
		accessCode: ['', Validators.required]
	});
	
	constructor(private formBuilder: FormBuilder, private rallyPointService: RallyPointService, private router: Router) {}
	
	ngOnInit() {}
	
	public tryCode(): void {
		if (!this.accessCodeForm.valid) {
			Logging.warning(ACCESS_CODE_QUICK_JOIN_NEED_CODE);
			return;
		}
		// This filters invalid characters from the user input string. Not having this will cause an error in Firebase.
		let code = this.accessCodeForm.value.accessCode.replace(this.validCharactersRegex, '');
		// Start loading
		this.loadingObservable.next(LoadingBlockerState.inProgress);
		let codeData = this.rallyPointService.getAccessCode(code);
		return codeData.$loaded()
			.then(() => {
				if (codeData._isValid && this.userId) {
					return this.rallyPointService.checkForProjectAccess(codeData.projectId, this.userId)
						.then((hasAccess) => {
							if (hasAccess) {
								this.loadingObservable.next(LoadingBlockerState.complete);
								return 'plan';
							}
							else {
								this.loadingObservable.next(LoadingBlockerState.complete);
								return this.rallyPointService.addProjectAccess(codeData.projectId, this.userId, code).then(() => {
									return 'plan';
								});
							}
						})
						.then(() => {
							this.router.navigate(['plan', codeData.projectId, codeData.planId]);
						})
						.catch((e) => {
							this.loadingObservable.next(LoadingBlockerState.failure);
							throw new Error(e);
						})
				} else {
					this.loadingObservable.next(LoadingBlockerState.failure);
					Logging.warning(ACCESS_CODE_QUICK_JOIN_INVALID_CODE);
					throw new Error(ACCESS_CODE_QUICK_JOIN_INVALID_CODE);
				}
			}).catch((e) => {
				throw new Error(e);
			})
	}
}
