import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'nav-bar-sign-up',
	template: require('./nav-bar-sign-up.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarSignUpComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
