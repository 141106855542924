import * as utils from "utils";
import * as moment from "moment";
import * as firebase from "firebase/app";

import * as bowser from "bowser";

import {default as Point} from "js/lib/math/Point";

export function windowifier(){
	window.utils = utils;
	window.Point = Point;
	window.moment = moment;
	window.firebase = firebase;
	window.bowser = bowser;
}
