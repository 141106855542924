import { DataSnapshot } from "@firebase/database-types";
import { AngularFireAction } from "@angular/fire/database";

import * as TicketAttributeConfig from "./TicketAttributeConfig";
import * as pinUtils from "ng2/common/utils/promise-pin-utils";

import * as utils from "utils";
import * as firebaseUtils from "ng2/fancy-firebase/firebase-utils";

import {PullColumnList} from "ng2/fancy-firebase/factories";

import { Side } from "./Side"; export {Side};
import { Variance } from "./Variance";
import { DependencyWeight } from "./Dependencies"; export { DependencyWeight }

import { dateCache } from "ng2/common/date-cache";

function addOrRemoveFirebaseProperty(view, key, add, data){
	if(add){ view[key] = data; }
	else{ delete view[key]; }
}

export enum TicketTypes {
	TASK = "task",
	CONSTRAINT = "constraint",
	MILESTONE = "milestone"
}


const SHORT_ACTIVITY_NAME_LENGTH = 500;
const CONSTRAINT_ACTIVE_SIZE = 145;
const CONSTRAINT_PULL_SIZE = 290;

export interface TicketFields{
	activityName?: string,
	actualFinish?: string,
	assignedProjectMemberId?: string,
	createdAt?: string,
	creatorId?: string,
	crewSize?: number,
	dateRequested?: string,
	dateRequired?: string,
	dateRequiredOverride?: string,
	durationDays?: number,
	hasPredecessorOutOfOrder?: boolean,
	hasScheduleViolations?: boolean,
	height?: number,
	id?: string,
	isActive?: boolean,
	isActualized?: boolean, //local
	isBlocked?: boolean,
	isCyclic?: boolean,
	isLateConstraintRequested?: boolean,
	isLateTicketFinish?: boolean,
	isOutOfOrder?: boolean,
	lastPlannedFinish?: string,
	lastPlannedStart?: string,
	left?: number,
	legacyDatePlanned?: string,
	legacyDateRequested?: string,
	liveCrewSize?: number,
	liveDurationDays?: number,
	livePromisePeriodStack?: Array<pinUtils.PromisePin>,
	liveLastPromise?: pinUtils.PromisePin,
	liveFinishX?: number,
	liveFinish?: string,
	liveRole?: any,
	liveStart?: string,
	notes?: string,
	locationId?: string,
	plannedFinish?: string,
	plannedStart?: string,
	predecessorOutOfOrderCount?: number,
	predecessors?: any, //todo, do better
	priorityId?: string,
	procoreRfiId?: string,
	promisePeriodPosition?: string,
	promisePeriodStack?: any, //todo, do better
	pullColumn?: number,
	responsibleProjectMemberId?: string,
	roleId?: string,
	successors?: any, //todo, do better
	side?: Side,
	tminusFinish?: number,
	top?: number,
	type?: string,
	userOrder?: number,
	width?: number,
	workingDays?, //todo, do better
	[key: string]: any
}

export enum TicketRenderTypes {
	database = "database",
	rawDatabase = "rawDatabase",
	drag = "drag",
	edit = "edit"
}



/**
 * # API feels stable now!
 *
 * ## General lifecycle
 * A ticket is constructed, generally using the data from a firebase child_added event.
 * It defaults to the database view. In this view the ticket is not generally
 * responding to continuous user interaction. There will be "instant" changes like
 * being selected, but the data the ticket is rendered can be safely considered to
 * be in sync with firebase. Changes will be applied via [[firebaseUpdate]], 
 * additionally any derived data expected to always be kept up to date should be
 * initialized and applied here (likely additionally in [[localUpdate if it changes locally]])
 *
 * To change to a longer term/ ignoring database mode (drag/edit/new thing), a 
 * ticket should be converted to that view via [[changeToSecondaryView]]. Where view
 * specific (and general) database -> edit/drag/other conversions are performed.
 * Any time a ticket needs to be changed, the relevant updates should be applied to
 * [[localUpdate]]. Once the long term view is done, the code that required it should
 * call [[persistSaveData]] and exit the view [[changeToDatabaseView]].
 *
 * ## Actual usage
 * Most of the app should never call any write methods, and never modify anything on the Ticket.
 * All changes to data are expected to run through the action system.
 * There may be some small exceptions to that rule though. Additional notes:
 * * never retain a reference to the .view object, it's only a reference, and that 
 * reference will change, frequently
 * * the "real" views (database, edit, drag) will always refer to the same object
 * throughout that view "session". This means that you'll be able to store some 
 * data in the view while your using it, it will be otherwise ignored and cleared the
 * next time that view is initialized (don't rely on it being there at the start
 * of the next view session though, consider it cleared at the end of the view session).
 * Probably don't rely on that, but the option is there.
 *
 * ## Derived data
 *
 * ### actualized stuff
 * All autogenerated via a config list
 *
 * ### joined data
 * * Largely handled by the [[joinKeyMapping]]
 *
 * ### special cases
 * Stuff like promise periods, [[magicRoleRecalc]] and predecessors/ successors, etc
 * are not going to be representable by a simple config list. Most of the time the
 * workflow involving them is going to be something like:
 * * convert raw database data to some more useful internal representation on [[firebaseUpdate]]'s
 * the rest of the code will use the internal representation
 * * maintain that code where necessary, probably in [[localUpdate]]s possibly just
 * in view changes
 * * before writing back to firebase, the internal representation will need to be converted
 * back the firebase-y state, then written
 */

export class Ticket {
	static joinKeyMapping = TicketAttributeConfig.joinKeyMapping;
	
	//TEMP_ACTUAL_STUFF
	// calculateActuals?: (actualFinish: string, durationDays: number, roleId: string, originalWidth: number)=>{left: number, width: number};
	//TEMP_ACTUAL_STUFF
	// actualArchive = {left: 0, width: 0};
	// 
	getXFromDate: (date:string)=>number;
	checkForLongestPull: (pullColumn: number, plannedStart: string)=>boolean;
	
	/** the tickets firebase id */
	$id: string;
	/** the data corresponding to the current state. Is a reference to the actual view */
	view:TicketFields = {};
	/** the data corresponding to the database (unfiltered) */
	rawDatabase:any = {};
	/** the data corresponding to the database (cleaned up a bit) */
	database:TicketFields = {};
	/** drag data */
	drag:TicketFields = {};
	/** edit data */
	edit:TicketFields = {}; 
	/** used to determine when joined data needs to be updated */
	prevData: any = {};
	/** the currently active renderType */
	renderType: TicketRenderTypes = TicketRenderTypes.database;
	/** defines if the ticket is currently selected or not */
	selected = false;
	/** defines if the ticket is currently a search result */
	searched = false;
	/** is it the longest, this is not on the view because it's got a hard dependency on fbdb and so live support is pointless */
	isLongestPull?: boolean;
	/** specifies the weight of the dependency view this ticket is involved in (usually just means the border) */
	dependencyWeight?: DependencyWeight;
	/** assorted bits of external data tickets need to be aware of */
	externalProperties = {
		ticketMovedToFloating: false,
		shouldHideCompletedTickets: false
	};
	
	/** the last known position of the active line */
	activeLineX = null;
	planHasActiveSpace = false;
	
	isAFloatingTicket = false;
	
	isTimelineTicket = false;
	timelineX:number = null;
	timelineTicketSplitId: [string, string];
	pullColumnList?: PullColumnList;
	varianceList?: Map<string, Variance>;
	
	hidden = false;

	constructor(ticketData?:any, key?: string){
		//temp
		this.$id = key;
		if(ticketData){
			this.firebaseUpdate(ticketData);
		}
		
		this.view = this.database;
	}
	
	//------------------------------
	//- Helper functions
	//------------------------------
	
	/**
	 * Generates a very small and very specific previous value cache for the join code.
	 * Should be called on every update.
	 */
	public populatePrev(subList?: string|Array<string>){
		var supportedPrev = ["roleId", "locationId", "priorityId", "responsibleProjectMemberId", "assignedProjectMemberId"];
		if(subList){
			if(typeof subList === "string" && supportedPrev.indexOf(subList) !== -1){ supportedPrev = [subList]; }
			else if((subList as Array<string>).every(thing => supportedPrev.indexOf(thing) !== -1)){ supportedPrev = subList as Array<string>; }
		}
		
		supportedPrev.forEach((key)=>{
			this.prevData[key] = this.view[key];
		})
	}
	
	
	//-----------------------------
	//- Update code
	//-----------------------------
	
	/**
	 * Should be called with any updates coming specifically from firebase (or anything that wants to simulate a firebase update)
	 * Updates both rawDatabase and database
	 * @param  ticketData DataSnapshot.val()
	 */
	public firebaseUpdate(ticketData){
		this.rawDatabase = ticketData;
		this.populatePrev();
		
		let isActual = !!this.rawDatabase.actualFinish;
		
		for( var key in TicketAttributeConfig.baseList){
			var attrData = TicketAttributeConfig.baseList[key];
			
			var exists = ticketData[key] !== null && ticketData[key] !== undefined;
			
			//actualized branch
			if(isActual && attrData.plannedKey){
				firebaseUtils.addOrRemoveFirebaseProperty(this.database, attrData.localKeyOverride, exists, ticketData[key]);
			}
			//planned branch
			if(!isActual && attrData.actualKey){
				firebaseUtils.addOrRemoveFirebaseProperty(this.database, attrData.localKeyOverride, exists, ticketData[key]);
			}
			
			//--------------------------------------------------------------------
			//--------- SCARY TEMP FIX
			//--------- since we haven't implemented the new version of actualizing
			//--------- the actual properties don't exist... so... yeah
			//--------------------------------------------------------------------
			
			if(isActual){
				this.database.liveCrewSize = ticketData.crewSize;
				this.database.liveDurationDays = ticketData.durationDays;
				this.database.liveStart = ticketData.lastPlannedStart;
				this.database.liveFinish = ticketData.lastPlannedFinish;
			}
			if(ticketData.type === "milestone"){
				this.database.liveDurationDays = 1;
			}
			
			//--------------------------------------------------------------------
			//--------- SCARY TEMP FIX
			//--------- since we haven't implemented the new version of actualizing
			//--------- the actual properties don't exist... so... yeah
			//--------------------------------------------------------------------
			
			// if(this.database.type === "milestone" || this.database.type === "constraint"){
			// 	this.database.width = this.database.height = 145;
			// }
			
			//just update normally
			firebaseUtils.addOrRemoveFirebaseProperty(this.database, key, exists, ticketData[key]);
		}
		
		//derived stuff
		this.database.isActualized = isActual;
		this.database.shortActivityName = this.database.activityName ? String(this.database.activityName).slice(0, SHORT_ACTIVITY_NAME_LENGTH) : "";
		
		if(this.database.promisePeriodStack){
			var stack = pinUtils.makePromisePeriodArray(this.database.promisePeriodStack, this.database.plannedFinish, this.getXFromDate, this.varianceList);
			// console.log('stack', this);
			this.database.livePromisePeriodStack = stack;
			if(stack){
				this.database.liveLastPromise = stack[stack.length -1];
			}
		}
		else if(this.database.livePromisePeriodStack){
			delete this.database.livePromisePeriodStack;
			delete this.database.liveLastPromise;
		}
		
		if(this.database.actualFinish){
			this.database.liveFinishX = this.getXFromDate(this.database.actualFinish);
		}
		else if(this.database.liveFinishX){
			delete this.database.liveFinishX;
		}
		if(this.database.pullColumn !== undefined && this.database.pullColumn !== null){
			this.isLongestPull = this.checkForLongestPull(this.database.pullColumn, this.database.plannedStart);
		}
		else if(this.isLongestPull){ delete this.isLongestPull; }
		
		if(this.pullColumnList){
			this.timelineX = this.pullColumnList.getXFromDate(this.view.liveFinish);
		}
		
		//THIS MIGHT BE A PROBLEM
		if(this.database.type === "constraint"){this.database.liveDurationDays = 1;}
		
		// this.actualArchive.left = this.database.left;
		// this.actualArchive.width = this.database.width;
		//TEMP_ACTUAL_STUFF
		// if(this.database.actualFinish){
		// 	var stuff = this.calculateActuals(this.database.actualFinish, this.database.durationDays, this.database.roleId, this.database.width);
		// 	this.database.left = stuff.left;
		// 	this.database.width = stuff.width;
		// }
		
		if(!this.recalcSide(TicketRenderTypes.database)){
			//update the sizes if recalcSide doesn't
			this.updateSize(TicketRenderTypes.database);
		}
		
		this.recalcHidden();
	}
	
	/**
	 * Should be called to update the app-wide render of the ticket or the "view"
	 * Any changes here are representing changes only to the local data.
	 * The database view *can* be written to view this key, but no guarantee should be 
	 * assumed that said data won't be clobbered by a real database update.
	 * Some processing of the data passed in will be done:
	 * * live data will be converted to real
	 * * any necessary derived data will be recalculated
	 * * updates to ids corresponding to joined data will trigger join updates
	 * @param  obj        The data to write
	 * @param  renderType Defaults to the current view, can override to write to another one.
	 */
	public localUpdate(obj, renderType?:TicketRenderTypes){
		if(!renderType){ renderType = this.renderType; }
		this.populatePrev();
		var isActual = obj.isActualized || this[renderType].isActualized;
		
		if(obj.side !== undefined){ delete obj.side; } //let recalcSide do the work
		if(this[renderType].type === TicketTypes.MILESTONE || this[renderType].type === TicketTypes.CONSTRAINT){
			if(obj.width){ delete obj.width; }
			if(obj.height){ delete obj.height; }
		}
		
		for(var key in obj){
			if(TicketAttributeConfig.reverseLiveMap[key]){
				if(isActual){
					this[renderType][TicketAttributeConfig.reverseLiveMap[key].actual] = obj[key];
				}
				else{
					this[renderType][TicketAttributeConfig.reverseLiveMap[key].planned] = obj[key];
				}
			}
			
			this[renderType][key] = obj[key];
		}
		
		if(obj.activityName){
			this[renderType].shortActivityName = obj.activityName.slice(0, SHORT_ACTIVITY_NAME_LENGTH);
		}
		
		
		//promise period stuff - run every time at first, can probably filter it to only change on interesting updates
		this.recalcPins(renderType);
		// var stack = this[renderType].livePromisePeriodStack;
		// if(stack){
		// 	stack.forEach((pin, idx)=>{
		// 		pinUtils.decoratePinData(pin.data, this[renderType].plannedFinish, this.getXFromDate, idx === stack.length - 1, this.varianceList);
		// 	});
		// }
		if(obj.left){
			this.recalcSide(renderType);
		}
		//enhancement, only do this on finish changing
		var finish = obj.actualFinish || ( isActual && this[renderType].actualFinish) ? this[renderType].actualFinish : null;
		if(finish){
			
			this[renderType].liveFinishX = this.getXFromDate(finish);
		}
		
		//type specific processing
		switch(renderType){
			case TicketRenderTypes.drag:
				this.dragUpdate(obj); break;
			case TicketRenderTypes.edit:
				this.editUpdate(obj); break;
			default: break;
		}
	}
	
	//placeholders to allow view specific local update overrides
	public dragUpdate(obj){	}
	public editUpdate(obj){ }
	
	
	
	
	//-----------------------------
	//- Changing views code
	//-----------------------------
	
	/**
	 * Change back to the database view, this can be considered as throwing out any un-persisted changes.
	 * If for some reason reason we "need" the ability to load secondary view into database
	 * without persisting it, it should be carefully considered first, it's likely a mistake.
	 * Then the output functions could _probably_ be called here.
	 */
	public changeToDatabaseView(){
		this.renderType = TicketRenderTypes.database;
		this.view = this[this.renderType];
		this.hidden = false;
	}

	/**
	 * Change the ticket to one of it's secondary views, part of this process involves
	 * initializing the view with a set of database data
	 * @param  renderType
	 */
	public changeToSecondaryView(renderType:TicketRenderTypes){
		if(renderType === this.renderType){ console.log("don't change the renderType to be the same"); return; }
		
		switch(renderType){
			case TicketRenderTypes.drag:
				this.viewInputDrag(); break;
			case TicketRenderTypes.edit:
				this.viewInputEdit(); break;
			default: break;
		}
		
		//set the view last
		this.renderType = renderType;
		this.view = this[this.renderType];
	}
	
	/** Called at the beginning of the drag */
	private viewInputDrag(){
		this.viewInputGeneric(TicketRenderTypes.drag);
	}
	/** Called at the beginning of the edit */
	private viewInputEdit(){
		this.viewInputGeneric(TicketRenderTypes.edit);
		this.magicRoleRecalc(TicketRenderTypes.edit);
	}
	
	//temp
	private viewInputGeneric(renderType:TicketRenderTypes){
		this[renderType] = {};
		// Object.assign(this.edit, this.view);
		Object.keys(TicketAttributeConfig.localList).forEach((attrKey)=>{
			let attrDef = TicketAttributeConfig.localList[attrKey];
			if(this.database[attrKey] !== undefined){ this[renderType][attrKey] = this.database[attrKey]; }
			
			//copy derived data
			let derivedKey = Ticket.joinKeyMapping[attrKey];
			if(derivedKey && this.database[derivedKey.key]){
				this[renderType][derivedKey.key] = this.database[derivedKey.key];
			}
		});
		
		//bypass the localList (come up with better solution
		if(this.database.plannedFinish){ this[renderType].plannedFinish = this.database.plannedFinish; }
		
		//magic local datas
		if(this.database.liveRole){ this[renderType].liveRole = this.database.liveRole;	}
		if(this.database.livePromisePeriodStack){ this[renderType].livePromisePeriodStack = pinUtils.cloneStack(this.database.livePromisePeriodStack); }
		if(this.database.liveLastPromise){ this[renderType].liveLastPromise = this[renderType].livePromisePeriodStack[this[renderType].livePromisePeriodStack.length-1]; }
		this[renderType].side = this.database.side;
	}
	
	
	//-----------------------------
	//- Saving data code
	//-----------------------------
	
	/**
	 * Take a view, copies all it's relevant data to the database view, and 
	 * returns an update object that can be used to write the changes to firebase.
	 * TODO - add support for writing to firebase from the database view
	 * @param  renderType 
	 * @return           	An object to be written to firebase
	 */
	public persistSaveData(renderType?: TicketRenderTypes){
		if(!renderType){ renderType = this.renderType; }
		
		var out;
		
		switch(renderType){
			case TicketRenderTypes.database:
				out = this.viewOutputGeneric(TicketRenderTypes.database); break;
			case TicketRenderTypes.drag:
				out = this.viewOutputDrag(); break;
			case TicketRenderTypes.edit:
				out = this.viewOutputEdit(); break;
			default: break;
		}
		
		console.log('writing to firebase...', out);
		return out;
	}
	
	/**
	 * Perform any operations necessary to convert the drag data back into database data.
	 */
	private viewOutputDrag(){
		return this.viewOutputGeneric(TicketRenderTypes.drag);
		
	}
	/**
	 * Perform any operations necessary to convert the edit data back into database data.
	 */
	private viewOutputEdit(){
		var out = this.viewOutputGeneric(TicketRenderTypes.edit);
		return out;
	}
	
	//temp
	private viewOutputGeneric(renderType: TicketRenderTypes){
		// this.resetTempActuals(renderType);
		var out:any = {};
		for ( var key in TicketAttributeConfig.baseList ){
			var thing = TicketAttributeConfig.baseList[key];
			
			if(this[renderType][key] !== undefined){
				if(thing.clientWrite){
					out[key] = this[renderType][key];
				}
				this.database[key] = this[renderType][key];
			}
			
			//copy joinned data that may have been modified
			let derivedKey = Ticket.joinKeyMapping[key];
			if(derivedKey && this[renderType][key] !== undefined){ 
				this.database[derivedKey.key] = this[renderType][derivedKey.key];
			}
		}
		//also copy the live data back
		for( var key in TicketAttributeConfig.reverseLiveMap ){
			if(this[renderType][key] !== undefined){
				this.database[key] = this[renderType][key];
			}
		}
		
		// if(this[renderType].livePromisePeriodStack){
			var outStack = pinUtils.returnToObject(this[renderType].livePromisePeriodStack);
			var outPos = outStack ? this[renderType].liveLastPromise.$id : null;
			this[renderType].promisePeriodStack = outStack;
			this[renderType].promisePeriodPosition = outPos;
			out.promisePeriodStack = outStack;
			out.promisePeriodPosition = outPos;
		// }
		
		this.magicRoleRecalc(TicketRenderTypes.database);
		this.recalcSide(TicketRenderTypes.database);
		return out;
	}
	
	// private resetTempActuals(renderType: TicketRenderTypes){
	// 	if(this[renderType].actualFinish){
	// 		this[renderType].left = this.actualArchive.left;
	// 		this[renderType].width = this.actualArchive.width;
	// 	}
	// }
	
	public dumpDatabaseRepresentation(){
		var renderType = TicketRenderTypes.database;
		// this.resetTempActuals(renderType);
		var out:any = {};
		for ( var key in TicketAttributeConfig.baseList ){
			var thing = TicketAttributeConfig.baseList[key];
			if(thing.clientWrite){
				if(this[renderType][key] !== undefined){ 
					out[key] = this[renderType][key];
				}
			}
		}
		return out;
	}
	
	
	//-----------------------------
	//- "special" derived data code
	//-----------------------------
	
	/**
	 * calculates the primary projectMember key and returns it
	 */
	private get primaryPmKey(){
		var viewType = this.renderType; //fix this after deciding a permanent join solution
		if(this[viewType].type !== "constraint"){return null;}
		// if(this[viewType]["assignedProjectMemberId"]){ return "assignedProjectMember"; } //assigned never actually gets the color... oops
		if(this[viewType]["responsibleProjectMemberId"]){ return "responsibleProjectMember"; }
		return null;
	}
	
	/**
	 * Calculates a liveRole key that works for both normal tickets and constraints
	 * @param  viewType
	 */
	public magicRoleRecalc(viewType?:TicketRenderTypes){
		if(!viewType){ viewType = this.renderType; }
		var role = null;
		if(this[viewType].type === "constraint"){
			var pmKey = this.primaryPmKey;
			if(pmKey){
				role = utils.getNested(this[viewType], pmKey+".role");
			}
		}
		else{
			if(this[viewType].role){
				role = this[viewType].role;
			}
		}
		this[viewType].liveRole = role;
	}
	
	public recalcSide(viewType?:TicketRenderTypes){
		if(!viewType){ viewType = this.renderType; }
		var newSide;
		if(!this.planHasActiveSpace){ newSide = Side.PULL; }
		else if(this.activeLineX === undefined || this.activeLineX === null){ newSide = Side.UNKNOWN; }
		else if(this[viewType].left >= this.activeLineX){ newSide = Side.PULL }
		else { newSide = Side.ACTIVE; }
		if(newSide !== this[viewType].side){
			this[viewType].side = newSide;
			this.updateSize(viewType);
			return true;
		}
		return false;
	}
	
	private updateSize(viewType?:TicketRenderTypes){
		if(!viewType){ viewType = this.renderType; }
		if(this[viewType].type === "constraint" || this[viewType].type === "milestone"){
			if(this[viewType].side === Side.PULL){
				this[viewType].width = this[viewType].height = CONSTRAINT_PULL_SIZE;
			}
			else{
				this[viewType].width = this[viewType].height = CONSTRAINT_ACTIVE_SIZE;
			}
		}
	}
	
	public updateVariance(varianceList: Map<string, Variance>){
		this.varianceList = varianceList;
		if(this.view.liveLastPromise){
			this.recalcPins(this.renderType);
			return true;
		}
		return false;
	}
	
	public recalcHidden(){
		let desiredHiddenState = false;
		if(	this.externalProperties.shouldHideCompletedTickets 
			&& this.database.actualFinish
			&& Math.abs(dateCache.diffDays(dateCache.todayString, this.database.actualFinish)) >= 30 ){ desiredHiddenState = true; }
		if(this.externalProperties.ticketMovedToFloating){ desiredHiddenState = true; }
		if(this.hidden !== desiredHiddenState){ this.hidden = desiredHiddenState; return true;}
		return false;
	}
	
	public recalcPins(renderType?: TicketRenderTypes){
		if(!renderType){ renderType = this.renderType; }
		
		var stack = this[renderType].livePromisePeriodStack;
		var changed = false;
		if(stack){
			stack.forEach((pin, idx)=>{
				// console.log('renderType', renderType, this[renderType], this[renderType].plannedFinish)
				changed = pinUtils.decoratePinData(pin.data, this[renderType].plannedFinish, this.getXFromDate, idx === stack.length - 1, this.varianceList) || changed;
			});
		}
		
		//possibly break this out into it's own update method?
		var finish = this[renderType].actualFinish;
		var oldLiveFinishX = this[renderType].liveFinishX;
		if(finish){ 
			this[renderType].liveFinishX = this.getXFromDate(finish);
			changed = this[renderType].liveFinishX !== oldLiveFinishX || changed;
		}
		else if(oldLiveFinishX){ 
			delete this[renderType].actualFinish;
			changed = true;
		}
		
		return changed;
	}
	
}
