import { FancySprite } from "../graphics/FancySprite";
import { Texture, utils } from "pixi.js";
import {LEVEL_OF_DETAIL_TABLE, LevelOfDetail} from "../core/LevelOfDetail";
import { TICKET_TEXTURE_OPTIONS } from "../tickets/";
import { TICKET_LAYERS } from "../layers/";
import { FancyText } from "../fontpath/";
import {HighResolutionText} from "../text/HighResolutionText";
import { rendererInfo } from "../core/RendererInfo";
/**
 * The PinnedCount class is created and managed by the FancyPinHistory.
 */
export class PinnedCount extends FancySprite {
	/**
	 * Stores the reference to the Pin FancySprite
	 */
	public fancyText: FancyText|HighResolutionText;
	/**
	 * Stores the reference to the Pin border FancySprite
	 */
	private _textBorder: FancySprite;
	/**
	 * @param {PIXI.Texture} texture The Pin background texture
	 */
	constructor(texture: Texture){
		super(texture);
		// Set the LOD Level for this graphic
		//this.lodLevel = LEVEL_OF_DETAIL_TABLE.MEDIUM;
		// All assets are created at 3x scale. So we scale down the dimensions so it is to scale with the plan
		// this.scale.x = 0.20;
		// this.scale.y = 0.20;
		
		this.width = 34;
		this.height = 34;
		
		this._setupText();
	}
	/**
	 * Creates the text and Ticket Border and adds it as a child to the pin background
	 */
	private _setupText(): void {
		if (rendererInfo.isWebGL()){
			this.fancyText = new FancyText("Roboto");
			this.fancyText.x = 42;
			this.fancyText.y = 24;
			// this.fancyText.scale.x = 2;
			// this.fancyText.scale.y = 2;
			this.fancyText.visible = true;
			this.fancyText.active = true;
			this.fancyText.fontSize = 54; // 18 * texture scale ratio (3) = 54
		} else {
			this.fancyText = new HighResolutionText("");
			this.fancyText.x = 42;
			this.fancyText.y = 24;
			// this.fancyText.scale.x = 2;
			// this.fancyText.scale.y = 2;
			this.fancyText.visible = true;
			this.fancyText.active = true;
			this.fancyText.style.fontSize = 54; // 18 * texture scale ratio (3) = 54
		}
		
		this._textBorder = new FancySprite(utils.TextureCache["ticket-pinned-border.png"]);
		//this._textBorder.zIndex = TICKET_LAYERS.ABOVE_TICKET;
		this._textBorder.x = 1;
		this._textBorder.y = 1;
		this._textBorder.visible = true;
		this._textBorder.active = true;
		
		this.addChild(this.fancyText, this._textBorder);
	}
	/**
	 * Called when the Pin should be destroyed.
	 * @param {Object | boolean} options
	 */
	public destroy(options): void {
		this.fancyText.destroy(options);
		this._textBorder.destroy(options);
		super.destroy(options);
	}
}