
// ---------------------------------------------
// HEY, USING HUD IN THIS WAY IS PROBABLY WRONG
// the sane thing would probably be to update the world in pixi, then use that to update the HUD
// instead of updating the hud from pixi events, then listening to the hud to update the world
// ---------------------------------------------
import { first } from "rxjs/operators";

import { PlanState } from "../../../common/services/plan-state.service";
import { DisplayObject } from "pixi.js";
import { Component } from "../../component-system/";
import { ComponentInterface } from "../../interfaces/";
import Point from "js/lib/math/Point";
import {EventBinder} from "../interaction-utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import { TicketSelection } from "ng2/common/models/TicketSelection"

export class PlanTap extends Component implements ComponentInterface{
	private _binder: EventBinder;
	constructor(){super();}
	/**
	 * The setup method is called once all properties on a component have been decorated by the ComponentManager.
	 *
	 * From this point on, you can safely use this.getParent() and this.getManager().
	 *
	 * Arguments passed into setup are passed in via the ComponentManager.add(name, component, ...args)
	 */
	public setup(planState: PlanState, parent: DisplayObject): void{
		this._binder = new EventBinder(parent);
		
		this._binder.add('hammer-singletap', (event)=>{
			let tickets = planState.tickets.selectedTicket$.getValue()
			if(tickets.list.size > 0){
				let count = tickets.list.size;
				let planId = planState.route.snapshot.paramMap.get("planId");
				analyticsService.multiselectTapPlanToCancel(count, planId);
				planState.actions.unSelectTicket(tickets.list);
			}
		})
	}
	
	/**
	 * ...must...kill...stuff....here
	 */
	public destroy(){
		this._binder.destroy();
	}
}
