import { PlanState } from "../../../common/services/plan-state.service";
import { CanvasRendererInterface } from "../../../canvas-renderer/interfaces";
import { Rectangle, autoDetectRenderer, WebGLRenderer, CanvasRenderer, DisplayObject, Texture, utils } from "pixi.js";
import { TweenManager } from "../../../canvas-renderer/tween";
import { renderQueue } from "../../../common/RenderQueue";
import { LayerManager } from "../../../canvas-renderer/layers";
import { FancyGraphics, FancySprite, FancyContainer } from "../../../canvas-renderer/graphics";
import { Camera } from "../../../canvas-renderer/core";
import { Grid } from "../../../canvas-renderer/canvas-grid";
import { HighResolutionText } from "../../../canvas-renderer/text/HighResolutionText";
import * as Utils from "utils";
import { PLAN_CONSTANTS } from "../../../common/plan-constants";
import { LaborQuery } from "./LaborQuery";
import { Role } from "../../../common/models/Role";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as ss from "simple-statistics";
import { LaborCount } from "../../../common/models/LaborCount";
import { SpritePool } from "../../../common/utils/GenericPool";
import {dateCache} from "../../../common/date-cache";
// For crew size icon name
import { TICKET_TEXTURE_OPTIONS } from "../../../canvas-renderer/tickets/";
import {LABOR_COUNT_FILTER_VALUES} from "../labour-count.component";

/**
 * The layers for the labor count application
 */
export enum LABOR_COUNT_LAYERS {
	UI_BACKGROUND = "uiBackgroundLayer",
	WORLD = "worldLayer",
	BARS = "barsLayer",
	GRID = "gridLayer",
	UI = "uiLayer",
	UI_X_AXIS = "uiXAxisLayer",
	UI_Y_AXIS = "uiYAxisLayer"
}

export const LABOUR_COUNT_SETTINGS = {
	HEIGHT: 122,
	BACKGROUND_COLOR: 0xe3e3e3,
	BACKGROUND_ALPHA: 0.6,
	AXES_ALPHA: 0.8,
	TIMELINE_Y_AXIS_WIDTH: PLAN_CONSTANTS.SIDEBAR_WIDTH + 50,
	TIMELINE_Y_AXIS_BACKGROUND_COLOR: 0xdfdfdf,
	TIMELINE_X_AXIS_HEIGHT: 20,
	TIMELINE_X_AXIS_BACKGROUND_COLOR: 0xc7c7c7,
	TIMELINE_X_AXIS_SPACING: 15,
	TIMELINE_X_AXIS_SPACING_TOP: 37,
	GRID_LODS: [2, 3],
	GRID_MIN_SCALE: 0.001,
	GRID_MAX_SCALE: 500,
	GRID_SPACING: 20,
	GRID_COLOR: 0xbdbdbd,
	BAR_DRAW_Y_OFFSET: 35,
	SCALE_FOR_DAYS: 0.23,
	Y_AXIS_X_OFFSET: 120,
	Y_AXIS_Y_OFFSET: -8,
	BAR_SPACING: 2, // The padding for the bars
	MAX_ZOOM_LEVEL: 0.035 // This is the zoom level when the "Zoom In" message appears at
};

export interface ICombinedLabor {
	id: string,
	plannedAndActual: number,
	color: number
}

export interface ILaborDraw {
	index: number,
	labor: Map<string, ICombinedLabor>,
	startX: number,
	finishX: number,
	width: number,
	day: string,
	plannedAndActual: number
	projectPlannedAndLastPlanned: number
}

/**
 * Convert the hex string to a number once, then store it..
 */
export interface ILaborColorCache {
	[key: string]: number
}

/**
 * The main canvas application for the LaborCount component
 */
export class LaborCountApplication {
	public active: boolean = false;
	public stage: FancyContainer = new FancyContainer();
	public renderer: WebGLRenderer|CanvasRenderer;
	public view: HTMLCanvasElement;
	public bounds: Rectangle;
	public tweenManager: TweenManager;
	public layerManager: LayerManager = new LayerManager();
	public backgroundGraphics: FancyGraphics = new FancyGraphics();
	public graphics: FancyGraphics = new FancyGraphics();
	public uiGraphics: FancyGraphics = new FancyGraphics(true);
	public camera: Camera;
	
	public width: number;
	public height: number;
	
	public crewSizeIcon: FancySprite;
	
	public drawDays: boolean = false;
	private _lastDrawDays: boolean = null;
	
	public grid: Grid;
	
	public query: LaborQuery;
	
	// Layer references
	public uiBackgroundLayer: FancyContainer;
	public worldLayer: FancyContainer;
	public barsLayer: FancyContainer;
	public gridLayer: FancyContainer;
	public uiLayer: FancyContainer;
	public xAxesLayer: FancyContainer;
	public yAxesLayer: FancyContainer;
	public projectText: HighResolutionText;
	
	private _destroyList: any[] = [];
	public _cachedDays;
	private _cachedRoles: Map<string, Role>;
	private _projectLabor: Map<string, LaborCount>;
	private _shutdownSubject$: Subject<boolean> = new Subject();
	private _visibleLabor: ILaborDraw[];
	private _lastStart: string;
	private _lastEnd: string;
	// For filtering
	private _filterBy: any = []; //LABOR_COUNT_FILTER_VALUES.ALL_ROLES;
	private _myRoleId: string = "";
	
	private _planCountTextPool: SpritePool;
	private _projectCountTextPool: SpritePool;
	
	private _maxText: HighResolutionText = new HighResolutionText("", {fontSize: 14, fill: "black", resolution: 2});
	private _midText: HighResolutionText = new HighResolutionText("", {fontSize: 14, fill: "black", resolution: 2});
	private _minBar: FancySprite = new FancySprite((<any>Texture).TASK_TEXTURE);
	private _cachedColors: ILaborColorCache = {};
	private _clearActiveColumnSub;
	
	constructor(public planState: PlanState, initOptions: CanvasRendererInterface) {
		// Init boilerplate
		this.renderer = autoDetectRenderer(initOptions);
		this.tweenManager = new TweenManager(this);
		this.bounds = this.renderer.screen;
		this.view = initOptions.view;
		
		// Set inital dimensions
		this.width = this.renderer.width;
		this.height = this.renderer.height;
		
		// Create static text textures
		this.projectText = new HighResolutionText("Project:", {fontFamily: "Roboto", fill: "black", fontSize: 12, resolution: 2});
		this.projectText.x = PLAN_CONSTANTS.SIDEBAR_WIDTH - LABOUR_COUNT_SETTINGS.Y_AXIS_Y_OFFSET;
		this.projectText.y = (LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT / 2) - (this.projectText.height / 2);
		
		// Create layers
		this.uiBackgroundLayer = this.layerManager.addLayer(LABOR_COUNT_LAYERS.UI_BACKGROUND, this.stage);
		this.worldLayer = this.layerManager.addLayer(LABOR_COUNT_LAYERS.WORLD, this.stage);
		this.barsLayer = this.layerManager.addLayer(LABOR_COUNT_LAYERS.BARS, this.layerManager.getLayer(LABOR_COUNT_LAYERS.WORLD));
		this.gridLayer = this.layerManager.addLayer(LABOR_COUNT_LAYERS.GRID, this.layerManager.getLayer(LABOR_COUNT_LAYERS.WORLD));
		this.uiLayer = this.layerManager.addLayer(LABOR_COUNT_LAYERS.UI, this.stage);
		this.xAxesLayer = this.layerManager.addLayer(LABOR_COUNT_LAYERS.UI_X_AXIS, this.stage);
		this.yAxesLayer = this.layerManager.addLayer(LABOR_COUNT_LAYERS.UI_Y_AXIS, this.stage);
		
		// Position Grid Container
		this.gridLayer.y = LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING_TOP;
		
		// Position axes containers
		this.xAxesLayer.x = 0;
		this.xAxesLayer.y = (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT;
		
		// Create the camera and assign it to our world
		this.camera = new Camera(this.worldLayer, this.stage, this.tweenManager, 0, 0, this.bounds.width, this.bounds.height);
		// Camera scale is set to 0 by default to fix a bug for plan camera...so just set it to 1 here
		this.camera.scale.x = this.camera.scale.y = 1;

		// Create our grid
		this.grid = new Grid(this.width / this.renderer.resolution, (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.BAR_DRAW_Y_OFFSET - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING_TOP, this.gridLayer);
		this.grid.setScaleV(LABOUR_COUNT_SETTINGS.GRID_LODS, LABOUR_COUNT_SETTINGS.GRID_MIN_SCALE, LABOUR_COUNT_SETTINGS.GRID_MAX_SCALE, LABOUR_COUNT_SETTINGS.GRID_SPACING, LABOUR_COUNT_SETTINGS.GRID_SPACING);
		
		// Crew Size Icon
		this.crewSizeIcon = new FancySprite(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CREW_ICON_NAME]);
		this.crewSizeIcon.width = 14;
		this.crewSizeIcon.height = 10;
		this.crewSizeIcon.active = true;
		this.crewSizeIcon.visible = true;
		this.crewSizeIcon.tint = 0x000000;
		
		// Add stuff to layers
		this.layerManager.getLayer(LABOR_COUNT_LAYERS.UI_BACKGROUND).addChild(this.backgroundGraphics);
		this.layerManager.getLayer(LABOR_COUNT_LAYERS.BARS).addChild(this.graphics, this._minBar); // This will eventually draw our bars
		this.layerManager.getLayer(LABOR_COUNT_LAYERS.UI).addChild(this.uiGraphics); // This draws our timeline background
		this.layerManager.getLayer(LABOR_COUNT_LAYERS.UI_X_AXIS).addChild(this.projectText); // Project Text
		this.layerManager.getLayer(LABOR_COUNT_LAYERS.UI_Y_AXIS).addChild(this._maxText, this._midText, this.crewSizeIcon); // Max / min text
		
		// Create pools
		
		// Plan Count text
		let textCreator = (): HighResolutionText => {
			return new HighResolutionText("", {fontSize: 14, fill: "black", resolution: 2});
		};
		
		// Project Count text
		let projectTextCreator = (): HighResolutionText => {
			return new HighResolutionText("", {fontSize: 14, fill: "black", resolution: 2});
		};
		
		this._planCountTextPool = new SpritePool(textCreator, this.layerManager.getLayer(LABOR_COUNT_LAYERS.UI));
		this._projectCountTextPool = new SpritePool(projectTextCreator, this.layerManager.getLayer((LABOR_COUNT_LAYERS.UI_X_AXIS)));
		
		this._minBar.visible = true;
		this._minBar.active = true;
		
		this._minBar.width = this.width / this.renderer.resolution;
		this._minBar.height = 1;
		this._minBar.tint = LABOUR_COUNT_SETTINGS.GRID_COLOR;
		
		this.setupSubscribers();
		
		// Draw UI
		this.drawUI();
		
		this.run();
		
		this._destroyList.push(
			this._projectCountTextPool, this._planCountTextPool, this.projectText, this.query, this.grid, this.camera,
			this.uiGraphics, this.graphics, this.backgroundGraphics, this.layerManager, this.tweenManager, this.renderer,
			this.stage, this._maxText, this._midText, this._minBar, this.crewSizeIcon
		);
		
		this._scaleDown();
		
		Utils.throttleEvent("resize", "throttledResize");
		window.addEventListener("throttledResize", this.onResize);
		
		window.LaborCountApplication = this;
	}
	
	public onResize = () => {
		let w = this.planState.scopeSoup.hud.w;
		this.renderer.resize(w, LABOUR_COUNT_SETTINGS.HEIGHT);
		this.camera.resize(w, LABOUR_COUNT_SETTINGS.HEIGHT);
		this.width = this.renderer.width;
		this.height = this.renderer.height;
		this._minBar.width = this.width;
		this._scaleDown();
		this.drawUI();
		this.run();
	};
	
	public setupSubscribers(){
		// Labor Query
		this.query = new LaborQuery(this.planState);
		
		// Subscribe to labor count data
		this.planState.laborCounts.list.list$.pipe(
		takeUntil(this._shutdownSubject$)).subscribe((laborCount) => {
			this.query.setData(laborCount);
			if (this._cachedDays){
				this.update(this._cachedDays, true);
			}
		});
		
		// Subscribe to 'you'
		this.planState.you.pipe(
			takeUntil(this._shutdownSubject$)
		).subscribe((newYou) => {
			this._myRoleId = (newYou && newYou.data) ? newYou.data.roleId : "";
			if (this._cachedDays){
				this.update(this._cachedDays, true);
			}
		});
		
		// Subscribe to project labor
		this.planState.projectLaborCounts.list.list$.pipe(
			takeUntil(this._shutdownSubject$)
		).subscribe((projectLabor: Map<string, LaborCount>) => {
			this._projectLabor = projectLabor;
			if (this._cachedDays){
				this.update(this._cachedDays, true);
			}
		});
		
		// Subscribe to role list
		this.planState.roles.list$.pipe(
			takeUntil(this._shutdownSubject$)
		).subscribe((roles: Map<string, Role>) => {
			this._cachedRoles = roles;
		});
		
		// Subscribe to active column
		this._clearActiveColumnSub = this.planState.activeColumnService.subscribe((thing) => {
			// MOC-3114 MOC-3116
			if (!thing){
				this.graphics.clear();
				this._planCountTextPool.reset();
				this._projectCountTextPool.reset();
				this._cachedDays = [];
				this.updateGrid(0, 1, 0);
				this.render();
				return;
			}
			this._cachedDays = this.planState.activeColumnService.getSubColumns(thing);
			if (this._cachedDays){
				this.update(this._cachedDays, true);
			}
		});
	}
	
	private isRoleActive(roleId: string): boolean {
		// return (this._filterBy === LABOR_COUNT_FILTER_VALUES.ALL_ROLES || (this._filterBy === LABOR_COUNT_FILTER_VALUES.MY_ROLE && this._myRoleId === roleId))
		// MOC-3150
		if (!this._filterBy || !this._filterBy.length){return true}
		for (let idx in this._filterBy) {
			if (this._filterBy[idx].roleId === roleId){
				return true;
			}
		}

		return false;
	}
	
	public updateFilter(newFilter: any): void {
		this._filterBy = newFilter;
		if (this._cachedDays){
			this.update(this._cachedDays, true);
		}
	}
	
	public update(columns = this._cachedDays, force: boolean = false): void {
		if (!columns || !columns.length || !this.active) { return }
		let finish = columns[0].activeDay;
		let start = columns[columns.length - 1].activeDay;
		if (!force && start === this._lastStart && finish === this._lastEnd){ return }
		
		this._visibleLabor = this.processLabor(columns);
		
		this.draw(this._visibleLabor);
		
		this._lastStart = start;
		this._lastEnd = finish;
	}
	
	public processLabor(activeColumns: any): any {
		// TODO: Clean this up (Move it to a model maybe? Can also improve performance here by caching all the things
		let returnResult: any = [];
		let columnWidth: number = 0;
		let displayColumnWidth: number = 0;
		let totalDays: number = 0;
		let totalValues: number[] = [];
		let first = activeColumns[activeColumns.length -1];
		let last = activeColumns[0];
		
		if (!this._myRoleId && this.planState.youSync){this._myRoleId = this.planState.youSync.data.roleId;}
		
		// Only process if we have at least two active days
		if (last && first){
			// Calculate the widths of our columns
			columnWidth = last.origFinishX - last.origStartX;
			displayColumnWidth = last.finishX - last.startX;
			
			// Determine how many days we have in the viewport range
			totalDays = dateCache.diffDays(last.activeDay, first.activeDay);
			let x = first.origFinishX;
			let screenX = first.startX;
			
			// Now we generate the data
			for (let i = 0; i <= totalDays; i++){
				// These are variable that change for each day. Store them in this scope
				let activeDay: string = this.planState.activeColumnService.guessDateFromX(x);
				let labourCount: LaborCount = this.query.getDay(activeDay);
				let combinedlabor: Map<string, ICombinedLabor> = new Map();
				
				// This tracks the total plannedAndLastPlanned values for the day
				let plannedAndActual: number = 0;
				let projectPlannedAndActual: number = 0;
				
				// We have crew size data for this day. Generate an ILaborDraw
				if (labourCount){
					// Crew Sizes data is a list of lists...So go deeper...
					for (let roleId in labourCount.view){
						// This controls our filter.
						if (!this.isRoleActive(roleId)){continue}
						
						// Generate the color
						let role: Role = this._cachedRoles.get(roleId);
						let roleColor: string = (role && role.color) ? role.color : "#000000";
						let color: number = this._cachedColors[roleId] || Utils.hexToNumber(roleColor);
						// We cache the color because turn the hex string to a number can be slow.
						// No reason to do the operation multiple times for the same input...
						this._cachedColors[roleId] = color;
						
						// I don't like this... Probably change.
						combinedlabor.set(roleId, {
							id: roleId,
							color: color,
							plannedAndActual: labourCount.view[roleId].plannedAndActual
						});
						
						plannedAndActual += labourCount.view[roleId].plannedAndActual;
					}
					
					// If we have a value higher than 0, add it to our total value tracker. We then pull the min and max values from this list
					if (plannedAndActual > 0){
						totalValues.push(plannedAndActual);
					}
				}
				
				// If we have project labor data, then we try to read it and add the value to our model
				if (this._projectLabor){
					let projectLabor: LaborCount = this._projectLabor.get(activeDay);
					if (projectLabor){
						for (let roleId in projectLabor.view){
							// This controls our filter.
							if (!this.isRoleActive(roleId) || roleId === "allRoles"){continue}
							projectPlannedAndActual += projectLabor.view[roleId].plannedAndActual;
						}
					}
				}
				
				// Add to our draw list
				returnResult.push({
						day: activeDay,
						labor: combinedlabor,
						startX: screenX,
						finishX: screenX + displayColumnWidth,
						plannedAndActual: plannedAndActual,
						projectPlannedAndLastPlanned: projectPlannedAndActual,
						width: displayColumnWidth
					}
				);
				x += columnWidth;
				screenX += displayColumnWidth;
			}
		}
		
		// Update our grid
		if (totalValues.length){
			let max: number = ss.max(totalValues);
			max = (max % 2 === 0) ? max : max += 1;
			let min: number = max / 2;
			this.updateGrid(min, max, activeColumns[0].finishX - activeColumns[0].startX);
		} else {
			this.updateGrid(0, 1, activeColumns[0].finishX - activeColumns[0].startX);
		}
		
		return returnResult
	}
	
	private _scaleDown(): void {
		this.view.style.width = this.renderer.width / this.renderer.resolution + 'px';
		this.view.style.height = this.renderer.height / this.renderer.resolution + 'px';
	}
	
	public updateGrid(min: number, max: number, columnWidth?: number): void {
		// Re-draw the UI...if necessary
		if (this.drawDays !== this._lastDrawDays){
			if (this.drawDays){
				this.projectText.visible = true;
				this.crewSizeIcon.visible = true;
				this.height = this.renderer.height;
			} else {
				this.projectText.visible = false;
				this.crewSizeIcon.visible = false;
				this.height = this.renderer.height + LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT;
			}
			
			this.drawUI();
			this._lastDrawDays = this.drawDays;
		}
		
		this.grid.yMaxRange = max;
		this.grid.yMinRange = min;
		
		this._maxText.text = "" + this.grid.yMaxRange;
		this._midText.text = (min === 0) ? "" : "" + this.grid.yMinRange;
		
		// Calculate the grid scale.
		const maxHeight = (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING_TOP;
		this.grid.yAxisScale = maxHeight / max;
		
		// Need to do this after setting scale.
		const maxHeight2 = (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING;
		
		// Now set positions
		this._minBar.y = maxHeight2 - this.grid.valueToPixelV(this.grid.yMinRange);
		
		this._maxText.x = LABOUR_COUNT_SETTINGS.Y_AXIS_X_OFFSET - this._maxText.width / 2;
		this._maxText.y = LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING_TOP + LABOUR_COUNT_SETTINGS.Y_AXIS_Y_OFFSET;
		
		this._midText.x = LABOUR_COUNT_SETTINGS.Y_AXIS_X_OFFSET - this._midText.width / 2;
		this._midText.y = this._minBar.y + LABOUR_COUNT_SETTINGS.Y_AXIS_Y_OFFSET;
		
		this.crewSizeIcon.x = LABOUR_COUNT_SETTINGS.Y_AXIS_X_OFFSET - this.crewSizeIcon.width / 2;
		this.crewSizeIcon.y = this.height - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING - (this.crewSizeIcon.height / 2);
	}
	
	public draw(visibleLabor: ILaborDraw[]): void {
		this.graphics.clear();
		this._planCountTextPool.reset();
		this._projectCountTextPool.reset();
		const maxHeight = (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING;
		visibleLabor.forEach((laborItem: ILaborDraw) => {
			let x: number = laborItem.startX;
			let w: number = laborItem.width;
			let sumValue: number = 0;
			laborItem.labor.forEach((labor: ICombinedLabor) => {
				const y: number = this.grid.valueToPixelV(labor.plannedAndActual);
				this.graphics.beginFill(labor.color, 1);
				this.graphics.drawRect(x + LABOUR_COUNT_SETTINGS.BAR_SPACING, (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.BAR_DRAW_Y_OFFSET - sumValue - y, w - LABOUR_COUNT_SETTINGS.BAR_SPACING * 2, y);
				this.graphics.endFill();
				sumValue += y;
			});
			
			if (laborItem.plannedAndActual && this.drawDays){
				let thing: HighResolutionText = this._planCountTextPool.grab() as HighResolutionText;
				thing.text = "" + laborItem.plannedAndActual;
				thing.active = true;
				thing.x = (x + LABOUR_COUNT_SETTINGS.BAR_SPACING) + ((w - LABOUR_COUNT_SETTINGS.BAR_SPACING * 2)) / 2 - (thing.width / 2);
				thing.y = maxHeight - sumValue - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING;
				if (thing.x <= LABOUR_COUNT_SETTINGS.Y_AXIS_X_OFFSET + 20){
					thing.visible = false;
				}
			}
			
			let newThingX: number = (x + LABOUR_COUNT_SETTINGS.BAR_SPACING) + ((w - LABOUR_COUNT_SETTINGS.BAR_SPACING * 2)) / 2;
			if (laborItem.projectPlannedAndLastPlanned && this.drawDays){
				let thing: HighResolutionText = this._projectCountTextPool.grab() as HighResolutionText;
				thing.text = "" + laborItem.projectPlannedAndLastPlanned;
				thing.active = true;
				thing.x = newThingX - (thing.width / 2);
				thing.y = 2;
				if (thing.x <= LABOUR_COUNT_SETTINGS.Y_AXIS_X_OFFSET + 20){
					thing.visible = false;
				}
			}
		});
		
		this.run();
	}
	
	/**
	 * This draws the grayish background and lines for the Y and X axes
	 */
	public drawUI(): void {
		this.uiGraphics.clear();
		this.backgroundGraphics.clear();
		
		// Draw the background shape
		this.backgroundGraphics.beginFill(LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_BACKGROUND_COLOR, LABOUR_COUNT_SETTINGS.BACKGROUND_ALPHA);
		this.backgroundGraphics.drawRect(0, 0, this.width, this.height);
		this.backgroundGraphics.endFill();
		
		// Draw the X-axis background
		if (this.drawDays){
			this.uiGraphics.beginFill(LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_BACKGROUND_COLOR, LABOUR_COUNT_SETTINGS.AXES_ALPHA);
			this.uiGraphics.drawRect(0, (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT, this.width, LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT);
			this.uiGraphics.endFill();
		}
		
		// Draw the Y-axis background
		this.uiGraphics.beginFill(LABOUR_COUNT_SETTINGS.TIMELINE_Y_AXIS_BACKGROUND_COLOR, 1);
		this.uiGraphics.drawRect(0, 0, LABOUR_COUNT_SETTINGS.TIMELINE_Y_AXIS_WIDTH, (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT);
		this.uiGraphics.endFill();
		
		// Draw the X-axis lines
		this.uiGraphics.lineStyle(1);
		this.uiGraphics.moveTo(LABOUR_COUNT_SETTINGS.TIMELINE_Y_AXIS_WIDTH, (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING);
		this.uiGraphics.lineTo(this.width, (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING);

		// Draw the Y-axis line
		this.uiGraphics.moveTo(LABOUR_COUNT_SETTINGS.TIMELINE_Y_AXIS_WIDTH, 0);
		this.uiGraphics.lineTo(LABOUR_COUNT_SETTINGS.TIMELINE_Y_AXIS_WIDTH, (this.height / this.renderer.resolution) - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_HEIGHT - LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING);
		this.uiGraphics.lineStyle(0);
		
		// Draw the grid
		this._drawGrid(LABOUR_COUNT_SETTINGS.TIMELINE_Y_AXIS_WIDTH, LABOUR_COUNT_SETTINGS.TIMELINE_X_AXIS_SPACING_TOP, LABOUR_COUNT_SETTINGS.GRID_SPACING);
	}
	
	private _drawGrid(startX: number, startY: number, spacing: number): void {
		this.uiGraphics.lineStyle(1, LABOUR_COUNT_SETTINGS.GRID_COLOR);
		// Draw max
		this.uiGraphics.moveTo(startX, startY);
		this.uiGraphics.lineTo(this.width / this.renderer.resolution, startY);
		
		this.uiGraphics.lineStyle(0);
	}
	
	public destroy(destroyOptions?): void {
		this._shutdownSubject$.next(true);
		
		this._destroyList.forEach((thing) => {
			thing.destroy();
			thing = null;
		});
		
		window.removeEventListener("throttledResize", this.onResize);
		
		this.planState.activeColumnService.unsubscribe(this._clearActiveColumnSub);
		
		// Null the things
		this.view = null;
		this.bounds = null;
		this._cachedDays = null;
		this._projectLabor = null;
		this._cachedRoles = null;
		this._visibleLabor = null;
		this._lastStart = null;
		this._lastEnd = null;
		this._filterBy = null;
		this._myRoleId = null;
		this._cachedColors = null;
		this._clearActiveColumnSub = null;
		
	}
	
	public run = () => {
		if (this.crewSizeIcon.texture.width <= 1){
			this.crewSizeIcon.texture = utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CREW_ICON_NAME];
		}
		this.camera.update();
		// We only want horizontal lines
		this.render();
	};

	public safeRender = () => {
		renderQueue.push(this.render, "draw");
	};
	
	public render = () => {
		this.renderer.render(this.stage);
	};
}
