'use strict';
import * as angular from "angular";

declare const window: any;
import * as PIXI from "pixi.js";

/**
 * @namespace ProjectView
 */
angular.module('ProjectView').factory("projectViewConfig", ProjectViewConfig);
function ProjectViewConfig(){
	var Config = {
		CORE: {
			HORIZONAL_SCALE: 1, // Sets the horizontal scale value for the project view. Value of 2 = data will be drawn twice as wide.
			BACKGROUND_COLOR: '#FFFFFF', // Sets the background color of the renderer
			ANTI_ALIAS: true, // sets antialias (only applicable in chrome at the moment)
			ROUND_PIXELS: true, // If true, will Math.floor() x/y values when rendering, stopping pixel interpol
			TRANSPARENT_RENDER: false, // If the render view is transparent, default false
			PRESERVE_DRAWING_BUFFER: false, // enables drawing buffer preservation, enable this if you need to call toDataUrl on the webgl context
			FORCE_CANVAS: false, // prevents selection of WebGL renderer, even if such is present
			LEGACY_MODE: false, // If true, will aim to ensure compatibility with older / less advanced devices. If you experience unexplained flickering try setting this to true.
			CLEAR_BEFORE_RENDER: true, // This sets if the CanvasRenderer will clear the canvas or not before the new render pass.
			FORCE_FXAA: false, // forces FXAA antialiasing to be used over native. FXAA is faster, but may not always look as good
			VERTICAL_SPACING: 6  // The amount of vertical spacing added to the See All transform
		},
		TIMELINE:{ // Used to customize timeline
			HEIGHT: 24, // Controls the height of the timeline graphic
			BACKGROUND_COLOR: '#999999', // Sets the background color of the timeline graphic
			BACKGROUND_ALPHA: 1, // Sets the background alpha of the timeline
			LINE_COLOR: '#999999', // Sets the line color of the timeline graphic
			LINE_SIZE: 0, // Sets the size of the boundary line of the timeline background
			LINE_ALPHA: 0, // Sets the alpha value of the boundary line of the timeline background
			COLUMN_WIDTH: 100, // Sets the width of the timeline columns.
			NATIVE_LINES: true // Voodoo to make lines draw sharper
		},
		TIMELINE_TEXT: { // Used to customize timeline date labels.
			fontFamily: 'Roboto', // The font family
			fontSize: 12, // The font size
			fill: 0xFFFFFF, // The font color
			fontWeight: '300', // The font weight
			resolution: 1, // Controls the text texture render resolution. Higher value = more VRAM, but crispier text at extreme zoom levels. Keep to 1 if text never scales
			DATE_FORMAT: "MMM DD YYYY" // Sets the date format of the timeline date labels
		},
		TIMELINE_LINES:{ // Used to customize timeline vertical lines
			LINE_COLOR: '#DCDCDC', // Sets the color of the today line
			LINE_ALPHA: 1, // Sets the alpha value of the today line
			LINE_SIZE: 1, // Sets the size of the today line. c
			NATIVE_LINES: true
		},
		TODAY_LINE:{
			LINE_COLOR: '#474242', // Sets the color of the today line
			LINE_ALPHA: 1, // Sets the alpha value of the today line
			LINE_SIZE: 3, // Sets the size of the today line.
			NATIVE_LINES: true
		},
		TODAY_TEXT: { // See this page for styling docs: http://pixijs.download/v4.5.1/docs/PIXI.TextStyle.html
			fontFamily: 'Roboto', // The font family
			fontSize: 14, // The font size
			fill: 0x626262, // The font color
			fontWeight: '600', // The font weight
			resolution: 4 // Controls the text texture render resolution. Higher value = more VRAM, but crispier text at extreme zoom levels.
		},
		VIEWPORT: {
			ZOOM_METHOD: 'smooth', // The zoom method. 'smooth' or 'fixed'
			ZOOM_SMOOTHNESS: 0.0001, // The smoothness of the zoom. Only used if 'smooth'
			ZOOM_INTENSITY_PINCH: 1.025, // The power/velocity of the zoom when used with a pinch.
			ZOOM_INTENSITY: 1.05, // The 'power' of the zoom.
			MAX_ZOOM: 20, // The max amount of linear zoom applied.
		},
		GUIDE_LINES: {
			FILL_COLOR: '#f1f1f1', // The color used to fill the space between the two boundary guidelines
			LINE_COLOR: '#EFEFED', // The color of the boundary lines for the guideline viewport
			LINE_ALPHA: 1, // The alpha of the guideline line fill
			FILL_ALPHA: 1, // The alpha of the guideline fill
			LINE_SIZE: 0 // Sets the line thickness of the guidelines.
		},
		ACTIVE_LINE: {
			LINE_COLOR: '#474242', // The fill color of the active line
			LINE_ALPHA: 0.8, // The alpha value of the active line
			LINE_SIZE: 1, // The line thickness for the active line
			NATIVE_LINES: true
		},
		DIVIDER_LINE: {
			FILL_COLOR: '#474242',
			FILL_ALPHA: 0.1, // The line alpha value
			LINE_SIZE: 2 // The line size.
		},
		PLAN_BARS: {
			BACKGROUND_COLOR: '#97a7ad', // Sets the plan bar background color
			BACKGROUND_ALPHA: 1, // Sets the plan bar background alpha
			SELECT_COLOR: '#c7d3d9', // Sets the color used when a plan bar is selected
			SELECT_ALPHA: 0.66, // The alpha of the select fill
			INITIAL_HEIGHT: 20, // Sets the height for each plan bar
			INITIAL_SPACING: 18, // Sets the individual spacing between each plan bar
			NO_DATES_OPTIONS: {
				DEFAULT_WIDTH_NO_DATES: 30, // The default width of a plan, in days. Applied to plans with no dates.
				NO_DATES_LINE_SIZE: 1, // The line size of the plan bar outline. Applied to plans with no dates.
				NO_DATES_LINE_COLOR: '#97a7ad', // The line color of the plan bar outline. Applied to plans with no dates.
				NO_DATES_FILL_COLOR: '#FFFFFF', // A hack. We still fill in this shape to make it easier to select. Applied to plans with no dates.
				NO_DATES_FILL_ALPHA: 1, // Fill alpha. Applied when plans have no dates.
				NO_DATES_LINE_ALPHA: 1 // The line alpha. Applied to plans with no dates.
			}
		},
		TICKET_STATUS: {
			FILL_COLOR_COMPLETE: '#0000fe', // The fill color of the 'complete' bar
			FILL_ALPHA_COMPLETE: 1, // The alpha of the complete bar
			FILL_COLOR_INCOMPLETE: '#82ff7d', // The fill color of the 'incomplete' bar
			FILL_ALPHA_INCOMPLETE: 1, // The alpha of the incomplete bar
			COMPLETE_BAR_HEIGHT: 6, // The height of the complete bar
			COMPLETE_BAR_OFFSET: 7, // The vertical draw offset of the complete bar
			INCOMPLETE_BAR_HEIGHT: 6, // The height of the incomplete bar
			INCOMPLETE_BAR_OFFSET: 7 // The vertical draw offset of the incomplete bar
		},
		PLAN_NAME_TEXT: {
			fontFamily: 'Roboto', // The font family
			fontSize: 14, // The font size
			fill: 0x626262, // The font color
			fontWeight: '600', // The font weight
			resolution: 4, // Controls the text texture render resolution. Higher value = more VRAM, but crispier text at extreme zoom levels.
			DRAW_OFFSET: 5, // Applies a drawing offset to the Plan name text. Used when the 'Toggle Ahead/Behind Text' is false.
			DRAW_OFFSET_FULL: 11, // Applies a drawing offset to the Plan name text. Used when the 'Toggle Ahead/Behind Text' is true.
		},
		PLAN_AHEAD_BEHIND_TEXT: {
			fontFamily: 'Roboto',
			fontSize: 11,
			fill: 0x8A8A8A,
			fontWeight: '400',
			resolution: 4, // Controls the text texture render resolution. Higher value = more VRAM, but crispier text at extreme zoom levels.
			AHEAD_COLOR: 0x7ad325,
			BEHIND_COLOR: 0xe13636,
			SPACING: 1
		},
		CURRENT_PLAN_DATE_TEXT: {
			fontFamily: 'Roboto',
			fontSize: 11,
			fill: 0x8A8A8A,
			fontWeight: '400',
			resolution: 4
		}
	};

	return Config;
}

angular.module('ticketspaceApp').constant("PIXI", PIXI);
