import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Location } from "@angular/common";

@Component({
	selector: 'nav-bar',
	template: require('./nav-bar.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent implements OnInit {
	
	@Input() hideLogo: boolean = false;
	public you;
	constructor(private location: Location) {}

	ngOnInit() {
	}
	
	public goBack(): void {
		this.location.back();
	}
}
