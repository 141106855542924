'use strict';

/*
	Helper class which represents a column on the pull side.
	Used to sort tickets into lists by column, so it is relatively easy to determine which column a ticket is in.
 */

/***
 * Creates a new column.
 * @param data
 * @constructor
 */
export default function Column(data){
	if (!data || !data.startX){throw "Invalid data parameter in Column constructor."}
	this.list = data.list || [];
	this.startX = data.startX;
	this.finishX = data.finishX;
	this.width = this.getWidth();
	this.forceX = data.forceX || false;
};

Column.prototype.add = function(thing){
	this.list.push(thing);
};

Column.prototype.getById = function(id){
	var l = this.list.length;
	for (var i = 0; i < l; i++){
		if (this.list[i].$id === id){return this.list[i]}
	}
	// No match.
	return false;
};

Column.prototype.getWidth = function(){
	return this.finishX - this.startX;
};

Column.prototype.inBounds = function(thingPos){
	var center = thingPos.left + thingPos.width / 2;
	return (center >= this.startX && center <= this.finishX)
};

Column.prototype.align = function(id){
	if (this.forceX){ this.startX = this.forceX;}
	var thing = this.getById(id);
	if (thing){
		thing.data.left = this.startX;
	} else {
		this.alignAll();
	}
};

Column.prototype.alignAll = function(){
	if (this.forceX){ this.startX = this.forceX;}
	var self = this;
	this.list.forEach(function(thing){
		thing.data.left = self.startX;
	})
};

Column.prototype.save = function(){
	var fbChangedTickets = [];
	this.list.forEach(function(ticket){
		fbChangedTickets.push({
			"id": ticket.$id,
			"top": ticket.data.top,
			"left": ticket.data.left
		});
	});
	return fbChangedTickets;
};

Column.prototype.calculateMode = function(numberList){
	var count = -100000;
	var countMap = {};
	var result;
	for (var i = 0; i < numberList.length; i++){
		countMap[numberList[i]] = (countMap[numberList[i]] || 0) + 1;
		if (countMap[numberList[i]] > count){
			count = countMap[numberList[i]];
			result = numberList[i];
		}
	}
	return result;
};

Column.prototype.verify = function(activeLineX){
	var self = this;
	var cnfCount = 0;
	this.list.forEach(function(ticket){
		for (var i = 0; i < self.list.length; i++){
			if (ticket.$id === self.list[i].$id) { continue; }
			if (ticket.data.left === self.list[i].data.left && ticket.data.top === self.list[i].data.top){
				cnfCount++;
				var xBefore = self.list[i].data.left;
				if (activeLineX && xBefore > activeLineX && (self.list[i].data.left - 50 * cnfCount) < activeLineX){
					ticket.data.left = xBefore;
					self.list[i].data.top -= (50 * cnfCount);
				} else {
					self.list[i].data.left -= (50 * cnfCount);
				}
			}
		}
	});
};
