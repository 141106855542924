export class Easing {
	public static BACK = {
		/**
		 * http://easings.net/#easeInBack
		 * @param {number} k
		 */
		IN(k: number): number {
			let s, a = 0.1, p = 0.4;
			if ( k == 0 ) return 0; if ( k == 1 ) return 1; if ( !p ) p = 0.3;
			if ( !a || a < 1 ) { a = 1; s = p / 4; }
			else s = p / ( 2 * Math.PI ) * Math.asin( 1 / a );
			return - ( a * Math.pow( 2, 10 * ( k -= 1 ) ) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) );
		},
		/**
		 * http://easings.net/#easeOutBack
		 * @param {number} k
		 */
		OUT(k: number): number {
			let s, a = 0.1, p = 0.4;
			if ( k == 0 ) return 0; if ( k == 1 ) return 1; if ( !p ) p = 0.3;
			if ( !a || a < 1 ) { a = 1; s = p / 4; }
			else s = p / ( 2 * Math.PI ) * Math.asin( 1 / a );
			return ( a * Math.pow( 2, - 10 * k) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) + 1 );
			
		},
		/**
		 * http://easings.net/#easeInOutBack
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			let s, a = 0.1, p = 0.4;
			if ( k == 0 ) return 0; if ( k == 1 ) return 1; if ( !p ) p = 0.3;
			if ( !a || a < 1 ) { a = 1; s = p / 4; }
			else s = p / ( 2 * Math.PI ) * Math.asin( 1 / a );
			if ( ( k *= 2 ) < 1 ) return - 0.5 * ( a * Math.pow( 2, 10 * ( k -= 1 ) ) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) );
			return a * Math.pow( 2, -10 * ( k -= 1 ) ) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) * 0.5 + 1;
			
		}
	};
	/**
	 * Make bouncy
	 */
	public static BOUNCE = {
		/**
		 * http://easings.net/#easeInBounce
		 * @param {number} k
		 */
		IN(k: number): number {
			return 1 - Easing.BOUNCE.OUT( 1 - k );
		},
		/**
		 * http://easings.net/#easeOutBounce
		 * @param {number} k
		 */
		OUT(k: number): number {
			if ( ( k /= 1 ) < ( 1 / 2.75 ) ) {
				return 7.5625 * k * k;
			} else if ( k < ( 2 / 2.75 ) ) {
				return 7.5625 * ( k -= ( 1.5 / 2.75 ) ) * k + 0.75;
			} else if ( k < ( 2.5 / 2.75 ) ) {
				return 7.5625 * ( k -= ( 2.25 / 2.75 ) ) * k + 0.9375;
			} else {
				return 7.5625 * ( k -= ( 2.625 / 2.75 ) ) * k + 0.984375;
			}
		},
		/**
		 * http://easings.net/#easeInOutBounce
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			if ( k < 0.5 ) return Easing.BOUNCE.IN( k * 2 ) * 0.5;
			return Easing.BOUNCE.OUT( k * 2 - 1 ) * 0.5 + 0.5;
		}
	};
	
	public static CIRCULAR = {
		/**
		 * http://easings.net/#easeInCirc
		 * @param {number} k
		 */
		IN(k: number): number {
			return - ( Math.sqrt( 1 - k * k ) - 1);
		},
		/**
		 * http://easings.net/#easeOutCirc
		 * @param {number} k
		 */
		OUT(k: number): number {
			return Math.sqrt( 1 - (--k * k) );
		},
		/**
		 * http://easings.net/#easeInOutCirc
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			if ( ( k /= 0.5 ) < 1) return - 0.5 * ( Math.sqrt( 1 - k * k) - 1);
			return 0.5 * ( Math.sqrt( 1 - ( k -= 2) * k) + 1);
		}
	};
	
	public static CUBIC = {
		/**
		 * http://easings.net/#easeInCubic
		 * @param {number} k
		 */
		IN(k: number): number {
			return k * k * k;
		},
		/**
		 * http://easings.net/#easeOutCubic
		 * @param {number} k
		 */
		OUT(k: number): number {
			return --k * k * k + 1;
		},
		/**
		 * http://easings.net/#easeInOutCubic
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			if ( ( k *= 2 ) < 1 ) return 0.5 * k * k * k;
			return 0.5 * ( ( k -= 2 ) * k * k + 2 );
		}
	};
	
	public static ELASTIC = {
		/**
		 * http://easings.net/#easeInElastic
		 * @param {number} k
		 */
		IN(k: number): number {
			let s, a = 0.1, p = 0.4;
			if ( k == 0 ) return 0; if ( k == 1 ) return 1; if ( !p ) p = 0.3;
			if ( !a || a < 1 ) { a = 1; s = p / 4; }
			else s = p / ( 2 * Math.PI ) * Math.asin( 1 / a );
			return - ( a * Math.pow( 2, 10 * ( k -= 1 ) ) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) );
		},
		/**
		 * http://easings.net/#easeOutElastic
		 * @param {number} k
		 */
		OUT(k: number): number {
			let s, a = 0.1, p = 0.4;
			if ( k == 0 ) return 0; if ( k == 1 ) return 1; if ( !p ) p = 0.3;
			if ( !a || a < 1 ) { a = 1; s = p / 4; }
			else s = p / ( 2 * Math.PI ) * Math.asin( 1 / a );
			return ( a * Math.pow( 2, - 10 * k) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) + 1 );
		},
		/**
		 * http://easings.net/#easeInOutElastic
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			let s, a = 0.1, p = 0.4;
			if ( k == 0 ) return 0; if ( k == 1 ) return 1; if ( !p ) p = 0.3;
			if ( !a || a < 1 ) { a = 1; s = p / 4; }
			else s = p / ( 2 * Math.PI ) * Math.asin( 1 / a );
			if ( ( k *= 2 ) < 1 ) return - 0.5 * ( a * Math.pow( 2, 10 * ( k -= 1 ) ) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) );
			return a * Math.pow( 2, -10 * ( k -= 1 ) ) * Math.sin( ( k - s ) * ( 2 * Math.PI ) / p ) * 0.5 + 1;
		}
	};
	
	public static EXPONENTIAL = {
		/**
		 * http://easings.net/#easeInExpo
		 * @param {number} k
		 */
		IN(k: number): number {
			return k == 0 ? 0 : Math.pow( 2, 10 * ( k - 1 ) );
		},
		/**
		 *http://easings.net/#easeOutExpo
		 * @param {number} k
		 */
		OUT(k: number): number {
			return k == 1 ? 1 : - Math.pow( 2, - 10 * k ) + 1;
		},
		/**
		 * http://easings.net/#easeInOutExpo
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			if ( k == 0 ) return 0;
			if ( k == 1 ) return 1;
			if ( ( k *= 2 ) < 1 ) return 0.5 * Math.pow( 2, 10 * ( k - 1 ) );
			return 0.5 * ( - Math.pow( 2, - 10 * ( k - 1 ) ) + 2 );
		}
	};
	
	public static QUADRATIC = {
		/**
		 * http://easings.net/#easeInQuad
		 * @param {number} k
		 */
		IN(k: number): number {
			return k * k;
		},
		/**
		 * http://easings.net/#easeOutQuad
		 * @param {number} k
		 */
		OUT(k: number): number {
			return - k * ( k - 2 );
		},
		/**
		 * http://easings.net/#easeInOutQuad
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			if ((k *= 2) < 1) return 0.5 * k * k;
			return - 0.5 * (--k * (k - 2) - 1);
		}
	};
	
	public static QUARTIC = {
		/**
		 * http://easings.net/#easeInQuart
		 * @param {number} k
		 */
		IN(k: number): number {
			return k * k * k * k;
		},
		/**
		 * http://easings.net/#easeOutQuart
		 * @param {number} k
		 */
		OUT(k: number): number {
			return - ( --k * k * k * k - 1 );
		},
		/**
		 * http://easings.net/#easeInOutQuart
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			if ( ( k *= 2 ) < 1) return 0.5 * k * k * k * k;
			return - 0.5 * ( ( k -= 2 ) * k * k * k - 2 );
		}
	};
	
	public static QUINTIC = {
		/**
		 * http://easings.net/#easeInQuint
		 * @param {number} k
		 */
		IN(k: number): number {
			return k * k * k * k * k;
		},
		/**
		 * http://easings.net/#easeOutQuint
		 * @param {number} k
		 */
		OUT(k: number): number {
			return ( k = k - 1 ) * k * k * k * k + 1;
		},
		/**
		 * http://easings.net/#easeInOutQuint
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			if ( ( k *= 2 ) < 1 ) return 0.5 * k * k * k * k * k;
			return 0.5 * ( ( k -= 2 ) * k * k * k * k + 2 );
		}
	};
	
	public static SINUSOIDAL = {
		/**
		 * http://easings.net/#easeInSine
		 * @param {number} k
		 */
		IN(k: number): number {
			return - Math.cos( k * Math.PI / 2 ) + 1;
		},
		/**
		 * http://easings.net/#easeOutSine
		 * @param {number} k
		 */
		OUT(k: number): number {
			return Math.sin( k * Math.PI / 2 );
		},
		/**
		 * http://easings.net/#easeInOutSine
		 * @param {number} k
		 */
		IN_OUT(k: number): number {
			return - 0.5 * ( Math.cos( Math.PI * k ) - 1 );
		}
	};
	
	public static LINEAR(k: number): number {
		return k
	};
}