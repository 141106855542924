'use strict';

/// <reference types="@types/jquery" />

// import "zone.js";
declare const Zone: any;

import * as angular from "angular";
import * as moment from "moment";
import * as tinycolor from "tinycolor2";
import { Point, Rectangle } from "pixi.js";
import {empty} from "rxjs";

import { Observable, Subscriber } from "rxjs";
import { concat, scan } from "rxjs/operators";
// import { subscribeToPromise } from "rxjs/internal/util/subscribeToPromise";

import { Ticket } from "../../ng2/common/models/Ticket";

declare const window;
declare const Intercom:any;
// import * as $ from "jQuery";
// 
if(Map){
	(<any>Map.prototype).first = function(){
		console.log('debug code, don\'t use');
		return this.size > 0 ? this.values().next().value : null;
	}
}


//pads 0s
//n - number, width - number to pad, z - character to pad
export function pad(n, width, z?){
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

// a proper modulo that handles negative numbers
export function properMod(n, m){
	return ((n % m) + m) % m;
}

export function seizure(){
	setInterval(function(){
		$('.zoomContent-underlay-ui').css({'background': randomColor()});
	}, 80);
}

//randomColor()
// returns a random golden ratio color in a hex string
export const randomColor = (function(){
	var ratio = 0.618033988749895;
	var counter = 0;
	/* accepts parameters
 * h  Object = {h:x, s:y, v:z}
 * OR
 * h, s, v
*/
	function HSVtoRGB(h, s, v) {
    var r, g, b, i, f, p, q, t;
    if (arguments.length === 1) {
        s = h.s, v = h.v, h = h.h;
    }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    };
	}


	function doAThing(countRange?){
		//console.log('countRange', countRange);
		if(!countRange){ var hue = Math.random(); }
		else{ var hue = counter += Number(countRange); }
		hue += ratio;
		hue % 1;

		// var rgb = HSVtoRGB(hue, 0.5, 0.95);
		// var hex = `rgba(${rgb.r},${rgb.g},${rgb.b},0.2)`;
		
		var rgb = HSVtoRGB(hue, 0.5, 0.95);
		var hex = "#"+rgb.r.toString(16)+rgb.g.toString(16)+rgb.b.toString(16);
		return hex;
	}
	return doAThing;
})();


export function goodNumber(num){ return num === null || !isNaN(num); }

export function clipString(string, length?){
	if(!length){ length = 50; }
	if(!string){return "";}
	if(string.length > length){ return string.substring(0, length) + "...";}
	return string;
}

//const $timeout = utils.angularInjector("$timeout");
export function angularInjector(serviceName:string):any{
	return angular.element(document).injector().get(serviceName);
}

export function makeStack(error){
	var extra:any = {};
	try{ throw error; }
	catch(e){
		extra.stack = e.stack || null;
		extra.description = e.description || null;
	}
	// console.log('extra', extra.stack);
	return extra;
}

//escapes an email for use as a firebase key
export function escapeEmail(email) {
	if (!email) { return false; }
	// Replace '.' (not allowed in a Firebase key) with ',' (not allowed in an email address)
	email = email.toLowerCase();
	email = email.replace(/\./g, ',');
	return email;
}

//returns the function signature of the passed in function
export function signature(fn){
	if(!fn){return;}
	if(typeof fn === "function"){
		var val = fn.toString().match(/^[^{]+/);
		return val ? val[0] : false;
	}
}

export function debugPromise(p){
	if(!p.then){console.log('this is not a promise'); return;}
	p.then(function(data){
		console.log('then hit', data);
	})
	.catch(function(err){
		console.log('catch hit', err);
	});
	return p;
}

export function shiftWeekStartToMonday(dayOfWeek){
	if(dayOfWeek === 0){return 6;}
	return dayOfWeek - 1;
}

export function tryThenFail(fn, tryAttempts, failTime, ctx){
	//consider actually throwing an error here
	if(isNaN(tryAttempts) || isNaN(failTime)){console.error('attempts and time need to be set'); return}
	var intervalTime = failTime / tryAttempts;
	var ticks = 0;
	setTimeout(test, intervalTime);
	function test(){
		ticks++;
		var result = fn.call(ctx, true);
		if(result){
			fn.call(ctx, true, true);
			return;
		}
		if(ticks < tryAttempts){ setTimeout(test, intervalTime); }
		else{
			fn.call(ctx, false);
		}
	}
}

export function dumpScope(){
	var scopes = [];
	['PCScope','MCScope'].forEach(function(scope){
		if(window[scope]){
			var temp = {};
			$loop(window[scope], function(val, key){
				if(typeof val !== 'function'){
					temp[key] = val;
				}
			});
			scopes.push(temp);
		}
	});

	var cache = [];
	return JSON.stringify(scopes, function(key, value) {
		if (typeof value === 'object' && value !== null) {
			if (cache.indexOf(value) !== -1) {
				// Circular reference found, discard key
				return '[Circular reference]';
			}
			// Store value in our collection
			cache.push(value);
		}
		return value;
	});
	//cache = null; // Enable garbage collection

	//return scopes;//JSON.stringify(scopes, censor(scopes));
}

// Bind a context to a function
export function bind(ctx, fn) {
	return function(){ return fn.apply(ctx, arguments) }
}

//combine two arrays, discarding duplicates
export const arrayMerge = function(a, b) {
	var hash = {}, i;
	for (i=0; i<a.length; i++) {
		hash[a[i]]=true;
	}
	for (i=0; i<b.length; i++) {
		hash[b[i]]=true;
	}
	return Object.keys(hash);
};

/*
	Usage:
	return sortedArr.sort(sortBy(<key>, false, <parseFloat, getDate, etc>));
 */
export function sortBy(field, reverse, primer) {
	if (primer === 'date'){primer = function(dateString){return new Date(dateString).getTime();}}
	var key = primer ? function (x) {
		return primer(x[field])
	} : function (x) {
		return x[field]
	};
	reverse = !reverse ? 1 : -1;
	return function (c, d) {
		var a = key(c);
		var b = key(d);
		return reverse * ((a > b) as any - ((b > a) as any));
	}
};

//### debug()
// Goal will be to migrate all debug functions/ values to use this
//* debug.add(key, val) - added a new value to the debug list
//* debug.* - all values added are exposed on the debug list

export const debug = (function(){
	var enabled = true;
	var bucket:any = {};
	var supportList = ['disableRequestDeletionOnSuccess', 'cullTickets', 'disableCompletedCheck'];

	bucket.add = add;

	function add(key, val){
		if(!enabled){ return null; }
		if(key === undefined || key === null){ return null; }
		if(supportList.indexOf(key) === -1){ return null; }

		bucket[key] = val;
		//console.log('val',bucket); //not getting here
	}
	return bucket;
})();
window.debug = debug;

// Creates a map of error messages with a timeStamp. If new timeStamp is too recent compared to old timestamp, no cally callback.
export function throttleError( fn, difference? ) {
	var map = {};
	var dif = difference || -10000;
	return function( message, severity? ){
		var timeStamp = new Date().getTime();
		if ( map[ message ] && ( map[ message ] - timeStamp ) > dif ) { return; }
		else if ( map[ message ] ) {
			map[ message ] = timeStamp;
			fn.apply( null, arguments );
			return;
		}
		// No error mapped. Add it to map.
		map[ message ] = timeStamp;
		fn.apply( null, arguments );
	}
}

// Run a fn repeatedy at most once per <interval> milliseconds
export function throttle(fn, interval?){
	interval = interval || 100;
	var timer = null;
	return function () {
		var context = this, args = arguments;
		if(timer){ return } else {
			timer = setTimeout(function () {
				fn.apply(context, args);
				timer = false
			}, interval)
		}
	}
}



/**
 * Debounce a function so repeated calls that happen in less then <delay>
 * millesconds get squelched
 * @param  {Function}  fn [function to be debounced]
 * @param  {number =  1000}        delay [delay]
 * @return {Function}     [function you actually call]
 */
export function soak(fn: Function, delay:number = 1000):Function {
	var timer = null;
	return function () {
		var context = this, args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
}


// Convert an object to an array, discards keys.
export function objectToArray(o){
	var a = [];
	for(var i in o) if (o.hasOwnProperty(i)) {
		a.push(o[i]);
	}
	return a;
}

// Fixed point function combinator
export function Y(f) {
	return (function(g){ return g(g) })(function(h) {
		return function(){ return f(h(h)).apply(null, arguments) }
	})
};

export function relationIterator(target, cb, context?){
	if(!target || typeof target !== "object"){ return; }
	if(target.data){ target = target.data; }
	var cessors = ['successors','predecessors'];
	cessors.forEach(function(cessor, cessorToggle){
		var oppositeCessor = cessors[(cessorToggle+1)%cessors.length];
		$loop(target[cessor],function(plan, planId){
			$loop(plan, function(__, otherTicketId){
				cb.call(context, {
					id: otherTicketId,
					planId: planId,
					cessor: cessor,
					oppositeCessor: oppositeCessor
				});
			});
		});
	});
}

//### getNested(obj, key)
// returns the value of obj[key] taking into account any potential nesting of key.
// Nesting being speced as "part1.part2.part3"
export function getNested(obj, key){
	return key.split(".").reduce(function(o, x) {
			return (typeof o == "undefined" || o === null) ? o : o[x];
	}, obj);
}

export function checkNested(obj, ...theRest /*, level1, level2, ... levelN*/) {
	for (var i = 0; i < theRest.length; i++) {
		if (!obj.hasOwnProperty(theRest[i])) { return false; }
		obj = obj[theRest[i]];
	}
	return true;
}
/** special variant of checkNested that considers a null-y value to not be there */
export function checkNestedOrNull(obj, ...theRest /*, level1, level2, ... levelN*/) {
	for (var i = 0; i < theRest.length; i++) {
		if (!obj.hasOwnProperty(theRest[i]) || obj[theRest[i]] === undefined || obj[theRest[i]] === null) { return false; }
		obj = obj[theRest[i]];
	}
	return true;
}

/**
 * set a deeply nested value, creating the tree as necessary
 * @param  obj        The object to write to
 * @param  value      The value to be written
 * @param  ...theRest Key names to nest with
 */
export function setNested(obj, value, ...theRest /*, level1, level2, ... levelN*/) {
	var iter = obj;
	for (var i = 0; i < theRest.length; i++) {
		if (!iter[theRest[i]]){ iter[theRest[i]] = {}; }
		if(i < theRest.length-1){ iter = iter[theRest[i]]; }
		else{ iter[theRest[i]] = value; }
	}
	iter = value;
}
export function checkNestedProto(obj, ...theRest /*, level1, level2, ... levelN*/) {
	for (var i = 0; i < theRest.length; i++) {
		if (!obj[theRest[i]]) { return false; }
		obj = obj[theRest[i]];
	}
	return true;
}

//compares an old/new date, whichever is smaller is returned
export function minDate(oldVal, newVal){
	if(oldVal)
	{
		if (moment(newVal).isBefore(oldVal)) { return newVal; }
		else { return oldVal; }
	}
	else
		return newVal;
};

export function $loop(obj, fn, ctx?){
	for(var i in obj){
		if(!(i.charAt(0) == '$') && obj.hasOwnProperty(i)){
			fn.call(ctx, obj[i], i, obj)
		}
	}
}

export const variableInterval = (function(){
	//called with new
	function interval(args?):void{
		if ( !(this instanceof interval) ){ return new interval()};
		var cb = args[0];
		var initialDelay = args[1];
		var remainingArgs = [];
		if(args.length > 2){
			for(var i = 2; i < args.length; i++){
				remainingArgs.push(args[i]);
			}
		}

		var timeoutId;
		var self = this;

		this.debug = false;
		this.callback = cb;
		this.delay = initialDelay;
		this.timeSum = 0;
		this.lastDiff = 0;
		this.clear = function(){
			clearTimeout(timeoutId);
		};

		function resetDelay(){
			//restarts the tick using the combination of the time remaining in the tick
			//and the new delay
			var dateDiff = Date.now() - this.startTime;
			var tempDelay = this.delay - dateDiff - this.lastDiff;
			if(tempDelay < 0){tempDelay = 0;}// this.lastDiff = 0;}
			this.clear();
			//console.log('tempDelay', tempDelay, this.lastDiff, dateDiff);

			startTick(tempDelay);
			this.lastDiff = dateDiff + this.lastDiff;

			//continue here
			//need to modify based on old delay applied to old tick
		}

		this.setDelay = function setDelay(val){
			if(!val || val < 0){ val = 0; }
			this.delay = val;
			resetDelay.call(self);
		}

		this.incrementDelay = function incrementDelay(val){
			var min = 5;
			if(!val){ val = 1; }
			this.delay += val;
			if(this.delay < min){ this.delay = min; }

			//resetDelay.apply(this);
		};
		this.decrementDelay = function decrementDelay(val){
			self.incrementDelay(-val);
		};

		//special first tick that doesn't trigger the callback
		function startTick(initialDelay?){
			if(initialDelay || initialDelay === 0){ timeoutId = setTimeout(tick, initialDelay); }
			else{ timeoutId = setTimeout(tick, self.delay); }
			self.startTime = Date.now();
			//self.timeSum = 0;
		}

		function tick(initialDelay){
			if(initialDelay || initialDelay === 0){ timeoutId = setTimeout(tick, initialDelay); }
			else{ timeoutId = setTimeout(tick, self.delay); }

			self.startTime = Date.now();
			self.lastDiff = 0;

			cb.apply(self, remainingArgs);

			if(self.debug){ console.log('delay', self.delay, 'id', timeoutId); }
		}
		startTick();
	}

	var list = [];

	function set(){
		var i = new interval(arguments);
		list.push(i);
		return i;
	}

	//todo, implement some selector (not entirely necessary)
	function clear(){
		console.log('list', list);
		for(var i = 0; i < list.length; i++){
			list[i].clear();
		}
		list = [];
	}

	return {
		set: set,
		clear: clear
	}
})();

export const analyticsHelper = (function(){
	var global = {}

	var history = [];

	function addHistory(path, extra){
		if(!path){return;}
		var obj = {
			path: path,
			extra: extra || null,
			timestamp: moment().format()
		}
		history.push(obj);
		if(history.length > 20){
			history.shift();
		}
	}

	function mergeProps(newProps, oldProps){
		oldProps = oldProps || {};
		var obj = angular.extend({}, oldProps);
		for(var key in newProps){
			if(newProps.hasOwnProperty(key)){
				obj[key] = newProps[key];
			}
		}
		return obj;
	}

	function setGlobal(properties, replace?){
		if(replace){ global = properties; return; }
		global = mergeProps(properties, global);
	}

	function track(event, properties?, options?, callback?){
		var props = mergeProps(properties, global);
		//addHistory(event, properties);
		var result = analytics.track(event, props, options, function(){
			if(Intercom){Intercom('update');}
			else{
				console.log('Intercom isn\'t defined');
			}
			if(callback){ callback(); }
		});
		return result;
	}

	function getProperty(name){
		var prop = global[name];
		if(!prop){ console.log('property ' + name+ ' not found'); }
		return prop ? prop : '';
	}

	function reset(keys){
		if(keys){
			keys.forEach(function(val, key){
				delete global[key];
			});
		}
		analytics.reset();
		if(window.Intercom){
			Intercom('shutdown');
		}
	}

	function id(userId){
		return userId + '@' + getProperty('firebaseInstance');
	}

	return {
		getHistory: function(){ return history; },
		addHistory: addHistory,
		id: id,
		reset: reset,
		getProperty: getProperty,
		track: track,
		setGlobal: setGlobal
	}
})();

//### snapping(x, list, stickiness, comp, ctx)
//generic function for calculating proximity to things within a list on a single dimension.
// In other words, it finds the list item closest to x.
//* x - number to test against [required]
//* list - an array of values to compare against [required]
//* stickiness - number that indicates the range of the culling function [required]
//* comp - a callback function, is passed the current list item, should return the numeric value that list item represents.
// Usually it's a property on the item. [required]
//* ctx - the context to execute with [optional]
//#### returns
//an object with two properties:
//* loc - the numeric value detected as the closest to x
//* item - the list item loc corresponds to
export const snapping = function (x, list, stickiness, comp, ctx?){
	var matches = [];

	function addMatch(x, item){
		var obj = {
			loc: x,
			item: item
		};
		matches.push(obj);
		return obj;
	}

	function cull(subList, subComp){
		subList.forEach(function(item){
			var result = Number(subComp.call(ctx, item));
			if(isNaN(result)){return;}

			if(x > result - stickiness && x < result + stickiness){
				addMatch(result, item);
			}
		}, ctx);
	}

	if(Array.isArray(comp)){
		if(list.length === comp.length){
			for(var i = 0; i < comp.length; i++){
				cull(list[i], comp[i]);
			}
		}
		else{
			console.log('multi-src mode requires the list and location function to have the same number of args');
		}
	}
	else{
		cull(list, comp);
	}

	//console.log('matches', matches);
	if(matches.length === 0){return false;}
	if(matches.length === 1){return matches[0];}

	var closest;
	var range = Infinity;
	matches.forEach(function(col){
		var diff = Math.abs(x - col.loc);
		if(diff < range){
			range = diff;
			closest = col;
		}
	});
	return closest;
}

export const dateJar = (function(){
		var cache = {};
		return function dateJar(date, format){
			if(!date){return '';}
			format = format || 'YYYY-MM-DD';
			if(cache[date] && cache[date][format]){ return cache[date][format]; }
			var fDate = moment(date).format(format);
			if(!cache[date]){ cache[date] = {}; }
			cache[date][format] = fDate;
			return fDate;
		};
})();





export const translateToGerman = (function(){
	var sectionsToChange = [
		".report-controller .left-container",
		".report-controller .center-container",
		".weekly-work-report-table thead",
		".sixweek-header",
		".gantt-tbl thead",
		".ppc-by-week-tbl thead",
		".constraint-log-table thead"
	];

	var replacements = [
		{orig: /^Plans/, replacement: "Pläne"},
		{orig: /^Roles/, replacement: "Gewerke"},
		{orig: /^Locations/, replacement: "Standorte"},
		{orig: /^Start Date/, replacement: "Startdatum"},
		{orig: /^End Date/, replacement: "Enddatum"},
		{orig: "Data as of", replacement: "Daten per"},
		{orig: "Weeks Commencing", replacement: "Woche beginnend"},
		{orig: "Week Commencing", replacement: "Woche beginnend"},
		{orig: "Task Description", replacement: "Aufgabenbeschreibung"},
		{orig: "All Plans", replacement: "Alle Pläne"},
		{orig: "All Roles", replacement: "Alle Gewerke"},
		{orig: "All Locations", replacement: "Alle Standorte"},
		{orig: "Prerequisite Work", replacement: "Notwendige Vorraussctzung"},
		{orig: "Project Roles", replacement: "Projekt Gewerke"},
		{orig: "Prior?", replacement: "vorherige woche?"},
		{orig: "Crew Size", replacement: "Kolonnengröße"},
		{orig: "Next", replacement: "nächste woche"},
		{orig: "Complete", replacement: "erledigt"},
		{orig: "Met Promise", replacement: "Zusage eingehalten"},
		{orig: "Reason for Variance", replacement: "Grund für Abweichung"},
		{orig: "Progress Notes", replacement: "Fortschrittsbemerkung"},
		{orig: "Notes", replacement: "Bemerkungen"},
		{orig: "Constraints", replacement: "Einschränkungen"},
		{orig: "Reason For Variance", replacement: "Grund für Abweichung"},
		{orig: "Six Week Look Ahead", replacement: "6-Wochenvorschau"},
		{orig: "Gantt Project Report", replacement: "Balkenplan"},
		{orig: "Days", replacement: "Tage"},
		{orig: "Planned Start", replacement: "Geplanter Anfang"},
		{orig: "Planned Finish", replacement: "Geplantes Ende"},
		{orig: "Actual Finish", replacement: "Tatsächliches Ende"},
		{orig: "Bold italic indicates a constraint", replacement: "Fett kursiv bedeutet eine Einschränkung"},
		{orig: "PPC By Week", replacement: "Wöchentlicher PEA Wert"},
		{orig: "Promises Met", replacement: "Zusage eingehalten"},
		{orig: "Promises Made", replacement: "gemachte Zusagen"},
		{orig: "Combined", replacement: "verbunden"},
		{orig: "PPC by Variance Reason", replacement: "PEA Nach Abweichungsgrund"},
		{orig: "PPC by Variance", replacement: "PEA Nach Abweichungsgrund"},
		{orig: "PPC Variance By Role", replacement: "Fett kursiv bedeutet eine Einschränkung"},
		{orig: "Constraint Description", replacement: "Beschreibung der Einschränkung"},
		{orig: "Constrained Tasks", replacement: "Einschränkende Aufgaben"},
		{orig: "Date Identified", replacement: "Tag der Feststellung"},
		{orig: "Date Promised", replacement: "Zugesagtes Datum"},
		{orig: "Reponsible Party", replacement: "Verantwortlicher"},
		{orig: "Notes", replacement: "Tag der Feststellung"},
		{orig: "Current Plan", replacement: "Aktueller Plan"},
		{orig: "Original Milestone", replacement: "Original Meilenstein"},
		{orig: "Days current schedule is before or after milestone", replacement: "Aktueller Plan ist vor oder nach Meilenstein"},
		{orig: "Work Days", replacement: "Arbeitstage"},
		{orig: "Required Date", replacement: "Zugesagtes Datum"},
		{orig: "Planned Date", replacement: "Geplantes Datum"},
		{orig: "Responsible Project Member", replacement: "Verantwortlicher"},
		{orig: "Assigned To", replacement: "Verantwortlicher"},
		{orig: "Week", replacement: "Woche"}
	];

	var walkDOM = function (node) {
		if(!node){return;}
		else if(node.hasChildNodes()){
			node.childNodes.forEach(function(childNode){ walkDOM(childNode); });
		}
		else{
			if(node.nodeType === 3){ //text
				var s = node.nodeValue.trim();
				if(!s){return;}
				//console.log(s);

				replacements.forEach(function(obj){
					node.nodeValue = node.nodeValue.replace(obj.orig, obj.replacement);
				});
			}
		}
	};

	function walkDOMList(list){
		list.forEach(function(selector){
			walkDOM(document.querySelector(selector));
		});
	}

	return function(){ walkDOMList(sectionsToChange); }
})();

export function checkVisible(elm, threshold, mode) {
	if (typeof elm === 'string'){elm = document.getElementById(elm);}
	threshold = threshold || 0;
	mode = mode || 'visible';

	var rect = elm.getBoundingClientRect();
	var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
	var above = rect.bottom - threshold < 0;
	var below = rect.top - viewHeight + threshold >= 0;

	return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
}

// Returns a function which stops the observation, to stop watching the element for visibility changes
export function intersectObserve(elm, cb, options){
	if (typeof elm === 'string'){elm = document.getElementById(elm);}
	if (!elm || !cb){return}
	var watchMe = new IntersectionObserver(cb, options);
	watchMe.observe(elm);
	return function(){
		watchMe.unobserve(elm);
		watchMe.disconnect();
	}
}

export function copy(object){
	if(!object || typeof(object) != 'object' || object instanceof HTMLElement) {return object;}
	var c = {};
	for( var i in object ) {c[i] = object[i];}
	return c;
}

/**
 * Might as well just have a general use toggle fuction...
 * @param  {boolean} target [the existing property]
 * @param  {boolean} force  [an optional force arguement]
 * @return {boolean}        [flipped]
 */
export function toggle(target:boolean, force?:boolean):boolean{
	if(force !== undefined){ return force; }
	else{ return !target; }
}

/**
 * From https://github.com/mreinstein/remove-array-items
 * A faster implementation of splice that doesn't generate garbage
 * @param arr
 * @param startIdx
 * @param removeCount
 */
export function fastSplice(arr, startIdx, removeCount){
	let i, length = arr.length;
	
	if (startIdx >= length || removeCount === 0) {
		return
	}
	
	removeCount = (startIdx + removeCount > length ? length - startIdx : removeCount);
	
	let len = length - removeCount;
	
	for (i = startIdx; i < len; ++i) {
		arr[i] = arr[i + removeCount]
	}
	
	arr.length = len
}

// export function flattenArray(arr: any[] = []){
// 	return arr.reduce((flat, toFlatten) => {
// 		return flat.concat(Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten);
// 	}, []);
// }

export function flattenArray(arr: any[] = []){
	let flattened = [];
	
	arr.forEach((thing) => {
		thing.forEach((val) => {
			flattened.push(val);
		});
	});
	
	return flattened
}

enum FANCY_TEXT_SETTINGS {
	"MAGIC_CHRIS" = 57,
	"MAGIC_CHRIS_MINUS_10" = 47
}
/**
 * Returns the most readable text color, given a background color.
 * @param {string} backgroundColor
 * @param {number} luminosityVariance Defaults to "MAGIC_CHRIS" or 57 because Chris.
 * @returns {string}
 */
export function getMostReadableColor(backgroundColor: string, luminosityVariance: number = FANCY_TEXT_SETTINGS.MAGIC_CHRIS): string {
	let tc = tinycolor(backgroundColor);
	let isLight = tc.isLight();
	// return (isLight) ? tc.darken(luminosityVariance).toHexString() : tc.lighten(luminosityVariance).toHexString();
	return isLight ? tinycolor("#171717").toHexString() : tinycolor("#ffffff").toHexString();
}
/**
 * This takes a color input and makes it lighter. A value of 100 = white.
 * @param {string} color The color to make light
 * @param {number} amount The amount to make light (100 = white)
 */
export function lightenColor(color: string, amount: number = 30): string {
	return tinycolor(color).lighten(amount).toHexString();
}
/**
 * Returns a boolen indicating of the color's perceived brightness is light.
 * @param {string} backgroundColor The background color to test against
 * @returns {boolean}
 */
export function colorIsLight(backgroundColor: string): boolean {
	return tinycolor(backgroundColor).isLight();
}
/**
 * Returns a boolen indicating of the color's perceived brightness is dark.
 * @param {string} backgroundColor The background color to test against
 * @returns {boolean}
 */
export function colorIsDark(backgroundColor: string): boolean {
	return tinycolor(backgroundColor).isDark();
}
/**
 * This utility throttles events against the provided object and only dispatches one event of said type each frame.
 *
 * This is used to throttle 'resize' in the canvas renderer, since resizing the canvas renderer can be expensive,
 * we don't want to do it multiple times each tick.
 * @param {string} type The event name to listen for
 * @param {string} name The new event name to dispatch once one tick has elapsed.
 * @param {any} obj The thing to listen for events.
 */
export function throttleEvent(type: string, name: string, obj?: any) {
	obj = obj || window;
	let running = false;
	let cancelRequest;
	let func = () => {
		if (running) {return;}
		running = true;
		cancelRequest = requestAnimationFrame(function() {
			obj.dispatchEvent(new CustomEvent(name));
			running = false;
		});
	};
	obj.addEventListener(type, func);
}

/** ripped from rxjs 5.5.6 and modified slightly*/
export const subscribeToPromise = <T>(promise: PromiseLike<T>, subscriber: Subscriber<T>) => {
	promise.then(
		(value) => {
			if (!subscriber.closed) {
				subscriber.next(value);
				subscriber.complete();
			}
		},
		(err: any) => subscriber.error(err)
	)
  return subscriber;
};

/**
 * Accepts a list of functions that are expected to return Promises.
 * Sets up an internal queue when the next promise can't execute until
 * the previous has resolved.
 * Returns an accumulated observable of all the results.
 * @param  list
 * @return     [description] Observable<Array<T>>
 */
export function sequencePopups<T=any>(list:Array<()=>Promise<T>>):Observable<Array<T>>{
	let returnObs: Observable<T>;
	if(!list || !list.length){ return empty(); }
	list.forEach((cb)=>{
		var ob = new Observable<T>((subscriber)=>{
			subscribeToPromise(cb(), subscriber);
		});
		if(!returnObs){ returnObs = ob; }
		else{ returnObs = returnObs.pipe( concat(ob)) }
	})
	return returnObs.pipe(
		scan((acc, curr:T)=>{
			return [...acc, curr]
		},[])
	);
}
/**
 * MOC-2641. Utility function to check if current user supports WebGL.
 * Factors in experimental tag.
 * This is the suggested way for checking for WebGL according to https://get.webgl.org/.
 * @returns {boolean}
 */
export function isWebGLEnabled(): boolean{
	let canvas = document.createElement("canvas");
	let gl;
	// Check to see if WebGL rendering context is available without a flag
	try {gl = canvas.getContext("webgl");}
	catch (x) {gl = null;}
	
	// Check to see if WebGL is available _with_ an experimental flag.
	if (gl === null) {
		try { gl = canvas.getContext("experimental-webgl");}
		catch (x) { gl = null; }
	}
	
	return !!(gl)

}
/**
 * Method to clamp a number to a min and max value
 * @param num The number to clamp
 * @param min The minimum value
 * @param max The maximum value
 * @returns {any}
 */
export function clamp(num: number, min: number, max: number = Number.MAX_VALUE) {
	return num <= min ? min : num >= max ? max : num;
}

/**
 * Method to clamp a number between 0 and 1
 * @param {number} num
 */
export function clamp01(num: number): number {
	return clamp(num, 0, 1);
}
/**
 * Method to interpolate between two numbers
 * @param {number} a
 * @param {number} b
 */
export function interpolate(a: number, b: number) {
	return function(t){return a * (1 - t) + b * t;};
}
/**
 * Reverse of interpolate
 * @param {number} a
 * @param {number} b
 */
export function uninterpolate(a: number, b: number) {
	b = (b -= a) || 1 / b;
	return function(x){ return (x - a) / b;};
}
/**
 * Method to floor a number
 * @param {number} p
 */
export function snapPixel(p: number): number {
	return Math.floor(p);
}
/**
 * Returns a random number. Tyler uses this to generate dummy data
 * @param {number} min Min value
 * @param {number} max Max value
 */
export function randomNumber(min: number, max: number): number {
	return Math.floor((Math.random() * max) + min);
}

//TODO - move these somewhere else

export function getCirclePoint( p:Point, angle:number, width:number, height:number ){
	var vy = Math.sin(angle);
	var vx = Math.cos(angle);
	var sides:any = {};
	var rad = 0.50;
	var offset = Math.sqrt(rad * (width*width + height*height));
	sides.left = (-width/2) + offset;
	sides.right = (width/2) + offset;
	sides.top = (-height/2) + offset;
	sides.bottom = (height/2) + offset;
	var min;
	for(var side in sides){ if(sides[side] > 0 && (!min || sides[side] < sides[min])){ min = side; } }
	var t = sides[min];
	return new Point(p.x+t*vx, p.y+t*vy);
}

export function getRectPoint( p:Point, angle:number, width:number, height:number ){
	var vy = Math.sin(angle);
	var vx = Math.cos(angle);

	var sides:any = {};
	sides.left = (-width/2)/vx;
	sides.right = (width/2)/vx;
	sides.top = (-height/2)/vy;
	sides.bottom = (height/2)/vy;

	var min;
	for(var side in sides){ if(sides[side] > 0 && (!min || sides[side] < sides[min])){ min = side; } }
	var t = sides[min];
	return new Point(p.x+t*vx, p.y+t*vy);
}

interface LineFunction{
	m: number,
	b: number
}

function getLineFunction(pt1:Point, pt2:Point){
	var m = (pt2.y - pt1.y)/(pt2.x - pt1.x);
	var b = pt1.y - m * pt1.x;
	return {m,b};
}

function getEdge(startLine: LineFunction, p:Point, width: number, height: number){
	var ptTop = new Point(p.x, p.y - height/2)
	var ptLeft = new Point(p.x - width/2, p.y);
	var ptRight = new Point(p.x + width/2, p.y);
	var ptBottom = new Point(p.x, p.y + height/2);
	
	var rangeRect = new Rectangle(ptLeft.x - 1, ptTop.y - 1, ptRight.x - ptLeft.x + 2, ptBottom.y - ptTop.y+2);
	
	var lines = [
		getLineFunction(ptLeft, ptTop),
		getLineFunction(ptTop, ptRight),
		getLineFunction(ptLeft, ptBottom),
		getLineFunction(ptBottom, ptRight)
	];
	// console.log('starting points', ptTop,ptLeft,ptRight,ptBottom);
	var mappedLines = [];
	 lines.forEach((lf,idx)=>{
		var x = (startLine.b - lf.b)/(lf.m - startLine.m);
		var y = lf.m * x + lf.b;
		
		if(rangeRect.contains(x, y)){
			mappedLines.push(new Point(x,y));
		}
	});
	// console.log('mappedLines', mappedLines, lines);
	return mappedLines;
	
}

export function getDiamondPoint( rawP:Point, angle: number, endP:Point, width:number, height:number ){
	// console.log('startPoint', p);
	var diamondRenderSize = 0;
	
	var p = new Point(rawP.x, rawP.y);
	
	var m = Math.tan(angle);
	// var m = (endP.y - p.y)/(endP.x - p.x);
	var b = p.y - m * p.x;
	
 	var newPoint = getEdge({m, b}, p, width + diamondRenderSize, height+diamondRenderSize).reduce((acc, lf)=>{
		var x = lf.x;
		var y = lf.y;
		
		var distance = Math.sqrt( Math.pow(x - endP.x, 2) + Math.pow(y - endP.y, 2) )
		if(distance < acc.distance){ return {distance, pt: new Point(x,y)} }
		else{ return acc; }
	}, {distance: Infinity, pt: new Point(0,0)});
	return newPoint.pt;
}

export function calcAngle(sPt:Point, ePt:Point){
	return Math.atan2(ePt.y - sPt.y, ePt.x - sPt.x);
}

export function essentiallyEquals(a: number, b:number, threshold = 0.5){
	return Math.abs(b - a) < threshold;
}

export function getCenterOffsetPoint( sPt:Point, ePt:Point, width:number, height:number, type?:string ) {
	if ( type === 'constraint') {
		return this.getCirclePoint( sPt, calcAngle(sPt, ePt), width, height );
	}
	else if (type === "milestone"){
		//console.log('need to implement diamonds...');
		// return this.getCirclePoint( sPt, Math.atan2(ePt.y - sPt.y, ePt.x - sPt.x), width, height );
		return this.getDiamondPoint( sPt, calcAngle(sPt, ePt), ePt, width, height );
	}
	else{
		return this.getRectPoint( sPt, calcAngle(sPt, ePt), width, height );
	}
}
/**
 * Converts a hex string like "#00ff00" to it's numerical equivalent -> 0x65280. WebGL expects numbers for colors, so it
 * is used in the canvas renderer to convert the string hex into a numerical representation
 * @param {string} hexString
 */
export function convertHexStringToNumber(hexString: string){
	return parseInt(hexString.substring(1), 16);
}

export const hexToNumber = convertHexStringToNumber;

/**
 * Returns the property of an object using a string where "." denotes depth
 * @param obj
 * @param attrString
 */
export function nestedKey(obj, attrString){
	return attrString.split('.').reduce((p,c)=>p&&p[c] || null, obj)
}

export function runInRootZone(cb){
	return Zone.root.run(cb);
}

/**
 * Instead of having a map of all named colors to hex string, create a canvas element and set the fillStyle and return it.
 * According to the spec, the fillStyle must be parsed when it is returned. This will give us a reliable mapping from namedColor to hex code
 * See: https://html.spec.whatwg.org/multipage/canvas.html#dom-context-2d-fillstyle
 */
const ctx: CanvasRenderingContext2D = document.createElement('canvas').getContext('2d');
export function colorToHex(color: string): string {
	ctx.fillStyle = color;
	return ctx.fillStyle;
}

export function rgbaToHexString(rgba: string): string {
	return tinycolor(rgba).toHexString();
}

export function rgbaToNumber(rgba: string): number {
	return hexToNumber(rgbaToHexString(rgba));
}

export interface ListLike<Thing = any>{
	// forEach: typeof Array.prototype.forEach //how type this with thing generic
	forEach: (callbackFn: (value: Thing, index: any) => void ) => void
}
export function isListLike<Thing>(t: any): t is ListLike<Thing> {
	return t.forEach !== undefined;
}
export function iterateStuff<Thing>(list: ListLike<Thing> | Thing, cb: (thing: Thing)=>void){
	if(isListLike<Thing>(list)){ list.forEach(cb); }
	else{ cb(list) }
}
/**
 * Check to see if the given point is within the provided Rectangle given world coordinates.
 * @param {PIXI.Point} point
 * @param {PIXI.Rectangle} rect
 */
export function containsWorldPoint(point: Point|Rectangle, rect: Rectangle): boolean {
	return rect.contains(point.x, point.y);
}

export function overlapRect(rec1: Rectangle, rec2: Rectangle): boolean {
	if (rec2.width === Infinity){return (rec1.top < rec2.bottom && rec1.bottom > rec2.top);}
	if (rec2.height === Infinity){return (rec1.left < rec2.right && rec1.right > rec2.left);}
	return (rec1.left < rec2.right && rec1.right > rec2.left &&
		rec1.top < rec2.bottom && rec1.bottom > rec2.top )
}

/**
 * Return a list of tickets within the given region. Coordinates should be in _world_ coordinates.
 *
 * If region width is Infinity, then it will only do a Y-axes check on the ticket
 * If region height is Infinity, then it will only do a X-axes check on the ticket
 *
 * TODO: MAKE GENERIC VERSION IF EVER NEEDED:
 * - Inclusion types:
 * -- entirely overlapping
 * -- partially overlapping
 * -- more than half overlapping
 * - Make generic
 * -- (Accept callback which returns an object containing X, Y, width, height?)
 * @param {Ticket[]} tickets The source ticket list
 * @param {PIXI.Rectangle} region The region that has to overlap the ticket
 */
export function getTicketsInRect(tickets: ListLike<Ticket>, region: Rectangle) {
	// If region.width and region.height are both Infinity...just return tickets.
	// - this check makes typing rather obnoxious, it's also not particularly useful, so..... just going to comment it out
	// if (region.width === Infinity && region.height === Infinity){return tickets}
	const output: Ticket[] = [];
	
	// Create one Rectangle then edit the values for each ticket. Don't want to create a new Rectangle instance for each ticket when it'll just be eaten in the end by GC anyway
	const scratchRect: Rectangle = new Rectangle(0,0,1,1);
	
	tickets.forEach((ticket: Ticket) => {
		// Populate rect and point values
		scratchRect.x = ticket.view.left;
		scratchRect.y = ticket.view.top;
		scratchRect.width = ticket.view.width;
		scratchRect.height = ticket.view.height;
		if (overlapRect(scratchRect, region)){
			output.push(ticket);
		}
	});
	
	return output;
}

class TrackTicketTapping{
	private timestamp = Date.now();
	private list:Array<{text:string, timeDiff: number}> = [];
	private extras = {};
	private started = false;
	
	public start(text?:string, extras?:Object){
		this.reset();
		this.started = true;
		if(text){ this.log(text, extras); }
	}
	public log(text:string, extras?:Object){
		if(!this.started){return;}
		if(extras){ Object.assign(this.extras, extras); }
		this.list.push(this.makeThing(text));
	}
	
	public end(fbReporting, text?:string, extras?:Object){
		if(!this.started){return;}
		this.started = false;
		
		if(text){ this.log(text, extras); }
		
		// console.log('did the thing', this.list, this.extras, fbReporting);
		var hit;
		for(var i = 0; i < this.list.length; i++){
			var item = this.list[i];
			if(item.timeDiff > 1000){
				hit = item;
				break;
				
			}
		}
		if(hit){
			Object.assign(this.extras, {
				slowStep: hit.text,
				delay: hit.timeDiff
			})
			console.log('ticket opened', this.extras);
			fbReporting.reportWarning('ticket took too long to open', this.extras);
		}
		this.reset();
	}
	
	private reset(){
		this.timestamp = Date.now();
		this.list.length = 0;
		this.extras = {};
	}
	
	private makeThing(text){
		return {
			text: text,
			timeDiff: Date.now() - this.timestamp
		}
	}
}
export const trackTicketTapping = new TrackTicketTapping();


// parseUrl('/foo/bar/baz', './qux');
// //=> /foo/bar/baz/qux
// 
// parseUrl('/foo/bar/baz', '../qux');
// //=> /foo/bar/qux
// 
// parseUrl('/foo/bar/baz', '../../qux');
// //=> /foo/qux
// 
// parseUrl('/foo/bar/baz', '..');
// //=> /foo/bar
export function parseUrl(url: string, redirectTo: string) {
	const urlTokens = url.split('/');
	const redirectToTokens = redirectTo.split('/');

	let token = redirectToTokens.shift();

	while (token) {
		if (token !== '.' && token !== '..') {
			redirectToTokens.unshift(token);
			break;
		}

		if (token === '..') {
			urlTokens.pop();
		}

		token = redirectToTokens.shift();
	}

	urlTokens.push(...redirectToTokens);

	return urlTokens.join('/');
}
