'use strict';
import * as angular from "angular";

angular.module('ticketspaceApp')
.factory('firebasePriorities', ["fbConnection", "$firebaseArray", function(fbConnection, $firebaseArray) {
	var defaultPriorities = [{
			"color": "#5cbf3a",
			"text": "On track",
			"sortOrder": 1
		},{
			"color": "orange",
			"text": "Needs Attention",
			"sortOrder": 2
		},{
			"color": "firebrick",
			"text": "Critical",
			"sortOrder": 3
	}];
	
	function assembleDefault(ref){
		var obj = {};
		defaultPriorities.forEach(function(p){
			var key = ref.push().key;
			obj[key] = p;
		}, this);
		return obj;
	}
	
	function PriorityFactory(ref){
		var query = ref.orderByChild('sortOrder'); //TODO - add security rules index
		return $firebaseArray.call(this, query);
	}
	PriorityFactory.prototype = {
		"setDefault": function setDefault(){
			var obj = assembleDefault(this.$ref().ref);
			this.$ref().ref.set(obj);
		}
	};
	
	
	
	
	var returnObj = $firebaseArray.$extend(PriorityFactory);
	returnObj.assembleDefault = assembleDefault;
	return returnObj;
}]);
