import { Injectable } from "@angular/core";
import { Observable, Subject, empty, of} from 'rxjs';
import { tap, takeUntil, mergeMap, merge, take } from 'rxjs/operators';
import { database } from "firebase/app"
import { AngularFireDatabase, AngularFireObject, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { FancyAction, FancyActionTypes, AngularLandConnection, FancyIdJoin } from 'ng2/fancy-firebase/base';
import { FancyActionTrackerFactory } from 'ng2/common/services/fancy-action-tracker-factory.service';

import * as firebaseUtils from 'ng2/fancy-firebase/firebase-utils';

export class Fragment{
	public lastSnap:AngularFireAction<DatabaseSnapshot<any>>;
	public event$:Observable<AngularFireAction<DatabaseSnapshot<any>>>;
	
	private shutdownSubject = new Subject();
	private finalEvent$ = new Subject<AngularFireAction<DatabaseSnapshot<any>>>();
	
	constructor(private afObject: AngularFireObject<DatabaseSnapshot<any>>){
		this.event$ = afObject.snapshotChanges()
		.pipe(
			takeUntil(this.shutdownSubject),
			mergeMap((snap)=>{
				let abort = false;
				let lastExists = this.lastSnap !== undefined && this.lastSnap.payload.exists();
				//added
				if(!lastExists && snap.payload.exists() ){
					snap.type = "child_added";
				}
				//removed
				else if(lastExists && !snap.payload.exists()){
					snap.type = "child_removed";
				}
				//no-op, is silly
				else if(!lastExists && !snap.payload.exists()){
					abort = true;
				}
				//changed
				else{
					snap.type = "child_changed";
				}
				this.lastSnap = snap;
				if(abort){ return empty(); }
				return of(snap);
			}),
			merge(this.finalEvent$)
		)
	}
	
	stop(){
		if(this.lastSnap.type !== "child_removed"){
			this.lastSnap.type = "child_removed";
			this.finalEvent$.next(this.lastSnap);
		}
		this.shutdownSubject.next();
	}
}

/**
 * FancyFragmenter exists as a class that can request a partial list from firebase.
 * It works by accepting the location of the whole list, and waiting for add/remove
 * calls to indicate what is included in the list. It then makes object calls
 * via angularfire and transforms them into list calls. The net result being
 * an observable that emits roughly equivalent events to angularfire2 stateEvents.
 * FancyIdJoin currently implements this api.
 */
export class FancyFragmenter{
	protected list: Map<string, Fragment> = new Map();
	public baseRef: database.Reference; //DEVTEXT replace with DatabaseReference
	protected angularFireDatabase: AngularFireDatabase;
	private shutdownSubject = new Subject();
	
	protected innerEmitter$:Subject<AngularFireAction<DatabaseSnapshot<any>>> = new Subject();
	public emitter$:Observable<AngularFireAction<DatabaseSnapshot<any>>> = this.innerEmitter$.pipe( takeUntil(this.shutdownSubject) );
	
	constructor(angularLand: AngularLandConnection, protected basePath:string){
		this.angularFireDatabase = angularLand.angularFireDatabase;
		this.baseRef = (<any>firebaseUtils.baseRef(basePath));
	}
	
	//isolate out the start to ensure all the firebase lists are listening before we start requesting events
	start(initialIds?:Array<string>){
		if(initialIds){
			initialIds.forEach((id)=>{
				this.add(id);
			});
		}
	}
	
	add(id:string){
		if(!this.list.has(id)){
			let fragment = new Fragment(this.angularFireDatabase.object(this.getChildRef(id)));
			
			fragment.event$.pipe( tap(thing => {(thing as any).key = id; })).subscribe(this.innerEmitter$);
			this.list.set(id, fragment);
		}
	}
	
	has(id:string){
		return this.list.has(id);
	}
	
	getChildRef(id:string){
		return this.baseRef.child(id);
	}
	remove(id:string){
		let fragment = this.list.get(id);
		// console.log('fancy fragmenter', fragment);
		if(!fragment){return;}
		
		fragment.stop();
		this.list.delete(id);
	}
	destroy(){
		this.shutdownSubject.next(true);
		this.list = null;
	}
}

@Injectable()
export class FancyFragmenterFactory{
	constructor(protected db: AngularFireDatabase, protected fancyActionTrackerFactory: FancyActionTrackerFactory){  }
	create(path):FancyFragmenter{
		var angularLand = {
			angularFireDatabase: this.db,
			fancyActionTrackerFactory: this.fancyActionTrackerFactory
		};
		return new FancyFragmenter(angularLand, path);
	}
}
