'use strict';

import * as angular from "angular";

angular.module('ticketspaceApp')
.factory('firebaseCustomOrdering', ["fbConnection", function(fbConnection) {
	//notes and such
	/*
	stuff
	- autofocus plan tickets in cycle
	- see only my tickets switch
	- grey autofilled fields on edit all (not sure I like this one...)


	do at some point
	- access level checks
	- confirms on all




	- firebaseTickets needs to be updated to support queries (either pass in a ref, or a query cb). Probably also save the queries on it.
	- all floating tickets need to have a "userOrder" key




	lazy firebase hashing algorithm (for floating tickets):
	potential properties
	- hashRange: the amount to increment when adding tickets (default: 100). The larger it is, the longer a collision will take, and the smaller keyspace will get.

	spec
	- tickets have a userOrder key, it will be set to a number.
	- tickets are pulled down with a firebase orderByChild('userOrder') query, which cause's the list to be magically reordered when that value changes.
	- when a new ticket is created userOrder be set to tickets[tickets.length-1].userOrder + "hashRange". Or 0 if undefined.
	- when a ticket is reordered, it's userOrder should be set to (tickets[desiredIdx + 1].userOrder - tickets[desiredIdx].userOrder)/2 + tickets[desiredIdx].userOrder Which will cause it to be positioned between those 2 positions. If +1 is undefined, use normal numbering.
	- when a collision happens (userOrder delta < 1), renumber the collection from the beginning then repeat the attempted move. This could also be run periodically in the background in theory (probably overkill).

	- theoretically no reason this couldn't be genercised and used for other things...

	//undecided whether to have this actually set the firebase values, or just to return a pre-constructed mass-set.
	//tending towards setting
	FirebaseHash(baseArray, arrayRef, {hashRange:100})
	.renumber() - renumbers, update locally (don't bother with this, won't actually be sorted until firebase sees it. Figure out different way to fake it for instant local feedback), set as single update to firebase, return promise
	.moveTo(array, idx) - update userOrder of array, potentially renumbering, return promise indicating completion
	.next(n=1) - returns the next numbers, n is the number of numbers to return. If > 1, returned as an array. (consider always an array)


	case 1: user moves all tickets at once, another user's edit changes get clobbered (write everything at all times)
	case 2: user moves all tickets at once, another user's edit changes merge nicely in (partial writes)
	case 3: user moves all tickets at once, another user's deletion goes through and there's now a ticket with nothing but top/left (partial writes)
	case 4: user moves all tickets at once, another user's deletion goes through and the original user has their entire move undone for trying to write a ticket without an id (atomic partial writes with security)
	case 5: user moves all tickets at once, another user's deletion goes through and the original user has only that one ticket's move undone (individual partial writes with security)

	random thought, consider one user doing a mass-set of lefts, and another user deleting one of those tickets. End result? Ticket with only a left.
	*/
	function FirebaseCustomOrdering(baseArray, ref, config){
		if(!config){ config = {}; }
		if(!baseArray || !ref){ throw new Error("FirebaseCustomOrdering init failed - list or ref is undefined"); }
		this.list = baseArray;
		this.ref = ref;
		this.hashRange = config.hashRange || 100;
		this.keyName = config.keyName || "userOrder";
	}
	FirebaseCustomOrdering.prototype = {
		renumber: function(){
			//consider creating an accessor function that can be set via the config object for objects of differing data structures
			var updateObj = {};
			this.list.forEach(function(obj, idx){
				var path = obj.$id + "/" + this.keyName;
				updateObj[path] = this.hashRange * idx;
			}, this);
			//console.log('updateObj', updateObj);
			return this.ref.update(updateObj);
		},
		//if 1, tickets are placed at position 1, ie, between the current 0 and the current 1
		moveTo: function(moveList, idx){
			var self = this;
			var startOrder;
			var endOrder;
			
			if(this.list.length === 0){
				startOrder = 0;
				endOrder = moveList.length * this.hashRange;
			}
			else if(idx === 0){
				console.log('about to error');
				endOrder = this.list[idx].data[this.keyName];
				startOrder = endOrder - this.hashRange;
				console.log('this went fine');
			}
			else if(idx >= this.list.length){
				startOrder = this.list[this.list.length-1].data[this.keyName];
				endOrder = startOrder + this.hashRange;
			}
			else{
				startOrder = this.list[idx-1].data[this.keyName];
				endOrder = this.list[idx].data[this.keyName];
			}
			
			//console.log('start', startOrder, 'end', endOrder);
			if(	isNaN(startOrder) || isNaN(endOrder) || endOrder - startOrder < moveList.length){
				return self.renumber().then(function(){
					return self.moveTo(moveList, idx);
				});
			}
			else{
				var offset = Math.floor((endOrder - startOrder)/(moveList.length+1));
				console.log('offset', offset);
				
				var updateObj = {};
				moveList.forEach(function(obj, idx){
					var path = obj.$id + "/" + self.keyName;
					updateObj[path] = startOrder + (idx+1) * offset;
				});
				return this.ref.update(updateObj);
			}
		},
		next: function(count){
			if(!this.list){return [];}
			if(!count){count = 1;}
			
			var arr = [];
			
			var theEnd;
			//some fancy shit to more gracefully recover from broken data
			for(var i = this.list.length-1; i >= 0; i--){
				if(this.list[i].data[this.keyName]){
					theEnd = this.list[i].data[this.keyName] + this.hashRange;
					break;
				}
			}
			if(!theEnd){theEnd = this.hashRange;}
			
			for(var i = 0; i < count; i++){
				arr.push(theEnd + this.hashRange * i);
			}
			return arr;
		},
		destroy: function destroy(){
			this.list = null;
		}
	};

	return function(baseArray, ref, config){
		return new FirebaseCustomOrdering(baseArray, ref, config);
	};
}]);
