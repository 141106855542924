import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Side } from "../../common/models/Side";
import { Ticket } from "../../common/models/Ticket";

export enum IS_LONGEST_SETTINGS {
	PADDING_BOTTOM = 10,
	ROW_HEIGHT = 35,
	LEFT_OFFSET = 15
}

export class TicketIsLongest extends TicketComponentHelper implements ComponentInterface {
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		this.alpha = 0.50;
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer);
	}
	
	public onChanged = (ticketContainer: TicketContainer) => {
		const data: Ticket = ticketContainer.getData();
		this.renderable = (data.view.side === Side.PULL && data.isLongestPull);
		if (this.renderable){
			const data: Ticket = this.ticket.getData();
			// Scale this a bit depending on the size of the liveDurationDays
			if (data.view.liveDurationDays >= 10){
				this.scale.x = 1.5;
			} else {
				this.scale.x = 1;
			}
			
			this.x = data.view.width - this.width - IS_LONGEST_SETTINGS.LEFT_OFFSET;
			// padding-bottom: 20px
			// Align to bottom-right, then offset the height and 20
			// 28 is the height of TicketDurationDays.
			// 20 is the spacing we want.
			this.y = data.view.height - IS_LONGEST_SETTINGS.ROW_HEIGHT - IS_LONGEST_SETTINGS.PADDING_BOTTOM;
		}
	};
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}