import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'loading-blocker-failure',
	template: require('./loading-blocker-failure.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBlockerFailureComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
