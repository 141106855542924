import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Component({
	selector: 'dropdown-menu',
	template: require('./dropdown-menu.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMenuComponent implements OnInit {
	public opened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	
	constructor(){
		window.DropdownMenu = this;
	}

	ngOnInit(){}
	
	public toggle(): void {
		this.opened$.next(!this.opened$.getValue());
	}
	
	public close(event?): void {
		this.opened$.next(false);
	}
}
