//by importing everything here typescript will enforce uniqueness
//consider re-exporting

import * as TicketActionBuilders from "ng2/actions/ticket-action-builders";
import { CombinedTicketActions, TicketActionTypes } from "./ticket-actions";
import { PlanState } from "ng2/common/services/plan-state.service";

export interface AllThePlanActions{
	changeTicketViewToEdit: typeof TicketActionBuilders.changeTicketViewToEdit,
	changeTicketViewToDrag: typeof TicketActionBuilders.changeTicketViewToDrag,
	changeTicketViewToDefault: typeof TicketActionBuilders.changeTicketViewToDefault, 
	ticketDragged: typeof TicketActionBuilders.ticketDragged,
	abortEdit: typeof TicketActionBuilders.abortEdit,
	updateTicketLiveData: typeof TicketActionBuilders.updateTicketLiveData,
	persistTicketData: typeof TicketActionBuilders.persistTicketData,
	persistTicketDataLocalOnly: typeof TicketActionBuilders.persistTicketDataLocalOnly,
	selectTicket: typeof TicketActionBuilders.selectTicket,
	unSelectTicket: typeof TicketActionBuilders.unSelectTicket,
	addTicketToSearch: typeof TicketActionBuilders.addTicketToSearch,
	removeTicketFromSearch: typeof TicketActionBuilders.removeTicketFromSearch,
	selectTicketToggle: typeof TicketActionBuilders.selectTicketToggle,
	selectTicketDelayed: typeof TicketActionBuilders.selectTicketDelayed,
	addFloatingTicketToPlan: typeof TicketActionBuilders.addFloatingTicketToPlan,
	moveTicketToFloating: typeof TicketActionBuilders.moveTicketToFloating,
	returnTicketToPlan: typeof TicketActionBuilders.returnTicketToPlan,
	saveFloatingTicketToPlan: typeof TicketActionBuilders.saveFloatingTicketToPlan,
	saveTicketToFloating: typeof TicketActionBuilders.saveTicketToFloating,
	deleteTicket: typeof TicketActionBuilders.deleteTicket
	removeFloatingTickets: typeof TicketActionBuilders.removeFloatingTickets;
	simpleDependencyToggle: typeof TicketActionBuilders.simpleDependencyToggle;
	createTicketWithId: typeof TicketActionBuilders.createTicketWithId;
}


export enum PlanActionTypes{
	other = "other"
}
export interface OtherAction{
	type: PlanActionTypes.other
}

export type CombinedPlanActions = 
	| CombinedTicketActions
	| OtherAction;


//takes all the actions and explicitly sets them to a passed in object, self is bound to
// another this you specify
export const applyScopedActions = function applyScopedActions(target:AllThePlanActions, self:PlanState){
	target.changeTicketViewToEdit = TicketActionBuilders.changeTicketViewToEdit.bind(self);
	target.changeTicketViewToDrag = TicketActionBuilders.changeTicketViewToDrag.bind(self);
	target.changeTicketViewToDefault = TicketActionBuilders.changeTicketViewToDefault.bind(self);
	target.ticketDragged = TicketActionBuilders.ticketDragged.bind(self);
	target.abortEdit = TicketActionBuilders.abortEdit.bind(self);
	target.updateTicketLiveData = TicketActionBuilders.updateTicketLiveData.bind(self);
	target.persistTicketData = TicketActionBuilders.persistTicketData.bind(self);
	target.persistTicketDataLocalOnly = TicketActionBuilders.persistTicketDataLocalOnly.bind(self);
	target.selectTicket = TicketActionBuilders.selectTicket.bind(self);
	target.unSelectTicket = TicketActionBuilders.unSelectTicket.bind(self);
	target.selectTicketDelayed = TicketActionBuilders.selectTicketDelayed.bind(self);
	target.addTicketToSearch = TicketActionBuilders.addTicketToSearch.bind(self);
	target.removeTicketFromSearch = TicketActionBuilders.removeTicketFromSearch.bind(self);
	target.selectTicketToggle = TicketActionBuilders.selectTicketToggle.bind(self);
	target.addFloatingTicketToPlan = TicketActionBuilders.addFloatingTicketToPlan.bind(self);
	target.moveTicketToFloating = TicketActionBuilders.moveTicketToFloating.bind(self);
	target.returnTicketToPlan = TicketActionBuilders.returnTicketToPlan.bind(self);
	target.saveFloatingTicketToPlan = TicketActionBuilders.saveFloatingTicketToPlan.bind(self);
	target.saveTicketToFloating = TicketActionBuilders.saveTicketToFloating.bind(self);
	target.deleteTicket = TicketActionBuilders.deleteTicket.bind(self);
	target.removeFloatingTickets = TicketActionBuilders.removeFloatingTickets.bind(self);
	target.simpleDependencyToggle = TicketActionBuilders.simpleDependencyToggle.bind(self);
	target.createTicketWithId = TicketActionBuilders.createTicketWithId.bind(self);
}
