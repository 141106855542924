import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Ticket, TicketTypes } from "../../common/models/Ticket";
import * as utils from "utils";
import { TicketUtils } from "../utils/TicketUtils";
import { MILESTONE_SIZE_OFFSET, MILESTONE_SIZE_OFFSET2 } from "../interfaces/milestone-size.interface";
import { Side } from "../../common/models/Side";

const BORDER_WIDTH = 15;
const MILESTONE_BORDER_WIDTH = 15;

export enum TICKET_BORDER_COLORS {
	SELECTED = "#49afcb",
	DEPENDENCY = "#2176ae",
	SEARCH = "#ECDF62",
	OUT_OF_SEQUENCE = "#C32A3C"
}

export class TicketSelected extends TicketComponentHelper implements ComponentInterface {
	public renderable: boolean = false;
	
	private _activeTexture: Texture;
	private _pullTexture: Texture;
	
	constructor(texture: Texture, pullTexture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		
		this._activeTexture = texture;
		this._pullTexture = pullTexture;
		
		this.alpha = 1.0;
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data = ticketContainer.getData();
		this.renderable = (data["selected"] || data["searched"] || <any>data["dependencyWeight"] || data.view.hasPredecessorOutOfOrder);
		
		if (this.renderable){
			if (data.view.side === Side.PULL){
				this.texture = this._pullTexture;
			} else {
				this.texture = this._activeTexture;
			}
			
			this._applyOffset(data);
			
			if (data["selected"]){
				this.tint = utils.convertHexStringToNumber(TICKET_BORDER_COLORS.SELECTED);
			} else if (data["searched"]){
				this.tint = utils.convertHexStringToNumber(TICKET_BORDER_COLORS.SEARCH);
			} else if (data["dependencyWeight"]){
				this.tint = utils.convertHexStringToNumber(TICKET_BORDER_COLORS.DEPENDENCY);
			} else if (data["isGhost"]) {
				const color = (data.view.liveRole && data.view.liveRole.color) ? data.view.liveRole.color : "#000000";
				this.tint = utils.convertHexStringToNumber(color);
			} else if (data.view.hasPredecessorOutOfOrder) {
				this.tint = utils.convertHexStringToNumber(TICKET_BORDER_COLORS.OUT_OF_SEQUENCE);
			}
		}
	};
	
	private _applyOffset(ticket: Ticket) {
		if (TicketUtils.getTicketType(ticket) === TicketTypes.MILESTONE){
			if(ticket.view.side === Side.PULL){ var sizeOffset = MILESTONE_SIZE_OFFSET2.PULL; }
			else{ var sizeOffset = MILESTONE_SIZE_OFFSET2.ACTIVE; }
			this.x = -MILESTONE_BORDER_WIDTH - sizeOffset / 2;
			this.y = -MILESTONE_BORDER_WIDTH - sizeOffset / 2;
			this.width = ticket.view.width + sizeOffset + MILESTONE_BORDER_WIDTH*2;
			this.height = ticket.view.height + sizeOffset + MILESTONE_BORDER_WIDTH*2;
		} else if (TicketUtils.getTicketType(ticket) === TicketTypes.CONSTRAINT) {
			this.x = -BORDER_WIDTH;
			this.y = -BORDER_WIDTH;
			this.width = ticket.view.width + BORDER_WIDTH*2;
			this.height = ticket.view.height + BORDER_WIDTH*2;
		}
	}
	
	public destroy(options?){
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}
