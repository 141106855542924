'use strict';

import * as angular from "angular";
import * as moment from "moment";

import { BehaviorSubject } from "rxjs";


import { dateCache } from "ng2/common/date-cache";

declare const window: any;

import AnimationQueue from "js/lib/ui/AnimationQueue";
import Redom from "js/lib/ui/Redom";

var indicatorArrowSrc = require("images/indicator-arrow.png");

export class ActiveColumnService{
	static $inject = ["$q", "promiseHistoryService", "shiftApi"];
	
	$loaded: any;
	$loadedSync: any;
	temp: any;
	dateMap: any;
	getDateFromX: any;
	init: any;
	reset: any;
	shouldDrawInner: any;
	overrideCull: any;
	overrideScreenRender: any;
	update: any;
	columnWidth: any;
	recalc: any;
	columns: any;
	subscribe: any;
	unsubscribe: any;
	width: any;
	blockRecalc: any;
	getSubColumns: any;
	getZoomStage: any;
	scrollOffset: any;
	observeParams: any;
	guessXFromDate: any;
	guessDateFromX: any;
	recalced$: BehaviorSubject<{activeLineX?: number, activeLineDate?: string}>;
	activeLine?: {
		x: number,
		date: string
	}
	timesInitedCount: number;
	
	constructor($q, promiseHistoryService, shiftApi){
		var dateMap = {};
		var self = this;
		
		var _columns;
		var _columnWidth;
		var _observers = [];
		var _datePool = {};
		var _drawEverything = false;
		var _scaleColumnCulling = 1;
		var hud;
		
		var loadedDefer = $q.defer();
		
		var _startX;
		var _startDate;
		
		var recalced$ = new BehaviorSubject({});
		
		var _haveData = false;
		var observeParams = {
			min:0,
			max: 0,
			columns: []
		};

		//special flag that disables realc
		var blockRecalc = false;
		
		function getDate(theDate){
			if(!_datePool[theDate]){
				_datePool[theDate] = {
					fullDate: moment(theDate).format('MMM DD'),
					minDate: moment(theDate).format('ddd'),
					dayDate: moment(theDate).format('DD')
				}
			}
			return _datePool[theDate];
		}
		function getMinDate(theDate){
			return getDate(theDate).minDate;
		}
		function getFullDate(theDate){
			return getDate(theDate).fullDate;
		}
		function getDayDate(theDate){
			return getDate(theDate).dayDate;
		}
		
		//width levels to start hiding things
		var stages = [33, 13, 9, 6, 5, 4, 3, 2, 1, 0.7];
		
		//private
		function trim(zoomLevel){
			var newArray = [];
			_columns.forEach(function(column){
				
			});
		}
		
		function observe(cols, min?, max?){
			for(var i = 0; i < _observers.length; i++){
				_observers[i](cols, min, max);
			}
		}
		
		//public
		function init(startX, startDate, columnWidth, h){
			this.timesInitedCount++;
			if(!h){ console.log('hud isn\'t defined'); return; }
			hud = h;
			recalc(startX, startDate, columnWidth);
			loadedDefer.resolve();
			hud.scope.orderedExecution.add("activeColumnService", function(){
				outerUpdate();
			});
		}
		
		function reset(){
			loadedDefer = $q.defer();
			hud = null;
			destroy();
		}
		
		function shouldDrawInner(){
			//console.log('inner', _scaleColumnCulling, hud.z, hud.z/ _scaleColumnCulling);
			var scale = hud.z / _scaleColumnCulling;
			return _columnWidth * scale > stages[0];
		}
		
		function getZoomStage(){
			var scale = hud.z / _scaleColumnCulling;
			var scaledWidth = _columnWidth * scale;
			var stageKey;
			for(stageKey = 0; stageKey < stages.length; stageKey++){
				if(scaledWidth > stages[stageKey]){ break; }
			}
			return stageKey;
		}
		
		function overrideCull(val){
			if(val === undefined || val === null || val === false){ val = 1;}
			_scaleColumnCulling = val;
			_lastScale = -1; //some invalid scale, make it always recalc
			update();
		}
		
		function overrideScreenRender(val){
			_drawEverything = val;
			update();
		}
		
		var _subColumns;
		var _lastScale;
		var _lastDate;
		var startCounter = 0;
		function getSubColumns(config, cb){
			//do jar thing
			if(hud.z === _lastScale){
				return _subColumns;
			}
			else{
				_subColumns = []; //garbage collector might be mean here...
				_lastScale = hud.z;
			}
			
			var min = config && config.min !== undefined ? config.min : 0;
			var max = config && config.max !== undefined ? config.max : _columns.length;
			var cols = config && config.columns !== undefined ? config.columns : _columns;
			
			var stageKey = getZoomStage();
			if(stageKey === stages.length){ return []; }
			//if(stages[stageKey] < 1){ return []; }
			
			var counter = startCounter;
			var first = true;
			
			for(var i = min; i < max; i++){
				if(!stageKey){ _subColumns.push(cols[i]); continue; }
				
				if(cols[i].fullDate){
					if(first){
						if(_lastDate !== cols[i].activeDay){
							//counter = -1;
							if(_lastDate > cols[i].activeDay){
								startCounter = (startCounter + 1) % stageKey;
							}
							else{
								startCounter = startCounter - 1;
								if(startCounter < 0){startCounter = stageKey -1;}
							}
							counter = startCounter;
						}
						_lastDate = cols[i].activeDay;
						first = false;
					}
					counter++;
					if(counter >= stageKey){
						_subColumns.push(cols[i]);
						counter = 0;
					}
				}
			}
			return _subColumns;
		}
		
		function recalc(startX, startDate, width){
			//console.log('recalcing', 'startX', startX, 'startDate', startDate, 'width', width, 'blockReclc', blockRecalc);
			if(blockRecalc){return;}
			_columnWidth = width || 145;
			_startX = startX;
			_startDate = startDate;
			
			if(startDate){
				self.activeLine = {
					x: startX,
					date: startDate
				}
			}
			else{ self.activeLine = null; }
			
			_columns = [];
			dateMap = {};
			
			window.activeColumns = _columns;
			
			update();
			recalced$.next({"activeLineX": startX, "activeLineDate": startDate});
			//outerUpdate();
		}
		
		function hudify(x){
			return x * hud.z + hud.x;
		}
		
		var firstUpdate = true;
		var _hasCleared = false;
		var scrollingOffset = 0;
		
		//plan
		//- change multi-dimensional array to be flat (identify sundays with special key), makes existing array work with flat array
		//- generate array based on active line x and column width and window width
		//- add more columns when scrolling left (figure out how to detect that)
		//- add a culling thing that sets a min and max on columns to draw, with the idea that only the columns that are on screen need to be drawn
		function update(){
			if(!hud){return;}
			// console.log('activeColumnService update', _observers);
			
			if(isNaN(_startX) || !_startDate){
				if(_haveData){
					firstUpdate = true;
					_haveData = false;
					observe(false);
				}
				return;
			}
			
			_haveData = true;
			
			var startPos = 0;
			for(var i = 0; i < _columns.length; i++){
				if(hudify(_columns[i].origFinishX) < window.innerWidth){
					startPos = i;
					break;
				}
			}
			if(_drawEverything){startPos = 0;}

			//console.log('shiftAmount', Math.floor(shiftAmount % _columnWidth));
			
			var o = 0;
			if(shiftAmount){
				o = Math.floor(shiftAmount % _columnWidth);
				if(shiftAmount <= 0){
					o = (Math.abs(o) - _columnWidth) * Math.sign(shiftAmount);
				}
				scrollingOffset = o;
				//startPos++;
			}

			var screenStart = hudify(_startX + o);
			var scaledWidth = _columnWidth * hud.z;
			//if(startPos){ var columnCount = Math.ceil(_columns[startPos].finishX / scaledWidth); }
			//console.log(scaledWidth);

			var columnCount = Math.ceil(screenStart / scaledWidth) + 1; //+1 to make speedy movements off screen a little less likely to leave columns
			if(columnCount > _columns.length){firstUpdate = true;}
			
			var innerMax;

			if(columnCount <=0){
				if(!_hasCleared){
					observe(false);
					_hasCleared = true;
				}
				return;
			}
			_hasCleared = false;
			
			for(var i = 0; i < columnCount; i++){
				if(_columns[i]){ //column exists
					_columns[i].finishX = screenStart - i * scaledWidth;
					_columns[i].startX = (screenStart - i * scaledWidth) - scaledWidth;
					_columns[i].origFinishX = ((screenStart - i * scaledWidth) - hud.x) / hud.z;
					_columns[i].origStartX = (((screenStart - i * scaledWidth) - hud.x) / hud.z) - _columnWidth;
					if(_columns[i].finishX < 0 && !innerMax){
						innerMax = i;
					}
				}
				else{ //new column
					var activeDay = moment(_startDate).subtract(i, 'days');
					
					var minDate = getMinDate(activeDay);
					var obj:any = {
						finishX: screenStart - i * scaledWidth,
						origFinishX: ((screenStart - i * scaledWidth) - hud.x) / hud.z,
						origStartX: (((screenStart - i * scaledWidth) - hud.x) / hud.z) - _columnWidth,
						startX: (screenStart - i * scaledWidth) - scaledWidth,
						activeDay: activeDay.format('YYYY-MM-DD'),
						minDate: minDate,
						dayDate: getDayDate(activeDay)
					};
					if(minDate === 'Sun'){ obj.fullDate = getFullDate(activeDay); }
					
					_columns.push(obj);
				}
				dateMap[_columns[i].activeDay] = _columns[i].origFinishX;
			}
			observeParams.min = startPos;
			observeParams.max = innerMax || columnCount;
			observeParams.columns = _columns;
			observe(_columns, startPos, innerMax || columnCount);
			
			if(firstUpdate){
				promiseHistoryService.observe();
				firstUpdate = false;
			}
			_lastScale = -1; //reset
		}
		
		function outerUpdate(){
			//console.log('outerUpdate');
			AnimationQueue(update, 'active-columns');
		}
		
		//ok... this is just getting silly
		function destroy(){
			_observers = [];
			_observers.push(laggedLinePositionCalc);
		}
		
		function subscribe(fn){
			_observers.push(fn);
			return fn;
		}
		function unsubscribe(fn){
			
			var idx = _observers.indexOf(fn);
			if(idx !== -1){ _observers.splice(idx, 1); }
		}
		
		//theoretical implementation
		// - if a callback is provided and the dateString is not found, return the first one, and
		//subscribe that cb to be notified whenever there's a new first one or an actual one to subscribe to
		// - if the actual one is found, remove the cb from the subscribe list
		// - this function should setup an observer that watches the observe function, only forward when the first column changes
		var oldColumnCount = 0;
		function laggedLinePositionCalc(columns){
			//console.log('cb cb\'d');
			if(columns.length !== oldColumnCount){
				oldColumnCount = columns.length;
				
				var i = dateMapCallbackList.length;
				while(i--){
					var obj = dateMapCallbackList[i];
					var x = dateMap[obj.date];
					if(x !== undefined){
						obj.cb(x);
						dateMapCallbackList.splice(i,1);
					}
					else{
						if(_columns.length){ obj.cb(_columns[_columns.length-1].origFinishX); }
						else{ console.log('columns list is empty, defaulting to -inf'); return -Infinity; }
					}
				}
			}
		}

		_observers.push(laggedLinePositionCalc);
		
		var dateMapCallbackList = [];
		function getDateMap(dateString, cb){
			if(!dateString){ return dateMap; }
			var x = dateMap[dateString];
			//console.log(JSON.stringify(dateMap, null, 2));
			if(x === undefined){
				//console.log('date not found', dateString);
				if(cb){ dateMapCallbackList.push({"cb": cb, "date": dateString});}
				if(!_columns.length){return undefined;}
				return _columns[_columns.length-1].origFinishX;
			}
			return x;
		}
		
		interface ActiveLineOverride{
			date: string,
			x: number
		}
		
		//use active line and column width to guess x coordinates for as yet uncalculated columns
		function guessXFromDate(date, dayOffset = 0, activeLineOverride?: ActiveLineOverride){
			var startDate = activeLineOverride ? activeLineOverride.date : _startDate;
			var startX = activeLineOverride ? activeLineOverride.x : _startX;
			
			var days = dateCache.diffDays(startDate, date) + dayOffset;
			return startX - _columnWidth * days;
		}
		function guessDateFromX(x, startXOffset = 0, columnWidth?){
			var startDate = (!_startDate) ? dateCache.todayString : _startDate;
			var startX = _startX + startXOffset;
			columnWidth = columnWidth || _columnWidth;
			var days = Math.floor(Math.round(Math.abs(startX - x)) / columnWidth);
			var d = dateCache.addDays(startDate, -days);
			return d;
		}

		function getDateFromX(x){
			if(!_columns || !_columns.length){return null;}
			var idx = Math.floor((Math.round(_columns[0].origFinishX - x)) / _columnWidth);
			return _columns[idx] ? _columns[idx].activeDay : null;
		}
		
		var shiftAmount = 0;
		var lastShiftedCount = 0;
		shiftApi.on('shiftStart', function(){
			shiftAmount = 0;
			lastShiftedCount = 0;
			scrollingOffset = 0;
		});
		shiftApi.on('shift', function(x, alX, side){
			if(side !== "active"){return;}
			shiftAmount += x;
			//if(shiftAmount <= 0){
				var cols = Math.floor(shiftAmount/_columnWidth);
			//}
			//else{
			//	var cols = Math.floor(shiftAmount/_columnWidth);
			//}
			if(cols !== lastShiftedCount){
				
				// if((lastShiftedCount === 0 && cols === 1) || (lastShiftedCount === 1 && cols === 0)){
					// lastShiftedCount = cols;
					// return;
				// }
				
				//screw with "temp" api
				var diff = cols - lastShiftedCount;
				//console.log('shift', x, shiftAmount, 'cols', cols, lastShiftedCount, 'diff', diff);
				if(diff > 0){
					//console.log('remove', Math.abs(diff));
					temp.removeFromStart(Math.abs(diff));
					temp.addToEnd(Math.abs(diff));
				}
				else{
					//console.log('add', Math.abs(diff));
					temp.addToStart(Math.abs(diff));
				}
				
				//console.log('col', _columns[0].activeDay);
				var first = _columns[0];
				if(first){
					var d = moment(first.activeDay, 'YYYY-MM-DD').format('MMM D YYYY');
					//lazy solution...
					$('.active-line-name span.ng-binding').addClass('hide');
					$('.active-line-name span.live').text("Active: "+d);
				}
				
				lastShiftedCount = cols;
			}
		});
		shiftApi.on('redrawShift', function(x, alX, side){
			//console.log('shiftAmount (shiftApi)', shiftAmount, 'offset', scrollingOffset);
			update();
			//console.log('offset post update', scrollingOffset);
		});
		shiftApi.on('shiftEnd', function(x, alX, side){
			var savedOffset = scrollingOffset;
			shiftAmount = 0;
			lastShiftedCount = 0;
			scrollingOffset = 0;
			update();
			$('.active-line-name span.ng-binding').removeClass('hide');
			$('.active-line-name span.live').text("");
			shiftApi.emit('shiftCleanup', -savedOffset, alX, side);
		});
		
		
		var temp = (function(){
			
			function _addAtStart(){
				if(!_columns.length){return;}
				var col = _columns[0];
				//if(!col){return;}
				var activeDay = (moment(col.activeDay).add('days', 1)).format('YYYY-MM-DD');
				var minDate = getMinDate(activeDay);
				var obj:any = {
					activeDay: activeDay,
					minDate: minDate,
					finishX: col.finishX + _columnWidth,
					startX: col.startX + _columnWidth,
					dayDate: getDayDate(activeDay)
				}
				if(minDate === 'Sun'){ obj.fullDate = getFullDate(activeDay); }
				_columns.unshift(obj);
			}
			
			function addToStart(x){
				if(!_columns.length){return;}
				//console.log('add x', x)
				if(isNaN(x)){return function(){};}
				_repeat(x, _addAtStart);
				var diff = _columnWidth * x;
				
				_columns.forEach(function(col){
					col.finishX -= diff;
					col.startX -= diff;
				});
				update();
				//shiftApi.shift(-diff, _columns[0].origFinishX, "active");
				
				return removeFromStart.bind(undefined, x);
			}
			
			function removeFromStart(x){
				if(!_columns.length){return;}
				//console.log('remove x', x);
				if(isNaN(x)){return;}
				_repeat(x, function(){_columns.shift();});
				var diff = _columnWidth * x;
				
				_columns.forEach(function(col){
					col.finishX += diff;
					col.startX += diff;
				});
				update();
				//shiftApi.shift(diff, _columns[0].origFinishX, "active");
			}
			
			function _addAtEnd(){
				if(!_columns.length){return;}
				var col = _columns[_columns.length - 1];
				if(!col){return;}
				var activeDay = (moment(col.activeDay).subtract('days', 1)).format('YYYY-MM-DD');
				var minDate = getMinDate(activeDay);
				var obj:any= {
					activeDay: activeDay,
					minDate: minDate,
					finishX: col.finishX - _columnWidth,
					startX: col.startX - _columnWidth,
					dayDate: getDayDate(activeDay)
				}
				if(minDate === 'Sun'){ obj.fullDate = getFullDate(activeDay); }
				_columns.push(obj);
				
				//shiftApi.shift(-_columnWidth, _columns[0].origFinishX);
			}
			
			function _removeAtEnd(){
				if(!_columns.length){return;}
				_columns.pop();
				//shiftApi.shift(_columnWidth, _columns[0].origFinishX);
			}
			
			function _repeat(x, func){
				if(!x || x < 1){ x = 1; }
				for(var i = 0; i < x; i++){ func(); }
				update();
			}
			
			return {
				addToStart: addToStart,
				addToEnd: function(x){ _repeat(x, _addAtEnd); },
				removeFromStart: removeFromStart,
				removeFromEnd: function(x){ _repeat(x, _removeAtEnd)}
			};
		})();
		
		this.$loaded = function(){return loadedDefer.promise}; //function is entirely un-necessary just for consistency...
		this.$loadedSync = function(){return !!hud;};
		this.temp = temp;
		this.dateMap = getDateMap; //function(key){ return key ? dateMap[key] : dateMap; };
		this.getDateFromX = getDateFromX;
		this.init = init;
		this.reset = reset;
		this.shouldDrawInner = shouldDrawInner;
		this.overrideCull = overrideCull;
		this.overrideScreenRender = overrideScreenRender;
		this.update = outerUpdate;
		this.columnWidth = function(){ return _columnWidth; };
		this.recalc = recalc;
		this.columns = function(){ return _columns; };
		this.subscribe = subscribe;
		this.unsubscribe = unsubscribe;
		this.width = function(){ return _columnWidth * hud.z; };
		this.blockRecalc = function(f){ blockRecalc = f; };
		this.getSubColumns = getSubColumns;
		this.getZoomStage = getZoomStage;
		this.scrollOffset = function(){return scrollingOffset;};
		this.observeParams = observeParams;
		this.guessXFromDate = guessXFromDate;
		this.guessDateFromX = guessDateFromX;
		this.recalced$ = recalced$;
		//debug
		this.timesInitedCount = 0;
	}
	
}

angular.module('ticketspaceApp')
.factory('activeColumnService', ActiveColumnService)
.directive('activeColumns', ["activeColumnService", function (activeColumnService) {
	var redomColumn = new Redom('<div class="column-marker sub-marker"></div>');
	var redomPrimary = new Redom('<div class="column-marker main-marker"><img src="'+indicatorArrowSrc+'" alt="Indicator Arrow" class="indicator-arrow"></div>');
	//var redomImg = 
    return {
        restrict: 'EA',
		//scope: true,
		link: function(scope:any, element, attrs, __, transcludeFn){
			activeColumnService.subscribe(function(cols, min, max){
				element.empty();
				if(cols === false){ return; }
				
				var subCols = activeColumnService.getSubColumns({min:min, max:max, columns:cols});
				
				var shouldDrawInner = activeColumnService.shouldDrawInner();
				if(shouldDrawInner){
					var columnDoms = redomColumn.get(subCols.length);
					var primaryDoms = redomPrimary.get(Math.ceil(subCols.length/7)+1);
				}
				else{
					var primaryDoms = redomPrimary.get(subCols.length);
				}
				
				function draw(col, dom){
					if(shouldDrawInner || col.fullDate){
						dom.style.left = col.finishX + 'px';
						element[0].appendChild(dom);
					}
				}
				
				for(var i = 0; i < subCols.length; i++){
					var col = subCols[i];
					if(col){
						if(col.fullDate){
							draw(col, primaryDoms.pop()[0]);
						}
						else{
							draw(col, columnDoms.pop()[0]);
						}
					}
				};
			});
		}
    };
}])
.directive('activeDates', ["activeColumnService", function (activeColumnService) {
	var redomColumn = new Redom('<span class="column-marker"></span>');
    return {
        restrict: 'EA',
		//scope: true,
		link: function(scope:any, element, attrs){
			element.addClass('activeDates');
			activeColumnService.subscribe(function(cols, min, max){
				element.empty();
				if(cols === false){ return; }
				
				var subCols = activeColumnService.getSubColumns({min:min, max:max, columns:cols});
				var columnDoms = redomColumn.get(subCols.length);
				for(var i = 0; i < subCols.length; i++){
					var col = subCols[i];
					//console.log('col', col, i, min, max);
					if(col.fullDate){
						var inner = columnDoms.pop()[0];
						inner.style.left = col.startX + 'px';
						//inner.style.width = '';
						inner.style.width = col.finishX - col.startX + 'px';
						//inner.innerHTML = col.fullDate;
						inner.textContent = col.fullDate; //supposedly this is more efficient?
						element[0].appendChild(inner);
					}
					else{
						var inner = columnDoms.pop()[0];
						inner.style.left = col.startX + 'px';
						inner.style.width = col.finishX - col.startX + 'px';
						//inner.innerHTML = col.dayDate;
						inner.textContent = col.dayDate;
						element[0].appendChild(inner);
					}
				}
				
			});
		}
    };
}])
.directive('activeMiniDates', ["activeColumnService", function (activeColumnService) {
	var redomColumn = new Redom('<div class="mini-date"></div>');
    return {
        restrict: 'EA',
		//scope: true,
		link: function(scope:any, element, attrs){
			//element.addClass('activeMiniDates');
			activeColumnService.subscribe(function(cols, min, max){
				var width = activeColumnService.width();
				var shouldDrawInner = activeColumnService.shouldDrawInner();
				element.empty();
				
				if(cols === false || !shouldDrawInner){ return; }
				
				var subCols = activeColumnService.getSubColumns({min:min, max:max, columns:cols});
				var columnDoms = redomColumn.get(subCols.length);
				for(var i = 0; i < subCols.length; i++){
					var col = subCols[i];
					var inner = columnDoms.pop()[0];
					inner.style.left = col.startX + 'px';
					inner.style.width = width + 'px';
					inner.innerHTML = col.minDate;
					element[0].appendChild(inner);
				};
			});
		}
    };
}])

//known bug here. This will forward all position updates to every update function.
//so it effectively only supports one background (and currently that's all it needs)
.factory('backgroundAreaService', function(){
	var updateFuncs = [];
	
	return {
		add: function(fn,id){
			updateFuncs.push({fn: fn, id: id});
		},
		recalc: function(start, end, id){ //startX, endX
			var args = arguments;
			updateFuncs.forEach(function(obj){
				//console.log('id match', id, obj);
				if(!id || obj.id === id){

					obj.fn.apply(undefined, args);
				}
			})
		}
	}
})
//the milestone position (milestone.scheduleX)
//the offset milestone position (milestone.targetX)
.directive('backgroundArea', ["$injector", "$parse", "backgroundAreaService", function ($injector, $parse, backgroundAreaService) {
	if($injector.has('shiftApi')){
		var shiftApi = $injector.get('shiftApi');
	}
	
    return {
        restrict: 'EA',
		//scope: true,
		transclude: true,
		link: function(scope:any, element, attrs, __, $transcludeFn){
			var edges = {
				start: 0,
				end: 0
			};
			var type;
			var setup = false;
			var parsed = {
				start: $parse(attrs.start),
				end: $parse(attrs.end)
			}
			
			//var preShift = {};
			var lastWatched:any = {};
			
			var transChild;
			var hidden;
			
			function updateChild(){
				if(!transChild){return;}
				
				transChild[1].style.left = hudify(edges.start)+"px";
				transChild[1].style.width = Math.abs(hudify(edges.start) - hudify(edges.end))+"px";
				// transChild.css({
				// 	left: hudify(edges.start)+"px",
				// 	width: Math.abs(hudify(edges.start) - hudify(edges.end))+"px"
				// });
			}
			if(attrs.transcludeParent){
				$transcludeFn(scope, function(clone){
					angular.element(attrs.transcludeParent).append(clone);
					transChild = clone;
					updateChild();

					//start hidden
					element.addClass("hide");
					if(transChild){transChild.addClass("hide");}
					hidden = true;
				});
			}
			
			var restrictToActive = attrs.restrictSide === "active";
			
			//stops side calculation
			var classOverride = !(attrs.classOverride === undefined);
			
			element.addClass('background-area');
			//console.log('attrs', attrs, element);
			
			//this edits the underlying value without angular knowing
			//so if the source x changes in some way that doesn't change the x value
			//the $watch won't fire, and the rendered x position could be stale
			//current thinking is to pull out of angular world and add some finer control
			function shiftBackground(x, activeLineX, side){
				if(restrictToActive && side === "pull"){return;}
				if(canUpdate()){
					
					if(side === "pull" && edges.start > activeLineX){
						edges.start += x;
						edges.end += x;
						// if(lineObj.$value +shiftOffset < activeLineX){
							// elem.addClass('hide');
						// }
						// else{ elem.removeClass('hide'); }
					}
					else if(side === "active" && edges.start <= activeLineX){
						if(attrs.scrollByExpanding === undefined){
							edges.end += x;
						}
						edges.start += x;
						
						// if(lineObj.$value +shiftOffset > activeLineX){
							// elem.addClass('hide');
						// }
						// else{ elem.removeClass('hide'); }
					}
					
					
					
					
					//parsed.start.assign(scope, edges.start);
					//parsed.end.assign(scope, edges.end);
					//console.log('shift');
					updateStuff();
				}
			}
			if(shiftApi){
				shiftApi.subscribe(shiftBackground);
				shiftApi.on("shiftStart", function(){
					//preShift = angular.extend({}, edges);
					//console.log('shiftStart', preShift);
				});
				shiftApi.on("shiftEnd", function(){
					//scope.pp.draw();
					//console.log('shiftEnd', edges.start, preShift.start);
					//updateStuff();
					//edges = preShift;
					// edges = {
						// start: edges.start + 100,
						// end: edges.end + 100
					// };
				});
				shiftApi.on("shiftCleanup", function(x, activeLineX, side){
					shiftBackground(x, activeLineX, side)
				});
			}
			
			
			function canUpdate(){
				return edges.start !== null && edges.start !== undefined
					&& edges.end !== null && edges.end !== undefined;
			}

			
			function updatePos(){
				//console.log('updating pos');
				if((!edges.start && edges.start !== 0) || (!edges.end && edges.end !== 0)){
					//console.log('not zero');
					element[0].style.left = "0px";
					element[0].style.width = "0px";
					return; 
				}
				// if(attrs.recalcFunction){
					// console.log('pos start:', edges.start, 'end:', edges.end);
				// }
				
				element[0].style.left = hudify(edges[type]) + 'px';
				element[0].style.width = Math.abs(hudify(edges.start) - hudify(edges.end)) + 'px';
				//console.log('updatePos', edges.end, Math.abs(hudify(edges.start) - hudify(edges.end)));
				updateChild();
			}
			
			function hudify(val){
				if(!scope.hud){ return 0; }
				return val * scope.hud.z + scope.hud.x;
			}
			
			function updateStuff(){
				//console.log('edges', edges.start, edges.end, attrs.recalcFunction, attrs.who);
				if((!edges.start && edges.start !== 0) 
						|| (!edges.end && edges.end !== 0)
						|| edges.start === edges.end){
					if(!hidden)
					{
						element.addClass("hide");
						if(transChild){transChild.addClass("hide"); }
						hidden = true;
					}
					//console.log('things are 0?');
					return; 
				}
				
				if(hidden){
					//console.log('things are hidden?');
					element.removeClass("hide");
					if(transChild){transChild.removeClass("hide");}
					hidden = false;
				}
				//console.log('updating stuff', edges.start);
				
				if(!setup){
					if(scope.hud){
						scope.orderedExecution.add("drawBackgroundArea",function(){ AnimationQueue(updatePos); });
						setup = true;
					}
				}
				
				
				if(edges.start < edges.end){
					type = 'start';
					if(!classOverride){
						element[0].className = 'background-area late';
					}
				}
				else{
					type = 'end';
					if(!classOverride){
						element[0].className = 'background-area early';
					}
				}
				//console.log('start (targetX): ', edges.start, '('+hudify(edges.start)+')', ', end (scheduleX): ', edges.end, '('+hudify(edges.end)+')', 'selected:', type);
				updatePos();
			}
			
			function watchUpdate(start, end){
				//console.log('watchUpdate');
				if(!start && !end){
					edges.start = start;
					edges.end = end;
					lastWatched.start = null;
					lastWatched.end = null;
					updateStuff();
					return;
				}
				if(!start){start = lastWatched.start;}
				if(!end){end = lastWatched.end;}
				
				edges.start = start;
				edges.end = end;
				
				lastWatched.start = start;
				lastWatched.end = end;
				updateStuff();
			}
			
			scope.$watch(attrs.start, function(value){
				if(attrs.recalcFunction){return;}
				watchUpdate(value, null);
				//console.log('start watch', value);
				// edges.start = value;
				// updateStuff();
				// lastWatched.start = value;
			});
			scope.$watch(attrs.end, function(value){
				if(attrs.recalcFunction){return;}
				watchUpdate(null, value);
				//console.log('end watch', value);
				// edges.end = value;
				// updateStuff();
			});
			scope.$on('destroy', function(){
				//console.log('boom');
				if(shiftApi){shiftApi.clear();}
			});
			
			if(attrs.recalcFunction){
				//console.log('scope, pre-add', scope.$id);
				backgroundAreaService.add(function(startX, endX){
					//console.log('recalcFunction called', scope.$id, startX, endX);
					if(startX !== undefined){edges.start = startX;}
					if(endX !== undefined){edges.end = endX;}
					updateStuff();
				}, attrs.who);
			}
			
			//attrs
			//observationList
			//
		},
		//template:'<ng-transclude></ng-transclude>',
    };
}]);
