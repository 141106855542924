import { Subject, Observable, of, from, merge, empty } from "rxjs";
import { takeUntil, map, mergeMap, switchMap, pairwise, tap, take, filter, groupBy, debounce, debounceTime, bufferTime, buffer } from "rxjs/operators";
import { drop } from "ng2/common/rxjs-internal-extensions";

import { LocalStorageHelper } from "ng2/canvas-renderer/utils/LocalStorageHelper";

import {renderQueue} from "ng2/common/RenderQueue";

import * as utils from "utils";

const localKey = 'enablePerfLogging';


interface PerfDataExtra{
	/** a value to be subtracted from the timestamp (used for special events) */
	timestampOffset?: number,
	/** indicate that this event will happen a lot, default logger with filter these out */
	spammy?: boolean,
	/** indicate that this event should be settled */
	logSettle?: boolean,
	/** specify a predcessor event, allows tracking of start and end events */
	previousEventName?: string,
	[key: string]: any
}

class PerfData{
	constructor(
		public name: string,
		public extraData:PerfDataExtra = {}
	){}
	public timestamp: number;
	public sinceLast: number;
	public sinceFirst: number;
	public sinceActualLast?: number;
}

interface PerfDataBuffered extends PerfData{
	firstTimestamp: number;
	lastTimestamp: number;
	firstEvent: PerfData;
	lastEvent: PerfData;
	bufferedEventCount: number;
	selfTime?: number;
}

export class Perf{
	public startedTimestamp: number;
	public enabled = true;
	public stream$: Observable<PerfData> = empty();
	private output$ = new Subject<PerfData>();
	
	private internal = new Subject();
	private shutdown = new Subject();
	
	private lastEventCache: Map<string, PerfData>;
	
	constructor(){
		if(MB4_PREPROCESSOR_CONFIG.ENABLE_DEV_MODE || LocalStorageHelper.read(localKey)){
			this.start();
		}
	}
	
	public start(){
		this.enabled = true;
		this.startedTimestamp = Date.now();
		this.lastEventCache = new Map();
		
		var setup = this.internal.pipe(
			mergeMap((thing: PerfData | Observable<PerfData>)=>{
				if(thing instanceof Observable){ return thing; }
				else { return of(thing); }
			}),
			takeUntil(this.shutdown),
			tap(thing =>{
				var offset = 0;
				if(thing.extraData && thing.extraData.timestampOffset){ offset = thing.extraData.timestampOffset; }
				thing.timestamp = Date.now() - offset;
				thing.sinceFirst = thing.timestamp - this.startedTimestamp;
				
				if(thing.extraData.previousEventName && this.lastEventCache.has(thing.extraData.previousEventName)){
					let lastThing = this.lastEventCache.get(thing.extraData.previousEventName);
					thing.sinceActualLast = thing.timestamp - lastThing.timestamp;
				}
				this.lastEventCache.set(thing.name, thing);
			}),
		);
		var firstItem = setup.pipe(
			take(1),
			tap(thing => thing.sinceLast = 0)
		);
		var paired = setup.pipe(
			pairwise(),
			map((pair)=>{
				pair[1].sinceLast = pair[1].timestamp - pair[0].timestamp;
				return pair[1];
			})
		);
		
		merge(firstItem, paired)
		.subscribe(this.output$);
		
		this.stream$ = this.output$.pipe(
			
		)
		
	}
	public stop(){
		this.enabled = false;
		this.shutdown.next(true);
	}
	
	public enablePersistent(){
		LocalStorageHelper.write(localKey, true);
		if(!this.enabled){ this.start(); }
	}
	public disablePersistent(){
		LocalStorageHelper.remove(localKey);
		this.stop();
	}
	
	
	//special extraData keys:
	//- timestampOffset - value to be subtracted from the timestamp
	
	public log(name: string, extraData?:PerfDataExtra){
		this.internal.next(new PerfData(name, extraData));
	}
	public merge(obs: Observable<{name: string, extraData?:PerfDataExtra}>){
		this.internal.next(obs);
	}
}

let color = "color: blue; padding:0 10px;"
let attentionGrabbingColor = "color: lime; background-color: black; padding:0 10px;"
function prettyLog(first:string, ...stuff){
	var arr = ["%cPerf - "+first, color, ...stuff];
	console.log.apply(console, arr); //do this
}
function prettyDramaticLog(first:string, ...stuff){
	var arr = ["%cPerf - "+first, attentionGrabbingColor, ...stuff];
	console.log.apply(console, arr); //do this
}

export let perf = new Perf();
// perf.stop();
perf.stream$.pipe(
	filter(thing => !thing.extraData.spammy)
)
.subscribe(thing =>{
	var timeDescriptor = "";
	var time;
	if(thing.sinceActualLast){
		time = thing.sinceActualLast / 1000;
		timeDescriptor = " from "+thing.extraData.previousEventName+" event";
	}
	else{ time = thing.sinceLast / 1000; }
	
	prettyLog(thing.name +" ("+ time + "s"+ timeDescriptor+")", thing);
});

const delay1000$ = renderQueue.ob$.pipe(drop(60));
const delay5000$ = renderQueue.ob$.pipe(drop(300));

perf.stream$.pipe(
	filter(thing => thing.extraData.logSettle),
	groupBy(thing => thing.name),
	mergeMap(thing$ => {
		let time = 1000;
		let notifier = thing$.pipe( debounce(() => delay1000$ ) );
		return thing$.pipe(
			buffer(notifier),
			filter(list => !!list.length),
			map(list => {
				let buffered:PerfDataBuffered = new PerfData(list[0].name, list[0].extraData) as PerfDataBuffered;
				buffered.firstEvent = list[0];
				buffered.lastEvent = list[list.length -1];
				buffered.firstTimestamp = list[0].timestamp;
				buffered.lastTimestamp = list[list.length-1].timestamp;
				buffered.timestamp = buffered.lastTimestamp;
				buffered.sinceLast = buffered.lastTimestamp - buffered.firstTimestamp;
				buffered.sinceFirst = 0;
				buffered.bufferedEventCount = list.length;
				
				//just check the first, possibly dangerous
				if(list[0].sinceActualLast !== undefined){
					// console.log('doing a thing', buffered.name);
					buffered.selfTime = list.reduce((total, next)=>{
						return next.sinceActualLast + total;
					}, 0);
				}
				
				return buffered;
				
			}),
			tap(thing => thing.name = thing.name + " - buffered")
		)
	})
)
.subscribe(thing => {
	var timeString;
	if(thing.selfTime){
		timeString = ` (${thing.selfTime/1000}s / ${thing.sinceLast/1000}s)`;
	}
	else{
		timeString = ` (${thing.sinceLast/1000}s)`
	}
	
	var last = thing.sinceLast ? thing.sinceLast / 1000 : 0;
	prettyDramaticLog(thing.name + timeString, thing);
});

var startTime = Date.now();
// perf.stream$.pipe(
// 	filter(thing => thing.name === "postrender" || thing.name === "ticket data settled"),
// 	debounceTime(5000), //<---- this causes a perf hit on renders, fix if uncommenting
// 	map(thing => Date.now()),
// 	take(1)
// ).subscribe(time => prettyDramaticLog(`App settled in ${((time - 5000) - startTime)/1000}s (since refresh)`));

window.perf = perf;
