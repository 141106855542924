import {Injectable, ErrorHandler} from "@angular/core";

import {sessionErrorTracking} from "ng2/common/SessionErrorTracking";

@Injectable()
export class AnotherErrorHandler extends ErrorHandler{
	constructor(){
		// console.log('init error handler');
		super();
	}
	handleError(error){
		super.handleError(error);
		this.legacyErrorHandling(error);
		
	}
	
	//the old error handling code just pasted in as a temp fix
	legacyErrorHandling(event){
		// console.log('error event listener', event, event.error, event.error.stack, JSON.stringify(event.error.stack));
		//hack n' slash
		var fbReporting = window.reporting;
		
		var fakeError = "oops!  An error has occurred.  Contact support if you have any issues.";
		var extra = {
			url: null,
			line: null,
			col: null,
			stack: null,
			code: null
		};
		var errorMessage:string = event.message;
		if(event.filename){ extra.url = event.filename; }
		if(event.lineno){ extra.line = event.lineno; }
		if(event.colno){ extra.col = event.colno; }
		//apparently there's poor support?
		if(event.error){
			var e = event.error;
			if(e.stack){ extra.stack = e.stack; }
			if(e.code){ extra.code = e.code; }
		}
		
		//it's a fairly normal error
		if(event.stack){ extra.stack = event.stack; }

		//list of errors to be explictly ignored
		var errorSuppressionList = [
			"PERMISSION_DENIED: Permission denied",
		];
		//list of errors to be explictly ignored, it's also fuzzy
		var fuzzyUISuppressionList = [
			"SecurityError (DOM Exception 18)",
			"permission_denied"
		];

		var ignoreError = false;
		var hideError = false;
		for(var i = 0; i < errorSuppressionList.length; i++){
			if(errorSuppressionList[i] === errorMessage){ignoreError = true;}
		}
		for(var i = 0; i < fuzzyUISuppressionList.length; i++){
			// if(errorMsg.indexOf(fuzzyUISuppressionList[i]) !== -1){hideError = true;}
			if(errorMessage.toLowerCase().indexOf(fuzzyUISuppressionList[i].toLowerCase()) !== -1){ignoreError = true;}
		}
		
		sessionErrorTracking.pushError(errorMessage);
		
		if(!ignoreError){
			// console.log('extra', extra);
			if(fbReporting){ fbReporting.reportError(errorMessage, extra); }
			if(!hideError){
				Logging.error(fakeError);
			}
		}
		Logging.debug(errorMessage);
		return false;
	}
}
