import{ Component, ViewChild, ElementRef, OnInit, ChangeDetectionStrategy } from "@angular/core"
import { Router,
	NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from "@angular/router";
import { TemplateRef } from '@angular/core';


@Component({
		selector:'loading-component',
		template: require('./loading.component.html'),
		changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoadingComponent implements OnInit {
	public shown = true;
	//Due to the nature of how the main thread gets block on state change to plan, we can't use ngIf
	@ViewChild('base') base:ElementRef;

	constructor (private router: Router){
		
	}
	
	ngOnInit(){
		this.base.nativeElement.classList.add('show');
		this.router.events.subscribe(event=>{
			if(event instanceof NavigationStart){
				this.shown = true;
				// console.log('this.base', this.base.nativeElement);
				this.base.nativeElement.classList.add('show');
				
				// console.log('show the thing');
				// console.time('loading');
			}
			else if(event instanceof NavigationEnd
					|| event instanceof NavigationCancel
					|| event instanceof NavigationError){
				this.shown = false;
				this.base.nativeElement.classList.remove('show');
				// console.log('hide the thing');
				// console.timeEnd('loading');
			}
			
		})
	}
}
