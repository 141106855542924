import {first, filter} from "rxjs/operators";

//just types
import { Ticket } from "ng2/common/models/Ticket";
import { FancyAction } from "ng2/fancy-firebase/base";
import { PlanState } from "ng2/common/services/plan-state.service";
import {TicketList} from "ng2/fancy-firebase/factories";

import { TicketActionTypes, FancyTicketAction } from "ng2/actions/ticket-actions";

import { ListLike } from "utils";




/**
 * ---------------------------------------
 * h1. DO NOT USE ARROW FUNCTIONS IN THIS FILE
 * ---------------------------------------
 */


// interface listLike{
// 	forEach: typeof Array.prototype.forEach
// }

//types/ interfaces
type TicketOrTicketArray = Ticket|ListLike<Ticket>;
function isTicketArray(tick: any): tick is TicketOrTicketArray {
	return tick.forEach !== undefined;
}


/**
 * Take an action builder which accepts a ticketList and spilts the list into multiple actions
 * @param  selectedTickets
 */
const _multiplexTicketArray = (planState, selectedTickets: TicketOrTicketArray, action:TicketActionTypes)=>{
	if(!selectedTickets){ console.log('boom'); return; }
	var arr = forceArray(selectedTickets);
	arr.forEach(function(t:Ticket){
		if(t.isTimelineTicket){
			planState.timelineTickets.appEvent$.next(new FancyTicketAction(t, action))
		}
		else{
			planState.tickets.appEvent$.next(new FancyTicketAction(t, action))
		}
	});
}
function forceArray(selectedTickets: TicketOrTicketArray):ListLike<Ticket>{
	return isTicketArray(selectedTickets) ? selectedTickets : [selectedTickets] as any;
}

/** Change the passed in ticket(s) to edit view */
export function changeTicketViewToEdit(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketChangedToEdit);
}
/** Change the passed in ticket(s) to edit view */
export function changeTicketViewToDrag(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketChangedToDrag);
}

/** Change the passed in ticket(s) to default view */
export const changeTicketViewToDefault = function(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketChangedToDefault);
}




export const selectTicket = function(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketSelected);
}
export const unSelectTicket = function(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketUnselected);
}
//this does not currently support timeline tickets
export const selectTicketDelayed = function(idOrIdArray){
	if(!idOrIdArray){return;}
	let arr:Array<string> = idOrIdArray.length ? idOrIdArray : [idOrIdArray];
	arr.forEach(id =>{
		this.tickets.appEvent$.next(new FancyTicketAction(id, TicketActionTypes.ticketSelectionDelayed, id));
	});
	
}

export const addTicketToSearch = function(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketSearched);
}
export const removeTicketFromSearch = function(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketUnsearched);
}

export function selectTicketToggle(selectedTickets: TicketOrTicketArray){
	var self = this;
	var arr = forceArray(selectedTickets);
	arr.forEach(function(t){
		if(t.selected){ self.tickets.appEvent$.next(new FancyTicketAction(t, TicketActionTypes.ticketUnselected)) }
		else{ self.tickets.appEvent$.next(new FancyTicketAction(t, TicketActionTypes.ticketSelected)) }
	});
}

//since this should execute syncronously anyways... lets try something
export function addFloatingTicketToPlan(startingData: any){
	var ticketAction;
	this.tickets.rawEvent$.pipe(
		filter(act => (<any>act).type === TicketActionTypes.floatingTicketAdded),
		first()
	)
	.subscribe(tick =>{
		ticketAction = tick;
	})
	this.tickets.appEvent$.next(new FancyTicketAction(startingData, TicketActionTypes.floatingTicketAdded));
	return ticketAction;
}
/** This applies to both originally floating tickets and normal plan tickets, the "backend" gets to differentiate */
export function moveTicketToFloating(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketMovedToFloating);
}
export function returnTicketToPlan(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketReturnedToPlan);
}
export function saveFloatingTicketToPlan(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.floatingTicketSaved);
}
/** Technically this is a no-op, but it allows the rest of the app to react instead of just ticket_handling */
export function saveTicketToFloating(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketSavedToFloating);
}

/** Legacy */
export const ticketDragged = function(ticketData: any, ticketId: string){
	this.tickets.appEvent$.next(new FancyTicketAction(ticketData, TicketActionTypes.ticketDragged, ticketId));
}
/** Modify local ticket data, applies your passed in data to both the selected renderType and the view. */
export const updateTicketLiveData = function(ticketId: string, ticketData: any, ticketListOverride?: TicketList){
	// debugger;
	if(!ticketListOverride){ ticketListOverride = this.tickets; }
	ticketListOverride.appEvent$.next(new FancyTicketAction(ticketData, TicketActionTypes.ticketLiveUpdate, ticketId));
}
//TODO - fix this null thing
/** Copies local data to database and persists to firebase */
export const persistTicketData = function(selectedTickets: TicketOrTicketArray, ticketListOverride?: TicketList){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketPersistData);
	// this.tickets.appEvent$.next(new FancyTicketAction(null, TicketActionTypes.ticketPersistData, ticketId));
}

/** only use this if your going to do something else with the data, since this one will not trigger a notification */
export const persistTicketDataLocalOnly = function(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.ticketPersistDataLocalOnly);
}

export const deleteTicket = function(selectedTickets: TicketOrTicketArray){
	return _multiplexTicketArray(this, selectedTickets, TicketActionTypes.deleteTicket);
}

/** This will never be an output action, but instead map to a series of movedToFloating actions */
export const removeFloatingTickets = function(){
	this.tickets.appEvent$.next(new FancyTicketAction(null, TicketActionTypes.removeFloatingTickets, "fake"));
}

export const createTicketWithId = function(ticketId: string, ticketData: any, ticketListOverride?: TicketList, config?: any){
	// debugger;
	if(!ticketListOverride){ ticketListOverride = this.tickets; }
	let action = new FancyTicketAction(ticketData, TicketActionTypes.createTicketWithId, ticketId);
	(action as any).config = config || {};
	ticketListOverride.appEvent$.next(action);
}

/**
 * Set a dependency in the simplest, most common way, starting at the src, to the tapped one. Toggling, and persisting.
 * @param  tappedTicketId String or Ticket corresponding to the ticket tapped on
 */
// export const simpleDependencyToggle = function(this:PlanState, tappedTicketId: string | Ticket){
export const simpleDependencyToggle = function(tappedTicketId: string | Ticket){
	if(this.dependencies.syncMode && this.dependencies.syncMode.target){
		let tappedTicket = tappedTicketId as Ticket;
		if(typeof tappedTicketId === "string"){ tappedTicket = this.dependencies.checkTicketCache(tappedTicketId); }
		let srcTicketId = this.dependencies.syncMode.target;
		let srcTicket = this.dependencies.checkTicketCache(srcTicketId);
		let predData = this.dependencies.togglePredecessor(tappedTicket.$id);
		console.log('pred data', predData);
		if(!predData){return;}
		// debugger;
		// this.actions.updateTicketLiveData(srcTicketId, {predecessors: predData[0]}, srcTicket.isTimelineTicket ? this.timelineTickets : this.tickets);
		// this.actions.updateTicketLiveData(tappedTicket.$id, {successors: predData[1]}, tappedTicket.isTimelineTicket ? this.timelineTickets : this.tickets);
		
		this.actions.updateTicketLiveData(srcTicket.$id, predData[0], srcTicket.isTimelineTicket ? this.timelineTickets : this.tickets);
		this.actions.updateTicketLiveData(tappedTicket.$id, predData[1], tappedTicket.isTimelineTicket ? this.timelineTickets : this.tickets);
		
		this.actions.persistTicketData([srcTicket, tappedTicket]);
	}
}

export const abortEdit = function(){
	var self = this;
	this.tickets.editTicket$.pipe(first()).subscribe(function(edits){
		if(edits && edits.list.size > 0){
			changeTicketViewToDefault.call(self, Array.from(edits.list.values()));
		}
	})
}
