declare const document: any;
/**
 * Manages a stored HTMLCanvasElement.
 */
export class FrameBuffer{
	private canvasFrame: HTMLCanvasElement;
	private canvasContext: any;
	constructor(w, h){
		if (!w || !h){throw "FrameBuffer must have a width and height!"}
		this.canvasFrame = document.createElement('canvas');
		this.canvasContext = this.canvasFrame.getContext('2d');
		this.resize(w, h);
	}
	/**
	 * Returns the Canvas element frame buffer
	 * @returns {HTMLCanvasElement}
	 */
	public getCanvas(){
		return this.canvasFrame;
	}
	/**
	 * returns the canvas drawing context
	 * @returns {any}
	 */
	public getContext(){
		return this.canvasContext;
	}
	/**
	 * Resizes the frame buffer to the new width and height.
	 * @param {number} w The new frame buffer width
	 * @param {number} h The new frame buffer height
	 * @returns FrameBuffer
	 */
	public resize(w: number, h: number){
		this.canvasFrame.width = w;
		this.canvasFrame.height = h;
		return this
	}
	/**
	 * Destroys the tracked canvas element and removes local references.
	 * @returns FrameBuffer
	 */
	public destroy(){
		this.canvasFrame = null;
		this.canvasContext = null;
		return this;
	}
}