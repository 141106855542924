import { Container, Text } from "pixi.js";
import { LevelOfDetail } from "../core/LevelOfDetail";

export class RenderTime extends Text{
	public container: Container = new Container();
	public ticker;
	public preUpdateTime: number = 0;
	public updateTime: number = 0;
	public postUpdateTime: number = 0;
	public renderTime: number = 0;
	public fps: number = 0;
	public drawCount: number = 0;
	public stopUpdate = false;
	constructor(text: string, style, stage){
		super(text, style);
		if(!stage){ this.stopUpdate = true; return; }
		this.container.x = 100;
		this.container.y = 100;
		this.container.addChild(this);
		stage.addChild(this.container);
		this.update();
	}
	public update(){
		if(this.stopUpdate){ return; }
		this.text = "Renderer: \n" + " Update: " + this.updateTime + "ms\n Draw: " + this.renderTime + "ms\n Detail: " + LevelOfDetail.getLOD() + "\n Total on Screen: " + this.drawCount + "\n FPS:" + this.fps;
	}
}
