import {FancyAction} from "./FancyAction";

export enum FancyActionTypes{
	childAdded = "child_added",
	childChanged = "child_changed",
	childRemoved = "child_removed",
	childReplayed = "child_replayed",
}

export type CombinedFancyActions<PayloadModel = any> = 
	| FancyAddAction<PayloadModel>
	| FancyChangedAction<PayloadModel>
	| FancyRemovedAction<PayloadModel>
	| FancyReplayedAction<PayloadModel>;

export interface FancyAddAction<PayloadModel = any> extends FancyAction{
	type: FancyActionTypes.childAdded,
	payload: PayloadModel
}
export interface FancyChangedAction<PayloadModel = any> extends FancyAction{
	type: FancyActionTypes.childChanged,
	payload: PayloadModel
}
export interface FancyRemovedAction<PayloadModel = any> extends FancyAction{
	type: FancyActionTypes.childRemoved,
	payload: PayloadModel
}
export interface FancyReplayedAction<PayloadModel = any> extends FancyAction{
	type: FancyActionTypes.childReplayed,
	payload: PayloadModel
}
