'use strict';

angular.module('ticketspaceApp')
.factory('shiftApi', function() {
	
	var _observers = {};
	
	//legacy api
	function subscribe(fn){
		on("shift", fn);
	}
	function shift(x, activeLineX, side){
		emit("shift", x, activeLineX, side);
	}
	
	
	//core
	//shiftStart,shift,shiftEnd
	function on(type, cb){
		if(!_observers[type]){ _observers[type] = []; }
		_observers[type].push(cb);
	}
	function emit(type, ...args){
		//var args = Array.prototype.slice.call(arguments, 1);
		if(_observers[type]){
			_observers[type].forEach(function(fn){
				fn.apply(undefined, args);
			});
		}
	}
	
	//nukes all the observers, mainly just for exiting a plan
	// and only really because implementing an off is much more effort
	function empty(){
		_observers = {};
	}
	
	
	//something else entirely
	var _arrows = [];
	function updateArrows(show, x, side, ratio){
		_arrows.forEach(function(fn){
			fn(show, x, side, ratio);
		});
	}
	function setArrowListener(fn, ctx){
		_arrows.push(fn.bind(ctx));
	}
	
	return {
		subscribe: subscribe,
		shift: shift,
		on: on,
		emit: emit,
		updateArrows: updateArrows,
		setArrowListener: setArrowListener,
		empty: empty
	}
});
