import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";

import { RallyBaseComponent } from "../rally-base.component";
import { RallyPointSignupService } from "ng2/common/services/rally-point-signup.service";

@Component({
	selector: 'rally-email',
	template: require('./rally-email.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RallyEmailComponent extends RallyBaseComponent implements OnInit {
	public rallyForm = this.formBuilder.group({
		email: ['']
	});

	constructor(
		private formBuilder: FormBuilder,
		public rallyPointSignupService: RallyPointSignupService
	) {
		super(rallyPointSignupService);
	}
	
	next(){
		this.rallyPointSignupService.startLoading(this.loadingSet);
		this.rallyPointSignupService.patchState(this.rallyForm.value);
		
		this.rallyPointSignupService.tryEmail(this.rallyForm.controls.email.value).then(result => {
			this.redirect(result);
		});
	}
}
