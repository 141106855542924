import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IColorPickerOptions, ColorPickerComponent } from "./color-picker.component";
import * as tinycolor from "tinycolor2";
const defaultColorPicketPalleteWithAlphaOptions: IColorPickerOptions = {
	showAlpha: true,
	preferredFormat: 'rgb'
};

@Component({
	selector: 'color-picker-with-alpha',
	template: require('./color-picker.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ColorPickerWithAlphaComponent extends ColorPickerComponent {
	
	@Input() options: IColorPickerOptions = defaultColorPicketPalleteWithAlphaOptions;
	
	constructor() {
		super();
	}
}
