/**
 * A thing to export the UI constants like sidebar width, header height, etc
 */
export enum PLAN_CONSTANTS {
	/**
	 * This is the width of the sidebar (Constraints, Members, Roles, Locations, etc...)
	 */
	SIDEBAR_WIDTH = 84,
	/**
	 * This is the height of the plan header (Where TouchPlan logo, Plan Actions, Support drop downs live)
	 */
	HEADER_HEIGHT = 62,
	/**
	 * This is the height of the Plan timeline
	 */
	TIMELINE_HEIGHT = 29,
	/**
	 * The height of the timeline day of week text
	 */
	TIMELINE_DOW_HEIGHT = 22,
	/**
	 * This is the combined height of the Header + timeline height
	 */
	TOPBAR_BEIGHT = 91,
	
	PULL_TICKET_WIDTH = 290,
	PULL_TICKET_HEIGHT = 270,
	/** Default width of an active side column */
	ACTIVE_COLUMN_WIDTH = 145,
	ACTIVE_COLUMN_HEIGHT = 135,
	CONSTRAINT_PANEL_WIDTH = 452,
	CONSTRAINT_DETAIL_PANEL_WIDTH = 340
}
