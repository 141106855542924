import { analyticsService } from "ng2/common/utils/AnalyticsService";
import * as angular from "angular";
import {LegacyRouting} from "ng2/routes/legacy-routing.service";

angular.module("ticketspaceApp")
.component("accessCodeMini",  {
	template: require("tpl/panels/access-code-mini.html"),
	bindings: {
		"accessCode": "<", //an instance of firebaseAccessCode
		"listLength": "<",
		"planName": "<",
		"project": "<",
		"selectedSidebar": "<",
		"onMore": "&",
		"onClose": "&"
	},
	controller: ["$scope", "$element", "$timeout", "rallyPointService", "legacyRouting", "accessCheck", function($scope, $element, $timeout, rallyPointService, legacyRouting, accessCheck){
		var ctrl = this;
		this.isMore = false;
		this.accessCheck = accessCheck;

		this.copyAccessCode = function(){
			Logging.notice('Access code copied to clipboard!');
		};

		this.toggleMore = function toggleMore(force){
			var direction;
			if(force !== undefined){
				direction = force;
			}
			else{
				direction = this.selectedSidebar === 'rallyPointFull' ? false : true;
			}
			if(direction){ analyticsService.rallyPointShowDetailsClicked(); }
			else{ analyticsService.rallyPointHideDetailsClicked(); }
			this.onMore({'isMore': direction});
		}
		
		this.createRallyPoint = function createRallyPoint(){
			var state = legacyRouting.oldStyleSnapshot();
			rallyPointService.tryToCreate(state.projectId, state.planId);
			this.toggleMore(true);
		}
		
		this.notifyNew = function notifyNew(){
			$element.addClass('notify');
			this.showNotification = true;
			$timeout(function(){
				$element.removeClass('notify');
				ctrl.showNotification = false;
			}, 5000);
		}
		
		this.$onChanges = function(change){
			//if we want to change this to log all the time (with the "Logging" fallback), this functionality will need to be broken out into an observer/ subscriber service
			// otherwise it just won't do anything while this is closed
			if(change.listLength){
				var ll = change.listLength;
				if(ll.previousValue && ll.currentValue > ll.previousValue){
					this.notifyNew(ll.currentValue);
				}
			}
		}
		
		$scope.closeSidebar = function closeSidebar(){
			ctrl.onClose();
		}
	}]
})


.component("accessCodeFull",  {
	template: require("tpl/panels/access-code-full.html"),
	bindings: {
		members: "<",
		roles: "<",
		projectMemberList: "<", //used for writes
		onClose: "&",
		onDelete: "&"
	},
	controller: ["$scope", "rallyPointService", "popup", function($scope, rallyPointService, popup){
		var ctrl = this;
		//now finally do the damn update
		this.updateProjectMember = function updateProjectMember(id, property, value){
			//console.log('update', this, arguments);
			if(!this.projectMemberList){console.log('projectMember list is required...'); return;}
			
			var pm = this.projectMemberList.fb.$getRecord(id);
			if(pm){
				pm.data[property] = value;
				this.projectMemberList.fb.$save(pm);
			}
		}
		this.deleteProjectMember = function(pmId, userId){
			ctrl.onDelete({projectMemberId: pmId, userId: userId});
		}
		
		$scope.closeSidebar = function closeSidebar(){
			ctrl.onClose();
		}
	}]
})

.component("accessCodeMemberTable",  {
	template: require("tpl/panels/access-code-full-table.html"),
	bindings: {
		members: "<", //TODO - NEED TO SETUP AN ONCHANGE TO CLONE VALUES WHEN THEY CHANGE. Using them directly will update the parent at the wrong time.
		roles: "<",
		showAdmin: "<",
		showDelete: "<",
		onUpdate: "&",
		onDelete: "&"
	},
	controller: function(){
		var ctrl = this;
		this.updateMember = function updateMember(id, property, value){
			this.onUpdate({projectMemberId: id, property: property, value: value});
		}
		this.deleteMember = function updateMember(id, userId){
			this.onDelete({projectMemberId: id, userId: userId});
		}
		
		this.sortFn = function sortFn(thing){
			return thing.data[ctrl.sortType];
		}
		ctrl.sameCount = 0;
		this.clickHeader = function clickHeader(key){
			if(ctrl.sortType === key){ ctrl.sameCount++; }
			else{ 
				ctrl.sortType = key; ctrl.sameCount = 0;
				analyticsService.rallyPointListSorted(key);
			}
		}
		
		this.$onInit = function(){
			this.sortType = "email";
		};
	}
})

//evidentally this can't be a component because tables
.directive("accessCodeMemberRow", ["multipleRolesUtils", "$timeout", function(multipleRolesUtils, $timeout){
	return {
		restrict: "A",
		template: require("tpl/panels/access-code-row.html"),
		bindToController: {
			"company": "<",
			"email": "<",
			"roleId": "<",
			"accessLevel": "<",
			"showAdmin": "<",
			"showDelete": "<",
			"roles": "<",
			"secondaryRoles": "<",
			"onUpdate": "&",
			"onDelete": "&"
		},
		scope: true,
		controllerAs: "$ctrl",
		controller: function(){
			var ctrl = this;
			//console.log('this', this);
			this.updateAdmin = function updateAdmin(admin){
				ctrl.onUpdate({value: admin, property: "accessLevel"});
			}
			this.updateRole = function updateRole(selectedRoles){
				if (!selectedRoles || !selectedRoles.length){
					Logging.warning("User must have at least one role!");
					return
				}
				// Update came from Angular 5 land. Try to force change detection...
				$timeout(()=>{
					ctrl.onUpdate({value: multipleRolesUtils.parseRoleList(selectedRoles), property: "secondaryRoles"});
					ctrl.onUpdate({value: selectedRoles[0].$id, property: "roleId"});
					this.role = selectedRoles;
				});
			}
			this.deleteMember = function(){
				ctrl.onDelete();
			}
			
			this.$onChanges = function(changesObj){
				//console.log('change-e', changesObj);
				if(changesObj.roleId || changesObj.secondaryRoles){
					this.role = multipleRolesUtils.unflattenAssignedRoles(this.roles.fb, this.secondaryRoles, this.roleId); //this.roles.fb.$getRecord(changesObj.roleId.currentValue);
				}
				
			}
			
			this.$onInit = function(){
				
			};
			
			
		}
	}
}])
