import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyActionTypes } from 'ng2/fancy-firebase/base';
import { LaborCount} from "ng2/common/models/LaborCount";
import { DefaultActionHandlers, DefaultActionTypes } from "ng2/fancy-firebase/generics";

export class LaborCountList extends FancyFirebaseList<LaborCount, FancyAction> {
	private defaultActionsHandlers:DefaultActionHandlers<LaborCount, FancyAction, LaborCountList> = new DefaultActionHandlers(this, [DefaultActionTypes.addItem]);
	
	protected addActions = {
		[FancyActionTypes.childAdded]: FancyActionTypes.childAdded,
		[DefaultActionTypes.addItem]: DefaultActionTypes.addItem
	};
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		let labor = new LaborCount(action.key);
		labor.databaseUpdate(action.payload.val());
		return labor;
	}
	
	$$updated(child: LaborCount, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.databaseUpdate(action.payload.val());
	}
	//
	// applyJoinData(baseKey: string, child: any) {
	// 	console.log("APPLY JOIN", baseKey, child);
	// }
	//
	// getDataForKey(baseKey: string, child: any) {
	// 	console.log("GET DATA", baseKey, child);
	// 	return child.view[baseKey]
	// }
	//
	// getPrevDataForKey(baseKey: string, child: any) {
	// 	console.log("GET PREV DATA", baseKey, child);
	// }
}