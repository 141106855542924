import { Ticket } from "./Ticket";
import { Rectangle } from "pixi.js";

interface selectable{
	selected: boolean
}

class AbstractSelection<Model extends selectable>{
	public list:Map<string, Model> = new Map();
	public combinedRect = new Rectangle();
	
	add(key: string, thing: Model){
		this.list.set(key, thing);
		this.addRectangle(thing);
	}
	// tryAdd(key: string, thing: Model){
	// 	if(thing.selected && !this.list.has(key)){
	// 		this.add(key, thing);
	// 		return true;
	// 	}
	// 	return false;
	// }
	remove(key:string){
		this.list.delete(key);
		this.recalcRectangle();
	}
	// tryRemove(key:string){
	// 	if(this.list.has(key) && !this.list.get(key).selected){
	// 
	// 	}
	// }
	addRectangle(thing:Model){}
	recalcRectangle(){}
}

export class TicketSelection extends AbstractSelection<Ticket>{
	public statistics = {
		completed: new Map<string, Ticket>(),
		pinned: new Map<string, Ticket>()
	};
	
	updateStats(changedTicket: Ticket){
		if(this.list.has(changedTicket.$id)){
			//completed check
			if(changedTicket.view.isActualized && !this.statistics.completed.has(changedTicket.$id)){
				this.statistics.completed.set(changedTicket.$id, changedTicket);
			}
			else if(!changedTicket.view.isActualized && this.statistics.completed.has(changedTicket.$id)){
				this.statistics.completed.delete(changedTicket.$id);
			}
			
			//pinned check
			// if(changedTicket.view.liveLastPromise && !this.statistics.pinned.has(changedTicket.$id)){
			// 	this.statistics.pinned.set(changedTicket.$id, changedTicket);
			// }
			// else if(!changedTicket.view.isActualized && this.statistics.pinned.has(changedTicket.$id)){
			// 	this.statistics.pinned.delete(changedTicket.$id);
			// }
		}
		else{
			this.statistics.completed.delete(changedTicket.$id);
			this.statistics.pinned.delete(changedTicket.$id);
		}
	}
	
	addRectangle(ticket:Ticket){
		var v = ticket.view;
		var rect = new Rectangle(v.left, v.top, v.width, v.height);
		if(this.list.size === 1){
			this.combinedRect = rect;
		}
		else{
			this.combinedRect.enlarge(rect);
		}
	}
	//TODO - replace with a fancy cache
	recalcRectangle(){
		var values = this.list.values();
		
		
		var first = this.list.values().next();
		if(first.done){ this.combinedRect = new Rectangle(); return;}
		
		var v = first.value.view;
		this.combinedRect = new Rectangle(v.left, v.top, v.width, v.height);
		
		var next = values.next();
		while(!next.done){
			var v = next.value.view;
			var rect = new Rectangle(v.left, v.top, v.width, v.height);
			this.combinedRect.enlarge(rect);
			
			next = values.next();
		}
	}
}
