import * as tinycolor from "tinycolor2";
import * as angular from "angular";

import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

(function(){
	'use strict';
	angular.module('ticketspaceApp')
	.factory('dynamicCss', dynamicCss)
	.factory('dynamicCssLocations', dynamicCssLocations)
	.factory('dynamicCssRoles', dynamicCssRoles).run(["dynamicCssRoles", "dynamicCssLocations", function(dynamicCssRoles, dynamicCssLocations){}])

	function dynamicCss(){
		return {
			makeStyleSheet: makeStyleSheet
		};
		
		function makeStyleSheet(){
			let styleDom = document.createElement('style');
			document.head.appendChild(styleDom);
			let sheet = styleDom.sheet;
			return sheet;
		}
	}
	
	let colors = {};
	
	dynamicCssRoles.$inject = ["dynamicCss", "projectNotifier", "fbConnection", "firebaseIndex", "SystemColors"];
	function dynamicCssRoles(dynamicCss, projectNotifier: ProjectNotifier, fbConnection, firebaseIndex, SystemColors){
		colors = SystemColors.ROLE_CLASS_NAME_TO_COLOR;
		let projectId = null;
		let fbRolesList = null;
		let sheet = dynamicCss.makeStyleSheet();
		let rolesRef = null;
		let colorsRef = null;
		let external = {
			"rolesList": function(){ return fbRolesList; }
		};

		projectNotifier.projectState.subscribe(function(stateObj){
			if(projectId !== stateObj.projectId){
				projectId = stateObj.projectId;
				teardown();
				if(projectId){ setup(projectId); }
			}
		});
		
		let extendedConstructor = firebaseIndex.$extend({
			"handleRoleColorChanged": function(obj){
				let rec = this.$getRecord(obj.$id);
				if(rec && rec.rule){
					let ruleIdx = Array.prototype.indexOf.call(sheet.cssRules, rec.rule);
					let dynamicTextRule = Array.prototype.indexOf.call(sheet.cssRules, rec.textRule);
					if(ruleIdx !== -1) {
						sheet.deleteRule(ruleIdx);
						sheet.deleteRule(dynamicTextRule-1);
					}
				}
			},
			"$$getDataRef": function(idxSnap){
				let colorId = idxSnap.child('roleColor').val();
				if (idxSnap.exists() && colorId && colorId.indexOf('#') < 0){
					return this._baseDataRef.child(colorId);
				}
				return idxSnap.ref
			},
			"$$addedIdx": function(snap, prevChild){
				let obj = firebaseIndex.prototype.$$addedIdx.apply(this, arguments);
				// projectId/colors/roles data is only changed in special situation. Without this, the old color style is not removed from the CSS class.
				this.handleRoleColorChanged(obj);
				let color = getColor(snap.val());
				let textColor = getTextColor(color);
				let idx = sheet.insertRule("."+obj.$id+"{background: "+color+";}",0);
				let fancyShape = sheet.insertRule("." + obj.$id + "-dynamic-text" + "{color:"+ textColor + ";}", 1);
				obj.rule = sheet.cssRules[idx];
				obj.textRule = sheet.cssRules[fancyShape];
				return obj;
			},
			"$$updatedIdx": function(snap){
				this.$list.forEach(function(item){
					if (item.id === snap.key && item.rule){
						let color = getColor(snap.val());
						let textColor = getTextColor(color);
						item.rule.style.background = color;
						item.textRule.style.color = textColor;
					}
				})
			},
			"$$removed": function(snap){
				let rec = this.$getRecord(snap.key);
				if(rec.rule){
					let idx = Array.prototype.indexOf.call(sheet.cssRules, rec.rule);
					let idx2 = Array.prototype.indexOf.call(sheet.cssRules, rec.textRule);
					if(idx !== -1) {
						sheet.deleteRule(idx);
						sheet.deleteRule(idx2-1);
					}
				}
				
			}
		});
		
		return external;
		
		/////////
		
		function setup(projectId){
			rolesRef = fbConnection.fbRef('roles').child(projectId);
			colorsRef = fbConnection.fbRef('projects').child(projectId + "/colors/roles/");
			fbRolesList = new extendedConstructor(rolesRef, colorsRef);
		}
		function teardown(){
			if(fbRolesList){ fbRolesList.$destroy(); fbRolesList = null; }
			if(sheet && sheet.cssRules && sheet.cssRules.length){
				for(let i = sheet.cssRules.length - 1; i >= 0; i--){
					sheet.deleteRule(i);
				}
			}
		}
		function getColor(obj){
			if(!obj){ return ""; }
			if(obj.color){return obj.color;}
			if(obj.roleColor){ return obj.roleColor; }
			if(obj.roleStyle){ return getLegacy(obj.roleStyle) || ""; }
			return "";
		}
		function getTextColor(color){
			if (!SystemColors.USE_BLENDED_TEXT){
				return tinycolor.mostReadable(color, SystemColors.VALID_TEXT_COLORS).toHexString();
			} else {
				let tc = tinycolor(color);
				let isLight = tc.isLight();
				if (isLight){return tc.darken(57).toHexString()
				} else {return tc.lighten(57).toHexString()}
			}
		}
		
		function getLegacy(styleName){
			return colors[styleName];
		}
	}
	
	dynamicCssLocations.$inject = ["dynamicCss", "projectNotifier", "fbConnection", "firebaseIndex", "SystemColors"];
	function dynamicCssLocations(dynamicCss, projectNotifier: ProjectNotifier, fbConnection, firebaseIndex, SystemColors){
		colors = SystemColors.ROLE_CLASS_NAME_TO_COLOR;
		let projectId = null;
		let fbLocationList = null;
		let sheet = dynamicCss.makeStyleSheet();
		let locationRef = null;
		let colorsRef = null;
		
		let external = {"locationList": function(){ return fbLocationList; }};
		
		projectNotifier.projectState.subscribe(function(stateObj){
			if(projectId !== stateObj.projectId){
				projectId = stateObj.projectId;
				teardown();
				if(projectId){ setup(projectId); }
			}
		});
		
		let extendedConstructor = firebaseIndex.$extend({
			"handleLocationColorChanged": function(obj){
				let rec = this.$getRecord(obj.$id);
				if(rec && rec.rule){
					let ruleIdx = Array.prototype.indexOf.call(sheet.cssRules, rec.rule);
					if(ruleIdx !== -1) {
						sheet.deleteRule(ruleIdx);
					}
				}
			},
			"$$getDataRef": function(idxSnap){
				let colorId = idxSnap.child('locationColor').val();
				if (idxSnap.exists() && colorId && colorId.indexOf('#') < 0){return this._baseDataRef.child(colorId);}
				return idxSnap.ref
			},
			"$$addedIdx": function(snap, prevChild){
				let obj = firebaseIndex.prototype.$$addedIdx.apply(this, arguments);
				// projectId/colors/roles data is only changed in special situation. Without this, the old color style is not removed from the CSS class.
				this.handleLocationColorChanged(obj);
				let color = getColor(snap.val());
				let textColor = getTextColor(color);
				let idx = sheet.insertRule("."+obj.$id+"{background: "+color+";" + "color:"+ textColor + "}",0);
				obj.rule = sheet.cssRules[idx];
				return obj;
			},
			"$$updatedIdx": function(snap){
				this.$list.forEach(function(item){
					if (item.id === snap.key && item.rule){
						let color = getColor(snap.val());
						let textColor = getTextColor(color);
						item.rule.style.background = color;
						item.rule.style.color = textColor;
					}
				})
			},
			"$$removed": function(snap){
				let rec = this.$getRecord(snap.key);
				if(rec.rule){
					let idx = Array.prototype.indexOf.call(sheet.cssRules, rec.rule);
					if(idx !== -1) {
						sheet.deleteRule(idx);
					}
				}
				
			}
		});
		return external;
		function setup(projectId){
			locationRef = fbConnection.fbRef('locations').child(projectId);
			colorsRef = fbConnection.fbRef('projects').child(projectId + "/colors/locations/");
			fbLocationList = new extendedConstructor(locationRef, colorsRef);
		}
		function teardown(){
			if(fbLocationList){ fbLocationList.$destroy(); fbLocationList = null; }
			if(sheet && sheet.cssRules && sheet.cssRules.length){
				for(let i = sheet.cssRules.length - 1; i >= 0; i--){
					sheet.deleteRule(i);
				}
			}
		}
		function getTextColor(color){
			if (!SystemColors.USE_BLENDED_TEXT){
				return tinycolor.mostReadable(color, SystemColors.VALID_TEXT_COLORS).toHexString();
			} else {
				let tc = tinycolor(color);
				let isLight = tc.isLight();
				if (isLight){return tc.darken(57).toHexString()
				} else {return tc.lighten(57).toHexString()}
			}
		}
		function getColor(obj){
			if(!obj){ return ""; }
			if(obj.color){return obj.color;}
			if(obj.locationColor){ return obj.locationColor; }
			return "";
		}
	}
})();
