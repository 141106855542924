import { ComponentManager } from "../component-system/";
import { HighResolutionText } from "../text/HighResolutionText";
import { utils, Point, Rectangle } from "pixi.js";
import { FancySprite } from "../graphics/";
import { Swimlane, SwimlaneRenderTypes } from "../../common/models/Swimlane";
import {PlanState} from "../../common/services/plan-state.service";
import {SWIMLANE_GRAPHICS, SwimlaneContainer } from "./";

import * as appUtils from "utils";

import {EventBinder} from "../interaction/interaction-utils";
import {SWIMLANE_NO_EDIT_RIGHTS} from "../../common/strings";

export const SWIMLANE_LABEL_STYLE = {
	"fontSize": 12,
	"fill": 0xffffff,
	"fontFamily": "Roboto"
};

export enum SWIMLANE_LABEL_SETTINGS {
	BACKGROUND_COLOR = "#454b50",
	BACKGROUND_ALPHA = 0.4,
	BACKGROUND_TOP_PADDING = 6,
	BACKGROUND_BOTTOM_PADDING = 6,
	BACKGROUND_LEFT_PADDING = 12,
	BACKGROUND_RIGHT_PADDING = 12,
	BACKGROUND_RADIUS = 6,
	LABEL_MAX_LENGTH = 35,
	LABEL_STICKINESS_THRESHOLD = 93,
	LABEL_TOP_PADDING = 3,
	AREA_DASH_LENGTH = 2,
	LINE_EDIT_COLOR = "#0496ff",
	LINE_NORMAL_COLOR = "#959DA5",
	LINE_HIT_AREA_HEIGHT = 15
}

export class SwimlaneLabel extends HighResolutionText {
	public components: ComponentManager;
	public background: FancySprite;
	public data: Swimlane;
	
	private mode: string;
	public stickyY: number;
	private _lastText: string;
	private _localPosition: Point = new Point(0, 0);
	private binder: EventBinder;
	private backgroundBinder: EventBinder;
	
	constructor(swimlaneModel: Swimlane, private planState: PlanState){
		super("", SWIMLANE_LABEL_STYLE);
		this.data = swimlaneModel;
		
		this.components = new ComponentManager(this);
		
		// Create label background
		this.background = new FancySprite(utils.TextureCache[SWIMLANE_GRAPHICS.SWIMLANE_TEXT_BACKGROUND]);
		this.background.interactive = true;
		this.background.cursor = "pointer";
		this.background.visible = true;
		this.background.alpha = 0.4;
		
		this.binder = new EventBinder(this);
		this.backgroundBinder = new EventBinder(this.background);
		
		this.binder.add('hammer-singletap', this.onSingleTap);
		this.binder.add('hammer-doubletap', this.onDoubleTap);
		
		this.backgroundBinder.add('hammer-singletap', this.onSingleTap);
		this.backgroundBinder.add('hammer-doubletap', this.onDoubleTap);
		
		// this.on('hammer-singletap', this.onSingleTap);
	}
	
	public update(swimlaneModel: Swimlane): void {
		this.mode = (swimlaneModel.currentRenderType === SwimlaneRenderTypes.edit) ? SwimlaneRenderTypes.edit : "view";
		
		this.setText(swimlaneModel[this.mode].label);
		this._calculateBackground(swimlaneModel);
		
		this.x = this.background.x + (this.background.width / 2) - (this.width / 2);
		this.y = this.stickyY + (this.background.height / 2) - (this.height / 2);
	}
	
	public onSingleTap = () => {
		if (this.planState.accessCheck.isAdmin() && !this.planState.accessCheck.isReadOnly()){
			const newView: string = (this.data.currentRenderType === SwimlaneRenderTypes.edit) ? SwimlaneRenderTypes.database : SwimlaneRenderTypes.edit;
			window.blehTimestamp = performance.now();
			this.planState.swimlanes.changeView(this.data, newView);
		} else {
			window.Logging.warning(SWIMLANE_NO_EDIT_RIGHTS);
		}
	};
	public onDoubleTap = () => {
		let s = this.data.view;
		let rect = new Rectangle(-Infinity, s.top, Infinity, s.bottom - s.top);
		let rawTickets = appUtils.getTicketsInRect(this.planState.tickets._internalList, rect);
		
		let tickets: typeof rawTickets = [];
		
		if(this.planState.accessCheck.isAdmin()){ tickets = rawTickets; }
		else{
			rawTickets.forEach(t => {
				if(this.planState.accessCheck.hasAccessToTicket2(t)){ tickets.push(t); }
			})
		}
		
		let allSame = true;
		let selected;
		let idx = 0;
		tickets.forEach(s => {
			if(idx === 0){ selected = s.selected;}
			else if(s.selected !== selected){
				allSame = false;
			}
			idx++;
		});
		if(allSame && selected){ this.planState.actions.unSelectTicket(tickets); }
		else{ this.planState.actions.selectTicket(tickets); }
	}
	
	public setText(text: string = ""): SwimlaneLabel {
		if (text === this._lastText){return}
		this._lastText = text;
		// Text is too long. Trim it!
		if (text.length >= SWIMLANE_LABEL_SETTINGS.LABEL_MAX_LENGTH){
			text = text.slice(0, SWIMLANE_LABEL_SETTINGS.LABEL_MAX_LENGTH) + "...";
		}
		this.text = "" + text.toUpperCase(); // Doing the "" + here to handle swimlanes that is a number
		return this
	}
	
	public getData(): any {
		return this.data;
	}
	
	public destroy(options?): void{
		super.destroy(options);
		
		this.binder.destroy();
		this.backgroundBinder.destroy();
		
		this.background.destroy();
		this.background = null;
		
		this.components.destroy(options);
		this.components = null;
	}
	
	private _calculateBackground(swimlaneModel: Swimlane): void {
		// make it sticky
		this.stickyY = (swimlaneModel[this.mode].screenTop <= SWIMLANE_LABEL_SETTINGS.LABEL_STICKINESS_THRESHOLD &&
		swimlaneModel[this.mode].screenBottom >= SWIMLANE_LABEL_SETTINGS.LABEL_STICKINESS_THRESHOLD + this.background.height) ? SWIMLANE_LABEL_SETTINGS.LABEL_STICKINESS_THRESHOLD : swimlaneModel[this.mode].screenTop + SWIMLANE_LABEL_SETTINGS.LABEL_TOP_PADDING;
		
		this.background.x = 86;
		this.background.y = this.stickyY;
		this.background.width = this.width + SWIMLANE_LABEL_SETTINGS.BACKGROUND_LEFT_PADDING + SWIMLANE_LABEL_SETTINGS.BACKGROUND_RIGHT_PADDING;
		this.background.height = SWIMLANE_LABEL_STYLE.fontSize + SWIMLANE_LABEL_SETTINGS.BACKGROUND_TOP_PADDING + SWIMLANE_LABEL_SETTINGS.BACKGROUND_BOTTOM_PADDING;
	}
	
	public getBackground(): FancySprite {
		return this.background
	}
}
