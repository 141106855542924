//just types
import { Ticket, DependencyWeight } from "ng2/common/models/Ticket";

import { FancyAction, CombinedFancyActions, FancyActionTypes } from "ng2/fancy-firebase/base";
export { FancyActionTypes };
// import * as ActionBuilders from "./ticket-action-builders";
export * from "./ticket-action-builders";

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
// STORY TIME
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
// So you want to create an action. Here are the steps (to ensure it's all typey and such)
// 1) add an entry to the TicketActionTypes enum
// 2) create a corresponding interface referencing said enum entry and whatever other necessary fields
// 3) add interface to CombinedTicketActions
// 4) add an action to the actions builders file
// 5) add action to the AllThePlanActions interface in plan-actions
// 6) add to applyScopedActions
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
// STORY TIME
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------


//types/ interfaces
type TicketOrTicketArray = Array<Ticket>&Ticket;
export type CombinedTicketActions =
	| CombinedFancyActions<Ticket>
	| TicketChangedToEditAction
	| TicketChangedToDragAction
	| TicketChangedToDefaultAction
	| TicketDraggedAction
	| TicketLiveUpdateAction
	| TicketPersistData
	| TicketPersistDataLocalOnly
	| TicketSelectedAction
	| TicketSelectionDelayedAction
	| TicketUnSelectedAction
	| TicketSearchedAction
	| TicketUnSearchedAction
	| FloatingTicketAddedAction
	| TicketMovedToFloatingAction
	| TicketReturnedToPlanAction
	| FloatingTicketSavedAction
	| TicketSavedToFloatingAction
	| DeleteTicketAction
	| RemoveFloatingTicketsAction
	| UpdateDependencyTarget
	| CreateTicketWithIdAction;

export interface TicketChangedToEditAction extends FancyAction{
	type: TicketActionTypes.ticketChangedToEdit,
	payload: Ticket
}
export interface TicketChangedToDragAction extends FancyAction{
	type: TicketActionTypes.ticketChangedToDrag,
	payload: Ticket
}
export interface TicketChangedToDefaultAction extends FancyAction{
	type: TicketActionTypes.ticketChangedToDefault,
	payload: Ticket
}
export interface TicketDraggedAction extends FancyAction{
	type: TicketActionTypes.ticketDragged,
	payload: Ticket
}
export interface TicketLiveUpdateAction extends FancyAction{
	type: TicketActionTypes.ticketLiveUpdate,
	payload: Ticket
}
export interface TicketPersistData extends FancyAction{
	type: TicketActionTypes.ticketPersistData,
	payload: Ticket
}
export interface TicketPersistDataLocalOnly extends FancyAction{
	type: TicketActionTypes.ticketPersistDataLocalOnly,
	payload: Ticket
}
export interface TicketSelectedAction extends FancyAction{
	type: TicketActionTypes.ticketSelected,
	payload: Ticket
}
export interface TicketSelectionDelayedAction extends FancyAction{
	type: TicketActionTypes.ticketSelectionDelayed,
	payload: string
}
export interface TicketUnSelectedAction extends FancyAction{
	type: TicketActionTypes.ticketUnselected,
	payload: Ticket
}
export interface TicketSearchedAction extends FancyAction{
	type: TicketActionTypes.ticketSearched,
	payload: Ticket
}
export interface TicketUnSearchedAction extends FancyAction{
	type: TicketActionTypes.ticketUnsearched,
	payload: Ticket
}
export interface FloatingTicketAddedAction extends FancyAction{
	type: TicketActionTypes.floatingTicketAdded,
	payload: Ticket
}
export interface TicketMovedToFloatingAction extends FancyAction{
	type: TicketActionTypes.ticketMovedToFloating,
	payload: Ticket
}
export interface TicketReturnedToPlanAction extends FancyAction{
	type: TicketActionTypes.ticketReturnedToPlan,
	payload: Ticket
}

export interface FloatingTicketSavedAction extends FancyAction{
	type: TicketActionTypes.floatingTicketSaved,
	payload: Ticket
}
export interface TicketSavedToFloatingAction extends FancyAction{
	type: TicketActionTypes.ticketSavedToFloating,
	payload: Ticket
}
export interface DeleteTicketAction extends FancyAction{
	type: TicketActionTypes.deleteTicket,
	payload: Ticket
}
export interface RemoveFloatingTicketsAction extends FancyAction{
	type: TicketActionTypes.removeFloatingTickets,
	payload: Ticket
}

export interface CreateTicketWithIdAction extends FancyAction{
	type: TicketActionTypes.createTicketWithId,
	payload: any,
	config: {
		selectImmediately?: boolean
	}
}

export interface UpdateDependencyTarget extends FancyAction{
	type: TicketActionTypes.updateDependencyTarget,
	payload: Ticket,
	weightyness: DependencyWeight
}

/**
 * The core data structure to be passed to an observer. 
 * @param payload Generally expected to be a ticket or an object representing a ticket.
 * Each action type will expect something different and specific.
 * @param type    The type of action. TODO - look into doing this class based instead of enum
 * @param key     Optional if the payload is a Ticket, otherwise required.
 */

export class FancyTicketAction extends FancyAction{
	public payload: Ticket;
}

// type definition enum
export enum TicketActionTypes{
	ticketChangedToEdit = "ticket_changed_to_edit",
	ticketChangedToDrag = "ticket_changed_to_drag",
	ticketChangedToDefault = "ticket_changed_to_default",
	ticketLiveUpdate = "ticket_live_update",
	ticketPersistData = "ticket_persist_data", //probably break this into more specific events? One for each type
	ticketPersistDataLocalOnly = "ticket_persist_data_local_only", 
	ticketDragged = "ticket_dragged",
	ticketSelected = "ticket_selected",
	ticketSelectionDelayed = "ticket_selection_delayed",
	ticketUnselected = "ticket_unselected",
	ticketSearched= "ticket_searched",
	ticketUnsearched = "ticket_unsearched",
	floatingTicketAdded = "floating_ticket_added",
	ticketMovedToFloating = "ticket_moved_to_floating",
	ticketReturnedToPlan = "ticket_returned_to_plan",
	floatingTicketSaved = "floating_ticket_saved",
	ticketSavedToFloating = "ticket_saved_to_floating",
	deleteTicket = "delete_ticket",
	removeFloatingTickets = "remove_floating_tickets",
	createTicketWithId = "create_ticket_with_id",
	
	//actions that are "mostly" internal, fill out interfaces, don't create builders
	updateDependencyTarget = "update_dependency_target",
	
	//internally triggered actions, it's not necessary to fill out all the interfaces and shit
	ticketPlanDataUpdated = "ticket_plan_data_updated",
}

// export type TicketActionTypes = 
// 	| FancyActionTypes
// 	| CustomTicketActionTypes;
