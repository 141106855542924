import { FancyTextStyle } from "../interfaces/fancy-text-style.interface";
import { FancyContainer } from "../graphics/";
import { HighResolutionText } from "../text/HighResolutionText";
import { Grid } from "./";
import * as utils from "utils";

/**
 * The thing that must be returned by the labelCreator
 */
export interface IGridLabelCreator {
	key: string,
	value: any,
	textSettings?: FancyTextStyle
}

export class GridLabel {
	public viewRatio: number = 0.8;
	
	public grid: Grid;
	public hLabelIdx: number = 0;
	public vLabelIdx: number = 0;
	
	protected textCache: Map<string, any> = new Map();
	protected lastDrawX: number = -Infinity;
	protected lastDrawY: number = -Infinity;
	protected lastScale: number;
	private _creatorFn: (grid: Grid, value: number) => any;
	private _textLayer: FancyContainer;
	constructor(grid: Grid, textLayer: FancyContainer, labelCreator: (grid: Grid, value: number) => IGridLabelCreator = GridLabel.createLabel){
		this.grid = grid;
		this._creatorFn = labelCreator;
		this._textLayer = textLayer;
	}
	
	public draw(): void {
		this.drawHorizontal();
		this.drawVertical();
	}
	
	public drawHorizontal(): void {
		if (!this.grid.hTicks){return}
		
		for (let i = this.grid.hTicks.minTickLevel; i <= this.grid.hTicks.maxTickLevel; ++i){
			let ratio: number = this.grid.hTicks.tickRatios[i];
			if (ratio >= this.viewRatio) {
				let ticks: number[] = this.grid.hTicks.ticksAtLevel(i,true);
				for (let j = 0; j < ticks.length; ++j) {
					let screen_x: number = utils.snapPixel(this.grid.valueToPixelH(ticks[j]));
					let thing = this.getLabel(screen_x);
					thing.visible = true;
				}
			}
		}
	}
	
	public drawVertical(): void {
		if (!this.grid.vTicks){return}
		
		for (let i = this.grid.vTicks.minTickLevel; i <= this.grid.vTicks.maxTickLevel; ++i){
			let ratio: number = this.grid.vTicks.tickRatios[i];
			if (ratio >= this.viewRatio) {
				let ticks: number[] = this.grid.vTicks.ticksAtLevel(i,true);
				for (let j = 0; j < ticks.length; ++j) {
					let screen_y: number = utils.snapPixel(this.grid.valueToPixelV(ticks[j]));
					let thing = this.getLabel(screen_y);
					thing.visible = true;
				}
			}
		}
	}
	
	public getLabel(value: number): any {
		let result: IGridLabelCreator = this._creatorFn(this.grid, value);
		let cachedObject: any = this.textCache.get(result.key);
		if (cachedObject){
			return cachedObject
		}
		cachedObject = new HighResolutionText(result.value, result.textSettings);
		this._textLayer.addChild(cachedObject);
		this.textCache.set(result.key, cachedObject);
		return cachedObject
	}
	
	public hideUnusedLabels(start: number, end: number): void {
		for (let i = start; i < end; i++){
			if (this.textCache[i]){
				this.textCache[i].visible = false;
			}
		}
	}
	
	static createLabel(grid: Grid, screenValue: number): IGridLabelCreator {
		return {
			key: "" + screenValue,
			value: grid.valueToPixelV(screenValue)
		}
	}
}