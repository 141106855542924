import { FancyContainer, FancyGraphics } from "../graphics/";
import { GridTicks } from "./";
import { Rectangle } from "pixi.js";
import * as utils from "utils";

export class Grid {
	public width: number;
	public height: number;
	
	public worldPosition: number[] = [0, 0];
	
	public hTicks: GridTicks;
	public xAxisScale: number = 1.0;
	public xAxisOffset: number = 0.0;
	public xAnchor: number = 0.5;
	
	public vTicks: GridTicks;
	public yAxisScale: number = 1.0;
	public yAxisOffset: number = 0.0;
	public yAnchor: number = 0.0;
	
	public xDirection: number = 1.0;
	public yDirection: number = 1.0;
	
	public graphics: FancyGraphics = new FancyGraphics(true);
	
	public parent: FancyContainer;
	
	public xMinRange: number = 0;
	public xMaxRange: number = 0;
	public yMinRange: number = 0;
	public yMaxRange: number = 0;
	
	private _xAnchorOffset: number = 0.0;
	private _yAnchorOffset: number = 0.0;
	
	constructor(width: number, height: number, parent: FancyContainer){
		this.parent = parent;
		
		parent.addChild(this.graphics);
		
		this.resize(width, height);
	}
	
	public destroy(): void {
		this.graphics.destroy();
		if (this.hTicks){
			this.hTicks.destroy();
		}
		if (this.vTicks){
			this.vTicks.destroy();
		}
		
		this.hTicks = null;
		this.vTicks = null;
		this.graphics = null;
	}
	
	public resize(width: number, height: number): Grid {
		this.width = width;
		this.height = height;
		return this;
	}
	
	public setAnchor(x: number, y: number): Grid {
		this.xAnchor = utils.clamp(x, -1, 1);
		this.yAnchor = utils.clamp(y, -1, 1);
		return this;
	}
	
	public setScaleH(lods: number[], minScale: number, maxScale: number, minSpacing: number, maxSpacing: number): Grid {
		this.hTicks = new GridTicks().initTicks(lods, minScale, maxScale).spacing(minSpacing, maxSpacing);
		this.xAxisScale = utils.clamp(this.xAxisScale, this.hTicks.minValueScale, this.hTicks.maxValueScale);
		return this;
	}
	
	public pixelToValueH(x: number): number {
		return (x - this.xAxisOffset) / this.xAxisScale;
	}
	
	public valueToPixelH(x: number): number {
		return x * this.xAxisScale + this.xAxisOffset;
	}
	
	public pixelToValueX(x: number){}
	
	public setMappingH(minValue: number, maxValue: number, pixelRange: number): Grid {
		this._xAnchorOffset = minValue / (maxValue - minValue);
		this.xDirection = (maxValue - minValue) > 0 ? 1 : -1;
		
		this.pixelToValueX = function(x){
			let pixelOffset = this.xAxisOffset;
			let ratio = this.width / pixelRange;
			let u = utils.uninterpolate(0.0, this.width);
			let i = utils.interpolate(minValue * ratio, maxValue * ratio);
			return i(u(x - pixelOffset)) / this.xAxisScale;
		};
		
		this.valueToPixelH = function(x){
			let pixelOffset = this.xAxisOffset;
			
			let ratio = this.width / pixelRange;
			let u = utils.uninterpolate(minValue * ratio, maxValue * ratio);
			let i = utils.interpolate(0.0, this.width);
			return i(u(x * this.xAxisScale)) + pixelOffset;
		};
		
		return this
	}
	
	public setRangeH(minValue: number, maxValue: number): Grid{
		this.xMinRange = minValue;
		this.xMaxRange = maxValue;
		return this
	}
	
	public setScaleV(lods: number[], minScale: number, maxScale: number, minSpacing: number, maxSpacing: number): Grid {
		this.vTicks = new GridTicks().initTicks(lods, minScale, maxScale).spacing(minSpacing, maxSpacing);
		this.yAxisScale = utils.clamp(this.yAxisScale, this.vTicks.minValueScale, this.vTicks.maxValueScale);
		return this;
	}
	
	public pixelToValueV(y: number): number {
		return (this.height - y + this.yAxisOffset) / this.yAxisScale;
	}
	
	public valueToPixelV(y: number): number {
		return y * this.yAxisScale + this.yAxisOffset;
		// return -y * this.yAxisScale + this.height + this.yAxisOffset;
	}
	
	public setMappingV(minValue: number, maxValue: number, pixelRange: number): Grid {
		this._yAnchorOffset = minValue / (maxValue - minValue);
		this.yDirection = (maxValue - minValue) > 0 ? 1 : -1;
		
		this.pixelToValueV = function(y) {
			let pixelOffset = this.yAxisOffset;
			let ratio = this.height / pixelRange;
			let u = utils.uninterpolate( 0.0, this.height );
			let i = utils.interpolate(minValue * ratio, maxValue * ratio);
			return i(u(y - pixelOffset)) / this.yAxisScale;
		};
		
		this.valueToPixelV = function(y) {
			let pixelOffset = this.yAxisOffset;
			let ratio = this.height / pixelRange;
			let u = utils.uninterpolate(minValue * ratio, maxValue * ratio);
			let i = utils.interpolate(0.0, this.height);
			return i(u(y * this.yAxisScale)) + pixelOffset;
		};
		
		return this
	}
	
	public setRangeV(minValue: number, maxValue: number): Grid {
		this.yMinRange = minValue;
		this.yMaxRange = maxValue;
		return this
	}
	
	public xAxisScaleAt(pixelX: number, scale: number): Grid {
		let oldValueX = this.pixelToValueH(pixelX);
		this.xAxisScale = utils.clamp(scale, this.hTicks.minValueScale, this.hTicks.maxValueScale);
		let newScreenX = this.valueToPixelH(oldValueX);
		this.pan(pixelX - newScreenX, 0);
		return this
	}
	
	public yAxisScaleAt(pixelY: number, scale: number): Grid {
		let oldValueY = this.pixelToValueV(pixelY);
		this.yAxisScale = utils.clamp(scale, this.vTicks.minValueScale, this.vTicks.maxValueScale);
		let newScreenY = this.valueToPixelV(oldValueY);
		this.pan(0, pixelY - newScreenY);
		return this
	}
	
	public xAxisSync(x: number, scaleX: number): Grid {
		this.xAxisOffset = x;
		this.xAxisScale = scaleX;
		return this;
	}
	
	public yAxisSync(y: number, scaleY: number): Grid {
		this.yAxisOffset = y;
		this.yAxisScale = scaleY;
		return this;
	}
	
	public scaleAction(offsetX: number, offsetY: number, newScale: number): Grid {
		if (this.hTicks) {this.xAxisScaleAt(offsetX, newScale);}
		if (this.vTicks ) {this.yAxisScaleAt(offsetY, newScale);}
		return this
	}
	
	public draw(bounds: Rectangle, color: number = 0x000000, doNotClear: boolean = true): Grid {
		this.graphics.clear();
		this.drawHorizontal(bounds.left, bounds.right, color, doNotClear);
		this.drawVertical(bounds.top, bounds.bottom, color, doNotClear);
		return this
	}
	
	public drawHorizontal(leftEdge: number, rightEdge: number, lineColor: number = 0x555555, doNotClear: boolean = true): Grid {
		if (this.hTicks) {
			let left = leftEdge;
			let right = rightEdge;
			
			if (!doNotClear){this.graphics.clear();}
			
			this.graphics.beginFill(lineColor);
			this.hTicks.range(left, right, this.width);
			
			for (let i = this.hTicks.minTickLevel; i <= this.hTicks.maxTickLevel; ++i) {
				let ratio = this.hTicks.tickRatios[i];
				if (ratio > 0) {
					this.graphics.lineStyle(1, lineColor, ratio * 0.5);
					let ticks = this.hTicks.ticksAtLevel(i,true);
					for (let j = 0; j < ticks.length; ++j) {
						let screen_x = this.valueToPixelH(ticks[j]);
						this.graphics.moveTo(utils.snapPixel(screen_x), -1.0 );
						this.graphics.lineTo(utils.snapPixel(screen_x), this.height);
					}
				}
			}
			this.graphics.endFill();
		}
		return this;
	}
	
	public drawVertical(topEdge: number, bottomEdge: number, lineColor: number = 0x555555, doNotClear: boolean = true): Grid {
		let top = topEdge;
		let bottom = bottomEdge;
		if (!doNotClear){
			this.graphics.clear();
		}
		
		this.graphics.beginFill(lineColor);
		this.vTicks.range(top, bottom, this.height);
		for (let i = this.vTicks.minTickLevel; i <= this.vTicks.maxTickLevel; ++i) {
			let ratio = this.vTicks.tickRatios[i];
			if (ratio > 0) {
				this.graphics.lineStyle(1, lineColor, ratio * 0.5);
				let ticks = this.vTicks.ticksAtLevel(i,true);
				for (let j = 0; j < ticks.length; ++j) {
					let screen_y = this.valueToPixelV(ticks[j]);
					if (screen_y > bottom){continue;}
					this.graphics.moveTo(0.0, utils.snapPixel(screen_y));
					this.graphics.lineTo(this.width, utils.snapPixel(screen_y));
				}
			}
		}
		this.graphics.endFill();
		
		return this;
	}
	
	public pan(deltaX: number, deltaY: number): Grid {
		this.panX(deltaX);
		this.panY(deltaY);
		return this
	}
	
	public panX(deltaPixelX: number){
		if (!this.valueToPixelH) {return;}
		
		let newOffset = this.xAxisOffset + deltaPixelX;
		this.xAxisOffset = 0.0; // calc range without offset
		
		let min, max;
		if (this.xMinRange !== undefined && this.xMinRange !== null) {min = this.valueToPixelH(this.xMinRange);}
		if (this.xMaxRange !== undefined && this.xMaxRange !== null) {
			max = this.valueToPixelH(this.xMaxRange);
			max = Math.max(0, max - this.width);
		}
		
		this.xAxisOffset = newOffset;
		
		if (min !== undefined && max !== undefined) {
			this.xAxisOffset = utils.clamp(this.xAxisOffset, -max, -min);
			return;
		}
		
		if (min !== undefined) {
			this.xAxisOffset = Math.min(this.xAxisOffset, -min);
			return;
		}
		
		if (max !== undefined) {
			this.xAxisOffset = Math.max(this.xAxisOffset, -max);
		}
	}
	
	public panY(deltaPixelY: number){
		if (!this.valueToPixelV) {return;}
		
		let newOffset = this.yAxisOffset + deltaPixelY;
		this.yAxisOffset = 0.0; // calc range without offset
		
		let min, max;
		if (this.yMinRange !== undefined && this.yMinRange !== null) {min = this.valueToPixelV(this.yMinRange);}
		if (this.yMaxRange !== undefined && this.yMaxRange !== null) {
			max = this.valueToPixelV(this.yMaxRange);
			max = Math.max(0, max - this.height);
		}
		
		this.yAxisOffset = newOffset;
		
		if (min !== undefined && max !== undefined) {
			this.yAxisOffset = utils.clamp(this.yAxisOffset, -max, -min);
			return;
		}
		if (min !== undefined) {
			this.yAxisOffset = Math.min( this.yAxisOffset, -min );
			return;
		}
		
		if (max !== undefined) {
			this.yAxisOffset = Math.max( this.yAxisOffset, -max );
		}
	}
}