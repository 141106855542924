/**
 * Helper to make working with localStorage a _little_ bit easier
 */
export class LocalStorageHelper {
	/**
	 * Read and parse the localStorage string. Return the parsed data.
	 * @param {string} key
	 * @returns {any}
	 */
	public static read(key: string){
		if (!LocalStorageHelper.storageAvailable()){return null}
		const dataString = localStorage.getItem(key) || null;
		return JSON.parse(dataString);
	}
	/**
	 * Saves the object to localStorage
	 * @param {string} key
	 * @param data
	 */
	public static write(key: string, data: any) {
		if (!LocalStorageHelper.storageAvailable()){return null}
		const dataString = JSON.stringify(data);
		localStorage.setItem(key, dataString);
	}
	/**
	 * Removes an item from localStorage
	 * @param {string} key
	 */
	public static remove(key: string){
		if (!LocalStorageHelper.storageAvailable()){return null}
		localStorage.removeItem(key);
	}
	/**
	 * Check if storage data VERSION key matches the newVersion param
	 * @param {string} key
	 * @param {string} newVersion
	 */
	public static isSameVersion(key: string, newVersion: string){
		if (!LocalStorageHelper.storageAvailable()){return null}
		const data = LocalStorageHelper.read(key);
		return (data && data.VERSION && data.VERSION === newVersion)
	}
	/**
	 * Determine if localStorage is available
	 * @param {string} type Type of storage to check
	 */
	public static storageAvailable(type: string = 'localStorage') {
		try {
			let storage = window[type],
				x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		}
		catch(e) {
			return false;
		}
	}
}

(<any>window).LocalStorageHelper = LocalStorageHelper;