import { interaction } from "pixi.js";
import { createWatchCompilerHost } from "typescript";

/*
This thing has the data for the UI to display in the edit form.
There is a boolean property isEditAll that is false for a single ticket
There's a "current type" property that is the type to display in the UI
There will be code here to set it to a default.  The UI will also need a 
list of possible types to choose from, and a count of tickets overall,
as well as a count of the current type.

isEditAll: boolean
currentType: on of the enum of known types 
typesInCollection: array of enum? computed from map
Count of overall tickets - computed from length of map
Count by type - computed from map, in a map?



*/


export class EditTicketsCombined {

	// Things dealing with the whole set of tickets
	isEditAll: boolean;   // false: single ticket, true: multi ticket
	currentType: string;  // 'task', 'constraint', or 'milestone'
	totalTickets: number; // count of all tickets selected for edit

	// Things dealing with ticket(s) by type
	typesInCollection: Map<string, any>;  // keys is type, value is the ticket data.

	// These are the "known keys" for edit data
	knownKeys = {
		"task": {
			"editAll": {
				// UI name : ticket data model path
				"roleName" : "view.role.roleName",
				"location" : "view.locationId", // Need a real location
				"activityName" : "view.activityName",
				"crew" : "view.liveCrewSize",
				"durationDays" : "view.liveDurationDays"
			},
			"singleTicket": {
				// UI name : ticket data model path
				"roleColor" : "view.liveRole.color",
				"roleName" : "view.role.roleName",
				"location" : "view.locationId", // Need a real location
				"activityName" : "view.activityName",
				"crew" : "view.liveCrewSize",
				"durationDays" : "view.liveDurationDays",
				"isComplete" : "view.isActualized",
				"liveStart": "view.liveStart",
				"liveFinish": "view.liveFinish"
				// "dates", //need actual dates here
				// "promises" // some deeper structure under here
			}
		},
		"constraint": {
			"editAll": {
				// UI name : ticket data model path
				"location" : "view.locationId", // Need a real location
				"activityName" : "view.activityName",
				// "requestedBy" : "need a path",
				// "requestedByDate" : "need a path",
				// "assignedTo" : "need a path"
			},
			"singleTicket": {
				// UI name : ticket data model path
				"location" : "view.locationId", // Need a real location
				"activityName" : "view.activityName",
				"roleColor" : "view.liveRole.color",
				"isComplete" : "view.isActualized",
				"liveStart": "view.liveStart",
				"liveFinish": "view.liveFinish",
				"dateRequested": "view.dateRequested",
				"assigneeColor": "view.assignedProjectMember.role.color",
				"assignedProjectMemberId": "view.assignedProjectMemberId",
				"responsibleProjectMemberId": "view.responsibleProjectMemberId"
				// "requestedBy" : "need a path",
				// "requestedByDate" : "need a path",
				// "assignedTo" : "need a path",
				// "someAcceptanceStatus"  : "need a path"// i.e. pending, accepted, etc.
			}
		},
		"milestone": {
			"editAll": {
				// UI name : ticket data model path
				"roleName" : "view.role.roleName",
				"location" : "view.locationId", // Need a real location
				"activityName" : "view.activityName",
			},
			"singleTicket": {
				// UI name : ticket data model path
				"roleColor" : "view.liveRole.color",
				"roleName" : "view.role.roleName",
				"location" : "view.locationId", // Need a real location
				"activityName" : "view.activityName",
				"isComplete" : "view.isActualized",
				"liveStart": "view.liveStart",
				"liveFinish": "view.liveFinish"
			}
		}
	};

	constructor(){
		this.typesInCollection = new Map();
	}

}

