import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment";
import {dateCache} from "ng2/common/date-cache";

import * as utils from "utils";
import { FancyFirebaseList } from "ng2/fancy-firebase/base";
import { RoleList } from "ng2/fancy-firebase/lists/RoleList";

function nextDow(num: number){
	return (num+1) % 7;
}

interface WorkShiftsByDow{
	// 0:number,
	// 1:number,
	// 2:number,
	// 3:number,
	// 
}

interface WorkdayConfig{
	requireNextDayToBeAWworkDay?: boolean
}

// potential optimization:
// - precalc a large workdays array (for each role)
// -- based on guessed size or something?
// - keep track of it's total contiguous size by updating it's start and finish date
// - when a request fit's, just use that
// - when it doesn't, precalc to that requests dates + some extra
// - when any work day property changes, throw out the whole cache and start over


//not going to bother making this generic/ extensible, is a pretty simple/ specific thing
export class CalendarWorkdays{
	private projecWorkday$:Observable<any>;
	
	private internalRoleDows = {};
	
	public exceptionDays;
	public projectWorkdays = null;
	public workByRole = {};
	
	private unsubscriber: Array<Subscription> = [];
	
	constructor(
		private rolesList: RoleList, 
		projecWorkday$: Observable<any>, //expects data of the project workdays form
		private exceptionDayList
	){
		this.projecWorkday$ = projecWorkday$;
		this.unsubscriber.push(this.projecWorkday$.subscribe((workDays)=>{
			if(!workDays || (workDays && workDays.length && workDays.every(d => d === 0))){
				Logging.warning("No workdays set, default to every day being a workday")
				this.projectWorkdays = [1,1,1,1,1,1,1];
			}
			else{
				this.projectWorkdays = workDays;
			}
			this.recalcAllRoleDow();
		}));
		
		this.unsubscriber.push(this.exceptionDayList.list$.subscribe((list)=>{
			this.exceptionDays = list;
		}))
		
		//could probably save some perf using rawEvent$
		this.unsubscriber.push(
			this.rolesList.list$.pipe(map((roleList)=>{
				let dows = {};
				roleList.forEach((role, key)=>{
					dows[key] = role.workShiftsByDow ? role.workShiftsByDow : null;
				})
				return dows;
			}))
			.subscribe((roleDows)=>{
				this.internalRoleDows = roleDows;
				this.recalcAllRoleDow();
			})
		);
		
	}
	
	private recalcAllRoleDow(){
		for(var key in this.internalRoleDows){
			this.recalcRoleDow(key, this.internalRoleDows[key]);
		}
	}
	
	private recalcRoleDow(roleKey, roleDow){
		if(roleDow){this.workByRole[roleKey] = roleDow; }
		else{ this.workByRole[roleKey] = this.projectWorkdays; }
	}
	
	private isException(start, dayOffset){
		var res = dateCache.addDays(start, dayOffset);
		return this.exceptionDays.has(res);
	}
	
	public getWorkdaysWithinRange(startDate, durationDays, roleId?, allowNonworkStart = false){
		if(durationDays === 0){return 0}
		if(!durationDays){throw new Error("duration is no defined");}
		
		let currentWorkdays = this.workByRole[roleId];
		if(!roleId){ currentWorkdays = this.projectWorkdays; }
		if(!currentWorkdays){ return 0; }
		
		// let dayOfWeek = utils.shiftWeekStartToMonday(moment(startDate).day());
		let dayOfWeek = utils.shiftWeekStartToMonday(dateCache.getDow(startDate));
		
		var count = 0;
		var workDayCount = 0;
		while(count < durationDays){
			let isWorking = currentWorkdays[dayOfWeek] && !this.isException(startDate, count);
			if(isWorking){workDayCount++;}
			//allow the first day to start on non-work days
			if(count === 0 && !isWorking && allowNonworkStart){ workDayCount++ }
			count++;
			dayOfWeek = nextDow(dayOfWeek);
		}
		return workDayCount;
	}
	
	/** Used primarily to convert the relative start workday offset from the left edge of the drag
		to the live position based representation */
	public getCalendarOffset(startDate, durationDays, roleId, allowNonworkStart = true){
		return this.internalWorkDayCalc(startDate, durationDays, roleId, allowNonworkStart, {requireNextDayToBeAWworkDay: true});
	}
	
	//there's potential to build in a cache here, start without
	public getWorkdayRepresentation(startDate, durationDays, roleId, allowNonworkStart = false){
		return this.internalWorkDayCalc(startDate, durationDays, roleId, allowNonworkStart);
	}
	
	private internalWorkDayCalc(startDate, durationDays, roleId?, allowNonworkStart = false, specialConfig:WorkdayConfig = {}){
		let currentWorkdays = this.workByRole[roleId];
		if(!roleId){ currentWorkdays = this.projectWorkdays; }
		//special check for the leftmost start offset check
		if(durationDays === 0){return { startOffsetDays: 0, calendarDays: 0	}}
		if(!durationDays){throw new Error("duration is no defined");}
		//if nothings loaded, everything is a valid work day
		if(!currentWorkdays){return {"startOffsetDays": 0, "calendarDays": durationDays}}
		
		//TODO likely the best method will be to calculate some day offsets to minimize the moment date queries
	
		let dayOfWeek = utils.shiftWeekStartToMonday(dateCache.getDow(startDate));
		let haveFoundFirstWorkDay = false;
		let startOffsetDays = 0;
		let workDayCount = 0;
		let calendarDays = 0;
		
		// debugger;
		let isWorking = currentWorkdays[dayOfWeek] && !this.isException(startDate, calendarDays);
		while(workDayCount < durationDays || (specialConfig.requireNextDayToBeAWworkDay && !isWorking)){
			
			if(allowNonworkStart && !haveFoundFirstWorkDay){
				haveFoundFirstWorkDay = true;
				workDayCount++;
			}
			else if(haveFoundFirstWorkDay){
				if(isWorking){ workDayCount++; }
				else{  } //noop
			}
			else{
				if(isWorking){ haveFoundFirstWorkDay = true; workDayCount++;}
				else{ startOffsetDays++; }
			}
			
			dayOfWeek = nextDow(dayOfWeek);
			
			
			calendarDays++;
			isWorking = currentWorkdays[dayOfWeek] && !this.isException(startDate, calendarDays);
		}
		return { startOffsetDays, calendarDays: calendarDays - startOffsetDays	}
	}
	
	destroy(){
		this.unsubscriber.forEach(sub => sub.unsubscribe());
	}
}
