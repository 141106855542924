/*
 * This component displays a little thumbnail of the ticket.  It 
 * takes three inputs:
 * 
 * o Ticket type to get the right shape
 * o Ticket color
 * o Completion status (to optionally show checkmark)
 * 
 */

import { Component, Input } from '@angular/core';

import { TicketType } from './TicketType';

import * as utils from "utils";

@Component({
	selector: 'ticket-thumbnail',
	template: require('./ticket-thumbnail.component.html'),
})
export class TicketThumbnailComponent {

	@Input() ticketType: TicketType;

	@Input() ticketColor: string;

	@Input() isComplete: boolean;

	@Input() pendingAcceptance: boolean;

	@Input() assigneeColor: string;

	// Compute a style property based on ticketType
	get ticketStyle() {
		// return 'ticket-thumbnail' for all types, and optionally
		// append a second type specific class for milestones and
		// constraints
		var requiredString = 'ticket-thumbnail';
		var optionalString = !(this.ticketType === TicketType.Task) ? ' ticket-thumbnail--' + this.ticketType : '';
		return requiredString + optionalString;
	}

	constructor(){
		this.ticketColor = "#ffd1c1";
		this.isComplete = true;
		this.ticketType = TicketType.Constraint;
	}

	public getMostReadableColor(bgColor: string): string{
		return utils.getMostReadableColor(bgColor);
	}
}
