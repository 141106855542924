import { TicketTypes, Ticket } from "../../common/models/Ticket";

export class TicketUtils{
	public static getTicketType(ticket: Ticket): string{
		switch (ticket.view.type){
			case "constraint":
				return TicketTypes.CONSTRAINT;
			case "milestone":
				return TicketTypes.MILESTONE;
			case "task":
				return TicketTypes.TASK;
			default:
				return TicketTypes.TASK;
		}
	}
}