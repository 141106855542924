import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'reset-password',
	template: require('./reset-password.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
