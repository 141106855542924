import { PlanState } from "../../common/services/plan-state.service";
import { Point, Container } from "pixi.js";
import { FancyInterface } from "../interfaces/";
import { ComponentManager } from "../component-system/";
import { FancyGraphics, FancyContainer } from "../graphics/";
import * as utils from "utils";
import {LevelOfDetail, LEVEL_OF_DETAIL_SIGNALS} from "../core/LevelOfDetail";
import { Camera } from "../core/";
import { Ticket } from "../../common/models/Ticket";
import { Easing, Tween } from "../tween/";
import { TicketInteractionContainer } from "./TicketInteractionContainer";
import { TicketRenderTypes } from "../../common/models/Ticket";

export enum TICKET_CHANGE_EVENTS {
	CHANGED_ISACTUALIZED = "changedIsActualized",
	CHANGED = "changed"
}

/**
 * A DataContainer class which is used to store data of differing types within the scene graph. See https://mocasystems.atlassian.net/browse/MOC-2552 for spec information.
 */
export class TicketContainer extends FancyContainer implements FancyInterface{
	/**
	 * MOC-2916. SHOULD BE FINE. SHADOW _MAY_ BE WEIRD IN SOME CIRCUMSTANCES, BUT IT IS SUBTLE. DON'T TELL ANYONE. "Okay, I'm fine with that" - Kurtis, 10/24/2018
	 */
	public BELOW: FancyContainer = new FancyContainer();
	public SURFACE: TicketInteractionContainer;
	public ABOVE: FancyContainer = new FancyContainer();
	
	public renderable: boolean = true;
	public components: ComponentManager;
	/**
	 * An object which contains all current assigned data for this data node.
	 */
	public data: any;
	/**
	 * Singleton instance of the projectMemebrs list.
	 */
	public projectMembers: any;
	public tweenManager: any;
	public stage: any;
	public camera: Camera;
	public ticketManager: any;
	public screen: any;
	public graphics: FancyGraphics;
	public liveRoleTextColor: number;
	public liveRoleColor: number;
	public locationTextColor: number;
	public locationColor: number;
	public isGhost: boolean = false;
	
	private wtfClear = ["projectMembers", "tweenManager", "stage", "camera", "ticketManager",
		"screen", "graphics"];
	
	public locationContainer: any;
	public sprite: any;
	/**
	 * Current planState
	 */
	public planState: PlanState;
	
	private _liveRoleLastColor: number = -1;
	private _locationLastColor: number = -1;
	private _tweener: Tween;
	constructor(planState: PlanState){
		super();
		
		//TODO DON'T DO THIS FFS
		this.projectMembers = (<any>window).MCScope.projectMembers.fb;
		this.tweenManager = (<any>window).TouchPlanCanvas.tweenManager;
		this.stage = (<any>window).TouchPlanCanvas.stage;
		this.camera = (<any>window).TouchPlanCanvas.camera;
		this.ticketManager = (<any>window).TouchPlanCanvas.ticketManager;
		this.screen = (<any>window).TouchPlanCanvas.screen;
		this.graphics = (<any>window).TouchPlanCanvas._graphics;
		
		this.components = new ComponentManager(this);
		this.planState = planState;
		//this.cacheAsBitmap = true;
		
		// LevelOfDetail.emitter.on(LEVEL_OF_DETAIL_SIGNALS.CHANGED, this.updateContainerVisibility);
		// this.updateContainerVisibility();
	}
	
	public setup(): void {
		this.SURFACE = new TicketInteractionContainer(this.planState, this);
		
		this.addChild(this.BELOW);
		this.addChild(this.SURFACE);
		this.addChild(this.ABOVE);
		
		const data = this.getData();
		
		if (data.isGhost){
			this.interactive = false;
			this.interactiveChildren = false;
		} else {
			this.interactiveChildren = true;
			this.BELOW.interactiveChildren = false;
			this.ABOVE.interactiveChildren = true;
			this.interactive = false;
		}
	};
	
	//Some debug stuff for reducing the number of FancyContainers by not rendering them
	// Needs an "onload" event to work properly
	/*
	private updateContainerVisibility = () =>{
		let total = 0;
		total += this.flip(this.BELOW);
		total += this.flip(this.SURFACE);
		total += this.flip(this.ABOVE);
		
		if(total && !this.visible){ this.visible = true; }
		else if(!total && this.visible){ this.visible = false; }
	}
	private flip(container: FancyContainer){
		let length = container.children.length;
		if(length && !container.visible){ container.visible = true; }
		else if(!length && container.visible){ container.visible = false; }
		return length;
	}
	*/
	
	/**
	 * Get the data assigned to this DataContainer
	 * @returns DataContainer.data
	 */
	public getData(){
		return this.data;
	}
	/**
	 * Assign new data to this DataContainer
	 * @returns DataContainer
	 */
	public assign(data: any){
		this.data = data;
		return this
	}
	/**
	 * @param {Boolean} [options.children = false] If true, will call destroy on all children as well.
	 * @param {Boolean} [options.texture = false] If true, will destroy the texture as well.
	 * @param {Boolean} [options.baseTexture = false] If true, will destroy the base texture as well.
	 */
	public destroy(options?: object|boolean){
		// Destroy textures
		this.components.destroy(options);
		this.components = null;
		this.planState = null;
		this.data = null;
		this.wtfClear.forEach( wtf => this[wtf] = null );
		// MOC-2937 - Keep a reference to our active tween and force complete/stop it when the ticket container is destroyed
		// This will fix the edge case of trying to tween a ticket that was destroyed...and no longer has any properties to tween
		if (this._tweener){
			this._tweener.destroy();
		}
		super.destroy(options);
	}
	
	public update(planState: PlanState, dataContainer: TicketContainer){
		// MOC-2953
		if (!this.visible){return}
		this._cull();
		if (this.renderable){
			this.components.update(planState, dataContainer);
		}
	}
	
	public updateData(planState: PlanState, dataContainer: TicketContainer){
		const data: Ticket = this.getData();
		
		// MOC-2953. We still need to trigger an update of components on data change so internal state is updated...
		this.visible = !data.hidden;
		if(this.visible){
			this.attach();
		}
		else{
			this.detach();
		}
		
		//Set ticket alpha
		if (data.view.isActualized && !(<any>data).isGhost) {
			this.alpha = 0.5;
		} else if (!(<any>data).isGhost) {
			this.alpha = 1.0;
		} else {
			this.alpha = 0.5;
		}
		
		this._createTextColor();
		
		this.components.updateData(planState, dataContainer);
		
		this._animate(data);
		
		this.emit(TICKET_CHANGE_EVENTS.CHANGED, dataContainer);
	}
	
	/**
	 * Animates ticket movement
	 * @param {Ticket} data
	 */
	private _animate(data: Ticket){
		if (data.renderType === TicketRenderTypes.drag || (data as any).isGhost){
			this.x = data.view.left;
			this.y = data.view.top;
		} else if (this.x !== data.view.left || this.y !== data.view.top) {
			this._tweener = this.tweenManager.tween(this, {
				x: data.view.left,
				y: data.view.top
			}, 500, {
				easing: Easing.QUADRATIC.IN_OUT,
				onComplete: () => {
					this.x = data.view.left;
					this.y = data.view.top;
				}
			});

			this._tweener.start();
		}
	}
	
	private _createTextColor(): void {
		const data = this.getData();
		
		if (!data.isGhost){
			// Get the color key or fallback to "black". MOC-2790
			["location", "liveRole"].forEach((key: string) => {
				if (this[key + "Color"] === this["_" + key + "LastColor"]){return;}
				let colorString: string = (data.view[key] && data.view[key].color) ? data.view[key].color : "#000000";
				let lighterColor: string = (data.view.isActualized) ? utils.lightenColor(colorString, 40) : colorString;
				
				let mostReadableColor = utils.getMostReadableColor(lighterColor);
				this[key + "TextColor"] = utils.convertHexStringToNumber(mostReadableColor);
				this["_" + key + "LastTextColor"] = this[key + "TextColor"];
				this[key + "Color"] = utils.convertHexStringToNumber(colorString);
			});
		} else {
			this.liveRoleTextColor = 0x000000;
			this.liveRoleColor = 0xffffff;
			this._liveRoleLastColor = this.liveRoleColor;
			
			this.locationTextColor = 0x000000;
			this.locationColor = 0xffffff;
			this._locationLastColor = this.locationColor;
		}
	}
	
	private _cull(){
		let data = this.getData();
		const cameraScale: Point = this.camera.scale;
		const point: Point = this.toGlobal(new Point(0, 0)); // 0,0 for top-left corner of this ticket
		this.renderable = LevelOfDetail.isForced || !((point.x + data.view.width * cameraScale.x < this.screen.x || point.x >= this.camera.view.width) || (point.y + data.view.height * cameraScale.y < this.screen.y || point.y >= this.camera.view.height));
		if (this.renderable){
			this.camera.totalInView++;
		}
		// console.log('cullin');
		if(this.renderable && this.isDetached){ this.attach(); }
		else if(!this.renderable && !this.isDetached){ this.detach(); }
	}
}
