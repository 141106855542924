import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {DropdownModule} from 'primeng/primeng';
import { UtilityComponentsModule } from "ng2/common/components";

import {RallyComponent} from "./rally/rally.component";
import {RallyEmailComponent} from "./rally-email/rally-email.component";
import {RallyLoginComponent} from "./rally-login/rally-login.component";
import {RallySignupComponent} from "./rally-signup/rally-signup.component";
import {RallyStartComponent} from "./rally-start/rally-start.component";

import {RallyPointResolverService} from "./rally-point-resolver.service";

import { RallyPointSignupService } from "ng2/common/services/rally-point-signup.service";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		DropdownModule,
		ReactiveFormsModule,
		UtilityComponentsModule
	],
	declarations: [
		RallyComponent,
		RallyEmailComponent,
		RallyLoginComponent,
		RallySignupComponent,
		RallyStartComponent,
	],
	exports: [
		RallyComponent,
		RallyEmailComponent,
		RallyLoginComponent,
		RallySignupComponent,
		RallyStartComponent,
	],
	providers: [
		RallyPointResolverService,
		RallyPointSignupService
	]
})
export class RallyPointModule{}
