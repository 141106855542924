import {Injectable, Inject, NgZone} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {Observable, BehaviorSubject, Subject, Subscription, of, combineLatest, merge } from "rxjs";
import {filter, tap, takeUntil, first, map, switchMap} from "rxjs/operators"

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";

import { FancyActionTrackerFactory } from "./fancy-action-tracker-factory.service";

import { DefaultProjectSetup, RallyPointService, AuthService, FIREBASE_USERS_TOKEN } from "ng2/common/ajs-upgrade-providers";
import { AppState } from "ng2/common/services/app-state.service";

import { LoadingBlockerState } from "ng2/common/components/loading-blocker";

const baseRoute = 'rally';

//http://localhost:9001/#!/rally2;email=wee/start
export interface RallyState{
	accessCode?: string;
	email?: string;
	password?: string;
	password2?: string;
	
	//signup specific 
	firstName?: string;
	lastName?: string;
	company?: string;
	phone?: string;
	title?: string;
	
	accessCodeData?: any
}

export interface TestResult{
	message?: string,
	route?: Array<string>
}

export const acceptedFields = [
	"accessCode",
	"email",
	"password",
	"password2",
	"firstName",
	"lastName",
	"company",
	"phone",
	"title",
	"accessCodeData"
];

// rally point controller functionality
// - parse url parameters into the state
// - validate that the current view can actually be shown based on the state
// -- also get/ validate code data
// - manage redirection
// - validate user input
// - do user signup

//next
// - get route transitions working (with loading blocker)
// - get route validation working
// - use start route as a template for the others

@Injectable()
export class RallyPointSignupService{
	private shutdownSubject = new Subject();
	// private unsubscribeList:Array<Subscription>;
	
	private rallyStateInternal = new BehaviorSubject<RallyState>({});
	public rallyStateSync: RallyState;
	public rallyState: Observable<RallyState>;
	
	constructor(
		private router: Router,
		// private activatedRoute: ActivatedRoute,
		private rallyPointService: RallyPointService,
		private appState: AppState,
		private authService: AuthService,
		@Inject(FIREBASE_USERS_TOKEN) private firebaseUsers,
		// public angularFireAuth: AngularFireAuth,
		// private angularFireDatabase: AngularFireDatabase,
		// private fancyActionTrackerFactory: FancyActionTrackerFactory,
		// private defaultProjectSetup: DefaultProjectSetup
	){
		this.rallyState = this.rallyStateInternal.pipe(takeUntil(this.shutdownSubject));
		
		// combineLatest(this.rallyStateInternal, merge(of({}), this.ge))
		// this.rallyStateInternal.pipe(
		// 
		// 
		// 	takeUntil(this.shutdownSubject)
		// )
		
		this.rallyState.subscribe(s => this.rallyStateSync = s);
		window.rally = this;
	}
	
	patchState(state: RallyState){
		return this._stateUpdate(state, this.rallyStateSync);
	}
	setState(state: RallyState){
		return this._stateUpdate(state);
	}
	
	startLoading(settingSubject: Subject<LoadingBlockerState>){
		settingSubject.next(LoadingBlockerState.inProgress);
	}
	failLoading(settingSubject: Subject<LoadingBlockerState>){
		settingSubject.next(LoadingBlockerState.failure);
	}
	
	getAccessCodeData(code:string){
		let data = this.rallyPointService.getAccessCode(code);
		return data.$loaded().then(()=>{
			this.patchState({accessCodeData: data})
			if(data.$value === null){
				return null;
			}
			return data;
		});
	}
	
	
	testAccessCode(codeData): Promise<TestResult>{
		if(!codeData){
			return Promise.resolve({message: "You must enter a code."})
		}
		if(!codeData._isValid){
			return Promise.resolve({message: codeData._validityMessage});
		}
		//unauthed
		if(!this.appState.userSync){
			return Promise.resolve({route: [baseRoute, 'email'] })
		}
		
		//testing
		// return Promise.resolve({route: [baseRoute, 'email'] })
		
		return this.rallyPointService.checkForProjectAccess(codeData.projectId, this.appState.userSync.$id).then((hasAccess)=>{
			if(hasAccess){ console.log('has access already'); return { route: ['plan', codeData.projectId, codeData.planId] }; }
			return this.rallyPointService.addProjectAccess(codeData.projectId, this.appState.userSync.$id, this.rallyStateSync.accessCode).then(function(){
				return { route: ['plan', codeData.projectId, codeData.planId] };
			});
		});
	}
	
	tryEmail(email){
		if(!email){ return Promise.resolve({message: 'An email is required'}); }
		return this.rallyPointService.checkEmail(email).then(exists => {
			if(exists){ return {route: [baseRoute, 'login']}}
			else{ return {route: [baseRoute, 'signup']}}
		})
	}
	
	login(email, password){
		if(!email || !password){ return Promise.resolve({message: "A password must be entered."}); }
		if(!this.rallyStateSync.accessCodeData){ return Promise.resolve({message: "Access code not found."}); }
		let codeData = this.rallyStateSync.accessCodeData;
		let userId;
		return this.authService.auth.$signInWithEmailAndPassword(email, password).then((user)=>{
			userId = user.user.uid;
			return this.rallyPointService.checkForProjectAccess(codeData.projectId, userId)
		}).then((hasAccess)=>{
			if(hasAccess){ return { route: ['plan', codeData.projectId, codeData.planId] }; }
			else{
				return this.rallyPointService.addProjectAccess(codeData.projectId, userId, this.rallyStateSync.accessCode).then(()=>{
					return { route: ['plan', codeData.projectId, codeData.planId] };
				})
			}
		})
	}
	
	signup(state: RallyState){
		//todo, validate
		let cloneList = ["firstName", "lastName", "company", "phone", "title", "email", "accessCode"];
		let userObj:any = {};
		let codeData = state.accessCodeData;
		
		cloneList.forEach(function(key){ userObj[key] = state[key]; });
		userObj.method = "rally point";
		return this.firebaseUsers.create(userObj, state.password).then(user => {
			return { route: ['plan', codeData.projectId, codeData.planId] };
		}).catch(errorMessage => {
			console.log('error', errorMessage);
			return { message: errorMessage }
		})
	}
	
	handleRedirect(result:TestResult, settingSubject: Subject<LoadingBlockerState>, waitingObservable:Observable<LoadingBlockerState>){
		if(result.route){ settingSubject.next(LoadingBlockerState.complete); }
		else{ settingSubject.next(LoadingBlockerState.failure); }
		if(result.message){ 
			//override for old code (remove later)
			if(result.message === "Can't create: Can't create, user already exists"){ return; }
			Logging.warning(result.message);
		}
		
		waitingObservable.pipe(first()).subscribe(done => {
			if(result.route){ this.router.navigate(result.route); }
		})
	}
	
	abort(){
		this.router.navigate(['login']);
	}
	
	private _stateUpdate(state:RallyState, initalData?:RallyState){
		let obj = initalData ? Object.assign({}, initalData) : {};
		acceptedFields.forEach(key => {
			if(state[key]){obj[key] = state[key]}
		});
		this.rallyStateInternal.next(obj);
	}
	

}
