import { Component, Input } from '@angular/core';

import { Location } from 'ng2/ticket-edit/components/temp-models/Location';

/**
 * Displays the ticket's location.  If the role is editable, then it's 
 * displayed as a dropdown.  If it's not editable, it's displayed 
 * in a grayed out panel.
 * 
 * Inputs:
 *   list of locations
 *   currently selected location (can be null)
 * Outputs:
 *   what happens on selection change...?
 */
@Component({
	selector: 'ticket-location',
	template: require('./ticket-location.component.html'),
	host: {'class': 'ticket-location--style'}
})
export class TicketLocationComponent {

	/**
	 * @input locationList - The list of locations to choose from
	 */
	@Input() locationList: Location[];

	/**
	 * @input roles - The list of roles to choose from
	 */
	@Input() selectedLocation: Location;

	constructor(){
		console.log('TicketLocationComponent constructor');
	}

}
