'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import * as strings from "ng2/common/strings";

import { BehaviorSubject } from "rxjs";
import { last, first } from "rxjs/operators";

import { Rectangle } from "pixi.js";

import Point from "js/lib/math/Point";
import Rect from "js/lib/math/Rect";
import AnimationQueue from "js/lib/ui/AnimationQueue";
import {DateValidationConstants} from "js/constants/date-validator.constant";

import * as pinUtils from "ng2/common/utils/promise-pin-utils";

import { TicketList } from "ng2/fancy-firebase/lists/TicketList"
import { PlanState } from "ng2/common/services/plan-state.service";
import { DragAnalysis, DragPromptConditions, DragPromptOutput } from "ng2/common/models/TicketDragService";

declare const window: any;
declare const fancyNotification: any;

/************************************************
*	Contains all the code related to interacting
*	with a ticket
*************************************************/

export default function ticketHandling(scope){
	scope.dragCache = {};
	var state = scope.legacyRouting.oldStyleSnapshot();
	//complex bit of crap that manages a special local cache
	//if the local value doesn't exist, it pulls it out of the actual value
	//returning null if the actual doesn't exists
	//only allows the copying of primitive values, trying to pull an object will also get a null
	function getLocal(ticketId, ...theRest){
		if(!ticketId){ return null; }

		//set up the initial nesting level, since it requires a function call
		var actual = g.tickets.$getRecord(ticketId).data;
		if(!scope.dragCache[ticketId]){ scope.dragCache[ticketId] = {}; }
		var cache = scope.dragCache[ticketId];

		for(var i = 0; i < theRest.length; i++){
			if(actual[theRest[i]] === undefined || actual[theRest[i]] === null){
				//console.log('args', i, arguments[i], actual[arguments[i]], actual);
				// return null;
				return {}; //this might prove problematic
			}
			if(cache[theRest[i]] === undefined || cache[theRest[i]] === null){
				//consider adding obj checks and subobject checks for dates and arrays, probably won't be relevant
				if( i === theRest.length-1){
					if( typeof actual[theRest[i]] !== 'object'){
						cache[theRest[i]] = actual[theRest[i]];
					}
					else{
						return null;
					}
				}
				else{
					cache[theRest[i]] = {};
				}
			}
			actual = actual[theRest[i]];
			cache = cache[theRest[i]];
		}
		return scope.dragCache[ticketId];
	}

	//empty values, try to avoid the garbage collector by going really specific
	//call on dragStart
	function clearCache(){
		for(var key in scope.dragCache){
			scope.dragCache[key] = {};
		}
	}
	this.scope = scope;
	
	//just used by tapTimelineTicket for now...
	// function fakeDispDate(ticket){
	// 	var dispDate = "";
	// 	if(ticket.data.plannedStart){ dispDate = dateJar(ticket.data.plannedStart, 'MMM DD'); }
	// 	else if(ticket.data.lastPlannedStart){dispDate = dateJar(ticket.data.lastPlannedStart, 'MMM DD'); }
	// 	if(ticket.data.durationDays && ticket.data.durationDays > 1){
	// 		if(ticket.data.plannedFinish){
	// 			dispDate += " - " + dateJar(ticket.data.plannedFinish, 'MMM DD');
	// 		}
	// 		else if(ticket.data.lastPlannedFinish){
	// 			dispDate += " - " + dateJar(ticket.data.lastPlannedFinish, 'MMM DD');
	// 		}
	// 	}
	// 	return dispDate;
	// }
	// 
	this.doorDeadZone = new BehaviorSubject<Rectangle>(null);
	
	this.tapTimelineTicket = function tapTimelineTicket(event, currentElement){
		//in theory the array shouldn't fall out of sync (needs testing though)
		var res = currentElement.timelineData;
		console.log('res', res);
		if(!res){return;}
		var ticket = res.constraint;
		if(ticket._debugIdxData.planId !== state.planId){
			ticket.local.element = $(currentElement);
		}
		
		scope.openTimelineTicket(ticket)
	}
	

	/**
	 * Call when a tap on a sticky is detected.  Toggles edit mode to put
	 * the sticky into edit mode.
	 */
	this.tap = function(event, currentElement){
		var ticket = currentElement.ticket;
		if(ticket === null){
			var extra = utils.makeStack(new Error("user attempted to click a null ticket"));
			//extra.ticketId = id || null;
			scope.fbReporting.reportWarning("user attempted to click a null ticket", extra);
			return;
		}

		//empty multi-select if necessary
		['tickets', 'floatingTickets'].forEach(function(tickets){
			if(!scope[tickets].selection.isEmpty()){
				scope[tickets].selection.empty();
			}
		});

		//access level check
		if(!scope.accessCheck.hasRole(ticket.data.roleId) || scope.accessCheck.isReadOnly()){
			ticket.local.readOnly = true;
		}
		else{
			ticket.local.readOnly = false;
		}
		scope.ticketOps.toggleEditMode([ticket]);
	};

	/**
	 * Call when a doubletap on a sticky is detected.  Toggles multi select mode
	 * on the sticky.
	 */
	this.doubleTap = function(event, currentElement){
		if(scope.flags.stickyInEditMode) return; //abort if a sticky is being edited

 		// var id = $(currentElement).data('id');
		// scope.tickets.selection.toggle(id); // Toggle selected status.
		var ticket = currentElement.ticket;
		var tickets = ticket.$parent;

		var flag = false;
		['tickets', 'floatingTickets'].forEach(function(otherList){
			if(scope[otherList] !== tickets && !scope[otherList].selection.isEmpty()){ flag = true; }
		});
		if(flag){return;}
		
		tickets.selection.toggle(ticket);
		analyticsService.multiselectDoubleTap(tickets.selection.count(), state.planId);
		
	};

	//tracks the furthest left and right tickets in a multi-select
	//the assumption being that this can be set at the beginning of the drag with the result cached
	// var lineElements = {
	// 	x: $('<div class="snapline horizontal-snapline"></div>'),
	// 	y: $('<div class="snapline vertical-snapline"></div>')
	// };
	// $('body').append(lineElements.x);
	// $('body').append(lineElements.y);
	var multiEdges:any = {};
	var startDragRect;
	var dragRect;
	var g:any = {};
	var dragging = false;
	var floatCache:any = {
		readOnly: false,
		regionElement: null,
		regionRect: null,
		regionScroll: null,
		ticketWidth: null,
		xCache: null,
		lastMargined: null,
		type: null,
		canBeFloated: true
	};
	
	var finishPresEvent;


	function recalcMultiEdges(skipValidation?){
		
		if(g.tickets.$getRecord(multiEdges.left) && g.tickets.$getRecord(multiEdges.right) && !skipValidation){return;}
		
		multiEdges.left = null;
		multiEdges.right = null;
		
		g.tickets.forEach(function(ticket){
			if(!multiEdges.left){ multiEdges.left = ticket.$id; }
			if(!multiEdges.right){ multiEdges.right = ticket.$id; }
			if(ticket.data.left > g.tickets.$getRecord(multiEdges.right).left){ multiEdges.right = ticket.$id; }
			if(ticket.data.left < g.tickets.$getRecord(multiEdges.left).left){ multiEdges.left = ticket.$id; }
		});
		//check for furthest left and right tickets

		
		//makeDragRect(ticket);
	}

	/**
	 * Call when dragstart on a sticky is detected.
	 * Code here will initialize the conditions for a one finger
	 * drag on a note to move it.
	 */
	this.dragStart = function(event, currentElement, ticketKey)
	{
		// console.log('legacy drag start');
		if(ticketKey === "tickets"){ var ticket = scope.tickets.selection.selectedItems[0]; }
		else{ var ticket = currentElement.ticket; }
		floatCache.readOnly = false;
		
		if(ticketKey === "floatingTickets"){
			scope.planState.actions.unSelectTicket(scope.planState.tickets.selectedTicket$.getValue().list);
		}
		
		//abort conditions
		if(!ticket || ticket.local.deleted){return false;}
		if(scope.flags.stickyInEditMode){ return false; }
		if(scope.flags.disableDrag){ return false; }
		if(scope.accessCheck.isReadOnly()){
			if(ticketKey !== "tickets"){ floatCache.readOnly = true; }
		} //abort in readonly
		
		if(!scope.accessCheck.isAdmin()){
			var userRole = scope.you.projectMember.roleId;
			
			//there's a "not your role" role involved
			var notAdminNotRole = scope[ticketKey].selection.selectedItems.some(function(ticket){
				return !scope.accessCheck.hasAccessToTicket(ticket);
			}) || !scope.accessCheck.hasAccessToTicket(ticket);
			if(notAdminNotRole){console.log("isn't this annoying"); return false;}
		}

		g = {
			"target": ticketKey,
			"tickets": scope[ticketKey],
			"multi": scope[ticketKey].selection
		};
		
		clearCache();

		// TODO: This probably needs to be shared among clients and not just
		// local to the current client.  Also, need to address issues in MOC-45.
		scope.maxZIndex++;

		if(!g.multi.selected(ticket)){
			//if the ticket isn't selected, switch to only it being selected
			['tickets', 'floatingTickets'].forEach(function(tickets){
				if(!scope[tickets].selection.isEmpty()){
					scope[tickets].selection.empty();
				}
			});
			g.multi.add(ticket);
		}

		//reset the multiEdges obj
		multiEdges = {};
		regionHit = false;
		sideSwap = false;
		
		this.initFloaties();
		ticketSetup(g.multi.selectedItems, event);
		dragging = true;
		
		
	};
	
	// this.startFloatingDetectionOnly = function(){
	// 	g = {
	// 		"target": "tickets",
	// 		"tickets": [],
	// 		"multi": {selectedItems: []}
	// 	};
	// 	this.initFloaties();
	// }
	
	this.initFloaties = function(){
		var felement = $('.floating-ticket-region');
		floatCache.regionElement = felement;
		floatCache.canBeFloated = true;

		felement.addClass('drag-in-progress');
		felement.css('opacity');
		var offset = felement.offset();
		//current rect is the regions actual x, and a y based on it's the bottom of the page - it's height
		floatCache.regionRect = new Rect(offset.left, window.innerHeight - felement.parent().parent().height(), felement.width(), felement.height());

		var floatingDoor = $('.floating-door');
		floatCache.doorElement = floatingDoor;
		floatCache.doorElement.css({"display":"block"});

		floatCache.tempDoor = new Rect(floatingDoor.offset().left, window.innerHeight - floatingDoor.height() - 50, floatingDoor.width(), floatingDoor.height() + 50);

		//console.log(floatCache.regionRect)
		floatCache.regionScroll = felement.scrollLeft();
		floatCache.scrollMax = felement[0].scrollWidth - felement[0].clientWidth;
		if(g.target === "floatingTickets"){floatCache.doorElement.addClass('hide');}
		
		floatCache.arrowLeft = offset.left + 50;
		floatCache.arrowRight = floatCache.regionRect.right - 50;

		floatCache.type = g.target;
		floatCache.ticketWidth = $('.floating-ticket').outerWidth(true); //consider hard coding or something more elegant
		buildFloatingPosList();
		
		this.updateFloatingRectObserver(scope.floatingRegionApi.isShown());
	}
	
	
	//## makeDragRect
	var makeDragRect = (function(){
		var left = Infinity;
		var right = -Infinity;
		var top = Infinity;
		var bottom = -Infinity;
		
		function done(){
			var rect = new Rect({"from": new Point(left,top), "to": new Point(right, bottom)});
			//console.log(rect);
			left = top = Infinity, right = bottom = -Infinity;
			return rect;
		}
		
		var returnFunction:any = function makeDragRect(ticket){
			left = Math.min(left, getLocal(ticket.$id, 'left').left);
			right = Math.max(right, getLocal(ticket.$id, 'left').left+getLocal(ticket.$id, 'width').width);
			top = Math.min(top, getLocal(ticket.$id, 'top').top);
			bottom = Math.max(bottom, getLocal(ticket.$id, 'top').top+getLocal(ticket.$id, 'height').height);
			//console.log('left', left, right, top, bottom);
		};
		returnFunction.done = done;
		return returnFunction;
	})();
	

	function ticketSetup(items, event){
		if(!items){ return; }
	  //Stash the starting left/top values
		items.forEach(function(ticket){
			//the hackery is real
			if(g.target === "floatingTickets"){
				// ticket.local.floatingElement = ticket.local.element;
				console.log('ticket.local.element', ticket.local.element, ticket.local);
				// var e = ticket.local.element.clone();
				if(ticket.data.type === "constraint"){
					var pm = scope.meshedUsers.$getPmRecord(ticket.data.responsibleProjectMemberId);
					var role = pm ? (pm.data.roleId || "") : "";
				}
				else{
					var role = ticket.data.roleId || "";
				}
				// var e = $('<div class=" wrap-color drag-ticket '+role+' floating-ticket"></div>');
				// ticket.local.element = e;
				// e.addClass('dragging');
				// var offset = e.offset();

				ticket.data.left = scope.hud.screenToRealX(event.center.x) - ticket.data.width/2;
				ticket.data.top = scope.hud.screenToRealY(event.center.y) - ticket.data.height/2;
				if(scope.activeLine.$value !== null && ticket.data.left < scope.activeLine.$value){
					let widthModifier = scope.ticketConstants.calDayWidthPixels.$value || 145;
					let heightModifier = scope.ticketConstants.calTicketHeightPixels.$value || 135;
					var width = (ticket.data.durationDays || 1) * widthModifier;
					var height = heightModifier;
					if(ticket.data.type === "constraint" || ticket.data.type === "milestone"){
						width = scope.ticketConstants.calDayWidthPixels.$value || 145;
						height = scope.ticketConstants.calTicketHeightPixels.$value || 135;
					}
					ticket.data.width = width;
					ticket.data.height = height;
				}

				// if(ticket.data.type === "constraint"){console.log('rounding'); e.addClass('timeline-dot');}
				// if(ticket.data.type === "milestone"){e.addClass('otherwise-unstyled-diamond');}

				ticket.local.element.addClass('dragging');
				// $('.zoomContent').append(e);
			}
			else if(g.target === "tickets"){
				if(ticket.data.type === "constraint"){
					var pm = scope.meshedUsers.$getPmRecord(ticket.data.responsibleProjectMemberId);
					var role = pm ? (pm.data.roleId || "") : "";
				}
				else{
					var role = ticket.data.roleId || "";
				}
				ticket.local.element = $('<div class="stickyNote wrap-color drag-ticket '+role+' floating-ticket"></div>');
				ticket.local.element.addClass('dragging hide');
				if(ticket.data.type === "constraint"){ticket.local.element.addClass('timeline-dot');}
				if(ticket.data.type === "milestone"){ticket.local.element.addClass('otherwise-unstyled-diamond');}
				// ticket.local.element.addClass('floating-ticket hide');
				// ticket.local.floatingElement.attr('style', '');
				// ticket.local.floatingElement.remove(); //<----------------- this is weird

				floatCache.regionElement.append(ticket.local.element);


				//promised
				if(ticket.data.promisePeriodStack){ floatCache.canBeFloated = false; }
				//statused (other then promised)
				if(ticket.data.actualFinish){ floatCache.canBeFloated = false; }
				//has dependencies
				if(ticket.data.predecessors || ticket.data.successors){ floatCache.canBeFloated = false; }
				//is constraint
				if(ticket.data.type === "constraint"){ floatCache.canBeFloated = false; }

			}
			ticket.local.startTop = ticket.data.top;
			ticket.local.startLeft = ticket.data.left;
			ticket.local.startWidth = ticket.data.width;
			ticket.local.height = ticket.data.height;

			//check for furthest left and right tickets
			if(!multiEdges.left){ multiEdges.left = ticket.$id; }
			if(!multiEdges.right){ multiEdges.right = ticket.$id; }
			//console.log('bleh', g.tickets.$getRecord(multiEdges.right).data.left, g.tickets.$getRecord(multiEdges.left).data.left);
			if(ticket.data.left > g.tickets.$getRecord(multiEdges.right).data.left){ multiEdges.right = ticket.$id; }
			if(ticket.data.left < g.tickets.$getRecord(multiEdges.left).data.left){ multiEdges.left = ticket.$id; }
			
			makeDragRect(ticket);
			
		});
		dragRect = (makeDragRect as any).done();
		startDragRect = dragRect.clone();
	  //Apply initial CSS for drag.
		// g.multi.selectedItems.forEach(function(ticket){
		// 	var element = ticket.local.element;
		// 	element.css("zIndex", scope.maxZIndex);
		// });
		//hacky means of fixing the slide thing
		$('.stickyNote-wrapper').removeClass('animateChanges');
	}
	
	




	var regionHit = false;
	var sideSwap;
	var shiftingTickets = [];

	//when this function is called "this.tickets" isn't actually a list of tickets
	//but instead a simple object containing some ticket data, current just a left
	var floatDragData = {tickets:[]};
	var drawLefts = utils.bind(floatDragData, function(){
		if(!dragging){return;}
		this.tickets.forEach(function(ticket, pos){
			if(!ticket.element){return;}
			ticket.element[0].style.left = ticket.left - floatCache.regionRect.x + floatCache.regionScroll + 'px';
		}, this);
	});

	/**
	 * Call when drag on a sticky is detected. Code here will process
	 * the repeated events to move the note in response to a one
	 * finger drag. Accepts optional arguements deltaX and deltaY
	 * which are used to apply an additional offset.
	 */
	this.drag = function(event, currentElement)
	{
		if(!g.multi.selectedItems.length){
			this.abortDrag();
			return false;
		}
		// console.log('legacy drag');
		var planState: PlanState = scope.planState;
		//ticket was deleted mid drag
		if(g.target === "floatingTickets" && (!currentElement || !currentElement.ticket)){
			$('.stickyNote-wrapper').addClass('animateChanges');
			Logging.warning("another user has deleted your current ticket");
			return false;
		}
		floatingOverlapCheck(event.center.x, event.center.y);
		checkFloatingSwap(event.center.x, event.center.y);
		checkFloatChange(event.center.x, event.center.y, this);

		//scroll check
		var isInFloating = inFloatingTicketRegion(event.center.x, event.center.y);
		if(isInFloating && event.center.x > floatCache.arrowRight){
			floatingScroll.start(1);
		}
		else if(isInFloating && event.center.x < floatCache.arrowLeft){
			floatingScroll.start(-1);
		}
		else{
			floatingScroll.stop();
		}

		//drawing an' shit
		if(isInFloating){
			floatDragData.tickets = g.multi.selectedItems.map(function(t, idx){
				return {left: event.center.x + idx * floatCache.ticketWidth, element: t.local.element};
			});
			AnimationQueue(drawLefts);
		}
		//don't actually forward to drag service unless it's not already running
		else if(g.target === "floatingTickets" && planState.ticketDragService.dragInProgress){
			planState.ticketDragService.drag(new Point(event.center.x, event.center.y));
		}
	};

	function inFloatingTicketRegion(x,y){
		if(floatCache.readOnly){return true;}
		//console.log('isShown', scope.floatingRegionApi.isShown(), 'y', y, floatCache.regionRect.y);
		return scope.floatingRegionApi.isShown() && y > floatCache.regionRect.y;// && floatCache.canBeFloated;
	}

	//check if the float region should be toggled
	var checkFloatChange = (function(){
		var timeout;
		var lastX;
		var lastY;

		function clear(){
			clearTimeout(timeout); timeout = null;
		}


		var tick = function(x,y, self = undefined){
			lastX = x;
			lastY = y;
			var inRegion = inFloatingTicketRegion(x,y);

			//start close timer
			if(scope.floatingRegionApi.isShown() && !inRegion && !timeout){
				timeout = setTimeout(function(){
					if(!inFloatingTicketRegion(lastX,lastY)){
						scope.$apply(function(){
							scope.floatingRegionApi.hide();
						});
						if(self){self.updateFloatingRectObserver(false);}
					}
					timeout = null;
				}, 1200);
			}
			//clear close timer
			if(inRegion){clearTimeout(timeout); timeout = null;}

			//check if it should open
			//(arbitrary position)
			var door = floatCache.tempDoor;
			if(x > door.left && x < door.right
			&& y > door.top && y < door.bottom && !scope.floatingRegionApi.isShown()){// && floatCache.canBeFloated){
				scope.floatingRegionApi.show();
				if(self){self.updateFloatingRectObserver(true);}
			}
		};

		(tick as any).clear = clear;
		return tick;
	})();


	function buildFloatingPosList(){
		var startX = floatCache.regionRect.x;
		var selection = g.multi.selectedItems;
		var theList = [];
		var matchCount = 0;
		scope.floatingTickets.forEach(function(fl, idx){
			if(selection.indexOf(fl) !== -1){matchCount++; return;} //exclude matches
			theList.push({
				ticket: fl,
				origIdx: idx,
				x: floatCache.ticketWidth * (idx - matchCount)
			});
		});
		//console.log('theList', theList);
		floatCache.xCache = theList;
	}

	//maybe just ditch all the ticket mapping crap and just return the index...
	function getFloatingTicketFromX(x){
		//x += floatCache.regionScroll;
		var index = Math.floor((x + floatCache.regionScroll - floatCache.regionRect.x) / floatCache.ticketWidth);
		//this capping might not work so well for before/ afters
		if(index < 0){index = 0;}
		// if(index > floatCache.xCache.length-1){index = floatCache.xCache.length-1;}
		if(index > floatCache.xCache.length-1){return null;}
		return floatCache.xCache[index];
	}

	function floatingOverlapCheck(x, y){
		if(!inFloatingTicketRegion(x,y)){return;}
		//x += floatCache.regionScroll;
		var ftc = getFloatingTicketFromX(x);
		//console.log(ftc);
		//if(floatCache.lastMargined && (!ftc || floatCache.lastMargined.ticket !== ftc.ticket)){
		if(floatCache.lastMargined !== ftc){
			if(floatCache.lastMargined){
				floatCache.lastMargined.ticket.local.element.css({"margin-left": 0});
			}
			if(ftc){
				ftc.ticket.local.element.css({"margin-left": floatCache.ticketWidth * g.multi.count()});
			}
		}
		floatCache.lastMargined = ftc;
	}

	function clearFloatingCache(){
		if(floatCache.lastMargined){floatCache.lastMargined.ticket.local.element.css({"margin-left": 0});}
		floatCache.regionElement.removeClass('drag-in-progress');
		(checkFloatChange as any).clear();
		floatCache.doorElement.css({"display":""});
		floatCache.doorElement.removeClass('hide');
	}

	function checkFloatingSwap(x, y){
		var isFloating = inFloatingTicketRegion(x + floatCache.regionScroll,y);
		
		//dragging into floating
		if(isFloating && floatCache.type === "tickets"){
			g.multi.selectedItems.forEach(function(t){
				// t.local.element.addClass('hide');
				t.local.element.removeClass('hide');
			});
			
			var selectedTickets;
			scope.planState.tickets.selectedTicket$.pipe(first()).subscribe((t)=>{selectedTickets = [...t.list.values()];})
			
			if(g.target === "floatingTickets"){
				scope.planState.actions.unSelectTicket(selectedTickets);
			}
			scope.planState.actions.moveTicketToFloating(selectedTickets);
			
			floatCache.doorElement.addClass('hide');
		}
		//dragging out of floating (into the plan)
		else if(!isFloating && floatCache.type === "floatingTickets"){
			g.multi.selectedItems.forEach(function(t){
				// t.local.element.removeClass('hide');
				t.local.element.addClass('hide');
			});
			floatCache.doorElement.removeClass('hide');
			
			
			var ticketList:TicketList = scope.planState.tickets;
			
			//started on the plan
			if(g.target === "tickets"){
				var previousPlanTickets = g.multi.selectedItems.reduce((acc, curr) => {
					var tick = ticketList._internalList.get(curr.$id);
					if(tick){ acc.push(tick); }
					return acc;
				}, []);
				scope.planState.actions.returnTicketToPlan(previousPlanTickets);
				// scope.planState.actions.selectTicket(previousPlanTickets)
			}
			//started in floating
			else{
				var floatingTicketData = g.multi.selectedItems.map(t => t.data);
				var startPoint = makeShapely(floatingTicketData);
				// console.log('floatingticketdata', floatingTicketData);
				floatingTicketData.forEach((t)=>{
					var action = scope.planState.actions.addFloatingTicketToPlan(t);
					// console.log('action.payload', action.payload);
					scope.planState.actions.selectTicket(action.payload)
				})
				if(!scope.planState.ticketDragService.dragInProgress){
					scope.planState.ticketDragService.dragStart(startPoint, ticketList.selectedTicket$);
				}
			}
		}
		floatCache.type = (isFloating) ? "floatingTickets" : "tickets";
	}
	
	function makeShapely(ticketDataList){
		var planData = scope.planState.plan._internalObject;
		var startX = planData && planData.activeLineX ? planData.activeLineX + 100 : scope.hud.screenToRealX(window.innerWidth/2);
		var y = scope.hud.screenToRealY(window.innerHeight / 2);
		console.log('y', y, window.innerHeight / 2, scope.hud.realToScreenY(y));
		ticketDataList.sort((a,b)=> a.userOrder - b.userOrder);
		
		ticketDataList.forEach((t, idx)=>{
			t.top = y;
			t.left = startX + (290+50) * idx;
			t.width = 290;
			t.height = 270;
		});
		return new Point(scope.hud.realToScreenX(startX), scope.hud.realToScreenY(y));
	}

	var floatingScroll = (function(){
		var timer;
		var distancePerTick = 6;
		var tickFrequency = 20;
		var distance;

		function increment(){
			var x = floatCache.regionScroll + distance;
			if(x < 0){x = 0;}
			if(x > floatCache.scrollMax){x = floatCache.scrollMax;}
			floatCache.regionScroll = x;
			return x;
		}

		function start(cardinality){
			clearInterval(timer);
			distance = distancePerTick * Math.sign(cardinality);
			floatCache.regionElement.scrollLeft(increment());
			timer = setInterval(function(){
				floatCache.regionElement.scrollLeft(increment());
				//floatingOverlapCheck(event.center.x, event.center.y); //<---  a working version of this should make things smoother
				AnimationQueue(drawLefts);
			}, tickFrequency);
		}

		function stop(){
			clearInterval(timer);
		}

		return {
			start: start,
			stop: stop
		};
	})();

	//match algorithm % ticketWidth, should map x values to an array index

/*
dragStart:
- make function that creates a floating-ticket position cache, exluding the tickets that aren't being dragged. Need to call this function on firebase updates (probably)
- make a queryCacheX function to map x -> ticket

drag:
- if dragging over floating region check position against cache, set margin-left = dragging elements width of ticket at cache position. Need to record where margin is set and ensure it's only set on one ticket at a time
- need to check if the drag has switched between plan or floating tickets on this iteration (maintain a currentType key of some sort). Two very different checks for when it should change type
- need to kick off a floating ticket scrolling function of some sort when hovering over the scroll arrows, will probably require storing that scrollLeft value and applying to the drag position

dragEnd:
- if plan -> floating. Tickets are removed from tickets, added to floatingTickets, and somehow positioned in one shot. Probably need to update the ordering function to just return it's proposed updateObj, most likely this isn't going to play nicely with the occasional need to reorder.
- if plan -> plan, usual shit
- if floating -> floating. Clean up floating stuff, update ordering.
- if floating -> plan. Clean up floating stuff, add to plan.
*/

	//if andReset is true, apply some additional rollbacks (intended as an undo of sorts)
	this.dragCleanUp = function(andReset){
		var self = this;
		$('.stickyNote-wrapper').addClass('animateChanges');
		//organize the various elements created as part of the drag
		g.multi.selectedItems.forEach(function(ticket){
			delete ticket.local.startTop;
			delete ticket.local.startLeft;
			
			if(ticket.local.element){
				if(g.target === "tickets"){
					ticket.local.element.remove();
				}
				ticket.local.element.attr('style', '');
				ticket.local.element.removeClass('dragging hide');
			}


		});
		// unselect selected (should this be only if there was 1?)
		if(g.multi.count() === 1){
			g.multi.empty(); // note: this calls .$apply()
		}
		
		
		this.doorDeadZone.next(null);
		scope.floatingRegionApi.update();
		clearCache();
		scope.orderedExecution.execute();
	};
	


	//TODO - do dependencies later
	this.checkAbortConditions = function(analysis: DragAnalysis, droppedInFloating: boolean){
		var planState: PlanState = scope.planState;
		
		if(planState.ticketDragService.checkAbortConditions(analysis)){ return true; }
		if(droppedInFloating && analysis.hasCompleted){
			Logging.warning(strings.DRAG_COMPLETED_TO_FLOATING);
			return true;
		}
		if(droppedInFloating && analysis.hasPromises){
			Logging.warning(strings.DRAG_PROMISED_INTO_FLOATING);
			return true;
		}
	}
	
	this.checkPromptConditions = function(analysis: DragAnalysis, droppedInFloating: boolean):Array<()=>Promise<DragPromptOutput>>{
		function wrap(type, payload){
			return {type, payload};
		}
		
		var planState: PlanState = scope.planState;
		var popupExecution = planState.ticketDragService.checkPromptConditions(analysis);
		
		if(droppedInFloating && analysis.hasConstraints){
			// console.log('constraint check hit');
			popupExecution.push(()=>{
				return scope.popup({
					"title": strings.DRAG_CONSTRAINT_TO_FLOATING_PROMPT_TITLE,
					"description": strings.DRAG_CONSTRAINT_TO_FLOATING_PROMPT_DESCRIPTION
				}).then(payload => wrap(DragPromptConditions.FLOATCONSTRAINTS, payload));
			})
		}
		
		if(droppedInFloating && analysis.hasDependencies){
			popupExecution.push(()=>{
				return scope.popup({
					"title": strings.DRAG_DEPENDENCY_TO_FLOATING_PROMPT_TITLE,
					"description": strings.DRAG_DEPENDENCY_TO_FLOATING_PROMPT_DESCRIPTION
				}).then(payload => wrap(DragPromptConditions.FLOATDEPENDENCIES, payload));
			})
		}
		
		// popupExecution.push(()=>{
		// 	return scope.popup({
		// 		"title": "Just sayin' hi",
		// 		"description": "Prompt for everything"
		// 	}).then(payload => wrap(DragPromptConditions.DEBUG, payload));
		// })
		// popupExecution.push(()=>{
		// 	return scope.popup({
		// 		"title": "No! don't go!",
		// 		"description": "ಥ﹏ಥ"
		// 	}).then(payload => wrap(DragPromptConditions.DEBUG, payload));
		// })
		
		// if(droppingInFloating && analysis.hasDependencies)
		
		return popupExecution;
	}
	
	this.runPrompts = function(popups: Array<()=>Promise<DragPromptOutput>>) :Promise<Map<DragPromptConditions, any>>{
		if(popups.length === 0){ return Promise.resolve(new Map()); }
		return utils.sequencePopups(popups).pipe( last() ).toPromise().then((list)=>{
			console.log('list', list);
			var obj = new Map();
			list.forEach((item)=>{
				obj.set(item.type, item.payload);
			});
			return obj;
		})
	}
	
	this.abortDrag = function(){
		this.resetFloaties();
		var planState: PlanState = scope.planState;
		planState.ticketDragService.dragCancel();
		planState.actions.removeFloatingTickets();
		this.dragCleanUp();
	}
	
	this.resetFloaties = function(){
		if(!scope.floatingTickets){return;}
		scope.floatingTickets.forEach(t => {
		    if(t.local && t.local.element){
					t.local.element.removeClass('hide');
				}
		});
		if(floatCache && floatCache.doorElement){ floatCache.doorElement.addClass('hide'); }
	}

	this.dragEnd = function(event, currentElement, preventSave)
	{
		// console.log('legacy drag end');
		var planState: PlanState = scope.planState;
		var self = this;
		var dragEndEntirelyHandled = false;
		var analyticsCollection:any = {};
		
		dragging = false;
		floatingScroll.stop();
		planState.ticketDragService.dragAtEdgeCancel.next(true);
		clearFloatingCache();

		var floatPath = scope.fbConnection.stringify(scope.floatingTickets.$ref().ref);

		var updateObj = {};
		var droppedInFloating = inFloatingTicketRegion(event.center.x, event.center.y);
		var floatDropList = [];
		
		var dragAnalysis = planState.ticketDragService.analyzeDragStatus();
		
		if(this.checkAbortConditions(dragAnalysis, droppedInFloating)){
			this.abortDrag();
			dragEndEntirelyHandled = true;
			return Promise.resolve(true);
		}
		
		var promptList = this.checkPromptConditions(dragAnalysis, droppedInFloating)
		console.log('promptList', promptList);
		return this.runPrompts(promptList).then((result)=>{
			console.log('result', result, dragAnalysis, DragPromptConditions.COLLECTVARIANCE);
			if(result.has(DragPromptConditions.COLLECTVARIANCE)){
				// debugger;
				//it may be wise to grab a new dragAnalysis post popup to get the most life data possible
				dragAnalysis.needVarianceList.forEach(ticket => {
					if(!ticket.view.liveLastPromise){
						planState.scopeSoup.fbReporting.reportRealWarning("stale liveLastPromise encountered"); return;
					}
					var res = result.get(DragPromptConditions.COLLECTVARIANCE);
					ticket.view.liveLastPromise.data.varianceId = res.variance.$id;
					if(res.varianceComment){ ticket.view.liveLastPromise.data.varianceComment = res.varianceComment; }
					planState.actions.updateTicketLiveData(ticket.$id, {"livePromisePeriodStack": ticket.view.livePromisePeriodStack });
					// debugger;
					// pinUtils.decoratePinData(ticket.view.liveLastPromise, ticket.view)
					analyticsService.varianceSetByMovingTicket(state.planId, ticket.$id, res.variance.$id);
				})
			}
			if(dragAnalysis.clearVariance){
				dragAnalysis.clearVarianceList.forEach(ticket => {
					if(!ticket.view.liveLastPromise){
						planState.scopeSoup.fbReporting.reportRealWarning("stale liveLastPromise encountered"); return;
					}
					analyticsService.varianceClearedByMovingTicket(state.planId, ticket.$id, ticket.view.liveLastPromise.data.varianceId);
					ticket.view.liveLastPromise.data.varianceId = null;
					ticket.view.liveLastPromise.data.varianceComment = null;
					planState.actions.updateTicketLiveData(ticket.$id, {"livePromisePeriodStack": ticket.view.livePromisePeriodStack });
					
				})
			}
			if(result.has(DragPromptConditions.FLOATDEPENDENCIES)){
				var newTicketsToPersist = new Map();
				dragAnalysis.dependencyList.forEach(t => {
					var output = planState.dependencies.unlinkTicket(t.$id);

					output.writeData.forEach((side, sideIdx)=>{
						var sideName = sideIdx === 0 ? "predecessors" : "successors";
						for( var ticketId in side ){
							var theTicket = output.ticketCache.get(ticketId);
							var isTimelineTicket = theTicket.isTimelineTicket;
							var actualTicketId = theTicket.$id;
							
							var selectedTicket = g.multi.selectedItems.find(ticket => ticket.$id === actualTicketId );
							if(selectedTicket){ //do stupid legacy shit
								selectedTicket.data[sideName] = null;
							}
							else{ //update the non-selected tickets
								planState.actions.updateTicketLiveData(actualTicketId, {
									[sideName]: side[actualTicketId][sideName]
								}, isTimelineTicket ? planState.timelineTickets : planState.tickets);
								newTicketsToPersist.set(theTicket.$id, theTicket);
							}
						}
					});
				});
				//doing this outside the loop should minimize duplicates
				planState.actions.persistTicketData(newTicketsToPersist);
			}
			
			
			// switch(result.type){
			// 	case DragPromptConditions.ALMOSTOUTOFBOUNDS:
			// 		break;
			// 	case DragPromptConditions.COLLECTVARIANCE:
			// 	debugger;
			// 	//it may be wise to grab a new dragAnalysis post popup to get the most life data possible
			// 	dragAnalysis.needVarianceList.forEach(ticket => {
			// 		if(!ticket.view.liveLastPromise){
			// 			planState.scopeSoup.fbReporting.reportRealWarning("stale liveLastPromise encountered");
			// 			return;
			// 		}
			// 		var res = result[DragPromptConditions.COLLECTVARIANCE];
			// 		ticket.view.liveLastPromise.data.varianceId = res.variance.$id;
			// 		if(res.varianceComment){ ticket.view.liveLastPromise.data.varianceComment = res.varianceComment; }
			// 		planState.actions.updateTicketLiveData(ticket.$id, {"livePromisePeriodStack": ticket.view.promisePeriodStack });
			// 		debugger;
			// 		// pinUtils.decoratePinData(ticket.view.liveLastPromise, ticket.view)
			// 	})
			// 		//result.payload.variance.$id
			// 		//result.payload.varianceComment;
			// 		break;
			// 	case DragPromptConditions.DONT: break;
			// 	default: break;
			// }
			var tickets = g.multi.selectedItems;
			var shouldEmpty = false;
			//fill in drag data
			tickets.forEach(function(ticket, i){
				//collect drag data
				var obj:any = {
					"left": Math.round(getLocal(ticket.$id,'left').left),
					"top": Math.round(getLocal(ticket.$id,'top').top)
				};
				angular.extend(ticket.data, obj);
			});
			
			
			
			//dropped into the plan from the floating space
			if(!droppedInFloating && g.target === "floatingTickets"){
				var ticketsToSave;
				planState.tickets.selectedTicket$.pipe(first()).subscribe((t)=>{ticketsToSave = [...t.list.values()];})
				
				planState.actions.persistTicketDataLocalOnly(ticketsToSave);
				planState.ticketDragService.dragCancel();
				planState.actions.unSelectTicket(ticketsToSave);
				planState.actions.moveTicketToFloating(ticketsToSave);
				planState.actions.saveFloatingTicketToPlan(ticketsToSave);
				
				tickets.forEach(function(ticket, i){
					updateObj[floatPath+ticket.$id] = null;
				});
				
				dragEndEntirelyHandled = true;
				
				analyticsCollection.count = ticketsToSave.length;
				analyticsCollection.fromMyTickets = true;
			}
			//dropped into floating space from the plan
			else if(droppedInFloating && g.target === "tickets"){
				//add floaters
				tickets.forEach(function(ticket, i){
					ticket.data.id = ticket.$parent.getNewId();
					ticket.$id = ticket.data.id;
					updateObj[floatPath+ticket.data.id] = ticket.data;
					
					if(ticket.local.element){ ticket.local.element.remove(); }
				})
				
				planState.ticketDragService.stopListening();
				
				planState.tickets.selectedTicket$.pipe(first()).subscribe((newTickets)=>{
					var listThatWontBeDeleted = [...newTickets.list.values()];
					planState.actions.saveTicketToFloating(listThatWontBeDeleted);
					planState.actions.unSelectTicket(listThatWontBeDeleted);
					planState.actions.deleteTicket(listThatWontBeDeleted);
					analyticsCollection.count = listThatWontBeDeleted.length;
					analyticsCollection.toMyTickets = true;
				});
				planState.ticketDragService.dragCancel();
				dragEndEntirelyHandled = true;

			}
			//only floating space drag
			else if(droppedInFloating && g.target === "floatingTickets"){
				//you started this, now you finish it
				if(planState.ticketDragService.dragInProgress){planState.ticketDragService.dragCancel();}
				analyticsCollection.count = tickets.length;
				analyticsCollection.withinMyTickets = true;
				analyticsService.movedTickets(analyticsCollection.count, scope.planState.planId, analyticsCollection.withinMyTickets, analyticsCollection.toMyTickets, analyticsCollection.fromMyTickets, this.hasCrossedActiveLine);
			}
			
			if(droppedInFloating){
				tickets.forEach(function(ticket, i){
					floatDropList.push(ticket);
				})
			}

			self.dragCleanUp();
			if(shouldEmpty){g.multi.empty();}

			// firebase.database().ref("Rev4/projectMemberTickets/-KiaWAvlGwLagS8ABhAc/-KiaWAzHp-uYV_utegv9/").child(tickets[0].$id).on("value", (val)=> console.log('snappy', val.val()));

			//save 1 (no ordering)
			var ref = scope.fbConnection.fbRef();
			ref.update(updateObj);
			console.log('updateObj', updateObj);

			//reorder (save 2), this "may" need to be changed to wait for the completion of the previous save
			if(floatDropList.length){
				var reorderTarget = getFloatingTicketFromX(event.center.x);
				console.log('reorderTarget', reorderTarget);
				if(!reorderTarget){ scope.floatingTickets.ordering.moveTo(floatDropList, scope.floatingTickets.length); }
				else{ scope.floatingTickets.ordering.moveTo(floatDropList, reorderTarget.origIdx); }
			}
			
			//floating space note involved, handling normally
			if(!dragEndEntirelyHandled){ planState.ticketDragService.dragEnd(event.center);	}
			else{
				analyticsService.movedTickets(analyticsCollection.count, scope.planState.planId, analyticsCollection.withinMyTickets, analyticsCollection.toMyTickets, analyticsCollection.fromMyTickets, this.hasCrossedActiveLine);
			}
		})
		.catch((e)=>{
			//handle no's here
			this.abortDrag();
			return true;
		});
	};

	const magicFakeInfinity = 1000000;
	this.updateFloatingRectObserver = function updateFloatingRectObserver(open:boolean){
		var rect;
		if(open){
			rect = paperToPixiRect(floatCache.regionRect, {height: magicFakeInfinity, x: -magicFakeInfinity, width: window.innerWidth + magicFakeInfinity*2} ); //woooooooo, arbitrary (because Infinity - Infinity)
		}
		else{
			if(floatCache.canBeFloated){
				rect = paperToPixiRect(floatCache.tempDoor, {height: magicFakeInfinity} ); //woooooooo, arbitrary (because Infinity - Infinity)
			}
			else{
				rect = null;
			}
		}
		console.log('rect update', rect);
		this.doorDeadZone.next(rect);
	}

	interface PartialRectangle {x?: number, y?: number, width?: number, height?: number}

	function paperToPixiRect(rect, offsetObj:PartialRectangle = {x: null, y: null, width: null, height: null}){
		if(!rect){return null;}
		return new Rectangle(
			offsetObj.x !== null && offsetObj.x !== undefined ? offsetObj.x : rect.x, 
			offsetObj.y !== null && offsetObj.y !== undefined ? offsetObj.y : rect.y, 
			offsetObj.width !== null && offsetObj.width !== undefined ? offsetObj.width : rect.w, 
			offsetObj.height !== null && offsetObj.height !== undefined ? offsetObj.height : rect.h
		);
	}
}
