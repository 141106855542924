/**
 * The core data structure to be passed to an observer. 
 * @param payload TODO
 * @param type    The type of action. TODO - look into doing this class based instead of enum
 * @param key     Optional if the payload is a Ticket, otherwise required.
 */

interface hasId{
	$id?: string
}

export class FancyAction<Item extends hasId = any>{
	constructor(
		public payload: Item,
		public type: string,
		public key?: string
	){
		if(!this.key){ this.key = payload.$id; }
	}
}
