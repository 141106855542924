
import { Component, 
		 Output,
		 EventEmitter} from '@angular/core';

@Component({
	selector: 'ticket-footer',
	template: require('./ticket-footer.component.html'),
})
export class TicketFooterComponent {

	/**
	 * @Outputs something to signal that one of the buttons was clicked
	 * TBD what the button are and what should get emited.  For now, get
	 * cancel working again.
	 */
	@Output() canceled: EventEmitter<string> = new EventEmitter<string>();

	cancelClicked() {
		this.canceled.emit("canceled");
		console.log("cancelClicked");
	}
}
