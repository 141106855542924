/**
 * @typedef {Object} ColorPosition
 * @property {number} rowIdx The row index
 * @property {number} slotIdx The slot index
 */
/**
 * @namespace SystemColors
 * @desc A factory which contains all of the default system colors for Roles and Locations
 * @memberOf ticketspaceApp
 */
angular.module('ticketspaceApp').constant("SystemColors", {
	MAX_COLORS_PER_ROW: 8,
	MAX_COLORS_PER_PALETTE: 120,
	USE_BLENDED_TEXT: true,
	VALID_TEXT_COLORS: [
		"#777777",
		"#594d24",
		"#285454",
		"#b5b5b5",
		"#ccd1cc",
		"#6a6a6a",
		"#1e4f27",
		"#f6ebc6",
		"#bdf1f1",
		"#d6f7dc",
		"#541d1d"
	],
	DEFAULT_ROLE_COLORS: [
		"#ddedc8",
		"#bcaba4",
		"#ffccbb",
		"#b3e5fc",
		"#efebe8",
		"#9ccc66",
		"#e1bee7",
		"#ef9a9a",
		"#a5d6a7",
		"#ffe183",
		"#ffcc80",
		"#c5cae8",
		"#80deea",
		"#9e9e9e",
		"#e57373",
		"#64b5f6",
		"#ff8a66",
		"#f48fb1",
		"#fffac3",
		"#00acc2",
		"#e6ee9b",
		"#c0ca33",
		"#80c783",
		"#b2dfdc",
		"#4cb6ac",
		"#b0bfc6",
		"#90caf9",
		"#98a2dd",
		"#a78ade",
		"#ce93d8"
	],
	DEFAULT_LOCATION_COLORS: [
		"#424242",
		"#f9a825",
		"#ff6f00",
		"#e65100",
		"#b71c1c",
		"#880e4f",
		"#6a1b9a",
		"#311b92",
		"#0d47a1",
		"#3e2723",
		"#795548",
		"#827717",
		"#689f38",
		"#1b5e20",
		"#00897b",
		"#006064",
		"#78909c",
		"#0277bd"
	],
	ROLE_CLASS_NAME_TO_COLOR: {
		"stickyColor1": "#ddedc8",
		"stickyColor2": "#ffccbb",
		"stickyColor3": "#b3e5fc",
		"stickyColor4": "#efebe8",
		"stickyColor5": "#9ccc66",
		"stickyColor6": "#e1bee7",
		"stickyColor7": "#ef9a9a",
		"stickyColor8": "#a5d6a7",
		"stickyColor9": "#ffe183",
		"stickyColor10": "#ffcc80",
		"stickyColor11": "#c5cae8",
		"stickyColor12": "#80deea",
		"stickyColor13": "#9e9e9e",
		"stickyColor14": "#e57373",
		"stickyColor15": "#64b5f6",
		"stickyColor16": "#ff8a66",
		"stickyColor17": "#f48fb1",
		"stickyColor18": "#fffac3",
		"stickyColor19": "#bcaba4",
		"stickyColor20": "#00acc2",
		"stickyColor21": "#e6ee9b",
		"stickyColor22": "#c0ca33",
		"stickyColor23": "#80c783",
		"stickyColor24": "#b2dfdc",
		"stickyColor25": "#4cb6ac",
		"stickyColor26": "#b0bfc6",
		"stickyColor27": "#90caf9",
		"stickyColor28": "#98a2dd",
		"stickyColor29": "#a78ade",
		"stickyColor30": "#ce93d8"
	}
});