'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

export class RallyPointService{
	static $inject = ["$q", "firebaseProjectMembersStatic", "firebaseAccessCode", "fbdbTime", "firebaseIndices", "fbConnection"];
	constructor(
		private $q,
		private firebaseProjectMembersStatic,
		private firebaseAccessCode,
		private fbdbTime,
		private firebaseIndices,
		private fbConnection,
	){
		
	}
	
	// -----------------------------------------------------
	// internal
	// -----------------------------------------------------
	private codePath = 'accessCodes/codes/';
	private emailPath = 'emailDirectory/';
	
	
	getBasePm(userId){
		return {accessLevel: "General", userId: userId}
	}
	
	//for easier testing...
	boilerplate(projectId, planId){
		if(!projectId){projectId = "-KiaWAvlGwLagS8ABhAc";}
		if(!planId){planId = "-KjOXcJLvFkc_MFsp5V1";}
		//http://localhost:9001/#/plan/-KiaWAvlGwLagS8ABhAc/-KjOXcJLvFkc_MFsp5V1
		var theCode = "00001";
		var accessCode = {
			"projectId": projectId,
			"planId": planId,
			"timestamp": this.fbdbTime().add(1, 'days').valueOf()
		};
		this.fbConnection.fbRef(this.codePath+theCode).set(accessCode);
		this.fbConnection.fbRef('plans').child(accessCode.projectId).child(accessCode.planId).update({
			"accessCode": theCode
		});
	}
	
	checkEmail(email){
		if(!email){ return this.$q.when(false); }
		return this.$q.when(this.fbConnection.fbRef(this.emailPath+utils.escapeEmail(email)).once('value')).then(function(snap){
			return snap.exists();
		});
	}
	
	checkForProjectAccess(projectId, userId){
		return this.firebaseIndices.projectMember(projectId, userId).then(function(pmIndex){
			return pmIndex.$value !== null;
		});
	}
	
	addProjectAccess(projectId, userId, accessCode){
		console.log('adding access');
		var accessObj = {};
		accessObj["users/"+userId+"/accessCode/"] = accessCode;
		return this.firebaseProjectMembersStatic.addAtomic(projectId, this.getBasePm(userId), accessObj)
		.catch((e)=>{
			console.log('e', e);
			return this.$q.reject(e);
		});
	}
	
	getCodePath(code){
		return this.codePath+code;
	}
	getAccessCode(code){
		return new this.firebaseAccessCode(this.fbConnection.fbRef(this.getCodePath(code)));
	}
	
	createAccessCode(code, projectId, planId){
		var fbCode = this.fbConnection.fbRef(this.getCodePath(code));
		var obj = {
			projectId: projectId,
			planId: planId,
			timestamp: this.fbdbTime().add(1, 'hours').valueOf()
		};
		
		return this.$q.when(fbCode.transaction((existingData)=>{
			console.log('transacting', existingData);
			if(this.codeIsAvailable(existingData)){ return obj; }
		}));
	}
	
	codeIsAvailable(codeData){
		return codeData === null || codeData.timestamp < this.fbdbTime().valueOf();
	}
	
	//goin' old school with the callback api for the recursion
	//do not call this function directly
	//1) - $value is null or old
	//2) - $value is something
	// Incidentally, this entire thing could probably have been implemented within the structure of a transaction
	// buut... since this is already written, might we well use it. In theory there's slightly less overhead.
	checkForAccessInternal(cb, count?){
		if(count > 20){ cb(false); return; }
		
		var code = this.generateCode();
		//console.log('trying new code...', code);
		var fbCode = this.getAccessCode(code);
		fbCode.$loaded().then(()=>{
			if(this.codeIsAvailable(fbCode.$value === null ? null : fbCode)){
				console.log('code is unused or expired');
				cb(fbCode.$ref().ref, code);
			}
			else{
				console.log("code in use", code, count);
				this.checkForAccessInternal(cb, count ? count+1 : 1);
			}
			fbCode.$destroy();
		})
		.catch((e)=>{
			console.log('the hell did you do? This should not happen!');
			fbCode.$destroy();
		});
	}
	
	//this is the one you call
	checkForAccess(){
		return new this.$q((resolve, reject)=>{
			this.checkForAccessInternal(function(ref, code){
				if(ref){ resolve({ref: ref, code: code}); }
				else{ reject(new Error("Access code retry limit hit, aborting")); }
			});
		})
	}
	
	//this may result in a recursive promise loop. Which will give up after 5 attempts
	tryToWriteAccessCode(projectId, planId, count?){
		if(count > 5){ return this.$q.rejectErr("Transaction conflicted too many times"); }
		return this.checkForAccess().then((result)=>{
			console.log('code get', result.code);
			return this.createAccessCode(result.code, projectId, planId);
		})
		.then((transactionResult)=>{
			if(transactionResult.committed){ return transactionResult.snapshot; } //all good!
			else{ return this.tryToWriteAccessCode(projectId, planId, count ? count+1 : 1); } //sketchy af
		});
	}
	
	//TODO - think of the access code plan index. What is preventing the "multiple codes same plan thing". Reread 
	//TODO - also need to multi-client test this + harden it up with transactions
	tryToCreate(projectId, planId){
		if(!projectId){projectId = "-KiaWAvlGwLagS8ABhAc";}
		if(!planId){planId = "-KjOXcJLvFkc_MFsp5V1";}
		
		var planSnap;
		
		//check the plan and potentially clear out the old accessCode
		return this.getPlanStuff(projectId, planId).then((snap)=>{
			planSnap = snap;
			if(planSnap.child('accessCode').exists()){
				var p = [];
				var code = planSnap.child('accessCode').val();
				var oldCodeRef = this.fbConnection.fbRef(this.getCodePath(code));
				p.push(oldCodeRef.remove());
				p.push(planSnap.child('rallyMembers').ref.remove());
				
				return this.$q.all(p);
			}
			else{return;}
		})
		//have removed stale pin if it exists, return value isn't interesting
		.then(()=>{
			return this.tryToWriteAccessCode(projectId, planId)
		})
		.then((accessCodeSnap)=>{
			if(accessCodeSnap && accessCodeSnap.exists()){
				analyticsService.rallyPointAccessCodeGenerated(accessCodeSnap.key);
				return planSnap.ref.child('accessCode').set(accessCodeSnap.key);
			}
		})
		.then(function(){
			console.log('accessCode finally created');
		})
		.catch(function(e){
			console.log('err', e);
		});
	}
	
	//tryToCreate();
	
	//when adding
	//- check /plans for pin data, if it's there, use the pinId to clear out /accessCodes
	//- create a new code
	//- populate access code data on plan
	
	getPlanStuff(projectId, planId){
		var ref = this.fbConnection.fbRef('plans').child(projectId).child(planId);
		return this.$q.when(ref.once('value'));
	}
	//for(var i = 0; i < 100; i++){ tryToCreate(); }
	
	
	generateCode(){
		//v0: 77777
		//return 77777;
		
		//v1: random 6 digit number
		var num = Math.floor(Math.random() * 99999);
		return utils.pad(num, 6);
		
		//v1.5: narrowed keyspace for testing purposes
		// var num = Math.floor(Math.random() * 9);
		// return pad(num, 1)
		
	}
	
	addToRallyMembers(projectId, planId, userId, pmId){
		if(!projectId && !planId){ return this.$q.rejectErr("addToPinMembers - projectId/ planId are requried");}
		
		//do this silly thing to get your pmId or bypass the async stuff if you have it
		return (()=>{
			if(pmId){ return this.$q.when(pmId); }
			else{
				this.firebaseIndices.projectMember(projectId, userId).then((pmIdx)=>{
					console.log('pmIdx', pmIdx);
					if(pmIdx.$value !== null){ return pmIdx.$value; }
					else{ return this.$q.rejectErr("project member index does not exist"); }
				});
			}
		})()
		.then((pmId)=>{
			var planPath = this.fbConnection.fbRef("plans").child(projectId).child(planId).child("rallyMembers").child(pmId);
			return planPath.set(pmId);
		});
	}
	
}

angular.module('ticketspaceApp').service('rallyPointService', RallyPointService);