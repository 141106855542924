import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseList, AngularLandConnection, FancyJoinRequirements } from 'ng2/fancy-firebase/base';

import { Variance } from "ng2/common/models/Variance";


export class VarianceList extends FancyFirebaseList<Variance, FancyAction>{
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		return new Variance(action.payload.val());
	}
	$$updated(child:Variance, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.update(action.payload.val());
	}
}
