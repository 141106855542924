angular.module('CanvasWrapper').factory("LayerManager", LayerManagerFactory);

LayerManagerFactory.$inject = ["Layer"];
function LayerManagerFactory(Layer){
	/**
	 * @namespace LayerManager
	 * @returns {CanvasWrapper.LayerManager}
	 * @memberOf CanvasWrapper
	 * @constructor
	 */
	var LayerManager = function(layerList){
		var self = this;
		this.add = function(name){
			return this.layers.push(new Layer(name))
		};

		this.setVisible = function(layerName, value){
			return this.getLayerByName(layerName).visible = value;
		};

		this.addFromList = function(list){
			list.forEach(function(layer){
				self.layers.push(new Layer(layer))
			});
			return this.layers;
		};

		this.getLayerByName = function(name){
			for (var i = 0; i < this.layers.length; i++){
				if ( this.layers[i].getName() === name){
					return this.layers[i].get()
				}
			}
			return false;
		};

		this.addChild = function(name, child){
			return this.getLayerByName(name).addChild(child);
		};

		this.getLayerChildren = function(name){
			return this.getLayerByName(name).getChildren();
		};

		this.cullChildren = function(name, begin, end){
			return this.getLayerByName(name).removeChildren(begin, end);
		};

		this.clearAll = function(){
			this.layers.forEach(function(layer){
				layer.cullChildren();
			})
		};

		this.get = function(name){
			return this.getLayerByName(name).get();
		};
		this.layers = [];
		if (layerList){this.addFromList(layerList)}
	};

	return LayerManager
}
