'use strict';
import * as angular from "angular";


angular.module('ticketspaceApp')
// Remove $q
.factory('firebaseIndices', ["fbConnection", function(fbConnection) {
	
	var uPath = 'indices/users/';
	
	
	//returns array or projectId
	function projectAccess(projectId, userId){
		var url = uPath + userId + '/projectAccess/';
		if(projectId){
			return fbConnection.loadObject(url + projectId);
		}
		else{
			return fbConnection.loadArray(url);
		}
	}
	
	function wrapPathAccess(projectId, userId){
		return uPath + userId + '/projectAccess/'+projectId+'/';
	}
	function setProjectAccess(projectId, userId){
		var fb = fbConnection.fbRef(wrapPathAccess(projectId, userId));
		return fbConnection.set(fb, projectId);
	}
	function removeProjectAccess(projectId, userId){
		var fb = fbConnection.fbRef(wrapPathAccess(projectId, userId));
		return fbConnection.remove(fb);
	}
	
	function wrapPathPM(projectId, userId){
		return uPath + userId + '/projectMember/' + projectId + '/projectMemberId/';
	}
	
	function projectMember(projectId, userId){
		return fbConnection.loadObject(wrapPathPM(projectId, userId));
	}
	
	//callback will be executed outside of the digest loop,
	//may or may not be an issue
	function setProjectMember(projectId, userId, pmId){
		var fb = fbConnection.fbRef(wrapPathPM(projectId, userId));
		return fbConnection.set(fb, pmId);
	}
	function removeProjectMember(projectId, userId){
		var fb = fbConnection.fbRef(wrapPathPM(projectId, userId));
		return fbConnection.remove(fb);
	}
	
	return {
		projectAccess: projectAccess,
		setProjectAccess: setProjectAccess,
		removeProjectAccess: removeProjectAccess,
		projectMember: projectMember,
		setProjectMember: setProjectMember,
		removeProjectMember: removeProjectMember
	};
}]);
