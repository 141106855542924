import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Location } from "@angular/common";
import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";

import * as firebase from "firebase/app";

import { User } from "ng2/common/models";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AppState } from "ng2/common/services/app-state.service";

import {titleFields} from "ng2/common/constants/title-fields";

interface UserWithUpdate extends User{
	update: (data: any)=>void
}

function isFormGroupEmpty(g: FormGroup){
	let someEmpty = false;
	for(let key in g.controls){
		let isEmpty = g.controls[key].value === null || g.controls[key].value === undefined || g.controls[key].value === "";
		someEmpty = someEmpty || isEmpty;
	}
	return someEmpty;
}

function passwordMatchValidator(field1:string, field2:string) {
	return (g: FormGroup)=>{
		return g.get(field1).value === g.get(field2).value
		? null : {'mismatch': true};
	}
}

function mustProvideCurrentValidator(g: FormGroup){
	if(g.get('newPassword').value !== "" && g.get('currentPassword').value === ""){
		return {'nocurrent': true};
	}
	return null;
}

@Component({
	selector: 'account-profile',
	template: require('./account-profile.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountProfileComponent implements OnInit, OnDestroy {
	private shutdownSubject = new Subject();
	public user:UserWithUpdate;
	public titleFields = titleFields;
	
	userForm = this.formBuilder.group({
		userData: this.formBuilder.group({
			email: [''],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			company: ['', Validators.required],
			phone: ['', Validators.required],
			title: ['', Validators.required],
			zip: ['', Validators.required],
		}),
		passwords: this.formBuilder.group({
			currentPassword: [''],
			newPassword: ['', Validators.minLength(6)],
			newPassword2: ['', Validators.minLength(6)],
		}, {validator: Validators.compose([
			passwordMatchValidator("newPassword", "newPassword2"),
			mustProvideCurrentValidator
		])}),
	});

	constructor(private location: Location, private formBuilder: FormBuilder, public appState: AppState) {}
	
	
	onSubmit(){
		console.log('form', this.userForm.value);
		
		if(!this.user){ console.log('user not defined on submit'); return; }
		
		//do the password stuff
		if(!isFormGroupEmpty(this.userForm.get('passwords') as FormGroup)){
			console.log('do password thing');
			let group = this.userForm.get('passwords') as FormGroup;
			if(group.valid){
				let credential = firebase.auth.EmailAuthProvider.credential(
					this.user.email,
					group.get("currentPassword").value
				);
				let authUser = firebase.auth().currentUser;
				authUser.reauthenticateAndRetrieveDataWithCredential (credential).then(()=>{
					return authUser.updatePassword(group.get('newPassword').value);
				})
				.then(u => console.log('password thing done', u))
				.catch(e => console.log(e))
			}
			Logging.notice('password changed');
		}
		else{
			this.user.update(this.userForm.get("userData").value);
			Logging.notice('user data updated');
		}
	}


	get passwords(){
		return this.userForm.get("passwords");
	}
	
	goBack(){
		this.location.back();
	}
	
	ngOnInit() {
		this.appState.user.pipe(takeUntil(this.shutdownSubject)).subscribe(user => {
			if(!user){return;}
			//todo, implement the password thing
			// - figure out group empty validation
			// - do the firebase set password thing
			// - do the revalidate credentials thing
			

			
			this.user = user as any;
			this.userForm.get("userData").patchValue(user);
		});
	}
	
	ngOnDestroy(){
		this.shutdownSubject.next(true);
	}

}
