import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'

import { FancyAction, FancyFirebaseList, AngularLandConnection } from 'ng2/fancy-firebase/base';
import { ProjectMember } from "ng2/common/models/ProjectMember";

export class ProjectMemberList extends FancyFirebaseList<ProjectMember, FancyAction>{
	
	constructor(angularLand:AngularLandConnection, path:any, fancyJoins?: any){ //fix any's
		super(angularLand, path, FancyAction, fancyJoins);
	}
	
	$$added(action: AngularFireAction<DatabaseSnapshot<any>>){
		return Object.assign({prevData: {}}, action.payload.val());
	}
	$$updated(child:ProjectMember, action: AngularFireAction<DatabaseSnapshot<any>>){
		child.prevData.roleId = child.roleId;
		Object.assign(child, action.payload.val());
	}
	
	//put in interface so you can explicitly say you're doing this
	applyJoinData(baseKey: string, child, otherChild:any){
		switch(baseKey){
			case "roleId":
				child.role = otherChild ? otherChild: null;
				break;
			case "userId":
				child.user = otherChild ? otherChild: null;
				break;
			default: break;
		}
	}
	getDataForKey(baseKey: string, child){
		return child[baseKey];
	}
	getPrevDataForKey(baseKey: string, child){
		return child.prevData[baseKey];
	}
}
