//screwing around
// try to prove its loading by loading a component
//https://pr18487-aedf0aa.ngbuilds.io/guide/upgrade#using-angular-components-from-angularjs-code

import * as firebase from "firebase/app";

import { NgZone, NgModule, Injectable, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from "@angular/forms";
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import {DropdownModule} from 'primeng/primeng';

import { AppRoutingModule } from "ng2/routes/app-routing.module";
import { AppComponent } from "ng2/routes/app.component";
import { EmptyComponent } from "ng2/routes/empty.component";
import { LoadingComponent } from "ng2/global-components/loading-component/loading.component";

import { RallyPointModule } from "ng2/routes/rally-point";

import { UtilityComponentsModule } from "ng2/common/components";

import { FancyFirebaseModule } from 'ng2/fancy-firebase/fancy-firebase.module';
import { TicketEditModule } from 'ng2/ticket-edit/ticket-edit.module'

import { ReportService } from 'ng2/common/services/report.service';
import { PlanState } from 'ng2/common/services/plan-state.service';
import { AppState } from 'ng2/common/services/app-state.service';
import { RouteUtils } from 'ng2/common/services/route-utils.service';
import { ProjectNotifier } from 'ng2/common/services/project-notifier.service';
import {
	PlanComponent,
	ProjectDashboardComponent,
	AccountComponent,
	AccountProfileComponent,
	SignupComponent,
	LoginComponent,
	ResetPasswordComponent,
	HarnessComponent,
	ReportComponent,
	TestComponent
} from 'ng2/routes';
import { 
	PlanControllerUpgradeDirective, 
	ProjectControllerUpgradeDirective,
	SignupControllerUpgradeDirective,
	LoginControllerUpgradeDirective,
	ResetPasswordControllerUpgradeDirective,
	TestControllerUpgradeDirective
} from 'js/components';

import { FancyActionTrackerFactory } from "ng2/common/services/fancy-action-tracker-factory.service";

import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { DropdownSelectModule } from "../ng2/dropdown-select/dropdown-select.module";
import { CanvasViewModule } from "../ng2/canvas-view/canvas-view.module";

import { UpgradeShorthandModule } from "ng2/plan-components/upgrade-shorthand.module";
import { PlanModule } from "ng2/plan-components/plan.module";

import environment from '../ng2/common/environment';
import { AjsUpgradeModule } from '../ng2/common/ajs-upgrade-providers';

import {InitEarly} from "../ng2/InitEarly";
import {AnotherErrorHandler} from "../ng2/common/services/AnotherErrorHandler";

import {MultiSelectMenuComponent} from 'ng2/multi-select-menu.component';
import { ColorPickerModule } from 'ng2/color-picker/color-picker.module';
import { IntercomService } from "../ng2/common/services/intercom.service";

@Injectable()
class ZoneThing{
	constructor(private zone: NgZone){
		// console.log('init-zone');
		this.zone.onError.subscribe((e)=>{
			// console.log('hi zone', e);
		})
	}
}


//the angular base
@NgModule({
	// providers: [TicketEditService],
	imports: [
		BrowserModule,
		UpgradeModule,
		HttpClientModule,
		ReactiveFormsModule,
		DropdownModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		DropdownSelectModule,
		FancyFirebaseModule,
		TicketEditModule,
		CanvasViewModule,
		AjsUpgradeModule,
		UpgradeShorthandModule,
		PlanModule,
		AppRoutingModule,
		ColorPickerModule,
		UtilityComponentsModule,
		RallyPointModule
	],
	declarations: [
		AppComponent,
		EmptyComponent,
		PlanComponent,
		ProjectDashboardComponent,
		AccountComponent,
		AccountProfileComponent,
		SignupComponent,
		LoginComponent,
		ResetPasswordComponent,
		HarnessComponent,
		ReportComponent,
		TestComponent,
		LoadingComponent,
		MultiSelectMenuComponent,
		//angular upgrades
		PlanControllerUpgradeDirective,
		ProjectControllerUpgradeDirective,
		SignupControllerUpgradeDirective,
		LoginControllerUpgradeDirective,
		ResetPasswordControllerUpgradeDirective,
		TestControllerUpgradeDirective
	],
	entryComponents: [
		AppComponent,
		LoadingComponent,
		EmptyComponent,
		// PlanComponent,
		MultiSelectMenuComponent
	],
	providers: [
		PlanState,
		AppState,
		RouteUtils,
		ProjectNotifier,
		ReportService,
		FancyActionTrackerFactory,
		AnotherErrorHandler,
		{provide: ErrorHandler, useClass: AnotherErrorHandler},
		ZoneThing,
		IntercomService
	],
	// bootstrap: [
	// 	AppComponent
	// ]
})
export default class AppModule {
	constructor(private upgrade: UpgradeModule, private initEarly: InitEarly) {
		initEarly.initFirebase();
	}
	ngDoBootstrap() {
		
		//hack to make angularfire1 not make a mess
		window.angularZone = Zone.current;

		this.upgrade.bootstrap(document.body, ['ticketspaceApp'], { strictDi: true });
	}
}
