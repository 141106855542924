'use strict';

import * as angular from "angular";
import * as moment from "moment";
import { analyticsService } from "ng2/common/utils/AnalyticsService";
import {ImprovedText} from "../renderer/text";

import { BehaviorSubject } from "rxjs";

angular.module('ProjectView').factory("ProjectViewCore", ProjectViewCore);
ProjectViewCore.$inject = ["PIXI", "LayerManager", "Guidelines", "PlanBar", "Renderer", "GeometricShape", "RenderLine", "LineOptions", "projectViewConfig", "projectViewUtils", "projectViewDateSync", "Timeline", "TimelineOptions", "ProjectViewApi"];
function ProjectViewCore(PIXI, LayerManager, Guidelines, PlanBar, Renderer, GeometricShape, RenderLine, LineOptions,
                         projectViewConfig, projectViewUtils, projectViewDateSync, Timeline, TimelineOptions, ProjectViewApi){
	/**
	 * @namespace ProjectViewCore
	 * @desc The core class which instantiates the project view window.
	 * @param canvas {HTMLCanvasElement|string} The html canvas element or the ID of the canvas element
	 * @param options {Object} The initialization options for the project view render
	 * @param options.resolution {Number} The resolution of the Overview renderer window
	 * @param options.name {String} The human readable unique identifier. Used with the focusManager.
	 * @param options.width {Number} The initial width of the Overview window
	 * @param options.height {Number} The initial height of the overview window
	 * @param options.plans {ticketspaceApp.Plans} Reference to the plans fbObject data (MCScope.plans)
	 * @param [features] {Object} An object containing the dependency data required to activate certain Overview features
	 * @param [features.hud] {ticketspaceApp.Hud} An instance of the MCScope.hud. Required for Guidelines and DateSync.
	 * @param [features.timeline] {ticketspaceApp.Timeline} An instance of the plan view timeline MCScope.timeline. Required for DateSync
	 * @param [features.pullColumns] {ticketspaceApp.PullColumns} An instance of the plan view pullColumns MCScope.pullColumns. Required for DateSync
	 * @param [features.milestone] {ticketspaceApp.Milestone} An instance of the plan view milestone MCScope.milestone. Required for DateSync
	 * @param [features.activeLine] {ticketspaceApp.ActiveLine} An instance of the plan view active line MCScope.activeLine. Required for DateSync.
	 * @param [features.today] {ticketspaceApp.Today} An instance of the plan view today line MCScope.today. Required for DateSync.
	 * @param [features.plan] {ticketspaceApp.Plan} An instance of the current plan we are in.
	 * @returns {ProjectViewCore}
	 * @memberOf ProjectView
	 * @constructor
	 */
	var ProjectView = function(canvas, options, features){
		if (!options || !options.plans){throw "options parameter must have a 'plans' key!"}
		this.running$ = new BehaviorSubject(false);
		/**
		 * @name initialOptions
		 * @desc Contains the initialization options for the Overview window.
		 * @type {{resolution, name, width, height, plans}}
		 * @memberOf ProjectView.ProjectViewCore
		 */
		this.initialOptions = options;
		/**
		 * @name initialFeatures
		 * @desc Contains the initialization features for the Overview window.
		 * @type {Object}
		 * @memberOf ProjectView.ProjectViewCore
		 */
		this.initialFeatures = features;
		/**
		 * @name enabledFeatures
		 * @desc An object which contains a list of supported features, and if they are enabled or not.
		 * @type {{guideLines: boolean, dateSync: boolean, timeline: boolean}}
		 * @memberOf ProjectView.ProjectViewCore
		 */
		this.enabledFeatures = {
			guideLines: false,
			dateSync: false,
			inPlan: false,
			timeline: true
		};
		/**
		 * @name resolution
		 * @desc Sets the Overview render resolution
		 * @memberOf ProjectView.ProjectViewCore
		 * @type {Number}
		 */
		this.resolution = options.resolution;
		/**
		 * @name layerManager
		 * @desc Local instance of the LayerManager class. Controls all instances of {Layer}
		 * @memberOf ProjectView.ProjectViewCore
		 * @type CanvasWrapper.LayerManager
		 */
		this.layerManager = new LayerManager([
			'underlay',
			'lines',
			'details',
			'main',
			'surface',
			'timeline',
			'timelineBackground',
			'timelineLines',
			'timelineText'
		]);
		options['backgroundColor'] = projectViewConfig.CORE.BACKGROUND_COLOR;
		options["roundPixels"] = projectViewConfig.CORE.ROUND_PIXELS;
		options["antialias"] = projectViewConfig.CORE.ANTI_ALIAS;
		options["transparent"] = projectViewConfig.CORE.TRANSPARENT_RENDER;
		options["preserveDrawingBuffer"] = projectViewConfig.CORE.PRESERVE_DRAWING_BUFFER;
		options["forceCanvas"] = projectViewConfig.CORE.FORCE_CANVAS;
		options["forceFXAA"] = projectViewConfig.CORE.FORCE_FXAA;
		options["clearBeforeRender"] = projectViewConfig.CORE.CLEAR_BEFORE_RENDER;
		options["legacy"] = projectViewConfig.CORE.LEGACY_MODE;
		var viewportOptions = {
			zoomStyle: projectViewConfig.VIEWPORT.ZOOM_METHOD,
			zoomSmoothness: projectViewConfig.VIEWPORT.ZOOM_SMOOTHNESS,
			zoomPower: projectViewConfig.VIEWPORT.ZOOM_INTENSITY,
			pinchPower: projectViewConfig.VIEWPORT.ZOOM_INTENSITY_PINCH,
			zoomMax: projectViewConfig.VIEWPORT.MAX_ZOOM,
			zoomMin: 1,
			panLayers: [this.layerManager.getLayerByName('main')],
			zoomLayers: [this.layerManager.getLayerByName('main')],
			view: this.view,
			renderer: this.renderer,
			name: options.name || 'projectView',
			canPan: true,
			resolution: this.resolution
		};
		/**
		 * @name app
		 * @desc Stores an instance of the {CanvasWrapper.Renderer}
		 * @memberOf ProjectView.ProjectViewCore
		 * @type CanvasWrapper.Renderer
		 */
		this.app = new Renderer(canvas, options, viewportOptions);
		/**
		 * @name renderer
		 * @desc Stores and instance of the main renderer class from {CanvasWrapper.Renderer}
		 * @memberOf ProjectView.ProjectViewCore
		 * @private
		 */
		
		this.start = function start(){
			analyticsService.projectViewOpened();
			this.running = true;
			this.running$.next(true);
			return this.app.start();
		}
		this.stop = function stop(){
			this.running = false;
			// console.log('running end', this.running);
			this.running$.next(false);
			return this.app.stop();
		}
		
		this.renderer = this.app.renderer;
		/**
		 * @name stage
		 * @desc A reference to the main drawing stage node
		 * @memberOf ProjectView.ProjectViewCore
		 * @private
		 * @type {*|a.default|null|string|string|string}
		 */
		this.stage = this.app.stage;
		/**
		 * @name view
		 * @desc The currently bound {HTMLCanvasElement}
		 * @memberOf ProjectView.ProjectViewCore
		 * @private
		 */
		this.view = this.app.view;
		/**
		 * @name ticker
		 * @desc The main renderer ticker.
		 * @memberOf ProjectView.ProjectViewCore
		 * @type {*|null}
		 * @private
		 */
		this.ticker = this.app.ticker;
		/**
		 * @name viewport
		 * @desc Reference to the {CanvasWrapper.Viewport} camera
		 * @memberOf ProjectView.ProjectViewCore
		 * @type CanvasWrapper.Viewport
		 * @private
		 */
		this.viewport = this.app.viewport;
		/**
		 * @name heatmaps
		 * @desc Contains a list of {ProjectView.PlanBar}
		 * @memberOf ProjectView.ProjectViewCore
		 * @type {Array}
		 */
		this.heatmaps = [];
		/**
		 * @name heatmapIndice
		 * @desc An index of plan bars.
		 * @memberOf ProjectView.ProjectViewCore
		 * @private
		 * @type ProjectView.PlanBar
		 */
		this.heatmapIndice = {};
		/**
		 * @name dividerLineTop
		 * @desc An array storing the {CanvasWrapper.Line} instances to draw dividing lines.
		 * @memberOf ProjectView.ProjectViewCore
		 * @private
		 * @type {Array}
		 */
		this.dividerLineTop = [];
		/**
		 * @name dividerLineBottom
		 * @desc An array storing the {CanvasWrapper.Line} instances to draw dividing lines
		 * @private
		 * @memberOf ProjectView.ProjectViewCore
		 * @type {Array}
		 */
		this.dividerLineBottom = [];

		// Determine which features are enabled
		this._setEnabledFeatures(features);
		// Register all watchers.
		this.registerWatchers();

		// Generate the plan bars based on options.plans.
		this.generate();

		// Load the Guideline module if the feature is supported based on provided data from the directive.
		this.loadGuidelines();
		// Load the Timeline module if the feature is supported based on provided data from the directive.
		this.loadTimeline();

		var self = this;
		if (this.enabledFeatures.guideLines){
			this.running$.subscribe(()=>{
				self.guideLines.update(projectViewDateSync.getDateRange(), projectViewConfig.CORE.HORIZONAL_SCALE, self.view.height);
			});
			this.initialFeatures.hud.observers.push(()=> {
				if(!this.running){return;}
				self.guideLines.update(projectViewDateSync.getDateRange(), projectViewConfig.CORE.HORIZONAL_SCALE, self.view.height);
			});
		}

		// Setup draw order
		this.setupStage();

		// Set scale
		this.layerManager.getLayerByName('main').scale.set(1, 1);

		// Pointer controls
		this.stage.interactive = true;
		this.mouseDown = false;

		// Mouse state.
		this.mouseDown = false;

		this.setupListeners();

		// Set initial zoom clamp
		var rect = this._buildRect();
		var leftPoint = rect.leftPoint;
		var rightPoint = rect.rightPoint;
		var planWidth = rightPoint.x - leftPoint.x;
		var scale = Math.min(this.view.width / planWidth, (this.view.height - 100) / (this._planBarHeight));
		if (scale >= 1) {
			scale = 1;
		}
		this.viewport.setMinZoom(scale);
	};
	/**
	 * @function loadGuidelines
	 * @desc If the feature is enabled, then the guideline module will be loaded
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.loadGuidelines = function(){
		if (this.enabledFeatures.guideLines){
			/**
			 * @name guideLines
			 * @desc Instance of {ProjectView.GuideLines}. Only created if {ProjectViewCore.enabledFeatures.guideLines} is true.
			 * @type {ProjectView.GuideLines}
			 * @memberOf ProjectView.ProjectViewCore
			 */
			this.guideLines = new Guidelines({
				x: 0,
				y: 0,
				width: 20,
				height: this.view.height,
				lineStyle: projectViewConfig.GUIDE_LINES.LINE_COLOR,
				fillStyle: projectViewConfig.GUIDE_LINES.FILL_COLOR,
				lineAlpha: projectViewConfig.GUIDE_LINES.LINE_ALPHA,
				fillAlpha: projectViewConfig.GUIDE_LINES.FILL_ALPHA,
				lineSize: projectViewConfig.GUIDE_LINES.LINE_SIZE,
				parent: this,
				scale: projectViewConfig.CORE.HORIZONAL_SCALE
			});

			// Do first cycle update of guidelines
			this.guideLines.update(projectViewDateSync.getDateRange(), projectViewConfig.CORE.HORIZONAL_SCALE, this.view.height);
			// Add the guidelines to the lines child layer
			this.layerManager.getLayerByName('lines').addChild(this.guideLines.get());
		}
	};
	/**
	 * @function loadTimeline
	 * @desc If the feature is enabled, then the timeline module will be loaded
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.loadTimeline = function(){
		if (this.enabledFeatures.timeline){
			/**
			 * @name timeline
			 * @desc Instance of {ProjectView.Timeline}. Only created if {ProjectViewCore.enabledFeatures.timeline} is true.
			 * @type ProjectView.ProjectViewTimeline
			 * @memberOf ProjectView.ProjectViewCore
			 */
			this.timeline = new Timeline(
				this.layerManager.getLayerByName('timelineBackground'),
				this.layerManager.getLayerByName('timelineLines'),
				this.layerManager.getLayerByName('timelineText'),
				this.viewport,
				TimelineOptions(projectViewConfig.TIMELINE),
				LineOptions(projectViewConfig.TIMELINE_LINES)
			);
		}
	};
	/**
	 * @function _setEnabledFeatures
	 * @desc Checks the data provided in the 3rd argument of the ProjectView constructor and determines if we can enable a feature based on that
	 * @param features {Object} An object where the keys are the bound data from the ProjectViewDirective
	 * @memberOf ProjectView.ProjectViewCore
	 * @private
	 */
	ProjectView.prototype._setEnabledFeatures = function(features){
		features = features || {};
		if (!features.timeline || !features.hud || !features.pullColumns || !features.milestone || !features.activeLine || !features.today){
			this.enabledFeatures.guideLines = false;
			this.enabledFeatures.dateSync = false
		} else{
			this.enabledFeatures.guideLines = true;
			this.enabledFeatures.dateSync = true;
		}
		if (!features.plan){this.enabledFeatures.inPlan = false;}
		else{this.enabledFeatures.inPlan = true;}
	};
	/**
	 * @function centerTodayLine
	 * @desc Centers the {CanvasWrapper.Viewport} so it is focused on the {ProjectView.ProjectViewCore.todayLine}
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.centerTodayLine = function(){
		var pos = {
			x: this.view.width /2,
			y: this.layerManager.getLayerByName('main').y
		};
		this.viewport.setPosition(pos, this.viewport.getScale());
	};
	/**
	 * @function resize
	 * @desc Used to resize the Overview renderer.
	 * @param w {Number} The width to resize the {CanvasWrapper.Renderer}
	 * @param h {Number} The height to resize the {CanvasWrapper.Renderer}
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.resize = function(w, h){
		this.renderer.resize(w, h);
		this.paint(w, h);
		this.centerGuidelines();
	};
	/**
	 * @function paint
	 * @desc The main function which performs all drawing operations. Call it when you want to re-paint the renderer
	 * @param w {Number} The width of the draw area. Used to properly draw divider lines.
	 * @param h {Number} The height of the draw area. Used to properly draw vertical lines.
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.paint = function(w, h){
		this.dividerLineTop.forEach(function(line){
			line.draw(null, null, w, null);
		});

		this.dividerLineBottom.forEach(function(line){
			line.draw(null, null, w, null);
		});
		if (this.enabledFeatures.inPlan){
			this.opened.get().clear();
			this.opened.get().beginFill(parseInt(projectViewConfig.PLAN_BARS.SELECT_COLOR.substring(1), 16), projectViewConfig.PLAN_BARS.SELECT_ALPHA);
			this.opened.get().drawRect(0, -12, w, projectViewConfig.PLAN_BARS.INITIAL_HEIGHT + 20);
			this.opened.get().endFill();
		}

		if (this.enabledFeatures.dateSync){projectViewDateSync.update();}
		if (this.enabledFeatures.guideLines){this.guideLines.update(projectViewDateSync.getDateRange(), projectViewConfig.CORE.HORIZONAL_SCALE, h);}
		if (this.enabledFeatures.timeline){this.updateTimeline();}
	};
	/**
	 * @function registerWaters
	 * @desc Called during set-up. Finds all listeners
	 * @memberOf ProjectView.ProjectViewCore
	 * @private
	 */
	ProjectView.prototype.registerWatchers = function(){
		var self = this;
		var unwatchPlans = this.initialOptions.plans.$watch(this.onPlanUpdate.bind(this));
		if (this.enabledFeatures.dateSync){
			var unwatchMilestone = this.initialFeatures.milestone.$watch(this.onMilestoneUpdate.bind(this));
			projectViewDateSync.activate(this.initialFeatures.hud, this.initialFeatures.timeline, this.initialFeatures.pullColumns, this.initialFeatures.milestone, this.initialFeatures.activeLine, this.initialFeatures.today, this.running$);
		}
		// Setup destroyer
		// console.log(this.initialOptions.plans.$on);
		// this.destroyWatcher = this.initalOptions.plans.$on('$destroy', function(){
		// 	unwatchPlans();
		// 	if (unwatchMilestone){unwatchMilestone();}
		// 	self.destroy();
		// });
	};
	/**
	 * @function initPlans
	 * @desc When called, it will collect relevant plan info from the plansArray and use that to generate the planBars
	 * @memberOf ProjectView.ProjectViewCore
	 * @returns {{}}
	 */
	ProjectView.prototype.initPlans = function(){
		var planBars = {};
		this.initialOptions.plans.forEach(function(plan, idx) {
			var startDate = projectViewUtils.getStartDate(plan);
			var finishDate = projectViewUtils.getFinishDate(plan);
			planBars[plan.$id] = {
				planId: plan.$id,
				planName: plan.name || plan.id,
				planIdx: idx,
				startDate: startDate,
				finishDate: finishDate
			};
		});
		return planBars
	};
	/**
	 * @function destroy
	 * @desc Destroys all data and cleans out all generated textures from VRAM
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.destroy = function(){
		if (!this.app){return}
		// this.scope.ctrl.selectedHeatmap = null;
		this.app.destroy(false, {children: false, texture: false, baseTexture: false});
		this.viewport.emitter.removeAllListeners();
		this.app = null;
		//this.destroyWatcher();
	};
	/**
	 * @function _onZoom
	 * @desc Called every time a zoom event is dispatched
	 * @param event {Event} The event data for the zoom
	 * @memberOf ProjectView.ProjectViewCore
	 * @private
	 */
	ProjectView.prototype._onZoom = function(event){
		var pointer = event.pointer;
		var self = this;
		var layer;
		['underlay', 'timelineLines'].forEach(function(layerName){
			var layer = self.layerManager.getLayerByName(layerName);
			layer.scale.x *= event.factor;
			layer.x -= (pointer.x - layer.x) * (event.factor - 1);
		});

		layer = this.layerManager.getLayerByName('timelineText');
		layer.x -= (pointer.x - layer.x) * (event.factor - 1);

		layer = this.layerManager.getLayerByName('details');
		layer.scale.y *= event.factor;
		layer.y -= (pointer.y - layer.y) * (event.factor - 1);

		this.drawTimeline();
	};
	/**
	 * @function _onPan
	 * @desc Called every time the pan event is dispatched
	 * @param eventData
	 * @memberOf ProjectView.ProjectViewCore
	 * @private
	 */
	ProjectView.prototype._onPan = function(eventData){
		// Align the underlay layer
		var layer = this.layerManager.getLayerByName('underlay');
		layer.x = layer.x + eventData.deltaX;

		layer = this.layerManager.getLayerByName('timelineLines');
		layer.x = layer.x + eventData.deltaX;

		layer = this.layerManager.getLayerByName('timelineText');
		layer.x = layer.x + eventData.deltaX;

		// Align the details layer
		layer = this.layerManager.getLayerByName('details');
		layer.y = layer.y + eventData.deltaY;

		this.drawTimeline();
	};

	/**
	 * @function _onSetPosition
	 * @desc Called every time the set position event is dispatched
	 * @memberOf ProjectView.ProjectViewCore
	 * @param event
	 * @private
	 */
	ProjectView.prototype._onSetPosition = function(event){
		// Position was set. Make sure the details and underlay positions are updated.
		var layer = this.layerManager.getLayerByName('underlay');
		layer.x = event.pos.x;
		layer.scale.x = event.factor;

		layer = this.layerManager.getLayerByName('timelineLines');
		layer.x = event.pos.x;
		layer.scale.x = event.factor;

		layer = this.layerManager.getLayerByName('timelineText');
		layer.x = event.pos.x;

		layer = this.layerManager.getLayerByName('details');
		layer.y = event.pos.y;
		layer.scale.y = event.factor;
		this.updateTimeline();
		this.drawTimeline();
	};

	/**
	 * @function setupListeners
	 * @desc Called on start-up. Binds events to the {CanvasWrapper.Viewport} and the {CanvasRenderer.Renderer}
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.setupListeners = function(){
		var self = this;
		this.viewport.emitter.on('zoom', this._onZoom.bind(this));
		this.viewport.emitter.on('pan', this._onPan.bind(this));
		this.viewport.emitter.on('setPosition', this._onSetPosition.bind(this));

		// Font hack: Update font every 250ms for 10 seconds.
		var startTime = new Date().getTime();
		console.log("-- commenting out to test performance");
		// var interval = setInterval(function(){
		// 	if(new Date().getTime() - startTime > 10000){
		// 		clearInterval(interval);
		// 		return;
		// 	}
		// 	self.heatmaps.forEach(function(bar){
		// 		bar.updatePlanDates(true);
		// 		bar.updatePlanName(true);
		// 		bar.updateScheduleText();
		// 	})
		// }, 250);
	};
	/**
	 * @function setTimelineVisibility
	 * @desc Toggles the Overview timeline visibility
	 * @param value {Boolean} Sets the visibility of the timeline
	 * @memberOf ProjectViewCore
	 */
	ProjectView.prototype.setTimelineVisibility = function(value){
		this.layerManager.getLayerByName('timeline').visible = value;
	};
	/**
	 * @function setVerticalGuideVisibility
	 * @desc Toggles the Overview vertical guide visibility
	 * @param value {Boolean} Sets the visibility of the vertical guides
	 * @memberOf ProjectViewCore
	 */
	ProjectView.prototype.setVerticalGuideVisibility = function(value){
		this.layerManager.getLayerByName('timelineLines').visible = value;
	};
	/**
	 * @function setupStage
	 * @desc Called on start-up, adds the {CanvasWrapper.Layer} from {CanvasWrapper.LayerManager} to the {ProjectView.ProjectViewCore.stage}
	 * @memberOf ProjectView.ProjectViewCore
	 * @private
	 */
	ProjectView.prototype.setupStage = function(){
		this.layerManager.getLayerByName('underlay').addChild(this.layerManager.getLayerByName('lines'));
		this.layerManager.getLayerByName('timeline').addChild(this.layerManager.getLayerByName('timelineBackground'));
		this.layerManager.getLayerByName('timeline').addChild(this.layerManager.getLayerByName('timelineText'));
		// Add the layers to the main layer, in correct draw order
		this.layerManager.getLayerByName('main').addChild(this.layerManager.getLayerByName('surface'));
		// Add the renderer layer to stage
		this.stage.addChild(this.layerManager.getLayerByName('underlay'));
		this.stage.addChild(this.layerManager.getLayerByName('details'));
		this.stage.addChild(this.layerManager.getLayerByName('timelineLines'));
		this.stage.addChild(this.layerManager.getLayerByName('main'));
		this.stage.addChild(this.layerManager.getLayerByName('timeline'));
	};
	/**
	 * @function generate
	 * @desc Called on start-up. This generates all of the {ProjectView.PlanBar} required.
	 * @memberOf ProjectView.ProjectViewCore
	 * @private
	 */
	ProjectView.prototype.generate = function () {
		if (this.heatmaps.length !== 0) {this.heatmaps = [];}
		var self = this;
		var i = 0;
		var today = moment();
		var planBars = this.initPlans();
		planBars = this.sortPlans(planBars);

		for (var planId in planBars) {
			var waveform = planBars[planId];
			// Set random sizes. On production, this will be drawn relative to the heatmap data.
			var y = (projectViewConfig.PLAN_BARS.INITIAL_HEIGHT + projectViewConfig.PLAN_BARS.INITIAL_SPACING) * i;
			var plan = self.initialOptions.plans.$getRecord(waveform.planId);

			var txtOptions = projectViewConfig.PLAN_NAME_TEXT;
			txtOptions['text'] = waveform.planName;

			// Get the new drawX position
			var isEmpty = projectViewUtils.planIsEmpty(waveform.startDate, waveform.finishDate);
			var drawX, w;
			if (isEmpty){
				w = projectViewConfig.PLAN_BARS.NO_DATES_OPTIONS.DEFAULT_WIDTH_NO_DATES;
				var future = (moment(today).add(projectViewConfig.PLAN_BARS.NO_DATES_OPTIONS.DEFAULT_WIDTH_NO_DATES, 'days')).format("YYYY-MM-DD");
				drawX = projectViewUtils.calculateDrawPos(today.format("YYYY-MM-DD"), future, w);
			} else {
				w = projectViewUtils.getDayDifference(waveform.startDate, waveform.finishDate);
				drawX = projectViewUtils.calculateDrawPos(waveform.startDate, waveform.finishDate, w);
			}
			var ticketStatusDates = projectViewUtils.getTicketStatusDraw(plan);
			// Options which are passed to the constructor.
			var options = {
				x: drawX,
				y: y,
				width: w,
				height: projectViewConfig.PLAN_BARS.INITIAL_HEIGHT,
				textOptions: txtOptions,
				activeLineOptions: projectViewConfig.ACTIVE_LINE,
				heatbarColor: projectViewConfig.PLAN_BARS.BACKGROUND_COLOR,
				heatbarAlpha: projectViewConfig.PLAN_BARS.BACKGROUND_ALPHA,
				selectFill: projectViewConfig.PLAN_BARS.SELECT_COLOR,
				selectAlpha: projectViewConfig.PLAN_BARS.SELECT_ALPHA,
				scale: projectViewConfig.CORE.HORIZONAL_SCALE,
				isEmpty: isEmpty,
				planAheadBehindTextOptions: projectViewConfig.PLAN_AHEAD_BEHIND_TEXT,
				isEmptyOptions: projectViewConfig.PLAN_BARS.NO_DATES_OPTIONS,
				view: self.view,
				stage: self.layerManager.getLayerByName('main'),
				waveform: waveform,
				ticketStatusDates: ticketStatusDates,
				plan: self.initialOptions.plans.$getRecord(waveform.planId)
			};
			// Construct the heatmap and assign it to a layer
			self.heatmaps.push(new PlanBar(options, self.heatmaps.length, self.registerClick.bind(self), self.jumpToPlan));
			self.heatmapIndice[options.plan.planId] = self.heatmaps.length-1;
			self.layerManager.getLayerByName('surface').addChild(self.heatmaps[self.heatmaps.length - 1].get());

			if (self.enabledFeatures.inPlan && plan.planId === self.initialFeatures.plan.planId){
				// Create the 'is opened' indicator
				self.opened = new GeometricShape({pos:{x:0, y:options.y}});
				// Draw our select layer, cache it, and then hide the container
				self.opened.get().beginFill(parseInt(options.selectFill.substring(1), 16), options.selectAlpha);
				self.opened.get().drawRect(0, -12, options.view.width, options.height +20);
				self.opened.get().endFill();
				self.layerManager.getLayerByName('details').addChild(self.opened.get());
				self.heatmaps[self.heatmaps.length-1].isOpened = true;
			}

			// Draw divider lines
			self.drawDividerLines(options);
			i++;
		}

		this._planBarHeight = (projectViewConfig.PLAN_BARS.INITIAL_HEIGHT + projectViewConfig.PLAN_BARS.INITIAL_SPACING) * i;
	};
	/**
	 * @function jumpToPlan
	 * @desc Triggers loading of a new plan
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.jumpToPlan = function(){
		ProjectViewApi.goToPlan();
	};

	/**
	 * @function _buildRect
	 * @desc Calculates the transform rectangle used for seeAll
	 * @memberOf ProjectView.ProjectViewCore
	 * @returns {{leftPoint, rightPoint}}
	 * @private
	 */
	ProjectView.prototype._buildRect = function(){
		var self = this;
		var highestX = -99999;
		var lowestX = 999999;
		var highestPlan = null;
		self.heatmaps.forEach(function(planBar, idx){
			var x = planBar.container.x;
			if (planBar.renderType && planBar.renderType.getDrawingCoordinates){
				var dimensions = planBar.renderType.getDrawingCoordinates();
				var compareX;
				if (dimensions.incompleteWidth > 0){compareX = dimensions.incompleteX;
				} else {compareX = dimensions.completeX;}
				compareX -= 150;
				// Compare if our ticket status bar is to the left of our plan container
				if (compareX < planBar.container.x){
					var leftOffset = planBar.container.x - compareX;
					x = compareX - leftOffset;
				}
			}
			if (x > highestX){
				highestX = x;
				highestPlan = planBar;
			}
			if (x < lowestX){lowestX = x;}
		});
		var leftPoint = new PIXI.Point(lowestX, self._planBarHeight);
		var rightPoint = new PIXI.Point(highestPlan.container.x + highestPlan.container.width, self._planBarHeight);
		return {
			leftPoint: leftPoint,
			rightPoint: rightPoint
		}
	};
	/**
	 * @function seeAll
	 * @desc When called, will position the {ProjectView.ProjectViewCore.viewport} so all {ProjectView.ProjectViewCore.heatmaps} are visible
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.seeAll = function(){
		var rect = this._buildRect();
		var leftPoint = rect.leftPoint;
		var rightPoint = rect.rightPoint;
		var planWidth = rightPoint.x - leftPoint.x;
		var scale = Math.min(this.view.width/planWidth, (this.view.height-30)/(this._planBarHeight));
		var centerPoint = this.stage.toGlobal(new PIXI.Point(this.view.width/2, 0));
		var centerX = centerPoint.x;
		if (centerX < 0){ centerX *= -1;}
		if (scale >= 1){scale = 1;}
		if (scale <= this.viewport.zoom.min){this.viewport.setMinZoom(scale);}
		this.setPosition(centerX, 30, scale);
	};
	/**
	 * @function centerGuidelines
	 * @desc When called, will center the {ProjectView.ProjectViewCore.viewport} to the center of the {ProjectView.GuideLines}
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.centerGuidelines = function(){
		if (!this.enabledFeatures.guideLines){this.seeAll();return}
		if (this.guideLines.size.width <= 0 || this.guideLines.size.height <= 0){return}
		var centerPoint = this.stage.toGlobal(new PIXI.Point((this.guideLines.pos.x + (this.guideLines.size.width/2)), this.viewport.pos.y));
		var z = this.viewport.getScale();
		var x = centerPoint.x - (this.view.width / z)/2;
		this.setPosition(-x*z, centerPoint.y, z);
	};
	/**
	 * @function focusOnPlan
	 * @desc Used to focus the {ProjectView.ProjectViewCore.viewport} on the {ProjectView.PlanBar}
	 * @param planId {String} The plan ID you wish to focus the Viewport on
	 * @param horizontalSpacing {Number} The horizontal scale of the plan bar
	 * @param minScale {Number} The minimum zoom scale of the viewport.
	 * @param maxScale {Number} The maximum zoom scale of the viewport
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.focusOnPlan = function(planId, horizontalSpacing, minScale, maxScale){
		if (!planId && !this.enabledFeatures.inPlan){return}
		planId = planId || this.initialFeatures.plan.planId;
		horizontalSpacing = horizontalSpacing || 0;
		minScale = minScale || null;
		maxScale = maxScale || null;
		var currentPlan = this.getBarFromPlanId(planId);
		if (currentPlan){
			if (currentPlan.container.x <= 0){horizontalSpacing *= 1;}
			var centerPoint = this.stage.toGlobal(new PIXI.Point(horizontalSpacing + currentPlan.container.x + (currentPlan.container.width/2), currentPlan.container.y + (currentPlan.container.height/2)));
			var wSpace = currentPlan.container.width + Math.abs(horizontalSpacing);
			var z = this.view.width/wSpace;
			if (maxScale && z >= maxScale){z = maxScale;}
			else if (minScale && z <= minScale){z = minScale;}
			var x = centerPoint.x - (this.view.width / z)/2;
			var y = centerPoint.y - (this.view.height / z)/2;
			this.setPosition(-x*z, -y*z, z);
		}
	};
	/**
	 * @function setPosition
	 * @desc Sets the position of the viewport to the provided values.
	 * @param x {Number} The X position to set the viewport
	 * @param y {Number} The Y position to set the viewport
	 * @param scale {Number} The scale value to set the viewport.
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.setPosition = function(x, y, scale){
		this.viewport.setPosition({x: x, y: y}, scale);
	};
	/**
	 * @function updateTimeline
	 * @desc Updates the timeline logic, if enabled
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.updateTimeline = function(){
		this.timeline.update(this.view.width, this.view.height, this.viewport.getScale());
	};
	/**
	 * @function drawTimeline
	 * @desc Draws the new timeline, if enabled
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.drawTimeline = function(){
		this.timeline.draw(this.view.width, this.view.height, this.viewport.getScale());
	};
	/**
	 * @function drawDividerLines
	 * @desc Draws all of the divider lines
	 * @param options {Object}
	 * @private
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.drawDividerLines = function(options){
		this.dividerLineTop.push(new RenderLine(LineOptions({
			LINE_SIZE: projectViewConfig.DIVIDER_LINE.LINE_SIZE * projectViewConfig.CORE.HORIZONAL_SCALE,
			LINE_COLOR: projectViewConfig.DIVIDER_LINE.FILL_COLOR,
			LINE_ALPHA: projectViewConfig.DIVIDER_LINE.FILL_ALPHA,
			NATIVE_LINES: true
		})));
		this.dividerLineBottom.push(new RenderLine(LineOptions({
			LINE_SIZE: projectViewConfig.DIVIDER_LINE.LINE_SIZE * projectViewConfig.CORE.HORIZONAL_SCALE,
			LINE_COLOR: projectViewConfig.DIVIDER_LINE.FILL_COLOR,
			LINE_ALPHA: projectViewConfig.DIVIDER_LINE.FILL_ALPHA,
			NATIVE_LINES: true
		})));
		this.layerManager.getLayerByName('details').addChild(this.dividerLineTop[this.dividerLineTop.length-1].get());
		this.layerManager.getLayerByName('details').addChild(this.dividerLineBottom[this.dividerLineBottom.length-1].get());
		var height = projectViewConfig.PLAN_BARS.INITIAL_HEIGHT;
		this.dividerLineTop[this.dividerLineTop.length-1].draw(0, options.y -11, options.view.width, options.y -11);
		this.dividerLineBottom[this.dividerLineBottom.length-1].draw(0, height + options.y + 7, options.view.width, height + options.y + 7);
	};
	/**
	 * @function sortPlans
	 * @desc Sorts the plan based by date
	 * @param plans
	 * @private
	 * @memberOf ProjectView.ProjectViewCore
	 * @returns {Array.<*>}
	 */
	ProjectView.prototype.sortPlans = function(plans){
		function sortBy(field, reverse, primer) {
			var key = primer ? function (x) {
				return primer(x[field])
			} : function (x) {
				return x[field]
			};
			reverse = !reverse ? 1 : -1;
			return function (a, b) {
				return a = key(a), b = key(b), reverse * (((a > b) as any) - ((b > a) as any));
			}
		};
		var sortedArr = [];
		for (var i in plans) {
			sortedArr.push(plans[i]);
		}
		return sortedArr.sort(sortBy('startDate', false, this.getDate));
	};
	/**
	 * @function onPlanUpdate
	 * @desc Called when the Plan updates
	 * @param newObj {fbObject} An object from Firebase with the changed keys
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.onPlanUpdate = function(newObj){
		if (newObj.event === 'child_changed'){
			var bar = this.getBarFromPlanId(newObj.key);
			if (!bar){return;}
			// Update internal variables storing plan bar start and end dates
			bar.updatePlanDates();
			// Check to see if bar width needs updating.
			bar.updatePlanBarWidth();
			// Check to see if plan name needs updating.
			bar.updatePlanName();
			// Check to see if plan schedule needs updating.
			bar.updateScheduleText();
			// Check to see if the active line needs updating
			bar.updateActiveLine();
		}
	};
	/**
	 * @function onMilestoneUpdate
	 * @desc Called when the milestone updates
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.onMilestoneUpdate = function(){
		var bar = this.getBarFromPlanId(this.initialFeatures.plan.$id);
		if (!bar){return;}
		bar.options.plan.milestone = this.initialFeatures.milestone;
		bar.updateScheduleText();
	};
	/**
	 * @function getBarFromPlanId
	 * @desc Returns the {ProjectView.PlanBar} based on the provided ID
	 * @param planId {String} The ID of the plan
	 * @returns {ProjectView.PlanBar|null}
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.getBarFromPlanId = function(planId){
		return this.heatmaps[this.heatmapIndice[planId]]
	};
	/**
	 * @function setGuidelineVisibility
	 * @desc Call to set if the guidelines should draw or now.
	 * @param value {Boolean} Should draw guidelines?
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.setGuidelineVisibility = function(value){
		if (!this.enabledFeatures.guideLines){return;}
		//this.guideLines.get().visible = value;
		this.guideLines.setVisible(value);
	};
	/**
	 * @function setActiveLineVisibility
	 * @desc Call to set if the activelines should draw for all {ProjectView.PlanBar}s
	 * @param value {Boolean} Should draw active lines.
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.setActiveLineVisibility = function(value){
		this.heatmaps.forEach(function(planBar){
			planBar.toggleActiveLineVisibility(value);
		})
	};
	/**
	 * @function setDrawAheadVisibility
	 * @desc Call to set if the draw ahead text is drawn or not
	 * @param value {Boolean} should draw ahead text?
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.setDrawAheadVisibility = function(value){
		this.heatmaps.forEach(function(planBar){
			planBar.setDrawAheadVisibility(value);
		})
	};
	/**
	 * @function setHeatmapVisibility
	 * @desc Set to draw the ticket status data on plan bars
	 * @memberOf ProjectView.ProjectViewCore
	 * @param value
	 */
	ProjectView.prototype.setHeatmapVisibility = function(value){
		this.heatmaps.forEach(function(heatmap){
			heatmap.toggleVisible(value);
		});
	};
	/**
	 * @function registerClick
	 * @desc Called by the {ProjectView.PlanBar} when a click/tap is registered
	 * @private
	 * @memberOf ProjectView.ProjectViewCore
	 * @param index
	 */
	ProjectView.prototype.registerClick = function(index){
		// Mark all heatmaps as not selected
		var self = this;
		this.heatmaps.forEach(function(heatmap, idx){
			if (idx !== index){
				heatmap.markSelected(false);
			} else {
				heatmap.toggleSelected();
				if (heatmap.isSelected){
					ProjectViewApi.setSelectedPlanBar(self.initialOptions.plans[heatmap.options.waveform.planIdx])
				} else {
					ProjectViewApi.setSelectedPlanBar(null)
				}
			}
		});
	};
	/**
	 * @function getFirstVisibleDate
	 * @desc Returns the first visible date from {ProjectView.projectViewDateSync}
	 * @returns {*}
	 * @memberOf ProjectView.ProjectViewCore
	 */
	ProjectView.prototype.getFirstVisibleDate = function(){
		return projectViewDateSync.getDateRange()[0];
	};
	return ProjectView
}
