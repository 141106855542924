'use strict';
import * as angular from "angular";

angular.module('ticketspaceApp')
.factory('settingStorage', function(){
	var saved = {};

	function save(key, value){
		return saved[key] = value;
	}

	function replace(key, childKey, value){
		if (exists(key)){return saved[key][childKey] = value;
		}else { return null}
	}

	function get(key){
		if (exists(key)){return saved[key]}
		else {return {}}
	}

	function clear(key){
		if (saved.hasOwnProperty(key)){ delete saved[key]; }
		return saved[key];
	}

	function exists(key){
		return saved.hasOwnProperty(key);
	}

	function setIfExists(key, value, fallback){
		if (exists(key)){return get(key)[value];}
		else { return fallback;}
	}

	return {
		save: save,
		get: get,
		clear: clear,
		exists: exists,
		setIfExists: setIfExists,
		replace: replace
	}
});
