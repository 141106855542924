import { extras, Texture } from 'pixi.js';
import { FancyGraphics } from "../graphics/FancyGraphics";
import { RendererCacheInterface } from "../interfaces/";
declare const document: any;
/**
 * An abstract class which provides some helper methods for caching of generated textures
 */
export abstract class RendererCache extends FancyGraphics{
	/**
	 * Contains an instance of a HTMLCanvasElement in memory. This is used as our working canvas for generating meshes. This turns into a {@link PIXI.Texture} during the final generation step
	 */
	protected _scratchCanvas: HTMLCanvasElement;
	/**
	 * Contains an instance of a canvas drawing context in memory.
	 */
	protected _scratchContext: any;
	/**
	 * Stores a spritesheet where each frame is a cached texture
	 */
	protected _textureCache: any;
	/**
	 * Stores a mapping of identifier to frame index. Used to get a texture from the _ticketTextures
	 */
	protected _textureMap: RendererCacheInterface = {};
	/**
	 * @param {boolean} nativeLines If true the lines will be draw using LINES instead of TRIANGLE_STRIP
	 */
	constructor(nativeLines: boolean){
		super(nativeLines);
		this._scratchCanvas = document.createElement('canvas');
		this._scratchContext = this._scratchCanvas.getContext('2d');
	}
	/**
	 * Adds a texture to the spritesheet cache. Prevents re-drawing in the future.
	 * @param {string} identifier The unique identifier you want to identify this texture as. Used to find it later.
	 * @param {PIXI.Texture} texture An instance of the PIXI.Texture you want to add to the cache
	 * @param {boolean} autoUpdate If true, will add the texture to the animation queued and will updated using the shared renderer
	 * @returns CanvasRenderer.TicketRenderer
	 */
	public cacheTexture(identifier: string, texture: Texture, autoUpdate?: boolean){
		if (!this._textureCache){
			this._textureCache = new extras.AnimatedSprite([texture], autoUpdate);
			this._textureMap[identifier] = this._textureCache.textures.length -1;
		}
		else{
			this._textureCache.textures.push(texture);
			this._textureMap[identifier] = this._textureCache.textures.length -1;
		}
		return this
	}
	/**
	 * New method which stores the new texture in the PIXI.TextureCache global instead of here
	 * @param {string} identifier
	 * @param {PIXI.Texture} texture
	 */
	public addToGlobalCache(identifier: string, texture: Texture): void {
		Texture.addToCache(texture, identifier);
	}
	
	/**
	 * Draws a rounded rectangle using native Canvas (Does not use Graphics.drawRoundRect)
	 * @param {number} x
	 * @param {number} y
	 * @param {number} w
	 * @param {number} h
	 * @param {number} r
	 */
	public canvasRoundedRect(x: number, y: number, w: number, h: number, r: number): void {
		if (w < 2 * r) r = w / 2;
		if (h < 2 * r) r = h / 2;
		this._scratchContext.beginPath();
		this._scratchContext.moveTo(x+r, y);
		this._scratchContext.arcTo(x+w, y,   x+w, y+h, r);
		this._scratchContext.arcTo(x+w, y+h, x,   y+h, r);
		this._scratchContext.arcTo(x,   y+h, x,   y,   r);
		this._scratchContext.arcTo(x,   y,   x+w, y,   r);
		this._scratchContext.closePath();
	}
	
	/**
	 * Get a cached texture using the unique identifier assigned
	 * @param {string} identifier The string which identifies the texture
	 * @returns {PIXI.Texture}
	 */
	public getTexture(identifier: string){
		if (!this._textureCache){return null}
		let frame = this._textureMap[identifier];
		return this._textureCache.textures[frame]
	}
	/**
	 * Workaround for https://github.com/pixijs/pixi.js/issues/4259. Creates a dummy canvas independent of our scratch canvas and is used to bind to a unique texture.
	 * @returns {HTMLCanvasElement}
	 */
	protected getCanvasTexture(canvas?){
		// Create temporary canvas to bind to new texture...See: https://github.com/pixijs/pixi.js/issues/4259
		if (canvas){
			console.log(canvas.width, canvas.height);
		}
		let _cvs = document.createElement('canvas');
		_cvs.width = this._scratchCanvas.width;
		_cvs.height = this._scratchCanvas.height;
		let _ctx = _cvs.getContext('2d');
		_ctx.drawImage(this._scratchCanvas, 0, 0);
		return _cvs;
	}
	
	public destroy(){
		super.destroy();
		this._scratchCanvas = null;
		this._scratchContext = null;
		this._textureCache = null;
		this._textureMap = null;
	}
}