import * as angular from "angular";
var anyWindow:any = window;

angular.module('CanvasWrapper').factory("InteractionTap", TapFactory);
/**
 * @namespace Tap
 * @returns {CanvasWrapper.Interaction.Tap}
 * @memberOf CanvasWrapper.Interaction
 * @constructor
 */
TapFactory.$inject = ["InteractionHandleEvent"];
function TapFactory(InteractionHandleEvent){
	var handleEvent = InteractionHandleEvent;
	function Tap(displayNode){
		function mouseDown (e) {
			start(e, e.data.originalEvent)
		}

		function touchStart (e) {
			if (!e || !e.data || !e.data.originalEvent || !e.data.originalEvent.targetTouches){return}
			start(e, e.data.originalEvent.targetTouches[0]);
		}

		// possibly be called twice or more
		function start (e, t) {
			if (displayNode._tap) {
				return
			}
			displayNode._tap = {
				p: {
					x: t.clientX,
					y: t.clientY
				}
			};
			handleEvent(displayNode).on('mousemove', mouseMove);
			handleEvent(displayNode).on('touchmove', touchMove);
		}

		function mouseMove (e) {
			move(e, e.data.originalEvent)
		}

		function touchMove (e) {
			var t = e.data.originalEvent.targetTouches;
			if (!t || t.length > 1) {
				displayNode._tap.canceled = true;
				end(e);
				return
			}
			move(e, t[0])
		}

		function move (e, t) {
			var dx = t.clientX - displayNode._tap.p.x;
			var dy = t.clientY - displayNode._tap.p.y;
			var distance = Math.sqrt(dx * dx + dy * dy);
			var threshold = (t instanceof anyWindow.MouseEvent) ? 2 : 7;
			if (distance > threshold) {displayNode._tap.canceled = true}
		}

		// possibly be called twice or more
		function end (e) {
			if (displayNode._tap && !displayNode._tap.canceled) {
				var event = {
					data: e.data
				};
				handleEvent(displayNode).emit('simpletap', event)
			}
			displayNode._tap = null;
			handleEvent(displayNode).removeListener('mousemove', mouseMove);
			handleEvent(displayNode).removeListener('touchmove', touchMove);
		}

		displayNode.interactive = true;
		handleEvent(displayNode).on('mousedown', mouseDown);
		handleEvent(displayNode).on('touchstart', touchStart);
		handleEvent(displayNode).on('mouseup', end);
		handleEvent(displayNode).on('mouseupoutside', end);
		handleEvent(displayNode).on('touchend', end);
		handleEvent(displayNode).on('touchendoutside', end);
	};

	return Tap
}
