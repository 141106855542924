import { Ticket } from "./";
import {Rectangle} from "pixi.js";

export class PullRegion{
	startDate: string;
	finishDate: string;
	startX: number;
	finishX: number;
	activeStartX?: number;
	activeFinishX?: number;
	calculatedCalendarDays?; //added later
	tickets: Array<Ticket> = []; //maybe create a "ColumnTicket" so it can be decorated with additional data
	
	public in(left: number, right: number){
		return left < this.finishX && right > this.startX;
	}
	public push(ticket:Ticket){
		var grew = false;
		var right = ticket.view.dragStartData.x + ticket.view.dragStartData.width;
		
		if(this.tickets.length === 0){
			this.startX = ticket.view.dragStartData.x;
			this.finishX = right;
		}
		else{
			if(ticket.view.dragStartData.x < this.startX){ this.startX = ticket.view.dragStartData.x; grew = true;}
			if(right > this.finishX){ this.finishX = right; grew = true;}
		}
		
		this.tickets.push(ticket);
		
		return grew && this.tickets.length > 1; //growing from empty regions is pointless
	}
}

//TODO - there's currently a pretty hard dependency on the ticket drag start data, see about removing that
export class PullCalculation{
	public regions: Array<PullRegion>;
	public activeRect?: Rectangle;
	public pullStartRect?: Rectangle;
	public get startDate(){
		if(this.regions.length){ return this.regions[this.regions.length - 1].startDate || null }
		return null;
	}
	
	constructor(tickets: Map<string, Ticket>){
		this.recalc(tickets);
	}
	
	recalc(tickets: Map<string, Ticket>){
		this.regions = []; //maybe don't clear it?
		
		var top = Infinity;
		tickets.forEach((t)=>{
			var tLeft = t.view.dragStartData.x;
			var tRight = t.view.dragStartData.x + t.view.dragStartData.width;
			var handled = false;
			//special handling for the first one
			if(!this.regions.length){
				var region = new PullRegion();
				region.push(t);
				this.regions.push(region);
				handled = true;
			}
			else{
				var existingRegion;
				for(var idx = 0; idx < this.regions.length; idx++){
					var region = this.regions[idx];
					//insert at the beginning
					if(tLeft >= region.finishX){
						
						existingRegion = new PullRegion();
						this.regions.splice(idx, 0, existingRegion);
						break;
					}
					//or in a region
					if(region.in(tLeft, tRight)){
						existingRegion = region;
						break;
					}
				}
				//or at the end
				if(!existingRegion){
					existingRegion = new PullRegion();
					this.regions.push(existingRegion);
				}
				var grew = existingRegion.push(t);
				if(grew){ this.smooshRegions(); }
			}
			
			if(t.view.dragStartData.y < top){ top = t.view.dragStartData.y; }
		});
		this.pullStartRect = new Rectangle(
			this.regions[0].startX, 
			top,
			this.regions[this.regions.length-1].finishX - this.regions[0].startX,
			0);
			//(this.startRect.y + pullSnappedY) + (pullTicketHeight + snappingDistance) * ticket.view.dragStartData.yOrder

	}
	
	public iterate(cb:(ticket:Ticket, region:PullRegion, ticketIndex?:number, regionIndex?:number)=>void){
		for( var i = this.regions.length-1; i >= 0; i--){
			var r = this.regions[i];
			r.tickets.forEach((ticket, idx)=>{
				cb(ticket, r, idx, i);
			});
		}
	}
	
	private smooshRegions(){
		var prev;
		for(var idx = 0; idx < this.regions.length; idx++){
			var region = this.regions[idx];
			if(!prev){ prev = region; continue; } //first
			if(region.finishX > prev.startX){
				prev.startX = region.startX;
				prev.tickets = [...prev.tickets, ...region.tickets];
				this.regions.splice(idx, 1);
				this.smooshRegions();
				break;
			}
		}
	}
}
