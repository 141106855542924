import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { FancySprite } from "../graphics/";
import { Texture } from "pixi.js";
/**
 *
 */
export abstract class TicketComponentHelper extends FancySprite implements ComponentInterface {
	public visible: boolean = true;
	protected ticket: TicketContainer;
	protected planState: PlanState;
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture);
		this.ticket = ticketContainer;
		this.planState = planState;
	}
}