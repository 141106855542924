import { Camera } from "../core/Camera";
import { FancyGraphics, FancyContainer } from "../graphics/";
import { SwimlaneRenderer, SwimlaneContainer } from "./";
import { PlanState } from "../../common/services/plan-state.service";
import { FancyAction } from "../../fancy-firebase/base/FancyAction";
import { Swimlane } from "../../common/models/Swimlane";
import { LevelOfDetail } from "../core/";
import { takeUntil } from "rxjs/operators";
import { alternativeBuffer } from "ng2/common/rxjs-internal-extensions";
import { Subject } from "rxjs";
import {RendererApplication} from "../core/RendererApplication";

// MOC-2897
// https://mocasystems.atlassian.net/wiki/spaces/FEAT/pages/480280577/Swim+Lane+Add+Ons
export class FancySwimlanes {
	/**
	 * Instance of FancyGraphics. Renders all of our swimlane rectangular areas
	 */
	private _renderer: FancyGraphics = new FancyGraphics();
	/**
	 * Our cache of SwimlaneLabels. Used to render text + text background
	 */
	private swimlanes: Map<string, SwimlaneContainer> = new Map();
	/**
	 * Reference to the camera
	 */
	private _camera: Camera;
	/**
	 * The container in the scene graph where all of our elements will be added too
	 */
	private _parent: FancyContainer;
	/**
	 * The container in the scene graph where all of our elements will be added too
	 */
	private _lineContainer: FancyContainer;
	/**
	 * Reference to our planState
	 */
	private _planState: PlanState;
	
	/**
	 * Generates our swimlane graphics as caches as sprites.
	 */
	private _swimlaneRenderer: SwimlaneRenderer;
	
	private _app: RendererApplication;
	private shutdownSubject$: Subject<boolean> = new Subject();
	constructor(planState: PlanState, parentLayer: FancyContainer, lineContainer: FancyContainer, camera: Camera, render: RendererApplication) {
		
		parentLayer.addChild(this._renderer);
		
		this._camera = camera;
		this._parent = parentLayer;
		this._lineContainer = lineContainer;
		this._planState = planState;
		this._app = render;
		// this._render = ()=>{
		// 	console.log('swimlane render', renderQueue.isRunning);
		// 	render();
		// }
		
		// Generate swimlane textures
		this._swimlaneRenderer = new SwimlaneRenderer(false);
		
		// Watch for changes to the rawEvent$ stream.
		// Listening to rawEvents so we can filter down _what_ is happening to the swimlanes so we adjust our cache accordingly
		planState.swimlanes.list.rawEvent$.pipe(
			takeUntil(this.shutdownSubject$),
			alternativeBuffer(planState.renderQueueWithCleanup$),
			// tap(stuff => console.log('fancy swimlanes tap', performance.now() - window.renderTimestamp) ),
			// filter(arr => !!arr.length),
			// map(thing => [thing])
		).subscribe(this.update);
	}
	/**
	 * Updates our cache of swimlanes
	 * @param actions
	 */
	public update = (actions: FancyAction[]) => {
		actions.forEach((action: FancyAction) => {
			// Add action
			if (this._planState.swimlanes.list.isAddAction(action)) {
				// Swimlane added
				this.add(action.key, action.payload);
			} else if (this._planState.swimlanes.list.isRemoveAction(action)) {
				// Swimlane removed
				this.remove(action.key);
			} else {
				// Swimlane updated
				const swimlane: SwimlaneContainer = this.swimlanes.get(action.key);
				if (!swimlane){
					this.add(action.key, action.payload);
				} else {
					swimlane.update(this._getRealWidth(), action.payload);
				}
			}
		});
		
		// Always re-draw after an update
		this._app.safeRender();
	};
	/**
	 * Adds a new swimlane to our cache for rendering.
	 * @param key The unique ID
	 * @param swimlaneModel
	 */
	public add(key: string, swimlaneModel: Swimlane): FancySwimlanes {
		const swimlane: SwimlaneContainer = new SwimlaneContainer(swimlaneModel, this._planState);
		swimlane.update(this._getRealWidth(), swimlaneModel);
		
		this.swimlanes.set(key, swimlane);
		
		this._parent.addChild(swimlane, swimlane.label.background, swimlane.label);
		this._lineContainer.addChild(swimlane.lineContainer);
		
		return this;
	}
	/**
	 * Removes a swimlane from our cache so it is no longer drawn.
	 * @param {string} id
	 */
	public remove(id: string){
		const thing = this.swimlanes.get(id);
		this.swimlanes.delete(id);
		if (thing){
			thing.destroy();
		}
	}
	/**
	 * DESTROY ALL THE THINGS
	 * @param options
	 */
	public destroy(options?){
		this.shutdownSubject$.next(true);
		
		this.swimlanes.forEach((swimlane: SwimlaneContainer) => {
			swimlane.destroy();
		});
		
		this._swimlaneRenderer.destroy();
		
		this.swimlanes.clear();
		this.swimlanes = null;
		
		this._app = null;
	}
	
	/**
	 * Helper method which returns the real width of the swimlane. Handles the printing case where the renderer is larger
	 * than the screen. (Swimlanes are rendered to screen dimensions)
	 */
	private _getRealWidth(): number {
		return (LevelOfDetail.isForced) ? this._app.renderer.width : this._camera.view.width;
	}
}
