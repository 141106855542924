import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SwimlanesComponent } from './swimlanes/swimlanes.component';
import { SwimlaneComponent } from './swimlanes/swimlane.component';
import { CompletedSelectionPromptComponent } from './completed-selection-prompt/completed-selection-prompt.component';
import { HideCompletedToggleComponent } from './hide-completed-toggle/hide-completed-toggle.component';

import { LabourCountComponent } from "./labour-counts/labour-count.component";

import { ColorPickerModule } from "../color-picker/color-picker.module";
import {DropdownModule} from 'primeng/dropdown';
import { MultiSelectModule } from "primeng/primeng";
import { UtilityComponentsModule } from "../common/components/utility-components.module";

//the angular base
@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ColorPickerModule,
		DropdownModule,
		MultiSelectModule,
		UtilityComponentsModule,
		FormsModule
	],
	declarations: [
		SwimlanesComponent,
		SwimlaneComponent,
		CompletedSelectionPromptComponent,
		HideCompletedToggleComponent,
		LabourCountComponent
	],
	exports: [
		SwimlanesComponent,
		SwimlaneComponent,
		CompletedSelectionPromptComponent,
		HideCompletedToggleComponent,
		LabourCountComponent
	],
	entryComponents: [

	],
	providers: [
		
	]
})
export class PlanModule {

}
