import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

//route component imports
import { PlanComponent } from "./plan.component";
import { EmptyComponent } from "./empty.component";
import { UserDataResolver } from "./user-data-resolver.service";


@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy{
	shouldAttach(route:ActivatedRouteSnapshot){
		return false;
	}
	shouldDetach(route:ActivatedRouteSnapshot){
		return false;
	}
	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle){
		
	}
	retrieve(route: ActivatedRouteSnapshot){
		return null;
	}
	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot){
		return false;
	}

}
