//import "../declarations/globals.d.ts";
// /// <reference types="declarations/globals.d.ts" />
/// <reference path="../declarations/globals.d.ts" />

//these need to load very early to make angular happy
import 'core-js/es6';
import 'core-js/es7/reflect';
import 'zone.js';
import 'zone.js/dist/zone-patch-rxjs';

// import {filter} from "rxjs/operators";
// window.rx_filter = filter;

// declare var __webpack_hash__: string;
// console.log('webpack-hash', __webpack_hash__);

import { windowifier } from "./windowifier";

import { perf } from "../ng2/common/perfLog";
perf.log('app entry point hit');

windowifier();

import * as pixi from "pixi.js";
import {renderQueue} from "ng2/common/RenderQueue";
let origonPointerMove = (pixi.interaction.InteractionManager as any).prototype.onPointerMove;
let origprocessInteractive = (pixi.interaction.InteractionManager as any).prototype.processInteractive;
// (pixi.interaction.InteractionManager as any).prototype.onPointerMove = function(){
// 	renderQueue.push(origonPointerMove.bind(this, arguments[0]), "interaction-manager");
// };

(pixi.interaction.InteractionManager as any).prototype.processInteractive = function(){
	if(this.startEventAlreadyDetected){ return true; }
	else{ return origprocessInteractive.apply(this, arguments); }
}

import "ng2/common/utils/AnalyticsService";
//css
import 'styles/main.scss';

//load favicons
(require as any).context('images/favicons', true);

//fill out the window where necessary...
var anyWindow = window as any;
import * as firebase from "firebase/app";
require("firebase/storage");
require("firebase/database");
require("firebase/auth");

import * as angular from 'angular';

//actually explicitly slap jquery onto the window... mainly just for reports...
anyWindow.$ = $;
anyWindow.jQuery = $;

// import MyWorker = require("../ng2/workers/test.worker");
// import MyWorker from "worker-loader!../ng2/workers/test.worker";

// console.log('Worker', MyWorker);

// const worker = new MyWorker();
// console.log('new worker', worker);
// worker.postMessage({ a: 1 });


//the below works
// import {workerManager } from "ng2/workers/WorkerManager";
// 
// workerManager.makeCbRequest("test", {"words": "word"}, (data)=>{
// 	console.log('callback hit', data);
// });

require("ng2/common/key-modifier");
/**
 * `require` all modules in the given webpack context
 */
function requireAll(context) {
	//TODO - setup proper filtering method to remove files
	//console.log('context.keys()', context.keys());
	// context.keys().forEach(context);
	context.keys().forEach(function(key){
		if(!key.match(/(outdated|angular\.js|angular\.min\.js)/)){
			context(key);
		}
		else{
			//console.log('hi', key);
		}
	});
}

// Collect all angular modules
requireAll((require as any).context(
  '.',
  /* use subdirectories: */ true,
	/\.(js|ts)$/
));

//get all popup html (so laaaaazzzzzzy.....)
// requireAll((require as any).context(
//   'tpl/popups',
//   /* use subdirectories: */ true,
// 	/\.(html)$/
// ));

//outdatedbrowser bs
require("file-loader?name=js/[name].[ext]!js/vendor/outdatedbrowser.js");
require("styles/actual-css/outdatedbrowser.min.css");
//other bs
require("!file-loader?name=[path][name].[ext]!images/tp-logo-sprite.png");
require('!file-loader?name=[path][name].[ext]!images/blankphoto.jpg');
require('!file-loader?name=[path][name].[ext]!images/downtime-ticket.jpg');
require('!file-loader?name=[path][name].[ext]!images/touchplan-logo.png');
require('!file-loader?name=[path][name].[ext]!images/handshake-dark.svg');
require('!file-loader?name=[path][name].[ext]!images/tp-logo-blue.png');

require('!file-loader?name=[path][name].[ext]!legal/Copyright-Policy.pdf');
require('!file-loader?name=[path][name].[ext]!legal/Privacy-Policy.pdf');
require('!file-loader?name=[path][name].[ext]!legal/Service-Terms.pdf');

require('iindex.html');
require('404.html');
require('manifest.json');


//remote libraries, move to another file eventually
(function(window){
	var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement("script");e.setAttribute('crossorigin', 'anonymous'); e.type="text/javascript";e.async=!0;e.src=("https:"===document.location.protocol?"https://":"http://")+"cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION="3.1.0";
	analytics.load(MB4_PREPROCESSOR_CONFIG.SEGMENT_KEY);
	analytics.page()
}})(anyWindow);
// require("../../test/mock/analytics-mock")


//------------------------------------------------
// Boilerplate webpack-y bs ends here
//------------------------------------------------

import AppModule from './app2.module';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
console.log('pre bootstrap');
platformBrowserDynamic().bootstrapModule(AppModule);
