import { Rectangle } from "pixi.js";
import * as Utils from "utils";
/**
 * Define the interface for objects that can be inserted into the QuadTree
 */
export interface IQuadTreeObject {
	x: number;
	y: number;
	width: number;
	height: number;
}
/**
 * Define the interface for the QuadTree node boundaries
 */
export interface IQuadTreeBounds extends IQuadTreeObject {
	subWidth: number;
	subHeight: number;
	right: number;
	bottom: number;
}
/**
 * A QuadTree implementation. The original code was a conversion of the Java code posted to GameDevTuts.
 * Original version at https://github.com/timohausmann/quadtree-js/
 * See a live demo: https://timohausmann.de/quadtree.js/dynamic.html
 */
export class QuadTree{
	public maxObjectsPerNode: number;
	public maxLevels: number;
	public level: number = 0;
	public bounds: IQuadTreeBounds;
	public objects: IQuadTreeObject[] = [];
	public nodes: QuadTree[] = [];
	/**
	 * A cache of all objects in the QuadTree. used for quick lookup of the index.
	 */
	private _objectCache: Map<IQuadTreeObject, any> = new Map();
	/**
	 * @param {number} x - The top left X coordinate of the quadtree.
	 * @param {number} y - The top left Y coordinate of the quadtree.
	 * @param {number} width - The width of the quadtree in pixels.
	 * @param {number} height - The height of the quadtree in pixels.
	 * @param {number} maxObjectsPerNode - The maximum number of objects per node.
	 * @param {number} maxLevels - The maximum number of levels to iterate to.
	 * @param {number} level - The current level
	 */
	constructor(x: number, y: number, width: number, height: number, maxObjectsPerNode: number = 4, maxLevels: number = 4, level: number = 0){
		this.reset(x, y, width, height, maxObjectsPerNode, maxLevels, level);
	}
	/**
	 * Reset the QuadTre bounds
	 * @param {number} x - The top left X coordinate of the quadtree.
	 * @param {number} y - The top left Y coordinate of the quadtree.
	 * @param {number} width - The width of the quadtree in pixels.
	 * @param {number} height - The height of the quadtree in pixels.
	 * @param {number} maxObjectsPerNode - The maximum number of objects per node.
	 * @param {number} maxLevels - The maximum number of levels to iterate to.
	 * @param {number} level - The current level
	 */
	public reset(x: number, y: number, width: number, height: number, maxObjectsPerNode: number = 4, maxLevels: number = 4, level: number = 0){
		this.maxObjectsPerNode = maxObjectsPerNode;
		this.maxLevels = maxLevels;
		this.level = level;
		// Create the QuadTree bounds
		this.bounds = {
			x: Math.round(x),
			y: Math.round(y),
			width: width,
			height: height,
			subWidth: Math.floor(width / 2),
			subHeight: Math.floor(height / 2),
			right: Math.round(x) + Math.floor(width / 2),
			bottom: Math.round(y) + Math.floor(height / 2)
		};
	}
	/**
	 * Split the current node into 4 subnodes
	 */
	public split(): void {
		// Create 4 new nodes. One for each quadrant
		this.nodes[0] = new QuadTree(this.bounds.right, this.bounds.y, this.bounds.subWidth, this.bounds.subHeight, this.maxObjectsPerNode, this.maxLevels, (this.level + 1));
		this.nodes[1] = new QuadTree(this.bounds.x, this.bounds.y, this.bounds.subWidth, this.bounds.subHeight, this.maxObjectsPerNode, this.maxLevels, (this.level + 1));
		this.nodes[2] = new QuadTree(this.bounds.x, this.bounds.bottom, this.bounds.subWidth, this.bounds.subHeight, this.maxObjectsPerNode, this.maxLevels, (this.level + 1));
		this.nodes[3] = new QuadTree(this.bounds.right, this.bounds.bottom, this.bounds.subWidth, this.bounds.subHeight, this.maxObjectsPerNode, this.maxLevels, (this.level + 1));
		
	}
	/**
	 * Insert the object into the node. If the node exceeds the capacity, it will split and add all objects to their corresponding subnodes.
	 * @param {any} thing - The Body object to insert into the quadtree. Can be any object so long as it exposes x, y, right and bottom properties.
	 */
	public insert(thing: IQuadTreeObject): void {
		let i: number = 0;
		let index: number;
		
		if (this.nodes[0] !== undefined){
			index = this.getIndex(thing);
			if (index !== -1){
				this.nodes[index].insert(thing);
				return;
			}
		}
		
		this._objectCache.set(thing, index);
		this.objects.push(thing);
		
		if (this.objects.length > this.maxObjectsPerNode && this.level < this.maxLevels){
			if (this.nodes[0] === undefined){
				this.split();
			}
			
			// Add objects to sub-nodes
			while (i < this.objects.length) {
				index = this.getIndex(this.objects[i]);
				if (index !== -1){
					// Using splice here is slow as fuck. Maybe refactor so we can use Utils.fastSplice? Need the return value
					// of elements that have been removed from the array
					const shiftedThing: IQuadTreeObject = this.objects.splice(i, 1)[0];
					this._objectCache.set(shiftedThing, shiftedThing);
					this.nodes[index].insert(shiftedThing);
				} else {
					i++;
				}
			}
		}
	}
	/**
	 * Determine which node the object belongs to.
	 * @param {Rectangle|object} rect - The bounds in which to check.
	 */
	public getIndex(rect: IQuadTreeObject): number {
		// The rect no fit in a quadrant is the default
		let index: number = this._objectCache.get(rect) || -1;
		if (index >= 0) { return index;}
		// Check to see if the rect can fit in the left quadrants
		if (rect.x <= this.bounds.right && rect.width <= this.bounds.right){
			if (rect.y < this.bounds.bottom && rect.height < this.bounds.bottom){
				// Rect fits within the top-left quadrant
				index = 1;
			} else if (rect.y > this.bounds.bottom){
				// Rect fits within the bottom-left quadrant
				index = 2;
			}
		// Check to see if the rect can fit in the right quadrants
		} else if (rect.x >= this.bounds.right){
			if (rect.y < this.bounds.bottom && rect.height < this.bounds.bottom){
				// Rect fits in the top-right quadrant
				index = 0;
			} else if (rect.y > this.bounds.bottom){
				// Rect fits in the bottom-right quadrant
				index = 3;
			}
		} else {index = 0;} // MOC-3225 failsafe. Should never be hit, but won't error in _really_ broken cases
		return index;
	}
	/**
	 * Return all objects that could collide with the given Sprite or Rectangle.
	 * @param {any} thing - The source object to check the QuadTree against. Either a Sprite or Rectangle.
	 */
	public grab(thing: IQuadTreeObject): IQuadTreeObject[] {
		let index: number = this.getIndex(thing);
		let returnObjects: IQuadTreeObject[] = this.objects;
		if (this.nodes[0]){
			if (index !== -1){
				returnObjects = returnObjects.concat(this.nodes[index].grab(thing));
			} else {
				returnObjects = returnObjects.concat(this.nodes[0].grab(thing));
				returnObjects = returnObjects.concat(this.nodes[1].grab(thing));
				returnObjects = returnObjects.concat(this.nodes[2].grab(thing));
				returnObjects = returnObjects.concat(this.nodes[3].grab(thing));
			}
		}
		
		return returnObjects
	}
	/**
	 * Checks to see if a IQuadTreeObject exists in the tree.
	 *
	 * This checks the object cache for fast lookup.
	 *
	 * @param {IQuadTreeObject} thing The thing to search for
	 */
	public exists(thing: IQuadTreeObject): boolean {
		return this._objectCache.get(thing) !== undefined;
	}
	/**
	 * Clear the quadtree.
	 */
	public clear(): void {
		this.objects = [];
		
		let i =  this.nodes.length;
		while (i--){
			this.nodes[i].clear();
			Utils.fastSplice(this.nodes, i, 1);
		}
		this.nodes = [];
	}
	/**
	 * Destroy the QuadTree
	 * @param options
	 */
	public destroy(options?): void{
		this.objects = null;
		this.nodes = null;
		this.bounds = null;
	}
}