
export class Plan{
	planHasActiveSpace = false;
	name: string;
	[index: string]:any;
	activeLineX?: number;
	activeLineDate?: string;
	
	constructor(data){
		Object.assign(this, data);
		if(data.activeLineX !== undefined){ this.planHasActiveSpace = true; }
	}
}
