import { Sprite, Texture } from "pixi.js";
import { IFontObject, Utils, fontManager, EVENTS_FONT_MANAGER, IFontData, WordWrapper, ITriangulateData, glyphCache, IWordWrapOptions, WORD_WRAP_MODE, IGLDataCache } from "./";
import { ComponentManager } from "../component-system/";
import { LEVEL_OF_DETAIL_TABLE, LEVEL_OF_DETAIL_SIGNALS, LevelOfDetail } from "../core/LevelOfDetail";
import * as utils from "utils";
/**
 * Interface defining the valid font options
 */
export interface IFontOptions {
	fontSize: number,
	wordWrap?: boolean,
	wordWrapOptions: IWordWrapOptions
}
/**
 * An interface to manage triangulation culling when a parent object has a mask applies.
 */
export interface IMaskArea {
	width: number,
	height: number
}
/**
 * Gist:
 * 1. Given a font family, the FontPath calculates the triangles of the string.
 * 2. FontPath caches the trig calculation. It will be used in the FontRenderer at render time.
 * 4. When font is changed, recalc the triangles.
 */
export class FancyText extends Sprite implements IFontObject {
	public needsVAO: boolean = false;
	public active: boolean = false;
	public visible: boolean = false;
	//public components: ComponentManager;
	public isFancy: boolean = true;
	/**
	 * The component manager that manages this component
	 */
	public manager: ComponentManager;
	/**
	 * The thing where the component manager is attached too.
	 */
	public managerParent: any;
	/**
	 * Tracks if the vertex data needs recalculated
	 */
	public dirty: boolean = false;
	/**
	 * Stores the layout data for wordwrapping
	 */
	public layout: any;
	/**
	 * Stores the merged buffer for the FancyText object. Set in the FontRenderer.
	 */
	public glDatas: IGLDataCache[] = [];
	/**
	 * The total vertex count for this object. This is used when merging geometry so we can apply an offset to the indices
	 */
	public vertexCount: number;
	/**
	 * This is used as an optimization feature so we don't triangulate glyphs that are outside of a mask
	 * Set the FancyText.maskArea.width and FancyText.maskArea.height to set the dimensions of the culling
	 */
	public maskArea: IMaskArea = null;
	/**
	 * Stores a flat Float32Array of all of our merged vertex data.
	 */
	public mergedVertexData: Float32Array;
	/**
	 * Stores the Uint16Array of all of our indices
	 */
	public mergedIndexData: Uint16Array;
	/**
	 * Once the text is laid out, we calculate the width of the resulting data in pixels and store it here.
	 * The reason it is not stored in the inherited FancyText.width property is because then that would also change the
	 * FancyText.scale values.
	 */
	public widthPx: number = 1;
	/**
	 * Once the text is laid out, we calculate the height of the resulting data in pixels and store it here.
	 * The reason it is not stored in the inherited FancyText.width property is because then that would also change the
	 * FancyText.scale values.
	 */
	public heightPx: number = 1;
	/**
	 * The current text this object is displaying
	 */
	private _text: string = "";
	/**
	 * The font family this object is using. Main purpose of this is to use deferred loading and triangulation of the glyphs.
	 * Since it is possible that the canvas and canvas renderer will all initiate before the font file is loaded, we can create
	 * a watcher for loaded font files and only do the triangulation of the glyphs once a font matching the font family has been loaded.
	 */
	private _fontFamily: string = "";
	/**
	 * Contains the OpenType.js raw font data. We use this to perform word wrapping and use the raw path data to triangulate
	 * the glyphs so we can render over in GPU land.
	 */
	private _fontData: IFontData;
	/**
	 * Specific font drawing options this object follows.
	 */
	private _font: IFontOptions;
	/**
	 * Stores the last text. Used to determine if this object is "dirty" and needs a recalculation or not
	 */
	protected _lastText: string = "";
	/**
	 *
	 * @param {string} fontFamily The font family this object uses to draw fonts
	 * @param {string} text The default text for this object
	 * @param {IFontOptions} fontOptions Font drawing options and word wrapping options.
	 */
	constructor(fontFamily: string, text: string = "", fontOptions: IFontOptions = {fontSize: 22, wordWrapOptions: {mode: WORD_WRAP_MODE.PRE}}) {
		// Call parent Sprite and pass in an empty texture. This is a bit silly. Maybe extend Container instead?
		super(Texture.EMPTY);
		this.pluginName = "font-as-geometry";
		
		if (window.TouchPlanCanvas){
			window.TouchPlanCanvas.on("forceCalculateText", ()=>{
				this.calculateText();
			});
		}
		
		//this.components = new ComponentManager(this);
		
		// Set private properties.
		this._fontFamily = fontFamily;
		this._text = text;
		this._font = fontOptions;
		
		// Attempt to retrieve the font data via our font manager. If it fails, then setup a listener and wait for the
		// font-family we are expected to be loaded.
		let fontData = fontManager.getFont(fontFamily);
		if (fontData){
			this._fontData = fontData;
			// No reason to calc text for an empty string...
			if (text){
				this.calculateText();
			}
		}
		else {
			// We're using 'on' instead of 'once' here, since the fontManager emits an event for EACH font file loaded
			// So it is possible an event can fire which contains font data for a font we don't care about...
			fontManager.on(EVENTS_FONT_MANAGER.LOADED, this.onFontLoaded);
		}
	}
	/**
	 * Invoked when this object is destroyed
	 * @param {Object | boolean} options
	 */
	public destroy(options?: object|boolean){
		super.destroy(options);
	}
	/**
	 * Only used if the FontPath class failed to retrieve parsed font data in the constructor. This is the listener
	 * which is invoked for each loaded (and successfully parsed) font file.
	 * @param {string} fontFamily The string representing the font-family that was loaded
	 * @param fontData The parsed data for the loaded font.
	 */
	public onFontLoaded = (fontFamily: string, fontData: any): void => {
		if (fontFamily === this._fontFamily) {
			this._fontData = fontData;
			this.calculateText();
			
			// Clean-up the listener.
			fontManager.off(EVENTS_FONT_MANAGER.LOADED, this.onFontLoaded);
		}
	};
	/**
	 * Returns the current text string of this FontPath instance
	 */
	get text(): string{
		return this._text;
	}
	/**
	 * Sets the next text string for this FontPath instance and re-calculates the triangles and vertices for the string.
	 */
	set text(newValue: string) {
		if (this._lastText === newValue){return}
		this._lastText = newValue;
		this._text = newValue;
		this.dirty = true;
	}
	/**
	 * Returns the IFontOptions font options currently assigned to this FontPath instance.
	 */
	get font(): IFontOptions {
		return this._font;
	}
	/**
	 * Sets new IFontOptions for this FontPath instance. This also triggers a re-calculation of the vertices and triangles
	 * for the current value of FontPath._text
	 * @param fontOptions
	 */
	set font(fontOptions: IFontOptions) {
		if (this._font === fontOptions){return}
		this._font = fontOptions;
		this.dirty = true;
	}
	/**
	 * Get the current font data
	 */
	get fontData(): IFontData {
		return this._fontData
	}
	/**
	 * Sets the new font data for this instance
	 * @param {IFontData} data The new font data
	 */
	set fontData(data: IFontData) {
		if (this._fontData === data){return}
		this._fontData = data;
		this.dirty = true;
	}
	/**
	 * Returns the current font size
	 * @returns {number}
	 */
	get fontSize(): number {
		return this._font.fontSize
	}
	
	get fontFamily(): string {
		return this._fontFamily;
	}
	
	set fontFamily(family: string) {
		this._fontFamily = family;
	}
	
	/**
	 * Sets a new font size (px) and applies the new scale to this object
	 *
	 * Note: Setting a fontSize doesn't _actually_ set a fontSize, but rather calculates the new scale given the fontSize used to generate the cache
	 * @param {number} newSize
	 */
	set fontSize(newSize: number) {
		this._font.fontSize = newSize;
		this.scale.x = newSize / glyphCache.getFontSize(this._fontFamily);
		this.scale.y = newSize / glyphCache.getFontSize(this._fontFamily);
	}
	/**
	 * Returns the current font size
	 * @returns {number}
	 */
	get lineHeight(): string {
		return this._font.wordWrapOptions.lineHeight
	}
	/**
	 * Sets the new line height size, in em units
	 * @param {number} newSize
	 */
	set lineHeight(newSize: string) {
		if (newSize === this._font.wordWrapOptions.lineHeight){return}
		this._font.wordWrapOptions.lineHeight = newSize;
		this.dirty = true;
	}
	/**
	 * Get the current word wrap width
	 * @returns {number}
	 */
	get wordWrapWidth(): number|string {
		return this._font.wordWrapOptions.width
	}
	/**
	 * Set a new word wrap width value
	 * @param {number} newWidth
	 */
	set wordWrapWidth(newWidth: number|string) {
		if (newWidth !== this._font.wordWrapOptions.width){
			this._font.wordWrapOptions.width = newWidth;
			this.dirty = true;
		}
	}
	/**
	 * Set word wrapping
	 * @param {boolean} newValue
	 */
	set wordWrap(newValue: boolean){
		this._font.wordWrap = newValue;
		this.dirty = true;
	}
	/**
	 * Get the current alignment
	 * @returns {string}
	 */
	get alignment(): string {
		return this._font.wordWrapOptions.align
	}
	/**
	 * Set the new alignment
	 * @param {string} newAlign
	 */
	set alignment(newAlign: string) {
		if (newAlign !== this._font.wordWrapOptions.align){
			this._font.wordWrapOptions.align = newAlign;
			this.dirty = true;
		}
	}
	/**
	 * Re-calculates text. This should only be fired when the 'dirty' flag is true.
	 *
	 * Once text and layout has been re-calculated, marks this object as requiring a new VAO. The renderer will then re-generate one for drawing.
	 */
	public calculateText(force: boolean = false) {
		if (!force && !this.active || !this.visible || !this.renderable || !this.dirty || !this._fontData){return}
		// window.perf.log("start triangulation", {logSettle: true, spammy: true});
		// Convert our options into em units
		const convertedOptions: IWordWrapOptions = this._convertToEmUnits(this._font.wordWrapOptions);
		// Layout our string
		this.layout = WordWrapper.wrap(this._fontData, this._text, convertedOptions);
		
		// ------- async version ---------
		// this.widthPx = WordWrapper.getPxUnits(this._fontData, this._font.fontSize, this.layout.maxLineWidth);
		// this.heightPx = WordWrapper.getPxUnits(this._fontData, this._font.fontSize, this.layout.height);
		// 
		// glyphCache.getMergedStringAsync(this._fontFamily, this._fontData, this._font, this.layout, this._text).then((mergedData)=>{
		// 	this.mergedVertexData = mergedData.vertexData;
		// 	this.mergedIndexData = mergedData.indexData;
		// 	this.vertexCount = mergedData.vertexCount;
		// 	// Ticket is no longer dirty. However, we need to generate a new VAO.
		// 	this.dirty = false;
		// 	this.needsVAO = true;
		// 	window.perf.log("end triangulation", {logSettle: true, spammy: true, previousEventName: "start triangulation"});
		// 
		// 	this.emit("triangulationFinished");
		// })
		
		// Set dimensions of FancyText
		const mergedData: ITriangulateData = glyphCache.getMergedString(this._fontFamily, this._fontData, this._font, this.layout, this._text);
		this.mergedVertexData = mergedData.vertexData;
		this.mergedIndexData = mergedData.indexData;
		this.vertexCount = mergedData.vertexCount;
		
		// Set dimensions of FancyText
		this.widthPx = WordWrapper.getPxUnits(this._fontData, this._font.fontSize, this.layout.maxLineWidth);
		this.heightPx = WordWrapper.getPxUnits(this._fontData, this._font.fontSize, this.layout.height);
		
		// Ticket is no longer dirty. However, we need to generate a new VAO.
		this.dirty = false;
		this.needsVAO = true;
		// window.perf.log("end triangulation", {logSettle: true, spammy: true, previousEventName: "start triangulation"});
		
		if (window.TouchPlanCanvas){
			window.TouchPlanCanvas.emit("triangulationFinished");
		}
		
		return mergedData
	}
	/**
	 * Convert pixel units into Em units for font layout calculations
	 * @param {IWordWrapOptions} options
	 */
	private _convertToEmUnits(options: IWordWrapOptions = {}): IWordWrapOptions {
		const convertedOptions: IWordWrapOptions = Object.assign({}, options);
		const optionKeysToConvert: string[] = ["width", "letterSpacing", "lineHeight"];
		const fontSizePx = WordWrapper.getFontSize(this._fontData, this._font.fontSize);
		optionKeysToConvert.forEach((key: string) => {
			if (options[key]){
				convertedOptions[key] = WordWrapper.getEmUnits(this._fontData, fontSizePx, options[key]);
			}
		});
		
		return convertedOptions
	}
}
