/*
 * Ticket description.  Should have these behaviors
 * 
 * o A blue background and an 'x' to clear when there are multiple values
 * 
 * o A red background and an exclamation point (clickable for popup) when
 *   there is a validation error.
 * 
 * API convention:
 * 
 * This component is meant to be embedded in a parent component that has
 * a parent form.  It's expected that the parent passes it's FormGroup
 * reference in as an Input to this component.  Further, the parent must
 * also configure the form control as part of creating the form group,
 * specicifically referring to this component with the name "description",
 * and supplying a required validator.  The parent can optionally supply an
 * initial text value too.
 * 
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OnInit } from '@angular/core';

@Component({
	selector: 'ticket-description',
	template: require('./ticket-description.component.html'),
})
export class TicketDescriptionComponent implements OnInit {

	@Input() parentForm: FormGroup;

	isMultipleValues: boolean = false;
	isInputReady = false;

	ngOnInit() {
		console.log('parentForm (onInit): ', this.parentForm);
		this.isInputReady = true;
	}

	constructor(){
		console.log('TicketDescriptionComponent constructor');
		console.log('parentForm: ', this.parentForm);		
	}

	// computed property to turn on the "error state" thing in the spec
	get invalid() {
		return (
		  this.parentForm.get('description').hasError('required') &&
		  this.parentForm.get('description').touched
		);
	}
}
