import { fontManager, ITriangulateData, Utils, IFontData } from "../fontpath/";
/**
 * Cache generator:
 * triangulator.download("Roboto", 22, "roboto.json", [".", "-", "_", "+", "!", "#", "&", "\'", "*", "=", "?", "^", "~", "A", "a", "B", "b", "C", "c", "D", "d", "E", "e", "F", "f", "G", "g", "H", "h", "I", "i", "J", "j", "K", "k", "L", "l", "M", "m", "N", "n", "O", "o", "P", "p", "Q", "q", "R", "r", "S", "s", "T", "t", "U", "u", "V", "v", "W", "w", "X", "x", "Y", "y", "Z", "z", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"])
 */
export class FontTriangulator {
	public static VERSION: string = "1.1.0";
	public static getFontData(fontFamily: string): IFontData {
		return fontManager.getFont(fontFamily)
	}
	
	public static triangulate(fontData: IFontData, chars: string[], fontSize: number, x: number = 0, y: number = 0) {
		
		let data = {
			VERSION: FontTriangulator.VERSION,
			FONT_SIZE: fontSize
		};
		
		let a = performance.now();
		chars.forEach((char: string) => {
			const pathData = fontData.getPath(char, x, y, fontSize);
			const trigData: ITriangulateData = Utils.triangulate(pathData, 0);
			
			data[char] = {};
			data[char].vertexData = Array.from(trigData.vertexData);
			data[char].indexData = Array.from(trigData.indexData);
			data[char].vertexCount = trigData.vertexCount;
		});
		let b = performance.now();
		console.log("TRIANGULATION COST: " + (b - a) + "ms" + " for " + chars.length + " glyphs.");
		return data
	}
	
	public static generateAndSave(fontFamily: string, fontSize: number = 44){
		const chars: string[] = ["@", "$", "\\", "/", "(", ")", ".", ",", "-", "_", "+", "!", "#", "&", "\'", "*", "=", "?", "^", "~", "A", "a", "B", "b", "C", "c", "D", "d", "E", "e", "F", "f", "G", "g", "H", "h", "I", "i", "J", "j", "K", "k", "L", "l", "M", "m", "N", "n", "O", "o", "P", "p", "Q", "q", "R", "r", "S", "s", "T", "t", "U", "u", "V", "v", "W", "w", "X", "x", "Y", "y", "Z", "z", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
		
		const data = FontTriangulator.triangulate(
			FontTriangulator.getFontData(fontFamily),
			chars,
			fontSize
		);
		
		(<any>console).save(data, fontFamily + "-TrigData.json", 0);
	}
	
	public static generateDebug(fontFamily: string, char: string, fontSize: number){
		return FontTriangulator.triangulate(
			FontTriangulator.getFontData(fontFamily),
			[char],
			fontSize
		);
	}
	
	public static download(fontFamily: string, fontSize: number, fileName: string, chars: string[]) {
		const data = FontTriangulator.triangulate(
				FontTriangulator.getFontData(fontFamily),
				chars,
				fontSize
		);
		
		(<any>console).save(data, fileName);
	}
}

(<any>window).fontTrig = FontTriangulator;