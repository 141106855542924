'use strict';

import * as angular from "angular";
import * as utils from "utils";

import { analyticsService } from "ng2/common/utils/AnalyticsService";
declare const window;

import TicketFilter from "js/model/Ticket";

angular.module('ticketspaceApp')
.factory('firebaseTickets', ["$rootScope", "$q", "$window", "$firebaseUtils", "$firebaseArray", "accessCheck", "fbReporting", "fbConnection", "fbdbTime", "firebaseCustomOrdering", "SelectionCollection2", function($rootScope, $q, $window, $firebaseUtils, $firebaseArray, accessCheck, fbReporting, fbConnection, fbdbTime, firebaseCustomOrdering, SelectionCollection2) {

	/*
	// Declared but not used.
	var baseClass = this;

	var constants = {
		duplicateOffset: 25 //px
	};
	*/

	function Ticket(snap){
		this.$id = snap.key;
		this.local = {};
		this.update(snap);
	}
	Ticket.prototype = {
		update: function(snap){
			//store the data on...data
			var oldData = this.data || {};

			//standard update from angularfire, ducktyping
			if(snap && typeof snap.exists === "function"){
				this.data = snap.val();
				var changed = !angular.equals(this.data, oldData);
			}
			//assume snap is an object specifying "immediate" changes
			else{
				var changed = false;
				for(var key in snap){
					if(this.data[key] !== snap[key]){
						this.data[key] = snap[key];
						changed = true;
					}
				}
			}

			if(changed){
				var dispDate = "";
				if(this.data.plannedStart){ dispDate = utils.dateJar(this.data.plannedStart, 'MMM DD'); }
				else if(this.data.lastPlannedStart){dispDate = utils.dateJar(this.data.lastPlannedStart, 'MMM DD'); }
				if(this.data.durationDays && this.data.durationDays > 1){
					if(this.data.plannedFinish){
						dispDate += " - " + utils.dateJar(this.data.plannedFinish, 'MMM DD');
					}
					else if(this.data.lastPlannedFinish){
						dispDate += " - " + utils.dateJar(this.data.lastPlannedFinish, 'MMM DD');
					}
				}
				this.local.displayDate = dispDate;
				
				//constraint massaging test code
				// if(this.data.type === "constraint" && this.data.hasOwnProperty('plannedX') && this.data.plannedX !== this.data.left){
				// 	this.data.left = this.data.plannedX;
				// }
			}
			this.local.lastSnap = snap;
			return changed;
		},
		remove: function(){
			var self = this;
			return new $q(function(resolve, reject){
				//set a flag that can be used to indicate this ticket is actually deleted
				self.local.deleted = true;
				//remove animation
				if(self.local.element){
					self.local.element.addClass('animateOpacity');
					self.local.element.css({opacity: 0});
					setTimeout(resolve, 500);
				}
				else{ resolve(); }
			});
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		},
		//queries the local promise
		isUnstatused: function(){
			if(!this.local.promise){return false;}
			if(this.local.promise.actualFinish){return false;}
			return !this.local.promise.varianceReason;
		},
		validateChanges: validateTicketChanges
	};

	//Array instance section
	function TicketsFactory(ref){
		// call the super constructor
		return $firebaseArray.call(this, ref);
	}
	TicketsFactory.prototype = {
		$$added: function(snap){
			$firebaseArray.prototype.$$added.call(this,snap);
			var t = new Ticket(snap);
			t.$parent = this.$list;

			var self = this;
			if(this.futureSelectionCache && this.futureSelectionCache[snap.key]){
				delete this.futureSelectionCache[snap.key];
				setTimeout(function(){self.$list.selection.add(t);});
			}
			/*
			var xPos = findPos(t.data.left,'left',this.$list.xList);
			var yPos = findPos(t.data.top,'top',this.$list.yList);

			//potential optimization here, turn this off until $loaded, sort the initial collection once
			this.$list.xList.splice(xPos,0,t);
			this.$list.yList.splice(yPos,0,t);
			*/
			return t;
		},
		$$updated: function(snap){
			var t = this.$getRecord(snap.key);
			return  t.update(snap);
		},
		$$removed: function(snap){
			var rec = this.$getRecord(snap.key);
			this.$list.selection.remove(rec);
			return rec;
		},
		
		$$notify: function(){
			//console.log('notif');
			return $firebaseArray.prototype.$$notify.apply(this, arguments);
		},
		// $watch: function(){
		// 	//noop
		// },
		//sadly this doesn't work...consider making an "exists" check that includes local.deleted
		// $getRecord: function(){
		// 	var record = $firebaseArray.prototype.$getRecord.apply(this,arguments);
		// 	if(record && record.local && record.local.deleted){return null;}
		// 	return record;
		// },
		$remove: function(recordOrIndex){
			var record = (typeof recordOrIndex === "number") ? this.$list[recordOrIndex] : recordOrIndex;
			var self = this;
			return record.remove().then(function(){
				return $firebaseArray.prototype.$remove.call(self,recordOrIndex);
			});
		},
		$childRef: function(childId){
			return this.$ref().child(childId).ref;
		},
		//only save the specified data
		$saveLite: function(id, data){
			if(!data || !this.$getRecord(id)){return $q.rejectErr("id is undefined, can't save");}
			console.log('saving', id, data);
			return fbConnection.update(this.$list.$ref().ref.child(id), data);
		},
		$destroy: function $destroy(){
			this.$list.forEach(t => {
				t.$parent = null;
				t.local = null;
			});
			if(this.$list.selection){ this.$list.selection.empty(); }
			this.$list.selection = null;
			if(this.$list.ordering){ this.$list.ordering.destroy(); } //eff it
			this.$list.ordering = null;
			this.$list.config = null;
			return $firebaseArray.prototype.$destroy.apply(this,arguments);
		},
		saveAfterEdit: saveAfterEdit,
		getPlanId: function(){ return this.$list.$ref().ref.key; },
		getNewId: function(){ return this.$list.$ref().ref.push().key; },
		alwaysTickets: alwaysTickets,
		processLocal: processLocal,
		populateLocal: populateLocal,
		resetLocalStatus: resetLocalStatus,
		findPos: findPos,
		toggleVisibility: toggleVisibility,
		constructAddArray: constructAddArray,
		add: add,
		remove: remove,
		duplicate: duplicate,
		massSave: massSave,
		selectTicketsInRange: selectTicketsInRange,
		addListener: addListener,
		tryEmit: tryEmit,
		min: min,
		selectWhenPossible: selectWhenPossible
	};
	var theFactory = $firebaseArray.$extend(TicketsFactory);

	function saveAfterEdit(ticketList){
		var self = this;
		var promises = [];
		
		//var isReadOnly = accessCheck.isReadOnly();
		var isReadOnly = self.$list.isReadOnly();
		
		ticketList.forEach(function(ticket){
			//resume drawing pins
			//scope.promiseHistoryService.observe({unclear:true}, ticket.$id);
			self.processLocal(ticket, isReadOnly);

			//normal operation (not readOnly)
			if(!isReadOnly || self.$list.config.readOnlyImunity){
				promises.push(self.$save(ticket).catch(function(e){
					fbReporting.reportRealWarning("tried to set a broken actualFinish", {
						"actualFinish": ticket.local.actualFinish || null,
						"id": ticket.$id
					});
					Logging.warning("finish date invalid");
				}));
			}
			else{ //readonly save version
				var baseWrite = {
					actualFinish: ticket.data.actualFinish,
					lastPlannedFinish: ticket.data.lastPlannedFinish,
					lastPlannedStart: ticket.data.lastPlannedStart,
					promisePeriodStack: ticket.data.promisePeriodStack
				};

				//MOC-4483 - this might actually be the safest place to add this...
				// clear out new actual fields potentially added by r37 for better "r37 did this" detection
				if(ticket.data.actualCrewSize !== undefined) { baseWrite['actualCrewSize'] = ticket.data.actualCrewSize; }
				if(ticket.data.actualDurationDays !== undefined) { baseWrite['actualDurationDays'] = ticket.data.actualDurationDays; }
				if(ticket.data.actualStart !== undefined) { baseWrite['actualStart'] = ticket.data.actualStart; }
				if(ticket.data.actualWorkingDays !== undefined) { baseWrite['actualWorkingDays'] = ticket.data.actualWorkingDays; }

				promises.push(self.$saveLite(ticket.$id, baseWrite));
			}
			
			analyticsService.editedATicket(ticket.$id, self.getPlanId(), isReadOnly, 
					ticket.local.promise ? ticket.local.promise.plannedFinish : null,
					self.$list.config.whoAmI === "floatingTickets");
		});
		return $q.all(promises);
	}

	function toggleVisibility(ticket, on){
		if((ticket.local.shown) && on || (!ticket.local.shown && !on)){ return; }
		if(!ticket.local.element){return;}

		ticket.local.shown = on;

		if(on){ticket.local.element.removeClass('hide'); }
		else{ ticket.local.element.addClass('hide'); }
	}

	//potential optimization, alternate branches/ only branch with same x/y
	//currently
	function branchOut(targetTicket, pos, list, lastPos){
		//console.log('target', targetTicket, pos, list, lastPos);
		if(targetTicket.$id === list[pos].$id){ console.log('hit'); return pos; }
		if((pos+1) !== lastPos && (pos + 1) < list.length){
			var b = branchOut(targetTicket, pos+1, list, pos);
			if(b){return b;}
		}
		else{ return; }
		if((pos-1) !== lastPos && (pos-1) >= 0){
			var b = branchOut(targetTicket, pos-1, list, pos);
			if(b){return b;}
		}
		else {return; }
	}

	//binary search...
	function findPos(val,key, list){
		var low = 0, high = list.length-1, i;
		while(low <= high){
			i = (low+high) >> 1
			if(list[i].data[key] < val){ low = ++i; continue; }
			if(list[i].data[key] > val){ high = i - 1; continue; }
			break;
		}
		// if(list[i]){
			// console.log(i, list[i].data[key])
		// }
		return i || 0;
	}

	//more complex manipulation
	//can be manually added as a static by binding it to the "this"
	//of an instance. Consider making a convienence function that does that...

	//### alwaysTickets
	// accepts an array (or single) of ticket ids or tickets
	// and returns an array of tickets or false
	function alwaysTickets(ticketOrId){
		if(!ticketOrId){ console.log('invalid tickets'); return false; }
		var self = this;
		//array
		if(Array.isArray(ticketOrId)){
			var result = ticketOrId.map(function(t){
				if(typeof t === "string"){
					if(self.$getRecord(t)){ return self.$getRecord(t); }
					else{ return null; }
				}
				else{ return t; }
			});
			return result;
		}
		else{
			if(typeof ticketOrId === "string" && self.$getRecord(ticketOrId)){
				return [self.$getRecord(ticketOrId)];
			}
			else{
				return [ticketOrId];
			}
		}
	}
	
	function processLocal(ticket, isReadOnly){
		var local = ticket.local;
		
		// actualFinish: ticket.data.actualFinish,
		// lastPlannedFinish: ticket.data.lastPlannedFinish,
		// lastPlannedStart: ticket.data.lastPlannedStart,
		// promisePeriodStack: ticket.data.promisePeriodStack
		
		var propertyList = ['activityName', 'crewSize', 'durationDays', 'actualFinish', 
				'roleId', 'locationId', 'notes', 'responsibleProjectMemberId','assignedProjectMemberId','dateRequested', 'priorityId', 'type',  'procoreRfiId', 'responsibleProjectMemberRoleId', 'assignedProjectMemberRoleId'];
		if(isReadOnly){
			propertyList = ['actualFinish'];
		}
		//simple properties
		propertyList.forEach(function(key){
			ticket.data[key] = (ticket.local[key] !== undefined ? ticket.local[key] : null);
		});
		if(ticket.data.assignedProjectMemberId && ticket.data.assignedProjectMemberId === ticket.data.responsibleProjectMemberId){
			ticket.data.assignedProjectMemberId = null;
			ticket.data.assignedProjectMemberRoleId = null;
		}

		//promises
		if(ticket.data.promisePeriodStack){
			var currentPromise = ticket.data.promisePeriodStack[ticket.data.promisePeriodPosition];
			if(!ticket.data.actualFinish){ticket.data.actualFinish = null;}
			var actualFinish = ticket.data.actualFinish;

			if(!local.promise){
				local.promise = {};
				fbReporting.reportWarning("local promise deleted somehow", utils.makeStack(new Error("local promise deleted somehow")));
			}
			if(!currentPromise){
				var stack = utils.makeStack(new Error("tickets current promise is broken"));
				stack.ticketId = ticket.$id;
				stack.promisePeriodPosition = ticket.data.promisePeriodPosition;
				fbReporting.reportWarning("tickets current promise is broken", stack);
			}

			var varianceComment = local.promise.varianceComment || null;
			var varianceId = local.promise.varianceReason ? local.promise.varianceReason.$id : null;
			if(local.state === 'missed'){
				currentPromise.varianceId = varianceId;
				currentPromise.varianceComment = varianceComment;
				currentPromise.actualFinish = actualFinish;
			}
			else if(local.state === 'completed' && currentPromise.plannedFinish === actualFinish){
				currentPromise.varianceId = null;
				currentPromise.varianceComment = null;
				currentPromise.actualFinish = actualFinish;
			}
			else if(local.state === 'completed' && currentPromise.plannedFinish !== actualFinish){
				currentPromise.varianceId = varianceId;
				currentPromise.varianceComment = varianceComment;
				currentPromise.actualFinish = actualFinish;
			}
			else{ //un-completing
				currentPromise.actualFinish = null;
				currentPromise.varianceId = null;
				currentPromise.varianceComment = null;
			}
		}
		else{
			ticket.data.promisePeriodStack = null;
			ticket.data.promisePeriodPosition = null;
		}

		//status
		//ticket.data.status = ticket.local.status || null;
		if(ticket.data.actualFinish){
			if(!ticket.data.lastPlannedFinish){
				ticket.data.lastPlannedFinish = ticket.data.plannedFinish || null;
			}
			if(!ticket.data.lastPlannedStart){
				ticket.data.lastPlannedStart = ticket.data.plannedStart ||null;
			}
		}
		else{
			ticket.data.lastPlannedFinish = null;
			ticket.data.lastPlannedStart = null;
		}
	}

	function resetLocalStatus(ticket, variances){
		ticket.local.start = ticket.data.plannedStart || ticket.data.lastPlannedStart || null;
		ticket.local.finish = ticket.data.plannedFinish || ticket.data.lastPlannedFinish || null;
		
		if(ticket.data.promisePeriodPosition){
			ticket.local.promise = angular.extend({},ticket.data.promisePeriodStack[ticket.data.promisePeriodPosition]);
			ticket.local.promise.varianceReason = variances.$getRecord(ticket.local.promise.varianceId);

			if(ticket.local.promise.varianceReason){
				ticket.local.state = "missed";
			}
			else if(ticket.local.promise.actualFinish){
				ticket.local.state = "completed";
			}
			else{
				ticket.local.state = null;
			}
		}
		else{
			if(ticket.local.actualFinish && ticket.local.actualFinish !== ticket.local.finish){
				ticket.local.state = "missed";
			}
			else if(ticket.local.actualFinish){
				ticket.local.state = "completed";
			}
			else{
				ticket.local.state = null;
			}
		}
		

	}

	function populateLocal(ticket, variances){
		['activityName', 'crewSize', 'durationDays', 'actualFinish', 'roleId', 'locationId',
		 'responsibleProjectMemberId','assignedProjectMemberId', 'notes','dateRequested',
		 'priorityId', 'type', 'procoreRfiId', 'promisePeriodPosition'].forEach(function(key){
			ticket.local[key] = ticket.data[key];
		});
		
		//need to copy (literally) objects
		['promisePeriodStack'].forEach(function(key){
			ticket.local[key] = angular.extend({}, ticket.data[key]);
		});

		resetLocalStatus(ticket, variances);
	}

	function constructAddArray(tObj, count){
		var objArray; //calculated array
		if(!tObj){ tObj = {}; } //empty ticket
		if(tObj.length){ objArray = tObj; } //is array
		else{ //create array from template object
			if(!count){count = 1;}
			var objArray = tObj;
			var arr = [];
			for(var i=0; i<count; i++){arr.push(tObj);}
			objArray = arr;
		}
		return objArray;
	}

	//### massSave(ticketList, otherStuff, conf)
	// ticketList - an array of "ticket objects" including keys of what to update along with said data to update
	// each object is expected to include a special "id" key to identify the ticket (it will not be in the update)
	// otherStuff - an object containing a prebuilt update object to be updated along with the tickets (indexed from root)
	// conf - config object for extra settings
	// conf[applyLocalImmediately] (bool) - sets all the values in this update locally before pushing off to firebase
	// conf[noSet] (bool) - if true disables actually saving the data to firebase (pretty pointless unless applyLocalImmediately is also true)
	function massSave(ticketList, otherStuff, conf){
		var obj = otherStuff || {};
		var baseRef = this.$ref();
		var self = this;
		var s = fbConnection.stringify;
		conf = conf || {};

		ticketList.forEach(function(t){
			var fbTicket = self.$getRecord(t.id);
			if(!t.id || !fbTicket){return;} //only updates
			var id = t.id;
			delete t.id;
			if(!conf.noSet){
				for(var key in t){
					obj[s(baseRef.child(id).child(key))] = t[key];
				}
			}
			if(conf.applyLocalImmediately){
				fbTicket.update(t);
			}

		});

		return fbConnection.update(fbConnection.fbRef(), obj);
	}

	//returns the left-most ticket
	function min(){
		if(!this.$list.length){return null;}
		return this.$list.reduce(function(prev, curr){
			if(prev.data.left === curr.data.left){ return (prev.data.plannedStart < curr.data.plannedStart) ? prev : curr; }
			return (prev.data.left < curr.data.left) ? prev : curr;
		});
	}

	//this needs to be "this'd"
	function add(targetList){
		var promises = [];
		var self = this;

		var resultArray = [];

		var nextOrderings = this.$list.ordering.next(targetList.length);
		
		var newTotal = this.$list.length + targetList.length;
		if(newTotal >= 1000){
			Logging.warning("This plan has over 1,000 tickets - please contact support to explore your use case.");
		}
		targetList.forEach(function(obj, arrayPosition){
			var ticket = new TicketFilter(obj);
			ticket.createdAt = fbdbTime.raw.$value;
			ticket.userOrder = nextOrderings[arrayPosition];
			//only things being added are floating tickets, whose pos is irrelevant
			if(ticket.left === undefined || ticket.left === null){ticket.left = 0;}
			if(ticket.top === undefined || ticket.top === null){ticket.top = 0;}
			promises.push(self.$add(ticket, 'id').then(function(ref){
				var t = self.$getRecord(ref.key);
				resultArray[arrayPosition] = t;

				if(ticket.type === "constraint"){ analyticsService.addedAConstraint(ref.key, self.$list.getPlanId()); }
				else if(ticket.type === "milestone"){ analyticsService.addedAMilestone(ref.key, self.$list.getPlanId()); }
				else{ analyticsService.addedATicket(ref.key, self.$list.getPlanId()); }
			}).catch(function(e){
				console.log('e', e);
				fbReporting.reportRealWarning("tried to set a broken actualFinish with bulk add");
				Logging.warning("actualFinish format invalid");
			}));
		});

		return $q.all(promises).then(function(){
			self.tryEmit("add", resultArray);
			return resultArray;
		});
	}

	//this needs to be "this'd"
	function remove(targetList){
		var self = this;
		var promises = [];
		var idList = [];
		targetList.forEach(function(ticket){
			idList.push({"$id": ticket.$id, "data": angular.extend({}, ticket.data)});
			promises.push(self.$remove(ticket));
			var type = ticket.data.type ? ticket.data.type : "task";
			analyticsService.deletedATicket(ticket.$id, self.getPlanId(), 
					self.$list.config.whoAmI === "floatingTickets", type);
		});
		analyticsService.multiselectDelete(targetList.length, this.getPlanId());
		return $q.all(promises).then(function(){
			self.tryEmit("remove", idList);
		});
	}

	function duplicate(targetList, duplicateOffsetOverrides?){
		var self = this;
		if(!duplicateOffsetOverrides){ duplicateOffsetOverrides = []; }

		//working off the assumption that it's not necessary to duplicate completion related
		//data. Since your unlikely want it in a duplicate
		var acceptedKeys = ["activityName", "crewSize", "durationDays", "height",
				"left", "top", "width", "roleId", "locationId", "type", "priority", "creatorId",
				"responsibleProjectMemberId", "assignedProjectMemberId", "notes", "dateRequested"];
		var filteredList = [];
		targetList.forEach(function(ticket, idx){
			var obj:any = {};
			acceptedKeys.forEach(function(key){
				//copy from the ticket
				if(ticket.data[key] || ticket.data[key] === 0){ obj[key] = ticket.data[key]; }

				//override from edit mode
				if(ticket.local.isEdit && ticket.local[key] !== undefined){
					obj[key] = ticket.local[key];
				}
			});
			var dupOverride = duplicateOffsetOverrides[idx];
			
			var xOffset = dupOverride ? dupOverride.x : 0;
			var yOffset = dupOverride ? dupOverride.y : self.$list.duplicateOffset;
			
			
			obj.left += xOffset;
			obj.top += yOffset;

			filteredList.push(obj);
			analyticsService.duplicatedATicket(ticket.$id, self.getPlanId(), 
					self.$list.config.whoAmI === "floatingTickets");
		});

		//console.log( filteredList);

		return self.add(self.constructAddArray(filteredList)).then(function(resultArray){
			return mapPrecedence(targetList, resultArray).then(function(){
				analyticsService.multiselectDuplicate(resultArray.length, self.getPlanId());
				self.tryEmit("duplicate", resultArray);
				return {filteredList: filteredList, resultArray: resultArray};
			});
		});

	}

	//### mapPrecedence(oldList, newList)
	//internal function
	//takes the precedence out of one list, clones it with new id's to
	//the new list, based on relative position of each list.
	//Most likely this is only used by duplicate
	function mapPrecedence(oldList, newList){
		if(!oldList || !newList){return $q.rejectErr("precedence mapping failed");}
		var basePath = oldList[0].$parent.$ref().ref;
		var updateObj = {};
		var theMap = {};
		oldList.forEach(function(val, key){
			theMap[val.$id] = newList[key].$id;
		});

		oldList.forEach(function(val, key){
			utils.relationIterator(val, function(obj){
				if(!theMap[obj.id] || !theMap[val.$id]){ return; }
				updateObj[theMap[val.$id]+"/"+obj.cessor+"/"+obj.planId+"/"+theMap[obj.id]] = true;
			});
		});
		return fbConnection.update(basePath, updateObj);
	}
	
	//set up a method of combined firebase operations...
	//going to support remove and duplicate in the first cut
	function addListener(functionName, cbFunction){
		if(!this.listeners){ this.listeners = {}; }
		if(!this.listeners[functionName]){ this.listeners[functionName] = []; }
		this.listeners[functionName].push(cbFunction)
	}
	function tryEmit(functionName, data){
		var self = this;
		if(this.listeners && this.listeners[functionName] && this.listeners[functionName].length){
			this.listeners[functionName].forEach(function(cb){ cb(self.$list, data); });
		}
	}

	//return a reason for failure, or false if everything's fine
	//can accept a ticket argument or use "this" so it can be connected to a Ticket directly.
	function validateTicketChanges(ticket){
		if(!ticket){ ticket = this; }
		if(!ticket){return "ticket doesn't exist";}
		//variance is valid
		if( ticket.data.promisePeriodPosition
			&& (ticket.local.state == 'missed' || completionBad(ticket))
			&& !ticket.local.promise.varianceReason
			&& accessCheck.hasAccessToTicket(ticket)
		){
			return 'Must select a variance reason if ticket is not on time';
		}
		//clear out variance data if the ticket is on time
		if(ticket.local.actualFinish && ticket.local.promise && ticket.local.promise.plannedFinish === ticket.local.actualFinish ){
			if(ticket.local.promise.varianceId || ticket.local.promise.varianceReason){ delete ticket.local.promise.varianceId; delete ticket.local.promise.varianceReason; }
			if(ticket.local.promise.varianceComment){ delete ticket.local.promise.varianceComment; }
		}

		//todo - need to figure out TicketOps.completionBad
		// console.log('days', ticket.local.durationDays);
		//new and exciting ticket number validation
		if(ticket.data.type === "constraint" || ticket.data.type === "milestone"){
			//it can be whatever it wants
		}
		else{
			var result = valNum(ticket.local.durationDays, "Duration") || (valNumCrew(ticket.local.crewSize, "Crew", true) ) || false;
			if(result){ return result; }
		}
		
		function valNum(num, name, allowZero?){
			if(num === undefined || num === null){ return name+" needs to be set"; }
			if(isNaN(num)){ return name+" needs to be a number"; }
			if(num === 0 && !allowZero){ return name+" needs to be at least 1, perhaps you want a milestone?"; }
			if(num > 300){ return name+" is too large ("+num+")"; }
		}

		// temp bs, half ass this to keep is as stable as possible
		function valNumCrew(num, name, allowZero?){
			if(num === undefined || num === null){ return name+" needs to be set"; }
			if(isNaN(num)){ return name+" needs to be a number"; }
			if(num === 0 && !allowZero){ return name+" needs to be at least 1, perhaps you want a milestone?"; }
			if(num > 9999){ return name+" is too large ("+num+")"; }
		}
		
		
		/* MOC-1865
		if(ticket.local.durationDays !== ticket.data.durationDays){
			//possibly also a fringe case where the current finish date + proposed duration difference is compared to the 
			//promised finish date + proposed duration difference, and clear the variance reason when not completed
			if(ticket.data.promisePeriodPosition && ticket.data.promisePeriodStack){
				var promise = ticket.local.promise;
			}
			if(promise && !promise.varianceReason){
				ticket.local.state = "missed";
				return "Duration change requires a variance reason. "+ticket.data.durationDays+" changed to "+ticket.local.durationDays;
			}
		}
		*/

		return false;
	}
	
	function selectTicketsInRange(startX, endX, filterFn, intersect){
		startX = Math.round(startX);
		endX = Math.round(endX);
		var self = this;
		this.$list.forEach(function(t){
			var x = t.data.left+t.data.width;
			if((x > startX && x <= endX)
					|| (intersect && ((t.data.left >= startX && t.data.left < endX) || (t.data.left < startX && x > endX)) )){
				if(filterFn && !filterFn(t)){return;}
				self.$list.selection.add(t);
			}
		});
	}

	//this only effects unpromised tickets...
	function completionBad (ticket){
		var lt = ticket.local; //local ticket
		if(!lt.promise){
			if(!lt.actualFinish || (!ticket.data.plannedFinish && !ticket.data.lastPlannedFinish)){ return false; }
			return !(ticket.data.plannedFinish === lt.actualFinish || ticket.data.lastPlannedFinish === lt.actualFinish);
		}

		if(!lt.actualFinish || !lt.promise || !lt.promise.plannedFinish){return false; }
		return lt.actualFinish !== lt.promise.plannedFinish;
	}

	//setup a "select-when-added cache"
	function selectWhenPossible(id){
		if(!this.futureSelectionCache){this.futureSelectionCache = {};}
		this.futureSelectionCache[id] = id;
	}

	//config.readOnlyImunity: true
	//config.planIdFn
	//config.whoAmI: string //some list identification for analytics
	function returnFunction(refUrl, config){
		if(!config){ config = {}; }
		var temp = new theFactory(fbConnection.fbRef(refUrl));
		temp.xList = [];
		temp.yList = [];
		temp.duplicateOffset = 50;
		/*
		temp.selection = SelectionCollection($rootScope,
			function(id){ return temp.$getRecord(id) },
			function(id){ return temp.$getRecord(id).local.element });

		//can only add a single completed ticket to the selection
		//this is pulled off by blocking completed adds when the collection
		//isn't empty. And removing the first element if it's completed.
		temp.selection.on('add', function(ticketId, list){
			if(window.debug.disableCompletedCheck){return true;}
			if(list.length){
				if(temp.$getRecord(list[0]) && temp.$getRecord(list[0]).data.actualFinish){
					temp.selection.remove(list[0], true);
				}
				if(temp.$getRecord(ticketId) && temp.$getRecord(ticketId).data.actualFinish){
					return false;
				}
			}
			return true;
		});
		*/
		
		
		temp.selection = new SelectionCollection2($rootScope);

		//can only add a single completed ticket to the selection
		//this is pulled off by blocking completed adds when the collection
		//isn't empty. And removing the first element if it's completed.
		
		
		// temp.selection.on('pre-add', function(ticket, list){
		// 	//console.log('args', ticket, list);
		// 	if(window.debug.disableCompletedCheck){return true;}
		// 	if(list.length){
		// 		var firstItem = list[0];
		// 		if(firstItem && firstItem.data.actualFinish){
		// 			temp.selection.remove(firstItem, true);
		// 		}
		// 		if(ticket && ticket.data.actualFinish){
		// 			return false;
		// 		}
		// 	}
		// 	return true;
		// });
		
		

		temp.ordering = firebaseCustomOrdering(temp, temp.$ref().ref);
		temp.config = config;
		if(config.planIdFn){
			temp.getPlanId = config.planIdFn;
		}
		if(!config.whoAmI){config.whoAmI = "unknownList";}
		
		//a default isReadOnly check for tickets to use
		//applying it to tickets allows for it to be overriden by each respective list
		temp.isReadOnly = function(){
			return accessCheck.isReadOnly();
		};

		return temp;
	}
	//add properties to the returnFunction for static code

	(returnFunction as any).sortXY = function(a,b){
		if(a.data.top === b.data.top){
			return a.data.left - b.data.left;
		}
		return a.data.top - b.data.top;
	};




	return returnFunction;
}]);
