// Created by kdo on 10/19/15.
"use strict";

import * as angular from "angular";

import {LegacyRouting} from "ng2/routes/legacy-routing.service"

angular.module('ticketspaceApp').directive('snapshotPanel', ["popup", "firebaseProjects", function (popup, firebaseProjects) {
	return {
		restrict: 'EA',
		scope: true,
		bindToController: true,
		controllerAs: 'snapshotCtrl',
		controller: ["$scope", "legacyRouting", "authService", function($scope, legacyRouting:LegacyRouting, authService){
			// ---
			//Code here should be generic and may potentially be used in more than one directive (one day)
			// ---
			this.addSnap = function(){
				var state = legacyRouting.oldStyleSnapshot();

				var that = this;
				this.isLoading = true;
				Logging.notice("Currently saving snapshot.");
				return $scope.snapshots.takeSnapshot(state.projectId, state.planId, authService.user().id).then(function(data){
					//technically poor practice, but a hell of a lot easier then making a directive that watches ng-repeat for new cells...
					$('.restore-list').animate({scrollTop: 0});
					Logging.notice("Snapshot saved successfully.");

					return data;
				}).catch(function(error) {
					console.log(error.message);
					Logging.error("There has been a problem with saving the current plan.");
				}).finally(function(error) {
					that.isLoading = false;
				});
			};

			this.restoreSnapshot = function(snapshotId){
				if(!snapshotId){ Logging.error("There has been an issue restoring the plan. System can't find its id."); return; }
				
				var state = legacyRouting.oldStyleSnapshot();
				
				var that = this;
				this.isLoading = true;
				Logging.notice("Currently loading snapshot.");
				$scope.snapshots.restoreSnapshot(state.projectId, state.planId, authService.user().id).then(function(){
					//technically poor practice, but a hell of a lot easier then making a directive that watches ng-repeat for new cells...
					$('.restore-list').animate({scrollTop: 0});
					$scope.hud.scaleToFit();
					Logging.notice("Snapshot loaded successfully.");
				}).catch(function(error) {
					console.log(error.message);
					Logging.error("Snapshot loaded unsuccessfully. The plan was not restored.");
				}).finally(function(error) {
					that.isLoading = false;
				});
			};
		}],
		link: function(scope:any, el, attr, controller:any) {
			// ---
			//Code here controls the specific UI components in THIS directive should be defined in this link function
			// ---
			controller.isLoading = false;
			scope.newSnapshotData = {
				name: null,
				description: null,
				show: false
			};

			scope.clearNewSnapshot = function() {
				scope.newSnapshotData = {
					name: null,
					description: null,
					show: false
				};
			};

			scope.showNewSnapshot = function() {
				//Make sure other snapshots aren't being edited
				scope.snapshots.editIndex = 0;

				scope.newSnapshotData.show = true;
				$('.restore-list').animate({scrollTop: 0});
			};

			scope.saveNewSnapshot = function() {

				//Almost there just sync with the edit watch.
				var waitForEditingNewSnapshot = scope.$watch('snapshots.editIndex', function(newValue, oldValue) {
					if(newValue != 0 && newValue !== -1) {
						scope.snapshots.temp.name = scope.newSnapshotData.name;
						scope.snapshots.temp.description = scope.newSnapshotData.description;
						scope.snapshots.finishEdit().then(function() {
							scope.clearNewSnapshot();
						}).catch(function() {
							Logging.warning("Your snapshot has been saved but there was an error when trying to add its name and description");
						});

						waitForEditingNewSnapshot(); //It only needs to be run once.
					}
				});

				controller.addSnap();
			};

			scope.countAutoSnapshots = function(snapshots) {
				var count = 0;
				angular.forEach(snapshots, function(snapshot, key) {
					if(snapshot.isSystem())
						count++;
				});

				return count;
			};

			scope.getHiddenAutoSnapshotMessage = function(count) {
				if(count === 1)
					return "There is a hidden system auto save";
				else if(count > 1)
					return "There are " + count + " system auto saves.";
				else
					return undefined;
			};

			scope.editSnapshot = function(id) {
				//Make sure the new snapshot dialogue isn't open
				if(scope.newSnapshotData.show === false) {
					scope.snapshots.edit(id)
				}
			};
		},
		template: require("tpl/snapshot-panel.html")
	};
}]);
