import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { FancyFirebaseObject } from "../base/FancyFirebaseObject";
import { AngularLandConnection } from "../base/interfaces";
import { FbdbSetting } from 'ng2/common/models/FbdbSetting';

export class FbdbSettingsObject extends FancyFirebaseObject<FbdbSetting>{
	constructor(angularLand:AngularLandConnection, path:any){ //fix any's
		super(angularLand, path);
	}
	
	$$updated(child:any){
		if(!child || !child.payload.exists()){ return {}; }
		return new FbdbSetting(child.payload.val());
	}
}
