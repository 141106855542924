//todo
// argumentize things:
// - roles
// - projectMembers
// - invitedUsers
// - user (pmAdd)
// - projectId

'use strict';
import * as angular from "angular";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

angular.module('ticketspaceApp').directive('projectMemberPanel', ["popup", "firebaseProjects", "authService", function (popup, firebaseProjects, authService) {
	return {
		restrict: 'EA',
		scope: true,
		controllerAs: "ctrl",
		bindToController: {
			"roles": "=",
			"projectMembers": "=",
			"invitedUsers": "=",
			"projectId": "@",
			"ownerPmId": "@",
			"projectType": "@"
		},
		controller: ["$scope", "$q", "fbConnection", "fbdbTime", "firebaseEmailDirectory", "firebaseProjectMembersStatic", "firebaseMeshedUsersGlobal", "firebaseMeshedUsers", "fbdbRequests", "multipleRolesUtils", function($scope, $q, fbConnection, fbdbTime, firebaseEmailDirectory, firebaseProjectMembersStatic, firebaseMeshedUsersGlobal, firebaseMeshedUsers, fbdbRequests, multipleRolesUtils){
			var self = this;
			this.meshedUsers = firebaseMeshedUsersGlobal.getSync();
			
			this.meshedUsers.$loaded().then(()=>{
				this.meshedUsersOrdered = this.meshedUsers.sort( firebaseMeshedUsers.orderByNameComparator);
				this.meshWatchCancel = this.meshedUsers.$watch(()=>{
					this.meshedUsersOrdered = this.meshedUsers.sort( firebaseMeshedUsers.orderByNameComparator);
				});
			});
			
			this.pmPanelClone = function(index, collection){
				//console.log('clonin');
				if(index === -1){
					return { "accessLevel": "General" };
				}
				if(index !== -1 && collection[index] !== undefined){
					var data = collection[index].data;
					var obj = {
						"accessLevel": data.accessLevel,
						"email": data.email
					};
					var roleObj = self.roles.fb.$getRecord(data.roleId);
					if(data.roleId && roleObj && roleObj.$id){
						let pm = self.projectMembers.fb.$getRecord(data.projectMemberId) || data;
						obj["roleSelection"] = (data.accessLevel === 'Admin') ? roleObj : multipleRolesUtils.parseSecondaryRoles(self.roles.fb, pm);
					}
					//console.log('Roleobj', obj);
					return obj;
				}
				return {};
			}
			
			this.tryEdit = function(internal){
				console.log("WHO YOU?");
				if(this.projectType === "sampleProject"){
					var text = "Sample projects are limited to single use only. To have others join you, <br> create a new project and add them as project members.";
					return popup({
						template: '<button class="close" ng-click="close()">&#10005;</button> <br> <h3>'+text+'</h3>',
					});
				}
				else{
					return internal.openNew();
				}
			}
			
			this.pmSet = function(record, editModel){
				if (!editModel || !editModel.roleSelection || (editModel.accessLevel === 'General' && !editModel.roleSelection.length)){ return $q.rejectErr("Must have at least one role selected!");}
				var pmRecord = this.projectMembers.fb.$getRecord(record.data.projectMemberId);
				if(pmRecord){
					//todo, add some validation
					pmRecord.data.accessLevel = editModel.accessLevel;
					if(editModel.roleSelection){
						pmRecord.data.secondaryRoles = (pmRecord.data.accessLevel === 'Admin') ? null : multipleRolesUtils.parseRoleList(editModel.roleSelection);
						pmRecord.data.roleId = (pmRecord.data.accessLevel === 'Admin') ? editModel.roleSelection.$id : editModel.roleSelection[0].$id;
					}
					return this.projectMembers.fb.$save(pmRecord);
				}
				return $q.when(true);
			};
			
			this.pmInvitedSet = function(record, editModel){
				if (!editModel.roleSelection || (editModel.accessLevel === 'General' && !editModel.roleSelection.length)){
					Logging.warning("Must have at least one role selected!");
					return $q.reject()
				}
				var pmRecord = this.invitedUsers.$getRecord(record.$id);
				if(pmRecord){
					//todo, add some validation
					pmRecord.data.accessLevel = editModel.accessLevel;
					if(editModel.roleSelection){
						pmRecord.data.roleId = (editModel.accessLevel === 'Admin') ? editModel.roleSelection.$id : editModel.roleSelection[0].$id;
						pmRecord.data.secondaryRoles = (editModel.accessLevel === 'Admin') ? null : multipleRolesUtils.parseRoleList(editModel.roleSelection);
					}
					return this.invitedUsers.$save(pmRecord);
				}
				return $q.when(true);
			};
			
			this.pmAdd = function(editModel){
				if(!editModel.email){ return $q.rejectErr("An email is required"); }
				if(!editModel.roleSelection || (editModel.accessLevel === 'General' &&!editModel.roleSelection.length)){ return $q.rejectErr("A role is required"); }
				
				var projectId = this.projectId;
				var inviterUser = authService.userData;
				let secondaryRoles = (editModel.accessLevel === 'Admin') ? null : multipleRolesUtils.parseRoleList(editModel.roleSelection);
				return firebaseEmailDirectory.getUserId(editModel.email).then(function(userId){
					//invite existing user flow
					if(userId){
						console.log('existing');
						return firebaseProjectMembersStatic.addAtomic(projectId, {
							"userId": userId,
							"roleId": (editModel.accessLevel === 'Admin') ? editModel.roleSelection.$id : editModel.roleSelection[0].$id,
							"accessLevel": editModel.accessLevel,
							"secondaryRoles": secondaryRoles
						}).then(function(ref){
							var pmId = ref.key;
							analyticsService.addProjectMemberUserExists(pmId, editModel.email);
							var uid = utils.analyticsHelper.id(userId);
							return fbdbRequests.intercomInvite.oldUser(uid, projectId, inviterUser.$id); //<----------------------------------- THIS IS BREAKING for some reason...
						});
					}
					//invite new user flow
					else{
						console.log('new');
						//probably make this an instance of a projectMember after that refactor
						return fbConnection.loadObject('invitedUsers/projects/'+projectId+'/'+utils.escapeEmail(editModel.email)).then(function(data){
							if(data.$value === null){
								var user = {
									company: inviterUser.company ? inviterUser.company : '',
									email: editModel.email,
									accessLevel: editModel.accessLevel,
									roleId: (editModel.accessLevel === 'Admin') ? editModel.roleSelection.$id : editModel.roleSelection[0].$id,
									secondaryRoles: secondaryRoles,
									method: 'invite'
								};
								analyticsService.addProjectMemberUserDoesNotExist(editModel.email);
								if(fbdbTime.raw.$value){ user["invitedTimestamp"] = fbdbTime.raw.$value; }
								data.$ref().set(user);
								fbConnection.fbRef('invitedUsers/emails/'+utils.escapeEmail(editModel.email)+'/projects/'+projectId).set(projectId);
								return fbdbRequests.intercomInvite.newUser(editModel.email, projectId, inviterUser.$id);
							}
							else{
								return $q.reject({message:'User already invited'});
							}
						});
					}
				}); //.then, some limits stuff, do later
				
			}
			this.pmRemoveOwner = function(){
				Logging.warning("The owner cannot be removed from the project");
			}
			this.pmRemove = function(record){
				var self = this;
				return popup().then(function(){
					var projectId = self.projectId;
					var userId = record.data.userId;
					var pmId = record.data.projectMemberId;
					
					return self.projectMembers.remove(projectId, userId, pmId).then(function(){
						analyticsService.deletedAProjectMember(pmId, userId);
						//self.enforcer.decrement();
					});
				});
			}
			this.pmInvitedRemove = function(record){
				var self = this;
				var projectId = this.projectId;
				return popup().then(function(){
					fbConnection.fbRef('invitedUsers/emails/'+record.$id+'/projects/'+projectId).remove();
					return self.invitedUsers.$remove(record).then(function(){
						//self.enforcer.decrement();
						
						analyticsService.projectMemberInviteDeleted(record.$id.replace(',', '.'));
					});
				});
			};
			
			this.pmInvitedResendEmail = function(record, closeEdit?){
				var projectId = this.projectId;
				var userId = authService.userData.$id;
				var email = record.data.email;
				// MOC-2508
				if (closeEdit){closeEdit();}
				
				fbdbRequests.intercomInvite.newUser(email, projectId, userId).then(function() {
					Logging.notice("Successfully Resent Invite to " + email + ".");
					analyticsService.resendInvite(email, userId);
				}).catch(function(err) {
					Logging.warning(err.message);
				});
			};

			this.convertRoleSelection = function(editModel, accessLevel, pm?){
				if (accessLevel === 'Admin'){
					editModel.roleSelection = (editModel.roleSelection && editModel.roleSelection[0]) ? editModel.roleSelection[0] : null;
				} else {
					if (pm){
						let firstRole = (editModel.roleSelection && editModel.roleSelection instanceof Array) ? editModel.roleSelection[0] : editModel.roleSelection;
						if (firstRole){
							editModel.roleSelection = [firstRole];
						}else{
							editModel.roleSelection = multipleRolesUtils.unflattenAssignedRoles(this.roles.fb, pm.data.secondaryRoles, pm.data.roleId);
						}
					} else {
						editModel.roleSelection = [];
					}
				}
			};
			this.$onInit = function(){
				
			}
			
			this.$onDestroy = function(){
				if(this.meshWatchCancel){ this.meshWatchCancel(); }
			}

		}],
		link: function(scope:any, element, attrs){
		},
		template: require("tpl/panels/project-member-panel.html")
	};
}]);
