angular.module('CanvasWrapper').factory("GeometricShape", GeometricShapeFactory);

GeometricShapeFactory.$inject = ["PIXI"];
function GeometricShapeFactory(PIXI){
	/**
	 * @namespace GeometricShape
	 * @desc Helper class for drawing primitive shapes
	 * @returns {CanvasWrapper.GeometricShape}
	 * @memberOf CanvasWrapper
	 * @constructor
	 */
	var GeometricShape = function(options){
		// Create the graphics class
		options = options || {};
		var shape = new PIXI.Graphics(options.nativeLines || false);
		if (options && options.pos){
			shape.x = options.pos.x;
			shape.y = options.pos.y;
		}

		this.get = function(){
			return shape
		};

		this.addChild = function(child){
			return shape.addChild(child);
		};

		this.addHole = function(){
			return shape.addHole();
		};

		this.arc = function(cx, cy, radius, startAngle, endAngle, anticlockwise){
			return shape.arc(cx, cy, radius, startAngle, endAngle, anticlockwise);
		};

		this.arcTo = function(x1, y1, x2, y2, radius){
			return shape.arcTo(x1, y1, x2, y2, radius);
		};

		this.bezierCurveTo = function(cpX, cpY, cpX2, cpY2, toX, toY){
			return shape.bezierCurveTo(cpX, cpY, cpX2, cpY2, toX, toY);
		};

		this.clear = function(){
			return shape.clear();
		};

		this.clone = function(){
			return shape.clone();
		};

		this.kill = function(options){
			return shape.destroy(options);
		};

		this.drawCircle = function(radius){
			return shape.drawCircle(shape.x, shape.y, radius);
		};

		this.drawEllipse = function(width, radius){
			return shape.drawEllipse(shape.x, shape.y, width, radius);
		};

		this.drawPolygon = function(path){
			return shape.drawPolygon(path);
		};

		this.drawRect = function(width, height, fillColor, alpha){
			if (fillColor){shape.beginFill(parseInt(fillColor.substring(1), 16), alpha);}
			shape.drawRect(shape.x, shape.y, width, height);
			shape.endFill();
			return shape
		};

		this.drawRoundedRect = function(width, height, radius, fillColor, alpha){
			if (fillColor){shape.beginFill(parseInt(fillColor.substring(1), 16), alpha);}
			shape.drawRoundedRect(shape.x, shape.y, width, height, radius);
			shape.endFill();
			return shape
		};

		this.getTexture = function(scaleMode, resolution){
			return shape.generateCanvasTexture(scaleMode, resolution);
		};

		this.setVisible = function(value){
			shape.visible = value;
			return this;
		};

		this.getLocal = function(){
			return shape.getLocalBounds();
		};
	};

	return GeometricShape;
}
