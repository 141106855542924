/**
 * @namespace CanvasWrapper.Grid
 * @desc A manager class to help draw a grid.
 */
angular.module('CanvasWrapper').factory("Grid", GridManager);
/**
 * @namespace Grid
 * @returns {CanvasWrapper.Grid}
 * @memberOf CanvasWrapper.Grid
 * @constructor
 */
GridManager.$inject = ["PIXI", "GridTicks", "GridText"];
function GridManager(PIXI, GridTicks, GridText){
	function Grid(graphicsNode){
		// Public
		this.canvasWidth = 0;
		this.canvasHeight = 0;
		this.worldPosition = [0, 0];

		this.hticks = null;
		this.xAxisScale = 1.0;
		this.xAxisOffset = 0.0;
		this.xAnchor = 0.5;

		this.vticks = null;
		this.yAxisScale = 1.0;
		this.yAxisOffset = 0.0;
		this.yAnchor = 0.5;

		this._xAnchorOffset = 0.0;
		this._yAnchorOffset = 0.0;

		this.xDirection = 1.0;
		this.yDirection = 1.0;

		this.bgGraphics = new PIXI.Graphics(true);

		var self = this;

		activate();
		/* PRIVATE FUNCTIONSD */
		function activate(){
			graphicsNode.addChild(self.bgGraphics);
		}

		this.resize = function(w, h){
			this.canvasWidth = w;
			this.canvasHeight = h;
		};

		/* PUBLIC FUNCTIONS */
		this.setAnchor = function(x, y){
			this.xAnchor = clamp(x, -1, 1);
			this.yAnchor = clamp(y, -1, 1);
		};
		// recommended: [5,2], 0.001, 1000
		this.setScaleH = function(lods, minScale, maxScale, minSpacing, maxSpacing){
			this.hticks = new GridTicks().initTicks(lods, minScale, maxScale).spacing(minSpacing, maxSpacing);
			this.xAxisScale = clamp(this.xAxisScale, this.hticks.minValueScale, this.hticks.maxValueScale);
		};

		this.pixelToValueH = function(x){
			return (x - this.xAxisOffset) / this.xAxisScale;
		};

		this.valueToPixelH = function(x){
			return x * this.xAxisScale + this.xAxisOffset;
		};

		this.setMappingH = function(minValue, maxValue, pixelRange){
			this._xAnchorOffset = minValue / (maxValue - minValue);
			this.xDirection = (maxValue - minValue) > 0 ? 1 : -1;

			this.pixelToValueX = function(x){
				var pixelOffset = this.xAxisOffset;
				var ratio = this.canvasWidth / pixelRange;
				var u = _uninterpolate(0.0, this.canvasWidth);
				var i = _interpolate(minValue * ratio, maxValue * ratio);
				return i(u(x - pixelOffset)) / this.xAxisScale;
			};
			this.valueToPixelH = function(x){
				var pixelOffset = this.xAxisOffset;

				var ratio = this.canvasWidth / pixelRange;
				var u = _uninterpolate( minValue * ratio, maxValue * ratio );
				var i = _interpolate( 0.0, this.canvasWidth );
				return i(u(x * this.xAxisScale)) + pixelOffset;
			};
		};

		this.setRangeH = function(minValue, maxValue){
			this.xMinRange = minValue;
			this.xMaxRange = maxValue;
		};

		this.setScaleV = function(lods, minScale, maxScale, minSpacing, maxSpacing){
			this.vticks = new GridTicks().initTicks(lods, minScale, maxScale).spacing(minSpacing, maxSpacing);
			this.yAxisScale = clamp(this.yAxisScale, this.vticks.minValueScale, this.vticks.maxValueScale);
		};

		this.pixelToValueV = function(y){
			return (this.canvasHeight - y + this.yAxisOffset) / this.yAxisScale;
		};

		this.valueToPixelV = function(y){
			return -y * this.yAxisScale + this.canvasHeight + this.yAxisOffset;
		};

		this.setMappingV = function(minValue, maxValue, pixelRange){
			this._yAnchorOffset = minValue / (maxValue - minValue);
			this.yDirection = (maxValue - minValue) > 0 ? 1 : -1;

			this.pixelToValueV = function(y) {
				var pixelOffset = this.yAxisOffset;
				var ratio = this.canvasHeight / pixelRange;
				var u = _uninterpolate( 0.0, this.canvasHeight );
				var i = _interpolate( minValue * ratio, maxValue * ratio );
				return i(u(y - pixelOffset)) / this.yAxisScale;
			};

			this.valueToPixelV = function(y) {
				var pixelOffset = this.yAxisOffset;
				var ratio = this.canvasHeight / pixelRange;
				var u = _uninterpolate( minValue * ratio, maxValue * ratio );
				var i = _interpolate( 0.0, this.canvasHeight );
				return i(u(y * this.yAxisScale)) + pixelOffset;
			};
		};

		this.setRangeV = function(minValue, maxValue){
			this.yMinRange = minValue;
			this.yMaxRange = maxValue;
		};

		this.xAxisScaleAt = function(pixelX, scale){
			var oldValueX = this.pixelToValueH(pixelX);
			this.xAxisScale = clamp(scale, this.hticks.minValueScale, this.hticks.maxValueScale);
			var newScreenX = this.valueToPixelH(oldValueX);
			this.pan(pixelX - newScreenX, 0);
		};

		this.yAxisScaleAt = function(pixelY, scale){
			var oldValueY = this.pixelToValueV(pixelY);
			this.yAxisScale = clamp(scale, this.vticks.minValueScale, this.vticks.maxValueScale);
			var newScreenY = this.valueToPixelV(oldValueY);
			this.pan(0, pixelY - newScreenY);
		};

		this.xAxisSync = function(x, scaleX){
			this.xAxisOffset = x;
			this.xAxisScale = scaleX;
		};

		this.yAxisSync = function(y, scaleY){
			this.yAxisOffset = y;
			this.yAxisScale = scaleY;
		};

		this.scaleAction = function(offsetX, offsetY, newScale){
			if (this.hticks) {this.xAxisScaleAt(offsetX, newScale);}
			if (this.vticks ) {this.yAxisScaleAt(offsetY, newScale);}
		};

		this.resize = function(w, h){
			this.canvasWidth = w;
			this.canvasHeight = h;
		};

		this.drawHorizontalLines = function(leftEdge, rightEdge, lineColor, doNotClear){
			if (this.hticks) {
				var left = leftEdge;
				var right = rightEdge;
				lineColor = (lineColor) ? parseInt(lineColor.substring(1), 16) : 0x555555;
				if (!doNotClear){this.bgGraphics.clear();}
				this.bgGraphics.beginFill(lineColor);
				this.hticks.range(left, right, this.canvasWidth);
				for (var i = this.hticks.minTickLevel; i <= this.hticks.maxTickLevel; ++i) {
					var ratio = this.hticks.tickRatios[i];
					if (ratio > 0) {
						this.bgGraphics.lineStyle(1, lineColor, ratio * 0.5);
						var ticks = this.hticks.ticksAtLevel(i,true);
						for (var j = 0; j < ticks.length; ++j) {
							var screen_x = this.valueToPixelH(ticks[j]);
							this.bgGraphics.moveTo(_snapPixel(screen_x), -1.0 );
							this.bgGraphics.lineTo(_snapPixel(screen_x), this.canvasHeight);
						}
					}
				}
				this.bgGraphics.endFill();
			}
		};

		this.drawVerticalLines = function(topEdge, bottomEdge, lineColor, doNotClear){
			var top = topEdge;
			var bottom = bottomEdge;
			lineColor = (lineColor) ? parseInt(lineColor.substring(1), 16) : 0x555555;
			if (!doNotClear){this.bgGraphics.clear();}
			this.bgGraphics.beginFill(lineColor);
			this.vticks.range(top, bottom, this.canvasHeight);
			for (var i = this.vticks.minTickLevel; i <= this.vticks.maxTickLevel; ++i) {
				var ratio = this.vticks.tickRatios[i];
				if (ratio > 0) {
					this.bgGraphics.lineStyle(1, lineColor, ratio * 0.5);
					var ticks = this.vticks.ticksAtLevel(i,true);
					for (var j = 0; j < ticks.length; ++j) {
						var screen_y = this.valueToPixelV( ticks[j] );
						this.bgGraphics.moveTo( 0.0, _snapPixel(screen_y) );
						this.bgGraphics.lineTo( this.canvasWidth, _snapPixel(screen_y) );
					}
				}
			}
			this.bgGraphics.endFill();
		};

		this.pan = function(deltaX, deltaY){
			this.panX(deltaX);
			this.panY(deltaY);
		};

		this.panX = function(deltaPixelX){
			if (!this.valueToPixelH) {return;}

			var newOffset = this.xAxisOffset + deltaPixelX;
			this.xAxisOffset = 0.0; // calc range without offset

			var min, max;
			if (this.xMinRange !== undefined && this.xMinRange !== null) {min = this.valueToPixelH(this.xMinRange);}
			if (this.xMaxRange !== undefined && this.xMaxRange !== null) {
				max = this.valueToPixelH(this.xMaxRange);
				max = Math.max(0, max - this.canvasWidth);
			}

			this.xAxisOffset = newOffset;

			if (min !== undefined && max !== undefined) {
				this.xAxisOffset = clamp(this.xAxisOffset, -max, -min);
				return;
			}

			if (min !== undefined) {
				this.xAxisOffset = Math.min(this.xAxisOffset, -min);
				return;
			}

			if (max !== undefined) {
				this.xAxisOffset = Math.max(this.xAxisOffset, -max);
			}
		};

		this.panY = function(deltaPixelY){
			if (!this.valueToPixelV) {return;}

			var newOffset = this.yAxisOffset + deltaPixelY;
			this.yAxisOffset = 0.0; // calc range without offset

			var min, max;
			if (this.yMinRange !== undefined && this.yMinRange !== null) {min = this.valueToPixelV(this.yMinRange);}
			if (this.yMaxRange !== undefined && this.yMaxRange !== null) {
				max = this.valueToPixelV(this.yMaxRange);
				max = Math.max(0, max - this.canvasHeight);
			}

			this.yAxisOffset = newOffset;

			if (min !== undefined && max !== undefined) {
				this.yAxisOffset = clamp(this.yAxisOffset, -max, -min);
				return;
			}
			if (min !== undefined) {
				this.yAxisOffset = Math.min( this.yAxisOffset, -min );
				return;
			}

			if (max !== undefined) {
				this.yAxisOffset = Math.max( this.yAxisOffset, -max );
			}
		};

	}

	function _snapPixel(p){
		return Math.floor(p);
	}

	function _uninterpolate(a, b){
		b = (b -= a) || 1 / b;
		return function(x){ return (x - a) / b;};
	}

	function _interpolate(a, b){
		return function(t){return a * (1 - t) + b * t;};
	}

	function clamp(num, min, max){
		return Math.min(max,Math.max(min, num));
	}

	return Grid
}
