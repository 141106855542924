import { LEVEL_OF_DETAIL_TABLE_STRINGS } from "./LevelOfDetail";

import { TicketTypes, Side } from "ng2/common/models"

export enum TextComponents {
	activityNameComponent = "activityNameComponent"
}

export enum SpecialLODConditions {
	isOneDayTicket = "isOneDayTicket"
}

interface LodFontData{
	fontSize: number,
	lineHeight: string
}

export type DefinitionTypes = TextComponents | TicketTypes | Side | LEVEL_OF_DETAIL_TABLE_STRINGS | SpecialLODConditions;

//there must also be a value to fall back to
// if a level omits a value, that level will be assumed to have the value of it's parent
// It will attempt to grab the deepest nested value based on the keys provided.
const definitionTable = {
	[TicketTypes.TASK]: {
		value: {fontSize: 32, lineHeight: "32px"},
		[TextComponents.activityNameComponent]: {
			value: {fontSize: 32, lineHeight: "36px"},
			[Side.ACTIVE]: {
				value: {fontSize: 42, lineHeight: "36px"},
				[LEVEL_OF_DETAIL_TABLE_STRINGS.MAXIMUM]: {
					value: {fontSize: 28, lineHeight: "28px"}
				},
				[LEVEL_OF_DETAIL_TABLE_STRINGS.VERY_HIGH]: {
					value: {fontSize: 32, lineHeight: "32px"}
				},
				[SpecialLODConditions.isOneDayTicket]: {
					value: {fontSize: 32, lineHeight: "32px"},
					[LEVEL_OF_DETAIL_TABLE_STRINGS.MAXIMUM]: {
						value: {fontSize: 24, lineHeight: "24px"}
					},
					[LEVEL_OF_DETAIL_TABLE_STRINGS.VERY_HIGH]: {
						value: {fontSize: 28, lineHeight: "28px"}
					}
				}
			}
		}
	},
	[TicketTypes.CONSTRAINT]: {
		value: {fontSize: 32, lineHeight: "32px"},
		[TextComponents.activityNameComponent]: {
			value: {fontSize: 32, lineHeight: "36px"},
			[Side.ACTIVE]: {
				value: {fontSize: 32, lineHeight: "32px"},
				[LEVEL_OF_DETAIL_TABLE_STRINGS.MAXIMUM]: {
					value: {fontSize: 24, lineHeight: "24px"}
				},
				[LEVEL_OF_DETAIL_TABLE_STRINGS.VERY_HIGH]: {
					value: {fontSize: 28, lineHeight: "28px"}
				}
			}
		}
	},
	[TicketTypes.MILESTONE]: {
		value: {fontSize: 32, lineHeight: "32px"},
		[TextComponents.activityNameComponent]: {
			value: {fontSize: 32, lineHeight: "36px"},
			[Side.ACTIVE]: {
				value: {fontSize: 32, lineHeight: "32px"},
				[LEVEL_OF_DETAIL_TABLE_STRINGS.MAXIMUM]: {
					value: {fontSize: 24, lineHeight: "24px"}
				},
				[LEVEL_OF_DETAIL_TABLE_STRINGS.VERY_HIGH]: {
					value: {fontSize: 28, lineHeight: "28px"}
				}
			}
		}
	},
}

export function getLodDefinition(keys:DefinitionTypes[]):LodFontData{
	let lastThing = definitionTable;
	let lastValue;
	
	for(var i = 0; i < keys.length; i++){
		let key = keys[i];
		let thing = lastThing[key];
		if(thing){
			lastThing = thing;
			if(thing.value){ lastValue = thing.value; }
		}
		else { break; }
	}
	return lastValue;
}

// MOC-3093
window.ticketTextScale = 1;
window.setTextScale = function(newScale: number){
	window.ticketTextScale = newScale;
};