'use strict';

import * as angular from "angular";
import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

//todo: add a watch function to the user generation/ project generation stuff that allows the generated
//array to be updated when new entires are added

angular.module('ticketspaceApp')
.factory('firebaseUsersGetters', ["$q", "$firebaseObject", "fbConnection", "projectNotifier", "firebaseUsersPresence", function($q, $firebaseObject, fbConnection, projectNotifier: ProjectNotifier, firebaseUsersPresence) {

	//## Manage the users list
	
	//handle the users list promise
	var usersDefer = $q.defer();
	var deferResolved = false;
	var _users;
	//tracks the user count
	var usersListCount = 0;
	
	//handles the actual users promise, and maps it to the one returned
	function handlePromise(resolve){
		
		// stale user ref bug is probably caused here
		// need some way of handling the case where several state changes happen
		// and they resolve in a manner that causes the user list to be out of date
		// for brief periods of time, perhaps rejecting promises or something...
		
		if(lastProjectId !== resolve.projectId){
			console.warn('extra user request');
			usersDefer.reject('extra user request');
			return;
		}
		
		_users = resolve.users; 
		deferResolved = true;
		usersDefer.resolve(_users);
	}
	
	//manage the promise state, update users list when project changes
	var lastProjectId = null;
	projectNotifier.projectState.subscribe(function(state){
		if(state.projectId && lastProjectId !== state.projectId){
			lastProjectId = state.projectId;
			if(deferResolved){ usersDefer = $q.defer(); }
			usersForProject(lastProjectId).then(handlePromise);
		}
	});
	
	//todo performance - cache generated initials/ names, empty on $$update
	var uFactory = $firebaseObject.$extend({
		//consider returning a size itentifier with the text so the font can be scaled according to the number of chars
		initials: function(){
			if(this.$value === null){ return 'XX'; }
			if(!this.email){ return 'XX'; }
			if(!this.firstName && !this.lastName){return this.email[0]+this.email[1];}
			if(!this.lastName){ return this.firstName[0]; }
			if(!this.firstName){ return this.lastName[0]; }
			
			return this.firstName[0] + this.lastName[0];
		},
		
		parsedName: function(){
			if(this.$value === null){ return 'userless'; }
			if(!this.firstName && !this.lastName){return this.email;}
			return (this.firstName ? this.firstName : '') +' '+ (this.lastName ? this.lastName : '');
		},
		
		$$updated: function(snap){
			// apply the changes using, here we'll just use the generic "updateRecord"
			
			//checking if the id key has works here because security rules
			//prevent it from being different then $id, it might be better to
			//compare the $id against the current list though
			
			updateCount(this.id, snap.child('id').val());
			
			return $firebaseObject.prototype.$$updated.apply(this, arguments);
			//if(snap.exists()){ usersListCount++; }
			//else{ usersListCount--; }
			//this = snap.val();
		},
		
		$$error: function(err){}
	});
	
	function updateCount(old, current){
		if((!old && !current) || old === current){}
		else if(old){
			usersListCount--;
		}
		else if(current){
			usersListCount++;
		}
		//console.log('old', old, 'new', current, usersListCount);
	}
	
	
	//#### usersForProject(projectId)
	//returns a promise resolving to a list of users
	function usersForProject(projectId){
		if(!projectId){return $q.reject();}
		if(projectId === 'SampleProjectV3'){return $q.reject();}
		
		usersListCount = 0;
		var defer = $q.defer();
		
		//truly an astounding degree of javascript bs
		var userList:any = {};
		userList.users = Object.create({
			get length(){ return usersListCount; }
		});
		
		function getPresence(){
			//clear out old presence refs (they keep updating)
			if(userList.presence){
				for(var key in userList.presence){
					if(key !== 'length'){
						//console.log('key', key);
						//perfomance - consider only destroying the removed ones, might work
						userList.presence[key].$destroy();
					}
				}
			}
			firebaseUsersPresence.getPresenceList(userList.users).then(function(p){
				userList.presence = p;
			});
		}
		
		var userIdList = fbConnection.fbArray('projects/'+projectId+'/users');
		userIdList.$loaded().then(function(){
			var batch = fbConnection.batch();
			userIdList.forEach(function(userId){
				var user = new uFactory(fbConnection.fbRef('users/'+userId.$id));
				batch.push(user.$loaded().then(function(){
						userList.users[user.id] = user;
					}));
			});
			batch.end().then(function(){
				//console.log('userList', userList.users, userList, projectId);
				defer.resolve({users: userList, projectId: projectId});
				getPresence();
				
				userIdList.$watch(function(change){
					if(change.event === "child_removed"){
						if(userList.users[change.key]){
							//console.log('change', change.key, userList.users[change.key], userList.users);
							userList.users[change.key].$destroy();
							usersListCount--;
							delete userList.users[change.key];
							getPresence();
						}
					}
					else if(change.event === "child_added"){
						if(!userList.users[change.key]){
							//userList.users[change.key]
							var user = new uFactory(fbConnection.fbRef('users/'+change.key));
							user.$loaded().then(function(){
								userList.users[user.id] = user;
								getPresence();
							});
						}
					}
				});
			});
		});
		return defer.promise;
	}
	
	function userFromUserId(userId, waitForUserToPopulate){
		var user = new uFactory(fbConnection.fbRef('users/'+userId));
		var promise = user.$loaded().then(function(){ return user; 	});
		if(!waitForUserToPopulate){ return promise; }
		
		//special case for the creating a user flow
		//which can resolve before the user object exists
		else{
			//console.log("waiting for user");
			return promise.then(function(user){
				//console.log('user loaded', user.id, user);
				if(user.$value === null){
					console.log('user data is null');
				}
				if(user.id){ return user; }
				else{
					//Logging.debug("this is where it would have failed");
					//using defer to prevent context switching...
					//on the off chance that's causing something
					var defer = $q.defer();
					
					var unwatch = user.$watch(function(){
						if(user.$value !== null){
							//Logging.debug("...and now it didn't");
							unwatch();
							defer.resolve(user);
						}
					});
					
					return defer.promise;
				}
			});
		}
	}
	
	return {
		getUsers: function(){ return usersDefer.promise; },
		getUsersSync: function(){ if(!deferResolved){ return false; } return _users;},
		userFromUserId: userFromUserId
	};
}]);
