import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {LegaleseComponent} from "./legalese/legalese.component";
import { FancyCheckboxComponent } from "./fancy-checkbox/fancy-checkbox.component";
import { LoadingBlockerDirective, LoadingBlockerSpinnerComponent, LoadingBlockerSuccessComponent, LoadingBlockerFailureComponent } from "./loading-blocker";
import { DropdownMenuComponent } from "./dropdown-menu/dropdown-menu.component";
import { NavBarComponent, SupportMenuComponent, NavBarAccountComponent, NavBarDashboardComponent, NavBarSignUpComponent, NavBarPlanComponent } from "./nav-bar/";
import { CloseWhenIgnoredDirective } from "./close-when-ignored/close-when-ignored.directive";
import { AccessCodeQuickJoinComponent } from "./access-code-quick-join/access-code-quick-join.component";
import { TicketSearchComponent } from "../../../js/components/ticket-search.component";
import { RoleChangerComponent } from "./role-changer/role-changer.component";
import { DropdownModule } from "primeng/primeng";
import { MultiSelectModule } from "primeng/primeng";
import { MultiRoleSelectComponent } from "./multi-role-select/multi-role-select.component";
import { MeetingPopup } from "./meeting-popup/meeting-popup.component";
import { DraggableContainerComponent } from "./draggable-container/draggable-container.component";

@NgModule({
	imports: [
		ReactiveFormsModule,
		CommonModule,
		DropdownModule,
		MultiSelectModule,
		FormsModule
	],
	declarations: [
		CloseWhenIgnoredDirective,
		LegaleseComponent,
		FancyCheckboxComponent,
		LoadingBlockerDirective,
		LoadingBlockerSpinnerComponent,
		LoadingBlockerSuccessComponent,
		LoadingBlockerFailureComponent,
		DropdownMenuComponent,
		NavBarComponent,
		SupportMenuComponent,
		NavBarAccountComponent,
		NavBarDashboardComponent,
		NavBarSignUpComponent,
		NavBarPlanComponent,
		AccessCodeQuickJoinComponent,
		TicketSearchComponent,
		RoleChangerComponent,
		MultiRoleSelectComponent,
		MeetingPopup,
		DraggableContainerComponent
	],
	exports: [
		CloseWhenIgnoredDirective,
		LegaleseComponent,
		FancyCheckboxComponent,
		LoadingBlockerDirective,
		LoadingBlockerSpinnerComponent,
		LoadingBlockerSuccessComponent,
		LoadingBlockerFailureComponent,
		DropdownMenuComponent,
		NavBarComponent,
		SupportMenuComponent,
		NavBarAccountComponent,
		NavBarDashboardComponent,
		NavBarSignUpComponent,
		NavBarPlanComponent,
		AccessCodeQuickJoinComponent,
		TicketSearchComponent,
		RoleChangerComponent,
		MultiRoleSelectComponent,
		MeetingPopup,
		DraggableContainerComponent
	],
	entryComponents: [
		LoadingBlockerSpinnerComponent,
		LoadingBlockerSuccessComponent,
		LoadingBlockerFailureComponent,
		NavBarDashboardComponent,
		NavBarSignUpComponent,
		NavBarPlanComponent
	],
	providers: [
	
	]
})
export class UtilityComponentsModule{}
