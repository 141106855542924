'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";

declare const window: any;


//MOVE TO THE BOTTOM LATER

//event is prepended with 'fbdb request '
function analyticsStuff(event, requestName, requestId, extra?){
	var obj = {
		requestName: requestName,
		requestId: requestId,
		date: new Date() //use client-side date, server side might be too slow
	};
	angular.extend(obj, extra);
	utils.analyticsHelper.track('fbdb request ' + event, obj);
}








export class FbdbRequests{
	promiseArchive = [];
	refArchive = [];
	progressArchive = [];
	
	static $inject = ["$q", "$timeout", "fbConnection", "presence", "authService"];
	constructor(private $q, private $timeout, private fbConnection, private presence, private authService){
		window.debug.add('disableRequestDeletionOnSuccess', false);
		//expose it to the world
		(<any>window).requestCheckIn = this.requestCheckIn;
		
	}
	
	getUserId(){
		var auth = this.authService.auth.$getAuth();
		if(auth && auth.uid){ return auth.uid; }
		else{ return null; }
	}
	
	/**
	 * generic request object spec'd out matching general spec given on MOC-413
	 * @param  requestName     string containing request name, read by fbdb
	 * @param  params          object containing fully build params object
	 * @param  preventDeletion [description]
	 * @return                 a promise containing the the response object cloned from the angularfire object
	 */
	private _request(requestName, params?, preventDeletion?){
		
		// console.log('current zone, _request start', currentZone);
		preventDeletion = preventDeletion || window.debug.disableRequestDeletionOnSuccess;
		params = params || {};
		
		var startTime = moment();
		
		var defer = this.$q.defer();
		
		if(window.statusMessageDisplayed){
			return this.$q.rejectErr("Requests are temporarily unavailable");
		}
		//validate params first...just go naive and assume single tier
		//going with "clean up and send anyways", could also reject the request
		for(var key in params){
			if(params[key] === undefined){
				console.warn("undefined found on key: "+ key);
				delete params[key];
			}
		}
		
		
		var requestRef = this.fbConnection.fbRef('fbdbRequests');
		var requestChild = requestRef.push();
		var requestObj = {
			name: requestName,
			params: params,
			requestingUserId: this.getUserId()
		};
		
		var archivePos = this.buildPromiseArchive(defer, requestChild);
		
		this.fbConnection.loadObject('fbdbRequests/'+requestChild.key+'/results').then((response)=>{
			//return value here isn't really interesting, the error might be
			this.fbConnection.update(requestChild, requestObj).then(()=>{})
			.catch((err)=>{ console.log('err', err); });
			analyticsStuff('started', requestName, requestChild.key);

			var longWait = setTimeout(()=>{
				if(response.startedAt){
					Logging.debug('fbdb is taking a long time to respond');
				}
				else{
					unwatch();
					// defer.reject({message: 'request timed out', ref: requestChild});
					// if(!preventDeletion){ requestChild.remove(); }
					this.cancel(defer.promise, {message: 'request timed out', ref: requestChild, recovered: true});
				}
			}, 30000);

			var unwatch = response.$watch(()=>{
				if(response.errorMessage){
					unwatch();
					clearTimeout(longWait);
					analyticsStuff('hit error', requestName, requestChild.key, {
						error: response.errorMessage,
						interval: moment().diff(startTime, 'seconds')
					});
					defer.reject({message: response.errorMessage, ref: requestChild});
				}
				else if(response.completedAt){
					// console.log('current zone, _request end', currentZone);
					//result is completed, clean up
					unwatch();
					clearTimeout(longWait);
					
					analyticsStuff('completed', requestName, requestChild.key, {
						interval: moment().diff(startTime, 'seconds')
					});
					
					var copiedResp = angular.extend({}, response);
					
					//final status update
					if(this.progressArchive[archivePos] && response.statusMessage && defer.promise.$$state.status === 0){
						this.progressArchive[archivePos](response);
					}
					//then resolve
					defer.resolve({requestId: requestChild.key, response: copiedResp});
					setTimeout(()=>{
						response.$destroy();
					});
					

					if(!preventDeletion){ requestChild.remove(); }
				}
				if(this.progressArchive[archivePos] && response.statusMessage){
					if(defer.promise.$$state.status === 0){
						this.progressArchive[archivePos](response);
					}
				}
			});
		});
		
		return defer.promise;
	}
	
	buildPromiseArchive(defer, requestRef){
		this.promiseArchive.push(defer);
		this.refArchive.push(requestRef);
		this.progressArchive.push(null); //just set null for now...
		
		defer.promise.finally(()=>{
			//splice things
			var index = this.promiseArchive.indexOf(defer);
			if(index === -1){ console.log('promise not found, this is concerning'); }
			else{
				this.promiseArchive.splice(index, 1);
				this.refArchive.splice(index, 1);
				this.progressArchive.splice(index, 1);
			}
		});
		console.log('promiseArchive', this.promiseArchive, this.refArchive);
		return this.promiseArchive.length-1;
	}
	
	findPromise(promise){
		//todo, before it's complete the promise seems to store
		//some info that might be the promise layers. We might
		//be able to latch onto that and support cancelling from any tier.
		if(promise.$$state.status !== 0){
			console.log("promise already done");
			return -1;
		}
		
		var index = -1;
		this.promiseArchive.some(function(d,k){if(d.promise === promise){index = k; return true;}});
		return index;
	}
	
	//## Syncronous inspection api
	// There is somewhat limited support for interacting with promises
	// between creation and resolution. Currently they are getProgress
	// and cancel. They are used as static methods on the fbdbRequests
	// object, passing in the progress as the first argument. There is
	// a key distinction here the promise passed has to be the same as
	// the promise returned by another fbdbRequests request, since its
	// used for identification. For example:
	// * (valid) var promise = fbdbRequests.thing();
	// * (invalid) var promise = fbdbRequests.thing().then();
	//
	// It's also worth noting, not every request will support these.
	// Either because there's no fbdb support, or becase there's
	// internal "re-promising" in fbdbRequests (these should be refactored
	// out over time).

	/**
	 * subscribe for progress notifications. Only one subscriber is supported.
	 * @param  promise [description]
	 * @param  cb      [description]
	 * @return         Returns the entire response object currently. May switch to only watching
	 * the statusMessage key. It's possible this may also be implemented as an inline call as part of
	 * request creation.
	 */
	getProgress(promise, cb){
		var index = this.findPromise(promise);
		if(index !== -1){
			this.progressArchive[index] = cb;
		}
	}
	
	//### cancel(promise, customReason)
	// Cancel the request. Calling this rejects the existing request,
	// in addition to setting the isCancelled flag as a notification for
	// fbdb.
	// * customReason - provide a different reject reason
	cancel(promise, customReason){
		var index = this.findPromise(promise);
		if(index !== -1){
			if(customReason){ this.promiseArchive[index].reject(customReason); }
			else{ this.promiseArchive[index].reject({message: "cancelled request", recovered: true}); }
			
			analyticsStuff('cancelled', null, this.refArchive[index].key);
			
			//could theoretically return a promise for the success of this
			//but eh...
			this.refArchive[index].child('isCancelled').set(true);
		}
	}
	
	/**
	 * wrapper function that calls _request() and returns the result
	 * @param  projectId   [description]
	 * @param  userId      [description]
	 * @param  limitations [description]
	 * @return             [description]
	 */
	projectCopy(projectId, userId, limitations?){
		var params:any = {
			srcProjectId: projectId,
			ownerUserId: userId
		};
		if(limitations){ //consider sanityChecking
			params.projectLimitations = limitations;
		}
		
		this.presence.pokeProjectPresence(projectId);
		
		return this._request('projectCopy', params).then((data)=>{
			if(data && data.response && data.response.destProjectId){
				this.presence.pokeProjectPresence(data.response.destProjectId);
			}
			return data;
		})
	}
	
	defaultProjectCopy(userId){
		return this.projectCopy('sampleProjectV2', userId);
	}
	
	//copies a plan
	//args:
	//(required)
	//projectId - id of the project the plan is in
	//planId - id of the plan to be copied
	//(optional)
	//nameWithPattern - value to be set at /plans/$projectId/$planId/name
	//	replaces occurances of:
	//	{0} with the name of the source plan
	//	{1} with the copy number, use with nCopies
	//nCopies - number of copies of the plan to make
	//userId - value to fill into the requestingUserId field of the request
	planCopy(projectId, planId, nameWithPattern, nCopies, userId){
		var params:any = {
			srcProjectId: projectId,
			srcPlanId: planId
		};
		if(nameWithPattern){
			params.nameFormatPattern = nameWithPattern;
		}
		if(nCopies){
			params.nCopies = nCopies;
		}
		
		return this._request('planCopy', params);
	}
	
	defaultReportNames = {
		"sixWeekLookAhead": "Six Week Look Ahead",
		"weeklyWorkPlan": "Weekly Work Plan",
		"ganttView": "Gantt Project Report",
		"constraintLog": "Constraint Log",
		"ppcVarianceByRole": "PPC Variance By Role",
		"ppcVarianceTableByRole": "PPC Variance By Role",
		"ppcVarianceByWeek": "PPC Variance By Week",
		"ppcVarianceTableByWeek": "PPC Variance By Week",
		"ppcChartsByTradeToDate": "PPC By Role",
		"ppcTableByTradeToDate": "PPC By Role",
		"ppcChartsByWeek": "PPC By Week",
		"ppcTableByWeek": "PPC By Week",
		"ppcChartsByVarianceReason": "PPC By Variance",
		"milestoneTrend": "Milestone Trend Chart",
		"milestoneStatus": "Milestone Status Chart",
		"bundleHeader": "Header",
		"bundleFooter": "Footer",
		"bundleInterstitial": "Interstitial"
	};
	
	unrollBundleByKey(report, key){
		if(!report || !key){console.log('report or key undefined');return [report];}
		var list = report[key];
		if(!list || !list.length){console.log('not a list'); return [report];}
		
		delete report[key];
		var newReportList = [];
		
		list.forEach((val)=>{
			var clone = angular.copy(report);
			clone[key] = [val];
			newReportList.push(clone);
		});
		return newReportList;
	}
	
	reportIterateCheck(report){
		var keys = Object.keys(report);
		
		var cleanedKeys = [];
		keys.forEach((key)=>{
			var idx = key.indexOf('iterate');
			if(idx !== -1){
				cleanedKeys.push(key.slice(idx + ('iterate').length));
				delete report[key];
			}
		});
		return cleanedKeys;
	}
	//0, 1
	wrapReportsList(reportsList){
		if(!reportsList && !reportsList.length){return;} //sure...
		if(reportsList[0].reportName === "bundleHeader"){return reportsList;} //already wrapped
		var projectId = reportsList[0].projectId;
		var bundleHeader = {"projectId": projectId, "reportName": "bundleHeader"};
		var bundleFooter = {"projectId": projectId, "reportName": "bundleFooter"};
		var bundleInterstitial = {"projectId": projectId, "reportName": "bundleInterstitial"};
		
		var newList = [];
		newList.push(bundleHeader);
		reportsList.forEach((report, idx)=>{
			newList.push(report);
			if(idx !== reportsList.length-1){ newList.push(bundleInterstitial); }
		});
		newList.push(bundleFooter);
		return newList;
	}
	
	projectReportBundle(theArray){
		if(!theArray || !theArray.length){ return this.$q.rejectErr("can't generate nothing"); }
		
		//might as well do some sort of sanity check
		var success = theArray.every((report)=>{
			return report.projectId && report.reportName;
		});
		if(!success){return this.$q.rejectErr('invalid report array');}
		theArray = this.wrapReportsList(theArray); //uncomment for support

		return this._request('projectReportBundle', {reports: theArray}, true);
	}
	
	//generic report request...
	//others should be deprecated
	requestReport(projectId, reportName, startDate, endDate, extra){
		if(!projectId || !reportName) { return this.$q.rejectErr("projectId invalid");}
		var params = extra || {};
		params.projectId = projectId;
		params.reportName = reportName;
		if(startDate){ params.asOfDate = startDate; }
		if(endDate){ params.upToDate = endDate; }
		console.log('fbdbRequest params', params);
		
		return this._request('projectReport', params, true);
	}
	
	//requests a report to be generated
	//args:
	//(required)
	//projectId
	//reportName
	//date - as of date to generate the report with
	//(optional)
	//userId - puts that userId in the requestingUserId field
	requestProjectReport(projectId, reportName, date, extra){
		if(!projectId || !reportName) { return }
		var params = extra || {};
		
		params.projectId = projectId;
		params.reportName = reportName;
		if(date){ params.asOfDate = date; }
		
		return this._request('projectReport', params, true);
	}
	
	requestGantt(projectId, startDate, endDate, extra){
		var params = extra || {};
		
		params.projectId = projectId;
		params.reportName = 'ganttView';
		params.asOfDate = startDate;
		params.upToDate = endDate;
		
		return this._request('projectReport', params, true);
	}
	
	requestPPCUpdate(projectId){
		var params = { "projectId": projectId };
		return this._request('projectTables', params);
	}
	requestPlanTable(projectId, planId){
		var params = { "projectId": projectId, "planId": planId};
		return this._request('planTables', params)
		.then(()=>{
			return this.fbConnection.fbObject('tables/'+projectId+'/'+planId+'/ticketTable/csvDataUri');
		});
	}
	
	requestProjectTable(projectId){
		var params = { "projectId": projectId };
		return this._request('projectTables', params)
		.then(()=>{
			return this.fbConnection.fbObject('tables/'+projectId+'/ticketTable/csvDataUri');
		});
	}
	
	//makes a request to fbdb with an invalid name to force an error response
	//if a response is recieved, we know fbdb is alive, if not, then it might be down
	//kind of abuses the fact that an invalid request returns a quick response that changes nothing in the app
	requestCheckIn(){
		var count = 0;
		var startDate = new Date();
		
		var tick = setInterval(()=>{
			count++;
			console.log('waiting for a response, ' + count*5 + 'secs');
		}, 5000);
	
		this._request('checkIn').then((response)=>{
			console.log('response', response);
		}, (error)=>{
			if(error && error.ref){
				error.ref.remove();
			}
			clearInterval(tick);
			console.log('fbdb responded in:', (new Date().getTime() - startDate.getTime())/1000 + 'secs');
		});
		
		return 'checking...';
	}
	
	requestPlanOverwrite(src, dst, userId, overwriteRoles){
		if(!src || !src.projectId || !src.planId){
			console.warn('src is invalid');
			return;
		}
		if(!dst || !dst.projectId || !dst.planId){
			console.warn('dst is invalid');
			return;
		}
		
		var params = {
			"srcProjectId" : src.projectId,
			"srcPlanId" : src.planId,
			"destProjectId" : dst.projectId,
			"destPlanId" : dst.planId,
			"overwriteRoles" : !!overwriteRoles
		};
		
		 return this._request('planOverwrite', params);	
	}
	
	//### planMoveDates(projectId, dayCount, config)
	// shifts dates
	//* projectId
	//* dayCount - number
	//* config[planId]
	planMoveDates(projectId, dayCount, config){
		var params = config || {};
		params.projectId = projectId;
		params.dayCount = dayCount;
		return this._request('planMoveDates', params);
	}
	
	rawRequest(name, config){
		return this._request(name, config);
	}
	
	//strip out cached request
	removeRequest(requestId){
		if(!requestId){return this.$q.reject('requestId ' + requestId+' is invalid');}
		var ref = this.fbConnection.fbRef('fbdbRequests/'+requestId);
		return this.fbConnection.remove(ref);
	}
	
	snapshot = ((self)=>{

		function fancyEffect(){
			var flash = $('<div class="snap-effect"></div>');
			$('body').append(flash);
			setTimeout(()=>{
				flash.css({height: 50, width: 300, opacity: 0, top: 220, left: 50});
				setTimeout(()=>{
					flash.remove();
				}, 500);
			}, 200);
		}

		//requests.snapshot.take("-JmoNDScSVd2S0Z4EZL8", "-JmoNLQyHZPVgkX4EF9t", "simplelogin:205", {"woo": "woo2"})
		function take(projectId, planId, userId, extraParams, meta){
			//consider sanity checking meta for firebase safe vals, going to blindly accept it as a base for params currently
			if(!projectId || !planId || !userId){ console.log('snapshot, invalid params'); return self.$q.rejectErr('invalid params'); }
			
			if(!meta){ meta = {}; }
			if(!meta.userId){ meta.userId = userId; }
			
			var params = {
				"projectId": projectId,
				"planId": planId,
				"snapshot": meta
			};
			if(extraParams){
				for(var key in extraParams){
					params[key] = extraParams[key];
				}
			}
			
			// fancyEffect();
			return self._request('snapshot', params, true);
		}
		
		function restore(projectId, planId, snapshotId, userId){
			if(!projectId || !planId || !snapshotId){ console.log('snapshot, invalid params'); return self.$q.rejectErr('invalid params'); }
			
			var params = {
				"projectId": projectId,
				"planId": planId,
				"snapshotId": snapshotId,
				"snapshotBeforeRestore": true //always take a snapshot for now
			};
			
			return self._request('restore', params);
		}
		
		return {
			take: take,
			restore: restore
		}
	})(this);
	
	hubSpot = (function(self){
		function getNestedCookies() {
			try{ var theCookies = document.cookie.split(';'); }
			catch(e){ return false; }
			
			var cookieObj = {},
				tmp, tmpName, tmpVal;
			for (var i = 1 ; i <= theCookies.length; i++) {
				tmp = theCookies[i-1].split('=');
				if(!tmp || tmp == ""){continue;}
				tmpName = decodeURIComponent(tmp[0].trim());
				tmpVal = decodeURIComponent(tmp[1].trim());
				if ( tmpName.indexOf('[') > -1 && tmpName.indexOf(']') > -1 ) {
					cookieObj[tmpName.split('[')[0]] = cookieObj[tmpName.split('[')[0]] || {};
					cookieObj[tmpName.split('[')[0]][tmpName.split('[')[1].replace(']', '')] = tmpVal;
				} else {
					cookieObj[tmpName] = tmpVal;
				}
			}
			return cookieObj;
		}

		function requestHubSpotFormSubmit(formGUID, pageName, email, formData){
			var cookies = getNestedCookies();
			if(!cookies){ console.log("can't submit to hubspot, user has disabled cookies"); return self.$q.when(true); }
			var utk = cookies['hubspotutk'] || null;
			var hs_context = {
				"pageUrl": window.location.href,
				"pageName": pageName
			};
			// Pass the hubspot tracking key, if it exists.
			if (utk){hs_context["hutk"] = utk;}

			var params = {
				"formGuid": formGUID,
				"email": email,
				"formData": formData,
				"hs_context": hs_context
			};

			return self._request('hubSpotFormSubmit', params);
		}

		return {
			submitFormData: requestHubSpotFormSubmit
		}
	})(this);
	
	intercomInvite = ((self)=>{
		function generic(id, params, projectId, inviterId, message){
			params['projectId'] = projectId;
			if(inviterId){ params['inviterUserId'] = inviterId; }
			if(message){ params['inviterMessage'] = message; }
			return self._request(id, params, true);
		}
		function newUser(inviteeEmail, projectId, inviterId, message){
			return generic('inviteNewUser', {'inviteeEmail': inviteeEmail}, projectId, inviterId, message);
		}
		function oldUser(inviteeId, projectId, inviterId, message){
			return generic('inviteExistingUser', {'inviteeIntercomId': inviteeId}, projectId, inviterId, message);
		}
		
		return {
			newUser: newUser,
			oldUser: oldUser
		};
	})(this);
}

angular.module('fbdbApi', ['firebase'])
.service('fbdbRequests', FbdbRequests)
//## fbdbTime()
// returns a moment representing the current time as seen by fbdb
.factory('fbdbTime', ["fbConnection", function(fbConnection){
	var timestamp = fbConnection.fbObject('fbdbStats/server_time_view/0/fbdb_current_timestamp');
	
	//since this gives us some constantly updated variable anyways...
	var statusTimeout
	timestamp.$watch(()=>{
		clearTimeout(statusTimeout);
		statusTimeout = setTimeout(()=>{
			console.warn('fbdb has gone quiet');
			Logging.debug('fbdb has gone quiet');
		}, 60000);
	});
	
	function getter(){
		//consider saving this format string on the object so it's accesible
		return moment(timestamp.$value, "YYYY-MM-DD HH:mm:ssZZ", false);
	}
	(getter as any).$loaded = timestamp.$loaded;
	(getter as any).raw = timestamp;
	return getter;
}])
.run(["fbdbRequests", "fbdbTime", function(fbdbRequests, fbdbTime){
	window.fbdbTime = fbdbTime;
	window.requests = fbdbRequests;
}]);
