import { database } from "firebase/app";
import { Ticket } from "../models/Ticket";
import { Variance } from "../models/Variance";

import * as firebaseUtils from "ng2/fancy-firebase/firebase-utils";

export enum pinColors {
	blue = "#0B73C0",
	red = "#E44B47",
	black = "#2D2D2D",
	green = "#93d200"
};

//TODO - this
export enum pinState {
	missed = "missed",
	completedOnTime = "completedOnTime",
	completedEarly = "completedEarly",
	completedLate = "completedLate",
	promised = "promised",
	late = "late"
}

const pinDbKeys = [
	"plannedStart",
	"plannedFinish",
	"actualFinish",
	"varianceId",
	"varianceComment",
	"pinType"
];

export interface PromisePin{
	$id: string,
	data: PromisePinData
}
export interface PromisePinData{
	plannedStart: string,
	plannedFinish: string,
	finishX: number;
	actualFinish?: string,
	varianceId?: string,
	varianceComment?: string,
	variance: Variance,
	pinType?: string,
	color: pinColors,
	state: pinState
}

export function decoratePinData(pinData:PromisePinData, ticketPlannedFinish:string, getDateFromX: (date:string)=>number, isMostRecent = false, varianceList?: Map<string, Variance>){
	if(!isMostRecent){
		pinData.color = pinColors.red;
		pinData.state = pinState.missed;
	}
	else if(pinData.actualFinish && pinData.actualFinish === pinData.plannedFinish ){
		pinData.color = pinColors.green;
		pinData.state = pinState.completedOnTime;
	}
	else if(pinData.actualFinish && pinData.actualFinish < pinData.plannedFinish){
		pinData.color = pinColors.blue;
		pinData.state = pinState.completedEarly;
	}
	else if(ticketPlannedFinish === pinData.plannedFinish && !pinData.varianceId){
		pinData.color = pinColors.black;
		pinData.state = pinState.promised
	}
	else if(pinData.actualFinish && pinData.actualFinish > pinData.plannedFinish){
		pinData.color = pinColors.red;
		pinData.state = pinState.completedLate;
	}
	else{
		pinData.color = pinColors.red;
		pinData.state = pinState.late;
	}
	
	if(pinData.varianceId && varianceList){
		pinData.variance = varianceList.get(pinData.varianceId); 
	}
	
	let lastX = pinData.finishX;
	pinData.finishX = getDateFromX(pinData.plannedFinish);
	return pinData.finishX !== lastX;
}

export function makePromisePeriodArray(stack, ticketPlannedFinish:string, getDateFromX: (date:string)=>number, varianceList?: Map<string, Variance>): Array<PromisePin>{
	if(!stack){return null;}
	return Object.keys(stack).sort().map((key, idx, arr)=>{
		var obj = {
			$id: key,
			data: stack[key] ? Object.assign({}, stack[key]) : stack[key]
		}
		decoratePinData(obj.data, ticketPlannedFinish, getDateFromX, idx === arr.length-1, varianceList);
		return obj;
	});
}

export function cloneStack(stack:Array<PromisePin>){
	var newArray = stack.map((pin)=>{
		return {
			$id: pin.$id,
			data: Object.assign({}, pin.data)
		}
	})
	return newArray;
}

export function returnToObject(promiseArray: Array<PromisePin>){
	if(!promiseArray){return null;}
	var obj = {};
	promiseArray.forEach((promise)=>{
		var innerObj = {};
		pinDbKeys.forEach((key)=>{
			if(promise.data[key]){ innerObj[key] = promise.data[key]; }
		});
		obj[promise.$id] = innerObj;
	})
	return obj;
}





/**
 * Creates a new pin in a firebase safe way. In order to make this flexible, accept
 * the desired pin data as an argument, we could _probably_ calculate it internally though.
 * @param  idOrParentRef A firebase ref to call .push on, or an id generated by .push
 * @param  ticket        The ticket the pin should be added to
 * @return               A completely new (potentially the same array) array intended to
 * be forwarded to liveUpdate to be applied to the ticket
 */
export function createPromisePin(idOrParentRef: string | database.Reference, ticket:Ticket, pinData: PromisePinData): Array<PromisePin>{ 
	var pin = internalCreatePromisePin(idOrParentRef, ticket, pinData);
	var arr = ticket.view.livePromisePeriodStack || [];
	arr.push(pin);
	return arr;
}
/**
 * Creates a new pin in a firebase safe way. Only returns the new pin data, intended
 * only to be used internally.
 * 
 * @param  idOrParentRef A firebase ref to call .push on, or an id generated by .push
 * @param  ticket        The ticket the pin should be added to
 * @return               Returns the new pin data
 */
export function internalCreatePromisePin(idOrParentRef: string | database.Reference, ticket:Ticket, pinData: PromisePinData): PromisePin{
	var id = getNewPinId(idOrParentRef);
	
	return {
		$id: id,
		data: pinData
	}
}

// export function cantCreatePromisePin(ticket: Ticket){
// 	var start = ticket.view.liveStart;
// 	var finish = ticket.view.liveFinish;
// 	if(!start || !finish){return true;}
// 	if(ticket.view.isActualized){return true;}
// 
// 	if(ticket.view.livePromisePeriodStack){
// 		ticket.view.livePromisePeriodStack[ticket.view.livePromisePeriodStack.length - 1].plannedFinish
// 	}
// 
// }
//may not use?
// export function updatePromisePin(id: string, pinData): Array<PromisePin>{
// 
// }
// //may not use?
// export function removePromisePin(id: string): Array<PromisePin>{
// 
// }

function getNewPinId(idOrParentRef: string | database.Reference){
	if(typeof idOrParentRef === "string"){ return idOrParentRef; }
	else if(firebaseUtils.isRef(idOrParentRef)){ return idOrParentRef.push().key; }
	else{ throw new Error("arg isn't string or a ref"); }
}
