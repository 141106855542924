'use strict';
import * as angular from "angular";
import * as utils from "utils";
import * as jwt_decode from "jwt-decode";

declare const window;
const detect = require("js/vendor/detect");

const defaultPopupTemplate = require('tpl/popup.html');

import { ProjectNotifier } from "ng2/common/services/project-notifier.service";




// Displays a confirmation popup
// Args:
//	- replacements (optional) - object with customizations to apply to the popup
//		fields:
//			title: tile of the popup, defaults to 'Are you sure?'
//			description: description of the popup, defaults to nothing
//			descriptionHtml: description of the popup, but with html
//			stackable: bool, modify behavior to allow popups to stack, defaults to false
//			templateUrl: a string for a template page to replace the default popup.html
//			template: string containing html to load for the popup
//				*note* both templates will interpolate {{title}} and {{description}} if provided
//			disableWrapper: bool, set to true to disable the popup div being applied
//			disableAllWrappers: bool, set to true to disable both the popup and popup wrapper div
//			data: object, an object of things to add to the scope
//	- scope (optional) - defaults to root scope
// Returns:
//	- a promise, success state is triggered by clicking yes, fail state is no, 
//		data returned is a true/false and is effectively useless, use the success/fail
// Examples:
// 	popup($scope).then(successFunc, failFunc)
// 	popup($scope).then(successFunc)
//	popup({title: 'woo'}, $scope)
//	popup({template: '<button></button>'})
// Notes:
//	scope.close() - closes popup with no callback
//	scope.confirm() - closes popup with success
//	scope.reject() - closes popup with failure
angular.module('ticketspaceApp')
.factory("popup", ["$q", "$rootScope", "$timeout", "$compile", "$sce", "$document", "$http", "$templateCache", "projectNotifier", function($q, $rootScope, $timeout, $compile, $sce, $document, $http, $templateCache, projectNotifier: ProjectNotifier){
	
	var popupList = [];
	
	projectNotifier.projectState.subscribe(function(){
		emptyPopupList();
	});
	
	function emptyPopupList(){
		popupList.forEach(function(popup){
			if(popup){ $timeout(function(){
				if(popup){ popup.scope.reject(); }
			}); }
		});
	}
	
	function removePopup(popup){
		var idx = popupList.indexOf(popup);
		//console.log('idx', idx);
		
		if(idx !== -1){
			popupList.splice(idx,1);
		}
	}
	
	function popup(replacements, baseScope){
		if(!replacements){ replacements = {}; }
		var currentPopup;
		
		if(!replacements || !replacements.stackable){ emptyPopupList(); }
		
		//wraps all popup templates with a popup div
		function wrap(data){
			if(replacements && replacements.disableAllWrappers){}
			else if(replacements && replacements.disableWrapper){data = '<div class="popup-wrapper">'+data+'</div>';}
			else{ data = '<div class="popup-wrapper"><div class="popup popupAnimation">' + data + '</div></div>';}
			return data;
		}
		
		//create a child scope
		var scope;
		if(baseScope){ scope = baseScope.$new(); }
		else{ scope = $rootScope.$new(); }
		angular.extend(scope, replacements.data || {});
		
		var defer = $q.defer();
		scope.defer = defer;
		
		var popup;
		
		//handle replacements
		var templateUrl = null;// 'tpl/popup.html';
		var template = defaultPopupTemplate;
		scope.title = 'Are you sure?';
		scope.description = '';
		if(replacements){
			if(replacements.title)
				scope.title = replacements.title;
			if(replacements.description)
				scope.description = replacements.description;
			if(replacements.descriptionHtml){
				scope.description = $sce.trustAsHtml(replacements.descriptionHtml);
			}
			if(replacements.templateUrl){
				console.error("templateUrl syntax is no longer supported.\n Perhaps what you actually want is: var tpl = require('thePath'); popup({template: tpl});");
				templateUrl = replacements.templateUrl;
			}
			if(replacements.template)
				template = replacements.template;
		}
		
		scope.close = function(){
			scope.$destroy();
			if(!popup){return;} //could in theory make popups not close instead of erroring
			popup.css({opacity: 0});
			
			removePopup(currentPopup);
			currentPopup = undefined;
			
			$timeout(function(){
				popup.remove();
			}, 500);
		};
		
		//yes callback
		scope.confirm = function(data){
			console.log( data );
			defer.resolve(data !== undefined ? data : true);
			scope.close();
		};
		
		//no callback
		scope.reject = function(data){
			defer.reject(data !== undefined ? data : false);
			scope.close();
		};
		
		scope.$on('closePopup', function(){
			console.log('close signal');
			scope.close();
		});
		
		scope.updateModel = function(model, key, value){
			$timeout(()=>{
				model[key] = value;
			})
		};
		
		
		function animatePopup(popup){
			popup.addClass('popupAnimation');
			popup.css({opacity: 100});
		}
		
		
		function assemble(pop){
			popup = pop;

			popup.removeClass('popupAnimation');
			
			popup.css({opacity: 0});
			$timeout(function(){animatePopup(popup);}, 100);
			
			$document.find('body').append(pop);
			return pop;
		}
		var htmlContent;
		if(template){ //load template
			htmlContent = assemble($compile(wrap(template))(scope));
		}
		else{ //or url
			$http.get(templateUrl, {cache: $templateCache}).then(function(response){
				htmlContent = assemble($compile(wrap(response.data))(scope));
			});    
		}
		
		currentPopup = {
			html: htmlContent,
			promise: defer.promise,
			scope: scope
		};
		
		popupList.push(currentPopup);
		
		return defer.promise;
	}
	
	return popup;
}]);
