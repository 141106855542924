/**
 * Defines the enum of DefaultActionTypes and corresponding interfaces
 */

import { FancyAction } from "../base";

//technically only need to bother creating interfaces for action types that have additional properties
export type CombinedDefaultActions<Model = any> = FancyAction<Model>
	| ChangeViewAction<Model>
	| AbortViewAction<Model>;


export interface ChangeViewAction<Model = any> extends FancyAction<Model>{
	type: DefaultActionTypes.changeView;
	renderType: string;
}
export interface AbortViewAction<Model = any> extends FancyAction<Model>{
	type: DefaultActionTypes.abortView;
	renderType: string;
}
export interface PersistDataAction<Model = any> extends FancyAction<Model>{
	type: DefaultActionTypes.persistData;
	renderType: string;
}

export enum DefaultActionTypes {
	addItem = "add_item",
	removeItem = "remove_item",
	localUpdate = "local_update",
	changeView = "change_view",
	abortView = "abort_view",
	cameraChanged = "camera_changed",
	//changeToPrimaryView = "change_to_primary_view", //remove this if it's not used
	persistData = "persist_data"
}
