import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Texture, utils } from "pixi.js";
import { TicketComponentHelper } from "./TicketComponentHelper";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { Side } from "../../common/models/Side";
import { Ticket } from "../../common/models/Ticket";
import * as GlobalUtils from "utils";
import { IMAGE_PADDING } from "../interfaces/image-padding.interface";
import { FancySprite } from "../graphics/FancySprite";
import { TICKET_TEXTURE_OPTIONS } from "../tickets/";

// Enum for positioning the constraint assigned icon
export enum ASSIGNED_ICON_SETTINGS {
	OFFSET_LEFT_ACTIVE = 74,
	OFFSET_TOP_ACTIVE = 0,
	
	OFFSET_LEFT_PULL = 126,
	OFFSET_TOP_PULL = 0
}

export class ConstraintAssignedIcon extends TicketComponentHelper implements ComponentInterface {
	/**
	 * Stores the reference to the Pin FancySprite
	 */
	private _border: FancySprite;
	
	private _cachedColor: string;
	
	constructor(texture: Texture, planState: PlanState, ticketContainer: TicketContainer) {
		super(texture, planState, ticketContainer);
		
		this._setupIcon();
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this.onChanged(ticketContainer);
	}
	
	/**
	 * Creates the Ticket Pin and Ticket Border and adds it as a child to the pin background
	 */
	private _setupIcon(): void {
		this._border = new FancySprite(utils.TextureCache[TICKET_TEXTURE_OPTIONS.TEXTURE_CONSTRAINT_ASSIGNED_BORDER]);
		// Offset the border image to make it not disjointed
		this._border.x = -5;
		this._border.y = -6;
		this._border.visible = true;
		this._border.active = true;
		
		this.addChild(this._border);
	}
	
	private onChanged = (ticketContainer: TicketContainer) => {
		const data: Ticket = ticketContainer.getData();
		this.renderable = !!data.view.assignedProjectMember;
		if (this.renderable){
			
			this._position(data);
			
			let assignedRole = data.view.assignedProjectMember.role || {};
			let color = assignedRole.color || "#000000";
			
			// We don't want to calculate the color on every update if we don't need too...
			if (this._cachedColor !== color){
				this.tint = GlobalUtils.convertHexStringToNumber(color);
				this._cachedColor = color;
			}
		}
	};
	
	private _position(data: Ticket): void {
		const newSide = data.view.side;
		
		if (newSide === Side.PULL){
			this.width = 90;
			this.height = 90;
			
			this.x = (data.view.width / 2) - (this.width / 2) + ASSIGNED_ICON_SETTINGS.OFFSET_LEFT_PULL - IMAGE_PADDING.ACTIVE_SHAPE;
			this.y = 0;
		} else {
			this.width = 49;
			this.height = 49;
			
			this.x = (data.view.width / 2) - (this.width / 2) + ASSIGNED_ICON_SETTINGS.OFFSET_LEFT_ACTIVE - IMAGE_PADDING.ACTIVE_SHAPE;
			this.y = ASSIGNED_ICON_SETTINGS.OFFSET_TOP_ACTIVE;
		}
	}
	
	public destroy(options?){
		this._border.destroy();
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		super.destroy(options);
	}
}