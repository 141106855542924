//reduce some boilerplate...
export class EventBinder{
	constructor(private parent, private startEvent?:string|Array<string>, private endEvent?: string|Array<string>){
		if(startEvent){
			if(typeof startEvent === "string"){
				this.startEvents[startEvent] = startEvent;
			}
			else{
				startEvent.forEach(se => this.startEvents[se] = se);
			}
		}
		if(endEvent){
			if(typeof endEvent === "string"){
				this.endEvents[endEvent] = endEvent;
			}
			else{
				endEvent.forEach(se => this.endEvents[se] = se);
			}
		}
	}
	private destroyList = new Map<string, any>();
	private failed = false;
	public detecting = false;
	
	private startEvents:any = {}
	private endEvents:any = {};
	/**
	 * add a callback 
	 * @param  eventName name of the event to added
	 * @param  cb        The handler, if this returns a truthy value in the start 
	 * event all subsequent events will be cancelled until the start event is called again.
	 * This check is only run if a startEvent is passed into the constructor.
	 */
	add(eventName: string, cb){
		this.parent.on(eventName, (event)=>{
			if(this.startEvents[eventName]){
				this.detecting = true;
				this.failed = !!cb(event);
				if(this.failed){ this.detecting = false; }
			}
			else if(this.endEvents[eventName]){
				if(!this.failed){ cb(event); }
				this.detecting = false;
			}
			else if(!this.failed){
				let ret = cb(event);
				if(ret){ this.failed = true; this.detecting = false; }
			}
			//the last case is that it's failed and it's not the start event
		});
		
		if(this.destroyList.has(eventName)){ console.log('overwriting '+eventName+' this is probably a bug'); }
		this.destroyList.set(eventName, cb);
	}
	destroy(){
		this.destroyList.forEach((cb, key)=>{
			this.parent.removeListener(key, cb);
		});
		this.destroyList = null;
		this.parent = null;
	}
}
