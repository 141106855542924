import { Subject, interval } from "rxjs";
import { skipUntil, takeUntil, buffer } from "rxjs/operators";

import { renderQueue } from "ng2/common/RenderQueue";

enum RENDER_DEPENDENCIES_OPTIONS {
	// The buffer time for events in the DataMapper.
	// Value is in milliseconds
	TICKER_INTERVAL = 16 // ms
}

export class RenderDependencies {
	private static destroyer$ = new Subject<boolean>();
	public static isLoaded: boolean = false;
	public static firstDraw: boolean = false;
	public static loaded$ = new Subject();
	// public static ticker = interval(RENDER_DEPENDENCIES_OPTIONS.TICKER_INTERVAL).pipe(skipUntil(RenderDependencies.loaded$));
	public static ticker = renderQueue.ob$.pipe(skipUntil(RenderDependencies.loaded$), takeUntil(RenderDependencies.destroyer$));
	public static dependenciesToLoad: Map<string, boolean> = new Map();
	public static setReady() {
		RenderDependencies.isLoaded = true;
		RenderDependencies.loaded$.next();
	}
	
	public static addDependency(id: string) {
		this.dependenciesToLoad.set(id, false);
	}
	
	public static markAsLoaded(id: string) {
		RenderDependencies.dependenciesToLoad.delete(id);
		RenderDependencies.checkDependencies();
	}
	
	public static checkDependencies(): boolean {
		if (!RenderDependencies.dependenciesToLoad.size){RenderDependencies.setReady();}
		return !RenderDependencies.dependenciesToLoad.size
	}
	
	public static getDependenciesToLoad(): Map<string, boolean> {
		return RenderDependencies.dependenciesToLoad;
	}
	
	public static destroy(){
		RenderDependencies.isLoaded = false;
		this.destroyer$.next(true);
	}
}
