import { Injectable } from '@angular/core';

import { EditWrapperComponent } from 'ng2/ticket-edit/edit-wrapper.component';

@Injectable()
export class TicketEditServiceTemplater{
	editWrapperComponent: EditWrapperComponent; //to change the templates programatically
	
	constructor(){}
	
	public registerEditWrapper(editWrapperComponent:EditWrapperComponent){
		if(this.editWrapperComponent){
			//do some cleanup
		}
		this.editWrapperComponent = editWrapperComponent;
	}
	
	//all of this is exceptionally placeholder-y
	public openEdit(selectedComponent){
		this.editWrapperComponent.changeChildComponent(selectedComponent);
	}
	public closeEdit(){
		this.editWrapperComponent.clearChildComponent();
	}
}
