import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { INTERCOM_CONNECTION_ERROR } from "../strings";
/**
 * These are the states that Intercom can be in.
 */
export enum INTERCOM_STATES {
	SHOW = 'show',
	HIDE = 'hide',
	ON_SHOW = 'onShow',
	ON_HIDE = 'onHide',
	NEW_MESSAGE = 'onUnreadCountChange'
}

/**
 * Enum containing our error strings that we pass into fbReporting.
 */
export enum INTERCOM_ERRORS {
	CONNECTION = "Intercom blocked by ad blocker, probably."
}

@Injectable()
export class IntercomService {
	/**
	 * BehaviourSubject that manages the current unread message count from Intercom
	 */
	public notificationCount$: BehaviorSubject<number> = new BehaviorSubject(0);
	/**
	 * BehaviourSubject that manages the visibility state of the Intercom window
	 */
	public visible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	/**
	 * BehaviourSubject which manages the loading state of Intercom.
	 */
	public loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	/**
	 * A reference to window.reporting so we can report warnings and errors to Firebase.
	 */
	private fbReporting: any;
	constructor(){
		this.fbReporting = window.reporting;
		/**
		 * Attempt to create a connection to Intercom.
		 */
		window.analytics.ready(() => {
			// Create Intercom handlers
			window.Intercom(INTERCOM_STATES.ON_SHOW, this.onShow);
			window.Intercom(INTERCOM_STATES.ON_HIDE, this.onHide);
			window.Intercom(INTERCOM_STATES.NEW_MESSAGE, this.onUnreadCountChange);
			
			// Successfully created connection. Mark Intercom as loaded.
			this.loaded$.next(true);
		});
	}
	/**
	 * Helper method to display a warning message when there is no connection to Intercom.
	 */
	private displayError(): void {
		this.fbReporting.reportRealWarning(INTERCOM_ERRORS.CONNECTION);
		window.Logging.warning(INTERCOM_CONNECTION_ERROR);
	}
	/**
	 * Forces the Intercom to enter the 'show' state.
	 */
	public show(): void {
		if (!this.loaded$.getValue()){
			this.displayError();
			return
		}
		
		window.Intercom(INTERCOM_STATES.SHOW);
	}
	/**
	 * Forces Intercom to enter the 'hide' state.
	 */
	public hide(): void {
		if (!this.loaded$.getValue()){
			this.displayError();
			return
		}
		
		window.Intercom(INTERCOM_STATES.HIDE);
	}
	/**
	 * Toggles between the show and hide states
	 */
	public toggle(): void {
		if (!this.loaded$.getValue()){
			this.displayError();
			return
		}
		
		const newVisibility: boolean = !this.visible$.getValue();
		if (newVisibility){window.Intercom(INTERCOM_STATES.SHOW);}
		else {window.Intercom(INTERCOM_STATES.HIDE);}
	}
	/**
	 * Invoked when the Intercom state changes to 'show'
	 */
	private onShow = () => {
		this.visible$.next(true);
	};
	/**
	 * Invoked when the Intercom state changes to 'hide'
	 */
	private onHide = () => {
		this.visible$.next(false);
	};
	/**
	 * Invoked when the unread message count changes in Intercom
	 */
	private onUnreadCountChange = (count: number) => {
		this.notificationCount$.next(count);
	};
}