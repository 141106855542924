const Hammer = require("js/vendor/hammer/hammer");
import * as Connector from "pixi-hammer";

const setTimeout = window.__zone_symbol__setTimeout ? window.__zone_symbol__setTimeout : window.setTimeout;

//prepend hammer- when listening
var supportedEvents = [
	'singletap',
	'doubletap',
	// 'panstart',
	// 'pan',
	// 'panend',
	'pinchstart',
	'pinch',
	'pinchend',
	'press',
	'pressup',
	'hammer.input'
];

//start by ripping off the old "feel"
var defaultHammerConfig = {
	recognizers: [
		[Hammer.Pinch],
		// [Hammer.Pan],
		// [Hammer.Tap, {event: 'doubletap', taps: 2, threshold: 7, posThreshold: 25}],
		// [Hammer.Tap, {event: 'singletap', threshold: 7}],
		[Hammer.Press, {time: 333, threshold: 3}]
	]
}
var singleTap = new Hammer.Tap({ event: 'singletap', threshold: 7 });
var doubleTap = new Hammer.Tap({event: 'doubletap', taps: 2, threshold: 7, posThreshold: 25 });
doubleTap.recognizeWith(singleTap);
singleTap.requireFailure(doubleTap);

var simpleDrag = new Hammer.Pan();
supportedEvents.push('panstart', 'pan', 'panend');

var holdDrag = new Hammer.Pan({event: 'holddrag'});
supportedEvents.push('holddragstart', 'holddrag', 'holddragend');

holdDrag.recognizeWith(simpleDrag);
// holdDrag.dropRecognizeWith(simpleDrag);
// simpleDrag.dropRecognizeWith(holdDrag);

/**
 * Ties the hammer connector to the pixi instance and hammer.
 * @param  app PIXI.Application
 * @return     The connector, use to destroy.
 *
 * ## Usage
 * ### initialize with the rest of the initializers
 * ```
 * HammerWrapper.init(app);
 * ```
 * ### use on pixi things
 * ```
 * let backgroundGestureContainer = new Container();
 * backgroundGestureContainer.hitArea = new Rectangle(0,0, this.renderer.view.width, this.renderer.view.height);
 * backgroundGestureContainer.interactive = true;
 * this.stage.addChild(backgroundGestureContainer);
 *
 * backgroundGestureContainer.on('hammer-singletap', (e)=>{
 * 	console.log('woo');
 * })
 * ```
 * 
 */
export function init(app){
	var hammertime = new Hammer.Manager(app.view, defaultHammerConfig);
	hammertime.add([doubleTap, singleTap]);
	
	
	//Some ridiculous shit here... "exactly" what's going on...
	//- add the two events that need to be handled with the one that can fail from a different event first
	//- check for the failure condition of the first (the different one), if it's hit, remove the first event entirely
	//- checkfor the failure condition of the second (the shared event). If hit, unbind the recognizeWith
	//- at the conclusion of all events, reset back to the initial state
	hammertime.add([simpleDrag, holdDrag]);
	//press detected, remove simpleDrag
	hammertime.on('press', (evt)=>{
		lastStartEventLogging(evt);
		holdDrag.dropRecognizeWith(simpleDrag);
		simpleDrag.dropRecognizeWith(holdDrag);
		hammertime.remove(simpleDrag);
	});
	//simple drag started, unlink holdDrag
	hammertime.on('panstart', evt=>{
		lastStartEventLogging(evt);
		holdDrag.dropRecognizeWith(simpleDrag);
		simpleDrag.dropRecognizeWith(holdDrag);
	});
	
	hammertime.on('panstart holddragstart',lastStartEventLogging)
	
	function lastStartEventLogging(evt){
		var cleanEvent = safeEventCloneForReporting(evt);
		// console.log('lastStartEvent', cleanEvent);
		window.lastStartEvent = {
			type: evt.type,
			timestamp: Date.now(),
			raw: cleanEvent
		}
	}
	
	hammertime.on('panstart', evt=>{
		app.renderer.plugins.interaction.startEventAlreadyDetected = true;
	});
	
	//reset things
	hammertime.on('hammer.input', (evt)=>{
		if(evt.isFinal){
			app.renderer.plugins.interaction.startEventAlreadyDetected = false;
			//this needs to execute before the other hammer.input event internal to the connector
			setTimeout(()=>{
				hammertime.remove(holdDrag);
				hammertime.add([simpleDrag, holdDrag]);
				holdDrag.recognizeWith(simpleDrag);
			})
		}
	})
	
	//End of ridiculous shit
	function safeEventCloneForReporting(evt){
		var thing = Object.assign({}, evt);
		[
			"changedPointers", 
			"currentTarget",
			"preventDefault",
			"srcEvent",
			"target",
			"pointers"
		].forEach(key => delete thing[key])
		
		if(evt.pointers && evt.pointers.length !== undefined){
			thing.pointerCount = evt.pointers.length;
		}
		return thing;
	}
	
	
	console.log('canvas hammer');
	var c = new Connector(app.view, app.renderer.plugins.interaction, hammertime);
	c.registerHandlerTypes(supportedEvents);
	return c;
}
