//just create something super lazy to get things moving
// inherits from this are probably useless aside from the most simple of cases
// Use to stub out Models for the lists
export abstract class SimpleJoinSupportModel {
	prevData: any = {}; //in the first iteration just build off of view
	data: any = {};

	constructor(data:any){
		this.update(data);
	}
	
	update(data){
		this.data.prevData = this.data;
		this.data = data;
	}
}
