import { first } from "rxjs/operators";

import { ComponentInterface } from "../../interfaces/";
import { PlanState } from "../../../common/services/plan-state.service";
import { TicketContainer } from "../../data-mappers/";
import { Component } from "../../component-system/";
import { EventBinder } from "../interaction-utils";

import { analyticsService } from "ng2/common/utils/AnalyticsService";

export class TicketDoubleTap extends Component implements ComponentInterface{
	/**
	 * Boilerplate for dealing with events.
	 */
	private _binder: EventBinder;
	constructor(){super();}
	/**
	 * The setup method is called once all properties on a component have been decorated by the ComponentManager.
	 *
	 * From this point on, you can safely use this.getParent() and this.getManager().
	 *
	 * Arguments passed into setup are passed in via the ComponentManager.add(name, component, ...args)
	 * @param {PlanState} planState
	 * @param {DataContainer} dataContainer
	 */
	public setup(planState: PlanState, dataContainer: TicketContainer){
		this._binder = new EventBinder(this.getParent());
		// Add new event binding
		this._binder.add('hammer-doubletap', (event)=>{
			let tappedTicket = dataContainer.getData();
			if (!planState.accessCheck.isReadOnly() && !planState.accessCheck.hasAccessToTicket2(tappedTicket)){return;}
			dataContainer.cutInLine();
			//TODO - break this divide into seperate classes
			if(planState.dependencies.syncMode && planState.dependencies.syncMode.target){
				planState.actions.simpleDependencyToggle(tappedTicket);
				// let srcTicketId = planState.dependencies.syncMode.target;
				// let srcTicket = planState.dependencies.checkTicketCache(srcTicketId);
				// let predData = planState.dependencies.togglePredecessor(tappedTicket.$id);
				// 
				// planState.actions.updateTicketLiveData(srcTicketId, predData[0]);
				// planState.actions.updateTicketLiveData(tappedTicket.$id, predData[1]);
				// 
				// planState.actions.persistTicketData([srcTicket, tappedTicket]);
			}
			else{
				planState.actions.selectTicketToggle(tappedTicket);
				let count = planState.tickets.selectedTicket$.getValue().list.size;
				let planId = planState.route.snapshot.paramMap.get("planId");
				analyticsService.multiselectDoubleTap(count, planId)
			}
		});
	}
	/**
	 * Called on destroy. Clean-up your shit here.
	 */
	public destroy(){
		this._binder.destroy();
		this._binder = null
	}
}
