import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { dateCache } from "../../date-cache";

var serviceTerms = require("legal/Service-Terms.pdf");
var privacyPolicy = require("legal/Privacy-Policy.pdf");
var copyrightPolicy = require("legal/Copyright-Policy.pdf");

@Component({
	selector: 'legalese',
	template: `
		<div class="footer-links">
			<div>
				<a href="${serviceTerms}">Service Terms</a>
				<a href="${privacyPolicy}">Privacy Policy</a>
				<a href="${copyrightPolicy}">Copyright Policy</a>
			</div>
			<div class="credits">
				© {{dateCacheThing.today.format("YYYY")}} MOCA Systems, Inc. All rights reserved. Produced Under U.S. Pat. 10,410,178 B2
			</div>
		</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegaleseComponent{
	private dateCacheThing = dateCache;
}
