import { Injectable } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { filter, take, first } from "rxjs/operators";

export enum ResultTypes {
	SUCCESS = 'success',
	FAILURE = 'failure'
}

export class FancyActionResult{
	public originalType;
	constructor(public originalAction, public type: ResultTypes, public error? ){
		this.originalType = originalAction.type;
	}
}

export interface FancyActionTrackerObj{
	actionTracker$: FancyActionTracker,
	unsubscriber: Subscription
}

/**
 * Creates an enhanced subject intended to be built by FancyActionTrackerFactory.create()
 * to be used as an error/ success pipeline.
 * @example To listen
 * ```
 * //setup a listener
 * tickets.actionTracker$.firstOfType(TicketActionTypes.ticketPersistData).subscribe((action)=>{
 *   if(action.type === ResultTypes.SUCCESS){  }
 *   else {}
 * })
 * //call your action
 * planState.actions.persistTicketData(planState.tickets._internalList.pipe(first()).$id)
 * ```
 * potential enhancement would be to have actionBuilders automatically call this and return it
 * 
 * @example To dispatch events
 * ```
 * tickets.actionTracker$.dispatchSuccess(originalAction)
 * tickets.actionTracker$.dispatchFailureoriginalAction, error)
 * ```
 * When dipatching failure, it's reccomended to also halt the original action .switchMap / .map.filter, etc
 */
export class FancyActionTracker extends Subject<FancyActionResult>{
	lift<R>(operator):Observable<R>{
		const observable = new FancyActionTracker(); //<-- important part here
		observable.source = this;
		observable.operator = operator;
		return <any>observable;
	}
	
	/**
	 * special operator that shorthand's the listen for specific event and then completes
	 * @param  type        the type...
	 * @param  takeCount=1 in case you need to check for more than one
	 */
	firstOfType(type:string, takeCount = 1){
		return this.pipe(
			filter(action => action.originalType === type),
			take(takeCount)
		)
	}
	
	/** push out a success event */
	dispatchSuccess(originalAction){
		this.next(new FancyActionResult(originalAction, ResultTypes.SUCCESS));
	}
	/**  push a failure event */
	dispatchFailure(originalAction, error){
		this.next(new FancyActionResult(originalAction, ResultTypes.FAILURE, error));
	}
	
	destroy(unsub){
		this.complete;
		unsub.unsubscribe();
	}
}


@Injectable()
export class FancyActionTrackerFactory{
	public all = new FancyActionTracker(); //may need to fall back to Subject<FancyActionResult>
	
	create():FancyActionTrackerObj{
		var newThing = new FancyActionTracker();
		var unsub = this.all.subscribe(newThing);
		return {actionTracker$: newThing, unsubscriber: unsub};
	}
	// destroy(theTracker){
	// 
	// }
}
