import { Text, Sprite, CanvasRenderer, WebGLRenderer, TextMetrics, settings } from 'pixi.js';
import { LEVEL_OF_DETAIL_TABLE, LevelOfDetail, LEVEL_OF_DETAIL_SIGNALS } from "../core/";
import { ComponentManager } from "../component-system/";
import { FancyDefaultStyles } from "./";
import { FancyTextStyle } from "../interfaces/";
import * as globalUtils from "utils";
/**
 * Render text at a resolution independent of the main renderer resolution
 */
export class HighResolutionText extends Text {
	public active: boolean = false;
	//public components: ComponentManager;
	public isFancy: boolean = false;
	/**
	 *
	 */
	public debugMode: boolean = false;
	/**
	 * Stores the last resolution for this object>
	 */
	private _lastResolution: number;
	/**
	 * @param {string} text The text to render
	 * @param style {PIXI.TextStyle} The styling of the rendered text
	 * @param {HTMLCanvasElement} [canvas] The canvas to render the text to. If not provided, will create a cache canvas and render the text on that.
	 */
	constructor(text: string, style: FancyTextStyle = FancyDefaultStyles.DEFAULT_STYLE, canvas?: HTMLCanvasElement){
		super(text, style, canvas);
		if (!style){style = {};}
		this.resolution = style.resolution || window.devicePixelRatio || 1;
		this._lastResolution = style.resolution || window.devicePixelRatio || 1;
		this.debugMode = style["debugMode"] || false;
	}
	
	// Intentionally stubbed out
	public calculateText(force: boolean = false){};
	
	public destroy(options?: object|boolean){
		super.destroy(options);
	}
	/**
	 * Called when WebGL mode is enabled
	 * @param {PIXI.WebGLRenderer} renderer
	 */
	renderWebGL(renderer: WebGLRenderer){
		if (this.resolution !== this._lastResolution) {
			this.resolution = this._lastResolution;
			this.dirty = true;
		}
		super.updateText(true);
		Sprite.prototype.renderWebGL.call(this, renderer);
	}
	/**
	 * Called when WebGL mode is not enabled
	 * @param {PIXI.CanvasRenderer} renderer
	 */
	_renderCanvas(renderer: CanvasRenderer){
		if (this.resolution !== this._lastResolution) {
			this.resolution = this._lastResolution;
			this.dirty = true;
		}
		super.updateText(true);
		// MOC-2471
		renderer.plugins[this.pluginName].render(this);
	}
	/**
	 * Given a string, will trim the string until it fits cleanly into a rectangle.
	 * @param {string} str The string to test against
	 * @param {number} maxWidth The max width of word wrap
	 * @param {number} maxHeight The max height of the wordWrap
	 * @param {number} heightOffset The offset from the top of the rectangle
	 */
	public autoFitString(str: string, maxWidth: number, maxHeight: number){
		return this.autoFitStringCheck(str, str.length, maxWidth, maxHeight);
	}
	
	private autoFitStringCheck(str: string, length: number, maxWidth: number, maxHeight: number, lastLength = 0){
		let slice = str.slice(0, length);
		let textDimensions = TextMetrics.measureText(slice, this.style, this.style["wordWrap"]);
		let lines = textDimensions.width / maxWidth;
		let height = textDimensions.height * lines;
		
		let diff = Math.abs(lastLength - length)/2;
		
		if(length === lastLength){
			return slice;
		}
		else if(height > maxHeight){
			let newLength = Math.floor(length - diff);
			if(newLength === length){ return slice; }
			return this.autoFitStringCheck(str, newLength, maxWidth, maxHeight, length);
		}
		else if(height <= maxHeight && lastLength !== 0){
			let newLength = Math.floor(length + diff);
			if(newLength === length){ return slice; }
			return this.autoFitStringCheck(str, Math.floor(length + diff), maxWidth, maxHeight, lastLength);
		}
		else{
			return slice;
		}
	}
	/**
	 * Returns the most readable color. Uses some arbitrary values Chris came up with to blend the return color with the background.
	 * @param {string} bgColor The background color where the new text will be drawn on
	 * @returns {string}
	 */
	public getMostReadableColor(bgColor: string): string{
		return globalUtils.getMostReadableColor(bgColor);
	}
}