import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { Observable, empty, of } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { Camera } from "ng2/common/models/CanvasConnector";
import { FancySupplementalAction, SupplementalActionTypes, CombinedSupplementalActions } from "ng2/actions/supplemental-actions";
import { FancyAction, FancyFirebaseObject, AngularLandConnection } from 'ng2/fancy-firebase/base';

const pullColumnKeys = [
	"finishX",
	"plannedFinish",
	"plannedStart",
	"pullColumn",
	"startX",
	"tminusFinish",
	"screenFinishX",
	"screenStartX"
];

class PullColumn{
	public startX?: number;
	public finishX?: number;
	public screenStartX?: number;
	public screenFinishX?: number;
	public pullColumn?: number;
	public tminusFinish?: number;
	public plannedFinish?: string;
	public plannedStart?: string;
	
	constructor(updateObj: PullColumn){
		this.update(updateObj);
	}
	
	// update(startX?: number, finishX?: number, pullColumn?: number, tminusFinish?: number){
	update(updateObj: PullColumn){
		pullColumnKeys.forEach(key => {
			if(updateObj[key] !== undefined){ this[key] = updateObj[key]; }
		});
	}

}

class PullColumnPool{
	private internalList = [];
	private currentIdx = 0;
	
	grab(pullColumnData: PullColumn){
		let item: PullColumn; 
		if(this.currentIdx >= this.internalList.length){
			item = new PullColumn(pullColumnData);
			this.internalList.push(item);
		}
		else{
			item = this.internalList[this.currentIdx];
			item.update(pullColumnData);
		}
		
		this.currentIdx++;
		return item;
	}
	
	reset(){
		this.currentIdx = 0;
	}
	
	destroy(){
		this.internalList = [];
	}
}


export class PullColumnObject extends FancyFirebaseObject<any>{
	private pullColumnPool = new PullColumnPool();
	private camera: Camera;
	
	constructor(angularLand:AngularLandConnection, path:any){ //fix any's
		super(angularLand, path);
	}
	
	$$updated(rawList:any){
		if(!rawList || !rawList.payload.exists()){ return []; }
		
		let list = [];
		
		rawList.payload.forEach(child => {
			list.push(this.pullColumnPool.grab(child.val()));
		});
		this.applyLatestCamera(list);
		console.log('pull', rawList);
		return list;
	}
	
	handleSupplementalEvents(baseObservable:Observable<CombinedSupplementalActions>){
		return baseObservable.pipe(
			mergeMap(action =>{
				switch(action.type){
					case SupplementalActionTypes.cameraChanged:
						return this.cameraUpdate(action.payload)
					default: break;
				}
				
				return empty();
			})
		)
	}
	
	private applyLatestCamera(list: Array<PullColumn>){
		if(!this.camera || !list){ return; } //maybe we want to actually sctrip camera data in this case
		
		list.forEach(pc => {
			pc.screenStartX = this.camera.realToScreenX(pc.startX);
			pc.screenFinishX = this.camera.realToScreenY(pc.finishX);
		});
	}
	
	protected cameraUpdate(camera: Camera){
		this.camera = camera;
		
		if(this._internalObject){
			this.applyLatestCamera(this._internalObject);
			return of(this._internalObject);
		}
		else {return empty();}
	}
}
