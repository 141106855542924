import { Subject, combineLatest, Observable } from "rxjs";
import { takeUntil, map, distinctUntilChanged } from "rxjs/operators";

export interface IEnabledFeatures {
	crewSizesEnabled?: boolean;
	sharedMeeting?: boolean;
}

export class EnabledChecker {
	public changed$: Observable<IEnabledFeatures>;
	private shutdownSubject$: Subject<boolean> = new Subject();
	constructor(project$, fbdbSettings$, plan$){
		//these 2 arrays need to be the same length
		const settingKeys: string[] = ['crewSizesEnabled', 'sharedMeeting'];
		const planKeys: string[] = ['activeLineDate', null];
		this.changed$ = combineLatest(project$, fbdbSettings$, plan$).pipe(
			takeUntil(this.shutdownSubject$),
			map((combined) => {
				let combinedObj: IEnabledFeatures = {};
				settingKeys.forEach((key: string, idx: number) => {
					if ( (combined[0] && combined[0][key] || combined[1] && combined[1][key]) 
							&& combined[2] && (
								planKeys[idx] === null 
								|| (planKeys[idx] && combined[2][planKeys[idx]])
							) ) {
						combinedObj[key] = true;
					} else {
						combinedObj[key] = false;
					}
				});
				return combinedObj;
			}),
			distinctUntilChanged()
		);
		// We add the value of enabled features to this object. That makes it easier to access inside AngularJS scope
		this.changed$.subscribe((settings: IEnabledFeatures) => {
			settingKeys.forEach((key) => {
				if (settings[key]){
					this[key] = true;
				} else {
					this[key] = false;
				}
			});
		});
	}
	
	destroy(){
		this.shutdownSubject$.next(true);
	}
}