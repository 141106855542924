// Created by kdo on 8/27/15.
'use strict';

import * as angular from "angular";

declare const window: any;
//This directive will show a customizable message across the application, editable through firebase. This features was
// introduced to inform users about maintence updates, or when FBDB is partially or fully down.
//#### how to display
//In firebase this directive looks for a particular keys on root/statusMessage. You can add plain text or html to either,
//of these keys.
//* statusMessage/message
//* statusMessage/popupMessage (optional)

//#### statusMessage/message
//This key is the main message that will appear on all pages. You can give it plain text or html.
//#### statusMessage/popupMessage
//If this key is present there will be a details link on the main message that will pop a popup containing the html or
//plain text in this key.

//#### how to hide
//To hide, delete the statusMessage/message key.

angular.module('ticketspaceApp.directives').directive('applicationWideMessage', ["fbConnection", "popup", function (fbConnection, popup) {

    return {
        restrict: 'EA',
        scope: true,
        template: "<div class='application-wide-message' ng-show='statusMessage.message'>" +
            "<span class='content'>{{statusMessage.message}}</span> " +
            "<span class='link' ng-click='generatePopup()' ng-show='statusMessage.popupMessage'>Details</span>" +
            "</div>",
        link: function(scope:any, element, attrs, ngModel){

            if(!scope.popup)
                scope.popup = popup;

					scope.statusMessage = fbConnection.fbObject('statusMessage');
					scope.statusMessage.$watch(function(){
						window.statusMessageDisplayed = !!scope.statusMessage.message;
					});

            scope.generatePopup = function(){
                scope.popup({
                    template: '<div class="popup-left-justify limit-width">' + scope.statusMessage.popupMessage + '</div>' +
                    '<div class="button-wrapper-lg" ng-click="close()"><button class="button done-button">Done</button></div>'
                });
            };
        }
    };
}]);
