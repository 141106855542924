'use strict';

import * as angular from "angular";
import * as moment from "moment";

angular.module('ticketspaceApp')
.factory('firebasePullColumns', ["$q", "$firebaseUtils", "$firebaseArray", "fbConnection", function($q, $firebaseUtils, $firebaseArray, fbConnection) {
	
	function PullColumns(ref){
		this.dateIdx = {};
		this.xIdx = [];
		// call the super constructor
		var thing = $firebaseArray.call(this, ref);
		return thing;
	}
	PullColumns.prototype = {
		$$process: function(type, rec, prevChild){
			var changed = $firebaseArray.prototype.$$process.apply(this, arguments);
			this.recalcDateCache();
			return changed;
		},
		$getDateRecord: function(key){
			return this.dateIdx.hasOwnProperty(key) ? this.dateIdx[key] : null;
		},
		$getXRecord: function(x){
			for(var i=this.$list.length-1; i >= 0; i--){
				if(this.$list[i].finishX !== undefined &&  (x <= this.$list[i].finishX || i === 0)){
					return this.$list[i];
				}
			}
			return null;
		},
		recalcDateCache: function recalcDateCache(){
			
			//probably not necessary, list should already be sorted?
			// this.xIdx = this.$list.slice();
			// this.xIdx.sort(function(a,b){
			// 	return a.finishX > b.finishX;
			// });
			
			this.dateIdx = {};
			var cache = this.dateIdx;
			this.$list.forEach(function(p){
				if(!p.finishX || !p.plannedFinish){ return; }
				cache[p.plannedFinish] = p;
				if(p.plannedFinish !== p.plannedStart){
					var failsafe = 90;
					var count = 0;
					var lastDate = moment(p.plannedStart, 'YYYY-MM-DD');
					var date = moment(p.plannedFinish, 'YYYY-MM-DD');
					while(date.subtract(1,'days').isSameOrAfter(lastDate) && count++ < failsafe){
						cache[date.format('YYYY-MM-DD')] = p;
					}
				}
			});
		}
	};
	
	var eFactory = $firebaseArray.$extend(PullColumns);
	
	return eFactory;
}])
