'use strict';

import * as angular from "angular";
import * as moment from "moment";

import * as PIXI from "pixi.js";

import { dateCache } from "ng2/common/date-cache";

angular.module('ProjectView').factory("Guidelines", ProjectViewGuidelinesFactory);

ProjectViewGuidelinesFactory.$inject = ["projectViewUtils"];
function ProjectViewGuidelinesFactory(projectViewUtils){
	/**
	 * @namespace GuideLines
	 * @param options {Object} Used to control Guideline draw colors
	 * @memberOf ProjectView
	 * @constructor
	 */
	function Guidelines(options){
		// Private vars
		var lineStyle = options.lineStyle || "#a5ed0f";
		var lineAlpha = options.lineAlpha || 1;
		var fillAlpha = options.fillAlpha || 1;
		var fillStyle = options.fillStyle || "#67774a";
		var lineSize = options.lineSize || 0;
		var guideLines = new PIXI.Graphics();
		var parent = options.parent || null;
		var active = true;
		var today = dateCache.todayString;
		this.pos = {
			x: 0,
			y: 0
		};
		this.size = {
			width: 0,
			height: 0
		};
		/**
		 * @function draw
		 * @desc Draws the guidelines
		 * @param x {Number} The X world coordinate to draw the guidelines to
		 * @param y {Number} The Y world coordinate to draw the guidelines to
		 * @param width {Number} The width of the guidelines
		 * @param height {Number} The height of the guidelines
		 * @memberOf ProjectView.GuideLines
		 */
		this.draw = function(x, y, width, height){
			if (!active){ return guideLines;}
			guideLines.clear();
			guideLines.lineStyle(options.lineSize || 2, parseInt(lineStyle.substring(1), 16), lineAlpha);
			guideLines.beginFill(parseInt(fillStyle.substring(1), 16), fillAlpha);
			guideLines.drawRect(x, y-100, width, height+100);
			guideLines.endFill();
			this.pos.x = x;
			this.pos.y = y;
			this.size.width = width;
			this.size.height = height;
			return guideLines
		};
		/**
		 * @function update
		 * @desc Updates the guidelines and then redraws
		 * @param dates {array} An array containing the date range.
		 * @param scale {Number} the horizontal scale of the Overview window
		 * @param height {Number} The height of the guidelines.
		 * @memberOf ProjectView.GuideLines
		 * @returns {*}
		 */
		this.update = function(dates, scale, height){
			scale = scale || options.scale || 1;
			if (active && dates && dates.length){
				if (!guideLines.visible){guideLines.visible = true;}
				var firstDate = dates[0];
				var lastDate = dates[1];
				var days = projectViewUtils.getDayDifference(firstDate, lastDate);
				if (days <= 0){ days = 1;}
				var drawX = projectViewUtils.calculateDrawPos(firstDate, lastDate, days);
				var width = days * scale;
				this.draw(drawX, options.y, width, height)
			} else {
				guideLines.visible = false;
			}
			return guideLines;
		};
		/**
		 * @function setFillStyle
		 * @desc Set the guideline fill style
		 * @param colorString {String} Hex color string for the new guideline fill
		 * @memberOf ProjectView.GuideLines
		 * @returns {*}
		 */
		this.setFillStyle = function(colorString){
			return fillStyle = colorString;
		};
		/**
		 * @function setLineStyle
		 * @desc Set the guideline line style
		 * @param colorString {String} Hex color string for the new boundary line fill
		 * @memberOf ProjectView.GuideLines
		 * @returns {*}
		 */
		this.setLineStyle = function(colorString){
			return lineStyle = colorString
		};
		/**
		 * @function setLineSize
		 * @desc Set the outer boundary line size
		 * @param size {Number} The size of the drawing lines, in pixels.
		 * @memberOf ProjectView.GuideLines
		 * @returns {*}
		 */
		this.setLineSize = function(size){
			return lineSize = size;
		};
		/**
		 * @function setBlend
		 * @desc Set the blending mode of the guideline graphic object
		 * @memberOf ProjectView.GuideLines
		 * @param mode
		 */
		this.setBlend = function(mode){
			this.get().blendMode = mode;
		};
		/**
		 * @function setVisible
		 * @desc Set if the GuideLines are visible or not
		 * @param value {Boolean}
		 * @memberOf ProjectView.GuideLines
		 */
		this.setVisible = function(value){
			guideLines.visible = value;
			active = value;
		};
		/**
		 * @function get
		 * @desc Returns the {CanvasWrapper.GeometricShape} graphics node.
		 * @returns {*}
		 * @memberOf ProjectView.GuideLines
		 *
		 */
		this.get = function(){return guideLines;};
		/**
		 * @function setPosition
		 * @desc Force set the position of the guidelines
		 * @param x {number} The X position of the {CanvasWrapper.GeometricShape}
		 * @param y {number} The Y position of the {CanvasWrapper.GeometricShape}
		 * @memberOf ProjectView.GuideLines
		 */
		this.setPosition = function(x, y){return guideLines.position.set(x, y)};
		/**
		 * @function bindTo
		 * @desc F
		 * @param bind Reset the parent graphics node and bind to it.
		 * @memberOf ProjectView.GuideLines
		 */
		this.bindTo = function(bind){
			parent = bind;
		};

		if (!options.manualDraw){this.update();}
	};

	return Guidelines;
}
