'use strict';

import * as angular from "angular";
import * as utils from "utils";

angular.module('ticketspaceApp')
//### firebaseInvitedUsers
// #### statics
// A set of functions accessed off the the object directly (firebaseInvitedUsers.set())
// ##### set(ref, obj)
// a setter method for firebaseInvitedUsers, feels largely superfluous...
// * ref - a reference to the firebase location, either a literal firebase ref, or a string path. Consider modifying this to take a projectId instead...
// * obj - the object to set

// ##### add()
// nyi
// #### instance
// Called by firebaseInvitedUsers(). Pulls down an instance of the angularfire data when called.
// ##### firebaseInvitedUsers(projectId)
// The constructor
// ##### set(child, data)
// Calls the static set method.
// * child - the id of the invitedMember
// * data - the data to set

// Remove $rootScope
.factory('firebaseInvitedUsers', ["$q", "$log", "$firebaseUtils", "$firebaseArray", "fbConnection", "addStatic", function($q, $log, $firebaseUtils, $firebaseArray, fbConnection, addStatic) {

	//checked during sets
	var allowedFields = ['accessLevel', 'company', 'method', 'roleId', 'secondaryRoles'];
	
	var initialPath = 'invitedUsers/projects/';

	function refify(val){
		if(typeof val === "string"){
			return fbConnection.fbRef(val);
		}
		else{
			return val;
		}
	}
	
	function filterObj(obj, allowed){
		var filteredObj = {};
		allowed.forEach(function(key){
			if(obj[key]){ filteredObj[key] = obj[key]; }
		});
		return filteredObj;
	}

	var statics = {
		add: function add(){
			//nyi
		},
		set: function set(projectId, email, obj, config){
			
			if((!projectId || !email) && (!config || !config.refOverride)){ return $q.reject('missing args'); }
			
			var email = utils.escapeEmail(email);
			var ref;
			if(config && config.refOverride){ ref = config.refOverride; }
			else { ref = initialPath+projectId+'/'+email; }
			return $q(function(resolve, reject){
				var filteredObj = filterObj(obj, allowedFields);
				
				ref = refify(ref);
				ref.update(filteredObj, function(err){
					if(err){ reject(err); }
					else{ resolve(ref); }
				});
			});
		}
	};
	
	var InvitedUsersFactory = function InvitedUsersFactory(ref){
		//console.log('woo');
		return $firebaseArray.call(this, ref);
	};
	InvitedUsersFactory.prototype = {
		$$added: function(snap){
			//console.log('added', this);
			return new InvitedUser(snap)
		},
		$$updated: function(snap){
			//console.log('updated');
			var rec = this.$getRecord(snap.key);
			rec.update(snap);
		},
		$$removed: function(snap){
			//console.log('$firebaseUtils', $firebaseUtils);
			this.$list._editIndex = 0;
			this.$list._temp = null;

			return this.$getRecord(snap.key);
		},
		set: function(email, obj){
			if(obj.role){obj.roleId = obj.role.$id; delete obj.role;}
			email = utils.escapeEmail(email);
			var ref = this.$list.$ref().child(email);
			return statics.set(null, null, obj, {refOverride: ref});
		}
	};
	//addStatic.direct(InvitedUsersFactory.prototype, statics);

	
	function InvitedUser(snap){
		this.$id = snap.key;
		this.update(snap);
	}
	InvitedUser.prototype = {
		update: function(snap){
			//store the data on...data
			this.data = snap.val();
		},
		toJSON: function(){
			//return the parsed data from it's "data" location
			return $firebaseUtils.toJSON(this.data);
		}
	};

	var arrayFactory = $firebaseArray.$extend(InvitedUsersFactory);
	

	// individual factory caching test
	var lastProjectId;
	var listCache;
	
	var creator = function creator(projectId){
		var refUrl = initialPath+projectId;
		var fb = new arrayFactory(fbConnection.fbRef(refUrl));
		fb._projectId = projectId;
		return fb;
	};
	addStatic.direct(creator, statics);

	return creator;
}]);
