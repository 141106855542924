import { IFontData } from "./";
import { IFontOptions } from "./FancyText";
import * as Wrapper from "opentype-layout-improved";
/**
 * An enum used to control the wrapping mode.
 *
 * 'PRE' = maintain spacing
 * "NO_WRAP" = Collapse whitespace but only break on newline characters.
 */
export enum WORD_WRAP_MODE {
	'PRE' = 'pre',
	'NO_WRAP' = 'nowrap',
	'GREEDY' = 'greedy'
}
/**
 * The options used to control the WordWrap:
 *
 * width = The width of the box in font units
 * align = The alignment of the text within its width
 * letterSpacing = The additional letter spacing in font units
 * lineHeight = The line height in font units. Default = 1.175 * font.unitsPerEm
 * start = The starting character index into text to layout.
 * end = The ending index into text to layout. This is exclusive.
 * mde = Value from WORD_WRAP_MODE
 */
export interface IWordWrapOptions {
	width?: string|number,
	align?: string,
	letterSpacing?: string|number,
	lineHeight?: string,
	start?: number,
	end?: number,
	mode?: WORD_WRAP_MODE,
	breakWords?: boolean
}
/**
 * Interface for storing the glyph paint position data
 */
export interface IGlyphPosition {
	0: number,
	1: number
}
/**
 * Interface for storing the glyph layout data
 */
export interface IGlyphLayout {
	char: string,
	column: number,
	data: any,
	index: number,
	position: IGlyphPosition,
	row: number
}
/**
 * Interface for storing the glyph layout lines
 */
export interface ILayoutLines {
	start: number,
	end: number,
	width: number
}
/**
 * Interface for the return data of WordWrapper.wrap.
 */
export interface ILayoutData {
	baseline: number,
	glyphs: IGlyphLayout[],
	height: number,
	leading: number,
	left: number,
	lineHeight: number,
	lines: ILayoutLines[],
	maxLineWidth: number,
	width: number
}
/**
 * An interface for a parsed unit. This is an array where:
 *
 * 0 = The numerical value of the unit
 * 1 = The unit for the value
 */
export interface IParsedUnit {
	0: number,
	1: string
}
/**
 * WordWrapper utility class. All methods within are static.
 */
export class WordWrapper {
	/**
	 * The wrap method is responsible for computing the drawing positions for each glyph in the string.
	 *
	 * For the wrapping options, it assumes all units are in Font Units ('em')
	 * @param {IFontData} font The OpenType.js parsed font data
	 * @param {string} text The string that we want to layout
	 * @param {IWordWrapOptions} options
	 */
	public static wrap(font: IFontData, text: string, options?: IWordWrapOptions): ILayoutData {
		return Wrapper(font, text, options);
	}
	/**
	 * Returns the font units for the given inputs
	 * @param {IFontData} font The parsed OpenType.js data. You can retrieve this via the FontManager
	 * @param {number} fontSize The size of the font
	 * @param {number | string} value The current value. This can be a value from CSS or an arbitrary number.
	 */
	public static getEmUnits(font: IFontData, fontSize: number, value: number|string): number {
		let parsed: IParsedUnit = WordWrapper.parseUnit(value);
		if (typeof value === 'number'){parsed[1] = 'px';}
		if (parsed[1] === 'em') {
			return parsed[0] * font.unitsPerEm;
		} else if (parsed[1] === 'px') {
			let pxScale = 1 / font.unitsPerEm * fontSize;
			return parsed[0] / pxScale;
		} else {
			throw new Error('Invalid unit for getPixelSize: ' + parsed[1]);
		}
	}
	/**
	 *
	 * @param {IFontData} font
	 * @param {number} fontSize
	 * @param {number} value
	 */
	public static getPxUnits(font: IFontData, fontSize: number, value: number): number {
		const pxScale = font.unitsPerEm / fontSize;
		return value / pxScale;
	}
	/**
	 * Returns the font size
	 * @param {IFontData} font The parsed OpenType.js data. You can retrieve this via the FontManager
	 * @param {string | number} value The current value. This can be a value from CSS or an arbitrary number.
	 * @param {number} dpi The Device Pixel Ratio.
	 */
	public static getFontSize(font: IFontData, value: string|number, dpi: number = 1): number {
		const parsed: IParsedUnit = WordWrapper.parseUnit(value);
		return parsed[0] * dpi;
	}
	/**
	 * Get the current scale
	 * @param {IFontData} font
	 * @param {number} fontSizePx
	 */
	public static getScale(font: IFontData, fontSizePx: number): number {
		return 1 / font.unitsPerEm * fontSizePx
	}
	
	public static getPx(value: string|number): number {
		let parsedValue: IParsedUnit = WordWrapper.parseUnit(value);
		return parsedValue[0];
	}
	/**
	 * Returns an array where idx 0 is the value and idx 1 is the unit. The unit will be '' if the passed in value does
	 * not have a unit. Example: '10em' will return [10, 'em'] and '10' will return [10, '']
	 * @param {string} str
	 * @param {IParsedUnit} out
	 * @returns {IParsedUnit}
	 */
	public static parseUnit(str: string|number, out: IParsedUnit = [0, '']): IParsedUnit {
		if (!out) {
			out = [0, ''];
		}
		
		str = String(str);
		out[0] = parseFloat(str);
		out[1] = str.match(/[\d.\-\+]*\s*(.*)/)[1] || '';
		return out
	}
}
