import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input, OnDestroy, Output, EventEmitter, forwardRef } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as $ from "jquery";
import * as tinycolor from "tinycolor2";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ColorPickerComponent),
	multi: true
};

/**
 * Interface to control our usedColors. If this is provided, this causes Spectrum to draw a Checkmark over all matching cases in the palette
 * Example:
 *  {'#000000': 1}
 * Tells Spectrum to draw a checkmark indicating 'color used' over the #000000 color in the palette
 */
export interface IUsedColors {
	[key: string]: number;
}
/**
 * These are options which are passed into the Spectrum color picker
 */
export interface IColorPickerOptions {
	color?: tinycolor,
	flat?: boolean,
	showInput?: boolean,
	showInitial?: boolean,
	allowEmpty?: boolean,
	showAlpha?: boolean,
	disabled?: boolean,
	localStorageKey?: string,
	showPalette?: boolean,
	showPaletteOnly?: boolean,
	togglePaletteOnly?: boolean,
	showSelectionPalette?: boolean,
	clickoutFiresChange?: boolean,
	cancelText?: string,
	labelText?: string,
	chooseText?: string,
	clearText?: string,
	noColorSelectedText?: string,
	editLabelText?: string,
	addColorText?: string,
	togglePaletteMoreText?: string,
	togglePaletteLessText?: string,
	containerClassName?: string,
	replacerClassName?: string,
	preferredFormat?: string,
	maxSelectionSize?: number,
	palette?: [[string]],
	selectionPalette?: [string],
	noAutoClose?: boolean,
	hideAfterPaletteSelect?: boolean,
	usedColors?: IUsedColors
}

const defaultColorPickerOptions: IColorPickerOptions = {
	showAlpha: false,
	showPalette: false,
	showSelectionPalette: false,
	showInput: true,
	preferredFormat: "rgb"
};

@Component({
	selector: 'color-picker',
	template: require('./color-picker.component.html'),
	styles: [],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ColorPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
	
	@ViewChild('spectrumColorPickerElement') $element;
	
	@Input() editLabelText: string = "Select a color";
	@Input() cancelText: string = "Cancel";
	@Input() labelText: string = "";
	@Input() chooseText: string = "Select";
	@Input() noColorSelectedText: string = "";
	@Input() addColorText: string = "";
	@Input() alpha: number = 1;
	@Input() format: string = defaultColorPickerOptions.preferredFormat;
	@Input() options: IColorPickerOptions = defaultColorPickerOptions;
	@Output() change: EventEmitter<string> = new EventEmitter();
	@Output() move: EventEmitter<string> = new EventEmitter();
	@Output() show: EventEmitter<string> = new EventEmitter();
	@Output() hide: EventEmitter<string> = new EventEmitter();
	public color: FormControl;
	public editColor: FormControl;
	
	protected _input: any;
	
	protected _valueChange: any;
	private started: boolean = false;
	
	constructor() {
		this.color = new FormControl('');
		this.editColor = new FormControl('');
	}
	
	public writeValue(value: string){
		const color = tinycolor(value);
		color.setAlpha(this.alpha);
		this.editColor.setValue(color);
		this.color.setValue(color);
		
		if (!this.started){
			this.start();
			this.started = true;
		}
	}
	
	public registerOnChange(fn: any){
		this._valueChange = fn;
	}
	
	public registerOnTouched(fn: any){
		// TODO NYI
	}
	
	protected _onChange(e, tiny: tinycolor): void{
		this.color.setValue(tiny);
		const stringified = tiny.toString(this.format);
		this._valueChange(stringified);
		this.change.emit(stringified);
	};
	
	protected _onMove(e, tiny: tinycolor): void{
		this.editColor.setValue(tiny);
		this.move.emit(this.editColor.value.toString());
	}
	
	protected _onShow(e, tiny: tinycolor): void {
		this.show.emit(this.color.value.toString());
	}
	
	protected _onHide(e, tiny: tinycolor): void {
		this.hide.emit(this.color.value.toString());
	}
	
	public ngOnInit() {
		// boop
	}
	
	public start(){
		if (this.alpha < 1){
			this.options["forceAlpha"] = true;
		}
		this.options["color"] = this.color.value.toString(this.format);
		this.options["cancelText"] = this.cancelText;
		this.options["editLabelText"] = this.editLabelText;
		this.options["labelText"] = this.labelText;
		this.options["chooseText"] = this.chooseText;
		this.options["noColorSelectedText"] = this.noColorSelectedText;
		this.options["addColorText"] = this.addColorText;
		this._input = $('input', this.$element.nativeElement);
		this._input.spectrum(this.options);
		
		// Setup the jQuery -> Angular bindings
		this._input.on('change.spectrum', (e, tiny: tinycolor) => this._onChange(e, tiny));
		this._input.on('move.spectrum', (e, tiny: tinycolor) => this._onMove(e, tiny));
		this._input.on('show.spectrum', (e, tiny: tinycolor) => this._onShow(e, tiny));
		this._input.on('hide.spectrum', (e, tiny: tinycolor) => this._onHide(e, tiny));
	}
	
	public ngOnDestroy() {
		// Calling .off() with no arguments removes all handlers attached to the elements
		this._input.off();
	}
}
