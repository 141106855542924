import { analyticsService } from "ng2/common/utils/AnalyticsService";
import * as angular from "angular";

/**
 * @namespace Directives
 */
angular.module('ticketspaceApp').directive("projectView", ProjectViewDirective);

/**
 * @namespace projectViewDirective
 * @desc Main projectView directive
 * @memberOf Directives
 * @constructor
 */
ProjectViewDirective.$inject = ["ProjectViewCore", "settingStorage", "$window", "activeColumnService", "ProjectViewApi"];
function ProjectViewDirective(ProjectViewCore, settingStorage, $window, activeColumnService, ProjectViewApi){
	return {
		scope: true,
		controllerAs: 'ctrl',
		template: require("tpl/components/project-view-window.html"),
		bindToController: {
			pvBound: '=',
			pvWidth: '=',
			pvHeight: '=',
			timeline: '=',
			pullColumns: '=',
			milestone: '=',
			activeLine: '=',
			today: '=',
			hud: '=',
			plans: '=',
			plan: "="
		},
		link: function(scope:any, element){
			scope.element = element;
		},
		controller: ["$scope", function($scope){
			var self = this;
			this.sizeSelected = settingStorage.setIfExists('projectView', 'sizeSelected', 'project-view-small');
			this.expanded = settingStorage.setIfExists('projectView', 'expanded', false);
			this.selectedHeatmap = settingStorage.setIfExists('projectView', 'selectedHeatmap', null);
			this.drawWaveformData = settingStorage.setIfExists('projectView', 'drawWaveformData', true);
			this.drawGuidelines = settingStorage.setIfExists('projectView', 'drawGuidelines', true);
			this.drawActiveLines = settingStorage.setIfExists('projectView', 'drawActiveLines', true);
			this.drawAheadText = settingStorage.setIfExists('projectView', 'drawAheadText', false);
			this.drawVerticalGuides = settingStorage.setIfExists('projectView', 'drawVerticalGuides', true);
			this.drawTimeline = settingStorage.setIfExists('projectView', 'drawTimeline', true);

			this.expand = function(){
				this.expanded = !this.expanded;
				if (this.expanded){
					analyticsService.projectViewSettingsClicked();
				}
			};

			this.resize = function (newSizeString, centerGuidelines) {
				if (!self.projectView){return}
				this.sizeSelected = newSizeString;
				var div = $('<div>').addClass(newSizeString).hide();
				$('body').append(div);
				var newWidth = div.width();
				var newHeight = div.height();
				div.remove();
				var element = document.getElementById('project-view-minimap');
				if (element){
					if (!newWidth){newWidth = 350;}
					if (!newHeight){newHeight = 250;}
					element.style.width = newWidth + "px";
					element.style.height = newHeight + "px";
					self.projectView.resize(newWidth, newHeight, centerGuidelines);
				}
			};

			this.close = function(){
				$scope.toggleProjectView();
			};

			this.goToPlan = function(){
				settingStorage.save('projectView',{
					sizeSelected: this.sizeSelected,
					expanded: this.expanded,
					selectedHeatmap: this.selectedHeatmap,
					drawWaveformData: this.drawWaveformData,
					drawGuidelines: this.drawGuidelines,
					drawAheadText: this.drawAheadText,
					drawActiveLines: this.drawActiveLines,
					drawTimeline: this.drawTimeline,
					drawVerticalGuides: this.drawVerticalGuides,
					viewport: {
						pos: self.projectView.viewport.getPositions(),
						z: self.projectView.viewport.getScale()
					},
					hud: {
						x: $scope.hud.screenToRealX(0),
						y: $scope.hud.screenToRealY(0),
						z: $scope.hud.z,
						transform: $scope.hud.transformation
					},
					firstDate: this.projectView.getFirstVisibleDate(),
					autoPositionHud: true,
					autoOpen: true
				});
				$scope.goToPlanProjectView(this.selectedHeatmap.$id);
			};

			this.setSelectedPlanBar = function(bar){
				$scope.$apply(function(){
					self.selectedHeatmap = bar;
				})
			};

			this.setWaveform = function(value){
				this.drawWaveformData = value;
				if (this.projectView){
					this.projectView.setHeatmapVisibility(value);
				}
			};

			this.seeAll = function(){
				if (self.projectView){
					self.projectView.seeAll();
					analyticsService.projectViewSeeAllClicked();
				}
			};

			this.focusOnPlan = function(planId){
				if (this.projectView){
					this.projectView.focusOnPlan(planId, 0, 0.1, 2.5);
				}
			};

			this.setDrawAheadText = function(value){
				this.drawAheadText = value;
				if (this.projectView){
					this.projectView.setDrawAheadVisibility(value);
				}
			};

			this.setDrawGuidelines = function(value){
				this.drawGuidelines = value;
				if (this.projectView){
					this.projectView.setGuidelineVisibility(value);
				}
			};

			this.setTimelineVisibility = function(value){
				this.drawTimeline = value;
				if (this.projectView){this.projectView.setTimelineVisibility(value);}
			};

			this.setVerticalGuideVisibility = function(value){
				this.drawVerticalGuides = value;
				if (this.projectView){this.projectView.setVerticalGuideVisibility(value);}
			};

			this.setDrawActiveLines = function(value){
				this.drawActiveLines = value;
				if (this.projectView){
					this.projectView.setActiveLineVisibility(value);
				}
			};

			// Perform an AABB collision check against the X-axis of all tickets and the position of the plan view camera
			function  normalizeCoordinates(startDate, z, topSpacing){
				topSpacing = topSpacing || 500;
				var lowestTop = 99999999;
				var cameraScale = z;
				var newCameraLeftX = activeColumnService.guessXFromDate(startDate);
				var transformX = -newCameraLeftX*cameraScale;
				var newCameraRightX = ($scope.hud.w / cameraScale) - (transformX / cameraScale);
				$scope.tickets.forEach(function(ticket){
					if (ticket.data.left >= newCameraLeftX &&
						ticket.data.left <= newCameraRightX){
						if (ticket.data.top <= lowestTop){lowestTop = ticket.data.top;}
					}
				});
				return {
					x: newCameraLeftX,
					y: lowestTop - (topSpacing/cameraScale)
				}
			}

			// Load up the projectView once everything is loaded.
			$scope.$watch('_allLoaded', function(allLoaded){
				if (!self.projectView && allLoaded && !$window.disableProjectView) {
					self.selectedHeatmap = null;
					// Used to control which features are enabled in the project overview.
					var features = {
						timeline: self.timeline,
						pullColumns: self.pullColumns,
						milestone: self.milestone,
						activeLine: self.activeLine,
						today: self.today,
						hud: self.hud,
						plan: self.plan
					};
					var initOptions = {
						autoStart: false,
						width: self.pvWidth,
						height: self.pvHeight,
						resolution: 1,
						plans: self.plans
					};
					self.projectView = new ProjectViewCore(document.getElementById(self.pvBound), initOptions, features);
					self.resize(self.sizeSelected);
					self.setWaveform(self.drawWaveformData);
					self.setDrawGuidelines(self.drawGuidelines);
					self.setDrawActiveLines(self.drawActiveLines);
					self.setDrawAheadText(self.drawAheadText);
					self.setTimelineVisibility(self.drawTimeline);
					self.setVerticalGuideVisibility(self.drawVerticalGuides);
					ProjectViewApi.build(self.projectView, self);
					
					// MOC-2693 MOC-3199
					// if ($scope.prefs.autoOpenProjectOverview || settingStorage.get('projectView').autoOpen){
					// 	// settingStorage.replace('projectView', 'autoOpen', false);
					// 	$scope.toggleSidebar('projectView');
					// 	//$scope.hud.scaleToFit();
					// 	console.log("OPEN PROJECT VIEW");
					// }
					// $scope.hud.scaleToFit();
					// self.focusOnPlan();
					// MOC-3299
					if ($scope.prefs.autoOpenProjectOverview || settingStorage.get('projectView').autoOpen){
						$scope.toggleSidebar('projectView');
					}
					if (settingStorage.get('projectView').autoOpen) {
						settingStorage.replace('projectView', 'autoOpen', false);
						// $scope.toggleSidebar('projectView');
						self.focusOnPlan();
						// Collect the guesstimated normalized position, given the date
						var newPos = (settingStorage.get('projectView').firstDate) ?
							normalizeCoordinates(settingStorage.get('projectView').firstDate, settingStorage.get('projectView').hud.z, 300) :
							{x: settingStorage.get('projectView').hud.x, y:settingStorage.get('projectView').hud.y};
						// Perform initial resize step of overview window..
						self.resize(self.sizeSelected);
						// Manually set the plan view camera position
						$scope.hud.setPosition(
							newPos.x,
							newPos.y,
							settingStorage.get('projectView').hud.z
						);
						// Manually set the Overview camera position
						self.projectView.viewport.setPosition(settingStorage.get('projectView').viewport.pos, settingStorage.get('projectView').viewport.z);
						settingStorage.replace('projectView', 'autoPositionHud', false);
						
						// console.log("NORMALIZE COORDS!");
					} else {
						// $scope.toggleSidebar('projectView');
						$scope.hud.scaleToFit();
						self.focusOnPlan();
						// console.log("Normal scale!");
					}
				}
			});
			var resizeTimer;
			// Resize the canvas when window changes size
			// Don't perform the resize operation every frame. Instead, debounce the call and do resize after some time has passed since the event was triggered.
			window.onresize = function (event) {
				clearTimeout(resizeTimer);
				resizeTimer = setTimeout(function(){
					self.resize(self.sizeSelected);
				}, 250);
			};
		}]
	};
}
