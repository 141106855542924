
import { Component, Input } from '@angular/core';


/**
 * This component displays an optional message at the top of the edit mode.
 * It's meant for informational things like "Viewing in read only mode" or
 * "Drag ticket or tap date...".
 */
@Component({
	selector: 'ticket-message',
	template: require('./ticket-message.component.html'),
})
export class TicketMessageComponent {

	/**
	 * @input seems like we just need text.  The mockups show some bolding
	 * so perhaps we need some kind of markup support too.
	 */
	@Input() message: String;

	constructor(){
	}

	shouldShow() {
		return this.message ? true : false;
	}
}
