'use strict';

import * as angular from "angular";

//half-assed variant
angular.module('ticketspaceApp.directives')
.directive("tooltip", ["$compile, $q", function($compile, $q){
	return {
		//template: require("tpl/components/intercom-bubble.html"),
		link: function(scope:any, element, attrs){
			console.log('tooltipping');
			
			var textToDisplay;
			var elementDefinition = '<div class="tooltip"><div class="tooltip-arrow-left"></div><div class="tooltip-text"></div></div>';
			var compiled = $compile(elementDefinition)(scope);
			var elementText = compiled.find('.tooltip-text');
			
			attrs.$observe('tooltip', function(val){
				textToDisplay = val;
			});

			function displayTheThing(){
				elementText.text(textToDisplay);
				element.append(compiled);
			}
			
			//dom shit
			var timeout;
			var timeoutDelay = 1000;
			element.on('click', function(){
				displayTheThing();
			});
			element.on('mouseover', function(){
				timeout = setTimeout(function(){
					displayTheThing();
				}, timeoutDelay);
			});
			element.on('mouseout', function(){
				clearTimeout(timeout);
				compiled.remove();
			});

			
			scope.$on('$destroy', function(){
				//need to do things here
			});
		}
	}
}]);
