"use strict";

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";

import {LegacyRouting} from "ng2/routes/legacy-routing.service";
import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

import {perf} from "ng2/common/perfLog"

import { analyticsService, AnalyticsService } from "ng2/common/utils/AnalyticsService";

declare const window: any;

//todo - use this to add generic styling/ positioning/ code to sidebar panels
angular.module('ticketspaceApp').directive('panel', function () {
    return {
        restrict: 'EA',
		compile: function(element){
			element.addClass("panel");
		}
    };
})

//<panel-head no-h1><h3>Roles</h3></panel-head>
//<panel-head>Roles</panel-head>
//if it needs to be more custom than this, just use the template directly
// close button defaults to a $scope.closeSidebar() call
// TODO - customize that
.directive("panelHead", function(){
	return {
		template: '<h1></h1><button class="panel-close" ng-click="closeSidebar();"><i class="material-icons notranslate">close</i></button>',
		transclude: true,
		link: function(scope:any, element, attrs:any, __, $transclude){
			var h1 = element.find('h1');
			$transclude(function(clone, scope){
				if(attrs.$attr.noH1){h1.remove(); element.prepend(clone); }
				else{ h1.append(clone); }
			},element);
		}
	}
})

.directive('reportProgress', function(){
	return {
		restrict: 'E',
		compile: function(element, attrs){
			
		},
		template: function(element, attrs){
			if(!attrs.type) return '';
			return '<div class="panel-status clearfix">\
					<progress-bar progress-bar-id="'+attrs.type+'"></progress-bar>\
					<div class="panel-status-button-container">\
						<button ng-show="ctrl.status.'+attrs.type+'.state === \'progress\'" ng-click="ctrl.cancel(\''+attrs.type+'\')" class="panel-status-button btn--dark-grey">Cancel</button>\<button ng-show="ctrl.status.'+attrs.type+'.state === \'error\'" ng-click="ctrl.getReport(\''+attrs.type+'\')" class="panel-status-button btn--blue btn--blue__no-border">Retry</button>\
					</div>\
					<div class="clear"></div>\
					<div class="panel-status-button-container" ng-show="ctrl.status.'+attrs.type+'.state === \'complete\'">\
						<button ng-click="ctrl.clearStatus(\''+attrs.type+'\')" class="panel-status-button btn--dark-grey">Reset</button>\
						<button ng-click="ctrl.openReport(\''+attrs.type+'\')" class="panel-status-button btn--blue btn--blue__no-border">Open Report</button>\
					</div>\
					<span class="panel-status-text" \
							ng-class="{error: ctrl.status.'+attrs.type+'.state === \'error\'}"\
							ng-bind="ctrl.status.'+attrs.type+'.message">\
					</span>\
				</div>\
				';
		}
	}
	
})

.directive('reportsPanel', ["$parse", "$timeout", "popup", "firebaseProjects", function ($parse, $timeout, popup, firebaseProjects) {
	return {
		restrict: 'EA',
		scope: true,
		controllerAs: 'ctrl',
		bindToController: {
			roles: '=',
			plans: '=',
			locations: '=',
			csvPlanPrefix: '@',
			csvProjectPrefix: '@',
			type: '@'
		},
		controller: ["$q", "$timeout", "fbConnection","fbdbRequests", "progressBarManager","developerMode", "authService", "presence", "DatePositionValidator", "projectNotifier", "legacyRouting", "reportService", function($q, $timeout, fbConnection,fbdbRequests, progressBarManager,developerMode, authService, presence, DatePositionValidator, projectNotifer:ProjectNotifier, legacyRouting: LegacyRouting, reportService){
			this.legacyState = projectNotifer.projectStateSnapshot;
			
			window.ReportPanel = this;
			
			this.user = authService.userData;
			this.userPrefs = authService.userPreferences;
			
			//shitty workaround for angular -> angularjs bs
			this.setUserPref = function(key, val){
				// console.log('hi', arguments);
				this.userPrefs.set(key, val);
			}
			this.setUserPrefList = function(key, val, nestKey){
				return this.setUserPref(key, val ? val.map(t => t[nestKey]) : val);
			}
			
			this.resetUserPrefList = function(){
				this.userPrefs.setObj({
					reportsLastProjectId: this.projectId,
					reportsLastAsOfDate: null,
					reportsLastUpToDate: null,
					reportsLastNumberOfWeeks: "1",
					reportsLastGanttTimeScale: "day",
					reportsLastTimeScale: 20,
					reportsLastShowCompleted:true,
					reportsLastSortByRole:null, //maybe fix this?
					reportsLastBreakUpByRole:false,
					reportsLastGroupByLocation:null, //maybe fix this?
					reportsLastOrderLocationByName:null, //maybe fix this?
					reportsOnlyLateConstraints:null,
					reportsFilterRoles: null,
					reportsFilterPlans: null,
					reportsFilterLocations: null,
				})
			};
			
			this.resetFilters = function(){
				this.roleSelection = null;
				this.planSelection = null;
				this.locationSelection = null;
				
				this.userPrefs.setObj({
					reportsFilterRoles: null,
					reportsFilterPlans: null,
					reportsFilterLocations: null,
				})
			}
			

			
			analyticsService.openedReportsPanel();
			this.developerMode = developerMode;
			this.locationPrependList = [{text:'No Location', '$id': 'thisCannotBe'}];
			this.groupByLocation = null;
			this.projectId = this.legacyState.projectId;
			
			if(this.userPrefs.reportsLastProjectId && this.userPrefs.reportsLastProjectId !== this.projectId){
				this.resetUserPrefList();
			}
			else{
				this.userPrefs.set("reportsLastProjectId", this.projectId);
			}
			
			// Select options for the new select dropdowns
			this.granularityOptions = [
				{label: "Week", value: "week"},
				{label: "Day", value: "day"},
				{label: "Quarter", value: "quarter"},
			];
			this.ppcSelectedReportOptions = [
				{label: "by Week", value: "ppcChartsByWeek"},
				{label: "by Role", value: "ppcChartsByTradeToDate"},
				{label: "by Variance Reason", value: "ppcChartsByVarianceReason"},
				{label: "Variance by Role", value: "ppcVarianceByRole"},
				{label: "Variance by Week", value: "ppcVarianceByWeek"},
			];
			this.varianceSelectedOptions = [
				{label: "Basic Variance Report", value: "varianceReason"},
				{label: "Advanced Variance Report", value: "varianceReview"},
			]
			this.numberOfWeeksOptions = [
				{label: "1", value: "1"},
				{label: "2", value: "2"},
				{label: "3", value: "3"},
				{label: "4", value: "4"},
				{label: "5", value: "5"},
				{label: "6", value: "6"},
			];
			if(!this.projectId){ console.log('yes, it\'s this problem'); }

			let supportedProperties = {
				"projectReportBundle": ["startDate"],
				"weeklyWorkPlan": ["startDate", "weekNum", "roleSelection", "planSelection", "locationSelection", "includeCompleted", "orderByDate", "iterateRoleIds", "groupByLocation", "orderLocationByName"],
				"varianceReason": ["varianceSelected", "startDate", "weekNum", "roleSelection", "planSelection", "locationSelection", "orderByDate", "iterateRoleIds", "groupByLocation", "orderLocationByName"],
				"sixWeekLookAhead": ["startDate", "roleSelection", "planSelection", "locationSelection", "includeCompleted", "orderByDate", "iterateRoleIds", "groupByLocation", "orderLocationByName"],
				"ganttView": ["startDate", "endDate", "granularity", "roleSelection", "planSelection", "locationSelection", "includeCompleted", "orderByDate", "iterateRoleIds", "groupByLocation", "orderLocationByName"],
				// "varianceReview": ["varianceSelected", "startDate", "endDate", "roleSelection", "planSelection", "locationSelection", "orderByDate", "groupByLocation"],
				"overdueActivity": ["startDate", "granularity", "roleSelection", "planSelection", "locationSelection", "orderByDate", "iterateRoleIds", "groupByLocation"],
				"ppc": ["ppcSelectedReport", "startDate", "endDate", "roleSelection", "planSelection", "locationSelection", "iterateRoleIds"],
				"constraintLog": ["roleSelection", "locationSelection", "includeCompleted", "orderByDate", "iterateRoleIds", "groupByLocation", "onlyLateConstraints"],
				"milestoneStatus": ["planSelection"],
				"milestoneTrend": ["startDate", "endDate", "scale", "planSelection"],
				"onDeckTickets": []
			};
			
			let internalProperties = ["roleSelection", "planSelection", "locationSelection", "scale", "includeNoLocation", "includeCompleted", "groupByLocation", "orderLocationByName", "startDate", "endDate", "weekNum", "iterateRoleIds", "onlyLateConstraints", "orderByDate", "granularity"];



			this.granularity = 'day';
			
			this.verifyDate = function(date, dateString: string, dateKey: string){
				if (!date){return}
				DatePositionValidator.verifyProjectDate(date, {
					"title": "Are you sure you want to set the " + dateString + " date to: " + date + "?",
					"description": "Your " + dateString + " is outside of the expected date range for your project."
				}, () =>{
					this[dateKey] = date;
				}, () => {
					this[dateKey] = null;
				});
			};
			
			this.reportBundles = {
				list: fbConnection.fbArray('projects/' + this.projectId+'/customReportBundles'),
				selected: "",
				submit: function(){
					Logging.notice('Error.');
				},
				buildReportsList: function(asOf, extra){
					if(!this.selected || !this.selected.reportBundle){return false;}
					console.log('selected', this.selected);
					
					var insertList = {};
					var shouldInsert = false;
					
					var reportsList = angular.copy(this.selected.reportBundle);
					reportsList.forEach(function(report,key){
						var enteredDateType = report.dateIsEndDate ? "upToDate" : "asOfDate";
						var calculatedDateType = report.dateIsEndDate ? "asOfDate" : "upToDate";
						
						if(asOf){ //do math
							var momentedDate = moment(asOf);
							var didSomething = false;
							if(report.durationOffsetDays !== undefined){
								momentedDate.add(report.durationOffsetDays, 'days');
								didSomething = true;
							}
							if(report.dayOfWeekOffset !== undefined){
								momentedDate.day(report.dayOfWeekOffset);
								didSomething = true;
							}
							
							if(report[enteredDateType] !== false) {report[enteredDateType] = asOf;}
							if(didSomething && report[calculatedDateType] !== false){
								report[calculatedDateType] = momentedDate.format('YYYY-MM-DD');
							}
						}
						
						if(report.iterateRoleIds && report.roleIds){
							insertList[key] = fbdbRequests.unrollBundleByKey(extra, "roleIds");
							// insertList[key] = fbdbRequests.unrollBundleByKey(undefined, "roleIds");
							shouldInsert = true;
						}
						
						//strip that which needs to be stripped
						if(report.asOfDate === false){report.asOfDate = null;}
						if(report.upToDate === false){report.upToDate = null;}
						
						report.durationOffsetDays = null;
						report.dayOfWeekOffset = null;
						report.dateIsEndDate = null;
						
					});
					
					if(shouldInsert){
						var idxOffset = 0;
						Object.keys(insertList).forEach(function(key){
							console.log('key', key);
							//reportsList.splice(key+idxOffset
							reportsList.splice.apply(reportsList, [key+idxOffset,0].concat(insertList[key]));
							idxOffset += Number(key);
							//idxOffset += key;
						});
					}
					console.log('reportsList', reportsList);
					return reportsList;
				}
			};
			
			var cont = this;
			this.parseRoleForSumo = function(role){
				if(!role){return '';}
				return "<div class='"+role.$id+" woo'></div>";
			}
			this.parseLocationForSumo = function(location){
				if(!location){return '';}
				return "<div style='background: "+location.data.backgroundStyle+"'; class='woo'></div>";
			}
			
			//index by reportType
			// {
				// state: undefined/"progress"/"error"/"done",
				// bar: progressBar,
				// message: "message",
				// requestId: "id"
				// promise
			// }
			this.status = {};
			[
				'weeklyWorkPlan', 'varianceReason', 'sixWeekLookAhead', 'ganttView',
				'ppcChartsByWeek', 'ppcChartsByTradeToDate', 'ppcChartsByVarianceReason',
				'ppcVarianceByRole', 'ppcVarianceByWeek', 'constraintLog',
				'milestoneStatus', 'milestoneTrend', 'onDeckTickets', 'overdueActivity',
				'varianceReview',
				'projectReportBundle'
			].forEach(function(key){
				this.status[key] = {bar: progressBarManager.get(key)}
			},this);
			
			function navigato(type, projectId, requestId){
				//they all go to the same place now
				var url = legacyRouting.href(['reports', projectId, requestId]);
				
				//a very rudimentary cross window communtions system...
				if(!window.reportClosed){ window.reportClosed = {}; }
				window.reportClosed[url] = function(){
					$timeout(function(){
						cont.clearStatus(type);
					});
				};
				
				perf.log('open report button clicked');
				//console.log(" NAVIGSTO URL", url )
				window.open(url, '_blank');
			}
			
			function validateDate(date){
				//console.log(date);
				if(date){
					var thisYear = moment().year();
					var prDate = moment(date, 'YYYY-MM-DD', true);
					var limiter = 100;

					if(prDate.isValid()){
						console.log('moment', prDate, prDate.year());

						if(prDate.year() > thisYear + limiter) {
							Logging.warning('Date "'+date+'" is more then '+limiter+' years in the future');
							return false;
						}
						else if(prDate.year() < thisYear - limiter) {
							Logging.warning('Date "'+date+'" is more then '+limiter+' years in the past');
							return false;
						}
						else{ return true; }
					}
					else{
						Logging.warning('Date: ' + date + ' is invalid');
						return false;
					}
				}
				return true;
			}
			
			function analyticsStuff(type, requestId, startDate, endDate, roles, plans){
				analyticsService.generatedReport(type, requestId, startDate, endDate, AnalyticsService.convertArrayToString(roles), AnalyticsService.convertArrayToString(plans));
			}
			
			this.genExtraLimits = function(){
				var extra:any = {};
				if(this.iterateRoleIds && (!this.roleSelection || !this.roleSelection.length)){var roles = this.roles;}
				else{ var roles = this.roleSelection; }
				
				if(roles && roles.length){
					extra.roleIds = roles.map(function(val){ return val.$id; });
				}
				if(this.planSelection && this.planSelection.length){
					extra.planIds = this.planSelection.map(function(val){ return val.$id; });
				}
				
				if(this.locationSelection && this.locationSelection.length){
					//console.log('this.locationSelection', this.locationSelection);
					var includeNoLocation = false;
					extra.locationIds = this.locationSelection.map(function(val){
						includeNoLocation = includeNoLocation || this.locationPrependList.some(function(s){return s.$id === val.$id });
						return val.$id; 
					},this);
					extra.includeNoLocation = includeNoLocation;
					//console.log( "Location IDs:", extra.locationIds, includeNoLocation );
				}
				else{
					extra.includeNoLocation = null;
				}
				//console.log('extra', extra);
				// if(!this.locationSelection && this.includeNoLocation){
				// 	extra.locationIds = ["allIdsArePossible"];
				// }
				//if(this.includeNoLocation){extra.includeNoLocation = true;}
				
				if(this.orderByDate === false || this.orderByDate === null || this.orderByDate === true){
					extra.orderByDate = this.orderByDate;
				}

				if(this.groupByLocation === false || this.groupByLocation === null || this.groupByLocation === true){
					extra.groupByLocation = this.groupByLocation;
					extra.orderLocationByName = this.orderLocationByName;
				}
				if(this.scale !== undefined && !isNaN(this.scale) ){
					extra.scale = this.scale;
				}

				if ( this.granularity ) {
					extra.granularity = this.granularity;
				}
				
				if(this.onlyLateConstraints){ extra.onlyLateConstraints = this.onlyLateConstraints; }

				//console.log("groupByLocation", extra.groupByLocation);
				//console.log( extra );
				
				return extra;
			}
			
			this.validPrefList = function(list, prefList){
				if(!prefList){return null;}
				return prefList.reduce((filtered, id) => {
					if(!list){ return filtered; }
					var rec = list.$getRecord(id)
					if(rec){filtered.push(rec);}
					return filtered;
				}, []);
			}
			
			this.toggleCell = function(type){
				
				function undefToNull(thing){
					if(thing === undefined){return null;}
					return thing;
				}

				this.roleSelection = this.validPrefList(this.roles, authService.userPreferences.reportsFilterRoles);
				this.planSelection = this.validPrefList(this.plans, authService.userPreferences.reportsFilterPlans);
				this.locationSelection = this.validPrefList(this.locations, authService.userPreferences.reportsFilterLocations);
				this.scale = authService.userPreferences.reportsLastTimeScale || null;
				this.includeNoLocation = null; //generated later
				this.includeCompleted = undefToNull(authService.userPreferences.reportsLastShowCompleted);
				this.groupByLocation = undefToNull(authService.userPreferences.reportsLastGroupByLocation);
				this.orderLocationByName = authService.userPreferences.reportsLastOrderLocationByName || null;
				this.startDate = authService.userPreferences.reportsLastAsOfDate || null;
				this.endDate = authService.userPreferences.reportsLastUpToDate || null;
				this.weekNum = authService.userPreferences.reportsLastNumberOfWeeks || null;
				
				this.iterateRoleIds = authService.userPreferences.reportsLastBreakUpByRole;
				this.onlyLateConstraints = authService.userPreferences.reportsOnlyLateConstraints;
				this.orderByDate = undefToNull(authService.userPreferences.reportsLastSortByRole);
				this.granularity = authService.userPreferences.reportsLastGanttTimeScale || "day";
				
				if(type === this.selectedType){this.selectedType = ""; }
				else{ this.selectedType = type; }
				
				// if(this.selectedType === "milestoneTrend"){this.scale = 20;}
				
				// if((this.selectedType === "weeklyWorkPlan"
				// 		|| this.selectedType === "sixWeekLookAhead"
				// 		|| this.selectedType === "ganttView"
				// 		|| this.selectedType === "constraintLog")){
				// 	this.includeCompleted = true;
				// }
				
				// if(this.selectedType === "constraintLog"){this.groupByLocation = false;}
				
				//initialization validation
				var goodProps = supportedProperties[type];
				internalProperties.forEach((key)=>{
					// console.log('key', goodProps, key, goodProps[key]);
					if(!goodProps.includes(key)){this[key] = null;}
				})
				
			}
			
			this.clearStatus = function(reportType){
				var status = this.status[reportType];
				delete status.state;
				delete status.message;
				delete status.requestId;
				delete status.promise;
				status.bar.setProgress(0);
			}
			
			this.openReport = (reportType) => {
				if(!utils.checkNested(this.status, reportType, "requestId")){return;}
				console.log("REPORT STUFF", reportType, this.status, this.status[reportType]);
				reportService.generateAndOpenReport(this.status[reportType].reportData);
				//navigato(reportType, this.legacyState.projectId, this.status[reportType].requestId);
			}
			
			this.cancel = function(reportType){
				console.log('cancel', this.status);
				if(!utils.checkNested(this.status, reportType, "promise")){return;}
				fbdbRequests.cancel(this.status[reportType].promise);
			}
			
			this.getReport = function(reportType){
				// Update projectPresence
				presence.pokeProjectPresence( this.projectId );

				var start = this.startDate?this.startDate:null;
				var end = this.endDate?this.endDate:null;
				
				if(!validateDate(start) || !validateDate(end)){ return; }
				if(start > end){ Logging.warning('start date is later than end date'); return; }
				
				if(this.weekNum && !end){
					if(!start){ start = moment().format('YYYY-MM-DD'); }
					end = moment(start, 'YYYY-MM-DD').add(this.weekNum * 7, 'days').format('YYYY-MM-DD');
				}
				
				var status = this.status[reportType];
				status.state = "progress";
				status.bar.setProgress(0);
				status.message = "";

				var self = this;
				var extra = self.genExtraLimits();

				if(this.selectedType === 'constraintLog' ||
						this.selectedType === 'weeklyWorkPlan' ||
						this.selectedType === 'sixWeekLookAhead' ||
						this.selectedType === 'ganttView'
					){ extra.includeCompleted = !!this.includeCompleted; }
				var projectId = this.legacyState.projectId;

				if(reportType == 'projectReportBundle'){
					var reports = this.reportBundles.buildReportsList(start, extra);
					if(!reports){
						this.clearStatus(reportType); 
						Logging.warning('need to select an option');
						return;
					}
					status.promise = fbdbRequests.projectReportBundle(reports);
					//console.log('woo'); return;
					//status.promise = fbdbRequests.requestReport(projectId, reportType, start, end, extra);
				}
				else{
					if(this.iterateRoleIds){
						console.log('roles', this.roles);
						extra.projectId = projectId;
						extra.reportName = reportType;
						extra.asOfDate = start;
						extra.upToDate = end;
						var reports = fbdbRequests.unrollBundleByKey(extra, "roleIds");
						reports.forEach(function(report){
							if(report.roleIds && fbdbRequests.defaultReportNames[report.reportName]){
								var role = self.roles.$getRecord(report.roleIds[0]);
								if(role && role.data.roleName){
									report.reportTitle = role.data.roleName + " " + fbdbRequests.defaultReportNames[report.reportName];
								}
							}
						});
						status.promise = fbdbRequests.projectReportBundle(reports);
					}
					else{
						status.promise = fbdbRequests.requestReport(projectId, reportType, start, end, extra);
					}
				}
				status.promise.then(function(data) {
					analyticsStuff(reportType, data.requestId, start, end, extra.roleIds, extra.planIds);
					// Dispatch weekly report. How many weeks users want to see.
					status.requestId = data.requestId;
				}).then(function(){
					// Now retrieve the report HTML MOC-3086
					return reportService.getReport(status.requestId, self.projectId);
				}).then(function(reportData){
					// MOC-3086
					status.state = "complete";
					status.reportData = reportData;
					return true
				}).catch(function(error){
					if (error && error.message) {
						$timeout(function(){
							status.state = "error";
							status.message = error.message;
							console.log("Error")
						});
						// if(error.recovered){
							// Logging.warning(error.message);
						// }
						// else{
							// Logging.error(error.message);
						// }
					}
				});
				
				var count = 0; //debug
				fbdbRequests.getProgress(status.promise, function(data){
					//$timeout(function(){
						//console.log('data', data.statusStep, data.statusMax, data.statusMessage);
						status.message = data.statusMessage;
						status.bar.setProgress(data.statusStep);
						status.bar.setMax(data.statusMax);
						//self.statusMessage = data.statusMessage;
						//console.log(status.message, count);
					//});
					
					// if(data.statusMessage.indexOf("Done") !== -1){
						// $timeout(function(){
							// self.statusMessage = null;
						// }, 1250);
					// }
				});
			}
			
		}],
		compile: function(element){
			element.addClass('reports-panel');
			return function(scope:any, element, attrs){
				scope.close = function(){
					var closeFn = $parse(attrs.onClose);
					closeFn(scope);
				};
				
			};
		},
		template: require("tpl/reports-panel.html")
    };
}]);
