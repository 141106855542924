/*
 * TicketEditBaseComponent is the top level component for the various
 * child components (calendar picker, crew input, etc) placed inside
 * a container component (task, constrain, or milestone edit components).
 * This is an abstract class (no template), common things like the
 * "error state" go here and the child components inherit from this
 * class to get the common behaviors.
 * 
 *  API convention:
 * 
 * Components extending this class are meant to be embedded in a parent
 * component that has a parent form.  It's expected that the parent passes
 * it's FormGroup reference in as an Input to this component.  Further,
 * the parent must also configure the form control as part of creating the
 * form group, specicifically referring to individual components with the
 * name that's used in the specific validation and supplying a required
 * validator.  The parent can optionally supply an initial value too.
 * 
 */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'ticket-edit-base',
	template: '',
})
export class TicketEditBaseComponent {

	@Input() parentForm: FormGroup;

	showErrorDetail:boolean = false;

	// Toggles the isMenuShown property
	toggleShowErrorDetail() {
		this.showErrorDetail = !this.showErrorDetail; 
	} 

	// computed property to turn on the "error state" thing in the spec
	// override to add specific validation.
	get invalid() {
		return true;
	}
	
}
