'use strict';
import * as angular from "angular";
import * as utils from "utils";
import * as firebase from "firebase/app";

export class DefaultProjectSetup{
	static $inject = ["$q", "$timeout", "fbConnection", "fbdbRequests", "fbReporting", "firebaseProjectMembers"];
	
	
	constructor(private $q, private $timeout, private fbConnection, private fbdbRequests, private fbReporting, private firebaseProjectMembers){
		
	}
	
	//generates a projectMember object
	//was duplicated in too many places
	generateProjectMember(userId, accessLevel, roleId){
		return { userId: userId, roleId: roleId, accessLevel: accessLevel };
	}

	addToSharedProject(userId){
		var pm = this.generateProjectMember(userId, 'General', 'sampleUser');
		return this.addToProject('SampleProjectV3', userId, pm);
	}
	addToProject(projectId, userId, pm){
		return this.firebaseProjectMembers.addAtomic(projectId, pm).then(function(){
			console.log('added to shared project');
		})
		.catch((err)=>{
			console.warn('error adding to project ', projectId, err);
			this.fbReporting.reportWarning('add to project failed', {
				userId: userId,
				err: err,
				projectId: projectId
			});
			//return $q.reject(err);
		});
	}
	
	copySampleProject(userId){
		return this.fbdbRequests.defaultProjectCopy(userId).then((data)=>{
			var projectId = data.response.destProjectId;
			return this.populateProject(projectId, userId);
		})
		.catch((e)=>{
			if(e.message === 'request timed out'){
				Logging.warning("cannot connect to backend, will copy sample project later");
				return this.cancelSampleProjectCopy(userId);
			}
			else{
				return this.$q.reject(e);
			}
		});
	}
	
	cancelSampleProjectCopy(userId){
		var ref = this.fbConnection.fbRef('users/'+userId).child("hasNotCopiedDefault");
		return this.$q.when(ref.set(firebase.database.ServerValue.TIMESTAMP));
	}
	getColorKey(colorSnap, color){
		var match = null;
		colorSnap.forEach((record)=>{
			if (record.child('color').val() === color){match = record.key;}
		});
		return match
	}
	populateProject(projectId, userId){
		var roleRef = this.fbConnection.fbRef("roles/"+projectId).push();
		var pm = this.generateProjectMember(userId, 'Admin', roleRef.key);
		
		return this.firebaseProjectMembers.addAtomic(projectId, pm).then(()=>{
			console.log('finished adding projectMember', projectId);
			return this.fbConnection.fbRef('projects/' + projectId + '/colors').once('value');
		}).then((projectColorSnap)=>{
			var defaultRoleColor = null;
			if (projectColorSnap.exists() && projectColorSnap.hasChild('roles')){defaultRoleColor = this.getColorKey(projectColorSnap.child('roles'), "#ddedc8");}
			var roleObj = {
				roleId: roleRef.key,
				roleName: 'default',
				roleColor: defaultRoleColor
			};
			var promises = [];
			promises.push(this.$q.when(roleRef.set(roleObj)));
			promises.push(this.fbConnection.remove(this.fbConnection.fbRef('users/'+userId+'/hasNotCopiedDefault')));
			promises.push(this.fbConnection.set(this.fbConnection.fbRef('projectLimitations/'+projectId+'/projectMemberMax'),1));
			return this.$q.all(promises).then(()=>{ console.log('all done'); return this.$timeout(100); })
		});
	}
}

angular.module('ticketspaceApp').service("defaultProjectSetup", DefaultProjectSetup);
