angular.module("ticketspaceApp").directive("planActionsMenu", PlanActionsDirective);
angular.module("ticketspaceApp").directive("supportMenu", SupportMenuDirective);
angular.module("ticketspaceApp").directive("planMenu", PlanMenuDirective);


function PlanActionsDirective(){
	return {
		template: require('tpl/plan-actions-menu.html'),
	}
}

function SupportMenuDirective(){
	return {
		template: require('tpl/support-menu.html'),
	}
}

function PlanMenuDirective(){
	return {
		template: require('tpl/plan-menu.html'),
	}
}
