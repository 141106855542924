import Point from "js/lib/math/Point";

export default function Rect(x, y?, w?, h?){/*
	Rect (2d)
	see: https://github.com/paperjs/paper.js/blob/master/src/basic/Matrix.js  
	
	new Rect(x, y, w, h || {x,y}, {w,h} || {from{x,y}, to{x,y}})
	.tolerance = Number 
	.set(x, y, w, h, silent)
	.clone -> new Rect(this)
	.equals(rect) -> Boolean()
	.toString() -> '[x,y,w,h]'
	.serialize() -> [x,y,w,h]
	.point = Point()
	.size = Point()
	.left = Number // not observed
	.top = Number // not observed
	.right = Number
	.bottom = Number
	.centerX = Number
	.centerY = Number
	.center = Point
	.tl -> Point
	.tr -> Point
	.bl -> Point
	.br -> Point
	.lc -> Point
	.rc -> Point
	.tc -> Point
	.bc -> Point
	.area -> Number
	.empty -> Boolean
	.containsPoint(Point) -> Boolean
	.containsRect(Rect) -> Boolean
	.contains(Rect||Point) -> Boolean
	.intersects(Rect) -> Boolean
	.touches(Rect) -> Boolean
	.intersect(Rect) -> new Rect()
	.unite(Rect) -> new Rect()
	.include(Point) -> new Rect()
	.expand(Point(size)) -> new Rect()
	.scale(x, y) -> new Rect()

*/ this.initialize.apply(this, arguments)}

Rect.prototype = {
	constructor: Rect,
	initialize: function (x, y, w, h){
		var t = typeof x;
		if(t == 'number'){ this.x = x; this.y = y, this.w = w; this.h = h; }
		else if (t == 'undefined' || x === null){ this.x = this.y = this.w = this.h = 0 }
		else if (arguments.length == 1) {
			if(Array.isArray(x)){ this.x = x[0]; this.y = x[1]; this.w = x[2]; this.h = x[3] }
			else if (x.x !== undefined || x.w !== undefined){ 
				this.x = x.x || 0; this.y = x.y ||0; this.w = x.w || 0; this.h = x.h || 0; } 
			else if ((x.from && x.from.x) && (x.to && x.to.x)){
				this.x = Math.min(x.from.x, x.to.x); 
				this.y = Math.min(x.from.y, x.to.y);
				this.w = Math.abs(x.from.x - x.to.x);
				this.h = Math.abs(x.from.y - x.to.y);
			}
		}
		this.observers = [];
		this.tolerance = 0.1;
	},
	set: function(x, y, w, h, silent){
		this.x = x; this.y = y, this.w = w, this.h = h;
		if(!silent) this.update()
		return this;
	},
	update: function(){
		this.observers.forEach(function(fn){ fn() });
	},
	clone: function(){ return new Rect(this.x, this.y, this.w, this.h) },
	equals: function(rect){ return rect.x - this.x < this.tolerance 
															&& rect.y - this.y < this.tolerance
															&& rect.w - this.w < this.tolerance 
															&& rect.h - this.h < this.tolerance },
	toString: function(){ return '[' + [this.x, this.y, this.w, this.h].join(', ') + ']' },
	serialize: function(){ return [this.x, this.y,this.w, this.h]; },
	get point(){ return new Point(this.x, this.y) },
	set point(point) { this.set(point.x, point.y, this.w, this.h) },
	get size(){ return new Point(this.w, this.h) },
	set size(size){ 
		if(this.fixX) this.x += (this.w - size.x) * this.fixX;
		if(this.fixY) this.y += (this.h - size.y) * this.fixY;
		this.w = size.x;  this.h = size.y;
		this.fixW = true; this.fixH = true;
	},
	get left(){ return this.x },
	set left(x){ if(!this.fixW){ this.w -= x - this.x }
							 this.x = x; this.fixX = false },
	get top(){ return this.y },
	set top(y){ if(!this.fixH){ this.h -= y - this.y }
									this.y = y; this.fixY = false },
	get right(){ return this.x + this.w },
	set right(x){
		if (this.fixX && this.fixX != true){ this.fixW = false }
		if (this.fixW){ x - this.width }
		else { this.width = x - this.x }
		this.fixX = true
	},
	get bottom(){ return this.y + this.h },
	set bottom(y){
		if (this.fixY && this.fixY != true){ this.fixH = false }
		if (this.fixH){ this.y = y - this.h }
		else { this.h = y - this.y }
		this.fixY = 1;
	},
	get centerX(){ return this.x + 0.5*this.w },
	set centerX(x){ this.x = x - 0.5*this.w; this.fixX = 0.5; },
	get centerY(){ return this.y + 0.5*this.h },
	set centerY(y){ this.y = y - 0.5*this.h; this.fixY = 0.5; },
	get center(){ return new Point(this.x + 0.5*this.w, this.y + 0.5*this.h) },
	set center(point) { this.centerX = point.x; this.centerY = point.y },
	get tl(){ return new Point(this.x, this.y) },
	get tr(){ return new Point(this.x + this.w, this.y) },
	get bl(){ return new Point(this.x, this.y + this.h) },
	get br(){ return new Point(this.x + this.w, this.y + this.h) },
	get lc(){ return new Point(this.x, this.y + (this.h/2)) },
	get rc(){ return new Point(this.x + this.w, this.y + (this.h/2)) },
	get tc(){ return new Point(this.x + (this.w/2), this.y) },
	get bc(){ return new Point(this.x + (this.w/2), this.y + this.w) },
	get area(){ return this.w * this.h },
	get empty(){ return this.w == 0 || this.h == 0 },
	containsPoint: function(point){ return point.x >= this.x          && point.y >= this.y
																			&& point.x <= this.x + this.w && point.y <= this.y + this.h },
	containsRect: function(rect){ return rect.x >= this.x                   && rect.y >= this.y
																		&& rect.x + rect.w <= this.x + this.w && rect.y + rect.h <= this.y + this.h },
	contains: function(arg){ return arg.w ? this.containsRect(arg) : this.containsPoint(arg) },
	intersects: function(rect){ return rect.x + rect.w > this.x && rect.y + rect.h > this.y
																	&& rect.x < this.x + this.w && rect.y < this.y + this.h },
	touches: function(rect){ return rect.x + rect.w >= this.x && rect.y + rect.h >= this.y
																	&& rect.x <= this.x + this.w && rect.y <= this.y + this.h },
	intersect: function(rect){ 
		var x1 = Math.max(this.x, rect.x), y1 = Math.max(this.y, rect.y);
		var x2 = Math.min(this.x + this.w, rect.x + rect.w), y2 = Math.min(this.y + this.h, rect.y + rect.h);
		return new Rect(x1, y1, x2-x1, y2-y1) 
	},
	unite: function(rect){
		var x1 = Math.min(this.x, rect.x), y1 = Math.min(this.y, rect.y);
		var x2 = Math.max(this.x + this.w, rect.x + rect.w), y2 = Math.max(this.y + this.h, rect.y + rect.h);
		return new Rect(x1, y1, x2-x1, y2-y1)
	},
	include: function(point){
		var x1 = Math.min(this.x, point.x), y1 = Math.min(this.y, point.y);
		var x2 = Math.max(this.x + this.width, point.x), y2 = Math.max(this.y + this.height, point.y);
		return new Rect(x1, y1, x2 - x1, y2 - y1);
	},
	expand: function(size){ return new Rect(this.x - size.x / 2, this.y - size.y / 2,
																					this.w + size.y,     this.h + size.y) },
	scale: function(x, y){ return this.expand(this.w*x - this.w, this.h * (y === undefined ? x:y) - this.h) }
	
};
var anyRect:any = Rect;

anyRect.fromPoints = function(p1, p2){
  return new Rect({
    from: new Point(Math.min(p1.x, p2.x), Math.min(p1.y, p2.y)),
    to:   new Point(Math.max(p1.x, p2.x), Math.max(p1.y, p2.y))
  })
};
