'use strict';

import * as utils from "utils";

export function changeClass(){
	$('*').each(function(i,e){$(e).css({ "color": utils.randomColor()})});
};

export function teamUp(){
	$('.zoomContent-underlay-layer2').toggleClass('team-background');
}
