import { Component, Input } from '@angular/core';

import { TicketEditBaseComponent } from 'ng2/ticket-edit/components/ticket-edit-base.component';

@Component({
	selector: 'ticket-crew',
	template: require('./ticket-crew.component.html'),
})
export class TicketCrewComponent extends TicketEditBaseComponent {
	get invalid() {
		// We're expecting a postive value.  Right now we have a placeholder
		// that it's required (and you have to have touched the field first).
		return (
			this.parentForm.get('crew').hasError('required') &&
			this.parentForm.get('crew').touched
		);
	}
}
