
// ---------------------------------------------
// HEY, USING HUD IN THIS WAY IS PROBABLY WRONG
// the sane thing would probably be to update the world in pixi, then use that to update the HUD
// instead of updating the hud from pixi events, then listening to the hud to update the world
// ---------------------------------------------
// 
// TODO - will need to resolve the "jump at end of pinch". Used to be solved at the plan-gesture-manager level.
// Can probably do better.

import { PlanState } from "../../../common/services/plan-state.service";
import { Component } from "../../component-system/";
import { DisplayObject } from "pixi.js";
import Point from "js/lib/math/Point";
import {EventBinder} from "../interaction-utils";
import {ComponentInterface} from "../../interfaces/";

let startCenter:any;

function actualCenter(event){
	if(event.pointers.length === 2){
		return {
			x: (event.pointers[1].clientX + event.pointers[0].clientX)/2,
			y: (event.pointers[1].clientY + event.pointers[0].clientY)/2,
		}
	}
	return false;
}
function actualDelta(event){
	let center:any = actualCenter(event);
	return {
		deltaX: center.x - startCenter.x,
		deltaY: center.y - startCenter.y
	}
}

export class PlanPinch extends Component implements ComponentInterface{
	private _binder: EventBinder;
	/**
	 * The setup method is called once all properties on a component have been decorated by the ComponentManager.
	 *
	 * From this point on, you can safely use this.getParent() and this.getManager().
	 *
	 * Arguments passed into setup are passed in via the ComponentManager.add(name, component, ...args)
	 * @param {PlanState} planState
	 * @param {DataContainer} dataContainer
	 */
	public setup(planState: PlanState, parent: DisplayObject): void{
		console.log('pinchy');
		this._binder = new EventBinder(parent);
		let hud = planState.scopeSoup.hud;

		this._binder.add('hammer-pinchstart', (event)=>{
			startCenter = actualCenter(event);
			console.log('hammer-pinchstart');
			hud.onStartTransform();
		});
		
		this._binder.add('hammer-pinch', (event)=>{
			console.log('hammer-pinch');
			let center = actualCenter(event);
			if(!center){ console.log('not 2 pointers'); return;}
			let delta = actualDelta(event);
			
			let p = new Point(delta.deltaX, delta.deltaY);
			let z = new Point(event.scale);
			let c = new Point(event.center.x, event.center.y);

			hud.onTransform(p, z, c)
		});
		
		this._binder.add('hammer-pinchend', (event)=>{
			console.log('hammer-pinchend');
			hud.onEndTransform();
		});
	}
	/**
	 * kill stuff
	 */
	public destroy(){
		this._binder.destroy();
	}
}