import * as angular from "angular";
import * as PIXI from "pixi.js";
angular.module('CanvasWrapper').factory("TicketStatus", ["GeometricShape", "projectViewConfig", function(GeometricShape, projectViewConfig) {
	var TicketStatus = function(stage, displayNode, backgroundNode){
		if (!displayNode){ throw "displayNode must be passed as first argument.";}
		var style = projectViewConfig.TICKET_STATUS;

		var shape = new GeometricShape();
		shape.x = 0;
		shape.y = 0;
		displayNode.addChild(shape.get());

		// Ticket status bars are drawn relative to the left edge of the plan bar.
		this.draw = function(data, scale){
			shape.clear();
			data = data || {};
			scale = scale || 1;
			// Have the main project view convert the dates into draw coordinates
			var earliestLastPlannedStartDiff = data.earliestLastPlannedStart;
			var latestLastPlannedFinishX = data.latestLastPlannedFinish;
			var earliestPlannedStartDif = data.earliestPlannedStart;
			var latestPlannedFinishX = data.latestPlannedFinish;
			var widthIncomplete = latestPlannedFinishX - earliestPlannedStartDif;
			var incompletePoint = new PIXI.Point(earliestPlannedStartDif, 0);
			var completePoint = new PIXI.Point(earliestLastPlannedStartDiff, 0);
			var width = latestLastPlannedFinishX - earliestLastPlannedStartDiff;

			// Clamp the width of the complete bar to prevent overdrawing where incomplete tickets are surrounded by complete tickets.
			if ((completePoint.x + width) > (incompletePoint.x + widthIncomplete) && width > 0 && widthIncomplete > 0){width = widthIncomplete;}
			// Draw the complete tickets rectangle
			if (width > 0){
				shape.get().beginFill(parseInt(style.FILL_COLOR_COMPLETE.substring(1), 16), style.FILL_ALPHA_COMPLETE);
				shape.get().drawRect(completePoint.x, completePoint.y + style.COMPLETE_BAR_OFFSET, width, style.COMPLETE_BAR_HEIGHT);
				shape.get().endFill();
			}

			// Draw incomplete tickets
			if (widthIncomplete > 0){
				shape.get().beginFill(parseInt(style.FILL_COLOR_INCOMPLETE.substring(1), 16), style.FILL_ALPHA_INCOMPLETE);
				shape.get().drawRect(incompletePoint.x, incompletePoint.y + style.INCOMPLETE_BAR_OFFSET, widthIncomplete, style.INCOMPLETE_BAR_HEIGHT);
				shape.get().endFill();
			}

			shape.completeX = completePoint.x;
			shape.incompleteX = incompletePoint.x;
			shape.completeWidth = width;
			shape.incompleteWidth = widthIncomplete;
			shape.y = displayNode.y;

			//shape.get().width = width + widthIncomplete;
		};

		this.setVisible = function(value){
			shape.setVisible(value);
		};

		this.get = function(){
			return shape.get()
		};

		this.getDrawingCoordinates = function(){
			return {
				completeX: shape.completeX,
				incompleteX: shape.incompleteX,
				completeWidth: shape.completeWidth,
				incompleteWidth: shape.incompleteWidth,
				y: shape.y,
				totalWidth: shape.completeWidth + shape.incompleteWidth,
				totalHeight: style.INCOMPLETE_BAR_HEIGHT,
				parentX: displayNode.x,
				parentY: displayNode.y,
				parentWidth: displayNode.width,
				parentHeight: displayNode.height,
				backgroundWidth: backgroundNode.width,
				backgroundHeight: backgroundNode.height
			}
		}

	};

	return TicketStatus;
}]);
