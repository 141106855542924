import { Rectangle } from "pixi.js";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

import * as utils from "utils";
import Point from "js/lib/math/Point";
import {Ticket} from "ng2/common/models";

import { ComponentInterface } from "../../interfaces/";
import { PlanState } from "../../../common/services/plan-state.service";
import { TicketContainer } from "../../data-mappers/";
import { Component } from "../../component-system/";
import {EventBinder} from "../interaction-utils";

import { DragAtEdge } from "ng2/common/models/DragAtEdge"

export class TicketDrag extends Component implements ComponentInterface{
	private _binder: EventBinder;
	private ticket: Ticket;
	
	constructor(){super();}
	/**
	 * The setup method is called once all properties on a component have been decorated by the ComponentManager.
	 *
	 * From this point on, you can safely use this.getParent() and this.getManager().
	 *
	 * Arguments passed into setup are passed in via the ComponentManager.add(name, component, ...args)
	 * @param {PlanState} planState
	 * @param {TicketContainer} dataContainer
	 */
	public setup(planState: PlanState, dataContainer: TicketContainer){
		
		this._binder = new EventBinder(this.getParent(), ['hammer-panstart', 'hammer-holddragstart'], ['hammer-panend', 'hammer-holddragend']);
		let camera = dataContainer.camera;
		let ticket = dataContainer.getData();
		this.ticket = ticket;
		this.planState = planState;
		
		let started = false;
		let dragStartAbortPoint = "uncalled";
		let hasEverCalledDragStart = false;
		
		let dragService = planState.ticketDragService;
		
		let didSelectATicket = false;

		let deadZoneSubscription: Subscription;
		
		let whichStartFired = 'none';
		
		let checkGlobal = true;
		let globalData = 'undefined';

		//heuristically determine if the start event would have been for this
		function checkLastGlobalStart(){
			if(!checkGlobal){ return false; }
			if(window.lastStartEvent){
				if(window.lastStartEvent.timestamp !== undefined && (Date.now() - window.lastStartEvent.timestamp) < 20){
					globalData = window.lastStartEvent.type;
				}
				checkGlobal = false;
			}
			return false;
		}
		
		const dragStart = (event) => {
			
			whichStartFired = event.type;
			hasEverCalledDragStart = true;
			dragStartAbortPoint = "very beginning";
			// console.log('new drag start', event);
			// console.profile("drag");
			// planState.actions.changeTicketViewToDefault(ticket.$id);
			dataContainer.cutInLine();
			dragStartAbortPoint = "cut";
			//access checking
			if(planState.accessCheck.isReadOnly()){
				planState.scopeSoup.readOnlyReaction();
				dragStartAbortPoint = "read only check";
				return true; 
			}
			var notAdminNotRole = !planState.accessCheck.hasAccessToTicket2(ticket); //check interacted ticket
			planState.tickets.selectedTicket$.getValue().list.forEach(t => {
				notAdminNotRole = notAdminNotRole || !planState.accessCheck.hasAccessToTicket2(t); //check selected tickets
			})
			if(notAdminNotRole){ dragStartAbortPoint = "not admin not role"; return true; }

			let startz = camera.getScale().x;
			
			let startCX = camera.view.x/startz;
			let startCY = camera.view.y/startz;
			
			planState.tickets.selectedTicket$.pipe(first()).subscribe((selection)=>{
				if(!selection.list.has(ticket.$id)){planState.actions.unSelectTicket(selection.list);}
				if(selection.list.size === 0){ planState.actions.selectTicket(ticket); didSelectATicket = true; }
			})
			
			dragStartAbortPoint = "after selection getting";
			dragService.dragStart(event.center, planState.tickets.selectedTicket$);
			dragStartAbortPoint = "after dragService.dragStart";
			
			//convert new selection to old selection
			planState.tickets.selectedTicket$.pipe(first()).subscribe((selection)=>{
				var oldTickets = planState.scopeSoup.tickets;
				if(oldTickets.selection.count){ oldTickets.selection.empty();	}
				selection.list.forEach((t)=>{
					var odl = oldTickets.$getRecord(t.$id)
					if(odl){ oldTickets.selection.add(odl); }
				});
			})
			dragStartAbortPoint = "before legacy dragStart";
			window.legacyTicketHandling.dragStart(event, null, "tickets");
			dragStartAbortPoint = "after legacy dragStart";
			deadZoneSubscription = window.legacyTicketHandling.doorDeadZone.subscribe((rect:Rectangle)=>{
				dragService.updateDragAtEdgeDeadZones(rect ? [rect] : []);
			})
			dragStartAbortPoint = "the end";
			
			started = true;
		};
		
		const drag = (event)=>{
			// if(!started && window.reporting){
			// 	window.reporting.reportRealWarning(
			// 		"canvas ticket drag detected without a successful dragStart", 
			// 		Object.assign(utils.makeStack(new Error("canvas ticket drag detected without a successful dragStart")), {
			// 			hasDestroyed: !this.planState,
			// 			ticketId: this.ticket ? this.ticket.$id : "undefined",
			// 			binderDetecting: this._binder && this._binder.detecting,
			// 			planStateState: this.planState.stateSync
			// 		})
			// 	)
			// 	return;
			// }
			if(!started){ 
				checkLastGlobalStart();
				//lets just return and see what happens to all the other errors
				return;
			}
			window.legacyTicketHandling.drag(event, null);
			return dragService.drag(event.center);
		}
		
		const dragEnd = (event)=>{
			if(!started && window.reporting){
				window.reporting.reportRealWarning(
					"canvas ticket dragEnd detected without a successful dragStart", 
					Object.assign(utils.makeStack(new Error("canvas ticket dragEnd detected without a successful dragStart")), {
						dragEventType: whichStartFired,
						lastGlobalEventType: globalData,
						lastGlobalEventRaw: {
							type: (window.lastStartEvent && window.lastStartEvent.type) ? window.lastStartEvent.type : 'undefined',
							timestamp: (window.lastStartEvent && window.lastStartEvent.timestamp) ? window.lastStartEvent.timestamp : 'undefined',
							raw: (window.lastStartEvent && window.lastStartEvent.raw) ? window.lastStartEvent.raw : 'undefined',
						},
						hasEverCalledDragStart: hasEverCalledDragStart,
						dragStartAbortPoint: dragStartAbortPoint,
						hasDestroyed: !this.planState,
						ticketId: this.ticket ? this.ticket.$id : "undefined",
						binderDetecting: this._binder && this._binder.detecting,
						planStateState: this.planState.stateSync,
						isReadOnly: planState.accessCheck && planState.accessCheck.isReadOnly ? planState.accessCheck.isReadOnly() : "undefined",
						isAdmin: planState.accessCheck && planState.accessCheck.isAdmin ? planState.accessCheck.isAdmin() : "undefined"
					})
				)
				
				//clean up, just in case
				started = false;
				dragStartAbortPoint = "uncalled";
				whichStartFired = 'none';
				checkGlobal = true;
				globalData = 'undefined';
				
				return;
			}
			
			//legacy handling is now expected to call the appropriate dragSevice clean up
			window.legacyTicketHandling.dragEnd(event)
			// if(!isHandled){ dragService.dragEnd(event.center); }
			.then(()=>{
				if(didSelectATicket){
					planState.actions.unSelectTicket(ticket);
				}
				
				didSelectATicket = false;
				deadZoneSubscription.unsubscribe();
			})
			
			started = false;
			dragStartAbortPoint = "uncalled";
			whichStartFired = 'none';
			checkGlobal = true;
			globalData = 'undefined';
		}
		
		this._binder.add('hammer-panstart', dragStart);
		this._binder.add('hammer-pan', drag);
		this._binder.add('hammer-panend', dragEnd);
		this._binder.add('hammer-holddragstart', dragStart);
		this._binder.add('hammer-holddrag', drag);
		this._binder.add('hammer-holddragend', dragEnd);
	}
	/**
	 * Clean shit up here.
	 */
	public destroy(){
		if(this._binder.detecting){
			this.planState.ticketDragService.dragCancel();
		}
		this._binder.destroy();
		
		this.ticket = null;
		this.planState = null;
	}
}
