import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input } from '@angular/core';
import{ ChangeDetectorRef } from "@angular/core"

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { sessionStorage } from "ng2/common/services/SessionStorage";

@Component({
  selector: 'hide-completed-toggle',
  template: require('./hide-completed-toggle.component.html'),
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HideCompletedToggleComponent implements OnInit, OnDestroy {
	public hideCompletedTickets = true; //default to true because the component shouldn't actually be shown when it's false
	private shutdownSubject = new Subject();
	@Input() ticketCount: number = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }


  ngOnInit() {
		sessionStorage.changed$.pipe(takeUntil(this.shutdownSubject)).subscribe(settings => {
			this.hideCompletedTickets = !!settings.hideCompletedTickets;
			this.changeDetectorRef.detectChanges() 
		});
  }
	ngOnDestroy(){
		this.shutdownSubject.next(true);
	}

	showCompleted(){
		sessionStorage.hideCompletedTickets = false;
	}
}
