import { Texture, Rectangle } from "pixi.js";
import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { SpriteTicket } from "./";
import { PlanState } from "../../common/services/plan-state.service";
import { TicketTypes } from "../../common/models/Ticket";
import { ticketSchema } from "../ticket-components/";
import { Ticket } from "../../common/models/Ticket";

/**
 * This class is the main representation of a ticket. It has a simple component api to add individual pieces associated with the same data
 */
export class SpriteTask extends SpriteTicket implements ComponentInterface{
	public type: string = TicketTypes.TASK;
	/**
	 * @param {PIXI.Texture} ticketShape
	 * @param {PlanState} planState
	 * @param {TicketContainer} ticketContainer
	 */
	constructor(ticketShape: Texture, planState: PlanState, ticketContainer: TicketContainer){
		super(ticketShape, planState, ticketContainer);
		
		const data = ticketContainer.getData();
		
		if (data.isGhost){
			ticketContainer.components.addDynamicComponents(
				ticketSchema.createGhostTaskSchema(planState, ticketContainer)
			);
		} else{
			ticketContainer.components.addDynamicComponents(
				ticketSchema.createTaskSchema(planState, ticketContainer)
			);
		}
	}
	
	public updateData(planState: PlanState, ticketContainer: TicketContainer){
		super.updateData(planState, ticketContainer);
		let data = ticketContainer.getData();
		
		this.width = data.view.width;
		this.height = data.view.height;
		
		(<any>ticketContainer.SURFACE).hitArea = new Rectangle(0, 0, data.view.width, data.view.height);
	}
}