import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { from, of } from "rxjs";
import { delay, concatMap } from "rxjs/operators";

import { FormControl } from "@angular/forms";

import { LoadingBlockerState } from "ng2/common/components/";

@Component({
	selector: 'harness',
	template: require('./harness.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HarnessComponent implements OnInit {
	public mediumCheckbox = new FormControl(false);
	public largeCheckbox = new FormControl(true);
	public smallCheckbox = new FormControl(false);
	
	public toggleBlockTest = false;
	
	public loadingState = LoadingBlockerState.empty;
	public startLoadingObs = false;
	public loadingObservable = from([LoadingBlockerState.inProgress, LoadingBlockerState.complete, LoadingBlockerState.empty, LoadingBlockerState.inProgress, LoadingBlockerState.failure]).pipe(
		concatMap(x => of(x).pipe(delay(300)))
	);

	constructor() { }

	ngOnInit() {
		// this.mediumCheckbox.valueChanges.subscribe(thing => {
		// 	console.log('thing', thing);
		// })
	}
	
	//checkbox testing
	setCheckbox(checkboxControl: FormControl){
		checkboxControl.setValue(!checkboxControl.value);
	}
	
	
	//loding blocker testing
	
	setLoadingState(state: LoadingBlockerState){
		this.loadingState = state;
	}
	
	setLoadingEmpty(){ this.setLoadingState(LoadingBlockerState.empty) }
	setLoadingComplete(){ this.setLoadingState(LoadingBlockerState.complete) }
	setLoadingFailure(){ this.setLoadingState(LoadingBlockerState.failure) }
	setLoadingInProgress(){ this.setLoadingState(LoadingBlockerState.inProgress) }
	
	logHarnessOutput(state){
		Logging.notice("blocker state: "+state);
	}

}
