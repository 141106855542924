'use strict';

import * as moment from "moment";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import AnimationQueue from "js/lib/ui/AnimationQueue";

/************************************************
*	Contains all the code related to interacting
*	with the plan.
*************************************************/
export default function promisePeriodHandling(scope){
	this.scope = scope;
	
	var startX;
	var endX;
	var cols;
	var lastMatched;
	var columnWidth;
	var activeLine;
	
	//does all the generic setup crap basically all the "start" functions will need to do
	function setup(){
		if(!scope.accessCheck.isAdmin()){ return false; }
		if(scope.accessCheck.isReadOnly()) { return false; }
		if(!scope.pp.promisePeriodNext){ return false;}
		if(!scope.activeColumnService.columns().length){ return false; }
		
		startX = scope.pp.promisePeriodNext.startX;
		endX = scope.pp.promisePeriodNext.endX;
		//startXFollower = scope.pp.promisePeriodNext.startX;
		//endXFollower = scope.pp.promisePeriodNext.endX;
		cols = scope.activeColumnService.columns();
		columnWidth = scope.activeColumnService.columnWidth();
		activeLine = cols[0].origFinishX;
		
		return true;
	}
	
	this.dragStart = function(event, currentElement){
		if(!setup()){return false;}
	}
	
	this.drag = function(event, currentElement){
		//if(scope.accessCheck.isReadOnly()) { return false; }
		
		var dx = event.deltaX / scope.hud.z;
		
		AnimationQueue(function(){
			var hit = utils.snapping(endX + dx, [cols], 500, [
				function (item) {
					return item.origFinishX;
				},
			]);
			if(hit){
				scope.pp.drawSimple(hit.loc - (endX - startX), hit.loc, "promisePeriod");
				lastMatched = hit;
			}
			// var otherX = hit.loc + Math.abs(endX-startX);
			// if(otherX > scope.activeLine.$value){otherX = scope.activeLine.$value;}
			
			// scope.pp.drawSimple(hit.loc, otherX);
			// lastMatched = hit;
		},"dragPromisePeriod");
		
		
	}
	
	this.dragEnd = function(event, currentElement) //now save -offset
	{
		//if(scope.accessCheck.isReadOnly()) { return false; }
		
		if(lastMatched){
			
			//var change = moment(lastMatched.item.activeDay).subtract(scope.pp.promiseLength, 'days');
			//scope.pp.setCurrentPeriod(change.format('YYYY-MM-DD'), lastMatched.item.activeDay);
			console.log('lastMatched', lastMatched);
			
			// var startDate = moment(lastMatched.item.activeDay).add(1,'days');
			// var changeAmount = startDate.diff(scope.pp.currentPromise.startDate, 'days');
			
			// var endDate = moment(scope.pp.currentPromise.endDate).add(changeAmount,'days').format('YYYY-MM-DD');
			// if(endDate > scope.activeLineDate.$value){endDate = scope.activeLineDate.$value;}
			// scope.pp.setCurrentPeriod(startDate.format('YYYY-MM-DD'), endDate);
		}
		else{
			scope.pp.draw();
		}
	}
	
	var edgeToDrag = "right";
	
	this.scaleStart = function(event, currentElement, edge){
		if(!setup()){return false;}
		edgeToDrag = edge || "right";
	};
	this.scale = function(event, currentElement){
		var dx = event.deltaX / scope.hud.z;
		if(edgeToDrag === "right" && endX+dx < startX +145
				|| edgeToDrag === "left" && startX+dx > endX-145){
			return;
		}
		
		AnimationQueue(function(){
			var x = edgeToDrag === "right" ? endX + dx : startX + dx;
			var hit = utils.snapping(x, [cols], 500, [
				function (item) {
					return item.origFinishX;
				},
			]);
			
			if(edgeToDrag === "right"){
				scope.pp.drawSimple(startX, hit.loc, "promisePeriodNext");
				//scope.pp.drawSimple(hit.loc, Math.min(hit.loc + (hit.loc - startX), activeLine), "promisePeriodNext");
				
				//scope.pp.drawSimple(hit.loc - (hit.loc - startX) *2, hit.loc - (hit.loc - startX), "promisePeriodPrevious");
			}
			else{
				scope.pp.drawSimple(hit.loc, endX, "promisePeriodNext");
				//scope.pp.drawSimple(endX, Math.min(endX + (endX - hit.loc),activeLine), "promisePeriodNext");
				
				//scope.pp.drawSimple(hit.loc - (endX - hit.loc), hit.loc, "promisePeriodPrevious");
				//console.log('eh', hit.loc, hit.loc - (hit.loc + endX));
			}
			lastMatched = hit;
		},"dragPromisePeriod");
	};
	this.scaleEnd = function(event, currentElement){
		if(lastMatched){
			var change = moment(lastMatched.item.activeDay);
			if(change > scope.activeLineDate.$value){change = scope.activeLineDate.$value;}
			if(edgeToDrag === "right"){
				scope.pp.updateCurrentPeriod({startDate: scope.pp.currentPromise.startDate, endDate: change.format('YYYY-MM-DD')});
			}
			else{
				var formattedChange = moment(change).add(1,'days').format('YYYY-MM-DD');
				scope.pp.updateCurrentPeriod({startDate: formattedChange, endDate: scope.pp.currentPromise.endDate});
			}
			analyticsService.promisePeriodDragged(scope.legacyState.planId);
		}
		else{
			scope.pp.draw();
		}
	};
	
}
