/*
 * TicketEditComponent is the top level component for editting tickets.
 * This is an abstract class (no template), specific ticket types (task,
 * constraint, milestone) inherent from this class to implement a 
 * template and any type specific behaviors.
 */

import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as interfaces from 'ng2/ticket-edit/interfaces';

import {forwardRef, Inject} from '@angular/core';

import { MultiEditThumbnail } from 'ng2/ticket-edit/components/multi-edit-thumbnail.component'; 
import { TicketEditService } from 'ng2/ticket-edit/ticket-edit.service';
import { TicketThumbnailComponent } from 'ng2/ticket-edit/components/ticket-thumbnail.component';
import { TicketActionMenuComponent } from 'ng2/ticket-edit/components/ticket-action-menu.component';
import { TicketRoleComponent } from 'ng2/ticket-edit/components/ticket-role.component';
import { TicketDescriptionComponent } from 'ng2/ticket-edit/components/ticket-description.component';

import { Role } from 'ng2/ticket-edit/components/temp-models/Role';
import { Location } from 'ng2/ticket-edit/components/temp-models/Location';
import { TicketType } from './TicketType';

import { DropdownComponent } from 'ng2/dropdown-select/components/dropdown.component';

import {CalendarModule} from 'primeng/primeng';

import { AccessCheck } from "ng2/common/ajs-upgrade-providers";

import { PlanState } from 'ng2/common/services/plan-state.service';

import { EditTicketsCombined } from 'ng2/common/models/EditTicketsCombined';

import * as moment from "moment";


// Temp data for component
interface ProjectMember {
	name: string;
	id: string;
}

@Component({
	selector: 'ticket-edit',
	template: '',
})
export class TicketEditComponent implements OnInit {

	editForm: FormGroup;
	description: String;

	ticketType: TicketType;
	ticketColor: string;
	isComplete: boolean;
	isMultiEdit: boolean;

	etc: EditTicketsCombined;

	ngOnInit() {
		this.createForm();
		console.log("editForm: ", this.editForm);
	}

	roles: Role[];
	selectedRole: Role;
	message: String;

	locations: Location[];
	selectedLocation: Location;

	projectMembers: ProjectMember[];

	testDate: Date;
	testDateArray: Date[];
	liveStart: Date;
	liveFinish: Date;
	liveRange: Date[];
	dateRequested: Date;
	assigneeColor: string;
	pendingAcceptance: boolean;
	crew: number;
	durationDays: number;
	
	constructor( protected ticketEditService: TicketEditService,
				 protected accessCheck: AccessCheck,
				 protected formBuilder: FormBuilder,
				 protected planState: PlanState,){
			
		console.log('TicketEditComponent');

		// get the list of roles from planState.roles.list$
		planState.roles.list$.subscribe(roleMap => {
			// Iterate the map and build a new array of roles
			this.roles = [];			
			for (var role of roleMap) {
				this.roles.push({roleName: role[1].roleName, roleColor: role[1].color, roleId: role[1].roleId});
			}
		} );

		// get the list of locations from planState.locations.list$
		planState.locations.list$.subscribe(locationMap => {
			// Iterate the map and build a new array of locations
			this.locations = [];			
			for (var location of locationMap) {
				this.locations.push({locationName: location[1].data.name, locationColor: location[1].data.color, locationId: location[1].data.id});
			}
		} );

		// get the list of project members from planState.projectMembers.list$ ...
		// ...Nope, apparently this is meshed users or soemthing.
		// planState.projectMembers.list$.subscribe(projectMemberMap => {
		// 	// Iterate the map and build a new array of project member
		// 	this.projectMembers = [];			
		// 	for (var projectMember of projectMemberMap) {
		// 		this.projectMembers.push({name: 'No names yet', id: 'No IDs yet'});
		// 	}
		// } );
		this.projectMembers = [
			{name: "Sally Super", id: "A1"},
			{name: "Tommy Tinknocker", id: "A2"},
			{name: "Archie Architect", id: "A3"},
			{name: "Lois Lowvoltage", id: "A4"}
		];

		// Throw in a placeholder date.
		this.testDate = new Date("March 1, 2018");
		this.testDateArray = [new Date("March 5, 2018"), new Date("March 7, 2018")];

		this.message = "(Optional message like: Read Only)"

		// Subscribe to editTicketsCombined$ to get the ticket(s) data
		// to populate the UI
		this.ticketEditService.editTicketsCombined$.subscribe( (v) => {
			console.log("v in TicketEditComponent: ", v);
			if (v) {
				this.etc = v;
				this.isMultiEdit   = v.isEditAll;
				this.description   = v.typesInCollection.get(v.currentType).activityName;
				this.ticketColor   = v.typesInCollection.get(v.currentType).roleColor;
				this.isComplete    = v.typesInCollection.get(v.currentType).isComplete;
				this.ticketType    = this.getTicketType(v.currentType);
				this.liveStart     = moment(v.typesInCollection.get(v.currentType).liveStart, "YYYY-MM-DD").toDate();
				this.liveFinish    = moment(v.typesInCollection.get(v.currentType).liveFinish, "YYYY-MM-DD").toDate();
				this.liveRange     = [this.liveStart, this.liveFinish];
				this.dateRequested = (v.typesInCollection.get(v.currentType).dateRequested) ? new Date(v.typesInCollection.get(v.currentType).dateRequested) : null;
				this.assigneeColor = v.typesInCollection.get(v.currentType).assigneeColor; //"rgb(0, 0, 0)";;
				this.crew          = v.typesInCollection.get(v.currentType).crew;
				this.durationDays  = v.typesInCollection.get(v.currentType).durationDays;
				this.pendingAcceptance = (v.typesInCollection.get(v.currentType).responsibleProjectMemberId && v.typesInCollection.get(v.currentType).assignedProjectMemberId); //true;
				//TODO: need to set the selectedRole, location, etc.

			}
		});
	}

	getTicketType(type: string) : TicketType {
		switch(type){
			case 'constraint': return TicketType.Constraint;
			case 'milestone': return TicketType.Milestone;
			case 'task': return TicketType.Task;
			default: return TicketType.Task;
		}
	}

	createForm(){
		this.editForm = this.formBuilder.group({
			description: [this.description, Validators.required],
			crew: [this.crew, Validators.required],
			durationDays: [this.durationDays, Validators.required]			
		});
	}
	
	updateForm(){
		//<--- continue here
	}
	
	// TODO: this is just a stub
	closeEdit() {
		// log what's in the textarea
		// console.log("this.tdc.descripton.value: ", this.tdc.descripton.value);
		console.log("this.editForm.description.value: ", this.editForm.value);
		// Get the array of tickets that are being edited and change them from 
		// edit back to default to close the edit.  
		// TODO: no persistence yet.
		this.planState.actions.abortEdit();
	}

	//update form on type changed (maybe put this in the superclass?)
	typeChanged(type: string) {
		this.editForm.patchValue(
			{description: this.etc.typesInCollection.get(type).activityName });
	}	
}
