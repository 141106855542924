import {AngularFireActionMock} from "ng2/../../test/ng2/FancyFirebaseHelpers"
//some sketchy shit here

let fakeDefinition = [
	{
		"projectMemberId": "billBelichicklProjectMemberId",
		"accessLevel": "Admin",
		"roleId": "-KeKjh5SwIwQZiJlfKq2",
		"userId": "billBelichick",
		"role": {
			"roleId": "-KeKjh5SwIwQZiJlfKq2",
			"roleName": "Mechanical",
			"roleColor": "-Kvo9bSpRrXMJx4DYaqp",
			"color": "#80deea"
		},
		"user": {
			"id": "billBelichick",
			"accountId": "-JvyAb-Ra4pqxNbgXHgt",
			"email": "bill@patriots.com",
			"company": "New England Patriots",
			"phone": "",
			"firstName": "Bill",
			"lastName": "Belichick",
			"zip": "",
		}
	},{
		"projectMemberId": "tomBradyProjectMemberId",
		"accessLevel": "General",
		"roleId": "-KeKjh5f-GvVplC-vLHS",
		"userId": "tomBrady",
		"role": {
			"roleId": "-KeKjh5f-GvVplC-vLHS",
			"roleName": "CM",
			"roleColor": "-Kvo9bSpRrXMJx4DYar1",
			"color": "#b0bfc6"
		},
		"user": {
			"id": "tomBrady",
			"accountId": "isThisNeeded",
			"email": "tom@patriots.com",
			"company": "New England Patriots",
			"phone": "",
			"firstName": "Tom",
			"lastName": "Brady",
			"zip": "",
		}
	},{
		"projectMemberId": "robGronkowskiProjectMemberId",
		"accessLevel": "General",
		"roleId": "-KeKjh5f-GvVplC-vLHQ",
		"userId": "robGronkowski",
		"role": {
			"roleId": "-KeKjh5f-GvVplC-vLHQ",
			"roleName": "Concrete",
			"roleColor": "-Kvo9bSpRrXMJx4DYaqo",
			"color": "#c5cae8"
		},
		"user": {
			"id": "robGronkowski",
			"accountId": "isThisNeeded",
			"email": "rob@patriots.com",
			"company": "New England Patriots",
			"phone": "",
			"firstName": "Rob",
			"lastName": "Gronkowski",
			"zip": "",
		}
	}
];

export let fakePmList = fakeDefinition.map(pm => {
	return new AngularFireActionMock("child_added", pm.projectMemberId, pm);
})
