import { ticker } from "pixi.js";

export class Timer {
	protected target: number = 0;
	protected base: number = 0;
	protected last: number = 0;
	protected pausedAt: number = 0;

	constructor(seconds?: number){
		this.base = ticker.shared.lastTime;
		this.last = ticker.shared.lastTime;
		this.target = seconds || 0;
	}

	public set(seconds: number){
		this.target = seconds || 0;
		this.base = ticker.shared.lastTime;
		this.pausedAt = 0;
	}

	public tick(){
		let delta = ticker.shared.lastTime - this.last;
		this.last = ticker.shared.lastTime;
		return (this.pausedAt ? 0 : delta);
	}

	public delta(){
		return (this.pausedAt || ticker.shared.lastTime) - this.base - this.target;
	}

	public pause(){
		if (!this.pausedAt){
			this.pausedAt = ticker.shared.lastTime;
		}
	}

	public unpause(){
		if (this.pausedAt){
			this.base += ticker.shared.lastTime - this.pausedAt;
			this.pausedAt = 0;
		}
	}

	public reset(){
		this.base = ticker.shared.lastTime;
		this.pausedAt = 0;
	}
}