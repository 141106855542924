import { TicketTextFontPath } from "./TicketTextFontPath";
import { PlanState } from "../../common/services/plan-state.service";
import { TicketContainer } from "../data-mappers/TicketContainer";
import { IFontOptions } from "../fontpath/FancyText";
import { TICKET_CHANGE_EVENTS } from "../data-mappers/TicketContainer";
import { TICKET_LOCATION_NAME_SETTINGS } from "./TICKET_TEXT_SETTINGS";
import { FancyGraphics, FancyContainer } from "../graphics/";
import { Side } from "../../common/models/Side";
import { WORD_WRAP_MODE } from "../fontpath/WordWrapper";

export class TicketLocationNameFontPath extends TicketTextFontPath {
	// TODO: Apply the mask globally on the world;
	public maskContainer: FancyContainer = new FancyContainer();
	public maskGraphics: FancyGraphics = new FancyGraphics();
	
	constructor(fontFamily: string, planState: PlanState, ticketContainer: TicketContainer, text: string = "", fontOptions: IFontOptions = {fontSize: 22, wordWrapOptions: {mode: WORD_WRAP_MODE.GREEDY}}) {
		super(fontFamily, planState, ticketContainer, text, fontOptions);
		
		this.maskContainer.mask = this.maskGraphics;
		this.maskContainer.addChild(this);
		
		ticketContainer.ABOVE.addChild(this.maskGraphics);
		
		ticketContainer.on(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		
		this.onChanged(ticketContainer, null);
	}
	
	public onChanged = (ticketContainer: any, old: any) => {
		const data = ticketContainer.getData();
		
		if (!data.view.location){
			this.renderable = false;
		} else{
			this.renderable = true;
			this.tint = this.ticket.locationTextColor;
			
			let pullSuffix = (data.view.side === Side.PULL) ? "_PULL" : "";
			
			this.x = TICKET_LOCATION_NAME_SETTINGS["LEFT_MARGIN" + pullSuffix] || 0;
			this.y = TICKET_LOCATION_NAME_SETTINGS["TOP_MARGIN" + pullSuffix] || 0;
			
			const paddingRight: number = TICKET_LOCATION_NAME_SETTINGS["RIGHT_MARGIN" + pullSuffix] || 0;
			const height: number = TICKET_LOCATION_NAME_SETTINGS["DRAWING_AREA_HEIGHT" + pullSuffix] || 0;
			
			this.wordWrapWidth = data.view.width - paddingRight - this.x;
			
			// Draw text mask
			this.maskGraphics.clear();
			this.maskGraphics.beginFill(0x000000, 1);
			this.maskGraphics.drawRect(this.x, 0, this.wordWrapWidth, height);
			this.maskGraphics.endFill();
			
			this.setText(data.view.location.name);
			this.calculateText();
			if (data.view.side === Side.PULL){
				this.fontSize = 32;
			} else {
				this.fontSize = 18;
			}
		}
	};
	
	public destroy(options?): void {
		this.ticket.off(TICKET_CHANGE_EVENTS.CHANGED, this.onChanged);
		this.maskContainer.destroy(options);
		
		this.maskContainer = null;
		this.maskGraphics = null;
		super.destroy(options);
	}
}