import { Observable, Subject } from "rxjs";
import {first, takeUntil} from "rxjs/operators"
import * as moment from "moment";
import * as strings from "ng2/common/strings";
import {ListLike} from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import { Point, Rectangle } from "pixi.js";
import { default as PaperPoint } from 'js/lib/math/Point';

import * as utils from "utils";

import { Ticket, TicketRenderTypes, TicketTypes, Side, Plan, You } from "ng2/common/models";
import { DragAtEdge } from "ng2/common/models/DragAtEdge"
import { PullCalculation, PullRegion } from "ng2/common/models/PullCalculation";

import { TicketSelection } from "ng2/common/models/TicketSelection"

import { FancyTicketAction, TicketActionTypes, FancyActionTypes } from "ng2/actions/ticket-actions";

import {PlanState} from "ng2/common/services/plan-state.service";
import { ActiveColumnService, DatePositionValidator } from "ng2/common/ajs-upgrade-providers";

import { ForEachAble } from "ng2/common/interfaces";
import { VALIDATOR_DRAG_TOO_FAR_AWAY } from 'ng2/common/strings';
// import {keyModifier} from "ng2/common/key-modifier";

declare const fancyNotification;

export interface DragAnalysis{
	hasPromises: boolean;
	promiseList?: Array<Ticket>;
	hasConstraints: boolean;
	constraintList?: Array<Ticket>;
	hasCompleted: boolean;
	completedList?: Array<Ticket>;
	hasMovedHorizontally: boolean;
	movedHorizontallyList?: Array<Ticket>
	isOutofBounds: boolean;
	isAlmostOutofBounds: boolean;
	isInAbyss: boolean;
	needVariance: boolean;
	needVarianceList?: Array<Ticket>;
	clearVariance: boolean;
	clearVarianceList?: Array<Ticket>;
	hasDependencies: boolean;
	dependencyList?: Array<Ticket>;
}

export enum DragPromptConditions{
	"DEBUG",
	"DONT",
	"ALMOSTOUTOFBOUNDS",
	"COLLECTVARIANCE",
	"FLOATCONSTRAINTS",
	"FLOATDEPENDENCIES"
}
export interface DragPromptOutput{
	type: DragPromptConditions,
	payload: any
}

interface StartData{
	screenX:number,
	screenY:number,
	x:number,
	y:number,
	z:number,
	originSide: Side,
	hasPins: boolean,
	hasLoggedPullPinWarning: boolean
}

interface angularLand{
	activeColumnService: ActiveColumnService
	datePositionValidator: DatePositionValidator
}

// declare global{
// 	namespace Ticket.view{
// 		export interface DragStartData{
// 			x: number
// 		}
// 	}
// }
// 
// namespace Ticket.view.dragStartData{
// 	export let x: number;
// }

const USE_NEW_GRID_DEFAULT = true;

const pullTicketWidth = 290;
const pullTicketHeight = 270;
const activeColumnWidth = 145;
const activeColumnHeight = 135;
const snappingDistance = 50;
const stickiness = 40;
const activeLineWidth = 60;
const pullSnapGridSize = 340;

window.stickiness = stickiness;

export class TicketDragService{
	public dragInProgress = false;
	public dragCanceled = false;
	
	private planData: Plan;
	private hasActiveLine = false;
	public dragAtEdgeCancel = new Subject();
	private dragAtEdge: DragAtEdge;
	private hasCrossedActiveLine = false;
	
	private fixedVertical = false;
	
	private startRect?: Rectangle;
	private activeSortedTickets: Array<Ticket>;
	
	private calculatedPullColumns: PullCalculation;
	
	private tickets: Map<string, Ticket>;
	
	public ghostTickets: Subject<any> = new Subject();
	private internalGhostTickets: Map<string, any> = new Map();
	
	private startData:StartData;
	
	private debugRect: Rectangle;
	private debugRectId: string = null;
	
	private unsubscribes = [];
	
	private snapCache = {
		xPull: new Set(),
		yPull: new Set(),
		yActive: new Set()
	};
	
	constructor(private planState: PlanState, private angularLand: angularLand){
		planState.plan.object$.subscribe( (plan) => {
			if(this.dragInProgress && ((!plan && this.planData) || (plan && !this.planData) || plan.activeLineDate !== this.planData.activeLineDate || plan.activeLineX !== this.planData.activeLineX)){
				Logging.warning(strings.ACTIVE_LINE_MODIFIED_DURING_DRAG);
				this.dragCancel();
			}
			this.planData = plan; 
			this.hasActiveLine = plan && plan.planHasActiveSpace; 
		});
		this.ghostTickets = new Subject();
		// window.dragThing = this;
		// window.Rectangle = Rectangle;
	}
	
	//can pass in planState.tickets.selectedTicket$ directly, or make your own if necessary
	public dragStart(center: Point, ticketSelection:Observable<TicketSelection>){
		//just used by the reporting
		let lastDragCanceled = this.dragCanceled;
		let lastDragInProgress = this.dragInProgress;
		
		if(!this.planData){throw new Error("Plan data not loaded")}
		this.dragCanceled = false;
		this.dragInProgress = true;
		this.hasCrossedActiveLine = false;

		this.unsubscribes = [];
		// console.log('start center', center);
		
		this.debugRect = new Rectangle(0,0, 50, 900);
		// this.debugRectId = this.planState.debugRectangles.addRectangle(this.debugRect);
		
		var camera = this.planState.canvasConnector.camera;
	
		var selectionUnSub = ticketSelection.subscribe(tickets => this.ticketSelectionModfied(tickets));
		// console.log('selectionUnSub', selectionUnSub);
		this.unsubscribes.push(selectionUnSub);
		//shockingly, this.tickets _will_ be defined now, _yay observables_
		
		if(!this.tickets || !this.tickets.size){
			
			window.reporting.reportRealWarning(
				"tickets undefined in dragStart", 
				Object.assign(utils.makeStack(new Error("tickets undefined in dragStart")), {
					dragCanceled: lastDragCanceled,
					dragInProgress: lastDragInProgress,
					planStateState: this.planState.stateSync,
					entireTicketListCount: (this.planState.tickets && this.planState.tickets._internalList) ? this.planState.tickets._internalList.size : 0,
					selectedTicketsCount: (this.planState.tickets && this.planState.tickets.selectedTicket$) ? this.planState.tickets.selectedTicket$.getValue().list.size : 0
				}))
			return;
		}
		this.planState.actions.changeTicketViewToDrag(this.tickets);
		
		this.startData = this.calcStartData(center);
		
		//placeholder
		let bleh = new Rectangle(0, 0, window.innerWidth, window.innerHeight);
		
		//move this to a function
		this.dragAtEdge = new DragAtEdge(this.planState, bleh);
		this.unsubscribes.push(
			this.dragAtEdge.change$.pipe(takeUntil(this.dragAtEdgeCancel)).subscribe((map)=>{
				var revisedPoint = map.shiftedPoint;
				let z = camera.getScale().x;
				// debugger;
				this.planState.scopeSoup.hud.onTranslate(new PaperPoint(-map.deltaX, -map.deltaY));
				// console.log('bleh', this.startRect.left, revisedPoint.x/z, this.startRect.left + revisedPoint.x/z, this.planData.activeLineX);
				if(this.hasActiveLine && this.startData.hasPins && this.startRect.left + revisedPoint.x/z >= this.planData.activeLineX){
					if(!this.startData.hasLoggedPullPinWarning){
						Logging.notice(strings.PINNED_TICKETS_DRAGGED_TO_ACTIVE);
						this.startData.hasLoggedPullPinWarning = true;
					}
					return;
				}
				
				this.tickets.forEach((t, idx)=>{
					this.planState.actions.updateTicketLiveData(t.$id, {left: t.view.left + map.deltaX/z, "top": t.view.top + map.deltaY/z});
				})
			})
		);
		this.recalcStart();
	}
	
	//called when a ticket gets added/ removed from the collection mid drag
	private recalcStart(){
		if(!this.tickets.size){
			console.log('abort');
			
			this.dragCancel();
			return;
		}
		console.log('this.ticket.size', this.tickets.size);
		this.tickets.forEach((t:Ticket) => {
			//leave it if it's already there (handles updates, probably doesn't harm anything)
			if(t.view.dragStartData){ return; } 
			
			if(t.renderType !== TicketRenderTypes.drag){ this.planState.actions.changeTicketViewToDrag(t); }
			
			t.view.dragStartData = {
				x: t.view.left,
				y: t.view.top,
				width: t.view.width,
				height: t.view.height,
				start: t.view.liveStart,
				finish: t.view.liveFinish
			}
			
			//this will only get set during the drag, it will not clear
			if(t.view.liveLastPromise){ this.startData.hasPins = true; }
		});
		
		this.startRect = calculateStartRect(this.tickets);
		if(this.startData.originSide === Side.ACTIVE){
			this.calculatedPullColumns = this.calculatePullFromActiveRect(this.tickets);
			var startDate = this.angularLand.activeColumnService.guessDateFromX(this.startRect.left, -activeColumnWidth);
			this.tickets.forEach((t)=>{
				var leftEdgeDiff = Math.round((t.view.dragStartData.x - this.startRect.left) / activeColumnWidth);
				t.view.dragStartData.startDayOffset = leftEdgeDiff;
				
				var workdays = this.planState.calendarWorkdays.getWorkdaysWithinRange(
					startDate,
					leftEdgeDiff, 
					t.view.liveRole ? t.view.liveRole.roleId : null, 
					true//leftEdgeDiff === 0
				);
				t.view.dragStartData.startWorkDayOffset = workdays;
				// console.log(workdays, t.view.dragStartData);
			});
			
			this.activeSortedTickets = sortActiveTickets(this.tickets);
			
			//column limited yOrder
			var maxYOrder = 0;
			this.calculatedPullColumns.regions.forEach((region)=>{
				var ySorted = [...region.tickets];
				ySorted.sort((a,b)=>{
					var aS = a.view.dragStartData;
					var bS = b.view.dragStartData;
					if(aS.y === bS.y){
						if(aS.x + aS.width === bS.x + bS.width){
							return a.$id.localeCompare(b.$id);
						}
						return (aS.x + aS.width) - (bS.x + bS.width);
					}
					return aS.y - bS.y;
				});
				ySorted.forEach((wt, key)=>{
					wt.view.dragStartData.yOrder = key;
					if(key > maxYOrder){ maxYOrder = key; }
				});
			});
			this.calculatedPullColumns.pullStartRect.height = pullTicketHeight + (pullTicketHeight + snappingDistance) * maxYOrder;
		}
		
		this.fixedVertical = false;
		//check for fixed vertical
		// mixed side selection/ completed
		var lastSide;
		this.tickets.forEach((ticket)=>{
			if(ticket.view.isActualized){ this.fixedVertical = true; }
			if(lastSide !== undefined && lastSide !== ticket.view.side){ this.fixedVertical = true; }
			lastSide = ticket.view.side;
		});
		
	}
	
	public drag(center: Point){
		if(this.dragCanceled){ return true; }
		this.dragAtEdge.update(center);
		var camera = this.planState.canvasConnector.camera;
		
		if(!this.tickets || !this.tickets.size){
			window.reporting.reportRealWarning(
				"tickets undefined in drag", 
				Object.assign(utils.makeStack(new Error("tickets undefined in drag")), {
					dragCanceled: this.dragCanceled,
					dragInProgress: this.dragInProgress,
					center: center,
					planStateState: this.planState.stateSync,
					entireTicketListCount: (this.planState.tickets && this.planState.tickets._internalList) ? this.planState.tickets._internalList.size : 0,
					selectedTicketsCount: (this.planState.tickets && this.planState.tickets.selectedTicket$) ? this.planState.tickets.selectedTicket$.getValue().list.size : 0
				}))
			return;
		}
		
		var hide = false
		this.tickets.forEach((t)=> hide = hide || t.hidden);
		if(hide){
			this.hideGhostTickets();
			return;
		}
		
		var worldPoint = new Point(camera.screenToRealX(center.x), camera.screenToRealY(center.y));
		var delta = new Point(worldPoint.x - this.startData.x, worldPoint.y - this.startData.y);
		
		var drawSpecial = false;
		
		var liveRect = this.startRect.clone();
		liveRect.x += delta.x;
		liveRect.y += delta.y;
		
		var lockInPlaceWhenInSameColumn = this.startData.originSide === Side.ACTIVE && (
			liveRect.x > this.startRect.x - activeColumnWidth/2 &&
			liveRect.x < this.startRect.x + activeColumnWidth/2
		);
		// console.log('liveRect', liveRect.x, lockInPlace);
		// console.log('start side', this.startData.originSide);
		if(this.fixedVertical || lockInPlaceWhenInSameColumn){
			var fixedVerticalSnaps = this.buildSnapCache(this.startRect);
			var	x = 0;
			var c = this.startData.originSide === Side.PULL ? fixedVerticalSnaps.yPull : fixedVerticalSnaps.yActive;
			var y = this.findSnaps(liveRect.y, c) - this.startRect.y;
		}
		//starting on pull side
		else if(this.startData.originSide === Side.PULL){
			this.snapCache = this.buildSnapCache(this.startRect);
			if(liveRect.x < this.planData.activeLineX + activeLineWidth){
				// this.showGhostTickets();
				var calcedActive = this.calculateActiveFromPullRect(this.tickets, delta);
				
				// this.debugRect.x = calcedActive.activeRect.x;
				// this.debugRect.y = liveRect.y;
				// this.debugRect.width = calcedActive.activeRect.width;
				// this.planState.debugRectangles.updateRectangle(this.debugRectId, this.debugRect);
				
				var calcedActiveWriteData = this.generateActiveWriteData(calcedActive, delta);
				
				//whole thing is in active space
				if(Math.round(calcedActive.activeRect.right) <= this.planData.activeLineX){
					drawSpecial = true;
					this.hideGhostTickets();
					calcedActiveWriteData.forEach((ticket)=>{
						this.planState.actions.updateTicketLiveData(ticket.$id, ticket.write);
					});
					this.hasCrossedActiveLine = true;
				}
				//ghosty things
				else{
					this.showGhostTickets();
					// console.log('show ghost tickets');
					calcedActiveWriteData.forEach((ticket)=>{
						var ghost = this.internalGhostTickets.get(ticket.$id);
						Object.assign(ghost.view, ticket.write);
						this.planState.actions.updateTicketLiveData(ticket.$id, ticket);
							var action = new FancyTicketAction(ghost, TicketActionTypes.ticketLiveUpdate, ghost.$id);
							this.ghostTickets.next(action);
					});
					
					var x = this.findSnaps(liveRect.x, this.snapCache.xPull) - this.startRect.x;
					var y = this.findSnaps(liveRect.y, this.snapCache.yPull) - this.startRect.y;
					//cap the delta
					x = this.planData.activeLineX - this.startRect.x + activeLineWidth;
					this.hasCrossedActiveLine = false;
				}
			}
			else{
				let useOldGrid = !USE_NEW_GRID_DEFAULT;
				if(this.planData.staticPullGridOverride === false || this.planData.staticPullGridOverride === true){
					useOldGrid = !this.planData.staticPullGridOverride;
				}
				// if(keyModifier.isAppleSafeCtrl){ useOldGrid = true; }
				
				if(useOldGrid){
					var x = this.findSnaps(liveRect.x, this.snapCache.xPull) - this.startRect.x;
					// var x = liveRect.x - this.startRect.x;
				}
				else{
					// var x = this.findSnaps(liveRect.x, this.snapCache.xPull) - this.startRect.x;
					var x = liveRect.x - (liveRect.x % pullSnapGridSize) - this.startRect.x;
				}
				var y = this.findSnaps(liveRect.y, this.snapCache.yPull) - this.startRect.y;
				this.hideGhostTickets();
				this.hasCrossedActiveLine = false;
			}
		}
		else if(this.startData.originSide === Side.ACTIVE){
			// console.log('is starting active');
			drawSpecial = true;
			var activeColumns = this.angularLand.activeColumnService.columns();
			var match = utils.snapping(liveRect.x, activeColumns, activeColumnWidth, (listItem)=>{
				return listItem.origStartX;
			});
			if(match !== false){ liveRect.x = match.loc; }
			
			//liveRect is now modified, along with tickets
			var shouldGhost = this.figureOutActive(liveRect, this.activeSortedTickets);
			if(shouldGhost && this.startData.hasPins && !this.startData.hasLoggedPullPinWarning){
				Logging.notice(strings.PINNED_TICKETS_DRAGGED_TO_ACTIVE);
				this.startData.hasLoggedPullPinWarning = true;
			}
			if(this.startData.hasPins){ shouldGhost = false; }
			// console.log('hasPins', this.startData.hasPins, shouldGhost);
			var pullLeft = this.startRect.left + delta.x + this.calculatedPullColumns.regions[this.calculatedPullColumns.regions.length-1].startX;
			
			var activeSnaps = this.buildSnapCache(liveRect);

			var activeSnappedY = this.findSnaps(liveRect.y, activeSnaps.yActive) - this.startRect.y;
			
			
			var pullRect = this.calculatedPullColumns.pullStartRect.clone();
			pullRect.x = this.startRect.x;
			pullRect.y = this.startRect.y;
			pullRect.x += delta.x;
			pullRect.y += delta.y;
			var pullFromActiveSnaps = this.buildSnapCache(pullRect);
			
			let useOldGrid = !USE_NEW_GRID_DEFAULT;
			if(this.planData.staticPullGridOverride === false || this.planData.staticPullGridOverride === true){
				useOldGrid = !this.planData.staticPullGridOverride;
			}
			// if(keyModifier.isAppleSafeCtrl){ useOldGrid = true; }
			
			if(useOldGrid){
				var pullSnappedX = this.findSnaps(pullRect.x, pullFromActiveSnaps.xPull) - this.startRect.x;
			}
			else{
				var pullSnappedX = pullRect.x - (pullRect.x % pullSnapGridSize) - this.startRect.x;
			}
			var pullSnappedY = this.findSnaps(pullRect.y, pullFromActiveSnaps.yPull) - this.startRect.y;

			// this.planState.debugRectangles.updateRectangle(this.debugRectId, this.debugRect);
			
			
			var calculatedPullTicketData = [];
			// var snappedX = this.findSnaps(pullLeft, this.snapCache.xPull) - this.startRect.x;
			this.calculatedPullColumns.iterate((ticket, region)=>{
				var obj = {
					left: Math.round(this.startRect.left + pullSnappedX + region.startX),
					top: Math.round((this.startRect.y + pullSnappedY) + (pullTicketHeight + snappingDistance) * ticket.view.dragStartData.yOrder),
					width: pullTicketWidth,
					height: pullTicketHeight,
					liveStart: ticket.view.dragStartData.start,
					liveFinish: ticket.view.dragStartData.finish,
					side: Side.PULL
				};
				calculatedPullTicketData.push({write: obj, $id: ticket.$id});
			});
			
			var calculatedActiveTicketData = [];
			this.tickets.forEach((t, idx)=>{
				var left = Math.round(liveRect.x + t.view.lastWorkdayRep.shiftedStartOffset * activeColumnWidth + t.view.lastWorkdayRep.startOffsetDays * activeColumnWidth);
				// var left = Math.round(liveRect.x + t.view.dragStartData.startDayOffset * activeColumnWidth + t.view.lastWorkdayRep.startOffsetDays * activeColumnWidth);
				var width = Math.round(t.view.lastWorkdayRep.calendarDays * activeColumnWidth);
				var guessedStart = this.angularLand.activeColumnService.guessDateFromX(left, -activeColumnWidth);
				var guessedFinish = this.angularLand.activeColumnService.guessDateFromX(left+width);
				// console.log('left', liveRect.x + t.view.dragStartData.startDayOffset * activeColumnWidth + t.view.lastWorkdayRep.startOffsetDays * activeColumnWidth, left, guessedStart, guessedFinish);
				var obj = {
					left: left,
					top: Math.round(t.view.dragStartData.y + activeSnappedY),
					width: width,
					height: Math.round(t.view.dragStartData.height),
					liveStart: guessedStart,
					liveFinish: guessedFinish
				};
				calculatedActiveTicketData.push({write: obj, $id: t.$id});
			});
			
			if(shouldGhost){
				// console.log('should ghost');
				//calculated pullTickets
				if(pullLeft > this.planData.activeLineX + activeLineWidth){
					// console.log('pullify');
					this.hideGhostTickets();
					calculatedPullTicketData.forEach((ticket)=>{
						this.planState.actions.updateTicketLiveData(ticket.$id, ticket.write);
					});
					this.hasCrossedActiveLine = true;
				}
				//active tickets and ghost pull tickets
				else{
					this.showGhostTickets();
					// console.log('activate');
					calculatedActiveTicketData.forEach((ticket)=>{
						this.planState.actions.updateTicketLiveData(ticket.$id, ticket.write);
					});
					
					calculatedPullTicketData.forEach((ticket)=>{
						var ghost = this.internalGhostTickets.get(ticket.$id);
						Object.assign(ghost.view, ticket.write);
						var action = new FancyTicketAction(ghost, TicketActionTypes.ticketLiveUpdate, ghost.$id);
						this.ghostTickets.next(action);
					});
					this.hasCrossedActiveLine = false;
				}
			}
			//just active tickets
			else{
				this.hideGhostTickets();
				calculatedActiveTicketData.forEach((ticket)=>{
					// console.log('ticket', ticket.write.liveStart === ticket.write.liveFinish, ticket.write.liveStart, ticket.write.liveFinish);
					this.planState.actions.updateTicketLiveData(ticket.$id, ticket.write);
				});
				this.hasCrossedActiveLine = false;
			}
		}
		else{
			console.log('da hell?');
		}
		
		
		
		
		
		
		
		//lazy short hand to prevent the default draw if some fancier is done
		if(drawSpecial){return;}
		this.tickets.forEach((t)=>{
			this.planState.actions.updateTicketLiveData(t.$id, {
				left: Math.round(t.view.dragStartData.x + x),
				top: Math.round(t.view.dragStartData.y + y),
				width: Math.round(t.view.dragStartData.width),
				height: Math.round(t.view.dragStartData.height),
				liveStart: t.view.dragStartData.start,
				liveFinish: t.view.dragStartData.finish
			});
		})
	}
	
	public dragEnd(center: Point){
		if(this.tickets){ //ignore floating space only drags
			this.applyConstraintAcceptance();
			analyticsService.movedTickets(this.tickets.size, this.planState.planId, false, false, false, this.hasCrossedActiveLine);
			this.planState.actions.persistTicketData(this.tickets);
			this.planState.actions.changeTicketViewToDefault(this.tickets);
		}
		this.dragCleanUp();
	}
	
	public applyConstraintAcceptance(){
		let constraints = checkForConstraintAcceptance(this.planState.youSync, this.tickets);
		constraints.forEach((t)=>{
			this.planState.actions.updateTicketLiveData(t.$id, {
				assignedProjectMemberId: null,
				assignedProjectMemberRoleId: null,
				responsibleProjectMemberId: t.view.assignedProjectMemberId,
				responsibleProjectMemberRoleId: null
			});
			let conName = t.view.activityName;
			fancyNotification.simpleMessage("You accepted responsibility for \""+conName+"\".", this.planState.scopeSoup);
		})
	}
	
	public dragCancel(){
		if(!this.dragInProgress){ return; }
		this.dragCanceled = true;
		this.planState.actions.changeTicketViewToDefault(this.tickets);
		this.dragCleanUp();
	}
	
	/** Turn off subscription listening so that the passed in selection can be modified as part of a more advanced dragEnd */
	public stopListening(){
		this.unsubscribes.forEach(unsub => unsub.unsubscribe() ); this.unsubscribes = [];
	}
	
	//called to cleanup after a drag
	public dragCleanUp(){
		if(!this.dragInProgress){ return; }
		this.unsubscribes.forEach(unsub => unsub.unsubscribe() ); this.unsubscribes = [];
		this.dragInProgress = false;
		this.planState.debugRectangles.removeRectangle(this.debugRectId);
		this.hideGhostTickets();
		
		this.tickets = undefined;
		this.dragAtEdge.cancel();
	}
	
	public destroy(){
		this.ghostTickets.complete();
		this.internalGhostTickets.clear();
	}

	
	public analyzeDragStatus(tickets?: ForEachAble<Ticket>):DragAnalysis{
		if(!tickets){ tickets = this.tickets; }
		if(!tickets){ tickets = []; } //likely this is a floating exclusive drag
		
		var analysis:DragAnalysis = {
			hasPromises: false,
			hasConstraints: false,
			hasCompleted: false,
			hasDependencies: false,
			hasMovedHorizontally: false,
			isOutofBounds: false,
			isAlmostOutofBounds: false,
			isInAbyss: false,
			needVariance: false,
			clearVariance: false
		};
		analysis.dependencyList
		function setCheck(hasKey, listKey, ticket){
			analysis[hasKey] = true;
			if(!analysis[listKey]){ analysis[listKey] = [];}
			analysis[listKey].push(ticket);
		}
		
		
		tickets.forEach((ticket)=>{
			var newLeft = ticket.view.left;
			var newTop = ticket.view.top;
			//promise check
			if(ticket.view.livePromisePeriodStack){
				setCheck("hasPromises", "promiseList", ticket);
			}
			
			//dependencies
			if(this.planState.dependencies.ticketHasDependencies(ticket.$id)){
				setCheck("hasDependencies", "dependencyList", ticket);
			}
			
			//constraint check
			if(ticket.view.type === TicketTypes.CONSTRAINT){
				setCheck("hasConstraints", "constraintList", ticket);
			}
			if(ticket.view.isActualized){
				setCheck("hasCompleted", "completedList", ticket);
			}
			if(ticket.view.dragStartData && Math.round(ticket.view.dragStartData.x) === Math.round(newLeft)){
				setCheck("hasMovedHorizontally", "movedHorizontallyList", ticket);
			}
			var validPosition = this.angularLand.datePositionValidator.isValidPosition(newLeft, newTop);
			var inWarningRange = this.angularLand.datePositionValidator.positionWarning(newLeft, newTop);
			if(!validPosition && !inWarningRange){
				analysis.isOutofBounds = true;
			}
			if(!validPosition && inWarningRange){
				analysis.isAlmostOutofBounds = true;
			}
			if(this.angularLand.datePositionValidator.isExtremelyInvalidPosition(newLeft, newTop)){
				analysis.isInAbyss = true;
			}
			if(ticket.view.livePromisePeriodStack && !ticket.view.isActualized){
				if(ticket.view.liveLastPromise.data.plannedFinish === ticket.view.liveFinish && ticket.view.liveLastPromise.data.varianceId){
					setCheck("clearVariance", "clearVarianceList", ticket);
				}
				else if(ticket.view.liveLastPromise.data.plannedFinish !== ticket.view.liveFinish && !ticket.view.liveLastPromise.data.varianceId){
					setCheck("needVariance", "needVarianceList", ticket);
				}
			}
		});
		console.log('analysis', analysis);
		return analysis;
	}
	
	public checkAbortConditions(analysis: DragAnalysis){
		if(analysis.isInAbyss){
			Logging.warning(VALIDATOR_DRAG_TOO_FAR_AWAY);
			//TODO - don't scopeSoup, probably not worth upgrading either, would likely be worthwhile to make a new one from scratch
			this.planState.scopeSoup.fbReporting.reportRealWarning("dragged into the abyss", {
				"ticketCount": this.tickets.size,
				"type": "tickets",
				"droppedInFloating": false
			});
			return true;
		}
		if(analysis.isOutofBounds){
			//TODO - don't scopeSoup, probably not worth upgrading either, would likely be worthwhile to make a new one from scratch
			analyticsService.positionViolationBlocked();
			Logging.warning(VALIDATOR_DRAG_TOO_FAR_AWAY);
			return true;
		}
	}
	
	// public test(){
	// 	var list = [];
	// 
	// 	var obs1 = new Observable((observer)=>{
	// 		console.log('obs1');
	// 		var prom = new Promise((resolve, reject)=>{
	// 			setTimeout(resolve, 2000);
	// 		});
	// 		prom.then((data)=> {
	// 			console.log('then obs1');
	// 			observer.next();
	// 			observer.complete();
	// 		})
	// 	});
	// 
	// 	var obs2 = new Observable((observer)=>{
	// 		console.log('obs2');
	// 		var prom = new Promise((resolve, reject)=>{
	// 			setTimeout(resolve, 2000);
	// 		});
	// 		prom.then((data)=> {
	// 			console.log('then obs2');
	// 			observer.next();
	// 			observer.complete();
	// 		})
	// 	});
	// 
	// 
	// 	list.push(obs1, obs2);
	// 	console.log('list', list);
	// 	var newObs = list.reduce((acc, obs)=>{
	// 		console.log('reducing', acc, obs);
	// 		return acc.concat(obs);
	// 	})
	// 	console.log('new obs', newObs);
	// 	newObs.subscribe((thing)=>{
	// 		console.log('subscribing', thing);
	// 	})
	// 	// .complete(thing => console.log('all done'));
	// 
	// 	console.log('done setup');
	// 
	// }
	// 
	// public test2(){
	// 	var list = [
	// 		()=>{
	// 			console.log('obs1');
	// 			return new Promise<string>((resolve, reject)=>{
	// 				setTimeout(()=> resolve("resolve obs1"), 2000);
	// 			});
	// 		},
	// 		()=>{
	// 			console.log('obs2');
	// 			return new Promise<string>((resolve, reject)=>{
	// 				setTimeout(()=> resolve("resolve obs2"), 2000);
	// 			});
	// 		},
	// 	];
	// 
	// 	utils.sequencePopups(list).subscribe((thing)=>{
	// 		console.log('subscribing', thing);
	// 	})
	// 	// .complete(thing => console.log('all done'));
	// 
	// 	console.log('done setup');
	// 
	// }
	 //Promise<DragPromptOutput>
	public checkPromptConditions(analysis: DragAnalysis):Array<()=>Promise<DragPromptOutput>>{
		var scopeSoup = this.planState.scopeSoup;
		var prompts = [];
		
		function wrap(type, payload){
			return {type, payload};
		}
		if(analysis.isAlmostOutofBounds){
			prompts.push(()=>{
				let stringPiece = (this.tickets.size > 1) ? "these tickets" : "this ticket";
				return scopeSoup.popup({
					"title": "Are you sure you want to drag " + stringPiece + " to that position?",
					"description": "Your drag position is outside of the expected position range for your plan."
				}).then(payload => wrap(DragPromptConditions.ALMOSTOUTOFBOUNDS, payload));
			})
			analyticsService.positionViolationNagConfirm();
		}
		if(analysis.needVariance){
			prompts.push(()=>{
				return scopeSoup.popup({
					"template": require("tpl/popups/collect-variance.html")
				},scopeSoup).then(payload => wrap(DragPromptConditions.COLLECTVARIANCE, payload));
			})
		}
		return prompts;
	}
	
	private buildSnapCache(dragRect:Rectangle){
		var cache = {
			xPull: new Set(),
			yPull: new Set(),
			yActive: new Set()
		};
		this.planState.tickets.list$.pipe(first()).subscribe((fullTickets)=>{
			fullTickets.forEach((ticket, id)=>{
				if(this.tickets.has(id)){ return;}
				// console.log('ticketactuls', ticket.view.isActualized);
				if(ticket.view.side === Side.ACTIVE){
					cache.yActive.add(ticket.view.top); //same position
					cache.yActive.add(ticket.view.top + ticket.view.height + snappingDistance); //50px below the bottom
					cache.yActive.add(ticket.view.top - snappingDistance - dragRect.height); // bottom of drag rect 50px above
				}
				else{
					cache.xPull.add(ticket.view.left); //same position
					cache.xPull.add(ticket.view.left + ticket.view.width + snappingDistance); //50px to the right
					cache.xPull.add(ticket.view.left - snappingDistance - dragRect.width); // right of drag rect 50px to the left
					
					cache.yPull.add(ticket.view.top); //same position
					cache.yPull.add(ticket.view.top + ticket.view.height + snappingDistance); //50px below the bottom
					cache.yPull.add(ticket.view.top - snappingDistance - dragRect.height); // bottom of drag rect 50px above
				}
			})
		})
		return cache;
	}
	
	private findSnaps(num:number, snapCache){
		// if(keyModifier.isAppleSafeCtrl){ return num; }
		var match = utils.snapping(num, snapCache, window.stickiness, (listItem)=>{
			return listItem;
		});
		return match ? match.loc : num;
	}
	
	//should probably do some fancy shit in here, do simple thing for now
	private ticketSelectionModfied(ticketSelection: TicketSelection){
		//mid drag update
		if(this.tickets){
			this.tickets = ticketSelection.list;
			this.recalcStart();
		}
		//initial
		else{
			this.tickets = ticketSelection.list;
		}
	}
	
	private generateActiveWriteData(calcedActive:PullCalculation, delta){
		var calcedActiveWriteData = [];
		var snaps = this.buildSnapCache(calcedActive.activeRect);
		var y = this.findSnaps(calcedActive.activeRect.y, snaps.yActive) - this.startRect.y;
		// console.log('calcedActive.startDate', calcedActive.startDate, );
		calcedActive.iterate((ticket, region)=>{
			var work = ticket.view.lastWorkdayRep;
			var data = {
				left: Math.round(region.activeStartX + work.startOffsetDays * activeColumnWidth), 
				top: Math.round(ticket.view.dragStartData.y + y),
				width: work.calendarDays * activeColumnWidth,
				height: activeColumnHeight,
				liveStart: calcedActive.startDate ? moment(calcedActive.startDate).add(work.startOffsetDays, "days").format("YYYY-MM-DD"): undefined,
				liveFinish: calcedActive.startDate ? moment(calcedActive.startDate).add(work.startOffsetDays+work.calendarDays-1, "days").format("YYYY-MM-DD"): undefined,
				side: Side.ACTIVE
			}
			calcedActiveWriteData.push({write: data, $id: ticket.$id});
		});
		return calcedActiveWriteData;
	}
	
	private calculateActiveFromPullRect(tickets: Map<string, Ticket>, worldDelta: Point){
		var leftEdge = this.startRect.x + worldDelta.x; //move it
		// console.log('left edge', leftEdge);
		var activeColumns = this.angularLand.activeColumnService.columns();
		var match = utils.snapping(leftEdge, activeColumns, activeColumnWidth, (listItem)=>{
			return listItem.origStartX;
		});
		//snap it if there's somewhere to snap to
		if(match){ leftEdge = match.loc; }
		var dayOffset = 0; //increment as we walk through
		// var startDate = this.angularLand.activeColumnService.guessDateFromX(leftEdge + dayOffset * activeColumnWidth);
		var startDate = this.angularLand.activeColumnService.guessDateFromX(Math.min(this.planData.activeLineX, leftEdge), -activeColumnWidth);
		// console.log('tickets', tickets);
		//don't love this "build pull data, then decorate with active data" thing, would prefer a more formal divide or a more formal decoration process
		var calculatedColumns = this.calculatePullColumns(tickets);
		
		var fullTop = Infinity; var fullBottom = -Infinity;
		
		//go in reverse
		for( var i = calculatedColumns.regions.length-1; i >= 0; i--){
			var r = calculatedColumns.regions[i];
			var columnWidth = 0;
			r.tickets.forEach((ticket)=>{
				var rep = this.planState.calendarWorkdays.getWorkdayRepresentation(startDate, ticket.view.liveDurationDays, ticket.view.liveRole ? ticket.view.liveRole.roleId : null, i === calculatedColumns.regions.length-1);
				var size = rep.startOffsetDays + rep.calendarDays;
				if(size > columnWidth){ columnWidth = size; }
				if(ticket.view.dragStartData.y + worldDelta.y < fullTop){ fullTop = ticket.view.dragStartData.y + worldDelta.y; }
				if(ticket.view.dragStartData.y + activeColumnHeight + worldDelta.y > fullBottom){ fullBottom = ticket.view.dragStartData.y + activeColumnHeight + worldDelta.y; }
			});
			
			r.activeStartX = leftEdge + dayOffset * activeColumnWidth;
			r.activeFinishX = leftEdge + dayOffset * activeColumnWidth + columnWidth * activeColumnWidth;
			r.startDate = startDate;
			r.calculatedCalendarDays = columnWidth;
			dayOffset += columnWidth;
			//advance the start date
			startDate = this.angularLand.activeColumnService.guessDateFromX(Math.min(this.planData.activeLineX, leftEdge + dayOffset * activeColumnWidth), -activeColumnWidth);
			// startDate = this.angularLand.activeColumnService.guessDateFromX(leftEdge + dayOffset * activeColumnWidth);
		}
		var fullLeft = calculatedColumns.regions[calculatedColumns.regions.length-1].activeStartX;
		var fullRight = calculatedColumns.regions[0].activeFinishX;
		//TODO - get y values
		calculatedColumns.activeRect = new Rectangle(fullLeft, fullTop, fullRight - fullLeft, fullBottom - fullTop);
		
		
		calculatedColumns.iterate((ticket, r, ticketIdx, i)=>{
			var r = calculatedColumns.regions[i];
			r.tickets.forEach((ticket)=>{
				var rightened = this.righten(r.startDate, r.calculatedCalendarDays, ticket, i === calculatedColumns.regions.length-1);
				ticket.view.lastWorkdayRep = rightened;
			});
		})
		
		// console.log('calculatedColumns', calculatedColumns.regions[0].calculatedCalendarDays);
		// console.log('calculatedColumns', calculatedColumns.regions.map(c => c.calculatedCalendarDays));
		return calculatedColumns;
	}
	
	private calculatePullFromActiveRect(tickets: Map<string, Ticket>){
		// console.log('tickets', tickets);
		//don't love this "build pull data, then decorate with active data" thing, would prefer a more formal divide or a more formal decoration process
		var calculatedColumns = this.calculatePullColumns(tickets);
		calculatedColumns.regions.forEach((region, idx, list)=>{
			var reversedIdx = list.length - idx - 1;
			region.startX = (pullTicketWidth + snappingDistance) * reversedIdx;
			region.finishX = pullTicketWidth * (reversedIdx + 1);
		});
		// calculatedColumns.pullStartRect.x = calculatedColumns.regions[0].startX;
		// calculatedColumns.pullStartRect.width = calculatedColumns.regions[calculatedColumns.regions.length-1].finishX - calculatedColumns.regions[0].startX;
		calculatedColumns.pullStartRect.x = calculatedColumns.regions[calculatedColumns.regions.length-1].startX;
		calculatedColumns.pullStartRect.width = calculatedColumns.regions[0].finishX - calculatedColumns.regions[calculatedColumns.regions.length-1].startX;
		// console.log('pullStartRect',calculatedColumns.regions, calculatedColumns.pullStartRect);
		return calculatedColumns;
	}
	
	//used to position tickets in the activeFromPullRect within a column as far right as they can go (perf challenged)
	//naive, start from left, keep retrying using getWorkDayRepresentation, also includes some of it's own date math
	private righten(startDate, maxDays, ticket, isFirst){
		
		var rep = this.planState.calendarWorkdays.getWorkdayRepresentation(startDate, ticket.view.liveDurationDays, ticket.view.liveRole ? ticket.view.liveRole.roleId : null, isFirst);
		var dayOffset = 0;
		var lastRep = null;
		while(true){
			if(rep.startOffsetDays + rep.calendarDays + dayOffset === maxDays){return decorateRep(rep, dayOffset);}
			else if(rep.startOffsetDays + rep.calendarDays + dayOffset > maxDays){ return decorateRep(lastRep, dayOffset); } //this can't be hit first iteration
			else{
				lastRep = rep; 
				var nextDay = moment(startDate, "YYYY-MM-DD").add(1+dayOffset, "days").format("YYYY-MM-DD");
				dayOffset++;
				rep = this.planState.calendarWorkdays.getWorkdayRepresentation(nextDay, ticket.view.liveDurationDays, ticket.view.liveRole ? ticket.view.liveRole.roleId : null, isFirst);
			}
		}
		
		function decorateRep(rep, offset){
			rep.startOffsetDays += offset;
			return rep;
		}
	}
	
	private figureOutActive(rect:Rectangle, tickets:Array<Ticket>){
		var leftSide = Math.round(Math.min(rect.left, this.planData.activeLineX - activeColumnWidth));
		if(leftSide > this.planData.activeLineX - activeColumnWidth){leftSide = this.planData.activeLineX - activeColumnWidth; }
		var rightSide;
		
		var startGhosting = leftSide >= this.planData.activeLineX - activeColumnWidth;
		
		var allDone = false;
		
		while(!allDone){
			var leftDate = this.angularLand.activeColumnService.guessDateFromX(leftSide, -activeColumnWidth);
			rightSide = -Infinity;
			allDone = true;
			
			// debugger;
			innerLoop:
			for(var i = 0; i < tickets.length; i++){
				var ticket = tickets[i];
				var ticketStartData = ticket.view.dragStartData;
				
				var workDayStartOffset = this.planState.calendarWorkdays.getCalendarOffset(
					leftDate, 
					ticketStartData.startWorkDayOffset, 
					ticket.view.liveRole ? ticket.view.liveRole.roleId : null,
					true
				);
				var altStartOffset = workDayStartOffset.calendarDays;// + workDayStartOffset.startOffsetDays;
				
				// console.log('ticket', ticket.view.activityName, ticketStartData.startWorkDayOffset, 'work cal', workDayStartOffset);
				var ticketLeft = leftSide + altStartOffset * activeColumnWidth;
				
				var startDate = this.angularLand.activeColumnService.guessDateFromX(ticketLeft, -activeColumnWidth);
				//console.log('ticketStartDate', startDate);
				var work = this.planState.calendarWorkdays.getWorkdayRepresentation(startDate, ticket.view.liveDurationDays, ticket.view.liveRole ? ticket.view.liveRole.roleId : null, ticketStartData.startDayOffset === 0);
				(<any>work).shiftedStartOffset = altStartOffset;
				// work.startOffsetDays += (altStartOffset - ticketStartData.startDayOffset);
				//no workdays left problem, pull everything back a day and try again
				if(ticketLeft + work.startOffsetDays * activeColumnWidth >= this.planData.activeLineX){
					// console.log('no workdays left');
					leftSide -= activeColumnWidth;
					allDone = false; break innerLoop;
				}
				
				ticket.view.lastWorkdayRep = work;
				
				// console.log('ticketLeft', ticketLeft, this.planData.activeLineX - activeColumnWidth);
				if(ticketLeft >= this.planData.activeLineX - activeColumnWidth){
					startGhosting = true;
				}
				// console.log('ticketLeft', ticketLeft)
				var ticketRight = ticketLeft + work.startOffsetDays * activeColumnWidth + work.calendarDays * activeColumnWidth;
				if(ticketRight > rightSide){rightSide = ticketRight; }
			}
		}
		
		rect.x = leftSide;
		rect.width = rightSide - leftSide;
		
		
		
		// console.log('output rect', rect.left, rect.right);
		return startGhosting;
	}
	
	private showGhostTickets(){
		if(this.internalGhostTickets.size){ return; } //already shown
		this.tickets.forEach((t)=>{
			var ghost = {
				$id: "ghost-"+t.$id,
				realId: t.$id,
				isGhost: true,
				view: Object.assign({}, t.view)
			};
			this.internalGhostTickets.set(t.$id, ghost);
			this.ghostTickets.next(new FancyTicketAction(ghost, FancyActionTypes.childAdded, ghost.$id))
		})
	}
	private updateGhostTickets(){
		
	}
	private hideGhostTickets(){
		this.internalGhostTickets.forEach((t)=>{
			this.ghostTickets.next(new FancyTicketAction(null, FancyActionTypes.childRemoved, t.$id));
		})
		
		this.internalGhostTickets.clear();
	}
	
	private calculatePullColumns(tickets: Map<string, Ticket>){
		return new PullCalculation(tickets);
	}
	
	private dragAtEdgeListener(map){
		
	}
	
	private calcStartData(center: Point){
		var camera = this.planState.canvasConnector.camera;
		var z = camera.getScale().x;

		var realX = camera.screenToRealX(center.x);
		
		// console.log('>>>> origin side calc', !this.planData.activeLineX || realX > this.planData.activeLineX, realX, this.planData.activeLineX, this.planData.activeLineDate);
		
		return {
			screenX: center.x,
			screenY: center.y,
			x: realX,
			y: camera.screenToRealY(center.y),
			z: camera.getScale().x,
			originSide: this.planData.activeLineX === undefined || this.planData.activeLineX === null || realX > this.planData.activeLineX ? Side.PULL : Side.ACTIVE,
			hasPins: false,
			hasLoggedPullPinWarning: false
		}
	}
	
	public updateDragAtEdgeDeadZones(zones: Array<Rectangle>){
		this.dragAtEdge.deadZones = zones;
	}

}


//move to a utility file:

function checkForConstraintAcceptance(you:You, tickets:ForEachAble<Ticket>){
	let constraintsToBeAccepted:Array<Ticket> = [];
	let pmYou = you.data.projectMemberId;
	tickets.forEach((ticket)=>{
		if(ticket.view.type === "constraint" &&
				ticket.view.assignedProjectMemberId &&
				pmYou === ticket.view.assignedProjectMemberId){
			constraintsToBeAccepted.push(ticket);
		}
	})
	return constraintsToBeAccepted;
}

function sortActiveTickets(tickets){
	return sortTicketMap(tickets, (ticketA, ticketB) => ticketA.view.dragStartData.startDayOffset < ticketB.view.dragStartData.startDayOffset);
}

//start lazy
function sortTicketMap(tickets:Map<string, Ticket>, cb){
	var arr = [...tickets.values()];
	arr.sort(cb);
	return arr;
}


//perf might not be great here
// function calculateStartRect(tickets:Map<string, Ticket>){
// 	var rect;
// 	var lefts = [];
// 	var tops = [];
// 	tickets.forEach((t)=>{
// 		lefts.push(t.view.left);
// 		tops.push(t.view.top);
// 	});
// 	var maxX = Math.max.apply(Math, lefts) + pullTicketWidth;
// 	var minX = Math.min.apply(Math, lefts);
// 	var maxY = Math.max.apply(Math, tops) + pullTicketHeight;
// 	var minY = Math.min.apply(Math, tops);
// 
// 	return new Rectangle(minX, minY, maxX - minX, maxY - minY);
// }
export function calculateStartRect(tickets:ListLike<Ticket>){
	var rect;
	var lefts = [];
	var rights = [];
	var tops = [];
	var bottoms = [];
	tickets.forEach((t)=>{
		// lefts.push(t.view.left);
		// rights.push(t.view.left + t.view.width);
		// tops.push(t.view.top);
		// bottoms.push(t.view.top + t.view.height);
		lefts.push(t.view.dragStartData.x);
		rights.push(t.view.dragStartData.x + t.view.dragStartData.width);
		tops.push(t.view.dragStartData.y);
		bottoms.push(t.view.dragStartData.y + t.view.dragStartData.height);
	});
	var maxX = Math.max.apply(Math, rights);
	var minX = Math.min.apply(Math, lefts);
	var maxY = Math.max.apply(Math, bottoms);
	var minY = Math.min.apply(Math, tops);

	return new Rectangle(minX, minY, maxX - minX, maxY - minY);
}
