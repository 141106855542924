import { TicketContainer } from "./";
/**
 * Provides rudimentary mapping functionality
 */
export class DataContainerIndex{
	/**
	 * Caches the DataContainers
	 */
	private _cache: Map<string, TicketContainer> = new Map();
	constructor(){}
	/**
	 * When invoked, maps the passed identifier to the passed in DataContainer
	 * @param key {String} A unique identifier which identifies the cached DataContainer
	 * @param dataContainer DataContainer Instance of DataContainer that is being cached.
	 */
	public cache(key: string, dataContainer: TicketContainer){
		this._cache.set(key, dataContainer);
		return this;
	}
	/**
	 * When invoked, this will return a DataContainer if a mapping exists. Returns null otherwise.
	 * @param key {String} The unique identifier which points to the DataContainer
	 * @returns {DataContainer|null}
	 */
	public get(key: string){
		return this._cache.get(key);
	}
	public getCache(){
		return this._cache
	}
	public remove(key: string){
		return this._cache.delete(key);
	}
	public destroy(){
		this._cache.forEach(thing =>{ thing.destroy(); });
		this._cache.clear();
		this._cache = null;
	}
}
