
const noErrors = "no errors this session";


export class SessionErrorTracking{
	private sessionStartedTimestamp = Date.now();
	private errorList:Array<string> = [];
	
	public pushError(errorMsg){
		this.errorList.push(errorMsg);
	}
	
	public firstError(){
		return this.errorList.length ? this.errorList[0] : noErrors;
	}
	public lastErrors(length = 5){
		if(!this.errorList.length){ return noErrors;}
		if(length > this.errorList.length){ length = this.errorList.length; }
		let start = this.errorList.length - length;
		if(start < 0){ start = 0; }
		return this.errorList.slice(start);
	}
	public get count(){
		return this.errorList.length;
	}
	public get sessionLengthS(){
		return Math.floor((Date.now() - this.sessionStartedTimestamp) / 1000);
	}
}

export const sessionErrorTracking = new SessionErrorTracking();
window.sessionErrorTracking = sessionErrorTracking;
