export class LinkMap<T = string, idx1 = string, idx2 = string>{
	private internalMap: Map<idx1, Map<idx2, T>> = new Map();
	public has(string1: idx1, string2: idx2){
		return this.internalMap.has(string1) && this.internalMap.get(string1).has(string2);
	}
	public get isEmpty(){
		return this.internalMap.size === 0;
	}
	public get(string1: idx1, string2: idx2){
		return this.internalMap.has(string1) ? this.internalMap.get(string1).get(string2) : undefined;
	}
	public set(string1: idx1, string2: idx2, optionalData?:T){
		let subMap;
		let data = optionalData !== undefined ? optionalData : string2;
		if(!this.internalMap.has(string1)){subMap = new Map(); this.internalMap.set(string1, subMap); }
		else{ subMap = this.internalMap.get(string1); }
		//don't check if the value already exists, let the code using this class use has first, leave this dumb
		subMap.set(string2, data);
		return this;
	}
	public delete(string1: idx1, string2: idx2){
		if(!this.internalMap.has(string1)){return false;}
		return this.internalMap.get(string1).delete(string2);
	}
	public forEachy(cb: (key1: idx1|idx2, key2: idx2|idx1, optionalData?:T)=>void, reverse = false){
		this.internalMap.forEach((subMap, mainKey)=>{
			subMap.forEach((sub, subKey)=>{
				if(reverse){ cb(subKey, mainKey, sub); }
				else{ cb(mainKey, subKey, sub); }
			})
		});
	}
}
