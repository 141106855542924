'use strict';
import {Observable, Subject, interval} from "rxjs";
import { share, publish, multicast, audit, observeOn } from "rxjs/operators"

import { ticker } from "pixi.js";
import * as utils from "utils";

//TODO - add a built in frame limiter, execute half as frequently for example by only emitter every other frame

const unZoneInterval = window.__zone_symbol__setInterval;

class RenderQueue{
	private dedupCollection = {};
	private fullCollection = [];
	private specialBoy = {};
	private privileged = {"draw": "draw"};
	
	private destroySubject = new Subject();
	private ticker = new ticker.Ticker();
	private sharedTicker = ticker.shared;
	
	public isRunning = false;
	private shouldRun = false;
	
	/** an observable that emits when the ticker ticks. You *need* to unsubscribe from this one
	Sample uses:
		`audit(ev => renderQueue.ob$)` - emit the most recent value of the source, allows checking the event
		`sample(renderQueue.ob$),`  - emit the most recent value of the source
	*/
	public ob$: Observable<number>;
	public fakeFrame$: Observable<boolean>;
	
	constructor(){
		this.ticker.autoStart = true;
		this.ob$ = new Observable((ob) =>{
			let listener = (deltaTime)=>{
				// this.isRunning = true;
				// console.log('diff', performance.now() - window.renderTimestamp); 
				// window.renderTimestamp = performance.now();
				ob.next(deltaTime);
				
				// console.timeEnd("frame ended");
				// this.isRunning = false;
			}
			ticker.shared.add(listener);
			// let id = unZoneInterval(listener, 16);
			
			this.destroySubject.subscribe(()=>{
				// window.__zone_symbol__clearInterval(id);
				ticker.shared.remove(listener);
				ob.complete();
			})
		}).pipe(publish<number>() );
		(this.ob$ as any).connect();
		
		this.fakeFrame$ = new Observable((sub)=>{
			setInterval(()=>{
				sub.next(true);
			}, 16);
		}).pipe(share<boolean>())
			

		
	}
	
	private addTicker() {
		this.ticker.addOnce((deltaTime)=>{
			// window.renderTimestamp = performance.now();
			// console.log('renderQueue execute delay', performance.now() - window.renderTimestamp); 
			this.execute();
		})
		
	}
	
	public push(fn, key?){
		if(this.isRunning){
			// console.log('execute immediately', key);
			if(key !== undefined && this.dedupCollection[key]){ delete this.dedupCollection[key]; }
			if(key !== undefined && this.specialBoy[key]){ delete this.specialBoy[key]; }
			fn();
			return;
		}
		
		if(key !== undefined){
			if(this.privileged[key]){ this.specialBoy[key] = fn; }
			else{ this.dedupCollection[key] = fn; }
		}
		else{
			this.fullCollection.push(fn);
		}
		if(!this.shouldRun){ this.addTicker(); }
		this.shouldRun = true;
	}
	
	public requestAnimationFramePromise():Promise<boolean>{
		return new Promise((resolve)=>{
			window.requestAnimationFrame(()=>{ resolve(true); })
		});
	}
	
	private execute(){
		this.isRunning = true;
		var any = false;
		// console.log('standard execution', this.dedupCollection, this.fullCollection.length);
		for (var key in this.dedupCollection){
			any = true;
			this.dedupCollection[key]();
		}
		if(any){this.dedupCollection = {};}
		
		if(!!this.fullCollection.length){
			for(var i = 0; i < this.fullCollection.length; i++){ this.fullCollection[i](); }
			utils.fastSplice(this.fullCollection, 0, this.fullCollection.length);
		}
		
		any = false;
		for (var key in this.specialBoy){
			any = true;
			this.specialBoy[key]();
		}
		if(any){this.specialBoy = {};}
		
		this.shouldRun = false;
		this.ticker.stop();
		this.isRunning = false;
	}
}
export const renderQueue = new RenderQueue();
window.renderQueue = renderQueue;
