import{ Component, ViewChild, ElementRef, AfterViewInit, Renderer, OnDestroy } from "@angular/core"
import{ PlanState, StateEvents } from "ng2/common/services/plan-state.service"
import { TemplateRef } from '@angular/core';

import {Subject} from "rxjs";
import { takeUntil } from "rxjs/operators";


import { AccessCheck } from "ng2/common/ajs-upgrade-providers";

import { Ticket } from "ng2/common/models";

@Component({
		selector:'multi-select-menu',
		template: require('ng2/multi-select-menu.component.html'),
})

export class MultiSelectMenuComponent implements OnDestroy {
	@ViewChild('multiSelectWrapper') multiSelectWrapper:ElementRef;
	
	private shutdownSubject = new Subject();

	shouldShow = false;
	public tickets: Map<string, Ticket>;
	
	public duplicate(){
		let oldTickets = this.planState.legacyActions.getOldTicketsFromNew(this.tickets);
		this.planState.scopeSoup.ticketOps.duplicateTickets(oldTickets);
	}
	public delete(){
		let oldTickets = this.planState.legacyActions.getOldTicketsFromNew(this.tickets);
		this.planState.scopeSoup.ticketOps.deleteTickets(oldTickets);
	}
	public editAll(){
		let oldTickets = this.planState.legacyActions.getOldTicketsFromNew(this.tickets);
		this.planState.scopeSoup.ticketEditService.editAll(this.planState.scopeSoup, oldTickets, {replace:true})
	}
	public editCycle(){
		let oldTickets = this.planState.legacyActions.getOldTicketsFromNew(this.tickets);
		this.planState.scopeSoup.ticketEditService.editCycle(this.planState.scopeSoup, oldTickets, {replace:true})
	}
	public alignColumns(){
		let oldTickets = this.planState.legacyActions.getOldTicketsFromNew(this.tickets);
		this.planState.scopeSoup.alignColumns(oldTickets);
	}
	public alignRows(){
		let oldTickets = this.planState.legacyActions.getOldTicketsFromNew(this.tickets);
		this.planState.scopeSoup.alignRows(oldTickets);
	}

	constructor (private planState:PlanState, private renderer:Renderer, private accessCheck: AccessCheck){
		this.planState.stateChange$.pipe(takeUntil(this.shutdownSubject)).subscribe((x)=>{
			if(x > 0){
				this.planState.tickets.selectedTicket$.pipe(takeUntil(this.shutdownSubject)).subscribe((select) => {
					this.tickets = select.list;
					if(select.list.size > 1){
						// console.log(this.multiSelectWrapper.nativeElement.innerHTML);
						this.shouldShow = true;
						// Don't use this
						// this.renderer.setElementStyle(
						//	 this.multiSelectWrapper.nativeElement,
						//	 'display',
						//	 ''
						// );
					}else{

						this.shouldShow = false;
						// Don't use this
						// this.renderer.setElementStyle(
						//	 this.multiSelectWrapper.nativeElement,
						//	 'display',
						//	 'none'
						// );
					}
				})
			}
		})
	}
	
	ngOnDestroy(){
		this.shutdownSubject.next(true);
	}
}
