import { TWEEN_LOOP, Easing, TweenSettings, TweenKeys } from "./";
import { Timer } from "../utils/";

/**
 * Interface to define valid properties that can be Tweened.
 */
export interface TweenKeys{
	[key: string]: any;
}

export interface TweenSettings{
	delay?: number;
	easing?: (k: number) => void;
	loop?: number;
	loopCount?: number;
	onComplete?: () => void;
	onUpdate?: () => void;
}

function NOOP(){}

export class Tween{
	public duration: number = 0;
	public complete: boolean = false;
	public paused: boolean = false;
	public easing: any;
	public onComplete: () => void = NOOP;
	public onUpdate: () => void = NOOP;
	public delay: number = 0;
	public loop: number|boolean = 0;
	public loopCount: number = 0;
	public loopNum: number = -1;
	public started: boolean = false;
	public destroyed: boolean = false;
	private _object: TweenKeys = {};
	private _originalObject: TweenKeys;
	private _originalOnComplete: () => void;
	private _originalOnUpdate: () => void;
	private _valuesStart: TweenKeys = {};
	private _valuesEnd: TweenKeys = {};
	private _valuesDelta: TweenKeys = {};
	private _elapsed: number = 0;
	private _timer: Timer = null;
	private _started: boolean = false;
	private _props: TweenKeys = {};
	private _chained: Tween;
	constructor(object: TweenKeys, properties: TweenKeys, duration: number, settings?: TweenSettings){
		if (!settings){settings = {};}
		this._object = object;
		this._originalObject = object;
		this.duration = duration;
		this.easing = settings.easing || Easing.LINEAR;
		this.onComplete = settings.onComplete || NOOP;
		this.onUpdate = settings.onUpdate || NOOP;
		this._originalOnComplete = this.onComplete;
		this._originalOnUpdate = this.onUpdate;
		this.delay = settings.delay || 0;
		this.loop = settings.loop || 0;
		this.loopCount = settings.loopCount || 0;
		this.loopNum = this.loopCount;
		this._props = properties;
		
		this._timer = new Timer();
	}
	
	public reset(){
		this._object = this._originalObject;
		this._props = this._valuesEnd;
		this._elapsed = 0;
		this.complete = false;
		this.onComplete = this._originalOnComplete;
		this.onUpdate = this._originalOnUpdate;
	}
	
	public chain(chainObj: any){
		this._chained = chainObj;
	}
	
	public destroy(){
		this._object = null;
		this.onComplete = null;
		this.onUpdate = null;
		this._props = null;
		this._chained = null;
		this.complete = true;
	}
	
	public start(){
		this.complete = false;
		this.paused = false;
		this.loopNum = this.loopCount;
		this.started = true;
		this._elapsed = 0;
		this._started = true;
		
		this._timer.reset();
		
		for (let prop in this._props){
			this.initEnd(prop, this._props, this._valuesEnd);
		}
		for (let prop in this._valuesEnd){
			this.initStart(prop, this._valuesEnd, this._object, this._valuesStart);
			this.initDelta(prop, this._valuesDelta, this._object, this._valuesEnd);
		}
	}
	
	public update(){
		if (!this._started){return false;}
		if (this.delay) {
			if (this._timer.delta() < this.delay){return;}
			this.delay = 0;
			this._timer.reset();
		}
		if (this.paused || this.complete){return false;}
		
		let elapsed = (this._timer.delta() + this._elapsed) / this.duration;
		elapsed  = elapsed  > 1 ? 1 : elapsed ;
		let value = this.easing(elapsed);
		
		for (let property in this._valuesDelta) {
			this.propUpdate(property, this._object, this._valuesStart, this._valuesDelta, value);
		}
		if (elapsed >= 1) {
			if (this.loopNum === 0 || typeof this.loop === "undefined") {
				this.complete = true;
				if (this.onComplete){this.onComplete();}
				if (this._chained){
					this._chained.start();
				}
				return false;
			} else if (this.loop === TWEEN_LOOP.REVERT) {
				for (let property in this._valuesStart ) {
					this.propSet(property, this._valuesStart, this._object);
				}
				this._elapsed  = 0;
				this._timer.reset();
				if (this.loopNum !== -1){this.loopNum--;}
			} else if (this.loop === TWEEN_LOOP.REVERSE) {
				let _start = {}, _end = {};
				Object.assign(_start, this._valuesEnd);
				Object.assign(_end, this._valuesStart);
				Object.assign(this._valuesStart, _start);
				Object.assign(this._valuesEnd, _end);
				for (let property in this._valuesEnd ) {
					this.initDelta(property, this._valuesDelta, this._object, this._valuesEnd);
				}
				this._elapsed = 0;
				this._timer.reset();
				if (this.loopNum !== -1){this.loopNum--;}
			}
		}
		
		if (!this.complete && this.onUpdate){
			this.onUpdate();
		}
	}
	
	public pause(){
		this.paused = true;
		this._elapsed += this._timer.delta();
	}
	
	public resume(){
		this.paused = false;
		this._timer.reset();
	}
	
	public stop(doComplete: boolean = false){
		if (doComplete){
			this.paused = false;
			this.complete = false;
			this.loop = false;
			this._elapsed += this.duration;
			this.update();
		}
		this.complete = true;
	}
	
	private propSet(prop: string, from: TweenKeys, to: TweenKeys){
		if (typeof (from[prop]) !== "object"){
			to[prop] = from[prop];
		}else{
			for (let sub in from[prop]){
				if (!to[prop]){to[prop] = {};}
				this.propSet(sub, from[prop], to[prop]);
			}
		}
	}
	
	private propUpdate(prop: string, obj: TweenKeys, start: TweenKeys, delta: TweenKeys, value: number){
		if (typeof(start[prop]) !== "object") {
			if ( typeof start[prop] != "undefined" ) {
				obj[prop] = start[prop] + delta[prop] * value;
			} else {
				obj[prop] = obj[prop];
			}
		} else {
			for (let subprop in start[prop]) {
				this.propUpdate(subprop, obj[prop], start[prop], delta[prop], value);
			}
		}
	}
	
	private initDelta(prop: string, delta: TweenKeys, start: TweenKeys, end: TweenKeys){
		if (typeof(end[prop]) !== "object") {
			delta[prop] = end[prop] - start[prop];
		} else {
			for (let subprop in end[prop]) {
				if (!delta[prop]){delta[prop] = {};}
				this.initDelta(subprop, delta[prop], start[prop], end[prop]);
			}
		}
	}
	
	private initEnd(prop: string, from: TweenKeys, to: TweenKeys){
		if (typeof(from[prop]) !== "object"){
			to[prop] = from[prop]
		} else {
			for (let sub in from[prop]){
				if (!to[prop]){to[prop] = {};}
				this.initEnd(sub, from[prop], to[prop]);
			}
		}
	}
	
	private initStart(prop: string, end: TweenKeys, from: TweenKeys, to: TweenKeys){
		if (typeof (from[prop]) !== "object"){
			if ( typeof(end[prop]) !== "undefined" ) to[prop] = from[prop];
		} else{
			for (let sub in from[prop]){
				if (!to[prop]){to[prop] = {};}
				if (typeof (end[prop]) !== "undefined"){
					this.initStart(sub, end[prop], from[prop], to[prop]);
				}
			}
		}
	}
}
