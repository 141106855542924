import * as angular from 'angular';
import Point from "js/lib/math/Point";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

import {PlanState} from "ng2/common/services/plan-state.service";
import { Ticket } from "ng2/common/models/Ticket";

interface ITicketSearch{
	text: string,
	search: () => void;
	clear: () => void;
	onChange: () => void;
}

class TicketSearchController implements ITicketSearch{
	/** MEMBERS **/
	public text: string = "";
	public results: Array<Ticket> = [];
	public betterResults: Map<string, string> = new Map();
	public api: any;
	public $ctrl: any;
	protected tickets:any;
	protected hud:any;
	private pos: number = 0;
	private show: boolean = false;
	private smallExpanded: boolean = false;
	private planState: PlanState;
	/** METHODS **/
	constructor(){}
	public search(){
		this.clear();
		this.pos = 0;
		if (!this.text || !this.tickets){this.unselectAllBetter(); return;}
		this.tickets.forEach((t: any) => {
			if (!t.data.activityName){return;}
			let lowered = t.data.activityName.toString().toLowerCase();
			//matched
			if(lowered.indexOf(this.text.toString().toLowerCase()) !== -1){
				//console.log('match hit', t);
				this.results.push(t);
				t.local.searchResult = true;
				
				if(!this.betterResults.has(t.$id)){
					this.betterResults.set(t.$id, t.$id);
					//emit
					let ticket = this.planState.tickets._internalList.get(t.$id);
					if(ticket){ this.planState.actions.addTicketToSearch(ticket); }
				}
			}
			else{
				if(this.betterResults.has(t.$id)){
					this.betterResults.delete(t.$id);
					//emit
					let ticket = this.planState.tickets._internalList.get(t.$id);
					if(ticket){ this.planState.actions.removeTicketFromSearch(ticket); }
				}
			}
		});
		
		//nuke deleted ticketd from cache
		this.betterResults.forEach(tId =>{
			if(!this.tickets.$getRecord(tId)){ this.betterResults.delete(tId); }
		});
		
		this.planState.legacySearch.in$.next(this.results);
	}
	public unselectAllBetter(){
		// debugger;
		this.betterResults.forEach(tId =>{
			this.betterResults.delete(tId);
			let ticket = this.planState.tickets._internalList.get(tId);
			if(ticket){ this.planState.actions.removeTicketFromSearch(ticket); }
		});
	}
	
	public clear(){
		this.results.forEach((t: any) => {
			delete t.local.searchResult;
		});
		this.results = [];
	}
	public toggleShow(){
		console.log('hi');
		if (!this.show){
			this.clear();
			this.text = '';
		}
		this.show = !this.show;
	}
	public next(){
		if(this.pos+1 < this.results.length){ this.pos++; }
		else{ this.pos = 0; }
	}
	public prev(){
		if(this.pos-1 >= 0){ this.pos--; }
		else{ this.pos = this.results.length - 1; }
	}
	public focus(){
		if(this.results[this.pos]){
			this.hud.focusTicketAt(this.results[this.pos], new Point(window.innerWidth/2, window.innerHeight/2));
		}
	}
	public onChange(){
		this.search();
	}
	public toggleSmallExpandMenu(){
		this.smallExpanded = !this.smallExpanded;
		if(this.smallExpanded) { analyticsService.searchBarOpened(true); }
	}
	public close(){
		this.clear();
		this.unselectAllBetter();
		this.text = '';
		this.smallExpanded = false;
	}
	
	$onInit(){
		this.api = this;
		// MOC-3158
		this.planState.scopeSoup.searchApi = this;
	}
	
	$onDestroy(){
		this.api = null;
		this.results = null;
		this.betterResults = null; 
		this.tickets = null;
		this.planState = null;
	}
}

angular.module('ticketspaceApp').component("ticketSearch", {
	bindings:{
		tickets: "<",
		hud: "<",
		api: "=",
		planState: "<"
	},
	template: require('tpl/components/ticket-search.html'),
	controller: TicketSearchController,
	controllerAs: "$ctrl",
});

//-------------------------------------------------
// Upgrade Boilerplate
//-------------------------------------------------

import { Directive, ElementRef, Injector, ChangeDetectionStrategy, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'ticket-search'
})
export class TicketSearchComponent extends UpgradeComponent {
	@Input() api: any;
	@Input() hud: any;
	@Input() tickets: any;
	@Input() planState: PlanState;
	constructor(elementRef: ElementRef, injector: Injector) {
		super('ticketSearch', elementRef, injector);
		window.ticketSearch = this;
	}
}