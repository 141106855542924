import { FancyContainer, FancySprite } from "../graphics/";
import { SwimlaneLabel, SwimlaneArea, SwimlaneLine, SWIMLANE_LABEL_SETTINGS, SWIMLANE_GRAPHICS, SWIMLANE_SPOT } from "./";
import { PlanState } from "../../common/services/plan-state.service";
import { SwimlaneRenderTypes, Swimlane } from "../../common/models/Swimlane";
import { utils, extras } from "pixi.js";
export class SwimlaneContainer extends FancyContainer {
	public visible: boolean = true;
	public label: SwimlaneLabel;
	public area: SwimlaneArea;
	public topLine: SwimlaneLine;
	public bottomLine: SwimlaneLine;
	public lineContainer: FancyContainer;
	public dragCircleTop: FancySprite;
	public dragCircleBottom: FancySprite;
	private data: Swimlane;
	
	constructor(swimlaneModel: Swimlane, private planState: PlanState){
		super();
		
		this.label = new SwimlaneLabel(swimlaneModel, planState);
		this.area = new SwimlaneArea(swimlaneModel, planState);
		this.topLine = new SwimlaneLine(swimlaneModel, planState, SWIMLANE_SPOT.TOP);
		this.bottomLine = new SwimlaneLine(swimlaneModel, planState, SWIMLANE_SPOT.BOTTOM);
		this.dragCircleTop = new FancySprite(utils.TextureCache[SWIMLANE_GRAPHICS.TEXTURE_SWIMLANE_DRAG_HANDLE]);
		this.dragCircleBottom = new FancySprite(utils.TextureCache[SWIMLANE_GRAPHICS.TEXTURE_SWIMLANE_DRAG_HANDLE]);
		
		this.dragCircleTop.width = 30;
		this.dragCircleTop.height = 15;
		this.dragCircleBottom.width = 30;
		this.dragCircleBottom.height = 15;
		
		this.lineContainer = new FancyContainer();
		
		this.lineContainer.interactiveChildren = true;
		
		this.lineContainer.addChild(this.topLine, this.bottomLine, this.dragCircleTop, this.dragCircleBottom);
		
		// The X is always 84 (width of the sidebar)
		// MOC-3022. Print has a gap on left side when doing this. So change to 0.
		this.x = 0;
		
		// Add our children to the container
		this.addChild(this.area);
	}
	/**
	 * Although the SwimlaneLabel lives in the SwimlaneContainer, we add it to the UI layer separately instead of as a child
	 * of the SwimlaneContainer. This is because scaling of the SwimlaneArea will also distort the children, and create blurred text
	 *
	 * Returns the SwimlaneLabel assigned to this swimlane
	 */
	public getLabel(): SwimlaneLabel {
		return this.label
	}
	
	public getArea(): SwimlaneArea {
		return this.area;
	}
	
	public update(globalWidth: number, swimlaneModel: Swimlane): void {
		this.data = swimlaneModel;
		
		// Get current mode
		const mode: string = (swimlaneModel.currentRenderType === SwimlaneRenderTypes.edit) ? SwimlaneRenderTypes.edit : "view";
		
		// Set swimlane Y
		this.y = swimlaneModel[mode].screenTop;
		
		// Set SwimlaneLine dimensions
		this.topLine.y = swimlaneModel[mode].screenTop;
		this.bottomLine.y = swimlaneModel[mode].screenBottom;
		this.topLine.width = globalWidth;
		this.topLine.height = 1;
		this.bottomLine.width = globalWidth;
		this.bottomLine.height = 1;
		this.topLine.setColor(swimlaneModel[mode].color);
		this.bottomLine.setColor(swimlaneModel[mode].color);
		
		// Set swimlane area dimensions
		this.area.width = globalWidth;
		this.area.height = swimlaneModel[mode].screenBottom - swimlaneModel[mode].screenTop;
		
		// Set swimlane color if changed
		this.area.setColor(swimlaneModel[mode].color);
		
		// Make interactive
		if (mode === SwimlaneRenderTypes.edit){
			this.area.interactive = true;
			this.area.cursor = "pointer";
			
			this.label.visible = false;
			this.label.background.visible = false;
			
			this.topLine.setEdit(globalWidth,true);
			this.bottomLine.setEdit(globalWidth,true);
			// Position our lines
			this.dragCircleTop.visible = true;
			this.dragCircleTop.x = (globalWidth / 2) - (this.dragCircleTop.width / 2);
			this.dragCircleTop.y = this.topLine.y - this.dragCircleTop.height / 2;
			this.dragCircleBottom.visible = true;
			this.dragCircleBottom.x = (globalWidth / 2) - (this.dragCircleBottom.width / 2);
			this.dragCircleBottom.y = this.bottomLine.y - this.dragCircleBottom.height / 2;
		} else if (swimlaneModel.currentRenderType === SwimlaneRenderTypes.database) {
			this.area.interactive = false;
			this.area.cursor = null;
			
			this.label.visible = true;
			this.label.background.visible = true;
			
			this.topLine.setInteractive(false);
			this.bottomLine.setInteractive(false);
			
			this.dragCircleTop.visible = false;
			this.dragCircleBottom.visible = false;
		}
		
		// Update the label
		this.label.update(swimlaneModel);
	}
	
	public destroy(): void {
		super.destroy();
		this.label.destroy();
		this.area.destroy();
		this.topLine.destroy();
		this.bottomLine.destroy();
		this.dragCircleTop.destroy();
		this.dragCircleBottom.destroy();
		this.topLine = null;
		this.bottomLine = null;
		this.dragCircleBottom = null;
		this.dragCircleTop = null;
		
		this.label = null;
		this.area = null;
		this.data = null;
	}
}