import { BehaviorSubject, merge, fromEvent } from "rxjs";
import { scan, filter } from "rxjs/operators";

export class KeyModifer{
	public key$ = new BehaviorSubject(new Map());
	public syncKeys = new Map();
	
	public get isAppleSafeCtrl(){
		return this.syncKeys.has("Control") || this.syncKeys.has("Meta");
	}
	
	constructor(private baseElement?){
		if(!baseElement){ this.baseElement = document.querySelector('html'); }
		merge(
			fromEvent<KeyboardEvent>(this.baseElement, "keydown"),
			fromEvent<KeyboardEvent>(this.baseElement, "keyup"),
		).pipe(
			filter( event => !event.repeat),
			scan((acc:Map<string, boolean>, event:KeyboardEvent)=>{
				// console.log('event', event);
				if(event.type === "keydown"){ acc.set(event.key, true); }
				if(event.type === "keyup"){ acc.delete(event.key); }
				return acc;
			}, new Map<string, boolean>())
		)
		.subscribe(keys => {
			this.syncKeys = keys;
			this.key$.next(keys);
		});
	}
}


export const keyModifier = new KeyModifer();
