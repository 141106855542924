//just types
// import { Ticket } from "ng2/common/models/Ticket";
import { FancyAction, CombinedFancyActions, FancyActionTypes } from "ng2/fancy-firebase/base";
import { Camera } from "ng2/common/models/CanvasConnector";

import { Plan } from "ng2/common/models/Plan";

export interface SessionSettings{
	hideCompletedTickets?: boolean
}

//types/ interfaces
export type CombinedSupplementalActions =
	// | CombinedFancyActions<Ticket>
	| PlanUpdatedAction
	| MilestoneUpdatedAction
	| SessionSettingsAction
	| CameraChangedAction

export interface PlanUpdatedAction extends FancySupplementalAction{
	type: SupplementalActionTypes.planUpdated,
	payload: Plan//Plan
}
export interface MilestoneUpdatedAction extends FancySupplementalAction{
	type: SupplementalActionTypes.planUpdated,
	payload: {x: number, date: string}//Plan
}
export interface SessionSettingsAction extends FancySupplementalAction{
	type: SupplementalActionTypes.sessionSettingsChanged,
	payload: SessionSettings
}
export interface CameraChangedAction extends FancySupplementalAction{
	type: SupplementalActionTypes.cameraChanged,
	payload: Camera
}

/**
 * The core data structure to be passed to an observer. 
 * @param payload Generally expected to be a ticket or an object representing a ticket.
 * Each action type will expect something different and specific.
 * @param type    The type of action. TODO - look into doing this class based instead of enum
 * @param key     Optional if the payload is a Ticket, otherwise required.
 */

export class FancySupplementalAction<T = any>{
	constructor(
		public payload: T,//Plan
		public type: string,
		public key?: string //won't always need a key
	){}
	
}

// type definition enum
export enum SupplementalActionTypes{
	planUpdated = "plan_updated",
	milestoneUpdated = "milestone_updated",
	varianceListUpdated = "variance_list_updated",
	sessionSettingsChanged = "session_settings_changed",
	cameraChanged = "camera_changed"
}
