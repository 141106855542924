'use strict';

import * as angular from "angular";
import * as moment from "moment";
import {ImprovedText} from "../renderer/text";

import { dateCache } from "ng2/common/date-cache";

angular.module('ProjectView').factory("PlanBar", ProjectViewPlanBarFactory);

ProjectViewPlanBarFactory.$inject = ["PIXI", "GeometricShape", "TicketStatus", "RenderLine", "LineOptions", "projectViewUtils", "projectViewConfig", "InteractionManager"];
function ProjectViewPlanBarFactory(PIXI, GeometricShape, TicketStatus, RenderLine, LineOptions, projectViewUtils, projectViewConfig, InteractionManager){
	/**
	 * @namespace PlanBar
	 * @param options {Object} Options data which is generated by {ProjectView.ProjectViewCore}
	 * @param idx {Number} The index in the ProejctViewCore list
	 * @param cbOnSelect {Function} The function which is called when a click is registered.
	 * @param goToPlanCallback {Function} The function which is called when 'goToPlan' operation is performed
	 * @memberOf ProjectView
	 * @constructor
	 */
	function PlanBar(options, idx, cbOnSelect, goToPlanCallback){
		// Create the parent container for text and the waveform graph and then set the position
		if (options.scale){options.width *= options.scale;}
		/**
		 * @name container
		 * @desc The parent {PIXI.Container} which will contain all child display nodes.
		 * @memberOf ProjectView.PlanBar
		 */
		this.container = new PIXI.Container();
		this.container.x = options.x;
		this.container.y = options.y -2;
		/**
		 * @name overlayContainer
		 * @desc Parent {PIXI.Container} node which contains overlaid display graphics
		 * @memberOf ProjectView.PlanBar
		 */
		this.overlayContainer = new PIXI.Container();
		this.overlayContainer.x = options.x;
		this.overlayContainer.y = options.y;
		/**
		 * @name _dirty
		 * @desc Force updates during the new tick if true.
		 * @memberOf ProjectView.PlanBar
		 * @type {boolean}
		 * @private
		 */
		this._dirty = true;
		/**
		 * @name isSelected
		 * @desc Controls if this bar is selected/highlighted
		 * @memberOf ProjectView.PlanBar
		 * @type {boolean}
		 */
		this.isSelected = false;
		/**
		 * @name id
		 * @desc The ID of this bar, as found in the ProjectViewCore list
		 * @memberOf ProjectView.PlanBar
		 * @type {Number}
		 */
		this.id = idx;
		/**
		 * @name selectCallback
		 * @desc The function which is called when this bar is selected
		 * @memberOf ProjectView.PlanBar
		 * @type {Function}
		 */
		this.selectCallback = cbOnSelect;
		/**
		 * @name gToPlanCallback
		 * @desc The function which is called when we jump to a new plan
		 * @memberOf ProjectView.PlanBar
		 * @type {Function}
		 */
		this.goToPlanCallback = goToPlanCallback;

		/**
		 * @name heatmapBackground
		 * @desc Contains an instance of {CanvasWrapper.GeometricShape} for the plan bar background
		 * @memberOf ProjectView.PlanBar
		 */
		this.heatmapBackground = new GeometricShape();
		if (options.isEmpty){
			this.heatmapBackground.get().lineStyle(options.isEmptyOptions.NO_DATES_LINE_SIZE, parseInt(options.isEmptyOptions.NO_DATES_LINE_COLOR.substring(1), 16), options.isEmptyOptions.NO_DATES_LINE_ALPHA)
			this.heatmapBackground.drawRoundedRect(options.width, options.height, 4, options.isEmptyOptions.NO_DATES_FILL_COLOR, options.isEmptyOptions.NO_DATES_FILL_ALPHA);
		} else {this.heatmapBackground.drawRoundedRect(options.width, options.height, 4, options.heatbarColor, options.heatbarAlpha);}

		// Create the text after drawing because the draw calls sets the bounds for our render elements.
		/**
		 * @name textRight
		 * @desc PIXI.Text and contains the plan name and plan status text
		 * @memberOf ProjectView.PlanBar
		 */
		this.textRight = new ImprovedText(options.textOptions.text, options.textOptions);
		this.textRight.x = this.heatmapBackground.get().width + 12; // Set base X coordinate for the text

		// Create the selection
		/**
		 * @name select
		 * @desc A GeometricShape object which is used to draw the selected graphic when selected
		 * @memberOf ProjectView.PlanBar
		 */
		this.select = new GeometricShape({pos:{x:this.heatmapBackground.get().x, y:this.heatmapBackground.get().y}});
		// Draw our select layer, cache it, and then hide the container
		this.select.get().lineStyle(6, parseInt("#4ca9d2".substring(1), 16), 1);
		this.select.drawRoundedRect(this.heatmapBackground.get().width, this.heatmapBackground.get().height, 4);
		this.select.get().visible = false;

		this.container.addChild(this.select.get());
		this.container.addChild(this.heatmapBackground.get());
		this.container.addChild(this.textRight);
		// Create the heatmap
		/**
		 * @name _renderTypeIdx
		 * @desc Used to control the index of this display node in the child renderType.
		 * @memberOf ProjectView.PlanBar
		 * @type {number}
		 * @private
		 */
		this._renderTypeIdx = 3;
		/**
		 * @name renderType
		 * @desc Contains the current class bound to this bar. By default it is {ProjectView.RenderTypes.TicketStatus}
		 * @memberOf ProjectView.PlanBar
		 */
		this.renderType = new TicketStatus(options.stage, this.container, this.heatmapBackground.get(), this._renderTypeIdx);
		// Draw the render type
		this.refresh(options);

		// Create the active line
		/**
		 * @name activeLine
		 * @desc Contains a new instance of {CanvasWrapper.Line}. used to draw the active line over the plan bar
		 * @memberOf ProjectView.PlanBar
		 */
		this.activeLine = new RenderLine(LineOptions(options.activeLineOptions));
		this.activeLine.setVisible(false);
		this.container.addChild(this.activeLine.get());
		this.updateActiveLine(options);

		// Create text
		/**
		 * @name daysBehindText
		 * @desc Contains an instance of PIXI.Text and is used to draw the plan status text
		 * @memberOf ProjectView.PlanBar
		 */
		this.daysBehindText = new ImprovedText("", {
			fontFamily: 'Roboto',
			fontSize: 12,
			fill: 0x8A8A8A,
			fontWeight: '400',
			resolution: 4
		});
		this.aheadOrBehindText = new ImprovedText("", {
			fontFamily: 'Roboto',
			fontSize: 12,
			fill: 0x8A8A8A,
			fontWeight: '400',
			resolution: 4
		});
		/**
		 * @name currentPlanDate
		 * @desc Stores a PIXI.Text which contains the current plan date
		 * @memberOf ProjectView.PlanBar}
		 */
		this.currentPlanDate = new ImprovedText("", projectViewConfig.CURRENT_PLAN_DATE_TEXT);

		// Perform first update of text
		this.updateScheduleText(options);
		this.daysBehindText.addChild(this.aheadOrBehindText);
		this.currentPlanDate.addChild(this.daysBehindText);
		this.textRight.addChild(this.currentPlanDate);

		// Register input events
		this.registerInput();

		this.options = options;
		this._origOptions = jQuery.extend(true, {}, options);
		this._fontSize = options.textOptions.fontSize || "14px";
	};
	/**
	 * @function calculateTextPositionOffset
	 * @desc Calculates the textRight draw position from the right edge of the plan bar. Used when a ticketStatus extends past the right edge
	 * @memberOf ProjectView.PlanBar
	 * @returns {number}
	 */
	PlanBar.prototype.calculateTextPositionOffset = function(){
		var draw = this.renderType.getDrawingCoordinates();
		var rightStatus = (draw.incompleteWidth > 0) ? (draw.parentX + draw.incompleteX) + draw.incompleteWidth : (draw.parentX + draw.completeX) + draw.completeWidth;
		var barStatus = draw.parentX + draw.backgroundWidth;
		if (rightStatus > barStatus){
			return rightStatus - barStatus;
		} else{
			return 0
		}
	};
	/**
	 * @function setNewRender
	 * @desc Updates the renderType to use a different data draw method
	 * @param options
	 * @param fn
	 * @memberOf ProjectView.PlanBar
	 * @returns {ProjectView.PlanBar}
	 */
	PlanBar.prototype.setNewRender = function(options, fn){
		this.removeRenderType(this._renderTypeIdx).setRenderType(options, fn);
		return this;
	};
	/**
	 * @function removeRenderType
	 * @desc Removes the renderType
	 * @param idx
	 * @returns {ProjectView.PlanBar}
	 * @memberOf ProjectView.PlanBar
	 * @private
	 */
	PlanBar.prototype.removeRenderType = function(idx){
		this.container.removeChildAt(idx);
		return this;
	};
	/**
	 * @function Sets a new render type
	 * @desc Set a new render type
	 * @param options
	 * @param fn
	 * @memberOf ProjectView.PlanBar
	 * @private
	 * @returns {ProjectView.PlanBar}
	 */
	PlanBar.prototype.setRenderType = function(options, fn){
		this.renderType = new fn(this.options.stage, this.container);
		return this;
	};
	/**
	 * @function getPlanName
	 * @desc Get the plan name of this plan bar
	 * @returns {*}
	 * @memberOf ProjectView.PlanBar
	 */
	PlanBar.prototype.getPlanName = function(){
		return this.options.waveform.planName;
	};
	/**
	 * @function updateBaseTextures
	 * @desc Regenerate all base textures used by the overview.
	 * @param width {number}
	 * @param height {number}
	 * @memberOf ProjectView.PlanBar
	 */
	PlanBar.prototype.updateBaseTextures = function(width, height){
		this.heatmapBackground.get().clear();
		this.heatmapBackground.drawRoundedRect(width, height, 4, this.options.heatbarColor, this.options.heatbarAlpha);
		this.heatmapBackground.get().width = width;
		this.textRight.x = this.heatmapBackground.get().width + 12;

		this.select.clear();
		this.select.get().lineStyle(6, parseInt("#4ca9d2".substring(1), 16), 1);
		this.select.drawRoundedRect(width, height, 4);
		this.select.get().x = this.heatmapBackground.get().x;
	};
	/**
	 * @function refresh
	 * @desc Draws the display data to the plan bar
	 * @param options {Object}
	 * @memberOf ProjectView.PlanBar
	 */
	PlanBar.prototype.refresh = function(options){
		this.renderType.draw(options.ticketStatusDates, options.scale);
		if (options.newWidth || options.newStartX){
			this.updateBaseTextures(options.newWidth, options.newHeight || this.options.height);
			this.container.x = options.newStartX || this.container.x;
		}
	};
	/**
	 * @function toggleVisible
	 * @desc Toggles if the render type is visible or not
	 * @memberOf ProjectView.PlanBar
	 * @param value
	 */
	PlanBar.prototype.toggleVisible = function(value){
		this.renderType.setVisible(value);
	};
	/**
	 * @function getSelect
	 * @desc Get the select member
	 * @memberOf ProjectView.PlanBar
	 */
	PlanBar.prototype.getSelect = function(){
		return this.select.get()
	};
	/**
	 * @function toggleActiveLineVisibility
	 * @desc Toggle the active line on/off
	 * @memberOf ProjectView.PlanBar
	 * @param value
	 */
	PlanBar.prototype.toggleActiveLineVisibility = function(value){
		if (this.activeLine){this.activeLine.setVisible(value);}
	};
	/**
	 * @function setDrawAheadVisibility
	 * @desc Set the draw ahead visibility
	 * @memberOf ProjectView.PlanBar
	 * @param value
	 */
	PlanBar.prototype.setDrawAheadVisibility = function(value){
		if (this.aheadOrBehindText){
			this.daysBehindText.visible = value;
			this.aheadOrBehindText.visible = value;
			this.currentPlanDate.visible = value;
			if (!value){
				this.textRight.y = this.daysBehindText.height /2 - projectViewConfig.PLAN_NAME_TEXT.DRAW_OFFSET;
			} else {
				this.textRight.y = this.daysBehindText.height /2 - projectViewConfig.PLAN_NAME_TEXT.DRAW_OFFSET_FULL;
			}
		}
	};
	/**
	 * @function getOverlay
	 * @desc get the overlayContainer member
	 * @memberOf ProjectView.PlanBar
	 * @returns {*}
	 */
	PlanBar.prototype.getOverlay = function(){
		return this.overlayContainer
	};
	/**
	 * @function markSelected
	 * @desc Mark this plan bar as selected
	 * @memberOf ProjectView.PlanBar
	 * @param val
	 */
	PlanBar.prototype.markSelected = function(val){
		this.isSelected = val;
		this.select.get().visible = this.isSelected;
	};
	/**
	 * @function
	 * @desc Toggle this plan bar as selected
	 * @memberOf ProjectView.PlanBar
	 */
	PlanBar.prototype.toggleSelected = function(){
		this.isSelected = !this.isSelected;
		this.select.get().visible = this.isSelected;
	};
	/**
	 * @private
	 */
	PlanBar.prototype.registerInput = function(){
		var self = this;
		this.container.interactive = true;
		this.container.buttonMode = true;

		InteractionManager.tap(this.container);
		this.container.on('simpletap', function(e){
			if (!self.isOpened){
				if (self.selectCallback){self.selectCallback(self.id);}
				else { self.toggleSelected(); }
			}
		});

		InteractionManager.doubleTap(this.container, 800);
		this.container.on('doubletap', function(){
			console.log("DOUBLE TAPPED", self.goToPlanCallback);
			if (!self.isOpened){
				if (self.goToPlanCallback){
					if (!self.isSelected){self.selectCallback(self.id);}
					self.goToPlanCallback()
				}
				else {self.toggleSelected();}
			}
		})
	};
	/**
	 * @function get
	 * @desc Get the PIXI.Graphics object
	 * @memberOf ProjectView.PlanBar
	 * @returns {*|PIXI.Container}
	 */
	PlanBar.prototype.get = function(){
		return this.container;
	};
	/**
	 * @function updateScheduleText
	 * @desc Update the schedule text (textRight member)
	 * @memberOf ProjectView.PlanBar
	 * @param [options]
	 */
	PlanBar.prototype.updateScheduleText = function(options){
		options = options || this.options;
		var self = this;
		options.isEmpty = projectViewUtils.planIsEmpty(options.waveform.startDate, options.waveform.finishDate);
		function drawEmpty(){
			if (!self.daysBehindText.texture.baseTexture){return}
			self.daysBehindText.text = "Add a milestone for Plan Status";
			self.daysBehindText.style = projectViewConfig.PLAN_AHEAD_BEHIND_TEXT;
			self.daysBehindText.y = self.textRight.height - options.planAheadBehindTextOptions.SPACING;
			self.aheadOrBehindText.text = "";
			self.aheadOrBehindText.style = projectViewConfig.PLAN_AHEAD_BEHIND_TEXT;
			self.aheadOrBehindText.x = Math.round(self.daysBehindText.width);
		}

		function drawAheadBehind(){
			if (!self.currentPlanDate.texture.baseTexture){return}
			var daysBehind = Math.abs(options.plan.milestone.daysBehind) || 0;
			var aheadOrBehind = (options.plan.milestone.daysBehind <= 0) ? " ahead" : " behind";
			var color = (aheadOrBehind === " behind") ? options.planAheadBehindTextOptions.BEHIND_COLOR : options.planAheadBehindTextOptions.AHEAD_COLOR;
			var dayOrDays = (daysBehind > 1 || daysBehind === 0) ? " days" : " day";
			self.currentPlanDate.text = (options.plan.milestone.scheduleDate) ? "Current Plan Date: " + options.plan.milestone.scheduleDate + " | " : "Add a milestone for Plan Status";
			self.currentPlanDate.style = projectViewConfig.CURRENT_PLAN_DATE_TEXT;
			self.currentPlanDate.y = self.textRight.height - options.planAheadBehindTextOptions.SPACING;

			self.daysBehindText.text = (options.plan.milestone.scheduleDate) ? daysBehind + dayOrDays : "";
			self.daysBehindText.style = options.planAheadBehindTextOptions;
			self.daysBehindText.x = self.currentPlanDate.width;
			if (self.daysBehindText.y !== 0){self.daysBehindText.y = 0;}
			self.aheadOrBehindText.text = (options.plan.milestone.scheduleDate) ? aheadOrBehind : "";
			self.aheadOrBehindText.style = {
				fontFamily: 'Roboto',
				fontSize: 12,
				fill: color,
				fontWeight: '400',
				resolution: 4
			};
			self.aheadOrBehindText.x = Math.round(self.daysBehindText.width);
		}

		// Create the ahead/behind text
		if (!options.isEmpty && options.plan.milestone){
			drawAheadBehind();
		} else{
			drawEmpty();
		}

		if (self._dirty){self.textRight.x += self.calculateTextPositionOffset();}
		// MOC-3181
		let drawingCoords = self.renderType.getDrawingCoordinates();
		if (self.textRight.x > drawingCoords.totalWidth + 24){
			self.textRight.x = drawingCoords.totalWidth + 24;
		}
		this._dirty = false;
	};
	/**
	 * @function updatePlanName
	 * @desc Update the plan name texture
	 * @param forceRedraw {Boolean} Forces a redraw by marking the texture as dirty
	 * @memberOf ProjectView.PlanBar
	 */
	PlanBar.prototype.updatePlanName = function(forceRedraw){
		if (forceRedraw){this.textRight.dirty = true; return;}
		if (this.options.plan.name === this._origOptions.plan.name){return}
		this.textRight.text = this.options.plan.name;
		this._origOptions.plan.name = this.options.plan.name;
	};
	/**
	 * @function updatePlanDates
	 * @desc Updates the internally saved plan dates
	 * @memberOf ProjectView.PlanBar
	 * @param initialUpdate
	 */
	PlanBar.prototype.updatePlanDates = function(initialUpdate){
		if(!initialUpdate){this._dirty = true;}
		this.options.waveform.startDate = projectViewUtils.getStartDate(this.options.plan);
		this.options.waveform.finishDate = projectViewUtils.getFinishDate(this.options.plan);
	};
	/**
	 * @function updatePlanBarWidth
	 * @desc Updates the width of the plan bar
	 * @memberOf ProjectView.PlanBar
	 */
	PlanBar.prototype.updatePlanBarWidth = function(){
		var startDate = projectViewUtils.getStartDate(this.options.plan);
		var finishDate = projectViewUtils.getFinishDate(this.options.plan);
		if(startDate === finishDate){return;}
		
		var w = projectViewUtils.getDayDifference(startDate, finishDate);
		var drawX = projectViewUtils.calculateDrawPos(startDate, finishDate, w);
		var options = {
			newWidth: w,
			newStartX: drawX,
			ticketStatusDates: projectViewUtils.getTicketStatusDraw(this.options.plan),
			scale: 1
		};
		this._origOptions.waveform.startDate = this.options.waveform.startDate;
		this._origOptions.waveform.finishDate = this.options.waveform.finishDate;
		this.refresh(options);
	};
	/**
	 * @function updateActiveLine
	 * @desc Updates the active line
	 * @memberOf ProjectView.PlanBar
	 * @param options
	 */
	PlanBar.prototype.updateActiveLine = function(options){
		options = options || this.options;
		function calculateActiveLine(){
			var startDate = projectViewUtils.getStartDate(options.plan);
			var difference = projectViewUtils.getDayDifference(startDate, options.plan.activeLineDate);
			if (startDate > options.plan.activeLineDate){ difference *= -1;}
			return difference
		}
		var activeLineDrawX = (options.plan.activeLineDate) ? calculateActiveLine() : null;
		if (activeLineDrawX){
			this.activeLine.setVisible(true);
			this.activeLine.draw(activeLineDrawX, -4, activeLineDrawX, this.heatmapBackground.get().height +4);
		} else {
			this.activeLine.setVisible(false);
		}
	};

	return PlanBar;
}
