import { ComponentInterface } from "../interfaces/";
import { TicketContainer } from "../data-mappers/";
import { PlanState } from "../../common/services/plan-state.service";
import { Point, Rectangle, TextStyleOptions } from "pixi.js";
import * as Utils from "utils";
import { Side } from "../../common/models/Side";
import { TicketUtils } from "../utils/TicketUtils";
import { Ticket } from "../../common/models/Ticket";
import { TicketTextSettings } from "./";
import { HighResolutionText } from "../text/HighResolutionText";
import { LEVEL_OF_DETAIL_TABLE, LevelOfDetail } from "../core/LevelOfDetail";

export class TicketTextCanvas extends HighResolutionText implements ComponentInterface {
	public active: boolean = true;
	public visible: boolean = true;
	public align = {x: false, y: false};
	public maskArea: Rectangle = new Rectangle(0, 0, 1, 1);
	protected ticket: TicketContainer;
	
	constructor(text: string, style: TextStyleOptions, planState: PlanState, ticketContainer: TicketContainer, canvas?: HTMLCanvasElement) {
		super(text, style, canvas);
		
		this.ticket = ticketContainer;
		this.tint = this.ticket.liveRoleTextColor;
	}
	
	public positionCenter(ticket: Ticket) {
		const textCenter: Point = new Point((this.width / 2), (this.height / 2));
		const drawCenter: Point = new Point(this.maskArea.x + (this.maskArea.width / 2), this.maskArea.y + (this.maskArea.height / 2));
		if (this.align.x){
			this.x = (drawCenter.x - textCenter.x);
		}
		if (this.align.y){
			this.y = Utils.clamp(drawCenter.y - textCenter.y, this.maskArea.y);
		}
	}
	
	protected _getHeightOffset(ticket: Ticket): number {
		let _offset: number = 0;
		if (ticket.view.location){
			_offset += (ticket.view.side === Side.PULL) ? 40 : 25;
		}
		if (ticket.view.liveStart || ticket.view.liveFinish  && LevelOfDetail.getLODLevel() >= LEVEL_OF_DETAIL_TABLE.MEDIUM){
			_offset += (ticket.view.side === Side.PULL) ? 36 : 20;
		}
		
		return _offset;
	}
	
	/**
	 * Applies offset and drawing area settings depending on the ticket type.
	 * @param {TicketContainer} ticketContainer
	 * @param {TicketTextSettings} settings
	 */
	protected applyOffsetAndDrawArea(ticketContainer: TicketContainer, settings: TicketTextSettings) {
		let data = ticketContainer.getData();
		let type = TicketUtils.getTicketType(data).toUpperCase();
		// Append a '_PULL' suffix if required.
		let pullSuffix = (data.view.side === Side.PULL) ? "_PULL" : "";
		
		// Set a width and height. This is so we can override the width or height from the ticket data.
		let width = settings[type + "_DRAWING_AREA_WIDTH" + pullSuffix] || data.view.width;
		let height = settings[type + "_DRAWING_AREA_HEIGHT" + pullSuffix] || data.view.height;
		
		// Set text offset (Changes depending on the active components
		this.style.wordWrapWidth = width - settings[type + "_RIGHT_MARGIN" + pullSuffix] - settings[type + "_LEFT_MARGIN" + pullSuffix];
		this.maskArea.x = settings[type + "_LEFT_MARGIN" + pullSuffix];
		this.maskArea.y = settings[type + "_TOP_MARGIN" + pullSuffix];
		
		// Set text drawing area.
		this.maskArea.width = this.style.wordWrapWidth as number;
		this.maskArea.height = (height - settings[type + "_BOTTOM_MARGIN" + pullSuffix]);
	}
	
	/**
	 * When text changes, re-calculate the trig data.
	 * @param {string} newText
	 * @param {string} oldText
	 */
	public onTextChanged = (newText: string, oldText: string) => {
		this.setText(newText);
	};
	/**
	 * Override this to change where the text data comes from
	 * @param text
	 */
	public setText(text: string){
		if (!text){return}
		this.text = "".concat(text);
	}
	
	public destroy(options?): void {
		super.destroy(options);
	}
}