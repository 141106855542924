'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

declare const window: any;

// todo - refactor out explicit project access set, need to figure out how projects are being managed

angular.module('ticketspaceApp')
.factory('firebaseProjects', ["$q", "$log", "$firebaseUtils", "$firebaseObject", "fbConnection", "firebasePriorities", "firebaseProjectLimitations", "firebaseProjectMembersStatic", "authService", "firebaseAccount", "fbdbTime", "SystemColors", function($q, $log, $firebaseUtils, $firebaseObject, fbConnection, firebasePriorities, firebaseProjectLimitations, firebaseProjectMembersStatic, authService, firebaseAccount, fbdbTime, SystemColors) {
	//console.log('authService', authService);


	//- look into redoing the way selecting a project works, possibly storing it on the object returned by this service
	//- because of the index nature getting the project list has, the angularfire array doesn't work and we have to essentially roll our own solution.
	//- currently a project list is returned as an object indexed by $id. This means the list can't be sorted, iteration is awkward, and local functions/ data either can't be added or will be awkward. Potential solution to this is instead returning an object that contains the original object, an array version, and whatever methods/ local data needs to be there.
	//- define better means of knowing when the project has been added and reflected in the project list

	//-----------------------------------
	// Constants
	//-----------------------------------
	var baseRef = 'projects/';
	var defaultVarianceList = {
		"default1": {"varianceKey": 1, "varianceReason": "Coordination / Scheduling", "varianceColor": "#5DA4DA"},
		"default2": {"varianceKey": 2, "varianceReason": "Design / Engineering", "varianceColor": "#F25858"},
		"default3": {"varianceKey": 3, "varianceReason": "Owner Changes", "varianceColor": "#FAA43D"},
		"default4": {"varianceKey": 4, "varianceReason": "Weather", "varianceColor": "#F27CB1"},
		"default5": {"varianceKey": 5, "varianceReason": "Material / Equipment / Labor", "varianceColor": "#61BC67"},
		"default6": {"varianceKey": 6, "varianceReason": "Permit / Inspections / Regulatory", "varianceColor": "#DFCF40"},
		"default7": {"varianceKey": 7, "varianceReason": "Site Coordination", "varianceColor": "#B475B4"},
		"default8": {"varianceKey": 8, "varianceReason": "GC Directive", "varianceColor": "#B2902E"},
		"default9": {"varianceKey": 9, "varianceReason": "Finished Before Plan", "varianceColor": "#2F7D99"}
	};



	//todo:
	// - have migrated this function from fbHelper
	// - probably need to update it so each project is an angularfire obj
	// - consider maintaining this service globally
	// - consider adding a method to only acquire one project
	// - consider adding a fake $watch function to mimic angularfire to replace the notifier function


	//projectsForUser('simplelogin:274').then(function(a){console.log('val',a);}).catch(function(a){console.log('err',a);})
	var projectFactory = $firebaseObject.$extend({
		$$updated: function(snap){
			var changed = $firebaseUtils.updateRec(this, snap);

			this._isDisabled = false;
			this._promptForPayment = false;
			//replace this with a switch checking for various project types
			switch(this.projectType){
				case 'disabled':
					this._isDisabled = true;
					break;
				case 'enterprise':
					this._isDisabled = false;
					break;
				case 'invoice':
					this._isDisabled = false;
					break;
				case 'invoiceExpired':
					this._isDisabled = true;
					break;
				case 'openBeta':
					this._isDisabled = false;
					break;
				case 'beta60':
					this._isDisabled = false;
					break;
				case 'trialing':
					this._promptForPayment = true;
					this._isDisabled = false;
					break;
				case 'trialExpired':
					this._promptForPayment = true;
					this._isDisabled = true;
					break;
				case 'paid':
					this._isDisabled = false;
					break;
				case 'delinquent':
					this._isDisabled = false;
					break;
				case 'canceled':
					this._promptForPayment = true;
					this._isDisabled = true;
					break;
				case 'cancelAtPeriodEnd':
					this._promptForPayment = true;
					this._isDisabled = false;
					break;
				case 'sampleProject':
					this._isDisabled = false;
					break;
				case 'sampleProjectShared':
					if (!authService.userData){return false;}
					if(this.ownerUserId !== authService.userData.$id){
						this._isDisabled = true;
					}
					break;
				case 'unknown':
					this._isDisabled = false;
					break;
				default:
					break;
			}
			
			//calculate the "current payment"
			if(this.invoicePayments){
				var child = snap.child('invoicePayments');
				var theOne;
				var theLast;
				child.forEach(function(payment){
					if(!theOne && !payment.child("paid").val()){ theOne = payment; }
					theLast = payment;
				});
				if(!theOne){ theOne = theLast; }
				this._currentPayment = theOne.val();
				
				if(!this._currentPayment.paid && moment(this._currentPayment.date).isBefore(moment()) && (!this.overrideNagUntil || moment(this.overrideNagUntil).isBefore(moment()))){
					this._shouldNag = true;
				}
				else{ this._shouldNag = false; }
			}
			else{ delete this._currentPayment; }

			return changed;
		},
		// isDisabled: function isDisabled(){
			// return true;
		// }
	});


	//builds a project list based on a userid passed in
	//accepts an optional notifier function the will be called anytime
	//a project is added or removed
	function projectsForUser(userId){
		if(!userId){return $q.rejectErr('userId is requred');}
		
		//console.log('a', authService.userData);
		if(authService.userData.support){
			return fbConnection.loadObject('projects');
		}

		var projectList = Object.create({
			"_watchers": [],
			"$watch": function $watch(fn){
				var length = this._watchers.push(fn);
				var self = this;
				return function(){
					self._watchers.splice(length-1, 1);
				};
			},
			"_fireWatchers" : function _fireWatchers(){
				var outerArgs = arguments;
				this._watchers.forEach(function(fn){fn.apply(undefined, outerArgs);});
			}
		});
		
		var projectIdList = fbConnection.fbArray('indices/users/'+userId+'/projectAccess');
		return projectIdList.$loaded().then(function(){
			//if(!projectIdList.length){return $q.rejectErr('user: '+userId+' does not exist');}
			//console.log('loaded', userId, projectIdList);
			var batch = fbConnection.batch();
			for(var i = 0; i < projectIdList.length; i++){
				var project = getProject(projectIdList[i].$id);
				projectList[projectIdList[i].$id] = project;

				batch.push(project.$loaded());
			}
			return batch.end().then(function(){return projectIdList;});
		})
		.then(function(projectIdList){
			//setup a watch to keep this fake list up to date on add/deletes
			projectIdList.$watch(function(change){
				if(change.event === "child_removed"){
					if(projectList[change.key]){
						//projectList[change.key].$destroy();
						delete projectList[change.key];
						projectList._fireWatchers(change);
					}
				}
				else if(change.event === "child_added"){
					//console.log('child_added', change.key, change);
					if(!projectList[change.key]){
						//projectList[change.key]
						var project = getProject(change.key);
						project.$loaded().then(function(){
							projectList[project.projectId] = project;
							projectList._fireWatchers(change);
						});
					}
				}
				else{ projectList._fireWatchers(change); }
			});

			return projectList;
		});
	}

	function getProject(projectId){
		if(!projectId){$q.rejectErr('projectId isn\'t set, can\'t get project');}
		return new projectFactory(fbConnection.fbRef('projects/'+projectId));
	}

	function getProjectName(projectId) {
		if(!projectId){$q.rejectErr('projectId isn\'t set, can\'t get project name');}
		return fbConnection.loadObject('projects/'+projectId+'/projectName');
	}

	//internal
	//fills in some fields that can be generated
	function initProjectData(proj){
		//var reqFields = ['projectName', 'ownerUserId'];
		proj = proj || {};

		var acc = firebaseAccount.get();
		//projectType checks
		if(!acc.isStandardAccount()){ proj.projectType = 'enterprise'; } //set enterprise
		if(acc.isAwaitingAFreeTrial()){ proj.projectType = 'trialing'; } //set trialing
		
		if(acc.isSteve()){
			proj.freeTrialStamp = moment().add(acc.trialDays || 3,'days').format('YYYY-MM-DD');
			proj.projectType = 'trialing';
		}
		
		

		if(!proj.projectName){ proj.projectName = "New"; }
		if(!proj.ownerUserId){ proj.ownerUserId = authService.userData.id; }
		if(!proj.accountId){ proj.accountId = acc.id; }
		if(!proj.variances){ proj.variances = defaultVarianceList; }
		if(!proj.priorities){ proj.priorities = firebasePriorities.assembleDefault(fbConnection.fbRef(baseRef));}
		if(!proj.useIntersectingPromisePeriods){ proj.useIntersectingPromisePeriods = true; }
		if(!proj.workShiftsByDow){ proj.workShiftsByDow = [1, 1, 1, 1, 1, 0, 0]; }
		proj.createdAt = fbdbTime.raw.$value;
		return proj;
	}

	//consider setting up thing on firebase where initial values are stored, the default variance list for example

	//#### addProjectAmbiguously(project)
	//adds a project leaving the exactly method up to the service to determine
	//* project - an object containing project properties to add
	//* instance - an instance of this service returned by a getter. Will be used to improve resolution of $watch events
	//todo - change name
	function addProjectAmbiguously(projectId, project, instance?){
		if(firebaseAccount.get().hasUsedAllFreeTrials()){
			return payForProject(projectId, project, instance).then(function() {
				analyticsService.createdAProject(projectId, project.projectName, project.address);
			});
		}
		else{
			return addProject(project, instance).then(function() {
				analyticsService.createdAProject(projectId, project.projectName, project.address);
			});
		}
	}

	function generateProjectColors(projectId){
		var colorsRef = fbConnection.fbRef("projects/" + projectId + "/colors");
		var newData = {
			colors: {}
		};
		function iterateColors(list, key){
			newData["colors"][key] = {};
			list.forEach(function(color){
				var newRef = colorsRef.push();
				newData["colors"][key][newRef.key] = {
					id: newRef.key,
					color: color
				}
			});
		}
		iterateColors(SystemColors.DEFAULT_ROLE_COLORS, 'roles');
		iterateColors(SystemColors.DEFAULT_LOCATION_COLORS, 'locations');
		return newData;
	}

	//#### addProject(project)
	//adds a project
	//* project - an object containing project properties to add
	//* instance - an instance of this service returned by a getter. Will be used to improve resolution of $watch events
	function addProject(project, instance?){
		project = initProjectData(project);
		var projectsRef = fbConnection.fbRef(baseRef);

		return fbConnection.add(projectsRef, project, 'projectId').then(function(ref){
			var projectColors = generateProjectColors(ref.key);
			return populateProject(ref.key, project.ownerUserId, projectColors, instance);
		});

	}
	//#### payForProject(projectId, project, instance)
	// kicks off the payment flow for adding a project
	//* projectId - id
	//* project - an object containing project properties to add
	//* instance - an instance of this service returned by a getter. Will be used to improve resolution of $watch events
	function payForProject(projectId, project, instance?){
		project = initProjectData(project);
		project.projectType = 'paid';
		console.log('payForProject', projectId, project);
		//do this after a successful payment
		return fbConnection.update(fbConnection.fbRef(baseRef+projectId), project).then(function(){
			return populateProject(projectId, project.ownerUserId, {}, instance);
		}).then(function() {

		});
	}
	
	//sample obj to call with:
	// {
		// projectName:this.newProject.name,
		// address:this.newProject.address,
		// photo: this.newProject.photo,
		// ownerUserId: $scope.userid,
		// freeTrialStamp: $scope.user.hasNotCreatedFree ? moment().add(31, 'days').format('YYYY-MM-DD') : null
	// };
	
	
	
	function getProjectColorRecord(colorList, color){
		for (var key in colorList){
			var rec = colorList[key];
			if (rec){
				if (rec.color === color){return rec}
			}
		}
		return null;
	}
	//currently internal
	function populateProject(projectId, userId, projectColors, instance){
		console.log('populatedProject', projectId);
		//eventually move this to firebaseProjectMembers, just need this to work for now...
		function generateProjectMember(userId, accessLevel, roleId){
			return {
				userId: userId,
				roleId: roleId,
				accessLevel: accessLevel
			};
		}
		//quietly remove hasNotCreatedFree key, callback un-necessary
		//move to user service at some point
		authService.userData.$ref().child('hasNotCreatedFree').remove();
		
		return fbConnection.add(fbConnection.fbRef('locations/' + projectId), {
			name: 'default',
			locationColor: getProjectColorRecord(projectColors.colors.locations, "#424242").id
		})
		//and the default location
		.then(function(ref){
			//move this default role to firebaseRoles
			return fbConnection.add(fbConnection.fbRef('roles/' + projectId), {
				roleName: 'default',
				roleColor: getProjectColorRecord(projectColors.colors.roles, "#ddedc8").id
			}, 'roleId')
		})
		.then(function(ref){
			var pm = generateProjectMember(userId, 'Admin', ref.key);
			return firebaseProjectMembersStatic.addAtomic(projectId, pm).then(function(){
			//return fbHelper.projectMember.add(projectId, userId, pm).then(function(){
				return firebaseAccount.addProject(projectId);
			})
			.then(function(){
				if(instance && !instance[projectId]){
					//Logging.debug('delayed project selection worked, report this');
					return $q(function(resolve, reject){
						var watcher = instance.$watch(function(change){
							if(instance && instance[projectId]){
								watcher();
								resolve(projectId); 
							}
						});
					});
				}
				
				return projectId;
			});
		}).then(function(projectId){
			var ref = fbConnection.fbRef("projects/" + projectId);
			return ref.update(projectColors).then(function(){console.log("Created colors!");})
			});
	}
	
	//#### removeProject(projectId)
	//removes a project via fbdb
	function removeProject(projectId){
		if(!projectId){ return $q.rejectErr('projectId is undefined'); }
		var ref = fbConnection.fbRef('projects').child(projectId).child('deletedAt');
		return $q.when(ref.set(fbdbTime.raw.$value));
	}
	
	//*******************
	//Statics
	//*******************
	
	function validateProjectData(project) {
		//project = initProjectData(project);
		//console.log(project);
		if(project.projectName) {
			return true;
		}
		else {
			Logging.warning("Projects require a name.");
			return false;
		}
	}
	
	//#### addAccess(projectId, userId)
	function addAccess(projectId, userId){
		if(!projectId || !userId){ return $q.reject('projectId/ userId are not defined');  }
		var ref = fbConnection.fbRef('projects/'+projectId+'/users/'+userId);
		return fbConnection.set(ref, true);
	}
	
	//#### removeAccess(projectId, userId)
	function removeAccess(projectId, userId){
		if(!projectId || !userId){ return $q.reject('projectId/ userId are not defined');  }
		var ref = fbConnection.fbRef('projects/'+projectId+'/users/'+userId);
		return fbConnection.remove(ref);
	}
	
	//### setProjectType(projectId, type)
	function setProjectType(projectId, type){
		if(!projectId || !type){ return $q.rejectErr("projectId/type are undefined"); }
		var ref = fbConnection.fbRef(baseRef+projectId+'/projectType');
		return fbConnection.set(ref, type);
	}
	
	//### validateProjecType(project)
	// this function should be expected to perform in the background with no response after
	// being called, responding to any changes it makes should be done through normal watches
	//* project - the fully loaded angularfire project
	function validateProjectType(project){
		
		//just assume projectType is invoice...
		//this check takes highest priority
		if(project.invoiceEndDate){
			if(project.projectType === 'disabled'){ return; } //hard override
			
			var expired = moment().isAfter(moment(project.invoiceEndDate), 'days');
			if(project.projectType === 'invoice' && expired){
				setProjectType(project.$id, 'invoiceExpired');
			}
			else if(project.projectType !== 'invoice' && project.projectType !== 'invoiceExpired'){
				setProjectType(project.$id, 'invoice');
			}
			return;
		}
		
		
		if(project.projectType === 'trialing'){
			var expired = moment().isAfter(moment(project.freeTrialStamp), 'days');
			if(expired){
				setProjectType(project.$id, 'trialExpired');
			}
		}

	}
	
	
	
	return {
		addAccess: addAccess,
		removeAccess: removeAccess,
		setProjectType: setProjectType,
		validateProjectType: validateProjectType,
		getProjects: projectsForUser,
		getProject: getProject,
		getProjectName: getProjectName,
		removeProject: removeProject,
		addProjectAmbiguously: addProjectAmbiguously,
		payForProject: payForProject,
		addProject: addProject,
		validateProjectData: validateProjectData
	}
}]);
