import * as angular from "angular";

angular.module('CanvasWrapper').factory("InteractionDoubleTap", DoubleTapFactory);
/**
 * @namespace DoubleTap
 * @returns {{activate: activate, update: update, getDateRange: getDateRange}}
 * @memberOf CanvasWrapper.Interaction
 * @constructor
 */
DoubleTapFactory.$inject = ["InteractionHandleEvent", "InteractionTap"];
function DoubleTapFactory(InteractionHandleEvent, InteractionTap){
	var handleEvent = InteractionHandleEvent;
	function DoubleTap(displayNode, delay){
		var taps = 0;
		var tap = InteractionTap(displayNode);
		handleEvent(displayNode).on('simpletap', function(eventData){
			taps++; // Increment clicks
			if (taps === 1){
				var timer = setTimeout(function(){
					taps = 0;
				}, delay)
			} else {
				clearTimeout(timer);
				taps = 0;
				handleEvent(displayNode).emit('doubletap');
			}
		})
	}

	return DoubleTap;
}
