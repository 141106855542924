angular.module("ticketspaceApp").directive("dropdownMenu", DropDownMenuDirective);

function DropDownMenuDirective(){
	return {
		transclude: {
			'title': 'menuToggle',
			'body': 'menuContent'
		},
		controllerAs: "$ctrl",
		scope: true,
		template: require('tpl/directive/dropdown-menu.html'),
		link: function(scope, element, attrs, ctrl, transclude){
			let titleNode = element.find('[ng-click="$ctrl.toggle(); $whenOpened()"]');
			let bodyNode = element.find('.drop-down-menu');
			transclude(scope, function (content) {
				titleNode.append(content);
			}, null, 'title');
			
			transclude(scope, function (content) {
				bodyNode.append(content);
			}, null, 'body');
		},
		controller: function(){
			this.opened = false;
			this.toggle = function(){
				this.opened = !this.opened;
			};
		}
	}
}