'use strict';
import * as angular from "angular";
import * as moment from "moment";
import { analyticsService } from "ng2/common/utils/AnalyticsService";
import {DateValidationConstants} from "js/constants/date-validator.constant";

import { ProjectNotifier } from "ng2/common/services/project-notifier.service";

export class DatePositionValidator{
	static $inject = ["projectNotifier", "fbConnection", "popup"];
	private planData: any = {};
	private projData: any = {};
	private analyticsService = analyticsService;
	private _lastProjectId: string;
	private _lastPlanId: string;
	constructor(private projectNotifier: ProjectNotifier, private fbConnection, private popup){
		// Use this to update reference paths for the plan data.
		projectNotifier.projectState.subscribe((state)=>{
			this.destroy();
			if (state && state.projectId){
				this.setup(state.projectId, state.planId);
			}
		});
	}
	// Get the plan data. fbdb will write plan context information here.
	private setup(projectId, planId){
		if (planId){
			let ref = this.fbConnection.fbRef('plans').child(projectId).child(planId);
			this.planData = this.fbConnection.fbObject(ref);
		}
		let projRef = this.fbConnection.fbRef('projects').child(projectId);
		this.projData = this.fbConnection.fbObject(projRef);
	}
	private destroy(){
		if (this.planData && this.planData.$destroy){this.planData.$destroy(); this.planData = null;}
		if (this.projData && this.projData.$destroy){this.projData.$destroy(); this.projData = null;}
	}

	private isSameState(projectId: string, planId?: string){
		return (projectId === this._lastProjectId && planId === this._lastPlanId)
	}

	public forceState(projectId: string, planId?: string) {
		if (this.isSameState(projectId, planId)){return}
		this.destroy();
		this.setup(projectId, planId);
	}

	/**
	 * DATE VALIDATOR API STARTS YONDER
	 */
	isValidDate(compareDate, srcData:any = {}, units?, inclusive?){
		// MOC-3083. Null/undefined compareDate is assumed to be a valid move.
		if (!compareDate || !DateValidationConstants.ENABLE_VALIDATOR || !srcData){return true}
		if(!moment(compareDate, "YYYY-MM-DD", true).isValid()){return false;}
		let startDate = srcData.minDate || moment();
		let endDate = srcData.maxDate || moment();
		let min = moment(startDate).subtract(DateValidationConstants.DAYS_WARNING_OFFSET, 'days');
		let max = moment(endDate).add(DateValidationConstants.DAYS_WARNING_OFFSET, 'days');
		units = units || 'days';
		inclusive = (angular.isDefined(inclusive)) ? inclusive : true;
		return moment(compareDate).isBetween(min, max, units, inclusive);
	}
	
	isBetween(num, min, max){
		if (num === Infinity){return true}
		return num >= min && num <= max
	}
	
	
	isValidPosition(x, y, srcData?){
		if (!srcData){
			srcData = this.planData;
			if (!srcData){return true}
		}
		if (!DateValidationConstants.ENABLE_VALIDATOR || !srcData.minX || !srcData.maxX){return true}
		let minX = (srcData.minX - DateValidationConstants.X_WARNING_OFFSET);
		let maxX = (srcData.maxX + DateValidationConstants.X_WARNING_OFFSET);
		let minY = (srcData.minY - DateValidationConstants.Y_WARNING_OFFSET);
		let maxY = (srcData.maxY + DateValidationConstants.Y_WARNING_OFFSET);
		return (this.isBetween(x, minX, maxX) && this.isBetween(y, minY, maxY))
	}
	
	isExtremelyInvalidPosition(x,y){
		return Math.abs(x) > DateValidationConstants.ABYSS_LIMIT || Math.abs(y) > DateValidationConstants.ABYSS_LIMIT;
	}

	dateWarning(compareDate, srcData, units?, inclusive?){
		// MOC-3083
		if (!compareDate){return false}
		if (!DateValidationConstants.ENABLE_VALIDATOR || !srcData){return true}
		let threshold = DateValidationConstants.DAYS_WARNING;
		units = units || 'days';
		let startDate = srcData.minDate || moment();
		let endDate = srcData.maxDate || moment();
		let min = moment(startDate).subtract(threshold, 'days');
		let max = moment(endDate).add(threshold, 'days');
		inclusive = (angular.isDefined(inclusive)) ? inclusive : true;
		return moment(compareDate).isBetween(min, max, units, inclusive);
	}
	
	/**
	 * Check if the position is within the "valid" range
	 */
	positionWarning(x:number, y:number, dataSrc?){
		if (!dataSrc) {dataSrc = this.planData;}
		if (!DateValidationConstants.ENABLE_VALIDATOR || !dataSrc || !dataSrc.minX || !dataSrc.maxX || !dataSrc.minY || !dataSrc.maxY){return true}
		let minX = dataSrc.minX - DateValidationConstants.X_WARNING_THRESHOLD;
		let maxX = dataSrc.maxX + DateValidationConstants.X_WARNING_THRESHOLD;
		let minY = dataSrc.minY - DateValidationConstants.Y_WARNING_THRESHOLD;
		let maxY = dataSrc.maxY + DateValidationConstants.Y_WARNING_THRESHOLD;
		return (this.isBetween(x, minX, maxX) && this.isBetween(y, minY, maxY))
	}
	
	verifyDate(compareDate, settingsObj, confirmCb, cancelCb?){
		let args = [].slice.call(arguments,4);
		if (!DateValidationConstants){return confirmCb.apply(undefined, arguments);}
		cancelCb = cancelCb || function(){};
		if (this.dateWarning(compareDate, this.planData) && !this.isValidDate(compareDate, this.planData)){
			analyticsService.dateViolationNagConfirm();
			return this.popup(settingsObj).then(function(){
				confirmCb.apply(undefined, args);
			}).catch(function(){
				cancelCb.apply(undefined, args);
			});
		} else if (!this.dateWarning(compareDate, this.planData) && !this.isValidDate(compareDate, this.planData)){
			Logging.warning("Your defined date is too far away from the current plan. Please choose a different date.");
			analyticsService.dateViolationBlocked();
			return cancelCb.apply(undefined, args);
		}else if (this.isValidDate(compareDate, this.planData)){
			return confirmCb.apply(undefined, args);
		}
		return cancelCb.apply(undefined, args);
	}
	
	verifyProjectDate(compareDate, settingsObj, confirmCb, cancelCb?){
		let args = [].slice.call(arguments,4);
		if (!DateValidationConstants){return confirmCb.apply(undefined, arguments);}
		cancelCb = cancelCb || function(){};
		if (this.dateWarning(compareDate, this.projData) && !this.isValidDate(compareDate, this.projData)){
			analyticsService.dateViolationNagConfirm();
			return this.popup(settingsObj).then(function(){
				confirmCb.apply(undefined, args);
			}).catch(function(){
				cancelCb.apply(undefined, args);
			});
		} else if (!this.dateWarning(compareDate, this.projData) && !this.isValidDate(compareDate, this.projData)){
			analyticsService.dateViolationBlocked();
			Logging.warning("Your defined date is too far away from the current plan. Please choose a different date.");
			return cancelCb.apply(undefined, args);
		}else if (this.isValidDate(compareDate, this.projData)){
			return confirmCb.apply(undefined, args);
		}
		return cancelCb.apply(undefined, args);
	}
	
	verifyPosition(x, y, settingsObj, confirmCb, cancelCb?){
		let args = [].slice.call(arguments,4);
		if (!DateValidationConstants){return confirmCb.apply(undefined, arguments);}
		cancelCb = cancelCb || function(){};
		if (this.positionWarning(x, y) && !this.isValidPosition(x, y)){
			analyticsService.positionViolationNagConfirm();
			return this.popup(settingsObj).then(function(){
				confirmCb.apply(undefined, args);
			}).catch(function(){
				cancelCb.apply(undefined, args);
			});
		} else if (!this.positionWarning(x, y) && !this.isValidPosition(x, y)){
			analyticsService.positionViolationBlocked();
			Logging.warning("Cannot drag ticket there as it is to far away from the rest of your tickets.");
			return cancelCb.apply(undefined, args);
		} else if (this.isValidPosition(x, y)){
			return confirmCb.apply(undefined, args);
		}
		return cancelCb.apply(undefined, args);
	}
	
	askForConfirmation(settings, confirmCb, cancelCb?){
		let args = [].slice.call(arguments,3);
		cancelCb = cancelCb || function(){};
		return this.popup(settings).then(function(){
			confirmCb.apply(undefined, args);
		}).catch(function(){
			cancelCb.apply(undefined, args);
		});
	}
	
	getData(){
		return this.planData;
	}
}

angular.module('ticketspaceApp').service('DatePositionValidator', DatePositionValidator)
