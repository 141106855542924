// Created by kdo on 9/15/15.

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";
import { analyticsService } from "ng2/common/utils/AnalyticsService";

var blankphotoSrc = require('!file-loader?name=[path][name].[ext]!images/blankphoto.png');

"use strict";
angular.module('ticketspaceApp').directive('projectPanel', ["popup", "firebaseProjects", "firebaseAccount", "fbdbTime", function (popup, firebaseProjects, firebaseAccount, fbdbTime) {
    return {
        restrict: 'EA',
        scope: true,
        controller: ["$scope", "$q", function($scope, $q){
					$scope.search = {text: "", showArchived: false};
					$scope.lastSorted = {list: []};
					
					$scope.filterOutArchived = function(val){
						return $scope.search.showArchived || !val._isDisabled || val.projectType === "sampleProjectShared";
					}
            //Add project is used to create a new projects. This function will route users to a payment popup, if needed.
            $scope.addProject = function(project, ownerId, isFreeTrial, projectId) {
                var projectData = {
                    projectName: project.name,
                    address: project.address,
                    projectLogo: project.projectLogo,
                    ownerUserId: ownerId,
                    freeTrialStamp: isFreeTrial ? moment().add(15, 'days').format('YYYY-MM-DD') : null
                };
				//freeTrialStamp may be getting applied to projects that shouldn't get it...
				
                //forwarding the existing promises...
                //if(firebaseProjects.validateProjectData(projectData)) {
					return firebaseProjects.addProjectAmbiguously(projectId, projectData, $scope.projects);
                // } else {
					// return $q.rejectErr('project is invalid');
                // }
            };

            $scope.saveProject = function(project) {
                if(firebaseProjects.validateProjectData(project)) {
					$scope.isEditing.inProgress = true;
					
                    return $scope.projects[project.projectId].$save()
					.then(function(projectId){
						$scope.isEditing.projectId = -1;
						
						// $scope.selectedProject.id = project.projectId;
						// $scope.selectedProject.proj =  $scope.projects[project.projectId];
					})
					.catch(function(err){
						Logging.warning(err.message);
					})
					.finally(function(){
						$scope.isEditing.inProgress = false;
					});
                }
				else{ return $q.rejectErr('project is invalid'); }
            };
        }],
        link: function(scope:any) {
            // ---
            //Code here controls the specific UI components in THIS directive should be defined in this link function
            // ---
            scope.newProject = {
                projectLogo: blankphotoSrc,
                name: '',
                address: ''
            };
            scope.isEditing = {
                projectId: -1,
                newProject: false,
				inProgress: false
            };

            scope.showEditNewProject = function() {
                scope.isEditing.newProject = true;
                scope.isEditing.projectId = -1;

                scope.projectOps.unSelect();
                scope.scrollToTop('.myprojects');
                resetEditNewProjectData();
            };

            scope.closeEditNewProject = function() {
                scope.isEditing.newProject = false;
                resetEditNewProjectData();
            };

            scope.removeProject = function(id, event) {
                if(event)
                    event.stopPropagation();

                if(scope.projects[id]) {
                    popup().then(function(){
						scope.selectedProject.proj.disabled = true;
                        scope.isEditing.projectId = -1;
                        scope.projectOps.actuallyRemove(id, event);
                        scope.closeAllProjectPanels();
                    });
                }
            };

            scope.saveImage = function(data, id){
                if(id){
                    scope.projects[id].projectLogo = data;
                    scope.projects[id].$save();
                }
                else{
                    scope.newProject.projectLogo = data;
                }
				analyticsService.setProjectImage();
            };

            scope.createNewProject = function() {
				scope.isEditing.inProgress = true;
				
				//passive agressive promise joining
				(function(){
					if(firebaseAccount.get().hasUsedAllFreeTrials()){
						return popup({template: require("tpl/popups/no-pay.html")});
					}
					else{
						//console.log('firebaseAccount', firebaseAccount.get(), firebaseAccount.get().hasUsedAllFreeTrials());
						return scope.addProject(scope.newProject, scope.user.id, firebaseAccount.get().isAwaitingAFreeTrial())
						.then(function(projectId){
							analyticsService.freeTrialActivated(scope.newProject ? scope.newProject.name : null);
							analytics.identify({freeTrialStarted_at: fbdbTime.raw.$value});
							return projectId;
						})
					}
				})()
				.then(function(projectId){
					scope.projectOps.select(projectId);
					scope.scrollToBottom('.myprojects');
					
					//check for magic keyword indicating the project hasn't had a user set name
					if(scope.projects[projectId] && scope.projects[projectId].projectName === "New"){
						scope.isEditing.projectId = projectId;
						scope.projects[projectId].projectName = "";
					}
					else{
						scope.closeEditNewProject();
					}
					
				})
				.catch(function(err){
					if(err){ //ignore empty rejects, user closed popup
						Logging.warning(err.message);
					}
				})
				.finally(function(){
					scope.isEditing.inProgress = false;
				});
            };

            function resetEditNewProjectData() {
                scope.newProject = {
                    projectLogo: blankphotoSrc,
                    name: '',
                    address: ''
                }
            }
        },
        template: require("tpl/project-panel.html")
    };
}]);
