import { Point } from "pixi.js";

export enum LineStyle {
	solid = "solid",
	dashed = "dashed"
}

export class SpacialPoint extends Point{
	xIsScreen = false;
	yIsScreen = false;
}

export class Line{
	static clone(line: Line){
		var l = new Line(line.start, line.end);
		l.color = line.color;
		l.lineStyle = line.lineStyle;
		return l;
	}
	
	public start: SpacialPoint;
	public end: SpacialPoint;
	
	public color = 0x2176ae;
	public lineStyle = LineStyle.solid;
	
	constructor(startPoint: Point|number, endPoint: Point|number, endX?: number, endY?: number){
		if(endX){
			this.start = new SpacialPoint(startPoint as number, endPoint as number);
			this.end = new SpacialPoint(endX, endY);
		}
		else{
			this.start = startPoint as SpacialPoint;
			this.end = endPoint as SpacialPoint;
		}
	}
}
