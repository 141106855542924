import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from "ng2/common/ajs-upgrade-providers";

 
@Injectable()
export class DefaultRouteGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService){}
	
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>{
		return this.authService.newWaitForSignIn()
		.then(success => {
			if(success){ this.router.navigate(['projects']);}
			else{ this.router.navigate(['login']);}

			return false;
		});
	}
	
}
