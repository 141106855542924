import { ComponentInterface } from "../../interfaces/";
import { PlanState } from "../../../common/services/plan-state.service";
import { TicketContainer } from "../../data-mappers/";
import { Component } from "../../component-system/";
import { EventBinder } from "../interaction-utils";

import { DependencyModeTypes } from "ng2/common/models/Dependencies";
export class TicketHold extends Component implements ComponentInterface{
	/**
	 * Boilerplate for dealing with events.
	 */
	private _binder: EventBinder;
	constructor(){super();}
	/**
	 * The setup method is called once all properties on a component have been decorated by the ComponentManager.
	 *
	 * From this point on, you can safely use this.getParent() and this.getManager().
	 *
	 * Arguments passed into setup are passed in via the ComponentManager.add(name, component, ...args)
	 * @param {PlanState} planState
	 * @param {DataContainer} dataContainer
	 */
	public setup(planState: PlanState, dataContainer: TicketContainer){
		this._binder = new EventBinder(this.getParent());
		// Add new event binding
		this._binder.add('hammer-pressup', (event)=>{
			if (planState.accessCheck.isReadOnly()){return;}
			dataContainer.cutInLine();
			planState.dependencies.toggleTarget(dataContainer.getData().$id);
			// planState.actions.selectTicketToggle(dataContainer.getData());
		});
	}
	/**
	 * Called on destroy. Clean-up your shit here.
	 */
	public destroy(){
		this._binder.destroy();
	}
}
