'use strict';

import * as angular from "angular";
import * as moment from "moment";

import * as firebase from "firebase/app";

angular.module('ticketspaceApp')
	// Removed $timeout
.service('fbConnection', ["$q", "$firebaseArray", "$firebaseObject", "firebaseRefUrl", function($q, $firebaseArray, $firebaseObject, firebaseRefUrl) {
	var refList = [];
	//## Batch Object
	// Constructor function, generates an object that manages a list of callbacks
	function Batch(){
		var defer = $q.defer();
		var promises = [];
		var _count = 0;
		this.count = function(){
			return _count;
		};
		
		//### push(promise)
		// add a promise to the promise list
		//#### Arguments
		//* promise - the promise
		this.push = function(promise){
			promise = $q.when(promise);
			promise.then(function(data){
				_count++;
				defer.notify(data);
			});
			if(promise){ promises.push(promise); }
		};
		
		//### end()
		// call when finished populating the callback list to get a master callback
		// on the conclusion of everything else
		//#### Returns
		//An empty promise that resolves when all other promises resolve
		//#### Example
		//batch.end().then(data){do stuff});
		this.end = function(){
			
			$q.all(promises).then(function(){
				defer.resolve(true);
			});
			
			return defer.promise;
		};
		
	}

	
	//## batch()
	//#### Returns 
	//a new Batch object
	this.batch = function(){
		return new Batch();
	};
	
	//## getInstance()
	//#### Returns 
	//firebaseRefUrl
	this.getInstance = function(){
		return firebaseRefUrl;
	};
	
	//## $firebase
	// exposes the $firebase reference
	//this.$firebase = $firebase;
	
	//## changed(fireRef, cb)
	//##### deprecated
	//registers a firebase api on value listener
	this.changed = function(fireRef, cb){
		if(fireRef && fireRef.$ref){
			var ref = fireRef.$ref();
			ref.on('value', cb);
		}
		else{
			console.log('ref is no good');
		}
	};
	//## add(ref, data, idKey)
	//adds an element to firebase, waits for it's callback then uses the name returned
	//to add an id key
	//* ref (angularfire ref) - eg. $firebase('path')
	//* data (object) - the item to add to firebase
	//* idKey (string) [optional] - name of the id key to be added, doesn't add an id key if empty
	//
	//#### Returns
	// A promise containing the firebase reference of the added item
	//##### inline comments
	this.add = function(ref, data, idKey){
		ref = this.fbRef(ref);
		if(!data){data = {};}
		if(!idKey){ idKey = "id"; }
		
		var aRef = ref.push();
		data[idKey] = aRef.key;
		return this.set(aRef, data);
	};
	
	this.addToWatch = function(obj){
		refList.push(obj);
	};
	
	this.prepForLogout = function(){
		console.log('$destroying '+refList.length+' firebase references');
		refList.forEach(function(value){
			if(value.$destroy){ value.$destroy(); }
		});
		refList = [];
	};

	//definitely wait for $loaded, prefer not using this and use fbArray and such directly
	this.loadObject = function(path){
		var thing = this.fbObject(path);
		return thing.$loaded().then(function(){ return thing; });
	};
	this.loadArray = function(path){
		var thing = this.fbArray(path);
		return thing.$loaded().then(function(){ return thing; });
	};
	
	//## $loop(obj, fn, ctx)
	//loop function that ignores properties starting with $
	//a workaround for a bug
	//#### Arguments
	//* obj (object) - object to loop over
	//* fn (function) - callback function(value, key, sourceObj)
	//* ctx [optional] - context object to use for "this"
	//
	//#### Returns
	//A promise containing the requested angularfire array
	this.$loop = function(obj, fn, ctx){
		for(var i in obj){
			if(!(i.charAt(0) == '$')){
				fn.call(ctx, obj[i], i, obj)
			}
		}
	};


	this.fbRef = function(path){
		if(!path){
			path = '';
			return firebase.database().ref(firebaseRefUrl + path);
		}
		else if(typeof path === 'string'){	
			return firebase.database().ref(firebaseRefUrl + path);
		}
		else if(path){ //it's defined, probably a firebase ref
			return path;
		}
	};

		//new api
	this.fbArray = function fbArray(ref){
		if(typeof ref === "string"){ ref = this.fbRef(ref);}
		return $firebaseArray(ref);
	};
	
	this.fbObject = function fbObject(ref){
		if(typeof ref === "string"){ ref = this.fbRef(ref);}
		return $firebaseObject(ref);
	};
	
	//#### set(ref, val)
	//wraps the native set method with a variant that using promises
	this.set = function set(ref, val){
		var self = this;
		var currentZone = Zone.current;
		var defer = $q.defer();
		var newRef = ref.set(val, function(err){
			if(err){
				self.runInZone(currentZone, ()=>{
					defer.reject(err);
				});
			}
			else{ 
				self.runInZone(currentZone, ()=>{
					defer.resolve(ref);
				});
			}
		});
		return defer.promise;
	};
	
	this.remove = function remove(ref){
		return this.set(ref, null);
	};
	
	this.update = function update(ref, val){
		var currentZone = Zone.current;
		return $q(function(resolve, reject){
			ref.update(val, function(err){
				if(err){
					//do some decorating
					err.fbRef = ref.toString ? ref.toString() : '';
					err.fbData = JSON.stringify(val);
					//much nicer
					currentZone.run( ()=> reject(err) );
				}
				else{ currentZone.run( ()=>resolve(ref) ) }
			});
		});
	};
	
	//returns a more useful firebase toString
	//maxLevel sets a ceiling to stop at
	//so stringify(tickets.ref, 'Rev4') is tickets/$projectId/$planId
	this.stringify = function stringify(ref, maxLevel){
		var pathSegments = firebaseRefUrl.split('/');
		var actualBaseKey = pathSegments[pathSegments.length-2];
		var parent = ref;
		var path = "";
		while(parent.key && parent.key !== actualBaseKey && parent.key !== maxLevel){
			path = parent.key + "/" + path;
			parent = parent.parent;
		}
		return path;
	};
	
	this.runInZone = function(zone, cb, args) {
		if(Zone.current === zone){ return cb.apply(undefined, args); }
		return zone.run(cb, undefined, args);
	};
	
	
	//end new api
}]);
