'use strict';

import * as angular from "angular";
import * as _ from "lodash";

angular.module('ticketspaceApp')
.factory('watcherStore', ["$rootScope", function($rootScope) {


	//private
	var simpleList = [];
	var keyedList = {};

	function reset(){
		console.log('lag watcherStore reset', simpleList, keyedList);
		for(var i = 0; i < simpleList.length; i++){
			simpleList[i]();
		}
		simpleList = [];
		
		for(var key in keyedList){
			keyedList[key]();
		}
		keyedList = {};
		console.log('lag watcherStore reset 2', simpleList, keyedList);
	}

	function push(watch, key){
		if(_.isFunction(watch)){
			if(key){
				keyedList[key] = watch;
			}
			else{
				simpleList.push(watch);
			}
		}
	}
	
	return {
		push: push,
		reset: reset
	}
}]);
