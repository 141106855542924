/*
 * Currently we're just injecting TicketEditService and relying on
 * knowing it's internals and that it will have it's edit model set.
 */

import { Component, Input } from '@angular/core';

import { TicketEditService } from 'ng2/ticket-edit/ticket-edit.service';

import TicketEdit from 'ng2/common/models/TicketEdit';

/**
 * This component displays ticket related icons.  The only supported
 * icon currently is the promise pin.
 */
@Component({
	selector: 'ticket-icons',
	template: require('./ticket-icons.component.html'),
})
export class TicketIconsComponent {

	// data access...
	ticketEditData: TicketEdit;

	/**
	 * @input seems like we need the ticket to know about it's
	 * state to figure out the right pin to show
	 */
	//@Input() TBD...

	constructor(private ticketEditService: TicketEditService){
		// this.ticketEditData = ticketEditService.editModel
		// console.log('TicketIconsComponent constructor: ', this.ticketEditData)
	}

}

