
//--------------------- ALERT ------------------------
// Did not implement this with the constructor support, will have to add later if necessary

// TODO
// come up with better write implementation for objects... they don't need to be
// as complex or as disjointed as lists

import { database } from "firebase/app"
import { AngularFireDatabase, AngularFireList, AngularFireObject, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database'
import { Observable, Subject, BehaviorSubject, ConnectableObservable, queueScheduler, merge, empty } from 'rxjs';
import { takeUntil, map, observeOn } from 'rxjs/operators';

import * as firebaseUtils from 'ng2/fancy-firebase/firebase-utils';

import * as utils from "utils";

import { FancyAction, FancyActionTypes, AngularLandConnection, FancyIdJoin } from 'ng2/fancy-firebase/base';
import { FancySupplementalAction, SupplementalActionTypes } from "ng2/actions/supplemental-actions";

import { FancyActionTracker, FancyActionTrackerObj } from 'ng2/common/services/fancy-action-tracker-factory.service';


//types/ interfaces
type PathOrRefOrObservable = string | database.Reference | Observable<any>;
function isRef(x: PathOrRefOrObservable):x is database.Reference{
	return (<any>x).ref !== undefined;
}
function isObservable(x: PathOrRefOrObservable) : x is Observable<any>{
	return (<any>x).subscribe !== undefined;
}



export class FancyFirebaseObject<FancyModel>{
	private shutdownSubject = new Subject();

	public listRef: AngularFireObject<FancyModel>;

	protected _firebaseEvent$: Observable<any>; //oh screw you typescript
	
	//TEMP this needs to be corrected, just use for setting up the FancyIdJoin
	public _internalObject:FancyModel = null;
	
	/** TODO - move private somehow*/
	public appEvent$: Subject<FancyAction>;
	protected supplementalUpdate$: Subject<any>;

	/** Subscribe to recieve an updated list of tickets events. */
	public object$: BehaviorSubject<FancyModel>;

	public actionTracker: FancyActionTrackerObj;

	constructAction<SomeOtherActionName>(ctor, payload: any, type: string, key?: string):SomeOtherActionName{
		return new ctor(payload, type, key);
	}
	

	
	constructor(
			private angularLand:AngularLandConnection,
			path:PathOrRefOrObservable,
			// private actionCtor: new (...args: any[]) => SomeOtherActionName,
		){
		
		this.actionTracker = this.angularLand.fancyActionTrackerFactory.create();
		
		this.object$ = new BehaviorSubject(this._internalObject);
		this.appEvent$ = new Subject();
		var mappedAppEvents = this.handleCustomEvents(this.appEvent$);
		
		this.supplementalUpdate$ = new Subject();
		var mappedSupplementalEvents = this.handleSupplementalEvents(this.supplementalUpdate$);

		this._firebaseEvent$ = merge(
			this.setupInputObservable(path)
			.pipe(
				takeUntil(this.shutdownSubject),
				map( data => this.$$updated(data) )
			),
			mappedSupplementalEvents,
			mappedAppEvents
		)
		.pipe(observeOn(queueScheduler));
		
		this._firebaseEvent$
		.subscribe((data)=>{
			this._internalObject = data;
			this.object$.next(this._internalObject);
		});
	}
	
	private setupInputObservable(path : PathOrRefOrObservable){
		if(typeof path === "string"){
			this.listRef = this.angularLand.angularFireDatabase.object(path);
			return this.listRef.snapshotChanges()
		}
		else if(isRef(path)){
			//it's actually valid to pass a ref, the typescript definition is dumb
			this.listRef = this.angularLand.angularFireDatabase.object(<any>path);
			return this.listRef.snapshotChanges()
		}
		else if(isObservable(path)){
			return path;
		}
		else{
			throw new Error("the hell you pass in here");
		}
	}
	
	/**
	 * Called by the list building code, 
	 * @param  action the angularfire snapshot
	 * @return        The constructed child object
	 */
	// $$added(action: AngularFireAction<DatabaseSnapshot<any>>){
	// 	var obj = action.payload.val();
	// 	if(obj){ obj.$id = action.key; }
	// 	return obj;
	// }
	// 
	$$updated(child:FancyModel){
		return child;
	}
	// $$removed(child:FancyModel){
	// 
	// }
	
	//pass in a supplemental obserable to subscribe to, optionally wrapping it in an action
	public addSupplementalObservable(obs:Observable<any>){
		obs.subscribe(this.supplementalUpdate$)
		// else{
		// 	obs.map(thing => this.constructAction(this.actionCtor, thing, actionType, key || null) ).subscribe(this.supplementalUpdate$);
		// }
	}
	
	//override with the custom observable chain
	handleCustomEvents(baseObservable){
		return baseObservable;
	}
	
	handleSupplementalEvents(baseObservable):Observable<FancySupplementalAction>{
		return empty();
	}
	
	public destroy(){
		this.shutdownSubject.next();
		this.appEvent$.complete();
		this.object$.complete();
		this.actionTracker.actionTracker$.destroy(this.actionTracker.unsubscriber);
		
	}
}
