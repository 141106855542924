import Point from "js/lib/math/Point";

export default function Matrix(a?,c?,b?,d?,tx?,ty?){ /* 
	Matrix (2d transform) // TODO: rename (More than one type of 'Matrix', et al) Matrix2dt
	see: https://github.com/paperjs/paper.js/blob/master/src/basic/Matrix.js 
	
	new Matrix(Matrix || {a,c,b,d,tx,ty} || [a,c,b,d,tx,ty] || undefined)
	.set(a, c, b, d, tx, ty, silent) -> this
	.array = [a,c,b,d,tx,ty] -> this
	.clone = new Matrix(this)
	.equals(mx) -> Boolean(doesEqual(this.tolerance))
	.serialize() -> '[a,c,b,d,tx,ty]'
	.toString() -> 'matrix(a,c,b,d,tx,ty)'
	.toString3d() -> 'matrix3d([mx3d])'
	.reset(silent) -> this // sets identity matrix
	.translate(point, silent) -> this
	.scale(scale, point, silent) -> this
	.rotate(angle, point, silent) -> this
	.shear(shear, point, silent) -> this
	.skew(skew, point, silent) -> this
	.add(mx, silent) -> this // addition  
	.subtract(mx, silent) -> this // difference
	.push(mx, silent) -> this // multiply/concatenate
	.unshift(mx, silent) -> this // multiply/pre-concatenate
	.isIdentity() -> Boolean()
	.determinant -> null || Number(a*d - b*c)
	.invertable -> Boolean() (has determinate)
	.singular -> Boolean() (no determinate)
	.point(p) -> Point(transformed)
	.coords(c=[x,y, x,y, ...], 0, c.length) -> [x2,y2, x2,y2, ... ]
	.inverseTransform(point) -> Point() // reverse transforms a point
	.decompose() -> {scale, rotation, translation, shear}
	.translation -> Point()
	.scaling -> Number()
	.rotation -> Number()
	.origin -> Point()
	.inverted -> Matrix() // reverse transformation  */
	 
	this.initialize.apply(this, arguments)
}

Matrix.prototype = {
	constructor: Matrix,
	initialize: function Matrix(mx) {
		this.observers = [];
		this.tolerance = 0.1;  
		var count = arguments.length, ok = true;
		if (count === 6) {
			this.set.apply(this, arguments);
		} else if (count === 1) {
			if (mx.a !== undefined) { this.set(mx.a, mx.c, mx.b, mx.d, mx.tx, mx.ty) } 
			else if (Array.isArray(mx)) { this.set.apply(this, mx);}
			else { ok = false }
		} else if (count === 0) { 
			this.reset(true) 
		} else ok = false;
		if (!ok) throw new Error('Unsupported matrix parameters');  
	},
	set: function(a, c, b, d, tx, ty, silent){
		this.a = a; this.c = c; this.b = b; this.d = d; this.tx = tx, this.ty = ty;
		if(!silent) this.update();   
		return this
	},
	update: function(){
		this.observers.forEach(function(fn){ fn() })
	},
	get array(){ return [this.a, this.c, this.b, this.d, this.tx, this.ty] },
	set array(a){ this.set.apply(this, a) },    
	get clone(){ return new Matrix(this.array) },
	equals: function(mx) {
		return mx == this
			||    mx.a  - this.a  < this.tolerance && mx.c  - this.c  < this.tolerance
				 && mx.b  - this.b  < this.tolerance && mx.d  - this.d  < this.tolerance
				 && mx.tx - this.ty < this.tolerance && mx.tx - this.ty < this.tolerance
			|| false
	},
	serialize: function(){ return '[' + [this.a,this.c,this.b,this.d,this.tx,this.ty].join(', ') + ']' },
	toString: function(){ return 'matrix(' + [this.a,this.c,this.b,this.d,this.tx,this.ty].join(',') + ')' },
	toString3d: function(){ return 'matrix3d(' + [
		this.a, 0, 0, 0,
		0, this.d, 0, 0,
		0, 0, 1, 0,
		this.tx,this.ty,0, 1 
	].join(',') + ')' },
	reset: function(silent){
		return this.set( 1,0,0,1, 0,0 ,silent)
	},
	translate: function(point, silent){
		this.set( this.a, this.c, 
							this.b, this.d, 
							this.tx + point.x * this.a + point.y * this.b,
							this.ty + point.x * this.c + point.y * this.d , silent);
		return this;
	},
	scale: function(scale, point, silent){
		scale = scale ? new Point(scale) : new Point(1,1);
		if (point) this.translate(point, true)
		this.set(  this.a * scale.x, this.c * scale.x, 
							 this.b * scale.y, this.d * scale.y, 
							 this.tx,          this.ty , silent );
		if (point) this.translate(point.negate());
		return this;
	},
	rotate: function(angle, point, silent){
		angle *= Math.PI / 180;
		var y = (point && point.x) || 0, 
				x = (point && point.x) || 0;
		var cos = Math.cos(angle), 
				sin = Math.sin(angle);
		var tx = x - (x * cos) + (y * sin), 
				ty = y - (x * sin) + (y * cos);
		this.set( cos*this.a  +  sin*this.b,         cos*this.c + sin*this.d,
						 -sin*this.a  + -sin*this.c,         cos*this.c + cos*this.d,
							this.tx + (tx*this.a + ty*this.b), this.ty + (tx*this.c + ty*this.d) , silent);
		return this
	},
	shear: function(shear, point, silent){
		if (point) this.translate(point, true)
		this.set( this.a + shear.y * this.b, this.c + shear.y * this.d,
							this.b + shear.x * this.a, this.d + shear.x * this.c,
							this.tx,                   this.ty , silent );
		if (point) this.translate(point.negate());
		return this
	},
	skew: function(skew, point, silent){
		return this.shear( new Point(
			Math.tan(skew.x * Math.PI / 180),
			Math.tan(skew.y * Math.PI / 180)
		), point, silent)
	},
	add: function(mx, silent){
		this.set( this.a+mx.a,   this.c+mx.c,
							this.b+mx.b,   this.d+mx.d,
							this.tx+mx.tx, this.ty+mx.ty, silent );
		return this;
	},  
	subtract: function(mx, silent){
		this.set( this.a-mx.a,   this.c-mx.c,
							this.b-mx.b,   this.d-mx.d,
							this.tx-mx.tx, this.ty-mx.ty, silent );
		return this;
	},
	push: function(mx, silent){
		this.set( 
			this.a*mx.a + this.b*mx.c, this.c*mx.a + this.d*mx.c,
			this.a*mx.b + this.b*mx.d, this.c*mx.b + this.d*mx.d,
			this.tx + (this.a*mx.tx + this.b*mx.ty), 
			this.ty + (this.c*mx.tx + this.d*mx.ty), 
			silent 
		);
		return this;
	},
	unshift: function(mx, silent){
		this.set( this.a*mx.a + this.c*mx.b,           this.a*mx.c + this.c*mx.d,
							this.b*mx.a + this.d*mx.b,           this.b*mx.c + this.d*mx.d,
							mx.tx + this.tx*mx.a + this.ty*mx.b, mx.tx + this.tx*mx.c + this.ty*mx.d , silent );
		return this;
	},
	isIdentity: function(){
		return this.a  === 1 && this.c  === 0 
				&& this.b  === 0 && this.d  === 1
				&& this.tx === 0 && this.ty === 0
	},
	get determinant() {
		var det = this.a * this.d - this.b * this.c;
		if ( isFinite(det) && det != 0 && isFinite(this.tx) && isFinite(this.ty) ) 
			return det;
		else return null
	},
	get invertable(){ return !!this.determinant },
	get singular(){ return !this.determinant },
	point: function(point){
		point = point || new Point();
		return new Point( point.x*this.a + point.y*this.b + this.tx, 
											point.x*this.c + point.y*this.d + this.ty );
	}, 
	coords: function(coords, start, count){
		start = start || 0; count = count || coords.length - start;
		var out = [];
		while (start < count){
			var transformed = this.transform(
				new Point([coords[start++], coords[start++]]));
			out.push(transformed.x); out.push(transformed.y);
		}
		return out;
	},
	rect: function(rect){ 
		// Todo?
	},
	inverseTransform: function(point){
			var det = this.determinant;
			if (!det) return null;
			var x = point.x - this.tx,  y = point.y - this.ty;
			return new Point( (x * this.d - y * this.b) / det, 
												(y * this.a - x * this.c) / det)
	},
	decompose: function(){
		var a = this.a, c = this.c, b = this.b, d = this.d, tx = this.tx, ty = this.ty;
		if ((a * d - b * c) == 0) return null;
		var scaleX = Math.sqrt(a*a + b*b);
		a /= scaleX; b /= scaleX;
		var shear = a * c + b * d;
		c -= a * shear; d -= b * shear;
		var scaleY = Math.sqrt(c*c + d*d);
		c /= scaleY; d /= scaleY;
		shear /= scaleY;
		if(a*d < b*c){
			a = -a; b = -b;
			shear = -shear;
			scaleX = -scaleX
		}
		return {
			scale: new Point(scaleX, scaleY),
			rotation: -Math.atan2(b, a) * 180 / Math.PI,
			translation: new Point(tx, ty),
			shear: shear
		}
	},
	get translation(){ return new Point(this.tx, this.ty) },
	get scaling(){ return (this.decompose() || {}).scale || 1 },
	get rotation(){ return (this.decompose() || {}).rotation || 0 },
	get origin(){ return new Matrix(this.a, this.c, this.b, this.d, 0, 0); },
	get inverted(){
		var det = this.determinant;
		return det && new Matrix( this.d  / det, -this.c / det,
															-this.b / det, this.a / det,
															(this.b*this.ty - this.d*this.tx) / det, 
															(this.c*this.tx - this.a*this.ty) / det)
	}

	
}
