import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, Renderer2, ElementRef, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';

import { Observable, Subject, BehaviorSubject } from "rxjs";
import { takeUntil, pluck, distinctUntilChanged, debounce, map } from "rxjs/operators";
import { drop } from "ng2/common/rxjs-internal-extensions";

import { Swimlane } from "ng2/common/models/Swimlane";

import {renderQueue} from "ng2/common/RenderQueue";

import * as strings from "ng2/common/strings";

@Component({
  selector: 'swimlane',
  template: require('./swimlane.component.html'),
  host: {class: 'swimlane'},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwimlaneComponent implements OnInit, OnChanges, OnDestroy {
	@Input() swimlane$: Observable<Swimlane>;
	@Output() onClose = new EventEmitter<Swimlane>();
	@Output() onDelete = new EventEmitter<Swimlane>();
	@Output() onUpdate = new EventEmitter<{model: Swimlane, data: Object}>();
	
	@Input() includeTickets:boolean;
	@Output() includeTicketsChange = new EventEmitter<boolean>();
	
	public tooSmall = false;
	public bitSmaller = false;
	
	private swimlaneSync:Swimlane;
	private resetSubject = new Subject(); //used for reseting the subscription on ngChange
	private shutdownSubject = new Subject(); //called on destruction
	
	public theForm = this.formBuilder.group({
		label: [''],
		color: [''],
		includeTicketsCheckbox: ['']
	})

  constructor(private renderer: Renderer2, private el: ElementRef, private formBuilder: FormBuilder, private cd: ChangeDetectorRef) {
		const delay$ = renderQueue.ob$.pipe(takeUntil(this.shutdownSubject), drop(3));
		
		this.theForm.valueChanges.pipe(
			takeUntil(this.shutdownSubject),
			debounce( ()=> delay$ ),
			map(form => {
				//do the map for now for when color is added... it's technically not necessary at the moment since the format of the form is the same as the model
				//update the distinctUntilChanged when you update this
				return {
					label: form.label,
					color: form.color
				}
			}),
			distinctUntilChanged((a, b)=> a.label === b.label && a.color === b.color),
		).subscribe(form => {
			this.update(form);
		});
		
		//specific check for the checkbox
		this.theForm.controls['includeTicketsCheckbox'].valueChanges.pipe(
			takeUntil(this.shutdownSubject)
		).subscribe(state => {
			console.log('emitting', state);
			this.includeTicketsChange.emit(state);
		});
	}
	/**
	 * Called when the user moves the color-picker pointer and selects a new color
	 */
	public colorMoved(color: string) {
		// Comment out this line if we don't want live updating of the swimlane area when setting a color
		this.theForm.controls['color'].setValue(color);
	}
	
	close(){
		if(!this.swimlaneSync.view.label){
			Logging.warning(strings.SWIMLANE_NEEDS_LABEL);
			return;
		}
		this.onClose.emit(this.swimlaneSync);
	}
	delete(){
		this.onDelete.emit(this.swimlaneSync);
	}
	update(data){
		this.onUpdate.emit({model: this.swimlaneSync, data: data});
	}
	
	//change detection does not fire on drags, only dragEnds, so handle manually
	//shutdown and resubscribe the the observable when it changes
	ngOnChanges(changes:SimpleChanges){
		if(changes.swimlane$){
			this.resetSubject.next(true);
			let cleaned$ = this.swimlane$.pipe(
				takeUntil(this.resetSubject),
				pluck("payload")
			);
			cleaned$.subscribe(this.updateSwimlanePosition);
			
			cleaned$.pipe(
				distinctUntilChanged((swimA:Swimlane, swimB:Swimlane)=>{
					return swimA.view.label === swimB.view.label && swimA.view.color === swimB.view.color;
				})
			)
			.subscribe(this.applyFormUpdates);
		}
		
		if(changes.includeTickets){
			console.log('hi', changes);
			this.theForm.controls['includeTicketsCheckbox'].setValue(changes.includeTickets.currentValue, {emitEvent: false});
		}

	}
	
	private applyFormUpdates = (swimlane: Swimlane)=> {
		
		this.theForm.patchValue({
			label: swimlane.view.label,
			color: swimlane.view.color
		}, {emitEvent: false})
		console.log('hi', swimlane, this);
	}
	
	private updateSwimlanePosition = (swimlane:Swimlane)=> {
		this.swimlaneSync = swimlane;
		
		//in theory the "correct" thing here would be to trigger change detection, but perf...
		//also in the perf version there should technically be a subject to push onto, then 
		//a view observer with a distinctUntilChanged instead of the getValue check
		let bitSmaller = (swimlane.view.screenBottom - swimlane.view.screenTop) < 94;
		if(bitSmaller !== this.bitSmaller){
			this.bitSmaller = bitSmaller;
			if(this.bitSmaller){ this.renderer.addClass(this.el.nativeElement, 'swimlane-bit-smaller'); }
			else{ this.renderer.removeClass(this.el.nativeElement, 'swimlane-bit-smaller'); }
		}
		
		let tooSmall  = (swimlane.view.screenBottom - swimlane.view.screenTop) < 46;
		if(tooSmall !== this.tooSmall){
			this.tooSmall = tooSmall;
			if(this.tooSmall){ this.renderer.addClass(this.el.nativeElement, 'swimlane-too-small'); }
			else{ this.renderer.removeClass(this.el.nativeElement, 'swimlane-too-small'); }
			this.cd.detectChanges();
		}
		
		renderQueue.push(()=>{
			this.renderer.setStyle(this.el.nativeElement, 'top', swimlane.view.screenTop+"px");
			this.renderer.setStyle(this.el.nativeElement, 'height', (swimlane.view.screenBottom - swimlane.view.screenTop)+"px");
		}, "drawSwimlane"+this.swimlaneSync.$id);
	}

  ngOnInit() {
		// console.log('swimlane.component inited', this.includeTickets);
		console.log('component inited', performance.now() - window.blehTimestamp);
  }
	
	ngAfterViewInit(){
		console.log('component fully inited', performance.now() - window.blehTimestamp);
	}
	
	ngOnDestroy(){
		console.log('destroy');
		this.resetSubject.next(true);
		this.shutdownSubject.next(true);
	}

}
