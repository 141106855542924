'use strict';

import * as angular from "angular";
import * as moment from "moment";
import * as utils from "utils";
import * as firebase from "firebase/app";

import * as bowser from "bowser";

import {sessionErrorTracking} from "ng2/common/SessionErrorTracking";

declare const window:any;

const gitCommit = MB4_PREPROCESSOR_CONFIG.SHORT_SHA;

//some voodo that reports the browser being used
window.navigator.sayswho= (function(){
    var ua= navigator.userAgent, tem,
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
})();

angular.module('ticketspaceApp').factory("fbReporting", ["$log", "$window", "firebaseRefUrl", function($log, $window, firebaseRefUrl) {
	var storedInfo:any = {};
	
	var additionalKey = MB4_PREPROCESSOR_CONFIG.ADDITIONAL_IDENTIFIER;
	
	function sessionInfo(){
		var obj:any = {
			screenWidth: $window.outerHeight ? $window.outerHeight : null,
			screenHeight: $window.outerWidth ? $window.outerWidth : null
		};
		if($window.location && $window.location.href){ obj.url = $window.location.href; }
		
		return obj;
	}
	
	//tests and such
	// [
		// "something-simple",
		// "[$rootScope:inprog] http://errors.angularjs.org/1.4.7/$rootScope/inprog?p0=%24digest",
		// "new Firebase failed: First argument must be a valid firebase URL and the path can't contain \".\", \"#\", \"$\", \"[\", or \"]\"."
	// ].forEach(function(path){
		// console.log('trying '+ path);
		// var refUrl = validateRefUrl(path);
		// console.log('parsed',refUrl);
		// console.log('final', firebaseRefUrl + refUrl);
		// console.log(firebase.database().ref(firebaseRefUrl + refUrl));
	// })
	
	function validateRefUrl(url){
		var decoded = decodeURIComponent(url);
		decoded = decoded.replace(/\//g, '|');
		decoded = decoded.replace(/\s/g, ' ');
		decoded = decoded.replace(/[.#$\[\]]/g, '*');
		return decoded;
	}

	function reportEvent(eventType, eventName, extra){
		return;
		setTimeout(function(){
			if(!eventName) { return; }
			if(!eventType) { eventType = 'events'; }
			
			var time = moment().format('YYYY-MM-DD');
			var refUrl = 'events/'+validateRefUrl(eventType)+'/'+validateRefUrl(time)+'/'+validateRefUrl(eventName);
			
			var ua = bowser.getParser(window.navigator.userAgent);
			var browserName = ua.getBrowserName() + ' ' + ua.getBrowserVersion();
			
			var eventObj:any = {
				timestamp: moment().format(),
				message: eventName,
				browser: browserName,
				agent: ua.getResult()
			};
			if(additionalKey){ eventObj[additionalKey] = true; }
			if(gitCommit){ eventObj.build = gitCommit; }
			
			if(extra){
				
				extra.prevErrorCount = sessionErrorTracking.count;
				extra.firstError = sessionErrorTracking.firstError();
				extra.recentErrors = sessionErrorTracking.lastErrors();
				
				eventObj['extra'] = extra;
			}
			if(storedInfo.userId){
				eventObj['userId'] = storedInfo.userId;
			}
			eventObj['sessionLengthInSeconds'] = sessionErrorTracking.sessionLengthS;
			
			if(window.analyticsHelper && window.analyticsHelper.getHistory()){
				eventObj['eventHistory'] = window.analyticsHelper.getHistory();
			}
			
			eventObj.session = sessionInfo();
			//console.log(eventObj);
			var ref = firebase.database().ref(firebaseRefUrl + refUrl);
			var countRef = ref.child('count');
			countRef.once('value', function(dataSnapshot){
				if(!dataSnapshot.exists()){
					countRef.set(1);
				}
				else{
					countRef.set( dataSnapshot.val() + 1);
				}
				
				var childRef = ref.child('instances');
				console.log(childRef.toString());
				childRef.push(eventObj);
				
			});
		});
	}
	
	function setUser(userId){
		storedInfo.userId = userId;
	}

	var errThrottle = utils.throttleError( function( error, extra? ){
		reportEvent("errors", error, extra );
		reportToAnalytics(error, extra, 'error-client');
	}, -10000 );

	function reportError(error, extra){
		errThrottle( error, extra );
		// sessionErrorTracking.pushError(error);
	}
	
	function reportWarning(message, extra){
		reportEvent('warnings', message, extra);
		reportToAnalytics(message, extra);
	}
	//since people have decided to make normal warnings worthless...
	function reportRealWarning(message, extra){
		reportEvent('errors', message, extra);
		reportToAnalytics(message, extra, 'error-client');
	}
	
	function reportNotice(message, extra){
		reportEvent('notice', message, extra);
	}
	
	function reportToAnalytics(msg, extra, customEvent?){
		return;
		var obj = {
			message: msg
		};
		if(extra){ angular.extend(obj, extra); }
		if(!customEvent){ customEvent = 'warning-client'; }
		if(analytics){
			utils.analyticsHelper.track(customEvent, obj);
		}
		else{
			console.log('not reporting error due to analytics not existing');
		}
	}
	
	return {
		reportNotice: reportNotice,
		reportWarning: reportWarning,
		reportRealWarning: reportRealWarning,
		reportError: reportError,
		setUser: setUser
	}
}]).run(["fbReporting", function(fbReporting){
	window.reporting = fbReporting;
}]);
	
