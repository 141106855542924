import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Role } from "../../models/Role";
import { You } from "../../models/You";
import { SelectItem } from "primeng/api";
import { Subject } from "rxjs";
@Component({
	selector: 'role-changer',
	template: require('./role-changer.component.html'),
	styles: [],
	changeDetection: ChangeDetectionStrategy.Default
})
export class RoleChangerComponent implements OnInit {
	@Input() roleList$: Subject<Map<string, Role>>;
	@Input() you$: Subject<You>;
	@Input() projectMember: any;
	@Output() roleChanged: EventEmitter<any> = new EventEmitter();
	
	public roleList: SelectItem [] = [];
	public currentRole: Role = null;
	
	constructor() {}
	
	ngOnInit() {
		this.roleList$.subscribe((newRoles: Map<string, Role>) => {
			this.roleList = [];
			newRoles.forEach((role: Role, roleId: string) => {
				if (this.projectMember.accessLevel === "General" && this.projectMember.secondaryRoles && !this.projectMember.secondaryRoles[roleId]){return}
				this.roleList.push({
					label: role.roleName,
					value: role
				});
			})
		});
		
		this.you$.subscribe((newYou: You) => {
			if (newYou.data){
				this.currentRole = newYou.data.role;
			}
		});
	}
	
	public updateProjectMemberRole(newRole) {
		this.projectMember.$ref().update({roleId: newRole.value.roleId});
	}
}
