import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'project-dashboard',
  template: require('./project-dashboard.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
