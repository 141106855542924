import * as angular from "angular";
import { dateCache } from "../../ng2/common/date-cache";

var serviceTerms = require("legal/Service-Terms.pdf");
var privacyPolicy = require("legal/Privacy-Policy.pdf");
var copyrightPolicy = require("legal/Copyright-Policy.pdf");

angular.module('ticketspaceApp')
	.component("legalese", {
		template: `
		<div class="footer-links">
			<div>
				<a href="${serviceTerms}">Service Terms</a>
				<a href="${privacyPolicy}">Privacy Policy</a>
				<a href="${copyrightPolicy}">Copyright Policy</a>
			</div>
			<div class="credits">
				© {{$ctrl.dateCache.today.format("YYYY")}} MOCA Systems, Inc. All rights reserved. Produced Under U.S. Pat. 10,410,178 B2
			</div>
		</div>
`,
		controller: function(){
			this.dateCache = dateCache;
		},
		controllerAs: "$ctrl"
	});
