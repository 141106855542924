/**
 * Current open issues:
 * How do  we decide if the role is editable and how do we make the
 * UI repsond appropriatly (i.e. display the dropdown or the grayed
 * out thing)?
 * 
 * Inputs:
 *   list of roles
 *   currently selected role
 * Outputs:
 *   what happens on selection change...?
 */
import { Component, Input } from '@angular/core';

import { Role } from 'ng2/ticket-edit/components/temp-models/Role';

/**
 * Displays the ticket's role.  If the role is editable, then it's 
 * displayed as a dropdown.  If it's not editable, it's displayed 
 * in a grayed out panel.
 * 
 * More detail...
 */
@Component({
	selector: 'ticket-role',
	template: require('./ticket-role.component.html'),
	host: {'class': 'ticket-role--style'}
})
export class TicketRoleComponent {

	/**
	 * @input roles - The list of roles to choose from
	 */
	@Input() roleList: Role[];

	/**
	 * @input roles - The list of roles to choose from
	 */
	@Input() selectedRole: Role[];

	constructor(){
		console.log('TicketRoleComponent constructor');
	}

}
