(function(){
	angular.module('ticketspaceApp')
	.component('flippyArrow', {
		bindings: {
			"up": "<"
		},
		controller: FlippyArrow
	})
	
	FlippyArrow.$inject = ['$element', '$attrs'];
	function FlippyArrow($element, $attrs){
		var up = $('<i class="material-icons">keyboard_arrow_up</i>');
		var down = $('<i class="material-icons">keyboard_arrow_down</i>');
		
		this.$onInit = function(){
			
		}
		this.$onChanges = function(changes){
			if(changes.up){
				if(changes.up.currentValue){$element.append(up); down.remove();}
				else{ $element.append(down); up.remove(); }
			}
		}
	}
})();
