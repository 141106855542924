/**
 * Define the interface for objects that can be inserted into the SpatialHash
 */
export interface ISpatialObject {
	x: number;
	y: number;
	width: number;
	height: number;
}
/**
 * The SpatialHash class applies spatial partitioning to the world (Broad phase). It accepts anything that meets the ISpatialObject interface
 *
 * It works by taking an ISpatialObject and placing it into multiple "buckets" of space. When you want to retrieve nearby
 * elements, you only receive ISpatialObject's which are close to the source ISpatialObject.
 */
export class SpatialHash {
	public grid: Map<string, ISpatialObject[]> = new Map();
	public list: ISpatialObject[] = [];
	public getKeys: (thing: ISpatialObject) => any;
	/**
	 * @param {number} shift - The number of times to shift each property of the object
	 *  to put it into "buckets". The larger this number, the larger the buckets. Larger
	 *  buckets will result in a more performant SpatialHash but result in more objects to
	 *  retrieve.
	 */
	constructor(shift: number = 8){
		this.getKeys = this._makeHash(shift);
	}
	/**
	 * Inserts a new object into the spatial hash.
	 *
	 * @param {ISpatialObject} thing - The object to store
	 */
	public insert(thing: ISpatialObject){
		const keys: string = this.getKeys(thing);
		let bucket;
		
		this.list.push(thing);
		
		for (let i = 0, len = keys.length; i < len; ++i){
			bucket = this.grid.get(keys[i]);
			
			if (bucket){
				bucket.push(thing);
			} else {
				this.grid.set(keys[i], [thing]);
			}
		}
	}
	/**
	 * Retrieves all the objects likely to collide with the passed in object or rect.
	 *
	 * If no thing is passed, returns every object.
	 *
	 * @param {ISpatialObject} thing - The object to check against.
	 */
	public grab(thing?: ISpatialObject): ISpatialObject[] {
		if (!thing) {
			return this.list;
		}
		
		let ret = [],
			keys = this.getKeys(thing),
			bucket;
		
		for (let i = 0, len = keys.length; i < len; ++i) {
			bucket = this.grid.get(keys[i]);
			
			if (bucket) {
				ret = ret.concat.apply(ret, bucket);
			}
		}
		
		return ret;
	}
	/**
	 * Clear the SpatialHash data
	 */
	public clear(){
		this.grid.clear();
		this.list = [];
	}
	/**
	 * Destroys this spatial hash
	 * @param options
	 */
	public destroy(options?){
		this.clear();
		this.getKeys = null;
		this.grid = null;
		this.list = null;
	}
	/**
	 * Creates a function that is used to hash an object into the keys it belongs to.
	 *
	 * @param {number} shift
	 */
	private _makeHash(shift: number): (thing: ISpatialObject) => any {
		let keys = [];
		
		return function (obj) {
			keys.length = 0;
			
			let sx = obj.x >> shift,
				sy = obj.y >> shift,
				ex = (obj.x + obj.width) >> shift,
				ey = (obj.y + obj.height) >> shift;
			
			for(let y = sy; y <= ey; ++y) {
				for(let x = sx; x <= ex; ++x) {
					keys.push(x + ':' + y);
				}
			}
			
			return keys;
		};
	}
}