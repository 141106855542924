import {Injectable} from "@angular/core";
import { Router, ActivationEnd, Event, ActivatedRouteSnapshot} from '@angular/router';
import { Subject, BehaviorSubject, Observable, merge } from "rxjs";
import { filter, tap, map, scan, distinctUntilChanged } from 'rxjs/operators';

import {RouteUtils} from "./route-utils.service";


//just replicate the old one, which was meant to solve the case of 
//"selected projects not necessarily being tied to the state"
//in theory we should be able to edit this out when everything is all angular

interface ProjectParamState{
	projectId?: string;
	planId?: string;
}

@Injectable()
export class ProjectNotifier{
	private storageSubject = new BehaviorSubject<ProjectParamState>({});
	
	//this is the main one
	public projectState: Observable<ProjectParamState>;
	//this is the less useful one
	public nonDistinctProjectState: Observable<ProjectParamState>;
	
	public projectStateSnapshot:ProjectParamState;
	
	constructor(
		private router: Router, private routeUtils: RouteUtils
	){
		routeUtils.snapshot.pipe(map(snapshot => {
			let map: ProjectParamState = {};
			if(!snapshot){ return map; }
			//v1 route is any update
			// if(snapshot.paramMap.has('projectId')){map.projectId = snapshot.paramMap.get('projectId');}
			// if(snapshot.paramMap.has('planId')){map.projectId = snapshot.paramMap.get('planId');}
			//v2 route update is a "reset"
			map.projectId = snapshot.paramMap.get('projectId') || null;
			map.planId = snapshot.paramMap.get('planId') || null;
			return map;
		})).subscribe(this.storageSubject);
		
		let temp = this.storageSubject.pipe(
			scan((acc:ProjectParamState, state:ProjectParamState) => {
				let newObj:ProjectParamState = Object.assign({}, acc);
				["projectId", "planId"].forEach(key => { if(state[key] !== undefined){ newObj[key] = state[key]; } })
				return newObj;
			}, {} as ProjectParamState)
		);
		
		this.projectState = temp.pipe(
			distinctUntilChanged((a, b) => a.projectId === b.projectId && a.planId === b.planId )
		);
		this.nonDistinctProjectState = temp;
		
		this.projectState.subscribe(sync => this.projectStateSnapshot = sync);
	}
	
	setProjectAndPlan(projectId?: string, planId?: string){
		let obj: ProjectParamState = {};
		if(projectId !== undefined){ obj.projectId = projectId; }
		if(planId !== undefined){ obj.planId = planId; }
		this.storageSubject.next(obj);
	}
	
	setProject(projectId: string){
		this.setProjectAndPlan(projectId);
	}
	setPlan(planId: string){
		this.setProjectAndPlan(undefined, planId);
	}
}
